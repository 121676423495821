import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {InputModel} from "@app/shared/communication-mapping/services/input-model";

@Injectable({
  providedIn: 'root'
})
export class CommunicationDispatcherService {

    private triggerEvent = new Subject();
    triggerEvent$ = this.triggerEvent.asObservable();

    static CONSTANTS: Object = {
        'loadedData': 'event:loadedData',
        'updatedTemplateId': 'event:updatedTemplateId',
        'readyToSave': 'event:readyToSave',
        'updateFormValue': 'event:updateFormValue',
        'checkIsValidForm': 'event:checkIsValidForm',
        'beforeSaveAction': 'event:beforeSaveAction',
        'afterSaveAction': 'event:afterSaveAction',
        'getCurrentTemplateId': 'event:getCurrentTemplateId',
        'unSaveConfirmation': 'event:unSaveConfirmation',
        'inlineSaveAction': 'event:inlineSaveAction',
        'updateTemplateDetails': 'event:updaTemplateDetails',
        'close': 'event:close',
        'saveAndClose': 'event:saveAndClose',
        'save': 'event:save'
    };
  constructor() { }

    dispatchEvent(actionName:string, data:any={}) {
        data['actionName'] = actionName;
        this.triggerEvent.next(data);
    }
}
