import {
    Component,
    ElementRef, OnInit,
    Renderer2,
    Inject,
    ViewEncapsulation
} from '@angular/core';
import {ModalContainerComponent} from "ngx-bootstrap/modal";
import {SidepanelOptions} from "@app/sidepanel/services/sidepanel-options";
import { TranslateService } from '@ngx-translate/core';
import {AddOpportunityService} from "@app/features/customers/add-opportunity/service/add-opportunity.service";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'popup-panel-modal-container',
    template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')" role="document">
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
    host: {
        class: 'modal cs-popup-panel-v2',
        role: 'dialog',
        tabindex: '-1',
        '[attr.aria-modal]': 'true',
        '[attr.aria-labelledby]': 'config.ariaLabelledBy',
        '[attr.aria-describedby]': 'config.ariaDescribedby'
    },
    styleUrls: ['./popup-panel.component.scss']
})

export class PopupPanelComponent extends ModalContainerComponent implements OnInit{

    config: SidepanelOptions;

    constructor(options: SidepanelOptions,
        protected element: ElementRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private translate: TranslateService,
        private opService: AddOpportunityService,
        private renderer: Renderer2) {
        super(options, element, renderer);
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if(this.bsModalService.getModalsCount()) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-'+this.bsModalService.getModalsCount());
        }
    }
}

