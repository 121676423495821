import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HansontableSelect2} from '@app/directives';
import Handsontable from 'handsontable';
import {CsToastBoxService} from '@app/services';
import {HotTableRegisterer} from '@handsontable/angular';
import {BehaviorSubject} from 'rxjs';

declare var _: any;
declare var jQuery;

@Injectable({
  providedIn: 'root'
})
export class CustomerInvoiceService implements OnDestroy{
    private totalEmitterSource: BehaviorSubject<Object> = new BehaviorSubject(null);
    totalSource$ = this.totalEmitterSource.asObservable();
    private lineItemId: BehaviorSubject<Object> = new BehaviorSubject(null);
    deletedId$ = this.lineItemId.asObservable();
    hotInstances = [];
    totals = {'breakdown': [], 'grandtotal': {}};
    breakdown: number = 0;
    cisOptions = [];
    nominalCodeOptions = [];
    invoiceItemCategories = [];
    taxItems = [];
    spreadSheetViewData = {'items' : [], pricingItems: [] };
    spreadsheetLineItems = [];
    spreadSheetSettings = [];
    defaultLineItemSchema: {};
    cisRate = 0;
    vatType = '';
    lineItemDefaults = {};
    isHideVat = false;
    isHideCis = false;
    validateErrorRow = false;
    customisableSpreadsheetColumns = [];
    invoiceBreakdownTypeName = ['', 'no_breakdown', 'breakdown_by_category', 'full_breakdown' , 'full_breakdown_by_category'];
    rowToDelete = [];
    colHeaders = ['id', 'Description', 'CIS', 'Quantity', 'Unit Price', '{taxLabel}', 'VAT Rate', 'Nominal Code', 'Total (exc {taxLabel})', 'Total VAT', 'Total CIS', 'category ID', 'ItemId', 'Actions'];
    disableColHeader = '';
    invoiceTaxState: any = '';
    jobId: number;
    requiredFields = {
        '1': 'name',
        '2': 'cis',
        '3': 'quantity',
        '4': 'unit price',
        '5': 'VAT item',
        '6': 'VAT rate',
        '7': 'nominal code'
    };

    constructor(private toastBox: CsToastBoxService,
                private hotRegisterer: HotTableRegisterer,
                @Inject('confirmationBoxHelper') private confirmationBoxHelper,
                @Inject('$scope') private ajsScope) {
        Handsontable.renderers.registerRenderer('customRenderer', this.customrenderer);
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }

    ngOnDestroy() {
    }

    /* custom functions */

    removeData(hotId){
        delete this.spreadsheetLineItems[hotId];
        delete this.hotInstances[hotId];
        delete this.totals[hotId];
    }

    formatTaxItems(taxData){
        let tax = [];
        const taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach((province) => {
            let taxLabel = province[0]['taxProvinceName'];
            tax.push({'text': taxLabel, 'children': province});
        });
        return tax;
    }
    setCustomisableSpreadsheetColumns(data){
        this.customisableSpreadsheetColumns = data;
        for (let hotId in this.hotInstances) {
            if (this.hotInstances[hotId]) {
                this.hotInstances[hotId].render();
            }
        }
    }

    setBasicSettings(params) {
        const defaults = params.defaults;
        this.taxItems = params.tax;
        this.nominalCodeOptions = params.nominalCode;
        this.invoiceItemCategories = params.invoiceItemCategories;
        this.cisRate = defaults.cisRate;
        this.lineItemDefaults['taxItemId'] = params.defaults.taxItemId;
        this.lineItemDefaults['nominalCodeId'] = this.nominalCodeOptions.length > 0 ? this.nominalCodeOptions[0]['id'] :'';
        this.isHideCis = defaults.isHideCIS;
        this.isHideVat = defaults.isHideVat;
        this.cisOptions = params.cisOptions;
        const taxLabel = params['taxData']['taxLabel'];
        this.colHeaders[5] = taxLabel;
        this.colHeaders[8] = `Total (exc ${taxLabel})`;
        this.jobId = params['jobId'];
    }

    setBreakdown(breakdown) {
        this.breakdown = breakdown;
        let hotIds = Object.keys(this.spreadsheetLineItems);
        let spreadsheetService=this;
        hotIds.forEach((value) => {
            // Push already saved line items to rowToDelete array to fix COM-21733
            this.spreadsheetLineItems[value].forEach((row) => {
                if ((typeof row !== 'undefined') && row.id) {
                    spreadsheetService.rowToDelete.push(row.id);
                }
            });
            this.spreadsheetLineItems[value] = [];
        });
    }

    setVatType(vatType){
        this.vatType = vatType;
        for (let hotId in this.hotInstances) {
            if (this.hotInstances[hotId]) {
                this.hotInstances[hotId].render();
            }
        }
    }

    populateData(data){
        let hotId = this.invoiceBreakdownTypeName[this.breakdown];

        if (typeof this.spreadsheetLineItems[hotId] === 'undefined') {
            this.spreadsheetLineItems[hotId] = [];
        }

        data.forEach((value, key) => {
           const lineItem = Object.assign({}, this.defaultLineItemSchema);
            lineItem['id'] = value['id'];
            lineItem['quantity'] = value['quantity'];
            lineItem['unitprice'] = value['unitprice'];
            lineItem['taxItemId'] = value['taxItemId'];
            lineItem['vatrate'] = value['vatrate'];
            lineItem['itemId'] = value['descriptionid'];
            lineItem['cis'] = value['cis'] ? value['cis'] : lineItem['cis'];
            lineItem['nominalcode'] = value['nominalCodeId'] ? value['nominalCodeId'] : lineItem['nominalcode'];

            if (this.breakdown === 2) {
                lineItem['item'] = value['description'];
            } else if (this.breakdown === 3 || this.breakdown === 4) {
                lineItem['pricingItem'] = value['description'];
            }
            if (this.breakdown === 4) {
                lineItem['invoiceItemCategoryId'] = value['invoiceItemCategoryId'];
                let hot =  _.findWhere(this.invoiceItemCategories, {spreadsheet_categoryId : parseInt(lineItem['invoiceItemCategoryId']) });
                hotId = hot['spreadsheetHotID'];
            }

           this.spreadsheetLineItems[hotId].push(lineItem);
        });
        const defaultLineItemSchema = Object.assign({},this.defaultLineItemSchema);
        if (this.invoiceTaxState === '') {
            // @ts-ignore
            this.invoiceTaxState = (_.max(_.pluck(data, 'taxItemId')) > null);
            if (this.invoiceTaxState === false) {
                defaultLineItemSchema['taxItemId'] = null;
            }
        }

        Object.keys(this.hotInstances).forEach((hot) => {
            if (this.invoiceTaxState === false) {
                this.isHideVat = true;
                this.spreadSheetSettings[hot]['hiddenColumns']['columns'].push(5);
                this.spreadSheetSettings[hot]['dataSchema'] = defaultLineItemSchema;
            }

            this.hotInstances[hot].updateSettings(this.spreadSheetSettings[hot]);
            this.hotInstances[hot].render();
        });
    }

    /* Handsontable call backs */

    afterInit(hotId, hotInstance) {
        this.hotInstances[hotId] = hotInstance;
        this.totals['breakdown'][hotId] = {'total' : 0 , 'lineItemCount' : 0};
        this.totalEmitterSource.next(this.totals);
        const baseSettings = {
            colWidths: [60, 220, 70, 120, 140, 200, 120, 170, 160, 70, 70, 70, 70, 70, 80],
            colHeaders: (index)  => {
                let header = this.colHeaders[index];
                let prop = this.spreadSheetSettings[hotId]['columns'][index]['data'];
                prop = (prop === 'unitprice') ? 'unitPrice' : prop;
                const check  = _.findWhere(this.customisableSpreadsheetColumns, {name: prop, selected : true});
                this.disableColHeader = '';
                if (check) {
                    header = header + '<span  tooltip="Column hidden from customer" tooltip-placement="top" class="ss-ban hidden-column"> </span>';
                    this.disableColHeader = index;
                }
                return header;
            },
            afterSelection: (row, column) => {
                if(hotInstance.countCols() === column+1) {
                    jQuery('.wtBorder').addClass('no-bg');
                } else {
                    jQuery('.wtBorder').removeClass('no-bg');
                }
            },
            enterMoves: () => {
                let maxCol = hotInstance.getCellMeta(0, 0).columns.length-1;
                if(hotInstance.getSelected()[1] >= maxCol) {
                    return {row: 1, col: -maxCol}
                } else {
                    return {row: 0, col: 1}
                }
            },
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: false
            },
            preventOverflow: 'vertical',
            rowHeaders: true,
            startRows: 0,
            startCols: 0,
            minSpareCols: 0,
            autoWrapRow: true,
            formulas: true,
            minSpareRows: 0,
            minRows: 0,
            comments: true,
            afterGetColHeader: this.afterGetColHeader,
            currentColClassName: 'selectedCol',
            currentRowClassName: 'selectedRow',
            viewportRowRenderingOffset: 1000,
            stretchH: 'all',
            copyPaste : false,
            tabMoves: {row: 0, col: 1},
            dataSchema: this.defaultLineItemSchema,
        };

        const customSettings = this.getBreakDownSettings();
        if (hotId === 'CU_Labour') {
            customSettings['hiddenColumns'] = { columns: [ 0, 6, 9, 10, 11, 12] };
        }

        if (this.isHideCis) {
            customSettings['hiddenColumns']['columns'].push(2);
        }
        if (this.isHideVat || this.invoiceTaxState === false) {
            customSettings['hiddenColumns']['columns'].push(5);
            if (this.invoiceTaxState === false) {
                baseSettings['dataSchema']['taxItemId'] = null;
            }
        }

        this.spreadSheetSettings[hotId] = Object.assign({},  baseSettings, customSettings);
        hotInstance.updateSettings(this.spreadSheetSettings[hotId]);
        this.breakdownTotal();
    }
    spreadsheetSettings() {
        switch (this.breakdown) {
            case 2:
                this.defaultLineItemSchema = { id: null, item: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid : null, itemId: '', deleteAction: '' };
                break;
            case 3:
                this.defaultLineItemSchema = {id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid : null, itemId: '' , deleteAction: ''};
                break;
            case 4:
                this.defaultLineItemSchema = {id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid : null,
                    itemId: '', deleteAction: '' };
                break;
        }

        return {};
    }

    getSpreadSheetData(hotId) {
        if (typeof this.spreadsheetLineItems[hotId] === 'undefined'){
            this.spreadsheetLineItems[hotId] = [];
        }
        return this.spreadsheetLineItems[hotId];
    }

    customrenderer = (instance, td, row, col, prop, value, cellProperties) => {
        let cellCase = prop;
        let hotId = instance.hotId;
        // let responseParam = this.spreadsheetLineItems[hotId];
        let activeEditor = instance.getActiveEditor();
        let cisTotal: any = 0;
        let total: any = 0;
        let selectedTax: any = [];

        if (this.breakdown === 4) {
            const category =  _.findWhere(this.invoiceItemCategories, {spreadsheetHotID : hotId});
            if (category && category['chosen']) {
                this.spreadsheetLineItems[hotId][row]['categoryid'] = category['spreadsheet_categoryId'];
            }
        }

        switch (cellCase) {
            case 'item':
                let itemObj = _.findWhere(this.spreadSheetViewData['items'], {id: value});

                if (typeof itemObj !== 'undefined') {
                    this.spreadsheetLineItems[hotId][row]['itemId'] = itemObj.itemId;
                }
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'pricingItem':
                let pricingItem = _.findWhere(this.spreadSheetViewData['pricingItems'], {id: value });
                if (typeof pricingItem !== 'undefined') {
                    value = pricingItem.text;
                    if (activeEditor && activeEditor.row === row && activeEditor.col === 2) {
                        let taxItemId = (pricingItem.taxItemId) ? pricingItem.taxItemId.toString() : this.lineItemDefaults['taxItemId'];
                        selectedTax = _.findWhere(this.taxItems, {id: parseInt(taxItemId) } );

                        if (this.isHideVat || this.invoiceTaxState === false) {
                            this.spreadsheetLineItems[hotId][row].taxItemId  = null;
                            this.spreadsheetLineItems[hotId][row].vatrate    = 0;
                        } else {
                            if (selectedTax) {
                                this.spreadsheetLineItems[hotId][row].taxItemId  = selectedTax['id'];
                                this.spreadsheetLineItems[hotId][row].vatrate    = selectedTax['effective_tax'];
                            } else {
                                this.spreadsheetLineItems[hotId][row].taxItemId  = null;
                                this.spreadsheetLineItems[hotId][row].vatrate    = 0;
                            }

                        }
                        // checking if the value is created on locally
                        if(pricingItem.itemId && pricingItem.unitprice) {
                            this.spreadsheetLineItems[hotId][row].itemId = pricingItem.itemId;
                            this.spreadsheetLineItems[hotId][row].unitprice = pricingItem.unitprice;
                        }

                        if(pricingItem.itemId && pricingItem.nominalCodeId) {
                            this.spreadsheetLineItems[hotId][row].nominalcode = pricingItem.nominalCodeId;
                        }
                    }
                }
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;

            case 'cis':
                if (value === 'Yes') {
                    cisTotal = ((this.spreadsheetLineItems[hotId][row].total * this.cisRate) / 100);
                    cisTotal = this.roundup(cisTotal,2);
                    this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                } else {
                    this.spreadsheetLineItems[hotId][row].totalcis = 0;
                }
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'taxItemId':
                let totalvat, effective_tax, selectedId;
                value = (value) ? value.toString() : value;
                if (this.isHideVat || !value && value != null) {
                    effective_tax = 0;
                } else {
                    selectedTax = _.findWhere(this.taxItems, {id: parseInt(value) } );

                    if (typeof selectedTax === 'undefined') {
                        selectedTax = _.findWhere(this.taxItems, {id: this.lineItemDefaults['taxItemId'] } );
                    }

                    selectedId = selectedTax.id;
                    effective_tax = parseFloat(selectedTax.effective_tax);
                    value = selectedTax.text;
                }

                /** calculate exc total*/
                if (this.vatType === 'inc_vat') {
                    total = (this.spreadsheetLineItems[hotId][row].quantity * this.spreadsheetLineItems[hotId][row].unitprice * 100) / (100 + effective_tax);
                } else {
                    total = this.spreadsheetLineItems[hotId][row].quantity * this.spreadsheetLineItems[hotId][row].unitprice;
                }
                total = this.roundup(total,2);

                /** calculate total vat */
                if (this.vatType === 'inc_vat') {
                    totalvat = (this.spreadsheetLineItems[hotId][row].quantity * this.spreadsheetLineItems[hotId][row].unitprice) - total;
                } else {
                    totalvat = (total * effective_tax) / 100;
                }

                /** calculate total cis */
                if (this.spreadsheetLineItems[hotId][row].cis === 'Yes') {
                    cisTotal = ((total / 100 ) * this.cisRate);
                    cisTotal = this.roundup(cisTotal,2);
                    this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                }

                this.spreadsheetLineItems[hotId][row].total = this.roundup(total,2);
                this.spreadsheetLineItems[hotId][row].vatrate = effective_tax;
                this.spreadsheetLineItems[hotId][row].totalvat = this.roundup(totalvat,2);
                this.spreadsheetLineItems[hotId][row].taxItemId = selectedId;
                this.breakdownTotal();
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'nominalcode':
                let selectedNominalCode = _.findWhere(this.nominalCodeOptions, {id: parseInt(value)});
                value = (selectedNominalCode) ? selectedNominalCode.text : '';
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'quantity':
                let newQuantity = (value < 0 || isNaN(parseFloat(value))) ? 1 : parseFloat(value);
                value = this.roundup(newQuantity,2);
                this.spreadsheetLineItems[hotId][row].quantity = value;
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'unitprice':
                value = parseFloat(value);
                value = (value === 0) ?  '0.00' : this.roundup(value,2);
                this.spreadsheetLineItems[hotId][row].unitprice = value;

                if (this.isHideVat)
                {
                    if (this.vatType === 'inc_vat') {
                        total = (this.spreadsheetLineItems[hotId][row].quantity * value * 100) / (100 + parseFloat(this.spreadsheetLineItems[hotId][row].vatrate));
                    } else {
                        total = this.spreadsheetLineItems[hotId][row].quantity * value;
                    }
                    this.spreadsheetLineItems[hotId][row].total = this.roundup(total,2);
                    if (this.spreadsheetLineItems[hotId][row].cis === 'Yes') {
                        cisTotal = ((this.spreadsheetLineItems[hotId][row].total / 100 ) * this.cisRate);
                        cisTotal = this.roundup(cisTotal,2)
                        this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                    }
                }
                this.breakdownTotal();
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                break;
            case 'deleteAction':
                Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                const link: HTMLAnchorElement = document.createElement('a');
                link.innerText = 'Delete';
                link.style.cursor = 'pointer';
                Handsontable.dom.addEvent(link, 'click', () => {
                    if (instance.getDataAtCell(row, 1) !== '') {
                        this.deleteAction(instance, row, col);
                    }
                });
                Handsontable.dom.empty(td);
                const content: HTMLDivElement = document.createElement('div');
                content.appendChild(link);
                td.appendChild(content);
                return td;
                break;
        }
    }

    afterGetColHeader = (col, TH) => {
        let className = '';
        if (this.disableColHeader) {
            className = 'disable-col-header ';
        }
        if (this.requiredFields[col]) {
            className += 'col_header_bg';
        }

        if (className && TH) {
            TH.className = className;
        }
    }

    beforeValidate (value, row, prop, source, hotId) {
        if (value !== '') {
            let activeEditor = this.hotInstances[hotId].getActiveEditor(),
                col = activeEditor.col,
                removeHighlightRow = [],
                cellType = activeEditor.cellProperties.type;
            if (this.validateErrorRow === true) {
                removeHighlightRow[0] = {'row': row, 'hotId': hotId};
                this.highlightErrorRows(removeHighlightRow, [hotId], false);
            }
            if (prop === 'unitprice') {
                this.numericLimitValidator(value, row, col, prop, 10, cellType, hotId);
            } else if (prop === 'quantity') {
                this.numericLimitValidator(value, row, col, prop, '', cellType, hotId);
            }
        }
    }
    highlightErrorRows(errorRow, hotId, isHighlight) {
        this.validateErrorRow = true;
        for (let i = 0; i < errorRow.length; i++) {
            let currentRow = errorRow[i]['row'];
            let currentRowHotId = errorRow[i]['hotI'];
            let currentInstance = this.hotInstances[currentRowHotId];
            let currentMetaRow = currentInstance.getCellMetaAtRow(currentRow);
            for (let j = 1; j < currentMetaRow.length; j++) {
                if (isHighlight === true) {
                    currentInstance.setCellMeta(currentRow, j, 'className', 'errorRowList');
                } else {
                    currentInstance.setCellMeta(currentRow, j, 'className', '');
                }
            }
        }
        hotId.forEach((value) => {
            this.hotInstances[value].render();
        });
    }
    numericLimitValidator (value, row, col, prop, limit, cellType, hotId) {
        if (value !== '') {
            let isInvalid = false;
            let data = parseFloat(value);
            if (isNaN(value)) {
                this.toastBox.show('Invalid data', 1000);
            } else if (limit !== '' && (data.toFixed(0).length) > limit) {
                isInvalid = true;
                this.toastBox.show('Invalid data length', 1000);
            }

            if (isInvalid) {
                this.hotInstances[hotId].setCellMeta(row, col, 'className', 'errorRowList');
            } else {
                this.hotInstances[hotId].setCellMeta(row, col, 'className', '');
            }
            this.hotInstances[hotId].render();
        }
    }

    emptyCellValidator = (value, callback) => {
        if (value === '') {
            this.toastBox.show('Empty cell not allowed', 1000);
            callback(false);
        }else{
            callback(true);
        }
    }

    afterChange (changes, src, hotId) {
        const current_instance = this.hotInstances[hotId];
        const getSelect = current_instance.getSelected();
        const selectedRow = (typeof  getSelect !== 'undefined') ? getSelect[0] : 0;

        const countRows = current_instance.countRows();
        // if (src === 'loadData' && this.spreadsheetLineItems[hotId].length === 0) {
        if (src === 'loadData') {
            current_instance.alter('insert_row', countRows);
            setTimeout(() =>  {
                current_instance.updateSettings({className: 'overflow_handsontable'});
                current_instance.render();
            }, 1000);
            current_instance.render();

        } else if (src === 'edit') {
            this.insertRow(current_instance, changes, src);
        }
        // // $scope.spreadSheetValidation();
        current_instance.scrollViewportTo(selectedRow - 1, 1);
    }

    insertRow (currentInstance, changes, src) {
        const selected = currentInstance.getActiveEditor(),
            selectedCol = selected.col,
            selectedRow = selected.row,
            rowVal = currentInstance.getSourceDataAtCell(selectedRow, '1');

        const isRowValidate = !!(selectedCol === 2 && rowVal);

        if (changes != null && changes[0][0] === (currentInstance.countRows() - 1) && src === 'edit' && isRowValidate) {
            currentInstance.alter('insert_row', currentInstance.countRows());
            currentInstance.render();
        }
    }

    afterSelection = (r, c, r2, c2, preventScrolling, hotId) => {
        const instanceSettings = this.hotInstances[hotId].getSettings();
        if (c === 1) {
            instanceSettings.fillHandle = false;
        } else {
            instanceSettings.fillHandle = {direction: 'vertical', autoInsertRow: true};
        }
    };

    deleteAction = (instance, r, c) => {
        let spreadsheetService=this;
        if (c === (instance.getInstance().countCols() - 1)) {
            this.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', this.ajsScope)
                .then(function () {

                    let rowId = instance.getSourceDataAtRow(r);
                    if (spreadsheetService.breakdown === 2){
                        spreadsheetService.lineItemId.next(rowId.breakdownId);
                    }else{
                        spreadsheetService.lineItemId.next(rowId.line_item_id);
                    }
                    if ((typeof rowId !== 'undefined') && rowId.id) {
                        spreadsheetService.rowToDelete.push(rowId.id);
                    }

                    instance.alter('remove_row', r);
                    instance.render();
                    spreadsheetService.breakdownTotal();

                    let rowsCount = instance.countRows();
                    if (rowsCount === 0 || rowsCount === r) {
                        instance.alter('insert_row', rowsCount);
                        instance.render();
                    }
                }, function () {
                    return false;
                });
        }
    }

    /* Breakdown functions */

    breakdownByCategory() {
        return {
            hiddenColumns: {
                columns: [0, 3, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true
                }, {
                    data: 'item',
                    validator: 'emptyCellValidator',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    className: 'overflow_handsontable select_icon',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: true,
                        ajax: {
                            url: 'getInvoiceCategory',
                            data: (query, page) => {
                                if (query === '') {
                                    return false;
                                }
                                return { 'searchText': encodeURIComponent(query) };
                            },
                            // On search ajax response
                            results: (data, page) => {
                                this.spreadSheetViewData['items'] = data.invoiceCategories;
                                return { results: data.invoiceCategories };
                            },
                        },
                        formatSelection: function (item) {
                            return item.text;
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', readOnly: true, validator: 'emptyCellValidator'

                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, validator: 'emptyCellValidator'
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    validator: 'emptyCellValidator',
                    className: 'overflow_handsontable select_icon',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'total', type: 'numeric', readOnly: true, disableVisualSelection: true,  numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer'
                }
            ],
        };
    }

    fullBreakdown() {
        return {
            hiddenColumns: {
                columns: [0, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true, type: 'numeric'
                }, {
                    data: 'pricingItem',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        ajax: {
                            url: 'getPricingItems',
                            data: (query, page) => {
                                if (query === '') {
                                    return false;
                                }
                                let data = {};
                                data['searchText'] = encodeURIComponent(query);
                                if(this.jobId){
                                    data['jobId'] = this.jobId;
                                }
                                return data ;
                            },
                            // On search ajax response
                            results: (data, page) => {
                                this.spreadSheetViewData['pricingItems'] = data.pricingItems;
                                return { results: data.pricingItems };
                            },
                        },
                        formatSelection: function (item) {
                            return item.text;
                        },
                        createSearchChoice: function (term, data) {
                            if ( $(data).filter( function() {
                                return this.text.localeCompare(term) === 0;
                            }).length === 0) {
                                return {id: term, text: term};
                            }
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0' }
                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' },
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    allowEmpty: false,
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })

                }, {
                    data: 'total', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer'
                }
            ],
        };
    }

    fullBreakdownByCategory() {

        return {
            hiddenColumns: {
                columns: [0, 2, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true, type: 'numeric'
                }, {
                    data: 'pricingItem',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    className: 'overflow_handsontable select_icon',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        ajax: {
                            url: 'getPricingItems',
                            data: (query, page) => {
                                if (query === '') {
                                    return false;
                                }
                                // return { 'searchText': encodeURIComponent(query) };
                                let data = {};
                                data['searchText'] = encodeURIComponent(query);
                                if(this.jobId){
                                    data['jobId'] = this.jobId;
                                }
                                return data ;
                            },
                            // On search ajax response
                            results: (data, page) => {
                                this.spreadSheetViewData['pricingItems'] = data.pricingItems;
                                return { results: data.pricingItems };
                            },
                        },
                        createSearchChoice: function (term, data) {
                            if ( $(data).filter( function() {
                                return this.text.localeCompare(term) === 0;
                            }).length === 0) {
                                return {id: term, text: term};
                            }
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', forceNumeric: true, validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0' }
                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    allowEmpty: false,
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'total', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer',
                }
            ],
        };
    }

    breakdownTotal() {
        const hotIds = Object.keys(this.hotInstances);
        this.totals['breakdown'] = [];
        this.totals['grandTotal'] = {};

        let total_price = 0, total_tax = 0, total_cis = 0, i, total_vat_rate = 0, breakdown_by_tax_rates = {}, vat_cost;

        hotIds.forEach((hotId)  => {
            let value = this.spreadsheetLineItems[hotId];
            let sub_total_price = 0, totalCount = 0;

            for (i = 0; i < value.length; i++) {

                totalCount = ((typeof value[i] !== 'undefined' && value[i].item) || value[i].pricingItem) ? totalCount + 1 : totalCount;

                total_price += parseFloat(value[i].total);
                sub_total_price += parseFloat(value[i].total);
                total_tax += parseFloat(value[i].totalvat);
                total_cis += parseFloat(value[i].totalcis);

                if (value[i].taxItemId !== 0 && (value[i].item || value[i].pricingItem)) {
                    vat_cost = (typeof breakdown_by_tax_rates[value[i].vatrate] === 'undefined') ? 0 : breakdown_by_tax_rates[value[i].vatrate];
                    breakdown_by_tax_rates[value[i].vatrate]  = vat_cost +  parseFloat(value[i].totalvat) ;
                }
                total_vat_rate += (value[i].totalvat > 0) ? parseFloat(value[i].vatrate) : 0;
            }
            this.totals['breakdown'][hotId] = {'total' : this.roundup(sub_total_price,2) , 'lineItemCount' : totalCount};
        });

        this.totals['grandTotal']['total_price'] = this.roundup(total_price,2);
        this.totals['grandTotal']['total_tax'] = total_tax ? this.roundup(total_tax,2) : 0;
        this.totals['grandTotal']['total_cis'] = (total_cis) ? this.roundup(total_cis,2) : 0;
        this.totals['grandTotal']['breakdown_by_tax_rates'] = breakdown_by_tax_rates;

        this.totalEmitterSource.next(this.totals);
    }

    formatSpreadsheetData() {
        // let hotIds = [];
        // if (this.breakdown === 4) {
        //     hotIds = _.pluck(this.spreadsheetLineItems, 'spreadsheetHotID');
        // } else {
        //      hotIds.push(this.invoiceBreakdownTypeName[this.breakdown]);
        // }
       const hotIds = Object.keys(this.spreadsheetLineItems);
        const lineItems = [];
        let lineItem = {};
        let position = 1;

        hotIds.forEach((hotId) => {
            let spreadsheetLineItems = this.spreadsheetLineItems[hotId];
            let spreadSheetLineItem = {};

            if (this.breakdown === 2) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function(v) { return v.item !== ''; });
            } else if (this.breakdown === 3) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function(v) { return v.pricingItem !== ''; });
            } else if (this.breakdown === 4) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function(v) { return v.pricingItem !== '' && v.categoryid !== null; });
            }

            for (let i = 0; i < spreadsheetLineItems.length; i++) {
                spreadSheetLineItem = spreadsheetLineItems[i];
                lineItem = { id: spreadSheetLineItem['id'],
                    cis: spreadSheetLineItem['cis'],
                    quantity: spreadSheetLineItem['quantity'],
                    unitprice: spreadSheetLineItem['unitprice'],
                    taxItemId: spreadSheetLineItem['taxItemId'],
                    vatrate: spreadSheetLineItem['vatrate'],
                    nominalcode: spreadSheetLineItem['nominalcode'],
                    categoryid : spreadSheetLineItem['categoryid'],
                    descriptionId: spreadSheetLineItem['itemId'],
                    position: position++ };
                if (this.breakdown === 2) {
                    lineItem['description'] = spreadSheetLineItem['item'];
                } else {
                    lineItem['description'] = spreadSheetLineItem['pricingItem'];
                }
                if(spreadSheetLineItem['nominalcode'] ==''){
                    return [];
                }else{
                    lineItems.push(lineItem);
                }

            }
        });

        return lineItems;
    }
    addLineItemsFromSidepanel(data){
        let hotId = this.invoiceBreakdownTypeName[this.breakdown];

        data.forEach((value, key) => {
            const lineItem = Object.assign({}, this.defaultLineItemSchema);
            const description = value['description'];
            const unitprice = parseFloat(value['price']) * parseFloat(value['quantity']);
            const nominalCodeId = value['nominalCodeId'];
            if (this.breakdown === 2) {
                const categoryLineItemIndex = _.findIndex(this.spreadsheetLineItems[hotId], {'itemId' : parseInt(value['categoryId']) });
                if (categoryLineItemIndex > -1) {
                    const existingUnitprice = this.spreadsheetLineItems[hotId][categoryLineItemIndex]['unitprice'];
                    this.spreadsheetLineItems[hotId][categoryLineItemIndex]['unitprice'] = parseFloat(existingUnitprice) + unitprice;
                } else {
                    const dataSchema= Object.assign({}, this.defaultLineItemSchema);
                    this.spreadsheetLineItems[hotId].push(dataSchema);
                    const emptyColumnIndex = _.findIndex(this.spreadsheetLineItems[hotId], {'item' : ''});
                    const categoryIndex = _.findIndex(this.invoiceItemCategories, {'spreadsheet_categoryId' : parseInt(value['categoryId']) });

                    if (categoryIndex > -1) {
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['item'] = this.invoiceItemCategories[categoryIndex]['spreadsheet_category'];
                    }
                    if(nominalCodeId){
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                    }
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['itemId'] = parseInt(value['categoryId']);
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = unitprice;
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['type'];
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['breakdownId'] = value['categoryId'];
                    if(value['taxRateID'] == null){
                        if(value['taxItemId']){
                            this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                        }
                    }else if(value['taxRateID'] != null){
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                    }
                }
            } else if (this.breakdown === 3) {
                const emptyColumnIndex = _.findIndex(this.spreadsheetLineItems[hotId], {'pricingItem' : ''});

                this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = value['price'];
                this.spreadsheetLineItems[hotId][emptyColumnIndex]['quantity'] = value['quantity'];
                this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['description'];
                this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];
                if(value['taxRateID'] == null){
                    if(value['taxItemId']){
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                    }
                }else if(value['taxRateID'] != null){
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                }
                if(nominalCodeId){
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                }
                this.spreadsheetLineItems[hotId].push(lineItem);
            } else if (this.breakdown === 4) {
                lineItem['invoiceItemCategoryId'] = value['invoiceItemCategoryId'];
                const hot =  _.findWhere(this.invoiceItemCategories, {spreadsheet_categoryId : parseInt(value['categoryId']) });
                if (hot && hot['chosen']) {
                    hotId = hot['spreadsheetHotID'];
                    const emptyColumnIndex = _.findIndex(this.spreadsheetLineItems[hotId], {'pricingItem' : ''});

                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = value['price'];
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['quantity'] = value['quantity'];
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['description'];
                    this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];

                    if(value['taxRateID'] == null){
                        if(value['taxItemId']){
                            this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                        }
                    }else if(value['taxRateID'] != null){
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                    }
                    if(nominalCodeId){
                        this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                    }
                    this.spreadsheetLineItems[hotId].push(lineItem);
                    this.hotInstances[hotId].render();
                }
            }

        });
        this.hotInstances[hotId].render();
    }
    getBreakDownSettings() {
        switch (this.breakdown) {
            case 2:
                return this.breakdownByCategory();
           case 3:
                return this.fullBreakdown();
            case 4:
                return this.fullBreakdownByCategory();
        }
    }
    getInvoiceTaxState(){
        return this.invoiceTaxState;
    }

    roundup(num, precision) {
        var base = 10 ** precision;
        return (Math.round(num * base) / base).toFixed(precision);
    }
    changingTaxList(taxitemNew){
        this.taxItems=[];
        this.taxItems=taxitemNew;
    }

}
