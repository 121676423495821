import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule} from "@app/shared";
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { AiDocumentParsingRoutingModule } from './ai-document-parsing-routing.module';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { FormsModule } from '@angular/forms';
import { FileUploadModule} from "ng2-file-upload";
import { ListParsedDataComponent } from './list-parsed-data/list-parsed-data.component';
import { RoundProgressModule} from "@app/shared/round-progress/round-progress.module";
import { DocumentParsedDetailsComponent } from './document-parsed-details/document-parsed-details.component';
import { SmartTableModule } from '@app/smart_table/smarttable.module';
import { AgGridModule } from "ag-grid-angular";
import 'ag-grid-enterprise';
import { CapitalizePipe } from '@app/pipes';
import { CsAgGroupCellRendererComponent } from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid-components/cs-ag-group-cell-renderer/cs-ag-group-cell-renderer.component";
import { CsComponentsModule } from '@app/shared/cs-components/cs-components.module';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { CustomTooltipRendererComponent } from './custom-tooltip-renderer/custom-tooltip-renderer.component';
import { DatePipe } from '@angular/common';


@NgModule({
    declarations: [
        UploadDocumentComponent,
        ListParsedDataComponent,
        DocumentParsedDetailsComponent,
        CustomTooltipComponent,
        CustomTooltipRendererComponent,
    ],
    imports: [
        CommonModule,
        AppCommonModule,
        AiDocumentParsingRoutingModule,
        FileUploadModule,
        FormsModule,
        RoundProgressModule,
        SmartTableModule,
        AgGridModule.withComponents([CsAgGroupCellRendererComponent,CustomTooltipComponent, CustomTooltipRendererComponent]),
        CsComponentsModule,
        ModalModule.forRoot()
    ],
    providers: [
        BsModalRef,
        CapitalizePipe,
        DatePipe
    ]
})
export class AiDocumentParsingModule { }
