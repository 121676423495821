<ng-container>
    <div class="modal-header" [style.box-shadow]="'none'">
        <div>
            <h4 class="modal-title" translate>{{title}} <small>{{categoryType}}</small></h4>
            <div *ngIf="!!helpText" class="cs-help-text-color"><span translate>{{helpText}}</span></div>
        </div>

        <span (click)="closeModal()" class="ml-auto cursor-pointer">
            <i class="far fa-times" aria-hidden="true"></i>
        </span>
    </div>
    <div class="modal-body">
        <app-communication-layout
            [communicationScreenType]="communicationScreenType"
            [allowSegmentation]="allowSegmentation"
            [objectName]="objectName"
            [objectId]="objectId"
            [objectTypeId]="objectTypeId"
            [categoryType]="categoryType"
            [isCustomerSpecific]="isCustomerSpecific"
            [selectedTemplateData]="selectedTemplateData"
            [viewMode]="viewMode"
            ($instance)="setLayoutComponent($event)"
            class="h-100 d-block"></app-communication-layout>
    </div>

    <!--<div class="modal-footer">
        <div class="cs-d-flex h-100 align-items-center">
            <div class="p-l&#45;&#45;15 p-r&#45;&#45;15 ml-auto">
                <button (click)="closeModal()" class="btn btn-plain link-color">Cancel</button>
                <button class="btn btn-primary" (click)="saveCommunication()">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </div>-->
</ng-container>
