import * as tslib_1 from "tslib";
import { ElementRef, Injector, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
var CsPaginationMigratedDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CsPaginationMigratedDirective, _super);
    function CsPaginationMigratedDirective(_hostRef, injector) {
        var _this = _super.call(this, 'cspagination', _hostRef, injector) || this;
        _this.fetchData = new EventEmitter();
        _this.showNewPagination = false;
        return _this;
    }
    return CsPaginationMigratedDirective;
}(UpgradeComponent));
export { CsPaginationMigratedDirective };
