
  <div id="toast-box"
    [ngClass]="{'toast-showing' : show }"
    ng-mouseenter="keepToastShowing()"
    ng-mouseleave="allowToastToHide()">
    <span class="confirmation">
      <strong class="text" translate>
        {{toastMessage}}
      </strong>
      <span class="ss-delete close" (click)="close()"></span>
    </span>
  </div>