import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//--
import { InterfaceService } from '@app/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SettingsInvoiceCategoriesService extends InterfaceService {

  constructor(
    private http: HttpClient
  ) { super(); }

  // Get list of invoice categories configured
  getInvoiceCategories(params?: Object): Observable<any> {
    return this.http
      .get(this.getApiUrl(`listInvoiceCategories`, params));
  }
}
