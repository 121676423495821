import {Injectable} from '@angular/core';
import {
    ICsAgGridAutoHeightParams,
    ICsAgGridParams,
    ICsAgNodeState
} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.interface";

@Injectable()
export class CsAgGridService {

    constructor() {
    }

    agNodeState: ICsAgNodeState;

    setGridAutoHeight(params: ICsAgGridAutoHeightParams, detailPanelsHeight = 0) {
        if (params.gridHolder && params.gridWrapper && params.grid) {
            const gridHolder = params.gridHolder.nativeElement;
            const gridWrapper = params.gridWrapper.nativeElement;
            const grid = params.grid.nativeElement;
            const gridHolderStyle = getComputedStyle(gridHolder);
            const gridHolderHeight = gridHolder.clientHeight - (parseFloat(gridHolderStyle.paddingTop) + parseFloat(gridHolderStyle.paddingBottom));
            const gridTopSpace = grid.getBoundingClientRect().top - gridWrapper.getBoundingClientRect().top;
            const gridHeaderHeight = grid.querySelector('.ag-header').getBoundingClientRect().height;
            const gridBottomSpace = gridWrapper.getBoundingClientRect().bottom - grid.getBoundingClientRect().bottom;

            if (detailPanelsHeight === 0) {
                params.detailPanelsHeight = 0;
            } else {
                params.detailPanelsHeight += detailPanelsHeight;
            }
            const availableContainerHeight = gridHolderHeight - (gridTopSpace + gridHeaderHeight + gridBottomSpace);
            // Todo: Need to work this line as dynamic, instead of `params.pageSize * 40`
            const requiredContainerHeight = (params.pageSize * 40) + params.detailPanelsHeight;

            const currentLayout = availableContainerHeight < requiredContainerHeight ? 'normal' : 'autoHeight';

            if (params.gridLayout !== currentLayout) {
                params.gridApi.setDomLayout(currentLayout);
                params.gridApi.resetRowHeights();
                params.gridLayout = currentLayout;
            }
        }
    }

    // `setNodeState` is using, for update the custom state of each, respective row & cell state
    setNodeState(rowId: string | number, colId, stateParams: object = {}) {
        if (this.agNodeState) {
            if (this.agNodeState[rowId]) {
                Object.entries(this.agNodeState[rowId]).forEach(([col, state]) => {
                    if (col !== colId) {
                        state['isExpanded'] = false;
                    }
                });

                if (this.agNodeState[rowId][colId]) {
                    this.agNodeState[rowId][colId]['isExpanded'] = stateParams['isExpanded'];
                } else {
                    this.agNodeState[rowId][colId] = {
                        isExpanded: stateParams['isExpanded']
                    };
                }
            } else {
                this.agNodeState[rowId] = {
                    [colId]: {
                        isExpanded: stateParams['isExpanded']
                    }
                };
            }
        } else {
            this.agNodeState = {
                [rowId]: {
                    [colId]: {
                        isExpanded: stateParams['isExpanded']
                    }
                }
            };
        }
    }

    getNodeState(rowId: string | number) {
        const rowNode = this.agNodeState && this.agNodeState[rowId];
        return (!!rowNode && rowNode);
    }

    resetNodeState() {
        this.agNodeState = null;
    }

    updateGridPagingData(params: ICsAgGridParams) {
        this.resetNodeState();
        this.updateGridPagination(params);
        this.renderGridSSData(params);
    }

    updateGridPagination(params: ICsAgGridParams) {
        params.gridApi.paginationSetPageSize(params.pageSize);
        params.gridApi.paginationGoToPage(params.currentPage - 1);
    }

    renderGridSSData(params: ICsAgGridParams) {
        params.gridApi.setServerSideDatasource(params.masterGridDataSource);
    }
}
