<ng-container>
    <ng-container *ngIf="type === 'email'">
    <label class="control-label" *ngIf="selector=='supplier-email-template'" translate>Email.title</label>
    <div class="control-group">
        <div class="tiny-mce-single-line-input-wrapper">
            <input id="{{ selector }}-subject" class="email-template-subject {{ customClass['subject'] }}" type="text" [(ngModel)]="data['subject']">
        </div>
    </div>
    </ng-container>
    <label class="control-label" *ngIf="selector=='supplier-email-template'" translate>Email.body</label>
    <div class="control-group">
        <textarea id="{{selector}}" class="email-template-message {{ customClass['message'] }}" [(ngModel)]="data['message']"></textarea>
    </div>
</ng-container>

