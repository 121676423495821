import { AfterViewInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import Handsontable from "handsontable";
import {ICsPopOver} from "@app/shared/cs-components/cs-components.interface";

declare var jQuery: any;
@Injectable()

export class CommonService extends InterfaceService{

    constructor(
        private http: HttpClient,) {
        super();
    }

    getUsersList(): Observable<any>{
        return this.http.get(this.getJobApiUrl(`getUsersList`));
    }

    setHtCellLongTextPopover(
        instance: Handsontable,
        cellEl: HTMLTableCellElement,
        row: number,
        col: number,
        htPopOverConfig?: Pick<ICsPopOver, Exclude<keyof ICsPopOver, 'content' | 'el'>>
    ) {
        if (
            instance.getDataAtCell(row, col)
            && (cellEl.offsetWidth < cellEl.scrollWidth)
        ) {
            const popOverData = {
                el: cellEl,
                content: () => cellEl.innerHTML
            };

            const popOverConfig = {...popOverData, ...htPopOverConfig};
            this.setCsPopOver(popOverConfig);
        } else {
            jQuery(cellEl).popover('destroy');
        }
    }

    setCsPopOver(config: ICsPopOver) {
        jQuery(config.el).popover({
            placement: config.placement || 'bottom',
            trigger: config.trigger || 'hover',
            content: config.content
        })
            .data('popover')
            .tip()
            .addClass(config.customClass || 'cs-popover')
            .popover('show');
    }
}
