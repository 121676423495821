/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./cs-modal.component";
var styles_CsModalComponent = [];
var RenderType_CsModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsModalComponent, data: {} });
export { RenderType_CsModalComponent as RenderType_CsModalComponent };
export function View_CsModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-content"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_CsModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cs-modal", [["class", "modal fade"]], null, null, null, View_CsModalComponent_0, RenderType_CsModalComponent)), i0.ɵdid(1, 245760, null, 0, i1.CsModalComponent, [i0.ElementRef, i0.Renderer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsModalComponentNgFactory = i0.ɵccf("cs-modal", i1.CsModalComponent, View_CsModalComponent_Host_0, { isDisabledBackdrop: "isDisabledBackdrop", showModal: "show" }, { onOpen: "onOpen", onClose: "onClose" }, ["*"]);
export { CsModalComponentNgFactory as CsModalComponentNgFactory };
