<cs-sidepanel id="signatureForm"
              [show]="showSignatureSidePanel"
              [name]="'signatureForm'"
              (close)="onSidepanelClose()"
              class="ltc-sidepanel dynamic-cert-sidepanel add-edit-cert-side-panel" [ngClass]="{'custom-fields': screenFor!=0}">

  <!-- Panel title -->
  <div id="page-panel-title-bar">
    <h4 translate>Signature</h4>
  </div>
  <div id="page-panel-main" style="padding: 17px 10px 0 10px !important;" class="flex flex-column">
    <div class="row-fluid small-padding">
      <span class="required_field" translate>Your.name</span>
      <div class="required-block">
        <input style="width: 97%;" type="text" [(ngModel)]="signatureText"/>
      </div>
    </div>
    <div class="signature-cont">
      <app-cs-signature-generator *ngIf="signatureText"
                                  [signatureText]="signatureText"
                                  imageHeight="130"
                                  imageWidth="350"
                                  (signatureAsBase64)="getSignature($event)">
      </app-cs-signature-generator>
    </div>
  </div>
  <div class="page-panel-actions buttons-fixed-bottom buttons-right">
    <div class="buttons">
      <button [disabled]="!base64Image"
              (click)="insertSignature()"
              class="btn btn-primary primary-button">
        <span translate>Insert</span>
      </button>
      <a (click)="onSidepanelClose()" translate id="cancel-panel-btn" href="javascript: void(0)">
          Cancel
      </a>
      </div>
    </div>
</cs-sidepanel>
