import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({ providedIn : 'root'})
export class OpportunityService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    /**
     *
     * @param {object} params
     * @returns {Observable<any>}
     */
    getPipelines(params?: object): Observable<any> {
        return this.http.get(this.getApiUrl(`listPipelines`, params));
    }

    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    getPipelineStages(pipelineId): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getPipelineStages/${pipelineId}`));
    }

    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    getPipelineOpportunities(pipelineId): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getPipelineOpportunities/${pipelineId}`));
    }

    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    getAcceptedOpportunities(pipelineId): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getAcceptedOpportunities/${pipelineId}`));
    }

    /**
     *
     * @param data
     * @param screen
     * @returns {Observable<any>}
     */
    updateConfirmationTemplateData(data, screen): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/updateConfirmationTemplate?screen=${screen}`), data);
    }

    /**
     *
     * @param screen
     * @returns {Observable<any>}
     */
    getConfirmationTemplate(screen): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getConfirmationTemplate?screen=${screen}`));
    }

    /**
     * Get supplier parts quotation message
     * @returns {Observable<any>}
     */
    getSupplierPartsQuotationMessage() : Observable<any> {
        return this.http.get(this.getApiUrl(`get_opportunity_supplier_parts_quotation_message`));
    }

    /**
     *
     * @param payload
     * @returns {Observable<Object>}
     */
    emailPartListToSuppliers(payload: any){
        return this.http.post(this.getApiUrl(`emailpartlisttosuppliers`), payload)
    }

    getOpportunitiesFilter(): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getOpportunitiesFilter`));
    }

    getPipelineStageOpportunities(payload, pipelineId): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/getPipelineStageOpportunities/${pipelineId}`), payload);
    }

    getAcceptedOpportunitiesByStage(payload, pipelineId): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/getAcceptedOpportunitiesByStage/${pipelineId}`), payload);
    }

    getOpportunitiesViewsList(): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getOpportunitiesViewsList`));
    }

    updateSupplierTemplateData(data): Observable<any> {
        return this.http.post(this.getApiUrl('updateSupplierTemplateData'), data);
    }
 }
