import { Injectable } from '@angular/core';

@Injectable()
export class TriggerDatasetService {

    fields = {
    };

    operators = {
        'equal':'equal',
        'not_equal':'not equal',
        'in':'in',
        'not_in':'not in',
        'begins_with':'begins with',
        'not_begins_with': 'doesn\'t begin with',
        'contains': 'contains',
        'not_contains': 'doesn\'t contain',
        'ends_with': 'ends with',
        'not_ends_with': 'doesn\'t end with',
        'is_empty': 'is empty',
        'is_not_empty': 'is not empty',
        'is_null': 'is null',
        'is_not_null':'is not null',
        'less': 'less than',
        'less_or_equal': 'less than or equal',
        'greater': 'greater than',
        'greater_or_equal': 'greater than or equal',
        'between': 'between',
        'not_between': 'not between'
    };

    operatorsDisplayText = {
        'equal':'is',
        'not_equal':'is not',
        'in':'is one of',
        'not_in':'is not one of',
        'less': 'less than',
        'less_or_equal': 'less than or equal',
        'greater': 'is greater than',
        'greater_or_equal': 'greater than or equal',
        'contains': 'contains',
        'begins_with': 'begins with'
    };

    opertatorSymbols = {
        'equal':'=',
        'not_equal':'!=',
        'in':'IN',
        'not_in':'NOT IN',
        'begins_with':'begins with',
        'not_begins_with': 'doesn\'t begin with',
        'contains': 'contains',
        'not_contains': 'doesn\'t contain',
        'ends_with': 'ends with',
        'not_ends_with': 'doesn\'t end with',
        'is_empty': 'IS EMPTY',
        'is_not_empty': 'IS NOT EMPTY',
        'is_null': 'IS NULL',
        'is_not_null':'IS NOT NULL',
        'less': '<',
        'less_or_equal': '<=',
        'greater': '>',
        'greater_or_equal': '>=',
        'between': 'BETWEEN',
        'not_between': 'NOT BETWEEN'
    };

    getFields() {
        return this.fields;
    }

    getOperators() {
        return this.operators;
    }

    getOperatorsDisplayText() {
        return this.operatorsDisplayText;
    }

    setMainObjects(datas:any) {
        this.fields = datas;
    }
}
