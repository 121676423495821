/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cs-toggleuserpreference.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./cs-toggleuserpreference.component";
import * as i6 from "../../../services/http-services/common.service";
import * as i7 from "../../../features/company-settings/integrations/services/integrations.service";
var styles_CsToggleuserpreferenceComponent = [i0.styles];
var RenderType_CsToggleuserpreferenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsToggleuserpreferenceComponent, data: {} });
export { RenderType_CsToggleuserpreferenceComponent as RenderType_CsToggleuserpreferenceComponent };
function View_CsToggleuserpreferenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["id", "feedbackForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "required_field request_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're sorry to see you switch back. We'd love to know why so we can continue to improve the software."])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "textarea", [["id", "feedbackArea"], ["type", "textarea"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.feedback = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.feedbackEmitter$.next($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-primary saveFeedbackButton"], ["translate", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitFeedbackForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Save and turn it back!"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.feedback; _ck(_v, 6, 0, currVal_7); var currVal_9 = ""; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = !_co.isFeedbackValid; _ck(_v, 9, 0, currVal_8); }); }
export function View_CsToggleuserpreferenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "span", [["class", "field user-preference-toggle"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "height": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "toggle-btn-sec"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUserPropertyViewPreference() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "btn-switch"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "btn-switch--on": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "btn-switch-circle"]], null, null, null, null, null)), i1.ɵdid(10, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "btn-switch-circle--on": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsToggleuserpreferenceComponent_1)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.showFeedbackForm ? "300px" : "initial")); _ck(_v, 1, 0, currVal_0); var currVal_2 = "btn-switch"; var currVal_3 = _ck(_v, 8, 0, _co.toggleSwitch); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = "btn-switch-circle"; var currVal_5 = _ck(_v, 11, 0, _co.toggleSwitch); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = _co.showFeedbackForm; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.labelName; _ck(_v, 4, 0, currVal_1); }); }
export function View_CsToggleuserpreferenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-cs-toggleuserpreference", ""]], null, null, null, View_CsToggleuserpreferenceComponent_0, RenderType_CsToggleuserpreferenceComponent)), i1.ɵdid(1, 114688, null, 0, i5.CsToggleuserpreferenceComponent, [i6.CommonService, i7.IntegrationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsToggleuserpreferenceComponentNgFactory = i1.ɵccf("[app-cs-toggleuserpreference]", i5.CsToggleuserpreferenceComponent, View_CsToggleuserpreferenceComponent_Host_0, { screenName: "screenName", labelName: "labelName", oldUrl: "oldUrl", newUrl: "newUrl", preferenceValue: "preferenceValue", integrationsUI: "integrationsUI" }, { selected_preference: "selected_preference" }, []);
export { CsToggleuserpreferenceComponentNgFactory as CsToggleuserpreferenceComponentNgFactory };
