import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
var ImageCropComponent = /** @class */ (function () {
    function ImageCropComponent(bsModalRef) {
        this.bsModalRef = bsModalRef;
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.ratioPoint = 1 / 1;
        this.aspectRatio = [{ 'ratio': '1:1', 'aspect': 1 / 1 },
            { 'ratio': '1:3', 'aspect': 1 / 3 },
            { 'ratio': '2:3', 'aspect': 2 / 3 },
            { 'ratio': '3:2', 'aspect': 3 / 2 },
            { 'ratio': '3:4', 'aspect': 3 / 4 },
            { 'ratio': '4:3', 'aspect': 4 / 3 }];
        this.imgtransform = {};
        this.slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };
    }
    ImageCropComponent.prototype.ngOnInit = function () {
        $('.widget-header').css({ 'width': '100%' });
        var image = $('#cs_editor').contents().find('div.cs_selected').find('img');
        if (image) {
            this.imageUrl = image.attr('src');
        }
        else {
            this.imageUrl = 'angularES/src/assets/images/arrow-left-down1.png';
        }
        var that = this;
        $("#slider").slider({
            range: "max",
            min: -180,
            max: 180,
            value: that.imgtransform.rotate,
            slide: function (event, ui) {
                that.imgtransform = tslib_1.__assign({}, that.imgtransform, { rotate: ui.value });
            }
        });
        this.onClose = new Subject();
    };
    ImageCropComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    ImageCropComponent.prototype.getvalue = function (value) {
        return (value * 100).toString() + '%';
    };
    ImageCropComponent.prototype.selectRatio = function (ratio) {
        this.ratioPoint = ratio.aspect;
    };
    ImageCropComponent.prototype.saveImageCrop = function () {
        this.onClose.next(this.croppedImage);
        this.bsModalRef.hide();
    };
    ImageCropComponent.prototype.resetImage = function () {
        this.imgtransform = {};
    };
    return ImageCropComponent;
}());
export { ImageCropComponent };
