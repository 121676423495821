import { ActivatedRouteSnapshot } from '@angular/router';

export function getUrlParams(routeSnapshot: ActivatedRouteSnapshot): Object {
    let rt = routeSnapshot, params: Object = Object.assign({}, rt.params);

    // move up
    while (rt.parent) {
        rt = rt.parent;
        params = Object.assign(params, rt.params);
    }

    // move down
    rt = routeSnapshot;
    while (rt.firstChild) {
        rt = rt.firstChild;
        params = Object.assign(params, rt.params);
    }
    
    return params;
}