import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, UrlHandlingStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConfigKBUrlService } from "@app/core/config/ConfigKBUrlService";
// --
import { HybridUrlHandlingStrategy } from '@app/core/hybrid-url-handling-strategy';
import { throwIfAlreadyLoaded } from '@app/core/module-import-guard';
import { SettingsService } from '@app/core/settings/settings.service';
import { AuthService } from '@app/core/auth/auth.service';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { UrlPatternGuard } from '@app/core/auth/url-pattern.guard';
import { AppHttpInterceptorService } from './interceptors/app-http-interceptor.service';
import { ErrorHandlerService } from './interceptors/error-handler.service';
import {
    MicroserviceHttpEndpointInterceptorService,
    MicroserviceHttpErrorInterceptorService,
    MicroserviceHttpTokenInterceptorService, TokenRenewalInterceptor
} from '@app/shared/microservice/interceptor/angular';
import {V5AuthService} from "@app/core/auth/v5-auth.service";
import {HybridRedirectGuard} from '@app/core/auth/hybrid-redirect.guard';

export function appInitializerFactory(settings: SettingsService) {
    return () => settings.loadAppData()
}

export const appInitializer = {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [SettingsService],
    multi: true
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule
    ],
    providers: [
        CookieService,
        //--
        { provide: HTTP_INTERCEPTORS, useClass: TokenRenewalInterceptor, multi: true},
        { provide: UrlHandlingStrategy, useClass: HybridUrlHandlingStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MicroserviceHttpEndpointInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MicroserviceHttpTokenInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MicroserviceHttpErrorInterceptorService, multi: true },
        SettingsService,
        appInitializer,
        AuthService,
        AuthGuard,
        HybridRedirectGuard,
        UrlPatternGuard,
        ErrorHandlerService,
        V5AuthService,
        ConfigKBUrlService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
