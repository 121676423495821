import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsToastBoxService {
  private toastControlSource: BehaviorSubject<{
    action: 'show' | 'hide';
    message?: string;
    duration?: number;
  }> = new BehaviorSubject(null);
  toastControl$ = this.toastControlSource.asObservable();

  constructor() { }

  show(message: string, duration?: number) {
    this.toastControlSource.next({
      action: 'show',
      message: message,
      duration: duration
    })
  }

  hide() {
    this.toastControlSource.next({ action: 'hide' });
  }
}
