/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./template-form/sc-template-form.component.ngfactory";
import * as i2 from "./template-form/sc-template-form.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../services/event-dispatcher.service";
import * as i5 from "../../../core/auth/auth.service";
import * as i6 from "./template-view/sc-template-view.component.ngfactory";
import * as i7 from "./template-view/sc-template-view.component";
import * as i8 from "./sc-layout.component";
import * as i9 from "../services/sc-http-service.service";
var styles_ScLayoutComponent = [];
var RenderType_ScLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScLayoutComponent, data: {} });
export { RenderType_ScLayoutComponent as RenderType_ScLayoutComponent };
export function View_ScLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "comm-layout-container h-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-sc-template-form", [], null, null, null, i1.View_ScTemplateFormComponent_0, i1.RenderType_ScTemplateFormComponent)), i0.ɵdid(2, 245760, null, 0, i2.ScTemplateFormComponent, [i3.TranslateService, i4.EventDispatcherService, i5.AuthService], { singlePropertyInputModel: [0, "singlePropertyInputModel"], multiplePropertyInputModel: [1, "multiplePropertyInputModel"], viewMode: [2, "viewMode"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-sc-template-view", [], null, null, null, i6.View_ScTemplateViewComponent_0, i6.RenderType_ScTemplateViewComponent)), i0.ɵdid(4, 245760, null, 0, i7.ScTemplateViewComponent, [i4.EventDispatcherService], { viewMode: [0, "viewMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.singlePropertyInput; var currVal_1 = _co.multiplePropertyInput; var currVal_2 = _co.viewMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.viewMode; _ck(_v, 4, 0, currVal_3); }, null); }
export function View_ScLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sc-layout", [], null, null, null, View_ScLayoutComponent_0, RenderType_ScLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i8.ScLayoutComponent, [i3.TranslateService, i9.ScHttpServiceService, i4.EventDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScLayoutComponentNgFactory = i0.ɵccf("app-sc-layout", i8.ScLayoutComponent, View_ScLayoutComponent_Host_0, { objectName: "objectName", screenType: "screenType", sourceId: "sourceId", selectedTemplateData: "selectedTemplateData", viewMode: "viewMode" }, {}, []);
export { ScLayoutComponentNgFactory as ScLayoutComponentNgFactory };
