import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap  } from 'rxjs/operators';

//--
import { InterfaceService } from '@app/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SettingsPpmService extends InterfaceService{

  constructor(
    private http: HttpClient
  ) { super(); }

  // fetching existing ppm
  fetchPPM(param?: object): Observable<any>{
    return this.http.get(this.getApiUrl('system_settings/list-ppm',param)).pipe();
   }

   // Add new ppm
  addPPM(data: Object): Observable<any> {
    return this.http.post(this.getApiUrl('ppm/add-new'), JSON.stringify(data)).pipe();
  }

  // Update ppm
  editPPM(id: number, data: Object): Observable<any> {
    return this.http.put(this.getApiUrl(`ppm/${id}/edit`), JSON.stringify(data)).pipe();
  }

  // Checking delete option for ppm
  isDeletePPM(id: number): Observable<any>{
    return this.http.get(this.getApiUrl(`ppm/${id}/is-ppm-delete`)).pipe();
  }

  // Delete ppm
  deletePPM(id: number): Observable<any>{
    return this.http.delete(this.getApiUrl(`ppm/${id}/delete`)).pipe();
  }

  // get ppm configuration
  getPpmConfiguration(ppmId: number, param?: object): Observable<any>{
    return this.http.get(this.getApiUrl(`ppm/${ppmId}/configure/list-items`,param)).pipe();
  }

  // set ppm configuration
  setPpmConfiguration(ppmId: number, data: object){
    return this.http.post(this.getApiUrl(`ppm/${ppmId}/configure`), JSON.stringify(data)).pipe();
  }

  // Checking delete option for ppm configuration
  isDeletePPMConfig(id: number): Observable<any>{
    return this.http.get(this.getApiUrl(`ppm/${id}/is-ppmconfig-delete`)).pipe();
  }

  // Delete ppm configuration
  deletePPMConfig(id: number): Observable<any>{
    return this.http.delete(this.getApiUrl(`ppm/${id}/config/delete`)).pipe();
  }

  // get ppm sheduling
  getPpmSheduling(ppmId: number, configId: number): Observable<any>{
    return this.http.get(this.getApiUrl(`ppm/${ppmId}/${configId}/sheduling-data`)).pipe();
  }

  // update ppm sheduling
  updatePpmConfiguration(ppmId: number, configId: number, data: object): Observable<any>{
    return this.http.put(this.getApiUrl(`ppm/${ppmId}/${configId}/update`), JSON.stringify(data)).pipe();
  }
  // Check duplicate name or not
  CheckDuplicateName(name: string): Observable<any>{
        return this.http.get(this.getApiUrl(`ppm/check-ppm-name`,{name: name}));
  }

}
