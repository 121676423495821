import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {InterfaceResolver} from "@app/interfaces";
import { PartsAndPricesService } from "../services/parts-and-prices.service";

@Injectable()
export class PartsAndPricesResolver extends InterfaceResolver implements Resolve<Object> {

    constructor(private dataService: PartsAndPricesService) {
        super();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Object> | Promise<Object> | Object {
        return this.dataService.getPartsAndPricesMetadata()
            .pipe(
                map(data => data),
                catchError(Err => {
                    return this.fail();
                })
            );
    }
}