import { CsScrollbarConfigInterface } from "@app/directives/cs-scrollbar/cs-scrollbar-config-interface";
var DEFAULT_CS_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
    wheelSpeed: 0.2,
    suppressScrollX: true,
    suppressScrollY: false,
    swipeEasing: true,
};
var ɵ0 = DEFAULT_CS_SCROLLBAR_CONFIG;
var ServiceReminderCommunicationModule = /** @class */ (function () {
    function ServiceReminderCommunicationModule() {
    }
    return ServiceReminderCommunicationModule;
}());
export { ServiceReminderCommunicationModule };
export { ɵ0 };
