import { OnInit, EventEmitter } from '@angular/core';
import { IntegrationsService } from '@app/features/company-settings/integrations/services/integrations.service';
import { CommonService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
var CsToggleuserpreferenceComponent = /** @class */ (function () {
    function CsToggleuserpreferenceComponent(commonService, integrationsService) {
        var _this = this;
        this.commonService = commonService;
        this.integrationsService = integrationsService;
        this.screenName = '';
        this.labelName = 'Try out the new view screen';
        this.oldUrl = '';
        this.newUrl = '';
        this.preferenceValue = '';
        this.selected_preference = new EventEmitter();
        this.integrationsUI = false;
        this.feedback = '';
        this.toggleSwitch = false;
        this.count = 0;
        this.feedbackEmitter$ = new Subject();
        this.feedbackEmitter$.pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (value) {
            _this.validateFeedback();
        });
    }
    CsToggleuserpreferenceComponent.prototype.ngOnInit = function () {
        if (this.preferenceValue == '1') {
            this.toggleSwitch = true;
        }
        else {
            this.toggleSwitch = false;
        }
    };
    CsToggleuserpreferenceComponent.prototype.setUserPropertyViewPreference = function (savedFeedback) {
        var _this = this;
        this.count++;
        if (!this.integrationsUI || savedFeedback) {
            if (savedFeedback) {
                this.toggleSwitch = true;
            }
            var data = {};
            data['screenName'] = this.screenName;
            if (this.toggleSwitch == true) {
                this.toggleSwitch = false;
                data['columnValue'] = 0;
                this.commonService.saveUserNewUIPreference(data)
                    .subscribe(function (resp) {
                    window.location.href = _this.oldUrl;
                }, function (error) {
                    console.log('err', error);
                });
            }
            else if (this.toggleSwitch == false) {
                this.toggleSwitch = true;
                data['columnValue'] = 1;
                this.commonService.saveUserNewUIPreference(data)
                    .subscribe(function (resp) {
                    window.location.href = _this.newUrl;
                }, function (error) {
                    console.log('err', error);
                });
            }
        }
        else {
            this.showFeedbackForm = true;
            this.toggleSwitch = false;
        }
        if (this.count == 2 && this.integrationsUI && !savedFeedback) {
            this.hideFeedbackForm();
        }
    };
    CsToggleuserpreferenceComponent.prototype.hideFeedbackForm = function () {
        if (this.showFeedbackForm) {
            this.showFeedbackForm = false;
            this.toggleSwitch = true;
            this.feedback = '';
            this.count = 0;
        }
    };
    CsToggleuserpreferenceComponent.prototype.submitFeedbackForm = function () {
        var _this = this;
        if (this.isFeedbackValid) {
            var data = {
                feedback: this.feedback,
                uiScreen: this.screenName
            };
            this.integrationsService.sendFeedbackToSlackChannel(data).subscribe(function () {
                _this.setUserPropertyViewPreference(true);
                _this.showFeedbackForm = false;
            }, function () {
                return false;
            });
        }
        else {
            return false;
        }
    };
    CsToggleuserpreferenceComponent.prototype.validateFeedback = function () {
        var trimmedFeedback = this.feedback.replace(/\s{2,}/g, ' ').trim();
        if (trimmedFeedback.length < 10) {
            this.isFeedbackValid = false;
            return false;
        }
        else {
            this.isFeedbackValid = true;
            return true;
        }
    };
    return CsToggleuserpreferenceComponent;
}());
export { CsToggleuserpreferenceComponent };
