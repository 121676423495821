import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingsInvoiceCategoriesService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsInvoiceCategoriesService, _super);
    function SettingsInvoiceCategoriesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Get list of invoice categories configured
    SettingsInvoiceCategoriesService.prototype.getInvoiceCategories = function (params) {
        return this.http
            .get(this.getApiUrl("listInvoiceCategories", params));
    };
    SettingsInvoiceCategoriesService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsInvoiceCategoriesService_Factory() { return new SettingsInvoiceCategoriesService(i0.inject(i1.HttpClient)); }, token: SettingsInvoiceCategoriesService, providedIn: "root" });
    return SettingsInvoiceCategoriesService;
}(InterfaceService));
export { SettingsInvoiceCategoriesService };
