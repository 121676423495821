import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dynamic-certificate-signature-side-panel',
  templateUrl: './dynamic-certificate-signature-side-panel.component.html',
  styles: []
})
export class DynamicCertificateSignatureSidePanelComponent implements OnInit {
  signatureText: string = '';
  base64Image: string = '';
  @Input() showSignatureSidePanel = false;
  @Output() onSignatureGenerate: EventEmitter<any> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Input() screenFor:number=0;
  constructor() { }

  ngOnInit() {
  }

  onSidepanelClose(){
    this.showSignatureSidePanel = false;
    this.closeEmitter.emit(false);
    this.signatureText = '';
  }

  getSignature(base64Image){
    this.base64Image = base64Image;
  }

  insertSignature(){
    this.onSignatureGenerate.emit(this.base64Image);
    this.signatureText = '';
  }

}
