import { UrlHandlingStrategy, UrlTree } from '@angular/router';

//--
import { HybridFactory } from './hybrid-factory';

export class HybridUrlHandlingStrategy implements UrlHandlingStrategy {

    shouldProcessUrl(url: UrlTree) {
        // Preventing Angular navigation for non-upgraded routes
        let urlString = decodeURIComponent(url.toString());
        let isUpgraded = HybridFactory.isUpgraded(urlString);

        return !!isUpgraded;
    }

    extract(url: UrlTree) {
        return url;
    }

    merge(url: UrlTree, whole: UrlTree) {
        return url;
    }
}
