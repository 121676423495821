<div class=" clearfix">
    <div class="filter-user-width" dropdown #dropdown="bs-dropdown" [autoClose]="autoClose" placement="bottom left">
        <a class="dropdown-toggle padding-none float-left mlr-5 text-color pointer-cursor" dropdownToggle type="button">
            <div id="userMenuButton" (click)="clearRange()" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false" class="user-dropdown">
                <div class="filter-name-show-section">
                    <span class="margin-r5 user-filter-more-text-align" *ngIf="dateRangeFilter.length<30"
                          translate>{{dateRangeFilter}}</span>
                    <span class="margin-r5 user-filter-more-text-align" *ngIf="dateRangeFilter.length>30"
                          placement="bottom" tooltip="{{dateRangeFilter}}" translate>{{dateRangeFilter}}</span>
                </div>
                <i class="fa fa-angle-down dropdown-list-icons date-filter" aria-hidden="true"></i>
            </div>
        </a>
        <div class="dropdown-menu dropdown-menu-user-list quick-links large-quick-links user-filter date-range-dropdown"
             aria-labelledby="userMenuButton" id="userList" *dropdownMenu>
            <span class="dropdown-menu-arrow-user"></span>
                <div class="filter-container">
                    <div class="title top">
                        <div class="search_box small date-range-search">
                            <a class="ss-search">
                            </a>
                            <input class="search" placeholder="Search" type="search" [(ngModel)]="rangeSearch" (input)="searchRange()"
                                   autofocus>
                        </div>
                    </div>
                </div>
                <div class="cs-d-flex">
                    <div class="user-list-border scroll-container">
                        <ng-container *ngFor="let date of filterDate; let dateIndex=index">
                            <a *ngIf="date.key != 'date-range' && date.key != 'before' && date.key != 'after' && date.show"
                               class="dropdown-itemdate dis-flex pointer-cursor "
                               (click)="getFilterByDate(date,dateIndex)"
                               [ngClass]="{'assigned_color':date.selected}">{{date.text}}
                            </a>
                            <a *ngIf="date.key == 'date-range' && date.show"
                               (bsValueChange)="onDateRangeChanges('range',$event,dateIndex)"
                               [type]="range" [placement]="'top'" #daterangepicker="bsDaterangepicker"
                               bsDaterangepicker
                               container="#bs-daterange-inline-container"
                               class="dropdown-itemdate pointer-cursor range-picker"
                               [bsConfig]="{ showWeekNumbers:false }"
                               [ngClass]="{'assigned_color':date.selected}"
                               (click)="setDatePickerHeight('range')"> {{date.text}}
                            </a>
                            <a *ngIf="date.key == 'before' && date.show"
                               (bsValueChange)="onDateRangeChanges('date',$event,dateIndex)"
                               [placement]="'top'" #datepicker="bsDatepicker" bsDatepicker [maxDate]="currentDate"
                               container="#bs-daterange-inline-container"
                               class="dropdown-itemdate pointer-cursor range-picker" [bsConfig]="{ showWeekNumbers:false }"
                               [ngClass]="{'assigned_color':date.selected}" (click)="setDatePickerHeight('before')"> {{date.text}}
                            </a>
                            <a *ngIf="date.key == 'after' && date.show"
                               (bsValueChange)="onDateRangeChanges('date',$event,dateIndex)"
                               [placement]="'top'" #datepickerAfter="bsDatepicker" bsDatepicker
                               [minDate]="currentDate"
                               container="#bs-daterange-inline-container"
                               class="dropdown-itemdate pointer-cursor range-picker" [bsConfig]="{ showWeekNumbers:false }"
                               [ngClass]="{'assigned_color':date.selected}" (click)="setDatePickerHeight('after')"> {{date.text}}
                            </a>
                        </ng-container>
                    </div>
                    <div class="">
                        <div id="bs-daterange-inline-container"></div>
                    </div>
                </div>
        </div>
    </div>
<!--    <div class="dropdown-menu dropdown-menu-user-list quick-links large-quick-links user-filter transparent-icon"-->
<!--         aria-labelledby="userMenuButton" id="usersList">-->
<!--        <span class="dropdown-menu-arrow-user"></span>-->
<!--        <div class="filter-container">-->
<!--            <div class="user-list-border user-list-height object-list">-->
<!--    <div class="filter-user-width" dropdown #dropdown="bs-dropdown" [autoClose]="false" placement="bottom left">-->
<!--        <a class="" dropdownToggle type="button">-->
<!--            <div id="statusMenuButton" (click)="clearRange()" data-toggle="dropdown" aria-haspopup="true"-->
<!--                 aria-expanded="false" class="user-dropdown">-->
<!--                <div class="filter-name-show-section">-->
<!--                    <span class="margin-r5 user-filter-more-text-align" *ngIf="dateRangeFilter.length<30"-->
<!--                          translate>{{dateRangeFilter}}</span>-->
<!--                </div>-->
<!--                <i class="fa fa-angle-down dropdown-list-icons " aria-hidden="true"></i>-->
<!--            </div>-->
<!--        </a>-->
<!--        <div class="dropdown-menu-user-list quick-links large-quick-links user-filter date-range-dropdown"-->
<!--             aria-labelledby="userMenuButton" id="statusList" *dropdownMenu>-->
<!--            <span class="dropdown-menu-arrow-user"></span>-->
<!--            <div class="cs-d-flex">-->
<!--                <div class="user-list-border scroll-container">-->
<!--                    <ng-container *ngFor="let object of filterObjects; let userIndex=index">-->
<!--                        <a *ngIf="object.visibility" class="dropdown-item pointer-cursor user-filter-text "-->
<!--                           (click)="selectedObject(object.key,object.value)"-->
<!--                           [ngClass]="{'assigned_color':(selectedFilterObject == object.value || object.selected),'user-to-select': object.selected}">-->
<!--                            <span> 2222</span>-->
<!--                        </a>-->
<!--                    </ng-container>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <div class="">
        <a class="dropdown-toggle padding-none float-left mlr-5" (click)="statusFilter()">
            <div>
                <div id="userMenuButton"  data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false" class="user-dropdown pointer-cursor">
                    <div class="filter-name-show-section">
                <span class="margin-r5 user-filter-more-text-align text-color pointer-cursor"
                      *ngIf="selectedFilterObject.length<30" translate>{{selectedFilterObject}}</span>
                        <span class="margin-r5 user-filter-more-text-align text-color" *ngIf="selectedFilterObject.length>30"
                              placement="bottom" tooltip="{{selectedFilterObject}}">{{selectedFilterObject}}</span>
                    </div>
                    <i class="fa fa-angle-down dropdown-list-icons statusFilter" aria-hidden="true"></i>
                </div>
                <div class="dropdown-menu dropdown-menu-user-list quick-links large-quick-links user-filter transparent-icon"
                     aria-labelledby="userMenuButton" id="userList">
                    <span class="dropdown-menu-arrow-user"></span>
                    <div class="filter-container-sd">
                        <div class="user-list-border user-list-height object-list">
                            <ng-container *ngFor="let object of filterObjects; let userIndex=index">
                                <a *ngIf="object.visibility" class="pad dropdown-item pointer-cursor user-filter-text "
                                   (click)="selectedObject(object.key,object.value)"
                                   [ngClass]="{'assigned_color':(selectedFilterObject == object.value || object.selected),'user-to-select': object.selected}">
                                    <span> {{object?.value }} </span>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>



                    <!--<div class="">
                        <a class="btn dropdown-toggle padding-none float-left mlr-5">
                            <div class="filter-date dropdown">
                                <div id="userMenuButton" (click)="clearRange()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-dropdown">
                                    <div class="filter-name-show-section">
                                        <span class="margin-r5 user-filter-more-text-align" *ngIf="dateRangeFilter.length<30" translate>{{dateRangeFilter}}</span>
                                        <span class="margin-r5 user-filter-more-text-align" *ngIf="dateRangeFilter.length>30" placement="bottom" tooltip="{{dateRangeFilter}}" translate>{{dateRangeFilter}}</span>
                                    </div>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="userMenuButton" id="userList">
                                    <span class="dropdown-menu-arrow-user"></span>
                                    <div class="filter-container">
                                        <div class="title top">
                                            <div class="search_box small">
                                                <a class="ss-search"></a>
                                                <input placeholder="Search" type="search" [(ngModel)]="rangeSearch" (input)="searchRange()" autofocus>
                                            </div>
                                        </div>
                                        <div class="user-list-border user-list-height">
                                            <ng-container *ngFor="let date of filterDate; let dateIndex=index">
                                                <a *ngIf="date.text != 'Date range' && date.text != 'Before' && date.text != 'After' && date.show"
                                                   class="dropdown-itemdate dis-flex pointer-cursor date-range-list" (click)="getFilterByDate(date,dateIndex)"
                                                   [ngClass]="{'assigned_color':date.selected}">{{date.text}}
                                                </a>
                                                <a *ngIf="date.text == 'Date range' && date.show" (bsValueChange)="onDateRangeChanges('range',$event,dateIndex)"
                                                   [type]="range" [placement]="'top'" #daterangepicker="bsDaterangepicker" bsDaterangepicker
                                                   class="dropdown-itemdate pointer-cursor date-range-list"
                                                   [ngClass]="{'assigned_color':date.selected}"> {{date.text}}
                                                </a>
                                                <a *ngIf="date.text == 'Before' && date.show" (bsValueChange)="onDateRangeChanges('date',$event,dateIndex)"
                                                   [placement]="'top'" #datepicker="bsDatepicker" bsDatepicker [maxDate]="currentDate"
                                                   class="dropdown-itemdate pointer-cursor date-range-list"
                                                   [ngClass]="{'assigned_color':date.selected}"> {{date.text}}
                                                </a>
                                                <a *ngIf="date.text == 'After' && date.show" (bsValueChange)="onDateRangeChanges('date',$event,dateIndex)"
                                                   [placement]="'top'" #datepicker="bsDatepicker" bsDatepicker [minDate]="currentDate"
                                                   class="dropdown-itemdate pointer-cursor date-range-list"
                                                   [ngClass]="{'assigned_color':date.selected}"> {{date.text}}
                                                </a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>-->
