var CustomerContract = /** @class */ (function () {
    function CustomerContract(data) {
        if (!(data instanceof Object))
            data = {};
        this.id = data['id'] || '';
        this.name = data['name'] || '';
        this.description = data['description'] || '';
        this.contractPeriod = data['contractPeriod'] || '';
        this.contractStartDate = data['contractStartDate'] ? new Date(data['contractStartDate']) : null;
        this.contractEndDate = data['contractEndDate'] ? new Date(data['contractEndDate']) : null;
        this.contractTemplate = data['contractTemplate'] || '';
        this.contractTemplateName = data['contractTemplateName'] || '';
        this.customer = data['customer'] || '';
        this.category = data['category'] || '';
        this.categoryName = data['categoryName'] || '';
        this.contractType = data['contractType'] || '';
        this.contractTypeType = data['contractTypeType'] || '';
        this.sla = data['sla'] || '';
        this.slaName = data['slaName'] || '';
        this.invoiceCategory = data['invoiceCategory'] || '';
        this.invoiceCategoryDescription = data['invoiceCategoryDescription'] || '';
        this.usersGroup = data['usersGroup'] || '';
        this.usersGroupDescription = data['usersGroupDescription'] || '';
        this.customerContact = data['customerContact'] || '';
        this.customerContactCompanyName = data['customerContactCompanyName'] || '';
        this.customerContactName = data['customerContactName'] || '';
        this.customerContactSurname = data['customerContactSurname'] || '';
        this.status = data['status'] || '';
        this.deleted = data['deleted'] || '';
        this.createdbyid = data['createdbyid'] || '';
        this.createdbyname = data['createdbyname'] || '';
        this.createdbytype = data['createdbytype'] || '';
        this.createdondatetime = data['createdondatetime'] ? new Date(data['createdondatetime']) : null;
        this.lastModifiedDateTime = data['lastModifiedDateTime'] ? new Date(data['lastModifiedDateTime']) : null;
        this.activatedBy = data['activatedBy'] || '';
        this.activatedByName = data['activatedByName'] || '';
        this.activatedDateTime = data['activatedDateTime'] ? new Date(data['activatedDateTime']) : null;
        this.termAndConditionId = data['termAndConditionId'] || '';
        this.termAndConditionName = data['termAndConditionName'] || '';
        this.termAndConditionMessage = data['termAndConditionMessage'] || '';
    }
    return CustomerContract;
}());
export { CustomerContract };
