import {Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, Inject} from '@angular/core';
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";

@Component({
  selector: 'app-chatmessage-block',
  templateUrl: './chatmessage-block.component.html',
  styles: []
})
export class ChatmessageBlockComponent implements OnInit, AfterViewInit {

    name:string = 'chatmessageBlock';
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() isEdit:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() taggingList:any={};
    @Input() mainObjectName:string;
    @Input() isReadOnlyMode:boolean = false;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

    channelName:any = null;
    mentionContent:string = '';
    messageDelta:any = [];
    csTaggingList:any[]=[];
    showDeleteBtn:boolean;

    modules = {
        mention: {
            mentionListClass: "ql-mention-list mat-elevation-z8",
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            showDenotationChar: true,
            spaceAfterInsert: true,
            onSelect: (item: DOMStringMap, insertItem) => {
                // const editor = this.editor.quillEditor as Quill;
                if(item.denotationChar === '[') {
                    item['value'] = item['value']+']';
                }
                insertItem(item)
            },
            mentionDenotationChars: ["["],
            source: (searchTerm, renderList, mentionChar) => {
                let values = [];
                if(mentionChar === '[') {
                    values = this.csTaggingList;
                }

                if (searchTerm.length === 0) {
                    renderList(values, searchTerm)
                }
                else {
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (
                            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                        )
                        matches.push(values[i]);
                    renderList(matches, searchTerm);
                }
            }
        },
        toolbar: false
    };

    constructor(
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    ngOnInit() {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.getCsTaggingList();
        this.channelName = this.inputModel.model.channelName;
    }

    ngAfterViewInit() {
        if(this.isEdit) {
            //console.log('isEdit', this.inputModel)
            const {channelName, messageDelta} = this.inputModel.model;
            this.messageDelta = messageDelta ? JSON.parse(messageDelta) : [];
            this.channelName = channelName;
            this.editor.quillEditor.setContents(this.messageDelta);
        }
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation('You are about to delete a task. Are you sure you wish to do this?', this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    onChange(event) {
        if(!event.html) {
            this.messageDelta=null;
            this.mentionContent='';
            return;
        }
        const delta = event.content;
        this.messageDelta = JSON.stringify(delta);
        this.mentionContent = event.html;
    }

    addValue() {
        this.inputModel.model.channelName = this.channelName;
        this.inputModel.model.textMessage = this.mentionContent.replace(/(<([^>]+)>)/gi, "");
        this.inputModel.model.messageDelta = this.messageDelta;
        //console.log('save',this.inputModel.model);
        this.outputModel.emit(this.inputModel);
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    getCsTaggingList() {
        let cstagKeys = [];
        if(this.mainObjectName === 'job') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if (this.mainObjectName === 'invoice') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice'];
        }
        else if (this.mainObjectName === 'diaryEvents') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'diary_events'];
        }
        else if (this.mainObjectName === 'certificates') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        let __csTaggingList=[];
        cstagKeys.forEach( tagName => {
            let tagNameList = Object.values(this.taggingList[tagName]);
            __csTaggingList = __csTaggingList.concat(tagNameList);
        });
        this.csTaggingList = __csTaggingList.map( item => {
            return { id: item, value: item };
        });
        return this.csTaggingList;
    }

}
