import { EventEmitter } from '@angular/core';
//--
import { CsToastBoxService } from '@app/services';
var CsToastBoxComponent = /** @class */ (function () {
    function CsToastBoxComponent(toastBoxService) {
        this.toastBoxService = toastBoxService;
        this.duration = 1000; // Milliseconds
        this.show = true;
        this.onclose = new EventEmitter();
        this.transitionDuration = 300;
    }
    Object.defineProperty(CsToastBoxComponent.prototype, "showupDuration", {
        set: function (duration) {
            this.duration = duration || 1000;
        },
        enumerable: true,
        configurable: true
    });
    CsToastBoxComponent.prototype.ngOnInit = function () {
        this.setCloseTimer();
    };
    CsToastBoxComponent.prototype.onmouseover = function (event) {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = null;
        }
    };
    CsToastBoxComponent.prototype.onmouseleave = function (event) {
        this.setCloseTimer();
    };
    CsToastBoxComponent.prototype.close = function () {
        var _this = this;
        this.show = false;
        setTimeout(function () {
            _this.onclose.emit(true);
            _this.toastBoxService.hide();
        }, this.transitionDuration);
    };
    CsToastBoxComponent.prototype.setCloseTimer = function () {
        var _this = this;
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = null;
        }
        this.closeTimer = setTimeout(function () {
            _this.close();
        }, this.duration);
    };
    return CsToastBoxComponent;
}());
export { CsToastBoxComponent };
