<ng-container *ngIf="templateForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>Edit sms</h4>
    </div>
    <div class="modal-body ng-workflow-add-block">
        <div class="loading-wrap" *ngIf="isLoading">
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
        </div>
        <ng-container *ngIf="!isLoading">
            <form class="form-horizontal no-form-style" [formGroup]='templateForm'>
                <div class="cs-d-flex flex-column h-100">
                    <div class="help_section p-15">
                        <div class="help-text" translate>
                            It is possible to edit the sms template in order to customise it to this particular sequence. Editing this will not affect the sequence template.
                        </div>
                    </div>
                    <div class="body-content-section p-15">
                        <div class="cs-d-flex flex-column h-100">
                            <div class="control-group">
                                <label class="required_field" translate>Message</label>
                                <div class="message-height" [style.height]="'200px'">
                                    <quill-editor-custom [tagsList]="tagsList"  [form]="templateForm" [lableName]="'sms_message'" ></quill-editor-custom>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="modal-footer" *ngIf="!isLoading">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Cancel</a>
        <button class="btn btn-primary" [disabled]='!!saving || !this.templateForm.valid' (click)="onSave()">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</ng-container>
