import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SystemSettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(SystemSettingsService, _super);
    function SystemSettingsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Get list of job descriptions configured
    SystemSettingsService.prototype.getJobDescriptions = function (params) {
        return this.http
            .get(this.getApiUrl("list_JobDescriptions", params));
    };
    // Get list of user groups configured
    SystemSettingsService.prototype.getUserGroups = function (params) {
        return this.http
            .get(this.getApiUrl("listUserGroups", params));
    };
    // Get list of Asset groups
    SystemSettingsService.prototype.getAssetGroups = function (params) {
        return this.http
            .get(this.getApiUrl('contract-templates/whats-included/asset-groups', params));
    };
    // Get notification preferences
    SystemSettingsService.prototype.getNotificationPreferences = function (params) {
        return this.http
            .get(this.getApiUrl('system_settings/notification_preferences', params));
    };
    /* Get settings for ppm events
  
         Response :
           Array<{
              data: ""/{ jobs:number, notificationGroups: Array };
              default: [{ id: number, text: string }]
           }>
    */
    SystemSettingsService.prototype.getSetupJobsPpmEvents = function () {
        return this.http.get(this.getApiUrl('system_settings/ppm-jobs'));
    };
    SystemSettingsService.prototype.getEmailTemplatePortalSettings = function (params) {
        return this.http.get(this.getApiUrl("listSettingsEmailTemplates", params));
    };
    /* Set settings for ppm events
  
       Request  :  Data = { jobs:number, notificationGroups: Array }
       Response :  "success"
  
    */
    SystemSettingsService.prototype.setSetupJobsPpmEvents = function (Data) {
        return this.http.post(this.getApiUrl('system_settings/ppm-jobs'), JSON.stringify(Data));
    };
    SystemSettingsService.prototype.postInvoiceEmailTemplate = function (Data) {
        return this.http.post(this.getApiUrl('system_settings/invoice_message'), JSON.stringify(Data));
    };
    SystemSettingsService.prototype.postInvoiceCustomTemplate = function (invoiceType, Data) {
        return this.http.post(this.getApiUrl('system_settings/invoice_message/update/' + invoiceType), JSON.stringify(Data));
    };
    SystemSettingsService.prototype.postCustomerPoratlEmailTemplate = function (Data) {
        return this.http.post(this.getApiUrl('system_settings/customer_portal_email_message'), JSON.stringify(Data));
    };
    SystemSettingsService.prototype.saveCustomInvoiceNames = function (customInvoiceName) {
        return this.http.post(this.getApiUrl('system_settings/save_custom_invoice_names'), JSON.stringify(customInvoiceName));
    };
    SystemSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SystemSettingsService_Factory() { return new SystemSettingsService(i0.inject(i1.HttpClient)); }, token: SystemSettingsService, providedIn: "root" });
    return SystemSettingsService;
}(InterfaceService));
export { SystemSettingsService };
