<ng-container>
    <div class="row-fluid new-parts-prices" style="padding: 30px">
        <section class="data-section with-filter with-tooltips">
            <div class="row-fluid without-padding">
                <div class="section-title with-edit">
                    <h3 translate>Exit.Criteria</h3>
                    <div class="section-btns">
                        <a (click)="addOrEditExitConditions(data)" class="btn btn-primary btn-small" translate>Add.Exit.Criteria</a>
                    </div>
                    <hr class="section-separator">
                </div>
            </div>
            <app-settings-table *ngIf="!loading"
                                [metadata]="exitConditionMetaData"
                                [actions]="actions"
                                [rowReorder]="false"
                                [data]="dataList"
                                [dataUrl]=""
                                [emptyMessage] = "'There are no exit criteria'"
                                ($instance)="setTableInstance($event)">
            </app-settings-table>
            <div *ngIf="loading" class="row-fluid no-padding">
                <div>
                    <div class="spreadsheet-loading-wrap">
                        <span class="loading-spreadsheet">
                            <span class="loading-x-axis"></span>
                            <span class="loading-y-axis"></span>
                            <div class="loading-row row-1"></div>
                            <div class="loading-row row-2"></div>
                            <div class="loading-row row-3"></div>
                            <div class="loading-row row-4"></div>
                            <div class="loading-row row-5"></div>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<cs-modal [formGroup]="deleteConfirmationForm" [show]="showDeleteConfirmationModal">
    <ng-container *ngIf="showDeleteConfirmationModal">
        <!-- Modal header -->
        <div class="modal-header">
            <button type="button" class="close" (click)="onConfirmationModal()">×</button>
            <h4 translate>Delete.Criteria</h4>
        </div>

        <!-- Modal body -->
        <div class="modal-body ">
            <div class="row-fluid">
                <p translate>Delete.Criteria.Message</p>
            </div>
            <div class="input-prepend box-shadow input-fullwidth row-fluid">

        <span class="add-on">
          <span class="ss-caution"></span>
        </span>
                <div class="input-wrapper">
                    <input type="text" formControlName="confirmationText" class="modalDeleteNumber" (keyup.enter)="deleteExitCriteria()"/>
                </div>
            </div>
            <validation-error [formGroup]="deleteConfirmationForm" controlName="confirmationText" showOnDirty="true">
                <div class="row-fluid">
                    <span translate [translateParams]="{word:'DELETE',message:'to delete the exit criteria'}">confirmation.warning.text</span>
                </div>
            </validation-error>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
      <span *ngIf="action == 'deleting'">
        <img src="/images/loading/ajax-loader.gif"/>&nbsp;
        <span translate>Loading</span>
      </span>
            <div *ngIf="action != 'deleting'">
                <a href="javascript:void(0)" class="btn btn-cancel"
                   (click)="onConfirmationModal()" translate>Close</a>
                <button type="button" (click)="deleteExitCriteria()"
                        [disabled]="deleteConfirmationForm.invalid || action == 'deleting'" class="btn btn-danger btn-medium"
                        translate>Delete
                </button>
            </div>
        </div>
    </ng-container>
</cs-modal>
