import { PipeTransform } from '@angular/core';
import { formatDate } from "@angular/common";
var LongDatePipe = /** @class */ (function () {
    function LongDatePipe(settings, locale) {
        this.settings = settings;
        this.locale = locale;
        this.ordinalWithDate = '';
        this.settingsFormats = [
            { 'id': 0, 'format': 'EEE X#X MMM y' },
            { 'id': 1, 'format': 'EEEE X#X MMM y' },
            { 'id': 2, 'format': 'EEE X#X MMMM y' },
            { 'id': 3, 'format': 'EEEE X#X MMMM y' },
            { 'id': 50, 'format': 'EEE MMM X#X y' },
            { 'id': 51, 'format': 'EEEE MMM X#X y' },
            { 'id': 52, 'format': 'EEE MMMM X#X y' },
            { 'id': 53, 'format': 'EEEE MMMM X#X y' }
        ];
        this.ordinalJSON = JSON.parse(this.settings.ordinals);
        if (this.locale == 'en' || !this.locale)
            this.locale = 'en-GB';
    }
    LongDatePipe.prototype.validateDateString = function (dateTime) {
        var pattern = /^(\d\d\d\d)-(0[1-9]|1[0-2])-([0-2][1-9]|[1-3][0-1]|)/g;
        var dateObj = new Date(dateTime);
        // return (pattern.test(dateTime)) && ((new Date(dateTime)).getTime() > 0);
        return (pattern.test(dateTime)) && (!isNaN(dateObj.getTime())) && (dateTime === dateObj.toISOString().split('T')[0]);
    };
    LongDatePipe.prototype.doSmartFormat = function (dateString, currentDate) {
        this.smartFormat = undefined;
        var inputDateObj = new Date(dateString);
        var currentDateObj = new Date(currentDate);
        var nextDateObj = new Date(currentDateObj.getTime() + (24 * 60 * 60 * 1000));
        var prevDateObj = new Date(currentDateObj.getTime() - (24 * 60 * 60 * 1000));
        if ((currentDateObj.getFullYear() === inputDateObj.getFullYear()) && (currentDateObj.getMonth() === inputDateObj.getMonth()) && (currentDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat = 'Today';
        }
        else if ((prevDateObj.getFullYear() === inputDateObj.getFullYear()) && (prevDateObj.getMonth() === inputDateObj.getMonth()) && (prevDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat = 'Yesterday';
        }
        else if ((nextDateObj.getFullYear() === inputDateObj.getFullYear()) && (nextDateObj.getMonth() === inputDateObj.getMonth()) && (nextDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat = 'Tomorrow';
        }
        return this.smartFormat;
    };
    LongDatePipe.prototype.getDayOfTheMonth = function (dateString) {
        //covert mysql timestamp to JS timestamp
        var parsedDate = Date.parse(dateString.replace(/-/g, '/'));
        return new Date(parsedDate).getDate();
    };
    LongDatePipe.prototype.getDateFormat = function (intlSetting) {
        var format = this.settingsFormats.find(function (f) { return f.id == intlSetting; });
        return format ? format.format : undefined;
    };
    LongDatePipe.prototype.transform = function (MYSQLDatetime, isSmartFormatRequired) {
        if (isSmartFormatRequired === void 0) { isSmartFormatRequired = false; }
        var dateString = MYSQLDatetime.slice(0, 10);
        var IsDateValid = this.validateDateString(dateString);
        if (!IsDateValid) {
            return 'Invalid date';
        }
        if (isSmartFormatRequired && this.doSmartFormat(dateString, new Date().toISOString().split('T')[0])) {
            return this.smartFormat;
        }
        else {
            //TODO: Remove the default 0 while moving the regional settings screen to live as we don't want to hardcode anything for the client
            var intlSettings = this.settingsFormatId ? this.settingsFormatId : this.settings._csIntlSettings.long_date || 0;
            var format = this.getDateFormat(intlSettings);
            if (format && this.ordinalJSON.hasOwnProperty(this.locale)) {
                var date = this.getDayOfTheMonth(dateString);
                this.ordinalWithDate = this.ordinalJSON[this.locale][date];
            }
            else {
                //TODO: uncomment the below line and remove the other return statement  while moving regional settings screen to live as we don't want to hardcode anything for the client. Also update the testcases accordingly
                // return (!format) ? 'Format not available' : 'Undefined locale';
                format = 'EEE d MMM y';
            }
            return formatDate(dateString, format, this.locale).replace("X#X", this.ordinalWithDate);
        }
    };
    LongDatePipe.prototype.transformById = function (MYSQLDatetime, settingsFormatId, isSmartFormatRequired) {
        this.settingsFormatId = settingsFormatId;
        return this.transform(MYSQLDatetime, isSmartFormatRequired);
    };
    return LongDatePipe;
}());
export { LongDatePipe };
