<form *ngIf="formLoaded" [formGroup]="upsellForm">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body upsell-body" [ngClass]="{'no-events': action == 'saving'}">
		<div class="help_section">
			<div class="help-text" translate>
				Upsell.help.text
			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<!-- List Name -->
					<div class="control-group clearfix mb-0">
						<label class="control-label required_field" translate>Upsell.description</label>
						<div class="controls">
							<input type="text" class="control-input" formControlName="description">
						</div>
					</div>
					<!-- Alternative parts kit selection -->
					<div class="control-group clearfix margin-top-1">
						<label class="control-label required_field" translate>Alternative.part</label>
						<div class="controls select2-form-container">
							<input type="hidden" (selected)="updatePrices($event)" csSelect2 [select2Config]="select2Config"  required="true"
                           formControlName="part" [placeholder]="'-- please select --'" class="chosen-select full-width-select select-part">
						</div>
					</div>
					<!-- Labour time -->
					<!-- Need to uncomment when upsell is confirmed -->
                    <div class="control-group clearfix mb-0">
                        <label class="control-label required_field" translate>Unit.cost</label>
                        <div class="controls">
                            <input type="text" class="control-input" formControlName="unitCost">
                        </div>
                    </div>
                    <div class="control-group clearfix mb-0">
                        <label class="control-label required_field" translate>Sale.Price</label>
                        <div class="controls">
                            <input type="text" class="control-input" formControlName="salePrice">
                        </div>
                    </div>
                    <div class="control-group clearfix margin-top-1" *ngIf="vatEnabled">
                        <label class="control-label required_field" translate>Part {{vatLabel}}</label>
                        <div class="controls select2-form-container">
                            <select class="full-width-select2" id="triggerVatRate"  csSelect2 [select2Config]="select2RateConfig"
                                    class="full-width-select" formControlName="vatRate">
                                <option value="" translate>Please.Choose</option>
                                <optgroup *ngFor="let taxState of formattedTaxLists" label="{{taxState.text}}">
                                    <option *ngFor="let rate of taxState['children']" value="{{rate.id}}">{{rate.text}}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
					<div class="control-group clearfix mb-0">
						<label class="control-label required_field" translate>labour.time</label>
						<div class="controls">
							<input type="text" class="control-input" formControlName="labourTime">
						</div>
					</div>
					<div class="control-group clearfix margin-top-1">
						<label class="control-label required_field" translate>labour.rates</label>
						<div class="controls select2-form-container">
							<select class="full-width-select2" id="triggerLabourRate"  csSelect2 [select2Config]="select2RateConfig"
								class="full-width-select" formControlName="labourRate">
								<option value="" translate>Please.Choose</option>
								<option *ngFor="let rate of labourRatesList" value="{{rate.id}}">
									{{rate.description}}</option>
							</select>
						</div>
					</div>
                    <div class="control-group clearfix margin-top-1" *ngIf="vatEnabled">
                        <label class="control-label required_field" translate>Labour {{vatLabel}}</label>
                        <div class="controls select2-form-container">
                            <select class="full-width-select2" id="triggerLabourVatRate"  csSelect2 [select2Config]="select2RateConfig"
                                    class="full-width-select" formControlName="labourVatRate">
                                <option value="" translate>Please.Choose</option>
                                <optgroup *ngFor="let taxState of formattedTaxLists" label="{{taxState.text}}">
                                    <option *ngFor="let rate of taxState['children']" value="{{rate.id}}">{{rate.text}}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
					<div *ngIf="upsellForm.controls['bulletIns'].controls.length">
						<label class="control-label required_field" translate>labour.bulletin</label>

						<div *ngFor="let bulletin of upsellForm.controls['bulletIns'].controls; let i = index" formArrayName="bulletIns">
							<div>
								<i class="fa fa-plus-circle" style="color: #505B65;margin-left: 1px;vertical-align: baseline;" aria-hidden="true"></i>
								<input type="text" class="control-input bullet-in" [formControlName]="i" style="width: 82% ; height: 30px ;margin-left: 12px;">
								<!--<div style="float: right;margin-top: 5px;" *ngIf="i != 0">
									<a class="delete-link" *ngIf="upsellForm.controls['bulletIns'].controls.length != 1" href="javascript:void(0)" (click) = "deleteBulletin(i)" href="javascript:void(0)" style="vertical-align: text-bottom;margin-left: 15px;margin-right: 4px;">Delete</a>
								</div>-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        <ng-container *access ="['Upsell.deleteaccess']">
            <button class="btn btn-danger" *ngIf="type=='edit'" (click)="removeUpsell()">
                <span translate>Remove.upsell</span>
            </button>
        </ng-container>
        <button class="btn btn-primary"
				[disabled]="upsellForm.invalid || action == 'saving'"
				(click)="saveParts()">
			<span [hidden]="action == 'saving'" translate>Save</span>
			<span [hidden]="action != 'saving'" translate>Saving</span>
		</button>
	</div>
</form>
