import { OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
var AddPricingItemsComponent = /** @class */ (function () {
    function AddPricingItemsComponent(bsModalRef, proposalTemplateService) {
        this.bsModalRef = bsModalRef;
        this.proposalTemplateService = proposalTemplateService;
        this.proposalTemplatePartsListConfig = {};
        this.subscriptions = {};
        this.labouritems = [];
        this.partsitems = [];
        this.expensesitems = [];
        this.supplyFititems = [];
        this.filteredlabouritems = [];
        this.filteredpartsitems = [];
        this.filteredexpensesitems = [];
        this.filteredsupplyfititems = [];
        this.pricingsection = true;
        this.choosedPricingsection = { templateCostData: [] };
        this.filterParams = {
            searchText: '',
        };
        this.deleteId = [];
        this.typeChoosed = 'display_all';
        this.open = false;
        this.submitEnable = true;
        this.mergeEnable = true;
        this.mergeId = [];
        this.isPricingItemsAvailable = true;
        this.loading = true;
        this.isSearching = false;
        this.selectAllLabour = false;
        this.selectAllExpenses = false;
        this.selectAllParts = false;
        this.selectAllSupplyAndFit = false;
        this.selectAllTop = false;
        this.taxLabel = '';
    }
    AddPricingItemsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        if (this.merge) {
            this.mergeSave();
            this.open = true;
        }
        else {
            this.subscriptions['listCostData'] = this.proposalTemplateService.getProposalOptionLinkedPricing(this.proposalTemplatePartsListConfig)
                .subscribe(function (resp) {
                _this.open = true;
                if (resp) {
                    resp['partsCostData'].forEach(function (data) {
                        var salePrice = parseFloat(data.salePrice).toFixed(2);
                        var totalSalePrice = parseFloat(data.totalSalePrice);
                        _this.partsitems.push({ id: data.id, pricingPartIds: data.partsAndPricesId, salePrice: salePrice, description: data.description, totalSalePrice: totalSalePrice, quantity: data.quantity, discount: null, total: null, tax: data.vatRate, selected: false });
                    });
                    resp['labourCostData'].forEach(function (data) {
                        var salePrice = parseFloat(data.salePrice).toFixed(2);
                        var totalSalePrice = (parseFloat(data.totalSalePrice)).toFixed(2);
                        _this.labouritems.push({ id: data.id, pricingPartIds: data.partsAndPricesId, salePrice: salePrice, description: data.description, totalSalePrice: totalSalePrice, quantity: data.quantity, discount: null, total: null, tax: data.vatRate, selected: false });
                    });
                    resp['expenseCostData'].forEach(function (data) {
                        var salePrice = parseFloat(data.salePrice).toFixed(2);
                        _this.expensesitems.push({ id: data.id, description: data.description, salePrice: salePrice, totalSalePrice: salePrice, quantity: 1, discount: null, total: null, tax: data.vatRate, selected: false });
                    });
                    resp['supplyFit'].forEach(function (data) {
                        _this.supplyFititems.push({ id: data.id, supplyFitId: data.supplyFitId, pricingPartIds: data.partCostId, partCostId: data.partCostId, description: data.description, totalSalePrice: parseFloat(data.supplyFitAmount).toFixed(2), quantity: 1, discount: null, total: null, tax: data.vatRate, selected: false });
                    });
                    _this.filteredpartsitems = JSON.parse(JSON.stringify(_this.partsitems));
                    _this.filteredlabouritems = JSON.parse(JSON.stringify(_this.labouritems));
                    _this.filteredexpensesitems = JSON.parse(JSON.stringify(_this.expensesitems));
                    _this.filteredsupplyfititems = JSON.parse(JSON.stringify(_this.supplyFititems));
                    _this.isPricingItemsAvailable = (_this.filteredpartsitems && _this.filteredpartsitems.length) || (_this.filteredlabouritems && _this.filteredlabouritems.length) || (_this.filteredsupplyfititems && _this.filteredsupplyfititems.length);
                    _this.vatEnabled = resp['vatEnabled'];
                    _this.taxLabel = resp['taxLabel'];
                }
                _this.loading = false;
            });
            $('.modal-body').css({ 'background-color': '#ffffff' });
        }
        this.subscriptions['template'] = this.proposalTemplateService.templateName$.subscribe(function (template) {
            if (template) {
                if (template.hasOwnProperty('optionName')) {
                    _this.selectedTemplate = 'option';
                }
                else if (template.hasOwnProperty('isopportunityTemplate') && template['isopportunityTemplate']) {
                    _this.selectedTemplate = 'opportunity';
                }
                else {
                    _this.selectedTemplate = 'proposal';
                }
            }
        });
    };
    AddPricingItemsComponent.prototype.ngAfterViewInit = function () {
    };
    AddPricingItemsComponent.prototype.dropDownToggle = function (event) {
        $('#dropdownMenuButton > .dropdown-toggle').dropdown('toggle');
    };
    AddPricingItemsComponent.prototype.displaySearch = function () {
        if ($('#iconupdown').hasClass('fa-chevron-down')) {
            $('#iconupdown').removeClass('fa-chevron-down');
            $('#iconupdown').addClass('fa-chevron-up');
            $('#showsearch').css({ 'display': 'none' });
        }
        else {
            $('#iconupdown').removeClass('fa-chevron-up');
            $('#iconupdown').addClass('fa-chevron-down');
            $('#showsearch').css({ 'display': 'block' });
        }
    };
    AddPricingItemsComponent.prototype.getFilteredData = function (data, searchText) {
        return data.filter(function (i) { return i.description.toLowerCase().indexOf(searchText.toLowerCase()) != -1; });
    };
    AddPricingItemsComponent.prototype.onSearch = function (event, type) {
        var value;
        if (type == 'inline') {
            value = event.target.value;
        }
        else {
            var element = document.getElementById('search');
            value = element.value;
        }
        if (value && value != '') {
            // let value = event.target.value;
            this.isSearching = true;
            if (this.typeChoosed == 'supply') {
                this.filteredsupplyfititems = this.getFilteredData(this.supplyFititems, value); //this.supplyFititems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
            }
            else {
                this.filteredlabouritems = this.getFilteredData(this.labouritems, value); //this.labouritems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
                this.filteredpartsitems = this.getFilteredData(this.partsitems, value); //this.partsitems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
                this.filteredexpensesitems = this.getFilteredData(this.expensesitems, value); //this.expensesitems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
            }
        }
        else {
            this.isSearching = false;
            if (this.typeChoosed == 'supply') {
                this.filteredsupplyfititems = this.supplyFititems;
            }
            else {
                this.filteredexpensesitems = this.expensesitems;
                this.filteredlabouritems = this.labouritems;
                this.filteredpartsitems = this.partsitems;
            }
        }
        this.updateParentSelection();
    };
    AddPricingItemsComponent.prototype.clearSelection = function () {
    };
    AddPricingItemsComponent.prototype.updateCheckboxValues = function (property, value) {
        var dataSelected = this[property];
        if (dataSelected.length) {
            dataSelected.map(function (element) {
                element.selected = value;
                return element;
            });
        }
    };
    AddPricingItemsComponent.prototype.handleSelect = function (event, option) {
        var valToUpdate = event.target.checked;
        if (option == 'all') {
            this.updateCheckboxValues('filteredexpensesitems', valToUpdate);
            this.updateCheckboxValues('filteredlabouritems', valToUpdate);
            this.selectAllExpenses = valToUpdate;
            this.selectAllLabour = valToUpdate;
            if (this.typeChoosed == 'supply') {
                this.selectAllSupplyAndFit = valToUpdate;
                this.updateCheckboxValues('filteredsupplyfititems', valToUpdate);
            }
            else {
                this.selectAllParts = valToUpdate;
                this.updateCheckboxValues('filteredpartsitems', valToUpdate);
            }
        }
        else if (option == 'parts') {
            this.updateCheckboxValues('filteredpartsitems', valToUpdate);
            this.selectAllParts = valToUpdate;
        }
        else if (option == 'labour') {
            this.updateCheckboxValues('filteredlabouritems', valToUpdate);
            this.selectAllLabour = valToUpdate;
        }
        else if (option == 'expenses') {
            this.updateCheckboxValues('filteredexpensesitems', valToUpdate);
            this.selectAllExpenses = valToUpdate;
        }
        else if (option == 'supply') {
            this.selectAllSupplyAndFit = valToUpdate;
            this.updateCheckboxValues('filteredsupplyfititems', valToUpdate);
        }
        this.updateParentSelection();
    };
    AddPricingItemsComponent.prototype.selectallPrices = function (event) {
        if (event.target.checked) {
            $('#select-all').addClass('checkbox-check');
            this.choosedPricingsection = {
                templateCostData: (this.labouritems.concat(this.partsitems)).concat(this.expensesitems).concat(this.supplyFititems)
            };
        }
        else {
            $('#select-all').removeClass('checkbox-check');
            this.choosedPricingsection = {
                templateCostData: []
            };
        }
        this.addeditenable();
    };
    AddPricingItemsComponent.prototype.selectOfthem = function (event, type) {
        if (event.target.checked) {
            if (type == 'labour') {
                $('#labour-selectall').addClass('checkbox-check');
                this.choosedPricingsection = {
                    templateCostData: (this.choosedPricingsection['templateCostData'].concat(this.labouritems))
                };
            }
            else if (type == 'parts') {
                $('#parts-selectall').addClass('checkbox-check');
                this.choosedPricingsection = {
                    templateCostData: (this.choosedPricingsection['templateCostData'].concat(this.partsitems))
                };
            }
            // else if(type == 'expense'){
            //     $('#expense-selectall').addClass('checkbox-check')
            //     this.choosedPricingsection = {
            //         templateCostData:(this.choosedPricingsection['templateCostData'.concat(this.expensesitems)])
            //     }
            // }
        }
        else {
            $('#labour-selectall').removeClass('checkbox-check');
            $('#parts-selectall').removeClass('checkbox-check');
            $('#expense-selectall').removeClass('checkbox-check');
            var indexes = [];
            var checktype = indexes.filter(function (value) {
                return type == value['type'];
            });
            this.choosedPricingsection['templateCostData'] = checktype;
        }
        this.addeditenable();
    };
    /*selectPrices(event, option, value, index) {
        let idx = this.choosedPricingsection['templateCostData'].findIndex((i:any) => i == value)
        if(idx == -1) {
            $('#' + option + index).addClass('checkbox-check')
            this.choosedPricingsection['templateCostData'].push(value)
        } else {
            $('#'+ option + index).removeClass('checkbox-checkad')
            if(idx !=-1) { this.choosedPricingsection['templateCostData'].splice(idx, 1) }
        }
        this.addeditenable()
    }*/
    AddPricingItemsComponent.prototype.selectPrices = function (event, option, value, index) {
        if (event.target.tagName == 'INPUT') {
            event.stopPropagation();
        }
        if (option == 'parts') {
            var previousValue = this.filteredpartsitems[index].selected;
            this.filteredpartsitems[index].selected = !previousValue;
        }
        else if (option == 'expenses') {
            var previousValue = this.filteredexpensesitems[index].selected;
            this.filteredexpensesitems[index].selected = !previousValue;
        }
        else if (option == 'labour') {
            var previousValue = this.filteredlabouritems[index].selected;
            this.filteredlabouritems[index].selected = !previousValue;
        }
        else if (option == 'supply') {
            var previousValue = this.filteredsupplyfititems[index].selected;
            this.filteredsupplyfititems[index].selected = !previousValue;
        }
        this.updateParentSelection();
    };
    AddPricingItemsComponent.prototype.checkAllSelected = function (items) {
        var allSelected = true;
        if (items && items.length) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (!item.selected) {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    };
    AddPricingItemsComponent.prototype.updateParentSelection = function () {
        this.selectAllParts = this.checkAllSelected(this.filteredpartsitems);
        this.selectAllExpenses = this.checkAllSelected(this.filteredexpensesitems);
        this.selectAllLabour = this.checkAllSelected(this.filteredlabouritems);
        this.selectAllSupplyAndFit = this.checkAllSelected(this.filteredsupplyfititems);
        if (this.typeChoosed == 'supply') {
            if (this.selectAllSupplyAndFit && this.selectAllExpenses && this.selectAllLabour) {
                this.selectAllTop = false;
            }
            else {
                this.selectAllTop = this.selectAllSupplyAndFit && this.selectAllExpenses && this.selectAllLabour;
            }
        }
        else {
            if (this.selectAllParts && this.selectAllExpenses && this.selectAllLabour) {
                this.selectAllTop = false;
            }
            else {
                this.selectAllTop = this.selectAllParts && this.selectAllExpenses && this.selectAllLabour;
            }
        }
        this.updateSelectedCostRecords();
    };
    AddPricingItemsComponent.prototype.checkValue = function (option, item, index) {
        var idx = this.choosedPricingsection['templateCostData'].findIndex(function (i) { return i.id == item.id; });
        if (idx == -1) {
            $('#' + option + index).removeClass('checkbox-check');
            return false;
        }
        else {
            $('#' + option + index).addClass('checkbox-check');
            return true;
        }
    };
    AddPricingItemsComponent.prototype.mergeSave = function () {
        $('.modal-body').css({ 'background-color': '#f9f9f9', 'padding': '15px' });
        this.pricingsection = false;
        this.mergeForm = new FormGroup({
            description: new FormControl('', [Validators.required]),
            quantity: new FormControl(null),
            totalSalePrice: new FormControl('', [Validators.required]),
            pricingPartIds: new FormControl(null),
            discount: new FormControl(null),
            tax: new FormControl(null),
            total: new FormControl(null)
        });
        if (!this.merge) {
            this.setAmount();
        }
        else {
            this.addAmt();
        }
    };
    AddPricingItemsComponent.prototype.updateSelectedCostRecords = function () {
        var selectedRecords = [];
        this.filteredlabouritems.forEach(function (data) {
            if (data.selected) {
                selectedRecords.push(data);
            }
        });
        if (this.typeChoosed == 'supply') {
            this.filteredsupplyfititems.forEach(function (data) {
                if (data.selected) {
                    selectedRecords.push(data);
                }
            });
        }
        else {
            this.filteredpartsitems.forEach(function (data) {
                if (data.selected) {
                    selectedRecords.push(data);
                }
            });
        }
        this.filteredexpensesitems.forEach(function (data) {
            if (data.selected) {
                selectedRecords.push(data);
            }
        });
        this.choosedPricingsection.templateCostData = selectedRecords;
        this.addeditenable();
    };
    AddPricingItemsComponent.prototype.savePricing = function () {
        var _this = this;
        var value = [];
        this.submitEnable = true;
        if (this.choosedPricingsection.hasOwnProperty('templateCostData')) {
            this.choosedPricingsection['templateCostData'].forEach(function (option) {
                var item = {
                    "pricingCostId": option['id'],
                    "description": option['description'],
                    "quantity": option['quantity'],
                    "vat": option['tax'],
                    "total": option['total'],
                    "unitPrice": option['totalSalePrice'],
                    "supplyFitId": option.hasOwnProperty('supplyFitId') ? option['supplyFitId'] : '',
                    "notes": "",
                    "fileId": null
                };
                value.push(item);
            });
        }
        var type = this.typeChoosed == 'display_all' ? 'normal' : 'supply_and_fit';
        this.proposalTemplateService.addCustomerPricingItem(value, this.proposalTemplatePartsListConfig['sectionId'], type).subscribe(function (res) {
            if (res) {
                _this.proposalTemplateService.publishInvoiceScheduleRefresh({ sectionId: _this.proposalTemplatePartsListConfig['sectionId'], 'refresh': true });
                _this.onClose.next(value);
                _this.bsModalRef.hide();
            }
            else {
                _this.submitEnable = false;
            }
        });
    };
    AddPricingItemsComponent.prototype.mergePricingRecords = function () {
        var _this = this;
        var item = {
            "description": this.mergeForm.value['description'],
            // "quantity" : this.mergeForm.value['quantity'],
            "quantity": 1,
            "vat": this.mergeForm.value['tax'],
            "total": this.mergeForm.value['total'],
            "unitPrice": this.mergeForm.value['totalSalePrice'],
            "selectedCostIds": this.mergeId.join(),
            "notes": "",
            "fileId": null,
        };
        this.proposalTemplateService.addCustomerPricingItem([item], this.proposalTemplatePartsListConfig['sectionId'], 'merge').subscribe(function (res) {
            if (res) {
                _this.proposalTemplateService.publishInvoiceScheduleRefresh({ sectionId: _this.proposalTemplatePartsListConfig['sectionId'], 'refresh': true });
                var val = [];
                val.push(_this.mergeForm.value);
                _this.onClose.next(val);
                _this.bsModalRef.hide();
            }
        });
        this.onClose.next(true);
        this.bsModalRef.hide();
    };
    AddPricingItemsComponent.prototype.setAmount = function () {
        var _this = this;
        this.mergeId = [];
        var amount = 0;
        var quantity = 0;
        var pricingPartIds = '';
        Object.values(this.choosedPricingsection).forEach(function (option) {
            if (option && option.length) {
                option.forEach(function (item) {
                    if (item.hasOwnProperty('pricingPartIds') && item['pricingPartIds']) {
                        if (pricingPartIds != '') {
                            pricingPartIds = pricingPartIds + ',' + item.pricingPartIds;
                        }
                        else if (pricingPartIds == '') {
                            pricingPartIds = item.pricingPartIds;
                        }
                    }
                    quantity = quantity + parseInt(item.quantity);
                    amount = amount + parseFloat(item.totalSalePrice);
                    _this.mergeId.push(item.id);
                });
            }
        });
        this.mergeForm.patchValue({
            quantity: quantity,
            totalSalePrice: amount,
            pricingPartIds: pricingPartIds
        });
    };
    AddPricingItemsComponent.prototype.addAmt = function () {
        var _this = this;
        this.deleteId = [];
        var dist_len = 0;
        var tax_len = 0;
        var pricingPartIds = '';
        var amount = 0;
        var quantity = 0;
        var discount = 0;
        var tax = 0;
        var total = 0;
        if (this.mergeData.length) {
            this.mergeData.forEach(function (item) {
                if (item.quantity) {
                    quantity = quantity + parseInt(item.quantity);
                }
                if (item.totalSalePrice) {
                    amount = amount + parseFloat(item.totalSalePrice);
                }
                if (item.discount) {
                    discount = discount + (item.discount);
                    dist_len++;
                }
                if (item.tax) {
                    tax = tax + parseFloat(item.tax);
                    tax_len++;
                }
                if (item.hasOwnProperty('pricingPartIds') && item['pricingPartIds']) {
                    if (pricingPartIds != '') {
                        pricingPartIds = pricingPartIds + ',' + item.pricingPartIds;
                    }
                    else if (pricingPartIds == '') {
                        pricingPartIds = item.pricingPartIds;
                    }
                }
                if (item.total) {
                    total = total + parseFloat(item.total);
                }
                _this.deleteId.push(item.id);
            });
            discount = (discount / dist_len);
            tax = (tax / tax_len);
        }
        this.mergeForm.patchValue({
            quantity: quantity,
            totalSalePrice: amount,
            pricingPartIds: pricingPartIds,
            discount: discount,
            tax: tax,
            total: total
        });
    };
    AddPricingItemsComponent.prototype.addeditenable = function () {
        var value = this.choosedPricingsection['templateCostData'];
        var multiVatItemsSelected = this.vatEnabled ? this.checkMultipleVAT(value) : false;
        if (value.length == 1) {
            this.mergeEnable = true;
            this.submitEnable = false;
        }
        else if (value.length > 1) {
            if (multiVatItemsSelected == false) {
                this.mergeEnable = false;
            }
            else {
                this.mergeEnable = true;
            }
            this.submitEnable = false;
        }
        else {
            this.mergeEnable = true;
            this.submitEnable = true;
        }
    };
    AddPricingItemsComponent.prototype.mergeCancel = function () {
        if (!this.merge) {
            this.pricingsection = true;
            $('.modal-body').css({ 'background-color': '#fff' });
        }
        else {
            this.bsModalRef.hide();
            this.onClose.next(false);
        }
    };
    AddPricingItemsComponent.prototype.chooseType = function (option) {
        var _this = this;
        this.typeChoosed = option;
        this.selectAllTop = false;
        this.selectAllLabour = false;
        this.selectAllParts = false;
        this.selectAllExpenses = false;
        this.selectAllSupplyAndFit = false;
        this.isSearching = false;
        this.choosedPricingsection = { templateCostData: [] };
        if (option == 'supply') {
            this.filteredlabouritems = this.labouritems.filter(function (i) { return _this.supplyFititems.filter(function (j) { return j.id == i.id; }); });
        }
        else {
            this.filteredlabouritems = this.labouritems;
            this.filteredexpensesitems = this.expensesitems;
            this.filteredpartsitems = this.partsitems;
        }
        this.onSearch(event, 'toggleSearch');
        $('#dropdownMenuButton > .dropdown-toggle').dropdown('toggle');
    };
    AddPricingItemsComponent.prototype.checkMultipleVAT = function (items) {
        var vatArray = [];
        for (var i = 0; i < items.length; i++) {
            if (vatArray.indexOf(items[i].tax) === -1) {
                vatArray.push(items[i].tax);
            }
        }
        return vatArray.length > 1;
    };
    return AddPricingItemsComponent;
}());
export { AddPricingItemsComponent };
