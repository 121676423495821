import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MicroserviceErrorNotificationService = /** @class */ (function () {
    function MicroserviceErrorNotificationService() {
        this.msErrorNotificationSource = new BehaviorSubject(null);
        this.msErrorNotification$ = this.msErrorNotificationSource.asObservable();
        this.msErrorModalDisplay = new BehaviorSubject(null);
        this.onErrorModalDisplay$ = this.msErrorModalDisplay.asObservable();
        this.msErrorModalClose = new BehaviorSubject(null);
        this.onErrorModalClose$ = this.msErrorModalClose.asObservable();
    }
    // tslint:disable-next-line:max-line-length
    MicroserviceErrorNotificationService.prototype.notify = function (message, redirect_to, title, icon, close_text, request_identifier, status_code) {
        this.msErrorNotificationSource.next({
            action: 'notify',
            message: message,
            redirect_to: redirect_to,
            title: title,
            icon: icon,
            close_text: close_text,
            request_identifier: request_identifier,
            status_code: status_code,
        });
    };
    MicroserviceErrorNotificationService.prototype.hide = function () {
        this.msErrorNotificationSource.next({ action: 'hide' });
    };
    MicroserviceErrorNotificationService.prototype.notifyDisplay = function (request_identifier) {
        this.msErrorModalDisplay.next(request_identifier);
    };
    MicroserviceErrorNotificationService.prototype.notifyClose = function (request_identifier) {
        this.msErrorModalClose.next(request_identifier);
    };
    MicroserviceErrorNotificationService.ngInjectableDef = i0.defineInjectable({ factory: function MicroserviceErrorNotificationService_Factory() { return new MicroserviceErrorNotificationService(); }, token: MicroserviceErrorNotificationService, providedIn: "root" });
    return MicroserviceErrorNotificationService;
}());
export { MicroserviceErrorNotificationService };
