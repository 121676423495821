import { Subject } from "rxjs/internal/Subject";
import * as i0 from "@angular/core";
var SidepanelService = /** @class */ (function () {
    function SidepanelService() {
        this.modalClosed = new Subject();
        this.pagePropertiesSidePanel = new Subject();
        this.onCloseSidePanel = new Subject();
    }
    SidepanelService.prototype.OpportunityModalClose = function () {
        this.modalClosed.next(true);
    };
    SidepanelService.prototype.closePagePropertiesSidePanel = function () {
        this.pagePropertiesSidePanel.next(true);
    };
    SidepanelService.prototype.onCloseSidePanelModal = function () {
        this.onCloseSidePanel.next(true);
    };
    SidepanelService.ngInjectableDef = i0.defineInjectable({ factory: function SidepanelService_Factory() { return new SidepanelService(); }, token: SidepanelService, providedIn: "root" });
    return SidepanelService;
}());
export { SidepanelService };
