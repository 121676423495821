import { Injectable } from '@angular/core';
import {InterfaceService} from "@app/interfaces";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class CsTaggingService extends InterfaceService {

    tags: any[] = [];
    storeId: string = 'cs-tags';
    _process:any;

    constructor(private http: HttpClient) {
        super();
    }

    getTagsList(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`get_tags_list`, params));
    }

    getTags(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.tags = this.getWithExpiry(this.storeId);
            if (this.tags.length > 0) {
                resolve(this.tags);
            }
            else if(this.tags.length === 0) {
                this.getTagsList()
                .subscribe({
                    next: (resp) => {
                        this.tags = this.format4Quill(resp);
                        this.setWithExpiry(this.storeId, this.tags);
                        resolve(this.tags);
                    }
                })
            }
            else {
                reject(null);
            }
        });
    }

    getWithExpiry(key:string) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return [];
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return [];
        }
        this.tags = item.value;
        return this.tags;
    }

    setWithExpiry(key:string, value:any, ttl:number = (60000*60*24)) {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    format4Quill(data:any) {
        const tagsModName:any[] = Object.keys(data);
        const tags:any[] = [];
        tagsModName.forEach( (tagName) => {
            let tagsList = Object.values(data[tagName]);
            let groupName;
            if (tagsList[0] == 'schedule_date'){
                groupName = tagName == 'diary_events' ? 'schedule_events'+ " _tags" : tagName;
            }else{
                groupName = tagName.indexOf("_tags") === -1 ? tagName+ " _tags" : tagName;
            }
            let group = {
                id: groupName,
                value: groupName,
                disabled: true,
            };
            tags.push(group);
            tagsList.forEach( (tag) => {
                tags.push({
                    id: tag,
                    value: tag
                });
            })
        });
        return tags;
    }
}
