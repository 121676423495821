import * as tslib_1 from "tslib";
var InputModel = /** @class */ (function () {
    function InputModel(sourceId, serviceReminderConfigId, communicationId, name, propertyType, screenType, interval, messages, followUpMessage) {
        if (messages === void 0) { messages = {}; }
        if (followUpMessage === void 0) { followUpMessage = []; }
        this.sourceId = 0;
        this.serviceReminderConfigId = 0;
        this.itemName = 'first-letter';
        this.messages = {};
        this.followUpMessage = [];
        this.communicationTypes = ['email', 'sms', 'letter'];
        this.screenType = 'contract';
        this.communicationId = 0;
        this.tasksCount = 1;
        this.completedTasksCount = 0;
        this.isActive = false;
        this.isFollowUpItem = false;
        this.status = 'pending';
        this._intervalOld = null;
        this.sourceId = sourceId;
        this.serviceReminderConfigId = serviceReminderConfigId;
        this.communicationId = communicationId;
        this.screenType = screenType;
        this.name = name;
        this.propertyType = propertyType;
        this.interval = interval;
        this.messages = messages;
        this.followUpMessage = followUpMessage;
    }
    InputModel.prototype.isFirstCommunication = function () {
        return this.isFollowUpItem === false;
    };
    InputModel.prototype.isValidInterval = function () {
        return typeof this.interval === "number";
    };
    InputModel.prototype.getFollowupCount = function () {
        return this.followUpMessage.length;
    };
    InputModel.prototype.setDefaultMessages = function (defaultTemplates) {
        var _this = this;
        if (defaultTemplates === void 0) { defaultTemplates = {}; }
        this.communicationTypes.forEach(function (type) {
            var comTemplate = defaultTemplates[type];
            if (!comTemplate) {
                comTemplate = {};
            }
            _this.messages[type] = {
                messageId: 0,
                message: comTemplate['message'] ? comTemplate['message'] : '',
                subject: comTemplate['subject'] ? comTemplate['subject'] : '',
                templateId: comTemplate['templateId'] ? comTemplate['templateId'] : null,
                title: comTemplate['title'] ? comTemplate['title'] : null,
                _templateIdOld: null,
                propertyType: _this.propertyType,
                communicationType: type,
            };
        });
        return this;
    };
    InputModel.prototype.addFollowUpInputModel = function (inputModel) {
        this.followUpMessage.push(inputModel);
    };
    InputModel.prototype.setFollowupInputModel = function (data) {
        var _this = this;
        if (data === void 0) { data = []; }
        data.forEach(function (item) {
            var input = new FollowUpInputModel(item['sourceId'], item['serviceReminderConfigId'], item['communicationId'], item['name'], item['propertyType'], item['screenType'], item['interval'], item['messages'], []);
            if (item['followUpOrder']) {
                input.followUpOrder = item['followUpOrder'];
            }
            if (item['_followUpOrderOld']) {
                input._followUpOrderOld = item['_followUpOrderOld'];
            }
            if (item['status']) {
                input.status = item['status'];
            }
            if (item['completedTasksCount']) {
                input.completedTasksCount = item['completedTasksCount'];
            }
            if (item['_intervalOld']) {
                input._intervalOld = item['_intervalOld'];
            }
            input.itemName = item['name'];
            input.isFollowUpItem = true;
            _this.addFollowUpInputModel(input);
        });
    };
    InputModel.prototype.setInactive = function () {
        this.isActive = false;
    };
    InputModel.prototype.setActive = function () {
        this.isActive = true;
    };
    InputModel.prototype.getName = function () {
        return this.name;
    };
    InputModel.prototype.getLastInterval = function () {
        var lastInterval = this.interval;
        if (this.followUpMessage.length > 0) {
            lastInterval = Math.max.apply(Math, this.followUpMessage.map(function (o) { return o.interval ? o.interval : lastInterval; }));
        }
        return lastInterval;
    };
    InputModel.prototype.getMinInterval = function () {
        var selectedInterval = this.getAllSelectedIntervals();
        var index = selectedInterval.indexOf(this.interval);
        if (index > -1) {
            selectedInterval.splice(index, 1);
        }
        if (selectedInterval.length > 0) {
            return Math.min.apply(Math, selectedInterval.map(function (o) { return o; }));
        }
        return -1;
    };
    InputModel.prototype.getAllSelectedIntervals = function () {
        var selectedInterval = [];
        /*if(this.interval) {
            selectedInterval.push(this.interval);
        }*/
        if (this.followUpMessage.length > 0) {
            this.followUpMessage.forEach(function (inputModel) {
                if (inputModel.interval) {
                    selectedInterval.push(inputModel.interval);
                }
            });
        }
        return selectedInterval;
    };
    InputModel.prototype.removeFollowupItem = function (index) {
        this.followUpMessage.splice(index, 1);
    };
    return InputModel;
}());
export { InputModel };
var FollowUpInputModel = /** @class */ (function (_super) {
    tslib_1.__extends(FollowUpInputModel, _super);
    function FollowUpInputModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.followUpOrder = 0;
        _this._followUpOrderOld = 0;
        return _this;
    }
    return FollowUpInputModel;
}(InputModel));
export { FollowUpInputModel };
