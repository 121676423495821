var ContractTemplate = /** @class */ (function () {
    function ContractTemplate(data) {
        if (!(data instanceof Object))
            data = {};
        this.id = data['id'] || '';
        this.name = data['name'] || '';
        this.description = data['description'] || '';
        this.category = data['category'] || '';
        this.contractType = data['contractType'] || '';
        this.contractPeriod = data['contractPeriod'] || '';
        this.sla = data['sla'] || '';
        this.invoiceCategory = data['invoiceCategory'] || '';
        this.usersGroup = data['usersGroup'] || '';
        this.status = data['status'] || '';
        this.deleted = data['deleted'] || '';
        this.uuid = data['uuid'] || '';
        this.createdondatetime = data['createdondatetime'] ? new Date(data['createdondatetime']) : null;
        this.createdbytype = data['createdbytype'] || '';
        this.createdbyid = data['createdbyid'] || '';
        this.lastModifiedDateTime = data['lastModifiedDateTime'] ? new Date(data['lastModifiedDateTime']) : null;
        this.contractTandC = data['contractTandC'] || '';
    }
    return ContractTemplate;
}());
export { ContractTemplate };
