import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { it } from "@angular/core/testing/src/testing_internal";
declare var $: any;

@Component({
    selector: 'app-add-pricing-items',
    templateUrl: './add-pricing-items.component.html',
    styleUrls: ['./add-pricing-items.component.scss']
})
export class AddPricingItemsComponent implements OnInit, AfterViewInit {

    public onClose: Subject<boolean>;
    title: string;
    proposalTemplatePartsListConfig: object = {}
    selectedTemplate: any;
    subscriptions: any = {}
    labouritems: any = []
    partsitems: any = []
    expensesitems: any = []
    supplyFititems: any = []
    filteredlabouritems: any = []
    filteredpartsitems: any = []
    filteredexpensesitems: any = []
    filteredsupplyfititems: any = []
    pricingsection: boolean = true;
    mergeForm: FormGroup;
    choosedPricingsection: any = { templateCostData: [] }
    filterParams: any = {
        searchText: '',
    };
    merge: boolean;
    mergeData: any;
    deleteId: any = [];
    customerPricing: any;
    typeChoosed: any = 'display_all';
    open: boolean = false;
    submitEnable: boolean = true;
    mergeEnable: boolean = true;
    mergeId: any = [];
    isPricingItemsAvailable: boolean = true;
    loading: boolean = true;
    isSearching: boolean = false;

    selectAllLabour: boolean = false;
    selectAllExpenses: boolean = false;
    selectAllParts: boolean = false;
    selectAllSupplyAndFit: boolean = false;
    selectAllTop: boolean = false;
    tableType:any;
    vatEnabled: boolean;

    taxLabel : string = '';
    constructor(public bsModalRef: BsModalRef, public proposalTemplateService: ProposalTemplateService) {
    }


    ngOnInit() {
        this.onClose = new Subject();
        if (this.merge) {
            this.mergeSave()
            this.open = true
        } else {
            this.subscriptions['listCostData'] = this.proposalTemplateService.getProposalOptionLinkedPricing(this.proposalTemplatePartsListConfig)
                .subscribe((resp: any) => {
                    this.open = true
                    if (resp) {
                        resp['partsCostData'].forEach(data => {
                            let salePrice = parseFloat(data.salePrice).toFixed(2);
                            let totalSalePrice = parseFloat(data.totalSalePrice);
                            this.partsitems.push({ id: data.id, pricingPartIds: data.partsAndPricesId, salePrice: salePrice, description: data.description, totalSalePrice: totalSalePrice, quantity: data.quantity, discount: null, total: null, tax: data.vatRate, selected: false })
                        })
                        resp['labourCostData'].forEach(data => {
                            let salePrice = parseFloat(data.salePrice).toFixed(2);
                            let totalSalePrice = (parseFloat(data.totalSalePrice)).toFixed(2);
                            this.labouritems.push({ id: data.id, pricingPartIds: data.partsAndPricesId, salePrice: salePrice, description: data.description, totalSalePrice: totalSalePrice, quantity: data.quantity, discount: null, total: null, tax: data.vatRate, selected: false })
                        })
                        resp['expenseCostData'].forEach(data => {
                            let salePrice = parseFloat(data.salePrice).toFixed(2);
                            this.expensesitems.push({ id: data.id, description: data.description, salePrice: salePrice, totalSalePrice: salePrice, quantity: 1, discount: null, total: null, tax: data.vatRate, selected: false })
                        })
                        resp['supplyFit'].forEach(data => {
                            this.supplyFititems.push({ id: data.id, supplyFitId: data.supplyFitId, pricingPartIds: data.partCostId, partCostId: data.partCostId, description: data.description, totalSalePrice: parseFloat(data.supplyFitAmount).toFixed(2), quantity: 1, discount: null, total: null, tax: data.vatRate, selected: false })
                        })
                        this.filteredpartsitems = JSON.parse(JSON.stringify(this.partsitems));
                        this.filteredlabouritems = JSON.parse(JSON.stringify(this.labouritems));
                        this.filteredexpensesitems = JSON.parse(JSON.stringify(this.expensesitems));
                        this.filteredsupplyfititems = JSON.parse(JSON.stringify(this.supplyFititems));

                        this.isPricingItemsAvailable = (this.filteredpartsitems && this.filteredpartsitems.length) || (this.filteredlabouritems && this.filteredlabouritems.length) || (this.filteredsupplyfititems && this.filteredsupplyfititems.length);
                        this.vatEnabled = resp['vatEnabled'];
                        this.taxLabel =  resp['taxLabel'];
                    }
                    this.loading = false;
                })
            $('.modal-body').css({ 'background-color': '#ffffff' })
        }
        this.subscriptions['template'] = this.proposalTemplateService.templateName$.subscribe((template: any) => {
            if (template) {
                if (template.hasOwnProperty('optionName')) {
                    this.selectedTemplate = 'option'
                } else if (template.hasOwnProperty('isopportunityTemplate') && template['isopportunityTemplate']) {
                    this.selectedTemplate = 'opportunity'
                } else {
                    this.selectedTemplate = 'proposal'
                }
            }
        })

    }

    ngAfterViewInit() {

    }

    dropDownToggle(event){
        $('#dropdownMenuButton > .dropdown-toggle').dropdown('toggle');
    }

    displaySearch() {
        if ($('#iconupdown').hasClass('fa-chevron-down')) {
            $('#iconupdown').removeClass('fa-chevron-down')
            $('#iconupdown').addClass('fa-chevron-up')
            $('#showsearch').css({ 'display': 'none' })
        } else {
            $('#iconupdown').removeClass('fa-chevron-up')
            $('#iconupdown').addClass('fa-chevron-down')
            $('#showsearch').css({ 'display': 'block' })
        }
    }

    getFilteredData(data, searchText) {
        return data.filter((i: any) => i.description.toLowerCase().indexOf(searchText.toLowerCase()) != -1);
    }

    onSearch(event,type) {
        let value
        if(type == 'inline'){
            value = event.target.value
        } else {
            let element:any = document.getElementById('search')
            value = element.value
        }
        if (value && value != '') {
            // let value = event.target.value;
            this.isSearching = true;
           if(this.typeChoosed == 'supply'){
                this.filteredsupplyfititems = this.getFilteredData(this.supplyFititems, value);//this.supplyFititems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
           }
           else{
                this.filteredlabouritems = this.getFilteredData(this.labouritems, value);//this.labouritems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
                this.filteredpartsitems = this.getFilteredData(this.partsitems, value);//this.partsitems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
                this.filteredexpensesitems = this.getFilteredData(this.expensesitems, value);//this.expensesitems.filter((i:any) => i.description.toLowerCase().indexOf(value.toLowerCase()) != -1);
           }
        } else {
            this.isSearching = false;
            if (this.typeChoosed == 'supply') {
                this.filteredsupplyfititems = this.supplyFititems;
            }
            else{
            this.filteredexpensesitems = this.expensesitems;
            this.filteredlabouritems = this.labouritems;
            this.filteredpartsitems = this.partsitems;
            }
        }
        this.updateParentSelection();
    }

    clearSelection() {

    }

    updateCheckboxValues(property, value) {
        let dataSelected = this[property];
        if (dataSelected.length) {
            dataSelected.map(function (element) {
                element.selected = value;
                return element;
            });
        }
    }

    handleSelect(event, option) {
        let valToUpdate = event.target.checked;

        if (option == 'all') {
            this.updateCheckboxValues('filteredexpensesitems', valToUpdate);
            this.updateCheckboxValues('filteredlabouritems', valToUpdate);

            this.selectAllExpenses = valToUpdate;
            this.selectAllLabour = valToUpdate;

            if (this.typeChoosed == 'supply') {
                this.selectAllSupplyAndFit = valToUpdate;
                this.updateCheckboxValues('filteredsupplyfititems', valToUpdate);
            } else {
                this.selectAllParts = valToUpdate;
                this.updateCheckboxValues('filteredpartsitems', valToUpdate);
            }
        } else if (option == 'parts') {
            this.updateCheckboxValues('filteredpartsitems', valToUpdate);
            this.selectAllParts = valToUpdate;
        } else if (option == 'labour') {
            this.updateCheckboxValues('filteredlabouritems', valToUpdate);
            this.selectAllLabour = valToUpdate;
        } else if (option == 'expenses') {
            this.updateCheckboxValues('filteredexpensesitems', valToUpdate);
            this.selectAllExpenses = valToUpdate;
        } else if (option == 'supply') {
            this.selectAllSupplyAndFit = valToUpdate;
            this.updateCheckboxValues('filteredsupplyfititems', valToUpdate);
        }

        this.updateParentSelection();
    }

    selectallPrices(event) {
        if (event.target.checked) {
            $('#select-all').addClass('checkbox-check')
            this.choosedPricingsection = {
                templateCostData: (this.labouritems.concat(this.partsitems)).concat(this.expensesitems).concat(this.supplyFititems)
            }
        } else {
            $('#select-all').removeClass('checkbox-check')
            this.choosedPricingsection = {
                templateCostData: []
            }
        }
        this.addeditenable()
    }

    selectOfthem(event, type) {
        if (event.target.checked) {
            if (type == 'labour') {
                $('#labour-selectall').addClass('checkbox-check')
                this.choosedPricingsection = {
                    templateCostData: (this.choosedPricingsection['templateCostData'].concat(this.labouritems))
                }
            }
            else if (type == 'parts') {
                $('#parts-selectall').addClass('checkbox-check')
                this.choosedPricingsection = {
                    templateCostData: (this.choosedPricingsection['templateCostData'].concat(this.partsitems))
                }
            }
            // else if(type == 'expense'){
            //     $('#expense-selectall').addClass('checkbox-check')
            //     this.choosedPricingsection = {
            //         templateCostData:(this.choosedPricingsection['templateCostData'.concat(this.expensesitems)])
            //     }
            // }
        } else {
            $('#labour-selectall').removeClass('checkbox-check')
            $('#parts-selectall').removeClass('checkbox-check')
            $('#expense-selectall').removeClass('checkbox-check')
            let indexes = [];
            let checktype = indexes.filter(function (value) {
                return type == value['type'];
            });
            this.choosedPricingsection['templateCostData'] = checktype;
        }
        this.addeditenable()
    }

    /*selectPrices(event, option, value, index) {
        let idx = this.choosedPricingsection['templateCostData'].findIndex((i:any) => i == value)
        if(idx == -1) {
            $('#' + option + index).addClass('checkbox-check')
            this.choosedPricingsection['templateCostData'].push(value)
        } else {
            $('#'+ option + index).removeClass('checkbox-checkad')
            if(idx !=-1) { this.choosedPricingsection['templateCostData'].splice(idx, 1) }
        }
        this.addeditenable()
    }*/

    selectPrices(event, option, value, index) {
        if (event.target.tagName == 'INPUT') {
            event.stopPropagation();
        }
        if (option == 'parts') {
            let previousValue = this.filteredpartsitems[index].selected;
            this.filteredpartsitems[index].selected = !previousValue;
        } else if (option == 'expenses') {
            let previousValue = this.filteredexpensesitems[index].selected;
            this.filteredexpensesitems[index].selected = !previousValue;
        } else if (option == 'labour') {
            let previousValue = this.filteredlabouritems[index].selected;
            this.filteredlabouritems[index].selected = !previousValue;
        } else if (option == 'supply') {
            let previousValue = this.filteredsupplyfititems[index].selected;
            this.filteredsupplyfititems[index].selected = !previousValue;
        }
        this.updateParentSelection();
    }

    checkAllSelected(items) {
        let allSelected = true;
        if (items && items.length) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (!item.selected) {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    }

    updateParentSelection() {
        this.selectAllParts = this.checkAllSelected(this.filteredpartsitems);
        this.selectAllExpenses = this.checkAllSelected(this.filteredexpensesitems);
        this.selectAllLabour = this.checkAllSelected(this.filteredlabouritems);
        this.selectAllSupplyAndFit = this.checkAllSelected(this.filteredsupplyfititems);

        if (this.typeChoosed == 'supply') {
            if (this.selectAllSupplyAndFit && this.selectAllExpenses && this.selectAllLabour){
                this.selectAllTop = false;
            }else{
                this.selectAllTop = this.selectAllSupplyAndFit && this.selectAllExpenses && this.selectAllLabour;
            }
        } else {
            if (this.selectAllParts && this.selectAllExpenses && this.selectAllLabour){
                this.selectAllTop = false;
            }else{
                this.selectAllTop = this.selectAllParts && this.selectAllExpenses && this.selectAllLabour;
            }
        }
        this.updateSelectedCostRecords();
    }

    checkValue(option, item, index) {
        let idx = this.choosedPricingsection['templateCostData'].findIndex((i: any) => i.id == item.id)
        if (idx == -1) {
            $('#' + option + index).removeClass('checkbox-check')
            return false
        } else {
            $('#' + option + index).addClass('checkbox-check')
            return true
        }
    }

    mergeSave() {
        $('.modal-body').css({ 'background-color': '#f9f9f9', 'padding': '15px' })
        this.pricingsection = false;
        this.mergeForm = new FormGroup({
            description: new FormControl('', [Validators.required]),
            quantity: new FormControl(null),
            totalSalePrice: new FormControl('', [Validators.required]),
            pricingPartIds: new FormControl(null),
            discount: new FormControl(null),
            tax: new FormControl(null),
            total: new FormControl(null)
        });
        if (!this.merge) { this.setAmount() }
        else {
            this.addAmt()
        }
    }

    updateSelectedCostRecords() {
        let selectedRecords = [];

        this.filteredlabouritems.forEach(data => {
            if (data.selected) {
                selectedRecords.push(data);
            }
        });

        if (this.typeChoosed == 'supply') {
            this.filteredsupplyfititems.forEach(data => {
                if (data.selected) {
                    selectedRecords.push(data);
                }
            });
        } else {
            this.filteredpartsitems.forEach(data => {
                if (data.selected) {
                    selectedRecords.push(data);
                }
            });
        }

        this.filteredexpensesitems.forEach(data => {
            if (data.selected) {
                selectedRecords.push(data);
            }
        });

        this.choosedPricingsection.templateCostData = selectedRecords;
        this.addeditenable();
    }

    savePricing() {
        let value: any = [];
        this.submitEnable = true;
        if (this.choosedPricingsection.hasOwnProperty('templateCostData')) {
            this.choosedPricingsection['templateCostData'].forEach(option => {
                let item = {
                    "pricingCostId": option['id'],
                    "description": option['description'],
                    "quantity": option['quantity'],
                    "vat": option['tax'],
                    "total": option['total'],
                    "unitPrice": option['totalSalePrice'],
                    "supplyFitId": option.hasOwnProperty('supplyFitId') ? option['supplyFitId'] : '',
                    "notes": "",
                    "fileId": null
                }
                value.push(item);
            });
        }
        let type = this.typeChoosed == 'display_all' ? 'normal' : 'supply_and_fit';
        this.proposalTemplateService.addCustomerPricingItem(value, this.proposalTemplatePartsListConfig['sectionId'], type).subscribe(
            res => {
                if (res) {
                    this.proposalTemplateService.publishInvoiceScheduleRefresh({ sectionId: this.proposalTemplatePartsListConfig['sectionId'], 'refresh': true });
                    this.onClose.next(value)
                    this.bsModalRef.hide()
                }
                else{
                    this.submitEnable = false;
                }
            }
        )
    }
    mergePricingRecords() {
        let item = {
            "description": this.mergeForm.value['description'],
            // "quantity" : this.mergeForm.value['quantity'],
            "quantity": 1,
            "vat": this.mergeForm.value['tax'],
            "total": this.mergeForm.value['total'],
            "unitPrice": this.mergeForm.value['totalSalePrice'],
            "selectedCostIds": this.mergeId.join(),
            "notes": "",
            "fileId": null,
        }
        this.proposalTemplateService.addCustomerPricingItem([item], this.proposalTemplatePartsListConfig['sectionId'], 'merge').subscribe(
            res => {
                if (res) {
                    this.proposalTemplateService.publishInvoiceScheduleRefresh({ sectionId: this.proposalTemplatePartsListConfig['sectionId'], 'refresh': true });
                    let val: any = [];
                    val.push(this.mergeForm.value)
                    this.onClose.next(val)
                    this.bsModalRef.hide()
                }
            }
        )
        this.onClose.next(true)
        this.bsModalRef.hide()
    }

    setAmount() {
        this.mergeId = []
        let amount = 0; let quantity = 0; let pricingPartIds = '';
        Object.values(this.choosedPricingsection).forEach((option: any) => {
            if (option && option.length) {
                option.forEach(item => {
                    if (item.hasOwnProperty('pricingPartIds') && item['pricingPartIds']) {
                        if (pricingPartIds != '') {
                            pricingPartIds = pricingPartIds + ',' + item.pricingPartIds
                        } else if (pricingPartIds == '') {
                            pricingPartIds = item.pricingPartIds
                        }
                    }
                    quantity = quantity + parseInt(item.quantity)
                    amount = amount + parseFloat(item.totalSalePrice)
                    this.mergeId.push(item.id)
                })
            }
        })
        this.mergeForm.patchValue({
            quantity,
            totalSalePrice: amount,
            pricingPartIds
        })
    }

    addAmt() {
        this.deleteId = []; let dist_len = 0; let tax_len = 0; let pricingPartIds = '';
        let amount = 0; let quantity = 0; let discount = 0; let tax = 0; let total = 0;
        if (this.mergeData.length) {
            this.mergeData.forEach((item: any) => {
                if (item.quantity) {
                    quantity = quantity + parseInt(item.quantity)
                }
                if (item.totalSalePrice) {
                    amount = amount + parseFloat(item.totalSalePrice)
                }
                if (item.discount) {
                    discount = discount + (item.discount)
                    dist_len++
                }
                if (item.tax) {
                    tax = tax + parseFloat(item.tax)
                    tax_len++
                }
                if (item.hasOwnProperty('pricingPartIds') && item['pricingPartIds']) {
                    if (pricingPartIds != '') {
                        pricingPartIds = pricingPartIds + ',' + item.pricingPartIds
                    } else if (pricingPartIds == '') {
                        pricingPartIds = item.pricingPartIds
                    }
                }
                if (item.total) {
                    total = total + parseFloat(item.total)
                }
                this.deleteId.push(item.id)
            })
            discount = (discount / dist_len)
            tax = (tax / tax_len)
        }
        this.mergeForm.patchValue({
            quantity,
            totalSalePrice: amount,
            pricingPartIds,
            discount: discount,
            tax: tax,
            total: total
        })
    }

    addeditenable() {
        const value = this.choosedPricingsection['templateCostData']
        const multiVatItemsSelected = this.vatEnabled ? this.checkMultipleVAT(value) : false;
        if (value.length == 1) {
            this.mergeEnable = true
            this.submitEnable = false
        } else if (value.length > 1) {
            if ( multiVatItemsSelected == false ) {
                this.mergeEnable = false;
            } else {
                this.mergeEnable = true;
            }
            this.submitEnable = false
        } else {
            this.mergeEnable = true
            this.submitEnable = true
        }
    }

    mergeCancel() {
        if (!this.merge) {
            this.pricingsection = true;
            $('.modal-body').css({ 'background-color': '#fff' })
        } else {
            this.bsModalRef.hide()
            this.onClose.next(false)
        }
    }

    chooseType(option) {
        this.typeChoosed = option
        this.selectAllTop = false;
        this.selectAllLabour = false;
        this.selectAllParts = false;
        this.selectAllExpenses = false;

        this.selectAllSupplyAndFit = false;
        this.isSearching = false;
        this.choosedPricingsection = { templateCostData: [] }
        if (option == 'supply') {
            this.filteredlabouritems = this.labouritems.filter((i: any) => this.supplyFititems.filter((j: any) => j.id == i.id))
        } else {
            this.filteredlabouritems = this.labouritems
            this.filteredexpensesitems = this.expensesitems
            this.filteredpartsitems = this.partsitems
        }
        this.onSearch(event,'toggleSearch')
        $('#dropdownMenuButton > .dropdown-toggle').dropdown('toggle')
    }

    checkMultipleVAT(items) {
        let vatArray = [];
        for (let i = 0; i < items.length; i++) {
            if (vatArray.indexOf(items[i].tax) === -1) {
                vatArray.push(items[i].tax);
            }
        }
        return vatArray.length > 1;
    }
}
