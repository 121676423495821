<div class="quill-editor-container h-100" [formGroup]="form">
    <quill-editor  *ngIf="lableName=='subject'" id="email-template-{{lableName}}"
                  class="subject-box"
                  (onContentChanged)="onContentChanged($event)"
                  [modules]="modulesSubject"
                  formControlName="{{lableName}}"
                  placeholder="To use tags, type '[' and choose from the list of tags">
    </quill-editor>
    <quill-editor  *ngIf="lableName=='message' && communicatioType!='sms'" id="email-template-{{lableName}}"
                   [modules]="modulesMessage" (onBlur)="blurHandler($event)"
                   (onContentChanged)="onContentChanged($event)"
                   formControlName="{{lableName}}"
                   placeholder="To use tags, type '[' and choose from the list of tags">
    </quill-editor>
    <quill-editor  *ngIf="lableName === 'sms_message' || (lableName=='message' && communicatioType=='sms')" id="email-template-sms-message"
                   [modules]="modulesSubject"
                   formControlName="message"
                   placeholder="To use tags, type '[' and choose from the list of tags">
    </quill-editor>
</div>
