import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { AddOpportunityService } from '@app/features/customers/add-opportunity/service/add-opportunity.service';
import { fontfamilyService } from '@app/shared/services/fontfamily.service';
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { AuthService } from "@app/core";
var TableFormatterOptionsComponent = /** @class */ (function () {
    function TableFormatterOptionsComponent(bsModalRef, opportunityService, fontFamilyService, proposaltemplateservice, authService) {
        this.bsModalRef = bsModalRef;
        this.opportunityService = opportunityService;
        this.fontFamilyService = fontFamilyService;
        this.proposaltemplateservice = proposaltemplateservice;
        this.authService = authService;
        this.listHideShow = false;
        this.horizontalVisibility = false;
        this.breakdown = true;
        this.taxStatus = "withouttax";
        this.any = 0;
        this.breakdownView = 'vertical';
        this.existingValues = [];
        this.existingValuesBackup = [];
        this.tableHeaders = [];
        this.isDisabledHorizentalOrVerticalOption = false;
        this.titleStyleFontFamily = this.fontFamilyService.getFontFamilyArr();
        this.titleStyleFontWeight = [
            { name: 'Bold', value: 600, visibility: true },
            { name: 'Semi-Bold', value: 400, visibility: true },
            { name: 'Medium', value: 300, visibility: false },
            { name: 'Light', value: 100, visibility: false },
        ];
        this.rowStyleFontWeight = [
            { name: 'Bold', value: 600, visibility: true },
            { name: 'Semi-Bold', value: 400, visibility: true },
            { name: 'Medium', value: 300, visibility: false },
            { name: 'Light', value: 100, visibility: false },
        ];
        this.subtotalVisible = 'yes';
        this.showPriceBreakdown = true;
        this.columnShowHide = true;
        this.selectedHeaderFont = "Arial";
        this.seletedRowFont = "Arial";
        this.selectedHeaderFontWeight = 400;
        this.selectedRowFontWeight = 400;
        this.titleSize = 14;
        this.rowSize = 14;
        this.titleHeight = 20;
        this.rowHeight = 20;
        this.titleClr = "No color";
        this.titleBg = "No background";
        this.borderRadius = 0;
        this.borderWidth = 1;
        this.titleAlignment = "left";
        this.rowAlignment = "left";
        this.color_options = {
            type: "component",
            showPalette: false,
            hideAfterPaletteSelect: true,
            showButtons: false,
            allowEmpty: false,
            preferredFormat: "hex",
            replacerClassName: 'format-replace',
            containerClassName: 'container-palette',
            showInput: true,
        };
        this.borderTopLeft = 0;
        this.borderTopRight = 0;
        this.borderBottomRight = 0;
        this.borderBottomLeft = 0;
        this.rowTextClr = "No color";
        this.rowBgClr = "No background";
        this.rowAltClr = "No alternate background";
        this.borderColor = "No border";
        this.borderIndividualSide = false;
        this.inputEnabled = false;
        this.hashId = '';
        //total section advance stling
        this.subtotalHeaderFont = "Arial";
        this.subtotalHeaderFontWeight = 400;
        this.subtotalTitleSize = 14;
        this.subtotalTitleClr = "No color";
        this.subtotalTitleStyleFontWeight = [
            { name: 'Bold', value: 600, visibility: true },
            { name: 'Semi-Bold', value: 400, visibility: true },
        ];
        this.grandtotalHeaderFont = "Arial";
        this.grandtotalHeaderFontWeight = 400;
        this.grandtotalTitleSize = 14;
        this.grandtotalTitleClr = "No color";
        this.grandtotalTitleStyleFontWeight = [
            { name: 'Bold', value: 600, visibility: true },
            { name: 'Semi-Bold', value: 400, visibility: true },
        ];
        this.taxChange = false;
        this.scrollEvent = function (event) {
            $(".sp-container").each(function () {
                if (!$(this).hasClass('sp-hidden')) {
                    $(this).addClass('sp-hidden');
                    $('.pricing-view-title').click();
                }
            });
        };
        $('.sp-container').remove();
    }
    TableFormatterOptionsComponent.prototype.ngOnInit = function () {
        if (this.vatEnabled) {
            this.tableHeaders = [
                { name: 'Quantity', value: '1', checked: true },
                { name: 'Unit price', value: '2', checked: true },
                { name: this.taxLabel, value: '3', checked: true },
                { name: "Total  (" + this.vatType + " " + this.taxLabel + ")", value: '4', checked: true }
            ];
        }
        else {
            this.tableHeaders = [
                { name: 'Quantity', value: '1', checked: true },
                { name: 'Unit price', value: '2', checked: true },
                { name: "Total", value: '4', checked: true }
            ];
        }
        this.isDisabledHorizentalOrVerticalOption;
        this.getAdvancedStylingTemplates();
        this.onClose = new Subject();
        window.addEventListener('scroll', this.scrollEvent, true);
        if (this.existingValues) {
            if (this.existingValues.advancedStyle && !this.existingValues.advancedStyle.hasOwnProperty('save')) {
                if (this.existingValues.advancedStyle.hasOwnProperty('sectionId') && !this.existingValues.advancedStyle.hasOwnProperty('save') || this.existingValues.advancedStyle.hasOwnProperty('currentSectionId')) {
                    this.getUndefinedTableDefultValue();
                }
            }
            this.existingValuesBackup = this.existingValues;
        }
        if (this.configData) {
            this.breakdownView = (this.configData.breakdownView) ? this.configData.breakdownView : 'vertical';
            this.breakdown = (this.configData.breakdownSwitch) ? this.configData.breakdownSwitch : true;
            this.listHideShow = (this.configData.listHideShow) ? this.configData.listHideShow : false;
            if (typeof this.configData.showHideColumns != "undefined") {
                this.showPriceBreakdown = this.configData.showHideColumns;
            }
            // this.taxStatus =  this.configData.tax;
            if (this.configData.visibleColumn) {
                this.tableHeaders = this.configData.visibleColumn;
                if (this.vatEnabled) {
                    this.tableHeaders[2]['name'] = this.taxLabel;
                    this.tableHeaders[3]['name'] = "Total  (" + this.vatType + " " + this.taxLabel + ")";
                }
                else {
                    if (this.tableHeaders[2]['name'] != 'Total') {
                        this.tableHeaders[2] = { name: "Total", value: '4', checked: true };
                        this.tableHeaders[2]['checked'] = this.tableHeaders[3]['checked'];
                        this.tableHeaders.pop(3);
                    }
                    /*this.tableHeaders[2] = {};
                    this.tableHeaders[3]['name'] = `Total`;*/
                }
            }
            if (this.configData.subtotalVisible) {
                this.subtotalVisible = this.configData.subtotalVisible;
            }
        }
        if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.advancedStyle) {
            var styleData = this.existingValues.advancedStyle;
            if (Array.isArray(styleData.borderRadius)) {
                this.borderTopLeft = styleData.borderRadius[0];
                this.borderTopRight = styleData.borderRadius[1];
                this.borderBottomRight = styleData.borderRadius[2];
                this.borderBottomLeft = styleData.borderRadius[3];
                this.borderRadius = 0;
                this.borderIndividualSide = true;
            }
            else {
                this.borderRadius = styleData.borderRadius;
                this.borderTopLeft = 0;
                this.borderTopRight = 0;
                this.borderBottomRight = 0;
                this.borderBottomLeft = 0;
                this.borderIndividualSide = false;
            }
            this.selectedHeaderFont = styleData.selectedHeaderFont;
            this.selectedHeaderFontWeight = styleData.selectedHeaderFontWeight;
            this.titleSize = styleData.titleSize;
            this.titleAlignment = styleData.titleAlignment;
            this.titleHeight = styleData.titleHeight;
            this.titleClr = styleData.titleClr;
            this.titleBg = styleData.titleBg;
            this.seletedRowFont = styleData.seletedRowFont;
            this.selectedRowFontWeight = styleData.selectedRowFontWeight;
            this.rowSize = styleData.rowSize;
            this.rowAlignment = styleData.rowAlignment;
            this.rowHeight = styleData.rowHeight;
            this.rowTextClr = styleData.rowTextClr;
            this.rowBgClr = styleData.rowBgClr;
            this.rowAltClr = styleData.rowAltClr;
            this.borderWidth = styleData.borderWidth;
            this.borderColor = styleData.borderColor;
            this.subtotalHeaderFont = styleData.subtotalHeaderFont || this.subtotalHeaderFont;
            this.subtotalHeaderFontWeight = styleData.subtotalHeaderFontWeight || this.subtotalHeaderFontWeight;
            this.subtotalTitleSize = styleData.subtotalTitleSize || this.subtotalTitleSize;
            this.subtotalTitleClr = styleData.subtotalTitleClr || this.subtotalTitleClr;
            this.grandtotalHeaderFont = styleData.grandtotalHeaderFont || this.grandtotalHeaderFont;
            this.grandtotalHeaderFontWeight = styleData.grandtotalHeaderFontWeight || this.grandtotalHeaderFontWeight;
            this.grandtotalTitleSize = styleData.grandtotalTitleSize || this.grandtotalTitleSize;
            this.grandtotalTitleClr = styleData.grandtotalTitleClr || this.grandtotalTitleClr;
            this.rowFontChange(this.seletedRowFont, false);
            this.headerFontChange(this.selectedHeaderFont, false);
        }
        if (this.breakdownView == "horizontal") {
            this.horizontalVisibility = true;
        }
        else {
            this.horizontalVisibility = false;
        }
        if (this.showPriceBreakdown == true) {
            this.columnShowHide = true;
        }
        else {
            this.columnShowHide = false;
        }
        if (this.isNormalTable) {
            var oldEle = this.save(true);
            var msg = { type: 'normalTableStylingCheck', oldEle: oldEle };
            window.top.postMessage(msg, '*');
        }
        else if (this.isOptionSummary) {
            var oldEle = this.save(true);
            var msg = { type: 'summaryTableStylingCheck', oldEle: oldEle };
            window.top.postMessage(msg, '*');
        }
        if (this.borderColor == 'No border') {
            this.borderColorStyle = '#ffffff';
        }
        else {
            this.borderColorStyle = this.borderColor;
        }
    };
    TableFormatterOptionsComponent.prototype.ngAfterViewInit = function () {
        if (this.editorStatus == 'style') {
            var that = this;
        }
        if ($('#tax2').is(':checked')) {
            this.taxInclusive = true;
        }
        // this.refreshSpectrum();
    };
    TableFormatterOptionsComponent.prototype.refreshSpectrum = function () {
        for (var index = 0; index < 4; index++) {
        }
    };
    TableFormatterOptionsComponent.prototype.rgbaToHex = function (r, g, b, a) {
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];
        // Pad single-digit output values
        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        });
        return ('#' + outParts.join(''));
    };
    TableFormatterOptionsComponent.prototype.rgbToHex = function (rgb) {
        rgb = rgb.trim();
        var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
        var result, r, g, b, hex = "";
        if ((result = rgbRegex.exec(rgb))) {
            r = this.componentFromStr(result[1], result[2]);
            g = this.componentFromStr(result[3], result[4]);
            b = this.componentFromStr(result[5], result[6]);
            hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
        return hex;
    };
    // convert rgb to hex
    TableFormatterOptionsComponent.prototype.componentFromStr = function (numStr, percent) {
        var num = Math.max(0, parseInt(numStr, 10));
        return percent ?
            Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
    };
    TableFormatterOptionsComponent.prototype.renderTable = function () {
        var hiddenColumn = [];
        this.tableHeaders.forEach(function (element, index, columnHeader) {
            if (!element.checked) {
                hiddenColumn.push(element.value);
            }
        });
        var headerData = {
            tableId: 'Pricing_' + this.currentSectionId,
            hiddenValues: hiddenColumn,
        };
        // this.opportunityService.setHandsonColumnFilter(headerData)
    };
    TableFormatterOptionsComponent.prototype.listHideShowToggle = function () {
        this.listHideShow = !this.listHideShow;
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.visibleColumnChange = function (evt, id) {
        if (evt.target.checked) {
            this.tableHeaders[id].checked = true;
        }
        else if (!evt.target.checked) {
            this.tableHeaders[id].checked = false;
        }
        var hiddenColumn = [];
        this.tableHeaders.forEach(function (element, index, columnHeader) {
            if (!element.checked) {
                hiddenColumn.push(element.value);
            }
        });
        // let data = {
        //   tableId: 'Pricing_' + this.currentSectionId,
        //   hiddenValues: hiddenColumn,
        // }
        // this.opportunityService.setHandsonColumnFilter(data)
        // setTimeout(() => {
        //   this.titleRefresh('height');
        // }, 200);
    };
    TableFormatterOptionsComponent.prototype.horizontalVisibilityToggle = function (val) {
        this.breakdownView = val;
        if (val == "horizontal") {
            this.horizontalVisibility = true;
        }
        else {
            this.horizontalVisibility = false;
        }
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.breakdownToggle = function () {
        this.breakdown = !this.breakdown;
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.taxStatusToggle = function (val) {
        this.taxChange = !this.taxChange;
        this.taxStatus = val;
        if (val == 'withtax') {
            this.taxInclusive = true;
            if ($('#yes').is(':checked')) {
                $('#yes').prop('checked', false);
                $('#no').prop('checked', true);
            }
        }
        else {
            this.taxInclusive = false;
            $('#yes').prop('checked', true);
            this.subtotalVisible = 'yes';
            if ($('#no').is(':checked')) {
                $('#yes').prop('checked', true);
                $('#no').prop('checked', false);
            }
        }
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.postWindowsMsg = function () {
        // let breakdownSwitch = this.breakdown;
        // let breakdownView = this.breakdownView;
        // let tax = this.taxStatus;
        // let visibleColumn = this.tableHeaders;
        // let currentTableID = "pricing_cs_component_" + this.currentSectionId;
        // let showHideColumns = this.columnShowHide;
        // let stylingData:any = '';
        // if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
        //   stylingData = this.existingValues.advancedStyle;
        // }
        // else if (this.editorStatus == 'style') {
        //   stylingData = this.formatStylingData();
        // }
        // let options = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow,'isOptionSummary':this.isOptionSummary  };
        // let msg = { type: 'breakdown', options }
        // window.top.postMessage(msg, '*')
        // if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.style != '') {
        //   setTimeout(() => {
        //     this.titleRefresh('height');
        //   }, 300);
        // }
    };
    TableFormatterOptionsComponent.prototype.save = function (getJson) {
        var _this = this;
        if (getJson === void 0) { getJson = false; }
        var taxvalue = "Exclusive";
        if (this.taxStatus == "withtax") {
            taxvalue = "Inclusive";
            this.vatType = 'Inc';
        }
        else {
            this.vatType = 'Exc';
        }
        if (this.vatEnabled) {
            this.tableHeaders[3]['name'] = "Total  (" + this.vatType + " " + this.taxLabel + ")";
        }
        else {
            this.tableHeaders[2]['name'] = "Total";
        }
        var data = {
            tablePkId: this.tablePkId,
            tableType: this.tableType,
            value: taxvalue,
            type: "POST"
        };
        var breakdownSwitch = this.breakdown;
        var breakdownView = this.breakdownView;
        var tax = this.taxStatus;
        var showHideColumns = this.columnShowHide;
        var currentTableID = this.isNormalTable ? this.hashId : this.isOptionSummary == true ? "pricing-items-breakup-tpl_" + this.currentSectionId : "pricing_cs_component_" + this.currentSectionId;
        var visibleColumn = this.tableHeaders;
        var stylingData = '';
        var msg = { type: 'saveChanges' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
        if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
            stylingData = this.existingValues.advancedStyle;
        }
        else if (this.editorStatus == 'style') {
            stylingData = this.formatStylingData();
        }
        var options = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData, 'isOptionSummary': this.isOptionSummary, 'isNormalTable': this.isNormalTable, 'taxData': data, 'editorStatus': this.editorStatus };
        if (getJson) {
            return options;
        }
        else {
            options.save = true;
            var msg_1 = { type: 'breakdown', options: options, taxChange: this.taxChange };
            this.taxChange = false;
            window.top.postMessage(msg_1, '*');
            setTimeout(function () {
                _this.bsModalRef.hide();
            }, 300);
        }
        if (this.isOptionSummary) {
            this.saveTemplate();
        }
        // this.taxChange = false;
        this.proposaltemplateservice.setEditorThumbnail('updateTableElement');
    };
    TableFormatterOptionsComponent.prototype.radiusAllSide = function () {
        this.borderIndividualSide = !this.borderIndividualSide;
        if (this.borderIndividualSide) {
            $('.all-side-border-radius').addClass('selected-style');
            this.borderTopLeft = this.borderTopRight = this.borderBottomRight = this.borderBottomLeft = 0;
        }
        else {
            this.borderRadius = 0;
            $('.all-side-border-radius').removeClass('selected-style');
        }
        this.borderRefresh();
    };
    TableFormatterOptionsComponent.prototype.subtotalStatusChange = function (val) {
        this.subtotalVisible = val;
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.priceTableBreakdown = function (val) {
        this.showPriceBreakdown = val;
        if (val == "withtblbreakdown") {
            this.columnShowHide = true;
        }
        else {
            this.columnShowHide = false;
        }
        this.postWindowsMsg();
    };
    TableFormatterOptionsComponent.prototype.titleAlignContent = function (alignment, position) {
        $('.align-font').css({ opacity: '50%' });
        $('.title-set-remove-bg').removeClass("switch-color-content-alignment");
        $('.' + position + '').addClass("switch-color-content-alignment");
        this.titleAlignment = alignment;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.rowAlignContent = function (alignment, position) {
        $('.align-font').css({ opacity: '50%' });
        $('.row-set-remove-bg').removeClass("switch-color-content-alignment");
        $('.' + position + '').addClass("switch-color-content-alignment");
        this.rowAlignment = alignment;
        this.rowRefresh();
    };
    TableFormatterOptionsComponent.prototype.headerFontChange = function (value, status) {
        if (status === void 0) { status = true; }
        if (['Caveat', 'Comfortaa', 'EB Garamond', 'Lora', 'Merriweather', 'Montserrat', 'Nunito', 'Oswald',
            'Playfair Display', 'Roboto', 'Roboto Mono', 'Spectral'].includes(value)) {
            this.titleStyleFontWeight[2].visibility = true;
            this.titleStyleFontWeight[3].visibility = true;
        }
        else {
            if (status) {
                this.selectedHeaderFontWeight = 400;
            }
            this.titleStyleFontWeight[2].visibility = false;
            this.titleStyleFontWeight[3].visibility = false;
        }
        this.selectedHeaderFont = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.subtotalFontChange = function (value, status) {
        if (status === void 0) { status = true; }
        if (['Caveat', 'Comfortaa', 'EB Garamond', 'Lora', 'Merriweather', 'Montserrat', 'Nunito', 'Oswald',
            'Playfair Display', 'Roboto', 'Roboto Mono', 'Spectral'].includes(value)) {
            this.subtotalTitleStyleFontWeight[0].visibility = true;
            this.subtotalTitleStyleFontWeight[1].visibility = true;
        }
        else {
            if (status) {
                this.subtotalHeaderFontWeight = 400;
            }
        }
        this.subtotalHeaderFont = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.subtotalFontWeightChange = function (value) {
        this.subtotalHeaderFontWeight = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.grandtotalFontChange = function (value, status) {
        if (status === void 0) { status = true; }
        if (['Caveat', 'Comfortaa', 'EB Garamond', 'Lora', 'Merriweather', 'Montserrat', 'Nunito', 'Oswald',
            'Playfair Display', 'Roboto', 'Roboto Mono', 'Spectral'].includes(value)) {
            this.grandtotalTitleStyleFontWeight[0].visibility = true;
            this.grandtotalTitleStyleFontWeight[1].visibility = true;
        }
        else {
            if (status) {
                this.grandtotalHeaderFontWeight = 400;
            }
        }
        this.grandtotalHeaderFont = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.grandtotalFontWeightChange = function (value) {
        this.grandtotalHeaderFontWeight = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.headerFontWeightChange = function (value) {
        this.selectedHeaderFontWeight = value;
        this.titleRefresh();
    };
    TableFormatterOptionsComponent.prototype.rowFontChange = function (value, status) {
        if (status === void 0) { status = true; }
        if (['Caveat', 'Comfortaa', 'EB Garamond', 'Lora', 'Merriweather', 'Montserrat', 'Nunito', 'Oswald',
            'Playfair Display', 'Roboto', 'Roboto Mono', 'Spectral'].includes(value)) {
            this.rowStyleFontWeight[2].visibility = true;
            this.rowStyleFontWeight[3].visibility = true;
        }
        else {
            if (status) {
                this.selectedRowFontWeight = 400;
            }
            this.rowStyleFontWeight[2].visibility = false;
            this.rowStyleFontWeight[3].visibility = false;
        }
        this.seletedRowFont = value;
        this.rowRefresh();
    };
    TableFormatterOptionsComponent.prototype.rowFontWeightChange = function (value) {
        this.selectedRowFontWeight = value;
        this.rowRefresh();
    };
    TableFormatterOptionsComponent.prototype.sendChangesToEditor = function () {
        var data = this.formatStylingData();
        var msg = { type: 'changeSectionStyling', style: data };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
    };
    TableFormatterOptionsComponent.prototype.titleRefresh = function (status) {
        if (status === void 0) { status = ''; }
        this.sendChangesToEditor();
    };
    TableFormatterOptionsComponent.prototype.rowRefresh = function () {
        this.sendChangesToEditor();
    };
    TableFormatterOptionsComponent.prototype.borderRefresh = function () {
        this.titleRefresh('border');
    };
    TableFormatterOptionsComponent.prototype.getColor = function (data, type) {
        if (type == 'titleTextColor') {
            this.titleClr = data;
            this.titleRefresh();
        }
        else if (type == 'titleBackgroundColor') {
            this.titleBg = data;
            this.titleRefresh();
        }
        else if (type == 'rowTextColor') {
            this.rowTextClr = data;
            this.rowRefresh();
        }
        else if (type == 'rowBackgroundColor') {
            this.rowBgClr = data;
            this.rowRefresh();
        }
        else if (type == 'rowAlternateColor') {
            this.rowAltClr = data;
            this.rowRefresh();
        }
        else if (type == 'subtotal') {
            this.subtotalTitleClr = data;
            this.rowRefresh();
        }
        else if (type == 'grandtotal') {
            this.grandtotalTitleClr = data;
            this.rowRefresh();
        }
        else {
            this.borderColor = data;
            this.borderColorStyle = data;
            this.borderRefresh();
        }
    };
    TableFormatterOptionsComponent.prototype.formatStylingData = function () {
        var borderRadius = this.borderRadius;
        if (this.borderIndividualSide) {
            borderRadius = [this.borderTopLeft, this.borderTopRight, this.borderBottomRight, this.borderBottomLeft];
        }
        var data = {
            selectedHeaderFont: this.selectedHeaderFont,
            selectedHeaderFontWeight: this.isNormalTable && this.existingValues == null ? 600 : this.selectedHeaderFontWeight,
            titleSize: this.isNormalTable && this.existingValues == null ? 16 : this.titleSize,
            titleAlignment: this.isNormalTable && this.existingValues == null ? 'center' : this.titleAlignment,
            titleHeight: this.titleHeight,
            titleClr: this.titleClr,
            titleBg: this.titleBg,
            seletedRowFont: this.seletedRowFont,
            selectedRowFontWeight: this.selectedRowFontWeight,
            rowSize: this.rowSize,
            rowAlignment: this.isNormalTable && this.existingValues == null ? 'center' : this.rowAlignment,
            rowHeight: this.rowHeight,
            rowTextClr: this.rowTextClr,
            rowBgClr: this.rowBgClr,
            rowAltClr: this.rowAltClr,
            borderWidth: this.borderWidth,
            borderRadius: borderRadius,
            borderColor: this.borderColor,
            subtotalHeaderFont: this.subtotalHeaderFont,
            subtotalHeaderFontWeight: this.subtotalHeaderFontWeight,
            subtotalTitleSize: this.subtotalTitleSize,
            subtotalTitleClr: this.subtotalTitleClr,
            grandtotalHeaderFont: this.grandtotalHeaderFont,
            grandtotalHeaderFontWeight: this.grandtotalHeaderFontWeight,
            grandtotalTitleSize: this.grandtotalTitleSize,
            grandtotalTitleClr: this.grandtotalTitleClr,
            save: true,
            currentSectionId: this.isNormalTable ? '' : this.currentSectionId,
            hashId: this.hashId,
            isNormalTable: this.isNormalTable,
            optionSummary: this.isOptionSummary ? true : false,
            sectionId: this.isNormalTable ? '' : this.currentSectionId,
        };
        return data;
    };
    TableFormatterOptionsComponent.prototype.getUndefinedTableDefultValue = function () {
        var getUndefineStyleData = this.formatStylingData();
        this.existingValues = {
            'advancedStyle': getUndefineStyleData
        };
        // this.selectedHeaderFont = getUndefineStyleData['selectedHeaderFont'];
        // this.selectedHeaderFontWeight = getUndefineStyleData['selectedHeaderFontWeight'];
    };
    TableFormatterOptionsComponent.prototype.cancel = function () {
        var msg = { type: 'revertAppliedStyles', sectionId: this.currentSectionId, tableType: this.isOptionSummary ? 'optionSummary' : 'pricingSection' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
        var data = this.save(true);
        this.onClose.next(data);
        this.bsModalRef.hide();
    };
    TableFormatterOptionsComponent.prototype.getCheckValue = function (value) {
        if (value) {
            this.inputEnabled = true;
        }
        else {
            this.inputEnabled = false;
        }
    };
    TableFormatterOptionsComponent.prototype.saveTemplate = function () {
        var _this = this;
        var stylingData = '';
        stylingData = this.formatStylingData();
        var options = {
            "templateName": this.templateName,
            "advancedStyle": stylingData,
            "currentSectionId": this.currentSectionId,
        };
        options.save = true;
        this.opportunityService.addAdvancedStylingTemplate(options).subscribe(function (resp) {
            if (resp['message'] == 'success') {
                setTimeout(function () {
                    _this.bsModalRef.hide();
                }, 300);
            }
        });
    };
    TableFormatterOptionsComponent.prototype.getAdvancedStylingTemplates = function () {
        var getAdvanceStylingPermission = this.authService.permitted(['AdvancedStyling.readaccess']) === true;
        if (getAdvanceStylingPermission) {
            this.opportunityService.getAdvancedStylingTemplate().subscribe(function (data) {
            });
        }
    };
    TableFormatterOptionsComponent.prototype.defaultColor = function (event) {
        var value = Number(event.target.value);
        if (value > 1 && this.borderColor == 'No border') {
            this.borderColor = '#595B65';
            this.borderRefresh();
            this.borderColorStyle = this.borderColor;
        }
        else if (value <= 1 && this.borderColor == '#595B65') {
            this.borderColor = 'No border';
            this.borderRefresh();
            this.borderColorStyle = '#ffffff';
        }
        else {
            return;
        }
    };
    TableFormatterOptionsComponent.prototype.detectChanges = function (data, event, tableType, actionType) {
        if (actionType === void 0) { actionType = ''; }
        var that = this;
        this.myValue = '';
        setTimeout(function () {
            that.myValue = data;
        }, 50);
        if ((actionType == 'grandtotal' && !tableType) || (actionType == 'borderColor' && tableType)) {
            this.openColorPalatePosition(event.srcElement);
        }
    };
    TableFormatterOptionsComponent.prototype.openColorPalatePosition = function (htmlelement) {
        var nextElementPopup = $(htmlelement).next().get(0);
        $(nextElementPopup).css('top', "-" + 458 + "px");
    };
    TableFormatterOptionsComponent.prototype.checkInputvalue = function (event, type) {
        var _this = this;
        var inputValue = event.target.value;
        if (inputValue < 0 || inputValue > 20) {
            event.preventDefault();
            setTimeout(function () {
                if (type == 'grandtotal') {
                    _this.grandtotalTitleSize = 20;
                }
                else if (type == 'subtotal') {
                    _this.subtotalTitleSize = 20;
                }
                _this.titleRefresh();
            }, 50);
        }
    };
    TableFormatterOptionsComponent.prototype.handleInput = function (event) {
        var _this = this;
        var value = +event.target.value;
        if (value < 60) {
            setTimeout(function () {
                _this.rowHeight = 60;
            }, 50);
        }
    };
    return TableFormatterOptionsComponent;
}());
export { TableFormatterOptionsComponent };
