import * as tslib_1 from "tslib";
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { InterfaceResolver } from '@app/interfaces';
import { getUrlParams } from '@app/utils';
var DataResolver = /** @class */ (function (_super) {
    tslib_1.__extends(DataResolver, _super);
    function DataResolver(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    DataResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var routeData = route.data;
        var routeParams = getUrlParams(route);
        // Validating configuration
        if (!('DataResolver' in routeData)) {
            console.error('DataResolver configuration is not provided');
            return this.fail();
        }
        if (!('url' in routeData['DataResolver'])) {
            console.error('There is no url provided to fetch resolver data in DataResolver');
            return this.fail();
        }
        var url = routeData['DataResolver']['url'], responseParser = routeData['DataResolver']['parseResponse'] instanceof Function ? routeData['DataResolver']['parseResponse'] : this.parseResponse, paramDef = {};
        // Patching url params
        if (routeData['DataResolver']['params'] instanceof Object) {
            paramDef = routeData['DataResolver']['params'];
        }
        try {
            Object.entries(paramDef).forEach(function (_a) {
                var param = _a[0], valueLookup = _a[1];
                if (!(valueLookup in routeParams))
                    throw Error("{param} no found in url");
                url = url.replace("{" + param + "}", routeParams[valueLookup]);
            });
        }
        catch (_a) {
            return this.fail();
        }
        // Data fetch call
        return this.http
            .get(this.getApiUrl(url))
            .pipe(map(function (Response) { return responseParser(Response); }), catchError(function (Err) {
            return _this.fail();
        }));
    };
    DataResolver.prototype.parseResponse = function (data) { return data; };
    return DataResolver;
}(InterfaceResolver));
export { DataResolver };
