<ng-container *ngIf="!isLoading; else: templateFormLoading">
    <div class="cs-template-form-row cs-d-flex flex-column h-100 pos-rlt">
        <div *ngIf="roleList.length">
            <div class="cs-d-flex flex-column cs-template-form-container p-b&#45;&#45;0">
                <div class="cs-form-title m-b--5">
                    Roles
                </div>
                <div class="cs-form-input m-b--5">
                    <!-- <input type="hidden" csSelect2
                    [select2Config]="select2TemplateConfig" [multiple]="true" [placeholder]="'-- Please select --'"
                    class="chosen-select full-width-select"> -->

                    <select class="notification-roles" csSelect2 [select2Config]="select2TemplateConfig" [multiple]="true"
                            (selected)="selectedRoles($event)" [(ngModel)]="previousRolesList">
                        <option value="" [disabled]='true' translate>Select role</option>
                        <option *ngFor="let role of roleList" value="{{role.id}}">{{role.text}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div *access="['SetupEmailTemplates.writeaccess']">
            <div class="cs-d-flex flex-column cs-template-form-container p-b&#45;&#45;0">
                <div class="cs-form-title m-b--5 title-group">
                    Email
                    <span class="ml-auto" [style.cursor]="(emailScreenLabel === selectedEmailTemplate) || viewMode ? 'not-allowed' : 'auto'"><a (click)="clearConfirmation('email')"
                        [class.disable] = "(emailScreenLabel === selectedEmailTemplate) || viewMode">Clear</a></span>
                </div>
                <div class="cs-form-input m-b--5">
                    <app-search-template [screen]="'email_template'" [screenLabel]="emailScreenLabel"
                                         [showAddNewTemplate]="!viewMode" [selectedTemplate]="selectedEmailTemplate"
                                         [viewMode]="viewMode"(selectedItem)="setTemplateDetails($event,'email')"
                                         [isArchived]="isSelectedEmailTemplateArchived"
                    ></app-search-template>
                </div>
            </div>
        </div>

        <ng-container *ngIf="smsModulePermission">
            <div *access="['SetupSMSTemplates.writeaccess']">
                <div class="cs-d-flex flex-column cs-template-form-container">
                    <div class="cs-form-title m-b--5 title-group">
                        SMS
                        <span class="ml-auto" [style.cursor]="(smsScreenLabel === selectedSmsTemplate) || viewMode ? 'not-allowed' : 'auto'">
                            <a (click)="clearConfirmation('sms')" [class.disable] = "(smsScreenLabel === selectedSmsTemplate) || viewMode">Clear</a>
                        </span>
                    </div>
                    <app-search-template [screen]="'sms_template'" [screenLabel]="smsScreenLabel"
                                         [showAddNewTemplate]="!viewMode" [selectedTemplate]="selectedSmsTemplate"
                                         [viewMode]="viewMode"
                                         (selectedItem)="setTemplateDetails($event,'sms')"
                                         [isArchived]="isSelectedSmsTemplateArchived"
                    ></app-search-template>
                </div>
            </div>
        </ng-container>

        <!-- <div *access="['SetupLetterTemplates.writeaccess']">
            <div class="cs-d-flex flex-column cs-template-form-container">
                <div class="cs-form-title  m-b--5">
                    Letter
                </div>
                <app-search-template [screen]="'letter_template'" [screenLabel]="letterScreenLabel"
                [showAddNewTemplate]="'true'" [selectedTemplate]="selectedLetterTemplate"
                (selectedItem)="setTemplateDetails($event,'letter')"></app-search-template>
            </div>
        </div> -->
    </div>
</ng-container>


<ng-template #templateFormLoading>
    <div class="cs-template-form-row cs-d-flex flex-column h-100 pos-rlt" *ngIf="isLoading">
        <div *ngFor="let item of [1,2,3];" class="m-b--15">
            <div class="cs-d-flex flex-column cs-template-form-container p-b--0">
                <div class="cs-form-title m-b--5">
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row">
                                <div class="ph-col-4 m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cs-form-input m-b--5">
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row">
                                <div class="ph-col-12 big m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row">
                                <div class="ph-col-6 sm m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
