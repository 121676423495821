var ProgressSidePanelModule = /** @class */ (function () {
    function ProgressSidePanelModule() {
    }
    ProgressSidePanelModule.forRoot = function () {
        return {
            ngModule: ProgressSidePanelModule
        };
    };
    return ProgressSidePanelModule;
}());
export { ProgressSidePanelModule };
