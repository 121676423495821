<div class="with-left-sidbar main-field main-sequence-wrapper" [ngClass]="{'cs-sequence-config': this.mode=='sequence_config'}">
    <div #mx_container class="mx-container pos-abt-full cur-grab" style="overflow:hidden"></div>
    <div #outlineContainer
         style="z-index:1;
         position:absolute;
         overflow:hidden;top:10px;right:10px;width:160px;height:120px;
         background:#fff;border-style:solid;border-color:lightgray;" class="mx-graph-outline-container">
    </div>
</div>

<cs-modal [show]="showConfirmation"  (onClose)="null" class="cs-sequence-confirmation-modal">
    <ng-container>
        <!-- Modal header -->
        <div class="modal-header">
            <button type="button" class="close hide">×</button>
            <h4 *ngIf="confirmationAction=='skip'" translate>Skip.Sequence.Step</h4>
            <h4 *ngIf="confirmationAction=='undo'" translate>Undo.Sequence.Step</h4>
        </div>
        <ng-container>
            <!-- Modal body -->
            <div class="modal-body ">
                <div class="row-fluid" *ngIf="confirmationAction=='skip'">
                    <p translate>Skip.Description</p>
                    <p translate>Skip.Confirmation.Text</p>
                </div>
                <div class="row-fluid" *ngIf="confirmationAction=='undo'">
                    <p translate>Undo.Description</p>
                    <p translate>Undo.Confirmation.Text</p>
                </div>
                <div class="input-prepend box-shadow input-fullwidth row-fluid">
                    <span class="add-on">
                      <i class="fas fa-stop-circle"></i>
                    </span>
                    <div class="input-wrapper">
                        <input type="text" class="modalDeleteNumber" id="confirm_skip_or_undo_input" [(ngModel)]="confirmTextInputModel" (keyup)="checkConfirmText()" (keyup.enter)="setConfirmSkipUndo()" />
                    </div>
                </div>
                <div>
                    <span class="control-error-message show row-fluid" *ngIf="inValidConfirmText">
                        <span translate [translateParams]="{Object: confirmText}"> You.need.to.type.the.word.Object</span>
                    </span>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <div>
                    <a href="javascript:void(0)" (click)="onCloseConfirmation()"  class="btn btn-cancel" translate>Cancel</a>
                    <button (click)="setConfirmSkipUndo()" type="button" class="btn btn-primary btn-medium" [disabled]='inValidConfirmText || confirmTextInputModel==""' translate>{{ confirmationAction=='skip' ? 'Skip' : 'Undo'}}</button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</cs-modal>
