<!-- Content -->
<div class="modal-body modal-height-increase" id="scroll-container1">
    <!--<div class="help_section" style="padding: 30px;">-->
    <!--<div class="help-text">-->
    <!--</div>-->
    <!--</div>-->
    <div class="help_section" style="">
        <div class="title-section-alignment">
            <h4 class="categories-title-content">
                <a href="javascript:void(0)" *ngIf="(category =='' && subcategory=='') && view=='cart'"
                   (click)="showCategories()">Categories</a>
                <span *ngIf="(category =='' && subcategory=='') && view=='categories' " class="categories-title-text-clr">Categories</span>
                <span *ngIf="view=='cart'">&nbsp;>&nbsp;Basket</span>
                <div style="font-size:14px;color:#505B65;cursor:pointer;">
                    <a href="javascript:void(0)" (click)="showCategories()" *ngIf="category !=''">{{ category }} </a> <span
                    *ngIf="category !='' && subcategory ==''"> > View sub-categories</span>
                    <span class="sub-categories-colour" href="javascript:void(0)" *ngIf="subcategory !=''"
                          (click)="onSubBreadClick()"> > {{ subcategory }} <span class="search-sub-category-text"> > Search
                    results </span> </span>
                </div>
            </h4>
        </div>
    </div>
    <div class="help_section">
        <input type="search" id="catalogue_search" placeholder="Search" [(ngModel)]="searchValue" appDebounceTimer
            (valueChanged)="onSearch($event)" style="width: 483px; margin: 0; height: 28px;padding: 0 5px;" />
    </div>
    <div class="body-content-section body-content-parts-category" style="padding: 0px !important;">
        <div class="row-fluid" *ngIf="!loading">
            <div class="span12">
                <div class="divSlides padding-slider" *ngIf="view=='categories'">
                    <div *ngIf="filteredCategorylist.length" class="grid-container"
                        style="display: flex; flex-wrap: wrap">
                        <ng-container *ngFor="let category of filteredCategorylist">
                            <div class="img-container" (click)="onChangeCategory(category)">
                                <img *ngIf="category['imageUrl']" src={{category?.imageUrl}}
                                    class="image-block addPartSildeImages" />
                                <img *ngIf="!category['imageUrl']" src="images/noImage.png"
                                    class="image-block addPartSildeImages" />
                                <div style="text-align: center">
                                    <label class="part-overflow">{{ category?.categoriesDescription }}</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!filteredCategorylist.length" class="grid-container"
                        style="display: flex; flex-wrap: wrap; padding: 5px;">
                        <label>No records found</label>
                    </div>
                </div>
                <div class="m-20 divSlide" *ngIf="view=='subcategories'">
                    <div *ngIf="filteredSubCategorylist.length" class="grid-container"
                        style="display: flex; flex-wrap: wrap">
                        <ng-container *ngFor="let subcategory of filteredSubCategorylist">
                            <div class="img-container" style="cursor: pointer;"
                                (click)="selectSubcategory(subcategory)">
                                <img *ngIf="subcategory['imageUrl']" src={{subcategory?.imageUrl}}
                                    class="image-block addPartSildeImages" />
                                <img *ngIf="!subcategory['imageUrl']" src="images/noImage.png"
                                    class="image-block addPartSildeImages" />
                                <div style="text-align: center">
                                    <label class="part-overflow">{{ subcategory?.subCategoryDescription }}</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!filteredSubCategorylist.length" class="grid-container"
                        style="display: flex; flex-wrap: wrap;padding: 5px;">
                        <label>No records found</label>
                    </div>
                </div>
                <div class="m-20 divSlide" *ngIf="view=='parts'" style="padding: 0 !important;">
                    <div *ngIf="filteredSelectedParts.length && !loading" class="grid-container"
                        style="display: flex; flex-wrap: wrap">
                        <ng-container *ngFor="let part of filteredSelectedParts; let ind = index">
                            <div class="row-fluid part-full-block">
                                <div class="part-image-block">
                                    <img id="{{ part['id'] }}" *ngIf="part['partImage']"
                                        src={{part?.partImage?.location}} class="part-section-image"
                                        (click)="selectedPart(part)" />
                                    <img *ngIf="!part['partImage']" src="images/noImage.png" class="part-section-image"
                                        (click)="selectedPart(part)" />
                                </div>
                                <div class="Content-block">
                                    <a href="javascript:void(0)">{{ part?.description }}</a>
                                    <!-- <span>dimension</span> -->
                                    <!--<span>Product code:</span>-->
                                    <br>
                                    <span>Cost price: {{ part?.price | currency }}</span>
                                </div>
                                <div class="add-count-btns">
                                    <button class="part-remove-btn" id="decrementbutton"
                                        (click)="removeCart(part,ind); " [disabled]="part.count == 0">-</button>
                                    <input name="inputbox" id="quantity-input-{{ind}}" min="0"  max="99999" type="number" pattern="[0-9]+"
                                        class="quantity-input input-text-box" [(ngModel)]="part.count"
                                        (ngModelChange)="addQuantity(part,ind)"
                                        (keypress)="omitSpecialChar($event,ind)"  (keydown)="keydown($event,ind)">
                                    <!--<span class="part-count-block">{{part?.count}}</span>                                       -->
                                    <button class="part-add-btn" id="incrementbutton"
                                        (click)="addCart(part,ind); ">+</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!filteredSelectedParts.length && !loading" class="grid-container"
                        style="display: flex; flex-wrap: wrap;padding: 20px;">
                        <label>No records found</label>
                    </div>
                    <div *ngIf="loading" class="grid-container" style="display: flex; flex-wrap: wrap;padding: 20px;">
                        <label style="width: 100%; text-align: center;">Loading...</label>
                    </div>
                </div>
                <div class="m-20 divSlide" *ngIf="view=='cart'" style="padding: 0 !important;">
                    <div *ngIf="totalPartsList.length && !loading" class="grid-container"
                        style="display: flex; flex-wrap: wrap">
                        <ng-container *ngFor="let part of totalPartsList; let ind = index">
                            <div class="row-fluid part-full-block">
                                <div class="part-image-block">
                                    <img id="{{ part['id'] }}" *ngIf="part['partImage']"
                                        src={{part?.partImage?.location}} class="part-section-image"
                                        (click)="selectedPart(part)" />
                                    <img *ngIf="!part['partImage']" src="images/noImage.png" class="part-section-image"
                                        (click)="selectedPart(part)" />
                                </div>
                                <div class="Content-block">
                                    <a href="javascript:void(0)">{{ part?.description }}</a>
                                    <br>
                                    <!-- <span>dimension</span> -->
                                    <!--<span>Product code:</span>-->
                                    <span>Cost price: {{ part?.price | currency }}</span>
                                </div>
                                <div class="add-count-btns">
                                    <input  id="quantity-input-{{ind}}" min="0"  max="99999" type="number" pattern="[0-9]+"
                                           class="quantity-input input-text-box" value={{part?.count}}
                                           (keyup)="addCartQuantity($event,part,ind)"  (keydown)="keydown($event,ind)"
                                           (keypress)="omitSpecialChar($event,ind)" >
                                    <div style="margin-top: 5px;">
                                        <a href="javascript:void(0)" (click)="deleteCart(ind, part)">Delete</a>
                                    </div>
                                    <!-- <span class="part-add-btn"(click)="addCart(part)">+</span> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!totalPartsList.length && !loading" class="grid-container"
                        style="display: flex; flex-wrap: wrap;padding: 20px;">
                        <label>No records found</label>
                    </div>
                </div>
            </div>
        </div>
        <!--loading  block start-->
        <div *ngIf="loading && view=='categories'" class="grid-container" style="display: flex; flex-wrap: wrap;">
            <!--<label style="width: 100%; text-align: center;">Loading...</label>-->
            <div class="divSlide">
                <div class="grid-container" style="display: flex; flex-wrap: wrap">
                    <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
                        <div class="img-container animated-background">
                            <img src="images/noImage.png" class="image-block addPartSildeImages" />
                            <div style="text-align: center">
                                <label class="part-overflow"></label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!--Loading block end-->
        </div>
        <div *ngIf="loading && view=='parts'" class="row-fluid">
            <div class="parts-loader animated-background" *ngFor="let i of [1,2,3,4,5,6]">
            </div>
        </div>
    </div>
</div>
<!-- Footer -->
<div class="modal-footer footer-padding">
    <div style="float:left; cursor: pointer;" [hidden]="loading" (click)="viewCart()">
        <div>{{totalprice | currency}}</div>
        <div class="fa fa-shopping-cart cart-icon"></div>
        <span class="total-items">{{ TotalSumofQuantity }} items</span>
    </div>
    <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
    <button [hidden]="loading" class="btn btn-primary" [disabled]="TotalSumofQuantity < 1 || saving"
        (click)="saveCategories()">
        <span [hidden]="saving" translate>Save</span>
        <span [hidden]="!saving" translate>Saving</span>
    </button>
</div>
