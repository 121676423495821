import { OnInit, KeyValueDiffer, OnDestroy, NgZone, KeyValueDiffers, ElementRef, OnChanges, SimpleChanges, DoCheck, EventEmitter, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subject, fromEvent } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { CS_ScrollbarEvent, CsScrollbarConfig, CsScrollbarConfigInterface, CsScrollbarEvents } from "@app/directives/cs-scrollbar/cs-scrollbar-config-interface";
var CsScrollbarDirective = /** @class */ (function () {
    function CsScrollbarDirective(ngZone, differs, renderer, elementRef, platformId, defaultConfig) {
        this.ngZone = ngZone;
        this.differs = differs;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.platformId = platformId;
        this.defaultConfig = defaultConfig;
        this.timeout = null;
        this.animation = null;
        this.instance = null;
        this.configDiff = null;
        this.ngDestroy = new Subject();
        this.disabled = false;
        this.psScrollY = new EventEmitter();
        this.psScrollX = new EventEmitter();
        this.psScrollUp = new EventEmitter();
        this.psScrollDown = new EventEmitter();
        this.psScrollLeft = new EventEmitter();
        this.psScrollRight = new EventEmitter();
        this.psYReachEnd = new EventEmitter();
        this.psYReachStart = new EventEmitter();
        this.psXReachEnd = new EventEmitter();
        this.psXReachStart = new EventEmitter();
    }
    CsScrollbarDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.disabled && isPlatformBrowser(this.platformId)) {
            this.renderer.addClass(this.elementRef.nativeElement, 'pos-rlt');
            var config_1 = new CsScrollbarConfig(this.defaultConfig);
            config_1.assign(this.config);
            this.ngZone.runOutsideAngular(function () {
                _this.instance = new PerfectScrollbar(_this.elementRef.nativeElement, config_1);
                if (_this.zIndex) {
                    _this.renderer.setStyle(_this.elementRef.nativeElement.querySelector('.ps__rail-x'), 'z-index', _this.zIndex);
                    _this.renderer.setStyle(_this.elementRef.nativeElement.querySelector('.ps__rail-y'), 'z-index', _this.zIndex);
                }
            });
            if (!this.configDiff) {
                this.configDiff = this.differs.find(this.config || {}).create();
                this.configDiff.diff(this.config || {});
            }
            setTimeout(function () {
                if (_this.instance)
                    _this.instance.update();
            }, 1000);
        }
        this.ngZone.runOutsideAngular(function () {
            CsScrollbarEvents.forEach(function (eventName) {
                var eventType = eventName.replace(/([A-Z])/g, function (c) { return "-" + c.toLowerCase(); });
                fromEvent(_this.elementRef.nativeElement, eventType)
                    .pipe(auditTime(10), takeUntil(_this.ngDestroy))
                    .subscribe(function (event) {
                    _this[eventName].emit(event);
                });
            });
        });
    };
    CsScrollbarDirective.prototype.ngOnDestroy = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.ngDestroy.next();
            this.ngDestroy.complete();
            if (this.timeout && typeof window !== 'undefined') {
                window.clearTimeout(this.timeout);
            }
            this.ngZone.runOutsideAngular(function () {
                if (_this.instance) {
                    _this.instance.destroy();
                }
            });
            this.instance = null;
        }
    };
    CsScrollbarDirective.prototype.ngOnChanges = function (changes) {
        if (changes['disabled'] && !changes['disabled'].isFirstChange() && isPlatformBrowser(this.platformId)) {
            if (changes['disabled'].currentValue !== changes['disabled'].previousValue) {
                if (changes['disabled'].currentValue === true) {
                    this.ngOnDestroy();
                }
                else if (changes['disabled'].currentValue === false) {
                    this.ngOnInit();
                }
            }
        }
    };
    CsScrollbarDirective.prototype.ngDoCheck = function () {
        if (!this.disabled && this.configDiff && isPlatformBrowser(this.platformId)) {
            var changes = this.configDiff.diff(this.config || {});
            if (this.instance)
                this.instance.update();
            if (changes) {
                this.ngOnDestroy();
                this.ngOnInit();
            }
        }
    };
    return CsScrollbarDirective;
}());
export { CsScrollbarDirective };
