import { Injectable } from '@angular/core';
import {Subject} from "rxjs/internal/Subject";

@Injectable({
  providedIn: 'root'
})
//common sidepanel services, if you want anyone use this services
export class SidepanelService {

    modalClosed = new Subject<boolean>();
    pagePropertiesSidePanel = new Subject<boolean>();
    onCloseSidePanel = new Subject<boolean>();
  constructor() { }

    OpportunityModalClose(){
      this.modalClosed.next(true);
    }

    closePagePropertiesSidePanel(){
      this.pagePropertiesSidePanel.next(true)
    }

    onCloseSidePanelModal(){
      this.onCloseSidePanel.next(true);
    }


}
