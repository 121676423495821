import { UpgradeModule } from '@angular/upgrade/static';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { NgZone } from '@angular/core';

//--
import { HybridFactory } from "@app/core/hybrid-factory";

export function setUpLocationSync(ngUpgrade: UpgradeModule) {
  if (!ngUpgrade.$injector) {
    throw new Error("\n" +
      "        RouterUpgradeInitializer can be used only after UpgradeModule.bootstrap has been called.\n" +
      "        Remove RouterUpgradeInitializer and call setUpLocationSync after UpgradeModule.bootstrap.\n" +
      "      ");
  }

  let location = ngUpgrade.injector.get(Location),
    $state = HybridFactory.ngUpgrade.$injector.get('$state');

  // Hooking into popstate events
  location.subscribe((event) => {

    if (event.type == 'popstate') {
      if (!event.url) return true;

      HybridFactory.popstate = event.url;

      // Forcing back in tha case, trying to get back to Angular7 from AngularJs. It was struck for the first back click, As it tries to navigate to the same AngularJs url on the first click
      if (!HybridFactory.isUpgraded(event.url) && $state.href($state.current.name) == event.url) {
        history.back();
      }
      return false;

    } else return true;
  });

}
