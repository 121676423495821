import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { OptionTemplateService } from '@app/features/system-settings/option-template/services/option-template.service';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { ArticleEditorService } from "@app/article-editor/article-editor.service";
import { FileUploader } from "ng2-file-upload";
import { FolderService } from "@app/shared/common-folder-structure/service/folder.service";
import { AuthService } from "@app/core";
var AddOptionModalComponent = /** @class */ (function () {
    function AddOptionModalComponent(fb, bsModalRef, optionTemplateService, articleservice, proposalTemplateService, folderService, authService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.optionTemplateService = optionTemplateService;
        this.articleservice = articleservice;
        this.proposalTemplateService = proposalTemplateService;
        this.folderService = folderService;
        this.authService = authService;
        this.optionType = 'blank';
        this.action = 'save';
        this.subscriptions = {};
        this.templatesLoading = true;
        this.uniqueName = false;
        this.loading = false;
        this.isFileSelected = false;
        this.fileList = [];
        this.pdfUploadFiles = [];
        this.isFreemium = true;
        this.freemiumAccess = true;
        this.screenLabel = 'Option templates';
        this.showAddNewTemplate = false;
        this.excludeOptions = [];
        var proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if (!proposalEditor) {
            this.freemiumAccess = true;
        }
        else {
            this.freemiumAccess = false;
        }
    }
    AddOptionModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.uploader = new FileUploader({
            url: "",
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['application/pdf'],
            disableMultipart: true
        });
        this.showModalType = this.showModalType;
        this.proposalTemplateService.templateName$.subscribe(function (template) {
            _this.template = template;
        });
        this.onClose = new Subject();
        this.buildForm();
        this.getAllTemplates();
        if (this.freemiumAccess) {
            setTimeout(function () {
                $("#optionName").select2("val", "");
                _this.optionType = 'template';
            }, 400);
        }
    };
    AddOptionModalComponent.prototype.initializeSelect2 = function (optionTemplates) {
        var dataToSet = [];
        if (optionTemplates && optionTemplates.length) {
            optionTemplates.forEach(function (item) {
                dataToSet.push({
                    id: parseInt(item.id),
                    text: item.optionName,
                    freemium: item.isFreemium
                });
            });
        }
        this.dataSet = dataToSet;
        this.select2TemplateConfig = {
            width: '100%',
            data: dataToSet
        };
        this.templatesLoading = false;
    };
    AddOptionModalComponent.prototype.getAllTemplates = function () {
        var _this = this;
        this.additionalInfo['type'] = 'option';
        this.subscriptions['optionsList'] = this.optionTemplateService.getAllOptionTemplates(this.additionalInfo).subscribe(function (data) {
            if (data) {
                for (var index = 0; index < data['excludeOptions'].length; index++) {
                    if (data['excludeOptions'][index]['addon'] == 0) {
                        _this.excludeOptions.push(data['excludeOptions'][index]['id']);
                    }
                }
            }
        });
    };
    // Form buildForm
    AddOptionModalComponent.prototype.buildForm = function () {
        this.optionForm = this.fb.group({
            'id': [''],
            'optionName': ['', Validators.required],
            'customerOptionName': ['']
        });
    };
    // submit option
    AddOptionModalComponent.prototype.save = function (optionType) {
        this.action = 'saving';
        var option;
        var formValue = this.optionForm.value;
        if (optionType == 'blank') {
            option = {
                "opportunityId": this.opportunityId,
                "opportunityProposalId": this.opportunityProposalId,
                "selectedTemplate": this.selectedTemplate,
                "proposalTemplateId": this.template.id,
                "setupOption": {
                    "optionName": formValue.optionName,
                    "customerOptionName": formValue.customerOptionName,
                },
                "lastPageNumber": this.lastPg,
                'freebee': this.freemiumAccess,
                'layout': this.layout ? this.layout : ''
            };
            this.saveProposalOption(option);
        }
        else {
            option = {
                "opportunityId": this.opportunityId,
                "opportunityProposalId": this.opportunityProposalId,
                "selectedTemplate": this.selectedTemplate,
                "proposalTemplateId": this.template.id,
                "optionTemplateId": formValue.optionName,
                "lastPageNumber": this.lastPg
            };
            this.saveProposalOption(option);
        }
    };
    //save Proposal Options
    AddOptionModalComponent.prototype.saveProposalOption = function (option) {
        var _this = this;
        this.optionTemplateService.addProposalOptions(option).subscribe(function (data) {
            var contentPageId = true;
            if (data) {
                if (data.hasOwnProperty('contentPageId')) {
                    // contentPageId = data['contentPageId'];
                    data['blockData'] = _this.blockData;
                    _this.onClose.next(data);
                }
                else {
                    contentPageId = true;
                    _this.onClose.next(contentPageId);
                }
                if (!_this.freemiumAccess) {
                    var msg = {
                        type: 'upgradeFreemium'
                    };
                    top.postMessage(msg, '*');
                }
            }
            // this.action = 'save';
            // this.onClose.next(contentPageId);
            _this.bsModalRef.hide();
        }, function (error) {
            console.log('err', error);
        });
    };
    // Select option
    AddOptionModalComponent.prototype.optionSelected = function (event) {
        this.optionForm.reset();
        $("#optionName").select2("val", "");
        this.optionType = event.target.value;
    };
    Object.defineProperty(AddOptionModalComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.optionForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // Check template name already exists
    AddOptionModalComponent.prototype.checkUniqueTemplateName = function (value) {
        var _this = this;
        this.loading = true;
        var checkName = true;
        var q = new Promise(function (resolve, reject) {
            var params = {
                optionName: value,
                tablePkId: _this.additionalInfo['tablePkId'],
                tableType: _this.additionalInfo['tableType']
            };
            if (checkName) {
                _this.optionTemplateService.checkOptionTemplateName(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.loading = true;
                        _this.uniqueName = true;
                    }
                    else {
                        _this.loading = false;
                        _this.uniqueName = false;
                        resolve(null);
                    }
                });
            }
            else {
                _this.loading = false;
                resolve(null);
            }
        });
        return q;
    };
    AddOptionModalComponent.prototype.selectAndDragDropFiles = function (eve, uploadType) {
        var _this = this;
        this.loading = false;
        this.isFileSelected = true;
        var file = uploadType == 'dragAndDrop' ? eve : eve.target.files;
        this.pdfUploadFiles = [];
        this.fileList = [];
        this.fileList.push({
            'name': file[0].name,
            'size': (file[0].size / 1024).toFixed(2),
            'type': file[0].name.substr(file[0].name.lastIndexOf('.') + 1)
        });
        this.pdfUploadFiles = file;
        $('[id^=uploadLoading]').removeClass('fas fa-circle-notch fa-spin fa-3x');
        setTimeout(function () {
            $("[id^=uploadLoading]").css("color", "#03A547");
            $('[id^=uploadLoading]').addClass('fa fa-check icon');
            $('[id^=uploadLoading]').removeClass('fa-spin');
            _this.loading = true;
        }, 2000);
    };
    AddOptionModalComponent.prototype.pdfUpload = function () {
        var _this = this;
        this.loading = false;
        if (this.pdfUploadFiles && this.pdfUploadFiles.length) {
            this.articleservice.editorFileUpload(this.pdfUploadFiles[0], this.template.id).subscribe(function (res) {
                if (res) {
                    _this.onClose.next(res);
                    _this.bsModalRef.hide();
                    _this.loading = true;
                    if (!_this.freemiumAccess) {
                        var msg = {
                            type: 'upgradeFreemium'
                        };
                        top.postMessage(msg, '*');
                    }
                }
            });
        }
    };
    AddOptionModalComponent.prototype.selectLayout = function (type, selectedClass) {
        $(document).ready(function () {
            $('.layout').click(function () {
                $('.layout').removeClass('selected');
                $(this).addClass('selected');
            });
        });
        this.layout = type;
    };
    AddOptionModalComponent.prototype.templateSelected = function (event) {
        this.optionForm.controls['optionName'].setValue(event.templateId);
    };
    AddOptionModalComponent.prototype.ngOnDestroy = function () {
        for (var subscription in this.subscriptions) {
            this.subscriptions[subscription].unsubscribe();
        }
    };
    return AddOptionModalComponent;
}());
export { AddOptionModalComponent };
