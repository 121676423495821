import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserVoipSettingsService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    /* Get user voIP settings
        Response : Array
    */
    getUserVoIPSettings(userId: number): Observable<any> {
        return this.http.get(this.getApiUrl(`${userId}/voip_user_settings`));
    }

    /*
      Set user voIP settings
        Response : String
    */
    setUserVoIPSettings(userId: string, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`${userId}/voip_user_settings`), data);
    }
    /*
      Delete user voIP settings
        Response : String
    */
    deleteVoIPSettings(userId: string): Observable<any> {
        return this.http.post(this.getApiUrl(`${userId}/delete_voip_settings`),{});
    }

}