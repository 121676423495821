import { CcCommonService } from '@app/features/customers/customer-contracts/cc-common.service';
var CustomTooltipComponent = /** @class */ (function () {
    function CustomTooltipComponent(ccCommonService) {
        this.ccCommonService = ccCommonService;
    }
    CustomTooltipComponent.prototype.ngOnInit = function () {
        var format = this.ccCommonService.getRegionalDateFormat();
        format = format.replace(/D/gi, 'd');
        this.regionalDateFormat = format.replace(/Y/gi, 'y');
    };
    CustomTooltipComponent.prototype.getStatusColor = function (status) {
        switch (status) {
            case 'Ready':
                return '#48954F';
            case 'Processing':
                return '#A2A360';
            case 'Error':
                return '#BE6D6D';
            case 'Information Missing':
                return '#A2A360';
            default:
                return ''; // Default color or handle other cases as needed
        }
    };
    return CustomTooltipComponent;
}());
export { CustomTooltipComponent };
