import { OnChanges, NgZone, EventEmitter, ElementRef, SimpleChanges, } from '@angular/core';
import { RoundProgressService } from './round-progress.service';
import { RoundProgressDefaults } from './round-progress.config';
import { RoundProgressEase } from './round-progress.ease';
var RoundProgressComponent = /** @class */ (function () {
    function RoundProgressComponent(service, easing, defaults, ngZone) {
        this.service = service;
        this.easing = easing;
        this.defaults = defaults;
        this.ngZone = ngZone;
        this.currentLinecap = '';
        /** Current value of the progress bar. */
        this.current = 0;
        /** Maximum value of the progress bar. */
        this.max = 0;
        /** Radius of the circle. */
        this.radius = this.defaults.radius;
        /** Name of the easing function to use when animating. */
        this.animation = this.defaults.animation;
        /** Time in millisconds by which to delay the animation. */
        this.animationDelay = this.defaults.animationDelay;
        /** Duration of the animation. */
        this.duration = this.defaults.duration;
        /** Width of the circle's stroke. */
        this.stroke = this.defaults.stroke;
        /** Color of the circle. */
        this.color = this.defaults.color;
        /** Background color of the circle. */
        this.background = this.defaults.background;
        /** Whether the circle should take up the width of its parent. */
        this.responsive = this.defaults.responsive;
        /** Whether the circle is filling up clockwise. */
        this.clockwise = this.defaults.clockwise;
        /** Whether to render a semicircle. */
        this.semicircle = this.defaults.semicircle;
        /** Whether the tip of the progress should be rounded off. */
        this.rounded = this.defaults.rounded;
        /** Emits when a new value has been rendered. */
        this.onRender = new EventEmitter();
        this.lastAnimationId = 0;
    }
    /** Animates a change in the current value. */
    RoundProgressComponent.prototype._animateChange = function (from, to) {
        var _this = this;
        if (typeof from !== 'number') {
            from = 0;
        }
        to = this._clamp(to);
        from = this._clamp(from);
        var self = this;
        var changeInValue = to - from;
        var duration = self.duration;
        // Avoid firing change detection for each of the animation frames.
        self.ngZone.runOutsideAngular(function () {
            var start = function () {
                var startTime = self.service.getTimestamp();
                var id = ++self.lastAnimationId;
                requestAnimationFrame(function animation() {
                    var currentTime = Math.min(self.service.getTimestamp() - startTime, duration);
                    var easingFn = self.easing[self.animation];
                    var value = easingFn(currentTime, from, changeInValue, duration);
                    self._updatePath(value);
                    if (self.onRender.observers.length > 0) {
                        self.onRender.emit(value);
                    }
                    if (id === self.lastAnimationId && currentTime < duration) {
                        requestAnimationFrame(animation);
                    }
                });
            };
            if (_this.animationDelay > 0) {
                setTimeout(start, _this.animationDelay);
            }
            else {
                start();
            }
        });
    };
    /** Updates the path apperance. */
    RoundProgressComponent.prototype._updatePath = function (value) {
        if (this.path) {
            var arc = this.service.getArc(value, this.max, this.radius - this.stroke / 2, this.radius, this.semicircle);
            var path = this.path.nativeElement;
            // Remove the rounded line cap when the value is zero,
            // because SVG won't allow it to disappear completely.
            var linecap = this.rounded && value > 0 ? 'round' : '';
            // This is called on each animation frame so avoid
            // updating the line cap unless it has changed.
            if (linecap !== this.currentLinecap) {
                this.currentLinecap = linecap;
                path.style.strokeLinecap = linecap;
            }
            path.setAttribute('d', arc);
        }
    };
    /** Clamps a value between the maximum and 0. */
    RoundProgressComponent.prototype._clamp = function (value) {
        return Math.max(0, Math.min(value || 0, this.max));
    };
    /** Determines the SVG transforms for the <path> node. */
    RoundProgressComponent.prototype.getPathTransform = function () {
        var diameter = this._getDiameter();
        if (this.semicircle) {
            return this.clockwise
                ? "translate(0, " + diameter + ") rotate(-90)"
                : "translate(" + (diameter + ',' + diameter) + ") rotate(90) scale(-1, 1)";
        }
        else if (!this.clockwise) {
            return "scale(-1, 1) translate(-" + diameter + " 0)";
        }
        return null;
    };
    /** Resolves a color through the service. */
    RoundProgressComponent.prototype.resolveColor = function (color) {
        return this.service.resolveColor(color);
    };
    /** Change detection callback. */
    RoundProgressComponent.prototype.ngOnChanges = function (changes) {
        if (changes.current) {
            this._animateChange(changes.current.previousValue, changes.current.currentValue);
        }
        else {
            this._updatePath(this.current);
        }
    };
    /** Diameter of the circle. */
    RoundProgressComponent.prototype._getDiameter = function () {
        return this.radius * 2;
    };
    /** The CSS height of the wrapper element. */
    RoundProgressComponent.prototype._getElementHeight = function () {
        if (!this.responsive) {
            return (this.semicircle ? this.radius : this._getDiameter()) + 'px';
        }
        return null;
    };
    /** Viewbox for the SVG element. */
    RoundProgressComponent.prototype._getViewBox = function () {
        var diameter = this._getDiameter();
        return "0 0 " + diameter + " " + (this.semicircle ? this.radius : diameter);
    };
    /** Bottom padding for the wrapper element. */
    RoundProgressComponent.prototype._getPaddingBottom = function () {
        if (this.responsive) {
            return this.semicircle ? '50%' : '100%';
        }
        return null;
    };
    return RoundProgressComponent;
}());
export { RoundProgressComponent };
