import * as tslib_1 from "tslib";
import { InterfaceClass } from "@app/interfaces/class.interface";
import { NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { CanDeactivateGuard } from "@app/services/router-services/can-deactivation-guard";
import { HybridFactory } from "@app/core/hybrid-factory";
var CanDeactivateGuardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CanDeactivateGuardComponent, _super);
    function CanDeactivateGuardComponent(router) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.subscriptions['__navEndSubscription'] = _this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            CanDeactivateGuard.setConfirmationCallback(_this.router.url, _this.canDeactivate);
        });
        return _this;
    }
    CanDeactivateGuardComponent.prototype.navigateToNextStateUrl = function () {
        if (HybridFactory.isUpgraded(CanDeactivateGuard.nextStateUrl))
            this.router.navigateByUrl(CanDeactivateGuard.nextStateUrl);
        else
            HybridFactory.navigate(CanDeactivateGuard.nextStateUrl);
    };
    CanDeactivateGuardComponent.prototype.canDeactivate = function (nextStateUrl) {
        return true;
    };
    CanDeactivateGuardComponent.prototype.ngOnDestroy = function () {
        CanDeactivateGuard.removeConfirmationCallback();
        _super.prototype.ngOnDestroy.call(this);
    };
    return CanDeactivateGuardComponent;
}(InterfaceClass));
export { CanDeactivateGuardComponent };
