<div onload="addJQueryEffects()">
    <div class="title_wrapper">
        <div class="title_bar">
            <h3>
                <span>
                    <a href="/suppliers" translate="">Suppliers</a> / <span translate>Confirm.your.pending.supplier.invoices.receipts</span>
                </span>
            </h3>
        </div>
    </div>

    <div class="cc-list-view-layout">
        <app-cs-list-view-layout
            [totalRecordCount]="totalRecords"
            [csListViewParams]="listViewParams"
            (onLayoutReady)="onLayoutReady($event)"
            (onPagingUpdate)="updateListViewParams($event)">
            
            <div grid>
                <div class="searchBox">
                    <input type="text" name="searchTerm" class="searchBoxIcon" placeholder="Search" [(ngModel)]="searchTerm"  (keydown.enter)="search($event)" (keyup)="displayList($event)">
                </div>
            
                <ag-grid-angular
                    style="width: 100%; height: 100%"
                    class="ag-theme-balham max-header-spn-2"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [domLayout]="'autoHeight'"
                    [animateRows]="true"
                    >
                </ag-grid-angular>
            </div>
        </app-cs-list-view-layout>
    </div>
</div>


<cs-full-screen-modal 
    bodyClass="common_fslayouts"
    zIndex="1051"
    [header]="title"
    [showFooter]="false"
    (onContentOpen)="onShownFSModal()"
    (onContentClose)="onHiddenFSModal()">

    <app-document-parsed-details
        class="slim-scroll thin"
        *ngIf="openDisplayPage"
        (closeModal)="closeFSModal()"
        [listCodes]="forDisplayNominalCode"
        [taxItems]="forDisplaySalesTax"
        [supplierDetails]="forDisplaySupplier"
        [actionType]="actionType"
    ></app-document-parsed-details>
</cs-full-screen-modal>


