import { TemplateRef, EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { modalConfigDefaults, SidepanelOptions, TRANSITION_DURATIONS } from './sidepanel-options';
import { BsModalRef } from 'ngx-bootstrap';
import { CsFullscreenPanelComponent } from "@app/sidepanel/cs-fullscreen-panel/cs-fullscreen-panel.component";
var FullscreenModalService = /** @class */ (function () {
    function FullscreenModalService(rendererFactory, clf) {
        this.clf = clf;
        this.config = modalConfigDefaults;
        // tslint:disable-next-line:no-any
        this.onShow = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onShown = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHide = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHidden = new EventEmitter();
        this.isBodyOverflowing = false;
        this.originalBodyPadding = 0;
        this.scrollbarWidth = 0;
        this.loaders = [];
        this.modalVisibleStatus = false;
        this.lastDismissReason = '';
        this._renderer = rendererFactory.createRenderer(null, null);
    }
    FullscreenModalService.prototype._createLoaders = function () {
        var loader = this.clf.createLoader(null, null, null);
        this.copyEvent(loader.onBeforeShow, this.onShow);
        this.copyEvent(loader.onShown, this.onShown);
        this.copyEvent(loader.onBeforeHide, this.onHide);
        this.copyEvent(loader.onHidden, this.onHidden);
        this.loaders.push(loader);
    };
    FullscreenModalService.prototype.copyEvent = function (from, to) {
        var _this = this;
        from.subscribe(function () {
            to.emit(_this.lastDismissReason);
        });
    };
    FullscreenModalService.prototype.removeLoaders = function () {
        this.loaders = [];
    };
    FullscreenModalService.prototype.show = function (content, config) {
        this._createLoaders();
        this.config = Object.assign({}, modalConfigDefaults, config);
        this.modalVisibleStatus = true;
        return this._showModal(content);
    };
    FullscreenModalService.prototype._showModal = function (content) {
        var _this = this;
        var modalLoader = this.loaders[0];
        if (this.config && this.config.providers) {
            for (var _i = 0, _a = this.config.providers; _i < _a.length; _i++) {
                var provider = _a[_i];
                modalLoader.provide(provider);
            }
        }
        var bsModalRef = new BsModalRef();
        var modalContainerRef = modalLoader
            .provide({ provide: SidepanelOptions, useValue: this.config })
            .provide({ provide: BsModalRef, useValue: bsModalRef })
            .attach(CsFullscreenPanelComponent)
            .to('body')
            .show({ content: content, isAnimated: this.config.animated, initialState: this.config.initialState, bsModalService: this });
        bsModalRef.hide = function () {
            var duration = _this.config.animated ? TRANSITION_DURATIONS.MODAL : 0;
            setTimeout(function () { return modalContainerRef.instance.hide(); }, duration);
        };
        bsModalRef.content = modalLoader.getInnerComponent() || null;
        bsModalRef.setClass = function (newClass) {
            modalContainerRef.instance.config.class = newClass;
        };
        return bsModalRef;
    };
    FullscreenModalService.prototype.hide = function () {
        var _this = this;
        setTimeout(function () {
            _this._hideModal();
            _this.removeLoaders();
        }, this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0);
        this.modalVisibleStatus = false;
    };
    FullscreenModalService.prototype._hideModal = function () {
        var modalLoader = this.loaders[0];
        if (modalLoader) {
            modalLoader.hide();
        }
    };
    FullscreenModalService.prototype.isOpen = function () {
        return this.modalVisibleStatus;
    };
    FullscreenModalService.prototype.getModalsCount = function () {
        return 1;
    };
    return FullscreenModalService;
}());
export { FullscreenModalService };
