<ng-container>
    <span *ngIf="isReady"
       class="tab-button higher-position cursor-pointer"
       id="update-column-link"
       (click)="showColumnEditor()"
       tooltip="Edit Columns" placement="right">
        <span class="ss-write">&nbsp;</span>
    </span>

    <div class="cs-sidepanel modal fade" bsModal #modal="bs-modal"
         tabindex="-1" role="dialog" aria-labelledby="dialog-events-name" [config]="modalConfig"
         (onShow)="handler('onShow', $event)"
         (onShown)="handler('onShown', $event)"
         (onHide)="handler('onHide', $event)"
         (onHidden)="handler('onHidden', $event)">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="cs-sidepanel-container">
                    <div class="modal-header">
                        <h4 id="dialog-events-name" class="modal-title pull-left" translate>Customise.Table.Columns</h4>
                    </div>
                    <div class="modal-body">
                        <div class="panel-main no-margin">
                            <div class="help_section">
                                <p translate>This.table.can.be.customised.in.2ways</p>
                                <ol>
                                    <li translate>Customise.table.description.1</li>
                                    <li translate>Customise.table.description.2</li>
                                </ol>
                            </div>
                            <div class="sortable-cols-container enabled-selection" [ngClass]="{'enabled-actions': isEnableActions}">
                                <!--<ng-template #itemTemplate let-item="item" let-index="index">-->
                                    <!--<div class="sortable-list-item">-->
                                        <!--<span class="more-icon fas fa-ellipsis-v"></span>-->
                                        <!--<input (change)="onShowHideColumnItem(item.value)" type="checkbox" [checked]="item.value.visible" class="inline-checkbox"/>-->
                                        <!--<span>{{item.value.title}}</span>-->
                                    <!--</div>-->
                                <!--</ng-template>-->
                                <!--<bs-sortable-->
                                        <!--[(ngModel)]="columns"-->
                                        <!--[itemTemplate]="itemTemplate"-->
                                        <!--itemClass="sortable-list-item-wrapper"-->
                                        <!--itemActiveClass="sortable-item-active"-->
                                        <!--placeholderItem="Drag here"-->
                                        <!--placeholderClass="placeholderStyle"-->
                                        <!--(onChange)="onChangeColumnItemModel()"-->
                                        <!--wrapperClass="sortable-list"-->
                                <!--&gt;</bs-sortable>-->
                                <!--<app-ng-sortable
                                    [items]="columns"
                                    [name]="'List'"
                                    (listSorted)="onChangeColumnItem($event)"
                                    [listStyle]="listStyle">
                                    <ng-template let-item>
                                        <div class="sortable-list-item">
                                            <span class="more-icon fas fa-ellipsis-v"></span>
                                            <input (change)="onShowHideColumnItem(item)" type="checkbox" [checked]="item.visible" class="inline-checkbox"/>
                                            {{item.title}}
                                        </div>
                                    </ng-template>
                                </app-ng-sortable>-->
                                <div class="sortable-container">
                                    <ul class="sortable-list" style="position:relative" #sortableContainer>
                                        <li *ngFor="let item of columns" class="sortable-list-item-li">
                                            <div class="sortable-list-item">
                                                <span class="more-icon fas fa-ellipsis-v"></span>
                                                <input (change)="onShowHideColumnItem(item)" type="checkbox" [checked]="item.visible" class="inline-checkbox"/>
                                                {{item.title}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" (click)="onSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <bs-modal-backdrop class="modal-backdrop fade in" [hidden]="!showBackdrop"></bs-modal-backdrop>
</ng-container>
