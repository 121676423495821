import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {
    MicroserviceHelperService
} from '@app/shared/microservice/helper/microservice-helper.service';

@Injectable({
    providedIn: 'root'
})
export class MicroserviceHttpTokenInterceptorService implements HttpInterceptor {

    constructor(
        private microserviceHelperService: MicroserviceHelperService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.microserviceHelperService.isInterceptableRequest(request.url)) {

            if (this.microserviceHelperService.isTokenExist()) {

                const tokenHeaders = this.microserviceHelperService.getTokenHeaders();

                if (Array.isArray(tokenHeaders) && tokenHeaders.length > 0) {
                    tokenHeaders.forEach((header) => {
                        if ('name' in header && 'value' in header) {
                            // clone the http request
                            request = request.clone(
                                {
                                    headers:
                                        request.headers
                                            .set(header.name, header.value)
                                }
                            );
                        }
                    });
                }
            }
        }

        return next.handle(request);

    }

}
