import { HybridFactory } from '@app/core/hybrid-factory';
import * as i0 from "@angular/core";
var HybridRedirectGuard = /** @class */ (function () {
    function HybridRedirectGuard() {
    }
    HybridRedirectGuard.prototype.canActivate = function (routeSnapshot) {
        if (routeSnapshot.data.hasOwnProperty('redirectToUrl')) {
            var redirectToUrl = routeSnapshot.data['redirectToUrl'];
            if (redirectToUrl) {
                HybridFactory.navigate(redirectToUrl);
                return false;
            }
        }
        return true;
    };
    HybridRedirectGuard.ngInjectableDef = i0.defineInjectable({ factory: function HybridRedirectGuard_Factory() { return new HybridRedirectGuard(); }, token: HybridRedirectGuard, providedIn: "root" });
    return HybridRedirectGuard;
}());
export { HybridRedirectGuard };
