import { Injectable, Inject } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { SettingsService } from "@app/core";
import { KB_Datas } from "@app/core/config/data/KB_Datas";

@Injectable({
    providedIn: 'root'
})
export class ConfigKBUrlService extends InterfaceService {
    private _regionDatas:any={};
    private _data:any;
    private _defaultRegion:string = 'United Kingdom';

    constructor(
        private settingsService: SettingsService
    ) {
        super();
        this._regionDatas = KB_Datas;
        this.setData(this.getRegion());
    }

    setData(name:string) {
        this._data = this._regionDatas.hasOwnProperty(name) ? this._regionDatas[name] : null;
        if(!this._data) {
            this._data = this._regionDatas[this._defaultRegion];
        }
    }

    getRegion() {
        let c = this.settingsService.getCountryCode();
        return c['countryName'];
    }

    getData(key:string) {
        let value = this._data['datas'][key];
        return this._data['domainName'] + value;
    }

    getDomain() {
        return this._data['domainName'];
    }

    getEmail() {
        return this._data['supportEmail'];
    }

}