import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
var PricingPaddingOptionsComponent = /** @class */ (function () {
    function PricingPaddingOptionsComponent(bsModalRef) {
        this.bsModalRef = bsModalRef;
        this.action = 'save';
        this.marginLeft = 0;
        this.marginRight = 0;
        this.marginTop = 0;
        this.marginBottom = 0;
        this.paddingLeft = 0;
        this.paddingRight = 0;
        this.paddingTop = 0;
        this.paddingBottom = 0;
    }
    PricingPaddingOptionsComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
    };
    PricingPaddingOptionsComponent.prototype.incDecValue = function (style, operator) {
        switch (style) {
            // Margin 
            case 'marginLeft':
                if (operator == 'inc') {
                    ++this.marginLeft;
                }
                else {
                    --this.marginLeft;
                }
                break;
            case 'marginRight':
                if (operator == 'inc') {
                    ++this.marginRight;
                }
                else {
                    --this.marginRight;
                }
                break;
            case 'marginTop':
                if (operator == 'inc') {
                    ++this.marginTop;
                }
                else {
                    --this.marginTop;
                }
                break;
            case 'marginBottom':
                if (operator == 'inc') {
                    ++this.marginBottom;
                }
                else {
                    --this.marginBottom;
                }
                break;
            // Padding
            case 'paddingLeft':
                if (operator == 'inc') {
                    ++this.paddingLeft;
                }
                else {
                    --this.paddingLeft;
                }
                break;
            case 'paddingRight':
                if (operator == 'inc') {
                    ++this.paddingRight;
                }
                else {
                    --this.paddingRight;
                }
                break;
            case 'paddingTop':
                if (operator == 'inc') {
                    ++this.paddingTop;
                }
                else {
                    --this.paddingTop;
                }
                break;
            case 'paddingBottom':
                if (operator == 'inc') {
                    ++this.paddingBottom;
                }
                else {
                    --this.paddingBottom;
                }
                break;
        }
    };
    PricingPaddingOptionsComponent.prototype.editValue = function (value) {
    };
    PricingPaddingOptionsComponent.prototype.save = function () {
        var data = {
            'margin-left': this.marginLeft + 'px',
            'margin-right': this.marginRight + 'px',
            'margin-top': this.marginTop + 'px',
            'margin-bottom': this.marginBottom + 'px',
            'padding-top': this.paddingTop + 'px',
            'padding-left': this.paddingLeft + 'px',
            'padding-right': this.paddingRight + 'px',
            'padding-bottom': this.paddingBottom + 'px'
        };
        this.onClose.next(data);
        this.bsModalRef.hide();
    };
    PricingPaddingOptionsComponent.prototype.onMouseOver = function (event) {
        if (event.target.matches('.margin-box')) {
            $(event.target).addClass('margin-hover');
        }
        else if (event.target.matches('.padding-box')) {
            $('.margin-box').removeClass('margin-hover');
            $(event.target).addClass('padding-hover');
        }
        else if (event.target.matches('.content-box')) {
            $('.margin-box').removeClass('margin-hover');
            $('.padding-box').removeClass('padding-hover');
            $(event.target).addClass('content-over');
        }
        else {
            $('.margin-box').removeClass('margin-hover');
            $('.padding-box').removeClass('padding-hover');
            $('content-box').removeClass('content-over');
        }
    };
    return PricingPaddingOptionsComponent;
}());
export { PricingPaddingOptionsComponent };
