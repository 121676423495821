<div class="widget-content wi-n-padding">

    <!-- Modal header -->
    <ng-container *ngIf="!customHeader">
        <div #widgetHeader class="widget-header" *ngIf="mapTemplate !== true">
            <button *ngIf="!hideCloseButton" type="button" class="close" id="close-button" (click)="close()"><strong>×</strong></button>
            <h4 translate class="mr-1">{{header}}</h4><span *ngIf="viewMode" class="label label-info">Read-only</span>
            <small *ngIf="subTitle!=='' && !showSubTitleAsTemplate">{{subTitle}}</small>
            <div class="full-screen-sub-title" [innerHTML]="subTitle"
                 *ngIf="subTitle !=='' && showSubTitleAsTemplate"></div>
        </div>
        <div class="widget-header" *ngIf="mapTemplate">
            <span *access="['TimecardApproval.updateaccess']"><button *ngIf="!hideCloseButton" type="button"
                                                                      class="btn btn-primary timecard-fullscreen-approve-close"
                                                                      id="close-button" (click)="approveMap()">Approve and close</button></span>
            <span *access="['TimecardApproval.deleteaccess']"><button *ngIf="!hideCloseButton" type="button"
                                                                      class="timecard-fullscreen-close timecard-fullscreen-delete-font"
                                                                      id="close-button" (click)="deleteTimesheet()">Delete timecard</button></span>
            <button *ngIf="!hideCloseButton" type="button"
                    class="timecard-fullscreen-close timecard-fullscreen-close-font" id="close-button"
                    (click)="close()">Close
            </button>
            <h5 translate class="mr-2"><strong class="timecard-map-title-ucfirst">{{header}}</strong> <span
                *ngIf="subTitle!=='' && !showSubTitleAsTemplate"> {{subText}} <strong>{{subTitle}}</strong></span></h5>
            <div class="full-screen-sub-title" [innerHTML]="subTitle"
                 *ngIf="subTitle !=='' && showSubTitleAsTemplate"></div>
        </div>
    </ng-container>
    <!-- Modal Custom header -->
    <ng-container *ngIf="customHeader">
        <ng-container appCsHeader></ng-container>
    </ng-container>
    <!-- Modal body -->
    <div class="widget-body" #widgetBody>
        <!-- Content -->
        <section>
            <ng-content></ng-content>
        </section>
    </div>
    <!--page actions-->
    <div *ngIf="showFooter" class="widget-footer">
        <div class="row-fluid">

        </div>
    </div>
</div>


