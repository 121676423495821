<form *ngIf="form" [formGroup]="form" (submit)="save()">
<div class="modal-header">
    <h4 class="modal-title pull-left" translate>{{title}}</h4>
</div>
<div class="modal-body ng-partsandprices part-and-prices-bottom-alignment">
    <div class="help_section">
        <div class="help-text" translate>
            Parts.configure.text
        </div>
    </div>
    <div class="body-content-section">
        <div class="row-fluid">
            <div class="span12">
                <!--<div class="control-group clearfix">-->
                    <!--<label class="control-label required_field">Field name</label>-->
                    <!--<div class="controls">-->
                        <!--<input type="text" focus-me="true" class="control-input">-->
                    <!--</div>-->
                <!--</div>-->

                <div class="control-group clearfix">
                    <label class="control-label" translate>Industry</label>
                    <div class="controls">
                        <select class="full-width-select" formControlName="settingsIndustryid">
                            <option value="" translate="">Please.Choose</option>
                            <option *ngFor="let industry of industrylist" value="{{industry.industryId}}">{{industry.industryDescription}}</option>
                        </select>
                    </div>
                    <span class="help-block" translate>Add.new.industry</span>
                </div>
                <div class="control-group clearfix">
                    <label class="control-label" translate>Category.Sub.Category</label>
                    <div class="controls">
                        <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1" formControlName="settingsSubcategoryid">
                            <option value="" translate>Please.Choose</option>
                            <optgroup *ngFor="let item of categorylist" label="{{item.categoriesDescription}}">
                                <option *ngFor="let category of item.subCategoryList" [value]="category.subCategoryId">{{category.subCategoryDescription}}</option>
                            </optgroup>
                        </select>
                    </div>
                    <span class="help-block" translate>add.new.category.parts</span>
                </div>

                <!--<div class="control-group clearfix">
                    <label class="control-label" translate>Category</label>
                    <div class="controls">
                        <select class="full-width-select" formControlName="settingsCategoryid" (change)="onChangeCategory(event)">
                            <option value="" translate="">Please.Choose</option>
                            <option *ngFor="let category of categorylist" value="{{category.categoriesDescription}}">{{category.categoriesDescription}}</option>
                        </select>
                    </div>
                </div>
                <div class="control-group clearfix">
                    <label class="control-label settings-sub-cats" translate>Sub-category</label>
                    <div class="controls">
                        <select class="full-width-select" formControlName="settingsSubcategoryid">
                            <option value="" translate="">Please.Choose</option>
                            <option *ngFor="let item of subCategorylist" value="{{item.subCategoryId}}">{{item.subCategoryDescription}}</option>
                        </select>
                    </div>
                    <div *ngIf="f.settingsSubcategoryid.errors?.isRequiredSubCategory">
                        <span class="control-error-message show">
                            <span translate>Please.Enter.Valid.Subcategory</span>
                        </span>
                    </div>
                    <span class="help-block" translate>add.new.category.parts</span>
                </div>-->

                <div class="control-group clearfix">
                    <label class="control-label required_field" translate>Part.Name</label>
                    <div class="controls">
                        <input type="text" class="control-input" formControlName="description" (keyup)="updateValidation($event)">
                    </div>
                    <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)">
                        <span *ngIf="f.description.errors.required" class="control-error-message show">
                            <span translate>Please.Enter.Valid.Part.Description</span>
                        </span>
                        <span *ngIf="f.description.errors.isUniqueDescription" class="control-error-message show">
                            <span translate>This.Part.Description.Already.Exists</span>
                        </span>
                    </div>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label" translate>Image</label>
                    <div class="controls">
                        <div>
                            <input  type="hidden" id="filePath" formControlName="filePath" />
                            <div class="uploader">
                                <input
                                  type="file"
                                  name="file"
                                  (change)="onFileSelected()"
                                  #fileUploader ng2FileSelect [uploader]="uploader" style="display: none; opacity: 0; width:0px;"/>

                                <div class="btn-group">
                                    <button class="btn btn-primary fake-uploader"
                                        type="button" (click)="fileUploader.click()"
                                        readonly="readonly">Click to upload image
                                    </button>
                                    <button class="btn upload_file_insafari upload-progress btn-primary">{{uploader.progress}}%</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isValidUploadFile">
                        <span class="control-error-message show mt1">
                            <span translate>Please upload a valid image file, should be in the format of jpg or jpeg or png</span>
                        </span>
                    </div>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label required_field" translate>Type</label>
                    <div class="controls">
                        <select id="partsandprices_parttype" class="full-width-select" formControlName="parttype">
                            <option *ngFor="let item of partTypeDropdown" value="{{item.id}}">{{ item.name }}</option>
                        </select>
                    </div>
                    <span class="help-block" translate>add.part.type.text</span>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label" translate>Manufacturer.Part.Number</label>
                    <div class="controls">
                        <input type="text" class="control-input" formControlName="manufacturepartnumber">
                    </div>
                    <div *ngIf="f.manufacturepartnumber.errors?.isUniqueMpn">
                        <span class="control-error-message show">
                            <span translate>This.Part.Description.MPN.Already.Exists</span>
                        </span>
                    </div>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label required_field" translate>Cost</label>
                    <div class="controls">
                        <input type="text" class="control-input" appDigitDecimaNumber formControlName="price">
                    </div>
                    <div *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)">
                        <span *ngIf="f.price.errors.required" class="control-error-message show">
                            <span translate>Please.Enter.Valid.Part.Price</span>
                        </span>
                    </div>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label required_field"><span translate>Mark.Up</span> (%)</label>
                    <div class="controls">
                        <input type="text" pattern="^[0-9]{0,3}(\.[0-9]{1,2})?$" class="control-input" appDigitDecimaNumber [scale]="2" [precision]="3" formControlName="markup">
                    </div>
                    <div *ngIf="f.markup.invalid && (f.markup.dirty || f.markup.touched)">
                        <span *ngIf="f.markup.errors.required" class="control-error-message show">
                            <span translate>Please.Enter.Valid.Part.Markup</span>
                        </span>
                        <span *ngIf="f.markup.errors?.pattern" class="control-error-message show">
                            <span translate>Please.Enter.Valid.Markup</span>
                        </span>
                    </div>
                    <span class="help-block" translate>mark.up.text</span>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label required_field">{{ taxLabel }}</label>
                    <div class="controls tax-items">
                        <input type="hidden" csSelect2 [select2Config]="select2Config"
                           formControlName="settingstaxitemid" [placeholder]="'-- please select --'" class="chosen-select full-width-select">
                       <!-- <select id="select2-settingstaxitemid" csSelect2 [select2Config]="select2Config" formControlName="settingstaxitemid">
                            <option value="" translate>Please.Choose</option>
                            <optgroup *ngFor="let group of taxByProvinces" label="{{group.text}}">
                                <option *ngFor="let item of group.children" value="{{item.id}}">{{ item.text }}</option>
                            </optgroup>
                        </select>-->
                    </div>
                    <div *ngIf="f.settingstaxitemid.invalid && (f.settingstaxitemid.dirty || f.settingstaxitemid.touched)">
                        <span *ngIf="f.settingstaxitemid.errors.required" class="control-error-message show">
                            <span translate>Please.Select.Valid.Part.Taxitem</span>
                        </span>
                    </div>
                </div>
                <!-- Supplier section for pricing sections -->
                <ng-container *ngIf="suppliers.length">
                    <hr class="seperator-top"/>
                    <ng-container *ngIf="form.get('supplierprice') && form.get('supplierprice')['controls']">
                    <div formArrayName="supplierprice" *ngFor="let supplier of form.get('supplierprice')['controls']; let i = index;">
                        <div [formGroupName]="i">
                            <div class="control-group clearfix">
                                <label class="control-label" translate>{{supplier?.value?.supplierName}} price</label>
                                <div class="controls inline-inputs">
                                    <input type="text" class="control-input" appDigitDecimaNumber formControlName="price">
                                </div>
                            </div>

                            <div class="control-group clearfix">
                                <label class="control-label" translate>{{supplier?.value?.supplierName}} product code</label>
                                <div class="controls inline-inputs">
                                    <input type="text" class="control-input" formControlName="supplierPartNumber">
                                </div>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                    <hr class="seperator-bottom"/>
                </ng-container>
                <div class="control-group clearfix">
                    <label class="control-label" translate>Default.install.time</label>
                    <div class="controls inline-inputs">
                        <input type="text" class="control-input" formControlName="defaultInstallTime" appDigitDecimaNumber [scale]="2">
                        <select formControlName="installtime_unit" (change)="onChangeUnit($event)">
                            <option *ngFor="let unit of units" value="{{unit.id}}">{{ unit.text }}</option>
                        </select>
                    </div>
                    <span class="help-block" translate [translateParams]="{unit: unitMessage}">This.how.long.install.part</span>
                </div>

                <div class="control-group clearfix">
                    <label class="control-label" translate>Default.labour.cost.rate</label>
                    <div class="controls inline-inputs">
                        <input type="text" class="control-input" appDigitDecimaNumber formControlName="labourCostRate">
                    </div>
                </div>
                    <div class="control-group clearfix" *ngIf="labourModuleEnabled">
                        <label class="control-label" translate>Default.labour.sale.rate</label>
                        <div class="controls">
                            <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1"
                                formControlName="labourRatesId">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let rate of labourData" [value]="rate.id">{{rate.description}}</option> -->
                            </select>
                        </div>
                    </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="cancel_()">Cancel</a>
    <button #saveBtn [disabled]="!form.valid  || action == 'saving'" class="btn btn-primary">
        <span [hidden]="action == 'saving'" translate>Save</span>
        <span [hidden]="action != 'saving'" translate>Saving</span>
    </button>
</div>
</form>
