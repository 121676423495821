import * as tslib_1 from "tslib";
//--
import { InterfaceClass } from '@app/interfaces';
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(settings) {
        var _this = _super.call(this) || this;
        _this.settings = settings;
        _this.userAccessDetails = {};
        // Listen for user permission changes and update the repertory
        _this.subscriptions['userAccessDetails'] = _this.settings.userAccessDetails$.subscribe(function (details) {
            _this.userAccessDetails = details;
        });
        _this.subscriptions['modulePermissions'] = _this.settings.modulePermissions$.subscribe(function (Data) {
            _this.modulePermissionsObject = Data;
        });
        return _this;
    }
    // Checks is authenticated
    AuthService.prototype.authenticated = function () {
        return this.userAccessDetails instanceof Object;
    };
    // Checks is user has permission
    AuthService.prototype.permitted = function (permittedFeatures) {
        var _this = this;
        var invalidPreviliges = [];
        var userPreviliges = permittedFeatures.filter(function (feature) {
            var _a = feature.split('.'), featureName = _a[0], accessLevel = _a[1];
            var featureId = features[featureName];
            // In case of valid feature id
            if (!featureId) {
                return false;
            }
            // Finds feature access in user permissions
            if (_this.userAccessDetails[featureId] && _this.userAccessDetails[featureId][accessLevel])
                return true;
            else {
                invalidPreviliges.push([featureName, accessLevel]);
                return false;
            }
        });
        // Checks user has all the provided permissions
        return permittedFeatures.length === userPreviliges.length ? true : invalidPreviliges;
    };
    // Check the module access
    AuthService.prototype.hasModuleAccess = function (module) {
        var modulePermissions;
        if (this.modulePermissionsObject) {
            modulePermissions = this.modulePermissionsObject['moduleDetails'];
            console.log(modulePermissions);
            if (modulePermissions[module] == 1)
                return true;
            else
                return false;
        }
    };
    return AuthService;
}(InterfaceClass));
export { AuthService };
