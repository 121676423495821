import { ChangeDetectorRef, OnInit, AfterViewInit, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs/index';
import { PropertyViewService } from '@app/features/customers/customer/property-view/service/property-view.service';
var RenameFilesModal = /** @class */ (function () {
    function RenameFilesModal(formBuilder, ajsScope, httpService, bsModalRef, changeDetector) {
        this.formBuilder = formBuilder;
        this.ajsScope = ajsScope;
        this.httpService = httpService;
        this.bsModalRef = bsModalRef;
        this.changeDetector = changeDetector;
        this.formModal = {};
        this.action = '';
        this.formDatas = {
            fileId: '', name: '', fileType: ''
        };
    }
    Object.defineProperty(RenameFilesModal.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    RenameFilesModal.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.form = this.formBuilder.group({
            'name': [this.name, [Validators.required, Validators.pattern('^.*$')]],
            'fileId': [this.fileId],
            'fileType': [this.fileType],
        });
    };
    RenameFilesModal.prototype.ngAfterViewInit = function () {
        var _this = this;
        var that = this;
        setTimeout(function () {
            _this.saveBtn.nativeElement.focus();
        }, 900);
    };
    RenameFilesModal.prototype.updateValidation = function (event) {
        if (this.form.get('name').value == '') {
            this.form.controls['name'].updateValueAndValidity();
        }
    };
    RenameFilesModal.prototype.save = function () {
        var _this = this;
        if (!this.form.valid) {
            this.action = '';
            return false;
        }
        this.action = 'saving';
        var renameFiles = this.form.value;
        this.httpService.renameFiles(renameFiles)
            .subscribe(function (resp) {
            _this.action = '';
            _this.onClose.next(true);
            _this.bsModalRef.hide();
        }, function (error) {
            _this.action = '';
            console.log('err', error);
        });
    };
    return RenameFilesModal;
}());
export { RenameFilesModal };
