import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable()

export class OpportunityTemplateService extends InterfaceService {

    private screenType = new BehaviorSubject('');
    screenType$ = this.screenType.asObservable()

    private closeModal = new BehaviorSubject('');
    closeModal$ = this.closeModal.asObservable()

    private saveData = new BehaviorSubject('');
    saveData$ = this.saveData.asObservable()

    private opportuniityData = new BehaviorSubject('');
    opportuniityData$ = this.opportuniityData.asObservable()

    private showPage = new BehaviorSubject(false);
    showPage$ = this.showPage.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    // Add/Edit opporuntity templates
    addOrEditOpportunityTemplate(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditOppurtunityTemplate`), data);
    }

    // Get job description list
    getJobDescriptionList(type, id): Observable<any> {
        return this.http.get(this.getApiUrl(`get_opputunity_template_add_data?type=${type}&id=${id}`));
    }

    // Clone opportunity template
    cloneOpportunityTemplate(id, parentId): Observable<any> {
        return this.http.get(this.getApiUrl(`cloneOppurtunityTemplate/${id}/${parentId}`));
    }

    // Delete opportunity template
    deleteOpportunityTemplate(id,params?: any): Observable<any> {
        return this.http.delete(this.getApiUrl(`${id}` + `/deleteOppurtunityTemplate`, params));
    }

    // Check opportunitytemplate already exists
    checkTemplateName(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`checkOppurtunityTemplateExists`, params));
    }

    // Reorder Template list
    reorderTemplate(data:Object): Observable<any> {
        return this.http.put(this.getApiUrl('reorderOppurtunityTemplates'),data);
    }

    // Set screentype
    setScreenType(data){
        this.screenType.next(data)
    }
    // Set screentype
    setData(data){
        this.saveData.next(data)
    }
    //setOpportunityData
    setOpportunityData(data){
        this.opportuniityData.next(data);
    }

    // Save confirmations
    saveConfirmations(data: object, templateId: any, menuType: string): Observable<any> {
        return this.http.post(this.getApiUrl(`oppurtunityConfirmation/${templateId}?type=${menuType}`), data);
    }

    // Get Reminders
    getConfirmations(templateId, menuType: string, customerId=null, opportunityId=null): Observable<any> {
        let params = {
            "type" : menuType,
        }
        if(customerId){
            params['customerId'] = customerId;
        }
        if(opportunityId){
            params['opportunityId'] = opportunityId;
        }
        return this.http.get(this.getApiUrl(`getOppurtunityConfirmations/${templateId}`, params));
    }

    // Save Reminders
    saveReminders(data: object, templateId: any): Observable<any> {
        return this.http.post(this.getApiUrl(`oppurtunityReminder/${templateId}`), data);
    }

    // Get Reminders
    getReminders(id): Observable<any> {
        return this.http.get(this.getApiUrl(`getOppurtunityReminders/${id}`));
    }

    // Save notes
    saveNotes(data: object, id): Observable<any> {
        return this.http.post(this.getApiUrl(`oppurtunityNotes/` + `${id}`), data);
    }

    // Get notes
    getNotes(id): Observable<any> {
        return this.http.get(this.getApiUrl(`oppurtunityNotes/` + `${id}`));
    }

    // Call configure close function
    callCloseModalFuntion(data) {
        this.closeModal.next(data)
    }

    //add Attachments
    addAttachments(file: File, mode, templateId){
        const formData: any = new FormData();
        const xhr = new XMLHttpRequest();
        formData.append('file', file, file.name);
        return this.http.post(this.getApiUrl(`upload_opportunity_template_file?mode=${mode}&selectedId=${templateId}`), formData)
    }

    //Delete Attachments
    deleteAttachments(id: any){
        return this.http.delete(this.getApiUrl(`deleteAttatchedFile/${id}`));
    }

    //update Attached files
    updateAttachments(id: any, data : any){
        return this.http.post(this.getApiUrl(`updateAttachedFile/${id}`), data);
    }

    // add opportunity questions
    addOrEditOpportunityQuestions(opportunityId: number,Data: object):Observable<any> {
        return this.http.put(this.getApiUrl(`opportunityTemplateQuestions/${opportunityId}`), JSON.stringify(Data));
    }
    // get opportunity questions
    getOpportunityQuestions(opportunityId: number, params?: any):Observable<any> {
        return this.http.get(this.getApiUrl(`opportunityTemplateQuestions/${opportunityId}`, params));
    }

    getArriveQuestions(opportunityTemplateId: number): Observable<object> {
        return this.http.get(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/arriveQuestions`));
    }
    saveArriveQuestions(opportunityTemplateId: number, params: any): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/saveOPArriveQuestions`), params);
    }
    deleteArriveQuestions(opportunityTemplateId: number, arriveQuestionId: number): Observable<any> {
        return this.http.delete(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/deleteOPArriveQuestions/${arriveQuestionId}`));
    }

    getLeaveQuestions(opportunityTemplateId: number): Observable<object> {
        return this.http.get(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/leaveQuestions`));
    }
    saveLeaveQuestions(opportunityTemplateId: number, params: any): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/saveOPLeaveQuestions`), params);
    }
    deleteLeaveQuestions(opportunityTemplateId: number, leaveQuestionId: number): Observable<any> {
        return this.http.delete(this.getApiUrl(`system_settings/opportunity_template/${opportunityTemplateId}/deleteOPLeaveQuestions/${leaveQuestionId}`));
    }

    setShowPage(data)  {
        this.showPage.next(data);
    }

    opportunityTemplateLinkedOpportunity(id): Observable<any> {
        return this.http.get(this.getApiUrl(`checkIsOpportunityLinkOpportunityTemplate/${id}`));
    }
}
