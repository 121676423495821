/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cs-ag-group-cell-renderer.component";
import * as i3 from "../../cs-ag-grid.service";
var styles_CsAgGroupCellRendererComponent = [];
var RenderType_CsAgGroupCellRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsAgGroupCellRendererComponent, data: {} });
export { RenderType_CsAgGroupCellRendererComponent as RenderType_CsAgGroupCellRendererComponent };
function View_CsAgGroupCellRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "ag-group-expanded"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "ag-icon ag-icon-expanded"]], null, null, null, null, null))], null, null); }
function View_CsAgGroupCellRendererComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "ag-group-contracted"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "ag-icon ag-icon-contracted"]], null, null, null, null, null))], null, null); }
export function View_CsAgGroupCellRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "ag-cell-wrapper ag-row-group customGroupCell"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((((_co.params == null) ? null : _co.params.value) && _co.onCellClicked($event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "expanded": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "ag-group-value m-0-pxl"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsAgGroupCellRendererComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsAgGroupCellRendererComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ag-cell-wrapper ag-row-group customGroupCell"; var currVal_1 = _ck(_v, 2, 0, _co.isCellExpanded); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.isCellExpanded; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_co.isCellExpanded; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.params == null) ? null : _co.params.valueFormatted) || ((_co.params == null) ? null : _co.params.value)); _ck(_v, 4, 0, currVal_2); }); }
export function View_CsAgGroupCellRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cs-ag-group-cell-renderer", [], [[8, "className", 0]], null, null, View_CsAgGroupCellRendererComponent_0, RenderType_CsAgGroupCellRendererComponent)), i0.ɵdid(1, 49152, null, 0, i2.CsAgGroupCellRendererComponent, [i3.CsAgGridService], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CsAgGroupCellRendererComponentNgFactory = i0.ɵccf("app-cs-ag-group-cell-renderer", i2.CsAgGroupCellRendererComponent, View_CsAgGroupCellRendererComponent_Host_0, {}, {}, []);
export { CsAgGroupCellRendererComponentNgFactory as CsAgGroupCellRendererComponentNgFactory };
