<div class="data-section information-bar" (show)="notification_bar">
    <!--Close icon-->
    <div *ngIf="showCloseBtn" (click)="closeInfobar()" class="ss-delete close-icon"></div>

    <div class="row-fluid without-padding" [ngClass]="{'with-title-bar' : single_notification_bar === false && information_data.length > 4}">
        <div class="information-wrapper" [ngClass]="{'with-padding' : information_data.length > 4}" (show)="information_open" *ngIf="information_data.length > 0">
            <div *ngFor="let information of information_data;let i= index" class="span12 single-notice" [ngClass]="{'with-bottom-border' : i+1 !== information_data.length}">
                <p>
                    <span class="icon" [ngClass]="{
									'ss-info' : information.type === 'info',
									'ss-check' : information.type === 'check' || (information.type === 'edit' && information.completed.completed === true),
									'ss-alert' : information.type === 'warning',
									'ss-send' : information.type === 'sending',
									'ss-write' : information.type === 'edit' && information.completed.completed !== true }">
                    </span>
                    <span *ngIf="information.date"
                          class="date">
                        {{information.date}}
                        <i *ngIf="information.time">({{information.time}})</i>
                    </span>
                    <strong translate>{{information.message}}</strong>
                    <i *ngIf="information.additional_message"><span>{{ information.additional_message }}</span></i>
                    <strong *ngIf="information.name">
                        &nbsp;by <strong>{{information.name}}</strong>
                    </strong>
                </p>
                <p *ngIf="information.help_text"
                   class="help-text with-left-margin">
                    {{information.help_text}}
                </p>
                <div class="information_bar_actions">
                    <a href="javascript:void(0)" *ngIf="information.category === 'draft_invoice';"
                       (click)="handleDraftInvoiceAction()">
                        Convert draft to invoice
                    </a>
                </div>
            </div>
        </div>
        <div class="information-wrapper"
             *ngIf="!information_data.length">
            <div class="span12 single-notice">
                <p>
                    <span class="{{icon}} icon"></span>
                    <span [innerHTML]="message" translate></span>
                </p>
            </div>
        </div>
    </div>
</div>
