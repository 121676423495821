<canvas #canvasEl [width]='imageWidth' [height]='imageHeight' style="display: none;background: #FFFFFF !important;"></canvas>
<div *ngIf="dataSource.length > 1" class="cs-signature-generator">
  <label class="sig-container"
         [ngClass]="{'sig-active' : currentIndex == i }"
         (click)="onSignatureSelection(item, i)"
         *ngFor="let item of dataSource; index as i">
    <span [ngStyle]="{'font-family': item.FontFamily, 'font-size': item.FontSize}">
      {{ signatureText }}
    </span>
  </label>
</div>
<div *ngIf="showSignature">
  <img [src]='base64Image' *ngIf="base64Image" style="border:1px solid black" [width]='imageWidth'
       [height]='imageHeight'>
</div>