var ContractTemplateArchiveEvent = /** @class */ (function () {
    function ContractTemplateArchiveEvent(pkId, resolveFn, rejectFn) {
        this.pkId = pkId;
        this.executor = {
            resolve: resolveFn,
            reject: rejectFn
        };
    }
    return ContractTemplateArchiveEvent;
}());
export { ContractTemplateArchiveEvent };
