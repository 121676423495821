import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//--
import { InterfaceService } from '@app/interfaces';
import { CustomerContract } from '@app/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerContractsService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerContractsService, _super);
    function CustomerContractsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Get customer contracts data
    CustomerContractsService.prototype.getcustomerContractFormData = function (type, customerId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/add-contract"));
    };
    // Add  new  customer contract
    CustomerContractsService.prototype.addCustomerContract = function (type, customerId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/add-contract"), JSON.stringify(data));
    };
    // Get all contracts details
    CustomerContractsService.prototype.getAllcustomerContractsDetails = function (type, customerId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/get-all-details"));
    };
    // Fetching customer contract basic details
    CustomerContractsService.prototype.getCustomerContractBasicDetails = function (type, customerId, contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/get-basic-details"));
    };
    // Chaning customer contract status
    CustomerContractsService.prototype.changeCustomerContractStatus = function (contractId, data) {
        return this.http.put(this.getApiUrl("customer-contracts/" + contractId + "/change-status"), JSON.stringify(data));
    };
    // Edit customer contract basic details
    CustomerContractsService.prototype.editCustomerContractBasicDetails = function (contractId, data) {
        return this.http.put(this.getApiUrl("customer-contracts/" + contractId + "/edit-basic-details"), JSON.stringify(data))
            .pipe(map(function (Response) {
            Response = new CustomerContract(Response);
            return Response;
        }));
    };
    // Get customer contract billable rates labour/travel costs
    CustomerContractsService.prototype.getBillableRatesLTCosts = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/labour-travel-costs"));
    };
    // Store customer contract billable rates labour/travel costs
    CustomerContractsService.prototype.saveBillableRatesLTCosts = function (contractId, data) {
        return this.http.put(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/labour-travel-costs/save"), JSON.stringify(data));
    };
    // Get customer contract billable rates pricing items
    CustomerContractsService.prototype.getBillableRatesPricingItems = function (contractId, params) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/pricing-items", params))
            .pipe();
    };
    // Savecustomer contract pricing items
    CustomerContractsService.prototype.saveBillableRatesPricingItems = function (contractId, data) {
        return this.http.put(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/pricing-items/save"), JSON.stringify(data));
    };
    // Get customer contract billable rates parts pricing
    CustomerContractsService.prototype.getBillableRatesPartsPricing = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/parts-pricing"));
    };
    // Save customer contract billable rates parts pricing
    CustomerContractsService.prototype.saveBillableRatesPartsPricing = function (contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/billable-rates/parts-pricing"), JSON.stringify(data));
    };
    // Update customer contractbillable rates parts pricing record
    CustomerContractsService.prototype.updateBillableRatesPartsPricing = function (partPriceId, data) {
        return this.http.put(this.getApiUrl("customer-contracts/billable-rates/parts-pricing/" + partPriceId), JSON.stringify(data));
    };
    // Delete customer contract billable rates parts pricing
    CustomerContractsService.prototype.deleteBillableRatesPartsPricing = function (partPriceId) {
        return this.http.delete(this.getApiUrl("customer-contracts/billable-rates/parts-pricing/" + partPriceId));
    };
    // Get customer contract property list
    CustomerContractsService.prototype.getAppliedProperties = function (type, customerId, contractId, param) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/properties", param));
    };
    // Save customer contract properties
    CustomerContractsService.prototype.setAppliedProperties = function (type, customerId, contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/properties"), JSON.stringify(data));
    };
    // Delete customer contract properties
    CustomerContractsService.prototype.deleteAppliedProperty = function (CcPropertyId) {
        return this.http.delete(this.getApiUrl("customer-contracts/properties/" + CcPropertyId));
    };
    // Get customer properties
    CustomerContractsService.prototype.getCustomerProperties = function (type, customerId, contractId, param) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/customer-properties", param));
    };
    // Get contract Linked Job Fup
    CustomerContractsService.prototype.getWhatsIncludedLinkedJobFup = function (type, customerId, contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/linked-jobfup"));
    };
    // Save customer contract whats included job FUP
    CustomerContractsService.prototype.saveContractsWhatsIncludedJobFUP = function (contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/job-fup"), JSON.stringify(data));
    };
    // Delete contract whats included job FUP
    CustomerContractsService.prototype.deleteContractWhatsIncludedJobFUP = function (jobFUPId) {
        return this.http.delete(this.getApiUrl("customer-contracts/whats-included/job-fup/" + jobFUPId));
    };
    // Get contract asset types against property
    CustomerContractsService.prototype.getContractPropertyAssetTypes = function (type, customerId, contractId, propertyId, params) {
        return this.http.get(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/" + propertyId + "/asset-types", params));
    };
    // Save contract asset types against property
    CustomerContractsService.prototype.addContractAssetTypes = function (type, customerId, contractId, propertyId, Data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/" + propertyId + "/asset-types"), JSON.stringify(Data));
    };
    // delete customer contract
    CustomerContractsService.prototype.checkCustomerContractIsDeletable = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/check-is-deletable"));
    };
    // delete customer contract
    CustomerContractsService.prototype.checkCustomerContractIsCancel = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/check-is-cancel"));
    };
    // delete customer contract
    CustomerContractsService.prototype.checkCustomerContractIsInvoiceNumber = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/check-is-invoice-number"));
    };
    // delete customer contract
    CustomerContractsService.prototype.deleteCustomerContract = function (type, customerId, contractId) {
        return this.http.delete(this.getApiUrl("customer-contracts/" + type + "/" + customerId + "/" + contractId + "/delete"));
    };
    // clone customer contract
    CustomerContractsService.prototype.cloneCustomerContract = function (contractId) {
        return this.http.put(this.getApiUrl("customer-contracts/" + contractId + "/clone"), {});
    };
    // save invoice settings customer contract
    CustomerContractsService.prototype.saveCustomerContractInvoicingSettings = function (contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/invoicing/settings"), JSON.stringify(data));
    };
    // save invoice billing customer contract
    CustomerContractsService.prototype.saveInvoiceBillingOption = function (contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/invoicing/billing-option"), JSON.stringify(data));
    };
    //  get customer contract invoice
    CustomerContractsService.prototype.getCustomerContractInvoicingDataEdit = function (contractId, invoicingId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/invoicing/edit/" + invoicingId));
    };
    //  get  customer contract invoice
    CustomerContractsService.prototype.getCustomerContractInvoicing = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/invoicing-data"));
    };
    // check customer contract invoice
    CustomerContractsService.prototype.checkCustomerContractIsInvoicePayment = function (contractId) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/check-is-invoice"));
    };
    // save set price change in contract invoice setup
    CustomerContractsService.prototype.saveCustomerContractInvoiceSetPriceChanges = function (contractId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/invoicing/set-price-changes"), JSON.stringify(data));
    };
    CustomerContractsService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerContractsService_Factory() { return new CustomerContractsService(i0.inject(i1.HttpClient)); }, token: CustomerContractsService, providedIn: "root" });
    return CustomerContractsService;
}(InterfaceService));
export { CustomerContractsService };
