import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {InterfaceService} from "@app/interfaces";

@Injectable()

export class PartsAndPricesService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    getPartsAndPricesMetadata(params?: Object): Observable<any> {
       return this.http
        .get(this.getApiUrl(`parts_and_prices_metadata`, params));
    }

    addEditPartsAndPrices(data: object, id:number): Observable<any> {
        if(id===0) {
            return this.http.post(this.getApiUrl(`parts_and_prices/add`), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl(`parts_and_prices/edit/${id}`), JSON.stringify(data));
        }
    }

    checkUniqueName(params?: Object): Observable<any> {
        return this.http
          .get(this.getApiUrl(`partsAndPricesExits`, params));
    }

    beforeDelete(id?: any): Observable<any>  {
      return this.http
        .get(this.getApiUrl(`deletePartsAndPrices/${id}`));
    }

    deletePartRequest(params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`delete_partsandprices`),params);
    }

    archivePartRequest(params?: any): Observable<any> {
      return this.http
        .post(this.getApiUrl(`archive_partsandprices`),params);
    }

    checkPassword (params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`validUserPassword?___noPageloading=true`),params);
    }

    checkExistsUsername(query?: any): Observable<any> {
        return this.http
            .get(this.getApiUrl(`validUserName?___noPageloading=true&username=${query}`));
    }

    doMassDeleteArchive(params?: any): Observable<any> {
        return this.http
          .post(this.getApiUrl(`massDeleteArchive`),params);
    }

    importRecordsPartsAndPrices(params?: any): Observable<any> {
        return this.http
          .post(this.getApiUrl(`import_records_from_csv/parts_and_prices`),params);
    }

    importRecordsSupplierPartsAndPrices(params?: any) : Observable<any> {
        return this.http
          .post(this.getApiUrl(`import_records_from_csv/supplier_part_and_prices`),params);
    }

    getSupplierPricesByPartId(id?: any): Observable<any>  {
        return this.http
          .get(this.getApiUrl(`parts_and_prices/${id}/supplier_prices`));
    }

    addOrEditSupplierPrice(params?: any) : Observable<any> {
      return this.http
        .post(this.getApiUrl(`parts_and_prices/supplier_prices/addOrEdit`), params);
    }

   deleteSupplierPrice(params?: any) : Observable<any> {
     return this.http
      .post(this.getApiUrl(`parts_and_prices/supplier_prices/delete`), params);
    }

  getlabourRate() {
    return this.http.get(this.getApiUrl(`sales/get_labour_rates`));
  }

    getPartCostByListId(listId, params?): Observable<any> {
        return this.http.get(this.getApiUrl(`getPartsPricingCosts/${listId}`, params));
    }
}
