import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

//--
import { InterfaceService } from '@app/interfaces';
import { ContractTemplate, CustomerContract } from '@app/models';

@Injectable({
  providedIn: 'root'
})

export class CustomerContractsService extends InterfaceService {

  constructor(
    private http: HttpClient
  ) { super(); }

  // Get customer contracts data
  getcustomerContractFormData(type: string, customerId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/add-contract`));
  }

  // Add  new  customer contract
  addCustomerContract(type: string, customerId: number, data: object): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${type}/${customerId}/add-contract`), JSON.stringify(data));
  }

  // Get all contracts details
  getAllcustomerContractsDetails(type: string, customerId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/get-all-details`));
  }

  // Fetching customer contract basic details
  getCustomerContractBasicDetails(type: string, customerId: number, contractId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/get-basic-details`));
  }

  // Chaning customer contract status
  changeCustomerContractStatus(contractId: number, data: object): Observable<any> {
    return this.http.put(this.getApiUrl(`customer-contracts/${contractId}/change-status`), JSON.stringify(data));
  }

  // Edit customer contract basic details
  editCustomerContractBasicDetails(contractId: number, data: object): Observable<any> {
    return this.http.put(this.getApiUrl(`customer-contracts/${contractId}/edit-basic-details`), JSON.stringify(data))
      .pipe(
        map(Response => {
          Response = new CustomerContract(Response);
          return Response;
        })
      );
  }

  // Get customer contract billable rates labour/travel costs
  getBillableRatesLTCosts(contractId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/labour-travel-costs`));
  }

  // Store customer contract billable rates labour/travel costs
  saveBillableRatesLTCosts(contractId: number, data: any[]): Observable<any> {
    return this.http.put(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/labour-travel-costs/save`), JSON.stringify(data));
  }

  // Get customer contract billable rates pricing items
  getBillableRatesPricingItems(contractId: number, params: object): Observable<Object> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/pricing-items`,params))
      .pipe();
  }

  // Savecustomer contract pricing items
  saveBillableRatesPricingItems(contractId: number, data: any[]) {
    return this.http.put(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/pricing-items/save`), JSON.stringify(data));
  }

  // Get customer contract billable rates parts pricing
  getBillableRatesPartsPricing(contractId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/parts-pricing`));
  }

  // Save customer contract billable rates parts pricing
  saveBillableRatesPartsPricing(contractId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/billable-rates/parts-pricing`), JSON.stringify(data));
  }

  // Update customer contractbillable rates parts pricing record
  updateBillableRatesPartsPricing(partPriceId: number, data: Object): Observable<any> {
    return this.http.put(this.getApiUrl(`customer-contracts/billable-rates/parts-pricing/${partPriceId}`), JSON.stringify(data));
  }

  // Delete customer contract billable rates parts pricing
  deleteBillableRatesPartsPricing(partPriceId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`customer-contracts/billable-rates/parts-pricing/${partPriceId}`));
  }
  
  // Get customer contract property list
  getAppliedProperties(type: string,customerId: number,contractId: number, param?: Object): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/properties`,param));
  }
  
  // Save customer contract properties
  setAppliedProperties(type: string,customerId: number,contractId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/properties`), JSON.stringify(data));
  }

  // Delete customer contract properties
  deleteAppliedProperty(CcPropertyId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`customer-contracts/properties/${CcPropertyId}`));
  }

   // Get customer properties
   getCustomerProperties(type: string,customerId: number,contractId: number, param?: Object): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/customer-properties`,param));
  }
  
  // Get contract Linked Job Fup
  getWhatsIncludedLinkedJobFup(type: string,customerId: number,contractId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/linked-jobfup`));
  }
  // Save customer contract whats included job FUP
  saveContractsWhatsIncludedJobFUP(contractId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/whats-included/job-fup`), JSON.stringify(data));
  }

  // Delete contract whats included job FUP
  deleteContractWhatsIncludedJobFUP(jobFUPId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`customer-contracts/whats-included/job-fup/${jobFUPId}`));
  }

  // Get contract asset types against property
  getContractPropertyAssetTypes(type: string,customerId: number,contractId: number,propertyId: number, params?: object): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/${propertyId}/asset-types`,params));
  }

  // Save contract asset types against property
  addContractAssetTypes(type: string,customerId: number,contractId: number,propertyId: number,Data: Object):Observable<any>{
    return this.http.post(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/${propertyId}/asset-types`), JSON.stringify(Data));
  }

  // delete customer contract
  checkCustomerContractIsDeletable(contractId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/check-is-deletable`));
  }
    // delete customer contract
    checkCustomerContractIsCancel(contractId: number): Observable<any> {
        return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/check-is-cancel`));
    }
    // delete customer contract
  checkCustomerContractIsInvoiceNumber(contractId: number): Observable<any> {
      return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/check-is-invoice-number`));
  }


    // delete customer contract
  deleteCustomerContract(type: string,customerId: number,contractId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`customer-contracts/${type}/${customerId}/${contractId}/delete`));
  }

  // clone customer contract
  cloneCustomerContract(contractId:number): Observable<any>{
      return this.http.put(this.getApiUrl(`customer-contracts/${contractId}/clone`), {});
  }
    // save invoice settings customer contract
  saveCustomerContractInvoicingSettings(contractId:number, data: Object): Observable<any> {
      return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/invoicing/settings`), JSON.stringify(data));
  }
    // save invoice billing customer contract
  saveInvoiceBillingOption(contractId:number,data:Object): Observable<any>{
      return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/invoicing/billing-option`), JSON.stringify(data));
  }
    //  get customer contract invoice
  getCustomerContractInvoicingDataEdit(contractId: number,invoicingId:number):Observable<any>{
      return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/invoicing/edit/${invoicingId}`));
  }
   //  get  customer contract invoice
  getCustomerContractInvoicing(contractId: number):Observable<any>{
      return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/invoicing-data`));
  }
  // check customer contract invoice
  checkCustomerContractIsInvoicePayment(contractId: number): Observable<any> {
      return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/check-is-invoice`));
  }

  // save set price change in contract invoice setup
  saveCustomerContractInvoiceSetPriceChanges(contractId: number, data: Object): Observable<any> {
      return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/invoicing/set-price-changes`), JSON.stringify(data));
  }
}
