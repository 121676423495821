var SmartTableModule = /** @class */ (function () {
    function SmartTableModule() {
    }
    SmartTableModule.forRoot = function () {
        return {
            ngModule: SmartTableModule
        };
    };
    return SmartTableModule;
}());
export { SmartTableModule };
