import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerInvoicesService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerInvoicesService, _super);
    function CustomerInvoicesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // common http calls
    CustomerInvoicesService.prototype.saveConfigColumns = function (type, id, jobId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns) {
        var configColumn = { 'columns': { 'show_vat_in_grand_totals': showVatInGrandTotal, 'columns': customisableSpreadSheetColumns }, 'invoiceId': invoiceId };
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/jobs/" + jobId + "/invoices/" + invoiceId + "/updateConfigColumn"), JSON.stringify(configColumn));
    };
    // Single customer invoice
    CustomerInvoicesService.prototype.saveInvoice = function (type, id, jobId, invoiceId, invoiceType, data) {
        if (invoiceId === '') {
            return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/jobs/" + jobId + "/invoices/add/" + invoiceType), JSON.stringify(data));
        }
        else if (invoiceId !== '') {
            return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/jobs/" + jobId + "/invoices/" + invoiceId + "/update/" + invoiceType), JSON.stringify(data));
        }
    };
    CustomerInvoicesService.prototype.saveOpportunityInvoice = function (type, id, opportunityId, invoiceId, invoiceType, data) {
        if (invoiceId === '') {
            return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/opportunities/" + opportunityId + "/invoices/add/" + invoiceType), JSON.stringify(data));
        }
        else if (invoiceId !== '') {
            return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/opportunities/" + opportunityId + "/invoices/" + invoiceId + "/update/" + invoiceType), JSON.stringify(data));
        }
    };
    CustomerInvoicesService.prototype.saveOpportunityConfigColumns = function (type, id, opportunityId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns) {
        var configColumn = { 'columns': { 'show_vat_in_grand_totals': showVatInGrandTotal, 'columns': customisableSpreadSheetColumns }, 'invoiceId': invoiceId };
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/opportunities/" + opportunityId + "/invoices/" + invoiceId + "/updateOpportunityConfigureColumn"), JSON.stringify(configColumn));
    };
    // Multiple customer Invoice
    CustomerInvoicesService.prototype.saveMultipleJobInvoice = function (params, invoiceId, Data) {
        if (params["customerType"] === 'customer') {
            if (invoiceId) {
                return this.http.post(this.getApiUrl("customers/" + params["customerType"] + "/" + params["customerId"] + "/multiple_invoice/" + invoiceId + "/save"), JSON.stringify(Data));
            }
            else {
                return this.http.post(this.getApiUrl("customers/" + params["customerType"] + "/" + params["customerId"] + "/multiple_invoice/save"), JSON.stringify(Data));
            }
        }
        else {
            if (invoiceId) {
                return this.http.post(this.getApiUrl("invoice_address/" + params["invoiceAddressId"] + "/multiple_invoice/" + invoiceId + "/save"), JSON.stringify(Data));
            }
            else {
                return this.http.post(this.getApiUrl("invoice_address/" + params["invoiceAddressId"] + "/multiple_invoice/save"), JSON.stringify(Data));
            }
        }
    };
    CustomerInvoicesService.prototype.printMultipleInvoice = function (customerType, customerId, invoiceId) {
        var headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        // return this.http.get((`/customers/${customerType}/${customerId}/multiple_invoice/${invoiceId}/print`));
        return this.http
            .get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/multiple_invoice/" + invoiceId + "/print"), { headers: headers, responseType: 'blob' })
            .pipe(map(function (data) { return data; }));
    };
    // Contract invoice update
    CustomerInvoicesService.prototype.saveInvoiceContract = function (type, id, jobId, invoiceId, invoiceType, data) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/customer-contracts/" + jobId + "/invoices/" + invoiceId + "/update/" + invoiceType), JSON.stringify(data));
    };
    // Contract invoice update config
    CustomerInvoicesService.prototype.saveConfigContractColumns = function (type, id, contractId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns) {
        var configColumn = { 'columns': { 'show_vat_in_grand_totals': showVatInGrandTotal, 'columns': customisableSpreadSheetColumns }, 'invoiceId': invoiceId };
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/customer-contracts/" + contractId + "/invoices/" + invoiceId + "/updateConfigColumn"), JSON.stringify(configColumn));
    };
    CustomerInvoicesService.prototype.saveMultipleJobCreditNote = function (params, invoiceId, creditNoteId, Data) {
        if (params["customerType"] === 'customer') {
            if (creditNoteId) {
                return this.http.post(this.getApiUrl("customers/" + params["customerType"] + "/" + params["customerId"] + "/credit_note/" + creditNoteId + "/update"), JSON.stringify(Data));
            }
            else {
                return this.http.post(this.getApiUrl("customers/" + params["customerType"] + "/" + params["customerId"] + "/multiple_invoice/" + invoiceId + "/add_credit_note"), JSON.stringify(Data));
            }
        }
        else {
            if (creditNoteId) {
                return this.http.post(this.getApiUrl("invoice_address/" + params["invoiceAddressId"] + "/credit_note/" + creditNoteId + "/update"), JSON.stringify(Data));
            }
            else {
                return this.http.post(this.getApiUrl("invoice_address/" + params["invoiceAddressId"] + "/multiple_invoice/" + invoiceId + "/add_credit_note"), JSON.stringify(Data));
            }
        }
    };
    // get creditNote allocation data
    CustomerInvoicesService.prototype.getCreditNoteAllocation = function (customerType, creditNoteId, invoiceAddressID) {
        if (invoiceAddressID) {
            return this.http.get(this.getApiUrl("invoice_address/" + invoiceAddressID + "/get_cash_allocation_data/new_customer_cash_allocation_invoices?creditNoteId=" + creditNoteId));
        }
        else {
            return this.http.get(this.getApiUrl("customers/" + customerType + "/get_cash_allocation_data/new_customer_cash_allocation_invoices?creditNoteId=" + creditNoteId));
        }
    };
    CustomerInvoicesService.prototype.saveEditCreditNoteAllocation = function (params, creditNoteId, Data) {
        if (params["customerType"] === 'customer') {
            return this.http.post(this.getApiUrl("customers/" + params["customerType"] + "/" + params["customerId"] + "/credit_note/" + creditNoteId + "/allocation/save"), JSON.stringify(Data));
        }
        else {
            return this.http.post(this.getApiUrl("invoice_address/" + params["invoiceAddressId"] + "/credit_note/" + creditNoteId + "/allocation/save"), JSON.stringify(Data));
        }
    };
    CustomerInvoicesService.prototype.checkCustomerCreditNote = function (creditNoteId) {
        return this.http.get(this.getApiUrl("check_consolidated_customer_credit_note/" + creditNoteId));
    };
    CustomerInvoicesService.prototype.getInvoiceCustomTemplates = function (action, invId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("listCustomConfigTemplates?screenMode=addCustomTemplates&action=" + action + "&objects=Invoices&objectTypes=Invoices&objectTypeID=" + invId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    CustomerInvoicesService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerInvoicesService_Factory() { return new CustomerInvoicesService(i0.inject(i1.HttpClient)); }, token: CustomerInvoicesService, providedIn: "root" });
    return CustomerInvoicesService;
}(InterfaceService));
export { CustomerInvoicesService };
