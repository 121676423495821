import * as tslib_1 from "tslib";
import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { MxGraphSequenceService } from "@app/workflow-common/services/mx-graph-sequence";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { SequenceAddContactComponent } from "@app/workflow-common/sequence-add-contact/sequence-add-contact.component";
import { CsFullScreenModalComponent } from "@app/directives";
import { FullScreenHeaderComponent } from "@app/workflow-common/full-screen-header/full-screen-header.component";
import { SequenceContainerComponent } from "@app/workflow-common/sequence-container/sequence-container.component";
import { mxgraphFactory } from "mxgraph-factory";
import { Subject } from "rxjs";
var _a = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}), mxCodec = _a.mxCodec, mxUtils = _a.mxUtils, mxGraph = _a.mxGraph;
var SendSequencePopupComponent = /** @class */ (function () {
    function SendSequencePopupComponent(modalRef, modalSidepanel, httpService, mxgraphService, renderer, modalService, document) {
        this.modalRef = modalRef;
        this.modalSidepanel = modalSidepanel;
        this.httpService = httpService;
        this.mxgraphService = mxgraphService;
        this.renderer = renderer;
        this.modalService = modalService;
        this.document = document;
        this.customHeader = FullScreenHeaderComponent;
        this.select2Config = {
            placeholder: 'Choose sequence template',
            allowClear: false,
            data: []
        };
        this.select2ContactsConfig = {
            placeholder: 'Send sequence to',
            allowClear: false,
            data: [],
            minimumResultsForSearch: -1,
            formatNoMatches: function () { return 'No contact found.'; }
        };
        this.sequenceData = [];
        this.contactsData = [];
        this.isLoading = true;
        this.onChangeSequenceLoading = false;
        this.sequenceContactInstance = null;
        this.customerContacts = {};
        this.selectedContactEmailModal = '';
        this.selectedContactMobileModal = '';
        this.selectedCustomerContact = {};
        this.selectedWorkflowBlocksList = [];
        this.selectedWorkflowTemplateList = [];
        this.displaySelectedContactName = '';
        this.displaySelectedContactInfo = {};
        this.disabledContact = true;
        this.contactHasEmail = true;
        this.contactHasMobile = true;
        this.isOpenedContactDropdown = false;
        this.displayBlocks = {
            delayBlock: {
                text: 'Delay',
                iconClass: 'fas fa-history',
                textClass: null,
            },
            emailBlock: {
                text: 'Email',
                iconClass: 'fas fa-envelope',
                textClass: null,
            },
            smsBlock: {
                text: 'SMS',
                iconClass: 'fas fa-comment',
                textClass: null,
            },
            officeTaskBlock: {
                text: 'Office task',
                iconClass: 'fas fa-tasks',
                textClass: null,
            },
            notificationBlock: {
                text: 'Notification task',
                iconClass: 'fas fa-bell',
                textClass: null,
            },
            scheduledActivityBlock: {
                text: 'Schedule activity task',
                iconClass: 'fas fa-calendar-alt',
                textClass: null,
            }
        };
        this.fullScreenModalOptions = {};
        this.isOpenEditSequence = false;
        this.selectedWorkflowTemplate = null;
        this.sendingSequenceText = 'Send';
    }
    SendSequencePopupComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onClose = new Subject();
                        return [4 /*yield*/, this.getSequences()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getContactDetails()];
                    case 2:
                        _a.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SendSequencePopupComponent.prototype.setContactDropdown = function (select2) {
        this.sequenceContactInstance = select2;
    };
    SendSequencePopupComponent.prototype.getSequences = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.httpService.getWorkflowSetupSequencesList({
                        sequenceType: 'Opportunity'
                    }).toPromise().then(function (resp) {
                        _this.sequenceData = resp['workflow_sequence'];
                        _this.select2Config.data = _this.sequenceData;
                    })];
            });
        });
    };
    SendSequencePopupComponent.prototype.getContactDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                param = {
                    limit: 9999
                };
                return [2 /*return*/, this.httpService.getCustomContactsList(this.customerId, 'customer', param)
                        .toPromise().then(function (resp) {
                        if (resp['contacts'] && resp['contacts'].hasOwnProperty('contactsDetails'))
                            _this.prepareContacts(resp['contacts']['contactsDetails']);
                    })];
            });
        });
    };
    SendSequencePopupComponent.prototype.prepareContacts = function (contacts) {
        var _this = this;
        if (contacts === void 0) { contacts = []; }
        contacts.forEach(function (item) {
            var contactItem = {};
            contactItem['email'] = {
                emailAddress: item.emailaddress,
                contactId: item.id
            };
            contactItem['mobile'] = [];
            if (item['numbers_collection']) {
                contactItem['mobile'] = item['numbers_collection'].filter(function (x) { return x.type == 'Mobile'; }).map(function (x) {
                    return {
                        telephone_id: x.id,
                        code: x.code,
                        phone_number: x.phone_number,
                        type: x.type,
                        type_id: x.type_id
                    };
                });
            }
            _this.customerContacts[item.id] = contactItem;
        });
        this.contactsData = contacts
            .map(function (item) {
            return { id: item.id, text: item.contactName };
        });
        this.select2ContactsConfig.data = this.contactsData;
    };
    SendSequencePopupComponent.prototype.onChangeSequence = function () {
        var _this = this;
        var selectedTemplate = this.sequenceData.find(function (item) { return item.id == _this.selectedSequenceModal; });
        if (selectedTemplate) {
            this.selectedWorkflowTemplate = selectedTemplate;
            var dags_1 = [], emailSmsTemplatesId_1 = [], dagSource_1 = selectedTemplate['dagsource'] ? JSON.parse(selectedTemplate['dagsource']) : null;
            var getChild_1 = function (child) {
                child.forEach(function (x) {
                    if (dagSource_1['blockObjects'].hasOwnProperty(x)) {
                        dags_1.push(dagSource_1['blockObjects'][x]);
                        if (dagSource_1['dagObject'][x]) {
                            getChild_1(dagSource_1['dagObject'][x]);
                        }
                    }
                });
            };
            if (dagSource_1) {
                var initBlock = dagSource_1['dagObject']['cs_initializer'];
                getChild_1(initBlock);
                dags_1.forEach(function (dag) {
                    if (dag['name'] == 'emailBlock' || dag['name'] == 'smsBlock') {
                        emailSmsTemplatesId_1.push(dag['model']['templateId']);
                    }
                });
                this.onChangeSequenceLoading = true;
                this.httpService.getEmailSmsTemplatesByIds({ ids: emailSmsTemplatesId_1 })
                    .subscribe({
                    next: function (resp) {
                        _this.selectedWorkflowTemplateList = resp['templates'];
                        _this.selectedWorkflowBlocksList = dags_1;
                        _this.onChangeSequenceLoading = false;
                    },
                    error: function () {
                        _this.onChangeSequenceLoading = false;
                    }
                });
            }
            this.disabledContact = false;
        }
    };
    SendSequencePopupComponent.prototype.empty = function (value) {
        if (value == '' || value == null || value == false || value == '0') {
            return true;
        }
        return false;
    };
    SendSequencePopupComponent.prototype.onChangeContact = function () {
        var _this = this;
        this.isOpenedContactDropdown = false;
        this.selectedContactEmailModal = '';
        this.selectedContactMobileModal = '';
        if (!this.selectedContactModal) {
            return;
        }
        this.selectedCustomerContact = this.customerContacts[this.selectedContactModal];
        this.contactHasEmail = !this.empty(this.selectedCustomerContact['email']['emailAddress']);
        this.contactHasMobile = this.selectedCustomerContact['mobile'].length > 0;
        var selectedContact = this.contactsData.find(function (x) { return x.id == _this.selectedContactModal; });
        if (selectedContact) {
            this.displaySelectedContactName = selectedContact['text'];
        }
        if (this.contactHasEmail) {
            this.displaySelectedContactInfo['emailAddress'] = this.selectedCustomerContact['email']['emailAddress'];
            this.selectedContactEmailModal = this.selectedCustomerContact['email']['contactId'];
        }
        if (this.contactHasMobile && this.selectedCustomerContact['mobile'].length === 1) {
            var selectedMobile = this.selectedCustomerContact['mobile'][0];
            this.selectedContactMobileModal = selectedMobile.telephone_id;
            this.displaySelectedContactInfo['mobile'] = '+' + selectedMobile.code + ' ' + selectedMobile.phone_number;
            this.displaySelectedContactInfo['contact_telephone_id'] = selectedMobile.telephone_id;
        }
        // console.log('this.selectedCustomerContact', this.selectedCustomerContact, selectedContact)
    };
    SendSequencePopupComponent.prototype.onResetContact = function () {
        var _this = this;
        this.selectedContactModal = '';
        this.displaySelectedContactName = '';
        this.displaySelectedContactInfo = {};
        this.selectedContactEmailModal = '';
        this.selectedContactMobileModal = '';
        this.sequenceContactInstance.select2('val', '');
        setTimeout(function () {
            _this.isOpenedContactDropdown = true;
        }, 300);
        this.addOrRemoveAnim('mobile', 'remove');
    };
    SendSequencePopupComponent.prototype.onCheckedContact = function (event, type) {
        var _this = this;
        // this.httpService.triggerSequenceView({'action': 'reload'})
        // console.log('type', type, this.selectedCustomerContact, event.target.value);
        if (type == 'email') {
            if (event.target.checked) {
                this.selectedContactEmailModal = event.target.value;
                this.displaySelectedContactInfo['emailAddress'] = this.selectedCustomerContact['email']['emailAddress'];
            }
            else {
                this.selectedContactEmailModal = '';
                this.displaySelectedContactInfo['emailAddress'] = '';
            }
        }
        if (type == 'mobile') {
            if (event.target.checked) {
                this.selectedContactMobileModal = event.target.value;
                var selectedMobile = this.selectedCustomerContact['mobile'].find(function (x) { return x.telephone_id == _this.selectedContactMobileModal; });
                if (selectedMobile) {
                    this.displaySelectedContactInfo['mobile'] = '+' + selectedMobile.code + ' ' + selectedMobile.phone_number;
                    this.displaySelectedContactInfo['contact_telephone_id'] = selectedMobile.telephone_id;
                }
            }
        }
        var selectedContact = this.contactsData.find(function (x) { return x.id == _this.selectedContactModal; });
        if (selectedContact) {
            this.displaySelectedContactName = selectedContact['text'];
        }
        this.addOrRemoveAnim(type, 'add');
    };
    SendSequencePopupComponent.prototype.getDisplayText = function (block) {
        var model = block.model, name = block.name;
        if (name == 'delayBlock') {
            return model.interval + ' ' + model.unit;
        }
        else if (name == 'emailBlock' || name == 'smsBlock') {
            if (this.selectedWorkflowTemplateList[model['templateId']]) {
                return this.selectedWorkflowTemplateList[model['templateId']]['templateName'];
            }
        }
        else if (name == 'officeTaskBlock' || name == 'notificationBlock') {
            return model['textMessage'];
        }
        else if (name == 'scheduledActivityBlock') {
            return model['description'];
        }
        else {
            return '';
        }
    };
    SendSequencePopupComponent.prototype.onEditWorkflow = function () {
        var _this = this;
        if (!this.selectedSequenceModal) {
            return;
        }
        var selectedSequence = this.sequenceData.find(function (item) { return item.id == _this.selectedSequenceModal; });
        this.isOpenEditSequence = true;
        this.sequenceContainer.setSelectedData(selectedSequence, {
            displayContactInfo: this.displaySelectedContactInfo,
            selectedContact: this.selectedCustomerContact,
            displaySelectedContactName: this.displaySelectedContactName,
            displaySequenceName: selectedSequence['text'],
            selectedSequenceId: selectedSequence['id'],
            contactId: this.selectedContactModal,
            objectId: this.objectId,
            objectName: this.objectName,
        });
        this.csFullModal.open();
        this._customHeader.header = selectedSequence['text'];
        this._customHeader.data = selectedSequence;
        this._customHeader.actionsButtons = [];
        this._customHeader.ngOnInit();
    };
    SendSequencePopupComponent.prototype.setCustomHeaderInstance = function ($event) {
        this._customHeader = $event;
    };
    SendSequencePopupComponent.prototype.showAddContact = function (type) {
        var _this = this;
        var initialState = {
            type: type,
            contactId: this.selectedContactModal,
            title: type === 'email' ? 'Add an email address' : 'Add a mobile number',
            helpText: type === 'email' ? 'In order to send a sequence which involves emails, it’s important the ' +
                'contact has a valid email address.' : 'In order to send a sequence which involves SMS, it’s important ' +
                'the contact has a valid mobile number.'
        };
        this.modalSidepanel = this.modalService.show(SequenceAddContactComponent, {
            initialState: initialState,
            size: 'sm',
            'class': 'ng-sequence-popup',
            wrapperClass: 'cs-sequence-add-contact',
            backdrop: true,
        });
        this.modalSidepanel.content.onClose.subscribe(function (data) {
            console.log('formdata', data);
            //update customerContacts
            if (type === 'email') {
                var emailaddress = data.emailaddress;
                _this.customerContacts[_this.selectedContactModal]['email']['emailAddress'] = emailaddress;
                _this.contactHasEmail = true;
                _this.displaySelectedContactInfo['emailAddress'] = emailaddress;
                _this.selectedContactEmailModal = _this.selectedCustomerContact.email['contactId'];
            }
            else if (type === 'mobile') {
                _this.customerContacts[_this.selectedContactModal]['mobile'] = [data];
                _this.contactHasMobile = true;
                _this.displaySelectedContactInfo['mobile'] = '+' + data.code + ' ' + data.phone_number;
                _this.displaySelectedContactInfo['contact_telephone_id'] = data.id;
                _this.selectedContactMobileModal = data.id;
            }
            _this.selectedCustomerContact = _this.customerContacts[_this.selectedContactModal];
        });
    };
    SendSequencePopupComponent.prototype.addOrRemoveAnim = function (type, action) {
        var _this = this;
        if (action === void 0) { action = 'add'; }
        setTimeout(function () {
            if ((type == 'mobile' && !_this.mobileElement)) {
                return;
            }
            var container = _this.mobileElement.nativeElement;
            if (action == 'remove' && container.classList.contains('hidden')) {
                container.classList.remove('hidden');
                setTimeout(function () {
                    container.classList.remove('visuallyhidden');
                }, 20);
            }
            if (action == 'add') {
                container.classList.add('visuallyhidden');
                setTimeout(function () {
                    container.classList.add('hidden');
                }, 500);
            }
        }, 60);
    };
    SendSequencePopupComponent.prototype.readyToDiagramDisplay = function (options) {
        var diagramComponent = this.sequenceContainer.getDiagramComponent();
        this._customHeader.actionsButtons = [{
                id: 'save',
                title: 'Save and start sequence',
                click: diagramComponent.sendSequenceAction.bind(diagramComponent),
                disabled: true,
                className: 'btn btn-primary save_btn loading_btn m-0 mr-1'
            }];
        diagramComponent.popupModalOnClose = this.onClose;
    };
    SendSequencePopupComponent.prototype.onShownFSModal = function () {
        this.document.body.classList.add('modal-open', 'cs-workflow-main-section');
        this.sequenceContainer.getMetaData('sequence');
    };
    SendSequencePopupComponent.prototype.onHiddenFSModal = function () {
        var _this = this;
        this.document.body.classList.remove('modal-open', 'cs-workflow-main-section');
        this.reset();
        setTimeout(function () {
            _this._customHeader.ngOnDestroy();
            _this.csFullModal.ngOnDestroy();
        }, 300);
    };
    SendSequencePopupComponent.prototype.prepareNewXmlSource = function (xmlSource, data) {
        var doc = mxUtils.parseXml(xmlSource);
        var codec = new mxCodec(doc);
        var _mxGraph = new mxGraph();
        codec.decode(doc.documentElement, _mxGraph.getModel());
        var initializerBlock = codec.getObject('cs_initializer');
        initializerBlock.setValue({
            model: data,
            action: 'sequence_save_send'
        });
        var codec2 = new mxCodec();
        var result = codec2.encode(_mxGraph.getModel());
        _mxGraph.destroy();
        return mxUtils.getXml(result);
    };
    SendSequencePopupComponent.prototype.sendSequenceAction = function () {
        var _this = this;
        var selectedSequence = this.sequenceData.find(function (item) { return item.id == _this.selectedSequenceModal; });
        var sequence = {};
        sequence['workflowId'] = this.selectedSequenceModal;
        sequence['sequenceObjectName'] = this.objectName;
        sequence['objectId'] = this.objectId;
        sequence['dagSource'] = this.selectedWorkflowTemplate['dagsource'];
        sequence['dagsource'] = JSON.parse(selectedSequence.dagsource);
        sequence['workflowType'] = 'Sequence';
        sequence['sequenceData'] = {
            displayContactInfo: this.displaySelectedContactInfo,
            selectedContact: this.selectedCustomerContact,
            displaySelectedContactName: this.displaySelectedContactName,
            displaySequenceName: selectedSequence['text'],
            selectedSequenceId: selectedSequence['id'],
            contactId: this.selectedContactModal,
            exitCriteria: sequence['dagsource'].blockObjects.exitCriteria,
        };
        var newXmlSource = this.prepareNewXmlSource(this.selectedWorkflowTemplate['xmlsource'], sequence['initializerValue']);
        sequence['xmlSource'] = newXmlSource;
        console.log('newXml', newXmlSource);
        this.sendingSequenceText = 'Sending';
        this.httpService.postSendSequenceRequest({ sequence: sequence }, this.objectName, this.objectId)
            .subscribe({
            next: function (resp) {
                if (resp['status'] == 'success') {
                    _this.sendingSequenceText = 'Processing';
                    _this.createDagRunAction(resp['data']);
                }
                else if (resp['status'] == 'error' && resp['warning']) {
                    _this.sendingSequenceText = 'Send';
                }
            }
        });
    };
    SendSequencePopupComponent.prototype.createDagRunAction = function (data) {
        var _this = this;
        console.log('data', data);
        var sequence = {};
        sequence['workflowId'] = data['workflowId'];
        sequence['sequenceObjectName'] = this.objectName;
        sequence['objectId'] = this.objectId;
        sequence['dagRunId'] = data['dagRunId'];
        sequence['dagStatus'] = data['dagStatus'];
        this.httpService.posSequenceDagRunCreateRequest({ sequence: sequence }, this.objectName, this.objectId)
            .subscribe({
            next: function (resp) {
                _this.sendingSequenceText = 'Created';
                if (resp['status'] == 'success') {
                    _this.onClose.next(resp['data']);
                    _this.httpService.triggerSequenceView({ 'action': 'reload' });
                }
                else if (resp['status'] == 'error' && resp['warning']) {
                    _this.sendingSequenceText = 'Send';
                }
            }
        });
    };
    SendSequencePopupComponent.prototype.reset = function () {
        this.isOpenEditSequence = false;
    };
    return SendSequencePopupComponent;
}());
export { SendSequencePopupComponent };
