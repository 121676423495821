export class Customer {
    id: number;
    customerName: string;
    customerType: string;
    emailaddress: string;
    mobilenumber: string;
    telephonenumber: string;
    parentPropertyId: number;
    parentPropertyName: string;

    addressline1: string;
    addressline2: string;
    addressline3: string;
    town: string;
    county: string;
    postcode: string;

    status: string;

    workAddressName: string;
    workAddressCount: string;
    workaddressSetting: string;
    invoiceAddressId: number;

    allowBranch: boolean;
    branchesCount: number;
    contactsCount: number
    defaultStatementMessage: string;
    defaultStatementSubject: string;
    filesCount: string;
    nextServiceDate: string;
    nextServiceName: string;
    notesCount: string;
    printAndPostModule: boolean;
    sendStatements: boolean;
    serviceRemindersCount: string;
    supportsStoredCards: boolean;
    tabletype: number;
    templateDetails: string;
    accountingsystemreference: string;

    constructor(data?: Object) {
        data = data instanceof Object ? data : {};

        this.id = data['id'] || '';
        this.customerName = data['customerName'] || '';
        this.customerType = data['customerType'] || '';
        this.emailaddress = data['emailaddress'] || '';
        this.mobilenumber = data['mobilenumber'] || '';
        this.telephonenumber = data['telephonenumber'] || '';
        this.parentPropertyId = data['parentPropertyId'] || '';
        this.parentPropertyName = data['parentPropertyName'] || '';

        this.addressline1 = data['addressline1'] || '';
        this.addressline2 = data['addressline2'] || '';
        this.addressline3 = data['addressline3'] || '';
        this.town = data['town'] || '';
        this.county = data['county'] || '';
        this.postcode = data['postcode'] || '';

        this.status = data['status'] || '';

        this.workAddressName = data['workAddressName'] || '';
        this.workAddressCount = data['workAddressCount'] || '';
        this.workaddressSetting = data['workaddressSetting'] || '';
        this.invoiceAddressId = data['invoiceAddressId'] || '';

        this.allowBranch = data['allowBranch'] || '';
        this.branchesCount = data['branchesCount'] || '';
        this.contactsCount = data['contactsCount'] || '';
        this.defaultStatementMessage = data['defaultStatementMessage'] || '';
        this.defaultStatementSubject = data['defaultStatementSubject'] || '';
        this.filesCount = data['filesCount'] || '';
        this.nextServiceDate = data['nextServiceDate'] || '';
        this.nextServiceName = data['nextServiceName'] || '';
        this.notesCount = data['notesCount'] || '';
        this.printAndPostModule = data['printAndPostModule'] || '';
        this.sendStatements = data['sendStatements'] || '';
        this.serviceRemindersCount = data['serviceRemindersCount'] || '';
        this.supportsStoredCards = data['supportsStoredCards'] || '';
        this.tabletype = data['tabletype'] || '';
        this.templateDetails = data['templateDetails'] || '';
        this.accountingsystemreference = data['accountingsystemreference'] || '';
    }
}