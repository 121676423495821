import { OnInit, ElementRef, Renderer, EventEmitter } from '@angular/core';
var CsModalAssetComponent = /** @class */ (function () {
    function CsModalAssetComponent(_hostRef, _renderer) {
        this._hostRef = _hostRef;
        this._renderer = _renderer;
        this.onOpen = new EventEmitter();
        this.onClose = new EventEmitter();
        this.isDisabledBackdrop = true;
    }
    CsModalAssetComponent.prototype.ngOnInit = function () {
        this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
    };
    Object.defineProperty(CsModalAssetComponent.prototype, "showModal", {
        set: function (show) {
            if (show && this.modalState !== 'open')
                this.open();
            else if (this.modalState !== 'closed')
                this.close();
        },
        enumerable: true,
        configurable: true
    });
    CsModalAssetComponent.prototype.open = function () {
        var _this = this;
        jQuery(this._hostRef.nativeElement).appendTo("body").modal("show")
            .on('hide', function () {
            _this.onClose.emit();
        });
        if (this.isDisabledBackdrop) {
            jQuery('body').find('.modal-backdrop').off('click');
        }
        this.modalState = 'open';
        this.onOpen.emit();
    };
    CsModalAssetComponent.prototype.close = function () {
        jQuery(this._hostRef.nativeElement).modal("hide");
        this.modalState = 'closed';
    };
    CsModalAssetComponent.prototype.ngOnDestroy = function () {
        // Remove content from body on destroy
        jQuery(this._hostRef.nativeElement).remove();
    };
    return CsModalAssetComponent;
}());
export { CsModalAssetComponent };
