var HandsontableModule = /** @class */ (function () {
    function HandsontableModule() {
    }
    HandsontableModule.forRoot = function () {
        return {
            ngModule: HandsontableModule
        };
    };
    return HandsontableModule;
}());
export { HandsontableModule };
