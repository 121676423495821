import {Component, Input, OnInit} from '@angular/core';
import {InputModel} from "@app/shared/service-reminder-communication/services/input-model";
import {TranslateService} from "@ngx-translate/core";
import {ScHttpServiceService} from "@app/shared/service-reminder-communication/services/sc-http-service.service";
import {EventDispatcherService} from "@app/shared/service-reminder-communication/services/event-dispatcher.service";

export const isEmpty = (input:any) => {
    if(typeof input == 'object') {
        return Object.keys(input).length === 0;
    }
    return !!input;
}

@Component({
  selector: 'app-sc-layout',
  templateUrl: './sc-layout.component.html',
  styles: []
})
export class ScLayoutComponent implements OnInit {

    @Input() objectName: string = '';

    @Input() screenType: string = '';
    @Input() sourceId: number = 0;
    @Input() selectedTemplateData: any = null;
    @Input() viewMode: boolean;

    emailTemplateList: any = [];
    smsTemplateList: any = [];
    emailTemplateListIndexById: object = {};
    smsTemplateListIndexById: object = {};
    singlePropertyInput: any = {};
    multiplePropertyInput: any = {};
    defaultTemplates: any = {};
    clientDetails: any = {};

    private _reservedSinglePropertyInput:any = {};
    private _reservedMultiplePropertyInput:any = {};

    constructor(
        private translate: TranslateService,
        private httpService: ScHttpServiceService,
        private eventDispatcher: EventDispatcherService,
    ) { }

    async ngOnInit() {
        // await this.getEmailTemplates();
        // await this.getSmsTemplates();
        await this.getServiceReminderConfig();
        if (isEmpty(this.singlePropertyInput)) {
            let singlePropertyMessage = this.loadMessage(1);
            this.singlePropertyInput = new InputModel(
                this.sourceId,
                0,
                0,
                this.translate.instant('First.Communication'),
                1,
                this.screenType,
                0,
                singlePropertyMessage,
                []
            );
            let defaultMessage = Object.keys(singlePropertyMessage).length ? singlePropertyMessage : {};
            this.singlePropertyInput._intervalOld = null;
            this.singlePropertyInput.setDefaultMessages(defaultMessage);
            this.singlePropertyInput.status = 'pending';
            this.singlePropertyInput.completedTasksCount=0;
        }
        if (isEmpty(this.multiplePropertyInput)) {
            let multiplePropertyMessage = this.loadMessage(2);
            this.multiplePropertyInput = new InputModel(
                this.sourceId,
                0,
                0,
                this.translate.instant('First.Communication'),
                2,
                this.screenType,
                0,
                multiplePropertyMessage,
                []
            );
            this.multiplePropertyInput._intervalOld = null;
            let defaultMessage = Object.keys(multiplePropertyMessage).length ? multiplePropertyMessage : {};
            this.multiplePropertyInput.setDefaultMessages(defaultMessage);
            this.multiplePropertyInput.status = 'pending';
            this.multiplePropertyInput.completedTasksCount=0;
        }


        // this.setEmailTemplateList(this.emailTemplateList);
        // this.setSmsTemplateList(this.smsTemplateList);
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['init'],{
            sourceId: this.sourceId,
            smsTemplateList: this.smsTemplateList,
            emailTemplateList: this.emailTemplateList,
            emailTemplateListIndexById: this.emailTemplateListIndexById,
            smsTemplateListIndexById: this.smsTemplateListIndexById,
            singlePropertyInput: this.singlePropertyInput,
            multiplePropertyInput: this.multiplePropertyInput,
            activeTab: 'single',
            defaultTemplates: this.defaultTemplates,
            clientDetails: this.clientDetails,
        })
    }

    loadMessage(propertyType){
        if(this.selectedTemplateData){
            let index = this.selectedTemplateData.findIndex(x => x.propertyType == propertyType);
            if(index !== -1){
                return this.selectedTemplateData[index].messages
            }
            return {};
        }
        return {};
    }

    async getServiceReminderConfig() {
        const serviceReminderConfig = {
            sourceId: this.sourceId,
            screenType: this.screenType,
            objectName: this.objectName,
        };
        return new Promise( (resolve, reject) => {
            this.httpService.serviceReminderConfig({serviceReminderConfig}, this.screenType)
            .toPromise()
            .then( (resp) => {
                if(resp['location'] && typeof resp['location'] == 'string') {
                    this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                }
                this.clientDetails = resp['clientDetails'];
                if(resp['serviceReminderConfig']) {
                    let data = resp['serviceReminderConfig'];
                    if(!isEmpty(data['singlePropertyInput'])) {
                        this.singlePropertyInput = this.setInputModel(data['singlePropertyInput']);
                        this._reservedSinglePropertyInput = data['singlePropertyInput'];
                    }
                    if(!isEmpty(data['multiplePropertyInput'])) {
                        this.multiplePropertyInput = this.setInputModel(data['multiplePropertyInput']);
                        this._reservedMultiplePropertyInput = data['multiplePropertyInput'];
                    }
                    this.defaultTemplates = data['defaultTemplates'];
                    /*this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['dispatchReservedData'], {
                        reservedSinglePropertyInput: JSON.parse(JSON.stringify(this._reservedSinglePropertyInput)),
                        reservedMultiplePropertyInput: JSON.parse(JSON.stringify( this._reservedMultiplePropertyInput))
                    })*/
                }
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        });
    }

    setInputModel(data: object = {}): InputModel {
        let input = new InputModel(
            data['sourceId'],
            data['serviceReminderConfigId'],
            data['communicationId'],
            data['name'],
            data['propertyType'],
            this.screenType,
            data['interval'],
            data['messages'],
            []
        );
        if(data['status']) {
            input.status = data['status'];
        }
        if(data['completedTasksCount']) {
            input.completedTasksCount = data['completedTasksCount'];
        }
        if(data['followUpMessage']) {
            input.setFollowupInputModel(data['followUpMessage']);
        }
        if(data['_intervalOld']) {
            input._intervalOld = data['_intervalOld'];
        }
        input.itemName = data['name'];
        return input;
    }

    async getEmailTemplates() {
        return new Promise((resolve, reject) => {
            this.httpService.getEmailTemplate()
            .toPromise()
            .then( (resp) => {
                if(resp['location'] && typeof resp['location'] == 'string') {
                    this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                }
                else if(resp['emailTemplates']) {
                    this.emailTemplateList = resp['emailTemplates']
                }
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        });
    }

    async getSmsTemplates() {
        return new Promise( (resolve, reject ) => {
            this.httpService.getSMSTemplate()
            .toPromise()
            .then( (resp ) => {
                if(resp['location'] && typeof resp['location'] == 'string') {
                    this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                }
                else if(resp['smsTemplates']) {
                    this.smsTemplateList = resp['smsTemplates']
                }
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        })
    }

    setEmailTemplateList(data: any) {
        if(!data.length) {
            return;
        }
        data.forEach( (item: any) => {
            this.emailTemplateListIndexById[item.id] = item;
        });
    }

    setSmsTemplateList(data: any) {
        if(!data.length) {
            return;
        }
        data.forEach( (item: any) => {
            this.smsTemplateListIndexById[item.id] = item;
        });
    }

}
