import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {
    ExitCriteriaSidePanelComponent
} from "@app/workflow-common/exit-criteria-side-panel/exit-criteria-side-panel.component";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {SettingsTableComponent} from "@app/smart_table/settings-table/settings-table.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppValidators} from "@app/directives";

@Component({
  selector: 'app-exit-condition-view',
  templateUrl: './exit-condition-view.component.html',
  styles: []
})
export class ExitConditionViewComponent implements OnInit {
    @Input() exitCondition: any;
    @Input() data: any;
    @Output() selectedExitCriteria: EventEmitter<any> = new EventEmitter();

    tableInstance: SettingsTableComponent;

    constructor(
      private modalService: SidepanelModalService,
      public httpService: WorkflowhttpService,
      private fb: FormBuilder,

  ) { }
    modalRef: BsModalRef;
    hasExitData = false;
    exitCriteria: any;

    selectedExitConditionData:any;
    actions: any;
    smartTableSettings: any;
    smartTableInstance: any;
    exitConditionMetaData: any;
    action: any;
    dataList: any=[];
    loading:boolean = true;
    modalType: any;
    confirmText: any;
    buttonText: any;
    validationMessage: any;
    deleteConfirmationForm: FormGroup;
    showDeleteConfirmationModal: any;
    deleteConfirmTextInput: string = '';
    inValidConfirmText: boolean = false;
    deleteData: any;


    ngOnInit() {
      this.loading = true;
      this.deleteConfirmationForm = this.fb.group({
          'confirmationText': ['', [Validators.required, AppValidators.confirmationTextValidator('DELETE')]]
      });
      this.httpService.getExitConditions(this.data.type, this.data.id)
          .subscribe( {
              next: (resp: any) => {
                  this.exitCriteria = resp.exitCriteria;
                  this.dataList = resp.exitCriteriaList;
                  this.loading = false;

              }
          })
      this.exitConditionMetaData = {
          columns: [{
              col_name: "text",
              title: 'Criteria',
              pos: 1,
              active: true,
          }],
          limit: 5,
          sort: null
      }

      this.actions = [
          {
              id: 'delete',
              title: 'Delete',
              access:[],
              visible: (data,type,row) => {
                  return !row.default;
              },
              onClick: (value) => {
                  this.showDeleteConfirmationModal = true;
                  this.deleteData = value.id;
              }
          }];
  }
    setSmartTableInstance($event: any) {
        this.smartTableInstance = $event;
    }
    addOrEditExitConditions(data,index:number = -1){
        const initialState = {
            list: [],
            data: !data ? null : data,
            exitCondition: this.exitCondition,
            exitCriteria: this.exitCriteria,
            index: index,
        };
        this.modalRef = this.modalService.show(ExitCriteriaSidePanelComponent, {
            initialState: initialState
        });
        this.modalRef.content.onClose.subscribe(result => {
            if (result) {
                this.hasExitData = true;
                this.selectedExitConditionData = result;
                this.saveExitCondition(this.selectedExitConditionData);
                this.selectedExitCriteria.emit(this.selectedExitConditionData);
            }
        });
    }
    saveExitCondition(condition){

        this.httpService.postExitConditionRequest({
            exitCriteria: condition,
            screen: 'settings'
        }, this.data.type, this.data.id)
            .subscribe( {
                next: (resp: any) => {
                    this.ngOnInit();
                }
            })
    }
    deleteExitCriteria(){
        if(this.deleteConfirmationForm.valid){
            this.action = 'deleting';
            this.httpService.deleteExitConditions(this.deleteData,
                this.data.type, this.data.id)
                .subscribe( {
                    next: (resp: any) => {
                        this.showDeleteConfirmationModal = false;
                        this.action = '';
                        this.ngOnInit();
                    }
                })
        }
    }
    setTableInstance($event){
        this.tableInstance = $event;
    }
    onConfirmationModal(){
        this.showDeleteConfirmationModal = false;
        this.deleteConfirmationForm.reset();
    }
}
