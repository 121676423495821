
<ng-container *ngIf="displayOption">
    <div class="modal-header pricing-view-padding">
        <h4 class="modal-title pricing-view-title" *ngIf="!transferToJob">Advance style view </h4>
        <h4 class="modal-title pricing-view-title" *ngIf="transferToJob">Transfer job notes </h4>
    </div>
    <div class="modal-bodys scrollbar element-advance-style">
        <div class="modal-content" >
            <div class="pad-3 formatting-option modal-body">
               <div class="over-all-content-block">
                   <ng-container *ngIf="hasShowSize && !imageStyling && !transferToJob">
                       <div class="flex-row">
                           <div class="bold" translate>Size</div>
                       </div>
                       <div class="flex-row flex-row-direction sizing-section">
                           <div class="flex-row mb-10 flex-0">
                               <div translate>Width</div>
                               <div class="input-groups">
                                   <input class="form-control number-input-fields" type="number" nxNoScroll [readonly]="border_width_dir.open" autocomplete="off" [(ngModel)]="block_size.width" (keyup.arrowDown)="marginDown = true" (keyup.arrowUp)="marginDown = false"  (ngModelChange)="setSizing($event,'width')"  onwheel="return false" />
                                   <div class="input-groups-append">
                                       <span class="input-groups-text" [ngStyle]="{'background-color': border_width_dir.open ? '#f3f3f3': '#fff' }">px</span>
                                   </div>
                               </div>
                           </div>
                           <div class="flex-row mb-10 flex-0">
                               <div translate>Height</div>
                               <div class="input-groups">
                                   <input class="form-control number-input-fields" type="number" nxNoScroll [readonly]="border_width_dir.open" autocomplete="off" [(ngModel)]="block_size.height" (keyup.arrowDown)="heightDown = true" (keyup.arrowUp)="heightDown = false" (ngModelChange)="setSizing($event,'height')"  onwheel="return false" />
                                   <div class="input-groups-append">
                                       <span class="input-groups-text" [ngStyle]="{'background-color': border_width_dir.open ? '#f3f3f3': '#fff' }">px</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <ng-container *ngIf="maximumWidth" >
                           <span class="margin-error-message">This is the maximum width that can be set to this element.</span>
                       </ng-container>
                       <div *ngIf="maximumHeight">
                           <span class="margin-error-message">This is the maximum height that can be set to this element</span>
                       </div>
                   </ng-container>


                   <!-- Start Background -->
                   <ng-container *ngIf="!imageStyling && !transferToJob">
                   <div class="flex-row">
                       <div class="bold" translate>Background</div>
                   </div>
                   <!-- Background Color -->
                   <div class="flex-row mb-10 margin-padding-border">
                       <div translate>background.color</div>
                       <div class="width-inherit">
                           <div class="cs-color-palatte dropdown">
                               <ng-container *ngIf="changedBackgroundColor == 'No background'">
                                   <div class="cross">
                                       <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                           <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                       </svg>
                                   </div>
                               </ng-container>
                               <ng-container *ngIf="changedBackgroundColor !== 'No background'">
                                   <span class="color-box color-box-top-align" data-toggle="dropdown" [style.background]="changedBackgroundColor"></span>
                               </ng-container>
                               <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(changedBackgroundColor)" [(ngModel)]="changedBackgroundColor">
                               <div class="dropdown-menu dropdown-height">
                                   <color-palatte [data]="myValue" (colorValue)="getColor($event,'background')"></color-palatte>
                               </div>
                           </div>
                       </div>
                   </div>
                   <!-- Background Image -->
                   <div class="flex-row margin-top-border">
                       <div translate>background.Image</div>
                       <div>
                           <div class="img-display-section" *ngIf="textElementBackgroundImage">
                               <img [src]="textElementBackgroundImage">
                           </div>
                           <div class="btn-section-image">
                               <a href="javascript:void(0)" [ngClass]="{'margin-right-align': textElementBackgroundImage}" (click)="openImages()">
                                   <span *ngIf="!textElementBackgroundImage" translate>select.image</span>
                                   <span *ngIf="textElementBackgroundImage" translate>Update.image</span>
                               </a>
                               <a href="javascript:void(0)" >
                                   <span *ngIf="textElementBackgroundImage" (click)="removeTextBg()" translate>Remove.image</span>
                               </a>
                           </div>

                       </div>
                   </div>
                   <!-- End Background -->
                   <!-- Start Border -->
                   <div class="flex-row">
                       <div class="bold m-10 margin-top-border" translate>Border</div>
                   </div>
                   <!-- Border Thickness -->
                   <div class="flex-row margin-bottom-align mb-10 ">
                       <div translate>border.thickness</div>
                       <div class="input-groups">
                           <input class="form-control number-input-fields border-thinkness-selector" (change)="defaultColor($event)" (input)="defaultColor($event)" type="number" nxNoScroll [readonly]="border_width_dir.open" autocomplete="off"
                                  [(ngModel)]="border_width" min="0" (ngModelChange)="setWidth($event,'border-width')"  onwheel="return false" />
                           <div class="input-groups-append">
                                <span class="input-groups-text"
                                      [ngStyle]="{'background-color': border_width_dir.open ? '#f3f3f3': '#fff' }">
                                    px
                                </span>
                           </div>
                           <span class="ml-10" (click)="toggle('border_width')"><i id="border-width-icon"
                                                                                   class="fas fa-expand-wide ft-22"></i></span>
                       </div>
                       <div *ngIf="border_width_dir.open" class="expand-flex">
<!--                           <i class="fas fa-border-top  mrt-5 ft-16"></i>-->
                           <div>Top border thickness</div>
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_width_dir.top"
                                      (ngModelChange)="setWidth($event,'border-top-width')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
<!--                           <i class="fas fa-border-right  mrt-5 ft-16"></i>-->
                           <div>Right border thickness</div>
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_width_dir.right"
                                      (ngModelChange)="setWidth($event, 'border-right-width')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Bottom border thickness</div>
<!--                           <i class="fas fa-border-bottom  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_width_dir.bottom"
                                      (ngModelChange)="setWidth($event, 'border-bottom-width')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Left border thickness</div>
<!--                           <i class="fas fa-border-left  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_width_dir.left"
                                      (ngModelChange)="setWidth($event, 'border-left-width')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                       </div>
                   </div>
                   <!-- Border Style -->
                   <div class="flex-row margin-top-align mb-10">
                       <div translate>border.style</div>
                       <div class="top-margin">
                           <select [(ngModel)]="border_style" class="select-width margin-bottom-align" (ngModelChange)="setStyle($event)">
                               <option value="solid">Solid</option>
                               <option value="dashed">Dashed</option>
                               <option value="dotted">Dotted</option>
                           </select>
                       </div>
                   </div>
                   <!-- Border Colour -->
                   <div class="flex-row mb-10">
                       <div translate>border.color</div>
                       <div class="width-inherit">
                           <div class="cs-color-palatte dropdown">
                               <ng-container *ngIf="changedBorderColor == 'No border'">
                                   <div class="cross">
                                       <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                           <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                       </svg>
                                   </div>
                               </ng-container>
                               <ng-container *ngIf="changedBorderColor !== 'No border'">
                                   <span class="color-box color-box-top-align" data-toggle="dropdown" [style.background]="borderColorStyle"></span>
                               </ng-container>
                               <input class="picker-color" autocomplete="off" data-toggle="dropdown" readonly (click)="detectChanges(changedBorderColor)" [(ngModel)]="changedBorderColor">
                               <div class="dropdown-menu dropdown-height">
                                   <color-palatte [data]="myValue" (colorValue)="getColor($event,'border')"></color-palatte>
                               </div>
                           </div>
                       </div>
                   </div>
                   <!-- Border radius -->
                   <div class="flex-row margin-top-border">
                       <div translate>border.radius</div>
                       <div class="input-groups">
                           <input class="form-control number-input-fields border-radius-selector" type="number" nxNoScroll [readonly]="border_radius_dir.open" autocomplete="off"
                                  [(ngModel)]="border_radius" (ngModelChange)="setRadius($event, 'border-radius')" />
                           <div class="input-groups-append">
                <span class="input-groups-text"
                      [ngStyle]="{'background-color': border_radius_dir.open ? '#f3f3f3': '#fff' }">
                    px
                </span>
                           </div>
                           <span class="ml-10" (click)="toggle('border_radius')"><i id="border-radius-icon"
                                                                                    class="fas fa-expand-wide ft-22"></i></span>
                       </div>
                       <div *ngIf="border_radius_dir.open" class="expand-flex">
                           <div>Top left border radius</div>
<!--                           <i class="fas fa-border-style  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_radius_dir.topLeft"
                                      (ngModelChange)="setRadius($event, 'border-top-left-radius')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Top right border radius</div>
<!--                           <i class="fas fa-border-style  mrt-5 ft-16" style="transform: rotate(90deg);"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_radius_dir.topRight"
                                      (ngModelChange)="setRadius($event,'border-top-right-radius')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Bottom right border radius</div>
<!--                           <i class="fas fa-border-style-alt  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_radius_dir.bottomRight"
                                      (ngModelChange)="setRadius($event, 'border-bottom-right-radius')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Bottom left border radius</div>
<!--                           <i class="fas fa-border-style-alt  mrt-5 ft-16" style="transform: rotate(90deg);"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="border_radius_dir.bottomLeft"
                                      (ngModelChange)="setRadius($event, 'border-bottom-left-radius')" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                       </div>
                   </div>
                   <!-- End Border -->
                   <!-- Start padding/margin -->
                   <div class="flex-row showPadding">
                       <div class="bold m-10" translate>Padding.margin</div>
                   </div>
                   <div class="flex-row margin-bottom-align mb-10 showPadding">
                       <div translate>Padding</div>
                       <div class="input-groups">
                           <input class="form-control number-input-fields padding-selector" type="number" nxNoScroll [readonly]="padding_dir.open" autocomplete="off"
                                  (keyup.arrowUp)="paddingDown = false" (keyup.arrowDown)="paddingDown = true" [(ngModel)]="padding" (ngModelChange)="setPadding($event, 'padding')"  onwheel="return false" />
                           <div class="input-groups-append">
                <span class="input-groups-text" [ngStyle]="{'background-color': padding_dir.open ? '#f3f3f3': '#fff' }">
                    px
                </span>
                           </div>
                           <span class="ml-10" (click)="toggle('padding')"><i id="padding-icon"
                                                                              class="fas fa-expand-wide ft-22"></i></span>
                       </div>
                       <div *ngIf="padding_dir.open" class="expand-flex">
                           <div>Top padding</div>
<!--                           <i class="fas fa-border-top  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="padding_dir.top" (keyup.arrowUp)="paddingDown = false" (keyup.arrowDown)="paddingDown = true"
                                      (ngModelChange)="setPadding($event, 'padding-top')"  onwheel="return false" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Right padding</div>
<!--                           <i class="fas fa-border-right  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="padding_dir.right" (keyup.arrowUp)="paddingDown = false" (keyup.arrowDown)="paddingDown = true"
                                      (ngModelChange)="setPadding($event, 'padding-right')"  onwheel="return false" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Bottom padding</div>
<!--                           <i class="fas fa-border-bottom  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="padding_dir.bottom" (keyup.arrowUp)="paddingDown = false" (keyup.arrowDown)="paddingDown = true"
                                      (ngModelChange)="setPadding($event, 'padding-bottom')"  onwheel="return false" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                           <div>Left padding</div>
<!--                           <i class="fas fa-border-left  mrt-5 ft-16"></i>-->
                           <div class="input-groups">
                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"
                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="padding_dir.left" (keyup.arrowUp)="paddingDown = false" (keyup.arrowDown)="paddingDown = true"
                                      (ngModelChange)="setPadding($event, 'padding-left')"  onwheel="return false" />
                               <div class="input-groups-append">
                    <span class="input-groups-text">
                        px
                    </span>
                               </div>
                           </div>
                       </div>
                       <div *ngIf="paddingError">
                        <span  class="margin-error-message">Maximum limit that can be set to this element has been exceeded.</span>
                    </div>
                   </div>
                   </ng-container>
                   <div class="flex-row mb-20 margin-top-align showMargin" *ngIf="!transferToJob">
<!--                       <div translate>Margin</div>-->
<!--                       <div class="input-groups">-->
<!--                           <input class="form-control number-input-fields margin-selector" type="number" nxNoScroll [readonly]="margin_dir.open" autocomplete="off"-->
<!--                                  [(ngModel)]="margin" (keyup.arrowUp)="marginDown = false" (keyup.arrowDown)="marginDown = true" (ngModelChange)="setMargin($event, 'margin')" />-->
<!--                           <div class="input-groups-append">-->
<!--                <span class="input-groups-text" [ngStyle]="{'background-color': margin_dir.open ? '#f3f3f3': '#fff' }">-->
<!--                    px-->
<!--                </span>-->
<!--                           </div>-->
<!--                           <span class="ml-10" (click)="toggle('margin')"><i id="margin-icon"-->
<!--                                                                             class="fas fa-expand-wide ft-22"></i></span>-->
<!--                       </div>-->
<!--                       <div *ngIf="margin_dir.open" class="expand-flex">-->
<!--                           <div *ngIf="!imageStyling">Top margin</div>-->
<!--&lt;!&ndash;                           <i *ngIf="!imageStyling" class="fas fa-border-top  mrt-5 ft-16"></i>&ndash;&gt;-->
<!--                           <div class="input-groups" *ngIf="!imageStyling">-->
<!--                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"-->
<!--                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="margin_dir.top" (keyup.arrowUp)="marginDown = false" (keyup.arrowDown)="marginDown = true"-->
<!--                                      (ngModelChange)="setMargin($event, 'margin-top')" />-->
<!--                               <div class="input-groups-append">-->
<!--                                    <span class="input-groups-text">px</span>-->
<!--                               </div>-->
<!--                           </div>-->
<!--                           <div>Right margin</div>-->
<!--&lt;!&ndash;                           <i class="fas fa-border-right  mrt-5 ft-16"></i>&ndash;&gt;-->
<!--                           <div class="input-groups">-->
<!--                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"-->
<!--                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="margin_dir.right" (keyup.arrowUp)="marginDown = false" (keyup.arrowDown)="marginDown = true"-->
<!--                                      (ngModelChange)="setMargin($event, 'margin-right')" />-->
<!--                               <div class="input-groups-append">-->
<!--                                    <span class="input-groups-text">px</span>-->
<!--                               </div>-->
<!--                           </div>-->
<!--                           <div *ngIf="!imageStyling">Bottom margin</div>-->
<!--&lt;!&ndash;                           <i *ngIf="!imageStyling" class="fas fa-border-bottom  mrt-5 ft-16"></i>&ndash;&gt;-->
<!--                           <div class="input-groups" *ngIf="!imageStyling">-->
<!--                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"-->
<!--                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="margin_dir.bottom" (keyup.arrowUp)="marginDown = false" (keyup.arrowDown)="marginDown = true"-->
<!--                                      (ngModelChange)="setMargin($event, 'margin-bottom')" />-->
<!--                               <div class="input-groups-append">-->
<!--                                    <span class="input-groups-text">px</span>-->
<!--                               </div>-->
<!--                           </div>-->
<!--                           <div>Left margin</div>-->
<!--&lt;!&ndash;                           <i class="fas fa-border-left  mrt-5 ft-16"></i>&ndash;&gt;-->
<!--                           <div class="input-groups">-->
<!--                               <input class="form-control number-input-fields ft-w-30" (focus)="addBorder($event)" (blur)="removeBorder($event)"-->
<!--                                      type="number" nxNoScroll autocomplete="off" [(ngModel)]="margin_dir.left" (keyup.arrowUp)="marginDown = false" (keyup.arrowDown)="marginDown = true"-->
<!--                                      (ngModelChange)="setMargin($event, 'margin-left')" />-->
<!--                               <div class="input-groups-append">-->
<!--                                    <span class="input-groups-text">px</span>-->
<!--                               </div>-->
<!--                           </div>-->
<!--                       </div>-->
<!--                       <div *ngIf="marginError">-->
<!--                           <span  class="margin-error-message">Maximum limit that can be set to this element has been exceeded.</span>-->
<!--                       </div>-->
                   </div>
                   <div *ngIf="transferToJob">
                       <div class="job-notes-text">
                           <span>Under what scenario should this text block get added to the job note</span>
                       </div>
                       <div class="dropdown-toggle" data-toggle="dropdown">
                           <input class="dropdown-input" autocomplete="off" data-toggle="dropdown" (click)="closeDropdown = false;addFocusClass()" readonly [(ngModel)]="jobNotesValue">
                           <i class="drop-down-icon fa fa-angle-down"></i>
                           <div class="dropdown-menu jobnotes-dropdown" id="jobNotes" *ngIf="!closeDropdown" (mouseenter)="removeFocusClass()">
                               <li class="dropdown-item" data-value='' (click)="enableSave('','','-- Please select --',false)">&nbsp;&nbsp;&nbsp;-- Please select --</li>
                               <li class="dropdown-item" *ngFor="let data of displayArray" tooltip="{{data.displayName}}" placement="bottom" id="{{ data.id }}{{data.type}}" (click)="enableSave(data.id,data.type,data.displayName, data.disabled)" [ngClass]="{'disable-cursor': data.disabled}">&nbsp;&nbsp;&nbsp;If <span [ngClass]="{'text-font': !data.disabled}">{{data.name}}</span> chosen by customer</li>
                           </div>
                       </div>
                   </div>
               </div>
            </div>

            <div class="modal-footer ">
                <a class="btn" href="javascript:void(0)" (click)="cancel()">
                    <span id="cancel-styles">Cancel</span>
                </a>
                <button class="btn btn-primary" (click)="save()" [disabled]="marginError || paddingError">
                    <span id="save-styles">Save</span>
                </button>
            </div>
        </div>
    </div>
</ng-container>

