import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

//--
import { HybridFactory } from '../hybrid-factory';

@Injectable({
  providedIn: 'root'
})
export class UrlPatternGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Validating url params
    if (next.data.hasOwnProperty('urlPatterns') && next.data['urlPatterns'] instanceof Object) {
      let params = next.params;

      Object.entries(params).forEach(([param, value]) => {
        if (next.data['urlPatterns'].hasOwnProperty(param)) {
          let exp = new RegExp(`^${next.data['urlPatterns'][param]}$`, 'g');

          if (!exp.test(value)) {
            HybridFactory.navigate('/404');
            return false;
          }
        }
      });
    }

    return true;
  }
}
