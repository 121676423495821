import { InjectionToken } from '@angular/core';
var CsScrollbarConfig = /** @class */ (function () {
    function CsScrollbarConfig(config) {
        this.assign(config);
    }
    CsScrollbarConfig.prototype.assign = function (config) {
        if (config === void 0) { config = {}; }
        for (var key in config) {
            this[key] = config[key];
        }
    };
    return CsScrollbarConfig;
}());
export { CsScrollbarConfig };
export var CS_SCROLLBAR_CONFIG = new InjectionToken('CS_SCROLLBAR_CONFIG');
export var CsScrollbarEvents = [
    'psScrollY',
    'psScrollX',
    'psScrollUp',
    'psScrollDown',
    'psScrollLeft',
    'psScrollRight',
    'psYReachEnd',
    'psYReachStart',
    'psXReachEnd',
    'psXReachStart'
];
