import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
var PartsAndPricesService = /** @class */ (function (_super) {
    tslib_1.__extends(PartsAndPricesService, _super);
    function PartsAndPricesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    PartsAndPricesService.prototype.getPartsAndPricesMetadata = function (params) {
        return this.http
            .get(this.getApiUrl("parts_and_prices_metadata", params));
    };
    PartsAndPricesService.prototype.addEditPartsAndPrices = function (data, id) {
        if (id === 0) {
            return this.http.post(this.getApiUrl("parts_and_prices/add"), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl("parts_and_prices/edit/" + id), JSON.stringify(data));
        }
    };
    PartsAndPricesService.prototype.checkUniqueName = function (params) {
        return this.http
            .get(this.getApiUrl("partsAndPricesExits", params));
    };
    PartsAndPricesService.prototype.beforeDelete = function (id) {
        return this.http
            .get(this.getApiUrl("deletePartsAndPrices/" + id));
    };
    PartsAndPricesService.prototype.deletePartRequest = function (params) {
        return this.http
            .post(this.getApiUrl("delete_partsandprices"), params);
    };
    PartsAndPricesService.prototype.archivePartRequest = function (params) {
        return this.http
            .post(this.getApiUrl("archive_partsandprices"), params);
    };
    PartsAndPricesService.prototype.checkPassword = function (params) {
        return this.http
            .post(this.getApiUrl("validUserPassword?___noPageloading=true"), params);
    };
    PartsAndPricesService.prototype.checkExistsUsername = function (query) {
        return this.http
            .get(this.getApiUrl("validUserName?___noPageloading=true&username=" + query));
    };
    PartsAndPricesService.prototype.doMassDeleteArchive = function (params) {
        return this.http
            .post(this.getApiUrl("massDeleteArchive"), params);
    };
    PartsAndPricesService.prototype.importRecordsPartsAndPrices = function (params) {
        return this.http
            .post(this.getApiUrl("import_records_from_csv/parts_and_prices"), params);
    };
    PartsAndPricesService.prototype.importRecordsSupplierPartsAndPrices = function (params) {
        return this.http
            .post(this.getApiUrl("import_records_from_csv/supplier_part_and_prices"), params);
    };
    PartsAndPricesService.prototype.getSupplierPricesByPartId = function (id) {
        return this.http
            .get(this.getApiUrl("parts_and_prices/" + id + "/supplier_prices"));
    };
    PartsAndPricesService.prototype.addOrEditSupplierPrice = function (params) {
        return this.http
            .post(this.getApiUrl("parts_and_prices/supplier_prices/addOrEdit"), params);
    };
    PartsAndPricesService.prototype.deleteSupplierPrice = function (params) {
        return this.http
            .post(this.getApiUrl("parts_and_prices/supplier_prices/delete"), params);
    };
    PartsAndPricesService.prototype.getlabourRate = function () {
        return this.http.get(this.getApiUrl("sales/get_labour_rates"));
    };
    PartsAndPricesService.prototype.getPartCostByListId = function (listId, params) {
        return this.http.get(this.getApiUrl("getPartsPricingCosts/" + listId, params));
    };
    return PartsAndPricesService;
}(InterfaceService));
export { PartsAndPricesService };
