import {Injectable, Input, EventEmitter} from '@angular/core';
import Handsontable from 'handsontable';
import {HansontableSelect2} from "@app/directives";
import {HotTableModule, HotTableRegisterer} from "@handsontable/angular";
import {CsToastBoxService} from "@app/services";
import {Subject} from "rxjs";

declare var _: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class MultipleInvoiceService {

    totalEmitterSource: Subject<any>;
    //totalSource$ = this.totalEmitterSource.asObservable();
    hotInstances : any;
    spreadsheetData: Object[] = [];
    nominalCode: Object[] = [];
    taxDetails: Object[] = [];
    invoiceCategories: Object[] = [];
    userGroups: Object[] = [];
    spreadSheetUpdateData: Object[] = [];
    invoiceType = [{'id': 'partial', 'text': 'Additional'},  {'id': 'interim', 'text': 'Interim'},{'id': 'final', 'text': 'Final'}];
    totalPrice:any[] = [];
    defaultTaxItemId: number;
    total_cost: number = 0.00;
    total_VAT: number = 0.00;
    grand_total: number = 0.00;
    SubTotal: number = 0.00;
    validateErrorRow = false;
    vatType = '';
    isHideVat = false;
    invoiceTaxState: any = '';
    hotFilterInstance:any;
    invalidRows: number = 0;
    invoiceId: any;

    constructor(private toastBox: CsToastBoxService) {
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
        this.totalEmitterSource = new Subject<any>();
        const A = Handsontable.languages.getLanguageDictionary('en-US');
        A['Filters:buttons.ok'] = 'Filter';
        Handsontable.languages.registerLanguageDictionary(A);
    }

    getSpreadSheetData(hotId) {
        return this.spreadsheetData;
    }

    Spreadsheetdata(data,route) {
        this.invoiceId = route.snapshot.params['invoiceId'];

        this.spreadsheetData = data['lineItems'];
       if(this.invoiceId > 0){
           this.spreadSheetUpdateData = data['lineItems'];
       }else{
           let updateData = [];
           _.each(this.spreadsheetData, function(value, key) {
              if(value['unitprice'] != 0){
                  updateData.push(data['lineItems'][key]);
              }
           });
           this.spreadSheetUpdateData = updateData;
       }
        this.nominalCode = data['nominalCode'];
        this.taxDetails = data['taxDetails'];
        this.invoiceCategories = data['invoiceCategories'];
        this.userGroups = data['userGroups'];
        this.defaultTaxItemId = data['defaultTaxItemId'];
        this.isHideVat = data['isHideVat'];
        if (this.invoiceTaxState === '') {
            // @ts-ignore
            this.invoiceTaxState = (_.max(_.pluck(data['lineItems'], 'taxItemId')) > null);
        }
        if (this.invoiceTaxState === false) {
            this.isHideVat = true;
        }
    }

    afterInit(hotId, hotInstance) {
        this.hotInstances = hotInstance;
        this.hotFilterInstance = hotInstance.getPlugin('filters');
        //console.log('filter', this.hotFilterInstance);
        let self = this;
        hotInstance.updateSettings({
            className: 'overflow_handsontable',
            afterGetColHeader: function(col, TH) {
                let requiredCols = [8,9,10,11];
                if (requiredCols.indexOf(col) >= 0) {
                    TH.className = 'col_header_bg';
                }
                const BUTTON_CLASS_NAME = 'changeType';
                const existingButton = TH.querySelector('.' + BUTTON_CLASS_NAME);
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]' && this.getSettings().filters.indexOf(col) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }
            },
            afterFilter(conditionsStack){
                let data = this.getData();
                let keys = Object.keys(data);
                console.log(typeof data);
                const Indexes = _.range(0,(data.length));
                self.spreadSheetUpdateData = [];
                Indexes.forEach((key) => {
                    let jobNumber = hotInstance.getDataAtRowProp(key,'jobNumber');
                    let lineItem = _.findWhere(self.spreadsheetData, {'jobNumber' : jobNumber});
                    const index = _.findIndex(self.spreadSheetUpdateData, {'jobNumber' : jobNumber});
                    console.log(lineItem);
                    console.log(data);
                    if(index > -1){
                        self.spreadSheetUpdateData[index] = lineItem;
                    }else{
                        if(lineItem.unitprice > 0){
                            self.spreadSheetUpdateData.push(lineItem);
                        }
                    }
                });

                console.log(self.spreadSheetUpdateData.length);
                console.log(self.spreadsheetData.length);
                self.calculateGrandTotal();
            }
        });
        if(this.isHideVat){
            hotInstance.updateSettings({
                hiddenColumns: {
                    columns: [0,10,12,13]
                }
            });
        }
        hotInstance.render();
    }


    spreadsheetSettings() {
        return {
            preventOverflow: 'vertical',
            autoWrapRow: true,
            rowHeaders: true,
            colHeaders: true,
            stretchH: 'all',
            dropdownMenu: ['filter_by_condition','filter_by_value','filter_action_bar'],
            filters: [1, 3, 4, 5, 6, 7],
            autoColumnSize: true,
            fillHandle: {
                direction: 'vertical'
            },
            // colWidths: [10, 45, 50, 60, 75, 50, 50, 60, 50, 60, 30, 50],
            hiddenColumns: {
                columns: [0,12,13]
            },
            columns: [
                {data: 'id', readOnly: true},
                {title: 'Job date', data: 'jobDate', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true},
                {title: 'Job number', data: 'jobNumber', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true},
                {title: 'Job description', data: 'description', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                {title: 'Customer reference', data: 'customerRef', readOnlyCellClassName: 'cursor-not-allowed-disabled',readOnly: true},
                {title: 'Job complete', data: 'jobStatus', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true},
                {title: 'User group', data: 'userGroupDesc', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true,},
                {title: 'Invoice category', data: 'categoryidDesc', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true,},
                {title: 'Invoice type',
                    //invalidCellClassName: 'errorRowList',
                    data: 'invoiceType', placeholder: 'Please select', className: 'overflow_handsontable select_icon required',validator: 'emptyCellValidator',
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {
                        let invoiceType = _.findWhere(this.invoiceType, {id: value});
                        value = invoiceType.text;
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.invoiceType,
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                {title: 'Nominal code', data: 'nominalcode', placeholder: 'Please select', className: 'overflow_handsontable select_icon required',validator: 'emptyCellValidator',
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {
                        let nominalCode = this.nominalCode.find(p => p['id'] == value);
                        if (nominalCode) nominalCode = nominalCode['text'];
                        else nominalCode = '';
                        value = nominalCode;
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCode,
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                {title: this.taxDetails['taxLabel'], data: 'taxItemId', placeholder: 'Please select', className: 'overflow_handsontable select_icon required',validator: 'emptyCellValidator',
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {
                        let taxItems = this.taxDetails['taxItems'].find(p => p['id'] == value);
                        if (taxItems) taxItems = taxItems['text'];
                        else taxItems = '';
                        value = taxItems;
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxDetails['taxItems']),
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                {title: 'Total price', data: 'unitprice', type: 'numeric', format: '0.00',validator: 'emptyCellValidator',
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
                    },},
                {data: 'userGroup', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true,},
                {data: 'categoryid', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true,},
            ],
            beforeAutofill: (start, end ,data) => {
                const Indexes = _.range(start['row'],(end['row']+1));
                Indexes.forEach((key) => {
                    let currentInstance = this.hotInstances;
                    let jobNumber = currentInstance.getDataAtRowProp(key,'jobNumber');
                    let lineItem = _.findWhere(this.spreadsheetData, {'jobNumber' : jobNumber});
                    const index = _.findIndex(this.spreadSheetUpdateData, {'jobNumber' : jobNumber});
                    if (index > -1 && this.invoiceId > 0) {
                        this.spreadSheetUpdateData[index] = lineItem;
                    } else if (index > -1 && !this.invoiceId) {
                        if (lineItem['unitprice'] <= 0) {
                            this.spreadSheetUpdateData.splice(index, 1)
                        }
                    } else {
                        if (lineItem['unitprice'] > 0) {
                            this.spreadSheetUpdateData.push(lineItem);
                        }
                    }
                });
            },
        };
    }

    emptyCellValidator = (value, callback) => {
        if (value === '') {
            this.toastBox.show('Empty cell not allowed', 1000);
        }
        callback(true);
    }

    formatTaxItems(taxData){
        let tax = [];
        const taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach((province) => {
            let taxLabel = province[0]['taxProvinceName'];
            tax.push({'text': taxLabel, 'children': province});
        });
        return tax;
    }

    numericLimitValidator (value, row, col, prop, limit, cellType, hotId) {
        if (value != '') {
            let data = parseFloat(value);
            if (isNaN(value)) {
                this.invalidRows += 1;
            } else if ((data.toFixed(0).length) > limit) {
                this.invalidRows += 1;
            }else {
                this.invalidRows -= 1;
            }
        }
    }

    afterChange (changes, src, hotId) {
        if (!changes) return;
        _.forEach(changes, (change, key) => {
            let currentInstance = this.hotInstances;
            let jobNumber = currentInstance.getDataAtRowProp(change[0],'jobNumber');
            let lineItem = _.findWhere(this.spreadsheetData, {'jobNumber' : jobNumber});
            const index = _.findIndex(this.spreadSheetUpdateData, {'jobNumber' : jobNumber});
            if (index > -1 && this.invoiceId > 0) {
                this.spreadSheetUpdateData[index] = lineItem;
            } else if (index > -1 && !this.invoiceId) {
                if (lineItem['unitprice'] <= 0) {
                    this.spreadSheetUpdateData.splice(index, 1)
                }
            } else {
                if (lineItem['unitprice'] > 0) {
                    this.spreadSheetUpdateData.push(lineItem);
                }
            }
            if (change[1] == 'unitprice' || change[1] == 'taxItemId' || change[1] == 'invoiceType') {
                this.calculateGrandTotal();
                //setTimeout(()=>{
            this.showHighligherError();
                //},400);

            }
        });

    }

    showHighligherError() {
        let currentInstance:Handsontable = this.hotInstances;
        let grandTotal=0, interimInvoiceTotal=0,
            data = currentInstance.getSourceData();
        _.forEach(data, (rowItem, rowInd) => {
            let total = rowItem['total'] ? parseFloat(rowItem['total']) : 0;
            let totalVat = rowItem['totalVat'] ? parseFloat(rowItem['totalVat']) : 0;
            grandTotal = total + totalVat;
            interimInvoiceTotal = parseFloat(rowItem['interimInvoiceTotal']);
            let currentMetaRow = currentInstance.getCellMetaAtRow(rowInd);

            if ((rowItem['invoiceType'] == 'final') && (grandTotal < interimInvoiceTotal)) {
                _.forEach(currentMetaRow, (col:Handsontable.ColumnSettings, key) => {
                    this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                this.toastBox.show('Interim invoice is greater for the final invoice raised jobs', 1000);
            }
            else {
                _.forEach(currentMetaRow, (col:Handsontable.ColumnSettings, key) => {
                    this.removeCellClass(rowInd, key,'errorRowList', currentInstance);
                });
            }

            if(isNaN(total)){
                _.forEach(currentMetaRow, (col:Handsontable.ColumnSettings, key) => {
                    this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                this.toastBox.show('Enter numeric data', 1000);
            }else if ((total.toFixed(0).length) > 10) {
                _.forEach(currentMetaRow, (col:Handsontable.ColumnSettings, key) => {
                    this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                this.toastBox.show('Invalid data length', 1000);
            }else{
                _.forEach(currentMetaRow, (col:Handsontable.ColumnSettings, key) => {
                    this.removeCellClass(rowInd, key,'errorRowList', currentInstance);
                });
            }
        });
        currentInstance.render();
    }

    beforeValidate(value, row, prop, source, hotId) {
        if (value != '') {
            let activeEditor = this.hotInstances.getActiveEditor(),
                col = activeEditor.col,
                removeHighlightRow = [],
                cellType = activeEditor.cellProperties.type;
            if (this.validateErrorRow === true) {
                removeHighlightRow[0] = {'row': row};
                // this.highlightErrorRows(removeHighlightRow, false);
            }
            if (prop == 'unitprice') {
                this.numericLimitValidator(value, row, col, prop, 10, cellType, hotId);
            }
        }
    }


    highlightErrorRows (errorRow, isHighlight) {
        this.validateErrorRow = true;
        for (let i = 0; i < errorRow.length; i++) {
            let currentRow = errorRow[i]['row'];
            let currentInstance:Handsontable = this.hotInstances;
            let currentMetaRow = currentInstance.getCellMetaAtRow(currentRow);
            for (let j = 1; j < currentMetaRow.length; j++) {
                if (isHighlight === true) {
                    //currentInstance.setCellMeta(currentRow, j, 'className', 'errorRowList');
                    this.addCellClass(currentRow, j, 'errorRowList', currentInstance);
                } else {
                    //currentInstance.setCellMeta(currentRow, j, 'className', '');
                    this.removeCellClass(currentRow,j,'errorRowList', currentInstance);
                }
            }
        }
        this.hotInstances.render();
    }

    addCellClass(rowIndex:number, columnIndex:number, newClass:string, instance:Handsontable) {
        let cellMeta:any = instance.getCellMeta(rowIndex, columnIndex);
        let cellClass:string = cellMeta.className;
        let hasClass:any = !!cellClass.match(new RegExp('(\\s|^)' + newClass + '(\\s|$)'));
        if(!hasClass) {
            instance.setCellMeta(rowIndex, columnIndex, 'className', cellMeta.className + ' ' +  newClass);
        }
    }

    removeCellClass(rowIndex:number, columnIndex:number, removeClass:string, instance:Handsontable) {
        let cellMeta = instance.getCellMeta(rowIndex, columnIndex);
        let existsClass = this.removeClass(cellMeta.className, removeClass);
        instance.setCellMeta(rowIndex, columnIndex, 'className', existsClass);
    }

    removeClass(currentClasses, toRemove) {
        if (currentClasses) {
            var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');
            currentClasses=currentClasses.replace(reg,'');
        }
        return currentClasses;
    }

    calculateGrandTotal(sourceData:any=[]) {
        let totalvat, effective_tax, total, selectedTax, selectedId, grandTotal, interimInvoiceTotal;

        var total_price = 0, total_tax = 0, total_vat_rate = 0, sub_total_price = 0,
            breakdown_by_vat_rates = {}, vat_cost, total_obj = {};
            for (var i = 0, l = this.spreadSheetUpdateData.length; i < l; i++) {
                if (this.isHideVat) {
                    effective_tax = 0;
                    selectedId = null
                } else {
                    selectedTax = this.taxDetails['taxItems'].find(p => p['id'] == this.spreadSheetUpdateData[i]['taxItemId']);

                    if (typeof selectedTax == 'undefined') {
                        selectedTax = this.taxDetails['taxItems'].find(p => p['id'] = this.defaultTaxItemId);
                    }

                    selectedId = selectedTax['id'];
                    effective_tax = selectedTax['effective_tax'];
                }


                /** calculate exc total*/
                if (this.vatType == 'inc_vat') {
                    total = (this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice'] * 100) / (100 + parseFloat(effective_tax));
                } else {
                    total = this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice'];
                }
                total = parseFloat(total.toFixed(2));

                /** calculate total vat */
                if (this.vatType == 'inc_vat') {
                    totalvat = (this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice']) - total;
                } else {
                    totalvat = (total * effective_tax) / 100;
                }

                this.spreadSheetUpdateData[i]['total'] = total.toFixed(2);
                this.spreadSheetUpdateData[i]['vatrate'] = effective_tax;
                this.spreadSheetUpdateData[i]['totalVat'] = totalvat.toFixed(2);
                this.spreadSheetUpdateData[i]['taxItemId'] = selectedId;
                grandTotal = (parseFloat(this.spreadSheetUpdateData[i]['total']) + parseFloat(this.spreadSheetUpdateData[i]['totalVat']));
                interimInvoiceTotal = parseFloat(this.spreadSheetUpdateData[i]['interimInvoiceTotal']);

                total_price += (parseFloat(this.spreadSheetUpdateData[i]['total']) + parseFloat(this.spreadSheetUpdateData[i]['totalVat']));
                sub_total_price += parseFloat(this.spreadSheetUpdateData[i]['total']);
                total_tax += parseFloat(this.spreadSheetUpdateData[i]['totalVat']);
                if (this.spreadSheetUpdateData[i]['taxItemId'] !== 0) {
                    vat_cost = (typeof breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']] === 'undefined') ? 0 : breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']];
                    breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']] = vat_cost + parseFloat(this.spreadSheetUpdateData[i]['totalVat']);
                }
            }
        total_obj = {
            'total_price': total_price.toFixed(2),
            'sub_total_price': sub_total_price.toFixed(2),
            'total_tax': total_tax.toFixed(2),
            'breakdown_by_vat_rates' : breakdown_by_vat_rates
        }
        this.totalEmitterSource.next(total_obj);
    }

    setVatType(vatType) {
        this.vatType = vatType;
        if(this.spreadSheetUpdateData.length != 0){
            this.calculateGrandTotal();
        }
    }

}
