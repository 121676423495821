import { Injectable } from '@angular/core';
import {from, of} from 'rxjs/index';
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs/internal/operators";

export interface DataColumnModel {
    data: string,
    title: string,
    visible: boolean,
    orderable: boolean,
    className: string,
    width: string
}

@Injectable()
export class SmartTableMetaDataService {
    metaHeaders: any;
    private _columns: any[] = [];
    private _columnsDefs: any[] = [];
    private _sort: any = null;
    private _limit: any = 20;

    constructor(private trans: TranslateService) {
        console.info('lang',this.trans.instant('Service.Periodsss'));
    }

    public initiaze(headers) {
      this.metaHeaders = headers;
      this._prepareColumns();
      return this;
    }

    private _prepareColumns() {
        let newCols = [];
        const sortableCols = this.metaHeaders.hasOwnProperty('sortable_columns') ? !this.metaHeaders.sortable_columns ? [] : this.metaHeaders.sortable_columns : [];
        const sortByPos$ = this.metaHeaders.columns.sort( (a,b) => {
            return a.pos < b.pos ? -1 : 1;
        });
        const mappedCols = [];
        let columnsDefs = [];
        const columns$ = from(sortByPos$).pipe( map( (item:any, index:number) => {
            if(item.hasOwnProperty('width')) {
                columnsDefs.push({
                    "width": item.width,
                    "targets": index,
                })
            }
            const newCols:any = {
                data: item.col_name,
                title: item.hasOwnProperty('title') ? item.title : this.trans.instant(item.col_name),
                pos: item.pos,
                orderable: sortableCols.indexOf(item.col_name) > -1,
                columnIndex: index,
                visible: item.active,
                active: item.active,
                col_name: item.col_name,
                color: item.color ? item.color : false
            };
            if(item.hasOwnProperty('render')) {
                newCols.render = item.render;
            }
            // hidden,width,field used for assets
            if(item.hasOwnProperty('field')) {
                newCols['field'] = item.field;
            }
            if(item.hasOwnProperty('hidden')) {
                newCols['hidden'] = item.hidden;
            }
            if(item.hasOwnProperty('width')) {
                newCols['width'] = item.width;
            }
            return newCols;
        }));
        this._columnsDefs = columnsDefs;
        columns$.subscribe( (col) => mappedCols.push(col) );
        this._columns = mappedCols;
        if(this.metaHeaders.sort) {
            this._sort = Object.keys(this.metaHeaders.sort).length===0 ? null : this.metaHeaders.sort;
        }

        if(this.metaHeaders.limit) {
            this._limit = this.metaHeaders.limit;
        }
        return this.metaHeaders.columns;
    }

    getColumns() {
      return this._columns;
    }

    getColumnsDefs() {
        return this._columnsDefs;
    }

    getSorting() {
      return this._sort;
    }

    getLimit() {
      return parseInt(this._limit);
    }

    capitalize = (s:string) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
}
