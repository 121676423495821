import {Component, ElementRef, Inject, OnInit, Renderer2, ViewEncapsulation, HostListener} from '@angular/core';
import {ModalContainerComponent} from "ngx-bootstrap/modal";
import {SidepanelOptions} from "@app/sidepanel/services/sidepanel-options";
import {TranslateService} from "@ngx-translate/core";
import {AddOpportunityService} from "@app/features/customers/add-opportunity/service/add-opportunity.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-cs-fullscreen-panel',
  template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')" role="document">
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  host: {
    class: 'modal cs-fullscreen-panel-v2',
    role: 'dialog',
    tabindex: '-1',
    '[attr.aria-modal]': 'true',
    '[attr.aria-labelledby]': 'config.ariaLabelledBy',
    '[attr.aria-describedby]': 'config.ariaDescribedby'
  },
  styleUrls: ['./cs-fullscreen-panel.component.scss']
})
export class CsFullscreenPanelComponent extends ModalContainerComponent implements OnInit {

      config: SidepanelOptions;
      private _isModalHiding:boolean = false;

      constructor(options: SidepanelOptions,
        protected element: ElementRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private translate: TranslateService,
        private opService: AddOpportunityService,
        private renderer: Renderer2) {
            super(options, element, renderer);
      }

    ngOnInit() {
        //super.ngOnInit();
        if (this.isAnimated) {
            this.renderer.addClass(
                this.element.nativeElement,
                'fades'
            );
        }
        this.renderer.setStyle(
            this.element.nativeElement,
            'display',
            'block'
        );
        setTimeout(() => {
            this.isShown = true;
            this.renderer.addClass(
                this.element.nativeElement,
                'show'
            );
        }, 300);
        if (document && document.body) {
           /* if (this.bsModalService.getModalsCount() === 1) {
                this.bsModalService.checkScrollbar();
                this.bsModalService.setScrollbar();
            }*/
            this.renderer.addClass(document.body, 'cs-fullscreen-modal-open');
        }
        if (this.element.nativeElement) {
            this.element.nativeElement.focus();
            let footerElement = this.element.nativeElement.querySelector('.modal-content > * > .modal-footer');
            if(footerElement) {
                this.renderer.addClass(this.element.nativeElement, 'foo'+'ter'+'-'+'en'+'abl'+'ed');
            }
        }

        if(this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if(this.bsModalService.getModalsCount()) {
            // this.renderer.addClass(this.element.nativeElement, 'modal-level-'+this.bsModalService.getModalsCount());
        }
    }

    hide(): void {
        if (this._isModalHiding || !this.isShown) {
            return;
        }
        this._isModalHiding = true;
        this.renderer.removeClass(
            this.element.nativeElement,
            'show'
        );
        this.renderer.addClass(
            this.element.nativeElement,
            'hide'
        );
        setTimeout(() => {
            this.isShown = false;
            if (
                document &&
                document.body &&
                this.bsModalService.getModalsCount() === 1
            ) {
                this.renderer.removeClass(document.body, 'cs-fullscreen-modal-open');
            }
            this.bsModalService.hide(this.level);
            this._isModalHiding = false;
        }, 300);
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
    }
}
