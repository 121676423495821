import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var fontfamilyService = /** @class */ (function (_super) {
    tslib_1.__extends(fontfamilyService, _super);
    function fontfamilyService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.fontObj = [
            {
                "name": "Amantic",
                "family": "'Amatic SC', cursive"
            },
            {
                "name": "Arial",
                "family": "'Arial', sans-serif"
            },
            {
                "name": "Caveat",
                "family": "'Caveat', cursive"
            },
            {
                "name": "Comfortaa",
                "family": "'Comfortaa', cursive"
            },
            {
                "name": "Courier Prime",
                "family": "'Courier Prime', monospace"
            },
            {
                "name": "EB Garamond",
                "family": "'EB Garamond', serif"
            },
            {
                "name": "Lobster",
                "family": "'Lobster', cursive"
            },
            {
                "name": "Lora",
                "family": "'Lora', serif"
            },
            {
                "name": "Merriweather",
                "family": "'Merriweather', serif"
            },
            {
                "name": "Montserrat",
                "family": "'Montserrat', sans-serif"
            },
            {
                "name": "Nunito",
                "family": "'Nunito', sans-serif"
            },
            {
                "name": "Oswald",
                "family": "'Oswald', sans-serif"
            },
            {
                "name": "Pacifico",
                "family": "'Pacifico', cursive"
            },
            {
                "name": "Playfair Display",
                "family": "'Playfair Display', serif"
            },
            {
                "name": "Roboto",
                "family": "'Roboto', sans-serif"
            },
            {
                "name": "Roboto Mono",
                "family": "'Roboto Mono', monospace"
            },
            {
                "name": "Spectral",
                "family": "'Spectral', serif"
            },
            {
                "name": "Times New Roman",
                "family": "'Times New Roman', sans-serif"
            }
        ];
        _this.googleFontSendComponent = new BehaviorSubject(null);
        _this.googleFontSendComponent$ = _this.googleFontSendComponent.asObservable();
        _this.googleFontObject = [];
        return _this;
    }
    fontfamilyService.prototype.sendGoogleFontToComponent = function (data) {
        this.googleFontSendComponent.next(data);
    };
    fontfamilyService.prototype.getFontFamilyArr = function () {
        return this.fontObj;
    };
    fontfamilyService.prototype.getFontFamilyObj = function () {
        var x = {};
        this.fontObj.forEach(function (obj, i) { x[obj.family] = obj.name; });
        return x;
    };
    fontfamilyService.prototype.appendStylesheetIframe = function (googleFontLinkURL, uploadType) {
        var rootHeadElement = $('head');
        var cs_editor = $('#cs_editor').contents().find('head');
        if (cs_editor && cs_editor.length) {
            this.appendLinksToHead(cs_editor, googleFontLinkURL, uploadType);
        }
        /*if (rootHeadElement && rootHeadElement.length) {
            this.appendLinksToHead(rootHeadElement, googleFontLinkURL,uploadType);
        }*/
    };
    fontfamilyService.prototype.appendLinksToHead = function (appendElement, links, uploadType) {
        links.forEach(function (link) {
            $('<link>', {
                rel: 'stylesheet',
                id: "sales" + uploadType,
                href: link
            }).appendTo(appendElement);
        });
    };
    fontfamilyService.prototype.insertGoogleFontListObject = function (newFontObjects) {
        var _loop_1 = function (newFontObject) {
            // Check if a font object with the same name already exists in fontObj
            var isDuplicate = this_1.googleFontObject.some(function (font) { return font.name === newFontObject.name; });
            if (!isDuplicate) {
                this_1.googleFontObject.push(newFontObject);
            }
        };
        var this_1 = this;
        for (var _i = 0, newFontObjects_1 = newFontObjects; _i < newFontObjects_1.length; _i++) {
            var newFontObject = newFontObjects_1[_i];
            _loop_1(newFontObject);
        }
    };
    fontfamilyService.prototype.getGoogleFontObject = function () {
        return this.googleFontObject;
    };
    fontfamilyService.prototype.combineFontLinksToApiUrl = function (fontLinksArray, uploadType) {
        if (uploadType === void 0) { uploadType = ''; }
        var fontFamilies = [];
        for (var _i = 0, fontLinksArray_1 = fontLinksArray; _i < fontLinksArray_1.length; _i++) {
            var link = fontLinksArray_1[_i];
            var fontFamilyMatches = link.match(/family=([^&]+)/g);
            if (fontFamilyMatches) {
                for (var _a = 0, fontFamilyMatches_1 = fontFamilyMatches; _a < fontFamilyMatches_1.length; _a++) {
                    var match = fontFamilyMatches_1[_a];
                    fontFamilies.push(match);
                }
            }
        }
        var fontFamiliesString = fontFamilies.join('&');
        var apiUrl = "https://fonts.googleapis.com/css2?" + fontFamiliesString + "&display=swap";
        this.sendGoogleFontToComponent(apiUrl);
        this.appendStylesheetIframe([apiUrl], uploadType);
    };
    fontfamilyService.ngInjectableDef = i0.defineInjectable({ factory: function fontfamilyService_Factory() { return new fontfamilyService(i0.inject(i1.HttpClient)); }, token: fontfamilyService, providedIn: "root" });
    return fontfamilyService;
}(InterfaceService));
export { fontfamilyService };
