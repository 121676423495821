import * as tslib_1 from "tslib";
import { PlatformLocation } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, ResolveEnd } from '@angular/router';
import { filter } from "rxjs/operators";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { TranslateService } from '@ngx-translate/core';
import { LicenseManager } from "ag-grid-enterprise";
//--
import { HybridFactory, SettingsService } from '@app/core';
import { CsToastBoxService, CsVersionService, SessionLogService } from '@app/services';
import { InterfaceClass } from './interfaces';
import { UPGRADED_ROUTES } from './config';
import { environment } from "../environments/environment";
var AppComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppComponent, _super);
    function AppComponent(locale, ajsScope, settingsService, toastBox, location, router, bsLocaleService, translate, csVersionService, SessionLogService) {
        var _this = _super.call(this) || this;
        _this.locale = locale;
        _this.ajsScope = ajsScope;
        _this.settingsService = settingsService;
        _this.toastBox = toastBox;
        _this.location = location;
        _this.router = router;
        _this.bsLocaleService = bsLocaleService;
        _this.translate = translate;
        _this.csVersionService = csVersionService;
        _this.SessionLogService = SessionLogService;
        _this.showToastMessage = false;
        // Import locale settings for possible languages
        enGbLocale.week.dow = 0; // set sunday as week start
        defineLocale('en-gb', enGbLocale);
        //setting default language for the angular application
        translate.setDefaultLang('en');
        translate.use(settingsService.getCsLanguage());
        // Set bootstrap datepicker locale
        var settingsLang = settingsService.getCsLanguage();
        var language = settingsService.getCsLanguage();
        if (language == 'en' || language == null || language == undefined) {
            language = 'en-GB';
        }
        _this.bsLocaleService.use(language);
        // Updating permissions on login success, pulling data from AngularJs
        _this.ajsScope.$on('permissionsChanged', function (e, data) {
            settingsService.setAccessDetails(data);
        });
        // Updating module details on login success, pulling data from AngularJs
        _this.ajsScope.$on('modulesChanged', function (e, data) {
            settingsService.setModulePermissions(data);
        });
        // Hooking router events, for hiding angularJs content after the angularES data resolve
        _this.router.events
            .pipe(filter(function (event) { return event instanceof ResolveEnd; }))
            .subscribe(function (event) {
            HybridFactory.onLocationChangeSuccess(event, 'NG2', _this.ajsScope);
        });
        // AG-GRID License setup
        _this.setCsAgGridLicense();
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // @ts-ignore
        //get userdetails on csVersionServices if null get and set value in this.ajsScope
        var _a = this.csVersionService.getuserDeatails() || {}, clientId = _a.clientId, username = _a.username;
        var userDetaisls = {
            clientId: clientId || this.ajsScope.clientId,
            username: username || this.ajsScope.username,
        };
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                var startTime = Date.now();
                _this.SessionLogService.sessionStartTime(startTime);
            }
            if (event instanceof NavigationEnd) {
                var endTime = Date.now();
                _this.SessionLogService.sessionEndTime(endTime);
                var sessionDuration = _this.SessionLogService.getDuration();
                if (clientId && username && event.url && sessionDuration != null) {
                    _this.SessionLogService.addToSessionLog(clientId, username, event.url, sessionDuration).subscribe(function (res) {
                        // console.log(res);
                    });
                }
            }
        });
        // Watch for toast message controll action
        this.subscriptions['toastControl'] = this.toastBox.toastControl$.subscribe(function (data) {
            if (!data)
                return;
            if (data.action == 'show') {
                _this.toastConfig = {
                    message: data.message,
                    duration: data.duration
                };
                setTimeout(function () {
                    _this.showToastMessage = true;
                }, 100);
            }
            if (data.action == 'hide') {
                _this.toastConfig = { message: null, duration: null };
                setTimeout(function () {
                    _this.showToastMessage = false;
                }, 100);
            }
        });
    };
    // Window keyup listener for application shortcuts
    AppComponent.prototype.onKeyup = function (event) {
        this.ajsScope.keyup(event);
    };
    AppComponent.prototype.redirectToAngular7Module = function (data) {
        var callURL;
        if (data) {
            callURL = data.detail.url;
        }
        // had to duplicate this code because could'nt import from hybrid-factory.ts file
        var presentInUpgradedList = UPGRADED_ROUTES.find(function (pathPattern) {
            return (new RegExp(pathPattern, 'g')).test(window.location.pathname);
        });
        if (presentInUpgradedList) {
            if (window.location.pathname !== '/settings' && window.location.pathname !== '/company_settings/integrations') {
                this.router.navigateByUrl(callURL);
            }
        }
    };
    AppComponent.prototype.setCsAgGridLicense = function () {
        LicenseManager.setLicenseKey(environment.agGridEnterpriseLicenseKey);
    };
    return AppComponent;
}(InterfaceClass));
export { AppComponent };
