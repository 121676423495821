import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AddEditCertificateService} from "@app/services";
import {CsFullScreenModalComponent} from "@app/directives";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-edit-certificate',
  templateUrl: './add-edit-certificate.component.html',
  styles: []
})
export class AddEditCertificateComponent implements OnInit {
    showSidepanel: boolean = false;
    certificates = [];
    categories = [];
    filterText: string = '';
    category: string;
    certificateIndex: number;
    certificate: any;
    subTitle: string;
    certificatePageData: any;

    // Customer/landlord page info
    customerOrLandlordInfo: customerOrLandlordInfo = {
        showLandlord: false,
        customerInfo: [],
        landlordInfo: [] ,
        jobId: null,
        settingsCertificateId: null
    };
    draftData: object = {};
    currentPageIndex: number = -1;
    certificateName: string = '';
    customerOrLandlord: string = 'Customer';
    userName: string = '';
    status: string = 'DRAFT';
    @Output('onSidePanelClose') valueEmitter = new EventEmitter();
    @Output('onReloadSmartTable') onReloadSmartTable = new EventEmitter();
    @Input('data') data: any;
    @ViewChild(CsFullScreenModalComponent) csFullModal: CsFullScreenModalComponent;

    constructor(private addEditCertificateService: AddEditCertificateService,
                private router: ActivatedRoute) {
    }

    ngOnInit() {
        if(this.data['type'] == 'ADD') {
            this.showSidepanel = true;
            this.addEditCertificateService.getDynamicCertificates().subscribe(res => {
                this.certificates = res;
                for (const [key, value] of Object.entries(res)) {
                    this.categories.push(key);
                }

            }, error1 => {
                this.certificates = [];
            });
        }else{
            this.addEditCertificateService.getDynamicCertificateJsonDraft(this.data['certificateId']).subscribe(res =>{
                if(res['certificatedata']){
                    this.certificateName = res['name'];
                    this.userName = res['userName'];
                    this.status = res['status'];
                    let certificateData = JSON.parse(res['certificatedata']);
                    if(certificateData){
                        this.draftData = certificateData['jsonDraft'];
                        this.currentPageIndex = certificateData['currentPageIndex'];
                        this.customerOrLandlord = certificateData['customerownership'];
                        let params = {};
                        params['lookupId'] = this.data['certificateId'];
                        params['customerId'] = this.data['customerId'];
                        if(res['type'] == 'DynamicCertificate'){
                            params['type'] = 1;
                            params['tablePkId'] = res['adminlookupsettingscertificateId'];
                        }
                        if(res['type'] == 'DynamicCertificate2.0'){
                            params['type'] = 2;
                            params['tablePkId'] = res['settingsdynamiccertificateId'];
                        }
                        this.getDynamicCertificateJson(params, res['settingsCertificatesid']);
                    }
                }
            }, error1 => {

            });
        }

        this.subTitle = `<p>Job number : ${this.data['jobId']} &nbsp; &nbsp;<i class="fa fa-home"></i>
                         ${this.data['addressline1']} ${this.data['addressline2']} `;
        if(this.data['county']){
            this.subTitle += ', ' + this.data['county'];
        }
        if(this.data['postcode']){
            this.subTitle += ', ' + this.data['postcode'];
        }
        this.subTitle += '</p>';
    }

    onSidepanelClose(){
      this.filterText = '';
      this.showSidepanel = false;
      setTimeout( () =>{
          this.valueEmitter.emit(false);
      }, 1000);
    }

    searchUpdated(){
        let queryString = this.filterText.trim();
        for (const [key, value] of Object.entries(this.certificates)) {
            value.forEach((certificate) => {
                certificate['show'] = certificate['name'].toLowerCase().includes(queryString.toLowerCase());
            });
        }
    }

    selectCertificate(category: string, certificateIndex: number, certificate: any){
        // Deselect the previously selected certificate
        if(this.category)
            this.certificates[this.category][this.certificateIndex]['selected'] = false;

        this.certificates[category][certificateIndex]['selected'] = true;
        this.category = category;
        this.certificateIndex = certificateIndex;
        this.certificate = certificate;
        this.certificateName = certificate['name'];
    }

    confirmCertificate(){
        let params = {};
        params['customerId'] = this.data['customerId'];
        if(this.certificate['type'] == 'DynamicCertificate'){
            params['type'] = 1;
            params['tablePkId'] = this.certificate['adminlookupsettingscertificateId'];
        }
        if(this.certificate['type'] == 'DynamicCertificate2.0'){
            params['type'] = 2;
            params['tablePkId'] = this.certificate['settingsdynamiccertificateId'];
        }
        this.getDynamicCertificateJson(params);
    }

    getDynamicCertificateJson(params, settingsCertificatesid?){
        this.addEditCertificateService.getDynamicCertificateJson(params).subscribe(res =>{
            if(res['json']){
                this.certificatePageData = JSON.parse(res['json']);
                this.customerOrLandlordInfo.showLandlord = res['showLandlord'];
                this.customerOrLandlordInfo.customerInfo = this.data;
                this.customerOrLandlordInfo.landlordInfo = res['landlord'];
                this.customerOrLandlordInfo.jobId = this.data['jobId'];
                this.customerOrLandlordInfo.settingsCertificateId = settingsCertificatesid? settingsCertificatesid : this.certificate['id'];
                this.filterText = '';
                this.showSidepanel = false;
                this.csFullModal.open();

            }
        }, error1 => {
            console.log(error1)
        });
    }

    closeModal($event){
        this.csFullModal.close();
    }

    _onClose(event){
        this.onReloadSmartTable.emit(true);
        setTimeout( () =>{
            this.valueEmitter.emit(true);
        }, 1000);
    }

    onOpen(event){
       console.log(event);
    }

}

export interface customerOrLandlordInfo {
    showLandlord: boolean;
    customerInfo: any;
    landlordInfo: any;
    jobId: number;
    settingsCertificateId: number;
}
