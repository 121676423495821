import { OnInit, EventEmitter, ViewContainerRef } from '@angular/core';
var ActionButtonsComponent = /** @class */ (function () {
    function ActionButtonsComponent(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
        this.data = {};
        this.index = -1;
        this.buttons = [];
        this.toggleEvent = new EventEmitter();
        this.onClickButtonEvent = new EventEmitter();
        this.active = -1;
    }
    ActionButtonsComponent.prototype.ngOnInit = function () {
        this.buttons.forEach(function (btn) {
            if (!btn.visible) {
                btn.visible = function () {
                    return true;
                };
            }
            if (!btn.permissions) {
                btn.permissions = [];
            }
        });
    };
    ActionButtonsComponent.prototype.checkVisible = function (data, button) {
        if (typeof button.visible === 'function') {
            return button.visible(data, this.index);
        }
        else {
            return button.visible;
        }
    };
    ActionButtonsComponent.prototype.toggleClick = function (event) {
        this.active = this.index;
        if ($(event.target).closest('td').hasClass('active')) {
            this.active = -1;
        }
        //console.log($(event.target).closest('td'));
        this.toggleEvent.emit({
            index: this.active,
            data: this.data
        });
    };
    ActionButtonsComponent.prototype.onClickButton = function (data, buttonProp) {
        this.onClickButtonEvent.emit({
            data: data,
            buttonProp: buttonProp,
            index: this.index
        });
    };
    ActionButtonsComponent.prototype.checkVisibility = function (data, buttonProp) {
        //console.log('checkVisible', data, buttonProp)
    };
    return ActionButtonsComponent;
}());
export { ActionButtonsComponent };
