import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AddEditCertificateService = /** @class */ (function (_super) {
    tslib_1.__extends(AddEditCertificateService, _super);
    function AddEditCertificateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /* Get dynamic certificates
        Response : Array
    */
    AddEditCertificateService.prototype.getDynamicCertificates = function (params) {
        return this.http.get(this.getApiUrl("getdynamiccertificates", params));
    };
    /* Get dynamic certificate json
       Response : Array
     */
    AddEditCertificateService.prototype.getDynamicCertificateJson = function (params) {
        return this.http.get(this.getApiUrl("getdynamiccertificatejson", params));
    };
    /* set certificate json
       Response : Array
     */
    AddEditCertificateService.prototype.setCertificateJson = function (data, params) {
        return this.http.post(this.getApiUrl("setcertificatejson", params), data);
    };
    /* Get dynamic certificate json
       Response : Array
     */
    AddEditCertificateService.prototype.getDynamicCertificateJsonDraft = function (lookupId, params) {
        return this.http.get(this.getApiUrl("getdynamiccertificatejsondraft/" + lookupId, params));
    };
    /* set certificate json
       Response : Array
     */
    AddEditCertificateService.prototype.setCertificateDataAndActivate = function (lookupId, data, params) {
        return this.http.post(this.getApiUrl("setcertificatedataandactivate/" + lookupId, params), data);
    };
    AddEditCertificateService.ngInjectableDef = i0.defineInjectable({ factory: function AddEditCertificateService_Factory() { return new AddEditCertificateService(i0.inject(i1.HttpClient)); }, token: AddEditCertificateService, providedIn: "root" });
    return AddEditCertificateService;
}(InterfaceService));
export { AddEditCertificateService };
