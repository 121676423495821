import { Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";
import { SettingsService } from "@app/core";
import * as i0 from "@angular/core";
import * as i1 from "../../../core/settings/settings.service";
var CcCommonService = /** @class */ (function () {
    function CcCommonService(settings, rendererFactory) {
        this.settings = settings;
        this.rendererFactory = rendererFactory;
        this.customerContractDetails = new BehaviorSubject({});
        this.currentBasicDetails$ = this.customerContractDetails.asObservable();
        this.utcFormat = 'YYYY-MM-DD h:mm:ss a';
        this._renderer = this.rendererFactory.createRenderer(null, null);
    }
    CcCommonService.prototype.setCcBasicDetails = function (data) {
        this.customerContractDetails.next(data);
    };
    CcCommonService.prototype.getCcBasicDetails = function () {
        return this.customerContractDetails.asObservable();
    };
    CcCommonService.prototype.getUtcStringAsLocaleDate = function (value) {
        return new Date(moment(value).utc().format(this.utcFormat));
    };
    CcCommonService.prototype.getLocaleDateAsUtcString = function (value) {
        return moment(value).format();
    };
    CcCommonService.prototype.validateNumberInput = function (event) {
        var key = event.keyCode;
        var isValid = (key >= 48 && key <= 57)
            || (key >= 96 && key <= 105)
            || [8, 9, 13, 46, 110, 190, 37, 38, 39, 40].includes(key);
        if (!isValid) {
            event.stopImmediatePropagation();
            event.preventDefault();
            return false;
        }
        return true;
    };
    CcCommonService.prototype.getRegionalDateFormat = function (dateType) {
        if (dateType === void 0) { dateType = 'short_date'; }
        return this.settings.getRegionalDateFormats()[this.settings.getIntlSettings()[dateType]];
    };
    CcCommonService.prototype.getCustomLocaleMoment = function (input, format) {
        return moment(input, format).locale('en');
    };
    CcCommonService.prototype.getTzFormattedDateString = function (value) {
        var locale = this.settings.getCsLanguage();
        var timeZone = this.settings.getCountryCode().timezone;
        var tzDate = new Date(value).toLocaleString(locale, { timeZone: timeZone });
        var regionalDateFormat = this.getRegionalDateFormat();
        return moment(new Date(tzDate)).format(regionalDateFormat);
    };
    // To set the date-picker position
    CcCommonService.prototype.setDpContainerPosition = function (DpInput, dpContainer, dpContainerHolder) {
        if (DpInput && dpContainer) {
            var dpContainerHolderPos = dpContainerHolder.getBoundingClientRect();
            var dpInputPos = DpInput.getBoundingClientRect();
            this._renderer.setStyle(dpContainer, 'position', 'absolute');
            this._renderer.setStyle(dpContainer, 'top', (dpInputPos.bottom - dpContainerHolderPos.top + 1) + 'px');
            this._renderer.setStyle(dpContainer, 'left', (dpInputPos.left - dpContainerHolderPos.left + 1) + 'px');
        }
    };
    CcCommonService.ngInjectableDef = i0.defineInjectable({ factory: function CcCommonService_Factory() { return new CcCommonService(i0.inject(i1.SettingsService), i0.inject(i0.RendererFactory2)); }, token: CcCommonService, providedIn: "root" });
    return CcCommonService;
}());
export { CcCommonService };
