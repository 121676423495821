import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconListComponent } from './icon-list.component';
import {FormsModule} from '@angular/forms';
import {TabsModule} from 'ngx-bootstrap';
import {TranslateModule} from "@ngx-translate/core";
import { DragAndDropDirective } from './drag-and-drop.directive';

@NgModule({
    declarations: [IconListComponent, DragAndDropDirective],
    exports: [
        IconListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TabsModule.forRoot(),
        TranslateModule
    ]
})
export class IconListModule { }
