import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

//--
import { ContractTemplateArchiveEvent, ContractTemplateDeleteEvent, InterfaceService } from '@app/interfaces';
import { ContractTemplate } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class ContractTemplatesService extends InterfaceService {
  private eventSource: Subject<ContractTemplateDeleteEvent | ContractTemplateArchiveEvent> = new Subject();
  events$: Observable<ContractTemplateDeleteEvent | ContractTemplateArchiveEvent> = this.eventSource.asObservable();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  // Initializing contract template delete process
  triggerDeleteContractTemplate(contractId: number, customersCount: number): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.eventSource.next(new ContractTemplateDeleteEvent(contractId, customersCount, resolve, reject));
      }, 50);
    });
  }

  // Initializing contract template archive process
  triggerArchiveContractTemplate(contractId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.eventSource.next(new ContractTemplateArchiveEvent(contractId, resolve, reject));
      }, 50);
    });
  }

  // Add new contract template
  addTemplate(data: Object): Observable<any> {
    return this.http
      .post(this.getApiUrl('contract-templates/add-template'), JSON.stringify(data))
      .pipe(
        map(Response => new ContractTemplate(Response))
      );
  }

  // Get data for contract template listing
  getContractTemplate(): Observable<Object> {
    return this.http.get(this.getApiUrl('contract-templates/details'))
      .pipe();
  }


  // Get contract template basic details
  getTemplate(id: number): Observable<any> {
    return this.http
      .get(this.getApiUrl(`contract-templates/${id}/get-template-details`))
      .pipe(
        map(Response => new ContractTemplate(Response))
      );
  }

  // Update contract template basic details
  editTemplate(id: number, data: Object): Observable<any> {
    return this.http
      .put(this.getApiUrl(`contract-templates/${id}/edit-template`), JSON.stringify(data))
      .pipe(
        map(Response => new ContractTemplate(Response))
      );
  }

  // Get data for contract template basic details configuration form
  getTemplateBasicDetailsFormData(templateId: number): Observable<Object> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/form-data/basic-details`))
      .pipe(
        map(Response => {
          Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);

          return Response;
        })
      );
  }

  // Update contract template basic details
  configureTemplateBasicDetails(templateId: number, data: Object): Observable<Object> {
    return this.http.put(this.getApiUrl(`contract-templates/${templateId}/setup/basic-details`), JSON.stringify(data))
      .pipe();
  }

  // Get Contracts list
  getContractsList(param?: object): Observable<any> {

    return this.http
      .get(this.getApiUrl('contract-templates/details', param)).pipe(
        map(Response => Response)
      );
  }

  // Delete Contract template
  deleteContractTemplate(id: number): Observable<any> {
    return this.http
      .delete(this.getApiUrl(`contract-templates/${id}/delete-template`))
      .pipe(
        map(Response => new ContractTemplate(Response))
      );
  }

  // Get data for contract template billable rates configuration form
  getTemplateBillableRatesFormData(templateId: number): Observable<Object> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/form-data/billable-rates`))
      .pipe(
        map(Response => {
          Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);

          return Response;
        })
      );
  }

  // Get contract template billable rates labour/travel costs
  getTemplateBillableRatesLTCosts(templateId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/billable-rates/labour-travel-costs`))
      .pipe();
  }

  // Store contract template billable rates labour/travel costs
  saveTemplateBillableRatesLTCosts(templateId: number, data: any[]): Observable<any> {
    return this.http.put(this.getApiUrl(`contract-templates/${templateId}/billable-rates/labour-travel-costs/save`), JSON.stringify(data));
  }

  // Get contract template billable rates pricing items
  getTemplateBillableRatesPricingItems(templateId: number, params?: object): Observable<Object> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/billable-rates/pricing-items`, params))
      .pipe();
  }

  // Save contract template pricing items
  saveTemplateBillableRatesPricingItems(templateId: number, data: any[]) {
    return this.http.put(this.getApiUrl(`contract-templates/${templateId}/billable-rates/pricing-items/save`), JSON.stringify(data));
  }

  // Get contract template billable rates parts pricing
  getTemplateBillableRatesPartsPricing(templateId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/billable-rates/parts-pricing`));
  }

  // Save contract template billable rates parts pricing
  saveTemplateBillableRatesPartsPricing(templateId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`contract-templates/${templateId}/billable-rates/parts-pricing`), JSON.stringify(data));
  }

  // Update contract template billable rates parts pricing record
  updateTemplateBillableRatesPartsPricing(id: number, data: Object): Observable<any> {
    return this.http.put(this.getApiUrl(`contract-templates/billable-rates/parts-pricing/${id}`), JSON.stringify(data));
  }

  // Delete contract template billable rates parts pricing
  deleteTemplateBillableRatesPartsPricing(partPriceId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`contract-templates/billable-rates/parts-pricing/${partPriceId}`));
  }

  // Get data for contract template whats included configuration form
  getTemplateWhatsIncludedFormData(templateId: number): Observable<Object> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/form-data/whats-included`)).pipe(
      map(Response => {
        Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);

        return Response;
      })
    );
  }

  // Get contract template whats included job FUP
  getTemplateWhatsIncludedJobFUP(templateId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/whats-included/job-fup`));
  }

  // Save contract template whats included job FUP
  saveTemplateWhatsIncludedJobFUP(templateId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`contract-templates/${templateId}/whats-included/job-fup`), JSON.stringify(data));
  }

  // Delete contract template contract template whats included job FUP
  deleteTemplateWhatsIncludedJobFUP(jobFUPId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`contract-templates/whats-included/job-fup/${jobFUPId}`));
  }

  // Get contract template whats included ppm
  getTemplateWhatsIncludedPPM(templateId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/whats-included/ppm`));
  }

  // Save contract template whats included ppm
  saveTemplateWhatsIncludedPPM(templateId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`contract-templates/${templateId}/whats-included/ppm`), JSON.stringify(data));
  }

  // Delete contract template contract template whats included ppm
  deleteTemplateWhatsIncludedPPM(ppmId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`contract-templates/whats-included/ppm/${ppmId}`), JSON.stringify(data));
  }

  // Get contract template whats included assets
  getTemplateWhatsIncludedAssets(templateId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`contract-templates/${templateId}/whats-included/assets`));
  }

  // Save contract template whats included assets
  saveTemplateWhatsIncludedAssets(templateId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`contract-templates/${templateId}/whats-included/assets`), JSON.stringify(data));
  }

  // Changing contract status
  changeContractStatus(templateId: number, data: object): Observable<any> {
    return this.http.put(this.getApiUrl(`contract-templates/${templateId}/change-status`), JSON.stringify(data));
  }

    // Get data for contract template invoicing configuration form
    getTemplateInvoicingFormData(templateId: number): Observable<Object> {
        return this.http.get(this.getApiUrl(`contract-templates/${templateId}/form-data/invoicing`)).pipe(
            map(Response => {
                Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);

                return Response;
            })
        );
    }

    saveTemplateInvoicingSettings(templateId: number, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`contract-templates/${templateId}/invoicing/settings`), JSON.stringify(data));
    }

    getTemplateInvoicingDate(templateId: number):Observable<any>{
        return this.http.get(this.getApiUrl(`contract-templates/${templateId}/invoicing/settings-data`));
    }

    saveTemplateInvoiceBillingOption(templateId: number, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`contract-templates/${templateId}/invoicing/billing-option`), JSON.stringify(data));
    }
    getTemplateInvoicingDateEdit(templateId: number,invoicingId:number):Observable<any>{
        return this.http.get(this.getApiUrl(`contract-templates/${templateId}/invoicing/edit/${invoicingId}`));
    }
    deleteTemplateInvoiceOption(templateId: number,invoicingId:number):Observable<any>{
        return this.http.get(this.getApiUrl(`contract-templates/${templateId}/invoicing/delete/${invoicingId}`));
    }
    saveTemplateInvoiceBillingSetPriceChanges(templateId: number, data: Object):Observable<any> {
        return this.http.post(this.getApiUrl(`contract-templates/${templateId}/invoicing/set-price-changes`), JSON.stringify(data));
    }

    // Get list of job descriptions configured
    listCtJobDescriptions(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`list_ctJobdescriptions`, params));
    }

    getCtJobdescriptions(templateId: number):Observable<any>{
        return this.http.get(this.getApiUrl(`contract_templates/${templateId}/whats-included/ppm/get-job-description`));
    }

    saveCtJobDescriptions(templateId: number, data: Object): Observable<any> {
        return this.http.put(this.getApiUrl(`contract_templates/${templateId}/whats-included/ppm/job-description`), JSON.stringify(data));
    }

    checkContractTemplateStatus(templateId: number):Observable<any>{
        return this.http.get(this.getApiUrl(`contract-templates/${templateId}/get-template-details`));
    }
}
