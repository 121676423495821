var JobsSla = /** @class */ (function () {
    function JobsSla(data) {
        if (!(data instanceof Object))
            data = {};
        this.id = data['id'] || '';
        this.name = data['name'] || '';
        this.description = data['description'] || '';
        this.deleted = data['deleted'] || '';
        this.uuid = data['uuid'] || '';
        this.createdondatetime = data['createdondatetime'] ? new Date(data['createdondatetime']) : null;
        this.createdbytype = data['createdbytype'] || '';
        this.createdbyid = data['createdbyid'] || '';
        this.lastModifiedDateTime = data['lastModifiedDateTime'] ? new Date(data['lastModifiedDateTime']) : null;
    }
    return JobsSla;
}());
export { JobsSla };
