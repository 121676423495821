import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
// --
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationTemplateService, _super);
    function NotificationTemplateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Get groups configured
    NotificationTemplateService.prototype.getNotifications = function (params) {
        return this.http
            .get(this.getApiUrl("notifications", params));
    };
    // complete selected notification
    NotificationTemplateService.prototype.completeSelectedAllNotifications = function (data) {
        return this.http.post(this.getApiUrl("completeSelectedAllNotification"), data);
    };
    NotificationTemplateService.prototype.completeSelectedNotifications = function (data) {
        return this.http.post(this.getApiUrl("completeNotification"), {
            'notificationId': data
        });
    };
    NotificationTemplateService.prototype.modalNotificationDetails = function (notificationId) {
        return this.http.post(this.getApiUrl('modalNotificationDetails'), {
            'notificationId': notificationId
        });
    };
    NotificationTemplateService.prototype.auditNotificationView = function (notificationId) {
        return this.http.post(this.getApiUrl('auditNotificationView'), {
            'notificationId': notificationId
        });
    };
    NotificationTemplateService.prototype.auditNotificationViewHistory = function (notificationId, status) {
        return this.http.post(this.getApiUrl('auditNotificationView'), {
            'notificationId': notificationId, 'status': status
        });
    };
    NotificationTemplateService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationTemplateService_Factory() { return new NotificationTemplateService(i0.inject(i1.HttpClient)); }, token: NotificationTemplateService, providedIn: "root" });
    return NotificationTemplateService;
}(InterfaceService));
export { NotificationTemplateService };
