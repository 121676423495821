import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';

@Component({
	selector: 'app-pricing-section-usepartkit',
	templateUrl: './pricing-section-usepartkit.component.html',
	styleUrls: ['./pricing-section-usepartkit.component.scss'],
})
export class PricingSectionUsepartkitComponent implements OnInit {
	partKitList: any;
	selectedKit :{};
	title: string;
	action: string = '';

	public onClose: Subject<{}>;
	partsKitForm: FormGroup;
	select2Config = {
        width: '100%'
    };
	showDescription: boolean = false;
	selectedPartKit = {};
	quantity: number = 1;
	subscriptions: any = {};
	partKitToDisplay: any = [];
    buttonDisabled: boolean = false;
	constructor(
		public bsModalRef: BsModalRef,
		private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.action = 'save';
		this.onClose = new Subject();
		this.partsKitForm = this.formBuilder.group({
			'partsKit': ['', Validators.required],
			'quantity': [1, Validators.required]
		});
		this.partsKitForm.get('quantity').valueChanges.subscribe((value) => {
			this.getQuantity(value);
		});
	}

	selectPartKit(event) {
		this.showDescription = true;
		this.selectedPartKit = this.partKitList.find(data => data.id == event);
		if (this.partsKitForm.value.partsKit) {
			this.selectedKit = this.selectedPartKit;
            this.getQuantity(this.partsKitForm.get('quantity').value);
        }
		else {
			let quantity = parseFloat(this.partsKitForm.value.quantity) <= 0 ? 1 : this.partsKitForm.value.quantity;
			this.getQuantity(quantity);
		}
	}

	getQuantity(quantity: number) {
		let setPartKit = [];
		let value = this.partsKitForm.value;
		quantity = quantity ? quantity : 1;
		this.quantity = quantity;
        if(this.selectedPartKit && this.selectedPartKit['kitItems'].length){
            for (let i = 0; i < this.selectedPartKit['kitItems'].length; i++) {
                let newElement = JSON.parse(JSON.stringify(this.selectedPartKit['kitItems'][i]));
                newElement.quantity = newElement.quantity * quantity;
                setPartKit.push(newElement);
            }
            this.partKitToDisplay = setPartKit;
        } else {
            this.partKitToDisplay = [];
        }
	}

	// Save parts kit
	savePartsKit() {
		// let kitItems = [];
		// this.selectedKit['kitItems'].forEach(element => {
			// if(!element['exists']){
		// 		kitItems.push(element);
			// }
		// });
		this.action = 'saving';
		let selectedItems = JSON.parse(JSON.stringify(this.selectedKit));
        selectedItems['kitItems'] = this.partKitToDisplay;
	    this.onClose.next(selectedItems);
		this.bsModalRef.hide();
	}

    restrictSpecialChar(event){
        let charCode = (event.which) ? event.which : event.keyCode
        if ((charCode < 48 || charCode > 57)){
            return false;
        }
    }

    restrictZero(event){
        let value = parseFloat(event.target.value)
        if(value == 0){
            this.buttonDisabled = true
        } else {
            this.buttonDisabled = false;
        }
        this.getQuantity(value)
    }
}
