import {forwardRef, Inject, Input, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {SettingsService} from "@app/core";

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
    // @Input('digits') digitsInfo: string = '1.2-2';
    constructor(@Inject('$scope') private ajsScope,
                @Inject(forwardRef(() => SettingsService)) private settings,
                @Inject(LOCALE_ID) private locale: string) {
    }

    transform(
        value: any,
        currencyCode: string = this.ajsScope.CustomCurrencyCode,
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '',
        locale: string = this.locale,
    ): string | null {
        const intlSettings = this.settings._csIntlSettings;
        if (parseInt(intlSettings['currency']) === 0) {
            return formatCurrency(
                value,
                locale,
                getCurrencySymbol(currencyCode, 'narrow'),
                currencyCode,
                '1.2-2',
            );
        } else if(parseInt(intlSettings['currency']) === 1) {
            return formatCurrency(
                value,
                locale,
                getCurrencySymbol(currencyCode, 'wide'),
                currencyCode,
                '1.0-0',
            );
        } else if(parseInt(intlSettings['currency']) === 2) {
            let currency = parseInt(value);
            return getCurrencySymbol(currencyCode, 'wide') + currency.toString();
        } else if(parseInt(intlSettings['currency']) === 3) {
            let currency = formatCurrency(
                value,
                locale,
                getCurrencySymbol(currencyCode, 'narrow'),
                currencyCode,
                '1.2-2',
            );
            return currency.toString().replace(/,/g, '');

        } else if(parseInt(intlSettings['currency']) === 4) {
            let currency = formatCurrency(
                value,
                locale,
                getCurrencySymbol(currencyCode, 'narrow'),
                currencyCode,
                '1.2-2',
            );
            return currency.toString().replace(/,/g, ' ');

        } else if(parseInt(intlSettings['currency']) === 5) {
            let currency = formatCurrency(
                value,
                locale,
                getCurrencySymbol(currencyCode, 'narrow'),
                currencyCode,
                '1.2-2',
            );
            let currency_arr = currency.toString().split(".");
            return currency_arr[0].replace(/,/g, '.') + ',' + currency_arr[1];

        } else {
            if(value) {
                if (typeof value === 'string') {
                    value = parseFloat(value);
                }
                return this.ajsScope.CustomCurrency + value.toFixed(2).toString();
            } else if (value == 0) {
                return this.ajsScope.CustomCurrency + value.toFixed(2).toString();
            }else{
                return '';
            }
        }
    }

}
