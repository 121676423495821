import { OnInit, AfterViewInit, NgZone, EventEmitter } from '@angular/core';
var TinymceComponent = /** @class */ (function () {
    function TinymceComponent(zone) {
        this.zone = zone;
        this.selector = '';
        this.customClass = { 'subject': '', 'message': '' };
        this.toolbar = 'fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright';
        this.outputdata = new EventEmitter();
    }
    TinymceComponent.prototype.ngOnInit = function () {
        if (this.type.toLowerCase() === 'sms') {
            this.toolbar = false;
        }
        if (this.selector == 'supplier-email-template') {
            this.toolbar = 'undo redo | bold italic | alignleft aligncenter alignright';
        }
    };
    TinymceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        tinymce.init({
            selector: '#' + this.selector + '-subject',
            menubar: false,
            toolbar: false,
            statusbar: false,
            plugins: 'paste',
            browser_spellcheck: true,
            paste_as_text: true,
            paste_block_drop: false,
            relative_urls: false,
            remove_script_host: false,
            convert_urls: true,
            fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
            setup: function (editor) {
                editor.on('LoadContent', function () {
                    if (_this.data['subject']) {
                        editor.setContent(_this.data['subject']);
                    }
                    else {
                        editor.setContent("");
                    }
                });
                editor.on('change', function () {
                    var content = editor.getContent();
                    _this.zone.run(function () {
                        _this.data['subject'] = content;
                    });
                    _this.updateChange();
                });
            }
        });
        tinymce.init({
            mode: 'textareas',
            selector: '#' + this.selector,
            menubar: false,
            toolbar: this.toolbar,
            plugins: 'paste,textcolor',
            content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
            paste_as_text: true,
            browser_spellcheck: true,
            paste_block_drop: false,
            fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
            setup: function (editor) {
                editor.on('LoadContent', function () {
                    if (_this.data['message']) {
                        editor.setContent(_this.data['message']);
                    }
                    else {
                        editor.setContent("");
                    }
                });
                // keyup is not used due to change can be even done from tags
                editor.on('change', function () {
                    var content = editor.getContent();
                    _this.zone.run(function () {
                        _this.data['message'] = content;
                    });
                    _this.updateChange();
                });
            }
        });
    };
    TinymceComponent.prototype.updateChange = function () {
        this.outputdata.emit(this.data);
    };
    return TinymceComponent;
}());
export { TinymceComponent };
