
    <span>
        <span
            placement="right"
            [tooltip]="HtmlContent"
            class="labels"
            container="body"
            [ngStyle]="{ 'background-color': getStatusColor(message.data.status) }">
            {{ message.value }}
    </span>

    <ng-template #HtmlContent>
        <div style="text-align:left;">
            <div>
                <span translate>Submitted.by.user</span><span>: </span>
                <span *ngIf="message.data.tooltip.submittedByUser!=null">{{message.data.tooltip.submittedByUser.date | date: regionalDateFormat}} ({{message.data.tooltip.submittedByUser.date | date: 'h:mm'}})</span>
            </div> 
            <div>
                <span translate>Sent.to.AI</span><span>: </span>
                <span *ngIf="message.data.tooltip.sendToAi!=null">{{message.data.tooltip.sendToAi.date | date: regionalDateFormat}} ({{message.data.tooltip.sendToAi.date | date: 'h:mm'}})</span>
                
            </div>
            <div>
                <span translate>Information.received.back.from.AI</span><span>: </span>
                <span *ngIf="message.data.tooltip.informationReceive!=null">{{message.data.tooltip.informationReceive.date | date: regionalDateFormat}} ({{message.data.tooltip.informationReceive.date | date: 'h:mm'}})</span>
            </div>
        </div>
    </ng-template>
  