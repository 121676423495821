import {ElementRef, Injectable, QueryList} from '@angular/core';
import {Subject, Observable} from "rxjs/index";
import {SmartTableComponent} from "@app/smart_table/smart-table/smart-table.component";

declare var $;

@Injectable()
export class SmartTableInstanceService {
    datatableElement: any = {};
    dtTrigger: Subject<any> = new Subject();
    container: ElementRef;
    private _datas: any[] = [];
    private _component:SmartTableComponent;

    public refresh(params:any = null) {
        if($(this.container.nativeElement).hasClass('hide') && this._datas.length !== 0) {
            $(this.container.nativeElement).removeClass('hide');
        }
        if(params) {
            this._component.setParams(params);
        }
        setTimeout( () => {
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                //dtInstance.destroy();
                //this.dtTrigger.next();
                dtInstance.clear();
                dtInstance.ajax.reload();
            });
        },300);

    }

    public getSelectedRows() {
        return this._datas.filter(data => data.selected===true );
    }

    public checkUncheckAllRows(toggleSwitch:boolean) {
        this._datas.forEach(data => data.selected = toggleSwitch);
    }

    public setDatas(datas:any[]): void {
        this._datas = datas;
    }

    setComponent(component:SmartTableComponent) {
        this._component = component;
    }

     getDatas():any[] {
        return this._datas;
    }

    public columnsAdjust() {
      setTimeout( () => {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
            dtInstance.columns.adjust();
        })
      },100);
    }

    public reload(data:any = null) {
        this.refresh(data);
    }

    empty() {
        this._datas = [];
        this.dtTrigger.unsubscribe();
    }
}
