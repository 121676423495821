import { PipeTransform } from '@angular/core';
import * as moment from 'moment';
var TimelineDatePipe = /** @class */ (function () {
    function TimelineDatePipe(settings) {
        this.settings = settings;
    }
    TimelineDatePipe.prototype.transform = function (value, args) {
        var intlSettings = this.settings._csIntlSettings;
        var formatString;
        var dateFormat;
        if (parseInt(intlSettings['long_date']) === 0) {
            dateFormat = 'ddd Do MMM YYYY';
        }
        else if (parseInt(intlSettings['long_date']) === 1) {
            dateFormat = 'dddd Do MMM YYYY';
        }
        else if (parseInt(intlSettings['long_date']) === 2) {
            dateFormat = 'ddd Do MMMM YYYY';
        }
        else if (parseInt(intlSettings['long_date']) === 3) {
            dateFormat = 'dddd Do MMMM YYYY';
        }
        else {
            dateFormat = 'dddd Do MMM YYYY';
        }
        var timeFormat;
        if (parseInt(intlSettings['time']) === 0) {
            timeFormat = 'h:mm a';
        }
        else if (parseInt(intlSettings['time']) === 1) {
            timeFormat = 'H:mm';
        }
        else {
            timeFormat = 'H:mm';
        }
        if (args == 'datetime') {
            formatString = dateFormat + ' (' + timeFormat + ')';
        }
        else if (args == 'time') {
            formatString = '(' + timeFormat + ')';
        }
        else if (args == 'diarytime') {
            formatString = timeFormat;
        }
        else if (args == 'date') {
            formatString = dateFormat;
        }
        else {
            formatString = dateFormat;
        }
        if (value && typeof value === 'object' && value.hasOwnProperty('_isAMomentObject')) {
            if (args == 'diarytime') {
                this.utcTimeString = value;
            }
            else {
                this.utcTimeString = value._d;
            }
        }
        else {
            var isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
            this.utcTimeString = (isoRegex.test(value)) ? new Date(value) : new Date(value + ' UTC');
            this.utcTimeString = new Date(this.utcTimeString.toLocaleString(this.settings._csLanguage, { timeZone: this.settings._csCountryCode.timezone }));
        }
        return moment(this.utcTimeString).format(formatString);
    };
    return TimelineDatePipe;
}());
export { TimelineDatePipe };
var TimeStringFromSeconds = /** @class */ (function () {
    function TimeStringFromSeconds() {
    }
    TimeStringFromSeconds.prototype.transform = function (value, args) {
        var timeStr = '';
        var timeInSeconds = !isNaN(parseInt(value)) ? parseInt(value) : 0;
        if (timeInSeconds > 0) {
            var secondsToShow = timeInSeconds % 60;
            var minutes = timeInSeconds > 60 ? Math.floor(timeInSeconds / 60) : 0;
            var minutesToShow = minutes > 0 ? minutes % 60 : minutes;
            var hours = minutes > 60 ? Math.floor(minutes / 60) : 0;
            if (hours > 0) {
                var hourText = hours > 1 ? 'hours' : 'hour';
                timeStr += hours + ' ' + hourText;
            }
            if (minutesToShow > 0) {
                var text = minutesToShow > 1 ? 'minutes' : 'minute';
                timeStr += timeStr != '' ? ' ' : '';
                timeStr += minutesToShow + ' ' + text;
            }
            if (secondsToShow > 0) {
                var secText = secondsToShow > 1 ? 'seconds' : 'second';
                timeStr += timeStr != '' ? ' ' : '';
                timeStr += secondsToShow + ' ' + secText;
            }
        }
        return timeStr;
    };
    return TimeStringFromSeconds;
}());
export { TimeStringFromSeconds };
