import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {InterfaceService} from '@app/interfaces';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CustomerInvoicesService extends InterfaceService{
    constructor(
        private http: HttpClient
    ) { super(); }

    // common http calls
    saveConfigColumns(type, id, jobId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns): Observable<any>{
        const configColumn = {'columns' : {'show_vat_in_grand_totals' : showVatInGrandTotal, 'columns' : customisableSpreadSheetColumns}, 'invoiceId' : invoiceId };
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/invoices/${invoiceId}/updateConfigColumn`), JSON.stringify(configColumn));
    }
    // Single customer invoice

    saveInvoice(type, id, jobId, invoiceId, invoiceType, data: Object): Observable<any> {
        if (invoiceId === '') {
            return this.http.post(this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/invoices/add/${invoiceType}`), JSON.stringify(data));
        } else if (invoiceId !== '') {
            return this.http.post(this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/invoices/${invoiceId}/update/${invoiceType}`), JSON.stringify(data));
        }
    }

    saveOpportunityInvoice(type, id, opportunityId, invoiceId, invoiceType, data: Object): Observable<any> {
        if (invoiceId === '') {
            return this.http.post(this.getApiUrl(`customers/${type}/${id}/opportunities/${opportunityId}/invoices/add/${invoiceType}`), JSON.stringify(data));
        } else if (invoiceId !== '') {
            return this.http.post(this.getApiUrl(`customers/${type}/${id}/opportunities/${opportunityId}/invoices/${invoiceId}/update/${invoiceType}`), JSON.stringify(data));
        }
    }

    saveOpportunityConfigColumns(type, id, opportunityId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns): Observable<any>{
        const configColumn = {'columns' : {'show_vat_in_grand_totals' : showVatInGrandTotal, 'columns' : customisableSpreadSheetColumns}, 'invoiceId' : invoiceId };
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/opportunities/${opportunityId}/invoices/${invoiceId}/updateOpportunityConfigureColumn`), JSON.stringify(configColumn));
    }

    // Multiple customer Invoice
    saveMultipleJobInvoice(params: object, invoiceId: number, Data: object): Observable<any> {
        if (params["customerType"] === 'customer') {
            if (invoiceId) {
                return this.http.post(this.getApiUrl(`customers/${params["customerType"]}/${params["customerId"]}/multiple_invoice/${invoiceId}/save`), JSON.stringify(Data));
            } else {
                return this.http.post(this.getApiUrl(`customers/${params["customerType"]}/${params["customerId"]}/multiple_invoice/save`), JSON.stringify(Data));
            }
        } else {
            if (invoiceId) {
                return this.http.post(this.getApiUrl(`invoice_address/${params["invoiceAddressId"]}/multiple_invoice/${invoiceId}/save`), JSON.stringify(Data));
            } else {
                return this.http.post(this.getApiUrl(`invoice_address/${params["invoiceAddressId"]}/multiple_invoice/save`), JSON.stringify(Data));
            }
        }
    }

    printMultipleInvoice(customerType: string, customerId: string, invoiceId: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        // return this.http.get((`/customers/${customerType}/${customerId}/multiple_invoice/${invoiceId}/print`));
        return this.http
            .get(this.getApiUrl(`customers/${customerType}/${customerId}/multiple_invoice/${invoiceId}/print`), { headers: headers, responseType: 'blob' })
            .pipe(
                map(data => data)
            )
    }

    // Contract invoice update
    saveInvoiceContract(type, id, jobId, invoiceId, invoiceType, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/customer-contracts/${jobId}/invoices/${invoiceId}/update/${invoiceType}`), JSON.stringify(data));
    }

    // Contract invoice update config
    saveConfigContractColumns(type, id, contractId, invoiceId, showVatInGrandTotal, customisableSpreadSheetColumns): Observable<any>{
        const configColumn = {'columns' : {'show_vat_in_grand_totals' : showVatInGrandTotal, 'columns' : customisableSpreadSheetColumns}, 'invoiceId' : invoiceId };
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/customer-contracts/${contractId}/invoices/${invoiceId}/updateConfigColumn`), JSON.stringify(configColumn));
    }

    saveMultipleJobCreditNote(params: object, invoiceId: number,creditNoteId:number, Data: object): Observable<any> {
        if (params["customerType"] === 'customer') {
            if (creditNoteId) {
                return this.http.post(this.getApiUrl(`customers/${params["customerType"]}/${params["customerId"]}/credit_note/${creditNoteId}/update`), JSON.stringify(Data));
            } else {
                return this.http.post(this.getApiUrl(`customers/${params["customerType"]}/${params["customerId"]}/multiple_invoice/${invoiceId}/add_credit_note`), JSON.stringify(Data));
            }
        } else {
            if (creditNoteId) {
                return this.http.post(this.getApiUrl(`invoice_address/${params["invoiceAddressId"]}/credit_note/${creditNoteId}/update`), JSON.stringify(Data));
            } else {
                return this.http.post(this.getApiUrl(`invoice_address/${params["invoiceAddressId"]}/multiple_invoice/${invoiceId}/add_credit_note`), JSON.stringify(Data));
            }
        }
    }

    // get creditNote allocation data
    getCreditNoteAllocation(customerType:string,creditNoteId: number,invoiceAddressID:number): Observable<any> {
        if(invoiceAddressID){
            return this.http.get(this.getApiUrl(`invoice_address/${invoiceAddressID}/get_cash_allocation_data/new_customer_cash_allocation_invoices?creditNoteId=${creditNoteId}`));
        }else{
            return this.http.get(this.getApiUrl(`customers/${customerType}/get_cash_allocation_data/new_customer_cash_allocation_invoices?creditNoteId=${creditNoteId}`));
        }
    }

    saveEditCreditNoteAllocation(params: object,creditNoteId:number, Data: object): Observable<any> {
        if (params["customerType"] === 'customer') {
                return this.http.post(this.getApiUrl(`customers/${params["customerType"]}/${params["customerId"]}/credit_note/${creditNoteId}/allocation/save`), JSON.stringify(Data));
        } else {
                return this.http.post(this.getApiUrl(`invoice_address/${params["invoiceAddressId"]}/credit_note/${creditNoteId}/allocation/save`), JSON.stringify(Data));
        }
    }

    checkCustomerCreditNote(creditNoteId){
        return this.http.get(this.getApiUrl(`check_consolidated_customer_credit_note/${creditNoteId}`))
    }

    getInvoiceCustomTemplates(action:string,invId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomConfigTemplates?screenMode=addCustomTemplates&action=${action}&objects=Invoices&objectTypes=Invoices&objectTypeID=${invId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }
}
