import { OnInit, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs/index";
import { SmartTableMetaDataService } from "@app/smart_table/services/metadata.service";
import { DataTableDirective } from "angular-datatables";
import { AuthService, SettingsService } from "@app/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { HybridFactory } from "@app/core/hybrid-factory";
import { Router } from "@angular/router";
var SettingsTableComponent = /** @class */ (function () {
    function SettingsTableComponent(metadataService, authService, http, jwtToken, ajsState, router) {
        this.metadataService = metadataService;
        this.authService = authService;
        this.http = http;
        this.jwtToken = jwtToken;
        this.ajsState = ajsState;
        this.router = router;
        this.apiOptions = [];
        this.actions = [];
        this.rowReorder = false;
        this.rowSelect = false;
        this.data = [];
        this.category = '';
        this.emptyMessage = 'No records found';
        this.defaultEmptyMessage = 'No records found';
        this.dataUrl = '';
        this.actionClass = 'actions-header-column';
        this.tdAdditionalClass = false;
        this.isExternalAPI = false;
        this.rowSelectable = false;
        this.processing = false;
        this.searchMessage = '';
        this.isPostMethod = false;
        this.$instance = new EventEmitter();
        this.$dataCount = new EventEmitter();
        this.$selectedRow = new EventEmitter();
        this.rowReorderCallBack = new EventEmitter();
        this.selectedCheckBoxValue = new EventEmitter();
        this.overAllSelect = new EventEmitter();
        this.onSelectChanges = new EventEmitter();
        this.columns = [];
        this.limit = 20;
        this.dtOptions = {};
        this.dtTrigger = new Subject();
        this.toggleData = {};
        this.columnsCount = 0;
        this.loading = true;
        this.selectAllCheckbox = false;
        this._externalParams = null;
        this.settingsApi = null;
        this.tableId = '';
        this.totalRecordCount = 0;
        this.loadingFirstTime = true;
    }
    SettingsTableComponent.prototype.ngOnInit = function () {
        this.metadataService.initiaze(this.metadata);
        this.columns = this.metadataService.getColumns();
        this.limit = this.metadataService.getLimit();
        this.tableId = Math.random().toString(36).substr(2, 9);
        this.renderTable();
    };
    SettingsTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dtTrigger.next();
        var that = this;
        this.$instance.emit(this);
        this.datatableElement.dtInstance.then(function (dtInstance) {
            var table = dtInstance.table('#cs-settings-table-v2-' + _this.tableId);
            var diff$ = [];
            table.on('row-reorder', function (e, diff, edit) {
                //console.log('table', edit.triggerRow.data());
                for (var i = 0, ien = diff.length; i < ien; i++) {
                    var rowData = table.row(diff[i].node).data();
                    diff$.push({
                        id: rowData.id,
                        position: [diff[i].newPosition, diff[i].oldPosition],
                        order: diff[i].newPosition
                    });
                }
                //console.log('diff', diff);
                if (diff.length === 0) {
                    dtInstance.draw(false);
                }
                if (diff.length && that.rowReorder) {
                    //that.rowReorder(diff$, edit.triggerRow.data(), that);
                    that.rowReorderCallBack.emit({
                        diff: diff$,
                        affectedRow: edit.triggerRow.data(),
                        instance: that
                    });
                }
                diff$ = [];
            });
            $('#cs-settings-table-v2-' + _this.tableId + ' tbody').on('change', 'select', function (e) {
                var data = table.row($(this).parents('tr')).data(), index = table.row($(this).parents('tr')).index();
                var selectedValue = $(e.target).val();
                that.onSelectChanges.emit({ dropDownChange: selectedValue, index: table.row($(this).parents('tr')).index() });
            });
            $('#cs-settings-table-v2-' + _this.tableId + ' tbody').on('click', 'a.link-action-btn, button.link-action-btn', function (e) {
                var _this = this;
                e.preventDefault();
                var data = table.row($(this).parents('tr')).data(), index = table.row($(this).parents('tr')).index();
                var actionProp = that.actions.find(function (x) { return x.id == $(_this).attr('action-id'); });
                if (actionProp && actionProp.hasOwnProperty('onClick') && typeof actionProp.onClick === 'function') {
                    //console.log(data, dtInstance.row($(this).parents('tr')).index(), actionProp);
                    $(e.currentTarget).blur();
                    if (actionProp.hasOwnProperty('actionType') && actionProp['actionType'] == 'route') {
                        var routeOptions = actionProp.onClick(data);
                        if (routeOptions.hasOwnProperty('state') && typeof routeOptions['state'] == 'string') {
                            that.ajsState.transitionTo(routeOptions['state'], routeOptions['params']);
                        }
                        else {
                            that.router.navigate([routeOptions['router'], routeOptions['params']]);
                        }
                    }
                    else {
                        e.stopPropagation();
                        actionProp.onClick(data, dtInstance.row($(this).parents('tr')).index(), that, $(this).parents('tr'));
                    }
                }
            });
        });
        $(window).on('resize', function () {
            that.datatableElement.dtInstance.then(function (dtInstance) {
                dtInstance.columns.adjust();
            });
        });
    };
    SettingsTableComponent.prototype.ngOnDestroy = function () {
        this.dtTrigger.unsubscribe();
    };
    SettingsTableComponent.prototype.defaultProps = function () {
        if (this.rowReorder) {
            this.data.map(function (item, index) {
                item['@empty_rowReOrder'] = index + 1;
                return item;
            });
        }
        return {
            dom: 'rtipl',
            lengthMenu: [[5, 10, 20, 50, 100], [5, 10, 20, 50, 100]],
            autoWidth: false,
            serverSide: false,
            sPaginationType: "bootstrap",
            processing: false,
            pagingType: 'full_numbers',
            scrollCollapse: true,
            scrollX: true,
            colReorder: false,
            aaSorting: [],
            select: false,
            info: true,
            bLengthChange: true,
            responsive: false,
            data: this.data,
            initComplete: function (settings, json) {
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 0);
            }
        };
    };
    SettingsTableComponent.prototype.renderActions = function (value, type, row) {
        var _this = this;
        var actions = '';
        this.actions.forEach(function (action) {
            var id = !action.id ? action.title : action.id;
            action.access = !action.hasOwnProperty('access') ? [] : action.access;
            var allow = _this.authService.permitted(action.access) === true;
            if (allow && action.hasOwnProperty('visible') && typeof action['visible'] == 'function') {
                var cb = action['visible'];
                allow = cb(value, type, row);
            }
            if (allow) {
                if (action.hasOwnProperty('render') && typeof action.render == 'function') {
                    actions += action.render(value, type, row);
                }
                else {
                    if (action.hasOwnProperty('actionType') && action['actionType'] == 'route') {
                        var routeOptions = action.onClick(row);
                        var $URL = '';
                        if (routeOptions.hasOwnProperty('state') && typeof routeOptions['state'] == 'string') {
                            var statePrams = routeOptions.hasOwnProperty('params') ? routeOptions['params'] : {};
                            $URL = HybridFactory.ngUpgrade.$injector.get('$state').href(routeOptions['state'], statePrams);
                        }
                        else {
                            $URL = '/' + routeOptions['router'];
                        }
                        actions += '<a tabindex="-1" href="' + $URL + '" action-id="' + id + '" class="link-action-btn cs-setting-table"> ' + action.title + ' </a> ';
                    }
                    else {
                        actions += '<button tabindex="-1" action-id="' + id + '" class="link-action-btn cs-setting-table"> ' + action.title + ' </button> ';
                    }
                }
            }
        });
        return actions;
    };
    ;
    SettingsTableComponent.prototype.renderTable = function () {
        var _this = this;
        var that = this;
        var options = this.defaultProps();
        options.pageLength = this.limit;
        options.columns = this.prepareColumns();
        options.order = [];
        var colsDefs = this.metadataService.getColumnsDefs();
        if (colsDefs.length) {
            options.columnDefs = colsDefs;
        }
        if (!options.columns.length) {
            throw new Error("Columns must not be empty.");
        }
        options.language = {};
        if (this.emptyMessage) {
            options.language['emptyTable'] = this.emptyMessage;
        }
        options.language['processing'] = '<div class="spreadsheet-loading-wrap">\n' +
            ' <span class="loading-spreadsheet">\n' +
            '  <span class="loading-x-axis"></span>\n' +
            '  <span class="loading-y-axis"></span>\n' +
            '  <div class="loading-row row-1"></div>\n' +
            '  <div class="loading-row row-2"></div>\n' +
            '  <div class="loading-row row-3"></div>\n' +
            '  <div class="loading-row row-4"></div>\n' +
            '  <div class="loading-row row-5"></div>\n' +
            ' </span>\n' +
            '</div>';
        options.drawCallback = function (settings) {
            _this.settingsApi = settings;
        };
        if (this.rowReorder) {
            options.rowReorder = {
                dataSrc: '@empty_rowReOrder',
                update: false,
                snapX: 1
            };
        }
        if (this.dataUrl !== '') {
            // options.ajax
            options.serverSide = true;
            options.processing = this.processing;
            options.ajax = function (dataTablesParameters, callback) {
                var params = that.paramsTransform(dataTablesParameters);
                var getData;
                if (typeof _this.apiOptions == 'object' && _this.apiOptions.length > 0) {
                    var method = _this.apiOptions[0];
                    var apiUrl = _this.apiOptions[1];
                    if (method == 'post') {
                        getData = that.http.post(that.getApiUrl(apiUrl, null), params);
                    }
                    else {
                        getData = that.http.get(that.getApiServiceUrl(apiUrl, null), { params: params });
                    }
                }
                else {
                    if (!_this.isPostMethod) {
                        if (!_this.isExternalAPI) {
                            getData = that.http.get(that.getApiUrl(that.dataUrl, null), { params: params });
                        }
                        else {
                            var url = _this.dataUrl;
                            getData = _this.http.get(that.getContractApiUrl(that.dataUrl, null), {
                                params: params
                            });
                        }
                    }
                    else {
                        getData = that.http.post(that.getApiUrl(that.dataUrl, null), params);
                    }
                }
                getData.subscribe(function (resp) {
                    var _resonseDatas = _this.getDescendantProp(resp, _this.category);
                    _this.data = _resonseDatas;
                    if (_resonseDatas) {
                        _this.responseData = resp;
                        _this.$instance.emit(_this);
                    }
                    if (resp.count == 0) {
                        $('#cs-settings-table-v2-' + _this.tableId + '_info').hide();
                        $('#cs-settings-table-v2-' + _this.tableId + '_paginate').hide();
                        $('#cs-settings-table-v2-' + _this.tableId + '_length').hide();
                    }
                    else {
                        $('#cs-settings-table-v2-' + _this.tableId + '_info').show();
                        $('#cs-settings-table-v2-' + _this.tableId + '_paginate').show();
                        $('#cs-settings-table-v2-' + _this.tableId + '_length').show();
                    }
                    _this.$dataCount.emit(resp.count);
                    callback({
                        recordsTotal: parseInt(resp.count),
                        recordsFiltered: parseInt(resp.count),
                        data: _resonseDatas
                    });
                    if (that.loadingFirstTime && resp.count == 0) {
                        that.loadingFirstTime = true;
                    }
                    else {
                        that.loadingFirstTime = false;
                    }
                    that.loading = false;
                    _this.totalRecordCount = parseInt(resp.count);
                    $('#cs-settings-table-v2-' + _this.tableId + ' tbody').on('click', 'tr', function (event) {
                        return _this.selectedRow(event, true);
                    });
                    $('#cs-settings-table-v2-' + _this.tableId + ' tbody').on('click', 'td:first-child', function (event) {
                        var position = $(event.currentTarget).parent('tr').index();
                        if ($(event.target).hasClass('file-checkbox') && (event.target.checked == true)) {
                            $("#cs-settings-table-v2-" + _this.tableId + " tbody tr td:first-child input").eq(position).addClass('checked-true');
                            $("#cs-settings-table-v2-" + _this.tableId + " tbody tr").eq(position).addClass('checked-row');
                            _this.selectedCheckBox(event, true);
                        }
                        if ($(event.target).hasClass('file-checkbox') && (event.target.checked == false)) {
                            $("#cs-settings-table-v2-" + _this.tableId + " tbody tr td:first-child input").eq(position).removeClass('checked-true');
                            $("#cs-settings-table-v2-" + _this.tableId + " tbody tr").eq(position).removeClass('checked-row');
                            _this.selectedCheckBox(event, false);
                        }
                    });
                    // if (this.tdAdditionalClass == true) {
                    //     $(`#cs-settings-table-v2-${this.tableId} tbody td`).addClass('td-background');
                    // }
                    setTimeout(function () {
                        $(window).trigger('resize');
                    }, 0);
                });
            };
        }
        this.dtOptions = options;
    };
    SettingsTableComponent.prototype.moveDown = function ($event) {
        if (this.rowSelectable) {
            $event.preventDefault();
            this.selectedRow('', false, 'down');
        }
    };
    SettingsTableComponent.prototype.moveUp = function ($event) {
        if (this.rowSelectable) {
            $event.preventDefault();
            this.selectedRow('', false, 'up');
        }
    };
    SettingsTableComponent.prototype.onResize = function (event) {
        setTimeout(function () {
            $(window).trigger('resize');
        }, 250);
    };
    SettingsTableComponent.prototype.selectedRow = function (event, eventBased, keyDirection) {
        if (event === void 0) { event = null; }
        if (eventBased === void 0) { eventBased = false; }
        if (keyDirection === void 0) { keyDirection = null; }
        if (this.rowSelectable) {
            var selectedRowIndex = '';
            if (eventBased) {
                if ($(event.target).is('a')) {
                    return false;
                }
                selectedRowIndex = $(event.currentTarget).index();
            }
            else {
                if (keyDirection == 'up') {
                    selectedRowIndex = $('.selected-row').prev().index();
                }
                else if (keyDirection == 'down') {
                    selectedRowIndex = $('.selected-row').next().index();
                }
            }
            if (selectedRowIndex >= 0) {
                $('.selected-row').removeClass('selected-row');
                $("#cs-settings-table-v2-" + this.tableId + " tbody tr").eq(selectedRowIndex).addClass('selected-row');
                this.$selectedRow.emit(this.data[selectedRowIndex]);
            }
        }
    };
    SettingsTableComponent.prototype.getDescendantProp = function (obj, cat) {
        var arr = cat.split(".");
        while (arr.length && (obj = obj[arr.shift()]))
            ;
        return obj;
    };
    SettingsTableComponent.prototype.paramsTransform = function (params) {
        var tableParams = {}, page = 1;
        if (params.length) {
            tableParams['limit'] = params.length;
        }
        tableParams['page'] = page + (params.start / params.length);
        // tableParams['category'] = this.category;
        var externalParams = this._externalParams;
        // this._externalParams=null;
        if (externalParams) {
            tableParams = Object.assign({}, tableParams, externalParams);
            if (externalParams.hasOwnProperty('fromTrigger')) {
                this._externalParams = null;
            }
        }
        if (params['order'] && params['order'].length) {
            var orders = params['order'];
            var sort_1 = [];
            orders.forEach(function (item) {
                var $cols = params['columns'][item['column']];
                sort_1.push({
                    'column': $cols['data'],
                    'dir': item['dir']
                });
            });
            if (sort_1.length) {
                tableParams['order'] = encodeURIComponent(JSON.stringify(sort_1));
            }
        }
        return tableParams;
    };
    SettingsTableComponent.prototype.prepareColumns = function () {
        if (this.rowSelect) {
            this.columns.unshift(this.checkBoxColumn());
        }
        if (this.rowReorder) {
            this.columns.unshift(this.rowReorderColumn());
        }
        if (this.actions.length) {
            this.columns.push(this.actionsBtnColumn());
        }
        this.columns.forEach(function (col, index) {
            col.columnIndex = index;
        });
        this.columnsCount = this.columns.length;
        return this.columns;
    };
    SettingsTableComponent.prototype.checkBoxColumn = function () {
        return {
            data: '@empty_select',
            title: '',
            className: 'select-checkbox-all',
            orderable: false,
            visible: true,
            width: '10px'
        };
    };
    SettingsTableComponent.prototype.rowReorderColumn = function () {
        return {
            data: '@empty_rowReOrder',
            title: '',
            className: 'row-reorder-col reorder',
            orderable: false,
            visible: true,
            width: '6px',
            render: function () { return '<span class=""><img src="/images/reorder_icon.png" alt="..."/></span>'; }
        };
    };
    SettingsTableComponent.prototype.actionsBtnColumn = function () {
        var _this = this;
        return {
            data: '@empty_actions',
            title: 'Actions',
            className: this.actionClass,
            orderable: false,
            visible: true,
            width: '16px',
            defaultContent: '',
            render: function (value, type, row) { return _this.renderActions(value, type, row); }
        };
    };
    SettingsTableComponent.prototype.onSelectAllRows = function () {
        this.selectAllCheckbox = !this.selectAllCheckbox;
        //this.smartTableInstance.checkUncheckAllRows(this.selectAllCheckbox);
    };
    SettingsTableComponent.prototype.onSelectRow = function (data, col) {
        data.selected = !data.selected;
    };
    SettingsTableComponent.prototype.updateData = function (newData, paging) {
        if (paging === void 0) { paging = true; }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.clear();
            dtInstance.rows.add(newData);
            dtInstance.draw(paging);
        });
    };
    SettingsTableComponent.prototype.updateRow = function (newData, index, paging, doRedraw) {
        if (paging === void 0) { paging = true; }
        if (doRedraw === void 0) { doRedraw = true; }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.row(index).data(newData).draw(paging);
        });
    };
    SettingsTableComponent.prototype.addRow = function (newData, paging, redirectPage) {
        if (paging === void 0) { paging = true; }
        if (redirectPage === void 0) { redirectPage = 'last'; }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.row.add(newData).draw(paging);
            dtInstance.page(redirectPage).draw(false);
        });
    };
    SettingsTableComponent.prototype.removeRow = function (index, paging) {
        if (paging === void 0) { paging = true; }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.row(index).remove();
            dtInstance.draw(paging);
        });
    };
    SettingsTableComponent.prototype.reload = function (params, resetPaging) {
        var _this = this;
        if (params === void 0) { params = null; }
        if (resetPaging === void 0) { resetPaging = true; }
        this._externalParams = params;
        //console.log(this._externalParams);
        setTimeout(function () {
            _this.datatableElement.dtInstance.then(function (dtInstance) {
                dtInstance.clear();
                dtInstance.ajax.reload(null, resetPaging);
            });
        }, 100);
    };
    SettingsTableComponent.prototype.getApiUrl = function (path, params) {
        var url = environment.apiHost + environment.apiBasePath + path;
        if (params instanceof Object) {
            url += '?page=' + params.page + '&limit=' + params.limit;
        }
        return url;
    };
    SettingsTableComponent.prototype.getContractApiUrl = function (path, params) {
        var url = environment.apiHost + environment.microserviceApiContract + path;
        if (params instanceof Object) {
            url += '?page=' + params.page + '&limit=' + params.limit;
        }
        return url;
    };
    SettingsTableComponent.prototype.updatePageLimit = function (pageNumber) {
        this._externalParams = this._externalParams ? this._externalParams : {};
        this._externalParams['page'] = pageNumber;
        this._externalParams['limit'] = this.limit;
        this._externalParams['fromTrigger'] = true;
    };
    SettingsTableComponent.prototype.triggerCountChange = function (action) {
        var _this = this;
        if (action === void 0) { action = 'add'; }
        var currentCount = this.totalRecordCount;
        var pageToLoad = 0;
        if (action == 'add') {
            currentCount += 1;
        }
        else if (action == 'remove') {
            currentCount -= 1;
        }
        var that = this;
        this.datatableElement.dtInstance.then(function (dtInstance) {
            if (action == 'remove') {
                var currentData = dtInstance.data();
                var currentPageCount = currentData ? currentData.length : 0;
                if (currentPageCount == 1) {
                    currentCount -= 1;
                    pageToLoad = Math.ceil((currentCount) / _this.limit);
                    if (pageToLoad == 0) {
                        pageToLoad = 1;
                    }
                    that.updatePageLimit(pageToLoad);
                    dtInstance.ajax.reload(function () { dtInstance.page('last').draw('page'); }, true);
                }
                else {
                    dtInstance.ajax.reload(null, true);
                }
            }
            else {
                pageToLoad = Math.ceil((currentCount) / _this.limit);
                that.updatePageLimit(pageToLoad);
                dtInstance.ajax.reload(function () { dtInstance.page('last').draw('page'); }, true);
            }
        });
    };
    SettingsTableComponent.prototype.selectedCheckBox = function (event, eventBased) {
        if (event === void 0) { event = null; }
        if (eventBased === void 0) { eventBased = false; }
        var selectedRowIndex = '';
        selectedRowIndex = $(event.currentTarget).parent('tr').index();
        var singleEvent = this.data[selectedRowIndex];
        singleEvent['indexValue'] = selectedRowIndex;
        singleEvent['isChecked'] = $(event.target).closest('.file-checkbox').prop('checked');
        this.selectedCheckBoxValue.emit(singleEvent);
    };
    SettingsTableComponent.prototype.documentClick = function (event) {
        var currentEle = $(event.target);
        if (currentEle.closest('.check-all').length) {
            $(".file-checkbox").prop('checked', false);
            $('.file-checkbox').removeClass('checked-true');
            $("#cs-settings-table-v2-" + this.tableId + " tbody tr").removeClass('checked-row');
            var checkboxVal = currentEle.closest('.check-all').prop('checked');
            $(".file-checkbox:lt(10)").prop('checked', checkboxVal);
            this.overAllSelect.emit(checkboxVal);
            if (checkboxVal) {
                $("#cs-settings-table-v2-" + this.tableId + " tbody tr:lt(10)").addClass('checked-row');
                $('.file-checkbox:lt(10)').addClass('checked-true');
                $('.file-checkbox').not('.checked-true').attr('disabled', true);
            }
            else {
                $('.file-checkbox').removeClass('checked-true');
                $("#cs-settings-table-v2-" + this.tableId + " tbody tr").removeClass('checked-row');
                $('.file-checkbox').not('.checked-true').attr('disabled', false);
            }
        }
    };
    SettingsTableComponent.prototype.getApiServiceUrl = function (path, params) {
        var url = path;
        if (params instanceof Object) {
            url += '?page=' + params.page + '&limit=' + params.limit;
        }
        return url;
    };
    return SettingsTableComponent;
}());
export { SettingsTableComponent };
