import { Subject } from "rxjs";
var EventDispatcherService = /** @class */ (function () {
    function EventDispatcherService() {
        this.triggerEvent = new Subject();
        this.triggerEvent$ = this.triggerEvent.asObservable();
    }
    EventDispatcherService.prototype.dispatchEvent = function (actionName, eventData) {
        if (eventData === void 0) { eventData = {}; }
        var data = {};
        data['actionName'] = actionName;
        data['data'] = eventData;
        this.triggerEvent.next(data);
    };
    EventDispatcherService.CONSTANTS = {
        'init': 'event:init',
        'onChangedTemplate': 'event:onChangedTemplate',
        'onChangedMenuItem': 'event:onChangedMenuItem',
        'isValidForm': 'event:isValidForm',
        'currentActiveInput': 'event:currentActiveInput',
        'dispatchUpdatedForm': 'event:dispatchUpdatedForm',
        'dispatchReservedData': 'event:dispatchReservedData',
        'unSavedChanges': 'event:unSavedChanges',
        'pushDeletedItem': 'event:pushDeletedItem',
        'close': 'event:close',
        'goToNextItem': 'event:goToNextItem',
        'checkIsLastIndex': 'event:checkIsLastIndex',
        'onSaveAction': 'event:onSaveAction'
    };
    return EventDispatcherService;
}());
export { EventDispatcherService };
