<form *ngIf="sectionForm" [formGroup]="sectionForm" (submit)="saveSectionName()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>
				Edit.section.name.text.help
			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">

					<!-- Section Name -->
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>Section.name</label>
						<div class="controls">
							<input type="text" class="control-input" formControlName="sectionName">
						</div>
					</div>

					<!-- List Name -->
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>List.name</label>
						<div class="controls">
							<input type="text" class="control-input" formControlName="listName">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="(!sectionForm.value['sectionName'] && !sectionForm.value['listName']) || action == 'saving'" class="btn btn-primary">
			<span [hidden]="action == 'saving'" translate>Save</span>
			<span [hidden]="action != 'saving'" translate>Saving</span>
		</button>
	</div>
</form>