import { BsDatepickerConfig } from "ngx-bootstrap";

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showWeeks: false,
    showWeekNumbers: false,
    customTodayClass: 'today',
    selectFromOtherMonth: true
  });
}
