import { Injectable } from '@angular/core';
import {InterfaceService} from "@app/interfaces";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class CommunicationHttpService extends InterfaceService {
  constructor(
      private http: HttpClient
  ) {
      super();
  }
    getEmailTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsEmailTemplates`, params));
    }

    getSMSTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsSmsTemplates`, params));
    }

    getCommunicationByScreenType(objectName, categoryType, screenType, params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`communication/settings/${objectName}/${categoryType}/constant/${screenType}`, params));
    }

    saveCommunication(objectName, categoryType, screenType, params?: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`communication/settings/${objectName}/${categoryType}/constant/${screenType}/save`), params);
    }
}
