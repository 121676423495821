<form (submit)="save()" *ngIf="form" [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}Rename file</h4>
    </div>
    <div class="modal-body ng-partsandprices">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>File.Name </label>
                        <div class="controls">
                            <input (keyup)="updateValidation($event)" class="control-input" formControlName="name"
                                   type="text">
                            {{ fileType}}
                        </div>
                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                        <span *ngIf="f.name.errors.required" class="control-error-message show">
                                <span translate>Please.enter.a.valid.Name</span>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <a (click)="bsModalRef.hide()" class="btn" href="javascript:void(0)">Cancel</a>
        <button #saveBtn [disabled]="form.invalid || action == 'saving'" class="btn btn-primary">
            <span [hidden]="action == 'saving'" translate>Save</span>
            <span [hidden]="action != 'saving'" translate>Saving</span>
        </button>
    </div>
</form>
