import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap  } from 'rxjs/operators';

// --
import { InterfaceService } from '@app/interfaces';

@Injectable({
    providedIn: 'root'
})
export class PriceBooksService extends InterfaceService {

    constructor(
        private http: HttpClient
    ) { super(); }

    // Get groups configured
    getPriceBookList(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`listPriceBooks`, params));
    }

    addOrEditPriceBooks(params?: Object): Observable<any> {
        if (params['id'] === '') {
            return this.http.post(this.getApiUrl(`system_settings/price_books/save`), JSON.stringify(params));
        }
        else {
            return this.http.post(this.getApiUrl(`system_settings/price_books/${params['id']}/update`), JSON.stringify(params));
        }
    }

    clonePriceBooks(params?: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/clone_price_books`), JSON.stringify(params));
    }

    checkUniqueName(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`priceBooksExists`, params));
    }

    getPricingItems(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`listPricingItems`, params));
    }

    listConfiguredPricingItems(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/price_books/${params}/configure/pricing_items`), params);
    }
    listConfiguredLabourRates(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/price_books/${params}/configure/labour_rates`), params);
    }
    listLabourRates(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`listPriceBookLabourRates`, params)).pipe(
                map(Response => Response)
            );
    }
    saveLabourRateLineItems(templateId: number, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/price_books/${templateId}/configure/manage_labour_rates`), JSON.stringify(data));
    }
    deletePriceBookLR(params?: Object): Observable<any> {
        return this.http
            .delete(this.getApiUrl(`delete_PB_labour_rates/${params}`), params);
    }
    deletePriceBookPI(params?: Object): Observable<any> {
        return this.http
            .delete(this.getApiUrl(`delete_PB_pricing_items/${params}`), params);
    }
    savePricingItemPb(templateId: number, data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/price_books/${templateId}/configure/add_edit_pricing_item_PB`), JSON.stringify(data));
    }
    beforeDelete(id?: any): Observable<any>  {
        return this.http
            .get(this.getApiUrl(`canDeletePriceBooks/${id}`));
    }
    deletePriceBooksRequest(params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`delete_priceBooks`),params);
    }

    checkPassword (params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`validUserPassword?___noPageloading=true`),params);
    }

    checkExistsUsername(query?: any): Observable<any> {
        return this.http
            .get(this.getApiUrl(`validUserName?___noPageloading=true&username=${query}`));
    }
}
