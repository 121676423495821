import { NgModule } from '@angular/core';
import { AppCommonModule } from "@app/shared";
import { FileUploadModule } from 'ng2-file-upload';

//--
import {ScheduleActivityModalComponent} from "@app/shared/communication-modals/schedule-activity-modal/schedule-activity-modal.component";
import { CallLogModalComponent } from './call-log-modal/call-log-modal.component';
import { CommunicationEmailModalComponent } from './communication-email-modal/communication-email-modal.component';
import { AcceptProposalModalComponent } from './accept-proposal-modal/accept-proposal-modal.component';
import { FileModalComponent } from './file-modal/file-modal.component';
import { NoteModalComponent } from './note-modal/note-modal.component';
import { SmsModalComponent } from './sms-modal/sms-modal.component';
import { CommonFolderStructureModule } from '../common-folder-structure/common-folder-structure.module';
import { SearchTemplateComponent } from '../common-folder-structure/search-template/search-template.component';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {BsDatepickerModule} from "ngx-bootstrap";
import {DirectivesModule} from "@app/directives";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";

@NgModule({
    declarations: [
        ScheduleActivityModalComponent,
        CallLogModalComponent,
        CommunicationEmailModalComponent,
        AcceptProposalModalComponent,
        CallLogModalComponent,
        FileModalComponent,
        NoteModalComponent,
        SmsModalComponent
    ],
    exports:[
        ScheduleActivityModalComponent,
        CallLogModalComponent,
        CommunicationEmailModalComponent,
        AcceptProposalModalComponent,
        CallLogModalComponent,
        FileModalComponent,
        NoteModalComponent,
        SmsModalComponent
    ],
    imports: [
        FileUploadModule,
        CommonFolderStructureModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        BsDatepickerModule,
        DirectivesModule,
        FormsModule,
        CsComponentsModule
    ],
})
export class CommunicationModalModule { }
