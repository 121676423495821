import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { HybridFactory } from '@app/core';
import { IntegrationsService } from '@app/features/company-settings/integrations/services/integrations.service';
import { CommonService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: '[app-cs-toggleuserpreference]',
    templateUrl: './cs-toggleuserpreference.component.html',
    styleUrls: ['./cs-toggleuserpreference.component.css'],

})

export class CsToggleuserpreferenceComponent implements OnInit {

    @Input('screenName') screenName: string = '';
    @Input('labelName') labelName: string = 'Try out the new view screen';
    @Input('oldUrl') oldUrl: string = '';
    @Input('newUrl') newUrl: string = '';
    @Input('preferenceValue') preferenceValue: string = '';
    @Output() selected_preference = new EventEmitter<any>()

    @Input('integrationsUI') integrationsUI: boolean = false;
    feedback: string = '';

    toggleSwitch: boolean = false;
    showFeedbackForm: boolean;
    count: number = 0;
    feedbackEmitter$: Subject<string> = new Subject<string>();
    isFeedbackValid: boolean;

    constructor(private commonService: CommonService,
        private integrationsService: IntegrationsService) {

            this.feedbackEmitter$.pipe(
                debounceTime(500),
                distinctUntilChanged())
                .subscribe(value => {
                    this.validateFeedback();
                });
        }

    ngOnInit() {
        if (this.preferenceValue == '1') {
            this.toggleSwitch = true;
        } else {
            this.toggleSwitch = false;
        }
    }

    setUserPropertyViewPreference(savedFeedback?: boolean) {
        this.count++
        if (!this.integrationsUI || savedFeedback) {
            if (savedFeedback) {
                this.toggleSwitch = true
            }
            let data = {};
            data['screenName'] = this.screenName;
            if (this.toggleSwitch == true) {
                this.toggleSwitch = false;
                data['columnValue'] = 0;
                this.commonService.saveUserNewUIPreference(data)
                    .subscribe(resp => {
                        window.location.href = this.oldUrl;
                    }, error => {
                        console.log('err', error);
                    });
            } else if (this.toggleSwitch == false) {
                this.toggleSwitch = true;
                data['columnValue'] = 1;
                this.commonService.saveUserNewUIPreference(data)
                    .subscribe(resp => {
                        window.location.href = this.newUrl;
                    }, error => {
                        console.log('err', error);
                    });
            }
        } else {
            this.showFeedbackForm = true;
            this.toggleSwitch = false;
        }

        if (this.count == 2 && this.integrationsUI && !savedFeedback) {
            this.hideFeedbackForm();
        }
    }

    hideFeedbackForm() {
        if (this.showFeedbackForm) {
            this.showFeedbackForm = false
            this.toggleSwitch = true;
            this.feedback = '';
            this.count = 0;
        }
    }

    submitFeedbackForm() {

        if (this.isFeedbackValid) {
            let data = {
                feedback: this.feedback,
                uiScreen: this.screenName
            }
            this.integrationsService.sendFeedbackToSlackChannel(data).subscribe(() => {
                this.setUserPropertyViewPreference(true);
                this.showFeedbackForm = false;
            }, () => {
                return false;
            })
        } else {
            return false;
        }
    }

    validateFeedback() {
        let trimmedFeedback = this.feedback.replace(/\s{2,}/g, ' ').trim();
        if (trimmedFeedback.length < 10) {
            this.isFeedbackValid = false;
            return false
        } else {
            this.isFeedbackValid=true
            return true
        }
    }
}
