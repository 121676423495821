import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
var InvoiceEditModalComponent = /** @class */ (function () {
    function InvoiceEditModalComponent(fb, bsModalRef) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.error = false;
    }
    InvoiceEditModalComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.buildForm();
    };
    InvoiceEditModalComponent.prototype.buildForm = function () {
        this.invoiceForm = this.fb.group({
            'deposit': ['', [Validators.required]],
            'on_start_job': ['', [Validators.required]],
            'on_completion': ['', [Validators.required]]
        });
    };
    InvoiceEditModalComponent.prototype.input = function () {
        var value = this.invoiceForm.value;
        var sum = 0;
        for (var k in value) {
            if (value.hasOwnProperty(k)) {
                sum = sum + value[k];
            }
        }
        if (sum > 100) {
            this.error = true;
        }
        else {
            this.error = false;
        }
    };
    Object.defineProperty(InvoiceEditModalComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.invoiceForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // submit form
    InvoiceEditModalComponent.prototype.save = function () {
    };
    return InvoiceEditModalComponent;
}());
export { InvoiceEditModalComponent };
