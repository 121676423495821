import { OnInit } from '@angular/core';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
//import { Value } from 'sass';
var PricingSectionPartsCategoriesComponent = /** @class */ (function () {
    function PricingSectionPartsCategoriesComponent(proposalService, bsModalRef) {
        this.proposalService = proposalService;
        this.bsModalRef = bsModalRef;
        this.categorylist = [];
        this.filteredCategorylist = [];
        this.existingParts = [];
        this.subCategorylist = [];
        this.filteredSubCategorylist = [];
        this.partList = [];
        this.selectedParts = [];
        this.filteredSelectedParts = [];
        this.resultParts = [];
        this.saving = false;
        this.view = "categories";
        this.category = "";
        this.subcategory = "";
        this.totalcount = 0;
        this.totalprice = '0';
        this.totalPartsList = [];
        this.cartParts = [];
        this.searchValue = "";
        this.lastScrollTop = 0;
        this.subcategoryId = null;
        this.loading = false;
        this.TotalSumofQuantity = 0;
        this.TotalQuantityArray = [];
    }
    PricingSectionPartsCategoriesComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.getPartsData();
        this.select2Config = {
            width: '100%'
        };
    };
    PricingSectionPartsCategoriesComponent.prototype.getPartsData = function () {
        var _this = this;
        this.loading = true;
        this.proposalService.getPartList('', '', 50, 1).subscribe(function (data) {
            if (data) {
                // partsAndPricesId
                _this.categorylist = data['categorylist'];
                _this.filteredCategorylist = _this.categorylist;
                var _loop_1 = function () {
                    if (data['partsandprices'][i].hasOwnProperty('subCategoryId')) {
                        var added_1 = false;
                        if (_this.existingParts) {
                            _this.existingParts.forEach(function (item) {
                                if (item['partsAndPricesId'] == data['partsandprices'][i]["id"]) {
                                    added_1 = true;
                                }
                            });
                        }
                        if (!added_1) {
                            _this.partList.push(data['partsandprices'][i]);
                        }
                    }
                };
                for (var i = 0; i < data['partsandprices'].length; i++) {
                    _loop_1();
                }
                _this.loading = false;
            }
        });
    };
    PricingSectionPartsCategoriesComponent.prototype.onChangeCategory = function (event) {
        this.searchValue = "";
        this.view = "subcategories";
        this.category = event['categoriesDescription'];
        this.subCategorylist = event['subCategoryList'];
        this.filteredSubCategorylist = event['subCategoryList'];
    };
    PricingSectionPartsCategoriesComponent.prototype.onSubBreadClick = function () {
        this.view = "subcategories";
        this.subcategory = '';
        this.filteredSubCategorylist = this.subCategorylist;
        this.searchValue = "";
    };
    PricingSectionPartsCategoriesComponent.prototype.selectSubcategory = function (event) {
        this.searchValue = "";
        this.view = "parts";
        this.subcategory = event['subCategoryDescription'];
        this.subcategoryId = event['subCategoryId'];
        this.loading = true;
        this.getSubCategoryPartList(event['subCategoryId'], '', 50, 1);
    };
    PricingSectionPartsCategoriesComponent.prototype.getSubCategoryPartList = function (subCategoryId, searchText, limit, page) {
        var _this = this;
        this.partList = [];
        // if (this.view != "parts") {
        this.loading = true;
        // }
        this.proposalService.getPartList(subCategoryId, searchText, limit, page).subscribe(function (data) {
            _this.totalPartsCount = data['count'];
            var _loop_2 = function () {
                if (data['partsandprices'][i].hasOwnProperty('subCategoryId')) {
                    var added_2 = false;
                    if (_this.existingParts && _this.existingParts.length) {
                        _this.existingParts.forEach(function (item) {
                            if (item['partsAndPricesId'] == data['partsandprices'][i]["id"]) {
                                added_2 = true;
                            }
                        });
                    }
                    if (!added_2) {
                        _this.partList.push(data['partsandprices'][i]);
                    }
                }
            };
            for (var i = 0; i < data['partsandprices'].length; i++) {
                _loop_2();
            }
            _this.selectedParts = _this.partList;
            for (var i = 0; i < _this.selectedParts.length; i++) {
                if (_this.totalPartsList.length) {
                    for (var j = 0; j < _this.totalPartsList.length; j++) {
                        if (_this.selectedParts[i]['id'] == _this.totalPartsList[j]['id']) {
                            _this.selectedParts[i]['count'] = _this.totalPartsList[j]['count'];
                        }
                    }
                }
                else {
                    _this.selectedParts[i]['count'] = 0;
                }
                if (!_this.selectedParts[i].hasOwnProperty('count')) {
                    _this.selectedParts[i]['count'] = 0;
                }
            }
            _this.filteredSelectedParts = _this.selectedParts;
            _this.loading = false;
        });
    };
    PricingSectionPartsCategoriesComponent.prototype.saveCategories = function () {
        this.saving = true;
        this.onClose.next(this.cartParts);
        this.bsModalRef.hide();
    };
    PricingSectionPartsCategoriesComponent.prototype.selectedPart = function (part) {
        if (this.resultParts.length) {
            if ($("#" + part['id']).hasClass("partAdded")) {
                $("#" + part['id']).removeClass("partAdded");
                this.resultParts = $.grep(this.resultParts, function (e) {
                    return e.id != part['id'];
                });
            }
            else {
                this.resultParts.push(part);
                $("#" + part['id']).addClass("partAdded");
            }
        }
        else {
            this.resultParts.push(part);
            $("#" + part['id']).addClass("partAdded");
        }
    };
    PricingSectionPartsCategoriesComponent.prototype.onSearch = function (event) {
        if (event && event != '') {
            if (event['code'] == "Backspace") {
                if (this.view == "categories")
                    this.filteredCategorylist = this.categorylist;
                if (this.view == "subcategories")
                    this.filteredSubCategorylist = this.subCategorylist;
                if (this.view == "parts") {
                    // this.filteredSelectedParts = this.selectedParts;
                    this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
                }
                if (this.view == "cart")
                    this.totalPartsList = this.cartParts;
            }
            if (this.view == "categories") {
                this.filteredCategorylist = this.filteredCategorylist.filter(function (i) { return i.categoriesDescription.toLowerCase().match(event.toLowerCase()); });
            }
            if (this.view == "subcategories") {
                this.filteredSubCategorylist = this.filteredSubCategorylist.filter(function (i) { return i.subCategoryDescription.toLowerCase().match(event.toLowerCase()); });
            }
            if (this.view == "parts") {
                // this.filteredSelectedParts = this.filteredSelectedParts.filter((i: any) => i.description.toLowerCase().match(event.toLowerCase()))
                this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
            }
            if (this.view == "cart") {
                this.totalPartsList = this.totalPartsList.filter(function (i) { return i.description.toLowerCase().match(event.toLowerCase()); });
            }
        }
        else {
            if (this.view == "categories") {
                this.filteredCategorylist = this.categorylist;
            }
            if (this.view == "subcategories") {
                this.filteredSubCategorylist = this.subCategorylist;
            }
            if (this.view == "parts") {
                // this.filteredSelectedParts = this.selectedParts;
                this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
            }
            if (this.view == "cart") {
                this.totalPartsList = this.cartParts;
            }
        }
    };
    PricingSectionPartsCategoriesComponent.prototype.showCategories = function () {
        this.searchValue = "";
        this.view = "categories";
        this.category = '';
        this.subcategory = '';
    };
    PricingSectionPartsCategoriesComponent.prototype.addCart = function (data, index) {
        this.filteredSelectedParts.forEach(function (element) {
            if (data['id'] == element['id']) {
                element['count'] = parseFloat(element['count']) + 1;
            }
        });
        this.totalprice = (parseFloat(this.totalprice) + parseFloat(data['price'])).toString();
        if (this.totalPartsList.length) {
            var found = false;
            for (var i = 0; i < this.totalPartsList.length; i++) {
                if (this.totalPartsList[i]['id'] == data['id']) {
                    found = true;
                    this.totalPartsList[i]['count'] = data['count'];
                }
            }
            if (!found) {
                this.totalPartsList.push(data);
            }
        }
        else {
            this.totalPartsList.push(data);
        }
        this.totalPartsList = this.totalPartsList.filter(function (parts) {
            return parts.count !== 0;
        });
        this.cartParts = this.totalPartsList;
        var sum = 0;
        this.cartParts.forEach(function (obj) {
            sum += obj.count;
        });
        this.TotalSumofQuantity = sum;
        // this.TotalQuantityArray[index] = this.filteredSelectedParts[index].count
        // let val = this.filteredSelectedParts[index].count
        // this.inputdata(val, data, index);
    };
    PricingSectionPartsCategoriesComponent.prototype.removeCart = function (data, index) {
        var cardStaus = false;
        this.filteredSelectedParts.forEach(function (element) {
            if (data['id'] == element['id']) {
                if (element['count'] >= 1) {
                    element['count'] = element['count'] - 1;
                    cardStaus = true;
                }
            }
        });
        if (cardStaus) {
            this.totalprice = (parseFloat(this.totalprice) - parseFloat(data['price'])).toString();
            if (this.totalPartsList.length) {
                var found = false;
                for (var i = 0; i < this.totalPartsList.length; i++) {
                    if (this.totalPartsList[i]['id'] == data['id']) {
                        found = true;
                        this.totalPartsList[i]['count'] = data['count'];
                    }
                }
                if (!found) {
                    this.totalPartsList.push(data);
                }
            }
            else {
                this.totalPartsList.push(data);
            }
            this.totalPartsList = this.totalPartsList.filter(function (parts) {
                return parts.count !== 0;
            });
            this.cartParts = this.totalPartsList;
            var cartPartsQty_1 = 0;
            this.cartParts.forEach(function (obj) {
                cartPartsQty_1 += obj.count;
            });
            this.TotalSumofQuantity = cartPartsQty_1;
        }
    };
    PricingSectionPartsCategoriesComponent.prototype.addQuantity = function (data, index) {
        var value = parseInt(data.count);
        var item = parseInt(data.count);
        isNaN(value) ? value = 0 : value;
        value == 0 ? item = 0 : value;
        if (value >= 0) {
            var totalPrice_1 = 0;
            this.filteredSelectedParts.forEach(function (element) {
                if (data['id'] == element['id']) {
                    element['count'] = value;
                }
                totalPrice_1 = totalPrice_1 + (element['count'] * element['price']);
            });
            this.totalprice = totalPrice_1.toString();
            if (this.totalPartsList.length) {
                var found = false;
                for (var i = 0; i < this.totalPartsList.length; i++) {
                    if (this.totalPartsList[i]['id'] == data['id']) {
                        found = true;
                        this.totalPartsList[i]['count'] = data['count'];
                    }
                }
                data['count'] = item;
                if (!found) {
                    this.totalPartsList.push(data);
                }
            }
            else {
                this.totalPartsList.push(data);
            }
        }
        this.totalPartsList = this.totalPartsList.filter(function (parts) {
            return parts.count !== 0;
        });
        this.cartParts = this.totalPartsList;
        var cartPartsQty = 0;
        this.cartParts.forEach(function (obj) {
            cartPartsQty += obj.count;
        });
        this.TotalSumofQuantity = cartPartsQty;
        // this.inputdata(value, data, index);
    };
    PricingSectionPartsCategoriesComponent.prototype.inputdata = function (value, data, index) {
        var inputboxValueAnd = value;
        this.TotalQuantityArray[index] = inputboxValueAnd;
        console.log(this.TotalQuantityArray);
        var total = 0;
        for (var i in this.TotalQuantityArray) {
            total += this.TotalQuantityArray[i];
        }
        this.TotalSumofQuantity = total;
    };
    PricingSectionPartsCategoriesComponent.prototype.onclick = function (data, index) {
        data.target.value = null;
        this.TotalQuantityArray[index] = 0;
    };
    PricingSectionPartsCategoriesComponent.prototype.viewCart = function () {
        this.searchValue = "";
        this.view = 'cart';
        this.category = '';
        this.subcategory = '';
    };
    PricingSectionPartsCategoriesComponent.prototype.deleteCart = function (ind, data) {
        var removeprice = parseFloat(data['price']) * data['count'];
        this.totalprice = (parseFloat(this.totalprice) - removeprice).toString();
        this.totalPartsList.splice(ind, 1);
        this.TotalQuantityArray.splice(ind, 1);
        this.cartParts = this.totalPartsList;
        var sum = 0;
        this.cartParts.forEach(function (obj) {
            sum += obj.count;
        });
        this.TotalSumofQuantity = sum;
    };
    PricingSectionPartsCategoriesComponent.prototype.addCartQuantity = function (event, data, index) {
        var value = parseInt(event.target.value);
        var item = parseInt(event.target.value);
        isNaN(value) ? value = 0 : value;
        value == 0 ? item = 0 : value;
        if (value >= 0) {
            if (value > data['count']) {
                var incprice = (value - data['count']) * parseFloat(data['price']);
                this.totalprice = (parseFloat(this.totalprice) + incprice).toString();
            }
            else if (value < data['count']) {
                var detectprice = (data['count'] - value) * parseFloat(data['price']);
                this.totalprice = (parseFloat(this.totalprice) - detectprice).toString();
            }
        }
        this.totalPartsList[index]['count'] = value;
        var cartPartsQty = 0;
        this.cartParts = this.totalPartsList;
        this.cartParts.forEach(function (obj) {
            cartPartsQty += obj.count;
        });
        this.TotalSumofQuantity = cartPartsQty;
    };
    PricingSectionPartsCategoriesComponent.prototype.onContainerScroll = function (event) {
        if (this.view == "parts") {
            var element = event.srcElement;
            if (element.scrollTop > this.lastScrollTop) {
                if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
                    var limit = this.selectedParts.length + 50;
                    if (this.totalPartsCount != this.selectedParts.length) {
                        this.getSubCategoryPartList(this.subcategoryId, '', limit, 1);
                    }
                }
            }
            this.lastScrollTop = element.scrollTop;
        }
    };
    PricingSectionPartsCategoriesComponent.prototype.omitSpecialChar = function (event, index) {
        var textboxvalue = $('#quantity-input-' + index).val().toString();
        if (textboxvalue.length > 4) {
            return false;
        }
        if (textboxvalue == '0') {
            event.target.value = null;
            this.TotalQuantityArray[index] = 0;
            return true;
        }
        if (textboxvalue.length > 3 && textboxvalue.includes('.')) {
            if (textboxvalue.indexOf('.') < 2) {
                return false;
            }
            else if ((textboxvalue.length - textboxvalue.indexOf('.')) > 2) {
                return false;
            }
        }
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode.keyCode <= 36 || charCode.keyCode > 57)) {
            if ((charCode.keyCode <= 96 || charCode.keyCode >= 106)) {
                if (charCode.keyCode != 8) {
                    event.preventDefault();
                    return false;
                }
            }
            else {
                return true;
            }
        }
    };
    PricingSectionPartsCategoriesComponent.prototype.keydown = function (event, index) {
        var textboxvalue = $('#quantity-input-' + index).val().toString();
        if (textboxvalue == '0') {
            if (event.keyCode <= 37 || event.keyCode > 57) {
                if (event.keyCode <= 96 || event.keyCode >= 106) {
                    if (event.keyCode != 8) {
                        event.preventDefault();
                        return false;
                    }
                }
                else {
                    return true;
                }
            }
        }
    };
    return PricingSectionPartsCategoriesComponent;
}());
export { PricingSectionPartsCategoriesComponent };
