<span class="field user-preference-toggle" [ngStyle]="{'height':showFeedbackForm?'300px':'initial'}" >
    <div class="field-label">{{labelName}}</div>
    <div class="toggle-btn-sec" (click)="setUserPropertyViewPreference()">
        <div class="btn-switch"
             [ngClass]="{'btn-switch--on':toggleSwitch}">
            <div
                class="btn-switch-circle"
                [ngClass]="{'btn-switch-circle--on':toggleSwitch}" ></div>
        </div>
    </div>
    <div *ngIf="showFeedbackForm" id="feedbackForm" >
        <span class="required_field request_text">We're sorry to see you switch back. We'd love to know why so we can continue to improve the software.</span>
        <textarea id="feedbackArea" type="textarea" [(ngModel)]="feedback" (ngModelChange)="this.feedbackEmitter$.next($event)"></textarea>
        <button class="btn btn-primary saveFeedbackButton" translate (click)="submitFeedbackForm()" [disabled]="!isFeedbackValid"
                >Save and turn it back!</button>
    </div>
</span>