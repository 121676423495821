<ng-container>
    <div class="widget-header">
        <h4 class="mr-1">{{header}}</h4><span class="badge" [ngClass]="{'badge-success': !!this.data.status}">{{!!this.data.status ? 'Active': 'Draft'}}</span>
        <small *ngIf="subTitle!==''">{{subTitle}}</small>

        <div class="cs-switch" style="position: absolute; top:18px; right:10px; z-index: 1">
            <div class="pull-left cs-switch-container" *ngIf="showActivation">
                <label class="switch" for="checkbox_{{data.id}}" [ngClass]="{'checked': !!this.data.status}">
                    <input type="checkbox" id="checkbox_{{data.id}}" [(ngModel)]="currentStatus"  (change)="onShowConfirmation()"/>
                    <div class="slider round"></div>
                </label>
            </div>

            <div class="actions">
                <button (click)="action.click()" *ngFor="let action of actionsButtons" [disabled]="action['disabled']" [attr.class]="action?.className">
                    <span translate>{{ action.title }}</span>
                </button>
                <a (click)="close(false)" href="javascript:void(0)" translate>Close</a>
            </div>
        </div>
    </div>

    <cs-modal *ngIf="confirmationForm" [formGroup]="confirmationForm" [show]="showConfirmationModal" data-backdrop="static" data-keyboard="false">
        <ng-container *ngIf="showConfirmationModal">
            <div class="modal-header">
                <button type="button" class="close" (click)="onCloseConfirmation()">×</button>
                <h4 *ngIf="this.data.status !== 1" translate>Confirm for activation</h4>
                <h4 *ngIf="this.data.status === 1" translate>Confirm for deactivation</h4>
            </div>
            <div class="modal-body">
                <div class="row-fluid">
                    <p>
                        <span translate>To confirm you wish to {{toggleType}} this sequence, please type 'CONFIRM' below and click confirm.</span>
                    </p>
                </div>
                <div class="input-prepend box-shadow input-fullwidth row-fluid">
                    <span class="add-on">
                      <span class="ss-caution"></span>
                    </span>
                    <div class="input-wrapper">
                        <input type="text" formControlName="confirmationText" class="modalDeleteNumber" (keyup.enter)="activeOrDeactive()"/>
                    </div>
                </div>
                <validation-error [formGroup]="confirmationForm" controlName="confirmationText" showOnDirty="true">
                    <div class="row-fluid">
                        <span translate [translateParams]="{word:'CONFIRM',message:'to activate/de-activate the sequence'}">confirmation.warning.text</span>
                    </div>
                </validation-error>
            </div>
            <div class="modal-footer">
              <span *ngIf="action == 'confirming'">
                <img src="/images/loading/ajax-loader.gif"/>&nbsp;
                <span translate>Loading</span>
              </span>
                <div *ngIf="action != 'confirming'">
                <span *ngIf="action == 'failed_to_delete'" class="control-error-message">
                  <span translate>failed.to.perform.operation</span>
                </span>
                    <a href="javascript:void(0)" class="btn btn-cancel"
                       (click)="onCloseConfirmation()" translate>Close</a>
                    <button type="button" (click)="activeOrDeactive()"
                            [disabled]="confirmationForm.invalid || action == 'confirming'" class="btn btn-primary btn-medium"
                            translate>Confirm
                    </button>
                </div>
            </div>
        </ng-container>
    </cs-modal>
</ng-container>
