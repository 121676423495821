import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MicroserviceHelperService } from "@app/shared/microservice/helper/microservice-helper.service";
import { V5AuthService } from "@app/core";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/microservice/helper/microservice-helper.service";
import * as i2 from "./v5-auth.service";
var RenewalTokenService = /** @class */ (function () {
    function RenewalTokenService(tokenService, authService) {
        this.tokenService = tokenService;
        this.authService = authService;
        this.isRunning = false;
        // this.start();
    }
    RenewalTokenService.prototype.getRenewalInterval = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var timestamp = _this.tokenService.getTokenInterval();
            var accessToken = _this.tokenService.getToken();
            setTimeout(function () {
                if (typeof timestamp == "number" && accessToken) {
                    _this.renewalIntervalMs = timestamp;
                    resolve(timestamp);
                }
                else {
                    reject("Not resolved token interval.");
                }
            });
        });
    };
    RenewalTokenService.prototype.currentInterval = function () {
        //one sec reduced from the actual expiry
        return ((this.renewalIntervalMs - (new Date()).getTime()) - 1000);
    };
    RenewalTokenService.prototype.start = function () {
        var _this = this;
        // this currently deprecated.
        if (this.isRunning) {
            return;
        }
        this.getRenewalInterval().then(function (timestamp) {
            _this.isRunning = true;
            _this.renewalIntervalMs = timestamp;
            var duration = _this.currentInterval();
            console.info('Hi, Renewal token interval initiated.', duration);
            if (duration <= 0) {
                _this.isRunning = false;
                _this.authService.renewToken()
                    .then(function (resp) {
                    _this.tokenService.setToken(resp['renewalToken']);
                    _this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                    _this.start();
                });
                return;
            }
            _this.isRunning = true;
            _this.timerSubscription = timer(duration).pipe(switchMap(function () {
                console.log('Hi, Access token requested.');
                _this.isRunning = false;
                return _this.authService.renewToken();
            }))
                .subscribe(function (resp) {
                console.log('Access token refreshed successfully.', (new Date()).getMinutes());
                if (resp['renewalToken']) {
                    _this.isRunning = false;
                    _this.tokenService.setToken(resp['renewalToken']);
                    _this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                    _this.start();
                }
            }, function (err) {
                _this.isRunning = false;
                console.error(err);
            });
        }, function () {
            _this.stop();
            console.log("Token interval handling error....");
        });
    };
    RenewalTokenService.prototype.getTokenService = function () {
        return this.tokenService;
    };
    RenewalTokenService.prototype.stop = function () {
        this.tokenService.clearTokenInterval();
        this.tokenService.clearToken();
        this.isRunning = false;
        console.info("Hoo, v5Token cleared!");
        if (this.timerSubscription) {
            console.info("The token renewal timer stopped!");
            this.timerSubscription.unsubscribe();
        }
    };
    RenewalTokenService.prototype.getAuthService = function () {
        return this.authService;
    };
    RenewalTokenService.ngInjectableDef = i0.defineInjectable({ factory: function RenewalTokenService_Factory() { return new RenewalTokenService(i0.inject(i1.MicroserviceHelperService), i0.inject(i2.V5AuthService)); }, token: RenewalTokenService, providedIn: "root" });
    return RenewalTokenService;
}());
export { RenewalTokenService };
