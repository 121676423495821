import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { V5AuthService } from "@app/core";
import { MicroserviceHelperService } from "@app/shared/microservice/helper/microservice-helper.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/auth/v5-auth.service";
import * as i2 from "../../helper/microservice-helper.service";
var TokenRenewalInterceptor = /** @class */ (function () {
    function TokenRenewalInterceptor(v5AuthService, tokenService) {
        this.v5AuthService = v5AuthService;
        this.tokenService = tokenService;
    }
    TokenRenewalInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req)
            .pipe(tap({
            next: function (event) {
                if (event instanceof HttpResponse) {
                    var data = event.body;
                    if (data && data.hasOwnProperty('v5TokenExpired_aebe10654a474f34443703d51047d11a') && data['v5TokenExpired_aebe10654a474f34443703d51047d11a'] == true && !_this.v5AuthService.isRequestedToken) {
                        _this.v5AuthService.renewToken().then(function (resp) {
                            _this.tokenService.setToken(resp['renewalToken']);
                            _this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                            setTimeout(function () {
                                _this.v5AuthService.isRequestedToken = false;
                            }, 10 * 1000);
                        });
                        console.log('v5 token expired, requesting token');
                    }
                }
            }
        }));
    };
    TokenRenewalInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenRenewalInterceptor_Factory() { return new TokenRenewalInterceptor(i0.inject(i1.V5AuthService), i0.inject(i2.MicroserviceHelperService)); }, token: TokenRenewalInterceptor, providedIn: "root" });
    return TokenRenewalInterceptor;
}());
export { TokenRenewalInterceptor };
