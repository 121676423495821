import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
var CommonService = /** @class */ (function (_super) {
    tslib_1.__extends(CommonService, _super);
    function CommonService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    CommonService.prototype.getUsersList = function () {
        return this.http.get(this.getJobApiUrl("getUsersList"));
    };
    CommonService.prototype.setHtCellLongTextPopover = function (instance, cellEl, row, col, htPopOverConfig) {
        if (instance.getDataAtCell(row, col)
            && (cellEl.offsetWidth < cellEl.scrollWidth)) {
            var popOverData = {
                el: cellEl,
                content: function () { return cellEl.innerHTML; }
            };
            var popOverConfig = tslib_1.__assign({}, popOverData, htPopOverConfig);
            this.setCsPopOver(popOverConfig);
        }
        else {
            jQuery(cellEl).popover('destroy');
        }
    };
    CommonService.prototype.setCsPopOver = function (config) {
        jQuery(config.el).popover({
            placement: config.placement || 'bottom',
            trigger: config.trigger || 'hover',
            content: config.content
        })
            .data('popover')
            .tip()
            .addClass(config.customClass || 'cs-popover')
            .popover('show');
    };
    return CommonService;
}(InterfaceService));
export { CommonService };
