import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap  } from 'rxjs/operators';

//--
import { InterfaceService } from '@app/interfaces';
import {ContractTemplate, JobsSla} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class JobsSlaService extends InterfaceService {

  constructor(
    private http: HttpClient
  ) { super(); }

  // fetching existing jobs sla
  fetchJobsSla(param?: object): Observable<any>{
    return this.http
    .get(this.getApiUrl('system_settings/jobs-sla',param))
    .pipe(
      map(Response => Response)
      );
   }

   // Add new contract template
  addJobsSla(data: Object): Observable<any> {
    return this.http
      .post(this.getApiUrl('jobs-sla/add-new'), JSON.stringify(data))
      .pipe(
        map(Response => new JobsSla(Response))
      );
  }

  // Update jobs sla
  editJobsSla(id: number, data: Object): Observable<any> {
    return this.http
      .put(this.getApiUrl(`jobs-sla/${id}/edit`), JSON.stringify(data))
      .pipe(
        map(Response => new JobsSla(Response))
      );
  }

  // Checking delete option for jobs sla
  isDeleteJobsSla(id: number): Observable<any>{
    return this.http
      .get(this.getApiUrl(`jobs-sla/${id}/is-sla-delete`))
      .pipe(
        map(Response => Response)
      );
   }

  // Delete jobs sla
  deleteJobsSla(id: number): Observable<any>{
    return this.http
      .delete(this.getApiUrl(`jobs-sla/${id}/delete`))
      .pipe(
        map(Response => new JobsSla(Response))
      );
  }

  // Get data for contract template basic details configuration form
  getSetupSlaConfigureDetails(templateId: number): Observable<Object> {
      return this.http.get(this.getApiUrl(`sla-setup/${templateId}/configure`))
          .pipe(
              map(Response => {
                  Response['settingsJobsSLA'] = new JobsSla(Response['settingsJobsSLA']);

                  return Response;
              })
          );
  }

    addSLAConfigure(id: number,cofigureType:number, data: Object): Observable<any> {
        return this.http
            .post(this.getApiUrl(`jobs-sla/${id}/add-configure-new/${cofigureType}`), JSON.stringify(data))
            .pipe(
                map(Response => Response)
            );
    }
    editSLAConfigure(id: number,cofigureType:string,configureSlaId:number, data: Object): Observable<any> {
        return this.http
            .put(this.getApiUrl(`sla-setup/${id}/configure/${cofigureType}/edit/${configureSlaId}`), JSON.stringify(data))
            .pipe(
                map(Response => new JobsSla(Response))
            );
    }

    slaConfiguredetails(id: number,cofigureType:number): Observable<Object> {
        return this.http.get(this.getApiUrl(`sla-setup/${id}/configure/${cofigureType}`))
            .pipe(
                map(Response => {
                    Response['slaConfiguredetails'] = new JobsSla(Response['slaConfiguredetails']);

                    return Response;
                })
            );
    }

    slaConfiguredetailsView(id: number,cofigureType:number,configureSlaId:number): Observable<Object> {
        return this.http.get(this.getApiUrl(`sla-setup/${id}/configure/${cofigureType}/${configureSlaId}`))
            .pipe(
                map(Response => {
                    Response['slaConfiguredetails'] = new JobsSla(Response['slaConfiguredetails']);

                    return Response;
                })
            );
    }

    // Check duplicate name or not
    CheckSLADuplicateName(name: string, id: number): Observable<any>{
        return this.http.get(this.getApiUrl(`sla/check-sla-name`,{name: name, id: id}));
    }

    deleteSLAConfigure(id: number,cofigureType:number,action:string, data: Object): Observable<any> {
        return this.http
            .post(this.getApiUrl(`jobs-sla/${id}/delete-configure/${cofigureType}/${action}`), JSON.stringify(data))
            .pipe(
                map(Response => new JobsSla(Response))
            );
    }

    isDeleteJobsSlaMetric(slaId:number,configureType:number,id: number,action:string): Observable<any>{
        return this.http
            .get(this.getApiUrl(`jobs-sla/${slaId}/${configureType}/${id}/is-sla-metric/${action}`))
            .pipe(
                map(Response => Response)
            );
    }

    addDefaultSLAEmailTemplate(Data:object):Observable<any> {
        return this.http.post(this.getApiUrl('system_settings/sla_email_template/add-data'),JSON.stringify(Data));
    }
    getDetailsRoute(id: number):Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/sla_email_template/${id}/edit`))
            .pipe(
                map(Response => Response)
            );
    }
    editDefaultSLAEmailTemplate(id:number,Data:object):Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/sla_email_template/${id}/edit-details`),JSON.stringify(Data));
    }

    cloneSLAConfigure(id: number,cofigureType:number, data: Object): Observable<any> {
        return this.http
            .post(this.getApiUrl(`jobs-sla/${id}/clone-configuration/${cofigureType}`), JSON.stringify(data))
            .pipe(
                map(Response => Response)
            );
    }

    configureSLABusinessHours(slaId: number, data: Object): Observable<any> {
        return this.http
            .put(this.getApiUrl(`sla-setup/${slaId}/configureBusinessHours`), JSON.stringify(data))
            .pipe(
                map(Response => Response)
            );
    }

    getSLABusinessHoursSetup(slaId: number): Observable<any> {
        return this.http.get(this.getApiUrl(`sla-setup/${slaId}/businessHours`))
            .pipe(
                map(Response => Response)
            );
    }
}
