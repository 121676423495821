import { AfterViewInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { InterfaceService } from "@app/interfaces";

@Injectable({
    providedIn: 'root'
})
export class FolderService extends InterfaceService {

    private triggerEvent = new BehaviorSubject(null);
    triggerEvent$ = this.triggerEvent.asObservable();

    constructor(private http: HttpClient) {
    super();
  }

  getApiCall(url){
    return this.http.post(this.getApiUrl(url),[]);
  }

  postApiCall(url,params){
    return this.http.post(this.getApiUrl(url),params);
  }

  addEditEmailTemplate(params){
    return this.http.post(this.getApiUrl('save_templates'),params);
  }

  getReplacedMessage(params){
    return this.http.post(this.getApiUrl('replaced_email_message'),params);
  }

  checkTemplateStatus(params){
    return this.http.post(this.getApiUrl('check_template_status'),params);
  }

  checkFolderStatus(params){
    return this.http.post(this.getApiUrl('check_folder_status'),params);
  }

  checkUniqueFolderName(params){
    return this.http.post(this.getApiUrl('check_unique_folder_name'),params);
  }

  checkUniqueTemplateName(params) {
    return this.http.post(this.getApiUrl('check_unique_template_name'),params);
  }

  loadData(value) {
      this.triggerEvent.next(value);
  }

  checkTemplateName(params?: Object): Observable<any> {
      return this.http.get(this.getApiUrl(`checkSetupOptionsExists`, params));
  }

  checkProposalTemplateExists(value) {
      return this.http.get(this.getApiUrl(`checkProposalTemplateExists`, value));
  }
  
}
