import { APP_INITIALIZER } from '@angular/core';
import { throwIfAlreadyLoaded } from '@app/core/module-import-guard';
import { SettingsService } from '@app/core/settings/settings.service';
export function appInitializerFactory(settings) {
    return function () { return settings.loadAppData(); };
}
export var appInitializer = {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [SettingsService],
    multi: true
};
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
    return CoreModule;
}());
export { CoreModule };
