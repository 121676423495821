import { NgModule } from '@angular/core';
import {AppCommonModule} from '@app/shared';
import {RenameFilesModal} from '@app/shared/attached-files/rename/rename-files.modal';
import {TranslateModule} from '@ngx-translate/core';
import {
    UploadFileComponent
} from '@app/shared/attached-files/upload-file/upload-file.component';
import {FileUploadModule} from "ng2-file-upload";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragNdropFileUploadComponent } from './drag-ndrop-file-upload/drag-ndrop-file-upload.component';
import {DirectivesModule} from "@app/directives";

@NgModule({
    declarations: [
        RenameFilesModal,
        UploadFileComponent,
        DragNdropFileUploadComponent
    ],
    exports: [
        RenameFilesModal,
        UploadFileComponent,
        DragNdropFileUploadComponent
    ],
    imports: [
        // AppCommonModule,
        TranslateModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
    ],
    providers: [
       RenameFilesModal,
    ],
    entryComponents: [
       RenameFilesModal,
    ]
})
export class AttachedFilesModule { }
