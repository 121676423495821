import { Subject } from "rxjs";
import * as i0 from "@angular/core";
var CommunicationDispatcherService = /** @class */ (function () {
    function CommunicationDispatcherService() {
        this.triggerEvent = new Subject();
        this.triggerEvent$ = this.triggerEvent.asObservable();
    }
    CommunicationDispatcherService.prototype.dispatchEvent = function (actionName, data) {
        if (data === void 0) { data = {}; }
        data['actionName'] = actionName;
        this.triggerEvent.next(data);
    };
    CommunicationDispatcherService.CONSTANTS = {
        'loadedData': 'event:loadedData',
        'updatedTemplateId': 'event:updatedTemplateId',
        'readyToSave': 'event:readyToSave',
        'updateFormValue': 'event:updateFormValue',
        'checkIsValidForm': 'event:checkIsValidForm',
        'beforeSaveAction': 'event:beforeSaveAction',
        'afterSaveAction': 'event:afterSaveAction',
        'getCurrentTemplateId': 'event:getCurrentTemplateId',
        'unSaveConfirmation': 'event:unSaveConfirmation',
        'inlineSaveAction': 'event:inlineSaveAction',
        'updateTemplateDetails': 'event:updaTemplateDetails',
        'close': 'event:close',
        'saveAndClose': 'event:saveAndClose',
        'save': 'event:save'
    };
    CommunicationDispatcherService.ngInjectableDef = i0.defineInjectable({ factory: function CommunicationDispatcherService_Factory() { return new CommunicationDispatcherService(); }, token: CommunicationDispatcherService, providedIn: "root" });
    return CommunicationDispatcherService;
}());
export { CommunicationDispatcherService };
