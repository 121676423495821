import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable()

export class OptionTemplateService extends InterfaceService {

    private contentType = new BehaviorSubject('');
    contentType$ = this.contentType.asObservable()

    private optionTemplate = new BehaviorSubject('');
    optionTemplate$ = this.optionTemplate.asObservable()

    constructor(private http: HttpClient) {
        super();
    }

    // Add/Edit option templates
    addOrEditOptionTemplate(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditSetupOption`), data);
    }

     // Reorder Template list
     reorderTemplate(data:Object): Observable<any> {
        return this.http.put(this.getApiUrl('reorderSetupOptions'),data);
    }

    // Delete option template
    deleteOptionTemplate(id, params?: any): Observable<any> {
        return this.http.delete(this.getApiUrl(`${id}` + `/deleteSetupOptions`, params));
    }

     // Check option template already exists
     checkTemplateName(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`checkSetupOptionsExists`, params));
    }

     // Set contentType
     setContentType(data){
        this.contentType.next(data)
    }

    // Set optionTemplate
    setOptionTemplate(data){
        this.optionTemplate.next(data)
    }

    getContentData(): Observable<any> {
        return this.http.get('../content-library-templates/templates.json');
    }
    //Get All Option templates
    getAllOptionTemplates(params: object = {}): Observable<any> {
        return this.http.post(this.getApiUrl(`getAllOptionTemplates`), params);
    }
    //Add proposal Template Options
    addProposalOptions(option) {
        return this.http.post(this.getApiUrl(`addOrLinkProposalOptions`),option);
    }
    //Add or link Add-ons
    addOrLinkAddOns(option) {
        return this.http.post(this.getApiUrl(`addOrLinkAddOns`),option);
    }

    //Edit Add-ons
    editAddOns(option) {
        return this.http.post(this.getApiUrl(`editAddOns`),option);
    }

    // Delete Add-ons
    deleteAddOns(option){
        return this.http.post(this.getApiUrl(`deleteAddOns`),option);
    }

    //Get Option template content
    getOptionTemplatePages(id) {
        return this.http.get(this.getApiUrl(`${id}/getOptionTemplatePages`))
    }

    //Get Finance template list
    getFinanceTemplateList() {
        return this.http.get(this.getApiUrl(`sales/getFinanceTemplatesList`))
    }

    // update finance option
    updateFinanceOption(data: object, type): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/optionTemplateFinanceConfig?type=${type}`), data);
    }

    checkOptionTemplateName(params?: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`checkProposalSetupOptionsExists`),params);
    }

    // Clone option template
    cloneOptionTemplate(id, parentId): Observable<any> {
        return this.http.get(this.getApiUrl(`cloneOptionTemplate/` + `${id}/` + `${parentId}`));
    }
    downloadOptionTemplate(id): Observable<any> {
        return this.http.get(this.getApiUrl(`downloadOptionTemplate?id=${id}`));
    }

    uploadOptionTemplate(params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`uploadOptionTemplate`), params);
    }

    proposalLinkedOption(id): Observable<any> {
        return this.http.get(this.getApiUrl(`checkIsOptionLinkProposalTemplate/${id}`));
    }

    getContentLibraryTemplate(id): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getContentLibraryTemplate/${id}`));
    }

    upgradeDowngradeFreemiumBlocks(data: object): Observable<any> {
        return this.http.post(this.getApiUrl('sales/upgradeDowngradeFreemiumBlocks'), data);
    }
}
