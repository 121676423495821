<div 
    class="jobUploadSection job-costings-scrollwrapper" 
    (dragover)="onDragOver($event)" 
    (dragleave)="onDragLeave($event)" 
    (drop)="onDrop($event)" 
    id="hideZIndexProperty"
    >

    <div class="position-absolute top-50 start-50 translate-middle">
        <input 
            type="file" 
            (change)="onFileSelected($event)" 
            multiple style="display: none;" 
            #fileInput />
    </div>

    <bs-modal-backdrop class="modal-backdrop fade show" [ngClass]="{'in': isFileDrop}"   *ngIf="isFileDrop"></bs-modal-backdrop>

    <div 
        bsModal 
        class="modal fade"
        [ngClass]="{'in': isFileDrop}" 
        id="fileUploadingModal" 
          
        aria-labelledby="fileUploadingModal" 
        aria-hidden="true"
     >

        <div class="modal-dialog modal-dialog-centered">
            <div class="section fileUploadDialogBox">
                <div class="row section headingBar">
                    <div class="headingBarText" translate>Upload</div>
                    <!--<div class="sapn2" ><button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button></div>-->

                </div>
                <div class="section actionSections">
                    <div class="row">
                        <div class="span12 p-0 g-0">
                            <p class="sectionHeaders" translate>Please.select.action</p>
                        </div>
                        <div 
                            id="existingTemplate" 
                            (click)="getSelectedOptions('existingTemplate')"
                            class="span6 actionOptionHolder position-relative" 
                            style="margin-right: 12px;" 
                            [style.border]="selectedActions === 'existingTemplate' ? '1px solid #3883C1' : '1px solid #FFFFFF'">

                            <div class="span12 form-check">
                                <span class="span1">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="selectedActions" 
                                        (change)="getSelectedOptions('existingTemplate')" 
                                        [value]="selectedActions" [checked]="selectedActions === 'existingTemplate'" />
                                </span>
                                <span class="span11">
                                    <span class="span12">
                                        <label class="form-check-label">
                                            <p class="actionOptionHeading" translate>Use.a.template</p>
                                            <p class="actionOptionSubText" translate>Select.supplier.to.allocate.the.invoice.to</p>
                                        </label>
                                    </span>
                                    <span *ngIf="selectedActions=='existingTemplate'" class="span12" style="margin: 0px; padding: 0px;">
                                        <input 
                                            (selected)="selectedItem" 
                                            [select2Config]="select2Options4Supplier" class="span5 select2-config"
                                            csSelect2
                                            [placeholder]="'-- Please enter supplier name --'"
                                            (ngModelChange)="selectSupplier($event)" [(ngModel)]="selectedItem"
                                            type="text"
                                        />
                                    </span>
                                </span>
                            </div>

                        </div>
                        <div 
                            id="newTemplate"
                            (click)="getSelectedOptions('newTemplate')"
                            class="span6 actionOptionHolder position-relative" 
                            [style.border]="selectedActions === 'newTemplate' ? '1px solid #3883C1' : '1px solid #FFFFFF'">

                            <div class="span12 form-check">
                                <span class="span1">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="selectedActions" 
                                        id="newTemplate" 
                                        (change)="getSelectedOptions('newTemplate')" 
                                        [value]="selectedActions"
                                        [checked]="selectedActions === 'newTemplate'" />
                                </span>
                                <span class="span11">
                                    <label class="form-check-label">
                                        <p class="actionOptionHeading" translate>Use.Commusoft.new.Supplier.AI</p>
                                        <p class="actionOptionSubText" translate>Commusoft.new.supplier.AI.does.not.require.a.template.to.be.selected</p>
                                    </label>
                                </span>
                            </div>
                            <div class="actionOptionTag">
                                <div style="position: absolute;padding: 2px;" translate>BETA</div>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="pointer" 
                        *ngIf="selectedActions != ''" 
                        [ngStyle]="{'left':selectedActions === 'existingTemplate' ? '22%':'74%'}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="15" viewBox="0 0 28 15" fill="none">
                            <path d="M0 15L14 0L27.8564 15" fill="white" style="stroke: #D9D9D9; stroke-width: 1px;" />
                        </svg>
                    </div>
                </div>


                <div class="section filesSections">
                    <div class="row-fluid">
                        <div class="span8" style="margin-top: 0px;">
                            <p class="sectionHeaders" translate>.Files</p>
                        </div>
                        <div class="span4" style="text-align: right;">
                            <p class="headAnchorTags" (click)="openFileInput()" translate>Add.more.files</p>
                            <input type="file" #fileInput style="display: none;" (change)="handleFileSelections($event)" multiple />
                        </div>

                        <div *ngIf="errorMsg!=null" style="margin-bottom: 10px;">
                            <span style="color: #d53847;">{{errorMsg}}</span>
                        </div>
                        <div *ngIf="fileCountError!=null" style="margin-bottom: 10px;">
                            <span style="color: #d53847;">{{fileCountError}}</span>
                        </div>
                    </div>
                    <div class="fileLabelPortions">
                        <div *ngFor="let item of this.droppedFiles" class="span12 fileLables">
                            <div class="span10">
                                <span class="span1">
                                    <img *ngIf="item.type == 'application/pdf'" [src]="'angularES/src/assets/icons/pdf.svg'" style="width: 20px; height: 20px;" />
                                    <img *ngIf="item.type == 'image/tiff'" [src]="'angularES/src/assets/icons/tiff.svg'" style="width: 20px; height: 20px;" />
                                    <img
                                        *ngIf="item.type == 'application/msword' || item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                        [src]="'angularES/src/assets/icons/docs.svg'"
                                        style="width: 20px; height: 16px;"
                                    />
                                    <img
                                        *ngIf="item.type == 'application/vnd.ms-excel' || item.type=='application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                        [src]="'angularES/src/assets/icons/excel.svg'"
                                        style="width: 20px; height: 20px;"
                                    />
                                    <img *ngIf="!this.extensions.includes(item.type)" [src]="'angularES/src/assets/icons/no-icon.svg'" style="width: 20px; height: 20px;" />
                                </span>
                                <span class="span9">
                                    <span class="actionOptionHeading mb-0">{{item.name}}</span><br />
                                    <!--For Docparser-->
                                    <span *ngIf="selectedActions == 'existingTemplate'">
                                        <span *ngIf="!this.docparserAllowedExtension.includes(item.type)" style="color: #d53847;" translate>Unable.to.upload.because.this.file.type.is.not.supported</span>
                                        <span *ngIf="this.docparserAllowedExtension.includes(item.type)" class="actionOptionSubText">{{((item.size)/(1024*1024)).toFixed(2)}} mb</span>
                                    </span>

                                    <!--For Mindee-->
                                    <span *ngIf="selectedActions == 'newTemplate'">
                                        <span *ngIf="!this.mindeeAllowedExtension.includes(item.type)" style="color: #d53847;" translate>Unable.to.upload.because.this.file.type.is.not.supported</span>
                                        <span *ngIf="this.mindeeAllowedExtension.includes(item.type)" class="actionOptionSubText">{{((item.size)/(1024*1024)).toFixed(2)}} mb</span>
                                    </span>
                                    <br>
                                    <span *ngIf="((item.size)/(1024*1024)).toFixed(2) > 5" style="color: #d53847;">
                                        <span translate>File.size.should.be.less.than</span><span> 5 mb</span>
                                    </span>
                                </span>
                            </div>

                            <div class="span2" style="padding:8px;">
                                <span *ngIf="progressValue>0 && progressValue<100" class="span2" style="margin-left: 20px; float: left;">
                                    <round-progress 
                                        [radius]= "9" 
                                        [duration]="1000" 
                                        [max]="100" 
                                        [current]="progressValue"
                                        [stroke]="2"
                                        style="margin-top: 5px;"
                                    >
                                    </round-progress>
                                </span>

                                <span class="span2" *ngIf="progressValue==100" style="margin-left: 20px; margin-top: 3px; float: left;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M7.06634 11.0666L11.7663 6.36659L10.833 5.43325L7.06634 9.19992L5.16634 7.29992L4.23301 8.23325L7.06634 11.0666ZM7.99967 14.6666C7.07745 14.6666 6.21079 14.4915 5.39967 14.1413C4.58856 13.791 3.88301 13.3161 3.28301 12.7166C2.68301 12.1166 2.20812 11.411 1.85834 10.5999C1.50856 9.78881 1.33345 8.92214 1.33301 7.99992C1.33301 7.0777 1.50812 6.21103 1.85834 5.39992C2.20856 4.58881 2.68345 3.88325 3.28301 3.28325C3.88301 2.68325 4.58856 2.20836 5.39967 1.85859C6.21079 1.50881 7.07745 1.3337 7.99967 1.33325C8.9219 1.33325 9.78856 1.50836 10.5997 1.85859C11.4108 2.20881 12.1163 2.6837 12.7163 3.28325C13.3163 3.88325 13.7915 4.58881 14.1417 5.39992C14.4919 6.21103 14.6668 7.0777 14.6663 7.99992C14.6663 8.92214 14.4912 9.78881 14.141 10.5999C13.7908 11.411 13.3159 12.1166 12.7163 12.7166C12.1163 13.3166 11.4108 13.7917 10.5997 14.1419C9.78856 14.4921 8.9219 14.667 7.99967 14.6666Z"
                                            fill="#60A369"
                                        />
                                    </svg>
                                </span>

                                <span class="span1 removeFile" (click)="removeFile(item)" style="float: right; margin-right: 16px;margin-top: 3px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M13.4908 4.49067C13.9465 4.03494 13.9465 3.29484 13.4908 2.83911C13.035 2.38338 12.2949 2.38338 11.8392 2.83911L8.00013 6.68182L4.15742 2.84276C3.70169 2.38703 2.96159 2.38703 2.50586 2.84276C2.05013 3.29849 2.05013 4.03859 2.50586 4.49432L6.34857 8.33338L2.50951 12.1761C2.05378 12.6318 2.05378 13.3719 2.50951 13.8277C2.96523 14.2834 3.70534 14.2834 4.16107 13.8277L8.00013 9.98494L11.8428 13.824C12.2986 14.2797 13.0387 14.2797 13.4944 13.824C13.9501 13.3683 13.9501 12.6282 13.4944 12.1724L9.65169 8.33338L13.4908 4.49067Z"
                                            fill="#8E8E8E"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer" style="color:white;">
            <div>

                <a class="cancelBtn" (click)="closeModal()"><span class="cancelBtnText" translate></span>Cancel</a>

                <a *ngIf="progressValue==0 && disableStartUpload==false" disabled class="btn btn-primary" translate>Start.upload</a>

                <a *ngIf="progressValue==0  && disableStartUpload==true" class="btn btn-primary" (click)="disableStartUpload ? startToUpload() : null" translate>Start.upload</a>

                <a *ngIf="progressValue>0 && progressValue<100" class="btn btn-primary" translate>Uploading</a>
            </div>
        </div>
    </div>
</div>


