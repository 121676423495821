import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable()

export class SettingsGrantTemplatesService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    // Add/Edit settings reason
    addOrEditGrantTemplate(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/addOrEditGrantTemplate`), data);
    }

    getGrantTemplateAddData(): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/get_grant_template_add_data`));
    }

    linkGrantsToOptionTemplate(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/optionTemplateGrantConfig`), data);
    }

    getLinkedGrants(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/getGrantTemplate`), data);
    }

    getListOfGrantTemplates(data: object): Observable<any> {
        return this.http.post(this.getApiUrl('sales/getAllGrantTemplates'), data);
    }

    // Delete settings reason
    deleteGrantTemplate(id: any): Observable<any> {
        return this.http.delete(this.getApiUrl(`sales/deleteSettingsGrantTemplate/${id}`));
    }

    // Check settings reason already exists
    checkGrantTemplateExists(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/checkGrantTemplateExists`, params));
    }

}