import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DynamicCertificatesService = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicCertificatesService, _super);
    function DynamicCertificatesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /* Get dynamic certificates
        Response : Array
    */
    DynamicCertificatesService.prototype.getDynamicCertificates = function (params) {
        return this.http.get(this.getApiUrl("system_settings/dynamic-certificates", params));
    };
    /* Set new dynamic certificate form
        Response : Array{ 'status' =>'success', 'data' => newFormData }
    */
    DynamicCertificatesService.prototype.setDynamicCertificates = function (Data) {
        return this.http.post(this.getApiUrl('system_settings/dynamic-certificates'), JSON.stringify(Data));
    };
    /* Edit dynamic certificate form
        Response : Array{ 'status' =>'success', 'data' => newData }
    */
    DynamicCertificatesService.prototype.editDynamicCertificate = function (certificateId, Data, params) {
        return this.http.put(this.getApiUrl("system_settings/dynamic-certificates/" + certificateId + "/edit", params), JSON.stringify(Data));
    };
    /* Delete dynamic certificate form
        Response : "success"
    */
    DynamicCertificatesService.prototype.deleteDynamicCertificate = function (certificateId) {
        return this.http.put(this.getApiUrl("system_settings/dynamic-certificates/" + certificateId + "/delete"), null);
    };
    /* Delete dynamic certificate form
        Response : "success"
    */
    DynamicCertificatesService.prototype.reOrderDynamicCertificates = function (Data) {
        return this.http.put(this.getApiUrl("system_settings/dynamic-certificates/reorder"), JSON.stringify(Data));
    };
    /* Download dynamic certificate zip file
        Response : Array{ 'error' => <boolean>, 'message' => <string>, 'fileLink' => <string> }
    */
    DynamicCertificatesService.prototype.downloadDynamicCertificateZip = function (certificateId) {
        return this.http.get(this.getApiUrl("system_settings/dynamic-certificates/" + certificateId + "/download-zip"));
    };
    /* Upload dynamic certificate zip file
        Response : Array{ 'error' => <boolean>, 'message' => <string>, 'data' => <array> }
    */
    DynamicCertificatesService.prototype.uploadDynamicCertificateZip = function (data) {
        return this.http.post(this.getApiUrl("system_settings/dynamic-certificates/upload-zip"), data);
    };
    DynamicCertificatesService.ngInjectableDef = i0.defineInjectable({ factory: function DynamicCertificatesService_Factory() { return new DynamicCertificatesService(i0.inject(i1.HttpClient)); }, token: DynamicCertificatesService, providedIn: "root" });
    return DynamicCertificatesService;
}(InterfaceService));
export { DynamicCertificatesService };
