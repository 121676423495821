/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/access.directive";
import * as i3 from "../../core/auth/auth.service";
import * as i4 from "./action-buttons.component";
var styles_ActionButtonsComponent = [];
var RenderType_ActionButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionButtonsComponent, data: {} });
export { RenderType_ActionButtonsComponent as RenderType_ActionButtonsComponent };
function View_ActionButtonsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-small"], ["style", "margin-right: 2px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickButton(_co.data, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ActionButtonsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonsComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkVisible(_co.data, _v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ActionButtonsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonsComponent_3)), i0.ɵdid(2, 212992, null, 0, i2.AccessDirective, [i0.TemplateRef, i0.ViewContainerRef, i3.AuthService], { access: [0, "access"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.permissions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ActionButtonsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "actions_buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonsComponent_2)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "preview-file-link actions-toggle ss-plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ActionButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ActionButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-action-buttons", [], null, null, null, View_ActionButtonsComponent_0, RenderType_ActionButtonsComponent)), i0.ɵdid(1, 114688, null, 0, i4.ActionButtonsComponent, [i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionButtonsComponentNgFactory = i0.ɵccf("app-action-buttons", i4.ActionButtonsComponent, View_ActionButtonsComponent_Host_0, { type: "type", data: "data", index: "index", buttons: "buttons" }, { toggleEvent: "toggleEvent", onClickButtonEvent: "onClickButtonEvent" }, []);
export { ActionButtonsComponentNgFactory as ActionButtonsComponentNgFactory };
