import * as i0 from "@angular/core";
var CanDeactivateGuard = /** @class */ (function () {
    function CanDeactivateGuard() {
    }
    CanDeactivateGuard.prototype.canDeactivate = function (component, route, state, nextState) {
        if (!CanDeactivateGuard.confirmationCallback)
            return true;
        if (!CanDeactivateGuard.invoked)
            CanDeactivateGuard.invoke(nextState.url);
        CanDeactivateGuard.invoked = false;
        return CanDeactivateGuard.invokeResult;
    };
    CanDeactivateGuard.invoke = function (nextStateUrl) {
        CanDeactivateGuard.nextStateUrl = nextStateUrl;
        if (CanDeactivateGuard.confirmationCallback) {
            CanDeactivateGuard.invoked = true;
            CanDeactivateGuard.invokeResult = CanDeactivateGuard.confirmationCallback.callback(nextStateUrl);
        }
    };
    CanDeactivateGuard.setConfirmationCallback = function (url, callback) {
        CanDeactivateGuard.confirmationCallback = {
            url: url,
            callback: callback
        };
        CanDeactivateGuard.invoked = false;
        CanDeactivateGuard.invokeResult = true;
    };
    CanDeactivateGuard.removeConfirmationCallback = function () {
        CanDeactivateGuard.confirmationCallback = null;
        CanDeactivateGuard.invoked = false;
        CanDeactivateGuard.invokeResult = true;
        CanDeactivateGuard.nextStateUrl = null;
    };
    CanDeactivateGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanDeactivateGuard_Factory() { return new CanDeactivateGuard(); }, token: CanDeactivateGuard, providedIn: "root" });
    return CanDeactivateGuard;
}());
export { CanDeactivateGuard };
