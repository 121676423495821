import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FolderService } from '../service/folder.service';

@Component({
  selector: 'app-add-edit-folder-modal',
  templateUrl: './add-edit-folder-modal.component.html',
  styleUrls: ['./add-edit-folder-modal.component.scss']
})
export class AddEditFolderModalComponent implements OnInit {
  public onClose: Subject<boolean>;
  title: string;
  name: string;
  folderId: number;
  form: FormGroup;
  action: string = '';
  url:string;
  editId:any = '';
  @ViewChild('saveBtn') saveBtn: ElementRef;
  uniqueName:boolean = false;
  screen: string;
  addOn:any;
  constructor(
    private formBuilder: FormBuilder,
    private httpService: FolderService,
    public bsModalRef: BsModalRef,
  ) {
  }


  get f() {
    return this.form.controls;
  }
  ngOnInit() {
    this.onClose = new Subject();
    this.form = this.formBuilder.group({
      'name': [this.name, [Validators.required]],
    });
    if(this.name != ''){
      this.form.controls['name'].setValue(this.name);
    }
  }

  checkUniqueName(value) {
      if(this.screen == 'content_library'){
          let text = value
          let data = {
              title: text,
              screen: this.screen,
              isFolder: 1,
          }
          let checkName = false;
          if (this.editId) {
              if (this.name == value) {
                  checkName = false;
              } else {
                  checkName = true;
              }
          } else {
              checkName = true;
          }
          if (checkName) {
              this.httpService.checkUniqueFolderName(data).subscribe((resp: any) => {
                  this.uniqueName = resp
              })
          }
      }
  }

  save() {
    if (!this.form.valid) {
      this.action = '';
      return false;
    }
    this.action = 'saving';
    let folderDetails:any = {
      folderName:this.form.controls['name'].value,
      folderId:this.folderId,
      editId:this.editId
    };
    if(this.addOn) {
        folderDetails['addOn'] = this.addOn;
    }
    this.httpService.postApiCall(this.url,folderDetails)
      .subscribe((resp:any) => {
        this.action = '';
        this.onClose.next(resp);
        this.bsModalRef.hide();
      }, error => {
        this.action = '';
        console.log('err', error);
      });
  }
}
