import {
    AfterViewChecked, AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input, OnDestroy,
    OnInit,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppValidators} from "@app/directives";
import {CsToastBoxService} from "@app/services";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {Subscription} from "rxjs";
import {MxGraphService} from "@app/workflow-common/services/mx-graph.service";

@Component({
  selector: 'app-full-screen-header',
  templateUrl: './full-screen-header.component.html',
  styles: ['[disabled] {cursor: not-allowed}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullScreenHeaderComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() close:Function;
  @Input() header:string;
  @Input() subTitle:string = '';
  @Input() data:any = {};
  actionsButtons: any[] = [];
  showActivation: boolean = false;
  showConfirmationModal: boolean = false;
  currentStatus:boolean = false;
  subscription: Subscription;

  confirmationForm: FormGroup;
  action: string;
  toggleType:string;
  mxGraph: MxGraphService;

  constructor(
      private changeDetector: ChangeDetectorRef,
      private fb: FormBuilder,
      private toastBox: CsToastBoxService,
      private workflowService: WorkflowhttpService,
  ) {
      this.actionsButtons = [];
  }

  ngOnInit() {
      this.confirmationForm = this.fb.group({
          'confirmationText': ['', [Validators.required, AppValidators.confirmationTextValidator('CONFIRM')]]
      });

      this.subscription = this.workflowService.updateCustomHeader$.subscribe({
          next: (content:any) => {
            if(typeof content == 'object') {
                if(content.hasOwnProperty('action') && content['action'] == 'save:disabled') {
                    let saveBtn = this.actionsButtons.find( ( btn ) => btn.id == 'save');
                    if(saveBtn) {
                        saveBtn['disabled'] = content['value'];
                    }
                }
            }
          }
      })
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  activeOrDeactive() {
    if (this.confirmationForm.invalid) {
        return false;
    }
    this.action = 'confirming';
    const workflowsetup = {
        id: this.data.id,
        statusCode: this.currentStatus,
    };
    this.workflowService.updateStatus(this.data.id, {workflowsetup})
        .subscribe((resp:any) => {
            console.log(resp);
            this.onCloseConfirmation();
            if(resp.status === 'success') {
                this.currentStatus = this.data.status = resp.statusCode;
                if(this.mxGraph) {
                    this.mxGraph.workflowStatus = this.currentStatus;
                }
                this.toastBox.show(resp.message, 1000);
            }
            else if(resp.status === 'failed') {
                this.toastBox.show(resp.message, 1000);
            }
        })
  }

  onCloseConfirmation() {
    this.showConfirmationModal = false;
    this.currentStatus=this.data.status;
    this.action = '';
    this.confirmationForm.reset();
  }

  onShowConfirmation() {
    this.toggleType = this.data.status === 1 ? 'deactivate' : 'activate';
    this.showConfirmationModal = true;
    console.log(this.data.status);
  }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
      console.log('unsubscribe custom header')
    }
}
