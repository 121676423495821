import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICsPagingParams} from "@app/shared/cs-components/cs-components.interface";
import {TCsPagingEvent} from "@app/shared/cs-components/cs-components.types";

@Component({
    selector: 'app-cs-pagination-panel',
    templateUrl: './cs-pagination-panel.component.html',
    styleUrls: ['cs-pagination-panel.component.scss']
})
export class CsPaginationPanelComponent implements OnInit {

    @Input() pageOffsetList = [5, 10, 20, 50, 100];
    @Input() pageList: number[];
    @Input() initialParams: ICsPagingParams;
    @Input() maxPagesDisplayLength = 10;

    @Input() set totalRecord(value: number) {
        this._totalRecord = value;
        if (this.pagingParams && value >= 0) {
            const pageSize = this.pagingParams.pageSize || 5;
            this.setPageSize(pageSize, 'none');
        }
    }

    @Output() onUpdatePagingParams = new EventEmitter<ICsPagingParams>();

    pagingParams: ICsPagingParams;
    _totalRecord: number;

    constructor() {
    }

    ngOnInit() {
        this.setInitialPagingParams();
        this.setPageList();
        this.setPaginationProps();
        this.updatePagingParams();
    }

    setInitialPagingParams() {
        this.pagingParams = {
            currentPage: (this.initialParams && this.initialParams.currentPage) || 1,
            pageSize: (this.initialParams && this.initialParams.pageSize) || 5,
            startRow: null,
            endRow: null
        };
    }

    setPageList() {
        this.pageList = Array(Math.ceil(this._totalRecord / this.pagingParams.pageSize)).fill(0).map((_, i) => i + 1);
    }

    setPaginationProps() {
        const remainingLastPageRowCount = (this._totalRecord % this.pagingParams.pageSize);
        this.pagingParams.startRow = (this.pagingParams.pageSize * (this.pagingParams.currentPage - 1)) + 1;
        this.pagingParams.endRow = this.pageList.length === this.pagingParams.currentPage && remainingLastPageRowCount ? (this.pagingParams.startRow - 1) + remainingLastPageRowCount : this.pagingParams.pageSize * this.pagingParams.currentPage;
    }

    setPageSize(offset: number, pagingEvent?: TCsPagingEvent) {
        this.pagingParams.currentEvent = pagingEvent || 'pageSize';
        this.pagingParams.pageSize = offset;
        this.pagingParams.currentPage = Math.trunc(((this.pagingParams.startRow - 1) / this.pagingParams.pageSize)) + 1;
        this.setPageList();
        this.setPaginationProps();
        this.updatePagingParams();
    }

    setCurrentPage(page: number) {
        this.pagingParams.currentEvent = 'pageNav';
        this.pagingParams.currentPage = page;
        this.setPaginationProps();
        this.updatePagingParams();
    }

    updatePagingParams() {
        this.onUpdatePagingParams.emit(this.pagingParams);
    }
}
