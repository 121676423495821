import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import {CommunicationLayoutComponent} from "@app/shared/communication-mapping/layouts/communication-layout.component";

@Component({
  selector: 'app-communication-container',
  templateUrl: './communication-container.component.html',
  styles: []
})
export class CommunicationContainerComponent implements OnInit, OnDestroy {

    public onClose: Subject<any>;
    allowSegmentation: boolean;
    communicationScreenType: any;
    categoryType: 'communication'|'notification' = 'communication';
    objectName: string = '';
    objectId: null|number = null; //tablePkId
    objectTypeId: string = '' //tableType
    isCustomerSpecific: boolean;
    disabledAction: boolean = true;
    subscription: Subscription;
    communicationLayoutComponent: CommunicationLayoutComponent
    unSaveConfirmation: boolean = false;
    title: string = '';
    selectedTemplateData:any = [];
    helpText:string = '';
    viewMode: boolean;
    constructor(
        public modalRef: BsModalRef,
        private eventDispatcher: CommunicationDispatcherService
    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: (data: any) => {
                if(typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                if(action == CommunicationDispatcherService.CONSTANTS['checkIsValidForm']) {
                    this.disabledAction= !data['isValid'];
                }
                else if(action == CommunicationDispatcherService.CONSTANTS['beforeSaveAction']) {
                    this.disabledAction = true;
                }
                else if(action == CommunicationDispatcherService.CONSTANTS['afterSaveAction']) {
                    this.onClose.next('saveAndClosed');
                    this.modalRef.hide();
                }
                else if(action == CommunicationDispatcherService.CONSTANTS['inlineSaveAction']) {
                    this.onClose.next(data['formData']);
                    this.modalRef.hide();
                }
                else if(action === CommunicationDispatcherService.CONSTANTS['close']) {
                    this.onClose.next('closed');
                    this.modalRef.hide();
                }
            }
        });
    }

    ngOnDestroy(): void {
        console.info('destroyed/unsubscribe full screen container');
        if(this.subscription)
            this.subscription.unsubscribe();
    }

    setLayoutComponent(component: CommunicationLayoutComponent) {
        // console.log('setLayoutComponent', component);
        this.communicationLayoutComponent = component;
    }

    saveCommunication() {
        this.communicationLayoutComponent.saveCommunication();
    }

    closeModal() {
        if(this.unSaveConfirmation) {

        }
        this.modalRef.hide();
    }

}
