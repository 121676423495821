import { Injectable } from '@angular/core';
import {InputModel} from "@app/shared/communication-mapping/services/input-model";

@Injectable({
  providedIn: 'root'
})
export class CommunicationHelperService {

    constructor() { }

    findCurrentTemplate(inputModel: InputModel, communicationType:string = 'email') {
        let {templates, activeSegmentationValue1, activeSegmentationValue2 } = inputModel;
        // console.log('templates',templates);
        if(!templates) {
            return null;
        }
        if(!inputModel.allowSegmentation) {
            activeSegmentationValue1 = -1;
            activeSegmentationValue2 = -1;
        }

        const activeRecord = templates.find((item) => {
            return (activeSegmentationValue1 == item.segmentationValue1 || item.segmentationValue1 == null)
                && (activeSegmentationValue2 == item.segmentationValue2 || item.segmentationValue2 == null)
                && item.communicationType == communicationType
        });
        if(!activeRecord) {
            return null;
        }
        return activeRecord;
    }

    getCurrentPreview(emailTemplate, smsTemplate, letterTemplate) {
        if( (emailTemplate && smsTemplate) || (emailTemplate && !smsTemplate) )
            return 'email';
        else if (!emailTemplate && smsTemplate)
            return 'sms'
        else if (!emailTemplate && !smsTemplate && letterTemplate)
            return 'letter'
    }
}
