<form *ngIf="partsKitForm" [formGroup]="partsKitForm" (submit)="savePartsKit()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>
				Parts.kit.help.text
			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<!-- Parts kit selection -->
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>Parts.kit</label>
						<div class="controls select2-form-container">
							<select class="full-width-select2"
									csSelect2
									[select2Config]="select2Config"
									class="full-width-select"
									formControlName="partsKit"
									(selected)="selectPartKit($event)">
                                <option value="" translate="">Please.Choose</option>
								<option *ngFor="let partKit of partKitList" value="{{partKit.id}}">
									{{partKit.description}}</option>
							</select>
						</div>
					</div>
					<div class="control-group">
						<label class="control-label required_field" translate >add.part.kit</label>
						<div class="controls">
							<input type="text"
								   class="control-input"
								   style="width:60%"
								   formControlName="quantity" (keypress)="restrictSpecialChar($event)" (keyup)="restrictZero($event)">
						</div>
						</div>
						<div *ngIf="showDescription">
							<p class="with-top-margin"><strong>{{selectedKit?.description}}</strong></p>
							<p *ngIf="partKitToDisplay.length"><strong translate>This.part.kit.contains</strong></p>
							<div>
								<ul class="part-kits-list">
									<li class="list" *ngFor="let kit of partKitToDisplay">{{kit?.quantity}} x {{kit?.part_name}} <span
											*ngIf="kit?.exists" translate="">already.Added</span></li>
								</ul>
							</div>
					 </div>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="action == 'saving' || partsKitForm.invalid || buttonDisabled" class="btn btn-primary">
			<span *ngIf="action == 'save'" translate>Save</span>
			<span *ngIf="action == 'saving'" translate>Saving</span>
		</button>
	</div>
</form>
