import { OnInit, Renderer, ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
var CsTimepickerComponent = /** @class */ (function () {
    function CsTimepickerComponent(el_ref, renderer) {
        this.el_ref = el_ref;
        this.renderer = renderer;
        this._onTouched = function () { };
        this._onChange = function () { };
        this.hourEvent = new EventEmitter();
        this.minuteEvent = new EventEmitter();
        this.valueEmitter = new EventEmitter();
        // Timepicker functions
        this.hoursDropdownItems = [];
        this.minutesDropdownItems = ['00', '15', '30', '45'];
        this.second = 0;
        this._showMeridian = true;
    }
    // Set Touched on blur
    CsTimepickerComponent.prototype.onBlur = function () {
        this._onTouched();
    };
    // From ControlValueAccessor Interface
    CsTimepickerComponent.prototype.writeValue = function (value) {
        var _this = this;
        if (value !== this._value) {
            if (value instanceof Date)
                this._value = value;
            else if (typeof (value) == 'string') {
                this._value = new Date(value);
                setTimeout(function () { return _this.updateModelValue(); }, 10);
            }
            else {
                this._value = new Date();
                setTimeout(function () { return _this.updateModelValue(); }, 10);
            }
            this.assignValues();
        }
    };
    // From ControlValueAccessor Interface
    CsTimepickerComponent.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    // From ControlValueAccessor Interface
    CsTimepickerComponent.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    // Publish updated value to form control
    CsTimepickerComponent.prototype.updateModelValue = function () {
        this._onChange(this._value);
        this.emitValue(this._value);
    };
    Object.defineProperty(CsTimepickerComponent.prototype, "showMeridian", {
        set: function (val) {
            var _this = this;
            this._showMeridian = !!val;
            var maxHours = this._showMeridian ? 12 : 23;
            this.hoursDropdownItems = (new Array(maxHours)).join('#').split('#').map(function (h, index) { return _this.pad(index + 1); });
            if (!this._showMeridian) {
                this.hoursDropdownItems = ["00"].concat(this.hoursDropdownItems);
            }
        },
        enumerable: true,
        configurable: true
    });
    CsTimepickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.timepickerChangeEvent) {
            this.eventsSubscription = this.timepickerChangeEvent.subscribe(function (data) {
                if (data) {
                    if (data.hasOwnProperty('hour')) {
                        _this.hour = data.hour;
                        _this.onHourChange();
                    }
                    else if (data.hasOwnProperty('min')) {
                        _this.minute = data.min;
                        _this.onMinuteChange();
                    }
                }
            });
        }
        if (!this.hoursDropdownItems.length)
            this.showMeridian = this._showMeridian;
    };
    // Assigning values to the component variables
    CsTimepickerComponent.prototype.assignValues = function () {
        if (this._showMeridian) {
            this.meridian = this._value.getHours() >= 12 ? 'PM' : 'AM';
            this.hour = this._value.getHours() > 12 ? this.pad(this._value.getHours() - 12) : this.pad(this._value.getHours());
            if (this._value.getHours() == 0)
                this.hour = this.pad(this._value.getHours() + 12);
        }
        else {
            this.hour = this.pad(this._value.getHours());
        }
        this.minute = this.pad(this._value.getMinutes());
    };
    // Add leading zero
    CsTimepickerComponent.prototype.pad = function (n) {
        return ("00" + n).slice(-2);
    };
    CsTimepickerComponent.prototype.onHourChange = function () {
        this._value.setHours(this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour));
        if (this._showMeridian && this.meridian === 'AM' && this.hour == '12')
            this._value.setHours(0);
        if (this._showMeridian && this.meridian === 'PM' && this.hour == '12')
            this._value.setHours(12);
        this._onTouched();
        this.updateModelValue();
        this.hourEvent.emit((this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour)).toString());
    };
    CsTimepickerComponent.prototype.onMinuteChange = function () {
        this._value.setMinutes(parseInt(this.minute));
        this._onTouched();
        this.updateModelValue();
        this.minuteEvent.emit(this.minute);
    };
    CsTimepickerComponent.prototype.onMeridianChange = function () {
        if (this.meridian == 'AM' && this._value.getHours() > 12) {
            this._value.setHours(parseInt(this.hour));
        }
        else if (this.meridian == 'AM' && this._value.getHours() == 12) {
            this._value.setHours(parseInt(this.hour) - 12);
        }
        else if (this.meridian == 'PM' && this.hour == '12') {
            this._value.setHours(12);
        }
        else if (this.meridian === 'PM' && this._value.getHours() < 12) {
            this._value.setHours(parseInt(this.hour) + 12);
        }
        this._onTouched();
        this.updateModelValue();
        this.hourEvent.emit((this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour)).toString());
        this.minuteEvent.emit(this.minute);
    };
    CsTimepickerComponent.prototype.emitValue = function (value) {
        this.valueEmitter.emit(value);
    };
    return CsTimepickerComponent;
}());
export { CsTimepickerComponent };
