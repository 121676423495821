import * as tslib_1 from "tslib";
import { InterfaceService } from "@app/interfaces";
import { HttpClient } from "@angular/common/http";
var CsTaggingService = /** @class */ (function (_super) {
    tslib_1.__extends(CsTaggingService, _super);
    function CsTaggingService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.tags = [];
        _this.storeId = 'cs-tags';
        return _this;
    }
    CsTaggingService.prototype.getTagsList = function (params) {
        return this.http
            .get(this.getApiUrl("get_tags_list", params));
    };
    CsTaggingService.prototype.getTags = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.tags = _this.getWithExpiry(_this.storeId);
            if (_this.tags.length > 0) {
                resolve(_this.tags);
            }
            else if (_this.tags.length === 0) {
                _this.getTagsList()
                    .subscribe({
                    next: function (resp) {
                        _this.tags = _this.format4Quill(resp);
                        _this.setWithExpiry(_this.storeId, _this.tags);
                        resolve(_this.tags);
                    }
                });
            }
            else {
                reject(null);
            }
        });
    };
    CsTaggingService.prototype.getWithExpiry = function (key) {
        var itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return [];
        }
        var item = JSON.parse(itemStr);
        var now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return [];
        }
        this.tags = item.value;
        return this.tags;
    };
    CsTaggingService.prototype.setWithExpiry = function (key, value, ttl) {
        if (ttl === void 0) { ttl = (60000 * 60 * 24); }
        var now = new Date();
        var item = {
            value: value,
            expiry: now.getTime() + ttl,
        };
        localStorage.setItem(key, JSON.stringify(item));
    };
    CsTaggingService.prototype.format4Quill = function (data) {
        var tagsModName = Object.keys(data);
        var tags = [];
        tagsModName.forEach(function (tagName) {
            var tagsList = Object.values(data[tagName]);
            var groupName;
            if (tagsList[0] == 'schedule_date') {
                groupName = tagName == 'diary_events' ? 'schedule_events' + " _tags" : tagName;
            }
            else {
                groupName = tagName.indexOf("_tags") === -1 ? tagName + " _tags" : tagName;
            }
            var group = {
                id: groupName,
                value: groupName,
                disabled: true,
            };
            tags.push(group);
            tagsList.forEach(function (tag) {
                tags.push({
                    id: tag,
                    value: tag
                });
            });
        });
        return tags;
    };
    return CsTaggingService;
}(InterfaceService));
export { CsTaggingService };
