import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CsVersionService } from'@app/services/utility-services/cs-version.service';

import {InterfaceService} from '@app/interfaces';
import {MicroserviceHelperService} from '@app/shared/microservice/helper/microservice-helper.service';
import {CommonDataService} from "@app/services/http-services/common-data.service";

declare var angular;

@Injectable()
export class SettingsService extends InterfaceService {
    private _csVersion: any;
    private _csLanguage: any;
    private _modulePermissions: Object;
    private _allPermissions: Object;
    csVersionHeaders: Object;
    private _csIntlSettings: Object;
    private _csV5Settings: Object;
    private _csCountryCode: any;
    private countriesJSONData: any;
    private JWT_TOKEN: any;
    private ordinals: Object;

    private userAccessDetailsSource: BehaviorSubject<Object> = new BehaviorSubject(null);
    userAccessDetails$ = this.userAccessDetailsSource.asObservable();

    private modulePermissionsSource: BehaviorSubject<Object> = new BehaviorSubject(null);
    modulePermissions$ = this.modulePermissionsSource.asObservable();

    private savedColorsData = new BehaviorSubject<any>(null)
    savedColorsData$ = this.savedColorsData.asObservable();

    constructor(
        private http: HttpClient,
        private microserviceHelperService: MicroserviceHelperService,
        private versionService: CsVersionService,
        private commonService: CommonDataService
    ) {
        super();
    }

    dataFromA1App(appData) {
        this._csVersion = appData['CS_VERSION'];
        this._csLanguage = appData['CS_LANGUAGE'];
        this._csIntlSettings = appData['INTL_SETTINGS'];
        this.setModulePermissions(appData['MODULE_PERMISSIONS']);
        this.setAccessDetails(appData['ALL_PERMISSIONS']);
        this._csCountryCode = appData['COUNTRY_CODE'];
        this.updateCSVersion();
        this.updateSavedColors();
    }

    updateSavedColors() {
        if(this._allPermissions['accessDetails'] && this._allPermissions['accessDetails'].hasOwnProperty('clientsconfig')){
            this.commonService.fetchSavedColorsData();
        }
    }

    updateCSVersion() {
        this.versionService.updateCSVersion(this._csVersion);
    }

    loadAppData(): Promise<boolean> {

        return <Promise<boolean>>new Promise<boolean>((resolve, reject) => {

            this.fetchData().subscribe(appData => {

                this._csVersion = appData['CS_VERSION'];
                this._csLanguage = appData['CS_LANGUAGE'];
                this._csIntlSettings = appData['INTL_SETTINGS'];
                this.setModulePermissions(appData['MODULE_PERMISSIONS']);
                this.setAccessDetails(appData['ALL_PERMISSIONS']);
                this._csCountryCode = appData['COUNTRY_CODE'];
                this.JWT_TOKEN = appData['JWT_TOKEN'];
                this.ordinals = appData['ORDINALS'];

                let responseHeaders = {};
                appData.responseHeaders.keys().forEach(key => {
                    responseHeaders[key] = appData.responseHeaders.get(key);
                });

                this.csVersionHeaders = responseHeaders;
                this.updateCSVersion();
                this.updateSavedColors();
                setTimeout(() => resolve(true), 50);
                if(appData['V5_WEB_TOKEN']) {
                    this.microserviceHelperService.setToken(appData['V5_WEB_TOKEN']);
                }
            }, Error => {
                reject(false);
            });

            this.http.get("./javascripts/countries.json").subscribe(data =>{
                this.countriesJSONData = data;
            });
        });

    }

    setAccessDetails(data: Object) {
        this._allPermissions = data;
        this.userAccessDetailsSource.next(this._allPermissions['accessDetails'] || null);
    }

    isAuthenticated() {
        const accessDetails =  this._allPermissions['accessDetails'];
        if(typeof accessDetails == 'object') {
            return Object.keys(accessDetails).length > 0;
        }
        return false;
    }

    setModulePermissions(data: Object){
        this._modulePermissions = data;
        this.modulePermissionsSource.next(this._modulePermissions || null);
    }

    getCountryCode() {
        return this._csCountryCode;
    }

    getCsVersion() {
        return this._csVersion;
    }

    getCsLanguage(){
        return this._csLanguage;
    }

    getModulePermissions() {
        return this._modulePermissions;
    }

    getAllPermissions() {
        return this._allPermissions;
    }

    getCountryMasterData() {
        return this.countriesJSONData;
    }
    getJWTTOKENData() {
        return this.microserviceHelperService.getToken();
    }


    getIntlSettings(refresh:boolean = false){
        let promise = this._csIntlSettings;
        if(refresh===true) {
            let that = this;
             promise = new Promise(function (resolve) {
                that.http.get(that.getApiUrl('get_intl'))
                    .subscribe(result => {
                        that._csIntlSettings = result;
                        resolve(result);
                    });
            });
        }
        return promise;
    }


    // Fetch initial data from backend
    fetchData() {
        return this.http.get(this.getApiUrl('get_user_config_data?from=7'), { observe: 'response' }).pipe(
            map(Response => {
                return {
                    ALL_PERMISSIONS: Response.body['accessDetailsResp'],
                    MODULE_PERMISSIONS: Response.body['moduleDetails'],
                    CS_VERSION: Response.body['csVersion'],
                    CS_LANGUAGE: Response.body['clientLanguage'],
                    INTL_SETTINGS: Response.body['intlSettings'],
                    responseHeaders: Response.headers,
                    COUNTRY_CODE: Response.body['clientCountryCode'],
                    V5_WEB_TOKEN: Response.body.hasOwnProperty('v5Token') ? Response.body['v5Token'] : null,
                    ORDINALS: Response.body['ordinalJson']
                };
            })
        );
        /*return forkJoin(
            this.http.get(this.getApiUrl('get_access_details'), { observe: 'response' }),
            this.http.get(this.getApiUrl('module_permission_check'), { observe: 'response' }),
            this.http.get(this.getApiUrl('get_version'), { observe: 'response' }),
            this.http.get(this.getApiUrl('get_client_language'), { observe: 'response',responseType : "text" }),
            this.http.get(this.getApiUrl('get_intl'), { observe: 'response'}),
            this.http.get(this.getApiUrl('get_client_country_code'), { observe: 'response'}),
        ).pipe(
            map(Response => {
                return {
                    ALL_PERMISSIONS: Response[0].body,
                    MODULE_PERMISSIONS: Response[1].body,
                    CS_VERSION: Response[2].body,
                    CS_LANGUAGE: Response[3].body,
                    INTL_SETTINGS: Response[4].body,
                    responseHeaders: Response[4].headers,
                    COUNTRY_CODE: Response[5].body,
                };
            })
        );*/
    }

    getRegionalDateFormats() {
        return {
            "0": 'DD/MM/YY',
            "1": 'DD/MM/YYYY',
            "2": 'DD/MMM/YY',
            "3": 'DD/MMM/YYYY',
            "4": 'DD-MM-YY',
            "5": 'DD-MM-YYYY',
            "6": 'DD-MMM-YY',
            "7": 'DD-MMM-YYYY',
            "50": 'MM/DD/YY',
            "51": 'MM/DD/YYYY',
            "52": 'MMM/DD/YY',
            "53": 'MMM/DD/YYYY',
            "54": 'MM-DD-YY',
            "55": 'MM-DD-YYYY',
            "56": 'MMM-DD-YY',
            "57": 'MMM-DD-YYYY'
        };
    }
}
