<form (submit)="save()" *ngIf="form" [formGroup]="form">
  <div class="modal-header">
      <div class="modal-title pull-left" translate>{{title}}</div>
  </div>
  <div class="modal-body ng-partsandprices">
      <div class="body-content-section">
          <div class="row-fluid">
              <div class="span12">
                  <div class="control-group clearfix">
                      <label class="control-label" translate>Folder.name</label>
                      <div class="controls">
                        <i class="fa fa-folder folder-icon" aria-hidden="true"></i>
                          <input focus placeholder="Folder name" appAutoFocus class="control-input folder-input" formControlName="name"
                                 type="text" (valueChanged)="checkUniqueName($event)" appDebounceTimer>
                      </div>
                      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                      <span *ngIf="f.name.errors.required" class="control-error-message show">
                              <span translate>Please.enter.valid.folder.name</span>
                      </span>
                      </div>
                      <div *ngIf="(f.name.dirty || f.name.touched) && uniqueName">
                          <span class="control-error-message show">
                              <span translate>This.template.name.already.exists</span>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="modal-footer">
      <a (click)="bsModalRef.hide()" class="btn" href="javascript:void(0)" translate>Cancel</a>
      <button #saveBtn [disabled]="form.invalid || action == 'saving' || uniqueName" class="btn btn-primary">
          <span [hidden]="action == 'saving'" translate>Save</span>
          <span [hidden]="action != 'saving'" translate>Saving</span>
      </button>
  </div>
</form>
