import { Injectable } from '@angular/core';
import {InterfaceService} from "@app/interfaces";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class ScHttpServiceService extends InterfaceService {

  constructor(
      private http: HttpClient
  ) {
      super();
  }

    getEmailTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsEmailTemplates`, params));
    }

    getSMSTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsSmsTemplates`, params));
    }

    serviceReminderConfig(data: object, screenType:string): Observable<any>  {
        return this.http.post(this.getApiUrl(`communication/settings/${screenType}/servicereminder/config`), JSON.stringify(data));
    }

    saveServiceReminderConfig(data: object, screenType:string): Observable<any> {
        return this.http.post(this.getApiUrl(`communication/settings/${screenType}/servicereminder/config/save`), JSON.stringify(data));
    }
}
