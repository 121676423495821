import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSortableComponent } from './ng-sortable/ng-sortable.component';

@NgModule({
  declarations: [NgSortableComponent],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [ NgSortableComponent ],
  bootstrap:[]
})
export class SortableModule { }
