import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
var ViewAttachFilesComponent = /** @class */ (function () {
    function ViewAttachFilesComponent(bsModalRef, sanitizer) {
        this.bsModalRef = bsModalRef;
        this.sanitizer = sanitizer;
    }
    ViewAttachFilesComponent.prototype.ngOnInit = function () {
        this.filesArray;
        this.clone;
        this.Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.clone.fileLocation);
        this.onClose = new Subject();
    };
    ViewAttachFilesComponent.prototype.keyEvent = function (event) {
        if (event.keyCode == 39) { //right key
            this.fileRightArrow();
        }
        else if (event.keyCode == 37) { //left key
            this.fileLeftArrow();
        }
    };
    ViewAttachFilesComponent.prototype.fileRightArrow = function () {
        if (this.filesArray.length > 0 && this.filesArray.length - 1 > this.index) {
            this.index++;
            this.selectedIndex(this.index);
        }
    };
    ViewAttachFilesComponent.prototype.fileLeftArrow = function () {
        if (this.filesArray.length > 0 && this.index > 0) {
            this.index--;
            this.selectedIndex(this.index);
        }
    };
    ViewAttachFilesComponent.prototype.selectedIndex = function (currentIndex) {
        if (this.filesArray[currentIndex]) {
            var findNextImage = this.filesArray[currentIndex];
            this.clone['fileLocation'] = findNextImage['fileLocation'];
            this.clone['fileName'] = findNextImage['fileName'];
        }
    };
    ViewAttachFilesComponent.prototype.attachFileDownload = function (imageSrc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var image, imageBlog, imageURL, link;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageSrc)];
                    case 1:
                        image = _a.sent();
                        return [4 /*yield*/, image.blob()];
                    case 2:
                        imageBlog = _a.sent();
                        imageURL = URL.createObjectURL(imageBlog);
                        link = document.createElement('a');
                        link.href = imageURL;
                        link.download = this.clone.fileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ViewAttachFilesComponent;
}());
export { ViewAttachFilesComponent };
