<!-- Title -->
<div class="modal-header">
    <h4 class="modal-title pull-left" translate>{{title}}</h4>
</div>
<!-- Video section -->
<div class="modal-body">
    <div class="help_section">
        <div class="help-text" translate>
            video.help.text
        </div>
    </div>
    <div class="body-content-section">
        <div class="row-fluid">
            <div class="span12">
                <!-- Embed Url -->
                <div class="control-group clearfix">
                    <label class="control-label" translate>embed.url</label>
                    <div class="controls w-70">
                        <input type="text" class="control-input" [(ngModel)]="embedUrl" (ngModelChange)="getVideoUrl()">
                        <span *ngIf="youtubeError != ''" class="control-error-message show">
                            <span translate>{{youtubeError}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Drop or select video -->
        <!-- <div class="m-20">
            <div (onFileDrop)="uploadFile($event)" ng2FileDrop [uploader]="uploader">
                <div class="upload-option" (click)="fileInput.click()">
                    <p class="upload-option-text" translate>Upload.video.option.help.text</p>
                    <input #fileInput type="file" (change)="selectFile($event)" style="display:none;" />
                </div>
            </div>
            <span *ngIf="errorMsg != ''" class="control-error-message show text-center">
                <span translate>{{errorMsg}}</span>
            </span>
        </div> -->
        <div class="m-20" >
            <ng-container *ngIf="embedUrl && embedUrl != '' && youtubeError == ''">
                <iframe id=iframetag style="width: 100%;" [src]="loadUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    Sorry your browser does not support inline frames.
                </iframe>
            </ng-container>
            <!-- <ng-container *ngIf="videoURL && videoURL != ''">
                <video id="videotag" style="width: 100%;" class="imgae-loaded" controls >
                    <source [src]="loadUrl" id="video_here">
                        Your browser does not support HTML5 video.
                </video>
            </ng-container> -->
        </div>

    </div>
</div>
 <!-- Footer -->
 <div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
    <button class="btn btn-primary" (click)="addVideoBlock()" [disabled]="(videoURL == '' && embedUrl == '') || errorMsg != '' || youtubeError != ''">
        <span translate>Save</span>
    </button>
</div>
