import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import { FormControl } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FormParserServicesService = /** @class */ (function (_super) {
    tslib_1.__extends(FormParserServicesService, _super);
    function FormParserServicesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.assetQuestionIds = [];
        _this.dependantFields = {};
        _this.dependantSections = {};
        _this.parentDependantFields = {};
        _this.parentDependantFieldsForSection = {};
        _this.checkBoxFields = [];
        _this.pageWiseDependantFields = {};
        _this.pages = {};
        return _this;
    }
    /**
     *
     * @param customerId
     * @param {Object} params
     * @returns {Observable<any>}
     */
    FormParserServicesService.prototype.getCustomerAppliances = function (customerId, params) {
        return this.http.get(this.getApiUrl(customerId + "/getAllAppliances", params));
    };
    /**
     *
     * @returns {{fieldAnswerType: string; fieldLabel: string; questionID: string}[]}
     */
    FormParserServicesService.prototype.getApplianceData = function () {
        var applianceData = [
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Appliance type',
                questionID: '!applianceType',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Fuel type',
                questionID: '!applianceFuel',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Make',
                questionID: '!applianceMake',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Model',
                questionID: '!applianceModel',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Location',
                questionID: '!applianceLocation',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Flue',
                questionID: '!applianceFlue',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Serial number',
                questionID: '!applianceSerialNo',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Reference',
                questionID: '!applianceReference',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Ventilation',
                questionID: '!applianceVentilation',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Status',
                questionID: '!applianceStatus',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Used for',
                questionID: '!applianceUsedFor',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Did you install the appliance?',
                questionID: '!applianceFInstall',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Have you notified the manufacturer?',
                questionID: '!applianceFNotified',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Installed on',
                questionID: '!applianceInstalledOn',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Installed by',
                questionID: '!applianceInstalledBy',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Commissioned on',
                questionID: '!applianceCommissioned',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'In warranty',
                questionID: '!applianceWarranty',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Warranty start date',
                questionID: '!applianceWarStart',
            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Warranty end date',
                questionID: '!applianceWarEnd',
            }
        ];
        return applianceData;
    };
    /**
     *
     * @param applianceFormData
     * @param pageNumber
     * @param itemCount
     * @returns {{}}
     */
    FormParserServicesService.prototype.getApplianceFormValue = function (applianceFormData, pageNumber, itemCount) {
        var applianceData = this.getApplianceData();
        var applianceFormValue = {};
        applianceData.forEach(function (appliance) {
            var tag = appliance.questionID + "_page" + pageNumber + "_" + itemCount;
            if (applianceFormData[appliance.fieldLabel] !== undefined) {
                applianceFormValue[tag] = applianceFormData[appliance.fieldLabel];
                if (appliance.hasOwnProperty('assetId') && appliance['assetId'] !== undefined && appliance['assetId'] !== null) {
                    var assetIdTag = "!assetId_page" + pageNumber + "_" + itemCount;
                    applianceFormValue[assetIdTag] = appliance['assetId'];
                }
            }
        });
        return applianceFormValue;
    };
    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {{listItemForm: FormGroup; formLabels: {}}}
     */
    FormParserServicesService.prototype.setApplianceForm = function (listItemForm, pageNumber, itemCount, listItemFieldOrderArray) {
        var applianceData = this.getApplianceData();
        var newFormLabels = {};
        var nonAssetFields;
        if (listItemFieldOrderArray[pageNumber - 1]) {
            if (listItemFieldOrderArray[pageNumber - 1][itemCount - 1]) {
                nonAssetFields = listItemFieldOrderArray[pageNumber - 1][itemCount - 1];
            }
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = [];
        }
        else {
            listItemFieldOrderArray[pageNumber - 1] = {};
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = [];
        }
        applianceData.forEach(function (appliance) {
            var tag = appliance.questionID + "_page" + pageNumber + "_" + itemCount;
            var formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
            var visibleOnList = false;
            if (appliance['questionID'] == '!applianceSerialNo' || appliance['questionID'] == '!applianceModel'
                || appliance['questionID'] == '!applianceMake' || appliance['questionID'] == '!applianceType')
                visibleOnList = true;
            newFormLabels[tag] = { label: appliance['fieldLabel'], visibleOnList: visibleOnList, type: '' };
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1].push(tag);
        });
        if (nonAssetFields)
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = listItemFieldOrderArray[pageNumber - 1][itemCount - 1].concat(nonAssetFields);
        return { listItemForm: listItemForm, formLabels: newFormLabels };
    };
    /**
     *
     * @param listItem
     * @param labels
     * @returns {{}}
     */
    FormParserServicesService.prototype.getCurrentlySelectedAppliance = function (listItem, labels) {
        var appliance = {};
        for (var _i = 0, _a = Object.entries(listItem); _i < _a.length; _i++) {
            var _b = _a[_i], questionId = _b[0], value = _b[1];
            if (labels[questionId]) {
                appliance[labels[questionId]['label']] = value;
            }
        }
        return appliance;
    };
    /**
     *
     * @param questionIdArray
     * @returns {any}
     */
    FormParserServicesService.prototype.getApplianceQuestionIds = function (questionIdArray) {
        var applianceData = this.getApplianceData();
        applianceData.forEach(function (appliance) {
            questionIdArray.push(appliance.questionID);
        });
        return questionIdArray;
    };
    /**
     *
     * @returns {any}
     */
    FormParserServicesService.prototype.getAssetQuestionIds = function () {
        return this.assetQuestionIds;
    };
    /**
     *
     * @param assetObjectArray
     * @param pageNumber
     * @param itemCount
     * @returns {{}}
     */
    FormParserServicesService.prototype.getAssetFormValue = function (assetObjectArray, pageNumber, itemCount) {
        var assetFormValue = {};
        assetObjectArray.forEach(function (asset) {
            var tag = asset.questionID + "_page" + pageNumber + "_" + itemCount;
            var applianceGrouptagId = "!applianceGroup_page" + pageNumber + "_" + itemCount;
            if (asset['fieldValue'] !== undefined) {
                assetFormValue[tag] = asset['fieldValue'];
                if (asset.hasOwnProperty('assetId') && asset['assetId'] !== undefined && asset['assetId'] !== null && tag == applianceGrouptagId) {
                    var assetIdTag = "!assetId_page" + pageNumber + "_" + itemCount;
                    assetFormValue[assetIdTag] = asset['assetId'];
                }
            }
        });
        return assetFormValue;
    };
    /**
     *
     * @param {FormGroup} formGroup
     * @param assetObjectArray
     * @param pageNumber
     * @param itemCount
     * @returns {{formGroup: FormGroup; formLabels: {}}}
     */
    FormParserServicesService.prototype.setAssetForm = function (formGroup, assetObjectArray, pageNumber, itemCount) {
        var _this = this;
        var newFormLabels = {};
        assetObjectArray.forEach(function (asset) {
            _this.setAssetQuestionId(asset.questionID);
            var tag = asset.questionID + "_page" + pageNumber + "_" + itemCount;
            var formControl = new FormControl('');
            formControl.setValue(asset['fieldValue']);
            if (tag.includes('!') && formGroup.get(tag)) {
                formGroup.removeControl(tag);
            }
            formGroup.addControl(tag, formControl);
            if (asset['fieldValue'] !== undefined && asset['fieldValue'].includes('/clientimages/'))
                newFormLabels[tag] = { label: asset['fieldLabel'], visibleOnList: true, type: 'Photo' };
            else
                newFormLabels[tag] = { label: asset['fieldLabel'], visibleOnList: true, type: '' };
        });
        return { formGroup: formGroup, formLabels: newFormLabels };
    };
    /**
     *
     * @param listItem
     * @param labels
     * @returns {{asset: any[]; assetGroupObjectKey: string}}
     */
    FormParserServicesService.prototype.getCurrentlySelectedAsset = function (listItem, labels) {
        var asset = [];
        var assetGroup = '';
        var assetType = '';
        for (var _i = 0, _a = Object.entries(listItem); _i < _a.length; _i++) {
            var _b = _a[_i], questionId = _b[0], value = _b[1];
            if (labels[questionId] && labels[questionId]['visibleOnList']) {
                var assetValue = {};
                assetValue['fieldValue'] = value;
                assetValue['fieldLabel'] = labels[questionId]['label'];
                var idArray = questionId.split('_');
                var questionID = '';
                if (idArray.length == 3) {
                    questionID = idArray[0];
                }
                else {
                    for (var i = 0; i < idArray.length - 2; ++i) {
                        if (questionID) {
                            questionID += '_' + idArray[i];
                        }
                        else {
                            questionID = idArray[i];
                        }
                    }
                }
                assetValue['questionID'] = questionID;
                var valueInString = value;
                if (valueInString != undefined && valueInString.includes('/clientimages/'))
                    assetValue['photo'] = true;
                else
                    assetValue['photo'] = false;
                if (labels[questionId]['label'] == 'Asset group' || labels[questionId]['label'] == 'Asset type') {
                    assetValue['hide'] = false;
                    if (labels[questionId]['label'] == 'Asset group')
                        assetGroup = value;
                    if (labels[questionId]['label'] == 'Asset type')
                        assetType = value;
                }
                if (questionId.includes('!'))
                    asset.push(assetValue);
            }
        }
        var assetGroupObjectKey = assetGroup + ' | ' + assetType;
        return { asset: asset, assetGroupObjectKey: assetGroupObjectKey };
    };
    /**
     *
     * @param questionID
     */
    FormParserServicesService.prototype.setAssetQuestionId = function (questionID) {
        var index = this.assetQuestionIds.findIndex(function (value) {
            return value == questionID;
        });
        if (index == -1) {
            this.assetQuestionIds.push(questionID);
        }
    };
    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {FormGroup}
     */
    FormParserServicesService.prototype.setAssetFormWithKeys = function (listItemForm, pageNumber, itemCount) {
        this.assetQuestionIds.forEach(function (questionID) {
            var tag = questionID + "_page" + (pageNumber + 1) + "_" + (itemCount + 1);
            var formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
        });
        return listItemForm;
    };
    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {FormGroup}
     */
    FormParserServicesService.prototype.setApplianceFormWithKeys = function (listItemForm, pageNumber, itemCount) {
        this.getApplianceData().forEach(function (appliance) {
            var questionID = appliance['questionID'];
            var tag = questionID + "_page" + (pageNumber + 1) + "_" + (itemCount + 1);
            var formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
        });
        return listItemForm;
    };
    /**
     *
     * @param {FormGroup} listItemForm
     * @param keyLabelArray
     * @param pageNumber
     * @param itemCount
     * @param draftData
     * @param listItemFieldOrderArray
     * @returns {{formGroup: FormGroup; formLabels: {}}}
     */
    FormParserServicesService.prototype.setAssetFormWithKeyLabel = function (listItemForm, keyLabelArray, pageNumber, itemCount, draftData, listItemFieldOrderArray) {
        var _this = this;
        var newFormLabels = {};
        var nonAssetFields;
        if (listItemFieldOrderArray[pageNumber - 1]) {
            if (listItemFieldOrderArray[pageNumber - 1][itemCount - 1]) {
                nonAssetFields = listItemFieldOrderArray[pageNumber - 1][itemCount - 1];
            }
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = [];
        }
        else {
            listItemFieldOrderArray[pageNumber - 1] = {};
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = [];
        }
        keyLabelArray.forEach(function (data) {
            var key = data['questionID'];
            var value = data['fieldLabel'];
            _this.setAssetQuestionId(key);
            var tag = key + "_page" + pageNumber + "_" + itemCount;
            if (tag in draftData) {
                var formControl = new FormControl('');
                listItemForm.addControl(tag, formControl);
                if (draftData[tag].includes('/clientimages/'))
                    newFormLabels[tag] = { label: value, visibleOnList: true, type: 'Photo' };
                else
                    newFormLabels[tag] = { label: value, visibleOnList: true, type: '' };
            }
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1].push(tag);
        });
        if (nonAssetFields)
            listItemFieldOrderArray[pageNumber - 1][itemCount - 1] = listItemFieldOrderArray[pageNumber - 1][itemCount - 1].concat(nonAssetFields);
        return { formGroup: listItemForm, formLabels: newFormLabels };
    };
    /**
     *
     * @param field
     * @param listQuestionId
     */
    FormParserServicesService.prototype.setDependantFields = function (field) {
        if (field['fieldDependencies'] && field['fieldDependencies'].length) {
            this.dependantFields[field['questionID']] = field['fieldDependencies'];
        }
        if (field['dependentQuestions'] && field['dependentQuestions'].length) {
            this.parentDependantFields[field['questionID']] = field['fieldAnswerOptions'];
            if (field['fieldAnswerType'] == 'Checkbox') {
                // Check if this checkbox is already added into the array as it happens when the form is closed
                // and reopened again
                if (this.checkBoxFields.indexOf(field['questionID']) == -1) {
                    this.checkBoxFields.push(field['questionID']);
                }
            }
        }
        if (field['dependentSections'] && field['dependentSections'].length) {
            this.parentDependantFieldsForSection[field['questionID']] = field['fieldAnswerOptions'];
            // Do this even if a section is dependent on a checkbox in another section on same page
            if (field['fieldAnswerType'] == 'Checkbox') {
                // Check if this checkbox is already added into the array as it happens when the form is closed
                // and reopened again
                if (this.checkBoxFields.indexOf(field['questionID']) == -1) {
                    this.checkBoxFields.push(field['questionID']);
                }
            }
        }
    };
    /**
     *
     * @param section
     */
    FormParserServicesService.prototype.setDependantSections = function (section) {
        if (section['fieldDependencies'] && section['fieldDependencies'].length) {
            this.dependantSections[section['sectionID']] = section['fieldDependencies'];
        }
    };
    /**
     *
     * @param page
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     * @returns {Promise<any>}
     */
    FormParserServicesService.prototype.checkDependantFields = function (page, formValue, pageNumber, type, postAppendString) {
        var _this = this;
        if (type === void 0) { type = 'STANDARD'; }
        this.pages = page;
        if (this.pages) {
            if (!this.pageWiseDependantFields[pageNumber]) {
                this.pageWiseDependantFields[pageNumber] = [];
            }
            if (this.pages.fields) {
                this.pages.fields.forEach(function (field) {
                    if (field['questionID'] in _this.dependantFields) {
                        _this.checkFieldDependencies(field, formValue, pageNumber);
                    }
                });
            }
            this.pages.sections.forEach(function (section) {
                if (section['sectionID'] in _this.dependantSections) {
                    if (type == 'STANDARD') {
                        _this.checkSectionDependencies(section, formValue, pageNumber);
                    }
                    else {
                        _this.checkSectionDependencies(section, formValue, pageNumber, type, postAppendString);
                    }
                }
                section.fields.forEach(function (field) {
                    if (field['questionID'] in _this.dependantFields) {
                        if (type == 'STANDARD') {
                            _this.checkFieldDependencies(field, formValue, pageNumber);
                        }
                        else {
                            _this.checkFieldDependencies(field, formValue, pageNumber, type, postAppendString);
                        }
                    }
                });
            });
        }
        return new Promise(function (resolve) {
            resolve(true);
        });
    };
    /**
     *
     * @param field
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     */
    FormParserServicesService.prototype.checkFieldDependencies = function (field, formValue, pageNumber, type, postAppendString) {
        var _this = this;
        if (type === void 0) { type = 'STANDARD'; }
        field['hasDependency'] = true;
        var fieldDependencies = this.dependantFields[field['questionID']];
        var answerIdsObject = {};
        var _loop_1 = function (i) {
            var parentQuestionArray = fieldDependencies[i]['parentQuestions'];
            var _loop_2 = function (j) {
                var answerId = '';
                var answerIdArray = [];
                var parentQuestionId = parentQuestionArray[j]['questionID'];
                var formValueKey = '';
                if (type == 'STANDARD')
                    formValueKey = parentQuestionId;
                else
                    formValueKey = parentQuestionId + postAppendString;
                if (this_1.parentDependantFields[parentQuestionId] && formValueKey in formValue) {
                    this_1.parentDependantFields[parentQuestionId].forEach(function (option) {
                        /** Check box alone requires a different check */
                        if (_this.checkBoxFields.includes(parentQuestionId)) {
                            if (formValue[formValueKey] !== undefined) {
                                var valueArray = formValue[formValueKey].split(',');
                                valueArray.forEach(function (value) {
                                    if (option['optionValue'] == value) {
                                        answerIdArray.push(option['answerID']);
                                    }
                                });
                            }
                        }
                        else {
                            if (option['optionValue'] == formValue[formValueKey]) {
                                answerId = option['answerID'];
                            }
                        }
                    });
                    answerIdsObject[parentQuestionArray[j]['questionID']] = false;
                    /** Check box alone requires a different check */
                    if (this_1.checkBoxFields.includes(parentQuestionId)) {
                        var includeAnswerId_1 = false;
                        answerIdArray.forEach(function (answerId) {
                            if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                                includeAnswerId_1 = true;
                            }
                        });
                        if (includeAnswerId_1)
                            answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                    }
                    else {
                        if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                            answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                        }
                    }
                }
            };
            for (var j = 0; j < parentQuestionArray.length; ++j) {
                _loop_2(j);
            }
        };
        var this_1 = this;
        for (var i = 0; i < fieldDependencies.length; ++i) {
            _loop_1(i);
        }
        field['previousShowStatus'] = field['show'];
        field['show'] = true;
        var index = this.pageWiseDependantFields[pageNumber].findIndex(function (value) {
            return value['id'] == field['questionID'];
        });
        if (index == -1 && field['required'])
            this.pageWiseDependantFields[pageNumber].push({ id: field['questionID'], required: true });
        if (index !== -1) {
            this.pageWiseDependantFields[pageNumber][index] = { id: field['questionID'], required: true };
        }
        index = this.pageWiseDependantFields[pageNumber].findIndex(function (value) {
            return value['id'] == field['questionID'];
        });
        var deleteValue = false;
        if (!Object.keys(answerIdsObject).length) {
            field['show'] = false;
            deleteValue = true;
        }
        for (var _i = 0, _a = Object.entries(answerIdsObject); _i < _a.length; _i++) {
            var _b = _a[_i], answerId = _b[0], value = _b[1];
            if (!value) {
                field['show'] = false;
                deleteValue = true;
            }
        }
        if (this.pages.hasOwnProperty('sections') && fieldDependencies.length) {
            this.pages.sections.forEach(function (sectionRow, sectionKey) {
                if (sectionRow.hasOwnProperty('fields')) {
                    sectionRow.fields.forEach(function (fieldVal, fieldKey) {
                        var checkParentIndex = fieldDependencies[0].parentQuestions.findIndex(function (x) { return x.questionID == fieldVal.questionID; });
                        if (checkParentIndex !== -1 && (fieldVal.hasOwnProperty('show') && !fieldVal.show)) {
                            field.show = false;
                        }
                    });
                }
            });
        }
        if (deleteValue && index != -1) {
            this.pageWiseDependantFields[pageNumber][index]['required'] = false;
        }
    };
    /**
     *
     * @param section
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     */
    FormParserServicesService.prototype.checkSectionDependencies = function (section, formValue, pageNumber, type, postAppendString) {
        var _this = this;
        if (type === void 0) { type = 'STANDARD'; }
        section['hasDependency'] = true;
        var fieldDependencies = this.dependantSections[section['sectionID']];
        var answerIdsObject = {};
        var _loop_3 = function (i) {
            var parentQuestionArray = fieldDependencies[i]['parentQuestions'];
            var _loop_4 = function (j) {
                var answerId = '';
                var answerIdArray = [];
                var parentQuestionId = parentQuestionArray[j]['questionID'];
                var formValueKey = '';
                if (type == 'STANDARD')
                    formValueKey = parentQuestionId;
                else
                    formValueKey = parentQuestionId + postAppendString;
                if (this_2.parentDependantFieldsForSection[parentQuestionId] && formValueKey in formValue) {
                    this_2.parentDependantFieldsForSection[parentQuestionId].forEach(function (option) {
                        /** Check box alone requires a different check */
                        if (_this.checkBoxFields.includes(parentQuestionId)) {
                            if (formValue[formValueKey] !== undefined) {
                                var valueArray = formValue[formValueKey].split(',');
                                valueArray.forEach(function (value) {
                                    if (option['optionValue'] == value) {
                                        answerIdArray.push(option['answerID']);
                                    }
                                });
                            }
                        }
                        else {
                            if (option['optionValue'] == formValue[formValueKey]) {
                                answerId = option['answerID'];
                            }
                        }
                    });
                    answerIdsObject[parentQuestionArray[j]['questionID']] = false;
                    /** Check box alone requires a different check */
                    if (this_2.checkBoxFields.includes(parentQuestionId)) {
                        var includeAnswerId_2 = false;
                        answerIdArray.forEach(function (answerId) {
                            if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                                includeAnswerId_2 = true;
                            }
                        });
                        if (includeAnswerId_2)
                            answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                    }
                    else {
                        if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                            answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                        }
                    }
                }
            };
            for (var j = 0; j < parentQuestionArray.length; ++j) {
                _loop_4(j);
            }
        };
        var this_2 = this;
        for (var i = 0; i < fieldDependencies.length; ++i) {
            _loop_3(i);
        }
        section['show'] = true;
        if (!Object.keys(answerIdsObject).length)
            section['show'] = false;
        for (var _i = 0, _a = Object.entries(answerIdsObject); _i < _a.length; _i++) {
            var _b = _a[_i], answerId = _b[0], value = _b[1];
            if (!value) {
                section['show'] = false;
            }
        }
        if (this.pages.hasOwnProperty('sections') && fieldDependencies.length) {
            this.pages.sections.forEach(function (sectionRow, sectionKey) {
                if (sectionRow.hasOwnProperty('fields')) {
                    sectionRow.fields.forEach(function (field, fieldKey) {
                        var checkParentIndex = fieldDependencies[0].parentQuestions.findIndex(function (x) { return x.questionID == field.questionID; });
                        if (checkParentIndex !== -1 && (sectionRow.hasOwnProperty('show') && !sectionRow.show)) {
                            section.show = false;
                        }
                    });
                }
            });
        }
        if (section['fields']) {
            var fields = section['fields'];
            fields.forEach(function (field) {
                var index = _this.pageWiseDependantFields[pageNumber].findIndex(function (value) {
                    return value['id'] == field['questionID'];
                });
                if (index == -1 && field['required']) {
                    _this.pageWiseDependantFields[pageNumber].push({ id: field['questionID'], required: section['show'] });
                }
                else {
                    _this.pageWiseDependantFields[pageNumber][index] = { id: field['questionID'], required: section['show'] };
                }
            });
        }
    };
    /**
     *
     * @param certificateId
     * @returns {Observable<any>}
     */
    FormParserServicesService.prototype.getCertificatesFieldOptions = function (certificateId) {
        return this.http.get(this.getApiUrl(certificateId + "/getcertificatesfieldoptions"));
    };
    /**
     *
     * @param jobId
     * @returns {Observable<any>}
     */
    FormParserServicesService.prototype.getJobServiceReminders = function (jobId) {
        return this.http.get(this.getApiUrl(jobId + "/getJobServiceReminders"));
    };
    /**
     *
     * @param jobId
     * @returns {Observable<any>}
     */
    FormParserServicesService.prototype.getUserData = function (jobId) {
        return this.http.get(this.getApiUrl(jobId + "/getDiaryUserData"));
    };
    /**
    *
    * @param opportunityId
    * @returns {Observable<any>}
    */
    FormParserServicesService.prototype.getOpportunityUserData = function (opportunityId, type) {
        return this.http.get(this.getApiUrl("sales/getOpportunityDiaryUserData?type=" + type + "&id=" + opportunityId));
    };
    FormParserServicesService.ngInjectableDef = i0.defineInjectable({ factory: function FormParserServicesService_Factory() { return new FormParserServicesService(i0.inject(i1.HttpClient)); }, token: FormParserServicesService, providedIn: "root" });
    return FormParserServicesService;
}(InterfaceService));
export { FormParserServicesService };
