import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HandsontableComponent} from './handsontable/handsontable.component';
import {AppCommonModule} from "@app/shared";

@NgModule({
    declarations: [HandsontableComponent],
    exports: [
        HandsontableComponent
    ],
    imports: [
        CommonModule,
        AppCommonModule
    ]
})

export class HandsontableModule {
    static forRoot() {
        return {
            ngModule: HandsontableModule
        };
    }
}
