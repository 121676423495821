import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    ChangeDetectorRef, AfterViewChecked, ElementRef
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import {from, of} from 'rxjs/index';
import {filter, map} from "rxjs/internal/operators";
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

declare var $;

@Component({
  selector: 'app-column-edit',
  templateUrl: './column-edit.component.html',
  styleUrls: ['./column-edit.component.scss'],
})
export class ColumnEditComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input() columns: any[] = [];
  @Input() isEnableSelect: boolean = false;
  @Input() isEnableActions: boolean = false;
  @Input() saveUrl: string;
  @Input() category: string;

  @Output() onReorderColumn : EventEmitter<any> = new EventEmitter();
  @Output() onShowHideColumn: EventEmitter<any> = new EventEmitter();
  @Output() onSaveColumns: EventEmitter<any> = new EventEmitter();
  @Output() onCloseModal: EventEmitter<any> = new EventEmitter();

  @ViewChild('sortableContainer') sortableContainer: ElementRef;
  @ViewChild(ModalDirective, {
      static: false
  } as any) modal: ModalDirective;
  isReady: boolean=false;
  visibility: boolean = true;
  listStyle: any = {
      width:'auto',
      height: 'auto',
      dropZoneHeight: '20px'
  };
  modalConfig: object = {
      keyboard: true,
      backdrop: false
  };
  showBackdrop:boolean = false;

  isDisableSave: boolean = true;
  //saveUrl: string;

  sortableInstance: Sortable;

  constructor(
      private cdr: ChangeDetectorRef,
      private http: HttpClient) {

  }

  ngAfterViewInit() {
      this.isReady=true;
      this.sortableInstance = Sortable.create(this.sortableContainer.nativeElement,{
          animation: 150,
          easing: "cubic-bezier(1, 0, 0, 1)",
          swapThreshold: 0.65,
          onUpdate: event => {
              const {oldIndex, newIndex} = event;
              const items = Object.assign([], this.columns);
              const item = items[oldIndex];
              items.splice(oldIndex, 1);
              items.splice(newIndex, 0, item);
              this.columns = items;
              this.onChangeColumnItem(items);
              //console.log('items', items, this.columns);
          }
      });
  }

  ngOnInit() {
    //console.log('cols_ini', this.columns)
  }

  showColumnEditor():void {
    this.modal.show();
  }

  onChangeColumnItem(newItems: any) {
      this.isDisableSave=false;
      this.onReorderColumn.emit(newItems);
  }

   onChangeColumnItemModel() {
      if(this.isReady) {
          this.onReorderColumn.emit(this.columns);
      }
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

  onShowHideColumnItem(event: any) {
      this.isDisableSave=false;
      event.visible = !event.visible;
      this.onShowHideColumn.emit(event);
  }

  onSave() {
    this.onSaveColumns.emit({
        modal: this.modal,
        columns: this._paramsTransform()
    });
  }

  _paramsTransform() {
    let newCols = [];
    const filterColumns = from(this.columns)
      .pipe(filter(col => !col.data.startsWith('@') && col.visible))
      .pipe(map( (col, index) => {
          if(col.color){
              return {
                  col_name: col.col_name,
                  pos: index+1,
                  active: col.visible,
                  color:col.color
              }
          }else{
              return {
                  col_name: col.col_name,
                  pos: index+1,
                  active: col.visible
              }
          }
      }));
    filterColumns.subscribe( col => newCols.push(col));
    return newCols;
  }

  paramsTransform() {
    let newCols = [], newParams={};
    const filterColumns = from(this.columns)
      .pipe(filter(col => !col.data.startsWith('@')))
      .pipe(map( (col, index) => {
          return {
              col_name: col.col_name,
              pos: index+1,
              active: col.visible
          }
      }));
    filterColumns.subscribe( col => newCols.push(col));
    return {
        columnValue: JSON.stringify(newCols),
        screenName: this.category
    }
  }

  test() {
      console.log(this.columns)
  }

  handler(type: string, $event: ModalDirective) {
    let $fileUploader = $('div.drag-n-drop-overlays');
    if(type === 'onShown' || type === 'onShow') {
        this.showBackdrop=true;
        if($fileUploader.length) {
            $fileUploader.attr('disabled', true);
            $fileUploader[0].classList.remove('dragover-enabled');
        }
        $('bs-modal-backdrop').on('click', () => {
            this.modal.hide();
            this.onCloseModal.emit(this.modal);
        })
    }
    else if(type === 'onHide' || type === 'onHidden') {
        $('bs-modal-backdrop').off('click');
        this.showBackdrop=false;
        if($fileUploader.length) {
            $fileUploader.removeAttr('disabled');
            $fileUploader[0].classList.add('dragover-enabled');
            $fileUploader[0].classList.remove('upload-prompt-expanding-into-view');
            $fileUploader[0].classList.remove('visible-on-screen');
        }
    }
  }
}

