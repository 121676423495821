import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: '[cs-information-bar]',
  templateUrl: './cs-information-bar.component.html',
  styles: []
})
export class CsInformationBarComponent implements OnInit {
    @Input('showCloseBtn') showCloseBtn = false;
    @Input('information_data') information_data = [];
    @Input('supports_multiple') supports_multiple = false;
    @Input('category')  category;
    @Input('message')  message;
    @Input('icon')  icon;
    @Output() response: EventEmitter<any> = new EventEmitter();
    notification_bar = false;
    single_notification_bar = true;
    information_open = true;

    constructor() { }

    ngOnInit() {
    }

    closeInfobar() {
        this.notification_bar = false;
    }
    toggleInformationView() {
        this.information_open = !this.information_open;
    }

    handleDraftInvoiceAction() {
        this.response.emit({'status' : 'success'});
    }
}
