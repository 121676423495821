<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}} history</h4>
    </div>
    <div class="modal-body ng-workflow-add-block cs-timeline">
        <div class="task-container diagram-container">
            <div class="task-item">
                <div class="task-header">{{startDate.format('dddd, Do MMMM YYYY')}}</div>
                <div class="task-content">
                    <div>Started on {{startDate.format('dddd MMMM YYYY')}} ({{startDate.format('HH:mm:ss')}})</div>
                    <div class="short-content">
                        <div class="d-block">
                            <div class="inline-block"><b>Status</b></div>
                            <div class="inline-block ml-05 ">Running <span class="color-box color-box-sm color-box-status-running rounded"></span></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isTaskOnSameDate" class="item-tail"></div>
            </div>

            <div class="task-item" *ngIf="showFailedStatus">
                <div class="task-header" *ngIf="!isTaskOnSameDate">{{endDate.format('dddd, Do MMMM YYYY')}}</div>
                <div class="task-content">
                    <div>Failed on {{endDate.format('dddd MMMM YYYY')}} ({{endDate.format('HH:mm:ss')}})</div>
                    <div class="short-content mb-10">
                        <div class="d-block">
                            <div class="inline-block"><b>Status</b></div>
                            <div class="inline-block ml-05 ">Failed <span class="color-box color-box-sm color-box-status-failed rounded"></span></div>
                        </div>
                        <div class="d-block" *ngIf="taskItem?.taskParams && taskItem.taskParams['message']">
                            <div class="inline-block"><b>Message</b></div>
                            <div class="inline-block ml-05 ">{{taskItem.taskParams['message']}} </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="task-item" *ngIf="showSkippedStatus">
                <div class="task-header" *ngIf="!isTaskOnSameDate">{{endDate.format('dddd, Do MMMM YYYY')}}</div>
                <div class="task-content">
                    <div>Skipped on {{endDate.format('dddd MMMM YYYY')}} ({{endDate.format('HH:mm:ss')}})</div>
                    <div class="short-content">
                        <div class="d-block">
                            <div class="inline-block"><b>Status</b></div>
                            <div class="inline-block ml-05 ">Skipped <span class="color-box color-box-sm color-box-status-skipped rounded"></span></div>
                        </div>
                        <div class="d-block" *ngIf="taskItem?.taskParams && taskItem.taskParams['message']">
                            <div class="inline-block"><b>Message</b></div>
                            <div class="inline-block ml-05 ">{{taskItem.taskParams['message']}} </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="task-item" *ngIf="showSuccessStatus">
                <div class="task-header" *ngIf="!isTaskOnSameDate">{{endDate.format('dddd, Do MMMM YYYY')}}</div>
                <div class="task-content">
                    <div>Completed on {{endDate.format('dddd MMMM YYYY')}} ({{endDate.format('HH:mm:ss')}})</div>
                    <div class="short-content mb-10">
                        <div class="d-block">
                            <div class="inline-block"><b>Status</b></div>
                            <div class="inline-block ml-05 ">Success <span class="color-box color-box-sm color-box-status-success rounded"></span></div>
                        </div>
                    </div>
                    <div class="truncate-content"  *ngIf="showFullDetails">
                        <a (click)='viewFullDetails(communicationId)'>View full details</a>
                    </div>
                    <div class="block-email-section" *ngIf="emailHidden">
                        <div class="short-content">
                            <div class="d-block">
                                <div class="inline-block">Email sent from: </div>
                                <div class="inline-block ml-05 "><b>{{fromEmail}}</b></div>
                            </div>
                        </div>
                        <div class="short-content mb-10">
                            <div class="d-block">
                                <div class="inline-block"><b>To: </b></div>
                                <div class="inline-block ml-05 ">{{toEmail}}</div>
                            </div>
                            <div class="d-block">
                                <div class="inline-block"><b>CC: </b></div>
                                <div class="inline-block ml-05 ">{{ccEmail}}</div>
                            </div>
                            <div class="d-block">
                                <div class="inline-block"><b>BCC: </b></div>
                                <div class="inline-block ml-05 ">{{bccEmail}}</div>
                            </div>
                        </div>
                        <div class="short-content mb-10">
                            <div class="d-block">
                                <div class="inline-block"><b>Subject: </b></div>
                                <div class="inline-block ml-05 " [innerHTML]="subject"></div>
                            </div>
                        </div>
                        <div class="short-content mb-10">
                            <div class="d-block">
                                <div class="inline-block" [innerHTML]="message | keepHtml"></div>
                            </div>
                        </div>
                        <div class="short-content">
                            <div class="d-block">
                                <div class="inline-block"><b>Email status: </b></div>
                                <div class="inline-block ml-05 ">{{status}}</div>
                            </div>
                        </div>
                        <div class="short-content">
                            <div class="d-block">
                                <div class="inline-block"><b>Email sentby: </b></div>
                                <div class="inline-block ml-05 ">{{sentBy}}</div>
                            </div>
                        </div>
                        <div class="truncate-content"  *ngIf="showLessDetails">
                            <a (click)='viewLessDetails()'>Show less</a>
                        </div>
                    </div>
                    <div class="block-sms-section"  *ngIf="smsHidden">
                        <div *ngFor="let taskDetail of taskDetails">
                            <div class="short-content mb-10" >
                                <div class="d-block">
                                    <div class="inline-block"><b>To: </b></div>
                                    <div class="inline-block ml-05 ">{{taskDetail.mobileNumber}}</div>
                                </div>
                                <div class="d-block">
                                    <div class="inline-block"><b>Status: </b></div>
                                    <div class="inline-block ml-05 ">{{taskDetail.status}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="short-content mb-10">
                            <div class="d-block">
                                <div class="inline-block" [innerHTML]="message | keepHtml"></div>
                            </div>
                        </div>
                        <div class="truncate-content"  *ngIf="showLessDetails">
                            <a (click)='viewLessDetails()'>Show less</a>
                        </div>
                    </div>
                </div>
                <!--<div class="item-tail"></div>-->
            </div>
        </div>
        <!--<ul class="timeline">-->
        <!--<li>-->
        <!--<div class="timeline-time">-->
        <!--<span class="date">26/04/2021</span>-->
        <!--<span class="time">04:20</span>-->
        <!--</div>-->
        <!--<div class="timeline-icon">-->
        <!--<a href="javascript:;">&nbsp;</a>-->
        <!--</div>-->
        <!--<div class="timeline-body"></div>-->
        <!--</li>-->
        <!--<li>-->
        <!--<div class="timeline-time">-->
        <!--<span class="date">26/04/2021</span>-->
        <!--<span class="time">04:20</span>-->
        <!--</div>-->
        <!--<div class="timeline-icon">-->
        <!--<a href="javascript:;">&nbsp;</a>-->
        <!--</div>-->
        <!--<div class="timeline-body"></div>-->
        <!--</li>-->
        <!--</ul>-->
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Close</a>
    </div>
</ng-container>
