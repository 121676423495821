<form *ngIf="invoiceForm" [formGroup]="invoiceForm" (submit)="save()">
    <!-- Header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>

    <!-- Content -->
    <div class="modal-body">
        <!-- <div class="help_section">
      <div class="help-text" translate>
        category.help.text
      </div>
    </div> -->
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">

                    <!-- DEPOSIT -->
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>Deposit</label>
                        <div class="controls">
                            <input type="number" (input)='input()' class="control-input" formControlName="deposit">
                        </div>
                    </div>
                    <!-- ON START JOB -->
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>On start job</label>
                        <div class="controls">
                            <input type="number" (input)='input()' class="control-input" formControlName="on_start_job">
                        </div>
                    </div>

                    <!-- ON COMPLETE JOB -->
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>On completion</label>
                        <div class="controls">
                            <input type="number" (input)='input()' class="control-input"
                                formControlName="on_completion">
                        </div>
                        <div *ngIf='error'>
                            <span class="control-error-message show">
                                <span translate>invoice.warning</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer -->
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        <!-- || action == 'saving' -->
        <button [disabled]="invoiceForm.invalid || error" class="btn btn-primary">
            <span translate>Save</span>
            <!-- <span [hidden]="action == 'save'" translate>Saving</span> -->
        </button>
    </div>
</form>