import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
//--
import { InterfaceClass } from './class.interface';
import { GetApiUrl } from '../utils';
import { GetContractApiUrl } from "@app/utils/get-contract-api-url";
import { GetJobApiUrl } from "@app/utils/get-job-api-url";
var InterfaceService = /** @class */ (function (_super) {
    tslib_1.__extends(InterfaceService, _super);
    function InterfaceService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Creates http options for the data type
    InterfaceService.prototype.getHttpOptions = function (type, auth) {
        if (type === void 0) { type = 'json'; }
        if (auth === void 0) { auth = true; }
        var headers = new HttpHeaders({});
        // headers = headers.append('Timezone', 'Asia/Kolkata');
        switch (type) {
            case "json": {
                // headers = headers.append('Content-Type', 'application/json');
                break;
            }
        }
        return {
            headers: headers
        };
    };
    InterfaceService.prototype.getApiUrl = function (path, params) {
        return GetApiUrl(path, params);
    };
    InterfaceService.prototype.getContractApiUrl = function (path, params) {
        return GetContractApiUrl(path, params);
    };
    InterfaceService.prototype.getJobApiUrl = function (path, params) {
        return GetJobApiUrl(path, params);
    };
    return InterfaceService;
}(InterfaceClass));
export { InterfaceService };
