import { FormGroup } from '@angular/forms';
var CustomTagFormComponent = /** @class */ (function () {
    function CustomTagFormComponent() {
    }
    Object.defineProperty(CustomTagFormComponent.prototype, "isNotValid", {
        get: function () { return !this.form.controls[this.tag.key].valid && this.form.controls[this.tag.key].dirty; },
        enumerable: true,
        configurable: true
    });
    ;
    return CustomTagFormComponent;
}());
export { CustomTagFormComponent };
