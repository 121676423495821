import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OpportunityService = /** @class */ (function (_super) {
    tslib_1.__extends(OpportunityService, _super);
    function OpportunityService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /**
     *
     * @param {object} params
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getPipelines = function (params) {
        return this.http.get(this.getApiUrl("listPipelines", params));
    };
    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getPipelineStages = function (pipelineId) {
        return this.http.get(this.getApiUrl("sales/getPipelineStages/" + pipelineId));
    };
    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getPipelineOpportunities = function (pipelineId) {
        return this.http.get(this.getApiUrl("sales/getPipelineOpportunities/" + pipelineId));
    };
    /**
     *
     * @param pipelineId
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getAcceptedOpportunities = function (pipelineId) {
        return this.http.get(this.getApiUrl("sales/getAcceptedOpportunities/" + pipelineId));
    };
    /**
     *
     * @param data
     * @param screen
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.updateConfirmationTemplateData = function (data, screen) {
        return this.http.post(this.getApiUrl("sales/updateConfirmationTemplate?screen=" + screen), data);
    };
    /**
     *
     * @param screen
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getConfirmationTemplate = function (screen) {
        return this.http.get(this.getApiUrl("sales/getConfirmationTemplate?screen=" + screen));
    };
    /**
     * Get supplier parts quotation message
     * @returns {Observable<any>}
     */
    OpportunityService.prototype.getSupplierPartsQuotationMessage = function () {
        return this.http.get(this.getApiUrl("get_opportunity_supplier_parts_quotation_message"));
    };
    /**
     *
     * @param payload
     * @returns {Observable<Object>}
     */
    OpportunityService.prototype.emailPartListToSuppliers = function (payload) {
        return this.http.post(this.getApiUrl("emailpartlisttosuppliers"), payload);
    };
    OpportunityService.prototype.getOpportunitiesFilter = function () {
        return this.http.get(this.getApiUrl("sales/getOpportunitiesFilter"));
    };
    OpportunityService.prototype.getPipelineStageOpportunities = function (payload, pipelineId) {
        return this.http.post(this.getApiUrl("sales/getPipelineStageOpportunities/" + pipelineId), payload);
    };
    OpportunityService.prototype.getAcceptedOpportunitiesByStage = function (payload, pipelineId) {
        return this.http.post(this.getApiUrl("sales/getAcceptedOpportunitiesByStage/" + pipelineId), payload);
    };
    OpportunityService.prototype.getOpportunitiesViewsList = function () {
        return this.http.get(this.getApiUrl("sales/getOpportunitiesViewsList"));
    };
    OpportunityService.prototype.updateSupplierTemplateData = function (data) {
        return this.http.post(this.getApiUrl('updateSupplierTemplateData'), data);
    };
    OpportunityService.ngInjectableDef = i0.defineInjectable({ factory: function OpportunityService_Factory() { return new OpportunityService(i0.inject(i1.HttpClient)); }, token: OpportunityService, providedIn: "root" });
    return OpportunityService;
}(InterfaceService));
export { OpportunityService };
