<div class="modal-header">
    <h4 class="modal-title pull-left">Select conditional field</h4>
</div>
<div class="modal-body">
    <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <app-condition-edge *ngIf="isConditional"
        [inputModel]="inputModel"
        [operators]="operators"
        [isMarketPlaceView]="isMarketPlaceView"
        [isReadOnlyMode]="isReadOnlyMode"
        [activeField] = "activeField">
    </app-condition-edge>

    <div class="item form-item p-15">
        <div class="control">
            <label class="control-label required_field">Add conditional field</label>
            <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                [disabled]="isMarketPlaceView || isEdit && hasChild"
                csSelect2
                [select2Config]="{}"
                [dropdownvalue]="inputModel.model.col_name"
                [(ngModel)]="inputModel.model.col_name">
                <option value="" translate>Please.Choose</option>
                <option *ngFor="let field of inputModel.config.fields" [value]="field.id">{{field.text}}</option>
                <optgroup *ngFor="let template of  inputModel.config['customFields']" [label]="'Custom fields: '+template['description']">
                    <option *ngFor="let field of template['fields']" [value]="field.col_name">{{field.text}}</option>
                </optgroup>
            </select>
        </div>

        <div class="information-bar" *ngIf="isEdit && hasChild">
            <div class="py-10">This block cannot be edited because there are dependent blocks based on the values of the field selected here.<span *ngIf="!isMarketPlaceView"> If you want to delete this, please delete all the dependent blocks and then delete this.</span></div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [disabled]="status" [hidden]="!showDeleteBtn"  class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
    <button class="btn btn-primary" [disabled]="saving || validateItems()" (click)="addValue()">
        <span *ngIf="!saving" translate>Save</span>
        <span *ngIf="saving" translate>Saving</span>
    </button>
</div>
