import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    Inject,
    forwardRef,
    ViewChild,
    HostBinding
} from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsDaterangepickerConfig, BsDaterangepickerDirective } from "ngx-bootstrap";
import {SettingsService} from "@app/core";

declare var _: any;
@Component({
    selector: 'cs-datepicker',
    templateUrl: './cs-datepicker.component.html',
    styles: [],
})

export class CsDatepickerComponent implements OnInit {

    @HostBinding('class') class = 'w-100';
    @Input('placeholder') placeholder: string;
    @Input('type') datePickerType = 'default';
    @Input('minDate') minDate?: Date;
    @Input('maxDate') maxDate?: Date;
    @Input('container') container?: string;
    @Input() isDpDisabled = false;
    @Input() disabledDates: Date[];
    @Input() isHideWeekNumbers = false;
    @Input() isReadOnly = false;    // Restrict only input events (Ex: Typing into the input box)
    @Input() initialDateVal: Date;
    @Input() set defaultValue(value: Date) {
        if (value) this.setFormattedDate(value);
    }
    @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();
    @Output() pickerShown = new EventEmitter<any>();
    @ViewChild('dataPicker') dataPicker: BsDatepickerDirective;
    @ViewChild('daterangepicker') daterangepicker: BsDaterangepickerDirective;

    customBsConfig?: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    customBsRangeConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    formattedDate: Date;
    formattedDateRange: Date;
    format = 'd-m-y';
    formats = [
        {'id' : 0, 'format' : 'DD/MM/YY'},
        {'id' : 1, 'format' : 'DD/MM/YYYY'},
        {'id' : 2, 'format' : 'DD/MMM/YY'},
        {'id' : 3, 'format' : 'DD/MMM/YYYY'},
        {'id' : 4, 'format' : 'DD-MM-YY'},
        {'id' : 5, 'format' : 'DD-MM-YYYY'},
        {'id' : 6, 'format' : 'DD-MMM-YY'},
        {'id' : 7, 'format' : 'DD-MMM-YYYY'},
        {'id' : 50, 'format' : 'MM/DD/YY'},
        {'id' : 51, 'format' : 'MM/DD/YYYY'},
        {'id' : 52, 'format' : 'MMM/DD/YY'},
        {'id' : 53, 'format' : 'MMM/DD/YYYY'},
        {'id' : 54, 'format' : 'MM-DD-YY'},
        {'id' : 55, 'format' : 'MM-DD-YYYY'},
        {'id' : 56, 'format' : 'MMM-DD-YY'},
        {'id' : 57, 'format' : 'MMM-DD-YYYY'}
    ];
    constructor(@Inject(forwardRef(() => SettingsService)) private settings) {
        this.bsConfigInit();
    }

    ngOnInit() {
        if (this.initialDateVal) {
            this.setFormattedDate(this.initialDateVal);
        }
        this.customBsConfig.showWeekNumbers = !this.isHideWeekNumbers;
        this.customBsRangeConfig.showWeekNumbers = !this.isHideWeekNumbers;
        this.customBsConfig.adaptivePosition = true;
        this.customBsRangeConfig.adaptivePosition = true;
        if(this.maxDate){
            this.customBsConfig.maxDate = new Date();
            this.customBsRangeConfig.maxDate = new Date();
        }
    }

    bsConfigInit(){
        const intlSettings = this.settings._csIntlSettings;
        const dateFormat = _.findWhere(this.formats, {'id' : parseInt(intlSettings['short_date']) });
        if (dateFormat) {
            this.format = dateFormat['format'];
        }
        if (this.datePickerType === 'inline') {
            this.customBsConfig.showWeekNumbers = false;
        }
        this.customBsConfig.dateInputFormat = this.format;
        this.customBsRangeConfig.rangeInputFormat = this.format;
    }

    /** BS Datepicker config changes */
    onDateChanges(date) {
        this.selectedDate.emit({'startDate': new Date(date), 'endDate': '' });

    }

    setFormattedDate(value) {
        if (this.datePickerType === 'inline' || this.datePickerType === 'default') {
            this.formattedDate = typeof value === 'string' ? new Date(value) : value;
        } else {
            value[0] = typeof value[0] === 'string' ? new Date(value[0]) : value[0];
            value[1] = typeof value[1] === 'string' ? new Date(value[1]) : value[1];
            this.formattedDateRange = value;
        }
    }

    setBsDateConfigChange() {
        this.dataPicker.setConfig();
    }

    /** BS Date range picker config changes */
    onDateRangeChanges(date) {
        this.setBsDateRangeConfigChange();
        this.selectedDate.emit({'startDate': new Date(date[0]), 'endDate': new Date(date[1]) });
    }

    setBsDateRangeConfigChange() {
        this.daterangepicker.setConfig();
    }

    getMinDate() {
        return this.customBsConfig.minDate;
    }

    setMaxDate(maxDate) {
        this.customBsConfig.maxDate = new Date(maxDate);
        this.setBsDateConfigChange();
    }

    setMinDate(minDate) {
        this.customBsConfig.minDate = new Date(minDate);
        this.setBsDateConfigChange();
    }

    getMaxDate() {
        return this.customBsConfig.maxDate;
    }

    onPickerShown(event) {
        this.pickerShown.emit(event);
    }
}

