import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

//--
import {FeaturesRouting} from './features.routing';
import {
    SettingsGrantTemplatesService
} from "@app/features/system-settings/grants-templates/services/grants-templates.service";
import {AppCommonModule} from "@app/shared";
import {SmartTableModule} from "@app/smart_table/smarttable.module";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FeaturesRouting,
        AppCommonModule,
        // SmartTableModule
    ],
    exports: [
    ],
    providers: [SettingsGrantTemplatesService]
})
export class FeaturesModule {
}
