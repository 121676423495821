<ng-container *ngIf="datePickerType == 'inline'">
    <bs-datepicker-inline
        [bsConfig]="customBsConfig"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [bsValue]="formattedDate"
        [isDisabled]="isDpDisabled"
        (bsValueChange)="onDateChanges($event)">
    </bs-datepicker-inline>
</ng-container>

<ng-container *ngIf="datePickerType == 'default'">
    <div class="dp-wrapper cs-d-flex">
        <input
            #dataPicker="bsDatepicker"
            bsDatepicker
            class="bsdatepicker-input cs-flex-1"
            placeholder="{{placeholder}}"
            type="text"
            [(ngModel)]="formattedDate"
            (ngModelChange)="onDateChanges($event)"
            [minDate]="minDate"
            [container]="container"
            [maxDate]="maxDate"
            [bsConfig]="customBsConfig"
            [placement]="'bottom'"
            [disabled]="isDpDisabled"
            [datesDisabled]="disabledDates"
            [readonly]="isReadOnly"
            (onShown)="onPickerShown($event)">
        <span
            (click)="!isDpDisabled && dataPicker.toggle()"
            class="add-on date-picker-edit cursor-pointer"
            [ngClass]="{'cursor-not-allowed': isDpDisabled}">
            <span class="ss-calendar"></span>
        </span>
    </div>
</ng-container>

<ng-container *ngIf="datePickerType == 'range'">
    <div class="dp-wrapper cs-d-flex">
        <input
            #daterangepicker="bsDaterangepicker"
            bsDaterangepicker
            placeholder="{{placeholder}}"
            class="bsdatepicker-input cs-flex-1"
            type="text"
            [(ngModel)]="formattedDateRange"
            (ngModelChange)="onDateRangeChanges($event)"
            [bsConfig]="customBsRangeConfig"
            [disabled]="isDpDisabled"
            [datesDisabled]="disabledDates">
        <span
            (click)="!isDpDisabled && daterangepicker.toggle()"
            class="add-on date-picker-edit cursor-pointer"
            [ngClass]="{'cursor-not-allowed': isDpDisabled}">
            <span class="ss-calendar"></span>
        </span>
    </div>
</ng-container>

