import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef, RatingComponent } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { environment } from '../../../../environments/environment';

declare var _:any;
declare var $: any;
@Component({
	selector: 'app-upsell-parts',
	templateUrl: './upsell-parts.component.html',
	styleUrls: ['../pricing-section-usepartkit/pricing-section-usepartkit.component.scss'],
})
export class UpsellPartsComponent implements OnInit {
	type: any;
	partList: any[] = [];
	existingParts : any[] = [];
	upsellForm: FormGroup;
	bulletIn: FormArray;
	select2Config: any;
	select2RateConfig: any;
	labourRatesList: any;
	upsellPartId: any;
	action = 'save';
	select2: any;
	bulletIns : any;
	formLoaded : boolean = false;
    taxLists: any;
    formattedTaxLists : any;
    vatLabel: any;
    vatEnabled: any;
	upsellFormData = {
		"id" : null,
		"description" : "",
		"part" : "",
		"unitCost" : "",
		"salePrice" : "",
		"labourTime" : "",
		"labourRate" : "",
        "vatRate" : "",
        "labourVatRate" : "",
		"bulletIns" : ""
	}
	public onClose: Subject<{}>;
	constructor(
		@Inject('confirmationBoxHelper') private confirmationBoxHelper,
		@Inject('$scope') private ajsScope,
		private fb: FormBuilder,
		public proposalTemplateService: ProposalTemplateService,
		public bsModalRef: BsModalRef,
	) { }

	ngOnInit() {
		if(this.type == 'edit'){
			this.proposalTemplateService.getPartUpsellData(this.upsellPartId).subscribe(
				response => {
					this.upsellFormData = response['upsellData'];
					this.partList = response['partData'];
					this.buidlUpsellForm(JSON.parse(this.upsellFormData.bulletIns));
				}
			)
		}else{
			this.buidlUpsellForm();
		}

		this.select2RateConfig = {
			width: '100%'
		};
        this.formattedTaxLists = this.formatTaxItems(this.taxLists);
		this.onClose = new Subject();
	}

    bulletInArray : any ;
	buidlUpsellForm(bulletIn = []){
		let bullets;
		let existData = this.existingParts.join();
		if(this.type == 'edit'){
			existData = existData +','+this.partList[0].id;
			bullets = new FormArray([]);
            this.bulletInArray = JSON.parse(this.upsellFormData.bulletIns);
			// this.upsellFormData.bulletIns = JSON.parse(this.upsellFormData.bulletIns);
			// bulletIn.forEach(element => {
			// 	bullets.push(this.getBulletIn());
			// });
		}

        const bulletInsFormArray = new FormArray([
            new FormControl(this.type === 'edit' && this.bulletInArray[0] !== undefined ? this.bulletInArray[0]['bullet-in'] : '', Validators.required),
            new FormControl(this.type === 'edit' && this.bulletInArray[1] !== undefined ? this.bulletInArray[1]['bullet-in'] : ''),
            new FormControl(this.type === 'edit' && this.bulletInArray[2] !== undefined ? this.bulletInArray[2]['bullet-in'] : ''),
        ]);

		this.upsellForm = this.fb.group({
			'description': [ this.upsellFormData.description, Validators.required],
			'part': [ this.upsellFormData.part, Validators.required],
			'unitCost' : [ this.upsellFormData.unitCost, Validators.required],
			'salePrice' : [ this.upsellFormData.salePrice, Validators.required],
			'labourTime' : [ this.upsellFormData.labourTime, Validators.required],
			'labourRate' : [ this.upsellFormData.labourRate, Validators.required],
            'vatRate' : [ this.upsellFormData.vatRate, Validators.required],
            'labourVatRate' : [ this.upsellFormData.labourVatRate, Validators.required],
            'bulletIns': bulletInsFormArray
		});



		this.select2Config = {
			minimumInputLength: 2,
			width: '100%',
			initSelection: () => {},
			ajax: {
				url : environment.apiBasePath+'getParstAndPricesList',
				data: (searchText) => {
				  if (searchText == '') {
					return false;
				  }
				  let newQueries = {};
				  newQueries['searchText'] = encodeURIComponent(searchText);
				  newQueries['existingPartIds'] = existData;
				  return newQueries;
				},
				results: (data) => {
				  return { results: data }
				},
			  },
			  formatResult: function(item, container, query, escapeMarkup) {
				var splitSearchTerm = query.term.split(" ");
				splitSearchTerm.forEach((v, k) => {
					splitSearchTerm[k] = "(" + v + ")";
				});
				let regex = new RegExp(splitSearchTerm.join("|"), 'gi');
				var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });
				if(item.manufacturepartnumber){
					htmlresult += ', '+ 'MPN' + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
				}
				return htmlresult;
			},

		};
		if(this.type == 'edit'){
			// this.upsellForm.get('bulletIns').patchValue(this.upsellFormData.bulletIns);
			setTimeout(() => {
				$(".select-part").select2('data', this.partList[0]);
			}, 200);
		}

		this.formLoaded = true;
	}

    get inputs() {
        return this.upsellForm.get('bulletIns') as FormArray;
    }

	getBulletIn(): FormGroup{
		return this.fb.group({
			"bullet-in": new FormControl('',Validators.required)
		  });
	  }

	addBulletinFormGroup(event,index) {
		this.bulletIn = this.upsellForm.get('bulletIns') as FormArray;
		let lastIndex = this.bulletIn.length - 1;
		if ((index == lastIndex) && (index < 2)) {
			let formGroup = this.fb.group({
				"bullet-in": new FormControl('')
			});
			this.bulletIn.push(formGroup);
			this.upsellForm.updateValueAndValidity();
		}
	}

	deleteBulletin(index){
		let formArray = this.upsellForm.get('bulletIns') as FormArray;
		formArray.removeAt(index);
		this.upsellForm.updateValueAndValidity();
	}

	saveParts() {
        const RemoveEmptyBullet = [];
		this.action = 'saving';
		let result = this.upsellForm.value;
        for (let i = 0; i < result.bulletIns.length; i++) {
           const isEmpty = Object.values(result.bulletIns[i].trim()).every(element => element === null || element === '');
            if (isEmpty == false) {
                RemoveEmptyBullet.push({'bullet-in' : result.bulletIns[i]});
            }
        }
		result['bulletIns'] = JSON.stringify(RemoveEmptyBullet);
		result['pricingPartsCostId'] = this.upsellPartId;
		result['id'] = this.upsellFormData.id;
		this.proposalTemplateService.addOrEditUpsellParts(result).subscribe(
			response => {
				this.onClose.next(this.upsellForm.value);
				this.bsModalRef.hide();
                let msg = {
                    type: 'upgradeFreemium'
                };
                top.postMessage(msg, '*');
			},
			error => {
				console.log(error);
			}
		);
	}

	removeUpsell(){
		let that = this;
		this.confirmationBoxHelper.getConfirmation('Are you sure you want to remove upsell?', that.ajsScope)
			.then(function () {
				that.removePartUpsell();
			}, function () {
                return false;
            });
	}

	removePartUpsell(){
		this.proposalTemplateService.removePartUpsell(this.upsellFormData.id).subscribe(
			response => {
				this.onClose.next(true);
				this.bsModalRef.hide();
			},
			error => {
				console.log(error);
			}
		)
	}

    updatePrices(selectedPart) {
	    if(selectedPart > 0) {
            this.proposalTemplateService.getSelectedPart(selectedPart).subscribe(response => {
                let labourRate = response['labourRate'];
                let vatRate = response['vatRate'];
                let labourVatRate = response['labourVatRate'];
                this.upsellForm.get('unitCost').setValue(response['unitCost']);
                this.upsellForm.get('salePrice').setValue(response['salePrice']);
                this.upsellForm.get('labourTime').setValue(response['labourTime']);
                this.upsellForm.get('labourRate').setValue(labourRate);
                this.upsellForm.get('vatRate').setValue(vatRate);
                this.upsellForm.get('labourVatRate').setValue(labourVatRate);
                if(labourRate > 0) {
                    $('#triggerLabourRate').val(labourRate).select2();
                }else {
                    $('#triggerLabourRate').val('').select2();
                }
                if(vatRate > 0) {
                    $('#triggerVatRate').val(vatRate).select2();
                }else {
                    $('#triggerVatRate').val('').select2();
                }
                if(labourVatRate > 0){
                    $('#triggerLabourVatRate').val(labourVatRate).select2();
                } else {
                    $('#triggerLabourVatRate').val('').select2();
                }
            });
        }
    }
    formatTaxItems(taxData){
        let tax = [];
        const taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach((province) => {
            let taxLabel = province[0]['taxProvinceName'];
            tax.push({'text': taxLabel, 'children': province});
        });
        return tax;
    }

}
