// Navigatiing into AngularJs routes without reload the page
export function navigateToAjs(path: string, params?: Object) {
    if (params instanceof Object) {
        path += '?';
        Object.entries(params).forEach(([param, value]) => {
            path += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
        })
    }
    let anchor = document.getElementById('navigator');
    if(anchor) {
        anchor.setAttribute('href', path);
        setTimeout(() => anchor.click(), 10);
    }
}
