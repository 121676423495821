import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
// --
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PriceBooksService = /** @class */ (function (_super) {
    tslib_1.__extends(PriceBooksService, _super);
    function PriceBooksService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Get groups configured
    PriceBooksService.prototype.getPriceBookList = function (params) {
        return this.http
            .get(this.getApiUrl("listPriceBooks", params));
    };
    PriceBooksService.prototype.addOrEditPriceBooks = function (params) {
        if (params['id'] === '') {
            return this.http.post(this.getApiUrl("system_settings/price_books/save"), JSON.stringify(params));
        }
        else {
            return this.http.post(this.getApiUrl("system_settings/price_books/" + params['id'] + "/update"), JSON.stringify(params));
        }
    };
    PriceBooksService.prototype.clonePriceBooks = function (params) {
        return this.http.post(this.getApiUrl("system_settings/clone_price_books"), JSON.stringify(params));
    };
    PriceBooksService.prototype.checkUniqueName = function (params) {
        return this.http
            .get(this.getApiUrl("priceBooksExists", params));
    };
    PriceBooksService.prototype.getPricingItems = function (params) {
        return this.http
            .get(this.getApiUrl("listPricingItems", params));
    };
    PriceBooksService.prototype.listConfiguredPricingItems = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/price_books/" + params + "/configure/pricing_items"), params);
    };
    PriceBooksService.prototype.listConfiguredLabourRates = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/price_books/" + params + "/configure/labour_rates"), params);
    };
    PriceBooksService.prototype.listLabourRates = function (params) {
        return this.http
            .get(this.getApiUrl("listPriceBookLabourRates", params)).pipe(map(function (Response) { return Response; }));
    };
    PriceBooksService.prototype.saveLabourRateLineItems = function (templateId, data) {
        return this.http.post(this.getApiUrl("system_settings/price_books/" + templateId + "/configure/manage_labour_rates"), JSON.stringify(data));
    };
    PriceBooksService.prototype.deletePriceBookLR = function (params) {
        return this.http
            .delete(this.getApiUrl("delete_PB_labour_rates/" + params), params);
    };
    PriceBooksService.prototype.deletePriceBookPI = function (params) {
        return this.http
            .delete(this.getApiUrl("delete_PB_pricing_items/" + params), params);
    };
    PriceBooksService.prototype.savePricingItemPb = function (templateId, data) {
        return this.http.post(this.getApiUrl("system_settings/price_books/" + templateId + "/configure/add_edit_pricing_item_PB"), JSON.stringify(data));
    };
    PriceBooksService.prototype.beforeDelete = function (id) {
        return this.http
            .get(this.getApiUrl("canDeletePriceBooks/" + id));
    };
    PriceBooksService.prototype.deletePriceBooksRequest = function (params) {
        return this.http
            .post(this.getApiUrl("delete_priceBooks"), params);
    };
    PriceBooksService.prototype.checkPassword = function (params) {
        return this.http
            .post(this.getApiUrl("validUserPassword?___noPageloading=true"), params);
    };
    PriceBooksService.prototype.checkExistsUsername = function (query) {
        return this.http
            .get(this.getApiUrl("validUserName?___noPageloading=true&username=" + query));
    };
    PriceBooksService.ngInjectableDef = i0.defineInjectable({ factory: function PriceBooksService_Factory() { return new PriceBooksService(i0.inject(i1.HttpClient)); }, token: PriceBooksService, providedIn: "root" });
    return PriceBooksService;
}(InterfaceService));
export { PriceBooksService };
