import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {customerOrLandlordInfo} from "@app/features/customers/jobs/add-edit-certificate/add-edit-certificate.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddEditCertificateService, CsToastBoxService} from "@app/services";
import {DynamicCertificateFormParserComponent} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/dynamic-certificate-form-parser.component";

@Component({
  selector: 'app-certificate-page-container',
  templateUrl: './certificate-page-container.component.html',
  styles: []
})
export class CertificatePageContainerComponent implements OnInit {


    customerOrLandlordForm: FormGroup;
    currentCertificateData: currentCertificateData = {
        jobId: null,
        settingsCertificateId: null,
        draftJsonData: null,
    };
    isFormValid: boolean = true;
    formValue: any;
    lastSavedPageIndex: number = -1;
    // page index -1 refers to the customer/landlord page
    @Input('currentPageIndex') pageIndex: number = -1;
    @Input('draftData') draftData: any = {};
    @Input('lookupId') lookupId: number;
    @Input('customerOrLandlord') customerOrLandlord : string = 'Customer';
    @Input('formData') formData: any;
    @Input('customerOrLandlordInfo') customerOrLandlordInfo: customerOrLandlordInfo = {
        showLandlord: false,
        customerInfo: [],
        landlordInfo: [],
        jobId: null,
        settingsCertificateId: null
    };
    @Input('userName') userName: string;
    @Input('status') status: string;
    @Output('closeModal') valueEmitter = new EventEmitter();
    @ViewChild(DynamicCertificateFormParserComponent) dynamicCertificateFormParserComponent: DynamicCertificateFormParserComponent;

    constructor(private fb: FormBuilder,
                private toastBox: CsToastBoxService,
                @Inject('confirmationBoxHelper') private confirmationBoxHelper,
                @Inject('$scope') private ajsScope,
                private addEditCertificateService: AddEditCertificateService) {
    }

    ngOnInit() {
        this.customerOrLandlordForm = this.fb.group({
            customerownership: [this.customerOrLandlord, Validators.required]
        });
    }

    ngOnChanges(changes){
        if(changes['formData']){
            this.dynamicCertificateFormParserComponent.setFormData(this.formData);
        }
        if(changes['customerOrLandlord']){
            if(this.customerOrLandlordForm && this.customerOrLandlordForm.get('customerownership'))
                this.customerOrLandlordForm.get('customerownership').setValue(this.customerOrLandlord);
        }
        if(changes['pageIndex']){
            this.lastSavedPageIndex = this.pageIndex-1;
        }
    }

    selectPage(index) {
        if(!this.isFormValid){
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        if((this.lastSavedPageIndex+1 >= index && this.status == 'DRAFT') || this.status == 'ACTIVE'){
            this.pageIndex = index;
            this.dynamicCertificateFormParserComponent.selectPage(index);
            if(index == -1)
                this.isFormValid = true;
        }
    }

    gotoNextPage() {
        this.pageIndex++;
    }

    saveAndContinue(saveAndContinue:boolean = true) {
        if(!this.isFormValid && saveAndContinue) return;
        if(!this.isFormValid && !saveAndContinue){
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        let params = {};
        if (this.lookupId) {
            params['lookupId'] = this.lookupId;
        }
        this.currentCertificateData.jobId = this.customerOrLandlordInfo.jobId;
        this.currentCertificateData.settingsCertificateId = this.customerOrLandlordInfo.settingsCertificateId;
        let draftArray: draftArray = {
            customerownership: this.customerOrLandlordForm.value.customerownership,
            jsonDraft: this.formValue,
            currentPageIndex: saveAndContinue? this.pageIndex: this.pageIndex,
        };
        this.currentCertificateData.draftJsonData = draftArray;
        this.addEditCertificateService.setCertificateJson(this.currentCertificateData, params).subscribe((res) => {
            this.toastBox.show('Certificate updated', 3000);
            if(saveAndContinue){
                this.lookupId = res['lookupId'];
                this.draftData = res['jsonDraft'];
                this.pageIndex++;
                if(this.pageIndex > this.lastSavedPageIndex+1)
                    this.lastSavedPageIndex++;
                this.dynamicCertificateFormParserComponent.selectPage(this.pageIndex);
            }else{
                this.valueEmitter.emit();
            }
        }, error1 => {
            this.toastBox.show('Failed to save', 3000);
        });
    }

    getFormStatusChanges(isFormValid){
        this.isFormValid = isFormValid;
    }

    getFormValueChanges(formValue){
       this.formValue = formValue;
    }

    saveAndActivate(){
        if(!this.isFormValid){
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        this.currentCertificateData.jobId = this.customerOrLandlordInfo.jobId;
        this.currentCertificateData.settingsCertificateId = this.customerOrLandlordInfo.settingsCertificateId;
        let draftArray: draftArray = {
            customerownership: this.customerOrLandlordForm.value.customerownership,
            jsonDraft: this.formValue,
            currentPageIndex: -1,
        };
        this.currentCertificateData.draftJsonData = draftArray;
        this.addEditCertificateService.setCertificateDataAndActivate(this.lookupId, this.currentCertificateData).subscribe(
            response => {
                if(!response['error']){
                    this.toastBox.show('Certificate activated', 3000);
                    this.valueEmitter.emit();
                }else{
                    this.toastBox.show('Failed activate certificate', 3000);
                }
            }, error1 => {
                this.toastBox.show('Failed activate certificate', 3000);
            }
        )
    }

    closeModal_(){
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to cancel?', this.ajsScope)
            .then(() => {
                this.valueEmitter.emit();
            }).catch(()=>{});
    }
}

export interface currentCertificateData {
    jobId: number;
    settingsCertificateId: number;
    draftJsonData: draftArray | null;
}

export interface draftArray {
    customerownership: string;
    jsonDraft: any;
    currentPageIndex: number;
}
