import { NgModule } from '@angular/core';
import { AppCommonModule } from "@app/shared";
import { CreditCardFormComponent } from './credit-card-form.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import {ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        CreditCardFormComponent,
    ],
    exports:[
        CreditCardFormComponent,
    ],
    imports: [
        // AppCommonModule,
        CreditCardDirectivesModule,
        ReactiveFormsModule,
        DirectivesModule,
        TranslateModule
    ],
})
export class CreditCardFormModule { }
