import Handsontable from 'handsontable';
declare var jQuery: any;

export function HansontableDecimal(scale: number = 4) {

    class HansontableDecimalEditor extends  Handsontable.editors.TextEditor {
        onInit:boolean = true;
        $textarea: any;
        TEXTAREA: HTMLInputElement;

        private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
        private regex: RegExp;
        constructor(hotInstance: Handsontable, row: number, col: number, prop: string | number, TD: HTMLTableCellElement, cellProperties: Handsontable.CellProperties) {
            super(hotInstance, row, col, prop, TD, cellProperties);
            if(scale===0) {
                this.regex = new RegExp('^\\d*\\.?\\d$','g');
            }
            else {
                this.regex = new RegExp('^\\d*\\.?\\d{0,'+scale+'}$','g');
            }
        }

        createElements() {
            super.createElements();

            this.TEXTAREA = document.createElement('TEXTAREA') as HTMLInputElement;
            this.$textarea = jQuery(this.TEXTAREA);
            this.TEXTAREA.className = 'handsontableInput';
            this.textareaStyle = this.TEXTAREA.style;
            Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
            Handsontable.dom.empty(this.TEXTAREA_PARENT);

            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
            Handsontable.dom.addEvent(this.TEXTAREA, 'keydown', this.onBeforeKeyDown.bind(this));
        }

        onBeforeKeyDown(event?) {
            var target = event.target;
            let keys=['1','2','3','4','5','6','7','8','9'];
            if (['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'].indexOf(event.key) !== -1) {
                return;
            }
            if(keys.indexOf(event.key) > -1 && this.TEXTAREA.value.trim()==='') {
                this.TEXTAREA.value=event.key;
            }
            let current: string = this.TEXTAREA.value;
            const position = this.TEXTAREA.selectionStart;
            const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        }
    }
    return HansontableDecimalEditor;
}