import * as tslib_1 from "tslib";
import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ModalContainerComponent } from "ngx-bootstrap/modal";
import { SidepanelOptions } from "@app/sidepanel/services/sidepanel-options";
import { TranslateService } from '@ngx-translate/core';
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
var PopupPanelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PopupPanelComponent, _super);
    function PopupPanelComponent(options, element, confirmationBoxHelper, ajsScope, translate, opService, renderer) {
        var _this = _super.call(this, options, element, renderer) || this;
        _this.element = element;
        _this.confirmationBoxHelper = confirmationBoxHelper;
        _this.ajsScope = ajsScope;
        _this.translate = translate;
        _this.opService = opService;
        _this.renderer = renderer;
        return _this;
    }
    PopupPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if (this.bsModalService.getModalsCount()) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-' + this.bsModalService.getModalsCount());
        }
    };
    return PopupPanelComponent;
}(ModalContainerComponent));
export { PopupPanelComponent };
