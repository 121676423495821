import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AddEditCertificateService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    /* Get dynamic certificates
        Response : Array
    */
    getDynamicCertificates(params?: object): Observable<any> {
        return this.http.get(this.getApiUrl(`getdynamiccertificates`, params));
    }
    /* Get dynamic certificate json
       Response : Array
     */
    getDynamicCertificateJson(params?: object): Observable<any> {
        return this.http.get(this.getApiUrl(`getdynamiccertificatejson`, params));
    }

    /* set certificate json
       Response : Array
     */
    setCertificateJson(data: any, params?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`setcertificatejson`, params), data);
    }

    /* Get dynamic certificate json
       Response : Array
     */
    getDynamicCertificateJsonDraft(lookupId, params?: object): Observable<any> {
        return this.http.get(this.getApiUrl(`getdynamiccertificatejsondraft/${lookupId}`, params));
    }

    /* set certificate json
       Response : Array
     */
    setCertificateDataAndActivate(lookupId, data: any, params?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`setcertificatedataandactivate/${lookupId}`, params), data);
    }
}