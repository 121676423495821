import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
var ArticleEditorService = /** @class */ (function (_super) {
    tslib_1.__extends(ArticleEditorService, _super);
    function ArticleEditorService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.$blockEmitted = new BehaviorSubject(null);
        _this.blockEmitted = _this.$blockEmitted.asObservable();
        _this.$pageEmitted = new BehaviorSubject(null);
        _this.pageEmitted = _this.$pageEmitted.asObservable();
        _this.$currentPage = new BehaviorSubject(null);
        _this.currentPage = _this.$currentPage.asObservable();
        _this.$template = new BehaviorSubject(null);
        _this.template = _this.$template.asObservable();
        _this.$targetPage = new BehaviorSubject(null);
        _this.targetPage = _this.$targetPage.asObservable();
        _this.$settingEmitted = new BehaviorSubject(null);
        _this.settingEmitted = _this.$settingEmitted.asObservable();
        _this.$blockSelected = new BehaviorSubject(null);
        _this.blockSelected = _this.$blockSelected.asObservable();
        return _this;
    }
    ArticleEditorService.prototype.triggerEditor = function (action) {
        this.$blockEmitted.next(action);
    };
    ArticleEditorService.prototype.triggerSettings = function (action) {
        this.$settingEmitted.next(action);
    };
    ArticleEditorService.prototype.emitPages = function (action) {
        this.$pageEmitted.next(action);
    };
    ArticleEditorService.prototype.pageContent = function (action) {
        this.$currentPage.next(action);
    };
    ArticleEditorService.prototype.setTemplate = function (template) {
        this.$template.next(template);
    };
    ArticleEditorService.prototype.addPages = function (url, data) {
        return this.http.post(this.getApiUrl(url), data);
    };
    ArticleEditorService.prototype.savePages = function (url, data) {
        return this.http.post(this.getApiUrl(url), data);
    };
    ArticleEditorService.prototype.sendTargetPage = function (details) {
        this.$targetPage.next(details);
    };
    ArticleEditorService.prototype.blockSelection = function (choosed) {
        this.$blockSelected.next(choosed);
    };
    ArticleEditorService.prototype.saveEditorScreenshotImages = function (data) {
        return this.http.post(this.getApiUrl("editorThumbnailUpdate"), data);
    };
    ArticleEditorService.prototype.editorFileUpload = function (data, templatesId) {
        if (templatesId === void 0) { templatesId = ''; }
        var formData = new FormData();
        var xhr = new XMLHttpRequest();
        formData.append('file', data, data.name);
        return this.http.post(this.getApiUrl("uploadPDF?templatesId=" + templatesId), formData);
    };
    return ArticleEditorService;
}(InterfaceService));
export { ArticleEditorService };
