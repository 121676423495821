import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserVoipSettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(UserVoipSettingsService, _super);
    function UserVoipSettingsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /* Get user voIP settings
        Response : Array
    */
    UserVoipSettingsService.prototype.getUserVoIPSettings = function (userId) {
        return this.http.get(this.getApiUrl(userId + "/voip_user_settings"));
    };
    /*
      Set user voIP settings
        Response : String
    */
    UserVoipSettingsService.prototype.setUserVoIPSettings = function (userId, data) {
        return this.http.post(this.getApiUrl(userId + "/voip_user_settings"), data);
    };
    /*
      Delete user voIP settings
        Response : String
    */
    UserVoipSettingsService.prototype.deleteVoIPSettings = function (userId) {
        return this.http.post(this.getApiUrl(userId + "/delete_voip_settings"), {});
    };
    UserVoipSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function UserVoipSettingsService_Factory() { return new UserVoipSettingsService(i0.inject(i1.HttpClient)); }, token: UserVoipSettingsService, providedIn: "root" });
    return UserVoipSettingsService;
}(InterfaceService));
export { UserVoipSettingsService };
