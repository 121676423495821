import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject,forkJoin } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import {Subject} from "rxjs/internal/Subject";
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()

export class ProposalTemplateService extends InterfaceService {

    private pageBreakContent = new BehaviorSubject(null);
    pageBreakContent$ = this.pageBreakContent.asObservable();

    private templateName = new BehaviorSubject(null);
    templateName$ = this.templateName.asObservable();

    private advanceStyleUpdates = new BehaviorSubject(null);
    advanceStyleUpdates$ = this.advanceStyleUpdates.asObservable();

    private pagePropertiesUpdates = new BehaviorSubject(null);
    pagePropertiesUpdates$ = this.pagePropertiesUpdates.asObservable();

    private entireEditorLoading = new BehaviorSubject(null);
    entireEditorLoading$ = this.entireEditorLoading.asObservable();

    private updatePricingSummaryStyles = new BehaviorSubject(null);
    updatePricingSummaryStyles$ = this.updatePricingSummaryStyles.asObservable();

    private coverPage = new BehaviorSubject(null);
    coverPage$ = this.coverPage.asObservable();

    private optionSummary = new BehaviorSubject(null);
    optionSummary$ = this.optionSummary.asObservable();

    private contentEditorStatus = new BehaviorSubject(null);
    contentEditorStatus$ = this.contentEditorStatus.asObservable();

    private opportunitycoverPage = new BehaviorSubject(null);
    opportunitycoverPage$ = this.opportunitycoverPage.asObservable();

    private hottableInstance = new Subject<any>();
    hotTableInstance$ = this.hottableInstance.asObservable();

    private pricingSectionType = new BehaviorSubject('');
    pricingSectionType$ = this.pricingSectionType.asObservable();

    private pageNumber = new BehaviorSubject('');
    pageNumber$ = this.pageNumber.asObservable();

    private pageList = new BehaviorSubject('');
    pageList$ = this.pageList.asObservable();

    private addOnBlocks = new BehaviorSubject('');
    addOnBlocks$ = this.addOnBlocks.asObservable();

    private opportunitypageList = new BehaviorSubject('');
    opportunitypageList$ = this.opportunitypageList.asObservable();

    private labourHotTableInstance = new Subject<any>();
    labourHotTableInstance$ = this.labourHotTableInstance.asObservable();

    private expensesHotTableInstance = new Subject<any>();
    expensesHotTableInstance$ = this.expensesHotTableInstance.asObservable();

    private depositHotTableInstance = new BehaviorSubject('');
    depositHotTableInstance$ = this.depositHotTableInstance.asObservable();

    private refreshPage = new BehaviorSubject('');
    refreshPage$ = this.refreshPage.asObservable();

    private refreshEditorThumbnailUrl = new BehaviorSubject('');
    refreshEditorThumbnailUrl$ = this.refreshEditorThumbnailUrl.asObservable();

    private clearData = new BehaviorSubject('');
    clearData$ = this.clearData.asObservable();

    private selectedAddon = new BehaviorSubject('');
    selectedAddon$ = this.selectedAddon.asObservable();

    private refreshAddonSidebar = new BehaviorSubject('');
    refreshAddonSidebar$ = this.refreshAddonSidebar.asObservable();

    private savaData = new BehaviorSubject('');
    savaData$ = this.savaData.asObservable();

    private savaEditorThumbnail = new BehaviorSubject(null);
    savaEditorThumbnail$ = this.savaEditorThumbnail.asObservable();

    private savePreviousPageData = new BehaviorSubject('');
    savePreviousPageData$ = this.savePreviousPageData.asObservable();

    private closeModal = new BehaviorSubject('');
    closeModal$ = this.closeModal.asObservable()

    private setOpenPricing = new BehaviorSubject('');
    setOpenPricing$ = this.setOpenPricing.asObservable();

    private zoomValue = new BehaviorSubject('');
    zoomValue$ = this.zoomValue.asObservable();

    private addCustomerPricingTable = new BehaviorSubject(false);
    addCustomerPricing$ = this.addCustomerPricingTable.asObservable();

    private loadData = new BehaviorSubject(false);
    loadData$ = this.loadData.asObservable();

    private pricingSectionData = new BehaviorSubject('');
    pricingSectionData$ = this.pricingSectionData.asObservable();

    private sectionDetails = new BehaviorSubject('');
    sectionDetails$ = this.sectionDetails.asObservable();


    private sidebarContent = new BehaviorSubject(null);
    sidebarContent$ = this.sidebarContent.asObservable();

    private sectionData = new BehaviorSubject(null);
    sectionData$ = this.sectionData.asObservable();

    private optionTypeWatch = new BehaviorSubject(null);
    optionTypeWatch$ = this.optionTypeWatch.asObservable();

    private proposalVersion = new BehaviorSubject(null);
    proposalVersion$ = this.proposalVersion.asObservable();

    private proposalStatus = new BehaviorSubject(null);
    proposalStatus$ = this.proposalStatus.asObservable();

    private addRemoveSectionData = new BehaviorSubject(null);
    addRemoveSectionData$ = this.addRemoveSectionData.asObservable();
    SelectedFinanceDetails = new BehaviorSubject(null);
    SelectedFinanceDetails$ = this.SelectedFinanceDetails.asObservable();

    SelectedFinanceChange = new BehaviorSubject(null);
    SelectedFinanceChange$ = this.SelectedFinanceChange.asObservable();

    SelectedGrantChange = new BehaviorSubject(null);
    SelectedGrantChange$ = this.SelectedGrantChange.asObservable();

    private $formDataEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public formDataEmitted: Observable<any> = this.$formDataEmitted.asObservable();

    private refreshInvoiceSchedule: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public refreshInvoiceSchedule$: Observable<any> = this.refreshInvoiceSchedule.asObservable();

    private updateInvoiceSchedulePopup: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public updateInvoiceSchedulePopup$: Observable<any> = this.updateInvoiceSchedulePopup.asObservable();

    private $invoiceFormEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public invoiceFormEmitted: Observable<any> = this.$invoiceFormEmitted.asObservable();
    private pricingSectionPartsData = new BehaviorSubject('');
    pricingSectionPartsData$ = this.pricingSectionPartsData.asObservable();


    private invoiceStatus = new BehaviorSubject(null);
    invoiceStatus$ = this.invoiceStatus.asObservable();

    private tableTemplateId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    tableTemplateId$ = this.tableTemplateId.asObservable();

    private refreshPricingBreakdownSidebar = new BehaviorSubject('');
    refreshPricingBreakdownSidebar$ = this.refreshPricingBreakdownSidebar.asObservable();


    private saveContentLibraryTemplate = new BehaviorSubject(null);
    saveContentLibraryTemplate$ = this.saveContentLibraryTemplate.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    updateAdvancedStyles(styles) {
        this.advanceStyleUpdates.next(styles);
    }

    updatePagePropertiesUpdates(pageProperties) {
        this.pagePropertiesUpdates.next(pageProperties);
    }
    setEntireEditorLoading(data) {
        this.entireEditorLoading.next(data);
    }

    updatePricingSummaryStyle(styles) {
        this.updatePricingSummaryStyles.next(styles);
    }

    publishInvoiceScheduleRefresh(content) {
        this.refreshInvoiceSchedule.next(content);
    }

    publishInvoiceOpenClose(content) {
        this.updateInvoiceSchedulePopup.next(content);
    }

    setPageBreakContent(content) {
        this.pageBreakContent.next(content);
    }

    DisplayzoomValue(value) {
        this.zoomValue.next(value)
    }

    setOptionTypeWatch(data) {
        this.optionTypeWatch.next(data)
    }

    setAddRemoveSectionData(data) {
        this.addRemoveSectionData.next(data)
    }

    setSectionData(data) {
        this.sectionData.next(data)
    }

    setProposalVersion(data) {
        this.proposalVersion.next(data)
    }

    setProposalStatus(data) {
        this.proposalStatus.next(data)
    }

    addCustomerPricingsection(value) {
        this.addCustomerPricingTable.next(value)
    }

    checkSectionExists(id, params) {
        return this.http.get(this.getApiUrl(`checkPricingSectionExists/${id}`, params))
    }

    //Add or Edit Proposal Template API Call
    addOrEditProposalTemplates(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditProposalTemplate`), data);
    }

    //Delete Proposal Template API Call
    deleteProposalTemplate(id, params?: any): Observable<any> {
        return this.http
            .delete(this.getApiUrl(`${id}/deleteProposalTemplates`,params));
    }

    // Delete Editor Pages
    deleteEditorPages(id: any, data?: any) {
        return this.http.post(this.getApiUrl(`deleteTemplatePages/${id}`), data)
    }

    // Delete Editor Pages
    deletePagePricingSections(id: any) {
        return this.http.get(this.getApiUrl(`deletePagePricingSections/${id}`))
    }

    // Delete pricing section from editor
    deletePricingSection(id: any, data: any) {
        return this.http.post(this.getApiUrl(`deletePricingSection/${id}`), data);
    }

    // Delete pricing section from editor
    checkIsAddOn(optionId: any) {
        return this.http.get(this.getApiUrl(`checkIsAddOn/${optionId}`));
    }

    //Row Reorder API Call
    rowReorder(data: any): Observable<any> {
        return this.http
            .put(this.getApiUrl(`reorderProposalTemplates`), data);
    }

    //Check proposal template already exists
    checkProposalTemplateExists(value) {
        return this.http.get(this.getApiUrl(`checkProposalTemplateExists`, value))
    }

    //Get Optional Template
    getSetupOption() {
        return this.http.get(this.getApiUrl(`listSetupOptions`));
    }

    getAddonList(id,type) {
        return this.http.get(this.getApiUrl(`getAddOnOptionTemplates/${id}/${type}`));
    }
    // Set contentType
    setTemplateName(data) {
        this.templateName.next(data)
    }

    setCoverPage(data) {
        this.coverPage.next(data)
    }

    setOptionSummary(data) {
        this.optionSummary.next(data)
    }

    setPagesList(data) {
        this.pageList.next(data)
    }

    setAddOnBlocks(data) {
        this.addOnBlocks.next(data)
    }

    setOpportunityCoverPage(data) {
        this.opportunitycoverPage.next(data)
    }

    setOpportunityPagesList(data) {
        this.opportunitypageList.next(data)
    }

    savePages(data) {
        this.savaData.next(data)
    }

    setEditorThumbnail(data){
        this.savaEditorThumbnail.next(data)
    }

    setPreviousPageData(data) {
        this.savePreviousPageData.next(data)
    }

    callCloseModalFuntion(data) {
        this.closeModal.next(data)
    }

    openPricingModal(data) {
        this.setOpenPricing.next(data)
    }

    setHottableInstance(data) {
        this.hottableInstance.next(data)
    }

    setTableTemplateId(data:any) {
        this.tableTemplateId.next(data)
    }

    getProposalPages(id) {
        let url = this.getApiUrl(`${id}/getProposalTemplatePages`);
        return this.http.get(url);
    }

    // Pricing section Parts save
    addOrEditPricingSectionParts(data: object, type): Observable<any> {
        return this.http.post(this.getApiUrl(`addProposalCostPart?type=${type}`), data);
    }

    //Pricing section get parts data
    getPricingSectionParts(data: object, type) {
        return this.http.post(this.getApiUrl(`getProposalCostParts?type=${type}`), data);
    }

    // Pricing section labour save
    addOrEditPricingSectionLabour(data: object, type): Observable<any> {
        return this.http.post(this.getApiUrl(`addProposalCostLabour?type=${type}`), data);
    }

    //Pricing section get labour data
    getPricingSectionLabour(data: object, type) {
        return this.http.post(this.getApiUrl(`getProposalCostLabour?type=${type}`), data);
    }
    // Pricing section expenses save
    addOrEditPricingSectionExpenses(data: object, type): Observable<any> {
        return this.http.post(this.getApiUrl(`addProposalCostExpenses?type=${type}`), data);
    }

    //Pricing section get expenses data
    getPricingSectionExpenses(data: object, type) {
        return this.http.post(this.getApiUrl(`getProposalCostExpenses?type=${type}`), data);
    }

    getPermissions() {
        return this.http.get(this.getApiUrl('get_access_details'))
    }

    setPricingSectionType(data) {
        this.pricingSectionType.next(data)
    }

    setPage(data) {
        this.pageNumber.next(data)
    }

    getPartsDropDownData(sectionIdList,viewMode) {
        return this.http.get(this.getApiUrl(`getPartsSupplierData`, {sections: sectionIdList,viewMode:viewMode}));
    }

    getSelectivePartsData(parts,listId?:any, viewMode?:any) {
        return this.http.get(this.getApiUrl(`getPartsSupplierData`, {parts: parts,listId:listId,viewMode:viewMode}));
    }

    setLabourHotInstance(data) {
        this.labourHotTableInstance.next(data)
    }

    setExpensesInstance(data) {
        this.expensesHotTableInstance.next(data)
    }
    setDepositInstance(data) {
        this.depositHotTableInstance.next(data)
    }

    getSuppliers() {
        return this.http.get(this.getApiUrl(`get_all_suppliers`));
    }

    refreshPages(data) {
        this.refreshPage.next(data)
    }

    refreshEditorThumbnailUrls(data) {
        this.refreshEditorThumbnailUrl.next(data)
    }

    clearSubscriptions(data) {
        this.clearData.next(data)
    }

    setSelectedAddon(data) {
        this.selectedAddon.next(data)
    }

    setRefreshAddonSidebar(data) {
        this.refreshAddonSidebar.next(data)
    }

    addPricingOptionTemplateList(data: object) {
        return this.http.post(this.getApiUrl(`addOrLinkProposalOptions`), data);
    }

    checkPricingOptionExists(optionId, pageNumber) {
        return this.http.get(this.getApiUrl(`${optionId}/checkOptionPricingExists?pageNumber=${pageNumber}`));
    }

    getPartsKitList() {
        return this.http.get(this.getApiUrl(`listPartKits?page=${1}&limit=${1000}`));
    }

    deletePrposalTemplateCost(id, partLabourId?): Observable<any> {
        if (partLabourId) {
            return this.http.delete(this.getApiUrl(`${id}/deleteProposalTemplateCost?partLabourCostId=${partLabourId}`));
        } else {
            return this.http.delete(this.getApiUrl(`${id}/deleteProposalTemplateCost`));
        }
    }

    // Delete options
    deleteOptions(id, proposal_id, params?: any): Observable<any> {
        return this.http
            .get(this.getApiUrl(`deleteProposalOption/${id}?proposalId=${proposal_id}`));
    }

    getTotalCost(params) {
        return this.http.get(this.getApiUrl(`getPricingOptionBreakdowns`, params));
    }

    getInvoiceScheduleData(params) {
        return this.http.post(this.getApiUrl(`sales/getInvoiceScheduleData`), params);
    }

    editSectionOrListName(data, id, type) {
        return this.http
            .post(this.getApiUrl(`updateSectionOrList/${id}?type=${type}`), data);
    }

    getSelectedPart(partId) {
        return this.http.get(this.getApiUrl(`getSelectedValuePart/${partId}`));
    }

    getPartList(category, searchText, limit, page) {
        return this.http.get(this.getApiUrl(`listPartsAndPrices?limit=${limit}&page=${page}&category=${category}&searchText=${searchText}`));
    }
    checkPricingProposalExists(proposalId, optionId, pageNumber) {
        return this.http.get(this.getApiUrl(`${proposalId}/checkProposalPricingExists?pageNumber=${pageNumber}&optionId=${optionId}`));
    }
    saveUpsellPartDeatils(data) {
        return this.http.post(this.getApiUrl(`addPricingSectionPartsUpsell`), data);
    }

    addCustomerPricing(data: object, template, merge) {
        return this.http.post(this.getApiUrl(`addCustomerPricing?type=${template}&merge=${merge}`), data);
    }

    updateTemplateSectionDetails(data: object, sectionId) {
        return this.http.post(this.getApiUrl(`sales/updateTemplateSectionDetails/${sectionId}`), data);
    }

    addCustomerPricingItem(data, sectionId, type) {
        return this.http.post(this.getApiUrl(`sales/addCustomerPricingItem/${sectionId}?type=${type}`), data);
    }

    getCustomerPricing(data: object, template) {
        return this.http.post(this.getApiUrl(`sales/getCustomerPricingItems?type=${template}`), data);
    }

    editCustomerPricing(data: object, id) {
        return this.http.post(this.getApiUrl(`sales/editCustomerPricingItem/${id}`), data);
    }
    getProposalOptionLinkedPricing(data: object) {
        return this.http.get(this.getApiUrl(`sales/getCustomerLineItems/${data['sectionId']}`), data);
    }
    addOrEditPricingFormattingOptions(data: object) {
        return this.http.post(this.getApiUrl(`addOrEditPricingFormattingOptions`), data);
    }
    updatetooltip(id) {
        return this.http
            .get(this.getApiUrl(`${id}/updateProposalTemplateToolTip`));
    }
    getAttachedFiles(id) {
        return this.http.get(this.getApiUrl(`getProposalAttatchedFiles/${id}`));
    }

    getCustomerImages(id = null) {
        return this.http.get(this.getApiUrl(`sales/get_customer_images?templateId=${id}`));
    }

    addPageImages(files, pageId): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/upload_editor_images/${pageId}`), files);
    }

    addPricingImages(files: object, pricingId): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/upload_customer_pricing_images/${pricingId}`), files);
    }

    addPageVideo(files, pageId): Observable<any> {
        if (files && files.length) {
            const formData: any = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
            return this.http.post(this.getApiUrl(`sales/upload_editor_video/${pageId}`), formData);
        }
    }

    getEstimatedSuppliers() {
        return this.http
            .get(this.getApiUrl(`get_all_estimate_suppliers`));
    }

    createPartsList(data) {
        return this.http.post(this.getApiUrl(`createOrEditPartsList`), data);
    }

    getlabourRate() {
        return this.http.get(this.getApiUrl(`sales/get_labour_rates`));
    }

    //Add or Edit Section Name
    addOrEditPricingSection(data: object, params): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditPricingSection`, params), data);
    }
    updatePricingSection(data: object, params): Observable<any> {
        return this.http.post(this.getApiUrl(`updatePricingSection`, params), data);
    }

    getPricingSummary(data: object, params): Observable<any> {
        return this.http.post(this.getApiUrl(`getPricingSummary`, params), data);
    }

    addOrEditUpsellParts(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addPricingSectionPartsUpsell`), data);
    }

    saveColumn(data) {
        let url = this.getApiUrl(`userColumnOrder`)
        return this.http.post(url, data);
    }

    getZoomValue() {
        return this.http.get(this.getApiUrl(`getEditorPagesZoomLevel`));
    }

    getPartsListData(listId) {
        return this.http.get(this.getApiUrl(`getPartsListData/${listId}`))
    }

    deleteList(sectionId, listId, labourPricingIds = []) {
        let data = {
            "labourPricingIds": labourPricingIds
        }
        return this.http.post(this.getApiUrl(`deletePricingList/${listId}?sectionId=${sectionId}`), data)
    }

    setLoadData(data) {
        this.loadData.next(data)
    }

    setPricingSectionData(data) {
        this.pricingSectionData.next(data)
    }

    setSidebarContent(data) {
        this.sidebarContent.next(data)
    }

    setSectionDetails(data) {
        this.sectionDetails.next(data)
    }

    saveContentLibrary(data){
        this.saveContentLibraryTemplate.next(data);
    }

    getNominalCodeList() {
        return this.http.get(this.getApiUrl(`listExpensesNominalAccounts`));
    }

    reorderOptionPages(id, data, type) {
        return this.http.post(this.getApiUrl(`reOrderTemplatePages/${id}?type=${type}`), data)
    }

    getPartsListImage(screen, templateid, proposalTemplateid) {
        return this.http.get(this.getApiUrl(`listPartImages?screenType=${screen}&templateId=${templateid}&proposalTemplateId=${proposalTemplateid}`));
    }

    addInvoiceSchedule(data, params) {
        return this.http.post(this.getApiUrl(`sales/addEditInvoiceSchedule?invoiceType=${params}`), data);
    }

    getInvoiceSchedule(id) {
        return this.http.get(this.getApiUrl(`sales/getInvoiceScheduleDetails/${id}`))
    }

    uploadPricingCustomerImage(data, id) {
        return this.http.post(this.getApiUrl(`sales/upload_customer_pricing_images/${id}`), data);
    }

    getInvoiceScheduleTax(params) {
        return this.http.get(this.getApiUrl(`sales/getInvoiceScheduleTaxDetails`, params))
    }

    getInvoiceVatGroup(data) {
        return this.http.post(this.getApiUrl(`sales/getPartsVatRateGroup`), data);
    }

    getFinanceTemplate(optionId, tableType, tablePkId) {
        let data = {
            "tableType": tableType,
            "tablePkId": tablePkId,
            "optionId": optionId
        }
        return this.http.post(this.getApiUrl(`sales/getFinanceTemplate`), data);
    }

    getAddOnData(optionId, tableType, tablePkId) {
        let data = {
            "tableType": tableType,
            "tablePkId": tablePkId,
            "optionId": optionId
        }
        return this.http.post(this.getApiUrl(`getAddOnData`), data);
    }

    getPartUpsellData(id) {
        return this.http.get(this.getApiUrl(`getPartUpsellData/${id}`))
    }

    removePartUpsell(id) {
        return this.http.get(this.getApiUrl(`removePartUpsell/${id}`))
    }

    getSelectedFinanceDetails(res) {
        this.SelectedFinanceDetails.next(res)
    }
    getSelectedFinanceChange(res) {
        this.SelectedFinanceChange.next(res)
    }

    getSelectedGrantChange(res) {
        this.SelectedGrantChange.next(res)
    }

    setContentEditorStatus(data) {
        this.contentEditorStatus.next(data)
    }
    getInvoiceDropDownData(data) {
        return this.http.post(this.getApiUrl(`sales/getInvoiceScheduleDropdown`), data)
    }
    addInvoiceSchedulePricing(data, id,) {
        return this.http.post(this.getApiUrl(`sales/addOrEditInvoicePricing/${id}`), data);
    }
    getInvoiceRelatedData(data) {
        return this.http.post(this.getApiUrl(`sales/getInvoiceRelatedData`), data);
    }
    getInvoiceSchedulePricing(id) {
        return this.http.get(this.getApiUrl(`sales/getInvoiceSchedulePricing/${id}`))
    }

    triggerInvoice(data) {
        this.$formDataEmitted.next(data)
    }

    getNominalList() {
        return this.http.get(this.getApiUrl(`listInvoiceNominalAccounts`));
    }

    deleteInvoiceSchedule(id: any) {
        return this.http.delete(this.getApiUrl(`sales/deleteInvoicePricing/${id}`))
    }

    getInvoiceScheduleExists(data) {
        return this.http.post(this.getApiUrl(`sales/getInvoiceScheduleExists/`), data)
    }

    setchangeValues(data) {
        this.$invoiceFormEmitted.next(data)
    }

    getTaxType(data){
        return this.http.post(this.getApiUrl(`sales/getInvoiceTaxType`), data)
    }

    updateProposalOptionPrices(opportunityId: Number) {
        return this.http.get(this.getApiUrl(`sales/addUpdateProposalPrices/${opportunityId}`))
    }

    opportunityTemplateLinkedProposal(id): Observable<any> {
        return this.http.get(this.getApiUrl(`checkIsProposalLinkOpportunityTemplate/${id}`));
    }

    getProposalPdf(id) {
        return this.http.get(this.getApiUrl(`getProposalPDF/${id}`))
    }

    // Clone proposal template
    cloneProposalTemplate(id, parentId): Observable<any> {
        return this.http.get(this.getApiUrl(`cloneProposalTemplate/${id}/${parentId}`));
    }

    getPricingStyles(data){
        return this.http.post(this.getApiUrl(`sales/get_pricing_styles`), data)
    }

    editCustomerPricingCost(data, id) {
        return this.http.post(this.getApiUrl(`editCustomerPricing/${id}`), data);
    }

    getDiaryFeedback(opportunityId, diaryId, screenType) {
        return this.http.get(this.getApiUrl(`get_diary_event_feedback/${opportunityId}/${diaryId}/${screenType}`))
    }

    getDiary(opportunityId) {
        return this.http.get(this.getApiUrl(`getDiaryDetails/${opportunityId}`));
    }

    editCustomerOptionName(Data) {
        return this.http.post(this.getApiUrl(`sales/editCustomerOptionName`), Data);
    }

    getCustomerOptionName(type, optionId, templateId) {
        return this.http.get(this.getApiUrl(`sales/getCustomerOptionName/${type}/${optionId}/${templateId}`));
    }

    getTaxDetails() {
        return this.http.get(this.getApiUrl(`getTaxDetails`));
    }

    checkFinanceTemplateExists(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/checkFinanceTemplateExists`, params));
    }

    addOrEditFinance(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/addOrEditFinanceTemplates`), data);
    }

    setInvoiceStatus(data) {
        this.invoiceStatus.next(data)
    }

    downloadProposalTemplate(id): Observable<any> {
        return this.http.get(this.getApiUrl(`downloadProposalTemplate?id=${id}`));
    }

    uploadProposalTemplate(params?: any): Observable<any> {
        return this.http.post(this.getApiUrl(`uploadProposalTemplate`), params);
    }
    setPricingSectionPartsData(data) {
        this.pricingSectionPartsData.next(data)
    }
    getOptionAddonsDetails(screenName, templateId, optionId): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getOptionAddonsDetails/${screenName}/${templateId}/${optionId}`));
    }

    pagePropertiesBackgroundImage(files, pageId,saveHeaderFooter = 'tmpshowing',uploadType): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/pagePropertiesBackgroundImage/${pageId}?saveHeaderFooter=${saveHeaderFooter}&uploadType=${uploadType}`), files);
    }

    saveBackgroundColor(backgroundColor): Observable<any> {
        return of({'backgroundColor': backgroundColor, 'status': 'saved',});
    }

    saveJobNotes(params): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/saveJobNotes`), params)
    }

    deleteJobNotes(data): Observable<any>  {
        return this.http.post(this.getApiUrl(`sales/deleteJobNotes`), data)
    }

    updateJobNotes(data): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/updateJobNotes`),data)
    }

    getJobNotesOptionAddonDetails(data): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/getJobNotesOptionAddonDetails`),data)
    }
    getSurveyTemplateList() {
        return this.http.get(this.getApiUrl(`list_LeaveJobQuestions`, {screen: 'opportunity', limit: 100}));
    }
    addOrEditCustomTags(data): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/addOrEditCustomTags`), data);
    }
    getCustomTags(templateId, screenName): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getCustomTags/${templateId}/${screenName}`));
    }
    changeJobNotesPageId(data): Observable<any> {
        return this.http.post(this.getApiUrl('sales/changeJobNotesPageId'), data);
    }
    updateCustomTagStatus(data): Observable<any> {
        return this.http.post(this.getApiUrl(`sales/updateCustomTagStatus`), data);
    }
    setRefreshPricingBreakdownSidebar(data) {
        this.refreshPricingBreakdownSidebar.next(data);
    }
    checkFinanceAvail() {
        return this.http.get(this.getApiUrl(`sales/checkNovunaAvail`));
    }

    getMarketplaceTemplatesList(templatesType = ''){
        if (templatesType != ''){
            return this.http.get(this.getApiUrl(`getMarketplaceTemplates?templatesname=${templatesType}`));
        }
    }
    manipulatemarketTemplates(data: object) {
        return this.http.post(this.getApiUrl(`addMarketPlaceTemplates`), data);
    }
    addOrEditPricingSectionOptions(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addProposalCostSections`), data);
    }
    getPageType(id) {
        return this.http.get(this.getApiUrl(`sales/getPageType/${id}`));
    }
    pageTypes() {
        return this.http.get(this.getApiUrl(`sales/pageTypes`));
    }

    getFreemiumBlocks() {
        return this.http.get(this.getApiUrl('sales/getFreemiumBlocks'));
    }

    upgradeDowngradeFreemiumBlocks(data: object): Observable<any> {
        return this.http.post(this.getApiUrl('sales/upgradeDowngradeFreemiumBlocks'), data);
    }

    addOrEditPageHeaderFooter(data) {
        let url = this.getApiUrl(`sales/addOrEditPageHeaderFooter`)
        return this.http.post(url, data);
    }
}
