import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { CsAgGridService } from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.service";
var CsListViewLayoutComponent = /** @class */ (function () {
    function CsListViewLayoutComponent(_csAgGrid) {
        var _this = this;
        this._csAgGrid = _csAgGrid;
        this.onLayoutReady = new EventEmitter();
        this.onPagingUpdate = new EventEmitter();
        this.isDisplayPanel = false;
        this.isHeaderAction = false;
        this.maxPagesDisplayLength = 5;
        this.initGrid = function (gridApi) {
            _this.csListViewParams.gridApi = gridApi;
            _this.gridAutoHeightParams.gridApi = gridApi;
            _this._csAgGrid.renderGridSSData(_this.csListViewParams);
        };
        this.setGridAutoHeight = function (detailPanelsHeight) {
            if (detailPanelsHeight === void 0) { detailPanelsHeight = 0; }
            if (_this.csListViewParams.gridType === 'ag-grid') {
                _this._csAgGrid.setGridAutoHeight(_this.gridAutoHeightParams, detailPanelsHeight);
            }
        };
    }
    CsListViewLayoutComponent.prototype.ngOnInit = function () {
        this.setCsListViewParams();
        this.prepareGridAutoHeightParams();
        this.afterLayoutReady();
    };
    CsListViewLayoutComponent.prototype.setCsListViewParams = function () {
        this.csListViewParams.pageSize = this.csListViewParams.pageSize || 5;
        this.csListViewParams.currentPage = this.csListViewParams.currentPage || 1;
        this.csListViewParams.setGridAutoHeight = this.setGridAutoHeight;
        this.csListViewParams.initGrid = this.initGrid;
    };
    CsListViewLayoutComponent.prototype.prepareGridAutoHeightParams = function () {
        this.gridAutoHeightParams = {
            gridHolder: this.gridHolder,
            gridWrapper: this.gridWrapper,
            grid: this.grid,
            gridApi: this.csListViewParams.gridApi,
            gridLayout: 'normal',
            detailPanelsHeight: 0,
            pageSize: this.csListViewParams.pageSize
        };
    };
    CsListViewLayoutComponent.prototype.afterLayoutReady = function () {
        this.onLayoutReady.emit(this.csListViewParams);
    };
    CsListViewLayoutComponent.prototype.updatePagingParams = function (params) {
        var _this = this;
        Object.entries(params).forEach(function (_a) {
            var prop = _a[0], val = _a[1];
            _this.csListViewParams[prop] = val;
        });
        this.onPagingUpdate.emit(this.csListViewParams);
        this.gridAutoHeightParams.pageSize = this.csListViewParams.pageSize;
        if (this.csListViewParams.currentEvent && this.csListViewParams.currentEvent !== 'none') {
            this.updateGridData();
        }
    };
    CsListViewLayoutComponent.prototype.updateGridData = function () {
        if (this.csListViewParams.gridType === 'ag-grid') {
            this._csAgGrid.updateGridPagingData(this.csListViewParams);
        }
    };
    return CsListViewLayoutComponent;
}());
export { CsListViewLayoutComponent };
