import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

//--
import { HybridFactory } from '@app/core';
import { InterfaceResolver } from '@app/interfaces';
import { getUrlParams } from '@app/utils';


@Injectable()
export class DataResolver extends InterfaceResolver implements Resolve<Object> {
    constructor(private http: HttpClient) { super(); }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Object> {
        let routeData = route.data;
        let routeParams: Object = getUrlParams(route);

        // Validating configuration
        if (!('DataResolver' in routeData)) {
            console.error('DataResolver configuration is not provided');
            return this.fail();
        }

        if (!('url' in routeData['DataResolver'])) {
            console.error('There is no url provided to fetch resolver data in DataResolver');
            return this.fail();
        }

        let url: string = routeData['DataResolver']['url'],
            responseParser = routeData['DataResolver']['parseResponse'] instanceof Function ? routeData['DataResolver']['parseResponse'] : this.parseResponse,
            paramDef = {};

        // Patching url params
        if (routeData['DataResolver']['params'] instanceof Object) {
            paramDef = routeData['DataResolver']['params'];
        }
        try {
            Object.entries(paramDef).forEach(([param, valueLookup]) => {
                if (!(<string>valueLookup in routeParams)) throw Error(`{param} no found in url`);

                url = url.replace(`{${param}}`, routeParams[<string>valueLookup]);
            });
        }
        catch{
            return this.fail();
        }

        // Data fetch call
        return this.http
            .get(this.getApiUrl(url))
            .pipe(
                map(Response => responseParser(Response)),
                catchError(Err => {
                    return this.fail();
                })
            );

    }

    parseResponse(data: any) { return data; }
}