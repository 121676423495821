/* tslint:disable:max-line-length */
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { Subject } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
var AddEditCustomTagComponent = /** @class */ (function () {
    function AddEditCustomTagComponent(fb, bsModalRef, proposalTemplateService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.proposalTemplateService = proposalTemplateService;
        this.action = 'save';
        this.customTag = false;
        this.selectedOption = 'salesPerson';
        this.formData = {}; // JSON data to be sent to the backend
        this.dropdownOptions = [];
        this.questionOptions = [];
        this.surveyQuestionsData = [];
        this.customTagValues = [];
        this.questionSelected = false;
    }
    AddEditCustomTagComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        var customTagGroup = {};
        this.formData['type'] = 'input';
        this.customTagValues.forEach(function (tag) {
            customTagGroup[tag.key] = new FormControl('', Validators.required);
        });
        this.customTagForm = new FormGroup(customTagGroup);
        this.proposalTemplateService.getSurveyTemplateList().subscribe(function (values) {
            if (values) {
                values['leaveJobQuestion'].forEach(function (question) {
                    if (question && question.JsonData) {
                        _this.surveyQuestionsData.push(question);
                    }
                });
                if (_this.data) {
                    _this.selectedOption = _this.data.tagCompletedBy || _this.selectedOption;
                    _this.customTagName = _this.data.value;
                    if (_this.selectedOption) {
                        _this.formData['type'] = _this.data.answerType || 'input';
                        if (_this.selectedOption === 'survey') {
                            _this.selectedTemplateId = _this.data.templateId;
                            _this.onTemplateChange(_this.selectedTemplateId);
                            _this.selectedQuestionId = _this.data.questionId;
                            setTimeout(function () {
                                $(_this.select2TemplateElement.nativeElement).select2("val", _this.selectedTemplateId);
                                $(_this.select2QuestionElement.nativeElement).select2("val", _this.selectedQuestionId);
                            }, 200);
                        }
                        else {
                            if (_this.formData.type === 'dropdown') {
                                _this.dropdownOptions = _this.data.options;
                            }
                        }
                    }
                }
            }
        });
    };
    AddEditCustomTagComponent.prototype.submitForm = function (form) {
        this.bsModalRef.hide();
        this.onClose.next(form.value);
    };
    AddEditCustomTagComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
        if (this.data) {
            this.onClose.next({ id: this.data.id, existingCustomTagName: this.data.value });
        }
    };
    AddEditCustomTagComponent.prototype.onOptionChange = function (option) {
        this.selectedOption = option;
        this.resetFormData();
        if (this.selectedOption === 'salesPerson' && this.formData.type !== 'dropdown') {
            this.formData['type'] = 'input';
        }
    };
    AddEditCustomTagComponent.prototype.onTemplateChange = function (template) {
        var _this = this;
        this.questionOptions = [];
        this.questionSelected = true;
        this.selectedTemplateId = template;
        this.formData['template'] = template;
        this.selectedQuestionId = null;
        if (this.select2QuestionElement) {
            $(this.select2QuestionElement.nativeElement).select2("val", this.selectedQuestionId);
        }
        var index = this.surveyQuestionsData.findIndex(function (question) { return question.id == template; });
        if (index >= 0) {
            var jsonData = this.surveyQuestionsData[index]['JsonData'];
            var data = JSON.parse(jsonData);
            if (data) {
                data.pages.forEach(function (page) {
                    page.sections.forEach(function (section) {
                        section.fields.forEach(function (field) {
                            // const questionData = {'questionLabel': field.fieldLabel, 'questionId' : field.questionID, 'answerType': field.fieldAnswerType};
                            // this.questionOptions.push(questionData);
                            if (field.fieldAnswerType !== 'Photo' && field.fieldAnswerType !== 'Signature') {
                                _this.surveyOptionConfig = {
                                    'questionLabel': field.fieldLabel,
                                    'questionId': field.questionID,
                                    'answerType': field.fieldAnswerType,
                                    'file': true
                                };
                                _this.questionOptions.push(_this.surveyOptionConfig);
                            }
                        });
                    });
                });
            }
            this.questionData = this.questionOptions.find(function (dO) { return dO.questionId === _this.data.questionId; }) || this.questionOptions[0];
        }
    };
    AddEditCustomTagComponent.prototype.onAddOption = function () {
        if (this.formData.type === 'dropdown' && this.formData.newOption) {
            this.dropdownOptions.push(this.formData.newOption);
            this.formData.newOption = ''; // Clear the input after adding
        }
    };
    AddEditCustomTagComponent.prototype.onRemoveOption = function (index) {
        this.dropdownOptions.splice(index, 1);
    };
    AddEditCustomTagComponent.prototype.onQuestionSelect = function (questionId) {
        this.questionData = this.questionOptions.find(function (dO) { return dO.questionId === questionId; });
    };
    AddEditCustomTagComponent.prototype.resetFormData = function () {
        this.selectedTemplateId = null;
        this.formData = {};
        this.dropdownOptions = [];
        this.selectedQuestionId = null;
        this.questionOptions = [];
        this.questionSelected = false;
    };
    AddEditCustomTagComponent.prototype.drop = function (event) {
        moveItemInArray(this.dropdownOptions, event.previousIndex, event.currentIndex);
    };
    AddEditCustomTagComponent.prototype.saveCustomTags = function () {
        var _this = this;
        if (this.selectedOption === 'salesPerson') {
            this.tagdatas = {
                name: this.customTagName,
                tagCompletedBy: this.selectedOption,
                answerType: this.formData.type,
            };
            if (this.formData.type === 'dropdown') {
                this.tagdatas.options = this.dropdownOptions;
            }
        }
        else if (this.selectedOption === 'survey') {
            this.tagdatas = {
                name: this.customTagName,
                tagCompletedBy: this.selectedOption,
                templateId: this.selectedTemplateId,
                questionId: this.questionData.questionId ? this.questionData.questionId : null,
                answerType: this.questionData.answerType ? this.questionData.answerType : null,
            };
        }
        this.tagdatas = JSON.stringify(this.tagdatas);
        this.customTagData = {
            tablePkId: this.templateId,
            tagData: this.tagdatas,
            currentScreen: this.screen,
            optionId: this.optionId,
        };
        if (this.data && this.data.id) {
            this.customTagData.editId = this.data.id;
        }
        this.proposalTemplateService.addOrEditCustomTags(this.customTagData).subscribe(function (data) {
            if (data) {
                _this.bsModalRef.hide();
                _this.proposalTemplateService.getCustomTags(_this.templateId, _this.screen).subscribe(function (customTagData) {
                    if (customTagData) {
                        _this.allcustomTags = customTagData;
                        var modalCloseData = { id: data, allData: customTagData, existingCustomTagName: _this.data.value };
                        _this.onClose.next(modalCloseData);
                    }
                });
            }
        });
    };
    return AddEditCustomTagComponent;
}());
export { AddEditCustomTagComponent };
