import {
    Injectable,
    TemplateRef,
    EventEmitter,
    Renderer2,
    RendererFactory2, ComponentRef
} from '@angular/core';
import { ComponentLoader, ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import {
    modalConfigDefaults,
    SidepanelOptions,
    TRANSITION_DURATIONS
} from './sidepanel-options';
import {BsModalRef, ModalBackdropComponent} from 'ngx-bootstrap';
import {CsMediumScreenPanelComponent} from "@app/sidepanel/cs-medium-screen-panel/cs-medium-screen-panel.component";
import {CsModalBackdropComponent} from "@app/sidepanel/cs-backdrop/cs-modal-backdrop.component";

@Injectable()
export class MediumScreenModalService {

    config: SidepanelOptions = modalConfigDefaults;
    // tslint:disable-next-line:no-any
    onShow: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-any
    onShown: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-any
    onHide: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-any
    onHidden: EventEmitter<any> = new EventEmitter();
    protected isBodyOverflowing = false;
    protected originalBodyPadding = 0;
    protected scrollbarWidth = 0;
    private loaders: ComponentLoader<CsMediumScreenPanelComponent>[]=[];
    protected backdropRef: ComponentRef<ModalBackdropComponent>;
    private _backdropLoader: ComponentLoader<ModalBackdropComponent>;
    private _renderer: Renderer2;
    private modalVisibleStatus:boolean = false;
    private lastDismissReason = '';

    constructor(rendererFactory: RendererFactory2, private clf: ComponentLoaderFactory) {
        this._backdropLoader = this.clf.createLoader<CsModalBackdropComponent>(
            null,
            null,
            null
        );
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    private _createLoaders(): void {
        const loader = this.clf.createLoader<CsMediumScreenPanelComponent>(
            null,
            null,
            null
        );
        this.copyEvent(loader.onBeforeShow, this.onShow);
        this.copyEvent(loader.onShown, this.onShown);
        this.copyEvent(loader.onBeforeHide, this.onHide);
        this.copyEvent(loader.onHidden, this.onHidden);
        this.loaders.push(loader);
    }

    private copyEvent(from: EventEmitter<any>, to: EventEmitter<any>) {
        from.subscribe(() => {
            to.emit(this.lastDismissReason);
        });
    }

    private removeLoaders(): void {
        this.loaders = [];
    }

    show(content: string | TemplateRef<any> | any, config?: SidepanelOptions): BsModalRef {
        this._createLoaders();
        this.config = Object.assign({}, modalConfigDefaults, config);
        this._showBackdrop();
        this.modalVisibleStatus = true;
        return this._showModal(content);
    }
    _showBackdrop(): void {
        const isBackdropEnabled =
            this.config.backdrop || this.config.backdrop === 'static';
        const isBackdropInDOM =
            !this.backdropRef || !this.backdropRef.instance.isShown;

        if (this.config.backdrop && !this.backdropRef) {
            this.removeBackdrop();

            if (isBackdropEnabled && isBackdropInDOM) {
                this._backdropLoader
                    .attach(CsModalBackdropComponent)
                    .to('body')
                    .show({ isAnimated: this.config.animated });
                this.backdropRef = this._backdropLoader._componentRef;
                //this._renderer.addClass(this.backdropRef.instance.element.nativeElement, 'modal-backdrop-leve-'+)
            }
        }
    }

    _hideBackdrop(): void {
        if (!this.backdropRef) {
            return;
        }
        this.backdropRef.instance.isShown = false;
        const duration = this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0;
        setTimeout(() => this.removeBackdrop(), duration);
    }

    removeBackdrop(): void {
        this._backdropLoader.hide();
        this.backdropRef = null;
    }

    _showModal(content: any): BsModalRef {
        const modalLoader = this.loaders[0];
        if (this.config && this.config.providers) {
            for (const provider of this.config.providers) {
                modalLoader.provide(provider);
            }
        }
        const bsModalRef = new BsModalRef();
        const modalContainerRef = modalLoader
            .provide({ provide: SidepanelOptions, useValue: this.config })
            .provide({ provide: BsModalRef, useValue: bsModalRef })
            .attach( CsMediumScreenPanelComponent )
            .to('body')
            .show({content, isAnimated: this.config.animated, initialState: this.config.initialState, bsModalService: this});
        bsModalRef.hide = () => {
            const duration = this.config.animated ? TRANSITION_DURATIONS.MODAL : 0;
            setTimeout(() => modalContainerRef.instance.hide(), duration);
        };
        bsModalRef.content = modalLoader.getInnerComponent() || null;
        bsModalRef.setClass = (newClass: string) => {
            modalContainerRef.instance.config.class = newClass;
        };
        return bsModalRef;
    }

    hide() {
        if (this.config.backdrop && this.backdropRef) {
            this._hideBackdrop();
        }
        setTimeout(() => {
            this._hideModal();
            this.removeLoaders();
        }, this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0);
        this.modalVisibleStatus = false;
    }

    _hideModal(): void {
        const modalLoader = this.loaders[0];
        if (modalLoader) {
            modalLoader.hide();
        }
    }

    isOpen(){
        return this.modalVisibleStatus;
    }

    getModalsCount(): number {
        return 1;
    }
}
