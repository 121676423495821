import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
    MicroserviceErrorNotificationService
} from '@app/shared/microservice/microservice-error-notification/microservice-error-notification.service';

@Injectable({
    providedIn: 'root'
})
export class MicroserviceHttpErrorInterceptorService implements HttpInterceptor {

    constructor(
        private msErrorNotification: MicroserviceErrorNotificationService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let requestParams = request.params;

        if (requestParams.has('on_error_title')) {
            requestParams = requestParams.delete('on_error_title', null);
        }
        if (requestParams.has('on_error_message')) {
            requestParams = requestParams.delete('on_error_message');
        }
        if (requestParams.has('on_error_close_text')) {
            requestParams = requestParams.delete('on_error_close_text');
        }
        if (requestParams.has('on_error_redirect_to')) {
            requestParams = requestParams.delete('on_error_redirect_to');
        }
        if (requestParams.has('on_error_request_identifier')) {
            requestParams = requestParams.delete('on_error_request_identifier');
        }

        // modify the http request and set new params
        const modifiedRequest = request.clone(
            {
                params: requestParams,
            }
        );

        // Intercepting Responses
        return next.handle(modifiedRequest)
            .pipe(
                // On Error
                catchError((errorResponse: HttpEvent<any>) => {

                    if (errorResponse instanceof HttpErrorResponse) {
                        const errorCode: number = errorResponse.status;

                        const supportedErrorCodes = [401, 403, 404, 500, 503];

                        if (supportedErrorCodes.includes(errorCode)) {

                            let notificationTitle = null;
                            let notificationMessage = null;
                            let notificationCloseText = null;
                            let notificationRedirectTo = null;
                            let notificationRequestIdentifier = null;

                            // Handling auth error
                            if (errorCode === 401 || errorCode === 403) {
                                notificationTitle = 'Auth Error';
                                notificationMessage = 'Auth error';
                                notificationRedirectTo = '/logout';
                            }

                            // Handling page not found error
                            if (errorCode === 404) {
                                notificationTitle = 'Page Not Found Error';
                                notificationMessage = 'Page Not Found';
                            }

                            // Internal server error
                            if (errorCode === 500) {
                                notificationTitle = 'Internal Server Error';
                                notificationMessage = 'Internal server error';
                            }

                            // Server not reachable
                            if (errorCode === 503) {
                                notificationTitle = 'Connectivity Error';
                                notificationMessage = 'Server not reachable';
                                notificationRedirectTo = '/system_settings';
                            }

                            if (request.params.has('on_error_title')
                                && typeof request.params.get('on_error_title') === 'string'
                            ) {
                                notificationTitle = request.params.get('on_error_title');
                            }

                            if (request.params.has('on_error_message')
                                && typeof request.params.get('on_error_message') === 'string'
                            ) {
                                notificationMessage = request.params.get('on_error_message');
                            }

                            if (request.params.has('on_error_close_text')
                                && typeof request.params.get('on_error_close_text') === 'string') {
                                notificationCloseText = request.params.get('on_error_close_text');
                            }

                            if (request.params.has('on_error_redirect_to')
                                && typeof request.params.get('on_error_redirect_to') === 'string'
                            ) {
                                notificationRedirectTo = request.params.get('on_error_redirect_to');
                            }

                            if (request.params.has('on_error_request_identifier')
                                && typeof request.params.get('on_error_request_identifier') === 'string'
                            ) {
                                notificationRequestIdentifier = request.params.get('on_error_request_identifier');
                            }

                            // notify if it has an error message
                            if (notificationMessage) {
                                // this.msErrorNotification.notify(
                                //     notificationMessage,
                                //     notificationRedirectTo,
                                //     notificationTitle,
                                //     null,
                                //     notificationCloseText,
                                //     notificationRequestIdentifier,
                                //     errorCode
                                // );
                            }
                        }
                        return throwError(errorResponse);
                    }
                }),
            );
    }

}
