import { ChangeDetectorRef, OnInit, AfterViewInit, ElementRef } from "@angular/core";
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { BsModalRef } from "ngx-bootstrap";
import { FileUploader } from "ng2-file-upload";
import { from } from "rxjs/index";
import { map } from "rxjs/internal/operators";
import { PartsAndPricesService } from "@app/features/system-settings/parts-and-prices/services/parts-and-prices.service";
import { Subject } from "rxjs/index";
import { environment } from "@app/utils/get-api-url";
import { AuthService } from "@app/core";
var URL = environment.apiBasePath + 'upload_parts_picture';
var PartsAndPricesModal = /** @class */ (function () {
    function PartsAndPricesModal(formBuilder, ajsScope, httpService, authService, bsModalRef, changeDetector) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.ajsScope = ajsScope;
        this.httpService = httpService;
        this.authService = authService;
        this.bsModalRef = bsModalRef;
        this.changeDetector = changeDetector;
        this.list = [];
        this.industrylist = [];
        this.categorylist = [];
        this.subCategorylist = [];
        this.partTypeDropdown = [];
        this.isEdit = false;
        this.formModal = {};
        this.taxLists = [];
        this.units = [{ id: 'hours', text: 'hours' }, { id: 'days', text: 'days' }, { id: 'mins', text: 'mins' }];
        this.dayHours = 8;
        this.unitMessage = 'hours';
        this.taxByProvinces = [];
        this.action = '';
        this.formDatas = {
            settingsIndustryid: '',
            settingsCategoryid: '',
            settingsSubcategoryid: '',
            description: '',
            filePath: '',
            parttype: 1,
            manufacturepartnumber: '',
            defaultInstallTime: '',
            price: '',
            markup: '',
            settingstaxitemid: '',
            installtime_unit: 'hours',
            labourCostRate: '',
            // labourSaleRate: '',
            labourRatesId: ''
        };
        this.isValidUploadFile = true;
        this.suppliers = [];
        this.labourModuleEnabled = false;
        this.uploader = new FileUploader({
            url: URL,
            itemAlias: 'file',
            autoUpload: true,
            allowedMimeType: ['image/png', 'image/jpeg']
        });
        this.uploader.onProgressAll = function (progress) { return _this.changeDetector.detectChanges(); };
        this.uploader.onCompleteAll = function () {
            _this.isValidUploadFile = true;
        };
        this.uploader.onWhenAddingFileFailed = function (fileItem, filter) {
            if (filter.name = "mimeType") {
                _this.isValidUploadFile = false;
            }
        };
    }
    PartsAndPricesModal.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        this.cancel = new Subject();
        this.taxLabel = this.ajsScope.taxLabel;
        if (this.partDetails) {
            this.formDatas.description = this.partDetails.description;
        }
        if (this.data) {
            this.isEdit = true;
            this.formDatas.description = this.data.description;
            this.formDatas.settingsIndustryid = this.data.hasOwnProperty('industryId') ? this.data.industryId : '';
            this.formDatas.price = this.data.price;
            this.formDatas.manufacturepartnumber = this.data.hasOwnProperty('manufacturePartNumber') ? this.data.manufacturePartNumber : '';
            this.formDatas.parttype = !this.data.type ? 1 : this.data.type;
            this.formDatas.markup = this.data.markUp;
            this.formDatas.defaultInstallTime = this.convertByUnit(this.data.defaultInstallTime, this.data.unit);
            this.formDatas.installtime_unit = this.data.unit;
            this.formDatas.labourRatesId = this.data.labourRatesId;
            this.formDatas.labourCostRate = this.data.labourCostRate;
            this.formDatas.settingstaxitemid = this.data.hasOwnProperty('taxItemId') ? this.data.taxItemId : '';
            this.formDatas.settingsSubcategoryid = this.data.hasOwnProperty('subCategoryId') ? this.data.subCategoryId : '';
            if (this.data.subCategoryId) {
                var selectedCats = this.categorylist.find(function (item) {
                    var selectedSubItem = item.subCategoryList.find(function (subItem) {
                        return subItem.subCategoryId == _this.data.subCategoryId;
                    });
                    return selectedSubItem ? true : false;
                });
                if (selectedCats) {
                    this.formDatas.settingsCategoryid = selectedCats.categoriesDescription;
                    this.subCategorylist = selectedCats.subCategoryList;
                }
            }
        }
        if (this.authService.permitted(['labourRates.updateaccess']) == true) {
            this.labourModuleEnabled = true;
        }
        //this.groupByProvinces();
        this.form = this.formBuilder.group({
            'settingsIndustryid': [this.formDatas.settingsIndustryid],
            //'settingsCategoryid': [this.formDatas.settingsCategoryid],
            'settingsSubcategoryid': [this.formDatas.settingsSubcategoryid],
            'description': [this.formDatas.description, [Validators.required], this.isUniqueDescription.bind(this)],
            'filePath': [this.formDatas.filePath],
            'parttype': [this.formDatas.parttype, [Validators.required]],
            'manufacturepartnumber': [this.formDatas.manufacturepartnumber, [], this.isUniqueMpn.bind(this)],
            'defaultInstallTime': [this.formDatas.defaultInstallTime],
            'price': [this.formDatas.price, [Validators.required]],
            'markup': [this.formDatas.markup, [Validators.required]],
            'settingstaxitemid': [this.formDatas.settingstaxitemid, [Validators.required]],
            'installtime_unit': [this.formDatas.installtime_unit],
            'labourCostRate': [this.formDatas.labourCostRate],
            'labourRatesId': [this.formDatas.labourRatesId]
        });
        this.uploader.onCompleteItem = function (item, response, status, headers) {
            var resp = JSON.parse(response);
            if (resp.error == false) {
                _this.form.get('filePath').setValue(resp.message);
            }
        };
        this.select2Config = {
            width: '100%',
            data: this.groupByProvinces()
        };
        if (this.suppliers.length) {
            this.form.addControl('supplierprice', new FormArray([]));
            this.addSuppliers();
        }
    };
    PartsAndPricesModal.prototype.ngAfterViewInit = function () {
        var _this = this;
        var that = this;
        setTimeout(function () {
            _this.saveBtn.nativeElement.focus();
        }, 900);
    };
    PartsAndPricesModal.prototype.updateValidation = function (event) {
        if (this.form.get('description').value == '') {
            this.form.controls['manufacturepartnumber'].updateValueAndValidity();
        }
    };
    PartsAndPricesModal.prototype.isRequiredSubCats = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            var settingsCat = _this.form.get('settingsCategoryid').value;
            if (settingsCat && control.value == '') {
                resolve({ isRequiredSubCategory: true });
            }
            else {
                resolve(null);
            }
        });
        return q;
    };
    PartsAndPricesModal.prototype.isUniqueDescription = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            //console.log('unique', control, this.form.get('manufacturepartnumber').value);
            if (_this.form) {
                var params = {
                    description: control.value,
                    manufacturepartnumber: _this.form.get('manufacturepartnumber').value,
                    ___noPageloading: true
                };
                if (_this.isEdit) {
                    params.selectedId = _this.data.id;
                }
                if (control.value === '') {
                    _this.form.controls['description'].updateValueAndValidity();
                    resolve(null);
                    return q;
                }
                _this.httpService.checkUniqueName(params).subscribe(function (resp) {
                    if (resp == true)
                        resolve({ isUniqueDescription: true });
                    else {
                        resolve(null);
                    }
                });
            }
        });
        return q;
    };
    PartsAndPricesModal.prototype.isUniqueMpn = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            if (_this.form) {
                var params = {
                    manufacturepartnumber: control.value,
                    description: _this.form.get('description').value,
                    ___noPageloading: true
                };
                if (_this.isEdit) {
                    params.selectedId = _this.data.id;
                }
                if (control.value === '') {
                    _this.form.controls['description'].updateValueAndValidity();
                    resolve(null);
                    return q;
                }
                _this.httpService.checkUniqueName(params).subscribe(function (resp) {
                    if (resp == true) {
                        resolve({ isUniqueMpn: true });
                    }
                    else {
                        _this.form.controls['description'].updateValueAndValidity();
                        resolve(null);
                    }
                });
            }
        });
        return q;
    };
    PartsAndPricesModal.prototype.onChangeCategory = function () {
        var value = this.form.get('settingsCategoryid').value;
        if (value === '') {
            this.subCategorylist = [];
            $('.settings-sub-cats').removeClass('required_field');
        }
        else {
            var activeCats = this.categorylist.find(function (item) { return item.categoriesDescription == value; });
            this.subCategorylist = activeCats.subCategoryList;
            $('.settings-sub-cats').addClass('required_field');
        }
        this.form.get('settingsSubcategoryid').setValue('');
        this.form.controls['settingsSubcategoryid'].updateValueAndValidity();
    };
    PartsAndPricesModal.prototype.onFileSelected = function () {
        //this.uploader.uploadAll();
    };
    PartsAndPricesModal.prototype.groupByProvinces = function () {
        var _this = this;
        this.taxByProvinces = [];
        var newItems = [];
        var source = from(this.taxLists);
        var taxSource = source.pipe(map(function (groupItems) {
            var _item = { text: '', children: [] };
            var cnt = 0;
            groupItems.forEach(function (group) {
                if (cnt === 0) {
                    _item.text = group.taxProvinceName;
                }
                _item.children.push({
                    id: group.id,
                    text: group.text
                });
                cnt++;
            });
            return _item;
        }));
        var subscribe = taxSource.subscribe(function (val) { return _this.taxByProvinces.push(val); });
        return this.taxByProvinces;
    };
    Object.defineProperty(PartsAndPricesModal.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    PartsAndPricesModal.prototype.convertHours = function (input) {
        if (input == '') {
            return input;
        }
        var unit = this.form.get('installtime_unit').value;
        if (unit == 'hours') {
            return input;
        }
        return unit == 'days' ? input * this.dayHours : input / 60;
    };
    PartsAndPricesModal.prototype.convertByUnit = function (input, unit) {
        if (input == '') {
            return input;
        }
        if (unit == 'hours') {
            return input;
        }
        return unit == 'days' ? Math.round(input / this.dayHours) : Math.round(input * 60);
    };
    PartsAndPricesModal.prototype.onChangeUnit = function (event) {
        //console.log(event, this.form.get('installtime_unit').value);
        var unitVal = this.form.get('installtime_unit').value;
        this.unitMessage = unitVal === 'days' ? 'one being 8 hours' : 'in ' + unitVal;
    };
    PartsAndPricesModal.prototype.save = function () {
        var _this = this;
        if (!this.form.valid) {
            return false;
        }
        this.action = 'saving';
        if (this.suppliers.length != 0) {
            var indexToRemove_1 = [];
            var fromArray_1 = this.form.get('supplierprice');
            fromArray_1.controls.forEach(function (control, index) {
                if (control.value.price === '' && control.value.supplierPartNumber === '') {
                    indexToRemove_1.push(index);
                }
            });
            indexToRemove_1.sort().reverse().forEach(function (index) {
                fromArray_1.removeAt(index);
            });
        }
        var defaultInstallTime = this.form.get('defaultInstallTime').value;
        var partsandprices = this.form.value;
        if (this.isEdit) {
            partsandprices.id = this.data.id;
        }
        partsandprices.defaultInstallTime = this.convertHours(defaultInstallTime);
        this.httpService.addEditPartsAndPrices({ partsandprices: partsandprices }, this.isEdit ? this.data.id : 0)
            .subscribe(function (resp) {
            _this.action = '';
            resp['data']['labourRatesId'] = partsandprices.labourRatesId;
            _this.onClose.next(resp);
            _this.bsModalRef.hide();
        }, function (error) {
            _this.action = '';
            console.log('err', error);
        });
    };
    // Adding suppliers field while adding parts in pricing section parts
    PartsAndPricesModal.prototype.addSuppliers = function () {
        // add suppliers to the list
        var control = this.form.controls['supplierprice'];
        for (var i = 0; i < this.suppliers.length; i++) {
            var supplierForm = void 0;
            supplierForm = this.formBuilder.group({
                price: [''],
                supplierId: [this.suppliers[i]['id']],
                supplierName: [this.suppliers[i]['name']],
                supplierPartNumber: ['']
            });
            control.push(supplierForm);
        }
    };
    PartsAndPricesModal.prototype.cancel_ = function () {
        this.bsModalRef.hide();
        this.cancel.next(true);
    };
    return PartsAndPricesModal;
}());
export { PartsAndPricesModal };
