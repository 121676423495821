/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./cs-toast-box.component";
import * as i4 from "../../../services/utility-services/cs-toast-box.service";
var styles_CsToastBoxComponent = [];
var RenderType_CsToastBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsToastBoxComponent, data: {} });
export { RenderType_CsToastBoxComponent as RenderType_CsToastBoxComponent };
export function View_CsToastBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["id", "toast-box"], ["ng-mouseenter", "keepToastShowing()"], ["ng-mouseleave", "allowToastToHide()"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "toast-showing": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 4, "span", [["class", "confirmation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "strong", [["class", "text"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "ss-delete close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.show); _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.toastMessage; _ck(_v, 6, 0, currVal_2); }); }
export function View_CsToastBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["csToastBox", ""]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onmouseover($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onmouseleave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CsToastBoxComponent_0, RenderType_CsToastBoxComponent)), i0.ɵdid(1, 114688, null, 0, i3.CsToastBoxComponent, [i4.CsToastBoxService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsToastBoxComponentNgFactory = i0.ɵccf("[csToastBox]", i3.CsToastBoxComponent, View_CsToastBoxComponent_Host_0, { toastMessage: "message", showupDuration: "duration" }, { onclose: "close" }, []);
export { CsToastBoxComponentNgFactory as CsToastBoxComponentNgFactory };
