export class ContractTemplate {
    id: number;
    name: string;
    description: string;
    category: number;
    contractType: number;
    contractPeriod: number;
    sla: number;
    status: string;
    deleted: boolean;
    uuid: string;
    createdondatetime: Date;
    createdbytype: number;
    createdbyid: number;
    lastModifiedDateTime: Date;
    invoiceCategory: number;
    usersGroup: number;
    contractTandC:number;

    constructor(data?: Object) {
        if (!(data instanceof Object)) data = {};

        this.id = data['id'] || '';
        this.name = data['name'] || '';
        this.description = data['description'] || '';
        this.category = data['category'] || '';
        this.contractType = data['contractType'] || '';
        this.contractPeriod = data['contractPeriod'] || '';
        this.sla = data['sla'] || '';
        this.invoiceCategory = data['invoiceCategory'] || '';
        this.usersGroup = data['usersGroup'] || '';
        this.status = data['status'] || '';
        this.deleted = data['deleted'] || '';
        this.uuid = data['uuid'] || '';
        this.createdondatetime = data['createdondatetime'] ? new Date(data['createdondatetime']) : null;
        this.createdbytype = data['createdbytype'] || '';
        this.createdbyid = data['createdbyid'] || '';
        this.lastModifiedDateTime = data['lastModifiedDateTime'] ? new Date(data['lastModifiedDateTime']) : null;
        this.contractTandC = data['contractTandC']|| '';

    }
}