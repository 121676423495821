import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(word: string) {
    if (!word || typeof(word) === 'number') return word;
    return word.substr(0, 1).toUpperCase() + word.substring(1).toLowerCase();
  }

}
