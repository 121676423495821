import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleEditorComponent } from './article-editor/article-editor.component';
import { ArticleEditorService } from './article-editor.service';
import { CoverPagesComponent } from './cover-pages/cover-pages.component';
import { AppCommonModule } from '@app/shared';
import { DndModule } from 'ngx-drag-drop';
import { ContentEditorDirectiveComponent } from './content-editor-directive/content-editor-directive.component';
import { ModalModule } from "ngx-bootstrap";
import { InvoiceScheduleContainerComponent } from './invoice-schedule-container/invoice-schedule-container.component';
import { SidepanelModule } from "@app/sidepanel/sidepanel.module";
import { AddOptionModalComponent } from './add-option-modal/add-option-modal.component';
import { OptionTemplateService } from '@app/features/system-settings/option-template/services/option-template.service';
import { PricingSectionsComponent } from './pricing-sections/pricing-sections.component';
import { HandsontableModule } from '@app/handsontable/handsontable.module';
import { PricingSectionLabourComponent } from './pricing-sections/pricing-section-labour/pricing-section-labour.component';
import { PricingSectionPartsComponent } from './pricing-sections/pricing-section-parts/pricing-section-parts.component';
import { PricingSectionExpensesComponent } from './pricing-sections/pricing-section-expenses/pricing-section-expenses.component';
import { ProposalTemplatePricingSectionService } from '@app/services';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { FileUploadModule } from 'ng2-file-upload';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OptionTemplateAddEditComponent } from '@app/features/system-settings/option-template/option-template-add-edit/option-template-add-edit.component';
import { InvoiceSchedulePopupComponent } from './invoice-schedule-popup/invoice-schedule-popup.component';

import { ImageBlocksComponent } from './image-blocks/image-blocks.component';
import { BlockSettingsModalComponent } from './block-settings-modal/block-settings-modal.component';
import { FroalaEditorComponent } from './froala-editor/froala-editor.component';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectivesModule } from '@app/directives';
import { SelectModule } from "ng2-select";
import { PricingSectionUsepartkitComponent } from './pricing-sections/pricing-section-usepartkit/pricing-section-usepartkit.component';
import { VideoBlocksComponent } from './video-blocks/video-blocks.component';
import { EditSectionNameComponent } from './pricing-sections/edit-section-name/edit-section-name.component';
import { PricingSectionPartsCategoriesComponent } from './pricing-sections/pricing-section-parts-categories/pricing-section-parts-categories.component';
import { PartsAndPricesService } from '@app/features/system-settings/parts-and-prices/services/parts-and-prices.service';
import { UpsellPartsComponent } from './pricing-sections/upsell-parts/upsell-parts.component';
import { PricingSectionDiscountComponent } from './pricing-section-discount/pricing-section-discount.component';
import { InvoiceEditModalComponent } from './invoice-edit-modal/invoice-edit-modal.component';
import { AddPricingItemsComponent } from './add-pricing-items/add-pricing-items.component';
import { PricingPaddingOptionsComponent } from './pricing-padding-options/pricing-padding-options.component';
import { PricingFormattingOptionsComponent } from './pricing-formatting-options/pricing-formatting-options.component';
import { PricingSectionModalComponent } from './pricing-section-modal/pricing-section-modal.component';
import { AddOpportunityService } from '@app/features/customers/add-opportunity/service/add-opportunity.service';
import { FormattingOptionsComponent } from './formatting-options/formatting-options.component';
import { ListCreationModalComponent } from './pricing-sections/list-creation-modal/list-creation-modal.component';
import { PartsAndPricesModal } from '@app/features/system-settings/parts-and-prices/sidepanel/parts-and-prices.modal';
import { EmailToSuppliersComponent } from './pricing-sections/email-to-suppliers/email-to-suppliers.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { AddAddonModalComponent } from './add-addon-modal/add-addon-modal.component';
import { DepositInvoiceScheduleComponent } from './deposit-invoice-schedule/deposit-invoice-schedule.main-layout.component';
import { DepositInvoiceComponent } from './deposit-invoice/deposit-invoice.component';
import { TableFormatterOptionsComponent } from './table-formatter-options/table-formatter-options.component';
import { PartsAndPricesResolver } from '@app/features/system-settings/parts-and-prices/resolver/parts-and-prices.resolver';
import {RevolvappEditorComponent} from "@app/article-editor/revolvapp-editor/revolvapp-editor.component";
import {CurrencyPipe} from '@app/pipes';
import {CustomTagFormComponent} from "@app/article-editor/custom-tag-form/custom-tag-form.component";
import { ConfigureAdvanceStylingComponent } from "@app/article-editor/configure-advance-styling/configure-advance-styling.component";
import {AddEditCustomTagComponent} from "@app/article-editor/add-edit-custom-tag/add-edit-custom-tag.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { PagePropertiesConfigurationComponent } from './page-properties-configuration/page-properties-configuration.component';

@NgModule({
	declarations: [
		ArticleEditorComponent,
		RevolvappEditorComponent,
		CoverPagesComponent,
		ContentEditorDirectiveComponent,
		InvoiceScheduleContainerComponent,
		AddOptionModalComponent,
		PricingSectionsComponent,
		TableFormatterOptionsComponent,
		PricingSectionLabourComponent,
		PricingSectionPartsComponent,
		PricingSectionExpensesComponent,
		ImageBlocksComponent,
		BlockSettingsModalComponent,
		FroalaEditorComponent,
		ImageCropComponent,
		PricingSectionUsepartkitComponent,
		VideoBlocksComponent,
		EditSectionNameComponent,
		PricingSectionPartsCategoriesComponent,
		UpsellPartsComponent,
		PricingSectionDiscountComponent,
		UpsellPartsComponent,
		InvoiceEditModalComponent,
		AddPricingItemsComponent,
		PricingPaddingOptionsComponent,
		PricingFormattingOptionsComponent,
		PricingSectionModalComponent,
		FormattingOptionsComponent,
		ListCreationModalComponent,
		EmailToSuppliersComponent,
		SurveyDetailsComponent,
		AddAddonModalComponent,
		DepositInvoiceScheduleComponent,
		DepositInvoiceComponent,
        InvoiceSchedulePopupComponent,
        PartsAndPricesModal,
        OptionTemplateAddEditComponent,
        CustomTagFormComponent,
        AddEditCustomTagComponent,
        ConfigureAdvanceStylingComponent,
        PagePropertiesConfigurationComponent
	],
	imports: [
		CommonModule,
		AppCommonModule,
		DndModule,
		ModalModule,
		SidepanelModule,
		HandsontableModule,
		FileUploadModule,
		ImageCropperModule,
        DragDropModule,
		DirectivesModule,
		SelectModule,
		CollapseModule.forRoot()
	],
	exports: [
		CoverPagesComponent,
		ContentEditorDirectiveComponent,
		ModalModule,
		SidepanelModule,
		FroalaEditorComponent
	],
	providers: [
        ArticleEditorService,
		OptionTemplateService,
		ProposalTemplatePricingSectionService,
		ProposalTemplateService,
		PartsAndPricesService,
		AddOpportunityService,
		PartsAndPricesResolver,
        CurrencyPipe
	],
	entryComponents: [
		AddOptionModalComponent,
		ImageBlocksComponent,
		BlockSettingsModalComponent,
		ImageCropComponent,
		PricingSectionUsepartkitComponent,
		VideoBlocksComponent,
		EditSectionNameComponent,
		PricingSectionPartsCategoriesComponent,
		UpsellPartsComponent,
		InvoiceEditModalComponent,
		AddPricingItemsComponent,
		PricingFormattingOptionsComponent,
		PricingPaddingOptionsComponent,
		FormattingOptionsComponent,
		ListCreationModalComponent,
		PartsAndPricesModal,
		EmailToSuppliersComponent,
		AddAddonModalComponent,
		OptionTemplateAddEditComponent,
		TableFormatterOptionsComponent,
        CustomTagFormComponent,
        AddEditCustomTagComponent,
        ConfigureAdvanceStylingComponent,
        PagePropertiesConfigurationComponent
	]
})
export class ArticleEditorModule { }
