import { Injectable, Inject, forwardRef } from '@angular/core';

//--
import { InterfaceClass } from '@app/interfaces';
import { SettingsService } from '@app/core';

// Feature NAME ID maps from 'web/javascripts/feature-constants.js'
declare var features: Object;

@Injectable()
export class AuthService extends InterfaceClass {
    userAccessDetails: Object = {};
    modulePermissionsObject: any;

    constructor(
        @Inject(forwardRef(() => SettingsService)) private settings,
    ) {
        super();

        // Listen for user permission changes and update the repertory
        this.subscriptions['userAccessDetails'] = this.settings.userAccessDetails$.subscribe(details => {
            this.userAccessDetails = details;
        });

        this.subscriptions['modulePermissions'] = this.settings.modulePermissions$.subscribe( Data => {
            this.modulePermissionsObject = Data;
        });
    }

    // Checks is authenticated
    public authenticated() {
        return this.userAccessDetails instanceof Object;
    }

    // Checks is user has permission
    public permitted(permittedFeatures: string[]) {
        let invalidPreviliges: string[][] = [];

        let userPreviliges: string[] = permittedFeatures.filter(feature => {

            let [featureName, accessLevel] = feature.split('.');
            let featureId: number = features[featureName];

            // In case of valid feature id
            if (!featureId) { return false; }

            // Finds feature access in user permissions
            if (this.userAccessDetails[featureId] && this.userAccessDetails[featureId][accessLevel])
                return true;
            else {
                invalidPreviliges.push([featureName, accessLevel]);
                return false;
            }
        });

        // Checks user has all the provided permissions
        return permittedFeatures.length === userPreviliges.length ? true : invalidPreviliges;

    }

    // Check the module access
    public hasModuleAccess(module) {
        let modulePermissions;

        if(this.modulePermissionsObject){
            modulePermissions = this.modulePermissionsObject['moduleDetails'];
            console.log(modulePermissions)
            if (modulePermissions[module] == 1)
                return true;
            else
                return false;
        }

    }
}
