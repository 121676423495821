import {forwardRef, Inject, Input, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {SettingsService} from "@app/core";
import {formatDate} from "@angular/common";

declare var _:any;

@Pipe({
  name: 'short_date'
})
export class DatePipe implements PipeTransform {
    format: string = 'd-m-y';
    timeFormat: string;
    formats = [
        {'id' : 0, 'format' : 'dd/MM/yy'},
        {'id' : 1, 'format' : 'dd/MM/yyyy'},
        {'id' : 2, 'format' : 'dd/MMM/yy'},
        {'id' : 3, 'format' : 'dd/MMM/yyyy'},
        {'id' : 4, 'format' : 'dd-MM-yy'},
        {'id' : 5, 'format' : 'dd-MM-yyyy'},
        {'id' : 6, 'format' : 'dd-MMM-yy'},
        {'id' : 7, 'format' : 'dd-MMM-yyyy'},
        {'id' : 50, 'format' : 'MM/dd/yy'},
        {'id' : 51, 'format' : 'MM/dd/yyyy'},
        {'id' : 52, 'format' : 'MMM/dd/yy'},
        {'id' : 53, 'format' : 'MMM/dd/yyyy'},
        {'id' : 54, 'format' : 'MM-dd-yy'},
        {'id' : 55, 'format' : 'MM-dd-yyyy'},
        {'id' : 56, 'format' : 'MMM-dd-yy'},
        {'id' : 57, 'format' : 'MMM-dd-yyyy'}
     ];

    currentdate;
    todayDate;

    yesterday;
    yesterdayDate;

    tomorrow;
    tomorrowDate;
    paramValue;

    constructor(@Inject(forwardRef(() => SettingsService)) private settings,
                @Inject(LOCALE_ID) private locale: string) {
        this.currentdate= new Date();
        this.todayDate=formatDate(this.currentdate, 'yyyy-MM-dd', this.locale);

        this.yesterday= new Date((new Date()).valueOf() - 1000*60*60*24);
        this.yesterdayDate=formatDate(this.yesterday, 'yyyy-MM-dd', this.locale);

        this.tomorrow= new Date((new Date()).valueOf() + 1000*60*60*24);
        this.tomorrowDate=formatDate(this.tomorrow, 'yyyy-MM-dd', this.locale);
    }

    doDateSmartFormat(value: any){
        const intlSettings = this.settings._csIntlSettings;
        const dateFormat = _.findWhere(this.formats, {'id' : parseInt(intlSettings['short_date']) });
        if (dateFormat) {
            this.format = dateFormat['format'];
        }
        // Parameter Value
        this.paramValue=formatDate(value, 'yyyy-MM-dd', this.locale);
        if (intlSettings['time'] == 1) {
            this.timeFormat = 'HH:mm';
        }else{
            this.timeFormat = 'hh:mm a';
        }
        if(this.paramValue==this.todayDate){
            return 'Today'+' '+formatDate(value, this.timeFormat, this.locale);
        }else if(this.paramValue==this.yesterdayDate){
            return 'Yesterday'+' '+formatDate(value, this.timeFormat, this.locale);
        } else if(this.paramValue==this.tomorrowDate){
            return 'Tomorrow'+' '+formatDate(value, this.timeFormat, this.locale);
        }else{
            return formatDate(value, this.format, this.locale);
        }
    }

    transform(value: any, args?: any, smartFormat?: any): any {
        if(value=='' || value==null){
            return '';
        }
        const intlSettings = this.settings._csIntlSettings;
        if (args) {
            if (smartFormat) {
                if (smartFormat==1) {
                    return this.doDateSmartFormat(value);
                }else{
                    this.format = args;
                    return formatDate(value, this.format, this.locale);
                }
            }else{
                this.format = args;
                return formatDate(value, this.format, this.locale);
            }
        } else {
            const dateFormat = _.findWhere(this.formats, {'id' : parseInt(intlSettings['short_date']) });
            if (dateFormat) {
                this.format = dateFormat['format'];
            }
            if (smartFormat) {
                if (smartFormat==1) {
                    return this.doDateSmartFormat(value);
                }else{
                    return formatDate(value, this.format, this.locale);
                }
            }else{
                return formatDate(value, this.format, this.locale);
            }
        }
    }
}
