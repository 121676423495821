import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {from, Subject} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap";
import {map} from "rxjs/operators";


@Component({
  selector: 'app-exit-criteria-side-panel',
  templateUrl: './exit-criteria-side-panel.component.html',
  styles: []
})
export class ExitCriteriaSidePanelComponent implements OnInit {
    public onClose: Subject<any>;
    title: string;
    closeBtnName: string;
    list: any[] = [];
    form: FormGroup;
    data:any;
    action: string = '';
    isEdit:boolean = false;
    select2LabelConfig: any;
    exitCriteria: any;
    exitCondition: any;
    inputModel: any;
    selectedData: any;
    formDatas = {
        name: [],
    };
    saving:boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public modalRef: BsModalRef,
    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        if(this.data) {
            this.isEdit=true;
            this.formDatas.name =  this.exitCondition;
        }

        this.form = this.formBuilder.group({
            'exitCriteria': [this.formDatas.name, [Validators.required] ]
        });

        function groupByData(data) {
            const allConditions = [];
            const newItems: any[] = [];
            newItems.push(data);
            const source = from(newItems);
            const tags = source.pipe(
                map(groupItems => {
                    const _item: any = {text: '', children: []};
                    let cnt: number = 0;
                    groupItems.forEach( group => {
                        if (cnt === 0) {
                            _item.text = group.type;
                        }
                        if(group.default == false){
                            _item.children.push({
                                id: group.id,
                                text: group.text
                            });
                        }
                        cnt++;
                    });
                    return _item;
                })
            );
            const subscribe = tags.subscribe(val => allConditions.push(val));
            return allConditions;
        }

        this.select2LabelConfig = {
            width: '100%',
            data: groupByData(this.exitCondition) ,
            tags: true,
            multiple: true
        }
    }
    get f() {
        return this.form.controls;
    }
    save(){
        const data = this.selectedData;
        this.onClose.next(this.selectedData);
        this.modalRef.hide();
    }
    selectedCondition($event){
        this.selectedData = $event;
        this.saving=!!this.selectedData.length;
    }
}
