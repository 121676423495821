import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef, AfterViewChecked, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs/index';
import { filter, map } from "rxjs/internal/operators";
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
var ColumnEditComponent = /** @class */ (function () {
    function ColumnEditComponent(cdr, http) {
        this.cdr = cdr;
        this.http = http;
        this.columns = [];
        this.isEnableSelect = false;
        this.isEnableActions = false;
        this.onReorderColumn = new EventEmitter();
        this.onShowHideColumn = new EventEmitter();
        this.onSaveColumns = new EventEmitter();
        this.onCloseModal = new EventEmitter();
        this.isReady = false;
        this.visibility = true;
        this.listStyle = {
            width: 'auto',
            height: 'auto',
            dropZoneHeight: '20px'
        };
        this.modalConfig = {
            keyboard: true,
            backdrop: false
        };
        this.showBackdrop = false;
        this.isDisableSave = true;
    }
    ColumnEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.isReady = true;
        this.sortableInstance = Sortable.create(this.sortableContainer.nativeElement, {
            animation: 150,
            easing: "cubic-bezier(1, 0, 0, 1)",
            swapThreshold: 0.65,
            onUpdate: function (event) {
                var oldIndex = event.oldIndex, newIndex = event.newIndex;
                var items = Object.assign([], _this.columns);
                var item = items[oldIndex];
                items.splice(oldIndex, 1);
                items.splice(newIndex, 0, item);
                _this.columns = items;
                _this.onChangeColumnItem(items);
                //console.log('items', items, this.columns);
            }
        });
    };
    ColumnEditComponent.prototype.ngOnInit = function () {
        //console.log('cols_ini', this.columns)
    };
    ColumnEditComponent.prototype.showColumnEditor = function () {
        this.modal.show();
    };
    ColumnEditComponent.prototype.onChangeColumnItem = function (newItems) {
        this.isDisableSave = false;
        this.onReorderColumn.emit(newItems);
    };
    ColumnEditComponent.prototype.onChangeColumnItemModel = function () {
        if (this.isReady) {
            this.onReorderColumn.emit(this.columns);
        }
    };
    ColumnEditComponent.prototype.ngAfterViewChecked = function () {
        this.cdr.detectChanges();
    };
    ColumnEditComponent.prototype.onShowHideColumnItem = function (event) {
        this.isDisableSave = false;
        event.visible = !event.visible;
        this.onShowHideColumn.emit(event);
    };
    ColumnEditComponent.prototype.onSave = function () {
        this.onSaveColumns.emit({
            modal: this.modal,
            columns: this._paramsTransform()
        });
    };
    ColumnEditComponent.prototype._paramsTransform = function () {
        var newCols = [];
        var filterColumns = from(this.columns)
            .pipe(filter(function (col) { return !col.data.startsWith('@') && col.visible; }))
            .pipe(map(function (col, index) {
            if (col.color) {
                return {
                    col_name: col.col_name,
                    pos: index + 1,
                    active: col.visible,
                    color: col.color
                };
            }
            else {
                return {
                    col_name: col.col_name,
                    pos: index + 1,
                    active: col.visible
                };
            }
        }));
        filterColumns.subscribe(function (col) { return newCols.push(col); });
        return newCols;
    };
    ColumnEditComponent.prototype.paramsTransform = function () {
        var newCols = [], newParams = {};
        var filterColumns = from(this.columns)
            .pipe(filter(function (col) { return !col.data.startsWith('@'); }))
            .pipe(map(function (col, index) {
            return {
                col_name: col.col_name,
                pos: index + 1,
                active: col.visible
            };
        }));
        filterColumns.subscribe(function (col) { return newCols.push(col); });
        return {
            columnValue: JSON.stringify(newCols),
            screenName: this.category
        };
    };
    ColumnEditComponent.prototype.test = function () {
        console.log(this.columns);
    };
    ColumnEditComponent.prototype.handler = function (type, $event) {
        var _this = this;
        var $fileUploader = $('div.drag-n-drop-overlays');
        if (type === 'onShown' || type === 'onShow') {
            this.showBackdrop = true;
            if ($fileUploader.length) {
                $fileUploader.attr('disabled', true);
                $fileUploader[0].classList.remove('dragover-enabled');
            }
            $('bs-modal-backdrop').on('click', function () {
                _this.modal.hide();
                _this.onCloseModal.emit(_this.modal);
            });
        }
        else if (type === 'onHide' || type === 'onHidden') {
            $('bs-modal-backdrop').off('click');
            this.showBackdrop = false;
            if ($fileUploader.length) {
                $fileUploader.removeAttr('disabled');
                $fileUploader[0].classList.add('dragover-enabled');
                $fileUploader[0].classList.remove('upload-prompt-expanding-into-view');
                $fileUploader[0].classList.remove('visible-on-screen');
            }
        }
    };
    return ColumnEditComponent;
}());
export { ColumnEditComponent };
