<div class="modal-header">
    <h4 class="modal-title pull-left">Chat message settings</h4>
</div>

<div class="modal-body">
    <ng-container>
        <div class="item form-item p-15">
            <div class="control">
                <label class="control-label required_field">Chat channel</label>
                <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                    [disabled]="isMarketPlaceView"
                    csSelect2
                    [select2Config]="{}"
                    [dropdownvalue]="channelName"
                    [(ngModel)]="channelName">
                    <option value="">Please.Choose</option>
                    <option value="#general">General</option>
                    <option value="#random">Random</option>
                </select>
            </div>
            <div class="control">
            <label class="control-label required_field">Message</label>
            <quill-editor
                [modules]="modules"
                (onContentChanged)="onChange($event)"
                placeholder="Enter chat message tag the marker using '['">
            </quill-editor>
            </div>
        </div>

    </ng-container>
</div>
<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
    <button class="btn btn-primary" (click)="addValue()" [disabled]="!mentionContent && !channelName">
        <span translate>Save</span>
    </button>
</div>