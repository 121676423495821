import { EventEmitter } from '@angular/core';
import Handsontable from 'handsontable';
import { CsToastBoxService } from "@app/services";
import { HansontableColor } from '@app/directives';
import { AuthService } from "@app/core";
import * as i0 from "@angular/core";
import * as i1 from "../utility-services/cs-toast-box.service";
import * as i2 from "../../core/auth/auth.service";
var PipelineStageService = /** @class */ (function () {
    function PipelineStageService(toastBox, confirmationBoxHelper, ajsScope, authService) {
        var _this = this;
        this.toastBox = toastBox;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.authService = authService;
        this.hotInstances = [];
        this.spreadsheetData = [];
        this.spreadSheetSettings = {};
        this.savechange = new EventEmitter();
        this.deletechange = new EventEmitter();
        this.deleteEvent = new EventEmitter();
        this.valid = false;
        this.allowDelete = false;
        this.emptyCellValidator = function (value, callback) {
            if (value === '') {
                _this.toastBox.show('Empty cell not allowed', 1000);
            }
            callback(true);
        };
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }
    PipelineStageService.prototype.emitSaveChangeEvent = function (obj) {
        this.savechange.emit(obj);
    };
    PipelineStageService.prototype.getSaveChangeEmitter = function () {
        return this.savechange;
    };
    PipelineStageService.prototype.emitdeleteChangeEvent = function (row) {
        this.deletechange.emit(row);
    };
    PipelineStageService.prototype.getdeleteChangeEmitter = function () {
        return this.deletechange;
    };
    PipelineStageService.prototype.emitdeleteEvent = function (row) {
        this.deleteEvent.emit(row);
    };
    PipelineStageService.prototype.getDeleteEvent = function () {
        return this.deleteEvent;
    };
    PipelineStageService.prototype.getSpreadSheetData = function (hotId) {
        return this.spreadsheetData;
    };
    PipelineStageService.prototype.removeData = function (hotId) {
        var instance = this.hotInstances[hotId];
        if (instance) {
            Handsontable.hooks.destroy(instance);
        }
        this.hotInstances = [];
        this.hotId = undefined;
        this.spreadsheetData = [];
    };
    PipelineStageService.prototype.populateData = function (hotId, data) {
        var _this = this;
        var instance = this.hotInstances[hotId];
        this.spreadsheetData = data;
        this.baseSettings = {
            preventOverflow: 'vertical',
            colHeaders: true,
            rowHeaders: true,
            viewportRowRenderingOffset: 1000,
            stretchH: 'all',
            copyPaste: false,
            afterValidate: function (isValid, value, row, prop, source) {
                // let isnum = /^\d+$/.test(value);
                // if (!isnum && prop == 'rotting') {
                //     instance.setDataAtCell(row, instance.propToCol(prop), null);
                // }
                // if (prop == 'rotting' && value != null && value.toString().length > 10) {
                //     let val = value.toString();
                //     let number = val.substring(0, 9);
                //     instance.setDataAtCell(row, instance.propToCol(prop), number);
                // }
            },
            tabMoves: function (e) {
                if (instance.getSelected()[0][1] + 1 === (instance.countCols() - 1)) {
                    instance.selectCell((instance.getSelected()[0][0] + 1), 0);
                    return {
                        row: 0,
                        col: 0
                    };
                }
                else {
                    return {
                        row: 0,
                        col: 1
                    };
                }
            },
            colWidths: [100, 10, 15, 15],
            enterMoves: function (e) {
                if (instance.getSelected()[0][1] + 1 === (instance.countCols() - 1)) {
                    instance.selectCell((instance.getSelected()[0][0] + 1), 0);
                    return {
                        row: 0,
                        col: 0
                    };
                }
                else {
                    return {
                        row: 0,
                        col: 1
                    };
                }
            },
            manualRowMove: true,
            data: this.spreadsheetData,
            columns: [{
                    title: 'Stage',
                    data: 'name',
                    allowEmpty: false,
                    allowInvalid: false,
                    validator: 'emptyCellValidator',
                    className: 'required',
                },
                {
                    title: 'Colour',
                    data: 'color',
                    allowEmpty: false,
                    allowInvalid: false,
                    className: 'overflow_handsontable',
                    validator: 'emptyCellValidator',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        if (_this.spreadsheetData[row][prop]) {
                            value = _this.spreadsheetData[row][prop];
                        }
                        else {
                            value = '#FFFFFF';
                        }
                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                        var childElement = document.createElement('div');
                        childElement.style.width = '15px';
                        childElement.style.height = '15px';
                        childElement.style.backgroundColor = value;
                        childElement.style.border = '1px solid #E3E3E3';
                        Handsontable.dom.empty(td);
                        td.appendChild(childElement);
                        var icon = document.createElement('i');
                        icon.setAttribute('class', 'fa fa-angle-down');
                        var icondiv = document.createElement('div');
                        icondiv.setAttribute('class', 'select_modified_icon');
                        icondiv.appendChild(icon);
                        td.appendChild(icondiv);
                    },
                    editor: HansontableColor()
                },
                {
                    title: 'Rotting(days)',
                    data: 'rotting',
                    allowEmpty: true,
                    allowInvalid: false,
                    invalidCellClassName: 'highlight-error',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        if (value && value != '') {
                            if (isNaN(value) || value.toString().indexOf('.') > -1 || value < 0) {
                                value = null;
                                _this.toastBox.show('Invalid data', 1000);
                            }
                            else {
                                value = value.toString().substring(0, 9);
                            }
                        }
                        setTimeout(function () {
                            if (_this.spreadsheetData[row]) {
                                _this.spreadsheetData[row].rotting = value;
                            }
                            Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                        }, 300);
                    }
                },
            ],
            // Set column header class names
            afterGetColHeader: function (col, TH) {
                if ((col === 0) && !Handsontable.dom.hasClass(TH, 'col_header_bg')) {
                    Handsontable.dom.addClass(TH, 'col_header_bg');
                }
            },
            beforeOnCellMouseDown: function (e) {
                if (e.target.innerText == 'Delete') {
                    e.stopImmediatePropagation();
                }
            }
        };
        this.checkPermissions();
        this.hotInstances[hotId].updateSettings(this.baseSettings);
        this.hotInstances[hotId].render();
    };
    PipelineStageService.prototype.checkPermissions = function () {
        var _this = this;
        var permissionAccess = ['SetupPipelines.deleteaccess'];
        var allow = this.authService.permitted(permissionAccess) === true;
        if (allow) {
            this.action = {
                title: 'Actions',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var link = document.createElement('a');
                    link.innerText = 'Delete';
                    if (_this.spreadsheetData[row]['id']) {
                        link.style.cursor = 'pointer';
                    }
                    else {
                        link.style.cursor = 'not-allowed';
                    }
                    td.style.cursor = 'not-allowed';
                    td.style.backgroundColor = '#eeeeee';
                    Handsontable.dom.addEvent(link, 'click', function () {
                        _this.onRowDelete(instance, row, col);
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                    return td;
                }
            };
            this.baseSettings['columns'].push(this.action);
        }
    };
    ;
    PipelineStageService.prototype.afterInit = function (hotId, hotInstance) {
        this.hotId = hotId;
        this.hotInstances[hotId] = hotInstance;
    };
    PipelineStageService.prototype.spreadsheetSettings = function () {
        return {};
    };
    PipelineStageService.prototype.onRowDelete = function (instance, row, col) {
        var that = this;
        var targetRow = that.spreadsheetData[row];
        this.row = row;
        this.col = col;
        this.tableInstance = instance;
        if (targetRow.hasOwnProperty('id') && targetRow['id']) {
            that.emitdeleteChangeEvent(targetRow);
        }
        // this.deleteData();
    };
    PipelineStageService.prototype.afterChange = function (changes, source, hotId) {
        var current_instance = this.hotInstances[hotId];
        if (source === 'loadData') {
            current_instance.alter('insert_row', this.spreadsheetData.length);
            current_instance.render();
        }
        if (source === 'edit' || source == 'Autofill.fill' || source == 'UndoRedo.undo') {
            for (var i = 0; i < changes.length; i++) {
                var change = changes[i];
                var rowId = change[0], oldValue = change[2], currentValue = change[3];
                //last row - insert new row
                if (this.spreadsheetData.length === rowId + 1 && source != 'Autofill.fill') {
                    if (this.spreadsheetData[rowId]['name']) {
                        this.hotInstances[hotId].alter('insert_row', this.spreadsheetData.length);
                    }
                }
                //auto save
                if (this.spreadsheetData[rowId]['name'] && oldValue != currentValue) {
                    var value = this.spreadsheetData[rowId]['rotting'];
                    var valid = true;
                    if (value && value != '') {
                        if (isNaN(value)) {
                            valid = false;
                        }
                        else if (value.toString().indexOf('.') > -1) {
                            valid = false;
                        }
                    }
                    if (this.spreadsheetData[rowId]['rotting']) {
                        this.spreadsheetData[rowId]['rotting'] = value.toString().substring(0, 9);
                    }
                    this.emitSaveChangeEvent(this.spreadsheetData[rowId]);
                }
            }
        }
    };
    PipelineStageService.prototype.clearData = function () {
        var instance = this.hotInstances[this.hotId];
        if (instance) {
            Handsontable.hooks.destroy(instance);
        }
        this.hotInstances = [];
        this.hotId = undefined;
        this.spreadsheetData = [];
    };
    PipelineStageService.prototype.deleteData = function () {
        var that = this;
        var targetRow = that.spreadsheetData[that.row];
        if (that.allowDelete == true) {
            if (that.col === (that.tableInstance.getInstance().countCols() - 1)) {
                that.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', this.ajsScope)
                    .then(function () {
                    if (targetRow.hasOwnProperty('id') && targetRow['id']) {
                        that.emitdeleteEvent(targetRow);
                    }
                    that.spreadsheetData.splice(that.row, 1);
                    that.tableInstance.render();
                    var rowsCount = that.tableInstance.countRows();
                    if (rowsCount === 0 || rowsCount === that.row) {
                        that.tableInstance.alter('insert_row', rowsCount);
                        that.tableInstance.render();
                    }
                }, function () {
                    return false;
                });
            }
        }
    };
    PipelineStageService.prototype.allowDeleteMethod = function (param) {
        this.confirmationBoxHelper.hideConfirmationBox();
        this.allowDelete = param;
        if (param == true) {
            this.deleteData();
        }
    };
    PipelineStageService.ngInjectableDef = i0.defineInjectable({ factory: function PipelineStageService_Factory() { return new PipelineStageService(i0.inject(i1.CsToastBoxService), i0.inject("confirmationBoxHelper"), i0.inject("$scope"), i0.inject(i2.AuthService)); }, token: PipelineStageService, providedIn: "root" });
    return PipelineStageService;
}());
export { PipelineStageService };
