import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';
//--
import { ErrorHandlerService } from './error-handler.service';
import { HybridFactory } from '../hybrid-factory';
import { CsVersionService } from '@app/services/utility-services/cs-version.service';
import * as jQuery from 'jquery';
import * as i0 from "@angular/core";
import * as i1 from "./error-handler.service";
import * as i2 from "../../services/utility-services/cs-version.service";
var AppHttpInterceptorService = /** @class */ (function () {
    function AppHttpInterceptorService(errorHandler, csVersionService) {
        this.errorHandler = errorHandler;
        this.csVersionService = csVersionService;
        this.requestsInProcess = [];
    }
    AppHttpInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        this.requestsInProcess.push(request.url);
        if ((request.url.indexOf('check_contract_categories')) > 0 || request.url.indexOf('___noPageloading') > 0) {
            jQuery('#loading-indicator').hide();
        }
        else {
            jQuery('#loading-indicator').show();
        }
        // show loading indicator
        var modifiedRequest = request.clone({
            withCredentials: true,
            setHeaders: { 'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone } // User's local timezone
        });
        // Intercepting Responses
        return next.handle(modifiedRequest)
            .pipe(
        // On Success
        tap(function (event) {
            // Skip other than 'Response' events
            if (event.type !== 4)
                return event;
            _this.removeProcessedRequest(request.url);
            var content = event['data'] || event['body'];
            var csVersion = event.hasOwnProperty('headers') ? event['headers'].get('X-Csversion') : 1;
            var clientId = event.hasOwnProperty('headers') ? event['headers'].get('x-clientid') : null;
            var username = event.hasOwnProperty('headers') ? event['headers'].get('x-username') : null;
            _this.csVersionService.setUserDetails(clientId, username);
            if (!isNaN(parseInt(csVersion)) && csVersion > 1) {
                _this.csVersionService.validateCSVersion(parseInt(csVersion));
            }
            if (content && content.location) {
                if (content.location.endsWith("/"))
                    // redirect to login page
                    HybridFactory.navigate('/');
                else {
                    if (!(request.url.indexOf('voip_user_settings') > 0))
                        HybridFactory.navigate(content.location);
                }
                return throwError(event);
            }
            else
                return event;
        }), 
        // On Error
        catchError(function (errorResponse) {
            //this.removeProcessedRequest(request.url);
            if (errorResponse instanceof HttpErrorResponse) {
                var errorCode = errorResponse.status;
                var errorMessage = errorResponse['error'] || errorResponse['data'] || errorResponse['body'];
                var error = {
                    errorCode: errorCode,
                    errorMessage: errorMessage
                };
                _this.errorHandler.setError(error);
                // Handling auth error
                if (errorCode === 401 || errorCode === 403) {
                    // redirect to login page
                    HybridFactory.navigate('/logout', { errorMessage: errorMessage });
                }
                return throwError(error);
            }
        }), 
        // To handle cancelled when subscriber explicitly unsubscribes
        // It will also call a specified function when the source terminates on complete or error
        finalize(function () { return _this.removeProcessedRequest(request.url); }));
    };
    AppHttpInterceptorService.prototype.removeProcessedRequest = function (url) {
        this.requestsInProcess = this.requestsInProcess.filter(function (r) { return r !== url; });
        // Hide loading indicator
        if (!this.requestsInProcess.length)
            jQuery('#loading-indicator').hide();
    };
    AppHttpInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function AppHttpInterceptorService_Factory() { return new AppHttpInterceptorService(i0.inject(i1.ErrorHandlerService), i0.inject(i2.CsVersionService)); }, token: AppHttpInterceptorService, providedIn: "root" });
    return AppHttpInterceptorService;
}());
export { AppHttpInterceptorService };
