import { ElementRef } from '@angular/core';
var CertificateContainerResizeDirective = /** @class */ (function () {
    function CertificateContainerResizeDirective(_elementRef) {
        this._elementRef = _elementRef;
        this._window = $(window);
    }
    CertificateContainerResizeDirective.prototype.ngAfterViewInit = function () {
        var el = $(this._elementRef.nativeElement).css({ 'overflow': 'hidden', 'overflow-y': 'auto' });
        el.css('height', ((this._window.height() - (el.offset().top)) - 80) + 'px');
        $('.widget-body').height(this._window.height());
    };
    CertificateContainerResizeDirective.prototype.onResize = function (event) {
        var h = this._window.height();
        var el = $(this._elementRef.nativeElement);
        var widgetHeight = h + window.pageYOffset;
        $('.widget-body').height(widgetHeight);
        if (widgetHeight > (el.offset().top)) {
            el.css('height', (widgetHeight - (el.offset().top)) + 'px');
        }
    };
    return CertificateContainerResizeDirective;
}());
export { CertificateContainerResizeDirective };
