export interface MessageInput {
    messageId: number;
    message?: string;
    subject?: string;
    title?: string;
    templateId: number|null;
    _templateIdOld: number|null;
    propertyType: number,
    communicationType: string |'email'| 'sms' | 'letter' ;
}

export interface MessagesObj {
    [key: string]: MessageInput,
}

export class InputModel {
    sourceId: number = 0;
    serviceReminderConfigId: number = 0;
    name: string;
    itemName: string = 'first-letter';
    propertyType: number;
    interval: number;
    messages: MessagesObj = {};
    followUpMessage: FollowUpInputModel[] = [];
    communicationTypes: any = ['email', 'sms', 'letter'];
    screenType: string | 'contract' | 'standard' = 'contract';
    communicationId: number = 0;

    tasksCount: number = 1;
    completedTasksCount: number = 0;
    isActive: boolean = false;
    isFollowUpItem: boolean = false;
    status: string | 'pending' | 'completed' = 'pending';

    _intervalOld: number|null = null;

    constructor(
        sourceId: number,
        serviceReminderConfigId: number,
        communicationId: number,
        name: string,
        propertyType: number,
        screenType: string,
        interval: number|null,
        messages: MessagesObj = {},
        followUpMessage: FollowUpInputModel[] = [],
    ) {
        this.sourceId = sourceId;
        this.serviceReminderConfigId = serviceReminderConfigId;
        this.communicationId = communicationId;
        this.screenType = screenType;
        this.name = name;
        this.propertyType = propertyType;
        this.interval = interval;
        this.messages = messages;
        this.followUpMessage = followUpMessage;
    }

    isFirstCommunication() {
        return this.isFollowUpItem === false;
    }

    isValidInterval() {
        return typeof this.interval === "number";
    }

    getFollowupCount() {
        return this.followUpMessage.length;
    }

    setDefaultMessages(defaultTemplates: object = {}) {

        this.communicationTypes.forEach( (type) => {
            let comTemplate = defaultTemplates[type];
            if(!comTemplate) {
                comTemplate = {};
            }
            this.messages[type] = {
                messageId: 0,
                message: comTemplate['message'] ? comTemplate['message'] : '',
                subject: comTemplate['subject'] ? comTemplate['subject'] : '',
                templateId: comTemplate['templateId'] ? comTemplate['templateId'] : null,
                title: comTemplate['title'] ? comTemplate['title'] : null,
                _templateIdOld: null,
                propertyType: this.propertyType,
                communicationType: type,
            };
        });
        return this;
    }

    addFollowUpInputModel(inputModel: FollowUpInputModel) {
        this.followUpMessage.push(inputModel);
    }

    public setFollowupInputModel(data: object[] = []) {
        data.forEach( (item) => {
            let input = new FollowUpInputModel(
                item['sourceId'],
                item['serviceReminderConfigId'],
                item['communicationId'],
                item['name'],
                item['propertyType'],
                item['screenType'],
                item['interval'],
                item['messages'],
                []
            );
            if(item['followUpOrder']) {
                input.followUpOrder = item['followUpOrder'];
            }
            if(item['_followUpOrderOld']) {
                input._followUpOrderOld = item['_followUpOrderOld'];
            }
            if(item['status']) {
                input.status = item['status'];
            }
            if(item['completedTasksCount']) {
                input.completedTasksCount = item['completedTasksCount'];
            }
            if(item['_intervalOld']) {
                input._intervalOld = item['_intervalOld'];
            }
            input.itemName = item['name'];
            input.isFollowUpItem = true;
            this.addFollowUpInputModel(input);
        });
    }

    setInactive() {
        this.isActive = false;
    }
    setActive() {
        this.isActive = true;
    }

    getName() {
        return this.name;
    }

    getLastInterval() {
        let lastInterval = this.interval;
        if(this.followUpMessage.length > 0) {
            lastInterval = Math.max(...this.followUpMessage.map(o => o.interval ? o.interval : lastInterval))
        }
        return lastInterval;
    }

    getMinInterval(): number {
        let selectedInterval:any[] = this.getAllSelectedIntervals();
        const index = selectedInterval.indexOf(this.interval);
        if (index > -1) {
            selectedInterval.splice(index, 1);
        }
        if(selectedInterval.length > 0) {
            return Math.min(...selectedInterval.map(o => o));
        }
        return -1;
    }

    getAllSelectedIntervals() {
        let selectedInterval = [];
        /*if(this.interval) {
            selectedInterval.push(this.interval);
        }*/
        if(this.followUpMessage.length > 0) {
            this.followUpMessage.forEach( (inputModel:InputModel) => {
                if(inputModel.interval) {
                    selectedInterval.push(inputModel.interval);
                }
            })
        }
        return selectedInterval;
    }

    removeFollowupItem(index:number) {
        this.followUpMessage.splice(index, 1)
    }
}

export class FollowUpInputModel extends InputModel {
    followUpOrder: number = 0;
    _followUpOrderOld: number = 0;
}

