<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>

    <div class="modal-body ng-workflow">
        <div class="help_section mb-0">
            <div class="p-15">{{helpText}}</div>
        </div>
        <form *ngIf="form" [formGroup]="form" class="no-form-style" (ngSubmit)="save()" id="ngForm">
            <div class="item form-item p-15">
                <div class="control-group clearfix" *ngIf="type==='email'">
                    <label class="control-label required_field">Email:</label>
                    <div class="controls">
                        <input type="text" class="control-input mb-0" formControlName="emailaddress">
                    </div>
                    <div *ngIf="f.emailaddress.invalid && (f.emailaddress.dirty || f.emailaddress.touched)">
                        <span *ngIf="f.emailaddress.errors.required" class="control-error-message show">
                            <span translate>Please.Enter.Valid.Email</span>
                        </span>

                        <span *ngIf="f.emailaddress.errors.email" class="control-error-message show">
                            <span translate>Invalid.Email.Address</span>
                        </span>
                    </div>
                </div>

                <div class="control-group clearfix phone-widget-wrapper" *ngIf="type==='mobile'">
                    <label class="control-label required_field">Mobile:</label>
                    <div class="controls">
                        <div class="phone-number-wrapper dropdown">
                            <span app-cs-phonewidget [labelName]="''" [selectedCode]="" (valueChanged)="onChangeCountryCode($event)"></span>
                            <input type="text" class="phone-number-input mb-0" placeholder="" formControlName="mobile">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Cancel</a>
        <button class="btn btn-primary" [disabled]="!form.valid || saving" type="submit" form="ngForm">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</ng-container>
