import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit, OnDestroy, HostListener,Inject
} from '@angular/core';
import {Subject} from "rxjs/index";
import {SmartTableInstanceService} from "@app/smart_table/services/smarttable.instance.service";
import {SmartTableMetaDataService} from "@app/smart_table/services/metadata.service";
import {DataTableDirective} from "angular-datatables";
import {AuthService, SettingsService} from "@app/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { HybridFactory } from "@app/core/hybrid-factory";
import {Router} from "@angular/router";

declare var $;

export interface SettingsActionButtonsInterface {
    title: string,
    permissions: any[],
    callBack: Function,
    visible: Function
}

@Component({
  selector: 'app-settings-table',
  templateUrl: './settings-table.component.html',
  providers: [SmartTableMetaDataService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./settings-table.component.scss']
})
export class SettingsTableComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input() apiOptions: any[] = [];
    @Input() metadata: any;
    @Input() actions: Array<SettingsActionButtonsInterface> = [];
    @Input() rowReorder: any = false;
    @Input() rowSelect = false;
    @Input() data: any[] = [];
    @Input() category = '';
    @Input() emptyMessage: string = 'No records found';
    @Input() defaultEmptyMessage: string = 'No records found';
    @Input() dataUrl = '';
    @Input() actionClass = 'actions-header-column';
    @Input() tdAdditionalClass = false;
    @Input() isExternalAPI = false;
    @Input() rowSelectable = false;
    @Input() processing = false;
    @Input() searchMessage = '';
    @Input() isPostMethod: boolean = false;

  @Output() $instance: EventEmitter<any> = new EventEmitter();
  responseData: any;
  @Output() $dataCount: EventEmitter<any> = new EventEmitter();
  @Output() $selectedRow: EventEmitter<any> = new EventEmitter();
  @Output() rowReorderCallBack: EventEmitter<any> = new EventEmitter();
  @ViewChild(DataTableDirective, { static: false } as any) datatableElement: DataTableDirective;
  @Output() selectedCheckBoxValue: EventEmitter<any> = new EventEmitter();
  @Output() overAllSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectChanges: EventEmitter<any> = new EventEmitter();

  columns: any[] = [];
  limit: number = 20;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  toggleData: object = {};
  columnsCount: number = 0;
  loading: boolean = true;
  selectAllCheckbox: boolean = false;
  _externalParams:any=null;
  settingsApi:any=null;
  tableId:any='';
  totalRecordCount: number = 0;
  loadingFirstTime:boolean = true;

  constructor(
    private metadataService: SmartTableMetaDataService,
    private authService: AuthService,
    private http: HttpClient,
    private jwtToken: SettingsService,
    @Inject('ajsState') private ajsState,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.metadataService.initiaze(this.metadata);
    this.columns = this.metadataService.getColumns();
    this.limit = this.metadataService.getLimit();
    this.tableId = Math.random().toString(36).substr(2, 9);
    this.renderTable();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    let that = this;
    this.$instance.emit(this);
    this.datatableElement.dtInstance.then((dtInstance:DataTables.Api) => {
        const table:any = dtInstance.table('#cs-settings-table-v2-'+this.tableId);
        let diff$:any[]=[];
        table.on('row-reorder', ( e:any, diff:any, edit:any) => {
            //console.log('table', edit.triggerRow.data());
            for ( var i=0, ien=diff.length ; i<ien ; i++ ) {
                let rowData = table.row( diff[i].node ).data();
                diff$.push({
                    id: rowData.id,
                    position: [diff[i].newPosition, diff[i].oldPosition],
                    order: diff[i].newPosition
                })
            }
            //console.log('diff', diff);
            if(diff.length===0) {
                dtInstance.draw(false);
            }
            if(diff.length && that.rowReorder) {
                //that.rowReorder(diff$, edit.triggerRow.data(), that);
                that.rowReorderCallBack.emit({
                    diff: diff$,
                    affectedRow:edit.triggerRow.data(),
                    instance: that
                })
            }
            diff$=[];
        });
        $('#cs-settings-table-v2-' + this.tableId + ' tbody').on('change', 'select', function (e: any) {
            let data = table.row($(this).parents('tr')).data(), index = table.row($(this).parents('tr')).index();
            const selectedValue = $(e.target).val();
            that.onSelectChanges.emit({ dropDownChange: selectedValue, index: table.row($(this).parents('tr')).index() });
        });
        $('#cs-settings-table-v2-'+this.tableId+' tbody').on( 'click', 'a.link-action-btn, button.link-action-btn', function (e:any) {
            e.preventDefault();
            let data = table.row( $(this).parents('tr') ).data(), index=table.row($(this).parents('tr')).index();
            let actionProp:any = that.actions.find( (x:any) => x.id == $(this).attr('action-id'));
            if(actionProp && actionProp.hasOwnProperty('onClick') && typeof actionProp.onClick === 'function') {
                //console.log(data, dtInstance.row($(this).parents('tr')).index(), actionProp);
                $(e.currentTarget).blur();
                if(actionProp.hasOwnProperty('actionType') && actionProp['actionType'] == 'route') {
                    const routeOptions = actionProp.onClick(data);
                    if(routeOptions.hasOwnProperty('state') && typeof routeOptions['state'] == 'string') {
                        that.ajsState.transitionTo(
                            routeOptions['state'],
                            routeOptions['params']
                        );
                    }
                    else {
                        that.router.navigate([routeOptions['router'], routeOptions['params']]);
                    }
                }
                else {
                    e.stopPropagation();
                    actionProp.onClick(data, dtInstance.row($(this).parents('tr')).index(),that, $(this).parents('tr'));
                }
            }
        });
    });

    $(window).on('resize', () => {
      that.datatableElement.dtInstance.then((dtInstance: any) => {
        dtInstance.columns.adjust();
      });
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private defaultProps() {
    if (this.rowReorder) {
      this.data.map((item, index) => {
        item['@empty_rowReOrder'] = index + 1;
        return item;
      });
    }
    return {
      dom: 'rtipl',
      lengthMenu: [[5, 10, 20, 50, 100], [5, 10, 20, 50, 100]],
      autoWidth: false,
      serverSide: false,
      sPaginationType: "bootstrap",
      processing: false,
      pagingType: 'full_numbers',
      scrollCollapse: true,
      scrollX: true,
      colReorder: false,
      aaSorting: [],
      select: false,
      info: true,
      bLengthChange: true,
      responsive: false,
      data: this.data,
      initComplete: (settings, json) => {
          setTimeout(() => {
              $(window).trigger('resize');
          },0);
      }
    };
  }

  renderActions(value, type, row) {
    let actions: string = '';
    this.actions.forEach((action: any) => {
      let id: string = !action.id ? action.title : action.id;
      action.access = !action.hasOwnProperty('access') ? [] : action.access;
      let allow = this.authService.permitted(action.access) === true;
      if (allow && action.hasOwnProperty('visible') && typeof action['visible'] == 'function') {
           let cb = action['visible'];
           allow = cb(value, type, row);
      }
      if (allow) {
        if (action.hasOwnProperty('render') && typeof action.render == 'function') {
          actions += action.render(value, type, row);
        }
        else {
            if(action.hasOwnProperty('actionType') && action['actionType'] == 'route') {
                const routeOptions = action.onClick(row);
                let $URL = ''
                if(routeOptions.hasOwnProperty('state') && typeof routeOptions['state'] == 'string') {
                    let statePrams = routeOptions.hasOwnProperty('params') ? routeOptions['params'] : {};
                    $URL = HybridFactory.ngUpgrade.$injector.get('$state').href(routeOptions['state'], statePrams);
                }
                else {
                    $URL = '/'+routeOptions['router'];
                }
                actions += '<a tabindex="-1" href="'+$URL+'" action-id="' + id + '" class="link-action-btn cs-setting-table"> ' + action.title + ' </a> ';
            }
            else {
                actions += '<button tabindex="-1" action-id="' + id + '" class="link-action-btn cs-setting-table"> ' + action.title + ' </button> ';
            }
        }
      }
    });
    return actions;
  };

    renderTable(): void {
        const that = this;
        const options: any = this.defaultProps();
        options.pageLength = this.limit;
        options.columns = this.prepareColumns();
        options.order = [];
        let colsDefs = this.metadataService.getColumnsDefs();
        if(colsDefs.length) {
            options.columnDefs = colsDefs;
        }
        if (!options.columns.length) {
            throw new Error(`Columns must not be empty.`);
        }
        options.language = {};
        if (this.emptyMessage) {
            options.language['emptyTable'] = this.emptyMessage;
        }
        options.language['processing'] = '<div class="spreadsheet-loading-wrap">\n' +
            ' <span class="loading-spreadsheet">\n' +
            '  <span class="loading-x-axis"></span>\n'  +
            '  <span class="loading-y-axis"></span>\n'  +
            '  <div class="loading-row row-1"></div>\n' +
            '  <div class="loading-row row-2"></div>\n' +
            '  <div class="loading-row row-3"></div>\n' +
            '  <div class="loading-row row-4"></div>\n' +
            '  <div class="loading-row row-5"></div>\n' +
            ' </span>\n' +
            '</div>';
        options.drawCallback = (settings) => {
            this.settingsApi = settings;
        };
        if (this.rowReorder) {
            options.rowReorder = {
                dataSrc: '@empty_rowReOrder',
                update: false,
                snapX: 1
            };
        }
        if (this.dataUrl !== '') {
            // options.ajax
            options.serverSide = true;
            options.processing = this.processing;
            options.ajax = (dataTablesParameters: any, callback) => {
                const params = that.paramsTransform(dataTablesParameters);
                let getData: Observable<any>;
                if (typeof this.apiOptions == 'object' && this.apiOptions.length > 0) {
                    const method = this.apiOptions[0];
                    const apiUrl = this.apiOptions[1];
                    if (method == 'post') {
                        getData = that.http.post(that.getApiUrl(apiUrl, null), params);
                    }
                    else {
                        getData = that.http.get(that.getApiServiceUrl(apiUrl, null), { params });
                    }
                }
                else {
                    if (!this.isPostMethod) {
                        if (!this.isExternalAPI) {
                            getData = that.http.get(that.getApiUrl(that.dataUrl, null), { params: params });
                        } else {
                            const url = this.dataUrl;
                            getData = this.http.get(that.getContractApiUrl(that.dataUrl, null), {
                                params: params
                            });
                        }
                    }
                    else {
                        getData = that.http.post(that.getApiUrl(that.dataUrl, null), params);
                    }
                }
                
                getData.subscribe((resp: any) => {
                    const _resonseDatas = this.getDescendantProp(resp, this.category);
                    this.data = _resonseDatas;
                    if (_resonseDatas) {
                        this.responseData = resp;
                        this.$instance.emit(this);
                    }
                    if (resp.count == 0) {
                        $('#cs-settings-table-v2-' + this.tableId + '_info').hide();
                        $('#cs-settings-table-v2-' + this.tableId + '_paginate').hide();
                        $('#cs-settings-table-v2-' + this.tableId + '_length').hide();
                    } else {
                        $('#cs-settings-table-v2-' + this.tableId + '_info').show();
                        $('#cs-settings-table-v2-' + this.tableId + '_paginate').show();
                        $('#cs-settings-table-v2-' + this.tableId + '_length').show();
                    }
                    this.$dataCount.emit(resp.count);
                    callback({
                        recordsTotal: parseInt(resp.count),
                        recordsFiltered: parseInt(resp.count),
                        data: _resonseDatas
                    });
                    if(that.loadingFirstTime && resp.count == 0) {
                        that.loadingFirstTime=true;
                    }
                    else {
                        that.loadingFirstTime=false;
                    }
                    that.loading = false;
                    this.totalRecordCount = parseInt(resp.count);
                    $('#cs-settings-table-v2-' + this.tableId + ' tbody').on('click', 'tr', (event) =>
                        this.selectedRow(event, true)
                    );
                    $('#cs-settings-table-v2-' + this.tableId + ' tbody').on('click', 'td:first-child', (event) =>{
                        let position=$(event.currentTarget).parent('tr').index();
                        if($(event.target).hasClass('file-checkbox') && (event.target.checked==true)){
                            $(`#cs-settings-table-v2-${this.tableId} tbody tr td:first-child input`).eq(position).addClass('checked-true');
                            $(`#cs-settings-table-v2-${this.tableId} tbody tr`).eq(position).addClass('checked-row');
                            this.selectedCheckBox(event, true)
                        }
                        if($(event.target).hasClass('file-checkbox') && (event.target.checked==false)){
                            $(`#cs-settings-table-v2-${this.tableId} tbody tr td:first-child input`).eq(position).removeClass('checked-true');
                            $(`#cs-settings-table-v2-${this.tableId} tbody tr`).eq(position).removeClass('checked-row');
                            this.selectedCheckBox(event, false)
                        }
                    });
                    // if (this.tdAdditionalClass == true) {
                    //     $(`#cs-settings-table-v2-${this.tableId} tbody td`).addClass('td-background');
                    // }
                    setTimeout(() => {
                        $(window).trigger('resize');
                    },0)
                });
            };
        }
        this.dtOptions = options;
    }

  @HostListener('window:keydown.arrowdown', ['$event'])
  public moveDown($event) {
    if (this.rowSelectable) {
      $event.preventDefault();
      this.selectedRow('', false, 'down');
    }
  }
  @HostListener('window:keydown.arrowup', ['$event'])
  public moveUp($event) {
    if (this.rowSelectable) {
      $event.preventDefault();
      this.selectedRow('', false, 'up');
    }
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      setTimeout(() => {
          $(window).trigger('resize');
      },250)
    }

    selectedRow(event = null, eventBased = false, keyDirection = null) {
        if (this.rowSelectable) {
            let selectedRowIndex: any = '';
            if (eventBased) {
                if ($(event.target).is('a')) {
                    return false;
                }
                selectedRowIndex = $(event.currentTarget).index();
            } else {
                if (keyDirection == 'up') {
                    selectedRowIndex = $('.selected-row').prev().index();
                } else if (keyDirection == 'down') {
                    selectedRowIndex = $('.selected-row').next().index();
                }
            }
            if (selectedRowIndex >= 0) {
                $('.selected-row').removeClass('selected-row');
                $(`#cs-settings-table-v2-${this.tableId} tbody tr`).eq(selectedRowIndex).addClass('selected-row');
                this.$selectedRow.emit(this.data[selectedRowIndex]);
            }
        }
    }

  getDescendantProp(obj, cat) {
    var arr = cat.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }

    paramsTransform(params: any) {
        let tableParams = {}, page = 1;
        if (params.length) {
            tableParams['limit'] = params.length;
        }
        tableParams['page'] = page + (params.start / params.length);
        // tableParams['category'] = this.category;
        const externalParams = this._externalParams;
        // this._externalParams=null;
        if (externalParams) {
            tableParams = Object.assign({}, tableParams, externalParams);
            if (externalParams.hasOwnProperty('fromTrigger')) {
                this._externalParams = null;
            }
        }
        if (params['order'] && params['order'].length) {
            const orders: any[] = params['order'];
            const sort: any[] = [];
            orders.forEach(item => {
                const $cols = params['columns'][item['column']];
                sort.push({
                    'column': $cols['data'],
                    'dir': item['dir']
                });
            });
            if (sort.length) {
                tableParams['order'] = encodeURIComponent(JSON.stringify(sort));
            }
        }
        return tableParams;
    }

    private prepareColumns() {
        if (this.rowSelect) {
            this.columns.unshift(this.checkBoxColumn());
        }
        if (this.rowReorder) {
            this.columns.unshift(this.rowReorderColumn());
        }
        if (this.actions.length) {
            this.columns.push(this.actionsBtnColumn());
        }
        this.columns.forEach((col, index) => {
            col.columnIndex = index;
        });
        this.columnsCount = this.columns.length;
        return this.columns;
    }

  private checkBoxColumn() {
    return {
      data: '@empty_select',
      title: '',
      className: 'select-checkbox-all',
      orderable: false,
      visible: true,
      width: '10px'
    };
  }

    private rowReorderColumn() {
        return {
            data: '@empty_rowReOrder',
            title: '',
            className: 'row-reorder-col reorder',
            orderable: false,
            visible: true,
            width: '6px',
            render: () => '<span class=""><img src="/images/reorder_icon.png" alt="..."/></span>'
        };
    }

  private actionsBtnColumn() {
    return {
      data: '@empty_actions',
      title: 'Actions',
      className: this.actionClass,
      orderable: false,
      visible: true,
      width: '16px',
      defaultContent: '',
      render: (value, type, row) => this.renderActions(value, type, row)
    };
  }

  onSelectAllRows(): void {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    //this.smartTableInstance.checkUncheckAllRows(this.selectAllCheckbox);
  }

  onSelectRow(data: any, col: any) {
    data.selected = !data.selected;
  }

  updateData(newData: any, paging: boolean = true) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear();
      dtInstance.rows.add(newData);
      dtInstance.draw(paging);
    })
  }

  updateRow(newData: any, index: number, paging: boolean = true, doRedraw:boolean=true) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.row(index).data(newData).draw(paging);
    })
  }

  addRow(newData: any, paging: boolean = true,redirectPage:string = 'last') {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.row.add(newData).draw(paging);
      dtInstance.page(redirectPage).draw(false)
    })
  }

  removeRow(index: number, paging: boolean = true) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.row(index).remove();
      dtInstance.draw(paging);
    })
  }

  reload(params:any = null, resetPaging:boolean=true) {
      this._externalParams = params;
      //console.log(this._externalParams);
      setTimeout( () => {
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.clear();
              dtInstance.ajax.reload(null, resetPaging);
          });
      },100);
  }

  getApiUrl(path: string, params: any) {
    let url = environment.apiHost + environment.apiBasePath + path;
    if (params instanceof Object) {
      url += '?page=' + params.page + '&limit=' + params.limit;
    }
    return url;
  }
  getContractApiUrl(path: string, params: any) {
    let url = environment.apiHost + environment.microserviceApiContract + path;
    if (params instanceof Object) {
      url += '?page=' + params.page + '&limit=' + params.limit;
    }
    return url;
  }

    updatePageLimit(pageNumber) {
        this._externalParams = this._externalParams ? this._externalParams : {};
        this._externalParams['page'] = pageNumber;
        this._externalParams['limit'] = this.limit;
        this._externalParams['fromTrigger'] = true;
    }

    triggerCountChange(action: string = 'add') {
        let currentCount = this.totalRecordCount;
        let pageToLoad = 0;
        if(action == 'add') {
            currentCount += 1;
        } else if (action == 'remove') {
            currentCount -= 1;
        }
        let that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            if(action == 'remove') {
                let currentData = dtInstance.data();
                let currentPageCount = currentData ? currentData.length : 0;
                if(currentPageCount == 1) {
                    currentCount -= 1;
                    pageToLoad = Math.ceil((currentCount) / this.limit);
                    if(pageToLoad == 0){
                        pageToLoad = 1;
                    }
                    that.updatePageLimit(pageToLoad);
                    dtInstance.ajax.reload(function() {dtInstance.page('last').draw('page');}, true);
                } else {
                    dtInstance.ajax.reload(null, true);
                }
            } else {
                pageToLoad = Math.ceil((currentCount) / this.limit);
                that.updatePageLimit(pageToLoad);
                dtInstance.ajax.reload(function() {dtInstance.page('last').draw('page');}, true);
            }
        })
    }
    selectedCheckBox(event = null, eventBased = false){
        let selectedRowIndex: any = '';
        selectedRowIndex = $(event.currentTarget).parent('tr').index();
        let singleEvent=this.data[selectedRowIndex];
        singleEvent['indexValue']=selectedRowIndex;
        singleEvent['isChecked']=$(event.target).closest('.file-checkbox').prop('checked');
        this.selectedCheckBoxValue.emit(singleEvent);
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        let currentEle: any = $(event.target);
        if(currentEle.closest('.check-all').length){
            $(".file-checkbox").prop('checked', false);
            $('.file-checkbox').removeClass('checked-true');
            $(`#cs-settings-table-v2-${this.tableId} tbody tr`).removeClass('checked-row');
            let checkboxVal = currentEle.closest('.check-all').prop('checked');
            $(".file-checkbox:lt(10)").prop('checked', checkboxVal);
            this.overAllSelect.emit(checkboxVal);

            if(checkboxVal){
                $(`#cs-settings-table-v2-${this.tableId} tbody tr:lt(10)`).addClass('checked-row');
               $('.file-checkbox:lt(10)').addClass('checked-true');
               $('.file-checkbox').not('.checked-true').attr('disabled',true);
            }else{
                $('.file-checkbox').removeClass('checked-true');
                $(`#cs-settings-table-v2-${this.tableId} tbody tr`).removeClass('checked-row');
                $('.file-checkbox').not('.checked-true').attr('disabled',false);
            }
        }
    }

    getApiServiceUrl(path: string, params: any) {
        let url = path;
        if (params instanceof Object) {
          url += '?page=' + params.page + '&limit=' + params.limit;
        }
        return url;
      }
}
