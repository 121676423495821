import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    CsDropDownItemTemplatesComponent
} from '@app/shared/cs-components/cs-drop-down/cs-drop-down-item-templates/cs-drop-down-item-templates.component';
import {
    CsDropDownTogglerTemplatesComponent
} from '@app/shared/cs-components/cs-drop-down/cs-drop-down-toggler-templates/cs-drop-down-toggler-templates.component';
import {CsDropDownComponent} from '@app/shared/cs-components/cs-drop-down/cs-drop-down.component';
import {CsFilterPanelComponent} from '@app/shared/cs-components/cs-filter-panel/cs-filter-panel.component';
import {CsQuickFilterComponent} from '@app/shared/cs-components/cs-quick-filter/cs-quick-filter.component';
import {CsSearchBarComponent} from '@app/shared/cs-components/cs-search-bar/cs-search-bar.component';
import {CsPositionFixerDirective} from './cs-directives/cs-position-fixer.directive';
import {CsPaginationPanelComponent} from '@app/shared/cs-components/cs-pagination-panel/cs-pagination-panel.component';
import {CsNavBarComponent} from '@app/shared/cs-components/cs-nav-bar/cs-nav-bar.component';
import {RouterModule} from '@angular/router';
import {CsNavTabComponent} from '@app/shared/cs-components/cs-nav-tab/cs-nav-tab.component';
import {
    CsAgGroupCellRendererComponent
} from './cs-ag-grid/cs-ag-grid-components/cs-ag-group-cell-renderer/cs-ag-group-cell-renderer.component';
import {CsListViewLayoutComponent} from './cs-list-view-layout/cs-list-view-layout.component';
import {
    SpItemListWidgetComponent
} from "@app/shared/cs-components/cs-widgets/sp-item-list-widget/sp-item-list-widget.component";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {PipesModule} from "@app/pipes";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives";
import {CommonModalPopupsComponent} from "@app/shared/cs-components/common-modal-popups/common-modal-popups.component";
import {CommonFsModalComponent} from "@app/shared/cs-components/common-fs-modal/common-fs-modal.component";
import {
    CommusoftFormMarketplaceComponent
} from "@app/shared/cs-components/commusoft-form-marketplace/commusoft-form-marketplace.component";
// import {FilterConfiguratorComponent} from "@app/shared/cs-components/filter-configurator/filter-configurator.component";
import {NoDataFoundComponent} from "@app/shared/cs-components/no-data-found/no-data-found.component";
import {
    PortalEmailTemplatesComponent
} from "@app/shared/cs-components/portal-email-templates/portal-email-template.component";
import {
    PortalsContactSupportCommunicationComponent
} from "@app/shared/cs-components/portals-contact-support-communication/portals-contact-support-communication.component";
import {SpreadsheetLoaderComponent} from "@app/shared/cs-components/spreadsheet-loader/spreadsheet-loader.component";
import {ViewAttachFilesComponent} from "@app/shared/cs-components/view-attach-files/view-attach-files.component";
import {WhoIsViewingComponent} from "@app/shared/cs-components/who-is-viewing/who-is-viewing.component";
import {SendEmailToComponent} from "@app/shared/cs-components/send-email-to/send-email-to.component";
import {ShareDetailsComponent} from "@app/shared/cs-components/share-details/share-details.component";
// import {AddTeamMemberComponent} from "@app/shared/cs-components/team-member/add-team-member.component";
import {TranslateModule} from "@ngx-translate/core";
import {ModalModule, PopoverModule, TooltipModule} from "ngx-bootstrap";
import {SmartTableModule} from "@app/smart_table";
import {CommonFolderStructureModule} from "@app/shared/common-folder-structure/common-folder-structure.module";
import {CommonService} from "@app/shared/cs-components/common-service/common-service";
import {ListItemComponent} from "@app/shared/cs-components/views/list-item/list-item.component";
import {ViewsSidebarComponent} from "@app/shared/cs-components/views/views-sidebar/views-sidebar.component";
// import {CardPanelComponent} from "@app/shared/cs-components/card-panel/card-panel.component";
import {TimelineLoaderComponent} from "@app/shared/cs-components/timeline-loader/timeline-loader.component";
import {FilterComponent} from "@app/shared/cs-components/filter/filter.component";
// import {PropertyCardComponent} from "@app/shared/cs-components/property-card/property-card.component";
import {KanbanBoardComponent} from "@app/shared/cs-components/kanban-board/kanban-board.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {DndModule} from "ngx-drag-drop";
import {OpportunityModalsModule} from "@app/shared/opportunity-modals/opportunity-modals.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { FilterConfiguratorOneComponent } from './filter-configurator-1/filter-configurator-1.component';
import { OpportunitiesSidebarComponent } from './views/opportunities-sidebar/opportunities-sidebar.component';

@NgModule({
    declarations: [
        CsDropDownItemTemplatesComponent,
        CsDropDownTogglerTemplatesComponent,
        CsDropDownComponent,
        CsFilterPanelComponent,
        CsQuickFilterComponent,
        CsSearchBarComponent,
        CsPositionFixerDirective,
        CsPaginationPanelComponent,
        CsNavBarComponent,
        CsNavTabComponent,
        CsAgGroupCellRendererComponent,
        CsListViewLayoutComponent,
        SpItemListWidgetComponent,
        CommonModalPopupsComponent,
        CommonFsModalComponent,
        CommusoftFormMarketplaceComponent,
        // FilterConfiguratorComponent,
        NoDataFoundComponent,
        PortalEmailTemplatesComponent,
        PortalsContactSupportCommunicationComponent,
        SpreadsheetLoaderComponent,
        ViewAttachFilesComponent,
        WhoIsViewingComponent,
        SendEmailToComponent,
        ShareDetailsComponent,
        // AddTeamMemberComponent,
        ListItemComponent,
        ViewsSidebarComponent,
        OpportunitiesSidebarComponent,
        // CardPanelComponent,
        TimelineLoaderComponent,
        FilterComponent,
        // PropertyCardComponent,
        KanbanBoardComponent,
        FilterConfiguratorOneComponent
    ],
    exports: [
        CsPositionFixerDirective,
        CsPaginationPanelComponent,
        CsNavBarComponent,
        CsNavTabComponent,
        CsListViewLayoutComponent,
        SpItemListWidgetComponent,
        CsDropDownComponent,
        CsFilterPanelComponent,
        CsQuickFilterComponent,
        CsSearchBarComponent,
        CommonModalPopupsComponent,
        CommonFsModalComponent,
        CommusoftFormMarketplaceComponent,
        // FilterConfiguratorComponent,
        NoDataFoundComponent,
        PortalEmailTemplatesComponent,
        PortalsContactSupportCommunicationComponent,
        SpreadsheetLoaderComponent,
        ViewAttachFilesComponent,
        WhoIsViewingComponent,
        SendEmailToComponent,
        ShareDetailsComponent,
        // AddTeamMemberComponent,
        ListItemComponent,
        ViewsSidebarComponent,
        OpportunitiesSidebarComponent,
        // CardPanelComponent,
        TimelineLoaderComponent,
        FilterComponent,
        // PropertyCardComponent,
        KanbanBoardComponent,
        FilterConfiguratorOneComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BsDropdownModule,
        BsDatepickerModule,
        PipesModule,
        DirectivesModule,
        ReactiveFormsModule,
        TranslateModule,
        PopoverModule,
        TooltipModule,
        SmartTableModule,
        CommonFolderStructureModule,
        DragDropModule,
        DndModule,
        ModalModule.forRoot(),
        OpportunityModalsModule,
        InfiniteScrollModule
    ],
    providers: [
        CommonService
    ]
})
export class CsComponentsModule {
}
