/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../layouts/communication-layout.component.ngfactory";
import * as i4 from "../layouts/communication-layout.component";
import * as i5 from "../services/communication-http.service";
import * as i6 from "../services/communication-dispatcher.service";
import * as i7 from "../services/communication-helper.service";
import * as i8 from "./communication-container.component";
import * as i9 from "ngx-bootstrap/modal";
var styles_CommunicationContainerComponent = [];
var RenderType_CommunicationContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommunicationContainerComponent, data: {} });
export { RenderType_CommunicationContainerComponent as RenderType_CommunicationContainerComponent };
function View_CommunicationContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "cs-help-text-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.helpText; _ck(_v, 3, 0, currVal_1); }); }
export function View_CommunicationContainerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "modal-header"]], [[4, "box-shadow", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "h4", [["class", "modal-title"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(4, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(5, null, ["", " "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommunicationContainerComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "ml-auto cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-times"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-communication-layout", [["class", "h-100 d-block"]], null, [[null, "$instance"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("$instance" === en)) {
        var pd_0 = (_co.setLayoutComponent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CommunicationLayoutComponent_0, i3.RenderType_CommunicationLayoutComponent)), i0.ɵdid(14, 245760, null, 0, i4.CommunicationLayoutComponent, [i5.CommunicationHttpService, i6.CommunicationDispatcherService, i7.CommunicationHelperService], { objectName: [0, "objectName"], isCustomerSpecific: [1, "isCustomerSpecific"], allowSegmentation: [2, "allowSegmentation"], communicationScreenType: [3, "communicationScreenType"], objectId: [4, "objectId"], objectTypeId: [5, "objectTypeId"], categoryType: [6, "categoryType"], selectedTemplateData: [7, "selectedTemplateData"], viewMode: [8, "viewMode"] }, { $instance: "$instance" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_4 = !!_co.helpText; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.objectName; var currVal_6 = _co.isCustomerSpecific; var currVal_7 = _co.allowSegmentation; var currVal_8 = _co.communicationScreenType; var currVal_9 = _co.objectId; var currVal_10 = _co.objectTypeId; var currVal_11 = _co.categoryType; var currVal_12 = _co.selectedTemplateData; var currVal_13 = _co.viewMode; _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.categoryType; _ck(_v, 7, 0, currVal_3); }); }
export function View_CommunicationContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-communication-container", [], null, null, null, View_CommunicationContainerComponent_0, RenderType_CommunicationContainerComponent)), i0.ɵdid(1, 245760, null, 0, i8.CommunicationContainerComponent, [i9.BsModalRef, i6.CommunicationDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommunicationContainerComponentNgFactory = i0.ɵccf("app-communication-container", i8.CommunicationContainerComponent, View_CommunicationContainerComponent_Host_0, {}, {}, []);
export { CommunicationContainerComponentNgFactory as CommunicationContainerComponentNgFactory };
