/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cs-signature-generator.component";
var styles_CsSignatureGeneratorComponent = [];
var RenderType_CsSignatureGeneratorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsSignatureGeneratorComponent, data: {} });
export { RenderType_CsSignatureGeneratorComponent as RenderType_CsSignatureGeneratorComponent };
function View_CsSignatureGeneratorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "label", [["class", "sig-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSignatureSelection(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "sig-active": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(5, { "font-family": 0, "font-size": 1 }), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sig-container"; var currVal_1 = _ck(_v, 2, 0, (_co.currentIndex == _v.context.index)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _v.context.$implicit.FontFamily, _v.context.$implicit.FontSize); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.signatureText; _ck(_v, 6, 0, currVal_3); }); }
function View_CsSignatureGeneratorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "cs-signature-generator"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsSignatureGeneratorComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CsSignatureGeneratorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["style", "border:1px solid black"]], [[8, "src", 4], [8, "width", 0], [8, "height", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.base64Image; var currVal_1 = _co.imageWidth; var currVal_2 = _co.imageHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CsSignatureGeneratorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsSignatureGeneratorComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.base64Image; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CsSignatureGeneratorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { canvasEl: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["canvasEl", 1]], null, 0, "canvas", [["style", "display: none;background: #FFFFFF !important;"]], [[8, "width", 0], [8, "height", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsSignatureGeneratorComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsSignatureGeneratorComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.dataSource.length > 1); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.showSignature; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageWidth; var currVal_1 = _co.imageHeight; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CsSignatureGeneratorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cs-signature-generator", [], null, null, null, View_CsSignatureGeneratorComponent_0, RenderType_CsSignatureGeneratorComponent)), i0.ɵdid(1, 4308992, null, 0, i2.CsSignatureGeneratorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsSignatureGeneratorComponentNgFactory = i0.ɵccf("app-cs-signature-generator", i2.CsSignatureGeneratorComponent, View_CsSignatureGeneratorComponent_Host_0, { signatureText: "signatureText", dataSource: "dataSource", imageHeight: "imageHeight", imageWidth: "imageWidth", showSignature: "showSignature" }, { signatureAsBase64: "signatureAsBase64" }, []);
export { CsSignatureGeneratorComponentNgFactory as CsSignatureGeneratorComponentNgFactory };
