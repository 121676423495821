<form *ngIf="settingsForm" [formGroup]="settingsForm" (submit)="save()">
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>

			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<ng-container *ngIf="tagElt=='pricingtable' || tagElt == 'table'">
						<div class="flex-row">
							<div class="bold m-10"  translate>Header</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Capitalisation</div>
							<div class="w-30">
								<select formControlName="capitalisation" style="width: 100%;">
									<option value="capitalize" >Title case</option>
									<option value="None">None</option>
									<option value="uppercase">UPPER CASE</option>
									<option value="lowercase">lower case</option>
								</select>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Line.spacing</div>
							<div class="w-30">
								<select formControlName="lineSpacing" style="width: 100%;">
									<option value="1">1</option>
									<option value="1.25">1.25</option>
									<option value="1.5">1.5</option>
									<option value="2">2</option>
								</select>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Text.alignment</div>
							<div class="w-30">
								<div class="btn-group btn-group-justified" style="width: 100%;">
									<button type="button" (click)="chooseAlign('left')" class="btn btn-default left"><i class="fas fa-align-left" style="color: #505B65;"></i></button>
									<button type="button" (click)="chooseAlign('right')" class="btn btn-default right"><i class="fas fa-align-right" style="color: #505B65;"></i></button>
									<button type="button" (click)="chooseAlign('center')" class="btn btn-default center"><i class="fas fa-align-center" style="color: #505B65;"></i></button>
									<button type="button" (click)="chooseAlign('justify')" class="btn btn-default justify"><i class="fas fa-align-justify" style="color: #505B65;"></i></button>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="tagElt=='pricingtable' || tagElt == 'table'">
						<div class="flex-row">
							<div class="bold m-10"  translate>Borders</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Show.horizontal.borders</div>
							<div class="w-30">
								<label class="switch">
									<input type="checkbox" formControlName="hBorder">
									<span class="slider round"></span>
								</label>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Show.vertical.borders</div>
							<div class="w-30">
								<label class="switch">
									<input type="checkbox" formControlName="vBorder">
									<span class="slider round"></span>
								</label>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Show.outline.border</div>
							<div class="w-30">
								<label class="switch">
									<input type="checkbox" formControlName="outlineBorder">
									<span class="slider round"></span>
								</label>
							</div>
						</div>
					</ng-container>
					<ng-container  *ngIf="tagElt=='pricingtable' || tagElt == 'table'">
						<div class="flex-row">
							<div class="bold m-10"  translate>Border.style</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Border.style</div>
							<div class="w-30">
								<select formControlName="borderStyle" style="width: 100%;">
									<option value="solid" >Solid</option>
									<option value="dashed">Dashed</option>
									<option value="dotted">Dotted</option>
								</select>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Border.width</div>
							<div class="w-30">
								<div class="input-group" style="display: flex;">
									<input type="text" class="form-control" style="width: 100%;" formControlName="borderWidth">
									<div class="input-group-btn" style="margin-top: 8px;margin-left: 2px;">
										Px
									</div>
								</div>
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Border.colour</div>
							<div class="w-30">
								<input id='border-picker' style="width: 100%;" />
							</div>
						</div>
					</ng-container>
					<ng-container  *ngIf="tagElt=='pricingtable' || tagElt == 'table'">
						<div class="flex-row">
							<div class="bold m-10"  translate>Row.style</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Row.colour</div>
							<div class="w-30">
								<input id='row-picker' style="width: 100%;" />
							</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Alternate.row.colour</div>
							<div class="w-30">
								<input id='row-picker1' style="width: 100%;" />
							</div>
						</div>
					</ng-container>
					<ng-container>
						<div class="flex-row">
							<div class="bold m-10"  translate>Cell.style</div>
						</div>
						<div class="flex-row">
							<div class="w-70"  translate>Padding</div>
							<div class="w-30">
								<select formControlName="padding" style="width: 100%;">
									<option value="3px">Narrow</option>
									<option value="7px">Medium</option>
									<option value="14px">Wide</option>
									<option>Custom</option>
								</select>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="settingsForm.invalid || action == 'saving'" class="btn btn-primary">
			<span [hidden]="action != 'save'" translate>Save</span>
			<span [hidden]="action == 'save'" translate>Saving</span>
		</button>
	</div>
</form>