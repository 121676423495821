import {Component, OnDestroy, OnInit} from '@angular/core';
import { TriggerDatasetService } from "../services/trigger-dataset.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import {BsModalRef } from "ngx-bootstrap";
import {FormArray, FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import { Subject, range } from "rxjs/index";
import { mxgraph } from "mxgraph-factory";
import * as moment from "moment";
import { map } from 'rxjs/operators';
import {from} from "rxjs/index";
import {pluck} from "rxjs/operators";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {Subscription} from "rxjs";


declare var _;
declare var $;

const  ordinal_suffix_of = (i)  => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
};

const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

@Component({
  selector: 'app-trigger-rules-panel',
  templateUrl: './trigger-rules-panel.component.html',
  styles: []
})
export class TriggerRulesPanelComponent implements OnInit,OnDestroy {

    public onClose: Subject<any>;

    title:string='';
    triggerObjects:any;
    isMarketPlaceView: boolean = false;
    operators:any;
    activeMetaDatas: any;
    activeInputs:any={};
    action='';
    isEdit:boolean = false;
    selectedCell: mxgraph.mxCell;
    mxGraph: mxgraph.mxGraph;
    isScheduler:boolean;
    activeScheduler:string = 'daily';
    //form
    // mainObject:string = '';
    form: FormGroup;
    hasDynamicFields:boolean = false;
    dependenciesList:any = {};
    dependencyDropDownList:any=[];
    dynamicFields:any=[];
    customFields: any=[];
    customFieldsGroup: any=[];
    dependencyName:string = '';
    select2Config = {};
    showMultipleDropdown:boolean = false;
    dataFunction = function(item){ return item.text; };
    deleteModalContent:any = {
        title: '',
        message: ''
    };
    openDeleteConfirmation:boolean = false;
    deleteConfirmTextInput: string = '';
    confirmText:string = 'DELETE';
    deleteError: boolean = false;
    inValidConfirmText: boolean = false;
    validation:boolean = false;
    validationMessage:string = '\'DELETE\' to remove the condition';
    dateList: any[] = [];
    selectedColName:any = [];
    fieldsStatic:any = [];
    fieldsCustom:any = [];
    fieldsCertficate:any = [];
    saving: boolean = false;
    isReadOnlyMode: boolean = false;
    diagramOptions: any = {};
    whitelistRules: any = [];
    warningMessage:string;
    subscription: Subscription;
    showTaskBlock: boolean = true;

    constructor(
        public modalRef: BsModalRef,
        private triggerService: TriggerDatasetService,
        private formBuilder: FormBuilder,
        public httpService: WorkflowhttpService,
        private commonService: WorkflowCommonService
    ) { }

    ngOnInit() {
        this.httpService.getDagrunnerStatus(this.diagramOptions.workflowId)
            .subscribe( {
                next: (resp: any) => {
                    if(resp.status == 'running'){
                        this.diagramOptions.isRunningStatus = true;
                    }else{
                        this.diagramOptions.isRunningStatus = false;
                    }
                }
            })
        this.warningMessage = 'This changes does not affect any running workflows. It will affect on the upcoming workflow';

        this.dateList=this.getDateList();
        this.onClose = new Subject();
        // console.log('dependenciesList', this.isMarketPlaceView);
        //this.triggerObjects = this.triggerService.getFields();
        this.operators = this.triggerService.getOperators();
        let formData = {
            mainObject:'',
            dependentId: '',
            rules: [],
            scheduler: {
                type: 'daily',
                fromTime: moment('08:00:00', 'HH:mm:ss').toDate(),
                fromDate: 1,
                weekdays: {
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: true,
                }
            }
        };
        if(this.isEdit) {
            let value = this.selectedCell.getValue();
            // console.log('v', value);
            formData['mainObject'] = value['mainObject'];
            formData['dependentId'] = value['dependentId'];
            formData['rules'] = value['rules'];
            const source$ = from(value['rules']);
            const colsName = source$.pipe(pluck('col_name'));
            colsName.subscribe(val => this.selectedColName.push(val));
            if(value['scheduler']) {
                //console.log( value['scheduler']);
                formData['scheduler'] = value['scheduler'];
                if(value['scheduler']['fromTime'] instanceof Date) {
                    formData['scheduler']['fromTime'] = moment( value['scheduler']['fromTime']).toDate()
                }
                else {
                    formData['scheduler']['fromTime'] = moment( value['scheduler']['fromTime']+':00', 'HH:mm:ss').toDate()
                }
            }

            this.activeMetaDatas = this.triggerObjects[formData['mainObject']];
            this.fieldsStatic = this.activeMetaDatas['fields'];
            this.initializeActiveInputs(value);
            if(formData['scheduler']) {
                this.activeScheduler = formData.scheduler['type'];
            }

        }
        this.form = this.formBuilder.group({
            mainObject: [{value:formData.mainObject, disabled: this.isMarketPlaceView || this.isEdit}, [Validators.required]],
            dependentId: [formData.dependentId],
            conditions: 'AND',
            rules: this.formBuilder.array([]),
            scheduler: this.formBuilder.group({
                type: [formData.scheduler['type']],
                fromTime: [ formData.scheduler['fromTime'], [Validators.required]],
                fromDate: [formData.scheduler['fromDate']],
                weekdays: this.formBuilder.group(formData.scheduler['weekdays']),
            })
        });

        if(this.isEdit) {
            _.forEach(formData['rules'], (value) => {
                this.addRule(value);
            });
        }
        if(this.diagramOptions.hasOwnProperty('whitelist')) {
            this.whitelistRules = !this.diagramOptions['whitelist']['rules'] ? [] : this.diagramOptions['whitelist']['rules'];
        }
        this.isReadOnlyMode = this.isMarketPlaceView;
        this.subscription = this.httpService.workflowEventTrigger$.subscribe((action:any) => {
            if (action === 'autoSave') {
                this.showTaskBlock = false;
            }
        });
    }

    chooseScheduler() {
        const schedulerForm = <FormGroup> this.form.controls['scheduler'];
        this.activeScheduler = schedulerForm.controls.type.value;
    }

    get schedulerGroup() {
        return this.form.controls.scheduler as FormGroup;
    }

    get f() {
        return this.form.controls;
    }

    get r() {
        return this.form.controls.rules as FormArray;
    }

    onBegainDeleteAction(rule:FormGroup, index:number) {
        console.log(rule.getRawValue(), index);
        this.deleteModalContent.title = 'Delete condition';
        this.deleteModalContent.deletableIndex=index;
        this.openDeleteConfirmation=true;
    }

    checkConfirmText() {
        this.inValidConfirmText = !(this.deleteConfirmTextInput.trim().toUpperCase() == this.confirmText);
    }

    removeTriggerCondition() {
        this.checkConfirmText();
        if(this.inValidConfirmText) {
            return false;
        }
        const { deletableIndex } = this.deleteModalContent;
        this.removeRuleByIndex(deletableIndex);
        this.openDeleteConfirmation = false;
        this.deleteModalContent.deletableIndex=-1;
        this.deleteConfirmTextInput = '';
    };

    getSchedulingType() {
        return [
            {value: 'daily', text: 'Daily'},
            {value: 'weekly', text: 'Weekly'},
            {value: 'monthly', text: 'Monthly'},
        ];
    }

    getDateList() {
        let n: any[] = [];
        const numbers = range(0, 31);
        numbers.pipe(map( v => {
            let val = String(v+1);
            return {value: v+1, text: val.length===1 ? '0'+val : val }
        }))
        .subscribe({
            next: value => n.push(value)
        });
        return n;
    }

    makeAsDisabled(col_name:string) {
        if(!this.isMarketPlaceView || this.whitelistRules.length > 0 && this.whitelistRules.includes(col_name)) {
            return false;
        }
        return true;
    }

    weekDayNamesList() {
        return {
            'mon': {value: 1, text: 'Mon'},
            'tue': {value: 2, text: 'Tue'},
            'wed': {value: 3, text: 'Wed'},
            'thu': {value: 4, text: 'Thu'},
            'fri': {value: 5, text: 'Fri'},
            'sat': {value: 6, text: 'Sat'},
            'sun': {value: 0, text: 'Sun'},
        }
    }


    addRule(ruleValue:any=null) {
        if(this.dynamicFields.length == 0 && this.form.get('mainObject').value == 'certificates'){
            this.validation = true;
        }
        const formRules = this.form.controls.rules as FormArray;
        let rule = {
            col_name: '',
            input: '',
            operator: 'equal',
            text: '',
            type: '',
            value: '',
            fieldType: 'static',
            pageType: '',
            templateId: '',
        };
        if(ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] =  ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['fieldType'] = _.isUndefined(ruleValue['fieldType']) ? 'static': ruleValue['fieldType'];
            rule['pageType'] = _.isUndefined(ruleValue['pageType']) ? '' : ruleValue['pageType'];
            rule['templateId'] = _.isUndefined(ruleValue['templateId']) ? '' : ruleValue['templateId'];
        }
        formRules.push(this.formBuilder.group({
            col_name: [rule['col_name'], [Validators.required] ],
            operator: [rule.operator],
            value: [rule.value, [Validators.required] ],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            fieldType: [rule.fieldType],
            pageType: [rule.pageType],
            templateId: [rule.templateId]
        }));
    }

    initializeActiveInputs(selectedCellValue:any) {
        this.activeMetaDatas.fields.forEach( (value: any) => {
            // static fields
            this.activeInputs[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                operators: value.operators
            };
            if(value.hasOwnProperty('options') && value.options.length > 0) {
                this.activeInputs[value.col_name]['options'] = value.options;
            }
        });
        if(this.activeMetaDatas.hasOwnProperty('custom_fields')) {
            this.setCustomFieldsList();
        }

        if(this.activeMetaDatas.id === 'certificates') {
            this.setDependencyDropDownlist();
            this.dynamicFields = this.activeMetaDatas['dynamic_fields'][selectedCellValue['dependentId']];
            const activeDynamicFields = this.activeMetaDatas['dynamic_fields'][selectedCellValue['dependentId']];
            this.dynamicFields = activeDynamicFields['fields'];
            this.dynamicFields.forEach( (value, index) => {
                this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: value.fieldType,
                    pageType: value.pageType,
                };
                if(value.hasOwnProperty('options') && value.options.length > 0) {
                    this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        }
    }

    checkToShowAddRule() {
        if(this.isMarketPlaceView) {
            return false;
        }
        if(this.dependencyDropDownList.length > 0 && this.form.get('mainObject').value &&  !this.form.get('dependentId').value && this.validation) {
            return false;
        }
        if(!this.form.get('mainObject').value) {
            return false;
        }
        if(this.validation){
            return false;
        }
        return true;
    }

    onSelectDependent() {
        const dependentId = this.form.get('dependentId').value;
        if(dependentId){
            this.validation = false;
            this.dynamicFields = this.activeMetaDatas['dynamic_fields'][dependentId]['fields'];
            this.removeRules();
            //this.activeMetaDatas.fields = this.activeMetaDatas.fields.concat(dynamicFields);
            this.dynamicFields.forEach( (value, index) => {
                this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: value.fieldType,
                    pageType: value.pageType,
                };
                if(value.hasOwnProperty('options') && value.options.length > 0) {
                    this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        }else{
            this.validation = true;
        }

    }

    setCustomFieldsList() {
        this.customFields = this.activeMetaDatas['custom_fields'];
        this.customFields.forEach( (template:string) => {
            template['fields'].forEach( (value:any) => {
                this.activeInputs[value.col_name] = value;
                if(value.hasOwnProperty('options') && value.options.length > 0) {
                    this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        })
    }

    setDependencyDropDownlist() {
        this.dependencyDropDownList = [];
        if(this.activeMetaDatas.id === 'certificates') {
            this.dependencyDropDownList = Object.values(this.activeMetaDatas['dynamic_fields']).map( (item:any) => {
                return {
                    text: item.text,
                    id: item.id
                };
            });
        }

    }

    onSelectMainObject() {
        this.validation = false;
        this.removeRules();
        if(this.form.get('mainObject').value != '') {
            this.activeMetaDatas = this.triggerObjects[this.form.get('mainObject').value];
            this.fieldsStatic = this.activeMetaDatas['fields'];
            console.log('activeMetaDatas ', this.activeMetaDatas );

            this.dependencyDropDownList = [];
            this.dynamicFields = [];
            if(this.activeMetaDatas.hasOwnProperty('dynamic_fields')) {
                this.setDependencyDropDownlist();
            }
            else {
                this.form.get('dependentId').setValue('');
            }

            if(this.activeMetaDatas.hasOwnProperty('custom_fields')) {
                this.setCustomFieldsList();
            }

            this.activeMetaDatas.fields.forEach( (value, index) => {
                this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: 'static',
                    pageType: '',
                };
                if(value.hasOwnProperty('options') && value.options.length > 0) {
                    this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
            console.log('d1', this.activeInputs)
        }
        else {
            this.dependencyDropDownList = [];
        }
    }

    removeRules() {
        const control = <FormArray>this.form.controls['rules'];
        for(let i = control.length-1; i >= 0; i--) {
            control.removeAt(i)
        }
    }

    removeRuleByIndex(index:number) {
        const control = <FormArray>this.form.controls['rules'];
        control.removeAt(index);
        setTimeout(() => {
            this.selectedColName = control.getRawValue().map( i => i['col_name']);
        });
    }

    onSelectOperator(value:any, index:number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        const operatorVal = controlArray.controls[index].get('operator').value;
        controlArray.controls[index].get('value').setValue('');
        if(operatorVal === 'in' || operatorVal === 'not_in') {
            this.showMultipleDropdown=true;
        }
        else {
            if($("#multiple_select_"+index).length) {
                $("#multiple_select_"+index).select2('destroy');
            }
            this.showMultipleDropdown=false;
        }
    }

    onSelectMultipleSelect(value:any, index:number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        controlArray.controls[index].get('value').setValue(value);
    }

    onSelectField(value:any, index:number) {
        const controlArray = <FormArray>this.form.controls['rules'];
        if($("#multiple_select_"+index).length) {
            $("#multiple_select_"+index).select2('destroy');
        }
        this.showMultipleDropdown=false;
        this.selectedColName = controlArray.getRawValue().map( i => i['col_name']);

        if(this.activeInputs[value]['input'] == 'select') {
            //controlArray.controls[index].get('operator').setValue(this.activeInputs[e.target.value]['operators'][0]);
        }
        controlArray.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        controlArray.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        controlArray.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        controlArray.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        controlArray.controls[index].get('fieldType').setValue(this.activeInputs[value]['fieldType']);
        controlArray.controls[index].get('pageType').setValue(this.activeInputs[value]['pageType']);
        controlArray.controls[index].get('value').setValue('');
        if(this.activeInputs[value]['fieldType'] === 'custom_field') {
           controlArray.controls[index].get('templateId').setValue(this.activeInputs[value]['templateId']);
        }
        return this.activeInputs[value];
    }

    prepareScheduleValue() {
        const {scheduler} = this.form.value;
        let cronValue = '* * * * *', cronText='';
        const fromTime = moment(scheduler['fromTime']).format('HH:mm');
        const [hour, minutes] = fromTime.split(':');
        if(scheduler['type'] === 'daily') {
            cronValue = minutes + ' ' + hour + ' * * *';
            cronText = 'Daily at ' + fromTime;
        }
        else if(scheduler['type'] === 'weekly') {
            const weekdaysList = this.weekDayNamesList();
            let weekdays = _.chain(scheduler['weekdays']).map((v,k) => {
                return {key:k, value:v, id: weekdaysList[k]}
            }).filter(v => v.value)
                .pluck('id').sortBy('value').value();
            if(weekdays.length > 0) {
                const weekVal = _.pluck(weekdays,'value');
                const weekTxt = _.pluck(weekdays,'text');
                cronText = 'Every week at ' + fromTime + ' on ' + weekTxt.join(',');
                cronValue = minutes + ' ' + hour + ' * * ' + weekVal.join(',');
            }
            else {
                cronText = 'Every week at ' + fromTime + ' on Sunday';
                cronValue = minutes + ' ' + hour + ' * * 0';
            }
        }
        else if(scheduler['type'] === 'monthly') {
            cronText = 'Every month on the ' + ordinal_suffix_of(scheduler['fromDate']) + ' at ' + fromTime;
            cronValue = minutes + ' ' + hour + ' ' + scheduler['fromDate'] + ' * *';
        }
        return [cronText, cronValue, fromTime];
    }

    save() {
        if (!this.form.valid) {
            return false;
        }
        this.saving = true;
        this.action='saving';
        let formValues = this.form.value;
        if(this.isEdit && !formValues['mainObject']) {
            let value = this.selectedCell.getValue();
            formValues['mainObject'] = value['mainObject'];
        }
        if(this.form.get('mainObject').value === 'certificates') {
            console.log('formdata', formValues, this.activeInputs);
        }
        let returnDatas:any = {
            formData: formValues,
            fields: this.activeInputs
        };
        if(this.isScheduler) {
            const [cronText, cronValue, fromTime] = this.prepareScheduleValue();
            returnDatas['formData']['scheduler']['fromTime'] = fromTime;
            returnDatas['formData']['cronText'] = cronText;
            returnDatas['formData']['cronValue'] = cronValue;
        }
        else {
            delete returnDatas['formData']['scheduler'];
        }
        // console.log(returnDatas);
        setTimeout(() => {
            this.onClose.next(returnDatas);
        },0);
        this.modalRef.hide();
    }
    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
            this.showTaskBlock = true;
        }

    }
}

