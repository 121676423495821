import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingsContractCategoriesService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsContractCategoriesService, _super);
    function SettingsContractCategoriesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // fetching existing contract categories
    SettingsContractCategoriesService.prototype.fetchContractCategories = function (param) {
        return this.http.get(this.getApiUrl('system_settings/list-contract-categories', param)).pipe();
    };
    // Add new contract category
    SettingsContractCategoriesService.prototype.addContractCategory = function (data) {
        return this.http.post(this.getApiUrl('contract-category/add-new'), JSON.stringify(data)).pipe();
    };
    // Update contract category
    SettingsContractCategoriesService.prototype.editContractCategory = function (id, data) {
        return this.http.put(this.getApiUrl("system_settings/" + id + "/edit_contract_categories"), JSON.stringify(data)).pipe();
    };
    // Delete contract categories
    SettingsContractCategoriesService.prototype.deleteContractCategory = function (id) {
        return this.http.delete(this.getApiUrl("system_settings/" + id + "/delete_contract_categories")).pipe();
    };
    SettingsContractCategoriesService.prototype.CheckDuplicateContactCategoryName = function (name) {
        return this.http.get(this.getApiUrl("check_contract_categories", { name: name }));
    };
    // Checking delete option for ppm
    SettingsContractCategoriesService.prototype.isDeletePPM = function (id) {
        return this.http.get(this.getApiUrl("system_settings/" + id + "/is_delete_contract_categories")).pipe();
    };
    SettingsContractCategoriesService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsContractCategoriesService_Factory() { return new SettingsContractCategoriesService(i0.inject(i1.HttpClient)); }, token: SettingsContractCategoriesService, providedIn: "root" });
    return SettingsContractCategoriesService;
}(InterfaceService));
export { SettingsContractCategoriesService };
