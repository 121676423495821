import * as i0 from "@angular/core";
var CommunicationHelperService = /** @class */ (function () {
    function CommunicationHelperService() {
    }
    CommunicationHelperService.prototype.findCurrentTemplate = function (inputModel, communicationType) {
        if (communicationType === void 0) { communicationType = 'email'; }
        var templates = inputModel.templates, activeSegmentationValue1 = inputModel.activeSegmentationValue1, activeSegmentationValue2 = inputModel.activeSegmentationValue2;
        // console.log('templates',templates);
        if (!templates) {
            return null;
        }
        if (!inputModel.allowSegmentation) {
            activeSegmentationValue1 = -1;
            activeSegmentationValue2 = -1;
        }
        var activeRecord = templates.find(function (item) {
            return (activeSegmentationValue1 == item.segmentationValue1 || item.segmentationValue1 == null)
                && (activeSegmentationValue2 == item.segmentationValue2 || item.segmentationValue2 == null)
                && item.communicationType == communicationType;
        });
        if (!activeRecord) {
            return null;
        }
        return activeRecord;
    };
    CommunicationHelperService.prototype.getCurrentPreview = function (emailTemplate, smsTemplate, letterTemplate) {
        if ((emailTemplate && smsTemplate) || (emailTemplate && !smsTemplate))
            return 'email';
        else if (!emailTemplate && smsTemplate)
            return 'sms';
        else if (!emailTemplate && !smsTemplate && letterTemplate)
            return 'letter';
    };
    CommunicationHelperService.ngInjectableDef = i0.defineInjectable({ factory: function CommunicationHelperService_Factory() { return new CommunicationHelperService(); }, token: CommunicationHelperService, providedIn: "root" });
    return CommunicationHelperService;
}());
export { CommunicationHelperService };
