import { Observable } from "rxjs/internal/Observable";
import * as i0 from "@angular/core";
var PushNotificationsService = /** @class */ (function () {
    function PushNotificationsService() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }
    PushNotificationsService.prototype.isSupported = function () {
        return 'Notification' in window;
    };
    PushNotificationsService.prototype.requestPermission = function () {
        var self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    };
    PushNotificationsService.prototype.create = function (title, options) {
        var self = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            var _notify = new Notification(title, options);
            _notify.onshow = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function (e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function () {
                return obs.complete();
            };
        });
    };
    PushNotificationsService.prototype.generateNotification = function (source) {
        var self = this;
        var options = {
            body: source.alertContent,
            icon: "./favicon.ico",
        };
        return self.create(source.title, options);
    };
    PushNotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function PushNotificationsService_Factory() { return new PushNotificationsService(); }, token: PushNotificationsService, providedIn: "root" });
    return PushNotificationsService;
}());
export { PushNotificationsService };
