import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { CsTaggingService } from "@app/directives/material-directives/cs-tagging/cs-tagging.service";
var SequenceSmsWidgetComponent = /** @class */ (function () {
    function SequenceSmsWidgetComponent(modalRef, fb, httpService, tagService) {
        this.modalRef = modalRef;
        this.fb = fb;
        this.httpService = httpService;
        this.tagService = tagService;
        this.isLoading = true;
        this.templateData = {};
        this.saving = false;
        this.tagsList = [];
        this.allowableTags = [];
        this.smsTextMessage = '';
    }
    SequenceSmsWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cellValue, cellModel;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onClose = new Subject();
                        cellValue = this.cell.getValue();
                        cellModel = cellValue['model'];
                        this.templateForm = this.fb.group({
                            'message': [cellModel.hasOwnProperty('customMessage') ? cellModel['customMessage']['message'] : '', Validators.required]
                        });
                        this.templateForm.get('message').valueChanges.subscribe(function (content) {
                            if (content == '') {
                                return;
                            }
                            var hasText = content.replace(/(<([^>]+)>)/gi, "");
                            if (!hasText) {
                                _this.templateForm.get('message').setValue('');
                            }
                        });
                        if (!!cellModel.hasOwnProperty('customMessage')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getSmsTemplate()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.getTagsList()];
                    case 3:
                        _a.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SequenceSmsWidgetComponent.prototype.getTagsList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.tagService.getTagsList({
                        tagList: this.allowableTags
                    }).toPromise()
                        .then(function (resp) {
                        _this.tagsList = _this.tagService.format4Quill(resp);
                    })];
            });
        });
    };
    SequenceSmsWidgetComponent.prototype.getSmsTemplate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var model;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                model = this.cell.getValue().model;
                this.httpService.getEmailSmsTemplatesByIds({ ids: model['templateId'] })
                    .toPromise()
                    .then(function (resp) {
                    _this.templateData = resp['templates'][model['templateId']];
                    _this.templateForm.patchValue({
                        message: _this.templateData['message'],
                    });
                    _this.templateForm.updateValueAndValidity();
                });
                return [2 /*return*/];
            });
        });
    };
    SequenceSmsWidgetComponent.prototype.onSave = function () {
        var formValue = this.templateForm.value;
        formValue['displayContent'] = formValue['message'].replace(/(<([^>]+)>)/gi, "");
        this.onClose.next(formValue);
        this.modalRef.hide();
    };
    return SequenceSmsWidgetComponent;
}());
export { SequenceSmsWidgetComponent };
