import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
var OptionTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(OptionTemplateService, _super);
    function OptionTemplateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.contentType = new BehaviorSubject('');
        _this.contentType$ = _this.contentType.asObservable();
        _this.optionTemplate = new BehaviorSubject('');
        _this.optionTemplate$ = _this.optionTemplate.asObservable();
        return _this;
    }
    // Add/Edit option templates
    OptionTemplateService.prototype.addOrEditOptionTemplate = function (data) {
        return this.http.post(this.getApiUrl("addOrEditSetupOption"), data);
    };
    // Reorder Template list
    OptionTemplateService.prototype.reorderTemplate = function (data) {
        return this.http.put(this.getApiUrl('reorderSetupOptions'), data);
    };
    // Delete option template
    OptionTemplateService.prototype.deleteOptionTemplate = function (id, params) {
        return this.http.delete(this.getApiUrl("" + id + "/deleteSetupOptions", params));
    };
    // Check option template already exists
    OptionTemplateService.prototype.checkTemplateName = function (params) {
        return this.http.get(this.getApiUrl("checkSetupOptionsExists", params));
    };
    // Set contentType
    OptionTemplateService.prototype.setContentType = function (data) {
        this.contentType.next(data);
    };
    // Set optionTemplate
    OptionTemplateService.prototype.setOptionTemplate = function (data) {
        this.optionTemplate.next(data);
    };
    OptionTemplateService.prototype.getContentData = function () {
        return this.http.get('../content-library-templates/templates.json');
    };
    //Get All Option templates
    OptionTemplateService.prototype.getAllOptionTemplates = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.post(this.getApiUrl("getAllOptionTemplates"), params);
    };
    //Add proposal Template Options
    OptionTemplateService.prototype.addProposalOptions = function (option) {
        return this.http.post(this.getApiUrl("addOrLinkProposalOptions"), option);
    };
    //Add or link Add-ons
    OptionTemplateService.prototype.addOrLinkAddOns = function (option) {
        return this.http.post(this.getApiUrl("addOrLinkAddOns"), option);
    };
    //Edit Add-ons
    OptionTemplateService.prototype.editAddOns = function (option) {
        return this.http.post(this.getApiUrl("editAddOns"), option);
    };
    // Delete Add-ons
    OptionTemplateService.prototype.deleteAddOns = function (option) {
        return this.http.post(this.getApiUrl("deleteAddOns"), option);
    };
    //Get Option template content
    OptionTemplateService.prototype.getOptionTemplatePages = function (id) {
        return this.http.get(this.getApiUrl(id + "/getOptionTemplatePages"));
    };
    //Get Finance template list
    OptionTemplateService.prototype.getFinanceTemplateList = function () {
        return this.http.get(this.getApiUrl("sales/getFinanceTemplatesList"));
    };
    // update finance option
    OptionTemplateService.prototype.updateFinanceOption = function (data, type) {
        return this.http.post(this.getApiUrl("sales/optionTemplateFinanceConfig?type=" + type), data);
    };
    OptionTemplateService.prototype.checkOptionTemplateName = function (params) {
        return this.http.post(this.getApiUrl("checkProposalSetupOptionsExists"), params);
    };
    // Clone option template
    OptionTemplateService.prototype.cloneOptionTemplate = function (id, parentId) {
        return this.http.get(this.getApiUrl("cloneOptionTemplate/" + (id + "/") + ("" + parentId)));
    };
    OptionTemplateService.prototype.downloadOptionTemplate = function (id) {
        return this.http.get(this.getApiUrl("downloadOptionTemplate?id=" + id));
    };
    OptionTemplateService.prototype.uploadOptionTemplate = function (params) {
        return this.http
            .post(this.getApiUrl("uploadOptionTemplate"), params);
    };
    OptionTemplateService.prototype.proposalLinkedOption = function (id) {
        return this.http.get(this.getApiUrl("checkIsOptionLinkProposalTemplate/" + id));
    };
    OptionTemplateService.prototype.getContentLibraryTemplate = function (id) {
        return this.http.get(this.getApiUrl("sales/getContentLibraryTemplate/" + id));
    };
    OptionTemplateService.prototype.upgradeDowngradeFreemiumBlocks = function (data) {
        return this.http.post(this.getApiUrl('sales/upgradeDowngradeFreemiumBlocks'), data);
    };
    return OptionTemplateService;
}(InterfaceService));
export { OptionTemplateService };
