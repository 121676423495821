import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EventDispatcherService} from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import {Subscription} from "rxjs";
import {
    FollowUpInputModel,
    InputModel,
    MessageInput
} from "@app/shared/service-reminder-communication/services/input-model";

@Component({
  selector: 'app-sc-template-view',
  templateUrl: './sc-template-view.component.html',
  styleUrls: ['./sc-template-view.component.scss']
})
export class ScTemplateViewComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    currentActiveInputModel: InputModel = null;
    isFollowupMessage: boolean = false;
    currentViewPort: string = 'desktop';
    previewModel: string | 'email' | 'sms' | 'letter' = 'email';
    emailMessage: string = '';
    smsMessage: string = '';
    letterMessage: string = '';
    noMessageView: boolean = true;

    select2Options: any = {
        minimumResultsForSearch: -1
    };
    select2PreviewModelInstance: any = null;
    emailTemplateListIndexById: any = {};
    smsTemplateListIndexById: any = {};
    loadingTemplateMessage: boolean = true;
    loadingMessage:boolean = true;
    showNextStep: boolean = false;
    disabledAction: boolean = true;
    disabledSaveAction: boolean = true;
    isSaving:boolean = false;
    activeTab: string|'single'|'multiple' = '';
    singlePropertyInput: InputModel = null;
    multiplePropertyInput: InputModel = null;
    clientDetails: any = {};
    randomMobileNumber: number = 0;

    @Input() enableView: boolean = false;
    @Input() viewMode: boolean;

    constructor(
        private eventDispatcher: EventDispatcherService,
    ) { }

    ngOnInit() {
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: ( data: object) => {
                if(typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                const value = data['data'];
                if(action == EventDispatcherService.CONSTANTS['init']) {
                    this.emailTemplateListIndexById = value['emailTemplateListIndexById'];
                    this.smsTemplateListIndexById = value['smsTemplateListIndexById'];
                    this.singlePropertyInput = value['singlePropertyInput'];
                    this.multiplePropertyInput = value['multiplePropertyInput'];
                    this.activeTab = value['activeTab'];
                    this.loadingTemplateMessage = false;
                    this.clientDetails = value['clientDetails'];
                }
                else if(action == EventDispatcherService.CONSTANTS['currentActiveInput']) {
                    this.currentActiveInputModel = value['currentActiveInputModel'];
                    this.setMessageTemplates();
                    if(value['actionName'] && value['actionName'] !== 'onChangedInterval')
                        this.reloadMessage();
                    else
                        this.loadingMessage=false;
                }
                else if(action == EventDispatcherService.CONSTANTS['onChangedMenuItem']) {
                    this.currentActiveInputModel = value['currentActiveInputModel'];
                    this.activeTab = value['activeTab'];
                    this.setMessageTemplates();
                    this.reloadTemplate();
                }
                else if(action == EventDispatcherService.CONSTANTS['onChangedTemplate']) {
                    this.currentActiveInputModel = value['currentActiveInputModel'];
                    this.setMessageTemplates();
                    this.previewModel = value['changedTemplate'];
                    if(this.select2PreviewModelInstance) {
                        this.select2PreviewModelInstance.select2('val', this.previewModel);
                    }
                    if(value['changedTemplate'] == 'email') {
                        this.currentViewPort='desktop';
                    }
                    else if (value['changedTemplate'] == 'sms' || value['changedTemplate'] == 'letter') {
                        this.currentViewPort = 'mobile';
                    }
                    this.reloadMessage();
                }
                else if(action == EventDispatcherService.CONSTANTS['checkIsLastIndex']) {
                    this.showNextStep = !value['checkIsLastIndex'];
                    if(!this.showNextStep) {
                        console.log('show save button')
                        this.disabledSaveAction = !this.validateForm();
                    }
                }
                else if(action === EventDispatcherService.CONSTANTS['isValidForm']) {
                    this.disabledSaveAction = this.disabledAction = !value['isValidForm'];
                }
            }
        });
        this.randomMobileNumber=Math.floor(Math.random() * 9000000000) + 1000000000;
    }

    validateForm() {
        if(this.hasDuplicateInterval()) {
            return false;
        }
        let isValid:boolean = true;
        if(this.singlePropertyInput.interval == null) {
            isValid = false;
        }
        const hasInValidMsg = (element:MessageInput, index:number, messages:[]) => {
            return element.templateId == null;
        };
        this.singlePropertyInput.followUpMessage.every( (followUpInput:FollowUpInputModel) => {
            if(followUpInput.interval == null) {
                isValid = false;
                return;
            }
            isValid = !Object.values(followUpInput.messages).every( hasInValidMsg );
            return true;
        });

        if(!isValid)
            return isValid;

        if(this.multiplePropertyInput.interval == null) {
            isValid = false;
        }
        return isValid;
    }

    hasDuplicateInterval() {
        let singleFollowupVars = [];
        const  checkIfDuplicateExists = (arr) => {
            return new Set(arr).size !== arr.length
        }
        if(this.singlePropertyInput.followUpMessage.length > 0) {
            this.singlePropertyInput.followUpMessage.forEach( (item) => {
                singleFollowupVars.push(item.interval)
            });
            if(checkIfDuplicateExists(singleFollowupVars)) {
                return true;
            }
        }
        let multipleFollowupVars = [];
        if(this.multiplePropertyInput.followUpMessage.length > 0) {
            this.multiplePropertyInput.followUpMessage.forEach( (item) => {
                multipleFollowupVars.push(item.interval)
            });
            return checkIfDuplicateExists(multipleFollowupVars);
        }
        return false;
    }

    setMessageTemplates() {
        // if(!this.isFollowupMessage) {
            const messages = this.currentActiveInputModel.messages;
            this.emailMessage = messages['email']['message'];
            this.smsMessage = messages['sms']['message'];
            this.letterMessage = messages['letter']['message'];
            if(!this.emailMessage && !this.smsMessage && !this.letterMessage) {
                this.noMessageView = true;
            }
            else if(this.emailMessage && !this.smsMessage && !this.letterMessage) {
                this.noMessageView = false;
                this.currentViewPort='desktop';
                this.previewModel = 'email';
            }
            else if(this.smsMessage && !this.emailMessage && !this.letterMessage) {
                this.noMessageView = false;
                this.currentViewPort='mobile';
                this.previewModel = 'sms';
            }
            else if(this.letterMessage && !this.emailMessage && !this.smsMessage) {
                this.noMessageView = false;
                this.currentViewPort='mobile';
                this.previewModel = 'letter';
            }
            else {
                this.currentViewPort='desktop';
                this.noMessageView = false;
                this.previewModel = 'email';
            }
        // }
    }

    setSelect2PreviewModel(instance) {
        this.select2PreviewModelInstance = instance;
    }

    changeViewPort(view: string) {
        if(view === this.currentViewPort)
            return;
        this.currentViewPort = view;
        this.reloadMessage();
    }

    onChangePreviewOption(value) {
        this.previewModel = value
        if(value == 'sms' || value == 'letter')
            this.currentViewPort = 'mobile';
        else if(value == 'email')
            this.currentViewPort = 'desktop';
        this.reloadMessage();
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    reloadTemplate() {
        this.loadingTemplateMessage=true;
        setTimeout(() => {
            this.loadingTemplateMessage=false;
        }, 1000);
    }

    reloadMessage() {
        this.loadingMessage=true;
        setTimeout(() => {
            this.loadingMessage=false;
        }, 1000);
    }

    closeModal() {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
    }

    goNextStep() {
        let event = {};
        if(this.currentActiveInputModel.propertyType == 1) {
            if( (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length == 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.singlePropertyInput.followUpMessage.length == this.currentActiveInputModel.followUpOrder)
            ) {
                event['nextActiveItem'] = this.multiplePropertyInput;
                event['index'] = undefined;
                event['activeTab'] = 'multiple';
                event['actionName'] = 'onSelectTabMenu';
            }
            else if(
                (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.singlePropertyInput.followUpMessage[this.currentActiveInputModel.followUpOrder])
            ) {
                let index = this.currentActiveInputModel instanceof FollowUpInputModel ? this.currentActiveInputModel['followUpOrder'] : 0;
                event['nextActiveItem'] = this.singlePropertyInput.followUpMessage[index];
                event['index'] = index;
                event['activeTab'] = 'single';
                event['actionName'] = 'onClickItem';
            }
        }
        else if (this.currentActiveInputModel.propertyType == 2) {
            if(
                (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.multiplePropertyInput.followUpMessage[this.currentActiveInputModel.followUpOrder])
            ) {
                let index = this.currentActiveInputModel instanceof FollowUpInputModel ? this.currentActiveInputModel['followUpOrder'] : 0;
                event['nextActiveItem'] = this.multiplePropertyInput.followUpMessage[index];
                event['index'] = index;
                event['activeTab'] = 'multiple';
                event['actionName'] = 'onClickItem';
            }
        }
        if(Object.keys(event).length > 0) {
            this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['goToNextItem'], event);
        }
    }

    saveAction() {
        this.isSaving=true;
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onSaveAction'], {});
    }

}
