import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MicroserviceErrorNotificationService {

    private msErrorNotificationSource: BehaviorSubject<{
        action: 'notify' | 'hide';
        message?: string;
        redirect_to?: string;
        title?: string;
        icon?: 'error' | 'warning' | 'danger';
        close_text?: string;
        request_identifier?: string;
        status_code?: number;
    }> = new BehaviorSubject(null);

    msErrorNotification$ = this.msErrorNotificationSource.asObservable();

    private msErrorModalDisplay: BehaviorSubject<string | null> = new BehaviorSubject(null);
    onErrorModalDisplay$ = this.msErrorModalDisplay.asObservable();

    private msErrorModalClose: BehaviorSubject<string | null> = new BehaviorSubject(null);
    onErrorModalClose$ = this.msErrorModalClose.asObservable();

    constructor() {
    }

    // tslint:disable-next-line:max-line-length
    notify(message?: string, redirect_to?: string, title?: string, icon?: 'error' | 'warning' | 'danger', close_text?: string, request_identifier?: string, status_code?: number) {

        this.msErrorNotificationSource.next({
            action: 'notify',
            message: message,
            redirect_to: redirect_to,
            title: title,
            icon: icon,
            close_text: close_text,
            request_identifier: request_identifier,
            status_code: status_code,
        });

    }

    hide() {
        this.msErrorNotificationSource.next({action: 'hide'});
    }

    notifyDisplay(request_identifier?: string) {
        this.msErrorModalDisplay.next(request_identifier);
    }

    notifyClose(request_identifier?: string) {
        this.msErrorModalClose.next(request_identifier);
    }

}
