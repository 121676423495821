import {
    Component,
    Input,
    OnInit,
    ViewChild,
    EventEmitter,
    Output
} from '@angular/core';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { FormGroup} from '@angular/forms';
import Quill from 'quill';
import 'quill-mention';
import {CsTaggingService} from "@app/directives/material-directives/cs-tagging/cs-tagging.service";

declare var $;

const FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = ['andale-mono', 'sans-serif', 'arial-black', 'comic-sans', 'book-antiqua', 'courier new', 'georgia', 'helvetica', 'impact', 'symbol', 'tahoma', 'terminal', 'times-new-roman', 'trebuchet-ms', 'verdana'];
Quill.register(FontAttributor, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = [false, '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];
Quill.register(Size, true);

const setDefaultCssProperties = () => {
    const Block = Quill.import("blots/block");
    class Pblock extends Block {
        static create(value) {
            let node = super.create();
            node.setAttribute(
                "style",
                "margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;"
            );
            return node;
        }
    }
    Quill.register(Pblock, true);
};
setDefaultCssProperties();
/*
let Delta = Quill.import('delta');
let Break:any = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

const lineBreakMatcher = () => {
    let newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
};
class SmartBreak extends Break {
    length () {
        return 0
    }
    value () {
        return '\n'
    }
    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref)
    }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';
Quill.register(SmartBreak)
*/


@Component({
  selector: 'quill-editor-custom',
  templateUrl: './quill-editor-custom.component.html',
  styleUrls: ['quill-editor-custom.component.scss']
})
export class QuillEditorCustomComponent implements OnInit {
    @Input() public form: FormGroup;
    @Input() public lableName;
    @Input() private uploadCommunication: EventEmitter<string>;
    @Input() fieldControlName;
    @Input() set tagsList(tags:any[]) {
        if (tags.length) {
            this._tagsList = tags;
            if ( $('.ql-mention-loading').length > 0) {
                this.editors.quillEditor.getModule('mention').openMenu("");
            }
        }
    };
    @Output() contentChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(QuillEditorComponent) editors: QuillEditorComponent;
    _tagsList:any[] = [];
    csTaggingList: any[] = [];
    initialLoad: boolean = false;
    communicatioType:string;
    module = {
        mentionListClass: 'ql-mention-list mat-elevation-z8',
        defaultMenuOrientation: 'bottom',
        allowedChars: /^[A-Za-z\sÅÄÖåäö\_]*$/,
        fixMentionsToQuill: false,
        showDenotationChar: false,
        spaceAfterInsert: false,
        isolateCharacter: false,
        renderItem: (data) => {
            if (data.disabled) {
                return `<div style="background-color:#ddd;margin:0 -14px;padding:2px 8px; font-weight: bold; pointer-events: none; color:#999;">${data.value}</div>`;
            }
            return data.value;
        },
        onSelect: (item: DOMStringMap, insertItem) => {
            if (item.denotationChar === '[') {
                item['value'] = '['+item['value'] + ']';
            }
            insertItem(item, false);
            setTimeout( () => {
                let $mentionSpan = $('span.mention > span');
                let $mentionWrap = $('span.mention');
                this.editors.quillEditor.insertText(this.editors.quillEditor.getSelection(true), item.value + ' ');

                if($mentionSpan.length > 0) {
                    // const $content = $.trim($mentionSpan.html());
                    //$mentionWrap.parent().append($content)
                    $mentionWrap.remove();
                    setTimeout(() => {
                        const curPos = this.editors.quillEditor.getSelection(true);
                        this.editors.quillEditor.setSelection(curPos, -1)
                    },0);
                }
            }, 300);
        },
        renderLoading: () => {
            return "Loading...";
        },
        mentionDenotationChars: ['['],
        source: (searchTerm, renderList, mentionChar) => {
            let values = [];
            if (searchTerm.length === 0) {
                values = this._tagsList;
            } else {
                const matches = [];
                values = this._tagsList;
                for (let i = 0; i < values.length; i++) {
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                        matches.push(values[i]);
                    }
                }
                values = matches;
                if(matches.length === 0) {
                    values.push({
                        id: 0,
                        value: "No tag found...",
                        disabled: true
                    });
                }
            }
            if(values.length) {
                renderList(values, searchTerm);
            }
        }};

    modulesMessage: QuillModule = {
        mention: this.module,
       /*clipboard: {
            matchers: [
                ['BR', lineBreakMatcher]
            ],
           // matchVisual: true,
        },
        keyboard: {
            bindings: {
                linebreak: {
                    key: 13,
                    shiftKey: true,

                    handler: function (range) {
                        let currentLeaf = this.quill.getLeaf(range.index)[0]
                        let nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                        this.quill.insertEmbed(range.index, "break", true, "user");
                        if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                            this.quill.insertEmbed(range.index, "break", true, "user");
                        }
                        // Now that we've inserted a line break, move the cursor forward
                        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                    }
                }
            }
        },*/
        toolbar: {
            container: [
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
                [{ font: [ 'andale-mono', 'arial-black', 'sans-serif', 'comic-sans', 'book-antiqua', 'courier new', 'georgia', 'helvetica', 'impact', 'symbol', 'tahoma', 'terminal', 'times-new-roman', 'trebuchet-ms', 'verdana'] }],
                [{ size: [ false, '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px']}],
                ['link']
            ]
        }
    };
    modulesSubject: QuillModule = {
        mention: this.module,
        toolbar: false
    };
    constructor( private tagService: CsTaggingService) { }

    ngOnInit() {
        if(this.uploadCommunication) {
            this.uploadCommunication.subscribe(data => {
                if (data === 'sms' || this.lableName === 'subject') {
                    this.initialLoad = true;
                }
                this.communicatioType = data;
            });
        }
    }

    blurHandler($event: { editor: any; source: string }) {
        const el = document.querySelector('.ql-tooltip [data-link]');
        if (!el) {
            return;
        }
        el.setAttribute('placeholder', 'http(s)://example.com');
        el.setAttribute('data-link', '');
    }

    onContentChanged(event) {
        this.contentChanged.emit(event);
    }
}
