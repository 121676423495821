import { Component, OnInit } from '@angular/core';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { event, inArray } from 'jquery';
//import { Value } from 'sass';

@Component({
	selector: 'app-pricing-section-parts-categories',
	templateUrl: './pricing-section-parts-categories.component.html',
	styleUrls: ['../pricing-section-usepartkit/pricing-section-usepartkit.component.scss'],
})
export class PricingSectionPartsCategoriesComponent implements OnInit {
	categorylist: Array<any> = [];
	filteredCategorylist: Array<any> = [];
	existingParts: Array<any> = [];
	subCategorylist: Array<any> = [];
	filteredSubCategorylist: Array<any> = [];
	partList: Array<any> = [];
	selectedParts: Array<any> = [];
	filteredSelectedParts: Array<any> = [];
	resultParts: Array<any> = [];
	saving: boolean = false;
	public onClose: Subject<{}>;
	select2Config: any;
	title: any;
	view: string = "categories";
	category: any = "";
	subcategory: any = "";
	totalcount = 0;
	totalprice = '0';
	totalPartsList: Array<any> = [];
	cartParts: Array<any> = [];
	searchValue = ""
	lastScrollTop = 0;
	subcategoryId = null;
	loading: boolean = false;
	totalPartsCount: any;
	TotalSumofQuantity = 0;
	TotalQuantityArray: Array<any> = [];

	constructor(
		public proposalService: ProposalTemplateService,
		public bsModalRef: BsModalRef,
	) {

	}

	ngOnInit() {
		this.onClose = new Subject();
		this.getPartsData();
		this.select2Config = {
			width: '100%'
		};
	}

	getPartsData() {
		this.loading = true;
		this.proposalService.getPartList('', '', 50, 1).subscribe(
			data => {
				if (data) {
					// partsAndPricesId
					this.categorylist = data['categorylist'];
					this.filteredCategorylist = this.categorylist;
					for (var i = 0; i < data['partsandprices'].length; i++) {
						if (data['partsandprices'][i].hasOwnProperty('subCategoryId')) {
							let added = false;
							if (this.existingParts) {
								this.existingParts.forEach((item: any) => {
									if (item['partsAndPricesId'] == data['partsandprices'][i]["id"]) {
										added = true;
									}

								});
							}
							if (!added) {
								this.partList.push(data['partsandprices'][i])
							}
						}
					}
					this.loading = false;
				}
			}
		)
	}

	onChangeCategory(event) {
		this.searchValue = "";
		this.view = "subcategories";
		this.category = event['categoriesDescription'];
		this.subCategorylist = event['subCategoryList']
		this.filteredSubCategorylist = event['subCategoryList']
	}

	onSubBreadClick() {
		this.view = "subcategories";
		this.subcategory = '';
		this.filteredSubCategorylist = this.subCategorylist;
		this.searchValue = "";
	}

	selectSubcategory(event) {
		this.searchValue = "";
		this.view = "parts";
		this.subcategory = event['subCategoryDescription'];
		this.subcategoryId = event['subCategoryId'];
		this.loading = true;
		this.getSubCategoryPartList(event['subCategoryId'], '', 50, 1)
	}

	getSubCategoryPartList(subCategoryId, searchText, limit, page) {
		this.partList = [];
		// if (this.view != "parts") {
		this.loading = true;
		// }
		this.proposalService.getPartList(subCategoryId, searchText, limit, page).subscribe(
			data => {
				this.totalPartsCount = data['count']
				for (var i = 0; i < data['partsandprices'].length; i++) {
					if (data['partsandprices'][i].hasOwnProperty('subCategoryId')) {
						let added = false;
						if (this.existingParts && this.existingParts.length) {
							this.existingParts.forEach((item: any) => {
								if (item['partsAndPricesId'] == data['partsandprices'][i]["id"]) {
									added = true;
								}
							});
						}
						if (!added) {
							this.partList.push(data['partsandprices'][i])
						}
					}
				}
				this.selectedParts = this.partList;
				for (var i = 0; i < this.selectedParts.length; i++) {
					if (this.totalPartsList.length) {
						for (var j = 0; j < this.totalPartsList.length; j++) {
							if (this.selectedParts[i]['id'] == this.totalPartsList[j]['id']) {
								this.selectedParts[i]['count'] = this.totalPartsList[j]['count'];
							}
						}
					} else {
						this.selectedParts[i]['count'] = 0;
					}
					if (!this.selectedParts[i].hasOwnProperty('count')) {
						this.selectedParts[i]['count'] = 0;
					}
				}
				this.filteredSelectedParts = this.selectedParts;
				this.loading = false;
			}
		)
	}

	saveCategories() {
		this.saving = true;
		this.onClose.next(this.cartParts);
		this.bsModalRef.hide();
	}

	selectedPart(part) {
		if (this.resultParts.length) {
			if ($("#" + part['id']).hasClass("partAdded")) {
				$("#" + part['id']).removeClass("partAdded");
				this.resultParts = $.grep(this.resultParts, function (e) {
					return e.id != part['id'];
				});
			} else {
				this.resultParts.push(part)
				$("#" + part['id']).addClass("partAdded");
			}
		} else {
			this.resultParts.push(part);
			$("#" + part['id']).addClass("partAdded");
		}
	}

	onSearch(event) {
		if (event && event != '') {
			if (event['code'] == "Backspace") {
				if (this.view == "categories") this.filteredCategorylist = this.categorylist;
				if (this.view == "subcategories") this.filteredSubCategorylist = this.subCategorylist;
				if (this.view == "parts") {
					// this.filteredSelectedParts = this.selectedParts;
					this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
				}
				if (this.view == "cart") this.totalPartsList = this.cartParts;
			}
			if (this.view == "categories") {
				this.filteredCategorylist = this.filteredCategorylist.filter((i: any) => i.categoriesDescription.toLowerCase().match(event.toLowerCase()))
			}
			if (this.view == "subcategories") {
				this.filteredSubCategorylist = this.filteredSubCategorylist.filter((i: any) => i.subCategoryDescription.toLowerCase().match(event.toLowerCase()))
			}
			if (this.view == "parts") {
				// this.filteredSelectedParts = this.filteredSelectedParts.filter((i: any) => i.description.toLowerCase().match(event.toLowerCase()))
				this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
			}
			if (this.view == "cart") {
				this.totalPartsList = this.totalPartsList.filter((i: any) => i.description.toLowerCase().match(event.toLowerCase()))
			}
		} else {
			if (this.view == "categories") {
				this.filteredCategorylist = this.categorylist;
			}
			if (this.view == "subcategories") {
				this.filteredSubCategorylist = this.subCategorylist;
			}
			if (this.view == "parts") {
				// this.filteredSelectedParts = this.selectedParts;
				this.getSubCategoryPartList(this.subcategoryId, event.toLowerCase(), 50, 1);
			}
			if (this.view == "cart") {
				this.totalPartsList = this.cartParts;
			}
		}

	}

	showCategories() {
		this.searchValue = "";
		this.view = "categories";
		this.category = '';
		this.subcategory = '';
	}

	addCart(data, index) {
		this.filteredSelectedParts.forEach(function (element) {
			if (data['id'] == element['id']) {
                element['count'] = parseFloat(element['count']) + 1;
			}
		});
		this.totalprice = (parseFloat(this.totalprice) + parseFloat(data['price'])).toString();
		if (this.totalPartsList.length) {
			let found = false;
			for (let i = 0; i < this.totalPartsList.length; i++) {
				if (this.totalPartsList[i]['id'] == data['id']) {
					found = true;
					this.totalPartsList[i]['count'] = data['count'];
				}
			}
			if (!found) {
				this.totalPartsList.push(data)
			}
		} else {
			this.totalPartsList.push(data);
		}
        this.totalPartsList = this.totalPartsList.filter(parts => {
            return parts.count !== 0;
        });
		this.cartParts = this.totalPartsList;
        let sum = 0;
        this.cartParts.forEach((obj) => {
            sum += obj.count;
        });
        this.TotalSumofQuantity = sum;
		// this.TotalQuantityArray[index] = this.filteredSelectedParts[index].count
		// let val = this.filteredSelectedParts[index].count
		// this.inputdata(val, data, index);
	}

	removeCart(data,index) {
		let cardStaus = false;
		this.filteredSelectedParts.forEach(function (element) {
			if (data['id'] == element['id']) {
				if (element['count'] >= 1) {
					element['count'] = element['count'] - 1;
					cardStaus = true;
				}
			}
		});

		if (cardStaus) {
			this.totalprice = (parseFloat(this.totalprice) - parseFloat(data['price'])).toString();

			if (this.totalPartsList.length) {
				let found = false;
				for (let i = 0; i < this.totalPartsList.length; i++) {
					if (this.totalPartsList[i]['id'] == data['id']) {
						found = true;
						this.totalPartsList[i]['count'] = data['count'];
					}
				}
				if (!found) {
					this.totalPartsList.push(data)
				}
			} else {
				this.totalPartsList.push(data);
			}
            this.totalPartsList = this.totalPartsList.filter(parts => {
                return parts.count !== 0;
            });
			this.cartParts = this.totalPartsList;
            let cartPartsQty = 0;
            this.cartParts.forEach((obj) => {
                cartPartsQty += obj.count;
            });
            this.TotalSumofQuantity = cartPartsQty;
		}
	}

	addQuantity(data, index) {
		let value = parseInt(data.count);
		let item = parseInt(data.count);
        isNaN(value) ? value = 0 : value;
		value == 0 ? item = 0 : value;
		if (value >= 0) {
            let totalPrice = 0;
			this.filteredSelectedParts.forEach(function (element) {
				if (data['id'] == element['id']) {
					element['count'] = value;
				}
                totalPrice = totalPrice + (element['count'] * element['price']);
			});
            this.totalprice = totalPrice.toString();
			if (this.totalPartsList.length) {
				let found = false;
				for (let i = 0; i < this.totalPartsList.length; i++) {
					if (this.totalPartsList[i]['id'] == data['id']) {
						found = true;
						this.totalPartsList[i]['count'] = data['count'];
					}
				}
				data['count'] = item;
				if (!found) {
					this.totalPartsList.push(data)
				}
			} else {
				this.totalPartsList.push(data);
			}
		}
		this.totalPartsList = this.totalPartsList.filter(parts => {
            return parts.count !== 0;
        });
        this.cartParts = this.totalPartsList;
        let cartPartsQty = 0;
        this.cartParts.forEach((obj) => {
            cartPartsQty += obj.count;
        });
        this.TotalSumofQuantity = cartPartsQty;

		// this.inputdata(value, data, index);
	}

	inputdata(value, data, index) {
		let inputboxValueAnd = value
		this.TotalQuantityArray[index] = inputboxValueAnd
        console.log(this.TotalQuantityArray)
		var total = 0;
		for (var i in this.TotalQuantityArray) {
			total += this.TotalQuantityArray[i];
		}
        this.TotalSumofQuantity = total;

	}
	onclick(data, index) {
		data.target.value = null
		this.TotalQuantityArray[index] = 0
	}

	viewCart() {
		this.searchValue = "";
		this.view = 'cart';
		this.category = '';
		this.subcategory = '';
	}

	deleteCart(ind, data) {
        let removeprice = parseFloat(data['price']) * data['count'];
        this.totalprice = (parseFloat(this.totalprice) - removeprice).toString();
        this.totalPartsList.splice(ind, 1);
        this.TotalQuantityArray.splice(ind, 1);
        this.cartParts = this.totalPartsList;
        let sum = 0;
        this.cartParts.forEach((obj) => {
            sum += obj.count;
        });
        this.TotalSumofQuantity = sum;
    }

	addCartQuantity(event, data, index) {
        let value = parseInt(event.target.value);
        let item = parseInt(event.target.value);
        isNaN(value) ? value = 0 : value;
        value == 0 ? item = 0 : value;
        if (value >= 0) {
            if (value > data['count']) {
                let incprice = (value - data['count']) * parseFloat(data['price']);
                this.totalprice = (parseFloat(this.totalprice) + incprice).toString();
            } else if (value < data['count']) {
                let detectprice = (data['count'] - value) * parseFloat(data['price']);
                this.totalprice = (parseFloat(this.totalprice) - detectprice).toString();
            }
        }
        this.totalPartsList[index]['count'] = value;
        let cartPartsQty = 0;
        this.cartParts = this.totalPartsList
        this.cartParts.forEach((obj) => {
            cartPartsQty += obj.count;
        });
        this.TotalSumofQuantity = cartPartsQty;

	}

	onContainerScroll(event) {
		if (this.view == "parts") {
			var element = event.srcElement;
			if (element.scrollTop > this.lastScrollTop) {
				if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
					let limit = this.selectedParts.length + 50;
					if (this.totalPartsCount != this.selectedParts.length) {
						this.getSubCategoryPartList(this.subcategoryId, '', limit, 1)
					}
				}
			}
			this.lastScrollTop = element.scrollTop;
		}
	}

	omitSpecialChar(event, index) {
		var textboxvalue = $('#quantity-input-' + index).val().toString();
        if (textboxvalue.length > 4) {
            return false;
        }
        if (textboxvalue == '0'){
            event.target.value = null
            this.TotalQuantityArray[index] = 0
            return true;
        }
    	if (textboxvalue.length > 3 && textboxvalue.includes('.')) {
			if (textboxvalue.indexOf('.') < 2) {
				return false;
			}
			else if ((textboxvalue.length - textboxvalue.indexOf('.')) > 2) {
				return false;
			}
		}
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode.keyCode <= 36 || charCode.keyCode > 57)){
            if((charCode.keyCode <= 96 || charCode.keyCode >= 106)) {                
                if(charCode.keyCode != 8){
					event.preventDefault();
					return false;
				}
            } else {
                return true;
            }
        }

	}

    keydown(event,index){
        let textboxvalue = $('#quantity-input-' + index).val().toString();
        if (textboxvalue == '0'){
            if (event.keyCode <= 37 || event.keyCode > 57){
                if( event.keyCode <= 96 || event.keyCode >= 106) {                    
                    if(event.keyCode != 8){
						event.preventDefault();
						return false;
					}
                } else {
                    return true;
                }
            }

        }

    }
}
