import { OnInit } from '@angular/core';
var ConditionEdgeComponent = /** @class */ (function () {
    function ConditionEdgeComponent() {
        this.activeField = {};
        this.isMarketPlaceView = false;
        this.isReadOnlyMode = false;
        this.dataFunction = function (item) { return item.text; };
    }
    ConditionEdgeComponent.prototype.ngOnInit = function () {
        if (this.parentBlockName && this.parentBlockName === 'untilConditionBlock') {
        }
    };
    ConditionEdgeComponent.prototype.onSelectMultipleSelect = function (value) {
        this.inputModel.condition.value = value;
    };
    ConditionEdgeComponent.prototype.onSelectOperator = function (value) {
        this.inputModel.condition.value = '';
        if ($("#conditional_multiple_select").length) {
            $("#conditional_multiple_select").select2('destroy');
        }
    };
    return ConditionEdgeComponent;
}());
export { ConditionEdgeComponent };
