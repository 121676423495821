import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
  Renderer,
  Output,
  EventEmitter
} from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'cs-modal',
  template: `
    <div class="modal-dialog">
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>`,
  styles: [],
  host: {
    class: 'modal fade'
  }
})
export class CsModalComponent implements OnInit {
  @Output() onOpen: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() isDisabledBackdrop:boolean = true;
  modalState: 'open' | 'closed';

  constructor(
    private _hostRef: ElementRef,
    private _renderer: Renderer
  ) {
  }

  ngOnInit() {
    this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
  }

  @Input('show')
  set showModal(show: boolean) {
    if (show && this.modalState !== 'open') this.open();
    else if (this.modalState !== 'closed') this.close();
  }

  open() {
    jQuery(this._hostRef.nativeElement).appendTo("body").modal("show")
      .on('hide', () => {
        this.onClose.emit();
      });
      if(this.isDisabledBackdrop) {
          jQuery('body').find('.modal-backdrop').off('click');
      }

    this.modalState = 'open';
    this.onOpen.emit();
  }

  close() {
    jQuery(this._hostRef.nativeElement).modal("hide");
    this.modalState = 'closed';
  }

  ngOnDestroy() {

    // Remove content from body on destroy
    jQuery(this._hostRef.nativeElement).remove();
  }
}
