import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { OptionTemplateService } from '@app/features/system-settings/option-template/services/option-template.service';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import {ArticleEditorService} from "@app/article-editor/article-editor.service";
import {FileUploader} from "ng2-file-upload";
import {FolderService} from "@app/shared/common-folder-structure/service/folder.service";
import {AuthService} from "@app/core";
declare var $: any;

@Component({
	selector: 'app-add-option-modal',
	templateUrl: './add-option-modal.component.html',
	styleUrls: ['./add-option-modal.component.scss']
})
export class AddOptionModalComponent implements OnInit {
	public onClose: Subject<boolean>;
	title: string;
	lastPg: any;
    additionalInfo: any;
	optionTemplates: any[];
	optionType: string = 'blank';
	optionForm: FormGroup;
	optionTemplatesList: any;
	action: string = 'save';
	selectedTemplate : any;
	opportunityId : any;
    opportunityProposalId: any;
	template: any;
	select2TemplateConfig: any;
    subscriptions: object = {};
    templatesLoading: boolean = true;
	uniqueName:boolean = false;
	loading : boolean = false;
    showModalType : any;
    isFileSelected : boolean = false;
    fileList: Array<any> = [];
    public uploader: FileUploader;
    pdfUploadFiles = [];
    isFreemium: boolean = true;
    layout: any;
    blockData: any;
    dataSet: any[];
    freemiumAccess: boolean = true;
    screenLabel:string = 'Option templates';
    showAddNewTemplate:boolean = false;
    excludeOptions:any = [];
	constructor(private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		public optionTemplateService: OptionTemplateService,
        private articleservice: ArticleEditorService,
		public proposalTemplateService: ProposalTemplateService,
        public folderService: FolderService,
        public authService: AuthService       ) {

        let proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if(!proposalEditor){
            this.freemiumAccess = true
        } else {
            this.freemiumAccess = false
        }
    }

	ngOnInit() {
        this.uploader = new FileUploader({
            url: ``,
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['application/pdf'],
            disableMultipart: true
        });

        this.showModalType = this.showModalType;
		this.proposalTemplateService.templateName$.subscribe(template => {
			this.template = template;
		})
		this.onClose = new Subject();
		this.buildForm();
		this.getAllTemplates();
        if(this.freemiumAccess){
            setTimeout( ()=>{
                $("#optionName").select2("val", "");
                this.optionType = 'template';
            },400)
        }
	}

	initializeSelect2(optionTemplates) {
	    let dataToSet = [];

	    if(optionTemplates && optionTemplates.length) {
            optionTemplates.forEach((item: any) => {
                dataToSet.push({
                    id: parseInt(item.id),
                    text: item.optionName,
                    freemium: item.isFreemium
                })
            });
        }
        this.dataSet = dataToSet

        this.select2TemplateConfig = {
            width: '100%',
            data: dataToSet
        }
	    this.templatesLoading = false;
    }

	getAllTemplates() {
        this.additionalInfo['type'] = 'option';
        this.subscriptions['optionsList'] = this.optionTemplateService.getAllOptionTemplates(this.additionalInfo).subscribe(data => {
            if (data) {
                for (let index = 0; index < data['excludeOptions'].length; index++) {
                    if(data['excludeOptions'][index]['addon'] == 0) {
                        this.excludeOptions.push(data['excludeOptions'][index]['id']);
                    }
                }
            }
        })
    }

	// Form buildForm
	buildForm() {
		this.optionForm = this.fb.group({
			'id': [''],
			'optionName': ['', Validators.required],
            'customerOptionName' :['']
		})
	}
	// submit option
	save(optionType) {
		this.action = 'saving';
		let option: any;
		let formValue = this.optionForm.value;
		if (optionType == 'blank') {
			option = {
				"opportunityId" : this.opportunityId,
                "opportunityProposalId" : this.opportunityProposalId,
				"selectedTemplate" : this.selectedTemplate,
				"proposalTemplateId": this.template.id,
				"setupOption": {
					"optionName": formValue.optionName,
                    "customerOptionName": formValue.customerOptionName,
				},
				"lastPageNumber": this.lastPg,
                'freebee': this.freemiumAccess,
                'layout': this.layout ? this.layout : ''
			}
			this.saveProposalOption(option)
		}
		else {
			option = {
				"opportunityId" : this.opportunityId,
                "opportunityProposalId" : this.opportunityProposalId,
				"selectedTemplate" : this.selectedTemplate,
				"proposalTemplateId": this.template.id,
				"optionTemplateId": formValue.optionName,
				"lastPageNumber": this.lastPg
			}
			this.saveProposalOption(option);
		}
	}
	//save Proposal Options
	saveProposalOption(option) {
		this.optionTemplateService.addProposalOptions(option).subscribe((data:any) => {
            let contentPageId = true;
            if(data){
                if(data.hasOwnProperty('contentPageId')){
                    // contentPageId = data['contentPageId'];
                    data['blockData'] = this.blockData;
                    this.onClose.next(data);
                } else {
                    contentPageId = true
                    this.onClose.next(contentPageId);
                }
                if(!this.freemiumAccess){
                    let msg = {
                        type: 'upgradeFreemium'
                    };
                    top.postMessage(msg, '*');
                }
            }
			// this.action = 'save';
			// this.onClose.next(contentPageId);
			this.bsModalRef.hide();
		}, error => {
			console.log('err', error);
		})
	}
	// Select option
	optionSelected(event) {
		this.optionForm.reset();
		$("#optionName").select2("val", "");
		this.optionType = event.target.value;
	}

	// Getting control values for validation check
	get f() {
		return this.optionForm.controls;
	}

	// Check template name already exists
	checkUniqueTemplateName(value) {
		this.loading= true;
		let checkName: boolean = true;
		const q = new Promise((resolve, reject) => {
			let params: any = {
				optionName: value,
				tablePkId: this.additionalInfo['tablePkId'],
				tableType: this.additionalInfo['tableType']
			};
			if (checkName) {
				this.optionTemplateService.checkOptionTemplateName(params).subscribe(resp => {
					if (resp == true){
					  this.loading = true;
					  this.uniqueName = true;
					}
					else {
					  this.loading = false;
					  this.uniqueName = false;
					  resolve(null);
					}
				  });
			} else {
				this.loading = false;
				resolve(null);
			}
		})
		return q;
	}

    selectAndDragDropFiles(eve,uploadType) {
        this.loading = false;
        this.isFileSelected = true;
        let file = uploadType == 'dragAndDrop' ? eve : eve.target.files
        this.pdfUploadFiles = [];
        this.fileList = [];
        this.fileList.push({
            'name': file[0].name,
            'size': (file[0].size / 1024).toFixed(2),
            'type': file[0].name.substr(file[0].name.lastIndexOf('.') + 1)
        });
        this.pdfUploadFiles = file;
        $('[id^=uploadLoading]').removeClass('fas fa-circle-notch fa-spin fa-3x')
        setTimeout(() => {
            $("[id^=uploadLoading]").css("color", "#03A547");
            $('[id^=uploadLoading]').addClass('fa fa-check icon');
            $('[id^=uploadLoading]').removeClass('fa-spin');
            this.loading = true;
        }, 2000);
    }

    pdfUpload(){
        this.loading = false;
        if (this.pdfUploadFiles && this.pdfUploadFiles.length){
            this.articleservice.editorFileUpload(this.pdfUploadFiles[0], this.template.id).subscribe((res: any) => {
                if (res) {
                    this.onClose.next(res);
                    this.bsModalRef.hide();
                    this.loading = true;
                    if(!this.freemiumAccess){
                        let msg = {
                            type: 'upgradeFreemium'
                        };
                        top.postMessage(msg, '*');
                    }
                }
            });
        }

    }

    selectLayout(type,selectedClass){
        $(document).ready(function() {
            $('.layout').click(function() {
                $('.layout').removeClass('selected');
                $(this).addClass('selected');
            });
        });
        this.layout = type;
    }

    templateSelected(event) {
        this.optionForm.controls['optionName'].setValue(event.templateId);
    }

    ngOnDestroy() {
        for (let subscription in this.subscriptions) {
            this.subscriptions[subscription].unsubscribe();
        }
    }
}
