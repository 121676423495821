import * as tslib_1 from "tslib";
//--
import { InterfaceService } from './service.interface';
import { HybridFactory } from '@app/core/hybrid-factory';
var InterfaceResolver = /** @class */ (function (_super) {
    tslib_1.__extends(InterfaceResolver, _super);
    function InterfaceResolver() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Handling resolver data fetch fails
    InterfaceResolver.prototype.fail = function () {
        HybridFactory.navigate('/404');
        return null;
    };
    return InterfaceResolver;
}(InterfaceService));
export { InterfaceResolver };
