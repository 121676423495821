import { Component, OnInit, ViewEncapsulation, Input, Output, AfterViewInit, HostListener, Renderer, ElementRef, forwardRef, OnDestroy, EventEmitter} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'cs-timepicker',
    encapsulation: ViewEncapsulation.Emulated,
    templateUrl: './cs-timepicker.component.html',
    styles: [''],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CsTimepickerComponent),
            multi: true
        }
    ]
})
export class CsTimepickerComponent implements OnInit, ControlValueAccessor {
    _value: Date;
    private _onTouched: (_?: any) => void = () => { };
    private _onChange: (_?: any) => void = () => { };
    unregister: Function;
    @Output() hourEvent = new EventEmitter<string>();
    @Output() minuteEvent  = new EventEmitter<string>();

    constructor(
        private el_ref: ElementRef,
        private renderer: Renderer,
    ) { }

    @Output('valueChanged') valueEmitter: EventEmitter<any> = new EventEmitter();

    // Set Touched on blur
    onBlur() {
        this._onTouched();
    }

    // From ControlValueAccessor Interface
    writeValue(value: any) {
        if (value !== this._value) {
            if (value instanceof Date) this._value = value;
            else if (typeof (value) == 'string') {
                this._value = new Date(value);
                setTimeout(() => this.updateModelValue(), 10);
            }
            else {
                this._value = new Date();
                setTimeout(() => this.updateModelValue(), 10);
            }
            this.assignValues();
        }
    }

    // From ControlValueAccessor Interface
    registerOnChange(fn: (_: any) => void) {
        this._onChange = fn;
    }

    // From ControlValueAccessor Interface
    registerOnTouched(fn: (_: any) => void) {
        this._onTouched = fn;
    }

    // Publish updated value to form control
    updateModelValue() {
        this._onChange(this._value);
        this.emitValue(this._value);
    }

    // Timepicker functions
    hoursDropdownItems: string[] = [];
    minutesDropdownItems: string[] = ['00', '15', '30', '45'];
    hour: string;
    minute: string;
    second: number = 0;
    meridian: 'AM' | 'PM';
    private eventsSubscription: Subscription;
    @Input() timepickerChangeEvent: Observable<any>;
    _showMeridian: boolean = true;
    @Input("meridian")
    set showMeridian(val: boolean) {
        this._showMeridian = !!val;

        let maxHours: number = this._showMeridian ? 12 : 23;
        this.hoursDropdownItems = (new Array(maxHours)).join('#').split('#').map((h, index) => this.pad(index + 1));
        if(!this._showMeridian ){
            this.hoursDropdownItems = ["00"].concat(this.hoursDropdownItems);
        }
    }

    ngOnInit() {
        if(this.timepickerChangeEvent){
            this.eventsSubscription = this.timepickerChangeEvent.subscribe((data:any) => {
                if(data){
                    if(data.hasOwnProperty('hour')){
                        this.hour = data.hour;
                        this.onHourChange();
                    }
                    else if(data.hasOwnProperty('min')){
                        this.minute = data.min;
                        this.onMinuteChange();
                    }
                }
            });
        }
        if (!this.hoursDropdownItems.length) this.showMeridian = this._showMeridian;
    }

    // Assigning values to the component variables
    assignValues() {
        if (this._showMeridian) {
            this.meridian = this._value.getHours() >= 12 ? 'PM' : 'AM';
            this.hour = this._value.getHours() > 12 ? this.pad(this._value.getHours() - 12) : this.pad(this._value.getHours());
            if(this._value.getHours() == 0) this.hour = this.pad(this._value.getHours() + 12);
        } else {
            this.hour = this.pad(this._value.getHours());
        }
        this.minute = this.pad(this._value.getMinutes());
    }

    // Add leading zero
    pad(n) {
        return ("00" + n).slice(-2);
    }

    onHourChange() {
        this._value.setHours(this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour));
        if(this._showMeridian && this.meridian === 'AM' && this.hour == '12' ) this._value.setHours(0);
        if(this._showMeridian && this.meridian === 'PM' && this.hour == '12' ) this._value.setHours(12);
        this._onTouched();
        this.updateModelValue();
        this.hourEvent.emit((this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour)).toString());
    }

    onMinuteChange() {
        this._value.setMinutes(parseInt(this.minute));
        this._onTouched();
        this.updateModelValue();
        this.minuteEvent.emit(this.minute);
    }

    onMeridianChange() {
        if (this.meridian == 'AM' && this._value.getHours() > 12) {
            this._value.setHours(parseInt(this.hour));
        }
        else if(this.meridian == 'AM' && this._value.getHours() == 12) {
            this._value.setHours(parseInt(this.hour) - 12);
        }
        else if(this.meridian == 'PM' && this.hour == '12') {
            this._value.setHours(12);
        }
        else if (this.meridian === 'PM' && this._value.getHours() < 12) {
            this._value.setHours(parseInt(this.hour) + 12);
        }

        this._onTouched();
        this.updateModelValue();
        this.hourEvent.emit((this._showMeridian && this.meridian === 'PM' ? parseInt(this.hour) + 12 : parseInt(this.hour)).toString());
        this.minuteEvent.emit(this.minute);
    }

    emitValue(value) {
        this.valueEmitter.emit(value);
    }
}
