/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-tooltip/custom-tooltip.component.ngfactory";
import * as i2 from "../custom-tooltip/custom-tooltip.component";
import * as i3 from "../../customers/customer-contracts/cc-common.service";
import * as i4 from "./custom-tooltip-renderer.component";
var styles_CustomTooltipRendererComponent = [];
var RenderType_CustomTooltipRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomTooltipRendererComponent, data: {} });
export { RenderType_CustomTooltipRendererComponent as RenderType_CustomTooltipRendererComponent };
export function View_CustomTooltipRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-tooltip", [], null, null, null, i1.View_CustomTooltipComponent_0, i1.RenderType_CustomTooltipComponent)), i0.ɵdid(1, 114688, null, 0, i2.CustomTooltipComponent, [i3.CcCommonService], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomTooltipRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-tooltip-renderer", [], null, null, null, View_CustomTooltipRendererComponent_0, RenderType_CustomTooltipRendererComponent)), i0.ɵdid(1, 49152, null, 0, i4.CustomTooltipRendererComponent, [], null, null)], null, null); }
var CustomTooltipRendererComponentNgFactory = i0.ɵccf("app-custom-tooltip-renderer", i4.CustomTooltipRendererComponent, View_CustomTooltipRendererComponent_Host_0, {}, {}, []);
export { CustomTooltipRendererComponentNgFactory as CustomTooltipRendererComponentNgFactory };
