import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {MicroserviceHelperService} from '@app/shared/microservice/helper/microservice-helper.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MicroserviceHttpEndpointInterceptorService implements HttpInterceptor {

    constructor(
        private microserviceHelperService: MicroserviceHelperService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.microserviceHelperService.isReplaceableEndpointUrl(request.url)) {

            const requestReplacementUrl = this.microserviceHelperService
                .getReplaceableEndpointReplacementUrl(request.url);

            // clone the http request
            request = request.clone({
                url: requestReplacementUrl,
                headers: request.headers.set('X-CS-ENDPOINT-REPLACEMENT', 'YES')
            });
        }

        return next.handle(request);
    }

}
