import * as tslib_1 from "tslib";
import { ComponentFactoryResolver, Injector, ApplicationRef, } from '@angular/core';
import { MxGraphService } from "@app/workflow-common/services/mx-graph.service";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { TriggerDatasetService } from "@app/workflow-common/services/trigger-dataset.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { mxgraphFactory } from "mxgraph-factory";
import { noop } from "rxjs/index";
import { SequenceAddContactComponent } from "@app/workflow-common/sequence-add-contact/sequence-add-contact.component";
import { BsModalRef } from "ngx-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "../../sidepanel/services/sidepanel-modal.service";
import * as i2 from "./trigger-dataset.service";
import * as i3 from "./workflow.common.service";
import * as i4 from "./workflowhttp.service";
import * as i5 from "ngx-bootstrap/modal";
var _a = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}), mxCodec = _a.mxCodec, mxUtils = _a.mxUtils, mxEvent = _a.mxEvent, mxCell = _a.mxCell;
var MxGraphSequenceService = /** @class */ (function (_super) {
    tslib_1.__extends(MxGraphSequenceService, _super);
    function MxGraphSequenceService(modalService, triggerService, commonService, componentFactoryResolver, injector, appRef, workflowService, modalSidepanel) {
        var _this = _super.call(this, modalService, triggerService, commonService, componentFactoryResolver, injector, appRef, workflowService) || this;
        _this.modalService = modalService;
        _this.triggerService = triggerService;
        _this.commonService = commonService;
        _this.componentFactoryResolver = componentFactoryResolver;
        _this.injector = injector;
        _this.appRef = appRef;
        _this.workflowService = workflowService;
        _this.modalSidepanel = modalSidepanel;
        _this.workflowStatus = null;
        _this.firstTimeRender = true;
        return _this;
    }
    MxGraphSequenceService.prototype.render = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = noop; }
        if (!this.xmlSource) {
            this.mxGraph.getModel().beginUpdate();
            try {
                var parent_1 = this.mxGraph.getDefaultParent();
                var blockWidth = this.blockWidth, blockHeight = this.blockHeight;
                var x = (this.getContainerWidth() / 2) - (blockWidth / 2);
                //const x = 30;
                var y = 20;
                var startBlock = this.mxGraph.insertVertex(parent_1, 'cs_initializer', '', x, y, blockWidth, blockHeight, 'cursor=pointer');
                startBlock['block_name'] = 'initializer';
                startBlock['parentId'] = '0';
                this.addOverlay(startBlock, false);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
            }
        }
        else {
            var doc = mxUtils.parseXml(this.xmlSource);
            var codec = new mxCodec(doc);
            this.mainObjectName = '';
            codec.decode(doc.documentElement, this.mxGraph.getModel());
            _.forEach(this.mxGraph.getModel().cells, function (cell) {
                if (cell.isVertex() && !_this.options.viewMode && !_this.isMarketPlaceView && (cell['block_name'] !== 'dummyBlock')) {
                    var isElseBlock = cell['block_name'] == 'elseBlock';
                    if (_this.options.mode == 'sequence_setup') {
                        _this.addOverlay(cell, isElseBlock);
                    }
                }
            });
        }
        setTimeout(function () {
            _this.mxGraph.zoomActual();
            // this.mxGraph.zoomTo(.7, true);
        }, 300);
        callback(this);
    };
    MxGraphSequenceService.prototype.prepareInitBlockContainer = function (ModalData, callback) {
        var _this = this;
        if (callback === void 0) { callback = null; }
        if (this.options.mode == 'sequence_setup') {
            return _super.prototype.prepareInitBlockContainer.call(this, ModalData, callback);
        }
        // console.log('Modaldata', ModalData)
        var model = ModalData.model;
        var data = {};
        var padding = 20, maxWidth = 400;
        var mainContainer = $('<div></div>').css({
            whiteSpace: 'normal',
            padding: padding,
            textAlign: 'left',
            fontSize: '14px',
            lineHeight: '20px',
            cursor: 'default',
            width: maxWidth + 'px'
        }).addClass('box-sizing').attr('id', 'cs-init-block-container');
        var sequenceSection = $('<div></div>').css({ marginBottom: '10px' }).text('Sequence:');
        var sequenceText = $('<div></div>').css({ paddingLeft: '20px' }).text(model['displaySequenceName']);
        sequenceSection.append(sequenceText);
        mainContainer.append(sequenceSection);
        var contactSection = $('<div></div>').css({ marginBottom: '10px' }).text('Contact:');
        var contactName = $('<div></div>').css({ paddingLeft: '20px' }).addClass('cs-mb1').text(model['displaySelectedContactName']);
        contactSection.append(contactName);
        var _a = model['displayContactInfo'], emailAddress = _a.emailAddress, mobile = _a.mobile;
        if (emailAddress) {
            var emailWrap = $('<div></div>').css({ paddingLeft: '20px' }).addClass('cs-mb1'), emailIcon = $('<i></i>').addClass('fas fa-envelope'), emailText = $('<span></span>').css('margin-left', '5px').text(model['displayContactInfo']['emailAddress']);
            emailWrap.append(emailIcon);
            emailWrap.append(emailText);
            contactSection.append(emailWrap);
        }
        if (mobile) {
            var mobWrap = $('<div></div>').css({ paddingLeft: '20px' }), mobIcon = $('<i></i>').addClass('fas fa-mobile'), mobText = $('<span></span>').css('margin-left', '5px').text(model['displayContactInfo']['mobile']);
            mobWrap.append(mobIcon);
            mobWrap.append(mobText);
            contactSection.append(mobWrap);
        }
        mainContainer.append(contactSection);
        if (model['displayContactInfo']['mobile'] && model['displayContactInfo']['emailAddress']) {
            contactSection.css('margin-bottom', '0');
        }
        if (model['selectedContact'] && model['selectedContact']['mobile'].length > 1 && !mobile) {
            var formDiv_1 = $('<div></div>').addClass('boxed-input'), formTitleDiv = $('<div></div>').addClass('cs-mb2').text('Select a mobile number to send SMS messages');
            formDiv_1.append(formTitleDiv);
            var mobileList = model['selectedContact']['mobile'];
            mobileList.forEach(function (item) {
                var formCheckBoxDiv = $('<div></div>').addClass('form-check cs-mb1'), checkBoxLabel = $('<label></label>').addClass('form-check-label').attr('for', 'contact_mobi_' + item.phone_number).text(item.code + ' ' + item.phone_number), inputCheckBox = $('<input/>').addClass('form-check-input').attr('type', 'radio').attr('id', 'contact_mobi_' + item.phone_number).attr('name', 'contact_mobi').val(item.telephone_id);
                inputCheckBox.on('change', _this.onCheckedContact.bind(_this));
                formCheckBoxDiv.append(inputCheckBox);
                formCheckBoxDiv.append(checkBoxLabel);
                formDiv_1.append(formCheckBoxDiv);
            });
            mainContainer.append(formDiv_1);
        }
        if (!emailAddress) {
            var addEmailDiv = $('<div></div>').addClass('cs-mt2 text-color-danger'), addEmailText = $('<span></span>').addClass('cs-ml1').text('The contact doesn’t have an email address.'), warnIcon = $('<i></i>').addClass('fas fa-exclamation-triangle');
            addEmailDiv.append(warnIcon);
            addEmailDiv.append(addEmailText);
            var btn = document.createElement('button');
            btn.className = 'btn btn-plain link-color';
            btn.style.paddingLeft = '0';
            mxUtils.write(btn, 'Click to add an email address');
            btn.addEventListener(mxEvent.CLICK, function () {
                _this.showAddContact('email');
            });
            addEmailDiv.append(btn);
            mainContainer.append(addEmailDiv);
        }
        if (!mobile && model['selectedContact']['mobile'].length == 0) {
            var addMobDiv = $('<div></div>').addClass('cs-mt2 text-color-danger'), addMobText = $('<span></span>').addClass('cs-ml1').text('The contact doesn’t have a mobile number.'), warnIcon = $('<i></i>').addClass('fas fa-exclamation-triangle');
            addMobDiv.append(warnIcon);
            addMobDiv.append(addMobText);
            var btn = document.createElement('button');
            btn.className = 'btn btn-plain link-color';
            btn.style.paddingLeft = '0';
            mxUtils.write(btn, 'Click to add a number');
            btn.addEventListener(mxEvent.CLICK, function () {
                _this.showAddContact('mobile');
            });
            addMobDiv.append(btn);
            mainContainer.append(addMobDiv);
        }
        if (this.firstTimeRender && callback instanceof mxCell || typeof callback === 'function') {
            this.firstTimeRender = false;
            var clonedMainContainer = mainContainer.clone();
            clonedMainContainer.appendTo('body');
            data = {
                width: clonedMainContainer.outerWidth(),
                height: clonedMainContainer.outerHeight(),
            };
            clonedMainContainer.remove();
            if (typeof callback === 'function') {
                callback(ModalData, data);
            }
        }
        data['container'] = mainContainer;
        return data;
    };
    MxGraphSequenceService.prototype.onCheckedContact = function (event) {
        var _this = this;
        if (event.target.checked) {
            var cell_1 = this.mxGraph.getModel().getCell('cs_initializer');
            var model = cell_1.getValue().model;
            var selectedMobile = model['selectedContact']['mobile'].find(function (x) { return x.telephone_id == event.target.value; });
            model['displayContactInfo']['mobile'] = '+' + selectedMobile.code + ' ' + selectedMobile.phone_number;
            model['displayContactInfo']['contact_telephone_id'] = selectedMobile.telephone_id;
            var updated_data_1 = { model: model, action: 'sequence_save_send' };
            setTimeout(function () { _this.renderInitBlockContainer(cell_1, { formData: updated_data_1 }); });
            this.workflowService.triggerCustomHeaderEvent({
                action: 'save:disabled',
                value: !(model['displayContactInfo']['emailAddress'] && model['displayContactInfo']['mobile'])
            });
        }
    };
    MxGraphSequenceService.prototype.showAddContact = function (type) {
        var _this = this;
        var cell = this.mxGraph.getModel().getCell('cs_initializer');
        var model = cell.getValue().model;
        var initialState = {
            type: type,
            contactId: model['contactId'],
            title: type === 'email' ? 'Add an email address' : 'Add a mobile number',
            helpText: type === 'email' ? 'In order to send a sequence which involves emails, it’s important the ' +
                'contact has a valid email address.' : 'In order to send a sequence which involves SMS, it’s ' +
                'important the contact has a valid mobile number.'
        };
        this.modalSidepanel = this.modalService.show(SequenceAddContactComponent, {
            initialState: initialState,
            size: 'sm',
            'class': 'ng-sequence-popup',
            wrapperClass: 'cs-sequence-add-contact',
            backdrop: true,
        });
        this.modalSidepanel.content.onClose.subscribe(function (resp) {
            if (type === 'mobile') {
                model['displayContactInfo']['mobile'] = '+' + resp.code + ' ' + resp.phone_number;
                model['displayContactInfo']['contact_telephone_id'] = resp.id;
                model['selectedContact']['mobile'] = [resp];
            }
            if (type === 'email') {
                model['displayContactInfo']['emailAddress'] = resp['emailaddress'];
                model['selectedContact']['email']['emailAddress'] = resp['emailaddress'];
            }
            var updated_data = { model: model, action: 'sequence_save_send' };
            _this.workflowService.triggerCustomHeaderEvent({
                action: 'save:disabled',
                value: !(model['displayContactInfo']['emailAddress'] || model['displayContactInfo']['mobile'])
            });
            _this.renderInitBlockContainer(cell, { formData: updated_data });
        });
    };
    MxGraphSequenceService.prototype.skipOrUndoButton = function (cell, text) {
        var _this = this;
        if (text === void 0) { text = 'Skip'; }
        var button = document.createElement('button');
        button.className = 'btn btn-plain link-color';
        button.style.position = 'absolute';
        button.style.top = '20px';
        button.style.right = '20px';
        button.style.padding = '0';
        var value = cell.getValue();
        button.addEventListener(mxEvent.CLICK, function (e) {
            e.stopPropagation();
            e.preventDefault();
            var model = cell.getValue().model;
            var triggerContent = {
                action: !model['current_task_status'] ? 'showSkipConfirmation' : 'showUndoConfirmation',
                value: cell
            };
            _this.workflowService.triggerEvent(triggerContent);
        });
        mxUtils.write(button, value['model']['current_task_status'] == 'skip' ? 'Undo' : 'Skip');
        return button;
    };
    MxGraphSequenceService.ngInjectableDef = i0.defineInjectable({ factory: function MxGraphSequenceService_Factory() { return new MxGraphSequenceService(i0.inject(i1.SidepanelModalService), i0.inject(i2.TriggerDatasetService), i0.inject(i3.WorkflowCommonService), i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.INJECTOR), i0.inject(i0.ApplicationRef), i0.inject(i4.WorkflowhttpService), i0.inject(i5.BsModalRef)); }, token: MxGraphSequenceService, providedIn: "root" });
    return MxGraphSequenceService;
}(MxGraphService));
export { MxGraphSequenceService };
