/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../layout/sc-layout.component.ngfactory";
import * as i4 from "../layout/sc-layout.component";
import * as i5 from "../services/sc-http-service.service";
import * as i6 from "../services/event-dispatcher.service";
import * as i7 from "./sc-container.component";
import * as i8 from "ngx-bootstrap/modal";
var styles_ScContainerComponent = [];
var RenderType_ScContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScContainerComponent, data: {} });
export { RenderType_ScContainerComponent as RenderType_ScContainerComponent };
function View_ScContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "cs-help-text-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.helpText; _ck(_v, 3, 0, currVal_1); }); }
export function View_ScContainerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "modal-header"]], [[4, "box-shadow", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h4", [["class", "modal-title"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(4, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScContainerComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "close"], ["id", "close-button"], ["style", "margin-left: auto;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-sc-layout", [["class", "h-100 d-block"]], null, null, null, i3.View_ScLayoutComponent_0, i3.RenderType_ScLayoutComponent)), i0.ɵdid(13, 114688, null, 0, i4.ScLayoutComponent, [i1.TranslateService, i5.ScHttpServiceService, i6.EventDispatcherService], { objectName: [0, "objectName"], screenType: [1, "screenType"], sourceId: [2, "sourceId"], selectedTemplateData: [3, "selectedTemplateData"], viewMode: [4, "viewMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_3 = !!_co.helpText; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.objectName; var currVal_5 = _co.screenType; var currVal_6 = _co.sourceId; var currVal_7 = _co.selectedTemplateData; var currVal_8 = _co.viewMode; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_ScContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sc-container", [], null, null, null, View_ScContainerComponent_0, RenderType_ScContainerComponent)), i0.ɵdid(1, 245760, null, 0, i7.ScContainerComponent, [i8.BsModalRef, i6.EventDispatcherService, i1.TranslateService, i0.KeyValueDiffers, i5.ScHttpServiceService, "confirmationBoxHelper", "$scope"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScContainerComponentNgFactory = i0.ɵccf("app-sc-container", i7.ScContainerComponent, View_ScContainerComponent_Host_0, {}, {}, []);
export { ScContainerComponentNgFactory as ScContainerComponentNgFactory };
