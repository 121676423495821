var ContractTemplateDeleteEvent = /** @class */ (function () {
    function ContractTemplateDeleteEvent(pkId, customersCount, resolveFn, rejectFn) {
        this.pkId = pkId;
        this.noOfCustomersAssigned = customersCount;
        this.executor = {
            resolve: resolveFn,
            reject: rejectFn
        };
    }
    return ContractTemplateDeleteEvent;
}());
export { ContractTemplateDeleteEvent };
