import Handsontable from 'handsontable';
import { HansontableSelect2 } from "@app/directives";
import { CsToastBoxService } from "@app/services";
import { Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../utility-services/cs-toast-box.service";
var MultipleInvoiceService = /** @class */ (function () {
    function MultipleInvoiceService(toastBox) {
        var _this = this;
        this.toastBox = toastBox;
        this.spreadsheetData = [];
        this.nominalCode = [];
        this.taxDetails = [];
        this.invoiceCategories = [];
        this.userGroups = [];
        this.spreadSheetUpdateData = [];
        this.invoiceType = [{ 'id': 'partial', 'text': 'Additional' }, { 'id': 'interim', 'text': 'Interim' }, { 'id': 'final', 'text': 'Final' }];
        this.totalPrice = [];
        this.total_cost = 0.00;
        this.total_VAT = 0.00;
        this.grand_total = 0.00;
        this.SubTotal = 0.00;
        this.validateErrorRow = false;
        this.vatType = '';
        this.isHideVat = false;
        this.invoiceTaxState = '';
        this.invalidRows = 0;
        this.emptyCellValidator = function (value, callback) {
            if (value === '') {
                _this.toastBox.show('Empty cell not allowed', 1000);
            }
            callback(true);
        };
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
        this.totalEmitterSource = new Subject();
        var A = Handsontable.languages.getLanguageDictionary('en-US');
        A['Filters:buttons.ok'] = 'Filter';
        Handsontable.languages.registerLanguageDictionary(A);
    }
    MultipleInvoiceService.prototype.getSpreadSheetData = function (hotId) {
        return this.spreadsheetData;
    };
    MultipleInvoiceService.prototype.Spreadsheetdata = function (data, route) {
        this.invoiceId = route.snapshot.params['invoiceId'];
        this.spreadsheetData = data['lineItems'];
        if (this.invoiceId > 0) {
            this.spreadSheetUpdateData = data['lineItems'];
        }
        else {
            var updateData_1 = [];
            _.each(this.spreadsheetData, function (value, key) {
                if (value['unitprice'] != 0) {
                    updateData_1.push(data['lineItems'][key]);
                }
            });
            this.spreadSheetUpdateData = updateData_1;
        }
        this.nominalCode = data['nominalCode'];
        this.taxDetails = data['taxDetails'];
        this.invoiceCategories = data['invoiceCategories'];
        this.userGroups = data['userGroups'];
        this.defaultTaxItemId = data['defaultTaxItemId'];
        this.isHideVat = data['isHideVat'];
        if (this.invoiceTaxState === '') {
            // @ts-ignore
            this.invoiceTaxState = (_.max(_.pluck(data['lineItems'], 'taxItemId')) > null);
        }
        if (this.invoiceTaxState === false) {
            this.isHideVat = true;
        }
    };
    MultipleInvoiceService.prototype.afterInit = function (hotId, hotInstance) {
        this.hotInstances = hotInstance;
        this.hotFilterInstance = hotInstance.getPlugin('filters');
        //console.log('filter', this.hotFilterInstance);
        var self = this;
        hotInstance.updateSettings({
            className: 'overflow_handsontable',
            afterGetColHeader: function (col, TH) {
                var requiredCols = [8, 9, 10, 11];
                if (requiredCols.indexOf(col) >= 0) {
                    TH.className = 'col_header_bg';
                }
                var BUTTON_CLASS_NAME = 'changeType';
                var existingButton = TH.querySelector('.' + BUTTON_CLASS_NAME);
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]' && this.getSettings().filters.indexOf(col) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }
            },
            afterFilter: function (conditionsStack) {
                var data = this.getData();
                var keys = Object.keys(data);
                console.log(typeof data);
                var Indexes = _.range(0, (data.length));
                self.spreadSheetUpdateData = [];
                Indexes.forEach(function (key) {
                    var jobNumber = hotInstance.getDataAtRowProp(key, 'jobNumber');
                    var lineItem = _.findWhere(self.spreadsheetData, { 'jobNumber': jobNumber });
                    var index = _.findIndex(self.spreadSheetUpdateData, { 'jobNumber': jobNumber });
                    console.log(lineItem);
                    console.log(data);
                    if (index > -1) {
                        self.spreadSheetUpdateData[index] = lineItem;
                    }
                    else {
                        if (lineItem.unitprice > 0) {
                            self.spreadSheetUpdateData.push(lineItem);
                        }
                    }
                });
                console.log(self.spreadSheetUpdateData.length);
                console.log(self.spreadsheetData.length);
                self.calculateGrandTotal();
            }
        });
        if (this.isHideVat) {
            hotInstance.updateSettings({
                hiddenColumns: {
                    columns: [0, 10, 12, 13]
                }
            });
        }
        hotInstance.render();
    };
    MultipleInvoiceService.prototype.spreadsheetSettings = function () {
        var _this = this;
        return {
            preventOverflow: 'vertical',
            autoWrapRow: true,
            rowHeaders: true,
            colHeaders: true,
            stretchH: 'all',
            dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
            filters: [1, 3, 4, 5, 6, 7],
            autoColumnSize: true,
            fillHandle: {
                direction: 'vertical'
            },
            // colWidths: [10, 45, 50, 60, 75, 50, 50, 60, 50, 60, 30, 50],
            hiddenColumns: {
                columns: [0, 12, 13]
            },
            columns: [
                { data: 'id', readOnly: true },
                { title: 'Job date', data: 'jobDate', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                { title: 'Job number', data: 'jobNumber', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                { title: 'Job description', data: 'description', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                { title: 'Customer reference', data: 'customerRef', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                { title: 'Job complete', data: 'jobStatus', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true },
                { title: 'User group', data: 'userGroupDesc', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true, },
                { title: 'Invoice category', data: 'categoryidDesc', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true, },
                { title: 'Invoice type',
                    //invalidCellClassName: 'errorRowList',
                    data: 'invoiceType', placeholder: 'Please select', className: 'overflow_handsontable select_icon required', validator: 'emptyCellValidator',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        var invoiceType = _.findWhere(_this.invoiceType, { id: value });
                        value = invoiceType.text;
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.invoiceType,
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                { title: 'Nominal code', data: 'nominalcode', placeholder: 'Please select', className: 'overflow_handsontable select_icon required', validator: 'emptyCellValidator',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        var nominalCode = _this.nominalCode.find(function (p) { return p['id'] == value; });
                        if (nominalCode)
                            nominalCode = nominalCode['text'];
                        else
                            nominalCode = '';
                        value = nominalCode;
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCode,
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                { title: this.taxDetails['taxLabel'], data: 'taxItemId', placeholder: 'Please select', className: 'overflow_handsontable select_icon required', validator: 'emptyCellValidator',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        var taxItems = _this.taxDetails['taxItems'].find(function (p) { return p['id'] == value; });
                        if (taxItems)
                            taxItems = taxItems['text'];
                        else
                            taxItems = '';
                        value = taxItems;
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxDetails['taxItems']),
                        width: 'resolve',
                        dropdownAutoWidth: true,
                        dropdownCssClass: 'handsontable-select'
                    })
                },
                { title: 'Total price', data: 'unitprice', type: 'numeric', format: '0.00', validator: 'emptyCellValidator',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }, },
                { data: 'userGroup', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true, },
                { data: 'categoryid', readOnlyCellClassName: 'cursor-not-allowed-disabled', readOnly: true, },
            ],
            beforeAutofill: function (start, end, data) {
                var Indexes = _.range(start['row'], (end['row'] + 1));
                Indexes.forEach(function (key) {
                    var currentInstance = _this.hotInstances;
                    var jobNumber = currentInstance.getDataAtRowProp(key, 'jobNumber');
                    var lineItem = _.findWhere(_this.spreadsheetData, { 'jobNumber': jobNumber });
                    var index = _.findIndex(_this.spreadSheetUpdateData, { 'jobNumber': jobNumber });
                    if (index > -1 && _this.invoiceId > 0) {
                        _this.spreadSheetUpdateData[index] = lineItem;
                    }
                    else if (index > -1 && !_this.invoiceId) {
                        if (lineItem['unitprice'] <= 0) {
                            _this.spreadSheetUpdateData.splice(index, 1);
                        }
                    }
                    else {
                        if (lineItem['unitprice'] > 0) {
                            _this.spreadSheetUpdateData.push(lineItem);
                        }
                    }
                });
            },
        };
    };
    MultipleInvoiceService.prototype.formatTaxItems = function (taxData) {
        var tax = [];
        var taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach(function (province) {
            var taxLabel = province[0]['taxProvinceName'];
            tax.push({ 'text': taxLabel, 'children': province });
        });
        return tax;
    };
    MultipleInvoiceService.prototype.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {
        if (value != '') {
            var data = parseFloat(value);
            if (isNaN(value)) {
                this.invalidRows += 1;
            }
            else if ((data.toFixed(0).length) > limit) {
                this.invalidRows += 1;
            }
            else {
                this.invalidRows -= 1;
            }
        }
    };
    MultipleInvoiceService.prototype.afterChange = function (changes, src, hotId) {
        var _this = this;
        if (!changes)
            return;
        _.forEach(changes, function (change, key) {
            var currentInstance = _this.hotInstances;
            var jobNumber = currentInstance.getDataAtRowProp(change[0], 'jobNumber');
            var lineItem = _.findWhere(_this.spreadsheetData, { 'jobNumber': jobNumber });
            var index = _.findIndex(_this.spreadSheetUpdateData, { 'jobNumber': jobNumber });
            if (index > -1 && _this.invoiceId > 0) {
                _this.spreadSheetUpdateData[index] = lineItem;
            }
            else if (index > -1 && !_this.invoiceId) {
                if (lineItem['unitprice'] <= 0) {
                    _this.spreadSheetUpdateData.splice(index, 1);
                }
            }
            else {
                if (lineItem['unitprice'] > 0) {
                    _this.spreadSheetUpdateData.push(lineItem);
                }
            }
            if (change[1] == 'unitprice' || change[1] == 'taxItemId' || change[1] == 'invoiceType') {
                _this.calculateGrandTotal();
                //setTimeout(()=>{
                _this.showHighligherError();
                //},400);
            }
        });
    };
    MultipleInvoiceService.prototype.showHighligherError = function () {
        var _this = this;
        var currentInstance = this.hotInstances;
        var grandTotal = 0, interimInvoiceTotal = 0, data = currentInstance.getSourceData();
        _.forEach(data, function (rowItem, rowInd) {
            var total = rowItem['total'] ? parseFloat(rowItem['total']) : 0;
            var totalVat = rowItem['totalVat'] ? parseFloat(rowItem['totalVat']) : 0;
            grandTotal = total + totalVat;
            interimInvoiceTotal = parseFloat(rowItem['interimInvoiceTotal']);
            var currentMetaRow = currentInstance.getCellMetaAtRow(rowInd);
            if ((rowItem['invoiceType'] == 'final') && (grandTotal < interimInvoiceTotal)) {
                _.forEach(currentMetaRow, function (col, key) {
                    _this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                _this.toastBox.show('Interim invoice is greater for the final invoice raised jobs', 1000);
            }
            else {
                _.forEach(currentMetaRow, function (col, key) {
                    _this.removeCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
            }
            if (isNaN(total)) {
                _.forEach(currentMetaRow, function (col, key) {
                    _this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                _this.toastBox.show('Enter numeric data', 1000);
            }
            else if ((total.toFixed(0).length) > 10) {
                _.forEach(currentMetaRow, function (col, key) {
                    _this.addCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
                _this.toastBox.show('Invalid data length', 1000);
            }
            else {
                _.forEach(currentMetaRow, function (col, key) {
                    _this.removeCellClass(rowInd, key, 'errorRowList', currentInstance);
                });
            }
        });
        currentInstance.render();
    };
    MultipleInvoiceService.prototype.beforeValidate = function (value, row, prop, source, hotId) {
        if (value != '') {
            var activeEditor = this.hotInstances.getActiveEditor(), col = activeEditor.col, removeHighlightRow = [], cellType = activeEditor.cellProperties.type;
            if (this.validateErrorRow === true) {
                removeHighlightRow[0] = { 'row': row };
                // this.highlightErrorRows(removeHighlightRow, false);
            }
            if (prop == 'unitprice') {
                this.numericLimitValidator(value, row, col, prop, 10, cellType, hotId);
            }
        }
    };
    MultipleInvoiceService.prototype.highlightErrorRows = function (errorRow, isHighlight) {
        this.validateErrorRow = true;
        for (var i = 0; i < errorRow.length; i++) {
            var currentRow = errorRow[i]['row'];
            var currentInstance = this.hotInstances;
            var currentMetaRow = currentInstance.getCellMetaAtRow(currentRow);
            for (var j = 1; j < currentMetaRow.length; j++) {
                if (isHighlight === true) {
                    //currentInstance.setCellMeta(currentRow, j, 'className', 'errorRowList');
                    this.addCellClass(currentRow, j, 'errorRowList', currentInstance);
                }
                else {
                    //currentInstance.setCellMeta(currentRow, j, 'className', '');
                    this.removeCellClass(currentRow, j, 'errorRowList', currentInstance);
                }
            }
        }
        this.hotInstances.render();
    };
    MultipleInvoiceService.prototype.addCellClass = function (rowIndex, columnIndex, newClass, instance) {
        var cellMeta = instance.getCellMeta(rowIndex, columnIndex);
        var cellClass = cellMeta.className;
        var hasClass = !!cellClass.match(new RegExp('(\\s|^)' + newClass + '(\\s|$)'));
        if (!hasClass) {
            instance.setCellMeta(rowIndex, columnIndex, 'className', cellMeta.className + ' ' + newClass);
        }
    };
    MultipleInvoiceService.prototype.removeCellClass = function (rowIndex, columnIndex, removeClass, instance) {
        var cellMeta = instance.getCellMeta(rowIndex, columnIndex);
        var existsClass = this.removeClass(cellMeta.className, removeClass);
        instance.setCellMeta(rowIndex, columnIndex, 'className', existsClass);
    };
    MultipleInvoiceService.prototype.removeClass = function (currentClasses, toRemove) {
        if (currentClasses) {
            var reg = new RegExp('(\\s|^)' + toRemove + '(\\s|$)');
            currentClasses = currentClasses.replace(reg, '');
        }
        return currentClasses;
    };
    MultipleInvoiceService.prototype.calculateGrandTotal = function (sourceData) {
        var _this = this;
        if (sourceData === void 0) { sourceData = []; }
        var totalvat, effective_tax, total, selectedTax, selectedId, grandTotal, interimInvoiceTotal;
        var total_price = 0, total_tax = 0, total_vat_rate = 0, sub_total_price = 0, breakdown_by_vat_rates = {}, vat_cost, total_obj = {};
        for (var i = 0, l = this.spreadSheetUpdateData.length; i < l; i++) {
            if (this.isHideVat) {
                effective_tax = 0;
                selectedId = null;
            }
            else {
                selectedTax = this.taxDetails['taxItems'].find(function (p) { return p['id'] == _this.spreadSheetUpdateData[i]['taxItemId']; });
                if (typeof selectedTax == 'undefined') {
                    selectedTax = this.taxDetails['taxItems'].find(function (p) { return p['id'] = _this.defaultTaxItemId; });
                }
                selectedId = selectedTax['id'];
                effective_tax = selectedTax['effective_tax'];
            }
            /** calculate exc total*/
            if (this.vatType == 'inc_vat') {
                total = (this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice'] * 100) / (100 + parseFloat(effective_tax));
            }
            else {
                total = this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice'];
            }
            total = parseFloat(total.toFixed(2));
            /** calculate total vat */
            if (this.vatType == 'inc_vat') {
                totalvat = (this.spreadSheetUpdateData[i]['quantity'] * this.spreadSheetUpdateData[i]['unitprice']) - total;
            }
            else {
                totalvat = (total * effective_tax) / 100;
            }
            this.spreadSheetUpdateData[i]['total'] = total.toFixed(2);
            this.spreadSheetUpdateData[i]['vatrate'] = effective_tax;
            this.spreadSheetUpdateData[i]['totalVat'] = totalvat.toFixed(2);
            this.spreadSheetUpdateData[i]['taxItemId'] = selectedId;
            grandTotal = (parseFloat(this.spreadSheetUpdateData[i]['total']) + parseFloat(this.spreadSheetUpdateData[i]['totalVat']));
            interimInvoiceTotal = parseFloat(this.spreadSheetUpdateData[i]['interimInvoiceTotal']);
            total_price += (parseFloat(this.spreadSheetUpdateData[i]['total']) + parseFloat(this.spreadSheetUpdateData[i]['totalVat']));
            sub_total_price += parseFloat(this.spreadSheetUpdateData[i]['total']);
            total_tax += parseFloat(this.spreadSheetUpdateData[i]['totalVat']);
            if (this.spreadSheetUpdateData[i]['taxItemId'] !== 0) {
                vat_cost = (typeof breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']] === 'undefined') ? 0 : breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']];
                breakdown_by_vat_rates[this.spreadSheetUpdateData[i]['vatrate']] = vat_cost + parseFloat(this.spreadSheetUpdateData[i]['totalVat']);
            }
        }
        total_obj = {
            'total_price': total_price.toFixed(2),
            'sub_total_price': sub_total_price.toFixed(2),
            'total_tax': total_tax.toFixed(2),
            'breakdown_by_vat_rates': breakdown_by_vat_rates
        };
        this.totalEmitterSource.next(total_obj);
    };
    MultipleInvoiceService.prototype.setVatType = function (vatType) {
        this.vatType = vatType;
        if (this.spreadSheetUpdateData.length != 0) {
            this.calculateGrandTotal();
        }
    };
    MultipleInvoiceService.ngInjectableDef = i0.defineInjectable({ factory: function MultipleInvoiceService_Factory() { return new MultipleInvoiceService(i0.inject(i1.CsToastBoxService)); }, token: MultipleInvoiceService, providedIn: "root" });
    return MultipleInvoiceService;
}());
export { MultipleInvoiceService };
