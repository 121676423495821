import { Component } from '@angular/core';

@Component({
    selector: 'app-custom-tooltip-renderer',
    template: `
        <app-custom-tooltip [message]="params"></app-custom-tooltip>`,
    styleUrls: []
})
export class CustomTooltipRendererComponent {
    params: any;

    agInit(params: any): void {
        this.params = params;
    }
}
