import { OnDestroy, OnInit, KeyValueDiffers } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { EventDispatcherService } from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import { TranslateService } from "@ngx-translate/core";
import { FollowUpInputModel } from "@app/shared/service-reminder-communication/services/input-model";
import { ScHttpServiceService } from "@app/shared/service-reminder-communication/services/sc-http-service.service";
var ScContainerComponent = /** @class */ (function () {
    function ScContainerComponent(modalRef, eventDispatcher, translate, differs, httpService, confirmationBoxHelper, ajsScope) {
        this.modalRef = modalRef;
        this.eventDispatcher = eventDispatcher;
        this.translate = translate;
        this.differs = differs;
        this.httpService = httpService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.title = '';
        this.objectName = '';
        this.unSaveConfirmation = false;
        this.disabledAction = true;
        this.formInput = {};
        this.deletedItems = [];
        this.helpText = '';
        /* primary of Service reminder ID or ServiceJob ID on contract template */
        this.sourceId = 0;
        this.saving = false;
        this._reservedSinglePropertyInput = {};
        this._reservedMultiplePropertyInput = {};
        this.selectedTemplateData = [];
    }
    ScContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                var value = data['data'];
                if (action === EventDispatcherService.CONSTANTS['isValidForm']) {
                    _this.disabledAction = !value['isValidForm'];
                }
                else if (action === EventDispatcherService.CONSTANTS['dispatchUpdatedForm']) {
                    _this.formInput = value;
                }
                else if (action === EventDispatcherService.CONSTANTS['close']) {
                    _this.onClose.next('closed');
                    _this.modalRef.hide();
                }
                else if (action === EventDispatcherService.CONSTANTS['dispatchReservedData']) {
                    _this._reservedSinglePropertyInput = Object.assign({}, value['reservedSinglePropertyInput']);
                    _this._reservedMultiplePropertyInput = Object.assign({}, value['reservedMultiplePropertyInput']);
                }
                else if (action === EventDispatcherService.CONSTANTS['unSavedChanges']) {
                    _this.unSaveConfirmation = true;
                }
                else if (action === EventDispatcherService.CONSTANTS['pushDeletedItem']) {
                    if (_this.deletedItems.indexOf(value['deletedItem']) == -1) {
                        _this.deletedItems.push(value['deletedItem']);
                    }
                }
                else if (action === EventDispatcherService.CONSTANTS['onSaveAction']) {
                    _this.saveAction();
                }
            }
        });
    };
    ScContainerComponent.prototype.closeModal = function () {
        var _this = this;
        if (this.unSaveConfirmation) {
            this.confirmationBoxHelper.getConfirmation(this.translate.instant('Before.Close.Save.Warning.Message'), this.ajsScope)
                .then(function () {
                _this.unSaveConfirmation = false;
                _this.saveAction();
            })
                .catch(function () {
                _this.unSaveConfirmation = false;
                _this.modalRef.hide();
            });
        }
        else {
            this.onClose.next('closed');
            this.modalRef.hide();
        }
    };
    ScContainerComponent.prototype.prepareFormData = function (formInput) {
        if (typeof formInput.interval != "number") {
            return [];
        }
        var formData = [];
        var make = function (formInput) {
            var formDataMock = [
                'sourceId',
                'serviceReminderConfigId',
                'communicationId',
                'name',
                'propertyType',
                'interval',
                'messages',
                'screenType',
                'isFollowUpItem',
                'followUpOrder',
                '_intervalOld',
                '_followUpOrderOld'
            ];
            var tmpObj = {};
            formDataMock.forEach(function (keyName) {
                if (typeof formInput[keyName] != "object" && typeof formInput[keyName] != undefined) {
                    tmpObj[keyName] = formInput[keyName];
                }
                else if (typeof formInput[keyName] == "object" && formInput[keyName] == null) {
                    tmpObj[keyName] = null;
                }
                else if (keyName == 'messages') {
                    // ['email', 'sms', 'letter'].forEach( (comType:string) => {
                    //     if(formInput[keyName][comType]) {
                    //         formInput[keyName][comType]['message'];
                    //         formInput[keyName][comType]['subject'];
                    //     }
                    // });
                    tmpObj[keyName] = formInput[keyName];
                }
            });
            formData.push(tmpObj);
            if (Array.isArray(formInput.followUpMessage) && formInput.followUpMessage.length > 0) {
                formInput.followUpMessage.forEach(function (followUpItem) {
                    make(followUpItem);
                });
            }
        };
        make(formInput);
        return formData;
    };
    /*makeNewFormData(oldModel: any[], newModel: any[]) {
        if(oldModel.length == 0) {
            return newModel;
        }
        let newFormData = [], deletedItems = [];
        oldModel.forEach( (item) => {
            let serviceReminderConfigId = item['serviceReminderConfigId'];
            let target = newModel.find( (newItem) => newItem['serviceReminderConfigId'] ===  serviceReminderConfigId);
            if(!target) {
                deletedItems.push(serviceReminderConfigId);
            }
            if(target['interval'] && item['interval'] != target['interval']) {
                ['email', 'sms'].forEach( (msgType) => {
                    if(!target['messages'][msgType]['templateId'] || (target['messages'][msgType] && target['messages'][msgType]['templateId'] == item['messages'][msgType]['templateId']) ) {
                        delete target['messages'][msgType];
                    }
                });
                newFormData.push(target);
            }
            else if(target['interval'] && item['interval'] == target['interval']
                && ( (target['messages']['email']
                    && target['messages']['email']['templateId']
                    && target['messages']['email']['templateId'] != item['messages']['email']['templateId'])
                    || (target['messages']['sms']
                    && target['messages']['sms']['templateId']
                    && target['messages']['sms']['templateId'] != item['messages']['sms']['templateId'])
                )
            ) {
                newFormData.push(target);
            }
        });
        return { newFormData, deletedItems };
    }*/
    ScContainerComponent.prototype.saveAction = function () {
        var _this = this;
        var singleInputModel = this.prepareFormData(JSON.parse(JSON.stringify(this.formInput['singlePropertyInputModel'])));
        var multipleInputModel = this.prepareFormData(JSON.parse(JSON.stringify(this.formInput['multiplePropertyInputModel'])));
        var formData = singleInputModel.concat(multipleInputModel);
        var serviceReminderConfig = {
            formData: formData,
            deletedItems: this.deletedItems,
            sourceId: this.sourceId
        };
        console.log('form', serviceReminderConfig);
        if (!this.sourceId) {
            this.onClose.next({ serviceReminderConfig: formData });
            this.modalRef.hide();
        }
        else {
            this.saving = true;
            this.httpService.saveServiceReminderConfig({ serviceReminderConfig: serviceReminderConfig }, this.screenType)
                .subscribe({
                next: function (resp) {
                    if (resp['serviceReminderConfig']) {
                        _this.modalRef.hide();
                    }
                    else {
                        _this.saving = false;
                    }
                }
            });
        }
    };
    ScContainerComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.onClose.complete();
    };
    return ScContainerComponent;
}());
export { ScContainerComponent };
