import { OnInit, ElementRef, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from "@ngx-translate/core";
import { BsDatepickerConfig, BsDatepickerDirective, BsDaterangepickerDirective, BsDropdownDirective } from "ngx-bootstrap";
var DatefilterComponent = /** @class */ (function () {
    function DatefilterComponent(translate, eRef, settings) {
        this.translate = translate;
        this.eRef = eRef;
        this.settings = settings;
        this.rangeSearch = '';
        this.filterParams = {
            name: '',
            fromDate: '',
            toDate: ''
        };
        this.displayFilter = '';
        this.dateRangeFilter = 'All days';
        this.filterObjects = [
            { 'key': 'Inprogress', value: 'In progress', 'selected': true, 'visibility': true },
            { 'key': 'Finished', value: 'Finished', 'selected': false, 'visibility': true },
            { 'key': 'Failed', value: 'Failed', 'selected': false, 'visibility': true },
            { 'key': 'All', value: 'All', 'selected': false, 'visibility': true },
        ];
        this.selectedFilterObject = 'In progress';
        this.autoClose = true;
        this.formats = {};
        this.currentDate = new Date();
        this.maxDate = new Date();
        this.customBsConfig = new BsDatepickerConfig();
        this.onChange = new EventEmitter();
    }
    DatefilterComponent.prototype.onClick = function (target) {
        if (!this.eRef.nativeElement.contains(target)) {
            this.hideAllPickers();
            if (this.dropdown && this.dropdown.isOpen) {
                this.dropdown.hide();
            }
        }
        if ($(target).hasClass('clearfix')) {
            $('.filter-user-width').removeClass('open');
        }
    };
    DatefilterComponent.prototype.ngOnInit = function () {
        this.formats = this.settings.getRegionalDateFormats();
        this.initDateFilter();
    };
    DatefilterComponent.prototype.initDateFilter = function () {
        var intlSettings = this.settings._csIntlSettings;
        var date = Date.now();
        var _today = moment(date), today = _today.format('YYYY-MM-DD'), todayDisplay = _today.format(this.formats[intlSettings['short_date']]);
        var _tomorrow = moment(date).add(1, 'day'), tomorrow = _tomorrow.format('YYYY-MM-DD'), tomorrowDisplay = _tomorrow.format(this.formats[intlSettings['short_date']]);
        var currentWeek = {
            from: moment(date).subtract(0, 'weeks').startOf('week').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'weeks').startOf('week').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'weeks').endOf('week').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'weeks').endOf('week').format(this.formats[intlSettings['short_date']])
        };
        var currentMonth = {
            from: moment(date).subtract(0, 'months').startOf('month').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'months').startOf('month').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'months').endOf('month').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'months').endOf('month').format(this.formats[intlSettings['short_date']]),
        };
        var CurrentQuarterMonth = moment(date).utc().quarter();
        var currentQuarterEndDate = moment().quarter(CurrentQuarterMonth).endOf('quarter');
        var currentQuarterStartDate = moment().quarter(CurrentQuarterMonth).startOf('quarter');
        var currentQuarter = {
            from: currentQuarterStartDate.format('YYYY-MM-DD'),
            fromDisplay: currentQuarterStartDate.format(this.formats[intlSettings['short_date']]),
            to: currentQuarterEndDate.format('YYYY-MM-DD'),
            toDisplay: currentQuarterEndDate.format(this.formats[intlSettings['short_date']])
        };
        var currentYear = {
            from: moment(date).subtract(0, 'year').startOf('year').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'year').startOf('year').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'year').endOf('year').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'year').endOf('year').format(this.formats[intlSettings['short_date']]),
        };
        var lastWeek = {
            from: moment(date).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'weeks').startOf('week').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'weeks').endOf('week').format(this.formats[intlSettings['short_date']]),
        };
        var lastMonth = {
            from: moment(date).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'months').startOf('month').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'months').endOf('month').format(this.formats[intlSettings['short_date']]),
        };
        var LastQuarterMonth = CurrentQuarterMonth == 1 ? 4 : CurrentQuarterMonth - 1;
        var lastQuarterEndDate = moment().quarter(LastQuarterMonth).endOf('quarter');
        var lastQuarterStartDate = moment().quarter(LastQuarterMonth).startOf('quarter');
        var lastQuarter = {
            from: lastQuarterStartDate.format('YYYY-MM-DD'),
            fromDisplay: lastQuarterStartDate.format(this.formats[intlSettings['short_date']]),
            to: lastQuarterEndDate.format('YYYY-MM-DD'),
            toDisplay: lastQuarterEndDate.format(this.formats[intlSettings['short_date']])
        };
        var lastyear = {
            from: moment(date).subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'year').startOf('year').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'year').endOf('year').format(this.formats[intlSettings['short_date']]),
        };
        this.filterDate = [
            { 'text': this.translate.instant("All.Days"), 'fromDate': '', 'toDate': '', 'selected': true, 'show': true, key: 'all' },
            { 'text': this.translate.instant("Today"), 'fromDate': today, 'toDate': today, 'selected': false, 'show': true, key: 'today', 'display': todayDisplay },
            { 'text': this.translate.instant("Tomorrow"), 'fromDate': tomorrow, 'toDate': tomorrow, 'selected': false, 'show': true, key: 'tomorrow', 'display': tomorrowDisplay },
            { 'text': this.translate.instant("This.Week"), 'fromDate': currentWeek.from, 'fromDisplay': currentWeek.fromDisplay, 'toDate': currentWeek.to, 'toDisplay': currentWeek.toDisplay, 'selected': false, 'show': true, key: 'this-week' },
            { 'text': this.translate.instant("This.Month"), 'fromDate': currentMonth.from, 'fromDisplay': currentMonth.fromDisplay, 'toDate': currentMonth.to, 'toDisplay': currentMonth.toDisplay, 'selected': false, 'show': true, key: 'this-month' },
            { 'text': this.translate.instant("This.Quarter"), 'fromDate': currentQuarter.from, 'fromDisplay': currentQuarter.fromDisplay, 'toDate': currentQuarter.to, 'toDisplay': currentQuarter.toDisplay, 'selected': false, 'show': true, key: 'this-quarter' },
            { 'text': this.translate.instant("This.Year"), 'fromDate': currentYear.from, 'fromDisplay': currentYear.fromDisplay, 'toDate': currentYear.to, 'toDisplay': currentYear.toDisplay, 'selected': false, 'show': true, key: 'this-year' },
            { 'text': this.translate.instant("Last.Week"), 'fromDate': lastWeek.from, 'fromDisplay': lastWeek.fromDisplay, 'toDate': lastWeek.to, 'toDisplay': lastWeek.toDisplay, 'selected': false, 'show': true, key: 'last-week' },
            { 'text': this.translate.instant("Last.Month"), 'fromDate': lastMonth.from, 'fromDisplay': lastMonth.fromDisplay, 'toDate': lastMonth.to, 'toDisplay': lastMonth.toDisplay, 'selected': false, 'show': true, key: 'last-month' },
            { 'text': this.translate.instant("Last.Quarter"), 'fromDate': lastQuarter.from, 'fromDisplay': lastQuarter.fromDisplay, 'toDate': lastQuarter.to, 'toDisplay': lastQuarter.toDisplay, 'selected': false, 'show': true, key: 'last-quarter' },
            { 'text': this.translate.instant("Last.Year"), 'fromDate': lastyear.from, 'fromDisplay': lastyear.fromDisplay, 'toDate': lastyear.to, 'toDisplay': lastyear.toDisplay, 'selected': false, 'show': true, key: 'last-year' },
            { 'text': this.translate.instant("Date.Range"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'date-range' },
            { 'text': this.translate.instant("Before"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'before' },
            { 'text': this.translate.instant("After"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'after' },
        ];
    };
    DatefilterComponent.prototype.onDateRangeChanges = function (type, event, index) {
        var intlSettings = this.settings._csIntlSettings;
        if (event.length && type == 'range') {
            var fromDate = new Date(event[0]);
            this.filterParams.fromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
            fromDate = fromDate.getDate() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getFullYear();
            var toDate = new Date(event[1]);
            this.filterParams.toDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
            toDate = toDate.getDate() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getFullYear();
            this.dateRangeFilter = fromDate + ' - ' + toDate;
            this.displayFilter = moment(fromDate).format(this.formats[intlSettings['short_date']]) + ' - ' + moment(toDate).format(this.formats[intlSettings['short_date']]);
        }
        else if (type == 'date' && event) {
            var selectedDate = new Date(event);
            var beforeDate = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" + selectedDate.getDate();
            this.filterParams.fromDate = this.filterParams.toDate = beforeDate;
            beforeDate = selectedDate.getDate() + "-" + selectedDate.getMonth() + "-" + selectedDate.getFullYear();
            this.dateRangeFilter = beforeDate;
            this.displayFilter = moment(selectedDate).format(this.formats[intlSettings['short_date']]);
        }
        else {
            this.dateRangeFilter = this.filterDate[index].text;
        }
        this.filterParams.name = this.filterDate[index].key;
        this.filterDate[index].selected = true;
        this.setSelectedRange(index);
        this.dropdown.hide();
        this.onChange.emit(this.filterParams);
    };
    DatefilterComponent.prototype.hideAllPickers = function () {
        if (this.daterangepicker && this.daterangepicker.isOpen) {
            this.daterangepicker.hide();
        }
        if (this.datepicker && this.datepicker.isOpen) {
            this.datepicker.hide();
        }
        if (this.datepickerAfter && this.datepickerAfter.isOpen) {
            this.datepickerAfter.hide();
        }
    };
    DatefilterComponent.prototype.getFilterByDate = function (date, index) {
        this.hideAllPickers();
        this.dateRangeFilter = date.text;
        this.filterParams.fromDate = date.fromDate;
        this.filterParams.toDate = date.toDate;
        this.setSelectedRange(index);
        this.displayFilter = date.key == 'today' || date.key == 'tomorrow' ? date.display : date.fromDisplay + ' - ' + date.toDisplay;
        this.filterParams.name = date.key;
        if (date.key == 'all') {
            this.displayFilter = '';
        }
        // $('.dropdown').removeClass('open');
        this.autoClose = true;
        $('.filter-user-width').removeClass('open');
        this.onChange.emit(this.filterParams);
    };
    DatefilterComponent.prototype.setSelectedRange = function (selectedIndex) {
        var _this = this;
        this.filterDate.forEach(function (val, key) {
            _this.filterDate[key].selected = false;
        });
        this.filterDate[selectedIndex].selected = true;
    };
    DatefilterComponent.prototype.clearRange = function () {
        var left = $('.date-filter').position().left - $('.aside-inner').width() - 20.5;
        $(".dropdown-menu").css("left", left);
        this.autoClose = true;
        this.rangeSearch = '';
        this.searchRange();
    };
    DatefilterComponent.prototype.searchRange = function () {
        var _this = this;
        this.autoClose = false;
        if (this.rangeSearch) {
            this.filterDate.forEach(function (val, key) {
                _this.filterDate[key]['show'] = false;
            });
            var filter_1 = this.filterDate.filter(function (i) { return i.text.toLowerCase().indexOf(_this.rangeSearch.toLowerCase()) != -1; });
            if (filter_1.length) {
                filter_1.forEach(function (val, key) {
                    filter_1[key]['show'] = true;
                });
            }
        }
        else {
            this.rangeSearch = '';
            this.filterDate.forEach(function (val, key) {
                _this.filterDate[key]['show'] = true;
            });
        }
    };
    DatefilterComponent.prototype.setDatePickerHeight = function (pickerType) {
        this.autoClose = false;
        if (pickerType == 'range') {
            var position = $('.range-picker').offset();
            var left_1 = (position.left + $('.range-picker').width()) + 'px';
            var top_1 = position.top + 'px';
            setTimeout(function () {
                $('bs-daterangepicker-container').css('top', "" + left_1);
            }, 200);
        }
        if (pickerType == 'before') {
            var position = $('.range-picker').offset();
            var left_2 = (position.left + $('.range-picker').width()) + 'px';
            var top_2 = position.top + 'px';
            setTimeout(function () {
                $('bs-datepicker-container').css('top', "" + left_2);
            }, 200);
        }
        if (pickerType == 'after') {
            var position = $('.range-picker').offset();
            var left_3 = (position.left + $('.range-picker').width()) + 'px';
            var top_3 = position.top + 'px';
            setTimeout(function () {
                $('bs-datepicker-container').css('top', "" + left_3);
            }, 200);
        }
    };
    DatefilterComponent.prototype.selectedObject = function (objectKey, value) {
        var _this = this;
        this.selectedFilterObject = value;
        this.filterParams.status = value;
        this.filterObjects.forEach(function (val, key) {
            _this.filterObjects[key].selected = false;
            if (val.key == objectKey) {
                _this.filterObjects[key].selected = true;
            }
        });
        $('.filter-object').removeClass('open');
        this.onChange.emit(this.filterParams);
    };
    DatefilterComponent.prototype.statusFilter = function () {
        var left = $('.statusFilter').position().left - $('.aside-inner').width() - 20.5;
        $(".dropdown-menu").css("left", left);
        $('.filter-user-width').removeClass('open');
    };
    return DatefilterComponent;
}());
export { DatefilterComponent };
