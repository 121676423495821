import {Component, OnInit, EventEmitter, ViewContainerRef, Input, Output} from '@angular/core';

declare var $;
export interface ButtonInterface {
    title?: string
    permissions: any[]
    visible: Function
    callBack: Function
}
@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styles: [],
  outputs:['toggleEvent']
})
export class ActionButtonsComponent implements OnInit {
    @Input() type: any;
    @Input() data: object={};
    @Input() index: number = -1;
    @Input() buttons: ButtonInterface[] = [];
    @Output() toggleEvent: EventEmitter<any> = new EventEmitter();
    @Output() onClickButtonEvent: EventEmitter<any> = new EventEmitter<any>();

    active:number = -1;

    constructor(public viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
      this.buttons.forEach( (btn:ButtonInterface) => {
          if(!btn.visible) {
              btn.visible = () => {
                  return true;
              };
          }
          if(!btn.permissions) {
              btn.permissions = [];
          }
      })
  }

  checkVisible(data:any, button:any) {
    if(typeof button.visible ==='function') {
        return button.visible(data, this.index);
    }
    else {
        return button.visible;
    }
  }

  toggleClick(event) {
    this.active = this.index;
    if($(event.target).closest('td').hasClass('active')) {
        this.active = -1;
    }
    //console.log($(event.target).closest('td'));
    this.toggleEvent.emit({
        index: this.active,
        data: this.data
    });
  }

  onClickButton(data:any, buttonProp:any) {
    this.onClickButtonEvent.emit({
        data: data,
        buttonProp: buttonProp,
        index: this.index
    });
  }

  checkVisibility(data:any, buttonProp:any) {
    //console.log('checkVisible', data, buttonProp)
  }
}
