import {forwardRef, Inject, Injectable} from '@angular/core';
import { environment} from "../../../environments/environment";
import {SettingsService} from "@app/core";
import {CookieService} from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {MicroserviceHelperService} from "@app/shared/microservice/helper/microservice-helper.service";

const SECURE_HASH_NAME = 'x_security_hash';
const V5_REFRESHTOKEN  = 'xv5_refresh_token';

@Injectable({
  providedIn: 'root'
})
export class V5AuthService {

  private readonly v5ApiBase: string = '';
  private apiBase: string = environment.apiHost;
  private username: string = '';
  private debug: boolean = false;
  private urlPaths = {
      authentication: 'json_login',
      renewalToken: 'token/renewal',
  };
  public isRequestedToken: boolean = false;
  constructor(
      @Inject(forwardRef(() => SettingsService)) private settings,
      private cookieService: CookieService,
      private http: HttpClient,
      private tokenService: MicroserviceHelperService,
  ) {
      this.v5ApiBase = this.apiBase + '/v5/core/';
  }

  getApiPath(pathName:string) {
      let apiUrlPath = '';
      if(this.urlPaths.hasOwnProperty(pathName)) {
          apiUrlPath = this.urlPaths[pathName];
      }
      if(this.debug) {
          apiUrlPath = '?DEBUG_SESSION_START=PHPSTORM';
      }
      return this.v5ApiBase + apiUrlPath;
  }

  authenticate(username:string = '') {
      const authTokenHash = this.cookieService.get(SECURE_HASH_NAME);
      if(!authTokenHash || this.tokenService.isTokenExist()) {
          return false;
      }
      const apiPath = this.getApiPath('authentication');
      this.doLoginReq(apiPath, {
          username: username,
          appToken: authTokenHash
      }).subscribe( {
          next: resp => {
              if(resp['v5token']) {
                  this.tokenService.setToken(resp['v5token'])
              }
          }
      })
  }

  doLoginReq(path:string = '', data={}): Observable<any> {
      return this.http.post(path, data)
  }

  renewToken() {
      const apiPath = this.getApiPath('renewalToken');
      this.isRequestedToken=true;
      return this.http.post(apiPath, {}).toPromise();
          /*.then( (resp) => {
              if(resp['renewalToken']) {
                  this.tokenService.setToken(resp['renewalToken'])
                  this.tokenService.setTokenInterval(resp['nextRenewalTime'])
              }
          }, (err) => {

          });*/
      /*.subscribe({
          next: resp => {
              if(resp['renewalToken']) {
                  this.tokenService.setToken(resp['renewalToken'])
                  this.tokenService.setTokenInterval(resp['nextRenewalTime'])
              }
          }
      })*/
  }
}
