import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap  } from 'rxjs/operators';

// --
import { InterfaceService } from '@app/interfaces';

@Injectable({
    providedIn: 'root'
})
export class NotificationTemplateService extends InterfaceService {

    constructor(
        private http: HttpClient
    ) { super(); }

    // Get groups configured
    getNotifications(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`notifications`, params));
    }

    // complete selected notification
    completeSelectedAllNotifications( data: any): Observable<any> {
        return this.http.post(this.getApiUrl(`completeSelectedAllNotification`), data);
    }

    completeSelectedNotifications( data: any): Observable<any> {
        return this.http.post(this.getApiUrl(`completeNotification`), {
            'notificationId': data});
    }

    modalNotificationDetails(notificationId: number): Observable<any> {
        return this.http.post(this.getApiUrl('modalNotificationDetails'), {
            'notificationId': notificationId
        });
    }

    auditNotificationView(notificationId: number): Observable<any> {
        return this.http.post(this.getApiUrl('auditNotificationView'), {
            'notificationId': notificationId
        });
    }

    auditNotificationViewHistory(notificationId: number,status: any): Observable<any> {
        return this.http.post(this.getApiUrl('auditNotificationView'), {
            'notificationId': notificationId, 'status': status
        });
    }

}
