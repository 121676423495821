<div class="cs-list-holder slim-scroll thin cs-v5" #gridHolder>
    <div class="cs-list-wrapper cs-v5-style" [ngClass]="{'h-100': gridAutoHeightParams.gridLayout === 'normal'}"
         #gridWrapper>
        <div class="cs-list-header">
            <div class="cs-list-header__title" translate>{{csListViewParams?.titleText}}</div>
            <div class="cs-list-header__action" *ngIf="isHeaderAction">
                <ng-content select="[headerAction]"></ng-content>
            </div>
        </div>
        <div class="cs-list" #grid>
            <ng-content select="[grid]"></ng-content>
        </div>
        <div class="cs-list-footer">
            <div class="cs-list-footer__pagination" *ngIf="totalRecordCount">
                <div class="cs-pagination-panel">
                    <app-cs-pagination-panel
                        [totalRecord]="totalRecordCount"
                        [maxPagesDisplayLength]="maxPagesDisplayLength"
                        [initialParams]="csListViewParams"
                        (onUpdatePagingParams)="updatePagingParams($event)">
                    </app-cs-pagination-panel>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isDisplayPanel" class="display-panel-wrapper cs-v5-style" [appCsScrollbar]="">
        <ng-content select="[displayPanel]"></ng-content>
    </div>
</div>
