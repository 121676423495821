import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ContractDataService = /** @class */ (function () {
    function ContractDataService() {
        this.form = new BehaviorSubject('');
        this.currentForm = this.form.asObservable();
    }
    ContractDataService.prototype.changeForm = function (form) {
        this.form.next(form);
    };
    ContractDataService.ngInjectableDef = i0.defineInjectable({ factory: function ContractDataService_Factory() { return new ContractDataService(); }, token: ContractDataService, providedIn: "root" });
    return ContractDataService;
}());
export { ContractDataService };
