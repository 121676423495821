import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CsActivityLogComponent} from "@app/shared/cs-activity-log/cs-activity-log.component";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";
import {CommunicationModalModule} from "@app/shared/communication-modals/communication-modal.module";
import {TimelineModule} from "@app/shared/timeline/timeline.module";

@NgModule({
    declarations: [CsActivityLogComponent],
    imports: [
        CommonModule,
        CsComponentsModule,
        TimelineModule,
        CommunicationModalModule
    ],
    exports: [
        CsActivityLogComponent
    ]
})
export class CsActivityLogModule { }
