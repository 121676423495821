import { OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { Subject } from "rxjs";
import { FormBuilder, Validators } from "@angular/forms";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var SequenceAddContactComponent = /** @class */ (function () {
    function SequenceAddContactComponent(modalRef, formBuilder, workflowHttpService) {
        this.modalRef = modalRef;
        this.formBuilder = formBuilder;
        this.workflowHttpService = workflowHttpService;
        this.saving = false;
    }
    SequenceAddContactComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        var formControlConfig = {};
        if (this.type == 'email') {
            formControlConfig['emailaddress'] = ['', [Validators.required, Validators.email]];
        }
        if (this.type == 'mobile') {
            formControlConfig['mobile'] = ['', [Validators.required]];
            formControlConfig['countrycode'] = ['', Validators.required];
        }
        this.form = this.formBuilder.group(formControlConfig);
    };
    Object.defineProperty(SequenceAddContactComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    SequenceAddContactComponent.prototype.onChangeCountryCode = function (code) {
        this.form.controls['countrycode'].setValue(code);
    };
    SequenceAddContactComponent.prototype.addContactEmail = function (contact, id) {
        var _this = this;
        this.workflowHttpService.postAddContactEmail({ contact: contact }, id)
            .subscribe({
            next: function (resp) {
                console.log(resp);
                if (resp['status'] === 'success') {
                    _this.onClose.next(contact);
                    _this.modalRef.hide();
                }
                else {
                    _this.modalRef.hide();
                }
            }
        });
    };
    SequenceAddContactComponent.prototype.addContactMobile = function (contact, id) {
        var _this = this;
        this.workflowHttpService.postAddContactMobile({ contact: contact }, id)
            .subscribe({
            next: function (resp) {
                console.log(resp);
                if (resp['status'] === 'success') {
                    _this.onClose.next(resp['data']);
                    _this.modalRef.hide();
                }
                else {
                    _this.modalRef.hide();
                }
            }
        });
    };
    SequenceAddContactComponent.prototype.save = function () {
        var contact = this.form.value;
        this.saving = true;
        contact['type'] = this.type;
        contact['contactId'] = this.contactId;
        if (this.type == 'email') {
            this.addContactEmail(contact, this.contactId);
        }
        else {
            this.addContactMobile(contact, this.contactId);
        }
    };
    return SequenceAddContactComponent;
}());
export { SequenceAddContactComponent };
