<ng-container *ngIf="open">
    <!-- Title -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>

    </div>
    <!-- Image section -->
    <div class="modal-body image-block-height page-propoerties-background-image-headerFooter">
        <ng-container *ngIf="option == 'pageBackground'">
            <div class="help_section">
                <div class="help-text" translate>
                    page.properties.color.image.upload.help.text
                </div>
            </div>
            <app-block-settings-modal [pageId]="id"></app-block-settings-modal>
            <div class="flex-display" id="scrolltoTop">
                <div class="page-properties-section-width page-bg-padding-left" [ngClass]="[pageType == 'EDITOR_CONTENT_PAGE' || pageType == 'EDITOR_BLANK_PAGE' ? 'page-bg-padding-top-0' : '']">
                    <div class="page-properties-main-section" >

                        <div class="select-backgrond-text">
                            <span translate>select.background</span>
                        </div>

                        <div class="accordion">
                            <div class="accordion-item" >
                                <div class="sec accordion-header pagecolor-image" >
                                    <input type="radio" (click)="backgroundOption('color')" class="background-radio bakcground-color-radio-check"
                                           [(ngModel)]="pageBackground" name="page-background" id="bg-color" value="color" checked />
                                    <label for="bg-color" class="control-label" [ngClass]="[pageBackground == 'color' && bgColorChecked ? 'arrow-animation-color' : 'arrow-disable-animation-color']">
                                        <span class="control-label-text" translate>background.color</span>
                                        <span class="accordion-arrow"><i class="fa fa-angle-down"></i></span>
                                    </label>
                                   <!-- <label class="accordion-header" >
                                        <span class="selector-indicator"> </span>
                                        <span> Background color</span>
                                        <span class="accordion-arrow"><i class="fa fa-angle-down"></i></span>
                                    </label>-->
                                </div>

                                <div class="accordion-content" [ngClass]="[pageBackground == 'color' && bgColorChecked  ? 'enableColorSection' : 'hideColorSection']">
                                    <div class="page-bg-section page-bg-section-padding" >
                                        <div class="cs-color-palatte dropdown" >
                                            <span class="color-box" data-toggle="dropdown" [style.background]="pageBackgroundColor"></span>
                                            <input class="picker-color" readonly autocomplete="off" container="body" data-toggle="dropdown" (click)="detectChanges(pageBackgroundColor)" [(ngModel)]="pageBackgroundColor">
                                            <div class="dropdown-menu dropdown-height" >
                                                <color-palatte  [data]="myValue" (colorValue)="updateColor($event,'pageBackgroundColor')"></color-palatte>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item" >

                                <div class="sec accordion-header pagecolor-image">
                                    <input type="radio" (click)="backgroundOption('image')" class="background-radio bakcground-color-radio-check"
                                           [(ngModel)]="pageBackground" name="page-background" id="bg-image" value="image" />
                                    <label for="bg-image" class="control-label" [ngClass]="[pageBackground == 'image' && bgImageChecked ? 'arrow-animation-image' : 'arrow-disable-animation-image']">
                                        <span class="control-label-text" translate>background.Image</span>
                                        <span class="accordion-arrow"><i class="fa fa-angle-down"></i></span>
                                    </label>
                                    <!--<label class="accordion-header" >
                                        <span class="selector-indicator"> </span>
                                        <span> Background image</span>
                                        <span class="accordion-arrow"><i class="fa fa-angle-down"></i></span>
                                    </label>-->
                                </div>

                                <div class="accordion-content page-propertiers-image-section" id="drop-zone"  (onFileDrop)="pagebackgroundImageUpload($event)" ng2FileDrop [uploader]="uploader" [ngClass]="[pageBackground == 'image' && bgImageChecked  ? 'enableImageSection' : 'hideImageSection']">

                                    <!--<div class="drag-zone-animation-section display-none">
                                        <div class="animation">
                                             <div class="upload-icon-list-section-fa-icon">
                                                 <div class="fa-icon-list">
                                                     <div class="image-fa-display-align">
                                                         <span class="fa-fa-icon-image-section1 slide-fwd-center"><i class="fa fa-image"></i></span>
                                                         <span class="fa-fa-icon-image-section2 slide-fwd-center"><i class="fa fa-image"></i></span>
                                                         <span class="fa-fa-icon-image-section3 slide-fwd-center"><i class="fa fa-image"></i></span>
                                                     </div>
                                                 </div>
                                                 <div class="upload-icon-section">
                                                     <i class="fas fa-cloud-upload-alt font-size-24"></i>
                                                 </div>
                                             </div>

                                            <span>drag and drop files on this page</span>
                                        </div>
                                    </div>-->


                                    <div class="page-background-section" [ngClass]="[!backgroundImageUrl ? 'ano' : 'page-background-image-already-selected']">
                                        <div class="page-properties-background-image-section">

                                            <ng-container *ngIf="backgroundImgfileSelected || fileErrorMsg || backgroundImageUrl || shimmerLoadingEnabled">
                                                <div class="pp-loading-crop-error-section" >
                                                    <ng-container *ngIf="backgroundImgfileSelected">

                                                        <div  class="pp-bakground-img pp-background-upload-section">
                                                            <div class="pp-upload-section-center">
                                                                <div class="circle-wrap">
                                                                    <div class="circle">
                                                                        <div class="mask full" id="pploadinganimation1">
                                                                            <div class="fill"></div>
                                                                        </div>
                                                                        <div class="mask half">
                                                                            <div class="fill" id="pploadinganimation2"></div>
                                                                        </div>
                                                                        <div class="inside-circle"> </div>
                                                                    </div>
                                                                </div>
                                                                <div class="pp-background-image" *ngIf="correctSizeAndFormat.length">
                                                                    <span>{{correctSizeAndFormat[0].name}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="shimmerLoadingEnabled">
                                                        <div class="loading-shimmer-section">
                                                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                                                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                                                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                                                        </div>
                                                    </ng-container>
                                                    <div class="pp-selection-image-showing-section" *ngIf="backgroundImageUrl">
                                                        <div class="page-properties-image-remove-section pagebackground-image-section">
                                                            <div class="remove-page-properties-image">
                                                                <div class="click-btn-set-image" (click)="removeAllImages('backgroundImage')">
                                                                    <span translate>remove.background.image</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <image-cropper class="imgae-loaded five-img-section" width="50%" [maintainAspectRatio]="false" [aspectRatio]="4 / 3"
                                                                       [imageQuality]="cropQuality" [imageURL]="backgroundImageUrl"  (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event,'pageBackgroundImage')">
                                                        </image-cropper>
                                                    </div>
                                                    <div class="pp-error-message-section" *ngIf="showBackgroundImageError">
                                                        <div class="pp-error-msg">
                                                            <span translate>upload.error.text</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div *ngIf="!backgroundImageUrl && !backgroundImgfileSelected" class="pp-bakground-img pp-background-upload-section hidezoneEnable" >
                                                <div class="pp-upload-section-center before-upload-mt-6 upload-image-section-opacity-section">
                                                    <img class="pp-noImage-size" src="images/noImage.png">
                                                    <div class="pp-background-image" >
                                                        <span class="drag-and-drop-upload-text" translate>drag.drop.image.upload</span>
                                                        <p class="upload-selection-text-color" (click)="fileInput.click()">Browse file</p>
                                                        <input  accept=".jpg,.png,.jpeg,.gif,.bmp,.svg" #fileInput type="file" (change)="pagebackgroundImageUpload($event)" style="display:none;"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="layout-section hidezoneEnable">
                                                <div class="pp-layout-text">
                                                    <span class="required_field" translate>layout</span>
                                                </div>
                                                <div class="layout-selection-section">
                                                    <select class="page-propertiers-layout-selection" [(ngModel)]="pagePropertiesLayoutStyle" (click)="pagePropertiesBgImageLayouts($event.target.value)">
                                                        <option [selected]="pagePropertiesLayoutStyle == 'dont_repeat'" value="dont_repeat">Don’t repeat</option>
                                                        <!--                                                    <option value="repeat">repeat</option>-->
                                                        <option [selected]="pagePropertiesLayoutStyle == 'repeat_vertically'" value="repeat_vertically">Repeat vertically</option>
                                                        <option [selected]="pagePropertiesLayoutStyle == 'repeat_vertically_horizontally'" value="repeat_vertically_horizontally">Repeat vertically and horizontally</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="backgroundImageList && backgroundImageList.length">
                                                <div class="content-image-block content-image-block-selected activity-files image-all-background-section" [ngClass]="{'image-section-disable-cursor' : pageBackground !== 'image' && !bgImageChecked }">
                                                    <div class="image-section-para" >
                                                        <span translate>background.images.pages</span>
                                                    </div>
                                                    <div class="grid-container" [ngClass]="{'image-section-disable-pointer' : pageBackground !== 'image' && !bgImageChecked }">
                                                        <ng-container *ngFor="let img of backgroundImageList">
                                                            <img src={{img}} class="image-block activityFiles" [ngClass]="{'selected-image': img == backgroundImageUrl}" (click)="selectImage($event)" />
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>

                                    <div class="upload-popover display-none" [style.top.px]="-dynamicHeight" [ngClass]="[backgroundImageList && backgroundImageList.length ? 'background-images-top-added' : 'no-images-top-added']" *ngIf="!backgroundImageUrl">
                                        <div class="image-upload text-center align-items-center" [style.height.px]="dynamicHeight" [ngClass]="[backgroundImageList && backgroundImageList.length ? 'background-images-height-added' : 'no-images-height-added']">
                                            <div class="image-drag-zone-section">
                                                <img class="pp-noImage-size1" src="images/noImage.png">

                                            </div>
                                            <p class="drag-drop-text">Drop your files here</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="accordion-item">
                                <input type="checkbox" [(ngModel)]="bgHedaerChecked"  id="section3"/>
                                <label class="accordion-header" for="header-footer"  >
                                    <span class="selector-indicator"> <input type="checkbox" class="header-footer-checkbox"  name="headerfooter" id="header-footer" value="headerFooter" (change)="toggleHeaderFooter()" /></span>
                                    <span translate>header.footer</span>
                                    <span class="accordion-arrow"><i class="fa fa-angle-down"></i></span>
                                </label>

                                <div class="accordion-content page-propertiers-image-section" [ngClass]="[bgHedaerChecked ? 'enabelHeaderFooterSection' : 'hideHeaderFooterSection']">
                                    <div class="header-and-footer-section">
                                        <div class="pp-header-section" id="pageHeaderSection" (onFileDrop)="pageHeaderFooterImageUpload($event,'pageHeader')" ng2FileDrop [uploader]="uploader">
                                            <div class="page-header-drag-zone-section" [ngClass]="[!pageHeaderImageUrl ? 'ano' : 'page-header-image-already-selected']">
                                                <div class="pp-layout-text margin-left-header">
                                                    <span class="header-text" translate>header</span>
                                                </div>
                                                <ng-container *ngIf="headerFileSelected || fileErrorMsg || pageHeaderImageUrl">
                                                    <div class="pp-loading-crop-error-section" >
                                                        <ng-container *ngIf="headerFileSelected">
                                                            <div  class="pp-bakground-img pp-background-upload-section">
                                                                <div class="pp-upload-section-center">
                                                                    <div class="circle-wrap">
                                                                        <div class="circle">
                                                                            <div class="mask full" id="pploadinganimation3">
                                                                                <div class="fill"></div>
                                                                            </div>
                                                                            <div class="mask half">
                                                                                <div class="fill" id="pploadinganimation4"></div>
                                                                            </div>
                                                                            <div class="inside-circle"> </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="pp-background-image" *ngIf="correctSizeAndFormat.length">
                                                                        <span>{{correctSizeAndFormat[0].name}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="pp-selection-image-showing-section" *ngIf="pageHeaderImageUrl">
                                                            <div class="page-properties-image-remove-section">
                                                                <div class="remove-page-properties-image">
                                                                    <div class="click-btn-set-image">
                                                                        <label for="bbb" class="common-color" translate>height</label>
                                                                        <input type="number" [value]="headerCropHeight" (input)="onInputChange($event,'pageHeader')" style="width: 100px;height: 30px" class="form-controls" id="bbb" min="50" max="200"/>
                                                                    </div>
                                                                </div>
                                                                <div class="remove-page-properties-image">
                                                                    <div class="click-btn-set-image" (click)="removeAllImages('backgroundHeader')">
                                                                        <span translate [translateParams]="{source: 'header'}">header.or.footer.image.remove</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="headerError">
                                                                <div class="showing-error-min-max-height">
                                                                    <span class="min-max-height">{{headerError}}</span>
                                                                </div>
                                                            </ng-container>

                                                            <div class="row-header-cropper" *ngIf="pageHeaderImageUrl">
                                                                <image-cropper [imageQuality]="headerCropQuality" [imageURL]="pageHeaderImageUrl" [onlyScaleDown]="true" [resizeToWidth]="headerCropWidth"
                                                                               [hideResizeSquares]="true" [resizeToHeight]="headerCropHeight" [maintainAspectRatio]="headerKeepSpectRatio" [aspectRatio]="getspectRatio('pageHeader')"
                                                                               (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"  (imageCropped)="imageCropped($event,'pageHeader')">
                                                                </image-cropper>
                                                            </div>
                                                        </div>

                                                        <div class="pp-error-message-section" *ngIf="showBackgroundHeaderError">
                                                            <div class="pp-error-msg">
                                                                <span translate>upload.error.text</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="pp-bakground-img" *ngIf="!pageHeaderImageUrl && !headerFileSelected">
                                                    <div class="pp-upload-section-center before-upload-mt-6">
                                                        <img class="pp-noImage-size" src="images/noImage.png">
                                                        <div class="pp-background-image" >
                                                            <span class="drag-and-drop-upload-text" translate>drag.drop.image.upload</span>
                                                            <p class="upload-selection-text-color" (click)="headerImage.click()" translate>browse.file</p>
                                                            <input #headerImage type="file" (change)="pageHeaderFooterImageUpload($event,'pageHeader')"
                                                                   accept=".jpg,.png,.jpeg,.gif,.bmp,.svg" style="display:none;"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="upload-popover display-none" *ngIf="!pageHeaderImageUrl">
                                                <div class="image-upload text-center align-items-center">
                                                    <div class="image-drag-zone-section">
                                                        <img class="pp-noImage-size1" src="images/noImage.png">

                                                    </div>
                                                    <p class="drag-drop-text">Drop your files here</p>
                                                </div>
                                            </div>
                                           </div>


                                        <div class="pp-header-section" id="pageFooterSection" (onFileDrop)="pageHeaderFooterImageUpload($event,'pageFooter')" ng2FileDrop [uploader]="uploader">
                                            <div class="page-header-drag-zone-section" [ngClass]="[!pageFooterImageUrl ? 'ano' : 'page-footer-image-already-selected']">
                                                <div class="pp-layout-text margin-left-header">
                                                    <span class="footer-text" translate>Footer</span>
                                                </div>
                                                <ng-container *ngIf="footerFileSelected || fileErrorMsg || pageFooterImageUrl">
                                                    <div class="pp-loading-crop-error-section" >
                                                        <ng-container *ngIf="footerFileSelected">
                                                            <div  class="pp-bakground-img pp-background-upload-section">
                                                                <div class="pp-upload-section-center">
                                                                    <div class="circle-wrap">
                                                                        <div class="circle">
                                                                            <div class="mask full" id="pploadinganimation5">
                                                                                <div class="fill"></div>
                                                                            </div>
                                                                            <div class="mask half">
                                                                                <div class="fill" id="pploadinganimation6"></div>
                                                                            </div>
                                                                            <div class="inside-circle"> </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="pp-background-image" *ngIf="correctSizeAndFormat.length">
                                                                        <span>{{correctSizeAndFormat[0].name}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="pp-selection-image-showing-section" *ngIf="pageFooterImageUrl">
                                                            <div class="page-properties-image-remove-section">
                                                                <div class="remove-page-properties-image">
                                                                    <div class="click-btn-set-image">
                                                                        <label for="bbb" class="common-color" translate>height</label>
                                                                        <input type="number" [value]="footerCropHeight" (input)="onInputChange($event,'pageFooter')" style="width: 100px;height: 30px" class="form-controls" id="bbb1" min="50" max="200"/>
                                                                    </div>
                                                                </div>
                                                                <div class="remove-page-properties-image">
                                                                    <div class="click-btn-set-image" (click)="removeAllImages('backgroundFooter')">
                                                                        <span translate [translateParams]="{source: 'footer'}">header.or.footer.image.remove</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="footerError">
                                                                <div class="showing-error-min-max-height">
                                                                    <span class="min-max-height">{{footerError}}</span>
                                                                </div>
                                                            </ng-container>

                                                            <div class="row-footer-cropper" *ngIf="pageFooterImageUrl">
                                                                <image-cropper [imageQuality]="footerCropQuality" [imageURL]="pageFooterImageUrl" [onlyScaleDown]="true" [resizeToWidth]="footerCropWidth"
                                                                               [hideResizeSquares]="true" [resizeToHeight]="footerCropHeight" [maintainAspectRatio]="footerKeepSpectRatio" [aspectRatio]="getspectRatio('pageFooter')"
                                                                               (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event,'pageFooter')">
                                                                </image-cropper>
                                                            </div>
                                                        </div>

                                                        <div class="pp-error-message-section" *ngIf="showBackgroundFooterError">
                                                            <div class="pp-error-msg">
                                                                <span translate>upload.error.text</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="pp-bakground-img" *ngIf="!pageFooterImageUrl && !footerFileSelected">
                                                    <div class="pp-upload-section-center before-upload-mt-6">
                                                        <img class="pp-noImage-size" src="images/noImage.png">
                                                        <div class="pp-background-image" >
                                                            <span class="drag-and-drop-upload-text" translate>drag.drop.image.upload</span>
                                                            <p class="upload-selection-text-color" (click)="footerImage.click()" translate>browse.file</p>
                                                            <input #footerImage type="file" (change)="pageHeaderFooterImageUpload($event,'pageFooter')"
                                                                   accept=".jpg,.png,.jpeg,.gif,.bmp,.svg" style="display:none;"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="upload-popover display-none" *ngIf="!pageFooterImageUrl">
                                                <div class="image-upload text-center align-items-center">
                                                    <div class="image-drag-zone-section">
                                                        <img class="pp-noImage-size1" src="images/noImage.png">

                                                    </div>
                                                    <p class="drag-drop-text">Drop your files here</p>
                                                </div>
                                            </div>
                                        </div>




                                        <!--<div class="pp-header-section">
                                            <div class="pp-layout-text-number-section">
                                                <span>Page number</span>
                                            </div>
                                            <div class="auto-incremenet">
                                                <select [(ngModel)]="headerFooterNumberFormat" (click)="pagePropoertiesHeaderFooterNumber($event.target.value)">
                                                    <option>&#45;&#45;Select option&#45;&#45;</option>
                                                    <option>Left</option>
                                                    <option>center</option>
                                                    <option>Right</option>
                                                </select>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container>
            <div class="set-all-page-bakground-section">
                <div class="checkbox-and-content-section">
                    <input  [(ngModel)]="setAllPageBackground" class="proposal-backgroung-checkbox" type="checkbox">
                    <span class="set-all-page-text" translate>apply.all.pages</span>
                </div>
            </div>
        </ng-container>

    </div>
    <!-- Footer -->
    <div class="modal-footer modal-footer-z-index">

        <a href="javascript:void(0)" class="btn" (click)="cancel()">Cancel</a>
       <!-- <ng-container *ngIf="!bgHedaerChecked">
            <button class="btn btn-primary" [disabled]="sidepanelOpen" (click)="onSubmit()">
                <span translate>Save</span>
            </button>
        </ng-container>-->
        <ng-container>
            <button class="btn btn-primary" [disabled]="imageUploadProgress || headerError || footerError "  (click)="onSubmit()">
                <span translate>Save</span>
            </button>
        </ng-container>
    </div>
</ng-container>
