import * as i0 from "@angular/core";
import * as i1 from "../../core/settings/settings.service";
var CountriesService = /** @class */ (function () {
    function CountriesService(settings) {
        this.settings = settings;
        this.countries = this.settings.countriesJSONData;
    }
    CountriesService.prototype.setNumberTypes = function (numberTypes) {
        this.numberTypes = numberTypes;
    };
    CountriesService.prototype.getNumberTypes = function () {
        return this.numberTypes;
    };
    CountriesService.prototype.getCountries = function () {
        return this.countries;
    };
    CountriesService.prototype.getCountryById = function (id) {
        for (var i = 0, l = this.countries.length; i < l; i++) {
            if (this.countries[i].id === id) {
                return this.countries[i];
            }
        }
    };
    CountriesService.prototype.getCountryByCode = function (code) {
        if (this.countries[code]['data'].length > 0) {
            return this.countries[code]['data'];
        }
    };
    CountriesService.ngInjectableDef = i0.defineInjectable({ factory: function CountriesService_Factory() { return new CountriesService(i0.inject(i1.SettingsService)); }, token: CountriesService, providedIn: "root" });
    return CountriesService;
}());
export { CountriesService };
