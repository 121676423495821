import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

//--
import { InterfaceService } from '@app/interfaces';
import { ContractTemplate, Customer, CustomerContract } from '@app/models';

@Injectable({
  providedIn: 'root'
})

export class CcWhatsIncludedPPMService extends InterfaceService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /* Get properties list with ppm counts mapped into the contracts

    Response:
      Array<{
        relationshipId: number;
        ppmCount: number;
        id: number;
        title: string;
        name: string;
        surname: string;
        companyName: string;
        mainContact: boolean;
        parentPropertiesId: number;
        addressline1: string;
        addressline2: string;
        addressline3: string;
        town: string;
        county: string;
        postcode: string;
      }
   */
  getPropertiesList(contractId: number, params?: Object): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/whats-included/ppm/get-properties-list`, params));
  }

  /* Get list of ppm associated to the customer contract and the property
  
      Response : 
        Array<{
          id: number;
          name: string;
          description: string;
        }> 
  */
  getPropertyPPMList(contractId: number, propertyId, params?: Object): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/whats-included/${propertyId}/get-ppm-list`, params));
  }

  /* Get configured PPM schedules of the customer contract property

        Response :
            {
              contractDetails: Object;
              customerDetails: Object;
              propertyDetails: Object;
              ppmList: Array<Object>;
            }
   */
  getPPMSchedules(contractId: number, propertyId, params?: Object){
    return this.http.get(this.getApiUrl(`customer-contracts/${contractId}/whats-included/${propertyId}/get-ppm-schedules`))
      .pipe(
        map((Response: any) => {
          Response.contractDetails = new CustomerContract(Response.contractDetails);
          Response.customerDetails = new Customer(Response.customerDetails);
          Response.propertyDetails = new Customer(Response.propertyDetails);
          return Response;
        })
      );
  }

  /* Enable already deleted ppms

      Response:
        Details of the enabled PPMs
   */
  enablePPMs(ppmIds: number[]): Observable<any> {
    return this.http.put(this.getApiUrl(`customer-contracts/whats-included/ppm/enable-ppms`), JSON.stringify({enabledPPMIds: ppmIds}));
  }

  /* Validates the ppm task schedules in order to minimise the jobs count

       Response:
          PPM schedules list with suggested week names
   */
  validatePPMConfigurations(contractId: number, configData: Object[]): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/whats-included/ppm/validate-ppm-schedules`), JSON.stringify(configData));
  }

  /* Saves the ppm task schedules

      Response:
        Full details of the saved PPM configurations
   */
  savePPMConfigurations(contractId: number, propertyId: number, data: Object[]): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/${contractId}/whats-included/${propertyId}/ppm/save-ppm-configurations`), JSON.stringify(data));
  }

  /* Deletes the ppm configuration
     
     Response :
       'success'
   */
  deletePPMConfiguration(ppmId: number): Observable<any> {
    return this.http.delete(this.getApiUrl(`customer-contracts/whats-included/ppm/${ppmId}/delete`));
  }

  /* Get list of assets associated to the PPM

   Response :
     Array<{
       id: number;
       text: string;
     }>
 */
  getPPMAssets(ppmId: number): Observable<any> {
    return this.http.get(this.getApiUrl(`customer-contracts/whats-included/ppm/${ppmId}/get-assets`));
  }

  /* Saving the assets selections for the PPM property

    Response :
      Array<{
        id: number;
        text: string;
      }>
  */
  savePPMAssets(ppmId: number, assetIds: number[]): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/whats-included/ppm/${ppmId}/save-assets`),
      JSON.stringify({
        assetIds: assetIds
      }));
  }

  /* Deleting PPM task

    Response :
      string
  */
  deletePPMTask(taskId: number): Observable<any>{
    return this.http.delete(this.getApiUrl(`customer-contracts/whats-included/ppm-task/${taskId}/delete`));
  }


  /* Add new PPM task

    Response :
      {
        id: number;
        shortDescription: string;
        longDescription: string;
        repeatType: string;
        schedules: Object[];
      }
  */
  addPPMTask(ppmId: number, data: Object): Observable<any> {
    return this.http.post(this.getApiUrl(`customer-contracts/whats-included/ppm/${ppmId}/add-new-task`), JSON.stringify(data));
  }


    // Get list of job descriptions configured
    listCcJobDescriptions(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`list_ctJobdescriptions`, params));
    }

    getCcJobdescriptions(templateId: number):Observable<any>{
        return this.http.get(this.getApiUrl(`customer_contract/${templateId}/whats-included/ppm/get-job-description`));
    }

    saveCcJobDescriptions(templateId: number, data: Object): Observable<any> {
        return this.http.put(this.getApiUrl(`customer_contract/${templateId}/whats-included/ppm/job-description`), JSON.stringify(data));
    }

    checkCustomerContractStatus(templateId: number):Observable<any>{
        return this.http.get(this.getApiUrl(`customer_contract/${templateId}/get-template-details`));
    }

}
