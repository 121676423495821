import { PipeTransform } from '@angular/core';
var CapitalizePipe = /** @class */ (function () {
    function CapitalizePipe() {
    }
    CapitalizePipe.prototype.transform = function (word) {
        if (!word || typeof (word) === 'number')
            return word;
        return word.substr(0, 1).toUpperCase() + word.substring(1).toLowerCase();
    };
    return CapitalizePipe;
}());
export { CapitalizePipe };
