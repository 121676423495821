import {Component, Input, Output, OnInit, AfterViewInit, NgZone, EventEmitter } from '@angular/core';

declare var tinymce: any;
@Component({
  selector: 'app-tinymce',
  templateUrl: './tinymce.component.html',
  styles: []
})
export class TinymceComponent implements OnInit, AfterViewInit {
  @Input('type') type: string;
  @Input('data') data: object;
  @Input() selector: string = '';
  @Input() customClass: object = {'subject' : '', 'message' : ''};
  @Input() toolbar: any = 'fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright';
  editor: any;
  @Output() outputdata = new EventEmitter<object>();

  constructor(private zone: NgZone) { }

  ngOnInit() {
      if (this.type.toLowerCase() === 'sms'){
          this.toolbar = false;
      }
      if(this.selector == 'supplier-email-template') {
          this.toolbar = 'undo redo | bold italic | alignleft aligncenter alignright'
      }
  }

    ngAfterViewInit() {
        tinymce.init(
            {
                selector: '#' + this.selector + '-subject',
                menubar: false,
                toolbar: false,
                statusbar: false,
                plugins: 'paste',
                browser_spellcheck: true,
                paste_as_text: true,
                paste_block_drop: false,
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                setup: editor => {
                    editor.on('LoadContent', () => {
                        if (this.data['subject']) {
                            editor.setContent(this.data['subject']);
                        } else {
                            editor.setContent("");
                        }
                    });
                    editor.on('change', () => {
                        const content = editor.getContent();
                        this.zone.run(() => {
                            this.data['subject'] = content;
                        });
                        this.updateChange();
                    });
                }
            });
        tinymce.init(
            {
                mode : 'textareas',
                selector: '#'+this.selector,
                menubar: false,
                toolbar: this.toolbar,
                plugins: 'paste,textcolor',
                content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
                paste_as_text: true,
                browser_spellcheck: true,
                paste_block_drop: false,
                fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                setup: editor => {
                    editor.on('LoadContent', () => {
                        if (this.data['message']) {
                            editor.setContent(this.data['message']);
                        } else {
                            editor.setContent("");
                        }
                    });
                    // keyup is not used due to change can be even done from tags
                    editor.on('change', () => {
                        const content = editor.getContent();
                        this.zone.run(() => {
                            this.data['message'] = content;
                        });
                        this.updateChange();
                    });
                }
            });
    }

    updateChange(){
        this.outputdata.emit(this.data);
    }
}
