import {Component, Input, OnInit} from '@angular/core';

declare var $;

@Component({
    selector: 'app-condition-edge',
    templateUrl: './condition-edge.component.html',
})
export class ConditionEdgeComponent implements OnInit {

    @Input() activeField:any = {};
    @Input() inputModel:any;
    @Input() operators: any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() parentBlockName:string;
    @Input() isReadOnlyMode:boolean = false;

    dataFunction = function(item){ return item.text; };

    constructor() { }

    ngOnInit() {
        if(this.parentBlockName && this.parentBlockName==='untilConditionBlock') {

        }
    }

    onSelectMultipleSelect(value:any) {
        this.inputModel.condition.value = value;
    }

    onSelectOperator(value:any) {
        this.inputModel.condition.value = '';
        if($("#conditional_multiple_select").length) {
            $("#conditional_multiple_select").select2('destroy');
        }
    }
}

