import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class EventDispatcherService {

    private triggerEvent = new Subject();
    triggerEvent$ = this.triggerEvent.asObservable();

    static CONSTANTS: Object = {
        'init': 'event:init',
        'onChangedTemplate': 'event:onChangedTemplate',
        'onChangedMenuItem': 'event:onChangedMenuItem',
        'isValidForm': 'event:isValidForm',
        'currentActiveInput': 'event:currentActiveInput',
        'dispatchUpdatedForm': 'event:dispatchUpdatedForm',
        'dispatchReservedData': 'event:dispatchReservedData',
        'unSavedChanges': 'event:unSavedChanges',
        'pushDeletedItem': 'event:pushDeletedItem',
        'close': 'event:close',
        'goToNextItem': 'event:goToNextItem',
        'checkIsLastIndex': 'event:checkIsLastIndex',
        'onSaveAction': 'event:onSaveAction'
    };
    constructor(

    ) { }

    dispatchEvent(actionName:string, eventData:any={}) {
        const data = {};
        data['actionName'] = actionName;
        data['data'] = eventData;
        this.triggerEvent.next(data);
    }
}
