import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import {OpportunityService} from "@app/services/http-services/opportunity.service";
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';

@Component({
	selector: 'app-email-to-suppliers',
	templateUrl: './email-to-suppliers.component.html',
	styleUrls: ['../list-creation-modal/list-creation-modal.component.scss']
})
export class EmailToSuppliersComponent implements OnInit {
	public onClose: Subject<{}>;
	title: string;
	emailContent: any;
	data: any;
	disable: boolean = true;
	count:any = 0;
	suppliers:any;
	constructor(
		public bsModalRef: BsModalRef,
		private opportunityService: OpportunityService,
		private proposalService: ProposalTemplateService
	) {}

	ngOnInit() {
		this.getSuppliers();
		this.fetchSupplierPartsQuotationMessage();
		this.onClose = new Subject();
	}

    getSuppliers() {
        this.proposalService.getEstimatedSuppliers().subscribe(
            (data: any) => {
                this.suppliers = data;
                for (let i = 0; i < this.data.suppliers.length; i++) {
                    let assigneeIndex = this.suppliers.findIndex((val: any) => {
                        return val.id == parseInt(this.data.suppliers[i])
                    });
                    if (this.suppliers[assigneeIndex]['emailaddress'] == null) {
                        this.count++;
                    }
                }
            }
        )
    }

    fetchSupplierPartsQuotationMessage(){
		this.opportunityService.getSupplierPartsQuotationMessage().subscribe(
			response => {
				this.emailContent = response;
				this.disable = false;
			},
			error => {
				console.log(error);
				this.disable = false;
			}
		);
	}

	sendEmail() {
		this.disable = true;
		let supplierData = {
			emailBody: this.emailContent['message'],
			emailHeader: this.emailContent['subject'],
			parts: this.data.parts,
			suppliers: this.data.suppliers,
			listId: this.data.listId,
            opportunityId: this.data.opportunityId
		};

		this.opportunityService.emailPartListToSuppliers(supplierData).subscribe(
			result => {
				console.log(result);
                this.bsModalRef.hide();
                this.disable = false;
            }, error => {
                this.disable = false;
			});
	}

	updateMessage(emailContent) {
		this.emailContent = emailContent;
	}

}
