import { EventEmitter, OnInit } from '@angular/core';
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { ExitCriteriaSidePanelComponent } from "@app/workflow-common/exit-criteria-side-panel/exit-criteria-side-panel.component";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { FormBuilder, Validators } from "@angular/forms";
import { AppValidators } from "@app/directives";
var ExitConditionViewComponent = /** @class */ (function () {
    function ExitConditionViewComponent(modalService, httpService, fb) {
        this.modalService = modalService;
        this.httpService = httpService;
        this.fb = fb;
        this.selectedExitCriteria = new EventEmitter();
        this.hasExitData = false;
        this.dataList = [];
        this.loading = true;
        this.deleteConfirmTextInput = '';
        this.inValidConfirmText = false;
    }
    ExitConditionViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.deleteConfirmationForm = this.fb.group({
            'confirmationText': ['', [Validators.required, AppValidators.confirmationTextValidator('DELETE')]]
        });
        this.httpService.getExitConditions(this.data.type, this.data.id)
            .subscribe({
            next: function (resp) {
                _this.exitCriteria = resp.exitCriteria;
                _this.dataList = resp.exitCriteriaList;
                _this.loading = false;
            }
        });
        this.exitConditionMetaData = {
            columns: [{
                    col_name: "text",
                    title: 'Criteria',
                    pos: 1,
                    active: true,
                }],
            limit: 5,
            sort: null
        };
        this.actions = [
            {
                id: 'delete',
                title: 'Delete',
                access: [],
                visible: function (data, type, row) {
                    return !row.default;
                },
                onClick: function (value) {
                    _this.showDeleteConfirmationModal = true;
                    _this.deleteData = value.id;
                }
            }
        ];
    };
    ExitConditionViewComponent.prototype.setSmartTableInstance = function ($event) {
        this.smartTableInstance = $event;
    };
    ExitConditionViewComponent.prototype.addOrEditExitConditions = function (data, index) {
        var _this = this;
        if (index === void 0) { index = -1; }
        var initialState = {
            list: [],
            data: !data ? null : data,
            exitCondition: this.exitCondition,
            exitCriteria: this.exitCriteria,
            index: index,
        };
        this.modalRef = this.modalService.show(ExitCriteriaSidePanelComponent, {
            initialState: initialState
        });
        this.modalRef.content.onClose.subscribe(function (result) {
            if (result) {
                _this.hasExitData = true;
                _this.selectedExitConditionData = result;
                _this.saveExitCondition(_this.selectedExitConditionData);
                _this.selectedExitCriteria.emit(_this.selectedExitConditionData);
            }
        });
    };
    ExitConditionViewComponent.prototype.saveExitCondition = function (condition) {
        var _this = this;
        this.httpService.postExitConditionRequest({
            exitCriteria: condition,
            screen: 'settings'
        }, this.data.type, this.data.id)
            .subscribe({
            next: function (resp) {
                _this.ngOnInit();
            }
        });
    };
    ExitConditionViewComponent.prototype.deleteExitCriteria = function () {
        var _this = this;
        if (this.deleteConfirmationForm.valid) {
            this.action = 'deleting';
            this.httpService.deleteExitConditions(this.deleteData, this.data.type, this.data.id)
                .subscribe({
                next: function (resp) {
                    _this.showDeleteConfirmationModal = false;
                    _this.action = '';
                    _this.ngOnInit();
                }
            });
        }
    };
    ExitConditionViewComponent.prototype.setTableInstance = function ($event) {
        this.tableInstance = $event;
    };
    ExitConditionViewComponent.prototype.onConfirmationModal = function () {
        this.showDeleteConfirmationModal = false;
        this.deleteConfirmationForm.reset();
    };
    return ExitConditionViewComponent;
}());
export { ExitConditionViewComponent };
