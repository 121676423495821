import * as tslib_1 from "tslib";
import { InterfaceService } from "@app/interfaces";
import { HttpClient } from "@angular/common/http";
var CommunicationHttpService = /** @class */ (function (_super) {
    tslib_1.__extends(CommunicationHttpService, _super);
    function CommunicationHttpService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    CommunicationHttpService.prototype.getEmailTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsEmailTemplates", params));
    };
    CommunicationHttpService.prototype.getSMSTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsSmsTemplates", params));
    };
    CommunicationHttpService.prototype.getCommunicationByScreenType = function (objectName, categoryType, screenType, params) {
        return this.http.get(this.getApiUrl("communication/settings/" + objectName + "/" + categoryType + "/constant/" + screenType, params));
    };
    CommunicationHttpService.prototype.saveCommunication = function (objectName, categoryType, screenType, params) {
        return this.http.post(this.getApiUrl("communication/settings/" + objectName + "/" + categoryType + "/constant/" + screenType + "/save"), params);
    };
    return CommunicationHttpService;
}(InterfaceService));
export { CommunicationHttpService };
