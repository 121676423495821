<ng-container>
    <div class="modal-header" [style.box-shadow]="'none'">
        <div>
            <h4 class="modal-title" translate>{{title}}</h4>
            <div *ngIf="!!helpText" class="cs-help-text-color"><span translate>{{helpText}}</span></div>
        </div>
        <button type="button" class="close" id="close-button" (click)="closeModal()" style="margin-left: auto;"><strong>×</strong></button>
    </div>
    <div class="modal-body">
        <app-sc-layout [sourceId]="sourceId" [screenType]="screenType" [selectedTemplateData]="selectedTemplateData" [objectName]="objectName" [viewMode]="viewMode" class="h-100 d-block">
        </app-sc-layout>
    </div>

    <!--<div class="modal-footer">
        <div class="cs-d-flex h-100 align-items-center">
            <div class="p-l&#45;&#45;15 p-r&#45;&#45;15 ml-auto">
                <button (click)="closeModal()" class="btn btn-plain link-color">Cancel</button>
                <button (click)="saveAction()" [disabled]="disabledAction || saving" class="btn btn-primary">
                    <span *ngIf="!saving">Save</span>
                    <span *ngIf="saving">Saving...</span>
                </button>
            </div>
        </div>
    </div>-->
</ng-container>
