import * as tslib_1 from "tslib";
import { InterfaceService } from '@app/interfaces';
import { SettingsService } from "@app/core";
import { KB_Datas } from "@app/core/config/data/KB_Datas";
import * as i0 from "@angular/core";
import * as i1 from "../settings/settings.service";
var ConfigKBUrlService = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigKBUrlService, _super);
    function ConfigKBUrlService(settingsService) {
        var _this = _super.call(this) || this;
        _this.settingsService = settingsService;
        _this._regionDatas = {};
        _this._defaultRegion = 'United Kingdom';
        _this._regionDatas = KB_Datas;
        _this.setData(_this.getRegion());
        return _this;
    }
    ConfigKBUrlService.prototype.setData = function (name) {
        this._data = this._regionDatas.hasOwnProperty(name) ? this._regionDatas[name] : null;
        if (!this._data) {
            this._data = this._regionDatas[this._defaultRegion];
        }
    };
    ConfigKBUrlService.prototype.getRegion = function () {
        var c = this.settingsService.getCountryCode();
        return c['countryName'];
    };
    ConfigKBUrlService.prototype.getData = function (key) {
        var value = this._data['datas'][key];
        return this._data['domainName'] + value;
    };
    ConfigKBUrlService.prototype.getDomain = function () {
        return this._data['domainName'];
    };
    ConfigKBUrlService.prototype.getEmail = function () {
        return this._data['supportEmail'];
    };
    ConfigKBUrlService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigKBUrlService_Factory() { return new ConfigKBUrlService(i0.inject(i1.SettingsService)); }, token: ConfigKBUrlService, providedIn: "root" });
    return ConfigKBUrlService;
}(InterfaceService));
export { ConfigKBUrlService };
