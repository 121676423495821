import { ElementRef, OnInit } from '@angular/core';
var DigitDecimalInputDirective = /** @class */ (function () {
    //private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    function DigitDecimalInputDirective(el) {
        this.el = el;
        this.scale = 4;
        this.precision = 0;
    }
    DigitDecimalInputDirective.prototype.ngOnInit = function () {
        this.regex = new RegExp('^\\d*\\.?\\d{0,' + this.scale + '}$', 'g');
    };
    DigitDecimalInputDirective.prototype.getPrecision = function () {
        return this.precision > 0 ? this.precision - 1 : 16;
    };
    DigitDecimalInputDirective.prototype.onKeyDown = function (e) {
        // Allow Backspace, tab, end, and home keys
        var key = e.charCode || e.keyCode || 0;
        var isNumeric = ((key == 8) || (key == 9) || (key == 13) || (key == 46)
            || (key == 116) || (key == 123) || ((key >= 35) && (key <= 40))
            || ((key >= 48) && (key <= 57)) || ((key >= 96) && (key <= 105)) || (key == 190 || key == 110));
        var actionKeys = ((key == 8) || (key == 9) || (key == 46) || (key == 13) || ((key >= 35) && (key <= 40)));
        if (!isNumeric) {
            e.preventDefault();
            return;
        }
        if (e.shiftKey) {
            e.preventDefault();
        }
        var hasDecimal = e.target.value.indexOf('.') !== -1;
        var dotcontains = e.target.value.indexOf(".");
        var newValue = e.target.value;
        var caratPos = e.target.selectionStart;
        if (hasDecimal && (e.keyCode == 190 || e.keyCode == 110)) {
            console.log('key', e.keyCode);
            e.preventDefault();
        }
        if (dotcontains > -1) {
            var arrVal = newValue.split(".");
            var scale = caratPos > dotcontains ? this.scale - 1 : this.getPrecision();
            var ind = caratPos > dotcontains ? 1 : 0;
            if (arrVal[ind].length > scale && !actionKeys) {
                e.preventDefault();
            }
        }
        else {
            newValue.length > this.getPrecision() && !(key == 190 || key == 110) && !actionKeys && e.preventDefault();
        }
    };
    return DigitDecimalInputDirective;
}());
export { DigitDecimalInputDirective };
