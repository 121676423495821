import { from } from 'rxjs/index';
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/internal/operators";
var SmartTableMetaDataService = /** @class */ (function () {
    function SmartTableMetaDataService(trans) {
        this.trans = trans;
        this._columns = [];
        this._columnsDefs = [];
        this._sort = null;
        this._limit = 20;
        this.capitalize = function (s) {
            if (typeof s !== 'string')
                return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        };
        console.info('lang', this.trans.instant('Service.Periodsss'));
    }
    SmartTableMetaDataService.prototype.initiaze = function (headers) {
        this.metaHeaders = headers;
        this._prepareColumns();
        return this;
    };
    SmartTableMetaDataService.prototype._prepareColumns = function () {
        var _this = this;
        var newCols = [];
        var sortableCols = this.metaHeaders.hasOwnProperty('sortable_columns') ? !this.metaHeaders.sortable_columns ? [] : this.metaHeaders.sortable_columns : [];
        var sortByPos$ = this.metaHeaders.columns.sort(function (a, b) {
            return a.pos < b.pos ? -1 : 1;
        });
        var mappedCols = [];
        var columnsDefs = [];
        var columns$ = from(sortByPos$).pipe(map(function (item, index) {
            if (item.hasOwnProperty('width')) {
                columnsDefs.push({
                    "width": item.width,
                    "targets": index,
                });
            }
            var newCols = {
                data: item.col_name,
                title: item.hasOwnProperty('title') ? item.title : _this.trans.instant(item.col_name),
                pos: item.pos,
                orderable: sortableCols.indexOf(item.col_name) > -1,
                columnIndex: index,
                visible: item.active,
                active: item.active,
                col_name: item.col_name,
                color: item.color ? item.color : false
            };
            if (item.hasOwnProperty('render')) {
                newCols.render = item.render;
            }
            // hidden,width,field used for assets
            if (item.hasOwnProperty('field')) {
                newCols['field'] = item.field;
            }
            if (item.hasOwnProperty('hidden')) {
                newCols['hidden'] = item.hidden;
            }
            if (item.hasOwnProperty('width')) {
                newCols['width'] = item.width;
            }
            return newCols;
        }));
        this._columnsDefs = columnsDefs;
        columns$.subscribe(function (col) { return mappedCols.push(col); });
        this._columns = mappedCols;
        if (this.metaHeaders.sort) {
            this._sort = Object.keys(this.metaHeaders.sort).length === 0 ? null : this.metaHeaders.sort;
        }
        if (this.metaHeaders.limit) {
            this._limit = this.metaHeaders.limit;
        }
        return this.metaHeaders.columns;
    };
    SmartTableMetaDataService.prototype.getColumns = function () {
        return this._columns;
    };
    SmartTableMetaDataService.prototype.getColumnsDefs = function () {
        return this._columnsDefs;
    };
    SmartTableMetaDataService.prototype.getSorting = function () {
        return this._sort;
    };
    SmartTableMetaDataService.prototype.getLimit = function () {
        return parseInt(this._limit);
    };
    return SmartTableMetaDataService;
}());
export { SmartTableMetaDataService };
