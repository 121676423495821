import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
	selector: 'app-pricing-padding-options',
	templateUrl: './pricing-padding-options.component.html',
	styleUrls: ['./pricing-padding-options.component.scss']
})
export class PricingPaddingOptionsComponent implements OnInit {
	public onClose: Subject<boolean>;
	action: string = 'save';
	title: string;
	marginLeft = 0;
	marginRight = 0;
	marginTop = 0;
	marginBottom = 0;
	paddingLeft = 0;
	paddingRight = 0;
	paddingTop = 0;
	paddingBottom = 0;
	constructor(
		public bsModalRef: BsModalRef,
	) { }

	ngOnInit() {
		this.onClose = new Subject();
	}

	incDecValue(style, operator) {
		switch (style) {
			// Margin 
			case 'marginLeft':
				if (operator == 'inc') {
					++this.marginLeft
				} else {
					--this.marginLeft
				}
				break;
			case 'marginRight':
				if (operator == 'inc') {
					++this.marginRight
				} else {
					--this.marginRight
				}
				break;
			case 'marginTop':
				if (operator == 'inc') {
					++this.marginTop
				} else {
					--this.marginTop
				}
				break;
			case 'marginBottom':
				if (operator == 'inc') {
					++this.marginBottom
				} else {
					--this.marginBottom
				}
				break;
			// Padding
			case 'paddingLeft':
				if (operator == 'inc') {
					++this.paddingLeft
				} else {
					--this.paddingLeft
				}
				break;
			case 'paddingRight':
				if (operator == 'inc') {
					++this.paddingRight
				} else {
					--this.paddingRight
				}
				break;
			case 'paddingTop':
				if (operator == 'inc') {
					++this.paddingTop
				} else {
					--this.paddingTop
				}
				break;
			case 'paddingBottom':
				if (operator == 'inc') {
					++this.paddingBottom
				} else {
					--this.paddingBottom
				}
				break;
		}
	}

	editValue(value) {

	}

	save() {
		let data: any = {
			'margin-left': this.marginLeft + 'px',
			'margin-right': this.marginRight + 'px',
			'margin-top': this.marginTop + 'px',
			'margin-bottom': this.marginBottom + 'px',
			'padding-top': this.paddingTop + 'px',
			'padding-left': this.paddingLeft + 'px',
			'padding-right': this.paddingRight + 'px',
			'padding-bottom': this.paddingBottom + 'px'
		}
		this.onClose.next(data)
		this.bsModalRef.hide()
	}

	@HostListener('mouseover', ['$event'])
	onMouseOver(event) {
	  if(event.target.matches('.margin-box')) {
		$(event.target).addClass('margin-hover')
	  } else if(event.target.matches('.padding-box')) {
		$('.margin-box').removeClass('margin-hover')
		$(event.target).addClass('padding-hover')
	  } else if(event.target.matches('.content-box')){
		$('.margin-box').removeClass('margin-hover')
		$('.padding-box').removeClass('padding-hover')
		$(event.target).addClass('content-over')
	  } else {
		$('.margin-box').removeClass('margin-hover')
		$('.padding-box').removeClass('padding-hover')
		$('content-box').removeClass('content-over')
	  }
	}
}
