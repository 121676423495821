<cs-sidepanel [show]="showSidepanel"
              [name]="'addEditCertificateSidePanel'"
              (close)="onSidepanelClose()"
              class="ltc-sidepanel add-edit-cert-side-panel slim-scroll thin">
  <!-- Panel title -->
  <div id="page-panel-title-bar">
    <h4 translate>add.edit.certificates</h4>
  </div>
    <div id="page-panel-main" class="flex flex-column">
      <!-- Panel help text -->
      <!--<div class="help_section flex-0">-->
        <!--<p translate>Please.select.the.certificate.that.you.are.going.to.add.edit.from.below.list</p>-->
      <!--</div>-->
      <!-- Panel content -->
      <div class="cs-dynamic-form-add_items-modal small-padding mb-2">
        <div class="add-item-form">
          <div class="search-input">
            <input type="text"
                   [(ngModel)]="filterText"
                   (keyup)="searchUpdated()"
                   autocomplete="off"
                   placeholder="Search">
            <a class="ss-search"></a>
          </div>
        </div>
        <div class="list-item-view-container no-item-warn">
          <ul class="list-item-view clearfix" >
            <ng-container *ngFor="let category of categories;">
              <li>
                <div class="item category">{{category}}</div>
              </li>
              <ng-container *ngFor="let certificate of certificates[category]; let certIndex = index;">
              <li *ngIf="certificate['show']"
                  [ngClass]="{'selected' : certificate['selected'] }"
                  class="certificate-li"
                  (click)="selectCertificate(category, certIndex, certificate)">
                <div class="item pd">
                  <span>{{certificate['name']}}</span>
                </div>
              </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <!-- Panel actions -->
    <div class="page-panel-actions buttons-fixed-bottom buttons-right">
      <div class="buttons">
        <button type="button"
                style="margin-right: unset;"
                [disabled]="!certificate"
                (click)="confirmCertificate()"
                class="btn btn-primary primary-button">
          <span translate>Confirm</span>
        </button>

        <a (click)="onSidepanelClose()" translate id="cancel-panel-btn"
           href="javascript: void(0)">
          Cancel
        </a>
      </div>
    </div>
</cs-sidepanel>
<cs-full-screen-modal
        [header]="certificateName && data['type'] == 'ADD' ? 'Add ' + certificateName : 'Edit ' + certificateName"
        [showSubTitleAsTemplate]="true"
        [subTitle]="subTitle"
        [bodyClass]="'add-edit-certificate-full-screen'"
        [showFooter]="false"
        [hideCloseButton]="true"
        (onContentOpen)="onOpen($event)"
        (onContentClose)="_onClose($event)">
  <app-certificate-page-container [formData]="certificatePageData"
                                  [currentPageIndex]="currentPageIndex"
                                  [customerOrLandlord]="customerOrLandlord"
                                  [lookupId]="data['certificateId']"
                                  [draftData]="draftData"
                                  [userName]="userName"
                                  [status]="status"
                                  [customerOrLandlordInfo]="customerOrLandlordInfo"
                                  (closeModal)="closeModal($event)"
  ></app-certificate-page-container>
</cs-full-screen-modal>