<ng-container *ngIf="buttons.length">
    <div class="actions_buttons">
        <ng-container *ngFor="let button of buttons; let actionIndex = index">
            <ng-container *access="button.permissions">
                <button
                    *ngIf="checkVisible(data, button)"
                    class="btn btn-primary btn-small"
                    style="margin-right: 2px;"
                    (click)="onClickButton(data, button)">
                    {{ button.title }}
                </button>
            </ng-container>
        </ng-container>
    </div>
    <span (click)="toggleClick($event)" class="preview-file-link actions-toggle ss-plus"></span>
</ng-container>
