/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cs-pagination-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./cs-pagination-panel.component";
var styles_CsPaginationPanelComponent = [i0.styles];
var RenderType_CsPaginationPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsPaginationPanelComponent, data: {} });
export { RenderType_CsPaginationPanelComponent as RenderType_CsPaginationPanelComponent };
function View_CsPaginationPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_v.context.$implicit !== ((_co.pagingParams == null) ? null : _co.pagingParams.pageSize)) ? _co.setPageSize(_v.context.$implicit) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.context.$implicit === ((_co.pagingParams == null) ? null : _co.pagingParams.pageSize))); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
function View_CsPaginationPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageOffsetList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CsPaginationPanelComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "control btn btn-link page disabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_CsPaginationPanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.parent.parent.parent.context.$implicit === ((_co.pagingParams.currentPage - _co.maxPagesDisplayLength) + 3)) || (_v.parent.parent.parent.parent.context.$implicit === (_co.pagingParams.currentPage + 2))); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CsPaginationPanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.parent.parent.context.$implicit >= ((_co.pagingParams.currentPage - _co.maxPagesDisplayLength) + 3)) && (_v.parent.parent.parent.context.$implicit <= (_co.pagingParams.currentPage + 2))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CsPaginationPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.pageList.length - _co.pagingParams.currentPage) + 1) >= _co.maxPagesDisplayLength); var currVal_1 = i1.ɵnov(_v.parent.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CsPaginationPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pagingParams.currentPage >= _co.maxPagesDisplayLength); var currVal_1 = i1.ɵnov(_v.parent, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CsPaginationPanelComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "control btn btn-link page disabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_CsPaginationPanelComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.parent.context.$implicit === (_co.maxPagesDisplayLength + 1)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CsPaginationPanelComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_10)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit > _co.maxPagesDisplayLength); var currVal_1 = i1.ɵnov(_v.parent, 5); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CsPaginationPanelComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "control btn btn-link page disabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_CsPaginationPanelComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.parent.context.$implicit === (_co.pageList.length - _co.maxPagesDisplayLength)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CsPaginationPanelComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_13)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit <= (_co.pageList.length - _co.maxPagesDisplayLength)); var currVal_1 = i1.ɵnov(_v.parent, 5); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CsPaginationPanelComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "control btn btn-link page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_v.parent.context.$implicit !== _co.pagingParams.currentPage) ? _co.setCurrentPage(_v.parent.context.$implicit) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled active": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "control btn btn-link page"; var currVal_1 = _ck(_v, 2, 0, (_co.pagingParams.currentPage === _v.parent.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
function View_CsPaginationPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["clipEndPages", 2]], null, 0, null, View_CsPaginationPanelComponent_9)), (_l()(), i1.ɵand(0, [["clipStartPages", 2]], null, 0, null, View_CsPaginationPanelComponent_12)), (_l()(), i1.ɵand(0, [["pageNumber", 2]], null, 0, null, View_CsPaginationPanelComponent_15))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit > 1) && (_v.context.$implicit < _co.pageList.length)); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CsPaginationPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "pagination-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 41, "div", [["class", "pagination-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "data-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Showing ", " to ", " of ", " entries"])), (_l()(), i1.ɵeld(4, 0, null, null, 38, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "page-size"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show"])), (_l()(), i1.ɵeld(8, 16777216, null, null, 9, "div", [["class", "btn-group page-size-dropdown-wrapper"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(10, 212992, null, 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn dropdown-toggle"], ["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_1)), i1.ɵdid(17, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["entries"])), (_l()(), i1.ɵeld(20, 0, null, null, 22, "div", [["class", "btn-group control-navigators"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "div", [["class", "control btn btn-link first"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.pagingParams.currentPage > 1) ? _co.setCurrentPage(1) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "disabled": 0 }), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["First"])), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "control btn btn-link previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.pagingParams.currentPage > 1) ? _co.setCurrentPage((_co.pagingParams.currentPage - 1)) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(28, { "disabled": 0 }), (_l()(), i1.ɵeld(29, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CsPaginationPanelComponent_3)), i1.ɵdid(32, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(33, 0, null, null, 4, "div", [["class", "control btn btn-link next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.pagingParams.currentPage < _co.pageList.length) ? _co.setCurrentPage((_co.pagingParams.currentPage + 1)) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(35, { "disabled": 0 }), (_l()(), i1.ɵeld(36, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "div", [["class", "control btn btn-link last"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.pagingParams.currentPage < _co.pageList.length) ? _co.setCurrentPage(_co.pageList.length) : false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(39, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(40, { "disabled": 0 }), (_l()(), i1.ɵeld(41, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); var currVal_10 = "control btn btn-link first"; var currVal_11 = _ck(_v, 23, 0, (_co.pagingParams.currentPage === 1)); _ck(_v, 22, 0, currVal_10, currVal_11); var currVal_12 = "control btn btn-link previous"; var currVal_13 = _ck(_v, 28, 0, (_co.pagingParams.currentPage === 1)); _ck(_v, 27, 0, currVal_12, currVal_13); var currVal_14 = _co.pageList; _ck(_v, 32, 0, currVal_14); var currVal_15 = "control btn btn-link next"; var currVal_16 = _ck(_v, 35, 0, (_co.pagingParams.currentPage === _co.pageList.length)); _ck(_v, 34, 0, currVal_15, currVal_16); var currVal_17 = "control btn btn-link last"; var currVal_18 = _ck(_v, 40, 0, (_co.pagingParams.currentPage === _co.pageList.length)); _ck(_v, 39, 0, currVal_17, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pagingParams == null) ? null : _co.pagingParams.startRow); var currVal_1 = ((_co.pagingParams == null) ? null : _co.pagingParams.endRow); var currVal_2 = _co._totalRecord; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).dropup; var currVal_4 = i1.ɵnov(_v, 10).isOpen; var currVal_5 = (i1.ɵnov(_v, 10).isOpen && i1.ɵnov(_v, 10).isBs4); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 12).isDisabled; var currVal_8 = i1.ɵnov(_v, 12).isOpen; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = ((_co.pagingParams == null) ? null : _co.pagingParams.pageSize); _ck(_v, 14, 0, currVal_9); }); }
export function View_CsPaginationPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cs-pagination-panel", [], null, null, null, View_CsPaginationPanelComponent_0, RenderType_CsPaginationPanelComponent)), i1.ɵdid(1, 114688, null, 0, i5.CsPaginationPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsPaginationPanelComponentNgFactory = i1.ɵccf("app-cs-pagination-panel", i5.CsPaginationPanelComponent, View_CsPaginationPanelComponent_Host_0, { pageOffsetList: "pageOffsetList", pageList: "pageList", initialParams: "initialParams", maxPagesDisplayLength: "maxPagesDisplayLength", totalRecord: "totalRecord" }, { onUpdatePagingParams: "onUpdatePagingParams" }, []);
export { CsPaginationPanelComponentNgFactory as CsPaginationPanelComponentNgFactory };
