import { HttpHeaders } from '@angular/common/http';

//--
import { InterfaceClass } from './class.interface';
import { GetApiUrl } from '../utils';
import { GetContractApiUrl } from "@app/utils/get-contract-api-url";
import {GetJobApiUrl} from "@app/utils/get-job-api-url";

export class InterfaceService extends InterfaceClass {
    // Creates http options for the data type
    public getHttpOptions(type: 'json' = 'json', auth: boolean = true): { headers: HttpHeaders } {
        let headers = new HttpHeaders({});

        // headers = headers.append('Timezone', 'Asia/Kolkata');

        switch (type) {
            case "json": {
                // headers = headers.append('Content-Type', 'application/json');
                break;
            }
        }

        return {
            headers: headers
        };
    }

    protected getApiUrl(path: string, params?: Object) {
        return GetApiUrl(path, params);
    }

    protected getContractApiUrl(path: string, params?: Object) {
        return GetContractApiUrl(path, params);
    }

    protected getJobApiUrl(path: string, params?: object) {
        return GetJobApiUrl(path, params);
    }
}
