//--
import { HybridFactory } from '../hybrid-factory';
import * as i0 from "@angular/core";
var UrlPatternGuard = /** @class */ (function () {
    function UrlPatternGuard() {
    }
    UrlPatternGuard.prototype.canActivate = function (next, state) {
        // Validating url params
        if (next.data.hasOwnProperty('urlPatterns') && next.data['urlPatterns'] instanceof Object) {
            var params = next.params;
            Object.entries(params).forEach(function (_a) {
                var param = _a[0], value = _a[1];
                if (next.data['urlPatterns'].hasOwnProperty(param)) {
                    var exp = new RegExp("^" + next.data['urlPatterns'][param] + "$", 'g');
                    if (!exp.test(value)) {
                        HybridFactory.navigate('/404');
                        return false;
                    }
                }
            });
        }
        return true;
    };
    UrlPatternGuard.ngInjectableDef = i0.defineInjectable({ factory: function UrlPatternGuard_Factory() { return new UrlPatternGuard(); }, token: UrlPatternGuard, providedIn: "root" });
    return UrlPatternGuard;
}());
export { UrlPatternGuard };
