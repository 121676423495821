import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication-segment',
  templateUrl: './communication-segment.component.html',
  styleUrls: ['./communication-segment.component.scss']
})
export class CommunicationSegmentComponent implements OnInit {

    activeSegment: any = false;
    constructor() { }

    ngOnInit() {
    }

    showNextSegment() {
        this.activeSegment = !this.activeSegment;
    }

}
