import {ChangeDetectorRef, Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';
import {BsModalRef } from "ngx-bootstrap";
import { FileUploader } from "ng2-file-upload";
import {from} from "rxjs/index";
import {map} from "rxjs/internal/operators";
import { PartsAndPricesService } from "@app/features/system-settings/parts-and-prices/services/parts-and-prices.service";
import { Subject } from "rxjs/index";
import {environment} from "@app/utils/get-api-url";
import { AuthService } from "@app/core";
//import {} from

declare var $: any;

const URL = environment.apiBasePath+'upload_parts_picture';


export interface FormDatas {
    settingsIndustryid?: string,
    settingsCategoryid?: string,
    settingsSubcategoryid?: string,
    description: string,
    filePath: string,
    parttype: number,
    manufacturepartnumber: string,
    defaultInstallTime: any,
    price: any,
    markup: any,
    settingstaxitemid: number,
    installtime_unit: number,
    labourCostRate: any,
    // labourSaleRate: any,
    labourRatesId?: string;
}
@Component({
    selector: 'modal-content',
    templateUrl:'./parts-and-prices.modal.html',
    styleUrls: ['./parts-and-prices.modal.scss']
})

export class PartsAndPricesModal implements OnInit, AfterViewInit {

    public onClose: Subject<boolean>;
    public cancel: Subject<boolean>;

    title: string;
    closeBtnName: string;
    list: any[] = [];
    industrylist: any[] = [];
    categorylist: any[] = [];
    subCategorylist: any[] = [];
    partTypeDropdown: any[] = [];
    isEdit:boolean = false;
    formModal:any = {};
    taxLists: any[] = [];
    units:any[] = [{id:'hours', text:'hours'}, {id:'days', text:'days'}, {id:'mins', text:'mins'}];
    dayHours:number=8;
    unitMessage:string = 'hours';
    data:any;
    uploader:FileUploader;
    select2Config: any;
    taxByProvinces: any[] = [];
    form: FormGroup;
    taxLabel: string;
    action: string = '';

    formDatas = {
        settingsIndustryid: '',
        settingsCategoryid: '',
        settingsSubcategoryid: '',
        description: '',
        filePath: '',
        parttype: 1,
        manufacturepartnumber: '',
        defaultInstallTime: '',
        price: '',
        markup: '',
        settingstaxitemid: '',
        installtime_unit: 'hours',
        labourCostRate: '',
        // labourSaleRate: '',
        labourRatesId:''
    };
    isValidUploadFile: boolean = true;
    partDetails: any;
    suppliers:any = [];
    supplierControl:any;
    labourData:any;
    labourModuleEnabled:boolean = false;
    @ViewChild('saveBtn') saveBtn: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        @Inject('$scope') private ajsScope,
        private httpService: PartsAndPricesService,
        private authService: AuthService,
        public bsModalRef: BsModalRef,
        private changeDetector: ChangeDetectorRef) {
        this.uploader = new FileUploader({
            url: URL,
            itemAlias: 'file',
            autoUpload: true,
            allowedMimeType: ['image/png', 'image/jpeg']
        });
        this.uploader.onProgressAll = (progress: any) => this.changeDetector.detectChanges();
        this.uploader.onCompleteAll = () => {
            this.isValidUploadFile=true;
        };
        this.uploader.onWhenAddingFileFailed = (fileItem:any, filter:any) => {
            if (filter.name = "mimeType") {
                this.isValidUploadFile=false;
            }
        }
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.cancel = new Subject();
        this.taxLabel = this.ajsScope.taxLabel;
        if(this.partDetails) {
            this.formDatas.description = this.partDetails.description
        }
        if(this.data) {
            this.isEdit=true;
            this.formDatas.description = this.data.description;
            this.formDatas.settingsIndustryid = this.data.hasOwnProperty('industryId') ? this.data.industryId: '';
            this.formDatas.price = this.data.price;
            this.formDatas.manufacturepartnumber = this.data.hasOwnProperty('manufacturePartNumber') ? this.data.manufacturePartNumber : '';
            this.formDatas.parttype = !this.data.type ? 1 : this.data.type;
            this.formDatas.markup = this.data.markUp;
            this.formDatas.defaultInstallTime = this.convertByUnit(this.data.defaultInstallTime, this.data.unit);
            this.formDatas.installtime_unit = this.data.unit;
            this.formDatas.labourRatesId = this.data.labourRatesId;
            this.formDatas.labourCostRate = this.data.labourCostRate;
            this.formDatas.settingstaxitemid = this.data.hasOwnProperty('taxItemId') ? this.data.taxItemId : '';
            this.formDatas.settingsSubcategoryid = this.data.hasOwnProperty('subCategoryId') ? this.data.subCategoryId : '';
            if(this.data.subCategoryId) {
                const selectedCats = this.categorylist.find( item => {
                   const selectedSubItem = item.subCategoryList.find( subItem => {
                       return subItem.subCategoryId == this.data.subCategoryId;
                   })
                   return selectedSubItem ? true : false;
                });
                if(selectedCats) {
                    this.formDatas.settingsCategoryid = selectedCats.categoriesDescription;
                    this.subCategorylist = selectedCats.subCategoryList;
                }
            }

        }
        if (this.authService.permitted(['labourRates.updateaccess']) == true) {
            this.labourModuleEnabled = true;
        }
        //this.groupByProvinces();

        this.form = this.formBuilder.group({
        'settingsIndustryid': [this.formDatas.settingsIndustryid],
        //'settingsCategoryid': [this.formDatas.settingsCategoryid],
        'settingsSubcategoryid': [this.formDatas.settingsSubcategoryid],
        'description': [this.formDatas.description, [Validators.required], this.isUniqueDescription.bind(this)],
        'filePath': [this.formDatas.filePath],
        'parttype': [this.formDatas.parttype, [Validators.required]],
        'manufacturepartnumber': [this.formDatas.manufacturepartnumber, [], this.isUniqueMpn.bind(this) ],
        'defaultInstallTime': [this.formDatas.defaultInstallTime],
        'price': [this.formDatas.price, [Validators.required]],
        'markup': [this.formDatas.markup, [Validators.required]],
        'settingstaxitemid': [this.formDatas.settingstaxitemid, [Validators.required]],
        'installtime_unit': [this.formDatas.installtime_unit],
        'labourCostRate': [this.formDatas.labourCostRate],
        'labourRatesId': [this.formDatas.labourRatesId]
        });
        this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
            const resp:any = JSON.parse(response);
            if(resp.error == false) {
                this.form.get('filePath').setValue(resp.message);
            }
        };
        this.select2Config = {
            width: '100%',
            data: this.groupByProvinces()
        };

        if(this.suppliers.length) {
            this.form.addControl('supplierprice', new FormArray([]))
            this.addSuppliers()
        }

    }

    ngAfterViewInit() {
        let that = this;
        setTimeout(() => {
            this.saveBtn.nativeElement.focus();
        },900);
    }

    updateValidation(event:any) {

        if(this.form.get('description').value=='') {
            this.form.controls['manufacturepartnumber'].updateValueAndValidity();
        }
    }

    isRequiredSubCats(control: FormControl) {
        const q = new Promise( (resolve, reject) => {
            let settingsCat = this.form.get('settingsCategoryid').value;
            if(settingsCat && control.value=='') {
                resolve({isRequiredSubCategory: true});
            }
            else {
                resolve(null);
            }
        });
        return q;
    }

    isUniqueDescription(control: FormControl) {
        const q = new Promise( (resolve, reject) => {
            //console.log('unique', control, this.form.get('manufacturepartnumber').value);
            if(this.form) {
                let params: any = {
                    description: control.value,
                    manufacturepartnumber: this.form.get('manufacturepartnumber').value,
                    ___noPageloading: true
                };
                if (this.isEdit) {
                    params.selectedId = this.data.id;
                }

                if (control.value === '') {
                    this.form.controls['description'].updateValueAndValidity();
                    resolve(null);
                    return q;
                }

                this.httpService.checkUniqueName(params).subscribe(resp => {
                    if (resp == true)
                        resolve({isUniqueDescription: true});
                    else {
                        resolve(null);
                    }
                })
            }
        });
        return q;
    }

    isUniqueMpn(control: FormControl) {
        const q = new Promise( (resolve, reject) => {
            if(this.form) {
                let params: any = {
                    manufacturepartnumber: control.value,
                    description: this.form.get('description').value,
                    ___noPageloading: true
                };
                if (this.isEdit) {
                    params.selectedId = this.data.id;
                }

                if (control.value === '') {
                    this.form.controls['description'].updateValueAndValidity();
                    resolve(null);
                    return q;
                }

                this.httpService.checkUniqueName(params).subscribe(resp => {
                    if (resp == true) {
                        resolve({isUniqueMpn: true});
                    }

                    else {
                        this.form.controls['description'].updateValueAndValidity();
                        resolve(null);
                    }
                })
            }
        });
        return q;
    }

    onChangeCategory() {
        let value =  this.form.get('settingsCategoryid').value;
        if(value === '') {
            this.subCategorylist=[];
            $('.settings-sub-cats').removeClass('required_field');
        }
        else {
            const activeCats:any = this.categorylist.find( item => item.categoriesDescription == value);
            this.subCategorylist = activeCats.subCategoryList;
            $('.settings-sub-cats').addClass('required_field');
        }
        this.form.get('settingsSubcategoryid').setValue('');
        this.form.controls['settingsSubcategoryid'].updateValueAndValidity();
    }

    onFileSelected () {
        //this.uploader.uploadAll();
    }

    groupByProvinces() {
      this.taxByProvinces=[];
      const newItems:any[] = [];
      const source = from(this.taxLists);
      const taxSource = source.pipe(
        map(groupItems => {
          const _item:any = {text:'', children:[]};
          let cnt:number=0;
          groupItems.forEach( group => {
            if(cnt===0) {
              _item.text=group.taxProvinceName
            }
            _item.children.push({
              id: group.id,
              text: group.text
            });
            cnt++;
        });
        return _item;
      })
    );
    const subscribe = taxSource.subscribe(val => this.taxByProvinces.push(val));
    return this.taxByProvinces;
    }

    get f() {
      return this.form.controls;
    }

    convertHours( input:any ) {
        if(input=='') {
            return input;
        }
        let unit = this.form.get('installtime_unit').value;
        if(unit == 'hours' ) {
            return input;
        }
        return unit == 'days' ? input*this.dayHours : input/60;
    }

    convertByUnit( input:any, unit:string ) {
        if(input=='') {
            return input;
        }
        if(unit == 'hours' ) {
            return input;
        }
        return unit == 'days' ? Math.round(input/this.dayHours) : Math.round(input*60);
    }

    onChangeUnit(event:any) {
        //console.log(event, this.form.get('installtime_unit').value);
        let unitVal = this.form.get('installtime_unit').value;
        this.unitMessage = unitVal==='days' ? 'one being 8 hours' : 'in '+unitVal;
    }

    save() {
        if(!this.form.valid) {
            return false;
        }
        this.action = 'saving';
        if (this.suppliers.length !=0) {
            let indexToRemove: number[] = [];

            let fromArray = this.form.get('supplierprice') as FormArray;
            fromArray.controls.forEach((control, index) => {
                if (control.value.price === '' && control.value.supplierPartNumber === '') {
                    indexToRemove.push(index);
                }
            });
            indexToRemove.sort().reverse().forEach((index) => {
                fromArray.removeAt(index);
            });
        }
        let defaultInstallTime = this.form.get('defaultInstallTime').value;
        let partsandprices:any = this.form.value;
        if(this.isEdit) {
            partsandprices.id = this.data.id;
        }
        partsandprices.defaultInstallTime = this.convertHours(defaultInstallTime);
        this.httpService.addEditPartsAndPrices({partsandprices}, this.isEdit ? this.data.id : 0)
          .subscribe( resp => {
            this.action = '';
            resp['data']['labourRatesId'] = partsandprices.labourRatesId;
            this.onClose.next(resp);
            this.bsModalRef.hide();
            }, error => {
              this.action='';
            console.log('err', error);
          });
    }


    // Adding suppliers field while adding parts in pricing section parts
    addSuppliers() {
        // add suppliers to the list
        var control = <FormArray>this.form.controls['supplierprice'];
        for(var i=0; i<this.suppliers.length; i++) {
            let supplierForm;
            supplierForm = this.formBuilder.group({
                price: [''],
                supplierId: [this.suppliers[i]['id']],
                supplierName: [this.suppliers[i]['name']],
                supplierPartNumber:['']
            });
            control.push(supplierForm);
        }
    }

    cancel_(){
        this.bsModalRef.hide();
        this.cancel.next(true);
    }
}
