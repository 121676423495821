import { ElementRef, OnChanges, EventEmitter } from '@angular/core';
// ..
import { CountriesService, CommonService } from '@app/services';
var CsPhonewidgetComponent = /** @class */ (function () {
    function CsPhonewidgetComponent(countriesService, commonService, el_ref, settings) {
        this.countriesService = countriesService;
        this.commonService = commonService;
        this.el_ref = el_ref;
        this.settings = settings;
        this.valueEmitter = new EventEmitter();
        this.selectedCountry = '';
        this.selectedCountryCode = '';
        this.countryName = '';
        this.hasImageValue = false;
        this.countries = this.settings.countriesJSONData;
    }
    CsPhonewidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedCountryID = this.settings._csCountryCode.id;
        this.selectedCountryCode = this.settings._csCountryCode.code;
        if (!this.selectedCode) {
            if (this.selectedCountryCode == '' || this.selectedCountryCode == undefined || this.selectedCountryCode == 'null') {
                this.commonService.getClientCountryCodes().subscribe(function (res) {
                    _this.selectedCountryID = res.id;
                    _this.selectedCountryCode = res.code;
                    var that = _this;
                    _.each(that.countries, function (val, key) {
                        if (key == that.selectedCountryCode) {
                            var country_obj = that.countriesService.getCountryByCode(key);
                            that.hasImageValue = true;
                            that.selectedCountry = country_obj[0].id;
                            that.selectedCountryID = country_obj[0].id;
                            that.selectedCountryCode = key;
                            that.valueEmitter.emit(key);
                        }
                    });
                });
            }
            if (this.selectedCountryCode != '') {
                var that_1 = this;
                _.each(that_1.countries, function (val, key) {
                    if (key == that_1.selectedCountryCode) {
                        var country_obj = that_1.countriesService.getCountryByCode(key);
                        that_1.hasImageValue = true;
                        that_1.selectedCountry = country_obj[0].id;
                        that_1.selectedCountryID = country_obj[0].id;
                        that_1.selectedCountryCode = key;
                        that_1.valueEmitter.emit(key);
                    }
                });
            }
        }
        if (this.selectedCode) {
            var that_2 = this;
            _.each(that_2.countries, function (val, key) {
                if (key == that_2.selectedCode) {
                    var country_obj = that_2.countriesService.getCountryByCode(key);
                    that_2.hasImageValue = true;
                    that_2.selectedCountry = country_obj[0].id;
                    that_2.selectedCountryID = country_obj[0].id;
                    that_2.selectedCountryCode = key;
                    that_2.valueEmitter.emit(key);
                }
            });
        }
        this.phoneInput = $(this.el_ref.nativeElement).parent().find('.phone-number-input');
        this.inputCollection = $(this.el_ref.nativeElement).parent().find('.phone-number-wrapper');
        this.areaCode = $(this.el_ref.nativeElement).parent().find('.selected-area-code');
        var that = this;
        $(document).on('click', function (e) {
            if ($(e.target).closest("#countrycode-dropdown").length === 0) {
                $(that.el_ref.nativeElement).removeClass('open');
            }
        });
    };
    CsPhonewidgetComponent.prototype.handleErrorMsgWidth = function (char_length) {
        if (char_length === 2) {
            this.phoneInput.css('width', '150px');
        }
        else if (char_length === 3) {
            this.phoneInput.css('width', '142px');
        }
    };
    CsPhonewidgetComponent.prototype.selectCountry = function (code) {
        var country_obj = this.countriesService.getCountryByCode(code);
        this.selectedCountry = country_obj[0].id;
        this.selectedCountryID = country_obj[0].id;
        this.selectedCountryCode = code;
        this.valueEmitter.emit(code);
        this.showCountries = false;
        $(this.el_ref.nativeElement).removeClass('open');
        // The width of the input changes depends on the number of characters the country code is
        (code.length > 2) ? this.handleErrorMsgWidth(3) : this.handleErrorMsgWidth(2);
    };
    CsPhonewidgetComponent.prototype.openDropDown = function () {
        if (this.showCountries == true) {
            this.showCountries = false;
            $(this.el_ref.nativeElement).removeClass('open');
        }
        else {
            $(this.el_ref.nativeElement).addClass('open');
            this.showCountries = true;
        }
    };
    CsPhonewidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedCode !== undefined) {
            if (this.selectedCode != '') {
                var that_3 = this;
                _.each(that_3.countries, function (val, key) {
                    if (key == that_3.selectedCode) {
                        var country_obj = that_3.countriesService.getCountryByCode(key);
                        that_3.hasImageValue = true;
                        that_3.selectedCountry = country_obj[0].id;
                        that_3.selectedCountryID = country_obj[0].id;
                        that_3.selectedCountryCode = key;
                        that_3.valueEmitter.emit(key);
                    }
                });
            }
        }
    };
    return CsPhonewidgetComponent;
}());
export { CsPhonewidgetComponent };
