import * as tslib_1 from "tslib";
import { ModalBackdropComponent } from "ngx-bootstrap";
import { ElementRef, OnInit, Renderer2 } from "@angular/core";
var CsModalBackdropComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CsModalBackdropComponent, _super);
    function CsModalBackdropComponent(element, renderer) {
        return _super.call(this, element, renderer) || this;
    }
    CsModalBackdropComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.modalsCount) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-' + this.modalsCount);
        }
    };
    return CsModalBackdropComponent;
}(ModalBackdropComponent));
export { CsModalBackdropComponent };
