import * as i0 from "@angular/core";
var CsVersionService = /** @class */ (function () {
    function CsVersionService() {
        this.reloadStarted = false;
        this.clientDetails = {
            'clientId': '',
            'username': ''
        };
    }
    CsVersionService.prototype.updateCSVersion = function (csVersion) {
        var updatedVersion = parseInt(csVersion);
        this.csVersion = !isNaN(updatedVersion) ? updatedVersion : 0;
    };
    CsVersionService.prototype.validateCSVersion = function (version) {
        if (this.csVersion > 0 && this.csVersion != version && !this.reloadStarted) {
            this.reloadStarted = true;
            window.location.reload();
        }
    };
    //set userDetails
    CsVersionService.prototype.setUserDetails = function (clientId, username) {
        if (clientId != '' && username != '') {
            this.clientDetails.clientId = clientId;
            this.clientDetails.username = username;
        }
        else {
            console.error("Invalid userDetails provided");
        }
    };
    //get userdetails
    CsVersionService.prototype.getuserDeatails = function () {
        return this.clientDetails;
    };
    CsVersionService.ngInjectableDef = i0.defineInjectable({ factory: function CsVersionService_Factory() { return new CsVersionService(); }, token: CsVersionService, providedIn: "root" });
    return CsVersionService;
}());
export { CsVersionService };
