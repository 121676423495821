import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { BsModalRef } from 'ngx-bootstrap';

declare var $: any;

@Component({
    selector: 'app-image-crop',
    templateUrl: './image-crop.component.html',
    styleUrls: ['./image-crop.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageCropComponent implements OnInit {

    public onClose: Subject<boolean>;
    title: string;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    data: any;
    imageUrl: any;
    ratioPoint: any = 1 / 1;
    aspectRatio: any = [{ 'ratio': '1:1', 'aspect': 1 / 1 },
    { 'ratio': '1:3', 'aspect': 1 / 3 },
    { 'ratio': '2:3', 'aspect': 2 / 3 },
    { 'ratio': '3:2', 'aspect': 3 / 2 },
    { 'ratio': '3:4', 'aspect': 3 / 4 },
    { 'ratio': '4:3', 'aspect': 4 / 3 }]
    imgtransform: ImageTransform = {}
    slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };
    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit() {
        $('.widget-header').css({'width':'100%'})
        let image = $('#cs_editor').contents().find('div.cs_selected').find('img')
        if (image) {
            this.imageUrl = image.attr('src')
        } else {
            this.imageUrl = 'angularES/src/assets/images/arrow-left-down1.png'
        }
        var that = this
        $("#slider").slider({
            range: "max",
            min: -180,
            max: 180,
            value: that.imgtransform.rotate,
            slide: function (event, ui) {
                that.imgtransform = {
                    ...that.imgtransform,
                    rotate: ui.value
                }
            }
        });

        this.onClose = new Subject();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    getvalue(value) {
        return (value * 100).toString() + '%';
    }

    selectRatio(ratio) {
        this.ratioPoint = ratio.aspect
    }

    saveImageCrop() {
        this.onClose.next(this.croppedImage);
        this.bsModalRef.hide();
    }
    resetImage() {
        this.imgtransform = {};
    }
}
