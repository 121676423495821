import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterfaceService } from '@app/interfaces';

@Injectable({
    providedIn: 'root'
})

export class CustomerJobTemplateService extends InterfaceService {
    constructor(
        private http: HttpClient
    ) { super(); }
    addJobNew(type: string, customerId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/job/new_jobs`), JSON.stringify(data));
    }

    editJob(type: string, customerId: number, jobId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/jobs/${jobId}/update`), JSON.stringify(data));
    }

    createContact(customerId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerId}/create_contact_add`), JSON.stringify(data));
    }

    getSkills(jobId?:number,params?: any): Observable<any> {
        return this.http.get(this.getApiUrl(`search_skills_description?jobId=${jobId}`, params));
    }
    getSearchSkills(jobDescId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`get_assigned_skills?type=job_description&primaryId=${jobDescId}`));
    }
    getDefaultJobDescriptions(jobDescId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`default_job_values/${jobDescId}`));
    }

    getJobDescriptionPricingItem(jobDescId, params?: object){
        return this.http.get(this.getApiUrl(`getJobDescriptionPricingItem/${jobDescId}`, params));
    }

    getAssets(propertyId:number, assetId:number, jobId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`get_job_asset_details?propertyId=${propertyId}&jobId=${jobId}&assetId=${assetId}`));
    }
    getContactPerference(contactId:number): Observable<any>{
        return this.http.get(this.getApiUrl(`diary_jobservice_preference_validation?contactID=${contactId}`));
    }

    getJDCustomTemplates(action:string,jobDescId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomConfigTemplates?screenMode=addCustomTemplates&action=${action}&objects=Jobs&objectTypes=JobDescription&objectTypeID=${jobDescId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    getInvoiceCatCustomTemplates(action:string,invCatId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomConfigTemplates?screenMode=addCustomTemplates&action=${action}&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=${invCatId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    checkInvCatCustomTemplatesAVl(invCatId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=${invCatId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    checkJobDescCustomTemplatesAVl(jobDescId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=JobDescription&objectTypeID=${jobDescId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    confirmDeleteCustomTemplatesAnswer(params?: any): Observable<any>{
        return this.http.post(this.getApiUrl(`confirmDeleteCustomTemplatesAnswer`),  JSON.stringify(params));
    }
}
