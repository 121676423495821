import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import * as moment from 'moment';
import { LongDatePipe } from "@app/pipes";
import { CsToastBoxService } from "@app/services";
var DagrunsComponent = /** @class */ (function () {
    function DagrunsComponent(workflowService, longDatePipe, toastBox) {
        this.workflowService = workflowService;
        this.longDatePipe = longDatePipe;
        this.toastBox = toastBox;
        this.currentShowingPageNumber = 1;
        this.totalEntriesCount = 0;
        this.totalPages = 0;
        this.dagrunsItems = [];
        this.loading = false;
        this.currentPage = 1;
        this.pageLimit = 20;
        this.selectedItem = {};
        this.dagSource = {};
        this.isScheduled = false;
        this.enableInfiniteScroll = false;
        this.dagrunsItemsScheduler = {};
        this.totalEntriesCountScheduler = 0;
        this.toggleOpenClose = 0;
        this.childLoading = false;
        this.isLoadingInfiniteScroll = false;
        this.childCurrentPage = 1;
        this.childPageLimit = 8;
        this.searchPlaceHolder = '';
        this.filterByText = '';
        this.filterParams = {};
        this.activeDagRunItem = new EventEmitter();
        this.firstTimeLoad = false;
    }
    DagrunsComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.childCurrentPage = 1;
        this.getDagRuns(true);
    };
    DagrunsComponent.prototype.getDagRuns = function (resetPage) {
        var _this = this;
        if (resetPage === void 0) { resetPage = false; }
        if (resetPage) {
            this.currentPage = 1;
            this.firstTimeLoad = true;
        }
        this.workflowService.getDagrunsList(this.data['id'], {
            page: this.currentPage,
            limit: this.pageLimit
        }).subscribe(function (data) {
            //console.log('res', data);
            _this.dagrunsItems = data['dagruns'];
            _this.objectName = data['objectName'];
            _this.totalEntriesCount = data['totalCount'];
            // this.dagSource = data['dagsource'];
            _this.isScheduled = data['isScheduled'];
            _this.totalPages = Math.ceil(data['totalCount'] / _this.pageLimit);
            _this.loading = false;
            if (!_this.isScheduled)
                _this.searchPlaceHolder = 'Search by ' + _this.objectName + ' id, name, address';
        });
    };
    DagrunsComponent.prototype.updateLimitValue = function () {
    };
    DagrunsComponent.prototype.filterBy = function (filterParams, resetPage) {
        var _this = this;
        if (resetPage === void 0) { resetPage = false; }
        if (resetPage) {
            this.currentPage = 1;
            this.firstTimeLoad = true;
        }
        var params = filterParams;
        this.filterParams = params;
        params['page'] = this.currentPage;
        params['limit'] = this.pageLimit;
        this.workflowService.getDagrunsList(this.data['id'], params).subscribe(function (data) {
            _this.dagrunsItems = data['dagruns'];
            _this.objectName = data['objectName'];
            _this.totalEntriesCount = data['totalCount'];
            // this.dagSource = data['dagsource'];
            _this.isScheduled = data['isScheduled'];
            _this.totalPages = Math.ceil(data['totalCount'] / _this.pageLimit);
            _this.loading = false;
            if (!_this.isScheduled)
                _this.searchPlaceHolder = 'Search by ' + _this.objectName + ' id, name, address';
            if (_this.dagItemsContainer) {
                _this.dagItemsContainer.nativeElement.scrollTop = 0;
            }
        }, function (err) {
            _this.toastBox.show('Error!');
        });
    };
    DagrunsComponent.prototype.schedulerDagRuns = function (item) {
        var _this = this;
        if (this.toggleOpenClose == item.id)
            this.toggleOpenClose = 0;
        else
            this.toggleOpenClose = item.id;
        this.dagrunsItemsScheduler = [];
        this.enableInfiniteScroll = false;
        this.childCurrentPage = 1;
        if (!this.toggleOpenClose) {
            return;
        }
        this.childLoading = true;
        this.workflowService.getScheduledDagrunsList(this.data['id'], item['id'], this.objectName, {
            page: this.childCurrentPage,
            limit: this.childPageLimit
        }).subscribe(function (data) {
            _this.childLoading = false;
            _this.dagrunsItemsScheduler = data['dagruns'];
            _this.totalEntriesCountScheduler = data['totalCount'];
            if (_this.childCurrentPage == 1 && _this.totalEntriesCountScheduler > 3) {
                _this.enableInfiniteScroll = true;
            }
        });
    };
    DagrunsComponent.prototype.onScroll = function (item) {
        var _this = this;
        if (this.totalEntriesCountScheduler <= this.dagrunsItemsScheduler.length) {
            this.childCurrentPage = 1;
            return;
        }
        this.childCurrentPage++;
        this.isLoadingInfiniteScroll = true;
        this.workflowService.getScheduledDagrunsList(this.data['id'], item['id'], this.objectName, {
            page: this.childCurrentPage,
            limit: this.childPageLimit
        }).subscribe(function (data) {
            _this.isLoadingInfiniteScroll = false;
            _this.dagrunsItemsScheduler = _this.dagrunsItemsScheduler.concat(data['dagruns']);
            _this.totalEntriesCountScheduler = data['totalCount'];
        });
    };
    DagrunsComponent.prototype.dagHistoryHandler = function (item) {
        var _this = this;
        this.selectedItem = item;
        //console.log(item);
        this.selectedItem = item;
        this.childCurrentPage = 1;
        this.workflowService.getDagrunTasksList(item.id)
            .subscribe(function (data) {
            var sourceBlocks = {};
            var historyItems = data['historyItems'];
            if (Object.keys(historyItems).length > 0) {
                sourceBlocks = _.forEach(historyItems, function (item, taskId) {
                    item['status'] = historyItems[taskId]['status'];
                    return item;
                });
            }
            else {
                sourceBlocks = {};
            }
            setTimeout(function () {
                _this.activeDagRunItem.emit({
                    'dagRunId': item.id,
                    'xmlsource': item.xmlsource,
                    'dagSource': data['dagSource'],
                    'tasks': sourceBlocks,
                });
            }, 300);
        });
    };
    DagrunsComponent.prototype.getCreatedDate = function (item, settingsFormat) {
        if (settingsFormat === void 0) { settingsFormat = false; }
        if (settingsFormat) {
            return this.longDatePipe.transform(item.createdondatetime['date']);
        }
        return moment.utc(item.createdondatetime['date']).local().fromNow();
    };
    DagrunsComponent.prototype.getPropertyAddress = function (item) {
        var address = item.addressline1;
        if (item.address2) {
            address += ', ' + item.address2;
        }
        if (item.addressline3) {
            address += ', ' + item.addressline3;
        }
        if (item.county) {
            address += ', ' + item.county;
        }
        if (item.town) {
            address += ', ' + item.town;
        }
        if (item.postcode) {
            address += ', ' + item.postcode;
        }
        return address;
    };
    DagrunsComponent.prototype.getDiaryDescription = function (item) {
        var desc = 'Event type: ';
        if (item.type === 1) {
            desc += 'Normal';
        }
        else if (item.type === 3) {
            desc += 'Job number ' + item.tablepkid;
        }
        else if (item.type === 2) {
            desc += 'Estimate number ' + item.tablepkid;
        }
        else if (item.type === 5) {
            desc += 'Opportunity number ' + item.tablepkid;
        }
        return desc;
    };
    DagrunsComponent.prototype.fetchHistory = function (event) {
        // console.log('fetchHistory', event);
        this.currentPage = event['page'];
        if (this.firstTimeLoad && event['page'] == 1) {
            this.firstTimeLoad = false;
            return;
        }
        this.filterBy(this.filterParams, false);
    };
    return DagrunsComponent;
}());
export { DagrunsComponent };
