import { OnDestroy } from '@angular/core';
import { HansontableSelect2 } from '@app/directives';
import Handsontable from 'handsontable';
import { CsToastBoxService } from '@app/services';
import { HotTableRegisterer } from '@handsontable/angular';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../utility-services/cs-toast-box.service";
import * as i2 from "@handsontable/angular";
var CustomerInvoiceService = /** @class */ (function () {
    function CustomerInvoiceService(toastBox, hotRegisterer, confirmationBoxHelper, ajsScope) {
        var _this = this;
        this.toastBox = toastBox;
        this.hotRegisterer = hotRegisterer;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.totalEmitterSource = new BehaviorSubject(null);
        this.totalSource$ = this.totalEmitterSource.asObservable();
        this.lineItemId = new BehaviorSubject(null);
        this.deletedId$ = this.lineItemId.asObservable();
        this.hotInstances = [];
        this.totals = { 'breakdown': [], 'grandtotal': {} };
        this.breakdown = 0;
        this.cisOptions = [];
        this.nominalCodeOptions = [];
        this.invoiceItemCategories = [];
        this.taxItems = [];
        this.spreadSheetViewData = { 'items': [], pricingItems: [] };
        this.spreadsheetLineItems = [];
        this.spreadSheetSettings = [];
        this.cisRate = 0;
        this.vatType = '';
        this.lineItemDefaults = {};
        this.isHideVat = false;
        this.isHideCis = false;
        this.validateErrorRow = false;
        this.customisableSpreadsheetColumns = [];
        this.invoiceBreakdownTypeName = ['', 'no_breakdown', 'breakdown_by_category', 'full_breakdown', 'full_breakdown_by_category'];
        this.rowToDelete = [];
        this.colHeaders = ['id', 'Description', 'CIS', 'Quantity', 'Unit Price', '{taxLabel}', 'VAT Rate', 'Nominal Code', 'Total (exc {taxLabel})', 'Total VAT', 'Total CIS', 'category ID', 'ItemId', 'Actions'];
        this.disableColHeader = '';
        this.invoiceTaxState = '';
        this.requiredFields = {
            '1': 'name',
            '2': 'cis',
            '3': 'quantity',
            '4': 'unit price',
            '5': 'VAT item',
            '6': 'VAT rate',
            '7': 'nominal code'
        };
        this.customrenderer = function (instance, td, row, col, prop, value, cellProperties) {
            var cellCase = prop;
            var hotId = instance.hotId;
            // let responseParam = this.spreadsheetLineItems[hotId];
            var activeEditor = instance.getActiveEditor();
            var cisTotal = 0;
            var total = 0;
            var selectedTax = [];
            if (_this.breakdown === 4) {
                var category = _.findWhere(_this.invoiceItemCategories, { spreadsheetHotID: hotId });
                if (category && category['chosen']) {
                    _this.spreadsheetLineItems[hotId][row]['categoryid'] = category['spreadsheet_categoryId'];
                }
            }
            switch (cellCase) {
                case 'item':
                    var itemObj = _.findWhere(_this.spreadSheetViewData['items'], { id: value });
                    if (typeof itemObj !== 'undefined') {
                        _this.spreadsheetLineItems[hotId][row]['itemId'] = itemObj.itemId;
                    }
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'pricingItem':
                    var pricingItem = _.findWhere(_this.spreadSheetViewData['pricingItems'], { id: value });
                    if (typeof pricingItem !== 'undefined') {
                        value = pricingItem.text;
                        if (activeEditor && activeEditor.row === row && activeEditor.col === 2) {
                            var taxItemId = (pricingItem.taxItemId) ? pricingItem.taxItemId.toString() : _this.lineItemDefaults['taxItemId'];
                            selectedTax = _.findWhere(_this.taxItems, { id: parseInt(taxItemId) });
                            if (_this.isHideVat || _this.invoiceTaxState === false) {
                                _this.spreadsheetLineItems[hotId][row].taxItemId = null;
                                _this.spreadsheetLineItems[hotId][row].vatrate = 0;
                            }
                            else {
                                if (selectedTax) {
                                    _this.spreadsheetLineItems[hotId][row].taxItemId = selectedTax['id'];
                                    _this.spreadsheetLineItems[hotId][row].vatrate = selectedTax['effective_tax'];
                                }
                                else {
                                    _this.spreadsheetLineItems[hotId][row].taxItemId = null;
                                    _this.spreadsheetLineItems[hotId][row].vatrate = 0;
                                }
                            }
                            // checking if the value is created on locally
                            if (pricingItem.itemId && pricingItem.unitprice) {
                                _this.spreadsheetLineItems[hotId][row].itemId = pricingItem.itemId;
                                _this.spreadsheetLineItems[hotId][row].unitprice = pricingItem.unitprice;
                            }
                            if (pricingItem.itemId && pricingItem.nominalCodeId) {
                                _this.spreadsheetLineItems[hotId][row].nominalcode = pricingItem.nominalCodeId;
                            }
                        }
                    }
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'cis':
                    if (value === 'Yes') {
                        cisTotal = ((_this.spreadsheetLineItems[hotId][row].total * _this.cisRate) / 100);
                        cisTotal = _this.roundup(cisTotal, 2);
                        _this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                    }
                    else {
                        _this.spreadsheetLineItems[hotId][row].totalcis = 0;
                    }
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'taxItemId':
                    var totalvat = void 0, effective_tax = void 0, selectedId = void 0;
                    value = (value) ? value.toString() : value;
                    if (_this.isHideVat || !value && value != null) {
                        effective_tax = 0;
                    }
                    else {
                        selectedTax = _.findWhere(_this.taxItems, { id: parseInt(value) });
                        if (typeof selectedTax === 'undefined') {
                            selectedTax = _.findWhere(_this.taxItems, { id: _this.lineItemDefaults['taxItemId'] });
                        }
                        selectedId = selectedTax.id;
                        effective_tax = parseFloat(selectedTax.effective_tax);
                        value = selectedTax.text;
                    }
                    /** calculate exc total*/
                    if (_this.vatType === 'inc_vat') {
                        total = (_this.spreadsheetLineItems[hotId][row].quantity * _this.spreadsheetLineItems[hotId][row].unitprice * 100) / (100 + effective_tax);
                    }
                    else {
                        total = _this.spreadsheetLineItems[hotId][row].quantity * _this.spreadsheetLineItems[hotId][row].unitprice;
                    }
                    total = _this.roundup(total, 2);
                    /** calculate total vat */
                    if (_this.vatType === 'inc_vat') {
                        totalvat = (_this.spreadsheetLineItems[hotId][row].quantity * _this.spreadsheetLineItems[hotId][row].unitprice) - total;
                    }
                    else {
                        totalvat = (total * effective_tax) / 100;
                    }
                    /** calculate total cis */
                    if (_this.spreadsheetLineItems[hotId][row].cis === 'Yes') {
                        cisTotal = ((total / 100) * _this.cisRate);
                        cisTotal = _this.roundup(cisTotal, 2);
                        _this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                    }
                    _this.spreadsheetLineItems[hotId][row].total = _this.roundup(total, 2);
                    _this.spreadsheetLineItems[hotId][row].vatrate = effective_tax;
                    _this.spreadsheetLineItems[hotId][row].totalvat = _this.roundup(totalvat, 2);
                    _this.spreadsheetLineItems[hotId][row].taxItemId = selectedId;
                    _this.breakdownTotal();
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'nominalcode':
                    var selectedNominalCode = _.findWhere(_this.nominalCodeOptions, { id: parseInt(value) });
                    value = (selectedNominalCode) ? selectedNominalCode.text : '';
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'quantity':
                    var newQuantity = (value < 0 || isNaN(parseFloat(value))) ? 1 : parseFloat(value);
                    value = _this.roundup(newQuantity, 2);
                    _this.spreadsheetLineItems[hotId][row].quantity = value;
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'unitprice':
                    value = parseFloat(value);
                    value = (value === 0) ? '0.00' : _this.roundup(value, 2);
                    _this.spreadsheetLineItems[hotId][row].unitprice = value;
                    if (_this.isHideVat) {
                        if (_this.vatType === 'inc_vat') {
                            total = (_this.spreadsheetLineItems[hotId][row].quantity * value * 100) / (100 + parseFloat(_this.spreadsheetLineItems[hotId][row].vatrate));
                        }
                        else {
                            total = _this.spreadsheetLineItems[hotId][row].quantity * value;
                        }
                        _this.spreadsheetLineItems[hotId][row].total = _this.roundup(total, 2);
                        if (_this.spreadsheetLineItems[hotId][row].cis === 'Yes') {
                            cisTotal = ((_this.spreadsheetLineItems[hotId][row].total / 100) * _this.cisRate);
                            cisTotal = _this.roundup(cisTotal, 2);
                            _this.spreadsheetLineItems[hotId][row].totalcis = cisTotal;
                        }
                    }
                    _this.breakdownTotal();
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    break;
                case 'deleteAction':
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    var link = document.createElement('a');
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', function () {
                        if (instance.getDataAtCell(row, 1) !== '') {
                            _this.deleteAction(instance, row, col);
                        }
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                    return td;
                    break;
            }
        };
        this.afterGetColHeader = function (col, TH) {
            var className = '';
            if (_this.disableColHeader) {
                className = 'disable-col-header ';
            }
            if (_this.requiredFields[col]) {
                className += 'col_header_bg';
            }
            if (className && TH) {
                TH.className = className;
            }
        };
        this.emptyCellValidator = function (value, callback) {
            if (value === '') {
                _this.toastBox.show('Empty cell not allowed', 1000);
                callback(false);
            }
            else {
                callback(true);
            }
        };
        this.afterSelection = function (r, c, r2, c2, preventScrolling, hotId) {
            var instanceSettings = _this.hotInstances[hotId].getSettings();
            if (c === 1) {
                instanceSettings.fillHandle = false;
            }
            else {
                instanceSettings.fillHandle = { direction: 'vertical', autoInsertRow: true };
            }
        };
        this.deleteAction = function (instance, r, c) {
            var spreadsheetService = _this;
            if (c === (instance.getInstance().countCols() - 1)) {
                _this.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', _this.ajsScope)
                    .then(function () {
                    var rowId = instance.getSourceDataAtRow(r);
                    if (spreadsheetService.breakdown === 2) {
                        spreadsheetService.lineItemId.next(rowId.breakdownId);
                    }
                    else {
                        spreadsheetService.lineItemId.next(rowId.line_item_id);
                    }
                    if ((typeof rowId !== 'undefined') && rowId.id) {
                        spreadsheetService.rowToDelete.push(rowId.id);
                    }
                    instance.alter('remove_row', r);
                    instance.render();
                    spreadsheetService.breakdownTotal();
                    var rowsCount = instance.countRows();
                    if (rowsCount === 0 || rowsCount === r) {
                        instance.alter('insert_row', rowsCount);
                        instance.render();
                    }
                }, function () {
                    return false;
                });
            }
        };
        Handsontable.renderers.registerRenderer('customRenderer', this.customrenderer);
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }
    CustomerInvoiceService.prototype.ngOnDestroy = function () {
    };
    /* custom functions */
    CustomerInvoiceService.prototype.removeData = function (hotId) {
        delete this.spreadsheetLineItems[hotId];
        delete this.hotInstances[hotId];
        delete this.totals[hotId];
    };
    CustomerInvoiceService.prototype.formatTaxItems = function (taxData) {
        var tax = [];
        var taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach(function (province) {
            var taxLabel = province[0]['taxProvinceName'];
            tax.push({ 'text': taxLabel, 'children': province });
        });
        return tax;
    };
    CustomerInvoiceService.prototype.setCustomisableSpreadsheetColumns = function (data) {
        this.customisableSpreadsheetColumns = data;
        for (var hotId in this.hotInstances) {
            if (this.hotInstances[hotId]) {
                this.hotInstances[hotId].render();
            }
        }
    };
    CustomerInvoiceService.prototype.setBasicSettings = function (params) {
        var defaults = params.defaults;
        this.taxItems = params.tax;
        this.nominalCodeOptions = params.nominalCode;
        this.invoiceItemCategories = params.invoiceItemCategories;
        this.cisRate = defaults.cisRate;
        this.lineItemDefaults['taxItemId'] = params.defaults.taxItemId;
        this.lineItemDefaults['nominalCodeId'] = this.nominalCodeOptions.length > 0 ? this.nominalCodeOptions[0]['id'] : '';
        this.isHideCis = defaults.isHideCIS;
        this.isHideVat = defaults.isHideVat;
        this.cisOptions = params.cisOptions;
        var taxLabel = params['taxData']['taxLabel'];
        this.colHeaders[5] = taxLabel;
        this.colHeaders[8] = "Total (exc " + taxLabel + ")";
        this.jobId = params['jobId'];
    };
    CustomerInvoiceService.prototype.setBreakdown = function (breakdown) {
        var _this = this;
        this.breakdown = breakdown;
        var hotIds = Object.keys(this.spreadsheetLineItems);
        var spreadsheetService = this;
        hotIds.forEach(function (value) {
            // Push already saved line items to rowToDelete array to fix COM-21733
            _this.spreadsheetLineItems[value].forEach(function (row) {
                if ((typeof row !== 'undefined') && row.id) {
                    spreadsheetService.rowToDelete.push(row.id);
                }
            });
            _this.spreadsheetLineItems[value] = [];
        });
    };
    CustomerInvoiceService.prototype.setVatType = function (vatType) {
        this.vatType = vatType;
        for (var hotId in this.hotInstances) {
            if (this.hotInstances[hotId]) {
                this.hotInstances[hotId].render();
            }
        }
    };
    CustomerInvoiceService.prototype.populateData = function (data) {
        var _this = this;
        var hotId = this.invoiceBreakdownTypeName[this.breakdown];
        if (typeof this.spreadsheetLineItems[hotId] === 'undefined') {
            this.spreadsheetLineItems[hotId] = [];
        }
        data.forEach(function (value, key) {
            var lineItem = Object.assign({}, _this.defaultLineItemSchema);
            lineItem['id'] = value['id'];
            lineItem['quantity'] = value['quantity'];
            lineItem['unitprice'] = value['unitprice'];
            lineItem['taxItemId'] = value['taxItemId'];
            lineItem['vatrate'] = value['vatrate'];
            lineItem['itemId'] = value['descriptionid'];
            lineItem['cis'] = value['cis'] ? value['cis'] : lineItem['cis'];
            lineItem['nominalcode'] = value['nominalCodeId'] ? value['nominalCodeId'] : lineItem['nominalcode'];
            if (_this.breakdown === 2) {
                lineItem['item'] = value['description'];
            }
            else if (_this.breakdown === 3 || _this.breakdown === 4) {
                lineItem['pricingItem'] = value['description'];
            }
            if (_this.breakdown === 4) {
                lineItem['invoiceItemCategoryId'] = value['invoiceItemCategoryId'];
                var hot = _.findWhere(_this.invoiceItemCategories, { spreadsheet_categoryId: parseInt(lineItem['invoiceItemCategoryId']) });
                hotId = hot['spreadsheetHotID'];
            }
            _this.spreadsheetLineItems[hotId].push(lineItem);
        });
        var defaultLineItemSchema = Object.assign({}, this.defaultLineItemSchema);
        if (this.invoiceTaxState === '') {
            // @ts-ignore
            this.invoiceTaxState = (_.max(_.pluck(data, 'taxItemId')) > null);
            if (this.invoiceTaxState === false) {
                defaultLineItemSchema['taxItemId'] = null;
            }
        }
        Object.keys(this.hotInstances).forEach(function (hot) {
            if (_this.invoiceTaxState === false) {
                _this.isHideVat = true;
                _this.spreadSheetSettings[hot]['hiddenColumns']['columns'].push(5);
                _this.spreadSheetSettings[hot]['dataSchema'] = defaultLineItemSchema;
            }
            _this.hotInstances[hot].updateSettings(_this.spreadSheetSettings[hot]);
            _this.hotInstances[hot].render();
        });
    };
    /* Handsontable call backs */
    CustomerInvoiceService.prototype.afterInit = function (hotId, hotInstance) {
        var _this = this;
        this.hotInstances[hotId] = hotInstance;
        this.totals['breakdown'][hotId] = { 'total': 0, 'lineItemCount': 0 };
        this.totalEmitterSource.next(this.totals);
        var baseSettings = {
            colWidths: [60, 220, 70, 120, 140, 200, 120, 170, 160, 70, 70, 70, 70, 70, 80],
            colHeaders: function (index) {
                var header = _this.colHeaders[index];
                var prop = _this.spreadSheetSettings[hotId]['columns'][index]['data'];
                prop = (prop === 'unitprice') ? 'unitPrice' : prop;
                var check = _.findWhere(_this.customisableSpreadsheetColumns, { name: prop, selected: true });
                _this.disableColHeader = '';
                if (check) {
                    header = header + '<span  tooltip="Column hidden from customer" tooltip-placement="top" class="ss-ban hidden-column"> </span>';
                    _this.disableColHeader = index;
                }
                return header;
            },
            afterSelection: function (row, column) {
                if (hotInstance.countCols() === column + 1) {
                    jQuery('.wtBorder').addClass('no-bg');
                }
                else {
                    jQuery('.wtBorder').removeClass('no-bg');
                }
            },
            enterMoves: function () {
                var maxCol = hotInstance.getCellMeta(0, 0).columns.length - 1;
                if (hotInstance.getSelected()[1] >= maxCol) {
                    return { row: 1, col: -maxCol };
                }
                else {
                    return { row: 0, col: 1 };
                }
            },
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: false
            },
            preventOverflow: 'vertical',
            rowHeaders: true,
            startRows: 0,
            startCols: 0,
            minSpareCols: 0,
            autoWrapRow: true,
            formulas: true,
            minSpareRows: 0,
            minRows: 0,
            comments: true,
            afterGetColHeader: this.afterGetColHeader,
            currentColClassName: 'selectedCol',
            currentRowClassName: 'selectedRow',
            viewportRowRenderingOffset: 1000,
            stretchH: 'all',
            copyPaste: false,
            tabMoves: { row: 0, col: 1 },
            dataSchema: this.defaultLineItemSchema,
        };
        var customSettings = this.getBreakDownSettings();
        if (hotId === 'CU_Labour') {
            customSettings['hiddenColumns'] = { columns: [0, 6, 9, 10, 11, 12] };
        }
        if (this.isHideCis) {
            customSettings['hiddenColumns']['columns'].push(2);
        }
        if (this.isHideVat || this.invoiceTaxState === false) {
            customSettings['hiddenColumns']['columns'].push(5);
            if (this.invoiceTaxState === false) {
                baseSettings['dataSchema']['taxItemId'] = null;
            }
        }
        this.spreadSheetSettings[hotId] = Object.assign({}, baseSettings, customSettings);
        hotInstance.updateSettings(this.spreadSheetSettings[hotId]);
        this.breakdownTotal();
    };
    CustomerInvoiceService.prototype.spreadsheetSettings = function () {
        switch (this.breakdown) {
            case 2:
                this.defaultLineItemSchema = { id: null, item: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid: null, itemId: '', deleteAction: '' };
                break;
            case 3:
                this.defaultLineItemSchema = { id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid: null, itemId: '', deleteAction: '' };
                break;
            case 4:
                this.defaultLineItemSchema = { id: null, pricingItem: '', cis: 'No', quantity: 1, unitprice: '0.00', taxItemId: this.lineItemDefaults['taxItemId'], vatrate: 0,
                    nominalcode: this.lineItemDefaults['nominalCodeId'], total: 0.00, totalvat: 0.00, totalcis: 0.00, categoryid: null,
                    itemId: '', deleteAction: '' };
                break;
        }
        return {};
    };
    CustomerInvoiceService.prototype.getSpreadSheetData = function (hotId) {
        if (typeof this.spreadsheetLineItems[hotId] === 'undefined') {
            this.spreadsheetLineItems[hotId] = [];
        }
        return this.spreadsheetLineItems[hotId];
    };
    CustomerInvoiceService.prototype.beforeValidate = function (value, row, prop, source, hotId) {
        if (value !== '') {
            var activeEditor = this.hotInstances[hotId].getActiveEditor(), col = activeEditor.col, removeHighlightRow = [], cellType = activeEditor.cellProperties.type;
            if (this.validateErrorRow === true) {
                removeHighlightRow[0] = { 'row': row, 'hotId': hotId };
                this.highlightErrorRows(removeHighlightRow, [hotId], false);
            }
            if (prop === 'unitprice') {
                this.numericLimitValidator(value, row, col, prop, 10, cellType, hotId);
            }
            else if (prop === 'quantity') {
                this.numericLimitValidator(value, row, col, prop, '', cellType, hotId);
            }
        }
    };
    CustomerInvoiceService.prototype.highlightErrorRows = function (errorRow, hotId, isHighlight) {
        var _this = this;
        this.validateErrorRow = true;
        for (var i = 0; i < errorRow.length; i++) {
            var currentRow = errorRow[i]['row'];
            var currentRowHotId = errorRow[i]['hotI'];
            var currentInstance = this.hotInstances[currentRowHotId];
            var currentMetaRow = currentInstance.getCellMetaAtRow(currentRow);
            for (var j = 1; j < currentMetaRow.length; j++) {
                if (isHighlight === true) {
                    currentInstance.setCellMeta(currentRow, j, 'className', 'errorRowList');
                }
                else {
                    currentInstance.setCellMeta(currentRow, j, 'className', '');
                }
            }
        }
        hotId.forEach(function (value) {
            _this.hotInstances[value].render();
        });
    };
    CustomerInvoiceService.prototype.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {
        if (value !== '') {
            var isInvalid = false;
            var data = parseFloat(value);
            if (isNaN(value)) {
                this.toastBox.show('Invalid data', 1000);
            }
            else if (limit !== '' && (data.toFixed(0).length) > limit) {
                isInvalid = true;
                this.toastBox.show('Invalid data length', 1000);
            }
            if (isInvalid) {
                this.hotInstances[hotId].setCellMeta(row, col, 'className', 'errorRowList');
            }
            else {
                this.hotInstances[hotId].setCellMeta(row, col, 'className', '');
            }
            this.hotInstances[hotId].render();
        }
    };
    CustomerInvoiceService.prototype.afterChange = function (changes, src, hotId) {
        var current_instance = this.hotInstances[hotId];
        var getSelect = current_instance.getSelected();
        var selectedRow = (typeof getSelect !== 'undefined') ? getSelect[0] : 0;
        var countRows = current_instance.countRows();
        // if (src === 'loadData' && this.spreadsheetLineItems[hotId].length === 0) {
        if (src === 'loadData') {
            current_instance.alter('insert_row', countRows);
            setTimeout(function () {
                current_instance.updateSettings({ className: 'overflow_handsontable' });
                current_instance.render();
            }, 1000);
            current_instance.render();
        }
        else if (src === 'edit') {
            this.insertRow(current_instance, changes, src);
        }
        // // $scope.spreadSheetValidation();
        current_instance.scrollViewportTo(selectedRow - 1, 1);
    };
    CustomerInvoiceService.prototype.insertRow = function (currentInstance, changes, src) {
        var selected = currentInstance.getActiveEditor(), selectedCol = selected.col, selectedRow = selected.row, rowVal = currentInstance.getSourceDataAtCell(selectedRow, '1');
        var isRowValidate = !!(selectedCol === 2 && rowVal);
        if (changes != null && changes[0][0] === (currentInstance.countRows() - 1) && src === 'edit' && isRowValidate) {
            currentInstance.alter('insert_row', currentInstance.countRows());
            currentInstance.render();
        }
    };
    /* Breakdown functions */
    CustomerInvoiceService.prototype.breakdownByCategory = function () {
        var _this = this;
        return {
            hiddenColumns: {
                columns: [0, 3, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true
                }, {
                    data: 'item',
                    validator: 'emptyCellValidator',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    className: 'overflow_handsontable select_icon',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: true,
                        ajax: {
                            url: 'getInvoiceCategory',
                            data: function (query, page) {
                                if (query === '') {
                                    return false;
                                }
                                return { 'searchText': encodeURIComponent(query) };
                            },
                            // On search ajax response
                            results: function (data, page) {
                                _this.spreadSheetViewData['items'] = data.invoiceCategories;
                                return { results: data.invoiceCategories };
                            },
                        },
                        formatSelection: function (item) {
                            return item.text;
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', readOnly: true, validator: 'emptyCellValidator'
                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, validator: 'emptyCellValidator'
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    validator: 'emptyCellValidator',
                    className: 'overflow_handsontable select_icon',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'total', type: 'numeric', readOnly: true, disableVisualSelection: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer'
                }
            ],
        };
    };
    CustomerInvoiceService.prototype.fullBreakdown = function () {
        var _this = this;
        return {
            hiddenColumns: {
                columns: [0, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true, type: 'numeric'
                }, {
                    data: 'pricingItem',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        ajax: {
                            url: 'getPricingItems',
                            data: function (query, page) {
                                if (query === '') {
                                    return false;
                                }
                                var data = {};
                                data['searchText'] = encodeURIComponent(query);
                                if (_this.jobId) {
                                    data['jobId'] = _this.jobId;
                                }
                                return data;
                            },
                            // On search ajax response
                            results: function (data, page) {
                                _this.spreadSheetViewData['pricingItems'] = data.pricingItems;
                                return { results: data.pricingItems };
                            },
                        },
                        formatSelection: function (item) {
                            return item.text;
                        },
                        createSearchChoice: function (term, data) {
                            if ($(data).filter(function () {
                                return this.text.localeCompare(term) === 0;
                            }).length === 0) {
                                return { id: term, text: term };
                            }
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0' }
                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' },
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    allowEmpty: false,
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'total', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer'
                }
            ],
        };
    };
    CustomerInvoiceService.prototype.fullBreakdownByCategory = function () {
        var _this = this;
        return {
            hiddenColumns: {
                columns: [0, 2, 6, 9, 10, 11, 12]
            },
            columns: [
                {
                    data: 'id', readonly: true, type: 'numeric'
                }, {
                    data: 'pricingItem',
                    placeholder: 'Please select',
                    allowEmpty: false,
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    className: 'overflow_handsontable select_icon',
                    editor: HansontableSelect2({
                        minimumInputLength: 2,
                        dropdownCssClass: 'handsontable-select',
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        ajax: {
                            url: 'getPricingItems',
                            data: function (query, page) {
                                if (query === '') {
                                    return false;
                                }
                                // return { 'searchText': encodeURIComponent(query) };
                                var data = {};
                                data['searchText'] = encodeURIComponent(query);
                                if (_this.jobId) {
                                    data['jobId'] = _this.jobId;
                                }
                                return data;
                            },
                            // On search ajax response
                            results: function (data, page) {
                                _this.spreadSheetViewData['pricingItems'] = data.pricingItems;
                                return { results: data.pricingItems };
                            },
                        },
                        createSearchChoice: function (term, data) {
                            if ($(data).filter(function () {
                                return this.text.localeCompare(term) === 0;
                            }).length === 0) {
                                return { id: term, text: term };
                            }
                        }
                    }),
                }, {
                    data: 'cis',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.cisOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        minimumResultsForSearch: Infinity,
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'quantity', type: 'numeric', forceNumeric: true, validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0' }
                }, {
                    data: 'unitprice', type: 'numeric', validator: 'emptyCellValidator', renderer: 'customRenderer', numericFormat: { pattern: '0.00' }
                }, {
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.formatTaxItems(this.taxItems),
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'vatrate', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'nominalcode',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon',
                    validator: 'emptyCellValidator',
                    renderer: 'customRenderer',
                    allowEmpty: false,
                    editor: HansontableSelect2({
                        editable: true,
                        data: this.nominalCodeOptions,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select'
                    })
                }, {
                    data: 'total', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }, disableVisualSelection: true
                }, {
                    data: 'totalvat', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'totalcis', type: 'numeric', readOnly: true, numericFormat: { pattern: '0.00' }
                }, {
                    data: 'categoryid', type: 'numeric', readOnly: true
                }, {
                    data: 'itemId', type: 'numeric', readOnly: true, format: '0'
                }, {
                    data: 'deleteAction', readOnly: true, disableVisualSelection: true, renderer: 'customRenderer',
                }
            ],
        };
    };
    CustomerInvoiceService.prototype.breakdownTotal = function () {
        var _this = this;
        var hotIds = Object.keys(this.hotInstances);
        this.totals['breakdown'] = [];
        this.totals['grandTotal'] = {};
        var total_price = 0, total_tax = 0, total_cis = 0, i, total_vat_rate = 0, breakdown_by_tax_rates = {}, vat_cost;
        hotIds.forEach(function (hotId) {
            var value = _this.spreadsheetLineItems[hotId];
            var sub_total_price = 0, totalCount = 0;
            for (i = 0; i < value.length; i++) {
                totalCount = ((typeof value[i] !== 'undefined' && value[i].item) || value[i].pricingItem) ? totalCount + 1 : totalCount;
                total_price += parseFloat(value[i].total);
                sub_total_price += parseFloat(value[i].total);
                total_tax += parseFloat(value[i].totalvat);
                total_cis += parseFloat(value[i].totalcis);
                if (value[i].taxItemId !== 0 && (value[i].item || value[i].pricingItem)) {
                    vat_cost = (typeof breakdown_by_tax_rates[value[i].vatrate] === 'undefined') ? 0 : breakdown_by_tax_rates[value[i].vatrate];
                    breakdown_by_tax_rates[value[i].vatrate] = vat_cost + parseFloat(value[i].totalvat);
                }
                total_vat_rate += (value[i].totalvat > 0) ? parseFloat(value[i].vatrate) : 0;
            }
            _this.totals['breakdown'][hotId] = { 'total': _this.roundup(sub_total_price, 2), 'lineItemCount': totalCount };
        });
        this.totals['grandTotal']['total_price'] = this.roundup(total_price, 2);
        this.totals['grandTotal']['total_tax'] = total_tax ? this.roundup(total_tax, 2) : 0;
        this.totals['grandTotal']['total_cis'] = (total_cis) ? this.roundup(total_cis, 2) : 0;
        this.totals['grandTotal']['breakdown_by_tax_rates'] = breakdown_by_tax_rates;
        this.totalEmitterSource.next(this.totals);
    };
    CustomerInvoiceService.prototype.formatSpreadsheetData = function () {
        var _this = this;
        // let hotIds = [];
        // if (this.breakdown === 4) {
        //     hotIds = _.pluck(this.spreadsheetLineItems, 'spreadsheetHotID');
        // } else {
        //      hotIds.push(this.invoiceBreakdownTypeName[this.breakdown]);
        // }
        var hotIds = Object.keys(this.spreadsheetLineItems);
        var lineItems = [];
        var lineItem = {};
        var position = 1;
        hotIds.forEach(function (hotId) {
            var spreadsheetLineItems = _this.spreadsheetLineItems[hotId];
            var spreadSheetLineItem = {};
            if (_this.breakdown === 2) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function (v) { return v.item !== ''; });
            }
            else if (_this.breakdown === 3) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function (v) { return v.pricingItem !== ''; });
            }
            else if (_this.breakdown === 4) {
                spreadsheetLineItems = _.filter(spreadsheetLineItems, function (v) { return v.pricingItem !== '' && v.categoryid !== null; });
            }
            for (var i = 0; i < spreadsheetLineItems.length; i++) {
                spreadSheetLineItem = spreadsheetLineItems[i];
                lineItem = { id: spreadSheetLineItem['id'],
                    cis: spreadSheetLineItem['cis'],
                    quantity: spreadSheetLineItem['quantity'],
                    unitprice: spreadSheetLineItem['unitprice'],
                    taxItemId: spreadSheetLineItem['taxItemId'],
                    vatrate: spreadSheetLineItem['vatrate'],
                    nominalcode: spreadSheetLineItem['nominalcode'],
                    categoryid: spreadSheetLineItem['categoryid'],
                    descriptionId: spreadSheetLineItem['itemId'],
                    position: position++ };
                if (_this.breakdown === 2) {
                    lineItem['description'] = spreadSheetLineItem['item'];
                }
                else {
                    lineItem['description'] = spreadSheetLineItem['pricingItem'];
                }
                if (spreadSheetLineItem['nominalcode'] == '') {
                    return [];
                }
                else {
                    lineItems.push(lineItem);
                }
            }
        });
        return lineItems;
    };
    CustomerInvoiceService.prototype.addLineItemsFromSidepanel = function (data) {
        var _this = this;
        var hotId = this.invoiceBreakdownTypeName[this.breakdown];
        data.forEach(function (value, key) {
            var lineItem = Object.assign({}, _this.defaultLineItemSchema);
            var description = value['description'];
            var unitprice = parseFloat(value['price']) * parseFloat(value['quantity']);
            var nominalCodeId = value['nominalCodeId'];
            if (_this.breakdown === 2) {
                var categoryLineItemIndex = _.findIndex(_this.spreadsheetLineItems[hotId], { 'itemId': parseInt(value['categoryId']) });
                if (categoryLineItemIndex > -1) {
                    var existingUnitprice = _this.spreadsheetLineItems[hotId][categoryLineItemIndex]['unitprice'];
                    _this.spreadsheetLineItems[hotId][categoryLineItemIndex]['unitprice'] = parseFloat(existingUnitprice) + unitprice;
                }
                else {
                    var dataSchema = Object.assign({}, _this.defaultLineItemSchema);
                    _this.spreadsheetLineItems[hotId].push(dataSchema);
                    var emptyColumnIndex = _.findIndex(_this.spreadsheetLineItems[hotId], { 'item': '' });
                    var categoryIndex = _.findIndex(_this.invoiceItemCategories, { 'spreadsheet_categoryId': parseInt(value['categoryId']) });
                    if (categoryIndex > -1) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['item'] = _this.invoiceItemCategories[categoryIndex]['spreadsheet_category'];
                    }
                    if (nominalCodeId) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                    }
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['itemId'] = parseInt(value['categoryId']);
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = unitprice;
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['type'];
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['breakdownId'] = value['categoryId'];
                    if (value['taxRateID'] == null) {
                        if (value['taxItemId']) {
                            _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                        }
                    }
                    else if (value['taxRateID'] != null) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                    }
                }
            }
            else if (_this.breakdown === 3) {
                var emptyColumnIndex = _.findIndex(_this.spreadsheetLineItems[hotId], { 'pricingItem': '' });
                _this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = value['price'];
                _this.spreadsheetLineItems[hotId][emptyColumnIndex]['quantity'] = value['quantity'];
                _this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['description'];
                _this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];
                if (value['taxRateID'] == null) {
                    if (value['taxItemId']) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                    }
                }
                else if (value['taxRateID'] != null) {
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                }
                if (nominalCodeId) {
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                }
                _this.spreadsheetLineItems[hotId].push(lineItem);
            }
            else if (_this.breakdown === 4) {
                lineItem['invoiceItemCategoryId'] = value['invoiceItemCategoryId'];
                var hot = _.findWhere(_this.invoiceItemCategories, { spreadsheet_categoryId: parseInt(value['categoryId']) });
                if (hot && hot['chosen']) {
                    hotId = hot['spreadsheetHotID'];
                    var emptyColumnIndex = _.findIndex(_this.spreadsheetLineItems[hotId], { 'pricingItem': '' });
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['unitprice'] = value['price'];
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['quantity'] = value['quantity'];
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['pricingItem'] = value['description'];
                    _this.spreadsheetLineItems[hotId][emptyColumnIndex]['line_item_id'] = value['line_item_id'];
                    if (value['taxRateID'] == null) {
                        if (value['taxItemId']) {
                            _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxItemId'];
                        }
                    }
                    else if (value['taxRateID'] != null) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['taxItemId'] = value['taxRateID'];
                    }
                    if (nominalCodeId) {
                        _this.spreadsheetLineItems[hotId][emptyColumnIndex]['nominalcode'] = nominalCodeId;
                    }
                    _this.spreadsheetLineItems[hotId].push(lineItem);
                    _this.hotInstances[hotId].render();
                }
            }
        });
        this.hotInstances[hotId].render();
    };
    CustomerInvoiceService.prototype.getBreakDownSettings = function () {
        switch (this.breakdown) {
            case 2:
                return this.breakdownByCategory();
            case 3:
                return this.fullBreakdown();
            case 4:
                return this.fullBreakdownByCategory();
        }
    };
    CustomerInvoiceService.prototype.getInvoiceTaxState = function () {
        return this.invoiceTaxState;
    };
    CustomerInvoiceService.prototype.roundup = function (num, precision) {
        var base = Math.pow(10, precision);
        return (Math.round(num * base) / base).toFixed(precision);
    };
    CustomerInvoiceService.prototype.changingTaxList = function (taxitemNew) {
        this.taxItems = [];
        this.taxItems = taxitemNew;
    };
    CustomerInvoiceService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerInvoiceService_Factory() { return new CustomerInvoiceService(i0.inject(i1.CsToastBoxService), i0.inject(i2.HotTableRegisterer), i0.inject("confirmationBoxHelper"), i0.inject("$scope")); }, token: CustomerInvoiceService, providedIn: "root" });
    return CustomerInvoiceService;
}());
export { CustomerInvoiceService };
