import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Third party
import {HotTableModule} from '@handsontable/angular';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {BsDropdownModule, BsModalRef, ModalModule, PopoverModule, PopoverConfig} from 'ngx-bootstrap';
import {SidepanelModule} from '@app/sidepanel/sidepanel.module';
import {TimepickerModule, TimepickerConfig} from 'ngx-bootstrap/timepicker';


import {DirectivesModule} from '@app/directives';
import {PipesModule} from '@app/pipes';
import {TranslateModule} from '@ngx-translate/core';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {TimelineDatePipe} from '@app/pipes/timeline-date.pipe';
import {CommunicationMappingModule} from "@app/shared/communication-mapping/communication-mapping.module";
import { CommonFolderStructureModule } from './common-folder-structure/common-folder-structure.module';
import {WorkflowCommonModule} from "@app/workflow-common/workflow-common.module";
import {TimelineModule} from "@app/shared/timeline/timeline.module";
import {CommunicationModalModule} from "@app/shared/communication-modals/communication-modal.module";
import {AttachedFilesModule} from "@app/shared/attached-files/attached-files.module";
import {ConfirmationTemplatesModule} from "@app/shared/confirmation-templates/confirmation-templates.module";
import {CreditCardFormModule} from "@app/shared/credit-card-form/credit-card-form.module";
import {DynamicCertificateFormModule} from "@app/shared/dynamic-certificate-form/dynamic-certificate-form.module";
import {IconListModule} from "@app/shared/icon-list/icon-list.module";
import {MicroserviceModule} from "@app/shared/microservice/microservice.module";
import {ProgressSidePanelModule} from "@app/shared/progress-side-panel/progress-side-panel.module";
import {RoundProgressModule} from "@app/shared/round-progress/round-progress.module";
import {
    ServiceReminderCommunicationModule
} from "@app/shared/service-reminder-communication/service-reminder-communication.module";
import {OpportunityModalsModule} from "@app/shared/opportunity-modals/opportunity-modals.module";
import {SmartTableModule} from "@app/smart_table";
import {
    DynamicCertificateFormParserModule
} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser.module";
import {SortableModule} from "@app/sortable/sortable.module";
import {CsActivityLogModule} from "@app/shared/cs-activity-log/cs-activity-log.module";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";


@NgModule({
    // declarations: [
    //
    // ],
    // imports: [
    //     CommonModule,
    //     FormsModule,
    //     ReactiveFormsModule,
    //
    //     // Angular materials
    //
    //     // Third party
    //     HotTableModule,
    //     CarouselModule,
    //     TooltipModule,
    //     BsDatepickerModule,
    //     TimepickerModule.forRoot(),
    //     AccordionModule,
    //     TranslateModule,
    //     ModalModule,
    //     SlickCarouselModule,
    //     DirectivesModule,
    //     PipesModule,
    //     SidepanelModule,
    //     BsDropdownModule,
    //     RouterModule,
    //     CommunicationMappingModule,
    //     PopoverModule,
    //     WorkflowCommonModule,
    //     BaseCommonModule,
    //     TimelineModule,
    //     CommunicationModalModule,
    //     AttachedFilesModule,
    //     CommonFolderStructureModule,
    //     ConfirmationTemplatesModule,
    //     CreditCardFormModule,
    //     DynamicCertificateFormModule,
    //     IconListModule,
    //     MicroserviceModule,
    //     ProgressSidePanelModule,
    //     RoundProgressModule,
    //     ServiceReminderCommunicationModule,
    //     OpportunityModalsModule,
    //     SmartTableModule,
    //     DynamicCertificateFormParserModule,
    //     SidepanelModule,
    //     SortableModule
    //     ],

    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        // Angular materials

        // Third party
        HotTableModule,
        CarouselModule,
        TooltipModule,
        BsDatepickerModule,
        TimepickerModule,
        AccordionModule,
        TranslateModule,
        SlickCarouselModule,
        DirectivesModule,
        PipesModule,
        BsDropdownModule,
        CommunicationMappingModule,
        WorkflowCommonModule,
        TimelineModule,
        CommunicationModalModule,
        AttachedFilesModule,
        CommonFolderStructureModule,
        ConfirmationTemplatesModule,
        CreditCardFormModule,
        DynamicCertificateFormModule,
        IconListModule,
        MicroserviceModule,
        ProgressSidePanelModule,
        RoundProgressModule,
        ServiceReminderCommunicationModule,
        OpportunityModalsModule,
        SmartTableModule,
        DynamicCertificateFormParserModule,
        SidepanelModule,
        SortableModule,
        CsComponentsModule,
        CsActivityLogModule
   ],
    providers: [
        TimepickerConfig,
        BsModalRef,
        TimelineDatePipe,
        PopoverConfig
    ]
})
export class AppCommonModule { }

