import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SessionLogService = /** @class */ (function (_super) {
    tslib_1.__extends(SessionLogService, _super);
    function SessionLogService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    SessionLogService.prototype.sessionStartTime = function (time) {
        this.startTime = time;
    };
    SessionLogService.prototype.sessionEndTime = function (time) {
        this.endTime = time;
    };
    SessionLogService.prototype.getDuration = function () {
        if (typeof this.endTime === 'number' && typeof this.startTime === 'number') {
            return this.endTime - this.startTime;
        }
        else {
            return null;
        }
    };
    //send data backend
    SessionLogService.prototype.addToSessionLog = function (clientId, username, url, timeTaken) {
        if (clientId === void 0) { clientId = ''; }
        if (username === void 0) { username = ''; }
        var object = {
            'clientId': clientId,
            'username': username,
            'url': url,
            'timeTaken': timeTaken.toString(),
        };
        return this.http.post(this.getApiUrl("add_to_sessionlog"), object);
    };
    SessionLogService.ngInjectableDef = i0.defineInjectable({ factory: function SessionLogService_Factory() { return new SessionLogService(i0.inject(i1.HttpClient)); }, token: SessionLogService, providedIn: "root" });
    return SessionLogService;
}(InterfaceService));
export { SessionLogService };
