<a (click)="openDropDown()" href="javascript:void(0);" class="dropdown-toggle">
    <img class="selected-country-image" src="../adminimages/flags/{{selectedCountryCode}}.png">
    <span class="cs-chevron up">&nbsp;</span>
    <span class="cs-chevron down">&nbsp;</span>
</a>

<span class="selected-area-code">
    <span class="code">+{{selectedCountryCode}}:</span>
</span>
<input type="hidden" name="{{labelName}}" value="{{selectedCountryCode}}">
<div class="dropdown-menu custom-dropdown small" style="width: 445px;">
    <div class="dropdown-inner">
        <div class="result-collection">
            <ul>
                <li *ngFor="let country of countries | keyvalue"
                    (click)="selectCountry(country.key)">
                    <span style="float: left; width: 20px;" class="area-code">+{{country.key}}</span>
                    <img src="../adminimages/flags/{{country.key}}.png">
                    <span class="country-name" style="width: auto !important;">
                        <span
                            *ngFor="let name of country.value['data'];  let
                            last = last; let first = first;">{{first? '' :
                            last ? ' / ' : ' / '}}{{name.name}}</span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
