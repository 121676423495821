import { EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
var ChatmessageBlockComponent = /** @class */ (function () {
    function ChatmessageBlockComponent(confirmationBoxHelper, ajsScope) {
        var _this = this;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.name = 'chatmessageBlock';
        this.activeField = {};
        this.isConditional = false;
        this.isEdit = false;
        this.isMarketPlaceView = false;
        this.taggingList = {};
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.channelName = null;
        this.mentionContent = '';
        this.messageDelta = [];
        this.csTaggingList = [];
        this.modules = {
            mention: {
                mentionListClass: "ql-mention-list mat-elevation-z8",
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                showDenotationChar: true,
                spaceAfterInsert: true,
                onSelect: function (item, insertItem) {
                    // const editor = this.editor.quillEditor as Quill;
                    if (item.denotationChar === '[') {
                        item['value'] = item['value'] + ']';
                    }
                    insertItem(item);
                },
                mentionDenotationChars: ["["],
                source: function (searchTerm, renderList, mentionChar) {
                    var values = [];
                    if (mentionChar === '[') {
                        values = _this.csTaggingList;
                    }
                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                    }
                    else {
                        var matches = [];
                        for (var i = 0; i < values.length; i++)
                            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                                matches.push(values[i]);
                        renderList(matches, searchTerm);
                    }
                }
            },
            toolbar: false
        };
    }
    ChatmessageBlockComponent.prototype.ngOnInit = function () {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.getCsTaggingList();
        this.channelName = this.inputModel.model.channelName;
    };
    ChatmessageBlockComponent.prototype.ngAfterViewInit = function () {
        if (this.isEdit) {
            //console.log('isEdit', this.inputModel)
            var _a = this.inputModel.model, channelName = _a.channelName, messageDelta = _a.messageDelta;
            this.messageDelta = messageDelta ? JSON.parse(messageDelta) : [];
            this.channelName = channelName;
            this.editor.quillEditor.setContents(this.messageDelta);
        }
    };
    ChatmessageBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation('You are about to delete a task. Are you sure you wish to do this?', this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    ChatmessageBlockComponent.prototype.onChange = function (event) {
        if (!event.html) {
            this.messageDelta = null;
            this.mentionContent = '';
            return;
        }
        var delta = event.content;
        this.messageDelta = JSON.stringify(delta);
        this.mentionContent = event.html;
    };
    ChatmessageBlockComponent.prototype.addValue = function () {
        this.inputModel.model.channelName = this.channelName;
        this.inputModel.model.textMessage = this.mentionContent.replace(/(<([^>]+)>)/gi, "");
        this.inputModel.model.messageDelta = this.messageDelta;
        //console.log('save',this.inputModel.model);
        this.outputModel.emit(this.inputModel);
    };
    ChatmessageBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    ChatmessageBlockComponent.prototype.getCsTaggingList = function () {
        var _this = this;
        var cstagKeys = [];
        if (this.mainObjectName === 'job') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if (this.mainObjectName === 'invoice') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice'];
        }
        else if (this.mainObjectName === 'diaryEvents') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'diary_events'];
        }
        else if (this.mainObjectName === 'certificates') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        var __csTaggingList = [];
        cstagKeys.forEach(function (tagName) {
            var tagNameList = Object.values(_this.taggingList[tagName]);
            __csTaggingList = __csTaggingList.concat(tagNameList);
        });
        this.csTaggingList = __csTaggingList.map(function (item) {
            return { id: item, value: item };
        });
        return this.csTaggingList;
    };
    return ChatmessageBlockComponent;
}());
export { ChatmessageBlockComponent };
