import { ElementRef, OnInit, EventEmitter } from '@angular/core';
var CsSignatureGeneratorComponent = /** @class */ (function () {
    function CsSignatureGeneratorComponent() {
        this.base64Image = "";
        this.currentIndex = -1;
        this.signatureText = 'Commusoft';
        this.dataSource = dataSource;
        this.imageHeight = "200";
        this.imageWidth = "200";
        this.showSignature = false;
        this.signatureAsBase64 = new EventEmitter();
    }
    CsSignatureGeneratorComponent.prototype.ngOnInit = function () { };
    CsSignatureGeneratorComponent.prototype.ngAfterViewInit = function () { };
    CsSignatureGeneratorComponent.prototype.onSignatureSelection = function (data, index) {
        this.currentIndex = index;
        this.generateSignature(data.FontSize, data.FontFamily, data.FontWeight);
    };
    CsSignatureGeneratorComponent.prototype.generateSignature = function (fontSize, fontFamily, fontWeight) {
        this.context = this.canvasEl
            .nativeElement.getContext("2d");
        this.context.font = " " + fontSize + " " + fontFamily;
        this.context.textBaseline = "middle";
        this.context.textAlign = "center";
        this.context.imageSmoothingQuality = "high";
        this.context.imageSmoothingEnabled = true;
        this.context.lineWidth = 15;
        this.context.fillStyle = "black";
        var x = this.canvasEl.nativeElement.width / 2;
        var y = this.canvasEl.nativeElement.height / 2;
        this.context.fillText(this.signatureText, x, y);
        if (this.context) {
            this.base64Image = this.canvasEl
                .nativeElement.toDataURL("image/png");
            var canvasWidth = this.canvasEl.nativeElement
                .width;
            var canvasHeight = this.canvasEl.nativeElement
                .height;
            this.context.clearRect(0, 0, canvasWidth, canvasHeight);
            this.signatureAsBase64.emit(this.base64Image);
        }
    };
    return CsSignatureGeneratorComponent;
}());
export { CsSignatureGeneratorComponent };
var dataSource = [
    {
        FontFamily: "Sacramento",
        FontSize: "30px",
        FontWeight: "bold"
    },
    {
        FontFamily: "Satisfy",
        FontSize: "30px",
        FontWeight: "bold"
    },
    {
        FontFamily: "Pacifico",
        FontSize: "30px",
        FontWeight: "bold"
    },
    {
        FontFamily: "Gloria Hallelujah",
        FontSize: "30px",
        FontWeight: "bold"
    },
    {
        FontFamily: "Lobster",
        FontSize: "30px",
        FontWeight: "bold"
    },
    {
        FontFamily: "Dancing Script",
        FontSize: "30px",
        FontWeight: "bold"
    },
];
