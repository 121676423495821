import { Directive, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceTimer]',
  host: {
    '(change)': 'emitValue($event.target.value)'
  }
})
export class DebounceTimerDirective {
  debounceTime: number = 400;

  @Input('appDebounceTimer')
  set interval(interval: number) {
    this.debounceTime = interval || 400;
  }

  @Output('valueChanged') valueEmitter: EventEmitter<any> = new EventEmitter();
  @Output('inputElement') inputElement: EventEmitter<any> = new EventEmitter();

  constructor(private _elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.inputElement.emit(this._elementRef);
    // Listener for input keyup event with delay time
    fromEvent(this._elementRef.nativeElement, 'keyup')
      .pipe(
        debounceTime(this.debounceTime),
        tap(event => this.emitValue((<any>event).target.value))
      ).subscribe();
  }

  // Emit modal value to outside world
  emitValue(value) {
    this.valueEmitter.emit(value);
  }
}