import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
var CommunicationContainerComponent = /** @class */ (function () {
    function CommunicationContainerComponent(modalRef, eventDispatcher) {
        this.modalRef = modalRef;
        this.eventDispatcher = eventDispatcher;
        this.categoryType = 'communication';
        this.objectName = '';
        this.objectId = null; //tablePkId
        this.objectTypeId = ''; //tableType
        this.disabledAction = true;
        this.unSaveConfirmation = false;
        this.title = '';
        this.selectedTemplateData = [];
        this.helpText = '';
    }
    CommunicationContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                if (action == CommunicationDispatcherService.CONSTANTS['checkIsValidForm']) {
                    _this.disabledAction = !data['isValid'];
                }
                else if (action == CommunicationDispatcherService.CONSTANTS['beforeSaveAction']) {
                    _this.disabledAction = true;
                }
                else if (action == CommunicationDispatcherService.CONSTANTS['afterSaveAction']) {
                    _this.onClose.next('saveAndClosed');
                    _this.modalRef.hide();
                }
                else if (action == CommunicationDispatcherService.CONSTANTS['inlineSaveAction']) {
                    _this.onClose.next(data['formData']);
                    _this.modalRef.hide();
                }
                else if (action === CommunicationDispatcherService.CONSTANTS['close']) {
                    _this.onClose.next('closed');
                    _this.modalRef.hide();
                }
            }
        });
    };
    CommunicationContainerComponent.prototype.ngOnDestroy = function () {
        console.info('destroyed/unsubscribe full screen container');
        if (this.subscription)
            this.subscription.unsubscribe();
    };
    CommunicationContainerComponent.prototype.setLayoutComponent = function (component) {
        // console.log('setLayoutComponent', component);
        this.communicationLayoutComponent = component;
    };
    CommunicationContainerComponent.prototype.saveCommunication = function () {
        this.communicationLayoutComponent.saveCommunication();
    };
    CommunicationContainerComponent.prototype.closeModal = function () {
        if (this.unSaveConfirmation) {
        }
        this.modalRef.hide();
    };
    return CommunicationContainerComponent;
}());
export { CommunicationContainerComponent };
