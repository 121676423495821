import { Component, OnInit } from '@angular/core';
import {mxgraph} from "mxgraph-factory";
import { Subject } from "rxjs/index";
import {BsModalRef } from "ngx-bootstrap";
import * as moment from 'moment';
import { WorkflowhttpService } from "../services/workflowhttp.service";

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

@Component({
    selector: 'app-history-timeline-panel',
    templateUrl: './history-timeline-panel.component.html',
    styles: []
})

export class HistoryTimelinePanelComponent implements OnInit {

    public onClose: Subject<any>;
    taskName:string;
    showSuccessStatus:boolean = false;
    showFailedStatus:boolean = false;
    showSkippedStatus:boolean = false;
    isTaskOnSameDate:boolean = false;
    startDate: any;
    endDate:any;
    communicationId:any;

    //sidepanel data
    cell: mxgraph.mxCell;
    mxGraph: mxgraph.mxGraph;
    title: string;
    taskItem:any;
    fromEmail:any;
    ccEmail:any;
    bccEmail:any;
    toEmail:any;
    subject:any;
    message:any;
    sentBy:any;
    status:any;
    showFullDetails:boolean = false;
    emailHidden:boolean = false;
    smsHidden:boolean = false;
    showLessDetails:boolean =false;
    taskDetails:any;
    constructor(public modalRef: BsModalRef,
                private dataService: WorkflowhttpService) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.showSuccessStatus = this.taskItem['status'] === 'success';
        this.showFailedStatus = this.taskItem['status'] === 'failed';
        this.showSkippedStatus = this.taskItem['status'] === 'skipped';
        this.communicationId = this.taskItem['taskParams']["communicationId"];
        this.title = capitalize(this.cell['block_name']);
        if(this.title == 'EmailBlock' || this.title == 'SmsBlock'){
            this.showFullDetails = true;
        }
        this.startDate = moment.utc( this.taskItem['startDate']['date'] ).local();
        if(this.taskItem['endDate']) {
            this.endDate = moment.utc( this.taskItem['endDate']['date'] ).local();
        }
        if(this.endDate) {
            this.isTaskOnSameDate = this.startDate.format('YYYY-MM-DD') === this.endDate.format('YYYY-MM-DD');
        }
        console.log(this.taskItem)
    }

    viewFullDetails(id : number){
        if(this.title == 'EmailBlock') {
            if(id) {
                this.dataService.getCommunicationEmailDetails(id).subscribe((resp) => {
                    if (!resp) return;
                    this.showFullDetails = false;
                    this.emailHidden = true;
                    this.showLessDetails = true;
                    this.fromEmail = resp.fromEmail;
                    this.toEmail = resp.toEmail;
                    this.ccEmail = resp.ccEmail;
                    this.bccEmail = resp.bccEmail;
                    this.subject = resp.subject;
                    this.message = resp.message;
                    this.sentBy = resp.sentBy;
                    this.status = resp.status;
                });
            }
        }else if(this.title == 'SmsBlock') {
            this.showFullDetails = false;
            this.showLessDetails = true;
            this.smsHidden = true;
            this.taskDetails = this.taskItem.taskParams.status.status;
            this.message = this.taskItem.taskParams.status.message;
        }
    }

    viewLessDetails(){
        this.showFullDetails = true;
        this.showLessDetails = false;
        if(this.title == 'EmailBlock') {
            this.emailHidden = false;
        }else if(this.title == 'SmsBlock') {
            this.smsHidden = false;
        }
    }

}

