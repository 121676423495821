import { OnInit } from '@angular/core';
var CommunicationSegmentComponent = /** @class */ (function () {
    function CommunicationSegmentComponent() {
        this.activeSegment = false;
    }
    CommunicationSegmentComponent.prototype.ngOnInit = function () {
    };
    CommunicationSegmentComponent.prototype.showNextSegment = function () {
        this.activeSegment = !this.activeSegment;
    };
    return CommunicationSegmentComponent;
}());
export { CommunicationSegmentComponent };
