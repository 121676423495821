import {Component, Inject, OnDestroy, OnInit, KeyValueDiffers} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import {EventDispatcherService} from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import {TranslateService} from "@ngx-translate/core";
import {FollowUpInputModel, InputModel} from "@app/shared/service-reminder-communication/services/input-model";
import {ScHttpServiceService} from "@app/shared/service-reminder-communication/services/sc-http-service.service";

@Component({
  selector: 'app-sc-container',
  templateUrl: './sc-container.component.html',
  styles: []
})
export class ScContainerComponent implements OnInit, OnDestroy {

    public onClose: Subject<any>;
    subscription: Subscription;

    title: string = '';
    objectName: string = '';
    unSaveConfirmation: boolean = false;
    disabledAction: boolean = true;
    formInput: any = {};
    screenType: string | 'contract' | 'standard' ;
    deletedItems: number[] = [];
    helpText:string = '';

    /* primary of Service reminder ID or ServiceJob ID on contract template */
    sourceId: number = 0;

    saving: boolean = false;
    private _reservedSinglePropertyInput:any = {};
    private _reservedMultiplePropertyInput:any = {};
    selectedTemplateData: any = [];
    viewMode: boolean;

    constructor(
        public modalRef: BsModalRef,
        private eventDispatcher: EventDispatcherService,
        private translate: TranslateService,
        private differs: KeyValueDiffers,
        private httpService: ScHttpServiceService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,

    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: (data: object) => {
                if(typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                const value = data['data'];
                if(action === EventDispatcherService.CONSTANTS['isValidForm']) {
                    this.disabledAction = !value['isValidForm'];
                }
                else if(action === EventDispatcherService.CONSTANTS['dispatchUpdatedForm']) {
                    this.formInput = value;
                }
                else if(action === EventDispatcherService.CONSTANTS['close']) {
                    this.onClose.next('closed');
                    this.modalRef.hide();
                }
                else if(action === EventDispatcherService.CONSTANTS['dispatchReservedData']) {
                    this._reservedSinglePropertyInput = Object.assign({}, value['reservedSinglePropertyInput']);
                    this._reservedMultiplePropertyInput = Object.assign({}, value['reservedMultiplePropertyInput']);
                }
                else if (action === EventDispatcherService.CONSTANTS['unSavedChanges']) {
                    this.unSaveConfirmation = true;
                }
                else if (action === EventDispatcherService.CONSTANTS['pushDeletedItem']) {
                    if(this.deletedItems.indexOf(value['deletedItem']) == -1) {
                        this.deletedItems.push(value['deletedItem'])
                    }
                }
                else if(action === EventDispatcherService.CONSTANTS['onSaveAction']) {
                    this.saveAction();
                }
            }
        })
    }

    closeModal() {
        if(this.unSaveConfirmation) {
            this.confirmationBoxHelper.getConfirmation(this.translate.instant('Before.Close.Save.Warning.Message'), this.ajsScope)
                .then( () => {
                    this.unSaveConfirmation = false;
                    this.saveAction();
                })
                .catch( () => {
                    this.unSaveConfirmation = false;
                    this.modalRef.hide();
                })
        }
        else {
            this.onClose.next('closed');
            this.modalRef.hide();
        }
    }

    prepareFormData(formInput: any) {
        if(typeof formInput.interval != "number") {
            return [];
        }
        let formData = [];
        const make = (formInput:any) => {
            let formDataMock = [
                'sourceId',
                'serviceReminderConfigId',
                'communicationId',
                'name',
                'propertyType',
                'interval',
                'messages',
                'screenType',
                'isFollowUpItem',
                'followUpOrder',
                '_intervalOld',
                '_followUpOrderOld'
            ];
            let tmpObj = {};
            formDataMock.forEach( (keyName:string) => {
                if( typeof formInput[keyName] != "object" && typeof formInput[keyName] != undefined) {
                    tmpObj[keyName] = formInput[keyName];
                }
                else if( typeof formInput[keyName] == "object" &&  formInput[keyName] == null) {
                    tmpObj[keyName] = null;
                }
                else if (keyName == 'messages') {
                    // ['email', 'sms', 'letter'].forEach( (comType:string) => {
                    //     if(formInput[keyName][comType]) {
                    //         formInput[keyName][comType]['message'];
                    //         formInput[keyName][comType]['subject'];
                    //     }
                    // });
                    tmpObj[keyName] = formInput[keyName];
                }
            });
            formData.push(tmpObj);
            if(Array.isArray(formInput.followUpMessage) && formInput.followUpMessage.length > 0) {
                formInput.followUpMessage.forEach( (followUpItem: FollowUpInputModel) => {
                    make(followUpItem);
                });
            }
        };
        make(formInput);
        return formData;
    }


    /*makeNewFormData(oldModel: any[], newModel: any[]) {
        if(oldModel.length == 0) {
            return newModel;
        }
        let newFormData = [], deletedItems = [];
        oldModel.forEach( (item) => {
            let serviceReminderConfigId = item['serviceReminderConfigId'];
            let target = newModel.find( (newItem) => newItem['serviceReminderConfigId'] ===  serviceReminderConfigId);
            if(!target) {
                deletedItems.push(serviceReminderConfigId);
            }
            if(target['interval'] && item['interval'] != target['interval']) {
                ['email', 'sms'].forEach( (msgType) => {
                    if(!target['messages'][msgType]['templateId'] || (target['messages'][msgType] && target['messages'][msgType]['templateId'] == item['messages'][msgType]['templateId']) ) {
                        delete target['messages'][msgType];
                    }
                });
                newFormData.push(target);
            }
            else if(target['interval'] && item['interval'] == target['interval']
                && ( (target['messages']['email']
                    && target['messages']['email']['templateId']
                    && target['messages']['email']['templateId'] != item['messages']['email']['templateId'])
                    || (target['messages']['sms']
                    && target['messages']['sms']['templateId']
                    && target['messages']['sms']['templateId'] != item['messages']['sms']['templateId'])
                )
            ) {
                newFormData.push(target);
            }
        });
        return { newFormData, deletedItems };
    }*/


    saveAction() {
        let singleInputModel = this.prepareFormData(JSON.parse(JSON.stringify(this.formInput['singlePropertyInputModel'])));
        let multipleInputModel = this.prepareFormData( JSON.parse(JSON.stringify(this.formInput['multiplePropertyInputModel'])));
        const formData = singleInputModel.concat(multipleInputModel);
        const serviceReminderConfig = {
            formData,
            deletedItems: this.deletedItems,
            sourceId: this.sourceId
        };
        console.log('form', serviceReminderConfig);
        if(!this.sourceId) {
            this.onClose.next({serviceReminderConfig: formData})
            this.modalRef.hide();
        }
        else {
            this.saving=true;
            this.httpService.saveServiceReminderConfig({serviceReminderConfig}, this.screenType)
            .subscribe( {
                next: (resp:any) => {
                    if(resp['serviceReminderConfig']) {
                        this.modalRef.hide();
                    }
                    else {
                        this.saving = false;
                    }
                }
            })
        }
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
        this.onClose.complete();
    }

}
