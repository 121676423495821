import { EventEmitter, OnInit } from '@angular/core';
var DynamicCertificateSignatureSidePanelComponent = /** @class */ (function () {
    function DynamicCertificateSignatureSidePanelComponent() {
        this.signatureText = '';
        this.base64Image = '';
        this.showSignatureSidePanel = false;
        this.onSignatureGenerate = new EventEmitter();
        this.closeEmitter = new EventEmitter();
        this.screenFor = 0;
    }
    DynamicCertificateSignatureSidePanelComponent.prototype.ngOnInit = function () {
    };
    DynamicCertificateSignatureSidePanelComponent.prototype.onSidepanelClose = function () {
        this.showSignatureSidePanel = false;
        this.closeEmitter.emit(false);
        this.signatureText = '';
    };
    DynamicCertificateSignatureSidePanelComponent.prototype.getSignature = function (base64Image) {
        this.base64Image = base64Image;
    };
    DynamicCertificateSignatureSidePanelComponent.prototype.insertSignature = function () {
        this.onSignatureGenerate.emit(this.base64Image);
        this.signatureText = '';
    };
    return DynamicCertificateSignatureSidePanelComponent;
}());
export { DynamicCertificateSignatureSidePanelComponent };
