<div class="modal-header">
	<h4 class="modal-title pull-left" translate>{{title}}</h4>
</div>
<div class="modal-body">
	<div class="help_section">
		<div class="help-text" translate>

		</div>
	</div>
	<div class="body-content-section">
		<div class="margin-box margin-text ">
			<div class="top-flex">
				<!-- <div> -->
				<p>MARGIN</p>
				<span style="cursor: pointer;margin-left: 22%;" (click)="incDecValue('marginTop', 'dec')">
					<i class="fas fa-angle-left"></i>
				</span>
				<div style="margin: 0% 8%;" class="value" (click)="editValue(marginTop)">
					<input type="text" [(ngModel)]="marginTop">
				</div>
				<span style="cursor: pointer;" (click)="incDecValue('marginTop', 'inc')">
					<i class="fas fa-angle-right"></i>
				</span>
				<!-- </div> -->
			</div>
			<div class="center-flex">
				<div class="left-center-flex">
					<div style="margin-top: 95%;width: 100%;text-align: center">
						<span style="cursor: pointer;" (click)="incDecValue('marginLeft', 'inc')">
							<i class="fas fa-angle-up"></i>
						</span>
						<div class="value" style="margin: 40% 0%;" (click)="editValue(marginLeft)">
							<input type="text" [(ngModel)]="marginLeft">
						</div>
						<span style="cursor: pointer;" (click)="incDecValue('marginLeft', 'dec')">
							<i class="fas fa-angle-down"></i>
						</span>
					</div>
				</div>
				<div class="padding-box padding-text">
					<div class="top-padding-box">
						<!-- <div> -->
						<p>PADDING</p>
						<span style="cursor: pointer;margin-left: 13%;" (click)="incDecValue('paddingTop', 'dec')">
							<i class="fas fa-angle-left"></i>
						</span>
						<div class="value" style="margin:0% 10%" (click)="editValue(paddingTop)">
							<input type="text" [(ngModel)]="paddingTop">
						</div>
						<span style="cursor:pointer;" (click)="incDecValue('paddingTop', 'inc')">
							<i class="fas fa-angle-right"></i>
						</span>
						<!-- </div> -->
					</div>
					<div class="center-padding-flex">
						<div class="left-center-flex">
							<div style="width: 100%;text-align: center">
								<span style="cursor: pointer;" (click)="incDecValue('paddingLeft', 'inc')">
									<i class="fas fa-angle-up"></i>
								</span>
								<div class="value" style="margin: 50% 0%;" (click)="editValue(paddingLeft)">
									<input type="text" [(ngModel)]="paddingLeft">
								</div>
								<span style="cursor: pointer;" (click)="incDecValue('paddingLeft', 'dec')">
									<i class="fas fa-angle-down"></i>
								</span>
							</div>
						</div>
						<div class="content-box"></div>
						<div class="right-center-flex">
							<div style="width: 100%;text-align: center">
								<span style="cursor: pointer;" (click)="incDecValue('paddingRight', 'inc')">
									<i class="fas fa-angle-up"></i>
								</span> 
								<div class="value" style="margin: 50% 0%;" (click)="editValue(paddingRight)">
									<input type="text" [(ngModel)]="paddingRight">
								</div>
								<span style="cursor: pointer;" (click)="incDecValue('paddingRight', 'dec')">
									<i class="fas fa-angle-down"></i>
								</span>
							</div>
						</div>
					</div>
					<div class="bottom-padding-box">
						<div class="bottom-icon">
							<span style="cursor: pointer;" (click)="incDecValue('paddingBottom', 'dec')">
								<i class="fas fa-angle-left"></i>
							</span>
							<div class="value" style="margin: 0% 10%;" (click)="editValue(paddingBottom)">
								<input type="text" [(ngModel)]="paddingBottom">
							</div>
							<span style="cursor: pointer;" (click)="incDecValue('paddingBottom', 'inc')">
								<i class="fas fa-angle-right"></i>
							</span>
						</div>
					</div>
				</div>
				<div class="right-center-flex">
					<div style="margin-top: 95%;width: 100%;text-align: center">
						<span style="cursor: pointer;" (click)="incDecValue('marginRight', 'inc')">
							<i class="fas fa-angle-up"></i>
						</span>
						<div class="value" style="margin: 40% 0%;" (click)="editValue(marginRight)">
							<input type="text" [(ngModel)]="marginRight">
						</div>
						<span style="cursor: pointer;" (click)="incDecValue('marginRight', 'dec')">
							<i class="fas fa-angle-down"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="bottom-flex">
				<div class="bottom-icon">
					<span style="cursor: pointer;" (click)="incDecValue('marginBottom', 'dec')">
						<i class="fas fa-angle-left"></i>
					</span>
					<div class="value" style="margin: 0% 8%;" (click)="editValue(marginBottom)">
						<input type="text" [(ngModel)]="marginBottom">
					</div>
					<span style="cursor: pointer;" (click)="incDecValue('marginBottom', 'inc')">
						<i class="fas fa-angle-right"></i>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
	<button [disabled]="action == 'saving'" class="btn btn-primary" (click)="save()">
		<span [hidden]="action != 'save'" translate>Save</span>
		<span [hidden]="action == 'save'" translate>Saving</span>
	</button>
</div>