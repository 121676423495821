<ng-container *ngIf="showModalType == 'addOption'">
<form *ngIf="optionForm" [formGroup]="optionForm" (submit)="save(optionType)">
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>
				Add.option.help.text
			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<div class="control-group clearfix">
						<div class="option-column1" *ngIf="!freemiumAccess">
							<label class="cursor-pointer line-height-18">
								<input type="radio" name="option" value="blank" (click)='optionSelected($event)' [checked]="!freemiumAccess">
								<span  translate>blank.option</span>
							  </label>
						</div>
						<div class="option-column2">
							<label  class="cursor-pointer line-height-18">
								<input type="radio" name="option" value="template" (click)='optionSelected($event)' [checked]="freemiumAccess">
								<span translate>template.option</span>
							  </label>
						</div>
					</div>
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>Option.name</label>
						<div class="controls">
							<input formControlName='optionName' *ngIf='!freemiumAccess && optionType == "blank"' type="text" class="control-input" style="width: 85%;" (valueChanged)="checkUniqueTemplateName($event)" appDebounceTimer>
							<div *ngIf="f.optionName.invalid && (f.optionName.dirty || f.optionName.touched)">
								<span *ngIf="f.optionName.errors.required && templatesLoading" class="control-error-message show">
									<span translate>Please.enter.valid.option.name</span>
								</span>
							</div>
							<span *ngIf="f.optionName.valid && uniqueName" class="control-error-message show">
								<span translate>This.template.name.already.exists</span>
							</span>
<!--							<span *ngIf="!templatesLoading" [ngClass]="{-->
<!--								'display-none':optionType != 'template'-->
<!--							  }">-->
<!--                                <ng-container *ngIf="!freemiumAccess">-->
<!--                                    <input formControlName="optionName" type="hidden" csSelect2 id="optionName"-->
<!--                                           [select2Config]="select2TemplateConfig" [placeholder]="'&#45;&#45; Please select &#45;&#45;'"-->
<!--                                           class="chosen-select full-width-select">-->
<!--                                </ng-container>-->
<!--                                <ng-container *ngIf="freemiumAccess">-->
<!--                                    <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1" formControlName="optionName">-->
<!--                                      <option value="" translate>Please.Choose</option>-->
<!--                                      <option *ngFor="let data of optionTemplates" [value]="data.id" [disabled]="data.isFreemium === 0 && freemiumAccess">-->
<!--                                        {{ data.optionName }}-->
<!--                                      </option>-->
<!--                                    </select>-->
<!--                                </ng-container>-->
<!--                            </span>-->
                            <div *ngIf='optionType != "blank"' class="search-template-box">
                                <ng-container>
                                    <app-search-template [screen]="'option_template'" [screenLabel]="'Option templates'" [addon]="false"
                                                         [showAddNewTemplate]="showAddNewTemplate" [excludeOptions]="excludeOptions"
                                                         (selectedItem)="templateSelected($event)"></app-search-template>
                                </ng-container>
                            </div>
						</div>
                            <span *ngIf='!freemiumAccess && optionType == "blank"' >
                                <label class="control-label" translate>Customer.displaying.name</label>
                                <div class="controls">
                                    <input formControlName='customerOptionName' *ngIf='optionType == "blank"' type="text" class="control-input" style="width: 85%;" (valueChanged)="checkUniqueTemplateName($event)" appDebounceTimer>
                                </div>
                            </span>
                        <div class="control-group" *ngIf="false">
                            <label class="control-label required_field" translate>Select layout</label>
                            <div class="controls">
                                <div class="layout-rows">
                                    <div class="layout layout-one" (click)="selectLayout('blockOne','layout-one')">
                                        <div class="title"></div>
                                        <div class="text-area"></div>
                                        <div class="text-area"></div>
                                    </div>
                                    <div class="layout layout-two" (click)="selectLayout('blockTwo','layout-two')">
                                        <div class="title"></div>
                                        <div class="layout-rows gap">
                                            <div class="column">
                                                <div class="text-area"></div>
                                            </div>
                                            <div class="column">
                                                <div class="image-block"></div>
                                            </div>
                                        </div>
                                        <div class="text-area"></div>
                                    </div>
                                    <div class="layout layout-three" (click)="selectLayout('blockThree','layout-three')">
                                        <div class="title"></div>
                                        <div class="layout-rows gap">
                                            <div class="column">
                                                <div class="text-area block-height"></div>
                                            </div>
                                            <div class="column">
                                                <div class="image-block block-height"></div>
                                            </div>
                                        </div>
                                        <div class="title"></div>
                                        <div class="text-area block-height"></div>
                                        <div class="layout-rows gap">
                                            <div class="column">
                                                <div class="text-area block-height"></div>
                                            </div>
                                            <div class="column">
                                                <div class="image-block block-height"></div>
                                            </div>
                                        </div>
                                        <div class="text-area block-height"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
					<!-- <div class="control-group clearfix">
						<label class="check-container">
							<span style="position: absolute;left:0;">Recommended</span>
							<input style='display:block' type="checkbox">
							<span class="check"></span>
						</label>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]='action != "save" || optionForm.invalid || loading ' class="btn btn-primary">
			<span *ngIf='action == "save"' translate>Save</span>
			<span *ngIf='action != "save"' translate>Saving</span>
		</button>
	</div>
</form>
</ng-container>


<ng-container *ngIf="showModalType == 'uploadFilesModal'">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <div class="modal-body">
        <div class="help_section">
            <div class="help-text" translate>
                Upload files help text
            </div>
        </div>
        <div class="body-content-section">
            <div>
                <div>
                    <div (onFileDrop)="selectAndDragDropFiles($event,'dragAndDrop')" ng2FileDrop [uploader]="uploader">
                        <div class="upload-option img-margin" (click)="fileInput.click()">
                            <p class="upload-option-text" translate>upload.pdf.files.drag.and.drop</p>
                        </div>
                        <input accept=".pdf" #fileInput type="file" (change)="selectAndDragDropFiles($event,'upload')" style="display:none;" />
                    </div>
                </div>
                <ng-container *ngIf='isFileSelected'>
                    <ng-container *ngFor="let file of fileList;let i=index;last as isLast">
                        <div class="row-upload-section" [ngClass]="isLast ? '' : 'mb-1' ">
                            <div class="col-50 w-15">
                                <i class="fas fa-file-pdf attachFile-font-pdf" aria-hidden="true"></i>
                            </div>
                            <div class="col-50 w-70 content-and-loading">
                                <div class="file-section">
                                    <div class="attach-file-width2 float-left">
                                        <p class="font-file-name ">{{file?.name}}</p>
                                        <p class="font-file-size">{{file?.size}}kb</p>
                                    </div>
                                    <div class="margin-l20 margin-top float-right ">
                                        <i id="{{'uploadLoading' + i }}" class="fas fa-circle-notch fa-spin fa-3x"
                                           style="font-size:24px;color: #477596"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        <button [disabled]='action != "save" || !loading' class="btn btn-primary" (click)="pdfUpload()">
            <span *ngIf='action == "save"' translate>Save</span>
            <span *ngIf='action != "save"' translate>Saving</span>
        </button>
    </div>
</ng-container>
