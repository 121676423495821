import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { environment } from '../../../../environments/environment';
var UpsellPartsComponent = /** @class */ (function () {
    function UpsellPartsComponent(confirmationBoxHelper, ajsScope, fb, proposalTemplateService, bsModalRef) {
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.fb = fb;
        this.proposalTemplateService = proposalTemplateService;
        this.bsModalRef = bsModalRef;
        this.partList = [];
        this.existingParts = [];
        this.action = 'save';
        this.formLoaded = false;
        this.upsellFormData = {
            "id": null,
            "description": "",
            "part": "",
            "unitCost": "",
            "salePrice": "",
            "labourTime": "",
            "labourRate": "",
            "vatRate": "",
            "labourVatRate": "",
            "bulletIns": ""
        };
    }
    UpsellPartsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.type == 'edit') {
            this.proposalTemplateService.getPartUpsellData(this.upsellPartId).subscribe(function (response) {
                _this.upsellFormData = response['upsellData'];
                _this.partList = response['partData'];
                _this.buidlUpsellForm(JSON.parse(_this.upsellFormData.bulletIns));
            });
        }
        else {
            this.buidlUpsellForm();
        }
        this.select2RateConfig = {
            width: '100%'
        };
        this.formattedTaxLists = this.formatTaxItems(this.taxLists);
        this.onClose = new Subject();
    };
    UpsellPartsComponent.prototype.buidlUpsellForm = function (bulletIn) {
        var _this = this;
        if (bulletIn === void 0) { bulletIn = []; }
        var bullets;
        var existData = this.existingParts.join();
        if (this.type == 'edit') {
            existData = existData + ',' + this.partList[0].id;
            bullets = new FormArray([]);
            this.bulletInArray = JSON.parse(this.upsellFormData.bulletIns);
            // this.upsellFormData.bulletIns = JSON.parse(this.upsellFormData.bulletIns);
            // bulletIn.forEach(element => {
            // 	bullets.push(this.getBulletIn());
            // });
        }
        var bulletInsFormArray = new FormArray([
            new FormControl(this.type === 'edit' && this.bulletInArray[0] !== undefined ? this.bulletInArray[0]['bullet-in'] : '', Validators.required),
            new FormControl(this.type === 'edit' && this.bulletInArray[1] !== undefined ? this.bulletInArray[1]['bullet-in'] : ''),
            new FormControl(this.type === 'edit' && this.bulletInArray[2] !== undefined ? this.bulletInArray[2]['bullet-in'] : ''),
        ]);
        this.upsellForm = this.fb.group({
            'description': [this.upsellFormData.description, Validators.required],
            'part': [this.upsellFormData.part, Validators.required],
            'unitCost': [this.upsellFormData.unitCost, Validators.required],
            'salePrice': [this.upsellFormData.salePrice, Validators.required],
            'labourTime': [this.upsellFormData.labourTime, Validators.required],
            'labourRate': [this.upsellFormData.labourRate, Validators.required],
            'vatRate': [this.upsellFormData.vatRate, Validators.required],
            'labourVatRate': [this.upsellFormData.labourVatRate, Validators.required],
            'bulletIns': bulletInsFormArray
        });
        this.select2Config = {
            minimumInputLength: 2,
            width: '100%',
            initSelection: function () { },
            ajax: {
                url: environment.apiBasePath + 'getParstAndPricesList',
                data: function (searchText) {
                    if (searchText == '') {
                        return false;
                    }
                    var newQueries = {};
                    newQueries['searchText'] = encodeURIComponent(searchText);
                    newQueries['existingPartIds'] = existData;
                    return newQueries;
                },
                results: function (data) {
                    return { results: data };
                },
            },
            formatResult: function (item, container, query, escapeMarkup) {
                var splitSearchTerm = query.term.split(" ");
                splitSearchTerm.forEach(function (v, k) {
                    splitSearchTerm[k] = "(" + v + ")";
                });
                var regex = new RegExp(splitSearchTerm.join("|"), 'gi');
                var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                if (item.manufacturepartnumber) {
                    htmlresult += ', ' + 'MPN' + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                }
                return htmlresult;
            },
        };
        if (this.type == 'edit') {
            // this.upsellForm.get('bulletIns').patchValue(this.upsellFormData.bulletIns);
            setTimeout(function () {
                $(".select-part").select2('data', _this.partList[0]);
            }, 200);
        }
        this.formLoaded = true;
    };
    Object.defineProperty(UpsellPartsComponent.prototype, "inputs", {
        get: function () {
            return this.upsellForm.get('bulletIns');
        },
        enumerable: true,
        configurable: true
    });
    UpsellPartsComponent.prototype.getBulletIn = function () {
        return this.fb.group({
            "bullet-in": new FormControl('', Validators.required)
        });
    };
    UpsellPartsComponent.prototype.addBulletinFormGroup = function (event, index) {
        this.bulletIn = this.upsellForm.get('bulletIns');
        var lastIndex = this.bulletIn.length - 1;
        if ((index == lastIndex) && (index < 2)) {
            var formGroup = this.fb.group({
                "bullet-in": new FormControl('')
            });
            this.bulletIn.push(formGroup);
            this.upsellForm.updateValueAndValidity();
        }
    };
    UpsellPartsComponent.prototype.deleteBulletin = function (index) {
        var formArray = this.upsellForm.get('bulletIns');
        formArray.removeAt(index);
        this.upsellForm.updateValueAndValidity();
    };
    UpsellPartsComponent.prototype.saveParts = function () {
        var _this = this;
        var RemoveEmptyBullet = [];
        this.action = 'saving';
        var result = this.upsellForm.value;
        for (var i = 0; i < result.bulletIns.length; i++) {
            var isEmpty = Object.values(result.bulletIns[i].trim()).every(function (element) { return element === null || element === ''; });
            if (isEmpty == false) {
                RemoveEmptyBullet.push({ 'bullet-in': result.bulletIns[i] });
            }
        }
        result['bulletIns'] = JSON.stringify(RemoveEmptyBullet);
        result['pricingPartsCostId'] = this.upsellPartId;
        result['id'] = this.upsellFormData.id;
        this.proposalTemplateService.addOrEditUpsellParts(result).subscribe(function (response) {
            _this.onClose.next(_this.upsellForm.value);
            _this.bsModalRef.hide();
            var msg = {
                type: 'upgradeFreemium'
            };
            top.postMessage(msg, '*');
        }, function (error) {
            console.log(error);
        });
    };
    UpsellPartsComponent.prototype.removeUpsell = function () {
        var that = this;
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to remove upsell?', that.ajsScope)
            .then(function () {
            that.removePartUpsell();
        }, function () {
            return false;
        });
    };
    UpsellPartsComponent.prototype.removePartUpsell = function () {
        var _this = this;
        this.proposalTemplateService.removePartUpsell(this.upsellFormData.id).subscribe(function (response) {
            _this.onClose.next(true);
            _this.bsModalRef.hide();
        }, function (error) {
            console.log(error);
        });
    };
    UpsellPartsComponent.prototype.updatePrices = function (selectedPart) {
        var _this = this;
        if (selectedPart > 0) {
            this.proposalTemplateService.getSelectedPart(selectedPart).subscribe(function (response) {
                var labourRate = response['labourRate'];
                var vatRate = response['vatRate'];
                var labourVatRate = response['labourVatRate'];
                _this.upsellForm.get('unitCost').setValue(response['unitCost']);
                _this.upsellForm.get('salePrice').setValue(response['salePrice']);
                _this.upsellForm.get('labourTime').setValue(response['labourTime']);
                _this.upsellForm.get('labourRate').setValue(labourRate);
                _this.upsellForm.get('vatRate').setValue(vatRate);
                _this.upsellForm.get('labourVatRate').setValue(labourVatRate);
                if (labourRate > 0) {
                    $('#triggerLabourRate').val(labourRate).select2();
                }
                else {
                    $('#triggerLabourRate').val('').select2();
                }
                if (vatRate > 0) {
                    $('#triggerVatRate').val(vatRate).select2();
                }
                else {
                    $('#triggerVatRate').val('').select2();
                }
                if (labourVatRate > 0) {
                    $('#triggerLabourVatRate').val(labourVatRate).select2();
                }
                else {
                    $('#triggerLabourVatRate').val('').select2();
                }
            });
        }
    };
    UpsellPartsComponent.prototype.formatTaxItems = function (taxData) {
        var tax = [];
        var taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach(function (province) {
            var taxLabel = province[0]['taxProvinceName'];
            tax.push({ 'text': taxLabel, 'children': province });
        });
        return tax;
    };
    return UpsellPartsComponent;
}());
export { UpsellPartsComponent };
