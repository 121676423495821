import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AppValidators } from "@app/directives";
import { CsToastBoxService } from "@app/services";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var FullScreenHeaderComponent = /** @class */ (function () {
    function FullScreenHeaderComponent(changeDetector, fb, toastBox, workflowService) {
        this.changeDetector = changeDetector;
        this.fb = fb;
        this.toastBox = toastBox;
        this.workflowService = workflowService;
        this.subTitle = '';
        this.data = {};
        this.actionsButtons = [];
        this.showActivation = false;
        this.showConfirmationModal = false;
        this.currentStatus = false;
        this.actionsButtons = [];
    }
    FullScreenHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.confirmationForm = this.fb.group({
            'confirmationText': ['', [Validators.required, AppValidators.confirmationTextValidator('CONFIRM')]]
        });
        this.subscription = this.workflowService.updateCustomHeader$.subscribe({
            next: function (content) {
                if (typeof content == 'object') {
                    if (content.hasOwnProperty('action') && content['action'] == 'save:disabled') {
                        var saveBtn = _this.actionsButtons.find(function (btn) { return btn.id == 'save'; });
                        if (saveBtn) {
                            saveBtn['disabled'] = content['value'];
                        }
                    }
                }
            }
        });
    };
    FullScreenHeaderComponent.prototype.ngAfterViewInit = function () {
        this.changeDetector.detectChanges();
    };
    FullScreenHeaderComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    FullScreenHeaderComponent.prototype.activeOrDeactive = function () {
        var _this = this;
        if (this.confirmationForm.invalid) {
            return false;
        }
        this.action = 'confirming';
        var workflowsetup = {
            id: this.data.id,
            statusCode: this.currentStatus,
        };
        this.workflowService.updateStatus(this.data.id, { workflowsetup: workflowsetup })
            .subscribe(function (resp) {
            console.log(resp);
            _this.onCloseConfirmation();
            if (resp.status === 'success') {
                _this.currentStatus = _this.data.status = resp.statusCode;
                if (_this.mxGraph) {
                    _this.mxGraph.workflowStatus = _this.currentStatus;
                }
                _this.toastBox.show(resp.message, 1000);
            }
            else if (resp.status === 'failed') {
                _this.toastBox.show(resp.message, 1000);
            }
        });
    };
    FullScreenHeaderComponent.prototype.onCloseConfirmation = function () {
        this.showConfirmationModal = false;
        this.currentStatus = this.data.status;
        this.action = '';
        this.confirmationForm.reset();
    };
    FullScreenHeaderComponent.prototype.onShowConfirmation = function () {
        this.toggleType = this.data.status === 1 ? 'deactivate' : 'activate';
        this.showConfirmationModal = true;
        console.log(this.data.status);
    };
    FullScreenHeaderComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        console.log('unsubscribe custom header');
    };
    return FullScreenHeaderComponent;
}());
export { FullScreenHeaderComponent };
