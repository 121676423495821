import {
    Injectable,
    ComponentFactoryResolver,
    Injector,
    ApplicationRef, Renderer2,
} from '@angular/core';
import {MxGraphService} from "@app/workflow-common/services/mx-graph.service";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {TriggerDatasetService} from "@app/workflow-common/services/trigger-dataset.service";
import {WorkflowCommonService} from "@app/workflow-common/services/workflow.common.service";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {mxgraph, mxgraphFactory} from "mxgraph-factory";
import {noop} from "rxjs/index";
import {SequenceAddContactComponent} from "@app/workflow-common/sequence-add-contact/sequence-add-contact.component";
import {BsModalRef} from "ngx-bootstrap";

declare var _;
declare var $;

const {
    mxCodec,
    mxUtils,
    mxEvent,
    mxCell,
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

@Injectable({
    providedIn: 'root'
})
export class MxGraphSequenceService extends MxGraphService {
    workflowStatus: any = null;
    constructor(
        public modalService: SidepanelModalService,
        public triggerService: TriggerDatasetService,
        public commonService: WorkflowCommonService,
        public componentFactoryResolver: ComponentFactoryResolver,
        public injector: Injector,
        public appRef: ApplicationRef,
        public workflowService: WorkflowhttpService,
        public modalSidepanel: BsModalRef,
    ) {
        super(modalService, triggerService, commonService, componentFactoryResolver, injector, appRef, workflowService);
    }

    render( callback: Function = noop) {
        if(! this.xmlSource ) {
            this.mxGraph.getModel().beginUpdate();
            try {
                const parent = this.mxGraph.getDefaultParent();
                const blockWidth = this.blockWidth, blockHeight = this.blockHeight;
                const x = (this.getContainerWidth() / 2) - (blockWidth / 2);
                //const x = 30;
                const y = 20;
                const startBlock = this.mxGraph.insertVertex(parent, 'cs_initializer', '', x, y, blockWidth, blockHeight, 'cursor=pointer');
                startBlock['block_name']='initializer';
                startBlock['parentId']='0';
                this.addOverlay(startBlock, false);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
            }
        }
        else {
            let doc = mxUtils.parseXml(this.xmlSource);
            let codec = new mxCodec(doc);
            this.mainObjectName = '';
            codec.decode(doc.documentElement, this.mxGraph.getModel());
            _.forEach(this.mxGraph.getModel().cells, (cell:mxgraph.mxCell) => {
                if(cell.isVertex() && !this.options.viewMode && !this.isMarketPlaceView && (cell['block_name'] !== 'dummyBlock')) {
                    const isElseBlock = cell['block_name'] == 'elseBlock';
                    if(this.options.mode == 'sequence_setup') {
                        this.addOverlay(cell, isElseBlock);
                    }
                }
            });
        }
        setTimeout( () => {
            this.mxGraph.zoomActual();
            // this.mxGraph.zoomTo(.7, true);
        },300)
        callback(this);
    }

    firstTimeRender: boolean = true;
    prepareInitBlockContainer(ModalData:any, callback:any = null) {
        if(this.options.mode == 'sequence_setup') {
            return super.prepareInitBlockContainer(ModalData, callback);
        }
        // console.log('Modaldata', ModalData)
        const {model} = ModalData;
        let data:any = {}
        let padding = 20, maxWidth = 400;
        let mainContainer = $('<div></div>').css({
            whiteSpace: 'normal',
            padding: padding,
            textAlign: 'left',
            fontSize: '14px',
            lineHeight: '20px',
            cursor: 'default',
            width: maxWidth+'px'
        }).addClass('box-sizing').attr('id', 'cs-init-block-container')
        let sequenceSection = $('<div></div>').css({marginBottom: '10px'}).text('Sequence:');
        let sequenceText = $('<div></div>').css({paddingLeft: '20px'}).text(model['displaySequenceName']);
        sequenceSection.append(sequenceText);
        mainContainer.append(sequenceSection)

        let contactSection = $('<div></div>').css({marginBottom: '10px'}).text('Contact:');
        let contactName = $('<div></div>').css({paddingLeft: '20px'}).addClass('cs-mb1').text(model['displaySelectedContactName']);
        contactSection.append(contactName);

        const {emailAddress, mobile} = model['displayContactInfo'];
        if(emailAddress) {
            let emailWrap = $('<div></div>').css({paddingLeft: '20px'}).addClass('cs-mb1'),
                emailIcon = $('<i></i>').addClass('fas fa-envelope'),
                emailText = $('<span></span>').css('margin-left', '5px').text(model['displayContactInfo']['emailAddress'] );
            emailWrap.append(emailIcon);
            emailWrap.append(emailText);
            contactSection.append(emailWrap);
        }

        if(mobile) {
            let mobWrap = $('<div></div>').css({paddingLeft: '20px'}),
                mobIcon = $('<i></i>').addClass('fas fa-mobile'),
                mobText = $('<span></span>').css('margin-left', '5px').text(model['displayContactInfo']['mobile']);
            mobWrap.append(mobIcon);
            mobWrap.append(mobText);
            contactSection.append(mobWrap);
        }
        mainContainer.append(contactSection);

        if(model['displayContactInfo']['mobile'] && model['displayContactInfo']['emailAddress']) {
            contactSection.css('margin-bottom', '0');
        }

        if(model['selectedContact'] && model['selectedContact']['mobile'].length > 1 && !mobile) {
            let formDiv = $('<div></div>').addClass('boxed-input'),
                formTitleDiv = $('<div></div>').addClass('cs-mb2').text('Select a mobile number to send SMS messages');
            formDiv.append(formTitleDiv);
            const mobileList: any[] = model['selectedContact']['mobile'];
            mobileList.forEach( (item) => {
                let formCheckBoxDiv = $('<div></div>').addClass('form-check cs-mb1'),
                    checkBoxLabel = $('<label></label>').addClass('form-check-label').attr('for', 'contact_mobi_'+item.phone_number).text(item.code + ' ' + item.phone_number),
                    inputCheckBox = $('<input/>').addClass('form-check-input').attr('type', 'radio').attr('id', 'contact_mobi_'+item.phone_number).attr('name', 'contact_mobi').val(item.telephone_id);
                inputCheckBox.on('change', this.onCheckedContact.bind(this))
                formCheckBoxDiv.append(inputCheckBox);
                formCheckBoxDiv.append(checkBoxLabel);
                formDiv.append(formCheckBoxDiv);
            })
            mainContainer.append(formDiv)
        }

        if(!emailAddress) {
            let addEmailDiv = $('<div></div>').addClass('cs-mt2 text-color-danger'),
                addEmailText = $('<span></span>').addClass('cs-ml1').text('The contact doesn’t have an email address.'),
                warnIcon = $('<i></i>').addClass('fas fa-exclamation-triangle');
            addEmailDiv.append(warnIcon);
            addEmailDiv.append(addEmailText);
            let btn  = document.createElement('button');
            btn.className = 'btn btn-plain link-color';
            btn.style.paddingLeft = '0';
            mxUtils.write(btn, 'Click to add an email address');
            btn.addEventListener(mxEvent.CLICK, () => {
                this.showAddContact('email');
            });
            addEmailDiv.append(btn);
            mainContainer.append(addEmailDiv);
        }

        if(!mobile && model['selectedContact']['mobile'].length == 0) {
            let addMobDiv = $('<div></div>').addClass('cs-mt2 text-color-danger'),
                addMobText = $('<span></span>').addClass('cs-ml1').text('The contact doesn’t have a mobile number.'),
                warnIcon = $('<i></i>').addClass('fas fa-exclamation-triangle');
            addMobDiv.append(warnIcon);
            addMobDiv.append(addMobText);
            let btn  = document.createElement('button');
            btn.className = 'btn btn-plain link-color';
            btn.style.paddingLeft = '0';
            mxUtils.write(btn, 'Click to add a number');
            btn.addEventListener(mxEvent.CLICK, () => {
                this.showAddContact('mobile');
            });
            addMobDiv.append(btn);
            mainContainer.append(addMobDiv);
        }

        if(this.firstTimeRender && callback instanceof mxCell || typeof callback === 'function') {
            this.firstTimeRender = false;
            let clonedMainContainer = mainContainer.clone();
            clonedMainContainer.appendTo('body');
            data = {
                width: clonedMainContainer.outerWidth(),
                height: clonedMainContainer.outerHeight(),
            };
            clonedMainContainer.remove();
            if(typeof callback === 'function') {
                callback(ModalData, data);
            }
        }
        data['container'] = mainContainer;
        return data;
    }

    onCheckedContact(event:any) {
        if(event.target.checked) {
            let cell:mxgraph.mxCell = this.mxGraph.getModel().getCell('cs_initializer');
            let { model } = cell.getValue();
            let selectedMobile = model['selectedContact']['mobile'].find( x => x.telephone_id == event.target.value);
            model['displayContactInfo']['mobile'] = '+'+selectedMobile.code + ' ' + selectedMobile.phone_number;
            model['displayContactInfo']['contact_telephone_id'] = selectedMobile.telephone_id;
            let updated_data = { model, action: 'sequence_save_send' };
            setTimeout( () => {this.renderInitBlockContainer(cell, {formData:updated_data})})
            this.workflowService.triggerCustomHeaderEvent({
               action: 'save:disabled',
               value: !(model['displayContactInfo']['emailAddress'] && model['displayContactInfo']['mobile'])
            });
        }
    }

    showAddContact(type: string) {
        let cell:mxgraph.mxCell = this.mxGraph.getModel().getCell('cs_initializer');
        let { model } = cell.getValue();
        const initialState = {
            type,
            contactId: model['contactId'],
            title: type === 'email'? 'Add an email address' : 'Add a mobile number',
            helpText: type === 'email' ? 'In order to send a sequence which involves emails, it’s important the ' +
                'contact has a valid email address.' : 'In order to send a sequence which involves SMS, it’s ' +
                'important the contact has a valid mobile number.'
        };
        this.modalSidepanel = this.modalService.show(SequenceAddContactComponent, {
            initialState: initialState,
            size: 'sm',
            'class': 'ng-sequence-popup',
            wrapperClass: 'cs-sequence-add-contact',
            backdrop: true,
        });
        this.modalSidepanel.content.onClose.subscribe( (resp:any) => {
            if(type === 'mobile') {
                model['displayContactInfo']['mobile'] = '+'+resp.code + ' ' + resp.phone_number;
                model['displayContactInfo']['contact_telephone_id'] =  resp.id;
                model['selectedContact']['mobile'] = [resp];

            }
            if(type === 'email') {
                model['displayContactInfo']['emailAddress'] = resp['emailaddress'];
                model['selectedContact']['email']['emailAddress'] = resp['emailaddress'];
            }
            let updated_data = { model, action: 'sequence_save_send' };
            this.workflowService.triggerCustomHeaderEvent({
                action: 'save:disabled',
                value: !(model['displayContactInfo']['emailAddress'] || model['displayContactInfo']['mobile'])
            });
            this.renderInitBlockContainer(cell, {formData:updated_data});
        })
    }

    skipOrUndoButton(cell: mxgraph.mxCell, text='Skip') {
        let button: HTMLButtonElement = document.createElement('button');
        button.className = 'btn btn-plain link-color';
        button.style.position = 'absolute';
        button.style.top = '20px';
        button.style.right = '20px';
        button.style.padding = '0';
        let value = cell.getValue();
        button.addEventListener(mxEvent.CLICK, (e) => {
            e.stopPropagation();
            e.preventDefault();
            const {model} = cell.getValue();
            const triggerContent = {
                action: !model['current_task_status'] ? 'showSkipConfirmation' : 'showUndoConfirmation',
                value: cell
            };
            this.workflowService.triggerEvent(triggerContent);
        });
        mxUtils.write(button, value['model']['current_task_status'] == 'skip' ? 'Undo' : 'Skip');
        return button;
    }
}
