import {ChangeDetectorRef, Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap';
import {Subject} from 'rxjs/index';
import {SidepanelModalService} from '@app/sidepanel/services/sidepanel-modal.service';
import {PropertyViewService} from '@app/features/customers/customer/property-view/service/property-view.service';

declare var $: any;

export interface FormDatas {
    name: string;
}

@Component({
    selector: 'app-rename-files-modal',
    templateUrl: './rename-files.modal.html',
    styleUrls: ['./rename-files.modal.scss'],
    providers: [SidepanelModalService]
})

export class RenameFilesModal implements OnInit, AfterViewInit {
    public onClose: Subject<boolean>;
    title: string;
    name: string;
    fileId: number;
    fileType: string;
    closeBtnName: string;
    formModal: any = {};
    form: FormGroup;
    action: string = '';
    formDatas = {
        fileId: '', name: '', fileType: ''
    };

    @ViewChild('saveBtn') saveBtn: ElementRef;

    constructor(private formBuilder: FormBuilder, @Inject('$scope') private ajsScope, private httpService: PropertyViewService, public bsModalRef: BsModalRef, private changeDetector: ChangeDetectorRef) {
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.form = this.formBuilder.group({
            'name': [this.name, [Validators.required, Validators.pattern('^.*$')]],
            'fileId': [this.fileId],
            'fileType': [this.fileType],
        });
    }

    ngAfterViewInit() {
        let that = this;
        setTimeout(() => {
            this.saveBtn.nativeElement.focus();
        }, 900);
    }

    updateValidation(event: any) {
        if (this.form.get('name').value == '') {
            this.form.controls['name'].updateValueAndValidity();
        }
    }

    save() {
        if (!this.form.valid) {
            this.action = '';
            return false;
        }
        this.action = 'saving';
        let renameFiles: any = this.form.value;
        this.httpService.renameFiles(renameFiles)
            .subscribe(resp => {
                this.action = '';
                this.onClose.next(true);
                this.bsModalRef.hide();
            }, error => {
                this.action = '';
                console.log('err', error);
            });
    }
}
