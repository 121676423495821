import {
    Component,
    OnInit,
    ViewChild,
    HostListener,
    ElementRef,
    Output,
    EventEmitter,
    Inject,
    forwardRef
} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";
import {
    BsDatepickerConfig,
    BsDatepickerDirective,
    BsDaterangepickerDirective,
    BsDropdownDirective
} from "ngx-bootstrap";
import {SettingsService} from "@app/core";

@Component({
    selector: 'app-datefilter',
    templateUrl: './datefilter.component.html',
    styleUrls: ['./datefilter.component.scss']
})
export class DatefilterComponent implements OnInit {

  filterDate: Array<any>;
  rangeSearch:any = '';
  filterParams:any = {
        name: '',
        fromDate: '',
        toDate: ''
    };
   displayFilter:any = '';
   dateRangeFilter: string = 'All days';
    filterObjects = [
        { 'key': 'Inprogress', value: 'In progress', 'selected': true, 'visibility': true },
        { 'key': 'Finished', value: 'Finished', 'selected': false, 'visibility': true },
        { 'key': 'Failed', value: 'Failed', 'selected': false, 'visibility': true },
        { 'key': 'All', value: 'All', 'selected': false, 'visibility': true },
    ];
    selectedFilterObject: string = 'In progress';
    autoClose: boolean = true;

    formats: any = {};

    currentDate = new Date();
    bsInlineRangeValue: Date[];
    maxDate = new Date();
    customBsConfig?: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('daterangepicker') daterangepicker: BsDaterangepickerDirective;
    @ViewChild('datepicker') datepicker: BsDatepickerDirective;
    @ViewChild('datepickerAfter') datepickerAfter: BsDatepickerDirective;
    @ViewChild('dropdown') dropdown: BsDropdownDirective;

    @HostListener('document:click', ['$event.target'])
    onClick(target) {
        if(!this.eRef.nativeElement.contains(target)) {
            this.hideAllPickers();
            if(this.dropdown && this.dropdown.isOpen) {
                this.dropdown.hide();
            }
        }
        if($(target).hasClass('clearfix')){
            $('.filter-user-width').removeClass('open');
        }
    }

  constructor(
      private translate: TranslateService,
      private eRef: ElementRef,
      @Inject(forwardRef(() => SettingsService)) private settings
  ) {

  }

    ngOnInit() {
        this.formats = this.settings.getRegionalDateFormats();
        this.initDateFilter();
    }

    initDateFilter() {
        const intlSettings = this.settings._csIntlSettings;
        let date = Date.now();
        let _today = moment(date), today =_today.format('YYYY-MM-DD'), todayDisplay = _today.format(this.formats[intlSettings['short_date']]);
        let _tomorrow = moment(date).add(1, 'day'), tomorrow = _tomorrow.format('YYYY-MM-DD'), tomorrowDisplay = _tomorrow.format(this.formats[intlSettings['short_date']]);

        let currentWeek = {
            from: moment(date).subtract(0, 'weeks').startOf('week').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'weeks').startOf('week').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'weeks').endOf('week').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'weeks').endOf('week').format(this.formats[intlSettings['short_date']])
        }
        let currentMonth = {
            from: moment(date).subtract(0, 'months').startOf('month').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'months').startOf('month').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'months').endOf('month').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'months').endOf('month').format(this.formats[intlSettings['short_date']]),
        }
        let CurrentQuarterMonth = moment(date).utc().quarter();
        let currentQuarterEndDate = moment().quarter(CurrentQuarterMonth).endOf('quarter');
        let currentQuarterStartDate = moment().quarter(CurrentQuarterMonth).startOf('quarter');
        let currentQuarter = {
            from: currentQuarterStartDate.format('YYYY-MM-DD'),
            fromDisplay: currentQuarterStartDate.format(this.formats[intlSettings['short_date']]),
            to: currentQuarterEndDate.format('YYYY-MM-DD'),
            toDisplay: currentQuarterEndDate.format(this.formats[intlSettings['short_date']])
        }
        let currentYear = {
            from: moment(date).subtract(0, 'year').startOf('year').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(0, 'year').startOf('year').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(0, 'year').endOf('year').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(0, 'year').endOf('year').format(this.formats[intlSettings['short_date']]),
        }
        let lastWeek = {
            from: moment(date).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'weeks').startOf('week').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'weeks').endOf('week').format(this.formats[intlSettings['short_date']]),
        }
        let lastMonth = {
            from: moment(date).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'months').startOf('month').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'months').endOf('month').format(this.formats[intlSettings['short_date']]),
        }
        let LastQuarterMonth = CurrentQuarterMonth == 1 ? 4 : CurrentQuarterMonth - 1;
        let lastQuarterEndDate = moment().quarter(LastQuarterMonth).endOf('quarter');
        let lastQuarterStartDate = moment().quarter(LastQuarterMonth).startOf('quarter');

        let lastQuarter = {
            from: lastQuarterStartDate.format('YYYY-MM-DD'),
            fromDisplay: lastQuarterStartDate.format(this.formats[intlSettings['short_date']]),
            to: lastQuarterEndDate.format('YYYY-MM-DD'),
            toDisplay: lastQuarterEndDate.format(this.formats[intlSettings['short_date']])
        }

        let lastyear = {
            from: moment(date).subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
            fromDisplay: moment(date).subtract(1, 'year').startOf('year').format(this.formats[intlSettings['short_date']]),
            to: moment(date).subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
            toDisplay: moment(date).subtract(1, 'year').endOf('year').format(this.formats[intlSettings['short_date']]),
        }
        this.filterDate = [
            { 'text': this.translate.instant("All.Days"), 'fromDate': '', 'toDate': '', 'selected': true, 'show': true, key:'all' },
            { 'text': this.translate.instant("Today"), 'fromDate': today, 'toDate': today, 'selected': false, 'show': true, key:'today', 'display': todayDisplay },
            { 'text': this.translate.instant("Tomorrow"), 'fromDate': tomorrow, 'toDate': tomorrow, 'selected': false, 'show': true, key:'tomorrow', 'display': tomorrowDisplay },
            { 'text': this.translate.instant("This.Week"), 'fromDate': currentWeek.from, 'fromDisplay': currentWeek.fromDisplay, 'toDate': currentWeek.to, 'toDisplay': currentWeek.toDisplay, 'selected': false, 'show': true, key:'this-week' },
            { 'text': this.translate.instant("This.Month"), 'fromDate': currentMonth.from, 'fromDisplay': currentMonth.fromDisplay, 'toDate': currentMonth.to, 'toDisplay': currentMonth.toDisplay, 'selected': false, 'show': true, key: 'this-month' },
            { 'text': this.translate.instant("This.Quarter"), 'fromDate': currentQuarter.from, 'fromDisplay': currentQuarter.fromDisplay, 'toDate': currentQuarter.to, 'toDisplay': currentQuarter.toDisplay, 'selected': false, 'show': true, key: 'this-quarter' },
            { 'text': this.translate.instant("This.Year"), 'fromDate': currentYear.from, 'fromDisplay': currentYear.fromDisplay, 'toDate': currentYear.to, 'toDisplay': currentYear.toDisplay, 'selected': false, 'show': true, key: 'this-year' },
            { 'text': this.translate.instant("Last.Week"), 'fromDate': lastWeek.from, 'fromDisplay': lastWeek.fromDisplay, 'toDate': lastWeek.to, 'toDisplay': lastWeek.toDisplay, 'selected': false, 'show': true, key:'last-week' },
            { 'text': this.translate.instant("Last.Month"), 'fromDate': lastMonth.from, 'fromDisplay': lastMonth.fromDisplay, 'toDate': lastMonth.to, 'toDisplay': lastMonth.toDisplay, 'selected': false, 'show': true, key: 'last-month' },
            { 'text': this.translate.instant("Last.Quarter"), 'fromDate': lastQuarter.from, 'fromDisplay': lastQuarter.fromDisplay, 'toDate': lastQuarter.to, 'toDisplay': lastQuarter.toDisplay, 'selected': false, 'show': true, key: 'last-quarter' },
            { 'text': this.translate.instant("Last.Year"), 'fromDate': lastyear.from, 'fromDisplay': lastyear.fromDisplay, 'toDate': lastyear.to, 'toDisplay': lastyear.toDisplay, 'selected': false, 'show': true, key: 'last-year' },
            { 'text': this.translate.instant("Date.Range"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'date-range' },
            { 'text': this.translate.instant("Before"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'before' },
            { 'text': this.translate.instant("After"), 'fromDate': '', 'toDate': '', 'selected': false, 'show': true, key: 'after' },
        ];
    }
    onDateRangeChanges(type, event, index) {
        const intlSettings = this.settings._csIntlSettings;
        if (event.length && type == 'range') {
            let fromDate: any = new Date(event[0]);
            this.filterParams.fromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
            fromDate = fromDate.getDate() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getFullYear();

            let toDate: any = new Date(event[1]);
            this.filterParams.toDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();

            toDate = toDate.getDate() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getFullYear();
            this.dateRangeFilter = fromDate + ' - ' + toDate;
            this.displayFilter = moment(fromDate).format(this.formats[intlSettings['short_date']]) + ' - ' + moment(toDate).format(this.formats[intlSettings['short_date']]);
        }
        else if (type == 'date' && event) {
            let selectedDate: any = new Date(event);
            let beforeDate = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" + selectedDate.getDate();
            this.filterParams.fromDate = this.filterParams.toDate = beforeDate;
            beforeDate = selectedDate.getDate() + "-" + selectedDate.getMonth() + "-" + selectedDate.getFullYear();
            this.dateRangeFilter = beforeDate;
            this.displayFilter = moment(selectedDate).format(this.formats[intlSettings['short_date']]);
        }else{
            this.dateRangeFilter = this.filterDate[index].text;
        }
        this.filterParams.name = this.filterDate[index].key;
        this.filterDate[index].selected = true;
        this.setSelectedRange(index);
        this.dropdown.hide();
        this.onChange.emit(this.filterParams);
    }

    hideAllPickers() {
        if(this.daterangepicker && this.daterangepicker.isOpen) {
            this.daterangepicker.hide();
        }
        if(this.datepicker && this.datepicker.isOpen) {
            this.datepicker.hide();
        }
        if(this.datepickerAfter && this.datepickerAfter.isOpen) {
            this.datepickerAfter.hide();
        }
    }

    getFilterByDate(date, index) {
        this.hideAllPickers();
        this.dateRangeFilter = date.text;
        this.filterParams.fromDate = date.fromDate;
        this.filterParams.toDate = date.toDate;
        this.setSelectedRange(index);
        this.displayFilter = date.key == 'today' || date.key == 'tomorrow'  ?  date.display : date.fromDisplay + ' - ' + date.toDisplay;
        this.filterParams.name = date.key;
        if(date.key == 'all') {
            this.displayFilter='';
        }
        // $('.dropdown').removeClass('open');
        this.autoClose = true;
        $('.filter-user-width').removeClass('open');

        this.onChange.emit(this.filterParams);
    }

    setSelectedRange(selectedIndex) {
        this.filterDate.forEach((val, key) => {
            this.filterDate[key].selected = false;
        });
        this.filterDate[selectedIndex].selected = true;
    }

    clearRange(){
        let left = $('.date-filter').position().left - $('.aside-inner').width() - 20.5;
        $(".dropdown-menu").css("left", left);
        this.autoClose = true;
        this.rangeSearch = '';
        this.searchRange();
    }
    searchRange() {
        this.autoClose = false;
        if (this.rangeSearch) {
            this.filterDate.forEach((val, key) => {
                this.filterDate[key]['show'] = false;
            });
            let filter = this.filterDate.filter((i: any) => i.text.toLowerCase().indexOf(this.rangeSearch.toLowerCase()) != -1);
            if (filter.length) {
                filter.forEach((val, key) => {
                    filter[key]['show'] = true;
                });
            }
        }
        else {
            this.rangeSearch = '';
            this.filterDate.forEach((val, key) => {
                this.filterDate[key]['show'] = true;
            });
        }
    }
    setDatePickerHeight(pickerType) {
        this.autoClose = false
        if (pickerType == 'range') {
            let position = $('.range-picker').offset();
            let left = (position.left + $('.range-picker').width()) + 'px';
            let top = position.top + 'px';
            setTimeout(() => {
                $('bs-daterangepicker-container').css('top', `${left}`)
            }, 200);
        }
        if (pickerType == 'before') {
            let position = $('.range-picker').offset();
            let left = (position.left + $('.range-picker').width()) + 'px';
            let top = position.top + 'px';
            setTimeout(() => {
                $('bs-datepicker-container').css('top', `${left}`)
            }, 200);
        }
        if (pickerType == 'after') {
            let position = $('.range-picker').offset();
            let left = (position.left + $('.range-picker').width()) + 'px';
            let top = position.top + 'px';
            setTimeout(() => {
                $('bs-datepicker-container').css('top', `${left}`)
            }, 200);
        }
    }
    selectedObject(objectKey, value) {
        this.selectedFilterObject = value;
        this.filterParams.status = value;
        this.filterObjects.forEach((val, key) => {
            this.filterObjects[key].selected = false;
            if (val.key == objectKey) {
                this.filterObjects[key].selected = true;
            }});
        $('.filter-object').removeClass('open');
        this.onChange.emit(this.filterParams);
    }
    statusFilter(){
        let left = $('.statusFilter').position().left - $('.aside-inner').width() - 20.5;
        $(".dropdown-menu").css("left", left);
        $('.filter-user-width').removeClass('open');
    }
}
