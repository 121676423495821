import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap  } from 'rxjs/operators';

//--
import { InterfaceService } from '@app/interfaces';

@Injectable({
    providedIn: 'root'
})
export class SettingsContractCategoriesService extends InterfaceService{
    constructor(
        private http: HttpClient
    ) { super(); }

    // fetching existing contract categories
    fetchContractCategories(param?: object): Observable<any>{
        return this.http.get(this.getApiUrl('system_settings/list-contract-categories',param)).pipe();
    }

    // Add new contract category
    addContractCategory(data: Object): Observable<any> {
        return this.http.post(this.getApiUrl('contract-category/add-new'), JSON.stringify(data)).pipe();
    }

    // Update contract category
    editContractCategory(id: number, data: Object): Observable<any> {
        return this.http.put(this.getApiUrl(`system_settings/${id}/edit_contract_categories`), JSON.stringify(data)).pipe();
    }

    // Delete contract categories
    deleteContractCategory(id: number): Observable<any>{
        return this.http.delete(this.getApiUrl(`system_settings/${id}/delete_contract_categories`)).pipe();
    }

    CheckDuplicateContactCategoryName(name: string): Observable<any>{
        return this.http.get(this.getApiUrl(`check_contract_categories`,{name: name}));
    }

    // Checking delete option for ppm
    isDeletePPM(id: number): Observable<any>{
        return this.http.get(this.getApiUrl(`system_settings/${id}/is_delete_contract_categories`)).pipe();
    }

}