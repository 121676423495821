import { Directive, Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

//--
import { CsToastBoxService } from '@app/services';

@Component({
  selector: '[csToastBox]',
  template: `
  <div id="toast-box"
    [ngClass]="{'toast-showing' : show }"
    ng-mouseenter="keepToastShowing()"
    ng-mouseleave="allowToastToHide()">
    <span class="confirmation">
      <strong class="text" translate>
        {{toastMessage}}
      </strong>
      <span class="ss-delete close" (click)="close()"></span>
    </span>
  </div>`,
  styles: []
})
export class CsToastBoxComponent {
  @Input('message') toastMessage: string;
  duration: number = 1000; // Milliseconds

  @Input('duration')
  set showupDuration(duration: number) {
    this.duration = duration || 1000;
  }

  show: boolean = true;
  @Output('close') onclose: EventEmitter<boolean> = new EventEmitter();
  transitionDuration: number = 300;
  closeTimer: any;

  constructor(
    private toastBoxService: CsToastBoxService
  ) { }

  ngOnInit() {
    this.setCloseTimer();
  }

  @HostListener("mouseenter", ["$event"])
  onmouseover(event: any) {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
  }

  @HostListener("mouseleave", ["$event"])
  onmouseleave(event: any) {
    this.setCloseTimer();
  }

  close() {
    this.show = false;

    setTimeout(() => {
      this.onclose.emit(true);
      this.toastBoxService.hide();
    }, this.transitionDuration);
  }

  setCloseTimer() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }

    this.closeTimer = setTimeout(() => {
      this.close();
    }, this.duration);
  }
}
