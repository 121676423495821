import { OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { BsModalRef } from "ngx-bootstrap";
import { Subject } from 'rxjs';
var BlockSettingsModalComponent = /** @class */ (function () {
    function BlockSettingsModalComponent(fb, bsModalRef, proposalTemplateService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.proposalTemplateService = proposalTemplateService;
        this.action = 'save';
        this.title = '';
        this.customCss = '';
        this.options = {
            type: "component",
            showPalette: false,
            hideAfterPaletteSelect: true,
            showButtons: false,
            allowEmpty: false,
            preferredFormat: "hex",
            containerClassName: 'container-palette'
        };
        this.oldCustomCssVal = '';
        this.blockError = {
            topMargin: false,
            rightMargin: false,
            bottomMargin: false,
            leftMargin: false
        };
        this.margin_dir = {
            open: false,
            left: '',
            right: '',
            top: '',
            bottom: ''
        };
        this.restoreEle = {};
        this.margin = 0;
        this.borderColor = 'No border';
    }
    BlockSettingsModalComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        if (this.pageId) {
            this.id = this.pageId;
        }
        if (this.id) {
            if ($('#cs_editor').contents().find(this.id).attr('data-page-type') == 'EHP') {
                var id = $('#cs_editor').contents().find(this.id).attr('id');
                this.coverPage = true;
                $.fn.inlineStyle = function (prop) {
                    var styles = $(this).attr("style");
                    var value;
                    styles && styles.split(";").forEach(function (e) {
                        var style = e.split(":");
                        if ($.trim(style[0]) === prop) {
                            value = style[1];
                        }
                    });
                    return value;
                };
                this.borderColor = $('#cs_editor').contents().find('#' + id).inlineStyle("border-color");
                if (this.borderColor && this.borderColor.includes('rgba')) {
                    this.borderColor = this.hexCode(this.borderColor);
                }
                else if (this.borderColor && this.borderColor.includes('rgb')) {
                    this.borderColor = this.rgbToHex(this.borderColor);
                }
                else {
                    this.borderColor = "No border";
                }
                this.restoreEle.borderColor = this.borderColor;
            }
            else {
                this.coverPage = false;
                var existData = $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('page-property');
                if (existData && typeof existData !== 'undefined') {
                    existData = JSON.parse(existData);
                    this.margin_dir = existData.margin_dir;
                    this.customCss = existData.customCss;
                    this.borderColor = existData.borderColor;
                    this.margin = existData.margin;
                }
                else {
                    this.margin = parseInt($('#cs_editor').contents().find(this.id).find('.cs_margin').css('margin'));
                }
                this.restoreEle.margin_dir = JSON.parse(JSON.stringify(this.margin_dir));
                this.restoreEle.customCss = this.customCss;
                this.restoreEle.borderColor = this.borderColor;
                this.restoreEle.margin = $('#cs_editor').contents().find(this.id).find('.cs_margin').css('margin');
                $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('old-property', JSON.stringify(this.restoreEle));
            }
        }
    };
    BlockSettingsModalComponent.prototype.ngAfterViewInit = function () {
        var that = this;
        $.fn.inlineStyle = function (prop) {
            var styles = this.attr("style"), value;
            styles && styles.split(";").forEach(function (e) {
                var style = e.split(":");
                if ($.trim(style[0]) === prop) {
                    value = style[1];
                }
            });
            return value;
        };
    };
    BlockSettingsModalComponent.prototype.rgbaToHex = function (r, g, b, a) {
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];
        // Pad single-digit output values
        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        });
        return ('#' + outParts.join(''));
    };
    BlockSettingsModalComponent.prototype.refreshSpectrum = function (id) {
        for (var index = 0; index < 4; index++) {
            $(id).trigger('change');
        }
    };
    BlockSettingsModalComponent.prototype.hexCode = function (color) {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        var hex = "#" + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        return hex;
    };
    BlockSettingsModalComponent.prototype.toggle = function (option) {
        switch (option) {
            case 'margin':
                if (this.margin_dir.open) {
                    this.margin_dir.open = false;
                    this.margin = 0;
                    $('#margin-icon').removeClass('selected-style');
                }
                else {
                    this.margin = 0;
                    var block = $('#cs_editor').contents().find(this.id);
                    block[0].style.removeProperty('margin');
                    $('#margin-icon').addClass('selected-style');
                    this.margin_dir.open = true;
                    this.margin_dir.left = 0;
                    this.margin_dir.top = 0;
                    this.margin_dir.right = 0;
                    this.margin_dir.bottom = 0;
                }
                break;
        }
    };
    BlockSettingsModalComponent.prototype.removeStyling = function (deleteEle) {
        $('#cs_editor').contents().find(this.id).each(function () {
            var styleEle = this;
            deleteEle.forEach(function (val) {
                styleEle.style.removeProperty("" + val);
            });
        });
    };
    BlockSettingsModalComponent.prototype.setMargin = function (value, key) {
        if (value >= 0) {
            var that_1 = this;
            var deleteEle_1 = [];
            $('#cs_editor').contents().find(this.id).each(function () {
                var csMargin = $(this).find('.cs_margin');
                if (csMargin.length > 0) {
                    var marginElement = csMargin[0];
                    if (key != 'padding') {
                        marginElement.style.setProperty([key], value + "px", 'important');
                        marginElement.style.setProperty('--allsidemargin', "0");
                    }
                    else {
                        deleteEle_1 = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                        that_1.removeStyling(deleteEle_1);
                        that_1.margin_dir.left = '';
                        that_1.margin_dir.right = '';
                        that_1.margin_dir.bottom = '';
                        that_1.margin_dir.top = '';
                        marginElement.style.setProperty([key], value + "px", 'important');
                    }
                }
                if (value == null) {
                    deleteEle_1 = [key];
                    that_1.removeStyling(deleteEle_1);
                }
            });
            var editorRef = $('#cs_editor').get(0);
            editorRef.contentWindow.postMessage({ type: 'showmarkers', element: this.id }, '*');
        }
        else if (value < 0) {
            if (key == "margin-top") {
                this.margin_dir.top = 0;
            }
            else if (key == "margin-right") {
                this.margin_dir.right = 0;
            }
            else if (key == "margin-left") {
                this.margin_dir.left = 0;
            }
            else if (key == "margin-bottom") {
                this.margin_dir.bottom = 0;
            }
        }
    };
    Object.defineProperty(BlockSettingsModalComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.settingsForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    BlockSettingsModalComponent.prototype.validValue = function (value, control) {
        var pxExp = /([0-9]+px)/gm;
        var numExp = /^\d*$/gm;
        if (numExp.test(value) || /^([0-9]+?%)$/gm.test(value) || pxExp.test(value)) {
            this.blockError[control] = false;
        }
        else if (value == '' || value == null) {
            this.blockError[control] = false;
        }
        else {
            this.blockError[control] = true;
        }
    };
    BlockSettingsModalComponent.prototype.validateFormfunc = function () {
        var arrs = Object.values(this.blockError);
        return !(arrs.every(function (val, i, arr) { return val === false; }));
    };
    BlockSettingsModalComponent.prototype.save = function (setAllPageValue) {
        $('#cs_editor').contents().find(this.id).addClass(this.customCss);
        $('#cs_editor').contents().find(this.id).removeClass(this.oldCustomCssVal);
        var data = {
            'margin_dir': this.margin_dir,
            'customCss': this.customCss,
            'borderColor': this.borderColor,
            'margin': this.margin,
            'setAllPage': setAllPageValue,
        };
        data = JSON.stringify(data);
        if (setAllPageValue) {
            var cs_page_Element = $("#cs_editor").contents().find('.cs_page').find('.cs_margin').get();
            $(cs_page_Element).attr('page-property', data);
            $(cs_page_Element).removeAttr('old-property');
        }
        else {
            $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('page-property', data);
            $('#cs_editor').contents().find(this.id).find('.cs_margin').removeAttr('old-property');
        }
        var editorRef = $('#cs_editor').get(0);
        editorRef.contentWindow.postMessage({ type: 'removeMarkers', element: this.id }, '*');
        this.proposalTemplateService.savePages(false);
    };
    // convert rgb to hex
    BlockSettingsModalComponent.prototype.componentFromStr = function (numStr, percent) {
        var num = Math.max(0, parseInt(numStr, 10));
        return percent ?
            Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
    };
    BlockSettingsModalComponent.prototype.rgbToHex = function (rgb) {
        rgb = rgb.trim();
        var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
        var result, r, g, b, hex = "";
        if ((result = rgbRegex.exec(rgb))) {
            r = this.componentFromStr(result[1], result[2]);
            g = this.componentFromStr(result[3], result[4]);
            b = this.componentFromStr(result[5], result[6]);
            hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
        return hex;
    };
    BlockSettingsModalComponent.prototype.cancel = function () {
        $('#block-border-color').spectrum('destroy');
        var page = $('#cs_editor').contents().find(this.id)[0];
        var block = $('#cs_editor').contents().find(this.id).find('.cs_margin');
        if (block.length > 0 && this.coverPage == false) {
            var marginElement = block[0];
            if (this.restoreEle.margin_dir.open == true) {
                var deleteEle = ['margin'];
                this.removeStyling(deleteEle);
                marginElement.style.setProperty('--allsidemargin', "0");
                marginElement.style.setProperty('margin-left', this.restoreEle.margin_dir.left + 'px');
                marginElement.style.setProperty('margin-right', this.restoreEle.margin_dir.right + 'px');
                marginElement.style.setProperty('margin-bottom', this.restoreEle.margin_dir.bottom + 'px');
                marginElement.style.setProperty('margin-top', this.restoreEle.margin_dir.top + 'px');
            }
            else {
                var deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                this.removeStyling(deleteEle);
                marginElement.style.setProperty('margin', this.restoreEle.margin);
            }
            block.css({ 'border-color': this.restoreEle.borderColor });
            $('#cs_editor').contents().find(this.id).find('.cs_margin').removeAttr('old-property');
        }
        this.borderColor = this.restoreEle.borderColor;
        if (this.borderColor == 'No border') {
            page.style.removeProperty('border-color');
        }
        else {
            page.style.setProperty('border-color', this.borderColor);
        }
        var editorRef = $('#cs_editor').get(0);
        editorRef.contentWindow.postMessage({ type: 'removeMarkers', element: this.id }, '*');
        // this.bsModalRef.hide();
    };
    BlockSettingsModalComponent.prototype.getColor = function (data) {
        var selectedPage = $('#cs_editor').contents().find(this.id);
        selectedPage.css({ 'border-color': data });
        this.borderColor = data;
    };
    BlockSettingsModalComponent.prototype.detectChanges = function (data) {
        var that = this;
        this.myValue = '';
        setTimeout(function () {
            that.myValue = data;
        }, 50);
    };
    BlockSettingsModalComponent.prototype.preventMinus = function (event) {
        if (event.keyCode == 109 || event.keyCode == 189) {
            event.preventDefault();
        }
    };
    return BlockSettingsModalComponent;
}());
export { BlockSettingsModalComponent };
