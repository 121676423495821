import { ChangeDetectorRef, ComponentFactoryResolver, ElementRef, EventEmitter, OnInit, Renderer, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//--
import { CsToastBoxService } from "@app/services";
import { CsHeaderDirective } from "@app/directives/material-directives/cs-full-screen-modal/cs-header.directive";
var CsFullScreenModalComponent = /** @class */ (function () {
    function CsFullScreenModalComponent(_hostRef, _renderer, route, location, toastBox, componentFactoryResolver, cdRef, confirmationBoxHelper, ajsScope) {
        this._hostRef = _hostRef;
        this._renderer = _renderer;
        this.route = route;
        this.location = location;
        this.toastBox = toastBox;
        this.componentFactoryResolver = componentFactoryResolver;
        this.cdRef = cdRef;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.showFooter = false;
        this.showTooltip = false;
        this.bodyClass = '';
        this.subTitle = '';
        this.subText = '';
        this.showSubTitleAsTemplate = false;
        this.hideCloseButton = false;
        this.mapTemplate = false;
        this.zIndex = '1030';
        this.headerZIndex = '1';
        this.customHeader = false;
        this.viewMode = false;
        this.onContentOpen = new EventEmitter();
        this.onContentClose = new EventEmitter();
        this.onMapContentApprove = new EventEmitter();
        this.onMapContentDelete = new EventEmitter();
        this.instance = new EventEmitter();
        this.getCustomHeaderInstance = new EventEmitter();
        this.action = 'save';
        this.ppmList = [];
    }
    CsFullScreenModalComponent.prototype.ngOnInit = function () {
        // For modal box purpose
        this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
        this._renderer.setElementStyle(this._hostRef.nativeElement, 'z-index', this.zIndex);
        if (this.bodyClass) {
            this._renderer.setElementClass(this.widgetBody.nativeElement, this.bodyClass, true);
        }
    };
    CsFullScreenModalComponent.prototype.ngAfterViewInit = function () {
        this.instance.emit(this);
        if (this.customHeader) {
            var factory = this.componentFactoryResolver.resolveComponentFactory(this.customHeader);
            var viewContainerRef = this.dynamicHeader.viewContainerRef;
            viewContainerRef.clear();
            var componentRef = viewContainerRef.createComponent(factory);
            componentRef.instance.close = this.close.bind(this);
            this.getCustomHeaderInstance.emit(componentRef.instance);
            //console.log('instance',this.customHeader);
            this.cdRef.detectChanges();
        }
        if (this.headerZIndex && this.widgetHeader != undefined) {
            this._renderer.setElementStyle(this.widgetHeader.nativeElement, 'z-index', this.headerZIndex);
        }
    };
    // Open widget
    CsFullScreenModalComponent.prototype.open = function () {
        var _this = this;
        jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block').addClass("bounceInUp").removeClass('bounceOutDown');
        // shorten the header if it is large
        this.onContentOpen.emit();
        setTimeout(function () {
            _this.shortenHeader();
        }, 100);
    };
    // Close widget
    CsFullScreenModalComponent.prototype.close = function (doDestroy) {
        var _this = this;
        if (doDestroy === void 0) { doDestroy = false; }
        jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
        setTimeout(function () {
            _this.onContentClose.emit();
        }, 100);
        if (doDestroy) {
            setTimeout(function () {
                jQuery(_this._hostRef.nativeElement).remove();
            }, 300);
        }
    };
    CsFullScreenModalComponent.prototype.ngOnDestroy = function () {
        // Remove content from body on destroy
        if (!this.mapTemplate) {
            jQuery(this._hostRef.nativeElement).remove();
        }
    };
    CsFullScreenModalComponent.prototype.shortenHeader = function () {
        this.headerName = this.header;
        if (this.header && this.header.length > 75) {
            this.header = this.header.substring(0, 75) + '..';
        }
    };
    CsFullScreenModalComponent.prototype.deleteTimesheet = function () {
        var _this = this;
        setTimeout(function () {
            _this.onMapContentDelete.emit();
        }, 100);
    };
    CsFullScreenModalComponent.prototype.approveMap = function () {
        var _this = this;
        setTimeout(function () {
            _this.onMapContentApprove.emit();
        }, 100);
    };
    return CsFullScreenModalComponent;
}());
export { CsFullScreenModalComponent };
