let anchor;

export function resolveUrl(url) {
  if (!anchor) {
    anchor = document.createElement('a');
  }
  anchor.setAttribute('href', url);
  anchor.setAttribute('href', anchor.href);
  return {
    // IE does not start `pathname` with `/` like other browsers.
    pathname: "/" + anchor.pathname.replace(/^\//, ''),
    search: anchor.search,
    hash: anchor.hash
  };
}
