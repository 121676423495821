import { Component, OnInit } from '@angular/core';
import {BsModalRef } from "ngx-bootstrap";
import { Subject } from "rxjs/index";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {mxgraph} from "mxgraph-factory";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {CsTaggingService} from "@app/directives/material-directives/cs-tagging/cs-tagging.service";
import {addWeekParseToken} from "ngx-bootstrap/chronos/parse/token";

@Component({
  selector: 'app-sequence-email-widget',
  templateUrl: './sequence-email-widget.component.html',
  styles: []
})
export class SequenceEmailWidgetComponent implements OnInit {

  templateForm: FormGroup;
  cell: mxgraph.mxCell;
  mxGraph: mxgraph.mxGraph;
  sender: any;
  isLoading: boolean = true;
  templateData: any = {};
  saving: boolean = false;
  tagsList: any[] = [];
  diagramOptions: any;
  workflowType: string;
  allowableTags: any[] = [];
  subjectTextMessage: string = '';
  public onClose: Subject<any>;

  constructor(
      public modalRef: BsModalRef,
      private fb: FormBuilder,
      private httpService: WorkflowhttpService,
      private tagService: CsTaggingService,
  ) { }

  async ngOnInit() {
      if(this.workflowType == 'Sequence' && this.diagramOptions['sequenceObjectName'] == 'Opportunity') {
        this.allowableTags = ['customers', 'opportunities'];
      }
      this.onClose = new Subject();
      const cellValue = this.cell.getValue();
      const cellModel = cellValue['model'];

      this.templateForm = this.fb.group({
          'subject': [cellModel.hasOwnProperty('customMessage') ? cellModel['customMessage']['subject'] : '', Validators.required],
          'message': [cellModel.hasOwnProperty('customMessage') ? cellModel['customMessage']['message'] : '', Validators.required]
      });
      this.templateForm.get('message').valueChanges.subscribe( (content) => {
          if(content == '') {
              return;
          }
          const hasText = content.replace(/(<([^>]+)>)/gi, "");
          if(!hasText) {
              this.templateForm.get('message').setValue('');
          }
      })
      this.templateForm.get('subject').valueChanges.subscribe( (content) => {
          if(content == '') {
              return;
          }
          const hasText = content.replace(/(<([^>]+)>)/gi, "");
          if(!hasText) {
              this.templateForm.get('subject').setValue('');
          }
      })
      if(!cellModel.hasOwnProperty('customMessage')) {
          await this.getEmailTemplate();
      }
      await this.getTagsList();
      this.isLoading = false;
  }

  async getTagsList() {
      return this.tagService.getTagsList({
          tagList: this.allowableTags
      }).toPromise()
      .then ( (resp) => {
          this.tagsList = this.tagService.format4Quill(resp);
      });
  }

  async getEmailTemplate() {
      const {model} = this.cell.getValue();
      this.httpService.getEmailSmsTemplatesByIds({ids: model['templateId']})
      .toPromise()
      .then(
      (resp:any) => {
          this.templateData = resp['templates'][model['templateId']]
          this.templateForm.patchValue({
              subject: this.templateData['subject'],
              message: this.templateData['message'],
          });
          this.templateForm.updateValueAndValidity();
      });
  }

  onChangeSubjectContent(content: any) {
      let hasText = content.text != '\n'; console.log('en', content.text)
      if(!hasText) {
        // this.templateForm.get('subject').setValue('');
      }
  }

  onChangeMessageContent(content: any) {
      let hasText = content.text != '\n';
      if(!hasText) {
          this.templateForm.get('message').setValue('');
      }
  }

  onSave() {
    const formValue = this.templateForm.value;
    if(!this.subjectTextMessage) {
        const {model} = this.cell.getValue();
        this.subjectTextMessage = model['subject'];
    }
    formValue['displaySubjectMessage'] = formValue['subject'].replace(/(<([^>]+)>)/gi, "");
    this.onClose.next(formValue);
    this.modalRef.hide();
  }

}
