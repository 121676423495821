import { NgModule } from '@angular/core';
import { AppCommonModule } from "@app/shared";
import { AddEditCertificateComponent } from "@app/features/customers/jobs/add-edit-certificate/add-edit-certificate.component";
import { CertificatePageContainerComponent } from './certificate-page-container/certificate-page-container.component';
import {CertificateContainerResizeDirective} from "@app/features/customers/jobs/add-edit-certificate/directives/certificate-container-resize.directive";
import {DynamicCertificateFormParserModule} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser.module";

@NgModule({
    declarations: [
        AddEditCertificateComponent,
        CertificatePageContainerComponent,
        CertificateContainerResizeDirective,
    ],
    imports: [
        AppCommonModule,
        // DynamicCertificateFormParserModule
    ],
})
export class AddEditCertificateModule { }
