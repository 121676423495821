import { InterfaceClass } from "@app/interfaces/class.interface";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { CanDeactivateGuard } from "@app/services/router-services/can-deactivation-guard";
import { OnDestroy } from "@angular/core";
import { HybridFactory } from "@app/core/hybrid-factory";

export class CanDeactivateGuardComponent extends InterfaceClass implements OnDestroy {
  protected router: Router;

  protected navigateToNextStateUrl() {
    if (HybridFactory.isUpgraded(CanDeactivateGuard.nextStateUrl)) this.router.navigateByUrl(CanDeactivateGuard.nextStateUrl);
    else HybridFactory.navigate(CanDeactivateGuard.nextStateUrl);
  }

  constructor(router: Router) {

    super();

    this.router = router;

    this.subscriptions['__navEndSubscription'] = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        CanDeactivateGuard.setConfirmationCallback(this.router.url, this.canDeactivate);
      });
  }

  canDeactivate(nextStateUrl): boolean {
    return true;
  }

  ngOnDestroy() {
    CanDeactivateGuard.removeConfirmationCallback();
    super.ngOnDestroy();
  }
}
