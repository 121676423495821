import { Directive, ElementRef, EventEmitter, Inject, Injector, Input, Output } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import {Observable} from "rxjs/index";

@Directive({
    selector: 'react-component-migrated'
})
export class ReactComponentDirective extends UpgradeComponent {
    @Input() componentname: any;
    @Input() watchDepth: boolean;
    @Input() props: Observable<any>;
    constructor(
        _hostRef: ElementRef,
        injector: Injector,
        @Inject('$scope') private ajsScope
    ) {
        super('reactComponent2', _hostRef, injector);
    }
}
