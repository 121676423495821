import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ArticleEditorService } from '../article-editor.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ImageBlocksComponent } from '../image-blocks/image-blocks.component';
import { SidepanelModalService } from '@app/sidepanel/services/sidepanel-modal.service';
import { Console } from 'console';
import {Subject} from "rxjs";
import {
    ProposalTemplateService
} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {Router} from "@angular/router";
import {AuthService} from "@app/core";

declare var $: any

@Component({
    selector: 'app-formatting-options',
    templateUrl: './formatting-options.component.html',
    styleUrls: ['./formatting-options.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormattingOptionsComponent implements OnInit {
    public onClose: Subject<boolean>;
    options = {
        type: "component",
        showPalette: false,
        hideAfterPaletteSelect: true,
        showButtons: false,
        allowEmpty: true,
        preferredFormat: "hex",
        containerClassName: 'container-palette',
        showInput: true,
    }
    border_options = {
        type: "component",
        showPalette: false,
        hideAfterPaletteSelect: true,
        showButtons: false,
        allowEmpty: true,
        preferredFormat: "hex",
        containerClassName: 'container-palette',
        showInput: true,
    }
    displayOption: boolean = false;
    position_left = '';
    element_width = '';
    border_radius: any = '';
    border_width: any = '0';
    border_color: any = '';
    border_style: any = '';
    padding: any = '';
    margin: any = '';
    background_url: any = '';
    background_color: any = '';
    border_width_dir: any = {
        open: false,
        left: '',
        right: '',
        top: '',
        bottom: ''
    }

    border_radius_dir: any = {
        open: false,
        left: '',
        right: '',
        top: '',
        bottom: '',
        properties: ['border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'],
        topLeft: '',
        topRight: '',
        bottomLeft: '',
        bottomRight: '',
    }

    margin_dir: any = {
        open: false,
        left: '',
        right: '',
        top: '',
        bottom: ''
    }

    padding_dir: any = {
        open: false,
        left: '',
        right: '',
        top: '',
        bottom: ''
    }

    block_size: any = {
        width: 0,
        height: 0
    };
    restoreEle:any = {};
    hasShowSize: any = false;
    changedBackgroundColor:string;
    changedBorderColor:string;
    modal: boolean;
    bg: string;
    bo: string;
    colorPicker: boolean = false;
    eyeDropperStatus: boolean;
    advanceStyle: string;
    imageStyling: boolean = false;
    marginError : boolean = false;
    paddingError : boolean = false;
    textElementBackgroundImage : string;
    textElementBg : string;
    borderColorStyle: string;
    myValue: string;
    parentElement: number = 0;
    csPageWidth;
    transferToJob: boolean = false;
    jobNotes: string;
    screenName: any;
    proposalId: any;
    optionId: any;
    optionName: any;
    displayArray: any = [];
    existingOptionId = null;
    existingAddonId = null;
    pageId:any;
    saveButton: boolean = false;
    element_height: '';
    maximumWidth: boolean = false;
    marginDown: boolean = false;
    paddingDown: boolean = false;
    maximumHeight: boolean = false;
    heightDown: boolean = false;
    optionData: any=[];
    addonData: any=[];
    jobNotesValue:string = '-- Please choose --'
    selectedTemplateId: any;
    selectedTemplateType: any;
    savedData: any = [];
    closeDropdown: boolean = false;
    existingTableId: any;
    selectedId: string;
    pageType: any;
    screenType: string = '';
    proposalTemplateId: any;
    opportunityProposalId: any;
    opportunityId: any;
    constructor(
        private articleService: ArticleEditorService,
        private modalService: SidepanelModalService,
        public bsModalRef: BsModalRef,
        public proposaltemplateservice: ProposalTemplateService,
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        var that = this
        this.textElementBackgroundImage = this.textElementBg;
        let screen = this.router.url;
        if(screen.includes('option_template')) {
            this.screenType = 'option_template';
        } else if (screen.includes('proposal_templates')) {
            this.screenType = 'proposal_templates';
        } else if (screen.includes('opportunity')) {
            this.screenType = 'opportunity';
        } else if (screen.includes('content_library')) {
            this.screenType = 'content_library';
        } else if (screen.includes('addon_template')){
            this.screenType = 'addon_template'
        }

        if(this.advanceStyle == "imageStyling"){
            this.imageStyling = true;
            this.displayOption = true;
        } else if (this.advanceStyle == 'transferToJob') {
            this.transferToJob = true;
            this.jobNotes = this.textElementBg;
        } else {
            let backgroundColor = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('background-color')
            if (backgroundColor && backgroundColor.includes('rgba')) {
                this.changedBackgroundColor = that.hexCode(backgroundColor);
            } else if (backgroundColor && backgroundColor.includes('rgb')) {
                this.changedBackgroundColor = that.rgbToHex(backgroundColor);
            } else {
                this.changedBackgroundColor = 'No background'
            }
            let borderColor = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-color');
            let borderThickness = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-width');
            this.changedBorderColor = that.rgbToHex(borderColor)
            if (this.changedBorderColor == '#595B65'|| this.changedBorderColor == "#595b65" || this.changedBorderColor =='#505b65' && borderThickness == '0px') {
                this.changedBorderColor = 'No border'
                this.borderColorStyle = '#ffffff'
            } else {
                this.borderColorStyle = this.changedBorderColor
            }
            let marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            this.pageType = $(marginBlock).parents('div.cs_page').attr('data-page-type')
        }

        this.articleService.blockSelected.subscribe(res => {
            if (res) {
                var block : any;
                var marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                if (this.imageStyling){
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

                }else{
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                }
                // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                this.hasShowSize = block.closest('.row-item').length === 0 ? true : false;
                if (block && block.length > 0) {
                    that.displayOption = true;
                    that.background_url = block.css('background-image');
                    $.fn.inlineStyle = function (prop) {
                        var styles = this.attr("style"),
                            value;
                        styles && styles.split(";").forEach(function (e) {
                            var style = e.split(":");
                            if ($.trim(style[0]) === prop) {
                                value = style[1];
                            }
                        });
                        return value;
                    };
                    that.position_left = block.inlineStyle('left');
                    if (typeof that.position_left == 'undefined') {
                        that.position_left = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'left': that.position_left });
                    }
                    this.restoreEle.position_left = that.position_left.trim();
                    that.element_width = marginBlock.inlineStyle('width');
                    if (typeof that.element_width == 'undefined') {
                        that.element_width = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').css({ 'width': that.element_width });
                    }
                    this.restoreEle.element_width = that.element_width.trim();
                    this.restoreEle.element_width = this.restoreEle.element_width.replace(' !important', '')
                    that.element_height = block.inlineStyle('height');
                    if (typeof that.element_height == 'undefined') {
                        that.element_height = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'height': that.element_height });
                    }
                    this.restoreEle.element_height = that.element_height.trim();
                    this.restoreEle.element_height = this.restoreEle.element_height.replace(' !important', '')
                    that.border_color = block.inlineStyle('border-color');
                    if (typeof that.border_color == 'undefined') {
                        that.border_color = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'border-color': that.border_color });
                    }
                    this.restoreEle.border_color = that.border_color;
                    that.background_color = block.inlineStyle('background-color');
                    if (typeof that.background_color == 'undefined') {
                        that.background_color = ''
                    }
                    this.restoreEle.background_color = that.background_color;
                    that.border_style = block.css('border-style')
                    if(that.border_style == 'none'){
                        that.border_style = 'solid';
                        this.setStyle(that.border_style);
                    }
                    this.restoreEle.border_style = that.border_style;

                    if (block.inlineStyle('--allsideborderradius')) {
                        this.border_radius_dir.topLeft = (block[0].style.borderTopLeftRadius) ? this.removePixel(block[0].style.borderTopLeftRadius) :'0';
                        this.border_radius_dir.topRight = (block[0].style.borderTopRightRadius) ? this.removePixel(block[0].style.borderTopRightRadius) : 0;
                        this.border_radius_dir.bottomLeft = (block[0].style.borderBottomLeftRadius) ? this.removePixel(block[0].style.borderBottomLeftRadius) : 0;
                        this.border_radius_dir.bottomRight = (block[0].style.borderBottomRightRadius) ? this.removePixel(block[0].style.borderBottomRightRadius) : 0;
                        $('#border-radius-icon').addClass('selected-style')
                        this.border_radius_dir.open = true
                        this.restoreEle.border_radius_dir = JSON.parse(JSON.stringify(that.border_radius_dir));
                        that.border_radius = 0;
                    }
                    else {
                        that.border_radius = block[0].style.borderRadius.replaceAll('px', '');
                        if (!that.border_radius || that.border_radius == '') {
                            that.border_radius = 0;
                        }
                        this.restoreEle.border_radius = that.border_radius;
                        this.border_radius_dir.open = false;
                        this.restoreEle.border_radius_dir = JSON.parse(JSON.stringify(that.border_radius_dir));
                        $('#border-radius-icon').removeClass('selected-style')
                    }
                    if (block.inlineStyle('--allsideborderwidth')) {
                        this.border_width_dir.left = (block[0].style.borderLeftWidth) ? this.removePixel(block[0].style.borderLeftWidth) : 0;
                        this.border_width_dir.top = (block[0].style.borderTopWidth) ? this.removePixel(block[0].style.borderTopWidth) : 0;
                        this.border_width_dir.right = (block[0].style.borderRightWidth) ? this.removePixel(block[0].style.borderRightWidth) : 0;
                        this.border_width_dir.bottom = (block[0].style.borderBottomWidth) ? this.removePixel(block[0].style.borderBottomWidth) : 0;
                        $('#border-width-icon').addClass('selected-style')
                        this.border_width_dir.open = true
                        that.border_width = 0;
                        this.restoreEle.border_width_dir = JSON.parse(JSON.stringify(that.border_width_dir));
                    }
                    else {
                        that.border_width = block[0].style.borderWidth.replaceAll('px', '');
                        if (!that.border_width || that.border_width == '') {
                            that.border_width = 0;
                            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-width','0px')
                        }
                        this.border_width_dir.open = false;
                        this.restoreEle.border_width_dir = JSON.parse(JSON.stringify(that.border_width_dir));
                        this.restoreEle.border_width = that.border_width;
                        $('#border-width-icon').removeClass('selected-style')
                    }
                    if (marginBlock.inlineStyle('--allsidepadding')) {
                        this.padding_dir.left = (marginBlock[0].style.paddingLeft) ? this.removePixel(marginBlock[0].style.paddingLeft) : 0;
                        this.padding_dir.top = (marginBlock[0].style.paddingTop) ? this.removePixel(marginBlock[0].style.paddingTop) : 0;
                        this.padding_dir.right = (marginBlock[0].style.paddingRight) ? this.removePixel(marginBlock[0].style.paddingRight) : 0;
                        this.padding_dir.bottom = (marginBlock[0].style.paddingBottom) ? this.removePixel(marginBlock[0].style.paddingBottom) : 0;
                        $('#padding-icon').addClass('selected-style')
                        this.padding_dir.open = true;
                        this.restoreEle.padding_dir = JSON.parse(JSON.stringify(that.padding_dir));
                        that.padding = 0;
                    }
                    else {
                        that.padding = marginBlock[0].style.padding.replaceAll('px', '');
                        if (!that.padding || that.padding == '') {
                            that.padding = 0;
                        }
                        this.restoreEle.padding = that.padding;
                        this.padding_dir.open = false;
                        this.restoreEle.padding_dir = JSON.parse(JSON.stringify(that.padding_dir));
                        $('#padding-icon').removeClass('selected-style')
                    }
                    if(this.pageType == 'ECP'){
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
                    }

                    if (block.inlineStyle('--allsidemargin') || this.pageType == 'ECP') {
                        that.margin_dir.open = true;
                        that.margin_dir.left = (block[0].style.marginLeft) ? this.removePixel(block[0].style.marginLeft) : 0;
                        that.margin_dir.right = (block[0].style.marginRight) ? this.removePixel(block[0].style.marginRight) : 0;
                        if(this.pageType == 'ECP'){
                            that.margin_dir.top = (block[0].style.marginTop) ? this.removePixel(block[0].style.marginTop) : 10;
                            that.margin_dir.bottom = (block[0].style.marginBottom) ? this.removePixel(block[0].style.marginBottom) : 10;
                        } else {
                            that.margin_dir.top = (block[0].style.marginTop) ? this.removePixel(block[0].style.marginTop) : 0;
                            that.margin_dir.bottom = (block[0].style.marginBottom) ? this.removePixel(block[0].style.marginBottom) : 0;
                        }
                        $('#margin-icon').addClass('selected-style')
                        this.margin_dir.open = true
                        this.restoreEle.margin_dir = JSON.parse(JSON.stringify(that.margin_dir));
                        that.margin = 0;
                    }
                    else {
                        that.margin_dir.open = false;
                        that.margin = marginBlock[0].style.margin.replaceAll('px', '');
                        if (!that.margin || that.margin == '') {
                            that.margin = 0;
                        }
                        this.restoreEle.margin_dir = JSON.parse(JSON.stringify(that.margin_dir));
                        this.restoreEle.margin = that.margin;
                        $('#margin-icon').removeClass('selected-style')
                    }

                    that.block_size.width = Math.round(marginBlock.css('width').replace('px', ''));
                    that.block_size.height = Math.round(block.css('height').replace('px', ''));
                }
            } else {
                that.border_radius = ''
                that.background_url = ''
                that.options['color'] = '#ffffff'
                that.border_options['color'] = '#ffffff'
                that.displayOption = false;
                that.background_url = '';
                that.border_color = ''
                that.border_width = ''
                that.border_style = ''
                that.padding = ''
                that.margin = ''
                $('#blocks-background-color').spectrum('destroy');
                $('#blocks-border-color').spectrum('destroy');
            }
        })

        this.csPageWidth = $($('#cs_editor').contents().find('.cs_page').get(0)).outerWidth();
        if(this.transferToJob){
            let data = {
                'proposalId': this.proposalId
            }
            this.proposaltemplateservice.getJobNotesOptionAddonDetails(data).subscribe((data) => {
                if (data) {
                    let value = data;
                    for (let i = 0; i < value.length; i++) {
                        this.savedData.push(value[i])
                    }
                }
            })

            this.proposaltemplateservice.getOptionAddonsDetails(this.screenName, this.proposalId, this.optionId).subscribe((data) => {
                if (data && data.length) {
                    this.displayArray = data
                    for (let i = 0; i < this.displayArray.length; i++) {
                        this.displayArray[i]['displayName'] = "If " + this.displayArray[i]['name'] + " chosen by customer"
                    }
                }
                this.displayArray.unshift({
                    'id': this.optionId,
                    'name': this.optionName,
                    'type': 'option',
                    'displayName': "If " + this.optionName + " chosen by customer"
                })
                if (this.existingOptionId) {
                    for (let i = 0; i < this.displayArray.length; i++) {
                        if (this.displayArray[i].id == this.existingOptionId) {
                            let value = this.displayArray[i].id + '+' + this.displayArray[i].type
                            let selectedValue = this.displayArray[i].displayName
                            this.jobNotesValue = selectedValue
                            this.selectedId = '#' + this.displayArray[i].id + 'option'
                            this.selectedTemplateType = 'option'
                            this.selectedTemplateId = ''
                            setTimeout(() => {
                                $(this.selectedId).addClass('dropdown-item-focus')
                            }, 500)
                        }
                    }
                }
                if (this.existingAddonId) {
                    for (let i = 0; i < this.displayArray.length; i++) {
                        if (this.displayArray[i].id == this.existingAddonId) {
                            let value = this.displayArray[i].id + '+' + this.displayArray[i].type
                            let selectedValue = this.displayArray[i].displayName
                            this.jobNotesValue = selectedValue
                            this.selectedId = '#' + this.displayArray[i].id + 'addon'
                            this.selectedTemplateType = 'addon'
                            this.selectedTemplateId = this.displayArray[i].id
                            setTimeout(() => {
                                $(this.selectedId).addClass('dropdown-item-focus')
                            }, 500)
                        }
                    }
                }
            })
        }
        setTimeout(() => {
            if($('#cs_editor').contents().find('div.cs_block_s.cs_selected').parents('.cs_page').attr('name') == 'header_page'){
                $('.showPadding').show();
                $('.showMargin').show();
            }else{
                $('.showPadding').hide();
                $('.showMargin').hide();
            }
        }, 100);
    }
    ngOnDestroy(){
        $('#blocks-background-color').spectrum('destroy');
        $('#blocks-border-color').spectrum('destroy');
    }
    refreshSpectrum(id){
        for (let index = 0; index < 3; index++) {
            $(id).trigger('change')
        }
    }
    // convert rgb to hex
    componentFromStr(numStr, percent) {
        var num = Math.max(0, parseInt(numStr, 10));
        return percent ?
            Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
    }
    removePixel(px){
        return px.replaceAll('px','');
    }

    rgbToHex(rgb) {
        if(rgb == "rgb(255, 255, 255)"){
            hex = 'No Background'
            return hex;
        }
        rgb = rgb.trim()
        var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
        var result, r, g, b, hex = "";
        if ((result = rgbRegex.exec(rgb))) {
            r = this.componentFromStr(result[1], result[2]);
            g = this.componentFromStr(result[3], result[4]);
            b = this.componentFromStr(result[5], result[6]);

            hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
        return hex;
    }

    hexCode(color){
        if(color == 'rgba(0, 0, 0, 0)'){
            let hex = 'No background'
            return hex
        } else {
            const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
            let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
                .toString(16).slice(1)}`;
            if (rgba[3]) {
                const alpha = Math.round(rgba[3] * 255);
                const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
                hex += hexAlpha;
            }
            return hex;
        }
    }

    setstyledesc(block) {
        this.padding_dir.left = block.css('padding')
    }

    setRadius(value, key) {
        let that = this;
        let deleteEle = [];
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
            if (key != 'border-radius') {
                this.style.setProperty([key], `${value}px`, 'important');
                this.style.setProperty('--allsideborderradius', `0`);
                that.border_radius = 0;
            }
            else {
                deleteEle = ['--allsideborderradius', 'border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'];
                that.removeStyling(deleteEle);
                that.border_radius_dir.left = 0;
                that.border_radius_dir.right = 0;
                that.border_radius_dir.bottom = 0;
                that.border_radius_dir.top = 0;
                this.style.setProperty([key], `${value}px`, 'important');
            }
            if (value == null) {
                deleteEle = [key]
                that.removeStyling(deleteEle);
            }
        });
    }

    setSizing(value, key) {
        let block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
        let pageWidth = parseInt(block.parents('.cs_page').css('width'))
        let left = parseInt(block.css('left'))
        let maxWidth = pageWidth - left
        let pageHeight = parseInt(block.parents('.cs_page').css('height')) -10
        let top = parseInt(block.css('top')) + 10
        let elementHeight = parseInt(block.css('height'))
        let maxHeight = pageHeight - top;
        if (value) {
            if(key == 'width'){
                let right = Math.round(block.css('right').replace('px', ''));
                if(value < maxWidth){
                    $('#cs_editor').contents().find('div.cs_block_s.cs_selected').each(function () {
                        this.style.setProperty([key], `${value}px`, 'important');
                    });
                    this.maximumWidth = false;
                } else {
                    this.maximumWidth = true;
                }
            } else {
                if(value < maxHeight){
                    $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
                        this.style.setProperty([key], `${value}px`, 'important');
                    });
                    this.maximumHeight = false;
                } else {
                    this.maximumHeight = true;
                }
            }
        }
    }

    setWidth(value, key) {
        let that = this;
        let deleteEle = [];
        if(!value){
            value = 0 ;
        }
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
            if (key != 'border-width') {
                this.style.setProperty([key], `${value}px`, 'important');
                this.style.setProperty('--allsideborderwidth', `0`);
                that.border_width = 0;
            }
            else {
                deleteEle = ['--allsideborderwidth', 'border-left-width', 'border-right-width', 'border-bottom-width', 'border-top-width'];
                that.removeStyling(deleteEle);
                that.border_width_dir.left = 0;
                that.border_width_dir.right = 0;
                that.border_width_dir.bottom = 0;
                that.border_width_dir.top = 0;
                this.style.setProperty([key], `${value}px`, 'important');
            }
            if (value == null) {
                deleteEle = [key]
                that.removeStyling(deleteEle);
            }
        });
    }

    setStyle(value) {
        if (value) {
            var block : any;
            if (this.imageStyling){
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

            }else{
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
            }
            block.each(function () {
                this.style.setProperty('border-style', `${value}`, 'important');
            });
        }
    }

    setMargin(value, key) {
        let that = this;
        let deleteEle = [];
        var block : any;
        let content = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
        let pageWidth = parseInt(content.parents('.cs_page').css('width'))
        let elementWidth = parseInt(content.css('width'))
        let left = parseInt(content.css('left'))
        let marginTop = parseInt(content.css('margin-top'))
        let marginLeft = parseInt(content.css('margin-left'))
        let maxWidth = pageWidth - (left + marginLeft)
        let pageHeight = parseInt(content.parents('.cs_page').css('height')) -10
        let top = parseInt(content.css('top')) + marginTop
        let elementHeight = parseInt(content.css('height'))
        let maxHeight = pageHeight - top;

        if (value >= 0) {
            if (this.imageStyling || this.pageType == 'ECP') {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

            } else {
                // block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            let limit: boolean = false;
            block.each(function () {
                if (key != 'margin') {
                    this.style.setProperty([key], `${value}px`, 'important');
                    this.style.setProperty('--allsidemargin', `0`);
                    that.margin = 0;
                    limit = false
                } else {
                    deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                    that.removeStyling(deleteEle, true);
                    that.margin_dir.left = 0;
                    that.margin_dir.right = 0;
                    that.margin_dir.bottom = 0;
                    that.margin_dir.top = 0;
                    this.style.setProperty([key], `${value}px`, 'important');
                }
                pageWidth = parseInt(content.parents('.cs_page').css('width'))
                elementWidth = parseInt(content.css('width'))
                left = parseInt(content.css('left'))
                marginTop = parseInt(content.css('margin-top'))
                marginLeft = parseInt(content.css('margin-left'))
                maxWidth = pageWidth - (left + marginLeft)
                pageHeight = parseInt(content.parents('.cs_page').css('height')) - 10
                top = parseInt(content.css('top')) + marginTop
                elementHeight = parseInt(content.css('height'))
                maxHeight = pageHeight - top;
                if (elementWidth > maxWidth || elementHeight > maxHeight) {
                    deleteEle = [key]
                    that.removeStyling(deleteEle, true);
                    limit = true
                } else {
                    limit = false
                }
            });
            if (limit) {
                this.marginError = true;
            } else {
                this.marginError = false;
            }
        } else{
            deleteEle = [key]
            that.removeStyling(deleteEle, true);
        }
    }

    setPadding(value, key) {
        let that = this;
        let deleteEle = [];
        let content = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
        let pageWidth = parseInt(content.parents('.cs_page').css('width'))
        let elementWidth = parseInt(content.css('width'))
        let left = parseInt(content.css('left'))
        let maxWidth = pageWidth - left
        let pageHeight = parseInt(content.parents('.cs_page').css('height')) -10
        let top = parseInt(content.css('top'))
        let elementHeight = parseInt(content.css('height'))
        let maxHeight = pageHeight - top;
        if (value >= 0) {
            var block : any;
            if (this.imageStyling){
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

            }else{
                // block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            if(value < 0){
                this.paddingError = true;
                value = 0;
            }else{
                this.paddingError = false;
            }
            let limit:boolean = false;
            block.each(function () {
                if (key != 'padding') {
                    this.style.setProperty([key], `${value}px`, 'important');
                    this.style.setProperty('--allsidepadding', `0`);
                    that.padding = 0;
                }
                else {
                    deleteEle = ['--allsidepadding', 'padding-left', 'padding-right', 'padding-bottom', 'padding-top'];
                    that.removeStyling(deleteEle, true);
                    that.margin_dir.left = 0;
                    that.margin_dir.right = 0;
                    that.margin_dir.bottom = 0;
                    that.margin_dir.top = 0;
                    this.style.setProperty([key], `${value}px`, 'important');
                }
                pageWidth = parseInt(content.parents('.cs_page').css('width'))
                elementWidth = parseInt(content.css('width'))
                left = parseInt(content.css('left'))
                maxWidth = pageWidth - left
                pageHeight = parseInt(content.parents('.cs_page').css('height')) -10
                top = parseInt(content.css('top'))
                elementHeight = parseInt(content.css('height'))
                maxHeight = pageHeight - top;
                if((elementWidth > maxWidth || elementHeight > maxHeight)){
                    deleteEle = [key]
                    that.removeStyling(deleteEle, true);
                    limit = true
                } else {
                    limit = false;
                }
            });
            if(limit){
                this.paddingError = true;
            } else {
                this.paddingError = false;
            }

        }
        else {
            deleteEle = [key]
            that.removeStyling(deleteEle, true);
        }
    }

    removeStyling(deleteEle, isMarginOrPadding = false) {
        var block : any;
        if (this.imageStyling){
            block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

        }else{
            block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
        }
        block.each(function () {
            let styleEle: any = this;
            deleteEle.forEach(val => {
                styleEle.style.removeProperty(`${val}`);
            });
        });
    }

    toggle(option) {
        switch (option) {
            case 'border_width':
                if (this.border_width_dir.open) {
                    this.border_width = 0;
                    this.border_width_dir.open = false;
                    $('#border-width-icon').removeClass('selected-style')
                } else {
                    this.border_width = 0;
                    $('#border-width-icon').addClass('selected-style')
                    this.border_width_dir.open = true;
                    var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('border-width')
                    block[0].style.borderWidth = '0px';
                    this.border_width_dir.left = 0;
                    this.border_width_dir.top = 0;
                    this.border_width_dir.right = 0;
                    this.border_width_dir.bottom = 0;
                }
                break;
            case 'border_radius':
                if (this.border_radius_dir.open) {
                    this.border_radius_dir.open = false;
                    this.border_radius = 0;
                    $('#border-radius-icon').removeClass('selected-style')
                } else {
                    this.border_radius = 0;
                    var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('border-radius');
                    $('#border-radius-icon').addClass('selected-style')
                    this.border_radius_dir.open = true;
                    this.border_radius_dir.topLeft = 0;
                    this.border_radius_dir.topRight = 0;
                    this.border_radius_dir.bottomLeft = 0;
                    this.border_radius_dir.bottomRight = 0;
                }
                break;
            case 'padding':
                if (this.padding_dir.open) {
                    this.padding_dir.open = false;
                    this.padding = 0;
                    $('#padding-icon').removeClass('selected-style')
                } else {
                    this.padding = 0;
                    var block : any;
                    if (this.imageStyling){
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

                    }else{
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    }
                    // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('padding')
                    $('#padding-icon').addClass('selected-style')
                    this.padding_dir.open = true
                    this.padding_dir.left = 0;
                    this.padding_dir.top = 0;
                    this.padding_dir.right = 0;
                    this.padding_dir.bottom = 0;
                }
                break;
            case 'margin':
                if (this.margin_dir.open) {
                    this.margin_dir.open = false;
                    this.margin = 0;
                    $('#margin-icon').removeClass('selected-style')
                } else {
                    var block : any;
                    if (this.imageStyling){
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

                    }else{
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    }
                    // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('margin')
                    $('#margin-icon').addClass('selected-style')
                    this.margin = 0;
                    this.margin_dir.open = true
                    this.margin_dir.left = 0;
                    this.margin_dir.top = 0;
                    this.margin_dir.right = 0;
                    this.margin_dir.bottom = 0;
                }
                break;
        }
    }

    addBorder(e) {
        $(e.target).parent().find('.input-groups-text').addClass('input-group-border')
    }

    removeBorder(e) {
        $(e.target).parent().find('.input-groups-text').removeClass('input-group-border')
    }

    openImages() {

        const initialState = {
            title: 'upload.background.image.title.text',
            option: 'elementBackground',
            open: true,
            backgroundImage: true
        };
        this.bsModalRef = this.modalService.show(ImageBlocksComponent, {
            initialState: initialState
        });
        this.bsModalRef.content.closeBtnName = 'Close';

        this.bsModalRef.content.onClose.subscribe(result => {
            if (result) {
                var element = $("#cs_editor").contents().find("div.cs_selected").not('div.cs_page');
                if (element && element.length) {
                    $(element).css({
                        backgroundImage: `url(${result})`, backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: ''
                    })
                }
            }
        });
    }
    backgroundColor() {
    }
    borderColor() {
    }

    @HostListener('mousewheel', ['$event'])
    onScrollHideColourPalate(e) {
        $('.container-palette').addClass('sp-hidden')
    }

    rgbaToHex(r, g, b, a) {
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];

        // Pad single-digit output values
        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        })
        return ('#' + outParts.join(''));
    }
    cancel(){
        if(!this.transferToJob){
            var block : any;
            var marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            if (this.imageStyling){
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')

            } else {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
            }
            // let block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
            block.css({'background-color': this.restoreEle.background_color});
            block.css({'border-color': this.restoreEle.border_color});
            block.css('border-style', this.restoreEle.border_style, 'important');
            let pageType = $(marginBlock).parents('div.cs_page').attr('data-page-type')
            marginBlock[0].style.setProperty('width', this.restoreEle.element_width == '' ? pageType != 'ECP' ? 384 : '' : this.restoreEle.element_width);
            block[0].style.setProperty('left', this.restoreEle.position_left);
            block[0].style.setProperty('height', this.restoreEle.element_height)
            // border radius
            if (this.restoreEle.border_radius_dir.open == true) {
                let deleteEle = ['border-radius'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('--allsideborderradius', `0`);
                block[0].style.setProperty('border-top-left-radius', this.restoreEle.border_radius_dir.topLeft + 'px')
                block[0].style.setProperty('border-top-right-radius', this.restoreEle.border_radius_dir.topRight + 'px')
                block[0].style.setProperty('border-bottom-left-radius', this.restoreEle.border_radius_dir.bottomLeft + 'px')
                block[0].style.setProperty('border-bottom-right-radius', this.restoreEle.border_radius_dir.bottomRight + 'px')
            } else {
                let deleteEle = ['--allsideborderradius', 'border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('border-radius', this.restoreEle.border_radius)
            }
            // border width
            if (this.restoreEle.border_width_dir.open == true) {
                let deleteEle = ['border-width'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('--allsideborderwidth', `0`);
                block[0].style.setProperty('border-left-width', this.restoreEle.border_width_dir.left + 'px')
                block[0].style.setProperty('border-right-width', this.restoreEle.border_width_dir.right + 'px')
                block[0].style.setProperty('border-bottom-width', this.restoreEle.border_width_dir.bottom + 'px')
                block[0].style.setProperty('border-top-width', this.restoreEle.border_width_dir.top + 'px')
            } else {
                let deleteEle = ['--allsideborderwidth', 'border-left-width', 'border-right-width', 'border-bottom-width', 'border-top-width'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('border-width', this.restoreEle.border_width)
            }
            // margin
            if(this.pageType == 'ECP'){
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
            }

            if (this.restoreEle.margin_dir.open == true) {
                let deleteEle = ['margin'];
                this.removeStyling(deleteEle, true);
                marginBlock[0].style.setProperty('--allsidemargin', `0`);
                marginBlock[0].style.setProperty('margin-left', this.restoreEle.margin_dir.left)
                marginBlock[0].style.setProperty('margin-right', this.restoreEle.margin_dir.right)
                marginBlock[0].style.setProperty('margin-bottom', this.restoreEle.margin_dir.bottom)
                marginBlock[0].style.setProperty('margin-top', this.restoreEle.margin_dir.top)
            } else {
                let deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                this.removeStyling(deleteEle, true);
                if (this.restoreEle.margin != '') {
                    marginBlock[0].style.setProperty('margin', this.restoreEle.margin)
                }
            }
            // padding
            if (this.restoreEle.padding_dir.open == true) {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
                let deleteEle = ['padding'];
                this.removeStyling(deleteEle, true);
                block[0].style.setProperty('--allsidepadding', `0`);
                block[0].style.setProperty('padding-left', this.restoreEle.padding_dir.left,'important')
                block[0].style.setProperty('padding-right', this.restoreEle.padding_dir.right, 'important')
                block[0].style.setProperty('padding-bottom', this.restoreEle.padding_dir.bottom, 'important')
                block[0].style.setProperty('padding-top', this.restoreEle.padding_dir.top, 'important')
            } else {
                let deleteEle = ['--allsidepadding', 'padding-left', 'padding-right', 'padding-bottom', 'padding-top'];
                this.removeStyling(deleteEle, true);
                if (this.restoreEle.padding != '') {
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')
                    block[0].style.setProperty('padding', this.restoreEle.padding, 'important')
                }
            }
        }
        this.proposaltemplateservice.saveContentLibrary(true);
        this.bsModalRef.hide();
        this.modalService._hideModal(1);
        // this.articleService.blockSelection(false)
    }
    save(){
        if(!this.transferToJob){
            this.bsModalRef.hide();
            this.modalService._hideModal(1);
            this.proposaltemplateservice.setEditorThumbnail('updateInputElement');
            this.upgradeFreemium();
        } else {
            if(this.selectedTemplateId || this.selectedTemplateType){
                let params = {}
                if(!this.existingOptionId && !this.existingAddonId){
                    params = {
                        'screenName': this.screenName,
                        'templateId': this.proposalId,
                        'optionId': this.optionId,
                        'type': this.selectedTemplateType ? this.selectedTemplateType : '',
                        'addonId': this.selectedTemplateId ? this.selectedTemplateId : '',
                        'jobNotes': this.jobNotes,
                        'pageId': this.pageId,
                        'updateContent': false
                    }
                } else {
                    params = {
                        'screenName': this.screenName,
                        'templateId': this.proposalId,
                        'optionId': this.optionId,
                        'type': this.selectedTemplateType ? this.selectedTemplateType : '',
                        'addonId': this.selectedTemplateId ? this.selectedTemplateId : '',
                        'jobNotes': this.jobNotes,
                        'pageId': this.pageId,
                        'updateContent': true,
                        'existingTableId': this.existingTableId
                    }
                }
                this.proposaltemplateservice.saveJobNotes(params).subscribe((res) => {
                    if (res && res.id && !res.exists) {
                        let result = {
                            'templateId': res.id,
                            'optionId': this.optionId,
                            'addon': false,
                            'addonId': '',
                            'dataExists': res.dataExists,
                            'updateJobNotes': res.updateJobNotes,
                            'type': res.type
                        }
                        if (this.optionId && params['addonId']) {
                            result['addon'] = true;
                            result['addonId'] = params['addonId'];
                        }
                        this.proposaltemplateservice.setTableTemplateId(result);
                        // this.upgradeFreemium()
                    }
                });
            } else if(!this.selectedTemplateId && !this.selectedTemplateType){
                let classList = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')[0].className.split(' ')
                let searchJobString = 'job'
                let searchOptionString = 'option'
                let searchAddonString = 'addon'
                let classArray =[]
                if (classList && classList.length > 0) {
                    for (let i = 0; i < classList.length; i++) {
                        if(classList[i].startsWith(searchJobString)){
                            let array = classList[i].split('-')
                            let id = array[array.length-1]
                            let data = {
                                'tableId': id
                            }
                            this.proposaltemplateservice.deleteJobNotes(data).subscribe((res) => {
                                for (let i = 0; i < classList.length; i++) {
                                    if (classList[i].startsWith(searchJobString) || classList[i].startsWith(searchOptionString) || classList[i].startsWith(searchAddonString)) {
                                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').removeClass(classList[i])
                                    }
                                }
                            })
                        }
                    }
                }
                // this.upgradeFreemium()
            }
            this.proposaltemplateservice.saveContentLibrary(true);
            this.bsModalRef.hide();
            this.modalService._hideModal(1);
        }
        // this.articleService.blockSelection(false)
    }
    getColor(data,type){
        if(type == 'background'){
            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'background-color': data })
            this.changedBackgroundColor = data;
        } else if (type == 'border'){
            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'border-color': data });
            this.changedBorderColor = data;
            this.borderColorStyle = data;
        }
    }

    removeTextBg(){
        this.textElementBackgroundImage = '';
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').css({'background-image' : 'none'});
    }

    defaultColor(event){
        let color = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-color')
        let value = Number(event.target.value)
        if(value > 0 && (color == 'rgb(89, 91, 101)'|| color == 'rgb(80, 91, 101)')){
            this.changedBorderColor = '#595B65'
            this.borderColorStyle = this.changedBorderColor
        } else if(value == 0 && this.changedBorderColor == '#595B65'){
            this.changedBorderColor = 'No border'
            this.borderColorStyle = '#ffffff'
        } else {
            return
        }
    }
    detectChanges(data){
        let that = this;
        this.myValue = ''
        setTimeout(() => {
            that.myValue = data;
        }, 50);
    }
    enableSave(id,type,text,disabled){
        if(!disabled){
            this.jobNotesValue = text
            type == 'addon' ? this.selectedTemplateId = id : this.selectedTemplateId = '';
            this.selectedTemplateType = type
            this.closeDropdown = true
        }
    }

    upgradeFreemium() {
        let proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if(proposalEditor){
            if (this.screenType != 'content_library') {
                let data = {};
                if (this.screenType == 'option_template') {
                    data = {
                        screenType: this.screenType,
                        id: this.optionId,
                        addon: false
                    }
                } else if (this.screenType == 'addon_template') {
                    data = {
                        screenType: this.screenType,
                        id: this.optionId,
                        addon: true,
                    }
                } else if (this.screenType == 'proposal_templates') {
                    data = {
                        screenType: this.screenType,
                        id: this.proposalId,
                        addon: false
                    }
                } else if (this.screenType == 'opportunity') {
                    data = {
                        screenType: this.screenType,
                        id: this.opportunityProposalId,
                        addon: false,
                        opportunityId: this.opportunityId
                    }
                }
                this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe((res: any) => {

                })
            }
        }
    }
}
