<div class="modal-header">
    <h4 class="modal-title pull-left">Email settings</h4>
</div>

<div>
    <div class="modal-body">
        <div class="loading-wrap" *ngIf="loading">
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
        </div>
        <div *ngIf="status && isEdit && !loading" class="wfe-block-notification-bar">
            <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
        </div>
        <!--<div class="mth-5 padding-fifteen" *ngIf="!loading">
            <div class="no-results-wrap">
                <div class="warning-icon-wrap with-info-icon">
                    <span class="ss-info icon fix-left" style="margin: 1px 0 0 11px !important">&nbsp;</span>
                </div>
                <div class="warning-message">No email template found for this block</div>
            </div>
        </div>-->
        <ng-container *ngIf="!loading">
            <app-condition-edge *ngIf="isConditional"
                            [inputModel]="inputModel"
                            [isMarketPlaceView]="isMarketPlaceView"
                            [isReadOnlyMode]="isReadOnlyMode"
                            [operators]="operators"
                            [activeField] = "activeField">
            </app-condition-edge>
            <div class="item form-item p-15"   [ngClass]="{'border-top': isConditional}">
                <div class="control" *ngIf="isSmtp">
                    <label class="control-label required_field">From:</label>
                    <div>
                        <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                                csSelect2
                                [select2Config]="{}"
                                [dropdownvalue]="inputModel.model.fromEmail"
                                [(ngModel)]="inputModel.model.fromEmail">
                            <option value="" translate>Please.Choose</option>
                            <option *ngFor="let field of fromEmail" [value]="field.id">{{field.text}}</option>
                        </select>
                    </div>
                </div>
                <div class="control" *ngIf="workflowType != 'Sequence'">
                    <label class="control-label required_field">To:
                        <span style="float: right">
                            <a [hidden]="(ccShow || inputModel.model.ccEmailTag.length)" (click)="showAdditioalInput('cc')">CC</a>&nbsp;
                            <a [hidden]="(bccShow || inputModel.model.bccEmailTag.length)" (click)="showAdditioalInput('bcc')">BCC</a>
                        </span>
                    </label>
                    <div>
                        <input (selected)="selectedEmailAddress($event,'','to')"
                               [(ngModel)]="inputModel.model.toEmailTag" [dropdownvalue]="inputModel.model.toEmailTag"
                               id="additional-email"
                               csSelect2 pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                               [select2Config]="select2LabelConfig"
                               class="full-width-select2 cs-select2-wfe mb-10"/>
                    </div>
                </div>
                <div class="control" *ngIf="workflowType != 'Sequence' && (ccShow || inputModel.model.ccEmailTag.length)">
                    <label class="control-label">CC:</label>
                    <div>
                        <input (selected)="selectedEmailAddress($event,'','cc')"
                               [(ngModel)]="inputModel.model.ccEmailTag" [dropdownvalue]="inputModel.model.ccEmailTag"
                               id="additional-email-cc"
                               csSelect2 pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                               [select2Config]="select2LabelConfig2"
                               class="full-width-select2 cs-select2-wfe mb-10"/>
                    </div>
                </div>
                <div class="control" *ngIf="workflowType != 'Sequence' && (bccShow || inputModel.model.bccEmailTag.length > 0)">
                    <label class="control-label">BCC:</label>
                    <div>
                        <input (selected)="selectedEmailAddress($event,'','bcc')"
                               [(ngModel)]="inputModel.model.bccEmailTag" [dropdownvalue]="inputModel.model.bccEmailTag"
                               id="additional-email-bcc"
                               csSelect2 pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                               [select2Config]="select2LabelConfig2"
                               class="full-width-select2 cs-select2-wfe mb-10"/>
                    </div>
                </div>

                <div class="control">
                    <ng-template #popTemplate>
                        <div class="contentScroll preview-special-char">
                            <span style="font-weight: bold">Subject:</span><div [innerHtml]="subject"></div><br/>
                            <span style="font-weight: bold">Message:</span><div class="email-display-container ql-editor" [innerHtml]="message | keepHtml"></div>
                        </div>
                    </ng-template>
                    <a *ngIf="message" style="float:right;cursor:pointer;" class="btn-default btn-secondary"
                            [popover]="popTemplate"
                            [outsideClick]="true"
                            placement="left" container="body" containerClass="customClass">
                        Preview template
                    </a>
                    <label class="control-label required_field" translate>Email.Template:</label>
                    <div>
                        <!--<select class="full-width-select2 cs-select2-wfe mb-10"
                                csSelect2 (ngModelChange)="uploadTemplateDetails($event)"
                                [select2Config]="{}"
                                [dropdownvalue]="inputModel.model.templateId"
                                [(ngModel)]="inputModel.model.templateId">
                            <option value="" translate>Please.Choose</option>
                            <option *ngFor="let field of emailTemplateList" [value]="field.id">{{field.text}}</option>
                        </select>-->
                        <app-search-template [screen]="'email_template'"
                             themeName="compact"
                             [screenLabel]="emailScreenLabel"
                             [showAddNewTemplate]="false"
                             [selectedTemplate]="this.activeTemplateTitle"
                             (selectedItem)="setTemplateDetails($event,'email')">
                        </app-search-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer" [hidden]="loading">
        <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
        <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
        <button class="btn btn-primary" [disabled]="saving || validateItems()" (click)="addValue()">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</div>
