import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap';
import { DomSanitizer} from '@angular/platform-browser';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';

@Component({
    selector: 'app-video-blocks',
    templateUrl: './video-blocks.component.html',
    styleUrls: ['./video-blocks.component.scss']
})
export class VideoBlocksComponent implements OnInit {

    public onClose: Subject<boolean>;
    title: string;
	videoURL= '';
	embedUrl='';
    errorMsg: string = '';
	videoPath: any;
    public uploader: FileUploader;
	showFrame:Boolean = true;
	loadUrl : any;
	youtubeError: any = '';
	option:any;
	page_id:any;
	videoFile:any;

	constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer,
		public proposaltemplateservice: ProposalTemplateService) {
        this.onClose = new Subject();
		this.uploader = new FileUploader({
			url: ``,
			autoUpload: false,
			itemAlias: 'file',
            allowedFileType: ['video'],
			disableMultipart: true
		})
    }

    ngOnInit() {
		if(this.option == 'edit') {
			let video = $('#cs_editor').contents().find('div.cs_selected').find('iframe')
			if(video) {
				this.embedUrl = video.attr('src')
				this.transform(this.embedUrl)
			}
		}
    }

    // Select file
	selectFile(event) {
		this.embedUrl = ''
		this.errorMsg = '';this.youtubeError = ''
		event.preventDefault();
		const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
		const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
		const videofile: FileList = target.files;
		const fileType = videofile[0].type;
		const fileTypeArray = ['video/mp4', 'video/webm', 'video/ogg']
		var filesize = (videofile[0].size/(1024 * 1024));
		if (!fileTypeArray.includes(fileType)) {
			this.errorMsg = 'Please upload video'
			this.videoURL = ''
		}
		else if (filesize > 10) {
			this.errorMsg = 'The File size cannot exceed 10 mb'
			this.videoURL = ''
		}
		else {
			this.errorMsg = ''
			this.videoFile = videofile
			this.videoURL = URL.createObjectURL(videofile[0])
			this.transform(this.videoURL)
		}
	}

	// Drag and upload File
	uploadFile(event) {
		this.embedUrl = ''
		this.youtubeError = '';this.errorMsg = ''
		const videofile: FileList = event;
		const fileType = videofile[0].type;
		const fileTypeArray = ['video/mp4', 'video/webm', 'video/ogg']
		const filesize = (videofile[0].size/(1024 * 1024));
		if (!fileTypeArray.includes(fileType)) {
			this.errorMsg = 'Please upload video'
			this.videoURL = ''
		}
		else if (filesize > 10) {
			this.errorMsg = 'The File size cannot exceed 10 mb'
			this.videoURL = ''
		}
		else {
			this.errorMsg = ''
			this.videoFile = videofile
			this.videoURL = URL.createObjectURL(videofile[0])
			this.transform(this.videoURL)
		}
	}

	getVideoUrl() {
		this.videoURL = ''
		var videoobj = this.getId(this.embedUrl)
		if(videoobj) {
			this.errorMsg = '';this.youtubeError = ''
			var url;
			if(videoobj['type'] == 'youtube') {
				url = 'https://www.youtube.com/embed/' + videoobj['id'];
			} else if(videoobj['type'] == 'wistia') {
				url = videoobj['id']
			} else if(videoobj['type'] == 'loom') {
				url = videoobj['id']
			}
			this.transform(url)
		} else if(this.embedUrl == '') {
			this.youtubeError = ''
		} else {
			this.youtubeError = 'Please upload valid url'
		}
	}

    transform(url) {
		if(url && url != '') {
			this.loadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		}
    }

    addVideoBlock() {
		let node;
		var regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
		if(this.embedUrl) {
			node = document.getElementById('iframetag')
			node.height = '100%';
			setTimeout(() => {
				var videoElements = $('#cs_editor').contents().find("[tag='video']");
				videoElements.each(function(i,content){
					var element = $(content);
					if(element.find('iframe').attr('src')){
						if(element.find('iframe').attr('src').match(regExp)){
							let eHeight = element.find('iframe').height();
							let eWidth = element.find('iframe').width();
							if(element.height()<(element.width()/1.77)){
								element.css({'text-align': 'center'});
								element.find('iframe').css({'height': '100%', 'width': (eHeight * 1.77), 'max-width':'100%', 'margin-top': 0});
							} else{
								element.find('iframe').css({ 'height': (eWidth / 1.77), 'width': '100%','max-height': '100%','margin-top': (element.height() - eHeight)/2});
							}
						}
					}
				});
			}, 100);
			this.onClose.next(node)
			this.bsModalRef.hide()
		} else {
			this.proposaltemplateservice.addPageVideo(this.videoFile, this.page_id).subscribe(
				res => {
					if(res && res.hasOwnProperty('file')) {
						node = document.getElementById('videotag')
						$(node).attr('src',res['file']['location'])
						this.onClose.next(node)
						this.bsModalRef.hide()
					}
				}
			)
		}
	}

	getId(url) {
		var type;
		// var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		var regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
		var match = url.match(regExp);
		if(match) {
			match = match[1]
			type = 'youtube'
		} else if(!match) {
			regExp = /https?:\/\/[^.]+\.(?:wistia\.com|wi\.st)\/(medias|embed)\/.*/
			match = url.match(regExp)
			if(match) {
				type = 'wistia'
				if(match[1] == 'medias') {
					url = url.substring(url.lastIndexOf('/') + 1)
					match = 'https://fast.wistia.net/embed/iframe/' + url
				} else {
					match = url
				}
			} else if(!match) {
				regExp = /https?:\/\/(?:.+)?(?:loom\.com)\/(share|embed)\/([a-zA-Z0-9]*)/
				match = url.match(regExp)
				if(match) {
					type = 'loom'
					if(match[1] == 'share') {
						match =  'https://www.loom.com/embed/' + match[2]
					} else {
						match = url
					}
				}
			}
		}

		return (match)
		  ? {id:match, type}
		  : null;
	}
}
