<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delay setup</h4>
    </div>
    <div class="modal-body">
        <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
            <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
        </div>
        <app-condition-edge *ngIf="isConditional"
                            [inputModel]="inputModel"
                            [operators]="operators"
                            [isMarketPlaceView]="isMarketPlaceView"
                            [isReadOnlyMode]="isReadOnlyMode"
                            [activeField] = "activeField">
        </app-condition-edge>
        <div class="item form-item p-15">
            <div class="control">
                <label class="control-label required_field">Delay</label>
                <ng-container [ngSwitch]="inputModel.model.unit">
                    <select *ngSwitchCase="'minutes'" class="full-width-select mr-10" [(ngModel)]="inputModel.model.interval">
                        <option *ngFor="let interval of minutesInterval" value="{{interval}}">{{interval}}</option>
                    </select>
                    <select *ngSwitchCase="'hours'" class="full-width-select mr-10" [(ngModel)]="inputModel.model.interval">
                        <option *ngFor="let interval of hoursInterval" value="{{interval}}">{{interval}}</option>
                    </select>
                    <select *ngSwitchDefault class="full-width-select mr-10" [(ngModel)]="inputModel.model.interval">
                        <option *ngFor="let interval of inputModel.config.intervalList" value="{{interval}}">{{interval}}</option>
                    </select>
                </ng-container>

                <select class="full-width-select" [(ngModel)]="inputModel.model.unit" (change)="onChangeUnit($event)">
                    <option *ngFor="let unit of units" value="{{unit}}">{{unit}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
        <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
        <button class="btn btn-primary" (click)="addValue()" [disabled]="saving || validateItems()">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</ng-container>
