import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingsPpmService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsPpmService, _super);
    function SettingsPpmService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // fetching existing ppm
    SettingsPpmService.prototype.fetchPPM = function (param) {
        return this.http.get(this.getApiUrl('system_settings/list-ppm', param)).pipe();
    };
    // Add new ppm
    SettingsPpmService.prototype.addPPM = function (data) {
        return this.http.post(this.getApiUrl('ppm/add-new'), JSON.stringify(data)).pipe();
    };
    // Update ppm
    SettingsPpmService.prototype.editPPM = function (id, data) {
        return this.http.put(this.getApiUrl("ppm/" + id + "/edit"), JSON.stringify(data)).pipe();
    };
    // Checking delete option for ppm
    SettingsPpmService.prototype.isDeletePPM = function (id) {
        return this.http.get(this.getApiUrl("ppm/" + id + "/is-ppm-delete")).pipe();
    };
    // Delete ppm
    SettingsPpmService.prototype.deletePPM = function (id) {
        return this.http.delete(this.getApiUrl("ppm/" + id + "/delete")).pipe();
    };
    // get ppm configuration
    SettingsPpmService.prototype.getPpmConfiguration = function (ppmId, param) {
        return this.http.get(this.getApiUrl("ppm/" + ppmId + "/configure/list-items", param)).pipe();
    };
    // set ppm configuration
    SettingsPpmService.prototype.setPpmConfiguration = function (ppmId, data) {
        return this.http.post(this.getApiUrl("ppm/" + ppmId + "/configure"), JSON.stringify(data)).pipe();
    };
    // Checking delete option for ppm configuration
    SettingsPpmService.prototype.isDeletePPMConfig = function (id) {
        return this.http.get(this.getApiUrl("ppm/" + id + "/is-ppmconfig-delete")).pipe();
    };
    // Delete ppm configuration
    SettingsPpmService.prototype.deletePPMConfig = function (id) {
        return this.http.delete(this.getApiUrl("ppm/" + id + "/config/delete")).pipe();
    };
    // get ppm sheduling
    SettingsPpmService.prototype.getPpmSheduling = function (ppmId, configId) {
        return this.http.get(this.getApiUrl("ppm/" + ppmId + "/" + configId + "/sheduling-data")).pipe();
    };
    // update ppm sheduling
    SettingsPpmService.prototype.updatePpmConfiguration = function (ppmId, configId, data) {
        return this.http.put(this.getApiUrl("ppm/" + ppmId + "/" + configId + "/update"), JSON.stringify(data)).pipe();
    };
    // Check duplicate name or not
    SettingsPpmService.prototype.CheckDuplicateName = function (name) {
        return this.http.get(this.getApiUrl("ppm/check-ppm-name", { name: name }));
    };
    SettingsPpmService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsPpmService_Factory() { return new SettingsPpmService(i0.inject(i1.HttpClient)); }, token: SettingsPpmService, providedIn: "root" });
    return SettingsPpmService;
}(InterfaceService));
export { SettingsPpmService };
