<ng-container>
    <ng-container *ngIf="loadingTemplateMessage">
        <ng-container *ngTemplateOutlet="loading_templateMessage;"></ng-container>
    </ng-container>
<!--inset 0 8px 8px -8px rgba(0,0,0,0.2)-->
    <div class="cs-template-view-row cs-d-flex flex-column h-100 pos-rlt shadow-inset" *ngIf="noMessageView && !loadingTemplateMessage">
        <div class="cs-flex-1">
            <div class="cs-template-empty-view cs-d-flex flex-column h-100 pos-rlt">
                <div class="empty-view-icon"><i class="fas fa-exclamation-triangle"></i></div>
                <div translate>You must select a template before the preview can be dispalyed</div>
            </div>
        </div>
    </div>

    <div class="cs-template-view-row cs-d-flex flex-column h-100 pos-rlt shadow-inset" *ngIf="!noMessageView && !loadingTemplateMessage">
        <div class="cs-template-view-col cs-template-view-header">
            <div class="cs-d-flex align-items-center cs-border cs-template-view-header-inner desktop-view ml-auto mr-auto">
                <div>
                    <div class="cs-d-flex align-items-center cs-template-chooser">
                        <div class="pr-10">Show me</div>
                        <div>
                            <select csSelect2
                                    ($instance)="setSelect2PreviewModel($event)"
                                    [select2Config]="select2Options"
                                    [dropdownvalue]="previewModel"
                                    (ngModelChange)="onChangePreviewOption($event)"
                                    [(ngModel)]="previewModel">
                                <option value="email">Email</option>
                                <option [disabled]="!smsMessage" value="sms">SMS</option>
                                <option [disabled]="!letterMessage" value="letter">Letter</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="cs-d-flex align-items-center cs-template-platform-chooser">
                        <div class="pr-10">View: </div>
                        <div>
                            <div class="btn-group" role="group">
                                <button [disabled]="previewModel==='sms' || previewModel=='letter'" (click)="changeViewPort('desktop')" type="button" class="btn" [ngClass]="{ 'btn-primary-active': currentViewPort === 'desktop', 'btn-default': currentViewPort === 'mobile'}"><i class="fas fa-desktop"></i> Desktop</button>
                                <button (click)="changeViewPort('mobile')" type="button" class="btn" [ngClass]="{ 'btn-primary-active': currentViewPort === 'mobile', 'btn-default': currentViewPort === 'desktop'}"><i class="fas fa-mobile"></i> Mobile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-template-view-col cs-template-view-body flex-fill pos-rlt overflow-hidden">
            <!-- Desktop view-->
            <div class="pos-abt-full desktop-view-port overflow-hidden" [hidden]="currentViewPort !== 'desktop'">
                <ng-container *ngIf="!loadingMessage; else:emailShimmer">
                    <div class="cs-template-view-desktop-container cs-d-flex flex-column align-items-center view-port-layout desktop-view ml-auto mr-auto h-100 pos-rlt cs-slim-scrollbarxx" [appCsScrollbar]>
                        <div class="cs-template-view-desktop-header w-100 cs-border-bottom">
                            <div class="cs-d-flex align-items-center">
                                <div class="cs-email-from-image mr-10">
                                    <div class="e-avatar-element">
                                        <span class="e-avatar e-avatar-md brand-color">C</span>
                                    </div>
                                </div>
                                <div class="cs-email-from-info">
                                    <h4>{{clientDetails['name']}}</h4>
                                    <div><span class="email-address">To:</span><span class="email-address-name">someone@gmail.com</span></div>
                                </div>
                                <div class="ml-auto">
                                    <div class="cs-d-flex email-actions">
                                        <div><i class="fas fa-reply"></i></div>
                                        <div><i class="fas fa-reply-all"></i></div>
                                        <div><i class="fas fa-arrow-right"></i></div>
                                        <div><i class="fas fa-ellipsis-h"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [style.align-self]="'stretch'" class="cs-template-view-desktop-content cs-email-content" [innerHTML]="emailMessage | keepHtml"></div>
                    </div>
                </ng-container>
            </div>

            <!-- Mobile view-->
            <div class="pos-abt-full mobile-view-port overflow-hidden" [hidden]="currentViewPort !== 'mobile'">
                <div class="cs-template-view-mobile-container cs-d-flex flex-column align-items-center view-port-layout mobile-view ml-auto mr-auto h-100 pos-rlt" [appCsScrollbar]>
                    <div class="p-t--15 pos-rlt">
                        <img src="/images/mobile.png" width="402">
                        <div class="pos-abt-full">
                            <div class="mobile-mask-wrapper">
                                <ng-container *ngIf="!loadingMessage; else:smsShimmer">
                                    <div class="cs-d-flex flex-column h-100">
                                        <div class="">
                                            <div class="cs-d-flex align-items-center mobile-top-bar">
                                                <div class="p-l--20 bold">9:16</div>
                                                <div class="ml-auto p-r--20">
                                                    <span class="m-r--5"><i class="fas fa-signal"></i></span>
                                                    <span class="m-r--5"><i class="fas fa-wifi"></i></span>
                                                    <span><i class="fas fa-battery-full"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mob-header cs-border-bottom">
                                            <div class="cs-d-flex">
                                                <div class="m-l--15 m-r--20"><i class="fas fa-chevron-left"></i></div>
                                                <div class="m-r--20" *ngIf="previewModel=='email'">Email</div>
                                                <div class="m-r--20" *ngIf="previewModel=='sms'">SMS</div>
                                                <div class="m-r--20" *ngIf="previewModel=='letter'">Letter</div>
                                            </div>
                                        </div>
                                        <div class="mob-body flex-1 pos-rlt">
                                            <div style="overflow: hidden" class="pos-abt-full cs-slim-scrollbarxxx mobile-content-section mobile-content-section--{{previewModel}}" [appCsScrollbar]>
                                                <div class="cs-d-flex flex-column align-items-center" *ngIf="previewModel==='email'">
                                                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom">
                                                        <div class="cs-d-flex align-items-center">
                                                            <div class="cs-email-from-image mr-10 m-l--10">
                                                                <div class="e-avatar-element">
                                                                    <span class="e-avatar e-avatar-md brand-color">C</span>
                                                                </div>
                                                            </div>
                                                            <div class="cs-email-from-info">
                                                                <h4>{{clientDetails['name']}}</h4>
                                                                <div><span class="email-address">To:</span><span class="email-address-name">someone@gmail.com</span></div>
                                                            </div>
                                                            <div class="ml-auto m-r--10">
                                                                <div class="cs-d-flex email-actions">
                                                                    <div><i class="fas fa-ellipsis-v"></i></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [style.align-self]="'stretch'" class="p-l--10 p-r--10 p-t--5" [innerHTML]="emailMessage | keepHtml"></div>
                                                </div>
                                                <div class="cs-d-flex flex-column align-items-center" *ngIf="previewModel==='sms'">
                                                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom"  [style.background-color]="'#FFF'">
                                                        <div class="cs-d-flex align-items-center">
                                                            <div class="cs-email-from-image mr-10 m-l--10">
                                                                <div class="e-avatar-element">
                                                                    <span class="e-avatar e-avatar-md brand-color">C</span>
                                                                </div>
                                                            </div>
                                                            <div class="cs-email-from-info">
                                                                <h4>{{clientDetails['name']}}</h4>
                                                                <div><span class="email-address">To:</span><span class="email-address-name">+{{clientDetails['countryCode']}} {{randomMobileNumber}}</span></div>
                                                            </div>
                                                            <div class="ml-auto m-r--10">
                                                                <div class="cs-d-flex email-actions">
                                                                    <div><i class="fas fa-ellipsis-v"></i></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [style.align-self]="'stretch'" class="p-l--10 p-r--10 p-t--5 preview-section-{{previewModel}}">
                                                        <div class="sms-display-area" [innerHTML]="smsMessage"></div>
                                                    </div>
                                                </div>
                                                <div class="cs-d-flex flex-column align-items-center" *ngIf="previewModel=='letter'">
                                                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom"  [style.background-color]="'#FFF'">
                                                        <div class="cs-d-flex align-items-center">
                                                            <div class="cs-email-from-image mr-10 m-l--10">
                                                                <div class="e-avatar-element">
                                                                    <span class="e-avatar e-avatar-md brand-color">C</span>
                                                                </div>
                                                            </div>
                                                            <div class="cs-email-from-info">
                                                                <h4>{{clientDetails['name']}}</h4>
                                                                <div><span class="email-address">To:</span><span class="email-address-name">+{{clientDetails['countryCode']}} {{randomMobileNumber}}</span></div>
                                                            </div>
                                                            <div class="ml-auto m-r--10">
                                                                <div class="cs-d-flex email-actions">
                                                                    <div><i class="fas fa-ellipsis-v"></i></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [style.align-self]="'stretch'" class="p-l--10 p-r--10 p-t--5 preview-section-{{previewModel}}">
                                                        <div class="sms-display-area" [innerHTML]="letterMessage"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-template-view-col cs-template-view-footer">
            <div class="cs-d-flex h-100 align-items-center">
                <div class="p-l&#45;&#45;15 p-r--15 ml-auto">
                    <button (click)="closeModal()" [disabled]="isSaving" class="btn btn-plain link-color">Cancel</button>
                    <button (click)="goNextStep()" *ngIf="showNextStep" [disabled]="disabledAction"  class="btn btn-primary">
                        <span>Next</span>
                    </button>
                    <button (click)="saveAction()" *ngIf="!viewMode && !showNextStep" [disabled]="disabledSaveAction || isSaving" class="btn btn-primary" [ngClass]="{'loading': isSaving}">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #smsShimmer>
    <div class="cs-d-flex flex-column h-100">
        <div class="">
            <div class="cs-d-flex align-items-center mobile-top-bar">
                <div class="p-l--20 bold">9:16</div>
                <div class="ml-auto p-r--20">
                    <span class="m-r--5"><i class="fas fa-signal"></i></span>
                    <span class="m-r--5"><i class="fas fa-wifi"></i></span>
                    <span><i class="fas fa-battery-full"></i></span>
                </div>
            </div>
        </div>
        <div class="mob-header cs-border-bottom">
            <div class="cs-d-flex">
                <div class="m-l--15 m-r--20"><i class="fas fa-chevron-left"></i></div>
                <div class="m-r--20" *ngIf="previewModel=='email'">Email</div>
                <div class="m-r--20" *ngIf="previewModel=='sms'">SMS</div>
                <div class="m-r--20" *ngIf="previewModel=='letter'">Letter</div>
            </div>
        </div>
        <div class="mob-body flex-1 pos-rlt">
            <div class="pos-abt-full cs-slim-scrollbar mobile-content-section mobile-content-section--{{previewModel}}">
                <div class="cs-d-flex flex-column align-items-center" *ngIf="previewModel==='email'">
                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom">
                        <div class="cs-d-flex align-items-center">
                            <div class="cs-email-from-image mr-10 m-l--10">
                                <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'40px'">
                                    <div class="ph-col-2" [style.padding]="0">
                                        <div class="ph-avatar" [style.min-width]="'40px'"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="cs-email-from-info">
                                <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'280px'">
                                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-row" [style.align-items]="'center'">
                                            <div class="ph-col-8 m-b--3"></div>
                                            <div class="ph-col-4 empty m-b--3"></div>
                                            <div class="ph-col-4 sm m-b--3"></div>
                                            <div class="ph-col-8 empty"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-auto m-r--10">
                                <div class="cs-d-flex email-actions">
                                    <div><i class="fas fa-ellipsis-v"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-l--10 p-r--10 p-t--10 w-100">
                        <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" >
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-2 m-b--5"></div>
                                    <div class="ph-col-8 empty m-b--5"></div>
                                </div>
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-8 m-b--10"></div>
                                    <div class="ph-col-4 empty m-b--10 "></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-2 m-b--10"></div>
                                    <div class="ph-col-10 empty m-b--10"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-6 m-b--10"></div>
                                    <div class="ph-col-6 empty m-b--10"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-2 m-b--10"></div>
                                    <div class="ph-col-10 empty m-b--10"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-2 m-b--5 sm"></div>
                                    <div class="ph-col-10 empty m-b--5"></div>
                                    <div class="ph-col-4 m-b--5"></div>
                                    <div class="ph-col-8 empty m-b--5"></div>
                                    <div class="ph-col-2 m-b--5"></div>
                                    <div class="ph-col-10 empty m-b--5"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cs-d-flex flex-column align-items-center" *ngIf="previewModel==='sms'">
                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom"  [style.background-color]="'#FFF'">
                        <div class="cs-d-flex align-items-center">
                            <div class="cs-email-from-image mr-10 m-l--10">
                                <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'40px'">
                                    <div class="ph-col-2" [style.padding]="0">
                                        <div class="ph-avatar" [style.min-width]="'40px'"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="cs-email-from-info">
                                <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'280px'">
                                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-row" [style.align-items]="'center'">
                                            <div class="ph-col-8 m-b--3"></div>
                                            <div class="ph-col-4 empty m-b--3"></div>
                                            <div class="ph-col-4 sm m-b--3"></div>
                                            <div class="ph-col-8 empty"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-auto m-r--10">
                                <div class="cs-d-flex email-actions">
                                    <div><i class="fas fa-ellipsis-v"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-l--10 p-r--10 p-t--5 preview-section-{{previewModel}}">
                        <div class="sms-display-area" style="width:330px">
                            <div class="ph-row">
                                <div class="ph-col-12 m-b--10"></div>
                                <div class="ph-col-8 m-b--10"></div>
                                <div class="ph-col-4 empty m-b--10 "></div>
                                <div class="ph-col-10 m-b--10"></div>
                                <div class="ph-col-2 empty m-b--10"></div>
                                <div class="ph-col-6"></div>
                                <div class="ph-col-6 empty"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #emailShimmer>
    <div class="cs-template-view-desktop-container cs-d-flex flex-column align-items-center view-port-layout desktop-view ml-auto mr-auto h-100">
        <div class="cs-template-view-desktop-header w-100 cs-border-bottom">
            <div class="cs-d-flex align-items-center">
                <div class="cs-email-from-image mr-10">
                    <!--<div class="e-avatar-element">
                        <span class="e-avatar e-avatar-md brand-color"></span>
                    </div>-->
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'40px'">
                        <div class="ph-col-2" [style.padding]="0">
                            <div class="ph-avatar" [style.min-width]="'40px'"></div>
                        </div>
                    </div>
                </div>
                <div class="cs-email-from-info">
                    <!--<h4>{{clientDetails['name']}}</h4>
                    <div><span class="email-address">To:</span><span class="email-address-name">someone@gmail.com</span></div>-->
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'280px'">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row" [style.align-items]="'center'">
                                <div class="ph-col-8 m-b--3"></div>
                                <div class="ph-col-4 empty m-b--3"></div>
                                <div class="ph-col-4 sm m-b--3"></div>
                                <div class="ph-col-8 empty m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="cs-d-flex email-actions">
                        <div><i class="fas fa-reply"></i></div>
                        <div><i class="fas fa-reply-all"></i></div>
                        <div><i class="fas fa-arrow-right"></i></div>
                        <div><i class="fas fa-ellipsis-h"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-template-view-desktop-content cs-email-content" [style.width]="'100%'">
            <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" >
                <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                    <div class="ph-row m-b--15">
                        <div class="ph-col-2 m-b--5"></div>
                        <div class="ph-col-8 empty m-b--5"></div>
                    </div>

                    <div class="ph-row m-b--15">
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-8 m-b--10"></div>
                        <div class="ph-col-4 empty m-b--10 "></div>
                        <div class="ph-col-10 m-b--10"></div>
                        <div class="ph-col-2 empty m-b--10"></div>
                    </div>

                    <div class="ph-row m-b--15">
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-10 m-b--10"></div>
                        <div class="ph-col-2 empty m-b--10"></div>
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-2 m-b--10"></div>
                        <div class="ph-col-10 empty m-b--10"></div>
                    </div>

                    <div class="ph-row m-b--15">
                        <div class="ph-col-10 m-b--10"></div>
                        <div class="ph-col-2 empty m-b--10"></div>
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-6 m-b--10"></div>
                        <div class="ph-col-6 empty m-b--10"></div>
                    </div>

                    <div class="ph-row m-b--15">
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-10 m-b--10"></div>
                        <div class="ph-col-2 empty m-b--10"></div>
                        <div class="ph-col-12 m-b--10"></div>
                        <div class="ph-col-2 m-b--10"></div>
                        <div class="ph-col-10 empty m-b--10"></div>
                    </div>

                    <div class="ph-row m-b--15">
                        <div class="ph-col-2 m-b--5 sm"></div>
                        <div class="ph-col-10 empty m-b--5"></div>
                        <div class="ph-col-4 m-b--5"></div>
                        <div class="ph-col-8 empty m-b--5"></div>
                        <div class="ph-col-2 m-b--5"></div>
                        <div class="ph-col-10 empty m-b--5"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loading_templateMessage>
    <div class="cs-template-view-row cs-d-flex flex-column h-100 pos-rlt shadow-inset">
        <div class="cs-template-view-col cs-template-view-header">
            <div class="cs-d-flex align-items-center cs-border cs-template-view-header-inner desktop-view ml-auto mr-auto">
                <div>
                    <div class="cs-d-flex align-items-center cs-template-chooser">
                        <div class="ph-item height-100 no-bg" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'280px'">
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row" [style.align-items]="'center'">
                                    <div class="ph-col-5 big2"></div>
                                    <div class="ph-col-1 big2 empty"></div>
                                    <div class="ph-col-6 big2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="cs-d-flex align-items-center cs-template-platform-chooser">
                        <div class="ph-item height-100 no-bg" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'220px'">
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row" [style.align-items]="'center'" [style.justify-content]="'end'">
                                    <div class="ph-col-2 big2"></div>
                                    <div class="ph-col-1 big2 empty"></div>
                                    <div class="ph-col-4 big2" style="margin-right: 1px;"></div>
                                    <div class="ph-col-4 big2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-template-view-col cs-template-view-body flex-fill pos-rlt overflow-hidden">
            <div class="pos-abt-full desktop-view-port overflow-hidden">
                <div class="cs-template-view-desktop-container cs-d-flex flex-column align-items-center view-port-layout desktop-view ml-auto mr-auto w-100 h-100">
                    <div class="cs-template-view-desktop-header w-100 cs-border-bottom">
                        <div class="cs-d-flex align-items-center">
                            <div class="cs-email-from-image mr-10">
                                <div class="ph-item height-100 no-bg" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'40px'">
                                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-avatar" style="min-width: 40px"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="cs-email-from-info">
                                <div class="ph-item height-100 no-bg" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'220px'">
                                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-row" [style.align-items]="'center'">
                                            <div class="ph-col-8 big m-b--5"></div>
                                            <div class="ph-col-4 big empty m-b--5"></div>
                                            <div class="ph-col-10 sm"></div>
                                            <div class="ph-col-2 sm"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-auto">
                                <div class="ph-item height-100 no-bg" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'95px'">
                                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-row" [style.align-items]="'center'" [style.justify-content]="'end'">
                                            <div class="ph-avatar" style="min-width: 20px; width: 20px; height: 20px; margin-right: 5px"></div>
                                            <div class="ph-avatar" style="min-width: 20px; width: 20px; height: 20px; margin-right: 5px"></div>
                                            <div class="ph-avatar" style="min-width: 20px; width: 20px; height: 20px; margin-right: 5px"></div>
                                            <div class="ph-avatar" style="min-width: 20px; width: 20px; height: 20px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs-template-view-desktop-content cs-email-content" [style.width]="'100%'">
                        <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'100%'" >
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-2 m-b--5"></div>
                                    <div class="ph-col-8 empty m-b--5"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-8 m-b--10"></div>
                                    <div class="ph-col-4 empty m-b--10 "></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                </div>

                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-2 m-b--10"></div>
                                    <div class="ph-col-10 empty m-b--10"></div>
                                </div>
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-6 m-b--10"></div>
                                    <div class="ph-col-6 empty m-b--10"></div>
                                </div>
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-10 m-b--10"></div>
                                    <div class="ph-col-2 empty m-b--10"></div>
                                    <div class="ph-col-12 m-b--10"></div>
                                    <div class="ph-col-2 m-b--10"></div>
                                    <div class="ph-col-10 empty m-b--10"></div>
                                </div>
                                <div class="ph-row m-b--15">
                                    <div class="ph-col-2 m-b--5 sm"></div>
                                    <div class="ph-col-10 empty m-b--5"></div>
                                    <div class="ph-col-4 m-b--5"></div>
                                    <div class="ph-col-8 empty m-b--5"></div>
                                    <div class="ph-col-2 m-b--5"></div>
                                    <div class="ph-col-10 empty m-b--5"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-template-view-col cs-template-view-footer">
            <div class="cs-d-flex h-100 align-items-center">
                <div class="p-l&#45;&#45;15 p-r--15 ml-auto">
                    <button [disabled]="true"  class="btn btn-plain link-color">Cancel</button>
                    <button *ngIf="showNextStep" [disabled]="true"  class="btn btn-primary">
                        <span>Next</span>
                    </button>
                    <button *ngIf="!viewMode && !showNextStep" [disabled]="true" class="btn btn-primary">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
