import { ElementRef } from '@angular/core';
var CellColorDirective = /** @class */ (function () {
    function CellColorDirective(elementRef) {
        this.elementRef = elementRef;
        this.column = {};
        this.value = '';
    }
    CellColorDirective.prototype.ngOnChanges = function () {
        if (this.column.color) {
            var _a = this.column.color, text = _a.text, background = _a.background;
            if (background)
                this.elementRef.nativeElement.style.background = background;
            if (text)
                this.elementRef.nativeElement.style.color = text;
        }
    };
    return CellColorDirective;
}());
export { CellColorDirective };
