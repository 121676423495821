import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ErrorHandlerService = /** @class */ (function () {
    function ErrorHandlerService() {
        this.error = new BehaviorSubject(null);
        this.$error = this.error.asObservable();
    }
    ErrorHandlerService.prototype.setError = function (error) {
        console.log('Error occurred', error);
        this.error.next(error || null);
    };
    ErrorHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
