import { HttpHeaders } from '@angular/common/http';

//--
import { InterfaceService } from './service.interface';
import { HybridFactory } from '@app/core/hybrid-factory';


export class InterfaceResolver extends InterfaceService {
    // Handling resolver data fetch fails
    protected fail() {
        HybridFactory.navigate('/404');
        return null;
    }
}