<form *ngIf="form" [formGroup]="form" class="no-form-style cs-trigger" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <div class="modal-body ng-workflow" [hidden]="!showTaskBlock">
<!--        <div class="help_section p-15">-->
<!--            <div class="help-text" translate>-->
<!--                Parts.configure.text-->
<!--            </div>-->
<!--        </div>-->
        <div *ngIf="diagramOptions.isRunningStatus" class="wfe-block-notification-bar">
            <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
        </div>
        <div class="body-content-section p-15 b-bottom">
            <ng-container *ngIf="isScheduler" formGroupName="scheduler">
                <div class="row-fluid">
                    <div class="span12">
                        <div class="cs-d-flex flex-column ">
                            <div>
                                <label class="control-label" translate>Scheduling.Type</label>
                                <div class="controls">
                                    <select class="full-width-select" formControlName="type" (change)="chooseScheduler()">
                                        <option *ngFor="let stype of getSchedulingType()" value="{{stype.value}}">{{stype.text}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="cs-d-flex align-items-end">
                            <div class="">
                                <label class="control-label" translate>Choose.Time</label>
                                <div class="controls">
                                    <cs-timepicker formControlName="fromTime" [meridian]="false" (valueChanged)="null" class=""></cs-timepicker>
                                </div>
                            </div>
                            <div *ngIf="activeScheduler == 'weekly'">
                                <label class="control-label" translate>Choose.Day</label>
                                <div class="controls">
                                    <div class="btn-group cs-schedule-weekly" formGroupName="weekdays">
                                        <label formControlName="mon" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Mon</label>
                                        <label formControlName="tue" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Tue</label>
                                        <label formControlName="wed" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Wed</label>
                                        <label formControlName="thu" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Thu</label>
                                        <label formControlName="fri" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Fri</label>
                                        <label formControlName="sat" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Sat</label>
                                        <label formControlName="sun" class="btn btn-primary" btnCheckbox tabindex="0" role="button">Sun</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="activeScheduler == 'monthly'">
                                <label class="control-label" translate>Select.Date</label>
                                <div class="controls">
                                    <select class="full-width-select" formControlName="fromDate">
                                        <option *ngFor="let fdate of dateList" value="{{fdate.value}}">{{fdate.text}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <hr/>
            </ng-container>

            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix">
                        <label class="control-label" translate>Main.Object</label>
                        <div class="controls">
                            <select class="full-width-select" formControlName="mainObject" (change)="onSelectMainObject()">
                                <option value="" translate="">Please.Choose</option>
                                <option *ngFor="let trigger of triggerObjects | keyvalue" value="{{trigger.key}}">{{trigger.value.text}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="control-group clear-fix" *ngIf="form.get('mainObject').value == 'certificates'">
                        <label class="control-label" translate>{{activeMetaDatas.subObjectName}}</label>
                        <select class="full-width-select" formControlName="dependentId" (change)="onSelectDependent()" [attr.disabled]="isMarketPlaceView ? '' : null">
                            <option value="" translate="">Please.Choose</option>
                            <option *ngFor="let item of dependencyDropDownList" value="{{item.id}}">{{item.text}}</option>
                        </select>
                        <validation-error *ngIf="validation" class="">Please Activates The Certificates Too Add Fields</validation-error>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <div class="validation_views trigger-rules-view">
                        <div class="validation_list">
                            <div class="item form-item" formArrayName="rules" *ngFor="let rule of form.controls.rules.controls; let i = index">
                                <ng-container *ngIf="!validation" [formGroupName]="i">
                                    <div class="control">
                                        <div class="item-actions float-right">
                                            <a [hidden]="isMarketPlaceView" href="javascript:void(0)" (click)="onBegainDeleteAction(rule, i)">Delete</a>
                                        </div>
                                        <label class="control-label required_field">Condition {{i+1}}</label>
                                        <select id="cs_fieldname_{{i}}"
                                                csSelect2
                                                [attr.disabled]="isMarketPlaceView ? '' : null"
                                                [select2Config]="select2Config"
                                                class="full-width-select cs-select2-wfe mb-10 default-input-height"
                                                formControlName="col_name" (selected)="onSelectField($event, i)">
                                            <option value="" translate [disabled]="true">Please.Choose</option>
                                            <option *ngFor="let field of fieldsStatic" [value]="field.col_name"
                                                    [disabled]="selectedColName.indexOf(field.col_name) > -1"
                                            >{{field.text}}</option>
                                            <!-- Custom fields-->
                                            <optgroup *ngFor="let template of customFields" [label]="'Custom fields: '+template['description']">
                                                <option *ngFor="let field of template['fields']" [value]="field.col_name"
                                                        [disabled]="selectedColName.indexOf(field.col_name) > -1">{{field.text}}</option>
                                            </optgroup>
                                            <!-- Certificate fields-->
                                            <optgroup *ngIf="dynamicFields?.length > 0" label="Dynamic fields">
                                                <option *ngFor="let item of dynamicFields" [value]="item.col_name"
                                                        [disabled]="selectedColName.indexOf(item.col_name) > -1">{{item.text}}</option>
                                            </optgroup>

                                        </select>

                                        <div class="condition-item" *ngIf="f.rules.value[i].col_name != ''">
                                            <div class="d-block">
                                                <select class="" formControlName="operator" (change)="onSelectOperator($event, i)" [attr.disabled]="isMarketPlaceView ? '' : null">
                                                    <option *ngFor="let operator of activeInputs[f.rules.value[i].col_name].operators" value="{{operator}}">{{operators[operator]}}</option>
                                                </select>
                                            </div>

                                            <div class="d-block" [ngSwitch]="true">
                                                <div *ngSwitchCase="activeInputs[f.rules.value[i].col_name].input === 'select' || activeInputs[f.rules.value[i].col_name].input === 'checkboxList'">
                                                    <select
                                                        [attr.disabled]="makeAsDisabled(f.rules.value[i].col_name) ? '' : null"  class="" formControlName="value" *ngIf="!['in', 'not_in'].includes(f.rules.value[i].operator)">
                                                        <option value="" translate>Please.Choose</option>
                                                        <option *ngFor="let option of activeInputs[f.rules.value[i].col_name].options" value="{{option.id}}">{{option.text}}</option>
                                                    </select>
                                                    <ng-container *ngIf="['in', 'not_in'].includes(f.rules.value[i].operator)">
                                                        <input [attr.disabled]="isReadOnlyMode ? '' : null" (selected)="onSelectMultipleSelect($event, i)"
                                                               formControlName="value"
                                                               id="multiple_select_{{i}}"
                                                               csSelect2
                                                               [select2Config]="{
                                                          multiple: true,
                                                          data: {
                                                            text: dataFunction,
                                                            results : activeInputs[f.rules.value[i].col_name].options
                                                          }
                                                    }" class="full-width-select2"/>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="activeInputs[f.rules.value[i].col_name].input === 'number'">
                                                    <input [attr.disabled]="makeAsDisabled(f.rules.value[i].col_name) ? '' : null"  type="text" formControlName="value" class="control-input" appDigitDecimaNumber/>
                                                </div>
                                                <div *ngSwitchCase="activeInputs[f.rules.value[i].col_name].input === 'text'">
                                                    <input [attr.disabled]="makeAsDisabled(f.rules.value[i].col_name) ? '' : null"  type="text" formControlName="value" class="control-input"/>
                                                </div>
                                                <div *ngSwitchCase="activeInputs[f.rules.value[i].col_name].input === 'checkbox'">
                                                    <input [attr.disabled]="makeAsDisabled(f.rules.value[i].col_name) ? '' : null" type="checkbox" formControlName="value" class="control-input"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-line" [hidden]="isMarketPlaceView">
                                        <span class="bar"></span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="btn-add-section" *ngIf="checkToShowAddRule()">
                                <div class="item-line">
                                    <div class="bar"></div>
                                    <div class="plus_circle" (click)="addRule()"><i class="fas fa-plus" style="font-size:18px"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Cancel</a>
        <button class="btn btn-primary" [disabled]="!form.valid || r.length==0 || saving || action == 'saving'">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</form>

<cs-modal [show]="openDeleteConfirmation" [ngClass]="{'wef-trigger-delete-modal': true}" (onClose)="null">
    <ng-container>
        <!-- Modal header -->
        <div class="modal-header">
            <button type="button" class="close" (click)="openDeleteConfirmation = false">×</button>
            <h4 translate>Delete.Condition</h4>
        </div>
        <ng-container>
            <!-- Modal body -->
            <div class="modal-body ">
                <div class="row-fluid">
                    <p translate>Please confirm you wish to delete this condition. By delete this condition you will delete it throughout WFE.</p>
                    <p translate>To confirm you wish to delete the condition please type 'DELETE' in the box below and click archive.</p>
                </div>
                <div class="input-prepend box-shadow input-fullwidth row-fluid">

                    <span class="add-on">
                      <span class="ss-caution"></span>
                    </span>
                    <div class="input-wrapper">
                        <input type="text" class="modalDeleteNumber" [(ngModel)]="deleteConfirmTextInput" id="confirm_delete_input" (keyup)="checkConfirmText()" (keyup.enter)="removeTriggerCondition()"/>
                    </div>
                </div>
                <div>
                    <span class="control-error-message show row-fluid" *ngIf="inValidConfirmText">
                        <span translate [translateParams]="{Object: validationMessage}"> You.need.to.type.the.word.Object</span>
                    </span>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <div>
                    <a href="javascript:void(0)" (click)="openDeleteConfirmation = false" class="btn btn-cancel" translate>Cancel</a>
                    <button (click)="removeTriggerCondition()" [disabled]='inValidConfirmText || deleteConfirmTextInput==""' type="button" class="btn btn-danger btn-medium" translate>Delete</button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</cs-modal>
