import * as tslib_1 from "tslib";
import Handsontable from 'handsontable';
export function HansontableDecimal(scale) {
    if (scale === void 0) { scale = 4; }
    var HansontableDecimalEditor = /** @class */ (function (_super) {
        tslib_1.__extends(HansontableDecimalEditor, _super);
        function HansontableDecimalEditor(hotInstance, row, col, prop, TD, cellProperties) {
            var _this = _super.call(this, hotInstance, row, col, prop, TD, cellProperties) || this;
            _this.onInit = true;
            _this.specialKeys = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
            if (scale === 0) {
                _this.regex = new RegExp('^\\d*\\.?\\d$', 'g');
            }
            else {
                _this.regex = new RegExp('^\\d*\\.?\\d{0,' + scale + '}$', 'g');
            }
            return _this;
        }
        HansontableDecimalEditor.prototype.createElements = function () {
            _super.prototype.createElements.call(this);
            this.TEXTAREA = document.createElement('TEXTAREA');
            this.$textarea = jQuery(this.TEXTAREA);
            this.TEXTAREA.className = 'handsontableInput';
            this.textareaStyle = this.TEXTAREA.style;
            Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
            Handsontable.dom.empty(this.TEXTAREA_PARENT);
            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
            Handsontable.dom.addEvent(this.TEXTAREA, 'keydown', this.onBeforeKeyDown.bind(this));
        };
        HansontableDecimalEditor.prototype.onBeforeKeyDown = function (event) {
            var target = event.target;
            var keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
            if (['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'].indexOf(event.key) !== -1) {
                return;
            }
            if (keys.indexOf(event.key) > -1 && this.TEXTAREA.value.trim() === '') {
                this.TEXTAREA.value = event.key;
            }
            var current = this.TEXTAREA.value;
            var position = this.TEXTAREA.selectionStart;
            var next = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        };
        return HansontableDecimalEditor;
    }(Handsontable.editors.TextEditor));
    return HansontableDecimalEditor;
}
