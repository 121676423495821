import { MicroserviceHelperService } from '@app/shared/microservice/helper/microservice-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "../../helper/microservice-helper.service";
var MicroserviceHttpTokenInterceptorService = /** @class */ (function () {
    function MicroserviceHttpTokenInterceptorService(microserviceHelperService) {
        this.microserviceHelperService = microserviceHelperService;
    }
    MicroserviceHttpTokenInterceptorService.prototype.intercept = function (request, next) {
        if (this.microserviceHelperService.isInterceptableRequest(request.url)) {
            if (this.microserviceHelperService.isTokenExist()) {
                var tokenHeaders = this.microserviceHelperService.getTokenHeaders();
                if (Array.isArray(tokenHeaders) && tokenHeaders.length > 0) {
                    tokenHeaders.forEach(function (header) {
                        if ('name' in header && 'value' in header) {
                            // clone the http request
                            request = request.clone({
                                headers: request.headers
                                    .set(header.name, header.value)
                            });
                        }
                    });
                }
            }
        }
        return next.handle(request);
    };
    MicroserviceHttpTokenInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function MicroserviceHttpTokenInterceptorService_Factory() { return new MicroserviceHttpTokenInterceptorService(i0.inject(i1.MicroserviceHelperService)); }, token: MicroserviceHttpTokenInterceptorService, providedIn: "root" });
    return MicroserviceHttpTokenInterceptorService;
}());
export { MicroserviceHttpTokenInterceptorService };
