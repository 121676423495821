import { navigateToAjs } from './navigator';
import { UPGRADED_ROUTES } from '../config';
import { environment } from '../../environments/environment';
import { UpgradeModule } from "@angular/upgrade/static";
import { resolveUrl } from "@app/core/resolve-url";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgZone } from "@angular/core";
import { CanDeactivateGuard } from "@app/services";
import { SettingsService } from "@app/core";

var pathToNav;
var apiHost = environment.apiHost;

export class HybridFactory {
    static navigate: (path: string, params?: Object) => void = navigateToAjs;
    static getEnvironment(): Object {
        return JSON.parse(JSON.stringify(environment));
    }

    //***************************************************************************
    //                    ANGULAR UPGRADE FUNCTIONS
    //***************************************************************************
    static ngUpgrade: UpgradeModule;

    static isUpgraded(url: string): boolean {
        let presentInUpgradedList = UPGRADED_ROUTES.find(pathPattern => {
            return (new RegExp(pathPattern, 'g')).test(url);
        });
        return !!presentInUpgradedList;
    }

    static routeContainerCleanedUp: boolean = false;

  static urlSwitchedDueToVersion: boolean = false;

  static popstate: string;

    static previousState: {
        name: string;
        url: string;
        params: Object;
    };

    static transitState: {
        name: string;
        url: string;
    };

    // Get AngularJs state from url
    static getState($state, url, queryParams): { state: any; params: Object } {
        let match = $state.get().find((state: any) => {
            if (!state.name || state.abstract || !state.$$state) return false;

            let privatePortion = state.$$state();

            if (!privatePortion.url) return false;

            return privatePortion.url.exec(url, queryParams);

        });

        return match ? { state: match, params: match.$$state().url.exec(url, queryParams) } : null;
    }

    static redirectNg7Route(route: string) {
        let router = HybridFactory.ngUpgrade.injector.get(Router),
            location = HybridFactory.ngUpgrade.injector.get(Location),
            $state = HybridFactory.ngUpgrade.$injector.get('$state'),
            ngZone = HybridFactory.ngUpgrade.injector.get(NgZone);
        let url = resolveUrl(route),
            path = location.normalize(url.pathname);
        ngZone.run(() => {
            $state.go('loggedin.empty');
            HybridFactory.routeContainerCleanedUp = true;
            router.navigateByUrl(path + url.search + url.hash)
        });
    }

    // AngularJs location change start hook
    static onLocationChangeStart(event, next, full) {

        let router = HybridFactory.ngUpgrade.injector.get(Router),
            location = HybridFactory.ngUpgrade.injector.get(Location),
            ngZone = HybridFactory.ngUpgrade.injector.get(NgZone),
            $state = HybridFactory.ngUpgrade.$injector.get('$state');

        let url = resolveUrl(next),
            path = location.normalize(url.pathname),
            isUpgraded = HybridFactory.isUpgraded(decodeURIComponent(path));

        // This is if we get this from statechange for switching to a new/old UI views
        if (HybridFactory.urlSwitchedDueToVersion) {
            HybridFactory.urlSwitchedDueToVersion = false;
            event.preventDefault();
            pathToNav = resolveUrl(full).pathname;
            HybridFactory.transitState = {
                name: $state.current.name,
                url: $state.href($state.current.name)
            };
            $state.go('loggedin.empty');
            return;
        }

        // if ('clientsconfig' in event.targetScope.accessDetails &&
        //     'NewPropertyView' in event.targetScope.accessDetails.clientsconfig) {
            let regex = new RegExp("^/customers/(customer|customer_list|work_address)/(\\d+)/view/property/view");
            if (regex.test(path)) {

              let groupings = regex.exec(path);
              ngZone.run(() => router.navigateByUrl('/customers/' + groupings[1] + '/' + groupings[2] + '/view_v2/property/view'));

              HybridFactory.urlSwitchedDueToVersion = true;
              event.preventDefault();
              return;

            }
        // }

        let pathName = window.location.pathname;
        localStorage.setItem('redirectTo', pathName);

       if ((next == apiHost + "/system_settings" || next == apiHost + "/company_settings" || next.includes('_settings#'))) {
          let pathName = window.location.pathname;
            //console.log("<--->1:"+pathName);
            if( localStorage.getItem('redirectTo') != "/settings" && localStorage.getItem('redirectTo') != "" ) {
                localStorage.setItem('redirectTo', pathName);
            }
            navigateToAjs('/settings?prevSetting=' + pathName);
       } else {
           if( next.includes('settings#') ) {

                let pathName = window.location.pathname;
                //console.log("<--->"+pathName);
                navigateToAjs('/settings?prevSetting=' + pathName);
                if( localStorage.getItem('redirectTo') != "/settings" && localStorage.getItem('redirectTo') != "" ) {
                    localStorage.setItem('redirectTo', pathName);
                }
                //router.navigateByUrl('/settings?prevSetting=' + pathName);
                event.preventDefault();
                //return;
           }
       }


        if (HybridFactory.popstate || (isUpgraded && HybridFactory.routeContainerCleanedUp)) {
            event.preventDefault();
        }
        // Preventing repeated navigation on, ng2 to ng redirection
        else if (!HybridFactory.popstate && !isUpgraded && pathToNav == path && $state.current.name !== 'loggedin.empty') {
            event.preventDefault();
        }

        // Preventing navigation for the unsaved changes warning confirmation in any angular7 component
        HybridFactory.checkForNavigationConfirmation(event, url.pathname + url.hash);

        pathToNav = path;

        // Avoid AngularJs navigation for AngularES routes
        if (!event.defaultPrevented) {
            ngZone.run(() => router.navigateByUrl(path + url.search + url.hash));
        }

        // Handle popstate events
        else if (HybridFactory.popstate) {

            let popstateIsUpgraded = HybridFactory.isUpgraded(HybridFactory.popstate),
                popstate = resolveUrl(HybridFactory.popstate);

            pathToNav = popstate.pathname;

            // Fill up the AngularJs ui-view, While getting back to the ng-ng2 transition state
            if (
                !popstateIsUpgraded
                && $state.current.name == "loggedin.empty"
                && HybridFactory.transitState
            ) {

                let toState = HybridFactory.getState($state,
                    HybridFactory.transitState.url == popstate.pathname ? HybridFactory.transitState.url : popstate.pathname, {});

                if (toState) $state.go(toState.state.name, toState.params);

                HybridFactory.routeContainerCleanedUp = false;
                HybridFactory.transitState = null;

            }

            // Transit AngularJs state with respect to the location
            else if (!popstateIsUpgraded && $state.current.name !== "loggedin.empty") {

                let matchedState = HybridFactory.getState($state, popstate.pathname, {});
                if (matchedState) $state.go(matchedState.state.name, matchedState.params);

            }
            // Clean up AngularJs ui-view, While ng-ng2 popstate transition
            else if (!HybridFactory.routeContainerCleanedUp && popstateIsUpgraded) {

                HybridFactory.transitState = {
                    name: $state.current.name,
                    url: $state.href($state.current.name)
                };
                $state.go('loggedin.empty');
                HybridFactory.routeContainerCleanedUp = true;
            }

        }

        HybridFactory.popstate = null;
    }

    static onStateChangeStart(event, toRoute, toParams, fromRoute, fromParams) {
        let router = HybridFactory.ngUpgrade.injector.get(Router),
            location = HybridFactory.ngUpgrade.injector.get(Location),
            ngZone = HybridFactory.ngUpgrade.injector.get(NgZone),
            $state = HybridFactory.ngUpgrade.$injector.get('$state');

        // if ('clientsconfig' in event.targetScope.accessDetails &&
        //     'NewPropertyView' in event.targetScope.accessDetails.clientsconfig) {
            if (toRoute.name == 'loggedin.customer_list.view.property') {

              ngZone.run(() => router.navigateByUrl('/customers/'+toParams['type']+'/' + toParams['id'] + '/view_v2/property/view'));

              HybridFactory.urlSwitchedDueToVersion = true;
              event.preventDefault();
              return;

            }
        // }

      HybridFactory.checkForNavigationConfirmation(event, toRoute.url);
  }

    // AngularJs location change success hook
    static onLocationChangeSuccess(event, caller: 'NG' | 'NG2', rootScope) {
        let $state = HybridFactory.ngUpgrade.$injector.get('$state'),
            settingsService = HybridFactory.ngUpgrade.$injector.get('NgSettingsService'),
            isUpgraded = HybridFactory.isUpgraded(decodeURIComponent(pathToNav));

        const $rootScope =  HybridFactory.ngUpgrade.$injector.get('$rootScope');
        /*new change start - screen overlap issue */
        const subscription =  HybridFactory.ngUpgrade.ngZone.onMicrotaskEmpty.subscribe(() => {
            if ($rootScope.$$phase && ($rootScope.$$phase === "$apply" || $rootScope.$$phase === "$digest") ) {
                $rootScope.$$phase = null;
                //console.trace('My-trace');
                return $rootScope.$evalAsync();
            }
            // return $rootScope.$digest();
        });
        $rootScope.$on('$destroy', () => { subscription.unsubscribe(); });
        /*new change end*/

        if (isUpgraded && (
            caller == 'NG2'
            || $state.current.name == "" // This is to prevent show mobile device warning on page refresh
        )) {
            // Route back to AngularJS pages if its bookmarked

            if (!HybridFactory.routeContainerCleanedUp) {
                HybridFactory.transitState = {
                    name: $state.current.name,
                    url: $state.href($state.current.name)
                };
                $state.go('loggedin.empty');
            } else {
                // Update active tab for angularES navigation
                if (event.url && rootScope) {
                    let tabValue = event.url.split('/');
                    if (tabValue[1] != null && tabValue[1] != '') {
                        rootScope.selectedTab = tabValue[1];
                    }
                }
            }
            HybridFactory.routeContainerCleanedUp = true;
        } else HybridFactory.routeContainerCleanedUp = false;
    }

    // AngularJs state change success hook
    static onStateChangeSuccess(toRoute, toParams, fromRoute, fromParams) {
        HybridFactory.previousState = {
            name: fromRoute.name,
            url: fromRoute.url,
            params: fromParams
        };
    }

    static checkForNavigationConfirmation(event, url: string) {
        if (!event.defaultPrevented && CanDeactivateGuard.confirmationCallback) {
            CanDeactivateGuard.invoke(url);
            if (!CanDeactivateGuard.invokeResult) event.preventDefault();
        }
    }
    // ***************************************************************************
}
