import {Directive, ElementRef, HostListener} from '@angular/core';

declare var $:any;

@Directive({
    selector: '[appCertificateContainerResize]'
})
export class CertificateContainerResizeDirective {
    _window : any;
    constructor(private _elementRef: ElementRef) {
        this._window = $(window);
    }

    ngAfterViewInit(){
        let el = $(this._elementRef.nativeElement).css({'overflow': 'hidden', 'overflow-y': 'auto'});
        el.css('height', ((this._window.height() - (el.offset().top) ) - 80)  + 'px');
        $('.widget-body').height(this._window.height());
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let h = this._window.height();
        let el = $(this._elementRef.nativeElement);
        let widgetHeight = h  + window.pageYOffset;
        $('.widget-body').height(widgetHeight);
        if(widgetHeight > (el.offset().top)) {
            el.css('height', (widgetHeight - (el.offset().top)) + 'px');
        }
    }
}


