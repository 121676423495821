import * as tslib_1 from "tslib";
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
//--
import { InterfaceClass } from '@app/interfaces';
import { AppValidators } from './app-validators';
// Temporary storage -----------------------------------------------------------------------
var ValidationDepotService = /** @class */ (function () {
    function ValidationDepotService() {
        this.activeValidationSource = new BehaviorSubject('');
        this.activeValidation$ = this.activeValidationSource.asObservable();
    }
    ValidationDepotService.prototype.setActiveValidation = function (name) {
        this.activeValidationSource.next(name || '');
    };
    return ValidationDepotService;
}());
export { ValidationDepotService };
// Validation message component --------------------------------------------------------------
var ValidationErrorComponent = /** @class */ (function () {
    function ValidationErrorComponent(validatinDepot) {
        this.validatinDepot = validatinDepot;
        this.hideComponentMessage = false;
        this.showOnDirty = false;
    }
    Object.defineProperty(ValidationErrorComponent.prototype, "errorMessage", {
        get: function () {
            if (!this.formGroup || !this.controlName)
                return;
            var control = this.formGroup.controls[this.controlName];
            for (var propertyName in control.errors) {
                if (control.errors.hasOwnProperty(propertyName)
                    && ((this.showOnDirty && control.dirty) || control.touched)) {
                    var errorMsg = AppValidators.getValidatorErrorMessages(control.errors[propertyName], this.controlName)[propertyName];
                    this.validatinDepot.setActiveValidation(propertyName);
                    if (errorMsg)
                        return errorMsg;
                    else
                        return control.errors[propertyName];
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return ValidationErrorComponent;
}());
export { ValidationErrorComponent };
// Customer vaidation error message component -----------------------------------------------------
var ValidationCustomErrorMessageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationCustomErrorMessageComponent, _super);
    function ValidationCustomErrorMessageComponent(validationDepot) {
        var _this = _super.call(this) || this;
        _this.validationDepot = validationDepot;
        _this.subscriptions['activeValidation'] = _this.validationDepot.activeValidation$.subscribe(function (name) { return _this.activeValidation = name; });
        return _this;
    }
    return ValidationCustomErrorMessageComponent;
}(InterfaceClass));
export { ValidationCustomErrorMessageComponent };
// Find control name from FormControl ...............................................................
export function getControlName(control) {
    var controlName = null;
    var parent = control["_parent"];
    // only such parent, which is FormGroup, has a dictionary 
    // with control-names as a key and a form-control as a value
    if (parent instanceof FormGroup) {
        // now we will iterate those keys (i.e. names of controls)
        Object.keys(parent.controls).forEach(function (name) {
            // and compare the passed control and 
            // a child control of a parent - with provided name (we iterate them all)
            if (control === parent.controls[name]) {
                // both are same: control passed to Validator
                //  and this child - are the same references
                controlName = name;
            }
        });
    }
    // we either found a name or simply return null
    return controlName;
}
