<div class="modal page-properties-background-color">
    <div class="colour-palatte-content-height set-modal-height" >
            <div class="header" [ngClass]="!savedColourAccess && collection.length ==0 ? 'no-border' : ''">
                <div class="section">
                    <div class="palatte">
                        <span (click)="displayContents($event,'colorPalatte')" class="cursor-pointer" [ngClass]="colorPalatte ? 'font-style' : ''" translate="">Color.palatte</span>
                        <div class="line" *ngIf="colorPalatte"></div>
                    </div>
                </div>
                <div class="section" *ngIf="(collection.length > 0 || savedColourAccess)">
                    <div class="saved">
                        <span (click)="displayContents($event,'savedColors')" class="cursor-pointer" [ngClass]="savedColors ? 'font-style' : ''" translate>saved.colors</span>
                        <div class="line" *ngIf="savedColors"></div>
                    </div>
                </div>
            </div>
        <div class="contents">
            <div *ngIf="colorPalatte">
                <div class="canvas">
                    <ng-color-box class="main-canvas" [(ngModel)]="color" (ngModelChange)="colorChange($event)" ></ng-color-box>
                </div>
                <div class="cp-preset-colors-class">
                    <div class="details">
                    <div id="start-button" (click)="eyeDropperOpen()" class="eye-drop">
                        <div class="inside-section">
                            <i id="i-dropper" class="fas fa-eye-dropper"></i>
                        </div>
                    </div>
                    <input class="color-value" [value]="colorDisplay" [ngStyle]="{'border': '1px solid' + colorDisplay}"(input)="colorInputValue($event);checkInputValue($event,'input')" type="text">
                        <div class="test">
                            <input class="alpha" type="number" [ngStyle]="{'border': '1px solid' + colorDisplay}" [value]="percentage" (keydown)="preventDecimal($event)" (keyup)="alphaValue($event)" (change)="alphaValue($event)" autocomplete="off" max="100" min="0"/>
                            <span class="percentage"> % </span>
                        </div>
                    </div>
                    <div class="recent-colors-title">Recently used:</div>
                    <div class="recent-colorrecent-colors-titles-help-text" *ngIf="recentColors.length == 0" translate>recently.used.colors</div>
                    <div class="recent-color-list">
                        <div class="color-list" *ngFor=" let selectedColors of recentColors,let i = index"
                             [style.background-color]="selectedColors" (click)="recentColor(selectedColors, $event)"></div>
                    </div>
                </div>
                <div class="apply-section">
                    <button (keydown.enter)="$event.preventDefault()" class="apply" (click)="closeModal($event)">Apply</button>
                </div>
            </div>
            <div class="saved-colors" *ngIf="savedColors">
                <div class="seach-content-section">
                    <i class="fa fa-search"></i>
                    <input class="search" (keyup)="searchValue($event)" (focus)="onFocus($event)"  placeholder="Search saved colours" [value]="searchColor">
                    <svg *ngIf = "showCloseIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="cross-icon" (click)="clearInput($event)">
                        <path d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z" fill="#8E8E8E"/>
                    </svg>
                </div>
                <div class="saved-color-display">
                    <div *ngIf="savedColorLoading" class="loading-section">
                        <div class="text-layout animated-background small div1 loading-line-one"></div>
                        <div class="flex-display" *ngFor="let loading of [0,1]">
                            <div class="text-layout animated-background small line-1 loader-user loader-main" style="margin-top: 10px"></div>
                            <div class="text-layout animated-background small line-1 loader-line" style="margin-top: 10px"></div>
                        </div>
                    </div>

                    <div class="saved-color-collection" *ngIf="!searchValues && !savedColorLoading">
                        <div class="loop-each-objetc-section" *ngFor="let data of collection">

                            <div class="line-and-text-section">
                                <div class="saved-clr-live"></div>
                                <span class="saved-clr-collection-font">{{data.collectionName}}</span>
                            </div>
                            <div class="data-content-loop-section" [ngClass]="[i==0  ? 'first-element-add-space' : 'next-element-remove-space']" *ngFor="let selectedColors of data.value;let i = index">
                                <div class="saved-color-display-section">
                                    <span class="saved-clr-color-list saved-colors-list" [style.background-color]="selectedColors.color" (click)="colorPicker(selectedColors.color, $event)"></span>
                                </div>
                                <div class="saved-clr-hash-clr-section">
                                    <span class="color-name" (click)="colorPicker(selectedColors.color,$event)">{{selectedColors.name}} - {{selectedColors.color}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="saved-color-collection" *ngIf="searchResult.length">
                        <div class="loop-each-objetc-section" *ngFor="let data of searchResult">
                            <div class="line-and-text-section">
                                <div class="saved-clr-live"></div>
                                <span class="saved-clr-collection-font">{{data.collectionName}}</span>
                            </div>
                            <div class="data-content-loop-section" [ngClass]="[i==0  ? 'first-element-add-space' : 'next-element-remove-space']" *ngFor="let selectedColors of data.value;let i = index">
                                <div class="saved-color-display-section">
                                    <span class="saved-clr-color-list saved-colors-list" [style.background-color]="selectedColors.color" (click)="colorPicker(selectedColors.color, $event)"></span>
                                </div>
                                <div class="saved-clr-hash-clr-section">
                                    <span class="color-name" (click)="colorPicker(selectedColors.color,$event)">{{selectedColors.name}} - {{selectedColors.color}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                    <div class="no-color-display" *ngIf="userSearchNotFound || collection.length == 0">
<!--                        <img class="rectangle-image" *ngIf="userSearchNotFound || searchResult.length == 0" src="images/Rectangle.png"/>-->
<!--                        <div class="question-mark-image">-->
<!--                            <i class="fa fa-question-circle" aria-hidden="true"></i>-->
<!--                        </div>-->
                        <div class="help-text-style" *ngIf="userSearchNotFound">There are no search results</div>
                        <div class="help-text-style" *ngIf="!savedColorLoading && collection.length == 0" translate>no.color.saved</div>
                        <div class="display-help-text" *ngIf="!savedColorLoading && collection.length == 0 && savedColourAccess" (click)="routeToSettingsScreen()"> Add color for future use <img class="subtract" src="images/Subtract.png"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

