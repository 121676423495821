    <!-- <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div> -->
    <!-- <div class="modal-body"> -->
        <!-- <div class="help_section">
            <div class="help-text" translate>
                page.properties.help.text
            </div>
        </div> -->
        <div class="body-content-section" *ngIf="!coverPage">
            <div class="row-fluid">
                <div class="span12">
                    <!-- Custom Css -->
                    <!-- <div class="control-group clearfix">
                        <label class="control-label" translate>custom.css</label>
                        <div class="controls w-40">
                            <input type="text" class="control-input" [(ngModel)]="customCss">
                        </div>
                    </div> -->
                    <div class="flex-row">
                        <div translate>Margin</div>
                        <div class="input-groups all-side-margin input-mt-3">
                            <input class="form-controls margin-input" type="number" min="0" [readonly]="margin_dir.open" autocomplete="off" (keydown)="preventMinus($event)"
                                   [(ngModel)]="margin" (ngModelChange)="setMargin($event, 'margin')" (wheel)="$event.preventDefault()"/>
                            <div class="input-groups-append">
                                <span class="input-groups-text" [ngStyle]="{'background-color': margin_dir.open ? '#eeeeee': '#fff' }">
                                    px
                                </span>
                            </div>
                            <span class="ml-10" (click)="toggle('margin')"><i id="margin-icon"
                                    class="fas fa-expand-wide ft-22"></i></span>
                        </div>
                        <div *ngIf="margin_dir.open" class="mbt-20">
                            <div>Top margin</div>
<!--                            <i class="fas fa-border-top  mrt-5 ft-16"></i>-->
                            <div class="input-groups">
                                <input class="form-control margin-input w-60" (keydown)="preventMinus($event)" (change)="validValue($event.target.value,'topMargin')"
                                       type="number" min="0" autocomplete="off" [(ngModel)]="margin_dir.top" (wheel)="$event.preventDefault()"
                                       (ngModelChange)="setMargin($event, 'margin-top')" />
                                <div class="input-groups-append">
                                    <span class="input-groups-text">
                                        px
                                    </span>
                                </div>
                            </div>
                            <div>Right margin</div>
<!--                            <i class="fas fa-border-right  mrt-5 ft-16"></i>-->
                            <div class="input-groups">
                                <input class="form-control margin-input w-60" (keydown)="preventMinus($event)" (change)="validValue($event.target.value,'rightMargin')"
                                    type="number" min="0" autocomplete="off" [(ngModel)]="margin_dir.right" (wheel)="$event.preventDefault()"
                                    (ngModelChange)="setMargin($event, 'margin-right')" />
                                <div class="input-groups-append">
                                    <span class="input-groups-text">
                                        px
                                    </span>
                                </div>
                            </div>
                            <div>Bottom margin</div>
<!--                            <i class="fas fa-border-bottom  mrt-5 ft-16"></i>-->
                            <div class="input-groups">
                                <input class="form-control margin-input w-60" (keydown)="preventMinus($event)" (change)="validValue($event.target.value,'bottomMargin')"
                                    type="number" min="0" autocomplete="off" [(ngModel)]="margin_dir.bottom" (wheel)="$event.preventDefault()"
                                    (ngModelChange)="setMargin($event, 'margin-bottom')" />
                                <div class="input-groups-append">
                                    <span class="input-groups-text">
                                        px
                                    </span>
                                </div>
                            </div>
                            <div>Left margin</div>
<!--                            <i class="fas fa-border-left  mrt-5 ft-16"></i>-->
                            <div class="input-groups">
                                <input class="form-control margin-input w-60" (keydown)="preventMinus($event)" (change)="validValue($event.target.value,'leftMargin')"
                                    type="number" min="0" autocomplete="off" [(ngModel)]="margin_dir.left" (wheel)="$event.preventDefault()"
                                    (ngModelChange)="setMargin($event, 'margin-left')" />
                                <div class="input-groups-append">
                                    <span class="input-groups-text">
                                        px
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="control-group clearfix">
                        <label class="control-label" translate>border.color</label>
                        <div class="controls w-40">
                            <div class="cs-color-palatte dropdown">
                                <span class="color-box page-properities-clr-box" data-toggle="dropdown" [style.background]="(borderColor=='No border') ? '#FFFFFF': borderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" (click)="detectChanges(borderColor)" data-toggle="dropdown" [(ngModel)]="borderColor">
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="myValue" (colorValue)="getColor($event)"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    <!-- </div> -->
    <!-- <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="cancel()">
            <span id="cancel-styles">Cancel</span>
        </a>
        <button class="btn btn-primary">
            <span [hidden]="action != 'save'" (click)="save()" translate>Save</span>
            <span [hidden]="action == 'save'" translate>Saving</span>
        </button>
    </div> -->
