import { ElementRef, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
var DebounceTimerDirective = /** @class */ (function () {
    function DebounceTimerDirective(_elementRef) {
        this._elementRef = _elementRef;
        this.debounceTime = 400;
        this.valueEmitter = new EventEmitter();
        this.inputElement = new EventEmitter();
    }
    Object.defineProperty(DebounceTimerDirective.prototype, "interval", {
        set: function (interval) {
            this.debounceTime = interval || 400;
        },
        enumerable: true,
        configurable: true
    });
    DebounceTimerDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.inputElement.emit(this._elementRef);
        // Listener for input keyup event with delay time
        fromEvent(this._elementRef.nativeElement, 'keyup')
            .pipe(debounceTime(this.debounceTime), tap(function (event) { return _this.emitValue(event.target.value); })).subscribe();
    };
    // Emit modal value to outside world
    DebounceTimerDirective.prototype.emitValue = function (value) {
        this.valueEmitter.emit(value);
    };
    return DebounceTimerDirective;
}());
export { DebounceTimerDirective };
