import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap'

@Component({
	selector: 'app-edit-section-name',
	templateUrl: './edit-section-name.component.html',
	styleUrls: ['./edit-section-name.component.scss']
})
export class EditSectionNameComponent implements OnInit {

	public onClose: Subject<{}>;
	sectionForm: FormGroup;
	editDetails: any;
	constructor(
		public bsModalRef: BsModalRef,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.onClose = new Subject();
		this.sectionForm = this.fb.group({
			'sectionName': [this.editDetails['sectionName']],
			'listName': [this.editDetails['listName']]
		});
	}
	// Save section name
	saveSectionName() {
		this.onClose.next(this.sectionForm.value);
		this.bsModalRef.hide();
	}
}
