import Handsontable from 'handsontable';
declare var jQuery: any;
import { GetApiUrl } from '@app/utils/get-api-url';
import {GetContractApiUrl } from '@app/utils/get-contract-api-url';

export function HansontableSelect2(options: Object) {
    if (options['ajax'] && options['ajax']['url'] !== undefined) {
        options['ajax']['url'] = GetApiUrl(options['ajax']['url']);
    } else if (options['ajax'] && options['ajax']['apiUrl'] !== undefined) {
        options['ajax']['url'] = GetContractApiUrl(options['ajax']['apiUrl']);
    }

    class HansontableSelect2Editor extends Handsontable.editors.TextEditor {
        $textarea: any;
        value: any;
        columnSettings: Object = {};
        select2Options: Object = options instanceof Object ? options : {};

        constructor(hotInstance: Handsontable, row: number, col: number, prop: string | number, TD: HTMLTableCellElement, cellProperties: Handsontable.CellProperties) {
            super(hotInstance, row, col, prop, TD, cellProperties);
            this.columnSettings = hotInstance.getCellMeta(hotInstance.rowOffset(), hotInstance.colOffset());
        }

        prepare(row: number, col: number, prop: string | number, TD: HTMLTableCellElement, originalValue: any, cellProperties: Handsontable.CellProperties) {
            this.$textarea.val(originalValue || '');
            Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);
        }

        createElements() {
            super.createElements();

            this.TEXTAREA = document.createElement('input');
            this.$textarea = jQuery(this.TEXTAREA);
            this.TEXTAREA.setAttribute('type', 'text');
            this.TEXTAREA.setAttribute('placeholder', '');
            this.TEXTAREA.className = 'handsontableInput';
            this.textareaStyle = this.TEXTAREA.style;

            Handsontable.dom.empty(this.TEXTAREA_PARENT);
            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);

            this.TEXTAREA = document.createElement('input');
            this.TEXTAREA.setAttribute('type', 'text');

            Handsontable.dom.addClass(this.TEXTAREA, 'handsontableInput');

            this.textareaStyle = this.TEXTAREA.style;
            this.textareaStyle.width = '0px';
            this.textareaStyle.height = '0px';


            this.TEXTAREA_PARENT = document.createElement('DIV');
            this.TEXTAREA_PARENT.style.top = '0px';
            this.TEXTAREA_PARENT.style.left = '0px';
            this.TEXTAREA_PARENT.style.display = 'none';

            Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');

            Handsontable.dom.empty(this.TEXTAREA_PARENT);
            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
        }

        open(keyboardEvent?: KeyboardEvent) {
            this.refreshDimensions();
            this.TEXTAREA_PARENT.style.display = 'block';
            this.instance.addHook('beforeKeyDown', this.onBeforeKeyDown);

            this.$textarea.css({
                height: jQuery(this.TD).height() + 4,
                // 'min-width': jQuery(this.TD).outerWidth() - 4
                'width': jQuery(this.TD).outerWidth() - 4,
                'max-width': jQuery(this.TD).outerWidth() - 4,
                'padding': '0 !important'
            });

            //display the list
            // this.$textarea.show();
            if(this.select2Options['ajax'] && this.select2Options['ajax']['url'].indexOf('/v5/') !== -1) {
                this.select2Options['ajax']['transport'] = function(params) {
                    params.beforeSend = function(request) {
                        request.setRequestHeader("v5Token", localStorage.getItem('cmsmsapt'));
                    };
                    return jQuery.ajax(params);
                };
            }

            this.$textarea.select2(this.select2Options)
                .on('change', () => {
                    this.value = this.$textarea.val();
                    this.close();
                    this.finishEditing();
                })
                .on('select2-close', () => {
                    this.value = this.$textarea.val();
                    this.close();
                    this.finishEditing();
                    setTimeout(function() {
                        $('.select2-container-active').removeClass('select2-container-active');
                    }, 1);
                })
                .on('select2-blur', () => {
                    //console.log('select2-blur')
                })
                .on('select2-open', () => {
                    $('body > .select2-drop').css({
                        'width': jQuery(this.TD).outerWidth(),
                    });
                });

            this.$textarea.select2('open');

            // To preserve the list with selected value
            if (this.TD.textContent != "Please select" ) {
               this.$textarea.select2('search', this.TD.textContent);
            } else if (keyboardEvent && keyboardEvent.keyCode) {
                var key = keyboardEvent.keyCode;
                var keyText = (String.fromCharCode((96 <= key && key <= 105) ? key - 48 : key)).toLowerCase();
                this.$textarea.select2('search', keyText);
            }
        }

        getValue() {
            return this.$textarea.val();
        }

        focus() {
            this.instance.listen();
        }

        moveToNextValidCell() {
            /*** Changes implemented to move to next cell once it closed Star Systems ***/
            let newCol = this.col, j = 1, isReadOnly = false;

            /*** Check the next cell is read only or not to move to next cell ***/
            for (let i = this.col; i < this.instance.countCols() - 1; i++) {
                newCol = this.col + j;
                if (this.instance.getCellMeta(this.row, newCol).readOnly) {
                    j++;
                    isReadOnly = true;
                } else {
                    isReadOnly = false;
                    break;
                }
            }

            /*** If the final cell of the row is readonly, then it will automatically move to next new row ***/
            if (isReadOnly) {
                /*** To find valid cell in next row (By Sales Team) ***/
                let colToSelect = 0;
                for (let nextRowCol = 0; nextRowCol < this.instance.countCols() - 1; nextRowCol++) {
                    if (this.instance.getCellMeta(this.row + 1, nextRowCol).readOnly || this.instance.getCellMeta(this.row + 1, nextRowCol).hidden) {
                        continue;
                    } else {
                        colToSelect = nextRowCol;
                        break;
                    }
                }
                this.instance.selectCell(this.row + 1, colToSelect);
            } else {
                this.instance.selectCell(this.row, newCol);
            }
        }

        close() {
            this.instance.listen();
            this.instance.removeHook('beforeKeyDown', this.onBeforeKeyDown);
            this.$textarea.off();
            //this.$textarea.select2('close');
            this.$textarea.select2('destroy');

            /* To avoid issue when fill handle is enabled - if its coming from fill handle state will be STATE_FINISHED*/
            if(this.state == 'STATE_EDITING') {
                Handsontable.editors.TextEditor.prototype.close.apply(this);
                this.moveToNextValidCell();
            }

        }
    
        onBeforeKeyDown = function (event?) {
            let that: any = this.getActiveEditor();

            var keyCodes = Handsontable.helper.KEY_CODES; // Changed "keyCode" as "KEY_CODES" by Star Systems (Need to check when upgrade)
            var ctrlDown = (event.ctrlKey || event.metaKey) && !event.altKey; //catch CTRL but not right ALT (which in some systems triggers ALT+CTRL)

            //Process only events that have been fired in the editor
            if (!jQuery(event.target).hasClass('select2-input')) { // Removed the "|| event.isImmediatePropagationStopped()" conditions by Star Systems (Need to check when upgrade)
                return;
            }
            if (event.keyCode === 17 || event.keyCode === 224 || event.keyCode === 91 || event.keyCode === 93) {
                //when CTRL or its equivalent is pressed and cell is edited, don't prepare selectable text in textarea
                event.stopImmediatePropagation();
                return;
            }

            var target = event.target;

            switch (event.keyCode) {
                case keyCodes.ARROW_RIGHT:
                    if (Handsontable.dom.getCaretPosition(target) !== target.value.length) {
                        event.stopImmediatePropagation();
                    } else {
                        that.$textarea.select2('close');
                    }
                    break;

                case keyCodes.ARROW_LEFT:
                    if (Handsontable.dom.getCaretPosition(target) !== 0) {
                        event.stopImmediatePropagation();
                    } else {
                        that.$textarea.select2('close');
                    }
                    break;

                case keyCodes.ENTER:
                    var selected = that.instance.getSelected();
                    var isMultipleSelection = !(selected[0] === selected[2] && selected[1] === selected[3]);
                    if ((ctrlDown && !isMultipleSelection) || event.altKey) { //if ctrl+enter or alt+enter, add new line
                        if (that.isOpened()) {
                            that.val(that.val() + '\n');
                            that.focus();
                        } else {
                            that.beginEditing(that.originalValue + '\n')
                        }
                        event.stopImmediatePropagation();
                    }
                    event.preventDefault(); //don't add newline to field
                    break;

                case keyCodes.A:
                case keyCodes.X:
                case keyCodes.C:
                case keyCodes.V:
                    if (ctrlDown) {
                        event.stopImmediatePropagation(); //CTRL+A, CTRL+C, CTRL+V, CTRL+X should only work locally when cell is edited (not in table context)
                    }
                    break;

                case keyCodes.BACKSPACE:
                case keyCodes.DELETE:
                case keyCodes.HOME:
                case keyCodes.END:
                    event.stopImmediatePropagation(); //backspace, delete, home, end should only work locally when cell is edited (not in table context)
                    break;
            }
        }
    }

    return HansontableSelect2Editor;
}
