import { Directive, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core'
import { UpgradeComponent } from '@angular/upgrade/static'

@Directive({
    selector: 'cspagination-migrated'
})

export class CsPaginationMigratedDirective extends UpgradeComponent {
    @Input('totalEntries') totalEntries: number;
    @Input('currentPage') currentPage: number;
    @Input('limit') limit: number;
    @Output('fetchData') fetchData: EventEmitter<any> = new EventEmitter();
    @Input('showNewPagination') showNewPagination: boolean=false;

    constructor(_hostRef: ElementRef, injector: Injector) {
        super('cspagination', _hostRef, injector);
    }
}
