/* tslint:disable:max-line-length */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import {Subject} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
declare var $: any;

@Component({
  selector: 'app-add-edit-custom-tag',
  templateUrl: './add-edit-custom-tag.component.html',
  styleUrls: ['./add-edit-custom-tag.component.scss']
})
export class AddEditCustomTagComponent implements OnInit {
    @ViewChild("select2Question") select2QuestionElement:ElementRef<HTMLElement>;
    @ViewChild("select2Template") select2TemplateElement:ElementRef<HTMLElement>;
    public onClose: Subject<boolean>;
    action = 'save';
    customTag = false;
    tagFilledBy: string;
    selectedOption = 'salesPerson';
    selectedTemplateId: number;
    formData: any = {}; // JSON data to be sent to the backend
    dropdownOptions: any = [];
    questionOptions: any = [];
    surveyQuestionsData: any = [];
    questionData: any;
    selectedQuestionId: any;
    surveyOptionConfig: any;
    customTagData: any;
    customTagValues: any[] = [];
    customTagForm: FormGroup;
    questionSelected = false;
    templateId: any;
    type: any;
    optionId: any;
    allcustomTags: any;
    tagdatas: any;
    data: any;
    screen: any;
    customTagName: string;
    constructor(
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private proposalTemplateService: ProposalTemplateService
    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        const customTagGroup: any = {};
        this.formData['type'] = 'input';
        this.customTagValues.forEach(tag => {
            customTagGroup[tag.key] = new FormControl('', Validators.required);
        });
        this.customTagForm = new FormGroup(customTagGroup);
        this.proposalTemplateService.getSurveyTemplateList().subscribe(values => {
            if (values) {
                values['leaveJobQuestion'].forEach(question => {
                    if (question && question.JsonData) {
                        this.surveyQuestionsData.push(question);
                    }
                });
                if (this.data) {
                    this.selectedOption = this.data.tagCompletedBy || this.selectedOption;
                    this.customTagName = this.data.value;
                    if (this.selectedOption) {
                        this.formData['type'] = this.data.answerType || 'input';
                        if (this.selectedOption === 'survey') {
                            this.selectedTemplateId = this.data.templateId;
                            this.onTemplateChange(this.selectedTemplateId);
                            this.selectedQuestionId = this.data.questionId;
                            setTimeout(() => {
                                $(this.select2TemplateElement.nativeElement).select2("val", this.selectedTemplateId);
                                $(this.select2QuestionElement.nativeElement).select2("val", this.selectedQuestionId);
                            }, 200);
                        } else {
                            if (this.formData.type === 'dropdown') {
                                this.dropdownOptions = this.data.options;
                            }
                        }
                    }
                }

            }
        });
  }

    submitForm(form) {
        this.bsModalRef.hide();
        this.onClose.next(form.value);
    }

    cancel() {
        this.bsModalRef.hide();
        if (this.data) {
            this.onClose.next({ id: this.data.id, existingCustomTagName: this.data.value } as any);
        }
    }
    onOptionChange(option: string) {
        this.selectedOption = option;
        this.resetFormData();
        if (this.selectedOption === 'salesPerson' && this.formData.type !== 'dropdown') {
            this.formData['type'] = 'input';
        }
    }

    onTemplateChange(template: number) {
        this.questionOptions = [];
        this.questionSelected = true;
        this.selectedTemplateId = template;
        this.formData['template'] = template;
        this.selectedQuestionId = null;
        if(this.select2QuestionElement) {
            $(this.select2QuestionElement.nativeElement).select2("val", this.selectedQuestionId);
        }
        const index = this.surveyQuestionsData.findIndex(question => question.id == template);
        if(index >= 0) {
            const jsonData = this.surveyQuestionsData[index]['JsonData'];
            const data = JSON.parse(jsonData);
            if (data) {
                data.pages.forEach(page => {
                    page.sections.forEach(section => {
                        section.fields.forEach(field => {
                            // const questionData = {'questionLabel': field.fieldLabel, 'questionId' : field.questionID, 'answerType': field.fieldAnswerType};
                            // this.questionOptions.push(questionData);
                            if (field.fieldAnswerType !== 'Photo' && field.fieldAnswerType !== 'Signature') {
                                this.surveyOptionConfig = {
                                    'questionLabel': field.fieldLabel,
                                    'questionId': field.questionID,
                                    'answerType': field.fieldAnswerType,
                                    'file': true
                                };
                                this.questionOptions.push(this.surveyOptionConfig);
                            }
                        });
                    });
                });
            }
            this.questionData = this.questionOptions.find(dO => dO.questionId === this.data.questionId) || this.questionOptions[0];
        }
    }

    onAddOption() {
        if (this.formData.type === 'dropdown' && this.formData.newOption) {
            this.dropdownOptions.push(this.formData.newOption);
            this.formData.newOption = ''; // Clear the input after adding
        }
    }

    onRemoveOption(index: number) {
        this.dropdownOptions.splice(index, 1);
    }
    onQuestionSelect(questionId) {
        this.questionData = this.questionOptions.find(dO => dO.questionId === questionId);
    }

    resetFormData() {
        this.selectedTemplateId = null;
        this.formData = {};
        this.dropdownOptions = [];
        this.selectedQuestionId = null;
        this.questionOptions = [];
        this.questionSelected = false;
    }


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.dropdownOptions, event.previousIndex, event.currentIndex);
    }

    saveCustomTags() {
        if (this.selectedOption === 'salesPerson') {
            this.tagdatas = {
                name : this.customTagName,
                tagCompletedBy: this.selectedOption,
                answerType: this.formData.type,
                // options: this.dropdownOptions,
            };
            if (this.formData.type === 'dropdown') {
                this.tagdatas.options = this.dropdownOptions;
            }
        } else if (this.selectedOption === 'survey') {
            this.tagdatas = {
                name : this.customTagName,
                tagCompletedBy : this.selectedOption,
                templateId : this.selectedTemplateId,
                questionId : this.questionData.questionId ? this.questionData.questionId : null,
                answerType : this.questionData.answerType ? this.questionData.answerType : null ,
                // options: this.dropdownOptions
            };
        }

        this.tagdatas = JSON.stringify(this.tagdatas);
        this.customTagData = {
            tablePkId : this.templateId,
            tagData : this.tagdatas,
            currentScreen: this.screen,
            optionId: this.optionId,
        };
        if (this.data && this.data.id) {
            this.customTagData.editId = this.data.id;
        }
        this.proposalTemplateService.addOrEditCustomTags(this.customTagData).subscribe(data => {
            if (data) {
                this.bsModalRef.hide();
                this.proposalTemplateService.getCustomTags(this.templateId, this.screen).subscribe(customTagData => {
                    if (customTagData) {
                        this.allcustomTags = customTagData;
                        const modalCloseData: any = {id: data, allData: customTagData, existingCustomTagName: this.data.value};
                        this.onClose.next(modalCloseData);
                    }
                });
            }
        });
    }
}


