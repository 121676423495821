import { InjectionToken } from '@angular/core';

export interface CsScrollbarConfigInterface {
    handlers?: string[];
    wheelSpeed?: number;
    swipeEasing?: boolean;
    suppressScrollX?: boolean;
    suppressScrollY?: boolean;
    wheelPropagation?: boolean;
    useBothWheelAxes?: boolean;
    scrollingThreshold?: number;
    minScrollbarLength?: number;
    maxScrollbarLength?: number;
    scrollXMarginOffset?: number;
    scrollYMarginOffset?: number;
}

export class CsScrollbarConfig implements CsScrollbarConfigInterface{
    public handlers?: string[];
    public wheelSpeed?: number;
    public swipeEasing?: boolean;
    public suppressScrollX?: boolean;
    public suppressScrollY?: boolean;
    public wheelPropagation?: boolean;
    public useBothWheelAxes?: boolean;
    public scrollingThreshold?: number;
    public minScrollbarLength?: number;
    public maxScrollbarLength?: number;
    public scrollXMarginOffset?: number;
    public scrollYMarginOffset?: number;

    constructor(config: CsScrollbarConfigInterface) {
        this.assign(config);
    }

    public assign(config: CsScrollbarConfigInterface = {}) {
        for (const key in config) {
            this[key as keyof CsScrollbarConfig] = config[key as keyof CsScrollbarConfigInterface] as never;
        }
    }
}
export const CS_SCROLLBAR_CONFIG = new InjectionToken<CsScrollbarConfigInterface>('CS_SCROLLBAR_CONFIG');
export type CS_ScrollbarEvent = 'psScrollY' | 'psScrollX' | 'psScrollUp'| 'psScrollDown' | 'psScrollLeft' | 'psScrollRight' | 'psYReachEnd' | 'psYReachStart' | 'psXReachEnd' | 'psXReachStart';

export const CsScrollbarEvents: CS_ScrollbarEvent[] = [
    'psScrollY',
    'psScrollX',
    'psScrollUp',
    'psScrollDown',
    'psScrollLeft',
    'psScrollRight',
    'psYReachEnd',
    'psYReachStart',
    'psXReachEnd',
    'psXReachStart'
];



