export class ContractTemplateArchiveEvent {
  pkId: number;
  executor: {
    resolve: () => void;
    reject: () => void;
  };

  constructor(pkId: number, resolveFn: () => void, rejectFn: () => void) {
    this.pkId = pkId;
    this.executor = {
      resolve: resolveFn,
      reject: rejectFn
    };
  }
}
