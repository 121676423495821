import { TemplateRef, EventEmitter, Renderer2, RendererFactory2, ComponentRef } from '@angular/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { modalConfigDefaults, SidepanelOptions, TRANSITION_DURATIONS } from './sidepanel-options';
import { BsModalRef } from 'ngx-bootstrap';
import { CsMediumScreenPanelComponent } from "@app/sidepanel/cs-medium-screen-panel/cs-medium-screen-panel.component";
import { CsModalBackdropComponent } from "@app/sidepanel/cs-backdrop/cs-modal-backdrop.component";
var MediumScreenModalService = /** @class */ (function () {
    function MediumScreenModalService(rendererFactory, clf) {
        this.clf = clf;
        this.config = modalConfigDefaults;
        // tslint:disable-next-line:no-any
        this.onShow = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onShown = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHide = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHidden = new EventEmitter();
        this.isBodyOverflowing = false;
        this.originalBodyPadding = 0;
        this.scrollbarWidth = 0;
        this.loaders = [];
        this.modalVisibleStatus = false;
        this.lastDismissReason = '';
        this._backdropLoader = this.clf.createLoader(null, null, null);
        this._renderer = rendererFactory.createRenderer(null, null);
    }
    MediumScreenModalService.prototype._createLoaders = function () {
        var loader = this.clf.createLoader(null, null, null);
        this.copyEvent(loader.onBeforeShow, this.onShow);
        this.copyEvent(loader.onShown, this.onShown);
        this.copyEvent(loader.onBeforeHide, this.onHide);
        this.copyEvent(loader.onHidden, this.onHidden);
        this.loaders.push(loader);
    };
    MediumScreenModalService.prototype.copyEvent = function (from, to) {
        var _this = this;
        from.subscribe(function () {
            to.emit(_this.lastDismissReason);
        });
    };
    MediumScreenModalService.prototype.removeLoaders = function () {
        this.loaders = [];
    };
    MediumScreenModalService.prototype.show = function (content, config) {
        this._createLoaders();
        this.config = Object.assign({}, modalConfigDefaults, config);
        this._showBackdrop();
        this.modalVisibleStatus = true;
        return this._showModal(content);
    };
    MediumScreenModalService.prototype._showBackdrop = function () {
        var isBackdropEnabled = this.config.backdrop || this.config.backdrop === 'static';
        var isBackdropInDOM = !this.backdropRef || !this.backdropRef.instance.isShown;
        if (this.config.backdrop && !this.backdropRef) {
            this.removeBackdrop();
            if (isBackdropEnabled && isBackdropInDOM) {
                this._backdropLoader
                    .attach(CsModalBackdropComponent)
                    .to('body')
                    .show({ isAnimated: this.config.animated });
                this.backdropRef = this._backdropLoader._componentRef;
                //this._renderer.addClass(this.backdropRef.instance.element.nativeElement, 'modal-backdrop-leve-'+)
            }
        }
    };
    MediumScreenModalService.prototype._hideBackdrop = function () {
        var _this = this;
        if (!this.backdropRef) {
            return;
        }
        this.backdropRef.instance.isShown = false;
        var duration = this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0;
        setTimeout(function () { return _this.removeBackdrop(); }, duration);
    };
    MediumScreenModalService.prototype.removeBackdrop = function () {
        this._backdropLoader.hide();
        this.backdropRef = null;
    };
    MediumScreenModalService.prototype._showModal = function (content) {
        var _this = this;
        var modalLoader = this.loaders[0];
        if (this.config && this.config.providers) {
            for (var _i = 0, _a = this.config.providers; _i < _a.length; _i++) {
                var provider = _a[_i];
                modalLoader.provide(provider);
            }
        }
        var bsModalRef = new BsModalRef();
        var modalContainerRef = modalLoader
            .provide({ provide: SidepanelOptions, useValue: this.config })
            .provide({ provide: BsModalRef, useValue: bsModalRef })
            .attach(CsMediumScreenPanelComponent)
            .to('body')
            .show({ content: content, isAnimated: this.config.animated, initialState: this.config.initialState, bsModalService: this });
        bsModalRef.hide = function () {
            var duration = _this.config.animated ? TRANSITION_DURATIONS.MODAL : 0;
            setTimeout(function () { return modalContainerRef.instance.hide(); }, duration);
        };
        bsModalRef.content = modalLoader.getInnerComponent() || null;
        bsModalRef.setClass = function (newClass) {
            modalContainerRef.instance.config.class = newClass;
        };
        return bsModalRef;
    };
    MediumScreenModalService.prototype.hide = function () {
        var _this = this;
        if (this.config.backdrop && this.backdropRef) {
            this._hideBackdrop();
        }
        setTimeout(function () {
            _this._hideModal();
            _this.removeLoaders();
        }, this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0);
        this.modalVisibleStatus = false;
    };
    MediumScreenModalService.prototype._hideModal = function () {
        var modalLoader = this.loaders[0];
        if (modalLoader) {
            modalLoader.hide();
        }
    };
    MediumScreenModalService.prototype.isOpen = function () {
        return this.modalVisibleStatus;
    };
    MediumScreenModalService.prototype.getModalsCount = function () {
        return 1;
    };
    return MediumScreenModalService;
}());
export { MediumScreenModalService };
