import { PipeTransform } from '@angular/core';
import * as moment from 'moment';
var MomentPipe = /** @class */ (function () {
    function MomentPipe() {
    }
    MomentPipe.prototype.transform = function (value, format) {
        if (!value)
            return value;
        return moment(value).format(format);
    };
    return MomentPipe;
}());
export { MomentPipe };
