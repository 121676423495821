import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BsModalRef } from "ngx-bootstrap";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {MxGraphSequenceService} from "@app/workflow-common/services/mx-graph-sequence";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {SequenceAddContactComponent} from "@app/workflow-common/sequence-add-contact/sequence-add-contact.component";
import {CsFullScreenModalComponent} from "@app/directives";
import {FullScreenHeaderComponent} from "@app/workflow-common/full-screen-header/full-screen-header.component";
import {DOCUMENT} from "@angular/common";
import {SequenceContainerComponent} from "@app/workflow-common/sequence-container/sequence-container.component";
import {mxgraph, mxgraphFactory} from "mxgraph-factory";
import {Subject} from "rxjs";
import {DiagramViewSequenceComponent} from "@app/workflow-common/diagram-view-sequence/diagram-view-sequence.component";
const {
    mxCodec,
    mxUtils,
    mxGraph,
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

@Component({
  selector: 'app-send-sequence-popup',
  templateUrl: './send-sequence-popup.component.html',
  styleUrls: ['./send-sequence-popup.components.scss']
})
export class SendSequencePopupComponent implements OnInit {

  @ViewChild('mobileContainer') mobileElement: ElementRef;
  @ViewChild(CsFullScreenModalComponent) csFullModal: CsFullScreenModalComponent;
  @ViewChild(SequenceContainerComponent) sequenceContainer: SequenceContainerComponent;

  public customHeader = FullScreenHeaderComponent;
  private _customHeader: FullScreenHeaderComponent;
  public onClose: Subject<any>;

  select2Config = {
      placeholder: 'Choose sequence template',
      allowClear: false,
      data: []
  };
  select2ContactsConfig = {
      placeholder:  'Send sequence to',
      allowClear: false,
      data: [],
      minimumResultsForSearch: -1,
      formatNoMatches: () => 'No contact found.'
  };

  sequenceData: any[] = [];
  contactsData: any[] = [];
  isLoading: boolean = true;
  onChangeSequenceLoading: boolean = false;
  sequenceContactInstance: any = null;

  customerId;
  objectName: string;
  objectId: number;
  customerContacts: any = {};

  selectedSequenceModal: any;
  selectedContactModal: any;
  selectedContactEmailModal: any = '';
  selectedContactMobileModal: any = '';
  selectedCustomerContact: any = {};
  selectedWorkflowBlocksList: any[] = [];
  selectedWorkflowTemplateList: any[] = [];
  displaySelectedContactName: string = '';
  displaySelectedContactInfo: any = {};
  disabledContact: boolean = true;
  contactHasEmail: boolean = true;
  contactHasMobile: boolean = true;
  isOpenedContactDropdown: boolean = false;

  displayBlocks = {
    delayBlock: {
        text: 'Delay',
        iconClass: 'fas fa-history',
        textClass: null,
    },
    emailBlock: {
        text: 'Email',
        iconClass: 'fas fa-envelope',
        textClass: null,
    },
    smsBlock: {
        text: 'SMS',
        iconClass: 'fas fa-comment',
        textClass: null,
    },
    officeTaskBlock: {
        text: 'Office task',
        iconClass: 'fas fa-tasks',
        textClass: null,
    },
    notificationBlock: {
        text: 'Notification task',
        iconClass: 'fas fa-bell',
        textClass: null,
    },
    scheduledActivityBlock: {
      text: 'Schedule activity task',
      iconClass: 'fas fa-calendar-alt',
      textClass: null,
    }
  };
  fullScreenModalOptions: any = {};
  isOpenEditSequence: boolean = false;
  selectedWorkflowTemplate: any = null;
  sendingSequenceText: string = 'Send';

  constructor(
      public modalRef: BsModalRef,
      public modalSidepanel: BsModalRef,
      private httpService: WorkflowhttpService,
      private mxgraphService: MxGraphSequenceService,
      private renderer: Renderer2,
      private modalService: SidepanelModalService,
      @Inject(DOCUMENT) private document,
  ) { }

  async ngOnInit() {
      this.onClose = new Subject();
      await this.getSequences();
      await this.getContactDetails();
      this.isLoading=false;
  }

  setContactDropdown(select2) {
      this.sequenceContactInstance = select2;
  }

  async getSequences() {
    this.isLoading=true;
    return this.httpService.getWorkflowSetupSequencesList({
        sequenceType: 'Opportunity'
    }).toPromise().then( (resp) => {
        this.sequenceData = resp['workflow_sequence'];
        this.select2Config.data = this.sequenceData;
    })
  }

  async getContactDetails() {
      this.isLoading=true;
      const param = {
          limit:9999
      }
      return this.httpService.getCustomContactsList(this.customerId, 'customer', param)
        .toPromise().then( (resp) => {
          if(resp['contacts'] && resp['contacts'].hasOwnProperty('contactsDetails'))
            this.prepareContacts(resp['contacts']['contactsDetails']);
      })
  }

  prepareContacts(contacts: any[] = []) {
    contacts.forEach((item) => {
        let contactItem = {};
        contactItem['email'] = {
            emailAddress: item.emailaddress,
            contactId: item.id
        };
        contactItem['mobile'] = [];
        if(item['numbers_collection']) {
            contactItem['mobile'] = item['numbers_collection'].filter( (x) => x.type == 'Mobile' ).map( (x) => {
                return {
                    telephone_id: x.id,
                    code: x.code,
                    phone_number: x.phone_number,
                    type: x.type,
                    type_id: x.type_id
                };
            })
        }
        this.customerContacts[item.id] = contactItem;
    })
    this.contactsData = contacts
      .map( (item:any) => {
          return {id: item.id, text: item.contactName}
    })
    this.select2ContactsConfig.data = this.contactsData;
  }

  onChangeSequence() {
      const selectedTemplate = this.sequenceData.find( (item:any) => item.id == this.selectedSequenceModal );
      if(selectedTemplate) {
          this.selectedWorkflowTemplate = selectedTemplate;
          let dags = [], emailSmsTemplatesId=[], dagSource = selectedTemplate['dagsource'] ? JSON.parse(selectedTemplate['dagsource']) : null;
          const getChild = (child: any[]) => {
              child.forEach( x => {
                  if(dagSource['blockObjects'].hasOwnProperty(x)) {
                      dags.push(dagSource['blockObjects'][x])
                      if(dagSource['dagObject'][x]) {
                          getChild(dagSource['dagObject'][x]);
                      }
                  }
              })
          };
          if(dagSource) {
              let initBlock: any[] = dagSource['dagObject']['cs_initializer'];
              getChild(initBlock);
              dags.forEach( (dag) => {
                  if(dag['name'] == 'emailBlock' || dag['name'] == 'smsBlock') {
                      emailSmsTemplatesId.push(dag['model']['templateId']);
                  }
              });
              this.onChangeSequenceLoading=true;
              this.httpService.getEmailSmsTemplatesByIds({ids: emailSmsTemplatesId})
                  .subscribe({
                      next: (resp:any) => {
                          this.selectedWorkflowTemplateList = resp['templates']
                          this.selectedWorkflowBlocksList = dags;
                          this.onChangeSequenceLoading=false;
                      },
                      error: () => {
                          this.onChangeSequenceLoading=false;
                      }
                  })
          }
          this.disabledContact=false;
      }
  }

  empty(value:any) {
      if(value == '' || value == null || value == false || value == '0') {
          return true
      }
      return false;
  }

  onChangeContact() {
      this.isOpenedContactDropdown=false;
      this.selectedContactEmailModal = '';
      this.selectedContactMobileModal = '';
      if(!this.selectedContactModal) {
          return;
      }
      this.selectedCustomerContact = this.customerContacts[this.selectedContactModal];
      this.contactHasEmail = !this.empty(this.selectedCustomerContact['email']['emailAddress']);

      this.contactHasMobile = this.selectedCustomerContact['mobile'].length > 0;
      const selectedContact = this.contactsData.find( x => x.id == this.selectedContactModal);
      if(selectedContact) {
          this.displaySelectedContactName = selectedContact['text'];
      }
      if(this.contactHasEmail) {
          this.displaySelectedContactInfo['emailAddress'] = this.selectedCustomerContact['email']['emailAddress'];
          this.selectedContactEmailModal = this.selectedCustomerContact['email']['contactId'];
      }
      if(this.contactHasMobile && this.selectedCustomerContact['mobile'].length === 1) {
          const selectedMobile = this.selectedCustomerContact['mobile'][0];
          this.selectedContactMobileModal = selectedMobile.telephone_id;
          this.displaySelectedContactInfo['mobile'] = '+'+selectedMobile.code + ' ' + selectedMobile.phone_number;
          this.displaySelectedContactInfo['contact_telephone_id'] = selectedMobile.telephone_id;
      }
      // console.log('this.selectedCustomerContact', this.selectedCustomerContact, selectedContact)
  }

  onResetContact() {
      this.selectedContactModal = '';
      this.displaySelectedContactName='';
      this.displaySelectedContactInfo = {};
      this.selectedContactEmailModal = '';
      this.selectedContactMobileModal = '';
      this.sequenceContactInstance.select2('val', '');
      setTimeout(() => {
          this.isOpenedContactDropdown=true;
      },300)
      this.addOrRemoveAnim('mobile', 'remove');
  }

  onCheckedContact(event:any, type: string) {
      // this.httpService.triggerSequenceView({'action': 'reload'})
      // console.log('type', type, this.selectedCustomerContact, event.target.value);
      if(type == 'email') {
          if(event.target.checked) {
              this.selectedContactEmailModal = event.target.value;
              this.displaySelectedContactInfo['emailAddress'] = this.selectedCustomerContact['email']['emailAddress'];
          }
          else {
              this.selectedContactEmailModal = '';
              this.displaySelectedContactInfo['emailAddress'] = '';
          }
      }

      if(type == 'mobile') {
          if(event.target.checked) {
              this.selectedContactMobileModal = event.target.value;
              const selectedMobile = this.selectedCustomerContact['mobile'].find( x => x.telephone_id == this.selectedContactMobileModal);
              if(selectedMobile) {
                  this.displaySelectedContactInfo['mobile'] = '+'+selectedMobile.code + ' ' + selectedMobile.phone_number;
                  this.displaySelectedContactInfo['contact_telephone_id'] = selectedMobile.telephone_id;
              }
          }
      }
      const selectedContact = this.contactsData.find( x => x.id == this.selectedContactModal);
      if(selectedContact) {
          this.displaySelectedContactName = selectedContact['text'];
      }
      this.addOrRemoveAnim(type, 'add');

  }

  getDisplayText(block) {
      const { model, name } = block;
      if(name == 'delayBlock') {
          return model.interval + ' ' + model.unit
      }
      else if(name == 'emailBlock' || name == 'smsBlock') {
          if(this.selectedWorkflowTemplateList[model['templateId']]) {
              return this.selectedWorkflowTemplateList[model['templateId']]['templateName'];
          }
      }
      else if(name == 'officeTaskBlock' || name == 'notificationBlock') {
          return model['textMessage']
      }
      else if(name == 'scheduledActivityBlock') {
          return model['description']
      }
      else {
          return '';
      }
  }

  onEditWorkflow() {
      if(!this.selectedSequenceModal) {
          return;
      }
      const selectedSequence = this.sequenceData.find( (item:any) => item.id == this.selectedSequenceModal );
      this.isOpenEditSequence=true
      this.sequenceContainer.setSelectedData(selectedSequence, {
          displayContactInfo: this.displaySelectedContactInfo,
          selectedContact: this.selectedCustomerContact,
          displaySelectedContactName: this.displaySelectedContactName,
          displaySequenceName: selectedSequence['text'],
          selectedSequenceId: selectedSequence['id'],
          contactId: this.selectedContactModal,
          objectId: this.objectId,
          objectName: this.objectName,
      });
      this.csFullModal.open();
      this._customHeader.header = selectedSequence['text'];
      this._customHeader.data = selectedSequence;

      this._customHeader.actionsButtons = [];
      this._customHeader.ngOnInit();
  }

  setCustomHeaderInstance($event: FullScreenHeaderComponent):void {
      this._customHeader = $event;
  }

  showAddContact(type: string) {
      const initialState = {
        type,
        contactId: this.selectedContactModal,
        title: type === 'email'? 'Add an email address' : 'Add a mobile number',
        helpText: type === 'email' ? 'In order to send a sequence which involves emails, it’s important the ' +
            'contact has a valid email address.' : 'In order to send a sequence which involves SMS, it’s important ' +
            'the contact has a valid mobile number.'
      };
      this.modalSidepanel = this.modalService.show(SequenceAddContactComponent, {
          initialState: initialState,
          size: 'sm',
          'class': 'ng-sequence-popup',
          wrapperClass: 'cs-sequence-add-contact',
          backdrop: true,
      });
      this.modalSidepanel.content.onClose.subscribe( (data:any) => {
          console.log('formdata', data);
          //update customerContacts
          if(type === 'email') {
              const {emailaddress} = data;
              this.customerContacts[this.selectedContactModal]['email']['emailAddress'] = emailaddress;
              this.contactHasEmail = true;
              this.displaySelectedContactInfo['emailAddress'] = emailaddress;
              this.selectedContactEmailModal = this.selectedCustomerContact.email['contactId'];
          }
          else if(type === 'mobile') {
              this.customerContacts[this.selectedContactModal]['mobile'] = [data];
              this.contactHasMobile = true;
              this.displaySelectedContactInfo['mobile'] = '+'+data.code + ' ' + data.phone_number;
              this.displaySelectedContactInfo['contact_telephone_id'] = data.id;
              this.selectedContactMobileModal = data.id;
          }
          this.selectedCustomerContact = this.customerContacts[this.selectedContactModal];
      })
  }

  addOrRemoveAnim(type:string, action:string = 'add') {
      setTimeout( () => {
      if( (type=='mobile' && !this.mobileElement )) {
          return;
      }

      let container = this.mobileElement.nativeElement;
      if(action == 'remove' && container.classList.contains('hidden')) {
          container.classList.remove('hidden');
          setTimeout(function () {
              container.classList.remove('visuallyhidden');
          }, 20);
      }
      if(action == 'add'){
          container.classList.add('visuallyhidden');
          setTimeout( () => {
              container.classList.add('hidden');
          }, 500)
      }
      },60)
  }

  readyToDiagramDisplay(options) {
      const diagramComponent:DiagramViewSequenceComponent = this.sequenceContainer.getDiagramComponent();
      this._customHeader.actionsButtons = [{
          id: 'save',
          title: 'Save and start sequence',
          click: diagramComponent.sendSequenceAction.bind(diagramComponent),
          disabled: true,
          className: 'btn btn-primary save_btn loading_btn m-0 mr-1'
      }];
      diagramComponent.popupModalOnClose = this.onClose;
  }

  onShownFSModal() {
      this.document.body.classList.add('modal-open','cs-workflow-main-section');
      this.sequenceContainer.getMetaData('sequence' );
  }

  onHiddenFSModal() {
      this.document.body.classList.remove('modal-open','cs-workflow-main-section');
      this.reset();
      setTimeout( () => {
          this._customHeader.ngOnDestroy();
          this.csFullModal.ngOnDestroy();
      },300)
  }

  @ViewChild('diagramFormat') diagramFormat: ElementRef<HTMLElement>;

  prepareNewXmlSource(xmlSource: string, data:object) {
      let doc = mxUtils.parseXml(xmlSource);
      let codec = new mxCodec(doc);
      const _mxGraph = new mxGraph();
      codec.decode(doc.documentElement, _mxGraph.getModel());
      let initializerBlock:mxgraph.mxCell = codec.getObject('cs_initializer');
      initializerBlock.setValue({
          model: data,
          action: 'sequence_save_send'
      });
      const codec2 = new mxCodec();
      let result = codec2.encode(_mxGraph.getModel());
      _mxGraph.destroy();
      return mxUtils.getXml(result);
  }

  sendSequenceAction() {
      const selectedSequence = this.sequenceData.find( (item:any) => item.id == this.selectedSequenceModal );
      const sequence = {};
      sequence['workflowId'] = this.selectedSequenceModal;
      sequence['sequenceObjectName'] = this.objectName;
      sequence['objectId'] = this.objectId;
      sequence['dagSource'] = this.selectedWorkflowTemplate['dagsource'];
      sequence['dagsource'] = JSON.parse(selectedSequence.dagsource);
      sequence['workflowType'] = 'Sequence';
      sequence['sequenceData'] = {
          displayContactInfo: this.displaySelectedContactInfo,
          selectedContact: this.selectedCustomerContact,
          displaySelectedContactName: this.displaySelectedContactName,
          displaySequenceName: selectedSequence['text'],
          selectedSequenceId: selectedSequence['id'],
          contactId: this.selectedContactModal,
          exitCriteria: sequence['dagsource'].blockObjects.exitCriteria,
      };
      const newXmlSource = this.prepareNewXmlSource(this.selectedWorkflowTemplate['xmlsource'], sequence['initializerValue']);
      sequence['xmlSource'] = newXmlSource;
      console.log('newXml', newXmlSource);
      this.sendingSequenceText = 'Sending';
      this.httpService.postSendSequenceRequest({sequence}, this.objectName, this.objectId)
      .subscribe( {
          next: (resp: any) => {
            if(resp['status'] == 'success') {
                this.sendingSequenceText = 'Processing'
                this.createDagRunAction(resp['data']);
            }
            else if(resp['status'] == 'error' && resp['warning']) {
                this.sendingSequenceText = 'Send'
            }
          }
      })
  }

  createDagRunAction(data:any) {
    console.log('data', data);
    const sequence = {};
    sequence['workflowId'] = data['workflowId'];
    sequence['sequenceObjectName'] = this.objectName;
    sequence['objectId'] = this.objectId;
    sequence['dagRunId'] = data['dagRunId'];
    sequence['dagStatus'] = data['dagStatus'];

    this.httpService.posSequenceDagRunCreateRequest({sequence}, this.objectName, this.objectId)
        .subscribe({
            next: (resp: any) => {
                this.sendingSequenceText = 'Created'
                if(resp['status'] == 'success') {
                    this.onClose.next(resp['data']);
                    this.httpService.triggerSequenceView({'action': 'reload'});
                }
                else if(resp['status'] == 'error' && resp['warning']) {
                    this.sendingSequenceText = 'Send'
                }
            }
        })
  }

  reset() {
    this.isOpenEditSequence=false;
  }
}
