import { OnDestroy, OnInit } from '@angular/core';
import { EventDispatcherService } from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import { FollowUpInputModel } from "@app/shared/service-reminder-communication/services/input-model";
var ScTemplateViewComponent = /** @class */ (function () {
    function ScTemplateViewComponent(eventDispatcher) {
        this.eventDispatcher = eventDispatcher;
        this.currentActiveInputModel = null;
        this.isFollowupMessage = false;
        this.currentViewPort = 'desktop';
        this.previewModel = 'email';
        this.emailMessage = '';
        this.smsMessage = '';
        this.letterMessage = '';
        this.noMessageView = true;
        this.select2Options = {
            minimumResultsForSearch: -1
        };
        this.select2PreviewModelInstance = null;
        this.emailTemplateListIndexById = {};
        this.smsTemplateListIndexById = {};
        this.loadingTemplateMessage = true;
        this.loadingMessage = true;
        this.showNextStep = false;
        this.disabledAction = true;
        this.disabledSaveAction = true;
        this.isSaving = false;
        this.activeTab = '';
        this.singlePropertyInput = null;
        this.multiplePropertyInput = null;
        this.clientDetails = {};
        this.randomMobileNumber = 0;
        this.enableView = false;
    }
    ScTemplateViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                var value = data['data'];
                if (action == EventDispatcherService.CONSTANTS['init']) {
                    _this.emailTemplateListIndexById = value['emailTemplateListIndexById'];
                    _this.smsTemplateListIndexById = value['smsTemplateListIndexById'];
                    _this.singlePropertyInput = value['singlePropertyInput'];
                    _this.multiplePropertyInput = value['multiplePropertyInput'];
                    _this.activeTab = value['activeTab'];
                    _this.loadingTemplateMessage = false;
                    _this.clientDetails = value['clientDetails'];
                }
                else if (action == EventDispatcherService.CONSTANTS['currentActiveInput']) {
                    _this.currentActiveInputModel = value['currentActiveInputModel'];
                    _this.setMessageTemplates();
                    if (value['actionName'] && value['actionName'] !== 'onChangedInterval')
                        _this.reloadMessage();
                    else
                        _this.loadingMessage = false;
                }
                else if (action == EventDispatcherService.CONSTANTS['onChangedMenuItem']) {
                    _this.currentActiveInputModel = value['currentActiveInputModel'];
                    _this.activeTab = value['activeTab'];
                    _this.setMessageTemplates();
                    _this.reloadTemplate();
                }
                else if (action == EventDispatcherService.CONSTANTS['onChangedTemplate']) {
                    _this.currentActiveInputModel = value['currentActiveInputModel'];
                    _this.setMessageTemplates();
                    _this.previewModel = value['changedTemplate'];
                    if (_this.select2PreviewModelInstance) {
                        _this.select2PreviewModelInstance.select2('val', _this.previewModel);
                    }
                    if (value['changedTemplate'] == 'email') {
                        _this.currentViewPort = 'desktop';
                    }
                    else if (value['changedTemplate'] == 'sms' || value['changedTemplate'] == 'letter') {
                        _this.currentViewPort = 'mobile';
                    }
                    _this.reloadMessage();
                }
                else if (action == EventDispatcherService.CONSTANTS['checkIsLastIndex']) {
                    _this.showNextStep = !value['checkIsLastIndex'];
                    if (!_this.showNextStep) {
                        console.log('show save button');
                        _this.disabledSaveAction = !_this.validateForm();
                    }
                }
                else if (action === EventDispatcherService.CONSTANTS['isValidForm']) {
                    _this.disabledSaveAction = _this.disabledAction = !value['isValidForm'];
                }
            }
        });
        this.randomMobileNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
    };
    ScTemplateViewComponent.prototype.validateForm = function () {
        if (this.hasDuplicateInterval()) {
            return false;
        }
        var isValid = true;
        if (this.singlePropertyInput.interval == null) {
            isValid = false;
        }
        var hasInValidMsg = function (element, index, messages) {
            return element.templateId == null;
        };
        this.singlePropertyInput.followUpMessage.every(function (followUpInput) {
            if (followUpInput.interval == null) {
                isValid = false;
                return;
            }
            isValid = !Object.values(followUpInput.messages).every(hasInValidMsg);
            return true;
        });
        if (!isValid)
            return isValid;
        if (this.multiplePropertyInput.interval == null) {
            isValid = false;
        }
        return isValid;
    };
    ScTemplateViewComponent.prototype.hasDuplicateInterval = function () {
        var singleFollowupVars = [];
        var checkIfDuplicateExists = function (arr) {
            return new Set(arr).size !== arr.length;
        };
        if (this.singlePropertyInput.followUpMessage.length > 0) {
            this.singlePropertyInput.followUpMessage.forEach(function (item) {
                singleFollowupVars.push(item.interval);
            });
            if (checkIfDuplicateExists(singleFollowupVars)) {
                return true;
            }
        }
        var multipleFollowupVars = [];
        if (this.multiplePropertyInput.followUpMessage.length > 0) {
            this.multiplePropertyInput.followUpMessage.forEach(function (item) {
                multipleFollowupVars.push(item.interval);
            });
            return checkIfDuplicateExists(multipleFollowupVars);
        }
        return false;
    };
    ScTemplateViewComponent.prototype.setMessageTemplates = function () {
        // if(!this.isFollowupMessage) {
        var messages = this.currentActiveInputModel.messages;
        this.emailMessage = messages['email']['message'];
        this.smsMessage = messages['sms']['message'];
        this.letterMessage = messages['letter']['message'];
        if (!this.emailMessage && !this.smsMessage && !this.letterMessage) {
            this.noMessageView = true;
        }
        else if (this.emailMessage && !this.smsMessage && !this.letterMessage) {
            this.noMessageView = false;
            this.currentViewPort = 'desktop';
            this.previewModel = 'email';
        }
        else if (this.smsMessage && !this.emailMessage && !this.letterMessage) {
            this.noMessageView = false;
            this.currentViewPort = 'mobile';
            this.previewModel = 'sms';
        }
        else if (this.letterMessage && !this.emailMessage && !this.smsMessage) {
            this.noMessageView = false;
            this.currentViewPort = 'mobile';
            this.previewModel = 'letter';
        }
        else {
            this.currentViewPort = 'desktop';
            this.noMessageView = false;
            this.previewModel = 'email';
        }
        // }
    };
    ScTemplateViewComponent.prototype.setSelect2PreviewModel = function (instance) {
        this.select2PreviewModelInstance = instance;
    };
    ScTemplateViewComponent.prototype.changeViewPort = function (view) {
        if (view === this.currentViewPort)
            return;
        this.currentViewPort = view;
        this.reloadMessage();
    };
    ScTemplateViewComponent.prototype.onChangePreviewOption = function (value) {
        this.previewModel = value;
        if (value == 'sms' || value == 'letter')
            this.currentViewPort = 'mobile';
        else if (value == 'email')
            this.currentViewPort = 'desktop';
        this.reloadMessage();
    };
    ScTemplateViewComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    ScTemplateViewComponent.prototype.reloadTemplate = function () {
        var _this = this;
        this.loadingTemplateMessage = true;
        setTimeout(function () {
            _this.loadingTemplateMessage = false;
        }, 1000);
    };
    ScTemplateViewComponent.prototype.reloadMessage = function () {
        var _this = this;
        this.loadingMessage = true;
        setTimeout(function () {
            _this.loadingMessage = false;
        }, 1000);
    };
    ScTemplateViewComponent.prototype.closeModal = function () {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
    };
    ScTemplateViewComponent.prototype.goNextStep = function () {
        var event = {};
        if (this.currentActiveInputModel.propertyType == 1) {
            if ((this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length == 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.singlePropertyInput.followUpMessage.length == this.currentActiveInputModel.followUpOrder)) {
                event['nextActiveItem'] = this.multiplePropertyInput;
                event['index'] = undefined;
                event['activeTab'] = 'multiple';
                event['actionName'] = 'onSelectTabMenu';
            }
            else if ((this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.singlePropertyInput.followUpMessage[this.currentActiveInputModel.followUpOrder])) {
                var index = this.currentActiveInputModel instanceof FollowUpInputModel ? this.currentActiveInputModel['followUpOrder'] : 0;
                event['nextActiveItem'] = this.singlePropertyInput.followUpMessage[index];
                event['index'] = index;
                event['activeTab'] = 'single';
                event['actionName'] = 'onClickItem';
            }
        }
        else if (this.currentActiveInputModel.propertyType == 2) {
            if ((this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0) ||
                (this.currentActiveInputModel instanceof FollowUpInputModel && this.multiplePropertyInput.followUpMessage[this.currentActiveInputModel.followUpOrder])) {
                var index = this.currentActiveInputModel instanceof FollowUpInputModel ? this.currentActiveInputModel['followUpOrder'] : 0;
                event['nextActiveItem'] = this.multiplePropertyInput.followUpMessage[index];
                event['index'] = index;
                event['activeTab'] = 'multiple';
                event['actionName'] = 'onClickItem';
            }
        }
        if (Object.keys(event).length > 0) {
            this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['goToNextItem'], event);
        }
    };
    ScTemplateViewComponent.prototype.saveAction = function () {
        this.isSaving = true;
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onSaveAction'], {});
    };
    return ScTemplateViewComponent;
}());
export { ScTemplateViewComponent };
