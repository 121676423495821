 <div class="pb-12" [formGroup]="form">
     <label class="form-label required_field" [attr.for]="tag.key">{{tag.label}}</label>
     <div [ngSwitch]="tag.answerType">
         <input *ngSwitchCase="'input'" type="text" class="form-control form-input" [formControlName]="tag.key" [id]="tag.key" />
         <input *ngSwitchCase="'number'" type="number" class="form-control form-input" [formControlName]="tag.key" [id]="tag.key" />
         <select [id]="tag.key" *ngSwitchCase="'dropdown'" [formControlName]="tag.key">
             <option *ngFor="let opt of tag.options" [value]="opt">{{opt}}</option>
         </select>
     </div>

     <div *ngIf="isNotValid" class="errorMessage">
         <small>This field is required</small>
     </div>
 </div>
