import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommonDataService = /** @class */ (function (_super) {
    tslib_1.__extends(CommonDataService, _super);
    function CommonDataService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.savedColorsData = new BehaviorSubject(null);
        _this.savedColorsData$ = _this.savedColorsData.asObservable();
        return _this;
    }
    CommonDataService.prototype.fetchSavedColorsData = function () {
        var _this = this;
        this.http.get(this.getApiUrl('getColorsCollection'))
            .subscribe(function (result) {
            if (result) {
                _this.setsavedColorsData(result);
            }
        });
    };
    CommonDataService.prototype.setsavedColorsData = function (data) {
        this.savedColorsData.next(data);
    };
    CommonDataService.ngInjectableDef = i0.defineInjectable({ factory: function CommonDataService_Factory() { return new CommonDataService(i0.inject(i1.HttpClient)); }, token: CommonDataService, providedIn: "root" });
    return CommonDataService;
}(InterfaceService));
export { CommonDataService };
