// Auth
export { AuthService } from './auth/auth.service';
export { AuthGuard } from './auth/auth.guard';
export { UrlPatternGuard } from './auth/url-pattern.guard';
export { V5AuthService } from './auth/v5-auth.service';

// Interceptors
export { AppHttpInterceptorService } from './interceptors/app-http-interceptor.service';
export { ErrorHandlerService } from './interceptors/error-handler.service';

//--
export { SettingsService } from './settings/settings.service';
export { CoreModule } from './core.module';
export { setUpLocationSync } from './router-upgrade';
export { HybridFactory } from './hybrid-factory';
