import { navigateToAjs } from './navigator';
import { UPGRADED_ROUTES } from '../config';
import { environment } from '../../environments/environment';
import { resolveUrl } from "@app/core/resolve-url";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgZone } from "@angular/core";
import { CanDeactivateGuard } from "@app/services";
var pathToNav;
var apiHost = environment.apiHost;
var HybridFactory = /** @class */ (function () {
    function HybridFactory() {
    }
    HybridFactory.getEnvironment = function () {
        return JSON.parse(JSON.stringify(environment));
    };
    HybridFactory.isUpgraded = function (url) {
        var presentInUpgradedList = UPGRADED_ROUTES.find(function (pathPattern) {
            return (new RegExp(pathPattern, 'g')).test(url);
        });
        return !!presentInUpgradedList;
    };
    // Get AngularJs state from url
    HybridFactory.getState = function ($state, url, queryParams) {
        var match = $state.get().find(function (state) {
            if (!state.name || state.abstract || !state.$$state)
                return false;
            var privatePortion = state.$$state();
            if (!privatePortion.url)
                return false;
            return privatePortion.url.exec(url, queryParams);
        });
        return match ? { state: match, params: match.$$state().url.exec(url, queryParams) } : null;
    };
    HybridFactory.redirectNg7Route = function (route) {
        var router = HybridFactory.ngUpgrade.injector.get(Router), location = HybridFactory.ngUpgrade.injector.get(Location), $state = HybridFactory.ngUpgrade.$injector.get('$state'), ngZone = HybridFactory.ngUpgrade.injector.get(NgZone);
        var url = resolveUrl(route), path = location.normalize(url.pathname);
        ngZone.run(function () {
            $state.go('loggedin.empty');
            HybridFactory.routeContainerCleanedUp = true;
            router.navigateByUrl(path + url.search + url.hash);
        });
    };
    // AngularJs location change start hook
    HybridFactory.onLocationChangeStart = function (event, next, full) {
        var router = HybridFactory.ngUpgrade.injector.get(Router), location = HybridFactory.ngUpgrade.injector.get(Location), ngZone = HybridFactory.ngUpgrade.injector.get(NgZone), $state = HybridFactory.ngUpgrade.$injector.get('$state');
        var url = resolveUrl(next), path = location.normalize(url.pathname), isUpgraded = HybridFactory.isUpgraded(decodeURIComponent(path));
        // This is if we get this from statechange for switching to a new/old UI views
        if (HybridFactory.urlSwitchedDueToVersion) {
            HybridFactory.urlSwitchedDueToVersion = false;
            event.preventDefault();
            pathToNav = resolveUrl(full).pathname;
            HybridFactory.transitState = {
                name: $state.current.name,
                url: $state.href($state.current.name)
            };
            $state.go('loggedin.empty');
            return;
        }
        // if ('clientsconfig' in event.targetScope.accessDetails &&
        //     'NewPropertyView' in event.targetScope.accessDetails.clientsconfig) {
        var regex = new RegExp("^/customers/(customer|customer_list|work_address)/(\\d+)/view/property/view");
        if (regex.test(path)) {
            var groupings_1 = regex.exec(path);
            ngZone.run(function () { return router.navigateByUrl('/customers/' + groupings_1[1] + '/' + groupings_1[2] + '/view_v2/property/view'); });
            HybridFactory.urlSwitchedDueToVersion = true;
            event.preventDefault();
            return;
        }
        // }
        var pathName = window.location.pathname;
        localStorage.setItem('redirectTo', pathName);
        if ((next == apiHost + "/system_settings" || next == apiHost + "/company_settings" || next.includes('_settings#'))) {
            var pathName_1 = window.location.pathname;
            //console.log("<--->1:"+pathName);
            if (localStorage.getItem('redirectTo') != "/settings" && localStorage.getItem('redirectTo') != "") {
                localStorage.setItem('redirectTo', pathName_1);
            }
            navigateToAjs('/settings?prevSetting=' + pathName_1);
        }
        else {
            if (next.includes('settings#')) {
                var pathName_2 = window.location.pathname;
                //console.log("<--->"+pathName);
                navigateToAjs('/settings?prevSetting=' + pathName_2);
                if (localStorage.getItem('redirectTo') != "/settings" && localStorage.getItem('redirectTo') != "") {
                    localStorage.setItem('redirectTo', pathName_2);
                }
                //router.navigateByUrl('/settings?prevSetting=' + pathName);
                event.preventDefault();
                //return;
            }
        }
        if (HybridFactory.popstate || (isUpgraded && HybridFactory.routeContainerCleanedUp)) {
            event.preventDefault();
        }
        // Preventing repeated navigation on, ng2 to ng redirection
        else if (!HybridFactory.popstate && !isUpgraded && pathToNav == path && $state.current.name !== 'loggedin.empty') {
            event.preventDefault();
        }
        // Preventing navigation for the unsaved changes warning confirmation in any angular7 component
        HybridFactory.checkForNavigationConfirmation(event, url.pathname + url.hash);
        pathToNav = path;
        // Avoid AngularJs navigation for AngularES routes
        if (!event.defaultPrevented) {
            ngZone.run(function () { return router.navigateByUrl(path + url.search + url.hash); });
        }
        // Handle popstate events
        else if (HybridFactory.popstate) {
            var popstateIsUpgraded = HybridFactory.isUpgraded(HybridFactory.popstate), popstate = resolveUrl(HybridFactory.popstate);
            pathToNav = popstate.pathname;
            // Fill up the AngularJs ui-view, While getting back to the ng-ng2 transition state
            if (!popstateIsUpgraded
                && $state.current.name == "loggedin.empty"
                && HybridFactory.transitState) {
                var toState = HybridFactory.getState($state, HybridFactory.transitState.url == popstate.pathname ? HybridFactory.transitState.url : popstate.pathname, {});
                if (toState)
                    $state.go(toState.state.name, toState.params);
                HybridFactory.routeContainerCleanedUp = false;
                HybridFactory.transitState = null;
            }
            // Transit AngularJs state with respect to the location
            else if (!popstateIsUpgraded && $state.current.name !== "loggedin.empty") {
                var matchedState = HybridFactory.getState($state, popstate.pathname, {});
                if (matchedState)
                    $state.go(matchedState.state.name, matchedState.params);
            }
            // Clean up AngularJs ui-view, While ng-ng2 popstate transition
            else if (!HybridFactory.routeContainerCleanedUp && popstateIsUpgraded) {
                HybridFactory.transitState = {
                    name: $state.current.name,
                    url: $state.href($state.current.name)
                };
                $state.go('loggedin.empty');
                HybridFactory.routeContainerCleanedUp = true;
            }
        }
        HybridFactory.popstate = null;
    };
    HybridFactory.onStateChangeStart = function (event, toRoute, toParams, fromRoute, fromParams) {
        var router = HybridFactory.ngUpgrade.injector.get(Router), location = HybridFactory.ngUpgrade.injector.get(Location), ngZone = HybridFactory.ngUpgrade.injector.get(NgZone), $state = HybridFactory.ngUpgrade.$injector.get('$state');
        // if ('clientsconfig' in event.targetScope.accessDetails &&
        //     'NewPropertyView' in event.targetScope.accessDetails.clientsconfig) {
        if (toRoute.name == 'loggedin.customer_list.view.property') {
            ngZone.run(function () { return router.navigateByUrl('/customers/' + toParams['type'] + '/' + toParams['id'] + '/view_v2/property/view'); });
            HybridFactory.urlSwitchedDueToVersion = true;
            event.preventDefault();
            return;
        }
        // }
        HybridFactory.checkForNavigationConfirmation(event, toRoute.url);
    };
    // AngularJs location change success hook
    HybridFactory.onLocationChangeSuccess = function (event, caller, rootScope) {
        var $state = HybridFactory.ngUpgrade.$injector.get('$state'), settingsService = HybridFactory.ngUpgrade.$injector.get('NgSettingsService'), isUpgraded = HybridFactory.isUpgraded(decodeURIComponent(pathToNav));
        var $rootScope = HybridFactory.ngUpgrade.$injector.get('$rootScope');
        /*new change start - screen overlap issue */
        var subscription = HybridFactory.ngUpgrade.ngZone.onMicrotaskEmpty.subscribe(function () {
            if ($rootScope.$$phase && ($rootScope.$$phase === "$apply" || $rootScope.$$phase === "$digest")) {
                $rootScope.$$phase = null;
                //console.trace('My-trace');
                return $rootScope.$evalAsync();
            }
            // return $rootScope.$digest();
        });
        $rootScope.$on('$destroy', function () { subscription.unsubscribe(); });
        /*new change end*/
        if (isUpgraded && (caller == 'NG2'
            || $state.current.name == "" // This is to prevent show mobile device warning on page refresh
        )) {
            // Route back to AngularJS pages if its bookmarked
            if (!HybridFactory.routeContainerCleanedUp) {
                HybridFactory.transitState = {
                    name: $state.current.name,
                    url: $state.href($state.current.name)
                };
                $state.go('loggedin.empty');
            }
            else {
                // Update active tab for angularES navigation
                if (event.url && rootScope) {
                    var tabValue = event.url.split('/');
                    if (tabValue[1] != null && tabValue[1] != '') {
                        rootScope.selectedTab = tabValue[1];
                    }
                }
            }
            HybridFactory.routeContainerCleanedUp = true;
        }
        else
            HybridFactory.routeContainerCleanedUp = false;
    };
    // AngularJs state change success hook
    HybridFactory.onStateChangeSuccess = function (toRoute, toParams, fromRoute, fromParams) {
        HybridFactory.previousState = {
            name: fromRoute.name,
            url: fromRoute.url,
            params: fromParams
        };
    };
    HybridFactory.checkForNavigationConfirmation = function (event, url) {
        if (!event.defaultPrevented && CanDeactivateGuard.confirmationCallback) {
            CanDeactivateGuard.invoke(url);
            if (!CanDeactivateGuard.invokeResult)
                event.preventDefault();
        }
    };
    HybridFactory.navigate = navigateToAjs;
    HybridFactory.routeContainerCleanedUp = false;
    HybridFactory.urlSwitchedDueToVersion = false;
    return HybridFactory;
}());
export { HybridFactory };
