import { Component, OnInit, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

import { AddOpportunityService } from '@app/features/customers/add-opportunity/service/add-opportunity.service';
import { environment } from '../../../environments/environment';
import { fontfamilyService } from '@app/shared/services/fontfamily.service';
import {
    ProposalTemplateService
} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {AuthService} from "@app/core";
declare var $: any;


@Component({
  selector: 'app-table-formatter-options',
  templateUrl: './table-formatter-options.component.html',
  styleUrls: ['./table-formatter-options.component.scss']
})
export class TableFormatterOptionsComponent implements OnInit {
  public onClose: Subject<boolean>;
  listHideShow: boolean = false;
  horizontalVisibility: boolean = false;
  breakdown: boolean = true;
  taxStatus: any = "withouttax";
  currentSectionId; any = 0;
  breakdownView: any = 'vertical';
  existingValues: any = [];
  existingValuesBackup: any = [];
  editorStatus: any;
  tablePkId: any;
  tableType: any;
  isOptionSummary: any;
  isNormalTable: any;
  taxLabel:any;
  vatType:any;
  vatEnabled: boolean;
  configData: any;
  tableHeaders:any = [];
  isDisabledHorizentalOrVerticalOption : boolean = false;
  titleStyleFontFamily = this.fontFamilyService.getFontFamilyArr();
  titleStyleFontWeight = [
    { name: 'Bold', value: 600,visibility:true },
    { name: 'Semi-Bold', value: 400,visibility:true },
    { name: 'Medium', value: 300,visibility:false },
    { name: 'Light', value: 100,visibility:false },
  ]
  rowStyleFontWeight = [
    { name: 'Bold', value: 600,visibility:true },
    { name: 'Semi-Bold', value: 400,visibility:true },
    { name: 'Medium', value: 300,visibility:false },
    { name: 'Light', value: 100,visibility:false },
  ]
  subtotalVisible: any = 'yes';
  showPriceBreakdown: any = true;
  columnShowHide: boolean = true;
  selectedHeaderFont: any = "Arial";
  seletedRowFont: any = "Arial";
  selectedHeaderFontWeight: any = 400;
  selectedRowFontWeight: any = 400;
  titleSize: any = 14;
  rowSize: any = 14;
  titleHeight: any = 20;
  rowHeight: any = 20;
  titleClr: any = "No color";
  titleBg: any = "No background";
  borderRadius: any = 0;
  borderWidth: any = 1;
  titleAlignment: any = "left";
  rowAlignment: any = "left";
  color_options = {
    type: "component",
    showPalette: false,
    hideAfterPaletteSelect: true,
    showButtons: false,
    allowEmpty: false,
    preferredFormat: "hex",
    replacerClassName: 'format-replace',
    containerClassName: 'container-palette',
    showInput: true,


  }
  borderTopLeft:any = 0;
  borderTopRight:any = 0;
  borderBottomRight:any = 0;
  borderBottomLeft:any = 0;

  rowTextClr: any = "No color";
  rowBgClr: any = "No background";
  rowAltClr: any = "No alternate background";
  borderColor: any = "No border";
  borderIndividualSide: boolean = false;
  inputEnabled: boolean = false;
  templateName:any;
  hashId = '';

  //total section advance stling

    subtotalHeaderFont: any = "Arial";
    subtotalHeaderFontWeight: any = 400;
    subtotalTitleSize: any = 14;
    subtotalTitleClr: any = "No color";

    subtotalTitleStyleFontWeight = [
        { name: 'Bold', value: 600,visibility:true },
        { name: 'Semi-Bold', value: 400,visibility:true },
    ]

    grandtotalHeaderFont: any = "Arial";
    grandtotalHeaderFontWeight: any = 400;
    grandtotalTitleSize: any = 14;
    grandtotalTitleClr: any = "No color";
    grandtotalTitleStyleFontWeight = [
        { name: 'Bold', value: 600,visibility:true },
        { name: 'Semi-Bold', value: 400,visibility:true },
    ]

  modal: boolean;
  taxInclusive: boolean;
  taxChange: boolean = false;
    borderColorStyle: any;
    myValue: any;
  constructor(
    public bsModalRef: BsModalRef,
    private opportunityService: AddOpportunityService,
    private fontFamilyService: fontfamilyService,
    public proposaltemplateservice: ProposalTemplateService,
    private authService: AuthService
  ) {
    $('.sp-container').remove();
  }

  ngOnInit() {
      if (this.vatEnabled) {
          this.tableHeaders = [
              {name: 'Quantity', value: '1', checked: true},
              {name: 'Unit price', value: '2', checked: true},
              {name: this.taxLabel, value: '3', checked: true},
              {name: `Total  (${this.vatType} ${this.taxLabel})`, value: '4', checked: true}
          ];
      } else {
          this.tableHeaders = [
              {name: 'Quantity', value: '1', checked: true},
              {name: 'Unit price', value: '2', checked: true},
              {name: `Total`, value: '4', checked: true}
          ];
      }
    this.isDisabledHorizentalOrVerticalOption;
    this.getAdvancedStylingTemplates();
    this.onClose = new Subject();
    window.addEventListener('scroll', this.scrollEvent, true);
    if (this.existingValues) {
        if (this.existingValues.advancedStyle && !this.existingValues.advancedStyle.hasOwnProperty('save')){
            if (this.existingValues.advancedStyle.hasOwnProperty('sectionId') && !this.existingValues.advancedStyle.hasOwnProperty('save') || this.existingValues.advancedStyle.hasOwnProperty('currentSectionId')){
                this.getUndefinedTableDefultValue();
            }
        }
      this.existingValuesBackup = this.existingValues;
    }
    if (this.configData ) {
        this.breakdownView = (this.configData.breakdownView) ? this.configData.breakdownView : 'vertical';
        this.breakdown = (this.configData.breakdownSwitch) ? this.configData.breakdownSwitch : true ;
        this.listHideShow = (this.configData.listHideShow) ? this.configData.listHideShow : false;
        if(typeof this.configData.showHideColumns != "undefined"){
            this.showPriceBreakdown = this.configData.showHideColumns;
        }
        // this.taxStatus =  this.configData.tax;
        if (this.configData.visibleColumn) {
            this.tableHeaders = this.configData.visibleColumn;
            if (this.vatEnabled) {
                this.tableHeaders[2]['name'] = this.taxLabel;
                this.tableHeaders[3]['name'] = `Total  (${this.vatType} ${this.taxLabel})`;
            } else {
                if (this.tableHeaders[2]['name'] != 'Total') {
                    this.tableHeaders[2] = {name: `Total`, value: '4', checked: true};
                    this.tableHeaders[2]['checked'] = this.tableHeaders[3]['checked'];
                    this.tableHeaders.pop(3);
                }
                /*this.tableHeaders[2] = {};
                this.tableHeaders[3]['name'] = `Total`;*/
            }
        }
        if (this.configData.subtotalVisible) {
            this.subtotalVisible = this.configData.subtotalVisible;
        }
    }
    if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.advancedStyle) {
      let styleData: any = this.existingValues.advancedStyle;
      if(Array.isArray(styleData.borderRadius)){
        this.borderTopLeft = styleData.borderRadius[0];
        this.borderTopRight = styleData.borderRadius[1];
        this.borderBottomRight = styleData.borderRadius[2];
        this.borderBottomLeft = styleData.borderRadius[3];
        this.borderRadius = 0;
        this.borderIndividualSide = true;
      }
      else{
        this.borderRadius = styleData.borderRadius;
        this.borderTopLeft = 0;
        this.borderTopRight = 0;
        this.borderBottomRight = 0;
        this.borderBottomLeft = 0;
        this.borderIndividualSide = false;
      }
      this.selectedHeaderFont = styleData.selectedHeaderFont;
      this.selectedHeaderFontWeight = styleData.selectedHeaderFontWeight;
      this.titleSize = styleData.titleSize;
      this.titleAlignment = styleData.titleAlignment;
      this.titleHeight = styleData.titleHeight;
      this.titleClr = styleData.titleClr;
      this.titleBg = styleData.titleBg;
      this.seletedRowFont = styleData.seletedRowFont;
      this.selectedRowFontWeight = styleData.selectedRowFontWeight;
      this.rowSize = styleData.rowSize;
      this.rowAlignment = styleData.rowAlignment;
      this.rowHeight = styleData.rowHeight;
      this.rowTextClr = styleData.rowTextClr;
      this.rowBgClr = styleData.rowBgClr;
      this.rowAltClr = styleData.rowAltClr;
      this.borderWidth = styleData.borderWidth;
      this.borderColor = styleData.borderColor;

      this.subtotalHeaderFont = styleData.subtotalHeaderFont || this.subtotalHeaderFont;
      this.subtotalHeaderFontWeight = styleData.subtotalHeaderFontWeight || this.subtotalHeaderFontWeight;
      this.subtotalTitleSize = styleData.subtotalTitleSize || this.subtotalTitleSize;
      this.subtotalTitleClr = styleData.subtotalTitleClr || this.subtotalTitleClr;

      this.grandtotalHeaderFont = styleData.grandtotalHeaderFont || this.grandtotalHeaderFont;
      this.grandtotalHeaderFontWeight = styleData.grandtotalHeaderFontWeight || this.grandtotalHeaderFontWeight;
      this.grandtotalTitleSize = styleData.grandtotalTitleSize || this.grandtotalTitleSize;
      this.grandtotalTitleClr = styleData.grandtotalTitleClr || this.grandtotalTitleClr;

        this.rowFontChange(this.seletedRowFont,false);
      this.headerFontChange(this.selectedHeaderFont,false);
    }
    if (this.breakdownView == "horizontal") {
      this.horizontalVisibility = true;
    }
    else {
      this.horizontalVisibility = false;
    }
    if (this.showPriceBreakdown == true) {
      this.columnShowHide = true;
    }
    else {
      this.columnShowHide = false;
    }
    if (this.isNormalTable){
        let oldEle = this.save(true);
        let msg = { type: 'normalTableStylingCheck', oldEle }
        window.top.postMessage(msg, '*')
    }else if(this.isOptionSummary){
        let oldEle = this.save(true);
        let msg = { type: 'summaryTableStylingCheck', oldEle }
        window.top.postMessage(msg, '*')
    }
      if(this.borderColor == 'No border'){
          this.borderColorStyle = '#ffffff'
      } else {
          this.borderColorStyle = this.borderColor
      }
  }



  scrollEvent = (event: any): void => {
    $(".sp-container").each(function() {
      if(!$(this).hasClass('sp-hidden')){
        $(this).addClass('sp-hidden');
        $('.pricing-view-title').click();
      }
  });
  }

  ngAfterViewInit() {
    if (this.editorStatus == 'style'){
      var that = this

    }
      if($('#tax2').is(':checked')){
          this.taxInclusive = true
      }
    // this.refreshSpectrum();
  }

  refreshSpectrum(){
    for (let index = 0; index < 4; index++) {

    }
  }
  rgbaToHex(r, g, b, a) {
    var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
        if (part.length === 1) {
            outParts[i] = '0' + part;
        }
    })
    return ('#' + outParts.join(''));
  }

  rgbToHex(rgb) {
    rgb = rgb.trim()
    var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
    var result, r, g, b, hex = "";
    if ((result = rgbRegex.exec(rgb))) {
        r = this.componentFromStr(result[1], result[2]);
        g = this.componentFromStr(result[3], result[4]);
        b = this.componentFromStr(result[5], result[6]);

        hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    return hex;
  }
    // convert rgb to hex
    componentFromStr(numStr, percent) {
      var num = Math.max(0, parseInt(numStr, 10));
      return percent ?
          Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
  }
  renderTable() {
    let hiddenColumn = [];
    this.tableHeaders.forEach((element, index, columnHeader) => {
      if (!element.checked) {
        hiddenColumn.push(element.value);
      }
    });

    let headerData = {
      tableId: 'Pricing_' + this.currentSectionId,
      hiddenValues: hiddenColumn,
    }
    // this.opportunityService.setHandsonColumnFilter(headerData)
  }

  listHideShowToggle() {
    this.listHideShow = !this.listHideShow;
    this.postWindowsMsg();
  }

  visibleColumnChange(evt, id) {
    if (evt.target.checked) {
      this.tableHeaders[id].checked = true;
    }
    else if (!evt.target.checked) {
      this.tableHeaders[id].checked = false;
    }

    let hiddenColumn = [];
    this.tableHeaders.forEach((element, index, columnHeader) => {
      if (!element.checked) {
        hiddenColumn.push(element.value);
      }
    });
    // let data = {
    //   tableId: 'Pricing_' + this.currentSectionId,
    //   hiddenValues: hiddenColumn,
    // }
    // this.opportunityService.setHandsonColumnFilter(data)
    // setTimeout(() => {
    //   this.titleRefresh('height');
    // }, 200);
  }

  horizontalVisibilityToggle(val) {
    this.breakdownView = val;
    if (val == "horizontal") {
      this.horizontalVisibility = true;
    }
    else {
      this.horizontalVisibility = false;
    }
    this.postWindowsMsg();
  }

  breakdownToggle() {
    this.breakdown = !this.breakdown;
    this.postWindowsMsg();
  }

  taxStatusToggle(val) {
      this.taxChange = !this.taxChange;
    this.taxStatus = val;
    if(val == 'withtax'){
        this.taxInclusive = true;
        if($('#yes').is(':checked')){
            $('#yes').prop('checked', false)
            $('#no').prop('checked', true)
        }
    } else {
        this.taxInclusive = false;
        $('#yes').prop('checked', true);
        this.subtotalVisible = 'yes';
        if ($('#no').is(':checked')) {
            $('#yes').prop('checked', true);
            $('#no').prop('checked', false);
        }
    }
    this.postWindowsMsg();
  }

  postWindowsMsg() {
    // let breakdownSwitch = this.breakdown;
    // let breakdownView = this.breakdownView;
    // let tax = this.taxStatus;
    // let visibleColumn = this.tableHeaders;
    // let currentTableID = "pricing_cs_component_" + this.currentSectionId;
    // let showHideColumns = this.columnShowHide;
    // let stylingData:any = '';
    // if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
    //   stylingData = this.existingValues.advancedStyle;
    // }
    // else if (this.editorStatus == 'style') {
    //   stylingData = this.formatStylingData();
    // }
    // let options = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow,'isOptionSummary':this.isOptionSummary  };
    // let msg = { type: 'breakdown', options }
    // window.top.postMessage(msg, '*')
    // if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.style != '') {
    //   setTimeout(() => {
    //     this.titleRefresh('height');
    //   }, 300);
    // }
  }

  save(getJson=false) {
      let taxvalue = "Exclusive";
      if (this.taxStatus == "withtax") {
          taxvalue = "Inclusive"
          this.vatType = 'Inc';
      } else {
          this.vatType = 'Exc';
      }
      if (this.vatEnabled) {
          this.tableHeaders[3]['name'] = `Total  (${this.vatType} ${this.taxLabel})`;
      } else {
          this.tableHeaders[2]['name'] = `Total`;
      }

      let data = {
        tablePkId: this.tablePkId,
        tableType: this.tableType,
        value: taxvalue,
        type: "POST"
      }
      let breakdownSwitch = this.breakdown;
      let breakdownView = this.breakdownView;
      let tax = this.taxStatus;
      let showHideColumns = this.columnShowHide;
      let currentTableID = this.isNormalTable ? this.hashId : this.isOptionSummary == true ? "pricing-items-breakup-tpl_" + this.currentSectionId :  "pricing_cs_component_" + this.currentSectionId;
      let visibleColumn = this.tableHeaders;
      let stylingData:any = '';


      let msg = { type: 'saveChanges' };
      $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');

      if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
        stylingData = this.existingValues.advancedStyle;
      }
      else if (this.editorStatus == 'style') {
        stylingData = this.formatStylingData();
      }
      let options:any = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData,'isOptionSummary':this.isOptionSummary,'isNormalTable':this.isNormalTable,'taxData':data,'editorStatus':this.editorStatus };
      if(getJson){
        return options;
      }
      else{
          options.save = true
        let msg = { type: 'breakdown', options, taxChange: this.taxChange }
          this.taxChange = false;
        window.top.postMessage(msg, '*')
        setTimeout(() => {
          this.bsModalRef.hide();
        }, 300);
      }
      if(this.isOptionSummary){
          this.saveTemplate();
      }
      // this.taxChange = false;
      this.proposaltemplateservice.setEditorThumbnail('updateTableElement');
  }

  radiusAllSide(){
    this.borderIndividualSide = !this.borderIndividualSide;
    if(this.borderIndividualSide){
        $('.all-side-border-radius').addClass('selected-style');
        this.borderTopLeft = this.borderTopRight = this.borderBottomRight = this.borderBottomLeft = 0;
    }
    else{
        this.borderRadius = 0;
        $('.all-side-border-radius').removeClass('selected-style');
    }
    this.borderRefresh();
  }
  subtotalStatusChange(val) {
    this.subtotalVisible = val;
    this.postWindowsMsg();
  }
  priceTableBreakdown(val) {
    this.showPriceBreakdown = val;
    if (val == "withtblbreakdown") {
      this.columnShowHide = true;
    }
    else {
      this.columnShowHide = false;
    }
    this.postWindowsMsg();
  }

  titleAlignContent(alignment, position) {
    $('.align-font').css({ opacity: '50%' })
    $('.title-set-remove-bg').removeClass("switch-color-content-alignment");
    $('.' + position + '').addClass("switch-color-content-alignment");
    this.titleAlignment = alignment;
    this.titleRefresh();
  }

  rowAlignContent(alignment, position) {
    $('.align-font').css({ opacity: '50%' })
    $('.row-set-remove-bg').removeClass("switch-color-content-alignment");
    $('.' + position + '').addClass("switch-color-content-alignment");
    this.rowAlignment = alignment;
    this.rowRefresh();
  }

  headerFontChange(value: any,status=true) {
    if(['Caveat','Comfortaa','EB Garamond','Lora','Merriweather','Montserrat','Nunito','Oswald',
      'Playfair Display','Roboto', 'Roboto Mono','Spectral'].includes(value)){
      this.titleStyleFontWeight[2].visibility = true;
      this.titleStyleFontWeight[3].visibility = true;
    }
    else{
      if(status){
        this.selectedHeaderFontWeight = 400;
      }
      this.titleStyleFontWeight[2].visibility = false;
      this.titleStyleFontWeight[3].visibility = false;
    }
    this.selectedHeaderFont = value;
    this.titleRefresh();
  }

    subtotalFontChange(value: any,status=true){
        if(['Caveat','Comfortaa','EB Garamond','Lora','Merriweather','Montserrat','Nunito','Oswald',
            'Playfair Display','Roboto', 'Roboto Mono','Spectral'].includes(value)){
            this.subtotalTitleStyleFontWeight[0].visibility = true;
            this.subtotalTitleStyleFontWeight[1].visibility = true;
        }
        else{
            if(status){
                this.subtotalHeaderFontWeight = 400;
            }
        }
        this.subtotalHeaderFont = value;
        this.titleRefresh();
    }

    subtotalFontWeightChange(value: any) {
        this.subtotalHeaderFontWeight = value;
        this.titleRefresh();
    }

    grandtotalFontChange(value: any,status=true){
        if(['Caveat','Comfortaa','EB Garamond','Lora','Merriweather','Montserrat','Nunito','Oswald',
            'Playfair Display','Roboto', 'Roboto Mono','Spectral'].includes(value)){
            this.grandtotalTitleStyleFontWeight[0].visibility = true;
            this.grandtotalTitleStyleFontWeight[1].visibility = true;
        }
        else{
            if(status){
                this.grandtotalHeaderFontWeight = 400;
            }
        }
        this.grandtotalHeaderFont = value;
        this.titleRefresh();
    }

    grandtotalFontWeightChange(value: any) {
        this.grandtotalHeaderFontWeight = value;
        this.titleRefresh();
    }
  headerFontWeightChange(value: any) {
    this.selectedHeaderFontWeight = value;
    this.titleRefresh();
  }

  rowFontChange(value: any,status=true) {
    if(['Caveat','Comfortaa','EB Garamond','Lora','Merriweather','Montserrat','Nunito','Oswald',
      'Playfair Display','Roboto', 'Roboto Mono','Spectral'].includes(value)){
      this.rowStyleFontWeight[2].visibility = true;
      this.rowStyleFontWeight[3].visibility = true;
    }
    else{
      if(status){
        this.selectedRowFontWeight = 400;
      }
      this.rowStyleFontWeight[2].visibility = false;
      this.rowStyleFontWeight[3].visibility = false;
    }
    this.seletedRowFont = value;
    this.rowRefresh();
  }

  rowFontWeightChange(value: any) {
    this.selectedRowFontWeight = value;
    this.rowRefresh();
  }

  sendChangesToEditor() {
      let data = this.formatStylingData();
      let msg = { type: 'changeSectionStyling', style: data };
      $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
  }

  titleRefresh(status = '') {
      this.sendChangesToEditor();
  }

  rowRefresh() {
      this.sendChangesToEditor();
  }

  borderRefresh() {
    this.titleRefresh('border');
  }
  getColor(data,type){
      if(type == 'titleTextColor'){
          this.titleClr = data;
          this.titleRefresh();
      } else if ( type == 'titleBackgroundColor'){
          this.titleBg = data;
          this.titleRefresh()
      } else if ( type == 'rowTextColor') {
          this.rowTextClr = data;
          this.rowRefresh()
      } else if ( type == 'rowBackgroundColor') {
          this.rowBgClr = data;
          this.rowRefresh()
      } else if (type == 'rowAlternateColor') {
          this.rowAltClr = data;
          this.rowRefresh()
      }else if(type == 'subtotal'){
          this.subtotalTitleClr = data;
          this.rowRefresh()
      }else if(type == 'grandtotal'){
          this.grandtotalTitleClr = data;
          this.rowRefresh()
      } else {
          this.borderColor = data
          this.borderColorStyle = data
          this.borderRefresh()
      }
  }
  formatStylingData() {
    let borderRadius = this.borderRadius;
    if(this.borderIndividualSide){
      borderRadius = [this.borderTopLeft,this.borderTopRight,this.borderBottomRight,this.borderBottomLeft];
    }
    let data = {

      selectedHeaderFont: this.selectedHeaderFont,
      selectedHeaderFontWeight: this.isNormalTable && this.existingValues == null ? 600 : this.selectedHeaderFontWeight,
      titleSize: this.isNormalTable && this.existingValues == null ? 16 : this.titleSize,
      titleAlignment: this.isNormalTable && this.existingValues == null ? 'center' : this.titleAlignment,
      titleHeight: this.titleHeight,
      titleClr: this.titleClr,
      titleBg: this.titleBg,
      seletedRowFont: this.seletedRowFont,
      selectedRowFontWeight: this.selectedRowFontWeight,
      rowSize: this.rowSize,
      rowAlignment: this.isNormalTable && this.existingValues == null ? 'center' : this.rowAlignment,
      rowHeight: this.rowHeight,
      rowTextClr: this.rowTextClr,
      rowBgClr: this.rowBgClr,
      rowAltClr: this.rowAltClr,
      borderWidth: this.borderWidth,
      borderRadius: borderRadius,
      borderColor: this.borderColor,

      subtotalHeaderFont : this.subtotalHeaderFont,
      subtotalHeaderFontWeight : this.subtotalHeaderFontWeight,
      subtotalTitleSize : this.subtotalTitleSize,
      subtotalTitleClr : this.subtotalTitleClr,

      grandtotalHeaderFont : this.grandtotalHeaderFont,
      grandtotalHeaderFontWeight : this.grandtotalHeaderFontWeight,
      grandtotalTitleSize : this.grandtotalTitleSize,
      grandtotalTitleClr : this.grandtotalTitleClr,

      save: true,
      currentSectionId: this.isNormalTable ? '' : this.currentSectionId,
      hashId: this.hashId,
      isNormalTable: this.isNormalTable,
      optionSummary : this.isOptionSummary ? true : false,
      sectionId: this.isNormalTable ? '' : this.currentSectionId,
    }
    return data;
  }

    getUndefinedTableDefultValue(){
      let getUndefineStyleData = this.formatStylingData();
      this.existingValues = {
          'advancedStyle' : getUndefineStyleData
        }
      // this.selectedHeaderFont = getUndefineStyleData['selectedHeaderFont'];
      // this.selectedHeaderFontWeight = getUndefineStyleData['selectedHeaderFontWeight'];
  }
  cancel(){
      let msg = { type: 'revertAppliedStyles', sectionId: this.currentSectionId, tableType :  this.isOptionSummary ? 'optionSummary' : 'pricingSection' };
      $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
    let data:any = this.save(true)
    this.onClose.next(data);
    this.bsModalRef.hide();
  }
  getCheckValue(value){
    if(value){
      this.inputEnabled = true;
    }
    else{
      this.inputEnabled = false;
    }
  }

  saveTemplate() {
    let stylingData: any = '';
    stylingData = this.formatStylingData();
    let options: any = {
      "templateName": this.templateName,
      "advancedStyle": stylingData,
      "currentSectionId" : this.currentSectionId,
    }
    options.save = true
    this.opportunityService.addAdvancedStylingTemplate(options).subscribe(resp => {
      if (resp['message'] == 'success') {
        setTimeout(() => {
          this.bsModalRef.hide();
        }, 300);
      }
    });

  }

  getAdvancedStylingTemplates() {
      let getAdvanceStylingPermission = this.authService.permitted(['AdvancedStyling.readaccess']) === true;
      if (getAdvanceStylingPermission){
          this.opportunityService.getAdvancedStylingTemplate().subscribe(
              data => {

              });
      }

  }
    defaultColor(event){
        let value = Number(event.target.value)
        if(value > 1 && this.borderColor == 'No border'){
            this.borderColor = '#595B65'
            this.borderRefresh();
            this.borderColorStyle = this.borderColor
        } else if(value <= 1 && this.borderColor == '#595B65'){
            this.borderColor = 'No border'
            this.borderRefresh();
            this.borderColorStyle = '#ffffff'
        } else {
            return
        }
    }
    detectChanges(data,event : MouseEvent,tableType,actionType=''){
        let that = this;
        this.myValue = ''
        setTimeout(() => {
            that.myValue = data;
        }, 50);

        if ((actionType == 'grandtotal' && !tableType) || (actionType == 'borderColor' && tableType)) {
            this.openColorPalatePosition(event.srcElement);
        }
    }

    openColorPalatePosition(htmlelement){
        let nextElementPopup = $(htmlelement).next().get(0);
        $(nextElementPopup).css('top', `-${458}px`);
    }
    checkInputvalue(event,type){
        let inputValue = event.target.value;
        if (inputValue < 0 || inputValue > 20) {
            event.preventDefault();
            setTimeout(() => {
                if (type == 'grandtotal') {
                    this.grandtotalTitleSize = 20;
                } else if (type == 'subtotal') {
                    this.subtotalTitleSize = 20;
                }
                this.titleRefresh();
            }, 50);
        }
    }
    handleInput(event: Event) {
        const value = +(event.target as HTMLInputElement).value;
        if (value < 60) {
            setTimeout(() => {
                this.rowHeight = 60;
            }, 50);
        }
    }

}
