import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {HttpInterceptor} from "@angular/common/http";
import { tap } from "rxjs/operators";
import {V5AuthService} from "@app/core";
import {MicroserviceHelperService} from "@app/shared/microservice/helper/microservice-helper.service";

@Injectable({
    providedIn: 'root'
})
export class TokenRenewalInterceptor implements HttpInterceptor {
    constructor(
        private v5AuthService: V5AuthService,
        private readonly tokenService: MicroserviceHelperService,
    ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
        .pipe( tap ({
            next: (event) => {
                if(event instanceof HttpResponse) {
                    let data = event.body;
                    if( data && data.hasOwnProperty('v5TokenExpired_aebe10654a474f34443703d51047d11a') && data['v5TokenExpired_aebe10654a474f34443703d51047d11a'] == true && !this.v5AuthService.isRequestedToken ) {
                        this.v5AuthService.renewToken().then( (resp) => {
                            this.tokenService.setToken(resp['renewalToken'])
                            this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                            setTimeout( () => {
                                this.v5AuthService.isRequestedToken=false;
                            },10*1000);
                        });
                        console.log('v5 token expired, requesting token');
                    }
                }
            }
        }))
    }
}
