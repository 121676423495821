import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
var SettingsGrantTemplatesService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsGrantTemplatesService, _super);
    function SettingsGrantTemplatesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Add/Edit settings reason
    SettingsGrantTemplatesService.prototype.addOrEditGrantTemplate = function (data) {
        return this.http.post(this.getApiUrl("sales/addOrEditGrantTemplate"), data);
    };
    SettingsGrantTemplatesService.prototype.getGrantTemplateAddData = function () {
        return this.http.get(this.getApiUrl("sales/get_grant_template_add_data"));
    };
    SettingsGrantTemplatesService.prototype.linkGrantsToOptionTemplate = function (data) {
        return this.http.post(this.getApiUrl("sales/optionTemplateGrantConfig"), data);
    };
    SettingsGrantTemplatesService.prototype.getLinkedGrants = function (data) {
        return this.http.post(this.getApiUrl("sales/getGrantTemplate"), data);
    };
    SettingsGrantTemplatesService.prototype.getListOfGrantTemplates = function (data) {
        return this.http.post(this.getApiUrl('sales/getAllGrantTemplates'), data);
    };
    // Delete settings reason
    SettingsGrantTemplatesService.prototype.deleteGrantTemplate = function (id) {
        return this.http.delete(this.getApiUrl("sales/deleteSettingsGrantTemplate/" + id));
    };
    // Check settings reason already exists
    SettingsGrantTemplatesService.prototype.checkGrantTemplateExists = function (params) {
        return this.http.get(this.getApiUrl("sales/checkGrantTemplateExists", params));
    };
    return SettingsGrantTemplatesService;
}(InterfaceService));
export { SettingsGrantTemplatesService };
