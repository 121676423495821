import { OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { OpportunityService } from "@app/services/http-services/opportunity.service";
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
var EmailToSuppliersComponent = /** @class */ (function () {
    function EmailToSuppliersComponent(bsModalRef, opportunityService, proposalService) {
        this.bsModalRef = bsModalRef;
        this.opportunityService = opportunityService;
        this.proposalService = proposalService;
        this.disable = true;
        this.count = 0;
    }
    EmailToSuppliersComponent.prototype.ngOnInit = function () {
        this.getSuppliers();
        this.fetchSupplierPartsQuotationMessage();
        this.onClose = new Subject();
    };
    EmailToSuppliersComponent.prototype.getSuppliers = function () {
        var _this = this;
        this.proposalService.getEstimatedSuppliers().subscribe(function (data) {
            _this.suppliers = data;
            var _loop_1 = function (i) {
                var assigneeIndex = _this.suppliers.findIndex(function (val) {
                    return val.id == parseInt(_this.data.suppliers[i]);
                });
                if (_this.suppliers[assigneeIndex]['emailaddress'] == null) {
                    _this.count++;
                }
            };
            for (var i = 0; i < _this.data.suppliers.length; i++) {
                _loop_1(i);
            }
        });
    };
    EmailToSuppliersComponent.prototype.fetchSupplierPartsQuotationMessage = function () {
        var _this = this;
        this.opportunityService.getSupplierPartsQuotationMessage().subscribe(function (response) {
            _this.emailContent = response;
            _this.disable = false;
        }, function (error) {
            console.log(error);
            _this.disable = false;
        });
    };
    EmailToSuppliersComponent.prototype.sendEmail = function () {
        var _this = this;
        this.disable = true;
        var supplierData = {
            emailBody: this.emailContent['message'],
            emailHeader: this.emailContent['subject'],
            parts: this.data.parts,
            suppliers: this.data.suppliers,
            listId: this.data.listId,
            opportunityId: this.data.opportunityId
        };
        this.opportunityService.emailPartListToSuppliers(supplierData).subscribe(function (result) {
            console.log(result);
            _this.bsModalRef.hide();
            _this.disable = false;
        }, function (error) {
            _this.disable = false;
        });
    };
    EmailToSuppliersComponent.prototype.updateMessage = function (emailContent) {
        this.emailContent = emailContent;
    };
    return EmailToSuppliersComponent;
}());
export { EmailToSuppliersComponent };
