/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/datepicker";
import * as i2 from "ngx-bootstrap/component-loader";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./cs-datepicker.component";
import * as i6 from "../../../core/settings/settings.service";
var styles_CsDatepickerComponent = [];
var RenderType_CsDatepickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsDatepickerComponent, data: {} });
export { RenderType_CsDatepickerComponent as RenderType_CsDatepickerComponent };
function View_CsDatepickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "bs-datepicker-inline", [], null, [[null, "bsValueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("bsValueChange" === en)) {
        var pd_0 = (_co.onDateChanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.BsDatepickerInlineDirective, [i1.BsDatepickerInlineConfig, i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i2.ComponentLoaderFactory], { bsValue: [0, "bsValue"], bsConfig: [1, "bsConfig"], isDisabled: [2, "isDisabled"], minDate: [3, "minDate"], maxDate: [4, "maxDate"] }, { bsValueChange: "bsValueChange" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedDate; var currVal_1 = _co.customBsConfig; var currVal_2 = _co.isDpDisabled; var currVal_3 = _co.minDate; var currVal_4 = _co.maxDate; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CsDatepickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "dp-wrapper cs-d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 8, "input", [["bsDatepicker", ""], ["class", "bsdatepicker-input cs-flex-1"], ["type", "text"]], [[8, "placeholder", 0], [8, "readOnly", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onShown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.esc" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).hide() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.formattedDate = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.onDateChanges($event) !== false);
        ad = (pd_8 && ad);
    } if (("onShown" === en)) {
        var pd_9 = (_co.onPickerShown($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(4, 737280, [[1, 4], ["dataPicker", 4]], 0, i1.BsDatepickerDirective, [i1.BsDatepickerConfig, i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i2.ComponentLoaderFactory], { placement: [0, "placement"], container: [1, "container"], bsConfig: [2, "bsConfig"], minDate: [3, "minDate"], maxDate: [4, "maxDate"], datesDisabled: [5, "datesDisabled"] }, { onShown: "onShown" }), i0.ɵdid(5, 16384, null, 0, i1.ɵr, [i1.BsDatepickerDirective, i1.BsLocaleService, i0.Renderer2, i0.ElementRef, i0.ChangeDetectorRef], null, null), i0.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.ɵr]), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.DefaultValueAccessor, i1.ɵr]), i0.ɵdid(8, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "span", [["class", "add-on date-picker-edit cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_co.isDpDisabled && i0.ɵnov(_v, 4).toggle()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(13, { "cursor-not-allowed": 0 }), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "ss-calendar"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = "bottom"; var currVal_10 = _co.container; var currVal_11 = _co.customBsConfig; var currVal_12 = _co.minDate; var currVal_13 = _co.maxDate; var currVal_14 = _co.disabledDates; _ck(_v, 4, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.isDpDisabled; var currVal_16 = _co.formattedDate; _ck(_v, 8, 0, currVal_15, currVal_16); var currVal_17 = "add-on date-picker-edit cursor-pointer"; var currVal_18 = _ck(_v, 13, 0, _co.isDpDisabled); _ck(_v, 12, 0, currVal_17, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_1 = _co.isReadOnly; var currVal_2 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 10).ngClassValid; var currVal_7 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_CsDatepickerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "dp-wrapper cs-d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 8, "input", [["bsDaterangepicker", ""], ["class", "bsdatepicker-input cs-flex-1"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.esc" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).hide() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.formattedDateRange = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.onDateRangeChanges($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(4, 737280, [[2, 4], ["daterangepicker", 4]], 0, i1.BsDaterangepickerDirective, [i1.BsDaterangepickerConfig, i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i2.ComponentLoaderFactory], { bsConfig: [0, "bsConfig"], datesDisabled: [1, "datesDisabled"] }, null), i0.ɵdid(5, 16384, null, 0, i1.ɵs, [i1.BsDaterangepickerDirective, i1.BsLocaleService, i0.Renderer2, i0.ElementRef, i0.ChangeDetectorRef], null, null), i0.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.ɵs]), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.DefaultValueAccessor, i1.ɵs]), i0.ɵdid(8, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "span", [["class", "add-on date-picker-edit cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_co.isDpDisabled && i0.ɵnov(_v, 4).toggle()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(13, { "cursor-not-allowed": 0 }), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "ss-calendar"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.customBsRangeConfig; var currVal_9 = _co.disabledDates; _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_10 = _co.isDpDisabled; var currVal_11 = _co.formattedDateRange; _ck(_v, 8, 0, currVal_10, currVal_11); var currVal_12 = "add-on date-picker-edit cursor-pointer"; var currVal_13 = _ck(_v, 13, 0, _co.isDpDisabled); _ck(_v, 12, 0, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_1 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 10).ngClassValid; var currVal_6 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CsDatepickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { dataPicker: 0 }), i0.ɵqud(671088640, 2, { daterangepicker: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsDatepickerComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsDatepickerComponent_2)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsDatepickerComponent_3)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.datePickerType == "inline"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.datePickerType == "default"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.datePickerType == "range"); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_CsDatepickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cs-datepicker", [], [[8, "className", 0]], null, null, View_CsDatepickerComponent_0, RenderType_CsDatepickerComponent)), i0.ɵdid(1, 114688, null, 0, i5.CsDatepickerComponent, [i6.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CsDatepickerComponentNgFactory = i0.ɵccf("cs-datepicker", i5.CsDatepickerComponent, View_CsDatepickerComponent_Host_0, { placeholder: "placeholder", datePickerType: "type", minDate: "minDate", maxDate: "maxDate", container: "container", isDpDisabled: "isDpDisabled", disabledDates: "disabledDates", isHideWeekNumbers: "isHideWeekNumbers", isReadOnly: "isReadOnly", initialDateVal: "initialDateVal", defaultValue: "defaultValue" }, { selectedDate: "selectedDate", pickerShown: "pickerShown" }, []);
export { CsDatepickerComponentNgFactory as CsDatepickerComponentNgFactory };
