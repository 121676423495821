import { CsAgGridService } from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.service";
var CsAgGroupCellRendererComponent = /** @class */ (function () {
    function CsAgGroupCellRendererComponent(_csAgGrid) {
        this._csAgGrid = _csAgGrid;
        this.class = 'w-100 h-100';
        this.isDetailOpen = false;
    }
    CsAgGroupCellRendererComponent.prototype.agInit = function (params) {
        this.params = params;
        this.colId = this.params.column.getColId();
        this.data = params.data;
        this.rowId = this.data['id'] || (+this.params.node.id + 1);
    };
    CsAgGroupCellRendererComponent.prototype.onCellClicked = function (event) {
        var _this = this;
        var rowNode = this.params.node;
        var isNodeExpanded = rowNode.expanded;
        var panelHeight = 0;
        if (this.params.detailParamsSetter) {
            this.params.detailParamsSetter(this.params);
        }
        if (isNodeExpanded && !this.isDetailOpen) {
            this.params.node.setExpanded(false);
            this.params.api.refreshCells();
            panelHeight = -rowNode.detailNode.rowHeight;
            if (this.params.layoutParams)
                this.params.layoutParams.setGridAutoHeight(panelHeight);
            isNodeExpanded = rowNode.expanded;
        }
        setTimeout(function () {
            _this.isDetailOpen = !_this.isDetailOpen;
            _this._csAgGrid.setNodeState(_this.rowId, _this.colId, { isExpanded: _this.isDetailOpen });
            _this.params.node.setExpanded(_this.isDetailOpen);
            _this.params.api.refreshCells();
            panelHeight = rowNode.detailNode.rowHeight * (isNodeExpanded ? -1 : 1);
            if (_this.params.layoutParams)
                _this.params.layoutParams.setGridAutoHeight(panelHeight);
        }, 300);
    };
    Object.defineProperty(CsAgGroupCellRendererComponent.prototype, "isCellExpanded", {
        get: function () {
            var rowState = this._csAgGrid.getNodeState(this.rowId);
            var isExpanded = false;
            if (rowState) {
                isExpanded = !!rowState[this.colId] && rowState[this.colId].isExpanded;
            }
            this.isDetailOpen = isExpanded;
            return isExpanded;
        },
        enumerable: true,
        configurable: true
    });
    CsAgGroupCellRendererComponent.prototype.refresh = function (params) {
        // this.params = params;
        return true;
    };
    return CsAgGroupCellRendererComponent;
}());
export { CsAgGroupCellRendererComponent };
