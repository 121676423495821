<form *ngIf="form" [formGroup]="form" class="no-form-style cs-trigger workflow-sidepanel" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>Exit.Criteria</h4>
    </div>
    <div class="modal-body">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix">
                        <label class="control-label required_field" style="padding: 10px 0 0 15px;" translate>Exit.Criteria</label>
                        <div class="control" >
                            <div style="padding: 0 15px 0 15px;">
                                <input formControlName="exitCriteria" (selected)="selectedCondition($event)"
                                       [(ngModel)]="exitCriteria" [dropdownvalue]="exitCriteria"
                                       id="additional-exit-condition"
                                       csSelect2 [select2Config]="select2LabelConfig"
                                       class="full-width-select2 cs-select2-wfe mb-10"
                                       placeholder="-- Select option --"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Cancel</a>
        <button #saveBtn [disabled]="!saving" class="btn btn-primary">
            <span [hidden]="action == 'saving'" translate>Save</span>
            <span [hidden]="action != 'saving'" translate>Saving</span>
        </button>
    </div>
</form>
