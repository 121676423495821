import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class fontfamilyService extends InterfaceService {

    fontObj = [
        {
            "name": "Amantic",
            "family": "'Amatic SC', cursive"
        },
        {
            "name": "Arial",
            "family": "'Arial', sans-serif"
        },
        {
            "name": "Caveat",
            "family": "'Caveat', cursive"
        },
        {
            "name": "Comfortaa",
            "family": "'Comfortaa', cursive"
        },
        {
            "name": "Courier Prime",
            "family": "'Courier Prime', monospace"
        },
        {
            "name": "EB Garamond",
            "family": "'EB Garamond', serif"
        },
        {
            "name": "Lobster",
            "family": "'Lobster', cursive"
        },
        {
            "name": "Lora",
            "family": "'Lora', serif"
        },
        {
            "name": "Merriweather",
            "family": "'Merriweather', serif"
        },
        {
            "name": "Montserrat",
            "family": "'Montserrat', sans-serif"
        },
        {
            "name": "Nunito",
            "family": "'Nunito', sans-serif"
        },
        {
            "name": "Oswald",
            "family": "'Oswald', sans-serif"
        },
        {
            "name": "Pacifico",
            "family": "'Pacifico', cursive"
        },
        {
            "name": "Playfair Display",
            "family": "'Playfair Display', serif"
        },
        {
            "name": "Roboto",
            "family": "'Roboto', sans-serif"
        },
        {
            "name": "Roboto Mono",
            "family": "'Roboto Mono', monospace"
        },
        {
            "name": "Spectral",
            "family": "'Spectral', serif"
        },
        {
            "name": "Times New Roman",
            "family": "'Times New Roman', sans-serif"
        }
    ];

    private googleFontSendComponent = new BehaviorSubject(null);
    googleFontSendComponent$ = this.googleFontSendComponent.asObservable();

    sendGoogleFontToComponent(data) {
        this.googleFontSendComponent.next(data);
    }

    googleFontObject = [

    ];

    constructor(private http: HttpClient) {
        super();
    }

    getFontFamilyArr(){
        return this.fontObj;
    }

    getFontFamilyObj(){
        let x = {};
        this.fontObj.forEach((obj,i)=> { x[obj.family]= obj.name; });
        return x;
    }

    appendStylesheetIframe(googleFontLinkURL,uploadType) {
        const rootHeadElement = $('head');
        const cs_editor = $('#cs_editor').contents().find('head');
        if (cs_editor && cs_editor.length) {
            this.appendLinksToHead(cs_editor, googleFontLinkURL,uploadType);
        }
        /*if (rootHeadElement && rootHeadElement.length) {
            this.appendLinksToHead(rootHeadElement, googleFontLinkURL,uploadType);
        }*/
    }

    appendLinksToHead(appendElement, links,uploadType) {
        links.forEach((link:any) => {
            $('<link>', {
                rel: 'stylesheet',
                id : `sales${uploadType}`,
                href: link
            }).appendTo(appendElement);
        });
    }

    insertGoogleFontListObject(newFontObjects){
        for (const newFontObject of newFontObjects) {
            // Check if a font object with the same name already exists in fontObj
            const isDuplicate =  this.googleFontObject.some(font => font.name === newFontObject.name);

            if (!isDuplicate) {
                this.googleFontObject.push(newFontObject);
            }
        }
    }

    getGoogleFontObject(){
        return this.googleFontObject;
    }

    combineFontLinksToApiUrl(fontLinksArray,uploadType='') {
        const fontFamilies = [];

        for (const link of fontLinksArray) {
            const fontFamilyMatches = link.match(/family=([^&]+)/g);
            if (fontFamilyMatches) {
                for (const match of fontFamilyMatches) {
                    fontFamilies.push(match);
                }
            }
        }
        const fontFamiliesString = fontFamilies.join('&');
        const apiUrl = `https://fonts.googleapis.com/css2?${fontFamiliesString}&display=swap`;
        this.sendGoogleFontToComponent(apiUrl)
        this.appendStylesheetIframe([apiUrl],uploadType);
    }

}
