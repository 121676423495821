<form *ngIf="!fromInvoice && optionTemplateForm" [formGroup]="optionTemplateForm" (submit)="save()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		<div *ngIf="!isEdit" class="help_section">
            <div class="help-text" translate>
                Option.template.configure.text
            </div>
        </div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">

					<!-- Template Name -->
					<div class="control-group clearfix">
						<label *ngIf="!isAddonTemplate" class="control-label required_field" translate>Option.name</label>
                        <label *ngIf="isAddonTemplate" class="control-label required_field" translate>Addon.name</label>
						<div class="controls">
							<input type="text" class="control-input" formControlName="optionName" (valueChanged)="checkUniqueTemplateName($event)" appDebounceTimer>
						</div>
						<div *ngIf="f.optionName.invalid && (f.optionName.dirty || f.optionName.touched)">
							<span *ngIf="f.optionName.errors.required" class="control-error-message">
								<span *ngIf="!isAddonTemplate" translate>Please.enter.valid.option.name</span>
                                <span *ngIf="isAddonTemplate" translate>Please.enter.valid.addon.name</span>
							</span>
						</div>
						<span *ngIf="f.optionName.valid && uniqueName && !dataSaved" class="control-error-message">
							<span translate>This.template.name.already.exists</span>
						</span>
					</div>
                    <div  class="control-group clearfix">
                        <label class="control-label" translate>Customer.displaying.name</label>
                        <div class="controls">
                            <input type="text" class="control-input" formControlName="customerOptionName" >
                        </div>
                    </div>
					<!-- Addon Checkbox -->
<!--					<div *access = "['Addons.writeaccess']" class="control-group clearfix addon-option">-->
<!--						<label for='addon'>-->
<!--							<input class="checkbox" id="addon" type="checkbox" formControlName="addOn">-->
<!--							<span class="title" translate>&emsp;is.add-on?</span>-->
<!--							<span class="tickmark"></span>-->
<!--						</label>-->
<!--					</div>-->
				</div>
			</div>
		</div>
	</div>

	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="!optionTemplateForm.valid || action == 'saving' || uniqueName" class="btn btn-primary">
			<span [hidden]="action == 'saving'" translate>Save</span>
			<span [hidden]="action != 'saving'" translate>Saving</span>
		</button>
	</div>
</form>


<form *ngIf="fromInvoice && financeEditForm" [formGroup]="financeEditForm" (submit)="updateFinanceTemplate()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>Set up finance template</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<!-- Finance template linking -->
					<div class="control-group clearfix">
						<label class="control-label" translate>Finance template</label>
						<div class="controls">
							<select class="full-width-select2"  csSelect2 [select2Config]="select2Config"
								class="full-width-select" formControlName="financeTemplate">
								<option value="" translate="">Please.Choose</option>
								<option *ngFor="let template of financeTemplateList" value="{{template.id}}">
									{{template.templateName}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="!financeEditForm.valid || action == 'saving' || loading" class="btn btn-primary">
			<span [hidden]="action == 'saving'" translate>Save</span>
			<span [hidden]="action != 'saving'" translate>Saving</span>
		</button>
	</div>
</form>

<form *ngIf = "customerOptionNameEdit" [formGroup]="customerOptionNameEditForm" (submit)="updateCustomerOptionName()">
    <div class="modal-header">
        <h4 *ngIf="!isViewMode" class="modal-title pull-left" translate>{{title}}</h4>
        <h4 *ngIf="isViewMode" class="modal-title pull-left" translate>Customer display name</h4>
    </div>
    <!-- Content -->
    <div class="modal-body">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <!-- Finance template linking -->
                    <div class="control-group clearfix">
                        <label class="control-label" translate>Customer displaying name</label>
                        <div class="controls customer-margin">
                            <input [readonly]="isViewMode" type="text" class="control-input" formControlName="customerOptionName"  [(ngModel)] = "customerOptionName">
                        </div>
                        <span  *ngIf="!isViewMode" (click)="customerOptionName = optionName" class="pointer"><a class="pointer" translate>copy.option.text</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ng-container  *ngIf="isViewMode">
            <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        </ng-container>
        <ng-container *ngIf="!isViewMode">
            <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
            <button [disabled]="!customerOptionNameEditForm.valid || action == 'saving' || loading" class="btn btn-primary">
                <span [hidden]="action == 'saving'" translate>Save</span>
                <span [hidden]="action != 'saving'" translate>Saving</span>
            </button>
        </ng-container>
    </div>
</form>

<form class="new-finance-section" *ngIf="newfinaceTemplates && FinanceForm == 'showFinanceForm'" [formGroup]="templateForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>

    <!-- Content -->
    <div class="modal-body">
        <div class="help_section">
            <div class="help-text" translate>
                finance.template.help.text
            </div>
        </div>
        <div *access = "['SetupFinance.readaccess']" class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <!-- Finance template -->
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>Template.name</label>
                        <div class="controls">
                            <input type="text" class="control-input" formControlName="templateName" (valueChanged)="isUniqueName($event)"
                                   appDebounceTimer>
                        </div>
                        <div *ngIf="formControls.templateName.invalid && (formControls.templateName.dirty || formControls.templateName.touched)">
              <span *ngIf="formControls.templateName.errors.required" class="control-error-message show">
                <span translate>enter.valid.finance.template</span>
              </span>
                        </div>
                        <span *ngIf="formControls.templateName.valid && uniqueName && !dataSaved" class="control-error-message show">
              <span translate>finance.template.exists</span>
            </span>
                    </div>
                    <!-- Year rates -->
                    <div class="control-group clearfix">
                        <div class="year-row-head" [ngClass]="{'year-row': integrated}">
                            <span translate>Term (months)</span>
                        </div>
                        <div class="year-row-head" [ngClass]="{'year-row': integrated}">
                            <span translate>rate.paid.by.customer</span>
                        </div>
                        <div class="year-row-head" *ngIf="!integrated">
                            <span translate>Multipliers</span>
                        </div>
                        <div class="year-row-head" [ngClass]="{'year-row': integrated}">
                            <span translate>Enable terms</span>
                        </div>
                        <div class="span12 no-margin"
                             *ngFor="let yearRate of templateForm.get('yearRates').controls; let i = index" formArrayName="yearRates">
                            <div class="flex" [formGroupName]="i">
                                <div class="controls year-rate-block" [ngClass]="{'year-rate-row': integrated}">
                                    <input type="number" [readOnly]="integrated" (wheel)="$event.preventDefault()"  (keyup)="addYearRateFormGroup($event,i);calculateMultipliers(i)" (keypress)="avoidArithmeticOperator($event)" class="control-input" formControlName="months">
                                    <!-- <div *ngIf="f.year.invalid && (f.year.dirty || f.year.touched)"> -->
                                </div>
                                <div class="controls year-rate-block" [ngClass]="{'year-rate-row': integrated}">
                                    <span class="input-holder">
                                        <input type="text" maxlength="5" [readOnly]="integrated" (keyup)="omitMaxPercentage($event,'rateByCustomer',i);changeTerms($event);calculateMultipliers(i)" (focusout)="changeValue(i);disableMonths()" (keypress)="omitSpecialChar($event, templateForm.controls['yearRates'].value[i]['rateByCustomer'])" class="control-input valuePadding" formControlName="rateByCustomer">
                                    </span>
                                </div>
                                <div class="controls year-rate-block flex" *ngIf="!integrated">
                                    <input type="number" (wheel)="$event.preventDefault()" (keypress)="avoidArithmeticOperatorForMultiplier($event)" class="control-input" formControlName="multipliers">
<!--                                    <div (click)="deleteYearRow(i)"><i class="fa fa-trash delete-icon"></i></div>-->
                                </div>
                                <div class="controls year-rate-block flex disable-months">
                                    <input type="checkbox" [ngClass]="{'archived-apr': yearRate.value['archived'] == 1 || yearRate.value['archived'] == 2}" [checked]="yearRate.value['disableTerms'] == false" class="control-input finance-checkbox" (click)="removeTerms($event,i)">
                                </div>
                                <div class="delete-text" *ngIf="!integrated" (click)="deleteYearRow(i)"><span [ngClass]="{'archived-apr': i == 0}">Delete</span></div>
                                <div *ngIf="integrated && yearRate.value['archived'] == 1"><span>Archived</span></div>
                            </div>
                            <div>
                                <span *ngIf="(yearRate.get('months').errors && (yearRate.get('months').dirty || yearRate.get('months').touched))" >
                                    <span *ngIf="yearRate.get('months').errors?.required" class="control-error-message show">Please enter the month</span>
                                    <span *ngIf="yearRate.get('months').errors?.min" class="control-error-message show">Enter a minimum value as 1</span>
                                </span>
                            </div>
                            <div>
                                <span *ngIf="(yearRate.get('multipliers').errors && (yearRate.get('multipliers').dirty || yearRate.get('multipliers').touched))" >
                                    <span *ngIf="yearRate.get('multipliers').errors?.required" class="control-error-message show">Please enter the multipliers</span>
                                    <span *ngIf="yearRate.get('multipliers').errors?.min" class="control-error-message show">Enter a value greater than 0.0001</span>
                                    <span *ngIf="yearRate.get('multipliers').errors?.max" class="control-error-message show">Enter a value lesser than or equal to 5</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Deposit default values -->
                    <div class="control-group clearfix">
                        <div class="span12">
                            <span translate>deposit.default.values</span>
                        </div>
                        <div class="span12 no-margin" formGroupName="deposits">
                            <div class="deposits-block">
                                <div class="controls">
                  <span class="input-holder">
                    <input type="text"  maxlength="5" (keyup)="omitMaxPercentage($event,'deposit1')" (keypress)="omitSpecialChar($event, templateForm.controls['deposits'].value['deposit1'])" class="control-input" formControlName="deposit1" [(ngModel)]="depositOne">
                  </span>
                                </div>
                                <div class="controls">
                  <span class="input-holder">
                    <input type="text"  maxlength="5" (keyup)="omitMaxPercentage($event,'deposit2')" (keypress)="omitSpecialChar($event, templateForm.controls['deposits'].value['deposit2'])" class="control-input" formControlName="deposit2" [(ngModel)]="depositTwo">
                  </span>
                                </div>
                                <div class="controls">
                  <span class="input-holder">
                    <input type="text"  maxlength="5" (keyup)="omitMaxPercentage($event,'deposit3')" (keypress)="omitSpecialChar($event, templateForm.controls['deposits'].value['deposit3'])" class="control-input" formControlName="deposit3" [(ngModel)]="depositThree">
                  </span>
                                </div>
                                <div class="controls">
                  <span class="input-holder">
                    <input type="text" maxlength="5" (keyup)="omitMaxPercentage($event,'deposit4')" (keypress)="omitSpecialChar($event, templateForm.controls['deposits'].value['deposit4'])" class="control-input" formControlName="deposit4" [(ngModel)]="depositFour">
                  </span>
                                </div>
                            </div>
                            <div class="span12 no-margin">
                                <div class="controls span10">
                                    <div class="checkbox-left">
                                        <input type="checkbox" id="allowOther" class="control-input finance-checkbox" formControlName="allowOther"/>
                                        <div class="allow-option">
                                            <span class="checkbox-text" for="allowOther" translate>deposit.allow.other.option</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="span12 no-margin representative-margin">
                                <div class="controls span10">
                                    <div class="checkbox-lefts">
                                        <input type="checkbox" id="representative" [checked] = checked class="control-input finance-checkbox" (click)="addRepresentative($event)"/>
                                        <span class="checkbox-text" for="representative">Add representative</span>
                                    </div>
                                </div>
                            </div>
                            <div class="span12 no-margin" *ngIf="showInput">
                                <div class="representative-details">
                                    <div class="deposits-block">
                                        <label class="required_field">APR</label>
                                        <select id="apr" class="deposits-block select-width" (change)="checkTerms($event,'event');checkInput()">
                                            <option value='' disabled>-- Please select --</option>
                                            <option *ngFor="let aprValue of apr" [selected]="selectedApr" value="{{aprValue}}">{{aprValue}}%</option>
                                        </select>
                                    </div>
                                    <div class="deposits-block input-margin">
                                        <label class="required_field">Term (months)</label>
                                        <select id="terms" class="deposits-block select-width" (change)="checkInput()">
                                            <option value=''>-- Please select --</option>
                                            <option *ngFor="let months of terms" value="{{months}}">{{months}}</option>
                                        </select>
                                    </div>
                                    <div class="deposits-block input-margin">
                                        <label class="required_field">Deposit</label>
                                        <select id="deposit" class="deposits-block select-width" (change)="checkInput()">
                                            <option value=''>-- Please select --</option>
                                            <option [value]="depositOne">{{depositOne}}%</option>
                                            <option [value]="depositTwo">{{depositTwo}}%</option>
                                            <option [value]="depositThree">{{depositThree}}%</option>
                                            <option [value]="depositFour">{{depositFour}}%</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="multiplierError">
                                <span class="error">Multipliers should be greater than 0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer -->
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()" translate>cancel</a>
        <!-- || action == 'saving' -->
        <button (click)="financeSave()" type="submit" [disabled]="!templateForm.valid || financeAction == 'saving' || uniqueName || (showInput && !valid)" class="btn btn-primary">
            <span [hidden]="financeAction != 'save'" translate>Save</span>
            <span [hidden]="financeAction == 'save'" translate>Saving</span>
        </button>
    </div>
</form>

<!--Grants template form -->

<form *ngIf="fromGrants && grantsForm" [formGroup]="grantsForm" (submit)="addOrUpdateGrantsTemplate()">
    <!-- Header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <!-- Content -->
    <div class="modal-body">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <!-- Grants template linking -->
                    <div class="control-group clearfix mb-1">
                        <label class="control-label" translate>Select grant</label>
                        <div class="controls">
                            <select class="full-width-select2"  csSelect2 [select2Config]="select2Config"
                                    class="full-width-select" formControlName="grantTemplate" (selected)="onSelectingGrant($event)">
                                <option value="" translate="">Please.Choose</option>
                                <option *ngFor="let template of grantsData.grants" value="{{template.id}}">
                                    {{template.templateName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>grant.label</label>
                        <div class="controls w-50">
                            <input type="text" maxlength="100" class="control-input mb-0" formControlName="grantLabel" />
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>grant.amount</label>
                        <div class="controls w-50">
                            <input type="number" step="0.01" class="control-input mb-0" formControlName="amount" nxNoScroll />
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>invoice.address</label>
                        <div class="controls">
                            <input class="full-width-select2 full-width-select" csSelect2 [select2Config]="select2ConfigForInvoiceAddress" tabindex="-1"
                                    formControlName="invoiceAddress" class="grants_invoice_address" />
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>invoice.description</label>
                        <div class="controls">
                            <input type="text" class="control-input mb-0" formControlName="invoiceDescription" />
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>invoice.note</label>
                        <div class="controls">
                            <textarea type="text" rows="4" class="mb-0" formControlName="invoiceNote"></textarea>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>nominal.code</label>
                        <div class="controls description-padding select2-form-container w-75">
                            <select class="full-width-select2" csSelect2 [select2Config]="select2Config"
                                    formControlName="nominalCode" class="grants_nominal_code">
                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                <option *ngFor="let code of grantsData.nominalCode" [value]="code?.NominalCodeId">
                                    {{code?.NominalCodeDescription}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1" *ngIf="grantsData.invoiceCategories.length">
                        <label class="control-label required_field" translate>business.unit</label>
                        <div class="controls description-padding select2-form-container w-75">
                            <select class="full-width-select2" csSelect2 [select2Config]="select2Config"
                                    formControlName="businessUnit" class="grants_business_unit">
                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                <option *ngFor="let unit of grantsData.invoiceCategories" value="{{unit?.id}}">
                                    {{unit?.description}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1" *ngIf="grantsData.userGroup.length">
                        <label class="control-label required_field" translate>user.group</label>
                        <div class="controls description-padding select2-form-container w-75">
                            <select class="full-width-select2" csSelect2 [select2Config]="select2Config"
                                    formControlName="userGroup" class="grants_user_group">
                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                <option *ngFor="let ug of grantsData.userGroup" [value]="ug?.id">
                                    {{ug?.description}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>tax.rate</label>
                        <div class="controls description-padding select2-form-container w-75">
                            <select class="full-width-select2" csSelect2 [select2Config]="select2Config"
                                    formControlName="taxRate" class="grants_tax_rate">
                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                <option *ngFor="let tax of grantsData.taxItems" [value]="tax?.id">
                                    {{tax?.description}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>inclusive.tax</label>
                        <div class="controls description-padding select2-form-container w-75">
                            <select class="full-width-select2" csSelect2 [select2Config]="select2Config"
                                    formControlName="isInclusive" class="grants_is_inclusive">
                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group clearfix mb-1">
                        <label class="control-label required_field" translate>payment.terms.after.job.completion</label>
                        <div class="controls w-50">
                            <input type="number" min="0" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="control-input mb-0" nxNoScroll formControlName="paymentTerms" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        <button [disabled]="!grantsForm.valid || action == 'saving' || loading" class="btn btn-primary">
            <span [hidden]="action == 'saving'" translate>Save</span>
            <span [hidden]="action != 'saving'" translate>Saving</span>
        </button>
    </div>
</form>
