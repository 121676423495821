import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[cellColor]'
})
export class CellColorDirective {

  @Input() column:any = {};
  @Input() value:string = '';

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges() {
    if(this.column.color) {
      const {text, background } = this.column.color;
      if(background)
        this.elementRef.nativeElement.style.background = background;
      if(text)
        this.elementRef.nativeElement.style.color = text;
    }
  }
}
