<ng-container *ngIf="formData">
<form class="form-horizontal form-parser bg-color"
      [ngClass]="{'appliance-form': pages && pages[currentPageNumber] && pages[currentPageNumber]['pageType'] == 'list'}"
      *ngIf="form"
      [formGroup]="form">
  <fieldset *ngIf="pages" id="field-set"
            [ngClass]="{'appliance-field-set': pages && pages[currentPageNumber] && pages[currentPageNumber]['pageType'] == 'list'}">
    <ng-container *ngFor="let page of pages; let i = index;">
      <div [hidden]="currentPageNumber !== i">
        <div class="header-cl" *ngIf="page.pageType !== 'list'">
            <span *ngIf="pageTitle !== ''" translate>{{pageTitle}}</span>
            <span *ngIf="pageTitle == ''" translate>{{page.pageName}}</span>
        </div>
        <!--Standard page fields-->
        <ng-container *ngIf="page.pageType == 'standard'">
          <!--Standard page old structure start-->
          <ng-container *ngFor="let field of page['fields']">
            <div class="field-container" *ngIf="!field['hasDependency'] || (field['hasDependency'] && field['show'])">

            <div class="span2">
              <label class="control-label"
                     *ngIf="field.fieldAnswerType !== 'Single Checkbox' && field.fieldAnswerType !== 'Plain Text'"
                     [ngClass]="{'required_field' : field.required }">{{field.fieldLabel}}
              </label>
            </div>
            <div class="span4">
              <!--Input-->
              <input type="text"
                     *ngIf="field.fieldAnswerType == 'Input' || field.fieldAnswerType == 'Non-editable Input'"
                     [ngClass]="{'none-p':field.fieldAnswerType == 'Non-editable Input'}"
                     [formControlName]="field.questionID"/>

              <!--Input with Selection Choices-->
              <input type="hidden"
                     csSelect2
                     [id]="field.questionID"
                     [select2Config]="select2LabelConfig[field.questionID]"
                     *ngIf="(field.fieldAnswerType == 'Input with Selection Choices' || field.fieldAnswerType == 'Dynamic Input'
                      || field.fieldAnswerType == 'Dynamic Input with Selection Choices')
                     && select2LabelConfig[field.questionID]"
                     (selected)="onSelectInputWithSelection($event, field.questionID)"
                     [formControlName]="field.questionID"
                     [placeholder]="'-- Please select --'"
                     class="chosen-select full-width-select">

              <!--Number-->
              <input type="number"
                     class="num"
                     [id]="field.questionID"
                     *ngIf="field.fieldAnswerType == 'Number'"
                     [formControlName]="field.questionID"/>
              <!--Decimal-->
              <input type="text"
                     class="num"
                     [id]="field.questionID"
                     *ngIf="field.fieldAnswerType == 'Decimal'"
                     [formControlName]="field.questionID"
                     appDigitDecimaNumber [scale]="4"/>
              <!--Text Area-->
              <textarea *ngIf="field.fieldAnswerType == 'Text Area'" [formControlName]="field.questionID"></textarea>
              <!--Toggle/DropDown-->
              <select [id]="field.questionID"
                      csSelect2
                      *ngIf="field.fieldAnswerType == 'Toggle' || field.fieldAnswerType == 'Dropdown'"
                      [select2Config]="select2Config"
                      class="full-width-select2"
                      [formControlName]="field.questionID">
                <option value="" translate>Please.Choose</option>
                <option *ngFor="let option of field.fieldAnswerOptions"
                        [value]="option.optionValue">{{option.optionValue}}
                </option>
              </select>
              <!--Single checkbox-->
              <ng-container *ngIf="field.fieldAnswerType == 'Single Checkbox'">
                <div class="c-r-group">
                  <input type="checkbox"
                         [id]="field.questionID"
                         [formControlName]="field.questionID"
                         [value]="field.fieldLabel" >
                  <label [ngClass]="{'required_field' : field.required }"
                         [for]="field.questionID">{{field.fieldLabel}}</label>
                </div>
              </ng-container>
              <!--Multiple checkbox-->
              <div *ngIf="field.fieldAnswerType == 'Checkbox'">
                <ng-container *ngFor="let option of checkboxGroupFields[field.questionID]">
                  <div class="c-r-group">
                    <input type="checkbox"
                           [id]="option.answerID"
                           (ngModelChange)="checkboxChanged(field.questionID)"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="option.formValue"
                           [value]="option.optionValue">
                    <label [for]="option.answerID">{{option.optionValue}}</label>
                  </div>
                </ng-container>
              </div>
              <!--Radio-->
              <div *ngIf="field.fieldAnswerType == 'Radio'">
                <ng-container *ngFor="let option of field.fieldAnswerOptions">
                  <div class="c-r-group">
                    <input type="radio"
                           [id]="option.answerID"
                           [formControlName]="field.questionID"
                           [value]="option.optionValue">
                    <label [for]="option.answerID">{{option.optionValue}}</label>
                  </div>
                </ng-container>
              </div>
              <!--Date-->
              <div  class="input-append datepicker-append" *ngIf="field.fieldAnswerType == 'Date'">
                <input class="bsdatepicker-input"
                       [formControlName]="field.questionID"
                       type="text"
                       readonly
                       #et="bsDatepicker"
                       bsDatepicker
                       [bsConfig]="bsConfig">
                <span (click)="et.toggle()" class="add-on date-picker-edit">
                    <span class="ss-calendar"></span>
                </span>
              </div>
              <!--Time-->
              <div *ngIf="field.fieldAnswerType == 'Time'">
                <select [(ngModel)]="timeFields[field.questionID]['hour']"
                        (ngModelChange)="timeChanged(field.questionID)"
                        class="time-drop"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let hour of hoursArray" [value]="hour">{{hour}}</option>
                </select>
                <select [(ngModel)]="timeFields[field.questionID]['minutes']"
                        (ngModelChange)="timeChanged(field.questionID)"
                        class="time-drop"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let minute of minutesArray" [value]="minute">{{minute}}</option>
                </select>
                <select [(ngModel)]="timeFields[field.questionID]['meridiem']"
                        class="time-drop"
                        (ngModelChange)="timeChanged(field.questionID)"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let value of meridiemArray" [value]="value">{{value}}</option>
                </select>
              </div>

              <!--Signature-->
              <div class="signature"
                   (click)="openSignatureSidePanel(field.questionID)"
                   *ngIf="field.fieldAnswerType == 'Signature'">
                <p class="sig-text" *ngIf="!form.value[field.questionID]" translate>Click.here.to.sign</p>
                <img class="sig-img" [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64,'+form.value[field.questionID])"
                     *ngIf="form.value[field.questionID]">
              </div>

              <span class="control-error-message show"
                    *ngIf="form && form.controls[field.questionID] && form.controls[field.questionID].touched &&
                    form.controls[field.questionID].errors">
                <span></span>
                <span *ngIf="field['validationType'] !== 'email' && field['validationType'] !== 'date' ">Please enter a valid value</span>
                <span *ngIf="field['validationType'] == 'email'">Please enter a valid email format ie. commusoft@gmail.com</span>
                <span *ngIf="field['validationType'] == 'date'">Please enter a valid date format, ie. dd/mm/YYYY, dd-mm-YYYY or dd.mm.YYYY</span>
              </span>
              <!--Plain Text-->
              <div *ngIf="field.fieldAnswerType == 'Plain Text'"><span>{{field.fieldLabel}}</span></div>
              <div *ngIf="field.fieldHelpText" class="help-block-r">
                <span class="help-text-r">{{field.fieldHelpText}}</span>
              </div>
              <!--Service job date        -->
              <div *ngIf="field.fieldAnswerType == 'Date' && (field['defaults'] && field['defaults']['defaultTitle']) &&
               serviceReminderData.length"
                   class="service-job-date-container">
                <div class="service-date-open"
                     (click)="showServiceJobDropDown(field.questionID)">
                  <p class="service-date-link" translate>Please.click.here.to.populate.next.inspection.due.date.based.on.service.reminder(s)</p>
                </div>
                <div *ngIf="currentServiceJobDateId == field.questionID">
                  <label translate>Service.type</label>
                  <select type="text"
                          class="service-drop"
                          [(ngModel)]="serviceJobDate"
                          (ngModelChange)="serviceJobDateChanged()"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let data of serviceReminderData" [value]="data['value']">{{data['name']}}</option>
                  </select>
                </div>
              </div>
            </div>
              <div class="span1 measurement-div"
                   *ngIf="field['allMeasurements'] && field['allMeasurements'].length">
                <select type="text"
                        [(ngModel)]="measurements[field.questionID]"
                        (ngModelChange)="measurementChanged(field.questionID)"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let value of field['allMeasurements']" [value]="value">{{value}}</option>
                </select>
              </div>
          </div>
          </ng-container>
          <!--Standard page old structure end-->

          <!--Standard page new structure start-->
          <ng-container *ngFor="let section of page['sections']; let sectionIndex = index;">
            <ng-container *ngIf="!section['hasDependency'] || (section['hasDependency'] && section['show'])">
              <div class="section-name section-line" *ngIf="section.sectionName">
                <p class="section-text" >{{section.sectionName}}</p>
              </div>
                <ng-container *ngFor="let field of section['fields']">
                  <ng-container
                          *ngIf="customTemplateScreen==0 || (field['showInScreen'] && ((customTemplateScreen==1 && (field['showInScreen']=='1' || field['showInScreen']=='3')) || (customTemplateScreen==2 && (field['showInScreen']=='2' || field['showInScreen']=='3'))) && field['showInScreen']!='4')">
                  <div class="field-container"
                       *ngIf="!field['hasDependency'] || (field['hasDependency'] && field['show'])">
                    <div class="span2">
                      <label class="control-label"
                       *ngIf="field.fieldAnswerType !== 'Single Checkbox' && field.fieldAnswerType !== 'Plain Text'"
                       [ngClass]="{'required_field' : field.required }">{{field.fieldLabel}}
                      </label>
                    </div>
                    <div class="span4">
                      <!--Input-->
                      <input type="text"
                             *ngIf="field.fieldAnswerType == 'Input' || field.fieldAnswerType == 'Non-editable Input'"
                             [ngClass]="{'non-editable':field.fieldAnswerType == 'Non-editable Input'}"
                             [formControlName]="field.questionID"/>

                      <!--Input with Selection Choices-->
                      <input type="hidden"
                             csSelect2
                             [id]="field.questionID"
                             [select2Config]="select2LabelConfig[field.questionID]"
                             *ngIf="(field.fieldAnswerType == 'Input with Selection Choices' || field.fieldAnswerType == 'Dynamic Input' ||
                              field.fieldAnswerType == 'Dynamic Input with Selection Choices')
                              && select2LabelConfig[field.questionID]"
                             (selected)="onSelectInputWithSelection($event, field.questionID)"
                             [formControlName]="field.questionID"
                             [placeholder]="'-- Please select --'"
                             class="chosen-select full-width-select">

                      <!--Number-->
                      <input type="number"
                             class="num"
                             [id]="field.questionID"
                             *ngIf="field.fieldAnswerType == 'Number'"
                             [formControlName]="field.questionID"/>
                      <!--Decimal-->
                      <input type="text"
                             class="num"
                             [id]="field.questionID"
                             *ngIf="field.fieldAnswerType == 'Decimal'"
                             [formControlName]="field.questionID"
                             appDigitDecimaNumber [scale]="4"/>
                      <!--Text Area-->
                      <textarea *ngIf="field.fieldAnswerType == 'Text Area'" [formControlName]="field.questionID"></textarea>
                      <!--Toggle/DropDown-->
                      <select [id]="field.questionID"
                              csSelect2
                              *ngIf="field.fieldAnswerType == 'Toggle' || field.fieldAnswerType == 'Dropdown'"
                              [select2Config]="select2Config"
                              class="full-width-select2"
                              [formControlName]="field.questionID">
                        <option value="" translate>Please.Choose</option>
                        <option *ngFor="let option of field.fieldAnswerOptions"
                                [value]="option.optionValue">{{option.optionValue}}
                        </option>
                      </select>
                      <!--Single checkbox-->
                      <ng-container *ngIf="field.fieldAnswerType == 'Single Checkbox'">
                        <div class="c-r-group s-chk-box">
                          <input type="checkbox"
                                 [id]="field.questionID"
                                 [formControlName]="field.questionID"
                                 [value]="field.fieldLabel" >
                          <label [ngClass]="{'required_field cus-fields-p-l' : field.required }"
                                 [for]="field.questionID">{{field.fieldLabel}}</label>
                        </div>
                      </ng-container>
                      <!--Multiple checkbox-->
                      <div *ngIf="field.fieldAnswerType == 'Checkbox'">
                        <ng-container *ngFor="let option of checkboxGroupFields[field.questionID]">
                          <div class="c-r-group">
                            <input type="checkbox"
                                   [id]="option.answerID"
                                   (ngModelChange)="checkboxChanged(field.questionID)"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="option.formValue"
                                   [value]="option.optionValue">
                            <label [for]="option.answerID">{{option.optionValue}}</label>
                          </div>
                        </ng-container>
                      </div>
                      <!--Radio-->
                      <div *ngIf="field.fieldAnswerType == 'Radio'">
                        <ng-container *ngFor="let option of field.fieldAnswerOptions">
                          <div class="c-r-group">
                            <input type="radio"
                                   [id]="option.answerID"
                                   [formControlName]="field.questionID"
                                   [value]="option.optionValue">
                            <label [for]="option.answerID">{{option.optionValue}}</label>
                          </div>
                        </ng-container>
                      </div>
                      <!--Date-->
                      <div  class="input-append datepicker-append" *ngIf="field.fieldAnswerType == 'Date'">
                        <input class="bsdatepicker-input"
                               [formControlName]="field.questionID"
                               type="text"
                               readonly
                               #et="bsDatepicker"
                               bsDatepicker
                               (onShown)="onShownDatepicker('shown')"
                               (onHidden)="onShownDatepicker('hidden')"
                               [bsConfig]="bsConfig">
                        <span (click)="et.toggle()" class="add-on date-picker-edit">
                            <span class="ss-calendar"></span>
                        </span>
                      </div>
                      <!--Time-->
                      <div *ngIf="field.fieldAnswerType == 'Time'">
                        <select [(ngModel)]="timeFields[field.questionID]['hour']"
                                (ngModelChange)="timeChanged(field.questionID)"
                                class="time-drop"
                                [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let hour of hoursArray" [value]="hour">{{hour}}</option>
                        </select>
                        <select [(ngModel)]="timeFields[field.questionID]['minutes']"
                                (ngModelChange)="timeChanged(field.questionID)"
                                class="time-drop"
                                [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let minute of minutesArray" [value]="minute">{{minute}}</option>
                        </select>
                        <select [(ngModel)]="timeFields[field.questionID]['meridiem']"
                                class="time-drop"
                                (ngModelChange)="timeChanged(field.questionID)"
                                [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let value of meridiemArray" [value]="value">{{value}}</option>
                        </select>
                      </div>

                      <!--Signature-->
                      <div class="signature"
                           (click)="openSignatureSidePanel(field.questionID)"
                           *ngIf="field.fieldAnswerType == 'Signature'">
                        <p class="sig-text" *ngIf="!form.value[field.questionID]" translate>Click.here.to.sign</p>
                        <img class="sig-img" [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64,'+form.value[field.questionID])"
                             *ngIf="form.value[field.questionID]">
                      </div>

                      <span class="control-error-message show"
                            *ngIf="form && form.controls[field.questionID] && form.controls[field.questionID].touched &&
                            form.controls[field.questionID].errors">
                        <span></span>
                        <span *ngIf="field['validationType'] !== 'email' && field['validationType'] !== 'date' ">Please enter a valid value</span>
                        <span *ngIf="field['validationType'] == 'email'">Please enter a valid email format ie. commusoft@gmail.com</span>
                        <span *ngIf="field['validationType'] == 'date'">Please enter a valid date format, ie. dd/mm/YYYY, dd-mm-YYYY or dd.mm.YYYY</span>
                      </span>
                      <!--Plain Text-->
                      <div *ngIf="field.fieldAnswerType == 'Plain Text'"><span>{{field.fieldLabel}}</span></div>
                      <div class="d-f-p-img" *ngIf="field.fieldAnswerType == 'Photo'">
                      <ng-container *ngFor="let formControl of getPhotoArray(field.max,field.questionID)">
                      <input  type="hidden" [formControlName]="formControl" />
                      <div class="uploader">
                        <input  type="file"
                                name="file"
                                (change)="onFileSelected(formControl)"
                                #fileUploader ng2FileSelect
                                [uploader]="uploaderArray[formControl]"
                                style="display: none; opacity: 0; width:0px;"/>

                        <div class="btn-group">
                          <button class="btn btn-primary fake-uploader"
                                  type="button"
                                  (click)="fileUploader.click();onFileSelected(formControl)"
                                  readonly="readonly">Click to upload image
                          </button>
                          <button class="btn upload_file_insafari upload-progress btn-primary">{{uploaderArray[formControl].progress}}%</button>
                        </div>
                      </div>
                        <div *ngIf="photoFileInvalidStatus[formControl]">
                          <span class="control-error-message show mt1">
                            <span translate>Please upload a valid image file, should be in the format of jpg or jpeg or png</span>
                          </span>
                        </div>
                      </ng-container>
                      </div>
                      <div *ngIf="field.fieldHelpText" class="help-block-r">
                        <span class="help-text-r">{{field.fieldHelpText}}</span>
                      </div>
                      <!--Service job date        -->
                      <div *ngIf="field.fieldAnswerType == 'Date' &&
                      (field['isServicedJobDT'] || (field['defaults'] && field['defaults']['defaultTitle'])) &&
                       serviceReminderData.length"
                           class="service-job-date-container">
                        <div class="service-date-open"
                              (click)="showServiceJobDropDown(field['questionID'])">
                          <p class="service-date-link" translate>Please.click.here.to.populate.next.inspection.due.date.based.on.service.reminder(s)</p>
                        </div>
                        <div *ngIf="currentServiceJobDateId == field['questionID']">
                          <label translate>Service.type</label>
                          <select type="text"
                                  class="service-drop"
                                  [(ngModel)]="serviceJobDate"
                                  (ngModelChange)="serviceJobDateChanged()"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let data of serviceReminderData" [value]="data['value']">{{data['name']}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="span1 measurement-div"
                         *ngIf="field['allMeasurements'] && field['allMeasurements'].length">
                      <select type="text"
                              [(ngModel)]="measurements[field.questionID]"
                              (ngModelChange)="measurementChanged(field.questionID)"
                              [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let value of field['allMeasurements']" [value]="value">{{value}}</option>
                      </select>
                    </div>
                  </div>
                  </ng-container>
                </ng-container>
              <div class="section-end"
                 [ngClass]="{'no-m': page['sections'][sectionIndex+1] && page['sections'][sectionIndex+1]['sectionName']}"
                 *ngIf="section.sectionName || (pages && pages[currentPageNumber] && pages[currentPageNumber]['sections'][sectionIndex+1] && pages[currentPageNumber]['sections'][sectionIndex+1]['sectionName'])" >
              </div>
            </ng-container>
          </ng-container>
          <!--Standard page new structure end-->
        </ng-container>
        <!--List page fields-->
        <ng-container *ngIf="page.pageType == 'list'">
          <div class="list-div span12">
            <div class="list-head">
              <span class="h-l">{{page['pageName']}}</span>
              <a (click)="openListPageSidePanel(i, draftData)"
                      class="btn btn-primary btn-small" translate>Add.item
              </a>
              <hr class="section-separator">
            </div>
            <div class="span12" *ngIf="(listFormIndexAndPageIndex[currentPageNumber] == 0 || listFormIndexAndPageIndex[currentPageNumber])
              && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
              && !listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).length">
                <div class="offset8 span6" style="padding-top: 15px;">
                  <div class="span4" style="display: flex;">
                    <p style="min-width: 95%;" translate>You’ve.not.,yet.added.a.list.item. Click.here.to.add.a.new.item</p>
                    <img class="help-arrow-a-e" src="/angularES-dist/commusoft-v4/assets/images/arrow-left-down1.png">
                  </div>
                </div>
            </div>

            <div style="padding: 10px;"
                 *ngFor="let listItem of listItemValueArray[currentPageNumber]; let listIndex = index;">
              <div class="span12 list-item-l"
                   *ngIf="listItem && listItemFieldOrderArray[currentPageNumber] && listItemFieldOrderArray[currentPageNumber][listIndex]">
                <div>
                  <a href="javascript:void(0);"
                     (click)="deleteListItem(listItem, listIndex)"
                     class="link-action-btn" translate>Delete</a>
                  <a href="javascript:void(0);"
                     (click)="editListItem(listItem, listIndex)"
                     class="link-action-btn" translate>Edit</a>
                </div>
                <!--<ng-container *ngFor="let item of listItem | keyvalue; let isFirst = first;">-->
                <ng-container *ngFor="let qid of listItemFieldOrderArray[currentPageNumber][listIndex] let isFirst = first;">
                  <ng-container *ngIf="listFormLabels && listFormLabels[qid] && listFormLabels[qid]['visibleOnList']">
                    <div class="span12" style="min-height: unset;">
                      <div class="span3 m-l">
                        <label class="control-label key">{{listFormLabels? listFormLabels[qid]['label'] : ''}}</label>
                      </div>
                      <div class="span6">
                        <ng-container *ngIf="listFormLabels[qid]['type'] == 'Single Checkbox'">
                          <label *ngIf="item.value === true" class="control-label lef">&#10004;</label>
                          <label *ngIf="item.value === false" class="control-label lef">&#10006;</label>
                        </ng-container>
                        <label *ngIf="listFormLabels[qid]['type'] == 'Date'"
                               class="control-label lef">{{listItem[qid] | moment:'ddd D MMM YYYY' }}
                        </label>
                        <img class="span3"
                             style="margin-left: 20px !important;"
                             *ngIf="listFormLabels[qid]['type'] == 'Photo' && listItem[qid]" [src]="listItem[qid]">

                        <!--Signature-->
                        <img class="span3 sign-list"
                             *ngIf="listFormLabels[qid]['type'] == 'Signature' && listItem[qid]"
                             [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64,'+ listItem[qid])">

                        <label *ngIf="listFormLabels[qid]['type'] !== 'Date'
                        && listFormLabels[qid]['type'] !== 'Single Checkbox'
                        && listFormLabels[qid]['type'] !== 'Photo' && listFormLabels[qid]['type'] !== 'Signature' "
                               class="control-label lef">{{listItem[qid]}}</label>
                      </div>
                      <div class="span2"></div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </fieldset>
</form>
  <cs-sidepanel id="SetupTaskForm"
                [show]="showListFormSidepanel"
                [name]="'SetupTaskForm'"
                (close)="onSidepanelClose()"
                class="ltc-sidepanel dynamic-cert-sidepanel add-edit-cert-side-panel">

    <!-- Panel title -->
    <div id="page-panel-title-bar">
      <h4 *ngIf="type == 'ADD'" translate>add.new.list.item</h4>
      <h4 *ngIf="type == 'EDIT'" translate>edit.list.item</h4>
    </div>
    <form *ngIf="listItemForm && showListFormSidepanel"
          [formGroup]="listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).at(currentListIndex)"
          (submit)='onFormSubmit()'>
      <div id="page-panel-main" style="padding: 17px 10px 0 10px !important;" class="flex flex-column">
        <!--Asset or appliance list-->
        <div class="asset-section" *ngIf="pages[currentPageNumber]['pageSelectAssetFirst']">
          <div class="app-filter search-with-tabs" *ngIf="!currentlySelectedAppliance">
            <div class="search-dropdown-widget">
              <div class="search_box">
                <a class="ss-search"></a>
                <input type="search"
                       [(ngModel)]="searchText"
                       [ngModelOptions]="{standalone:true}"
                       (valueChanged)="fetchApplianceData()"
                       appDebounceTimer
                       placeholder="Search">
              </div>
            </div>
            <div class="sortable_options">
              <span class="filter-label" translate>Type</span>
              <select id="select2-filter-category"
                      csSelect2
                      style="margin-top: 6px;"
                      (selected)="typeFilterChanged($event)"
                      [select2Config]="{width: '209px', dropdownCssClass: 'slim-scroll thin'}">
                <option value="" translate>Please.Choose</option>
                <optgroup [label]="group.key" *ngFor="let group of applianceGroupTypes | keyvalue">
                  <option [value]="type['id']" *ngFor="let type of group.value">{{type['text']}}</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="loading-wrapp" *ngIf="loading">
            <div *ngFor="let line of [1,2,3,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]"
                 class="text-layout animated-background long">
            </div>
          </div>

          <div *ngIf="!hasAssetModuleAccess && !appliances.length && !loading"
               class="ma3 no-field-wrapper margin-top">
            <br>
            <div class="cs-no-field-content">
              <span class="info"><span class="ss-info"></span></span>
              <p><b translate>There.are.no.appliances.available</b></p>
            </div>
          </div>
          <div *ngIf="hasAssetModuleAccess && !loading && assetData.length == 0"
               class="ma3 no-field-wrapper margin-top">
            <br>
            <div class="cs-no-field-content">
              <span class="info"><span class="ss-info"></span></span>
              <p><b translate>There.are.no.assets.available</b></p>
            </div>
          </div>
          <ng-container *ngIf="!hasAssetModuleAccess">
            <ng-container *ngIf="!currentlySelectedAppliance && !loading">
              <ng-container *ngFor="let appliance of appliances">
                <div class="asset span12">
                  <div class="span8">
                    <p>{{appliance['Appliance type']}}</p>
                    <p>{{appliance['Fuel type']}}</p>
                    <p>{{appliance['Make']}}</p>
                    <p>{{appliance['Model']}}</p>
                    <p>{{appliance['Location']}}</p>
                  </div>
                  <div class="span4">
                    <a class="select-asset"
                       href="javascript: void(0)"
                       (click)="addAppliance(appliance)">Select</a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="currentlySelectedAppliance">
              <div class="asset m-b span12">
                <div class="span8">
                  <p>{{currentlySelectedAppliance['Appliance type']}}</p>
                  <p>{{currentlySelectedAppliance['Fuel type']}}</p>
                  <p>{{currentlySelectedAppliance['Make']}}</p>
                  <p>{{currentlySelectedAppliance['Model']}}</p>
                  <p>{{currentlySelectedAppliance['Location']}}</p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="hasAssetModuleAccess">
            <ng-container *ngIf="!currentlySelectedAppliance && !loading">
              <ng-container *ngFor="let assetGroupObject of assetData | keyvalue">
                <ng-container *ngFor="let assetObjectArray of assetGroupObject.value">
                  <div class="asset span12">
                    <ng-container *ngFor="let asset of assetObjectArray; let isFirst = first;">
                      <div class="span10" *ngIf="asset['fieldLabel'] == 'Asset group' || asset['fieldLabel'] == 'Asset type'">
                        <p class="span10" *ngIf="isFirst"><b>{{assetGroupObject.key}}</b></p>
                          <p *ngIf="asset['fieldLabel'] == 'Asset group' || asset['fieldLabel'] == 'Asset type'"
                             class="span5 min-h">{{asset['fieldLabel']}}</p>
                        <p *ngIf="asset['fieldLabel'] == 'Asset group' || asset['fieldLabel'] == 'Asset type'"
                           class="span5 min-h">{{asset['fieldValue']}}</p>

                        <!--<p *ngIf="!asset['photo']" class="span5 min-h">{{asset['fieldValue']}}</p>-->
                          <!--<img class="span5" *ngIf="asset['photo']" [src]="asset['fieldValue']">-->
                      </div>
                      <div class="span2" *ngIf="isFirst">
                        <a class="select-asset n-m"
                           href="javascript: void(0)"
                           (click)="addAppliance(assetObjectArray, assetGroupObject.key)">Select</a>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="currentlySelectedAppliance">
              <div class="asset m-b span12">
              <ng-container *ngFor="let asset of currentlySelectedAppliance;let isFirst = first;">
                <div class="span10" style="min-height: 24px;" *ngIf="!asset['hide']">
                  <p class="span10" *ngIf="assetGroupObjectKey && isFirst"><b>{{assetGroupObjectKey}}</b></p>
                  <p class="span5 min-h">{{asset['fieldLabel']}}</p>
                  <p *ngIf="!asset['photo']" class="span5 min-h">{{asset['fieldValue']}}</p>
                  <img style="margin-bottom: 8px;" class="span5" *ngIf="asset['photo']" [src]="asset['fieldValue']">
                </div>
              </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <!--List page old structure start-->
        <ng-container *ngFor="let field of pages[currentPageNumber]['fields']">
          <ng-container *ngIf="!field['hasDependency'] || (field['hasDependency'] && field['show'])">
            <div [hidden]="(pages[currentPageNumber]['pageSelectAssetFirst'] && !currentlySelectedAppliance)" class="row-fluid small-padding">
              <span *ngIf="field.fieldAnswerType !== 'Single Checkbox' && field.fieldAnswerType !== 'Plain Text'"
                    [ngClass]="{required_field : field['required']}" translate>{{field.fieldLabel}}</span>
                <div style="display: flex">
                    <div class="required-block w-c"
                         [ngClass]="{'measurements-block': field['allMeasurements'] && field['allMeasurements'].length}">
              <!--Input-->
              <input type="text"
                     *ngIf="field.fieldAnswerType == 'Input' || field.fieldAnswerType == 'Non-editable Input'"
                     [ngClass]="{'none-p':field.fieldAnswerType == 'Non-editable Input'}"
                     [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"/>

              <!--Input with Selection Choices-->
              <input type="hidden"
                     csSelect2
                     [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     [select2Config]="select2LabelConfigList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                     *ngIf="(field.fieldAnswerType == 'Input with Selection Choices' || field.fieldAnswerType == 'Dynamic Input'
                     || field.fieldAnswerType == 'Dynamic Input with Selection Choices') &&
                     select2LabelConfigList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                     (selected)="onSelectInputWithSelection($event, field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1), 'LIST')"
                     [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     [placeholder]="'-- Please select --'"
                     class="chosen-select full-width-select">

              <!--Number-->
              <input type="number"
                     class="num"
                     [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     *ngIf="field.fieldAnswerType == 'Number'"
                     [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"/>
              <!--Decimal-->
              <input type="text"
                     class="num"
                     [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     *ngIf="field.fieldAnswerType == 'Decimal'"
                     [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     appDigitDecimaNumber [scale]="4"/>
              <!--Text Area-->
              <textarea *ngIf="field.fieldAnswerType == 'Text Area'"
                        [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"></textarea>
              <!--Toggle/DropDown-->
              <div *ngIf="field.fieldAnswerType == 'Toggle' || field.fieldAnswerType == 'Dropdown'" style="margin-bottom: 10px;">
                <select [id]="field.questionID"
                        csSelect2
                        [select2Config]="select2Config"
                        class="full-width-select2"
                        [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)">
                  <option value="" translate>Please.Choose</option>
                  <option *ngFor="let option of field.fieldAnswerOptions"
                          [value]="option.optionValue">{{option.optionValue}}
                  </option>
                </select>
              </div>
              <!--Single checkbox-->
              <ng-container *ngIf="field.fieldAnswerType == 'Single Checkbox'">
                <div class="c-r-group">
                  <input type="checkbox"
                         [id]="field.questionID"
                         [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                         [value]="field.fieldLabel" >
                  <label [ngClass]="{'required_field' : field.required }"
                         [for]="field.questionID">{{field.fieldLabel}}</label>
                </div>
              </ng-container>
              <!--Multiple checkbox-->
              <div *ngIf="field.fieldAnswerType == 'Checkbox'">
                <ng-container *ngFor="let option of checkboxGroupFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]">
                  <div class="c-r-group">
                    <input type="checkbox"
                           [id]="option.answerID"
                           (ngModelChange)="checkboxChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="option.formValue"
                           [value]="option.optionValue">
                    <label [for]="option.answerID">{{option.optionValue}}</label>
                  </div>
                </ng-container>
              </div>
              <!--Radio-->
              <div *ngIf="field.fieldAnswerType == 'Radio'">
                <ng-container *ngFor="let option of field.fieldAnswerOptions">
                  <div class="c-r-group">
                    <input type="radio"
                           [id]="option.answerID"
                           [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                           [value]="option.optionValue">
                    <label [for]="option.answerID">{{option.optionValue}}</label>
                  </div>
                </ng-container>
              </div>
              <!--Date-->
              <div  class="input-append datepicker-append" *ngIf="field.fieldAnswerType == 'Date'">
                <input class="bsdatepicker-input"
                       [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                       type="text"
                       readonly
                       #et="bsDatepicker"
                       bsDatepicker
                       [bsConfig]="bsConfig">
                <span (click)="et.toggle()" class="add-on date-picker-edit">
                      <span class="ss-calendar"></span>
                  </span>
              </div>
              <!--Time-->
              <div *ngIf="field.fieldAnswerType == 'Time'">
                <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['hour']"
                        (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                        class="time-drop"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let hour of hoursArray" [value]="hour">{{hour}}</option>
                </select>
                <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['minutes']"
                        (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                        class="time-drop"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let minute of minutesArray" [value]="minute">{{minute}}</option>
                </select>
                <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['meridiem']"
                        class="time-drop"
                        (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let value of meridiemArray" [value]="value">{{value}}</option>
                </select>
              </div>
              <span class="control-error-message show"
                    *ngIf="listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).at(currentListIndex)
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)].touched
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)].errors">
                <span *ngIf="field['validationType'] !== 'email' && field['validationType'] !== 'date' ">Please enter a valid value</span>
                <span *ngIf="field['validationType'] == 'email'">Please enter a valid email format ie. commusoft@gmail.com</span>
                <span *ngIf="field['validationType'] == 'date'">Please enter a valid date format, ie. dd/mm/YYYY, dd-mm-YYYY or dd.mm.YYYY</span>
             </span>
              <!--Plain Text-->
              <div style="margin-bottom: 10px;" *ngIf="field.fieldAnswerType == 'Plain Text'"><span>{{field.fieldLabel}}</span></div>
              <!--Photo -->
              <div *ngIf="field.fieldAnswerType == 'Photo'">
                <ng-container *ngFor="let formControl of getPhotoListArray(field.max,field.questionID)">
                  <input  type="hidden" [formControlName]="formControl" />
                  <div class="uploader">
                    <input  type="file"
                            name="file"
                            (change)="onFileSelected(formControl)"
                            #fileUploader ng2FileSelect
                            [uploader]="uploaderArrayList[formControl]"
                            style="display: none; opacity: 0; width:0px;"/>

                    <div class="btn-group">
                      <button class="btn btn-primary fake-uploader"
                              type="button"
                              (click)="fileUploader.click();onFileSelected(formControl)"
                              readonly="readonly">Click to upload image
                      </button>
                      <button class="btn upload_file_insafari upload-progress btn-primary">{{uploaderArrayList[formControl].progress}}%</button>
                    </div>
                  </div>
                  <div *ngIf="photoFileInvalidStatus[formControl]">
                      <span class="control-error-message show mt1">
                        <span translate>Please upload a valid image file, should be in the format of jpg or jpeg or png</span>
                      </span>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="field.fieldHelpText" class="help-block-r">
                <span class="help-text-r">{{field.fieldHelpText}}</span>
              </div>
              <!--Service job date        -->
              <div *ngIf="field.fieldAnswerType == 'Date' && (field['defaults'] && field['defaults']['defaultTitle']) &&
               serviceReminderData.length"
                   class="service-job-date-container">
                <div class="service-date-open"
                     (click)="showServiceJobDropDown(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))">
                  <p class="service-date-link w-u" translate>Please.click.here.to.populate.next.inspection.due.date.based.on.service.reminder(s)</p>
                </div>
                <div *ngIf="currentServiceJobDateId == field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)">
                  <label translate>Service.type</label>
                  <select type="text"
                          class="service-drop"
                          [(ngModel)]="serviceJobDate"
                          (ngModelChange)="serviceJobDateChanged('LIST')"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let data of serviceReminderData" [value]="data['value']">{{data['name']}}</option>
                  </select>
                </div>
              </div>
            </div>
                </div>
                <div class="span1 measurement-div"
                     *ngIf="field['allMeasurements'] && field['allMeasurements'].length">
                    <select type="text"
                            [(ngModel)]="measurements[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let value of field['allMeasurements']" [value]="value">{{value}}</option>
                    </select>
                </div>
          </div>
          </ng-container>
        </ng-container>
        <!--List page old structure end-->
        <!--List page new structure start-->
        <ng-container *ngIf="pages && pages[currentPageNumber]">
        <ng-container *ngFor="let section of pages[currentPageNumber]['sections']">
          <ng-container *ngIf="!section['hasDependency'] || (section['hasDependency'] && section['show'])">
          <div class="section-name" *ngIf="(section.sectionName && currentlySelectedAppliance) || (section.sectionName && !pages[currentPageNumber]['pageSelectAssetFirst'])">
            <p>{{section.sectionName}}</p>
          </div>
          <ng-container *ngFor="let field of section['fields']">
            <ng-container *ngIf="!field['hasDependency'] || (field['hasDependency'] && field['show'])">
              <div [hidden]="(pages[currentPageNumber]['pageSelectAssetFirst'] && !currentlySelectedAppliance)" class="row-fluid small-padding">
                <span *ngIf="field.fieldAnswerType !== 'Single Checkbox' && field.fieldAnswerType !== 'Plain Text'"
                    [ngClass]="{required_field : field['required']}" translate>{{field.fieldLabel}}</span>
              <div style="display: flex">
              <div class="required-block w-c"
                   [ngClass]="{'measurements-block': field['allMeasurements'] && field['allMeasurements'].length}">
            <!--Input-->
            <input type="text"
                   *ngIf="field.fieldAnswerType == 'Input' || field.fieldAnswerType == 'Non-editable Input'"
                   [ngClass]="{'none-p':field.fieldAnswerType == 'Non-editable Input'}"
                   [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"/>
                  <!--Input with Selection Choices-->
            <input type="hidden"
                   csSelect2
                   [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                   [select2Config]="select2LabelConfigList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                   *ngIf="(field.fieldAnswerType == 'Input with Selection Choices' || field.fieldAnswerType == 'Dynamic Input'
                    || field.fieldAnswerType == 'Dynamic Input with Selection Choices') &&
                    select2LabelConfigList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                   (selected)="onSelectInputWithSelection($event, field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1), 'LIST')"
                   [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                   [placeholder]="'-- Please select --'"
                   class="chosen-select full-width-select">

            <!--Number-->
            <input type="number"
                   class="num"
                   [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                   *ngIf="field.fieldAnswerType == 'Number'"
                   [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"/>
            <!--Decimal-->
            <input type="text"
                   class="num"
                   [id]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                   *ngIf="field.fieldAnswerType == 'Decimal'"
                   [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                   appDigitDecimaNumber [scale]="4"/>
            <!--Text Area-->
            <textarea *ngIf="field.fieldAnswerType == 'Text Area'"
                      [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"></textarea>
            <!--Toggle/DropDown-->
            <div *ngIf="field.fieldAnswerType == 'Toggle' || field.fieldAnswerType == 'Dropdown'" style="margin-bottom: 10px;">
              <select [id]="field.questionID"
                      csSelect2
                      [select2Config]="select2Config"
                      class="full-width-select2"
                      [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)">
                <option value="" translate>Please.Choose</option>
                <option *ngFor="let option of field.fieldAnswerOptions"
                        [value]="option.optionValue">{{option.optionValue}}
                </option>
              </select>
            </div>
            <!--Single checkbox-->
            <ng-container *ngIf="field.fieldAnswerType == 'Single Checkbox'">
              <div class="c-r-group">
                <input type="checkbox"
                       [id]="field.questionID"
                       [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                       [value]="field.fieldLabel" >
                <label [ngClass]="{'required_field' : field.required }"
                       [for]="field.questionID">{{field.fieldLabel}}</label>
              </div>
            </ng-container>
            <!--Multiple checkbox-->
            <div *ngIf="field.fieldAnswerType == 'Checkbox'">
              <ng-container *ngFor="let option of checkboxGroupFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]">
                <div class="c-r-group">
                  <input type="checkbox"
                         [id]="option.answerID"
                         (ngModelChange)="checkboxChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="option.formValue"
                         [value]="option.optionValue">
                  <label [for]="option.answerID">{{option.optionValue}}</label>
                </div>
              </ng-container>
            </div>
            <!--Radio-->
            <div *ngIf="field.fieldAnswerType == 'Radio'">
              <ng-container *ngFor="let option of field.fieldAnswerOptions">
                <div class="c-r-group">
                  <input type="radio"
                         [id]="option.answerID"
                         [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                         [value]="option.optionValue">
                  <label [for]="option.answerID">{{option.optionValue}}</label>
                </div>
              </ng-container>
            </div>
            <!--Date-->
            <div  class="input-append datepicker-append" *ngIf="field.fieldAnswerType == 'Date'">
              <input class="bsdatepicker-input"
                     [formControlName]="field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)"
                     type="text"
                     readonly
                     #et="bsDatepicker"
                     bsDatepicker
                     [bsConfig]="bsConfig">
              <span (click)="et.toggle()" class="add-on date-picker-edit">
                      <span class="ss-calendar"></span>
                  </span>
            </div>
            <!--Time-->
            <div *ngIf="field.fieldAnswerType == 'Time'">
              <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['hour']"
                      (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                      class="time-drop"
                      [ngModelOptions]="{standalone: true}">
                <option *ngFor="let hour of hoursArray" [value]="hour">{{hour}}</option>
              </select>
              <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['minutes']"
                      (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                      class="time-drop"
                      [ngModelOptions]="{standalone: true}">
                <option *ngFor="let minute of minutesArray" [value]="minute">{{minute}}</option>
              </select>
              <select [(ngModel)]="timeFieldsList[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]['meridiem']"
                      class="time-drop"
                      (ngModelChange)="timeChangedList(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                      [ngModelOptions]="{standalone: true}">
                <option *ngFor="let value of meridiemArray" [value]="value">{{value}}</option>
              </select>
            </div>
            <span class="control-error-message show"
                  *ngIf="listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).at(currentListIndex)
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)].touched
                  && listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                  .at(currentListIndex).controls[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)].errors">
                  <span *ngIf="field['validationType'] !== 'email' && field['validationType'] !== 'date' ">Please enter a valid value</span>
                  <span *ngIf="field['validationType'] == 'email'">Please enter a valid email format ie. commusoft@gmail.com</span>
                  <span *ngIf="field['validationType'] == 'date'">Please enter a valid date format, ie. dd/mm/YYYY, dd-mm-YYYY or dd.mm.YYYY</span>
             </span>
            <!--Plain Text-->
            <div style="margin-bottom: 10px;" *ngIf="field.fieldAnswerType == 'Plain Text'"><span>{{field.fieldLabel}}</span></div>
            <!--Photo -->
            <div *ngIf="field.fieldAnswerType == 'Photo'">
              <ng-container *ngFor="let formControl of getPhotoListArray(field.max,field.questionID)">
                <input  type="hidden" [formControlName]="formControl" />
                <div class="uploader">
                  <input  type="file"
                          name="file"
                          (change)="onFileSelected(formControl)"
                          #fileUploader ng2FileSelect
                          [uploader]="uploaderArrayList[formControl]"
                          style="display: none; opacity: 0; width:0px;"/>

                  <div class="btn-group">
                    <button class="btn btn-primary fake-uploader"
                            type="button"
                            (click)="fileUploader.click();onFileSelected(formControl)"
                            readonly="readonly">Click to upload image
                    </button>
                    <button class="btn upload_file_insafari upload-progress btn-primary">{{uploaderArrayList[formControl].progress}}%</button>
                  </div>
                </div>
                <div *ngIf="photoFileInvalidStatus[formControl]">
                      <span class="control-error-message show mt1">
                        <span translate>Please upload a valid image file, should be in the format of jpg or jpeg or png</span>
                      </span>
                </div>
              </ng-container>
            </div>

            <!--Signature-->
            <div class="signature"
                 (click)="openSignatureSidePanel(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))"
                 *ngIf="field.fieldAnswerType == 'Signature'">
              <p class="sig-text"
                 *ngIf="!listItemForm.at(listFormIndexAndPageIndex[currentPageNumber])
                 .at(currentListIndex).value[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                 translate>Click.here.to.sign</p>
              <img class="sig-img"
                   [src]="_DomSanitizer.bypassSecurityTrustUrl('data:image/png;base64,'+listItemForm.
                   at(listFormIndexAndPageIndex[currentPageNumber]).
                   at(currentListIndex).value[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)])"
                   *ngIf="listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).
                   at(currentListIndex).value[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]">
            </div>

            <div *ngIf="field.fieldHelpText" class="help-block-r">
              <span class="help-text-r">{{field.fieldHelpText}}</span>
            </div>
                <!--Service job date        -->
                <div *ngIf="field.fieldAnswerType == 'Date' &&
                ( field['isServicedJobDT'] || (field['defaults'] && field['defaults']['defaultTitle']) ) &&
               serviceReminderData.length"
                     class="service-job-date-container">
                  <div class="service-date-open"
                       (click)="showServiceJobDropDown(field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1))">
                    <p class="service-date-link w-u" translate>Please.click.here.to.populate.next.inspection.due.date.based.on.service.reminder(s)</p>
                  </div>
                  <div *ngIf="currentServiceJobDateId == field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)">
                    <label translate>Service.type</label>
                    <select type="text"
                            class="service-drop"
                            [(ngModel)]="serviceJobDate"
                            (ngModelChange)="serviceJobDateChanged('LIST')"
                            [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let data of serviceReminderData" [value]="data['value']">{{data['name']}}</option>
                    </select>
                  </div>
                </div>
              </div>
                <div class="span1 measurement-div"
                     *ngIf="field['allMeasurements'] && field['allMeasurements'].length">
                  <select type="text"
                          [(ngModel)]="measurements[field.questionID+'_page'+(currentPageNumber+1)+'_'+(currentListIndex+1)]"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let value of field['allMeasurements']" [value]="value">{{value}}</option>
                  </select>
                </div>
              </div>
            </div>
            </ng-container>
          </ng-container>
          <div class="section-end"
               [ngClass]="{'no-m': (pages && pages[currentPageNumber] && pages[currentPageNumber]['sections'][sectionIndex+1]) &&
               pages[currentPageNumber]['sections'][sectionIndex+1]['sectionName']}"
               *ngIf="section.sectionName && currentlySelectedAppliance ||
               (pages && pages[currentPageNumber] && pages[currentPageNumber]['sections'][sectionIndex+1] && pages[currentPageNumber]['sections'][sectionIndex+1]['sectionName'])">
          </div>
          </ng-container>
        </ng-container>
        </ng-container>
        <!--List page new structure end-->
      </div>
      <div class="page-panel-actions buttons-fixed-bottom buttons-right" style="padding: 20px 0 20px 20px;">
        <div class="buttons">
            <button [disabled]="listItemForm.at(listFormIndexAndPageIndex[currentPageNumber]).at(currentListIndex).invalid"
                  *ngIf="!pages[currentPageNumber]['pageSelectAssetFirst']
                  || (pages[currentPageNumber]['pageSelectAssetFirst'] && currentlySelectedAppliance)"
                  type="submit"
                  [ngClass]="{'loading': disableSave}"
                  class="btn btn-primary primary-button">
            <span [hidden]="disableSave" translate>Save</span>
            <span [hidden]="!disableSave" translate>Saving</span>
          </button>
          <a (click)="cancelForm()"  translate id="cancel-panel-btn"
             href="javascript: void(0)">
            Cancel
          </a>
        </div>
      </div>
    </form>
  </cs-sidepanel>
  <app-dynamic-certificate-signature-side-panel [showSignatureSidePanel]="showSignatureSidePanel"
                                                [screenFor]="customTemplateScreen"
                                                (onSignatureGenerate)="getSignatureBase64($event)"
                                                (closeEmitter)="onSignatureSidePanelClose()">

  </app-dynamic-certificate-signature-side-panel>
</ng-container>
