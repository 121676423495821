import {
    Directive,
    OnInit,
    KeyValueDiffer,
    OnDestroy,
    Input,
    Output,
    NgZone,
    KeyValueDiffers,
    ElementRef,
    Inject,
    PLATFORM_ID,
    Optional, OnChanges, SimpleChanges, DoCheck, EventEmitter, Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subject, fromEvent } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import {
    CS_SCROLLBAR_CONFIG, CS_ScrollbarEvent,
    CsScrollbarConfig,
    CsScrollbarConfigInterface, CsScrollbarEvents
} from "@app/directives/cs-scrollbar/cs-scrollbar-config-interface";

@Directive({
  selector: '[appCsScrollbar]'
})
export class CsScrollbarDirective implements OnInit, OnDestroy, OnChanges, DoCheck {

    private timeout: number | null = null;
    private animation: number | null = null;
    private instance:PerfectScrollbar|null = null;
    private configDiff: KeyValueDiffer<string, any> | null = null;
    private readonly ngDestroy: Subject<void> = new Subject();

    @Input() disabled: boolean = false;
    @Input() zIndex: number;
    @Input('appCsScrollbar') config?: CsScrollbarConfigInterface;

    @Output() psScrollY: EventEmitter<any> = new EventEmitter<any>();
    @Output() psScrollX: EventEmitter<any> = new EventEmitter<any>();
    @Output() psScrollUp: EventEmitter<any> = new EventEmitter<any>();
    @Output() psScrollDown: EventEmitter<any> = new EventEmitter<any>();
    @Output() psScrollLeft: EventEmitter<any> = new EventEmitter<any>();
    @Output() psScrollRight: EventEmitter<any> = new EventEmitter<any>();
    @Output() psYReachEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() psYReachStart: EventEmitter<any> = new EventEmitter<any>();
    @Output() psXReachEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() psXReachStart: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private ngZone: NgZone,
        private differs: KeyValueDiffers,
        private renderer: Renderer2,
        public elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() @Inject(CS_SCROLLBAR_CONFIG) private defaultConfig: CsScrollbarConfigInterface
    ) { }

    ngOnInit(): void {
        if(!this.disabled && isPlatformBrowser(this.platformId)) {
            this.renderer.addClass(this.elementRef.nativeElement, 'pos-rlt');
            const config = new CsScrollbarConfig(this.defaultConfig);
            config.assign(this.config);
            this.ngZone.runOutsideAngular( () => {
                this.instance = new PerfectScrollbar(this.elementRef.nativeElement, config);
                if (this.zIndex) {
                    this.renderer.setStyle(this.elementRef.nativeElement.querySelector('.ps__rail-x'), 'z-index', this.zIndex);
                    this.renderer.setStyle(this.elementRef.nativeElement.querySelector('.ps__rail-y'), 'z-index', this.zIndex);
                }
            });
            if (!this.configDiff) {
                this.configDiff = this.differs.find(this.config || {}).create();
                this.configDiff.diff(this.config || {});
            }
            setTimeout( () => {
                if(this.instance)
                    this.instance.update();
            }, 1000);
        }

        this.ngZone.runOutsideAngular(() => {
            CsScrollbarEvents.forEach( (eventName:CS_ScrollbarEvent) => {
                const eventType = eventName.replace(/([A-Z])/g, (c) => `-${c.toLowerCase()}`);
                fromEvent<Event>(this.elementRef.nativeElement, eventType)
                    .pipe(
                        auditTime(10),
                        takeUntil(this.ngDestroy)
                    )
                    .subscribe((event: Event) => {
                        this[eventName].emit(event);
                    });
            });
        });
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ngDestroy.next();
            this.ngDestroy.complete();

            if (this.timeout && typeof window !== 'undefined') {
                window.clearTimeout(this.timeout);
            }
            this.ngZone.runOutsideAngular(() => {
                if (this.instance) {
                    this.instance.destroy();
                }
            });
            this.instance = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled'] && !changes['disabled'].isFirstChange() && isPlatformBrowser(this.platformId)) {
            if (changes['disabled'].currentValue !== changes['disabled'].previousValue) {
                if (changes['disabled'].currentValue === true) {
                    this.ngOnDestroy();
                } else if (changes['disabled'].currentValue === false) {
                    this.ngOnInit();
                }
            }
        }
    }

    ngDoCheck(): void {
        if (!this.disabled && this.configDiff && isPlatformBrowser(this.platformId)) {
            const changes = this.configDiff.diff(this.config || {});
            if (this.instance) this.instance.update();
            if (changes) {
                this.ngOnDestroy();
                this.ngOnInit();
            }
        }
    }
}
