import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { SmartTableComponent } from './smart-table/smart-table.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import {DomService} from "@app/smart_table/services/dom.service";
import { BrowserModule } from '@angular/platform-browser';
import { ColumnEditComponent } from './column-edit/column-edit.component'
import { TooltipModule, ModalModule, SortableModule as BsSortableModule } from "ngx-bootstrap";
import {FormsModule} from "@angular/forms";
import {AppCommonModule} from "@app/shared";
import {SmartTableInstanceService} from "@app/smart_table/services/smarttable.instance.service";
import { SmartTableMetaDataService } from "@app/smart_table/services/metadata.service";
import { SortableModule } from "@app/sortable/sortable.module";
import {CommonModule} from "@angular/common";
import { SettingsTableComponent } from './settings-table/settings-table.component';
import { CellColorDirective } from './directives/cell-color.directive';
import {DirectivesModule} from "@app/directives";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        SmartTableComponent,
        ActionButtonsComponent,
        ColumnEditComponent,
        SettingsTableComponent,
        CellColorDirective
    ],
    providers: [
        DomService,
        SmartTableInstanceService,
        SmartTableMetaDataService
    ],
    imports: [
        CommonModule,
        FormsModule,
        // AppCommonModule,
        SortableModule,
        // SortableModule.forRoot(),
        DataTablesModule,
        //BrowserModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        DirectivesModule,
        TranslateModule
    ],
    entryComponents: [
        SmartTableComponent,
        ActionButtonsComponent,
        ColumnEditComponent,
    ],
    exports: [
      ModalModule,
      SmartTableComponent,
      ActionButtonsComponent,
      ColumnEditComponent,
      SettingsTableComponent,
      CellColorDirective
    ],
    schemas: [ ]
})
export class SmartTableModule {
    static forRoot() {
        return {
            ngModule: SmartTableModule
        };
    }
}
