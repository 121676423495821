import { EventEmitter, OnDestroy } from '@angular/core';
import Handsontable from 'handsontable';
import { CsToastBoxService } from "@app/services";
import { HansontableSelect2 } from '@app/directives';
import { CommonService } from '@app/shared/services/common-service';
import * as moment from 'moment';
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { CurrencyPipe } from '@app/pipes';
import * as i0 from "@angular/core";
import * as i1 from "../utility-services/cs-toast-box.service";
import * as i2 from "../../features/system-settings/proposal-template/services/proposal-template.service";
import * as i3 from "../../shared/services/common-service";
import * as i4 from "../../pipes/currency.pipe";
var ProposalTemplatePricingSectionService = /** @class */ (function () {
    function ProposalTemplatePricingSectionService(toastBox, nominalService, commonService, confirmationBoxHelper, ajsScope, currencyPipe) {
        var _this = this;
        this.toastBox = toastBox;
        this.nominalService = nominalService;
        this.commonService = commonService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.currencyPipe = currencyPipe;
        this.spreadsheetData = [];
        this.hotData = [];
        this.savechange = new EventEmitter();
        this.hotInstances = [];
        this.spreadSheetSettings = {};
        this.spreadSheetViewData = { 'items': [] };
        this.partsData = [];
        this.supplierList = [];
        this.labourRates = [];
        this.selectedPricingData = {};
        this.check = false;
        this.selectedValue = [];
        this.selectedInstanceData = {};
        this.navchange = new EventEmitter();
        this.deletechange = new EventEmitter();
        this.deleteKey = "";
        this.partsKitSelection = false;
        this.upsellEvent = new EventEmitter();
        this.expenses_total_values = {};
        this.originalPartColumn = [];
        this.suppliers = [];
        this.partArray = [];
        this.nominalCode = [];
        this.vatRates = [];
        this.newPartName = new EventEmitter();
        this.notShowEmptyCell = false;
        this.supplierDataList = [];
        this.vatEnabled = true;
        this.editedDataAdded = [];
        // customer parts column
        this.partsColumn = [
            {
                title: 'Part',
                data: 'partsAndPricesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    _this.selectedHotInstance = instance;
                    var addUpsellClass = false;
                    if (value) {
                        var selectedPart = _this.partArray.find(function (item) { return item.id == value; });
                        var partName = '';
                        if (selectedPart) {
                            partName = selectedPart['text'];
                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                            if (selectedIndex != -1) {
                                _this.spreadsheetData[selectedIndex]['data'][row].partsAndPricesId = selectedPart.id;
                                var isUpsellContain = _this.spreadsheetData[selectedIndex]['data'][row]['isContainUpsell'];
                                if (isUpsellContain == "1" || isUpsellContain == true) {
                                    addUpsellClass = true;
                                }
                            }
                        }
                        value = partName;
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                        cellProperties.placeholder = "";
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                        cellProperties.placeholder = "Please select";
                    }
                    if (addUpsellClass) {
                        cellProperties.className = cellProperties.className + " highlight-upsell-row";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'getPartsSupplierData',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            var previousPartId = false;
                            if (_this.selectedRowRef && typeof _this.selectedRowRef == 'object') {
                                previousPartId = _this.selectedRowRef.partsAndPricesId;
                            }
                            _this.partsData = data['parts'];
                            var selectedParts = _this.spreadsheetData.find(function (data) { return data.id == _this.selectedTableInstance; });
                            if (Object.keys(selectedParts).length != 0) {
                                if (selectedParts.hasOwnProperty('data')) {
                                    var filtered = [];
                                    data.parts.forEach(function (e) {
                                        if (!selectedParts['data'].some(function (s) { return s.partsAndPricesId == e.id && s.partsAndPricesId != previousPartId; })) {
                                            filtered.push(e);
                                        }
                                    });
                                    _this.spreadSheetViewData['items'] = filtered;
                                }
                                else {
                                    _this.spreadSheetViewData['items'] = data.parts;
                                }
                            }
                            else {
                                _this.spreadSheetViewData['items'] = data.parts;
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    },
                    formatNoMatches: function (term) {
                        var that = _this;
                        $('.select2-no-results').css({ 'cursor': 'pointer' });
                        $('.select2-input').keyup(function (e) {
                            if (e.keyCode == 13) {
                                that.notShowEmptyCell = true;
                                that.emitNewPartDetails(that, term);
                            }
                        });
                        $('#select2-drop').click(function (e) {
                            that.notShowEmptyCell = true;
                            that.emitNewPartDetails(that, term);
                        });
                        return "Add new part: " + term;
                    }
                }),
            },
            {
                title: 'Quantity',
                data: 'quantity',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var selectedParts = instance.getCopyableData(row, 0);
                    _this.selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts; });
                    if (_this.selectedPartsData) {
                        if (value == null) {
                            value = 1;
                        }
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            _this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                        }
                        if (_this.selectedPartsData.suppliersData.length != 0) {
                            _this.selectedPartsData.suppliersData.forEach(function (el) { el.selected = false, el.priceSelected = false; });
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                }
            },
            {
                title: 'Markup',
                data: 'markup',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var selectedParts = instance.getCopyableData(row, 0);
                    var selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts; });
                    if (selectedPartsData) {
                        if (value == null) {
                            value = selectedPartsData.markup;
                        }
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            _this.spreadsheetData[selectedIndex]['data'][row].markup = value;
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML && value != 'N/A') {
                        td.innerHTML = td.innerHTML + '%';
                    }
                },
            },
            {
                title: 'General price',
                data: 'generalPrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var showSelected = false;
                    var selectedParts = instance.getCopyableData(row, 0);
                    var selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts; });
                    if (selectedPartsData) {
                        var lowestSupplierPrice = void 0, selectedSuppliers = [];
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        /*if (this.spreadsheetData[selectedIndex]['suppliers'].length != 0) {
                            let listSupplierArray = this.spreadsheetData[selectedIndex]['suppliers'].map(supplier => parseInt(supplier.id));
                            if (listSupplierArray) {
                                selectedSuppliers = this.selectedPartsData.suppliersData.filter(supplier => listSupplierArray.includes(supplier.supplierId))
                            }
                            if (selectedSuppliers.length != 0) {
                                lowestSupplierPrice = selectedSuppliers.reduce(function (res, obj) {
                                    return (parseFloat(obj.supplierPrice) < parseFloat(res.supplierPrice)) ? obj : res;
                                });
                                let index = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                                if (index != -1) {
                                    this.selectedPartsData.suppliersData[index].selected = true
                                }
                            }
                        }*/
                        if (value == null) {
                            value = selectedPartsData.price;
                        }
                        if (value) {
                            value = parseFloat(value).toFixed(2);
                        }
                        _this.spreadsheetData[selectedIndex]['data'][row].generalPrice = value;
                        if (_this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg";
                            showSelected = true;
                        }
                        else {
                            cellProperties.readOnlyCellClassName = "";
                            cellProperties.className = "";
                        }
                        /*if (this.spreadsheetData[selectedIndex]['data'][row].supplierId) {
                            if (selectedSuppliers.length != 0) {
                                let selectedPrice = selectedSuppliers.find(i => i.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                                if (selectedPrice) {
                                    this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = selectedPrice.supplierPrice
                                } else {
                                    this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = null
                                    this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                                }
                            }
                            cellProperties.readOnlyCellClassName = ""
                            cellProperties.className = ""
                        }
                        else {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            showSelected = true
                        }*/
                        /*if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('lowestPrice') && this.spreadsheetData[selectedIndex]['data'][row]['lowestPrice']) {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.spreadsheetData[selectedIndex]['data'][row].lowestPrice
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = this.spreadsheetData[selectedIndex]['data'][row].supplierId
                            let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                            if (supplierIndex != -1) {
                                this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                            }
                        } else if (lowestSupplierPrice) {
                            if (parseFloat(lowestSupplierPrice.supplierPrice) < parseFloat(this.selectedPartsData.price)) {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = lowestSupplierPrice.supplierPrice
                                this.spreadsheetData[selectedIndex]['data'][row].supplierId = lowestSupplierPrice.supplierId
                                let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                                if (supplierIndex != -1) {
                                    this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                                }
                            } else {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                                showSelected = true
                                cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            }
                        } else {
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            showSelected = true
                        }*/
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        var new_value = "£" + td.innerHTML;
                        td.innerHTML = "";
                        var label = document.createElement('label');
                        label.className = 'checkbox-button';
                        label.style.cursor = 'pointer';
                        var inputElement = document.createElement('input');
                        inputElement.type = "checkbox";
                        inputElement.name = prop + row + col;
                        inputElement.id = prop + row + col;
                        if (showSelected) {
                            inputElement.checked = true;
                        }
                        if (inputElement.checked) {
                            inputElement.disabled = true;
                        }
                        inputElement.className = "checkbox-button__input";
                        Handsontable.dom.addEvent(inputElement, 'click', function ($event) {
                            _this.selectPrice($event, instance, row, col);
                        });
                        label.appendChild(inputElement);
                        var content = document.createElement('span');
                        content.className = "checkbox-button__control";
                        label.appendChild(content);
                        var priceValue = document.createElement('span');
                        priceValue.innerText = new_value;
                        label.appendChild(priceValue);
                        td.appendChild(label);
                    }
                },
            },
            {
                title: 'Unit sale price',
                data: 'unitSalePrice',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value == null) {
                        value = 0;
                        var selectedParts_1 = instance.getCopyableData(row, 0);
                        if (selectedParts_1) {
                            _this.selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts_1; });
                            value = _this.selectedPartsData.unitSalePrice;
                        }
                    }
                    if (value) {
                        value = parseFloat(value).toFixed(2);
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total cost',
                data: 'totalCost',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('selectedPriceCol')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = parseFloat(_this.selectedValue[row]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        var priceColeName = _this.selectedValue[row]['selectedPriceCol'];
                                        var unitCost = _this.selectedValue[row][priceColeName];
                                        var totalCalculated = 0;
                                        if (unitCost) {
                                            totalCalculated = _this.getTotalCost(unitCost, quantity);
                                        }
                                        else {
                                            totalCalculated = _this.getTotalCost(_this.selectedValue[row]['generalPrice'], quantity);
                                        }
                                        value = _this.convertAndUpdatePrice(totalCalculated);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var costTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('selectedPriceCol')) {
                                            var priceColeName = _this.selectedValue[j]['selectedPriceCol'];
                                            var unitCost = _this.selectedValue[j][priceColeName];
                                            var tempTotal = 0;
                                            if (unitCost) {
                                                tempTotal = _this.getTotalCost(unitCost, quantity);
                                            }
                                            else {
                                                tempTotal = _this.getTotalCost(_this.selectedValue[j]['generalPrice'], quantity);
                                            }
                                            costTotal += tempTotal;
                                        }
                                        /*if (this.selectedValue[j].hasOwnProperty('lowestPrice')) {
                                            if (this.selectedValue[j]['lowestPrice'] != null) {
                                                costTotal += parseFloat(this.selectedValue[j]['lowestPrice']) * quantity
                                            }
                                        }*/
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total sale price',
                data: 'totalSalePrice',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('unitSalePrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = parseFloat(_this.selectedValue[row]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[row]['unitSalePrice'] != null) {
                                            value = (parseFloat(_this.selectedValue[row]['unitSalePrice']) * quantity).toFixed(2);
                                        }
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('unitSalePrice')) {
                                            if (_this.selectedValue[j]['unitSalePrice']) {
                                                saleTotal += (parseFloat(_this.selectedValue[j]['unitSalePrice'])) * quantity;
                                            }
                                        }
                                    }
                                    value = saleTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Discount',
                data: 'discount',
                type: 'numeric',
                allowEmpty: true,
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                if (selectedIndex != -1) {
                                    if (value == null && Object.keys(_this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                        value = "0.00";
                                    }
                                    if (value != null) {
                                        value = parseFloat(value).toFixed(2);
                                        _this.spreadsheetData[selectedIndex]['data'][row].discount = value;
                                    }
                                    cellProperties.readOnly = false;
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                                if (row == instance.countRows() - 1) {
                                    var discount = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('discount') && _this.selectedValue[j]['discount']) {
                                            discount = (parseFloat(_this.selectedValue[j]['discount']) + discount);
                                        }
                                    }
                                    value = discount.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    cellProperties.readOnly = true;
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                }
            },
            {
                title: 'Final sale price',
                data: 'finalSalePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row]['totalSalePrice']) {
                                        if (_this.selectedValue[row]['discount']) {
                                            value = _this.selectedValue[row]['totalSalePrice'] - _this.selectedValue[row]['discount'];
                                        }
                                        else {
                                            value = _this.selectedValue[row]['totalSalePrice'];
                                        }
                                        if (value) {
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].finalSalePrice = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleFinalPrice = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('finalSalePrice') && _this.selectedValue[j]['finalSalePrice']) {
                                            saleFinalPrice = (parseFloat(_this.selectedValue[j]['finalSalePrice']) + saleFinalPrice);
                                        }
                                    }
                                    value = saleFinalPrice.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Profit',
                data: 'profit',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('finalSalePrice') && _this.selectedValue[row].hasOwnProperty('totalCost')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            quantity = parseFloat(_this.selectedValue[row]['quantity']);
                                        }
                                        if (_this.selectedValue[row]['finalSalePrice'] != null && _this.selectedValue[row]['totalCost'] != null) {
                                            value = (parseFloat(_this.selectedValue[row]['finalSalePrice']) - parseFloat(_this.selectedValue[row]['totalCost'])).toFixed(2);
                                        }
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].profit = value;
                                        }
                                        if (value != null) {
                                            if (value > 0) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                            }
                                            else if (value == 0) {
                                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                            }
                                            else if (value < 0 || !value) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                            }
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        // cellProperties.readOnlyCellClassName = "htDimmed"
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var profitTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('profit')) {
                                            if (_this.selectedValue[j]['profit'] != null) {
                                                profitTotal += parseFloat(_this.selectedValue[j]['profit']);
                                            }
                                        }
                                    }
                                    value = profitTotal.toFixed(2);
                                    if (value != null) {
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        }
                                        else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        }
                                        else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "price-total-bg"
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    /*else {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }*/
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Margin',
                data: 'margin',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('profit')) {
                                        if (_this.selectedValue[row]['profit'] != null) {
                                            var profit = parseInt(_this.selectedValue[row]['profit']);
                                            if (profit && parseFloat(_this.selectedValue[row]['finalSalePrice']) > 0) {
                                                value = ((parseFloat(_this.selectedValue[row]['profit']) / parseFloat(_this.selectedValue[row]['finalSalePrice'])) * 100).toFixed(2);
                                            }
                                            else {
                                                value = profit.toFixed(2);
                                            }
                                            if (value < 0) {
                                                value = 0;
                                            }
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1) {
                                                _this.spreadsheetData[selectedIndex]['data'][row].margin = value;
                                            }
                                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "calculate-class-margin";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row != instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "";
                        td.className = "";
                        if (value > 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                        }
                        else if (value == 0) {
                            cellProperties.readOnlyCellClassName = "calculate-bg";
                        }
                        else if (value < 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = td.innerHTML + "%";
                    }
                },
            },
            {
                title: 'Labour time',
                data: 'labourTime',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class-margin';
                        cellProperties.className = "";
                        cellProperties.placeholder = "";
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                    }
                    if (_this.selectedPartsData) {
                        if (value == null) {
                            value = _this.selectedPartsData.labourTime;
                        }
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            _this.spreadsheetData[selectedIndex]['data'][row].labourTime = value;
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
            },
            {
                title: 'Labour rate',
                data: 'labourRatesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var selectedLabourRate = _this.labourRates.find(function (item) { return item.id == value; });
                        var labourRate = '';
                        if (selectedLabourRate) {
                            labourRate = selectedLabourRate['description'];
                        }
                        value = labourRate;
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'sales/get_labour_rates',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            _this.spreadSheetViewData['items'] = [];
                            for (var i = 0; i < data.labourRates.length; i++) {
                                _this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] });
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    }
                }),
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var selectedInstance = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                    if (selectedInstance) {
                        if (Object.keys(selectedInstance).length != 0) {
                            var selectedValue = selectedInstance['data'];
                            if (!selectedValue.hasOwnProperty('isUpsell') || selectedValue[row]['isUpsell'] == "False" || selectedValue[row]['isUpsell'] == false) {
                                var link = document.createElement('a');
                                var type_1 = 'add';
                                link.innerText = "Upsell";
                                if (selectedValue[row].hasOwnProperty('isContainUpsell') && selectedValue[row]['isContainUpsell'] == "1") {
                                    link.innerText = "Edit";
                                    type_1 = 'edit';
                                }
                                if (selectedValue && Object.keys(selectedValue[row]).length) {
                                    link.style.cursor = 'pointer';
                                }
                                else {
                                    link.style.cursor = "default";
                                }
                                if (selectedValue && Object.keys(selectedValue[row]).length) {
                                    Handsontable.dom.addEvent(link, 'click', function () {
                                        _this.Upsell(instance, row, col, type_1);
                                    });
                                }
                                Handsontable.dom.empty(td);
                                td.style.borderRight = '0px solid';
                                var content = document.createElement('div');
                                content.appendChild(link);
                                td.appendChild(content);
                            }
                            if (row == instance.countRows() - 2) {
                                td.innerHTML = '';
                            }
                        }
                    }
                }
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (row == instance.countRows() - 2) {
                        td.innerHTML = '';
                    }
                    else {
                        var link = document.createElement('a');
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        Handsontable.dom.addEvent(link, 'click', function () {
                            _this.deleteKey = 'parts';
                            _this.onRowDelete(instance, row, _this.deleteKey);
                        });
                        Handsontable.dom.empty(td);
                        var content = document.createElement('div');
                        content.appendChild(link);
                        td.appendChild(content);
                    }
                }
            }
        ];
        // Option and proposal template parts column
        this.systemSettingPartsColumn = [
            {
                title: 'Part',
                data: 'partsAndPricesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    _this.selectedHotInstance = instance;
                    var addUpsellClass = false;
                    if (value) {
                        var selectedPart = _this.partArray.find(function (item) { return item.id == value; });
                        var partName = '';
                        if (selectedPart) {
                            partName = selectedPart['text'];
                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                            var isUpsellContain = _this.spreadsheetData[selectedIndex]['data'][row]['isContainUpsell'];
                            if (selectedIndex != -1) {
                                _this.spreadsheetData[selectedIndex]['data'][row].partsAndPricesId = selectedPart.id;
                                if (isUpsellContain == "1" || isUpsellContain == true) {
                                    addUpsellClass = true;
                                }
                            }
                        }
                        value = partName;
                    }
                    if (row == instance.countRows()) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                        cellProperties.placeholder = "";
                    }
                    if (row != instance.countRows()) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                        cellProperties.placeholder = "Please select";
                    }
                    if (addUpsellClass) {
                        cellProperties.className = cellProperties.className + " highlight-upsell-row";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'getPartsSupplierData',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            var previousPartId = false;
                            if (_this.selectedRowRef && typeof _this.selectedRowRef == 'object') {
                                previousPartId = _this.selectedRowRef.partsAndPricesId;
                            }
                            _this.partsData = data['parts'];
                            var selectedParts = _this.spreadsheetData.find(function (data) { return data.id == _this.selectedTableInstance; });
                            if (Object.keys(selectedParts).length != 0) {
                                if (selectedParts.hasOwnProperty('data')) {
                                    var filtered = [];
                                    data.parts.forEach(function (e) {
                                        if (!selectedParts['data'].some(function (s) { return s.partsAndPricesId == e.id && s.partsAndPricesId != previousPartId; })) {
                                            filtered.push(e);
                                        }
                                    });
                                    _this.spreadSheetViewData['items'] = filtered;
                                }
                                else {
                                    _this.spreadSheetViewData['items'] = data.parts;
                                }
                            }
                            else {
                                _this.spreadSheetViewData['items'] = data.parts;
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    },
                    formatNoMatches: function (term) {
                        var that = _this;
                        $('.select2-no-results').css({ 'cursor': 'pointer' });
                        $('.select2-input').keyup(function (e) {
                            if (e.keyCode == 13) {
                                that.notShowEmptyCell = true;
                                that.emitNewPartDetails(that, term);
                            }
                        });
                        $('#select2-drop').click(function (e) {
                            that.notShowEmptyCell = true;
                            that.emitNewPartDetails(that, term);
                        });
                        return "Add new part: " + term;
                    }
                }),
            },
            {
                title: 'Quantity',
                data: 'quantity',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var selectedParts = instance.getCopyableData(row, 0);
                    _this.selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts; });
                    if (_this.selectedPartsData) {
                        if (value == null) {
                            value = 1;
                        }
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            _this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                        }
                        if (_this.selectedPartsData.suppliersData.length != 0) {
                            _this.selectedPartsData.suppliersData.forEach(function (el) { el.selected = false, el.priceSelected = false; });
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                }
            },
            /*{
                title: 'Unit price',
                data: 'costPrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    let showSelected = false;
                    let selectedParts = instance.getCopyableData(row, 0);
                    this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts);
                    if (this.selectedPartsData) {
                        let lowestSupplierPrice, selectedSuppliers = [];
                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                        if (this.spreadsheetData[selectedIndex]['suppliers'].length != 0) {
                            let listSupplierArray = this.spreadsheetData[selectedIndex]['suppliers'].map(supplier => parseInt(supplier.id));
                            if (listSupplierArray) {
                                selectedSuppliers = this.selectedPartsData.suppliersData.filter(supplier => listSupplierArray.includes(supplier.supplierId))
                            }
                            if (selectedSuppliers.length != 0) {
                                lowestSupplierPrice = selectedSuppliers.reduce(function (res, obj) {
                                    return (parseFloat(obj.supplierPrice) < parseFloat(res.supplierPrice)) ? obj : res;
                                });
                                let index = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                                if (index != -1) {
                                    this.selectedPartsData.suppliersData[index].selected = true
                                }
                            }
                        }
                        if (value == null || value != this.selectedPartsData.price) {
                            value = this.selectedPartsData.price;
                        }
                        if (value) {
                            value = parseFloat(value).toFixed(2)
                        }
                        this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                        if(this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg";
                            showSelected = true;
                        } else {
                            cellProperties.readOnlyCellClassName = "";
                            cellProperties.className = "";
                        }
                        /!*if (this.spreadsheetData[selectedIndex]['data'][row].supplierId) {
                            if (selectedSuppliers.length != 0) {
                                let selectedPrice = selectedSuppliers.find(i => i.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                                if (selectedPrice) {
                                    this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = selectedPrice.supplierPrice
                                } else {
                                    this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = null
                                    this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                                }
                            }
                            cellProperties.readOnlyCellClassName = ""
                            cellProperties.className = ""
                        }
                        else {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            showSelected = true
                        }*!/
                        /!*if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('lowestPrice') && this.spreadsheetData[selectedIndex]['data'][row]['lowestPrice']) {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.spreadsheetData[selectedIndex]['data'][row].lowestPrice
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = this.spreadsheetData[selectedIndex]['data'][row].supplierId
                            let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                            if (supplierIndex != -1) {
                                this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                            }
                        } else if (lowestSupplierPrice) {
                            if (parseFloat(lowestSupplierPrice.supplierPrice) < parseFloat(this.selectedPartsData.price)) {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = lowestSupplierPrice.supplierPrice
                                this.spreadsheetData[selectedIndex]['data'][row].supplierId = lowestSupplierPrice.supplierId
                                let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                                if (supplierIndex != -1) {
                                    this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                                }
                            } else {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                                showSelected = true
                                cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            }
                        } else {
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                            showSelected = true
                        }*!/
                    } else {
                        cellProperties.readOnlyCellClassName = ""
                        cellProperties.className = ""
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        let new_value = "£" + td.innerHTML
                        td.innerHTML = ""
                        let label: HTMLElement = document.createElement('label');
                        label.className = 'checkbox-button';
                        label.style.cursor = 'pointer';
                        let inputElement: HTMLInputElement = document.createElement('input');
                        inputElement.type = "checkbox"
                        inputElement.name = prop + row + col
                        inputElement.id = prop + row + col
                        if (showSelected) {
                            inputElement.checked = true
                        }
                        if (inputElement.checked) {
                            inputElement.disabled = true
                        }
                        inputElement.className = "checkbox-button__input"
                        Handsontable.dom.addEvent(inputElement, 'click', ($event) => {
                            this.selectPrice($event, instance, row, col);
                        });
                        label.appendChild(inputElement);
                        let content: HTMLElement = document.createElement('span');
                        content.className = "checkbox-button__control"
                        label.appendChild(content)
                        let priceValue: HTMLElement = document.createElement('span');
                        priceValue.innerText = new_value
                        label.appendChild(priceValue)
                        td.appendChild(label);
                    }
                },
            },
            {
                title: 'Total cost',
                data: 'totalCost',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    if (this.spreadsheetData.length != 0) {
                        this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                        if (this.selectedInstanceData) { //Totalcalulate from SS
                            if (Object.keys(this.selectedInstanceData).length != 0) {
                                this.selectedValue = this.selectedInstanceData['data']
                                if (Object.keys(this.selectedValue[row]).length != 0) {
                                    if (this.selectedValue[row].hasOwnProperty('selectedPriceCol')) {
                                        let quantity = 1
                                        if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (this.selectedValue[row]['quantity']) {
                                                quantity = this.selectedValue[row]['quantity']
                                            } else {
                                                quantity = 1
                                            }
                                        }
    
                                        let priceColeName = this.selectedValue[row]['selectedPriceCol'];
                                        let unitCost = this.selectedValue[row][priceColeName];
                                        let totalCalculated = 0;
                                        if (unitCost) {
                                            totalCalculated = this.getTotalCost(unitCost, quantity);
                                        } else {
                                            totalCalculated = this.getTotalCost(this.selectedValue[row]['generalPrice'], quantity);
                                        }
                                        value = this.convertAndUpdatePrice(totalCalculated);
    
                                        /!*if (this.selectedValue[row]['lowestPrice'] != null) {
                                            value = (quantity * parseFloat(this.selectedValue[row]['lowestPrice'])).toFixed(2)
                                        }*!/
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1) {
                                            this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    let costTotal = 0;
                                    for (var j = 0; j < this.selectedValue.length; j++) {
                                        let quantity = 1
                                        if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(this.selectedValue[j]['quantity'])
                                            } else {
                                                quantity = 1
                                            }
                                        }
                                        if (this.selectedValue[j].hasOwnProperty('selectedPriceCol')) {
                                            let priceColeName = this.selectedValue[j]['selectedPriceCol'];
                                            let unitCost = this.selectedValue[j][priceColeName];
                                            let tempTotal = 0;
                                            if (unitCost) {
                                                tempTotal = this.getTotalCost(unitCost, quantity);
                                            } else {
                                                tempTotal = this.getTotalCost(this.selectedValue[j]['generalPrice'], quantity);
                                            }
                                            costTotal += tempTotal;
                                        }
                                        /!*if (this.selectedValue[j].hasOwnProperty('lowestPrice')) {
                                            if (this.selectedValue[j]['lowestPrice']) {
                                                costTotal += parseFloat(this.selectedValue[j]['lowestPrice']) * quantity
                                            }
                                        }*!/
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = ""
                        }
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML
                    }
                },
            },*/
            {
                title: 'Labour time',
                data: 'labourTime',
                allowEmpty: true,
                type: 'numeric',
                readOnly: false,
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    cellProperties.readOnlyCellClassName = "";
                    if (_this.selectedPartsData) {
                        if (value == null) {
                            value = _this.selectedPartsData.labourTime;
                        }
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            _this.spreadsheetData[selectedIndex]['data'][row].labourTime = value;
                        }
                    }
                    /*if (row == instance.countRows()) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class-margin';
                        cellProperties.className = "";
                        cellProperties.placeholder = "";
                    } else {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        cellProperties.readOnly = false;
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }*/
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
            },
            {
                title: 'Labour rate',
                data: 'labourRatesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                readOnly: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var selectedLabourRate = _this.labourRates.find(function (item) { return item.id == value; });
                        var labourRate = '';
                        if (selectedLabourRate) {
                            labourRate = selectedLabourRate['description'];
                            if (_this.spreadsheetData.length != 0) {
                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                if (selectedIndex != -1) {
                                    if (value) {
                                        _this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                    }
                                }
                            }
                        }
                        value = labourRate;
                    }
                    /*if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class-margin';
                        cellProperties.className = "";
                        cellProperties.placeholder = "";
                    } else {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        cellProperties.readOnly = false;
                        cellProperties.placeholder = "Please select";
                    }
    
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }*/
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'sales/get_labour_rates',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            _this.spreadSheetViewData['items'] = [];
                            for (var i = 0; i < data.labourRates.length; i++) {
                                _this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] });
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    }
                }),
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var selectedInstance = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                    if (selectedInstance) {
                        if (Object.keys(selectedInstance).length != 0) {
                            var selectedValue = selectedInstance['data'];
                            var link = document.createElement('a');
                            var type_2 = 'add';
                            link.innerText = "Upsell";
                            if (selectedValue[row].hasOwnProperty('isContainUpsell') && selectedValue[row]['isContainUpsell'] == "1") {
                                link.innerText = "Edit";
                                type_2 = 'edit';
                            }
                            if (selectedValue && Object.keys(selectedValue[row]).length) {
                                link.style.cursor = 'pointer';
                            }
                            else {
                                link.style.cursor = "default";
                            }
                            if (selectedValue && Object.keys(selectedValue[row]).length) {
                                Handsontable.dom.addEvent(link, 'click', function () {
                                    _this.Upsell(instance, row, col, type_2);
                                });
                            }
                            Handsontable.dom.empty(td);
                            td.style.borderRight = '0px solid';
                            var content = document.createElement('div');
                            content.appendChild(link);
                            td.appendChild(content);
                            /*if (row == instance.countRows() - 1) {
                                td.innerHTML = '';
                            }*/
                        }
                    }
                }
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var link = document.createElement('a');
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', function () {
                        _this.deleteKey = 'systemSettingsParts';
                        _this.onRowDelete(instance, row, _this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                }
            }
        ];
        // customer labour column
        this.labourColumn = [
            {
                title: 'Description',
                data: 'description',
                allowEmpty: false,
                validator: 'emptyCellValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    _this.selectedHideInstance = instance;
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if (value) {
                                _this.spreadsheetData[selectedIndex]['data'][row].description = value;
                            }
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
            },
            {
                title: 'Labour rate',
                data: 'labourRatesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var selectedLabourRate = _this.labourRates.find(function (item) { return item.id == value; });
                        var labourRate = '';
                        if (selectedLabourRate) {
                            labourRate = selectedLabourRate['description'];
                            if (_this.spreadsheetData.length != 0) {
                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                if (selectedIndex != -1) {
                                    if (value) {
                                        _this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                    }
                                }
                            }
                        }
                        value = labourRate;
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'sales/get_labour_rates',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            _this.spreadSheetViewData['items'] = [];
                            for (var i = 0; i < data.labourRates.length; i++) {
                                _this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] });
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    }
                }),
            },
            {
                title: 'Quantity',
                data: 'quantity',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if (value == null && row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                                value = 1;
                            }
                            if (value != null) {
                                _this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                            }
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                }
            },
            {
                title: 'Cost price',
                data: 'costPrice',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if ((value == null || value == "") && Object.keys(_this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                value = "0.00";
                            }
                            if (value != null) {
                                value = parseFloat(value).toFixed(2);
                                _this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                            }
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                }
            },
            {
                title: 'Sale price',
                data: 'salePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex_1 = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex_1 != -1) {
                            if (_this.spreadsheetData[selectedIndex_1]['data'].length != 0) {
                                var selectedLabourRate = _this.labourRates.find(function (i) { return i.id == _this.spreadsheetData[selectedIndex_1]['data'][row]['labourRatesId']; });
                                if (selectedLabourRate) {
                                    value = selectedLabourRate.basicRatePerHour;
                                }
                                if (value) {
                                    value = parseFloat(value).toFixed(2);
                                    _this.spreadsheetData[selectedIndex_1]['data'][row].salePrice = value;
                                }
                            }
                        }
                        if (row != instance.countRows() - 2) {
                            cellProperties.readOnlyCellClassName = "htDimmed";
                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total cost',
                data: 'totalCost',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('costPrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = _this.selectedValue[row]['quantity'];
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        value = (quantity * parseFloat(_this.selectedValue[row]['costPrice'])).toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var costTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('costPrice')) {
                                            if (_this.selectedValue[j]['costPrice']) {
                                                costTotal += parseFloat(_this.selectedValue[j]['costPrice']) * quantity;
                                            }
                                        }
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2 || row == instance.countRows() - 1) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total sale price',
                data: 'totalSalePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('salePrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = _this.selectedValue[row]['quantity'];
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        value = (quantity * parseFloat(_this.selectedValue[row]['salePrice'])).toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('salePrice')) {
                                            if (_this.selectedValue[j]['salePrice']) {
                                                saleTotal += parseFloat(_this.selectedValue[j]['salePrice']) * quantity;
                                            }
                                        }
                                    }
                                    value = saleTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Discount',
                data: 'discount',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                if (selectedIndex != -1) {
                                    if (value == null || !value) {
                                        value = 0;
                                    }
                                    value = parseFloat(value).toFixed(2);
                                    _this.spreadsheetData[selectedIndex]['data'][row].discount = value;
                                    cellProperties.readOnly = false;
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                                if (row == instance.countRows() - 1) {
                                    var discount = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('discount') && _this.selectedValue[j]['discount']) {
                                            discount = (parseFloat(_this.selectedValue[j]['discount']) + discount);
                                        }
                                    }
                                    value = discount.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    cellProperties.readOnly = true;
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                }
            },
            {
                title: 'Final sale price',
                data: 'finalSalePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row]['totalSalePrice']) {
                                        if (_this.selectedValue[row]['discount']) {
                                            value = _this.selectedValue[row]['totalSalePrice'] - _this.selectedValue[row]['discount'];
                                        }
                                        else {
                                            value = _this.selectedValue[row]['totalSalePrice'];
                                        }
                                        if (value != null) {
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].finalSalePrice = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleFinalPrice = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('finalSalePrice') && _this.selectedValue[j]['finalSalePrice']) {
                                            saleFinalPrice = (parseFloat(_this.selectedValue[j]['finalSalePrice']) + saleFinalPrice);
                                        }
                                    }
                                    value = saleFinalPrice.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Profit',
                data: 'profit',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('salePrice') && _this.selectedValue[row].hasOwnProperty('costPrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = _this.selectedValue[row]['quantity'];
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        var selectedSalePrice = void 0;
                                        if (_this.selectedValue[row].hasOwnProperty('finalSalePrice') && _this.selectedValue[row]['finalSalePrice']) {
                                            selectedSalePrice = _this.selectedValue[row]['finalSalePrice'];
                                        }
                                        else {
                                            if (_this.selectedValue[row].hasOwnProperty('totalSalePrice') && _this.selectedValue[row]['totalSalePrice']) {
                                                selectedSalePrice = _this.selectedValue[row]['totalSalePrice'];
                                            }
                                        }
                                        value = (selectedSalePrice - quantity * parseFloat(_this.selectedValue[row]['costPrice'])).toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].profit = value;
                                        }
                                        if (value != null) {
                                            if (value > 0) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                            }
                                            else if (value == 0) {
                                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                            }
                                            else if (value < 0 || !value) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                            }
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        // cellProperties.readOnlyCellClassName = "htDimmed"
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var profitTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('profit') && _this.selectedValue[j]['profit']) {
                                            profitTotal = (parseFloat(_this.selectedValue[j]['profit']) + profitTotal);
                                        }
                                    }
                                    value = profitTotal.toFixed(2);
                                    if (value != null) {
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        }
                                        else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        }
                                        else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "price-total-bg"
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    /*else {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }*/
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Margin',
                data: 'margin',
                allowEmpty: true,
                validator: 'numericValidator',
                readOnly: true,
                type: 'numeric',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('profit')) {
                                        var profitVal = _this.selectedValue[row]['profit'];
                                        if (profitVal != null) {
                                            var profit = profitVal * 100;
                                            if (profit) {
                                                /*let selectedSalePrice;
                                                if (this.selectedValue[row].hasOwnProperty('finalSalePrice') && this.selectedValue[row]['finalSalePrice']) {
                                                    selectedSalePrice = this.selectedValue[row]['finalSalePrice']
                                                } else {
                                                    if (this.selectedValue[row].hasOwnProperty('totalSalePrice') && this.selectedValue[row]['totalSalePrice']) {
                                                        selectedSalePrice = this.selectedValue[row]['totalSalePrice']
                                                    }
                                                }*/
                                                value = _this.getMargin(_this.selectedValue[row]['profit'], _this.selectedValue[row]['finalSalePrice']); //this.selectedValue[row]['profit'] * 100 / selectedSalePrice).toFixed(2)
                                            }
                                            value = _this.convertAndUpdatePrice(value);
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1) {
                                                _this.spreadsheetData[selectedIndex]['data'][row].margin = value;
                                            }
                                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "calculate-class-margin";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row != instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "";
                        td.className = "";
                        if (value > 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                        }
                        else if (value == 0) {
                            cellProperties.readOnlyCellClassName = "calculate-bg";
                        }
                        else if (value < 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = td.innerHTML + "%";
                    }
                },
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var link = document.createElement('a');
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', function () {
                        _this.deleteKey = 'labour';
                        _this.onRowDelete(instance, row, _this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                }
            }
        ];
        // Option and proposal template labour columns
        this.systemSettingsLabourColum = [
            {
                title: 'Description',
                data: 'description',
                allowEmpty: false,
                validator: 'emptyCellValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    _this.selectedHideInstance = instance;
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if (value) {
                                _this.spreadsheetData[selectedIndex]['data'][row].description = value;
                            }
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
            },
            {
                title: 'Labour rate',
                data: 'labourRatesId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var selectedLabourRate = _this.labourRates.find(function (item) { return item.id == value; });
                        var labourRate = '';
                        if (selectedLabourRate) {
                            labourRate = selectedLabourRate['description'];
                            if (_this.spreadsheetData.length != 0) {
                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                if (selectedIndex != -1) {
                                    if (value) {
                                        _this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                    }
                                }
                            }
                        }
                        value = labourRate;
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    minimumInputLength: 2,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    ajax: {
                        url: 'sales/get_labour_rates',
                        data: function (query, page) {
                            if (query === '') {
                                return false;
                            }
                            return { 'searchText': encodeURIComponent(query) };
                        },
                        // On search ajax response
                        results: function (data, page) {
                            _this.spreadSheetViewData['items'] = [];
                            for (var i = 0; i < data.labourRates.length; i++) {
                                _this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] });
                            }
                            return { results: _this.spreadSheetViewData['items'] };
                        },
                    },
                    formatSelection: function (item) {
                        return item.text;
                    }
                }),
            },
            {
                title: 'Quantity',
                data: 'quantity',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if (value == null && row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                                value = 1;
                            }
                            if (value <= 0 && (instance.getDataAtCell(row, 0) || instance.getDataAtCell(row, 0) == '')) {
                                value = 1;
                            }
                            if (value != null) {
                                _this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                            }
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                }
            },
            {
                title: 'Unit cost',
                data: 'costPrice',
                allowEmpty: true,
                type: 'numeric',
                readOnly: false,
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if ((value == null || value == "") && Object.keys(_this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                value = "0.00";
                            }
                            if (value != null) {
                                value = parseFloat(value).toFixed(2);
                                _this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                            }
                        }
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        td.className = "customer-pricing-labour-border";
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                }
            },
            {
                title: 'Sale price',
                data: 'salePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex_2 = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex_2 != -1) {
                            if (_this.spreadsheetData[selectedIndex_2]['data'].length != 0) {
                                var selectedLabourRate = _this.labourRates.find(function (i) { return i.id == _this.spreadsheetData[selectedIndex_2]['data'][row]['labourRatesId']; });
                                if (selectedLabourRate) {
                                    value = selectedLabourRate.basicRatePerHour;
                                }
                                if (value) {
                                    value = parseFloat(value).toFixed(2);
                                    _this.spreadsheetData[selectedIndex_2]['data'][row].salePrice = value;
                                }
                            }
                        }
                        if (row != instance.countRows() - 2) {
                            cellProperties.readOnlyCellClassName = "htDimmed";
                            cellProperties.className = "";
                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        cellProperties.className = "";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total cost',
                data: 'totalCost',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('costPrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = _this.selectedValue[row]['quantity'];
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        value = (quantity * parseFloat(_this.selectedValue[row]['costPrice'])).toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var costTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('costPrice')) {
                                            if (_this.selectedValue[j]['costPrice']) {
                                                costTotal += parseFloat(_this.selectedValue[j]['costPrice']) * quantity;
                                            }
                                        }
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Total sale price',
                data: 'totalSalePrice',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('salePrice')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            if (_this.selectedValue[row]['quantity']) {
                                                quantity = _this.selectedValue[row]['quantity'];
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (!_this.selectedValue[row]['salePrice']) {
                                            value = 0;
                                        }
                                        else {
                                            value = (quantity * parseFloat(_this.selectedValue[row]['salePrice'])).toFixed(2);
                                        }
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value;
                                        }
                                        cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        var quantity = 1;
                                        if (_this.selectedValue[j].hasOwnProperty('quantity')) {
                                            if (parseFloat(_this.selectedValue[j]['quantity'])) {
                                                quantity = parseFloat(_this.selectedValue[j]['quantity']);
                                            }
                                            else {
                                                quantity = 1;
                                            }
                                        }
                                        if (_this.selectedValue[j].hasOwnProperty('salePrice')) {
                                            if (_this.selectedValue[j]['salePrice']) {
                                                saleTotal += parseFloat(_this.selectedValue[j]['salePrice']) * quantity;
                                            }
                                        }
                                    }
                                    value = saleTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        if (!td.innerHTML.includes('£')) {
                            td.innerHTML = "£" + td.innerHTML;
                        }
                    }
                },
            },
            {
                title: 'Profit',
                data: 'profit',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('totalSalePrice') && _this.selectedValue[row].hasOwnProperty('totalCost')) {
                                        var quantity = 1;
                                        if (_this.selectedValue[row].hasOwnProperty('quantity')) {
                                            quantity = parseFloat(_this.selectedValue[row]['quantity']);
                                        }
                                        var totalSalePrice = parseFloat(_this.selectedValue[row]['totalSalePrice']);
                                        var profit = _this.getProfit(totalSalePrice, parseFloat(_this.selectedValue[row]['totalCost']));
                                        value = profit.toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1) {
                                            _this.spreadsheetData[selectedIndex]['data'][row].profit = value;
                                        }
                                        if (value != null) {
                                            if (value > 0) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                            }
                                            else if (value == 0) {
                                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                            }
                                            else if (value < 0 || !value) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                            }
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        // cellProperties.readOnlyCellClassName = "htDimmed";
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    var profitTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('profit')) {
                                            if (_this.selectedValue[j]['profit'] != null) {
                                                profitTotal += parseFloat(_this.selectedValue[j]['profit']);
                                            }
                                        }
                                    }
                                    value = profitTotal.toFixed(2);
                                    if (value != null) {
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        }
                                        else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        }
                                        else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "price-total-bg"
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    /* else {
                         cellProperties.readOnlyCellClassName = "calculate-bg";
                     }*/
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = "£" + td.innerHTML;
                    }
                },
            },
            {
                title: 'Margin',
                data: 'margin',
                allowEmpty: true,
                validator: 'numericValidator',
                readOnly: true,
                type: 'numeric',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    var quantity = _this.selectedValue[row]['quantity'];
                                    if (quantity < 1) {
                                        quantity = 1;
                                    }
                                    var totalSalePrice = parseFloat(_this.selectedValue[row]['totalSalePrice']);
                                    var profit = _this.getProfit(totalSalePrice, parseFloat(_this.selectedValue[row]['totalCost']));
                                    value = _this.getMargin(profit, totalSalePrice);
                                    /*if(!profit || profit == NaN){
                                        value = profit.toFixed(2)
                                    }else{
                                        value = totalSalePrice && totalSalePrice > 0 ? (profit * 100 / this.selectedValue[row]['totalSalePrice']).toFixed(2) : -100;
                                    }*/
                                    var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                    if (selectedIndex != -1) {
                                        _this.spreadsheetData[selectedIndex]['data'][row].margin = value;
                                    }
                                    if (value > 0) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                    }
                                    else if (value == 0) {
                                        cellProperties.readOnlyCellClassName = "calculate-bg";
                                    }
                                    else if (value < 0 || !value) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                    }
                                    value = _this.convertAndUpdatePrice(value);
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "calculate-class-margin";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row == instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row != instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "";
                        td.className = "";
                        if (value > 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                        }
                        else if (value == 0) {
                            cellProperties.readOnlyCellClassName = "calculate-bg";
                        }
                        else if (value < 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    if (td.innerHTML) {
                        td.innerHTML = td.innerHTML + "%";
                    }
                },
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var link = document.createElement('a');
                    if (row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        td.className = "";
                    }
                    Handsontable.dom.addEvent(link, 'click', function () {
                        _this.deleteKey = 'systemSettingsLabour';
                        _this.onRowDelete(instance, row, _this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    if (row != instance.countRows() - 2) {
                        content.appendChild(link);
                    }
                    td.appendChild(content);
                }
            }
        ];
        // cusomer expenses column
        this.expensesColumn = [
            {
                title: 'Description',
                data: 'description',
                allowEmpty: false,
                validator: 'emptyCellValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                }
            },
            // {
            //     title: 'Supplier',
            //     data: 'supplierId',
            //     validator: 'emptyCellValidator',
            //     placeholder: 'Please select',
            //     allowEmpty: false,
            //     className: 'overflow_handsontable select_icon',
            //     renderer: (instance, td, row, col, prop, value, cellProperties) => {
            //         if (value) {
            //             let selectedSupplier = this.supplierList.find((item: any) => item.id == value);
            //             let supplier: string = '';
            //             if (selectedSupplier) {
            //                 supplier = selectedSupplier['text'];
            //             }
            //             value = supplier
            //         }
            //         Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            //     },
            //     editor: HansontableSelect2({
            //         minimumInputLength: 2,
            //         dropdownCssClass: 'handsontable-select',
            //         dropdownAutoWidth: true,
            //         ajax: {
            //             url: 'get_all_suppliers',
            //             data: (query, page) => {
            //                 if (query === '') {
            //                     return false;
            //                 }
            //                 return { 'q': encodeURIComponent(query) };
            //             },
            //             // On search ajax response
            //             results: (data, page) => {
            //                 this.spreadSheetViewData['items'] = data
            //                 return { results: this.spreadSheetViewData['items'] };
            //             },
            //         },
            //         formatSelection: function (item) {
            //             return item.text;
            //         }
            //     }),
            // },
            {
                title: 'Nominal code',
                data: 'settingsNominalCodeId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var nominalCode = _this.nominalCode.find(function (item) { return item.id == value; });
                        var nominalValue = void 0;
                        if (nominalCode) {
                            nominalValue = nominalCode['description'];
                        }
                        value = nominalValue;
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    // minimumInputLength: 0,
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    data: this.nominalCode,
                }),
            },
            {
                title: 'Cost price',
                data: 'costPrice',
                allowEmpty: false,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if ((value == null || value == "" || isNaN(value)) &&
                                Object.keys(_this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                value = "0.00";
                            }
                            if (value != null) {
                                value = parseFloat(value).toFixed(2);
                                _this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                            }
                        }
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (value != null) {
                                    value = parseFloat(value).toFixed(2);
                                }
                                else {
                                    value = '';
                                }
                                if (row == instance.countRows() - 1) {
                                    var costTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('costPrice')) {
                                            if (_this.selectedValue[j]['costPrice']) {
                                                costTotal += parseFloat(_this.selectedValue[j]['costPrice']);
                                            }
                                        }
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnly = true;
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                    }
                    value = !isNaN(value) ? value : "";
                    if (row == instance.countRows() - 1 && !isNaN(value)) {
                        value = _this.currencyPipe.transform(value);
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    // if (td.innerHTML) {
                    //     td.innerHTML = "£" + td.innerHTML
                    // }
                }
            },
            {
                title: 'Sale price',
                data: 'salePrice',
                allowEmpty: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                        if (selectedIndex != -1) {
                            if ((value == null || value == "" || isNaN(value)) &&
                                Object.keys(_this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                value = "0.00";
                            }
                            if (value != null) {
                                value = parseFloat(value).toFixed(2);
                                _this.spreadsheetData[selectedIndex]['data'][row].salePrice = value;
                            }
                        }
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (value != null) {
                                    value = parseFloat(value).toFixed(2);
                                }
                                if (row == instance.countRows() - 1) {
                                    var saleTotal = 0;
                                    cellProperties.readOnly = true;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('salePrice')) {
                                            if (_this.selectedValue[j]['salePrice']) {
                                                saleTotal += parseFloat(_this.selectedValue[j]['salePrice']);
                                            }
                                        }
                                    }
                                    value = saleTotal.toFixed(2);
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                    }
                    value = !isNaN(value) ? value : "";
                    if (row == instance.countRows() - 1 && !isNaN(value)) {
                        value = _this.currencyPipe.transform(value);
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    // if (td.innerHTML) {
                    //     td.innerHTML = "£" + td.innerHTML
                    // }
                }
            },
            {
                title: 'VAT',
                data: 'vatRate',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value == null || value == '') {
                        value = 3;
                    }
                    if (value) {
                        var vatRate = _this.vatRates.find(function (item) { return item.id == value; });
                        var nominalValue = void 0;
                        if (vatRate) {
                            nominalValue = vatRate['description'];
                        }
                        value = nominalValue;
                    }
                    if (row == instance.countRows() - 1) {
                        value = "";
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    data: this.formatTaxItems(this.vatRates),
                }),
            },
            {
                title: 'Profit',
                data: 'profit',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (_this.selectedValue && _this.selectedValue.length != 0) {
                                    if (Object.keys(_this.selectedValue[row]).length != 0) {
                                        if (_this.selectedValue[row].hasOwnProperty('salePrice') && _this.selectedValue[row].hasOwnProperty('costPrice')) {
                                            var quantity = 1;
                                            var salePriceValue = parseFloat(_this.selectedValue[row]['salePrice']);
                                            var costPriceValue = parseFloat(_this.selectedValue[row]['costPrice']);
                                            var profitCalculated = _this.getProfit(salePriceValue, costPriceValue);
                                            value = profitCalculated.toFixed(2);
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1) {
                                                _this.spreadsheetData[selectedIndex]['data'][row].profit = value;
                                            }
                                            if (value != null) {
                                                if (value > 0) {
                                                    cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                                }
                                                else if (value == 0) {
                                                    cellProperties.readOnlyCellClassName = "calculate-bg";
                                                    cellProperties.readOnlyCellClassName = "htDimmed";
                                                }
                                                else if (value < 0 || !value) {
                                                    cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                                }
                                                value = parseFloat(value).toFixed(2);
                                            }
                                            // cellProperties.readOnlyCellClassName = "htDimmed"
                                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                        }
                                    }
                                    if (row == instance.countRows() - 1) {
                                        var profitTotal = 0;
                                        for (var j = 0; j < _this.selectedValue.length; j++) {
                                            if (_this.selectedValue[j].hasOwnProperty('profit')) {
                                                if (_this.selectedValue[j]['profit'] != null) {
                                                    profitTotal += parseFloat(_this.selectedValue[j]['profit']);
                                                }
                                            }
                                        }
                                        value = !isNaN(profitTotal) ? profitTotal : 0;
                                        value = value.toFixed(2);
                                        if (value != null) {
                                            if (value > 0) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                            }
                                            else if (value == 0) {
                                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                            }
                                            else if (value < 0 || !value) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                            }
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        // cellProperties.readOnlyCellClassName = "price-total-bg"
                                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                            }
                        }
                        if (row == instance.countRows() - 2) {
                            if (!value) {
                                value = "";
                            }
                            cellProperties.readOnlyCellClassName = "calculate-bg";
                        }
                        if (row == instance.countRows() - 1 && !isNaN(value)) {
                            value = _this.currencyPipe.transform(value);
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                        // if (td.innerHTML) {
                        //     td.innerHTML = "£" + td.innerHTML
                        // }
                    }
                },
            },
            {
                title: 'Margin',
                data: 'margin',
                allowEmpty: true,
                readOnly: true,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (Object.keys(_this.selectedValue[row]).length != 0) {
                                    if (_this.selectedValue[row].hasOwnProperty('profit')) {
                                        if (_this.selectedValue[row]['profit'] != null) {
                                            value = _this.getMargin(_this.selectedValue[row]['profit'], _this.selectedValue[row]['salePrice']);
                                            //value = (this.selectedValue[row]['profit'] * 100 / this.selectedValue[row]['salePrice']).toFixed(2)
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1) {
                                                _this.spreadsheetData[selectedIndex]['data'][row].margin = value;
                                            }
                                            value = _this.convertAndUpdatePrice(value);
                                            Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnlyCellClassName = "calculate-class-margin";
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    else if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    else if (row != instance.countRows() - 1) {
                        if (value != null) {
                            cellProperties.readOnlyCellClassName = "";
                            td.className = "";
                            if (value > 0) {
                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                            }
                            else if (value == 0) {
                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                cellProperties.readOnlyCellClassName = "htDimmed";
                            }
                            else if (value < 0) {
                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                            }
                        }
                        else {
                            cellProperties.readOnlyCellClassName = "calculate-bg";
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    if (td.innerHTML) {
                        td.innerHTML = td.innerHTML + "%";
                    }
                },
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (row < instance.countRows() - 2) {
                        var link = document.createElement('a');
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        Handsontable.dom.addEvent(link, 'click', function () {
                            _this.deleteKey = 'expenses';
                            _this.onRowDelete(instance, row, _this.deleteKey);
                        });
                        Handsontable.dom.empty(td);
                        var content = document.createElement('div');
                        content.appendChild(link);
                        td.appendChild(content);
                    }
                    else {
                        //cellProperties.className = 'calculate-class';
                        td.innerHTML = '';
                    }
                    // TODO : vat enable disable add extra on column hidden expansive
                    if (!_this.vatEnabled) {
                        if (row == instance.countRows() - 1) {
                            td.hidden = true;
                        }
                        else {
                            td.hidden = false;
                        }
                    }
                }
            }
        ];
        // System settings expense columns
        this.systemSettingsexpensesColumn = [
            {
                title: 'Description',
                data: 'description',
                allowEmpty: false,
                validator: 'emptyCellValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (row == instance.countRows() - 1) {
                        cellProperties.readOnly = true;
                        cellProperties.readOnlyCellClassName = 'calculate-class';
                        cellProperties.className = 'customer-pricing';
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                }
            },
            // {
            //     title: 'Supplier',
            //     data: 'supplierId',
            //     validator: 'emptyCellValidator',
            //     placeholder: 'Please select',
            //     allowEmpty: false,
            //     className: 'overflow_handsontable select_icon',
            //     renderer: (instance, td, row, col, prop, value, cellProperties) => {
            //         if (value) {
            //             let selectedSupplier = this.supplierList.find((item: any) => item.id == value);
            //             let supplier: string = '';
            //             if (selectedSupplier) {
            //                 supplier = selectedSupplier['text'];
            //             }
            //             value = supplier
            //         }
            //         Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            //     },
            //     editor: HansontableSelect2({
            //         minimumInputLength: 2,
            //         dropdownCssClass: 'handsontable-select',
            //         dropdownAutoWidth: true,
            //         ajax: {
            //             url: 'get_all_suppliers',
            //             data: (query, page) => {
            //                 if (query === '') {
            //                     return false;
            //                 }
            //                 return { 'q': encodeURIComponent(query) };
            //             },
            //             // On search ajax response
            //             results: (data, page) => {
            //                 this.spreadSheetViewData['items'] = data
            //                 return { results: this.spreadSheetViewData['items'] };
            //             },
            //         },
            //         formatSelection: function (item) {
            //             return item.text;
            //         }
            //     }),
            // },
            {
                title: 'Nominal code',
                data: 'settingsNominalCodeId',
                validator: 'emptyCellValidator',
                placeholder: 'Please select',
                allowEmpty: false,
                className: 'overflow_handsontable select_icon',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (value) {
                        var nominalCode = _this.nominalCode.find(function (item) { return item.id == value; });
                        var nominalValue = void 0;
                        if (nominalCode) {
                            nominalValue = nominalCode['description'];
                        }
                        value = nominalValue;
                    }
                    else {
                        value = '';
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                },
                editor: HansontableSelect2({
                    dropdownCssClass: 'handsontable-select',
                    dropdownAutoWidth: true,
                    data: this.nominalCode,
                }),
            },
            {
                title: 'Cost price',
                data: 'costPrice',
                allowEmpty: false,
                type: 'numeric',
                validator: 'numericValidator',
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    if (_this.spreadsheetData.length != 0) {
                        _this.selectedInstanceData = _this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
                        if (_this.selectedInstanceData) {
                            if (Object.keys(_this.selectedInstanceData).length != 0) {
                                _this.selectedValue = _this.selectedInstanceData['data'];
                                if (value != null) {
                                    value = parseFloat(value).toFixed(2);
                                }
                                if (row == instance.countRows() - 1) {
                                    var costTotal = 0;
                                    for (var j = 0; j < _this.selectedValue.length; j++) {
                                        if (_this.selectedValue[j].hasOwnProperty('costPrice')) {
                                            if (_this.selectedValue[j]['costPrice']) {
                                                costTotal += parseFloat(_this.selectedValue[j]['costPrice']);
                                            }
                                        }
                                    }
                                    value = costTotal.toFixed(2);
                                    cellProperties.readOnly = true;
                                    cellProperties.readOnlyCellClassName = "price-total-bg";
                                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = "";
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg";
                    }
                    if (row != instance.countRows() - 1) {
                        cellProperties.readOnly = false;
                        cellProperties.readOnlyCellClassName = "";
                    }
                    Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    // if (td.innerHTML) {
                    //     td.innerHTML = "£" + td.innerHTML
                    // }
                }
            },
            {
                title: 'Actions',
                readOnly: true,
                disableVisualSelection: false,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    var link = document.createElement('a');
                    if (row == instance.countRows() - 1) {
                        link.style.display = 'none';
                        td.className = "customer-pricing-labour-border";
                    }
                    if (row != instance.countRows() - 1) {
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        td.className = "";
                    }
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', function () {
                        _this.deleteKey = 'systemSettingsExpenses';
                        _this.onRowDelete(instance, row, _this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    var content = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                }
            }
        ];
        this.viewMode = false;
        // Action colspan and header bg setting
        this.afterGetColHeader = function (col, TH) {
            var className = '';
            className += 'pricing_col_header_bg';
            if (className && TH) {
                TH.className = className;
            }
            if (_this.hotId.startsWith('pricing-section-partsId') || _this.hotId.startsWith('system-settings-pricing-section-partsId')) {
                if (TH.textContent == 'Actions') {
                    TH.colSpan = 2;
                }
                else {
                    TH.colSpan = 1;
                }
            }
            if (_this.hotId.startsWith('pricing-section-partsId') || _this.hotId.startsWith('system-settings-pricing-section-partsId')) {
                var index = _this.originalPartColumn.findIndex(function (i) { return i.title == TH.textContent; });
                if (index == -1) {
                    TH.className = 'pricing_supplier_bg';
                }
            }
        };
        this.emptyCellValidator = function (value, callback) {
            if (value === '' && !_this.notShowEmptyCell) {
                _this.toastBox.show('Empty cell not allowed', 1000);
            }
            _this.notShowEmptyCell = false;
            callback(true);
        };
        this.numericValidator = function (value, callback) {
            var dataValid = true;
            if (value && value != '') {
                if (isNaN(value)) {
                    dataValid = false;
                    _this.toastBox.show('Invalid data', 1000);
                }
            }
            callback(dataValid);
        };
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
        Handsontable.validators.registerValidator('numericValidator', this.numericValidator);
        this.nominalService.getNominalCodeList().subscribe(function (data) {
            if (data) {
                _this.nominalCode = data['data'];
                var searchData_1 = [];
                _this.nominalCode.forEach(function (data) {
                    var entry = { id: data['id'], text: data['description'] };
                    searchData_1.push(entry);
                });
                _this.setNominalCodeEditor(searchData_1);
            }
        });
    }
    ProposalTemplatePricingSectionService.prototype.setViewMode = function (viewMode) {
        this.viewMode = viewMode;
    };
    ProposalTemplatePricingSectionService.prototype.emitSaveChangeEvent = function (obj) {
        this.savechange.emit(obj);
    };
    ProposalTemplatePricingSectionService.prototype.emitNavChangeEvent = function (obj) {
        this.navchange.emit(obj);
    };
    ProposalTemplatePricingSectionService.prototype.getSaveChangeEmitter = function () {
        return this.savechange;
    };
    ProposalTemplatePricingSectionService.prototype.getNavChangeEmitter = function () {
        return this.navchange;
    };
    ProposalTemplatePricingSectionService.prototype.clearData = function () {
        var _this = this;
        if (this.check) {
            for (var i = 0; i < this.hotInstances.length; i++) {
                var instance = this.hotInstances.find(function (instance) { return instance.id == _this.hotInstances[i]['id']; })['instance'];
                Handsontable.hooks.destroy(instance);
            }
            this.hotData = [];
            this.hotInstances = [];
            this.spreadsheetData = [];
            this.selectedPricingData = {};
        }
    };
    ProposalTemplatePricingSectionService.prototype.clearDeletedInstances = function (hotIdToDelete) {
        var selectedHotIndex = this.hotInstances.findIndex(function (data) { return data.id == hotIdToDelete; });
        var spreadDataIndex = this.spreadsheetData.findIndex(function (data) { return data.id == hotIdToDelete; });
        if (selectedHotIndex != -1) {
            this.hotInstances.splice(selectedHotIndex, 1);
        }
        if (spreadDataIndex != -1) {
            this.spreadsheetData.splice(spreadDataIndex, 1);
        }
    };
    ProposalTemplatePricingSectionService.prototype.getSpreadSheetData = function (hotId) {
        if (this.spreadsheetData.length != 0) {
            var pricingData = this.spreadsheetData.find(function (data) { return data.id == hotId; });
            if (pricingData) {
                this.hotData = pricingData['data'];
            }
        }
        this.check = true;
        return this.hotData;
    };
    ProposalTemplatePricingSectionService.prototype.spreadsheetSettings = function () {
        return {};
    };
    // Merge existing data while adding partskit, catalogue,upsell and new parts
    ProposalTemplatePricingSectionService.prototype.mergeSpreadSheetData = function (hotId, data) {
        this.partsKitSelection = true;
        this.selectedHotIndex = this.spreadsheetData.findIndex(function (data) { return data.id == hotId; });
        var hotInstance = this.hotInstances.find(function (instance) { return instance.id == hotId; });
        var indexCountToRemove = hotId.startsWith('system-settings-pricing-section-partsId') ? 1 : 2;
        this.spreadsheetData[this.selectedHotIndex]['data'].splice.apply(this.spreadsheetData[this.selectedHotIndex]['data'], [this.spreadsheetData[this.selectedHotIndex]['data'].length - indexCountToRemove, 0].concat(data));
        if (hotId.startsWith('pricing-section-partsId')) {
            hotInstance['instance'].updateSettings({
                mergeCells: [
                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                    { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                ]
            });
        }
        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
            hotInstance['instance'].updateSettings({
            /*mergeCells: [
                { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                { row: this.hotData.length - 1, col: 4 + this.suppliers.length, rowspan: 1, colspan: 4 }
            ]*/
            });
        }
        hotInstance['instance'].render();
    };
    ProposalTemplatePricingSectionService.prototype.getSupplierPrice = function (partId, supplierId) {
        var supplierPrice = '';
        if (this.partArray && this.partArray.length) {
            var part = this.partArray.find(function (part) { return part.id == partId; });
            if (part) {
                var suppliersData = part.suppliersData;
                if (suppliersData && suppliersData.length) {
                    var supplierData = suppliersData.find(function (supplier) { return supplier.supplierId == supplierId; });
                    supplierPrice = supplierData ? parseFloat(supplierData.supplierPrice).toFixed(2) : '';
                }
            }
        }
        return supplierPrice;
    };
    ProposalTemplatePricingSectionService.prototype.updateSupplierPrices = function (data) {
        var _this = this;
        if (data && data.length) {
            data.map(function (row) {
                var suppliers = row.suppliers && row.suppliers != '' ? JSON.parse(row.suppliers) : [];
                if (suppliers.length) {
                    for (var i = 0; i < suppliers.length; i++) {
                        row[suppliers[i].name] = _this.getSupplierPrice(row.partsAndPricesId, suppliers[i].id);
                    }
                }
                return row;
            });
        }
        return data;
    };
    ProposalTemplatePricingSectionService.prototype.checkGivenSupplierCostIsLow = function (spreadsheetData, prop, row) {
        var suppliers = spreadsheetData.suppliers;
        var currentRow = spreadsheetData['data'][row];
        var isLowestPrice = true;
        var currentPrice = currentRow[prop] ? parseFloat(currentRow[prop]) : false;
        if (suppliers && suppliers.length > 1 && currentPrice) {
            for (var i = 0; i < suppliers.length; i++) {
                var supplierName = suppliers[i].name;
                var supplierPrice = currentRow[supplierName] ? parseFloat(currentRow[supplierName]) : false;
                if (supplierPrice && supplierName != prop) {
                    if (supplierPrice < currentPrice) {
                        isLowestPrice = false;
                        break;
                    }
                }
            }
        }
        if (!currentPrice) {
            isLowestPrice = false;
        }
        return isLowestPrice;
    };
    ProposalTemplatePricingSectionService.prototype.updateSpreadSheetData = function (hotId, data) {
        var instanceIndex = this.spreadsheetData.findIndex(function (i) { return i.id == hotId; });
        var updatedData = this.updateSupplierPrices(data);
        if (instanceIndex != -1) {
            this.spreadsheetData[instanceIndex]['data'] = updatedData;
            var hotInstance = this.hotInstances.find(function (instance) { return instance.id == hotId; });
            if (hotInstance && hotInstance.hasOwnProperty('instance')) {
                hotInstance['instance'].render();
            }
        }
    };
    // Settings data in handsontable and configuration
    ProposalTemplatePricingSectionService.prototype.populateData = function (hotId, data, listIndex, sectionIndex, sectionId, listId, suppliers, type) {
        var _this = this;
        this.supplierDataList[hotId] = suppliers;
        var hotInstance = this.hotInstances.find(function (instance) { return instance.id == hotId; });
        if (hotInstance) {
            var instance = hotInstance['instance'];
            var columnsData_1 = [];
            var colwidth = [];
            if (hotId.startsWith('pricing-section-partsId') || hotId.startsWith('system-settings-pricing-section-partsId')) {
                data = this.updateSupplierPrices(data);
                var readOnly_1 = hotId.startsWith('system-settings-pricing-section-partsId');
                var suppliersList = [];
                var additionalColumnWidth_1 = [];
                for (var i = 0; i < suppliers.length; i++) {
                    suppliersList.push({
                        title: suppliers[i]['name'],
                        data: suppliers[i]['name'],
                        allowEmpty: true,
                        type: 'numeric',
                        readOnly: readOnly_1,
                        validator: 'numericValidator',
                        /*numericFormat: {
                            pattern: '0,0.00 $'
                        },*/
                        renderer: function (instance, td, row, col, prop, value, cellProperties) {
                            cellProperties.isSupplier = true;
                            cellProperties.className = '';
                            if (readOnly_1) {
                                value = row == instance.countRows() ? '' : 'N/A';
                                additionalColumnWidth_1.push('145px');
                                cellProperties.readOnlyCellClassName = '';
                                cellProperties.className = 'calculate-class-supplier';
                                Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                if (value == 'N/A') {
                                    td.title = 'Cost price cannot be set on templates as prices will vary from suppliers';
                                }
                            }
                            else {
                                var supplier = _this.supplierDataList[instance.hotId].find(function (i) { return i.name == prop; });
                                var showSelected = false;
                                var selectedParts_2 = instance.getCopyableData(row, 0);
                                _this.selectedPartsData = _this.partArray.find(function (i) { return i.id == selectedParts_2; });
                                if (_this.selectedPartsData) {
                                    if (_this.selectedPartsData['suppliersData'].length != 0) {
                                        var selectedSupplier = _this.selectedPartsData['suppliersData'].find(function (i) { return i.supplierName == prop; });
                                        if (selectedSupplier) {
                                            if (value) {
                                                value = parseFloat(value).toFixed(2);
                                            }
                                            else {
                                                value = parseFloat(selectedSupplier.supplierPrice).toFixed(2);
                                            }
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1 && _this.selectedColumn == prop) {
                                                _this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                                _this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                    "supplierCatalogId": selectedSupplier.supplierCatalogId,
                                                    "price": value,
                                                    "supplierId": supplier.id,
                                                    'selectedSupplier': prop
                                                };
                                            }
                                            else {
                                                if (_this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                    if (_this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != _this.selectedColumn) {
                                                        delete _this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                    }
                                                }
                                            }
                                            var SupplierClass = void 0;
                                            if (_this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                                                SupplierClass = "pricing-selected-bg";
                                                showSelected = true;
                                            }
                                            else {
                                                SupplierClass = "calculate-class-supplier";
                                            }
                                            /* if (selectedSupplier.priceSelected) {
                                                 SupplierClass = "pricing-selected-bg"
                                                 showSelected = true
                                             } else {
                                                 SupplierClass = "calculate-class-supplier"
                                             }*/
                                            var isLowerPrice = false;
                                            if (selectedIndex != -1) {
                                                isLowerPrice = _this.checkGivenSupplierCostIsLow(_this.spreadsheetData[selectedIndex], prop, row);
                                            }
                                            if (isLowerPrice) {
                                                cellProperties.className = "ss-down-arrow";
                                            }
                                            if (_this.spreadsheetData[selectedIndex]['data'][row] && value && !_this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty(prop)) {
                                                _this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                            }
                                            cellProperties.className = SupplierClass + ' ' + cellProperties.className;
                                        }
                                        else {
                                            cellProperties.className = "calculate-class-supplier";
                                            if (value) {
                                                value = parseFloat(value).toFixed(2);
                                                var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                                if (selectedIndex != -1 && _this.selectedColumn == prop) {
                                                    _this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                                    _this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                        "supplierCatalogId": null,
                                                        "price": value,
                                                        "supplierId": supplier.id,
                                                        'selectedSupplier': prop
                                                    };
                                                }
                                                else {
                                                    if (_this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                        if (_this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != _this.selectedColumn) {
                                                            delete _this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        cellProperties.className = "calculate-class-supplier";
                                        if (value) {
                                            value = parseFloat(value).toFixed(2);
                                            var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                            if (selectedIndex != -1 && _this.selectedColumn == prop) {
                                                _this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                                _this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                    "supplierId": supplier.id,
                                                    "supplierCatalogId": null,
                                                    "price": value,
                                                    'selectedSupplier': prop
                                                };
                                            }
                                            else {
                                                if (_this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                    if (_this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != _this.selectedColumn) {
                                                        delete _this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    cellProperties.className = "calculate-class-supplier";
                                    if (value) {
                                        value = parseFloat(value).toFixed(2);
                                        var selectedIndex = _this.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
                                        if (selectedIndex != -1 && _this.selectedColumn == prop) {
                                            _this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                            _this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                "supplierId": supplier.id,
                                                "supplierCatalogId": null,
                                                "price": value,
                                                'selectedSupplier': prop
                                            };
                                        }
                                        else {
                                            if (_this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                if (_this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != _this.selectedColumn) {
                                                    delete _this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (value && value != '' && !cellProperties.hasOwnProperty('valid')) {
                                    cellProperties.valid = true;
                                }
                                Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                                if (td.innerHTML) {
                                    var new_value = "£" + td.innerHTML;
                                    td.innerHTML = "";
                                    var label = document.createElement('label');
                                    label.className = 'checkbox-button';
                                    label.style.cursor = 'pointer';
                                    var inputElement = document.createElement('input');
                                    inputElement.type = "checkbox";
                                    inputElement.name = prop + row + col;
                                    inputElement.id = prop + row + col;
                                    if (showSelected) {
                                        inputElement.checked = true;
                                    }
                                    if (inputElement.checked) {
                                        inputElement.disabled = true;
                                    }
                                    inputElement.className = "checkbox-button__input";
                                    Handsontable.dom.addEvent(inputElement, 'click', function ($event) {
                                        _this.selectPrice($event, instance, row, col);
                                    });
                                    label.appendChild(inputElement);
                                    var content = document.createElement('span');
                                    content.className = "checkbox-button__control";
                                    label.appendChild(content);
                                    var priceValue = document.createElement('span');
                                    priceValue.innerText = new_value;
                                    label.appendChild(priceValue);
                                    td.appendChild(label);
                                }
                                if (_this.selectedPartsData) {
                                    if (_this.selectedPartsData['suppliersData'].length != 0) {
                                        var selectedSupplier = _this.selectedPartsData['suppliersData'].find(function (i) { return i.supplierName == prop; });
                                        if (selectedSupplier) {
                                            var recentUpdatedClass = void 0;
                                            var content = document.createElement('span');
                                            if (selectedSupplier.lastUpdate) {
                                                recentUpdatedClass = 'latest-updated';
                                                if (selectedSupplier['lastmodifieddatetime']) {
                                                    var testDateUtc = moment.utc(selectedSupplier['lastmodifieddatetime']['date']);
                                                    var localDate = testDateUtc.local();
                                                    var date = moment(localDate).format('dddd Do MMM YYYY (H:mm)');
                                                    content.title = date;
                                                }
                                            }
                                            else {
                                                recentUpdatedClass = 'not-recent-updated';
                                            }
                                            content.className = "updated-supplier-status" + ' ' + recentUpdatedClass;
                                            td.appendChild(content);
                                        }
                                    }
                                }
                            }
                        },
                    });
                    if (hotId.startsWith('pricing-section-partsId')) {
                        additionalColumnWidth_1.push('145px');
                    }
                    else if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                        additionalColumnWidth_1.push('145px');
                    }
                }
                var widthColumns = void 0;
                if (hotId.startsWith('pricing-section-partsId')) {
                    this.originalPartColumn = this.partsColumn;
                    columnsData_1 = this.partsColumn.slice(0, 4).concat(suppliersList, this.partsColumn.slice(4));
                    widthColumns = ['450px', '100px', '100px', '135px', '145px', '145px', '145px', '145px', '145px', '145px', '145px', '125px', '175px', '60px', '60px'];
                }
                else if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                    this.originalPartColumn = this.systemSettingPartsColumn;
                    columnsData_1 = this.systemSettingPartsColumn.slice(0, 2).concat(suppliersList, this.systemSettingPartsColumn.slice(2));
                    widthColumns = ['450px', '100px', '145px', '175px', '60px', '60px'];
                }
                if (suppliers.length != 0) {
                    if (hotId.startsWith('pricing-section-partsId')) {
                        colwidth = widthColumns.slice(0, 4).concat(additionalColumnWidth_1, widthColumns.slice(4));
                    }
                    else if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                        colwidth = widthColumns.slice(0, 2).concat(additionalColumnWidth_1, widthColumns.slice(2));
                    }
                }
                else {
                    colwidth = widthColumns;
                }
            }
            else if (hotId.startsWith('pricing-section-labourId')) {
                columnsData_1 = this.labourColumn;
                colwidth = [40, 15, 12, 15, 15, 15, 20, 12, 20, 15, 10, 12];
            }
            else if (hotId.startsWith('pricing-section-expensesId')) {
                columnsData_1 = this.expensesColumn;
                colwidth = ['250px', '145px', '145px', '145px', '145px', '145px', '145px', '100px'];
                /*if (!this.vatEnabled) {
                    colwidth.splice(4, 1);
                }*/
                if (!this.vatEnabled && (this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel)) {
                    this.expensesColumn.splice(4, 1); // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
                    colwidth.splice(4, 1);
                    this.updateCols();
                }
                //colwidth = [50, 50, 25, 25, 20, 15, 15, 15];
            }
            else if (hotId.startsWith('system-settings-pricing-section-labourId')) {
                columnsData_1 = this.systemSettingsLabourColum;
                colwidth = [80, 30, 20, 25, 25, 25, 35, 20, 20, 20];
            }
            else if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
                columnsData_1 = this.expensesColumn; //systemSettingsexpensesColumn
                colwidth = ['250px', '145px', '145px', '145px', '145px', '145px', '145px', '105px']; //[50, 50, 25, 25, 15]
                /*if (!this.vatEnabled) {
                    colwidth.splice(4, 1);
                }*/
                if (!this.vatEnabled && (this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel)) {
                    this.expensesColumn.splice(4, 1); // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
                    colwidth.splice(4, 1);
                    this.updateCols();
                }
            }
            if (this.partsKitSelection) {
                this.spreadsheetData.splice(this.selectedHotIndex, 0, {
                    'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                    'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                });
            }
            else {
                if (type) {
                    if (type == 'edit') {
                        var instanceIndex = this.spreadsheetData.findIndex(function (i) { return i.id == hotId; });
                        if (instanceIndex != -1) {
                            this.spreadsheetData[instanceIndex]['suppliers'] = suppliers;
                        }
                    }
                    else {
                        var instanceIndex = this.spreadsheetData.findIndex(function (i) { return i.id == hotId; });
                        var dataToUpdate = {
                            'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                            'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                        };
                        if (instanceIndex != -1) {
                            this.spreadsheetData[instanceIndex] = dataToUpdate;
                        }
                        else {
                            this.spreadsheetData.push(dataToUpdate);
                        }
                    }
                }
                else {
                    var instanceIndex = this.spreadsheetData.findIndex(function (i) { return i.id == hotId; });
                    var dataToUpdate = {
                        'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                        'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                    };
                    if (instanceIndex != -1) {
                        this.spreadsheetData[instanceIndex] = dataToUpdate;
                    }
                    else {
                        this.spreadsheetData.push(dataToUpdate);
                    }
                }
            }
            if (this.viewMode) {
                var colCount = columnsData_1.length;
                var lastCol = columnsData_1[colCount - 1];
                if (lastCol.title == 'Actions') {
                    columnsData_1.splice(colCount - 2);
                }
            }
            if (type == 'add' || hotId.startsWith('pricing-section-labourId') || hotId.startsWith('pricing-section-expensesId') || hotId.startsWith('system-settings-pricing-section-labourId')
                || hotId.startsWith('system-settings-pricing-section-expensesId')) {
                var component = this;
                this.baseSettings = {
                    preventOverflow: 'vertical',
                    colHeaders: true,
                    // rowHeaders: function (row) {
                    //     let icon;
                    //     switch (row) {
                    //         case row: icon = '<i class="fas fa-bars" style="margin-left:-4px"></i>'; break;
                    //     }
                    //     return icon;
                    // },
                    rowHeaders: false,
                    viewportRowRenderingOffset: 1000,
                    stretchH: 'all',
                    copyPaste: false,
                    afterGetColHeader: this.afterGetColHeader,
                    tabMoves: { row: 0, col: 1 },
                    colWidths: colwidth,
                    enterMoves: { row: 0, col: 1 },
                    manualRowMove: true,
                    //manualColumnMove: true,
                    data: data,
                    columns: columnsData_1,
                    mergeCells: true,
                    fillHandle: {
                        // enable plugin in vertical direction and with autoInsertRow as false
                        autoInsertRow: false,
                        direction: 'vertical'
                    },
                    afterBeginEditing: function (row, column) {
                        var spreadSheetData = component.getSpreadSheetData(component.selectedTableInstance);
                        component.selectedRowRef = spreadSheetData && spreadSheetData.length ? spreadSheetData[row] : false;
                        component.selectedPricingData = component.spreadsheetData.find(function (data) { return data.id == hotId; });
                        if (component.selectedRowRef.id != null) {
                            var columnTitle = columnsData_1[column]['data'];
                            component.editedDataAdded.push({
                                'row': row,
                                'col': columnTitle,
                                'id': component.selectedRowRef.id,
                                'instance': component.selectedTableInstance,
                                'sectionIndex': component.selectedPricingData['sectionIndex'],
                                'listId': component.selectedPricingData['listId'],
                                'sectionId': component.selectedPricingData['sectionId']
                            });
                        }
                    },
                    afterOnCellCornerMouseDown: function () {
                        var lastSelectedElement = this.getSelectedLast();
                        var autoFillPlugin = this.getPlugin('autofill');
                        if (lastSelectedElement && lastSelectedElement.length > 1) {
                            var cellMetaData = this.getCellMeta(lastSelectedElement[0], lastSelectedElement[1]);
                            if ((cellMetaData.prop == 'partsAndPricesId' || cellMetaData.readOnly) && autoFillPlugin.enabled) {
                                autoFillPlugin.disablePlugin();
                            }
                            else if (!autoFillPlugin || autoFillPlugin && !autoFillPlugin.enabled) {
                                autoFillPlugin.enablePlugin();
                            }
                        }
                    },
                    afterRowMove: function (rows, fId, dId, movePossible, orderChanged) {
                        component.selectedTableInstance = this.getInstance().hotId;
                        component.reorderRow(this, rows, fId);
                    },
                    beforeOnCellMouseDown: function (col, TH, e, controller) {
                        component.selectedTableInstance = this.getInstance().hotId;
                        component.selectedColumn = this.getColHeader()[TH.col];
                        var spreadSheetData = component.getSpreadSheetData(component.selectedTableInstance);
                        component.selectedRowRef = spreadSheetData && spreadSheetData.length ? spreadSheetData[TH.row] : false;
                        if (col.target.colSpan) {
                            if (col.target.colSpan != 1) {
                                col.stopImmediatePropagation();
                            }
                        }
                        /*let autoFillPlugin = this.getPlugin('autofill');
                        if(autoFillPlugin.enabled) {
                            autoFillPlugin.enablePlugin();
                        }*/
                    },
                    beforeKeyDown: function (e) {
                        if (['Backspace', 'Enter', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'].indexOf(e.code) === -1) {
                            var row = this.getSelectedLast()[0];
                            var col = this.getSelectedLast()[1];
                            var type_3 = this.getDataType(row, col, row, col);
                            if (type_3 == 'numeric' && e.target.value && e.target.value != '') {
                                if (this.getInstance()['hotId'].startsWith('pricing-section-partsId')) {
                                    var selectedField_1 = this.getColHeader()[col];
                                    var selectedFieldIndex = component.partsColumn.findIndex(function (i) { return i.title == selectedField_1; });
                                    if (selectedFieldIndex != -1) {
                                        component.commonService.allowDecimalInput(e, 10, 2);
                                    }
                                    else {
                                        component.commonService.allowDecimalInput(e, 10, 4);
                                    }
                                }
                                else {
                                    component.commonService.allowDecimalInput(e, 10, 2);
                                }
                            }
                        }
                        /*let autoFillPlugin = this.getPlugin('autofill');
                        if(autoFillPlugin.enabled) {
                            autoFillPlugin.enablePlugin();
                        }*/
                    }
                };
                instance.updateSettings(this.baseSettings);
                instance.render();
                this.partsKitSelection = false;
            }
            else {
                var instanceIndex = this.spreadsheetData.findIndex(function (i) { return i.id == hotId; });
                if (instanceIndex != -1) {
                    if (hotId.startsWith('pricing-section-partsId')) {
                        instance.updateSettings({
                            columns: columnsData_1,
                            colWidths: colwidth,
                            mergeCells: [
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 + suppliers.length },
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 11 + suppliers.length, rowspan: 1, colspan: 4 }
                            ]
                        });
                    }
                    else if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                        instance.updateSettings({
                            columns: columnsData_1,
                            colWidths: colwidth,
                        });
                    }
                    if (type == 'update') {
                        this.spreadsheetData[instanceIndex]['data'] = data;
                        instance.updateSettings({
                            data: data
                        });
                    }
                    instance.render();
                }
            }
        }
    };
    // Initialising hot instance
    ProposalTemplatePricingSectionService.prototype.afterInit = function (hotId, hotInstance) {
        this.hotId = hotId;
        this.hotInstances.push({ 'id': this.hotId, 'instance': hotInstance });
    };
    ProposalTemplatePricingSectionService.prototype.Spreadsheetdata = function (data) {
        if (data.hasOwnProperty('parts')) {
            this.partArray = data.parts;
        }
        if (data.hasOwnProperty('suppliers')) {
            this.supplierList = data.suppliers;
        }
        if (data.hasOwnProperty('labourRates')) {
            this.labourRates = data.labourRates;
        }
        if (data.hasOwnProperty('vatRates')) {
            this.vatRates = data.vatRates;
            this.taxLabel = data['taxLabel'];
            this.vatEnabled = data['vatEnabled'];
            if (this.vatEnabled) {
                this.setTaxEditor(data.vatRates, this.taxLabel);
            }
            else if (this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel) {
                this.expensesColumn.splice(4, 1); // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
                this.updateCols();
            }
        }
        if (data.hasOwnProperty('data')) {
            this.nominalCode = data.data;
        }
    };
    // Emitting upsell data
    ProposalTemplatePricingSectionService.prototype.Upsell = function (instance, row, col, type) {
        if (type === void 0) { type = 'add'; }
        var selectedInstance = this.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
        if (selectedInstance) {
            if (Object.keys(selectedInstance).length != 0) {
                var selectedValue = selectedInstance['data'];
                if (row == (selectedValue.length - 1))
                    return;
                var targetRow = selectedValue[row];
                this.emitUpsellEvent({
                    'data': targetRow, 'sectionIndex': selectedInstance['sectionIndex'],
                    'listIndex': selectedInstance['listIndex'], 'rowId': row,
                    'type': type
                });
            }
        }
    };
    ProposalTemplatePricingSectionService.prototype.UpdateSupplierPrices = function (newValue, row, instance) {
        if (this.suppliers && this.suppliers.length) {
            for (var i = 0; i < this.suppliers.length; i++) {
                var supplier = this.suppliers[i];
                var supplierPrice = this.getSupplierPrice(parseInt(newValue), supplier.id);
                instance.setDataAtRowProp(row, supplier.name, supplierPrice, 'cs_internal');
            }
        }
    };
    ProposalTemplatePricingSectionService.prototype.AfterCustomerPartChanged = function (oldValue, newValue, row, instance) {
        if (oldValue == newValue) {
            return false;
        }
        this.UpdateSupplierPrices(newValue, row, instance);
        var part = this.partArray.find(function (part) { return part.id == newValue; });
        var quantity = 1;
        if (part) {
            var unitSalePrice = parseFloat(part['unitSalePrice']);
            var totalCost = this.getTotalCost(part['price'], quantity);
            var totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
            var finalSalePrice = this.getFinalSalePrice(totalSalePrice, 0);
            var profit = this.getProfit(finalSalePrice, totalCost);
            var margin = this.getMargin(profit, finalSalePrice);
            this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
            this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
            this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
            this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
            this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);
            this.hotData[row]['quantity'] = this.convertAndUpdatePrice(quantity);
            this.hotData[row]['markup'] = this.convertAndUpdatePrice(part['markup']);
            this.hotData[row]['generalPrice'] = this.convertAndUpdatePrice(part['price']);
            this.hotData[row]['labourTime'] = this.convertAndUpdatePrice(part['installtime']);
            if (!this.hotData[row]['id']) {
                this.hotData[row]['selectedPriceCol'] = 'generalPrice';
            }
            /*instance.setDataAtRowProp(row, "markup", part['markup'], 'cs_internal');
                instance.setDataAtRowProp(row, "unitSalePrice", part['unitSalePrice'], 'cs_internal');
                instance.setDataAtRowProp(row, "generalPrice", part['price'], 'cs_internal');
                instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "quantity", quantity, 'cs_internal');*/
            //this.updateSpreadsheetData(instance.hotId, row, 'selectedPriceCol', 'generalPrice');
        }
    };
    ProposalTemplatePricingSectionService.prototype.AfterCustomerMarkupChanged = function (oldValue, newValue, row, instance) {
        if (oldValue == newValue) {
            return false;
        }
        var hotData = this.hotData[row];
        var quantity = hotData['quantity'];
        var selectedCol = hotData.hasOwnProperty('selectedPriceCol') ? hotData['selectedPriceCol'] : 'generalPrice';
        var selectedPrice = hotData[selectedCol];
        var unitSalePrice = this.getUnitSalePrice(selectedPrice, newValue);
        var totalCost = this.getTotalCost(selectedPrice, quantity);
        var totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
        var finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
        var profit = this.getProfit(finalSalePrice, totalCost);
        var margin = this.getMargin(profit, finalSalePrice);
        this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
        this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
        this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
        this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
        this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
        this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);
        instance.setDataAtRowProp(row, "unitSalePrice", unitSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');
    };
    ProposalTemplatePricingSectionService.prototype.AfterCustomerUnitPriceChanged = function (oldValue, newValue, row, instance) {
        if (oldValue == newValue) {
            return false;
        }
        var hotData = this.hotData[row];
        var quantity = hotData['quantity'];
        var selectedCol = hotData.hasOwnProperty('selectedPriceCol') ? hotData['selectedPriceCol'] : 'generalPrice';
        var selectedPrice = hotData[selectedCol];
        var unitSalePrice = !isNaN(parseFloat(newValue)) ? parseFloat(newValue) : 0;
        var totalCost = this.getTotalCost(selectedPrice, quantity);
        var totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
        var finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
        var profit = this.getProfit(finalSalePrice, totalCost);
        var margin = this.getMargin(profit, finalSalePrice);
        var markup = this.getMarkupFromUnitSalePrice(selectedPrice, unitSalePrice);
        if (markup != 'NC') {
            var markupToShow = markup == 'N/A' ? markup : parseFloat(markup).toFixed(2);
            instance.setDataAtRowProp(row, "markup", markupToShow, 'cs_internal');
        }
        this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
        this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
        this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
        this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);
        /*instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');*/
    };
    ProposalTemplatePricingSectionService.prototype.convertAndUpdatePrice = function (price) {
        // To handle null values
        if (price == null) {
            return "";
        }
        else if (price == 0) {
            return 0.00;
        }
        else if (!price) {
            return "";
        }
        // To handle Number / NA values
        var newPrice = parseFloat(price);
        if (isNaN(newPrice)) {
            return price;
        }
        return newPrice.toFixed(2);
    };
    ProposalTemplatePricingSectionService.prototype.AfterSettingsPartChanged = function (oldValue, newValue, row, instance) {
        if (oldValue == newValue) {
            return false;
        }
        this.UpdateSupplierPrices(newValue, row, instance);
        var part = this.partArray.find(function (part) { return part.id == newValue; });
        if (part) {
            var unitPrice = this.convertAndUpdatePrice(part['price']);
            var totalCost = this.convertAndUpdatePrice(this.getTotalCost(part['price'], this.hotData[row]['quantity']));
            this.hotData[row]['costPrice'] = unitPrice;
            this.hotData[row]['totalCost'] = totalCost;
            this.hotData[row]['labourTime'] = this.convertAndUpdatePrice(part['installtime']);
            if (!this.hotData[row]['id']) {
                this.hotData[row]['selectedPriceCol'] = 'costPrice';
            }
            //instance.setDataAtRowProp(row, "unitPrice", part['unitPrice'], 'cs_internal');
        }
    };
    ProposalTemplatePricingSectionService.prototype.getMarkupFromUnitSalePrice = function (unitCost, unitSalePrice) {
        var markup = 'N/A';
        var cost = parseFloat(unitCost);
        var price = parseFloat(unitSalePrice);
        cost = isNaN(cost) ? 0 : cost;
        price = isNaN(price) ? 0 : price;
        if (cost > 0) {
            var markupCalculated = ((price - cost) / cost) * 100;
            if (markupCalculated == parseFloat(markupCalculated.toFixed(2))) {
                markup = markupCalculated.toString();
            }
        }
        else {
            markup = 'NC';
        }
        return markup;
    };
    ProposalTemplatePricingSectionService.prototype.getUnitSalePrice = function (cost, markup) {
        var unitCost = parseFloat(cost);
        var markupGiven = parseFloat(markup);
        markupGiven = isNaN(markupGiven) ? 0 : markupGiven;
        unitCost = isNaN(unitCost) ? 0 : unitCost;
        return unitCost + (unitCost * (markupGiven / 100));
    };
    ProposalTemplatePricingSectionService.prototype.getTotalCost = function (unitCost, quantity) {
        var cost = parseFloat(unitCost);
        var quantityConverted = parseFloat(quantity);
        cost = isNaN(cost) ? 0 : cost;
        quantityConverted = isNaN(quantityConverted) ? 0 : quantityConverted;
        return cost * quantityConverted;
    };
    ProposalTemplatePricingSectionService.prototype.getFinalSalePrice = function (totalSalePrice, discount) {
        var total = parseFloat(totalSalePrice);
        var discountAmount = parseFloat(discount);
        total = isNaN(total) ? 0 : total;
        discountAmount = isNaN(discountAmount) ? 0 : discountAmount;
        return total - discountAmount;
    };
    ProposalTemplatePricingSectionService.prototype.getMargin = function (profit, totalSalePrice) {
        var profitAmount = parseFloat(profit);
        var price = parseFloat(totalSalePrice);
        price = isNaN(price) ? 0 : price;
        profitAmount = isNaN(profitAmount) ? 0 : profitAmount;
        return price != 0 ? (profitAmount / price) * 100 : 0;
    };
    ProposalTemplatePricingSectionService.prototype.getProfit = function (totalSalePrice, totalCost) {
        var price = parseFloat(totalSalePrice);
        var cost = parseFloat(totalCost);
        price = isNaN(price) ? 0 : price;
        cost = isNaN(cost) ? 0 : cost;
        return price - cost;
    };
    ProposalTemplatePricingSectionService.prototype.AfterSelectedSupplierValueChanged = function (oldValue, newValue, row, instance, isFromSettings) {
        if (oldValue == newValue) {
            return false;
        }
        var hotData = this.hotData[row];
        var quantity = hotData['quantity'];
        if (isFromSettings) {
            var totalCost = this.getTotalCost(newValue, quantity).toFixed(2);
            //instance.setDataAtRowProp(row, "totalCost", totalCost, 'cs_internal');
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
        }
        else {
            var unitSalePrice = this.getUnitSalePrice(newValue, hotData['markup']);
            var totalCost = this.getTotalCost(newValue, quantity);
            var totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
            var finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
            var profit = this.getProfit(finalSalePrice, totalCost);
            var margin = this.getMargin(profit, finalSalePrice);
            this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
            this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
            this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
            this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
            this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);
            /*instance.setDataAtRowProp(row, "unitSalePrice", unitSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');*/
        }
    };
    // Invoke on changes
    ProposalTemplatePricingSectionService.prototype.afterChange = function (changes, source, hotId) {
        if (source == 'cs_internal') {
            return;
        }
        var instance = this.hotInstances.find(function (instance) { return instance.id == hotId; });
        if (instance) {
            var current_instance = instance['instance'];
            if (source != 'populateFromArray' && changes && changes[0]) {
                var changeObj = changes[0];
                var row = changeObj[0];
                var oldValue = changeObj[2];
                var newValue = changeObj[3];
                if (oldValue != newValue) {
                    var i = this.spreadsheetData
                        .findIndex(function (data) { return data.id == hotId; });
                    if (hotId.startsWith('pricing-section-expensesId') ||
                        hotId.startsWith('system-settings-pricing-section-expensesId')) {
                        switch (changeObj[1]) {
                            case 'supplierId':
                                if (i !== -1) {
                                    // set nominal code id on supplier selection
                                    var lineItem_1 = this.spreadsheetData[i]['data'][row];
                                    var selectedSupplier = this.supplierList.find(function (item) { return item.id == lineItem_1['supplierId']; });
                                    if (selectedSupplier && selectedSupplier['nominalCode']) {
                                        if (selectedSupplier['nominalCode']['id']) {
                                            lineItem_1['settingsNominalCodeId'] = selectedSupplier['nominalCode']['id'];
                                        }
                                        else {
                                            lineItem_1['settingsNominalCodeId'] = null;
                                        }
                                    }
                                }
                                current_instance.render();
                        }
                    }
                }
            }
            if (this.spreadsheetData.length != 0) {
                this.selectedPricingData = this.spreadsheetData.find(function (data) { return data.id == hotId; });
                if (this.selectedPricingData) {
                    if (Object.keys(this.selectedPricingData).length != 0) {
                        this.hotData = this.selectedPricingData['data'];
                    }
                    if (this.selectedPricingData.hasOwnProperty('suppliers')) {
                        this.suppliers = this.selectedPricingData['suppliers'];
                    }
                    // Execute on first time load
                    if (source === 'loadData') {
                        if (hotId.startsWith('pricing-section-labourId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: this.hotData.length - 1, col: 10, rowspan: 1, colspan: 2 }
                                ],
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-labourId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: this.hotData.length - 1, col: 8, rowspan: 1, colspan: 2 }
                                ],
                            });
                        }
                        if (hotId.startsWith('pricing-section-expensesId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                                    { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                                ]
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                                    { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                                ]
                                /*mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 }
                                ]*/
                            });
                        }
                        if (hotId.startsWith('pricing-section-partsId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                                    { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                                ]
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                            current_instance.alter('insert_row', this.hotData.length, 1);
                            current_instance.updateSettings({
                            /*mergeCells: [
                                { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                                { row: this.hotData.length - 1, col: 4 + this.suppliers.length, rowspan: 1, colspan: 4 }
                            ],*/
                            });
                        }
                    }
                    // Execute on edit & Execute on auto fill
                    if (source === 'edit' || source == 'Autofill.fill') {
                        //let isFromEdit = source === 'edit';
                        for (var changeIndex = 0; changeIndex < changes.length; changeIndex++) {
                            var currentChange = changes[changeIndex];
                            this.handleItemsSaveAction(currentChange, hotId, current_instance);
                        }
                    }
                }
            }
        }
    };
    ProposalTemplatePricingSectionService.prototype.handleItemsSaveAction = function (change, hotId, current_instance) {
        var rowId = change[0], colName = change[1], oldValue = change[2], currentValue = change[3];
        var isSettingPart = false;
        //last row - insert new row
        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                        { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                    ]
                });
            }
        }
        if (hotId.startsWith('pricing-section-partsId')) {
            if (colName == 'partsAndPricesId') {
                this.AfterCustomerPartChanged(oldValue, currentValue, rowId, current_instance);
            }
            if (colName == 'markup') {
                this.AfterCustomerMarkupChanged(oldValue, currentValue, rowId, current_instance);
            }
            if (colName == 'unitSalePrice') {
                this.AfterCustomerUnitPriceChanged(oldValue, currentValue, rowId, current_instance);
            }
        }
        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
            isSettingPart = true;
            if (colName == 'partsAndPricesId') {
                this.AfterSettingsPartChanged(oldValue, currentValue, rowId, current_instance);
            }
        }
        var updatedCol = current_instance.propToCol(colName);
        var cellMetaData = current_instance.getCellMeta(rowId, updatedCol);
        if (cellMetaData.hasOwnProperty("isSupplier") && cellMetaData.hasOwnProperty('selectedUnitPrice') && cellMetaData.selectedUnitPrice) {
            this.AfterSelectedSupplierValueChanged(oldValue, currentValue, rowId, current_instance, isSettingPart);
        }
        if (this.hotData.length === rowId + 1 && hotId.startsWith('system-settings-pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                /*mergeCells: [
                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                    { row: this.hotData.length - 1, col: 4 + this.suppliers.length , rowspan: 1, colspan: 4 }
                ]*/
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-labourId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['totalCost'] != null && this.hotData[rowId]['totalSalePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                        { row: this.hotData.length - 1, col: 10, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('system-settings-pricing-section-labourId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /* && this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['totalCost'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                        { row: this.hotData.length - 1, col: 8, rowspan: 1, colspan: 2 }
                        // { row: this.hotData.length - 1, col: 6 + this.suppliers.length, rowspan: 1, colspan: 9 }
                    ]
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /* && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                        { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('system-settings-pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                // && this.hotData[rowId]['costPrice'] != null
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    /*mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 }
                    ]*/
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                        { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }
        // auto save cutomer parts
        if (hotId.startsWith('pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity'] && (oldValue != currentValue || change[1] == "totalCost")) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].listIndex = this.selectedPricingData['listIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
        // auto save parts(option and proposal template)
        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
            // && (oldValue != currentValue)
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].listIndex = this.selectedPricingData['listIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
        // auto save labour(option and proposal template)
        if (hotId.startsWith('pricing-section-labourId')) {
            /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['totalCost'] != null && this.hotData[rowId]['totalSalePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null */
            if (this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
        // auto save cutomer labour
        if (hotId.startsWith('system-settings-pricing-section-labourId')) {
            /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null
                && this.hotData[rowId]['totalCost'] != null*/
            if (this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
        // auto save expenses(option and proposal template)
        if (hotId.startsWith('pricing-section-expensesId')) {
            /*&& this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null */
            if (this.hotData[rowId]['description'] != '' && this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                if (this.hotData[rowId]['vatRate'] == null || this.hotData[rowId]['vatRate'] == '') {
                    this.hotData[rowId]['vatRate'] = 3; //Todo: Default vat rate fix required
                }
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
        // Auto save customer expenses
        if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != '' && this.hotData[rowId]['description'] != null && this.hotData[rowId]['costPrice'] != null && oldValue != currentValue) {
                if (this.hotData[rowId]['vatRate'] == null || this.hotData[rowId]['vatRate'] == '') {
                    this.hotData[rowId]['vatRate'] = 3; //Todo: Default vat rate fix required
                }
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex'];
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId'];
                this.hotData[rowId].listId = this.selectedPricingData['listId'];
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }
    };
    // Row delete for parts,labour and expenses
    ProposalTemplatePricingSectionService.prototype.onRowDelete = function (instance, row, deleteKey) {
        var that = this;
        that.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', that.ajsScope)
            .then(function () {
            var hotinstance = that.hotInstances.find(function (hotIns) { return hotIns.id == instance.hotId; })['instance'];
            var selectedInstance = that.spreadsheetData.find(function (data) { return data.id == instance['hotId']; });
            var indexValue = that.spreadsheetData.findIndex(function (data) { return data.id == instance['hotId']; });
            if (selectedInstance) {
                if (Object.keys(selectedInstance).length != 0) {
                    if (selectedInstance.hasOwnProperty('suppliers')) {
                        that.suppliers = selectedInstance['suppliers'];
                    }
                    var selectedValue = selectedInstance['data'];
                    if (row == (selectedValue.length - 1))
                        return;
                    var targetRow_1 = selectedValue[row];
                    targetRow_1['sectionIndex'] = selectedInstance['sectionIndex'];
                    targetRow_1['listIndex'] = selectedInstance['listIndex'];
                    if (that.spreadsheetData[indexValue]['data'].length != 0) {
                        //Todo: Need to find better solution instead of checking < 4
                        if (targetRow_1.hasOwnProperty('id') && targetRow_1['id'] > 0) {
                            var pricingIndex = that.spreadsheetData[indexValue]['data'].findIndex(function (item) { return item.id == targetRow_1['id']; });
                            if (pricingIndex != -1) {
                                that.spreadsheetData[indexValue]['data'].pop();
                            }
                        }
                    }
                    hotinstance.alter('remove_row', row);
                    hotinstance.render();
                    that.selectedPricingData = that.spreadsheetData.find(function (data) { return data.id == instance.hotId; });
                    if (deleteKey == 'expenses') {
                        hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                            mergeCells: [
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 },
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 5, rowspan: 1, colspan: 2 }
                            ]
                        });
                    }
                    if (deleteKey == 'labour') {
                        hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                            mergeCells: [
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 },
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 10, rowspan: 1, colspan: 2 }
                            ]
                        });
                    }
                    if (deleteKey == "parts") {
                        hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                            mergeCells: [
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 + that.suppliers.length },
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 11 + that.suppliers.length, rowspan: 1, colspan: 4 }
                            ]
                        });
                    }
                    if (deleteKey == 'systemSettingsParts') {
                        //hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                        /*mergeCells: [
                            { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 + that.suppliers.length },
                            { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 4 + that.suppliers.length , rowspan: 1, colspan: 4 }
                        ],*/
                        });
                    }
                    if (deleteKey == 'systemSettingsLabour') {
                        hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                            mergeCells: [
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 },
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 8, rowspan: 1, colspan: 2 }
                            ],
                        });
                    }
                    if (deleteKey == 'systemSettingsExpenses') {
                        hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                        hotinstance.updateSettings({
                            mergeCells: [
                                { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 }
                            ]
                        });
                    }
                    if (targetRow_1) {
                        that.emitdeleteChangeEvent({ 'data': targetRow_1, 'rowId': row });
                    }
                }
            }
        }, function () {
            return false;
        });
    };
    ProposalTemplatePricingSectionService.prototype.emitdeleteChangeEvent = function (id) {
        this.deletechange.emit(id);
    };
    ProposalTemplatePricingSectionService.prototype.getdeleteChangeEmitter = function () {
        return this.deletechange;
    };
    ProposalTemplatePricingSectionService.prototype.emitUpsellEvent = function (row) {
        this.upsellEvent.emit(row);
    };
    ProposalTemplatePricingSectionService.prototype.getUpsellEvent = function () {
        return this.upsellEvent;
    };
    ProposalTemplatePricingSectionService.prototype.emitNewParts = function (details) {
        this.newPartName.emit(details);
    };
    ProposalTemplatePricingSectionService.prototype.getNewPart = function () {
        return this.newPartName;
    };
    // Emit data while adding new parts
    ProposalTemplatePricingSectionService.prototype.emitNewPartDetails = function (that, term) {
        var sectionDetails = {};
        var selectedSection = that.spreadsheetData.find(function (data) { return data.id == that.selectedTableInstance; });
        sectionDetails['sectionIndex'] = selectedSection['sectionIndex'];
        sectionDetails['listIndex'] = selectedSection['listIndex'];
        sectionDetails['sectionId'] = selectedSection['sectionId'];
        sectionDetails['listId'] = selectedSection['listId'];
        sectionDetails['partName'] = term;
        $("#select2-drop-mask").click();
        that.emitNewParts({ 'data': sectionDetails });
    };
    // Calculate price on supplier and general price selection
    ProposalTemplatePricingSectionService.prototype.calculateSelectedPrice = function (component, selectedTable) {
        var _this = this;
        var rowSelected = selectedTable.row;
        var selectedSupplier = component.getColHeader()[selectedTable.col];
        this.selectedPricingData = this.spreadsheetData.find(function (data) { return data.id == _this.selectedTableInstance; });
        var selectedInstanceIndex = this.spreadsheetData.findIndex(function (data) { return data.id == _this.selectedTableInstance; });
        this.selectedPartsData = this.partArray.find(function (i) { return i.id == _this.selectedPricingData['data'][rowSelected]['partsAndPricesId']; });
        var supplierData = this.selectedPartsData.suppliersData.find(function (i) { return i.supplierName == selectedSupplier; });
        if (supplierData) {
            this.selectedPricingData['data'][rowSelected]['supplierId'] = supplierData.supplierId;
            //this.selectedPricingData['data'][rowSelected]['lowestPrice'] = supplierData.supplierPrice
        }
        else {
            this.selectedPricingData['data'][rowSelected]['supplierId'] = null;
            //this.selectedPricingData['data'][rowSelected]['lowestPrice'] = this.selectedPartsData.price
        }
        var selectedPriceColName = component.colToProp(selectedTable.col);
        this.selectedPricingData['data'][rowSelected]['selectedPriceCol'] = selectedPriceColName;
        var selectedPrice = parseFloat(this.selectedPricingData['data'][rowSelected][selectedPriceColName]);
        var quantity = parseFloat(this.selectedPricingData['data'][rowSelected]['quantity']);
        var totalCost = selectedPrice * quantity;
        if (this.selectedTableInstance.startsWith('pricing-section-partsId')) {
            var markup = parseFloat(this.selectedPricingData['data'][rowSelected]['markup']);
            if (isNaN(markup)) {
                markup = 0;
            }
            var unitSalePrice = selectedPrice + (selectedPrice * (markup / 100));
            var totalSalePrice = unitSalePrice * quantity;
            var profit = totalSalePrice - totalCost;
            this.selectedPricingData['data'][rowSelected]['totalCost'] = totalCost.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['unitSalePrice'] = unitSalePrice.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['totalSalePrice'] = totalSalePrice.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['profit'] = profit.toFixed(2);
            component.setDataAtRowProp(rowSelected, "totalCost", totalCost, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "unitSalePrice", unitSalePrice, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "totalSalePrice", totalSalePrice, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "profit", profit, 'cs_internal');
        }
        else if (this.selectedTableInstance.startsWith('system-settings-pricing-section-partsId')) {
            /*this.selectedPricingData['data'][rowSelected]['totalCost'] = totalCost.toFixed(2);
            component.setDataAtRowProp(rowSelected, "totalCost", totalCost, 'cs_internal');*/
        }
        var cellMeta = component.getCellMeta(rowSelected, selectedTable.col);
        for (var i = 0; i < component.countCols() - 1; i++) {
            var cellMeta_1 = component.getCellMeta(0, i);
            if (cellMeta_1.hasOwnProperty('selectedUnitPrice') && cellMeta_1.selectedUnitPrice) {
                cellMeta_1.selectedUnitPrice = false;
                break;
            }
        }
        cellMeta.selectedUnitPrice = true;
        this.spreadsheetData[selectedInstanceIndex] = this.selectedPricingData;
        this.hotData[rowSelected].sectionIndex = this.selectedPricingData['sectionIndex'];
        this.hotData[rowSelected].listIndex = this.selectedPricingData['listIndex'];
        this.hotData[rowSelected].sectionId = this.selectedPricingData['sectionId'];
        this.hotData[rowSelected].listId = this.selectedPricingData['listId'];
        this.emitSaveChangeEvent({ 'items': this.hotData[rowSelected], 'rowId': rowSelected });
    };
    ProposalTemplatePricingSectionService.prototype.reorderRow = function (component, rows, fId) {
        var _this = this;
        var hotObj = this.spreadsheetData.find(function (i) { return i.id == _this.selectedTableInstance; });
        var hotIndex = this.spreadsheetData.findIndex(function (i) { return i.id == _this.selectedTableInstance; });
        var hotinstance = this.hotInstances.find(function (hotIns) { return hotIns.id == _this.selectedTableInstance; })['instance'];
        if (hotObj.hasOwnProperty('data') && hotObj['data'] && hotObj['data'].length) {
            var value = hotObj['data'];
            for (var i = 0; i < rows.length; i++) {
                value.splice(fId, 0, value.splice(rows[i], 1)[0]);
            }
            this.spreadsheetData[hotIndex]['data'] = value;
            this.selectedPricingData['data'] = value;
            // reorderRow(value)
            hotinstance.render();
        }
    };
    // Funtion invoke on select general and supplier
    ProposalTemplatePricingSectionService.prototype.selectPrice = function (event, instance, row, col) {
        if (event.target.checked) {
            instance.selectCell(row, col + 1);
            this.selectedTableInstance = instance.getInstance().hotId;
            var TH_1 = { 'col': col, 'row': row };
            var columnIndex = this.originalPartColumn.findIndex(function (i) { return i.title == instance.getColHeader()[TH_1.col]; });
            var columnTitle = this.originalPartColumn.find(function (i) { return i.title == instance.getColHeader()[TH_1.col]; });
            if ((columnIndex == -1 || columnTitle['title'] == 'General price' || columnTitle['title'] == 'Unit price') && (this.selectedTableInstance.startsWith('pricing-section-partsId') || this.selectedTableInstance.startsWith('system-settings-pricing-section-partsId'))) {
                this.calculateSelectedPrice(instance, TH_1);
            }
        }
    };
    ProposalTemplatePricingSectionService.prototype.setNominalCodeEditor = function (searchData) {
        var _this = this;
        var nominalEditor = {
            title: 'Nominal code',
            data: 'settingsNominalCodeId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: function (instance, td, row, col, prop, value, cellProperties) {
                if (value) {
                    var nominalCode = _this.nominalCode.find(function (item) { return item.id == value; });
                    var nominalValue = void 0;
                    if (nominalCode) {
                        nominalValue = nominalCode['description'];
                    }
                    value = nominalValue;
                }
                else {
                    value = '';
                }
                Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: searchData,
            }),
        };
        //this.systemSettingsexpensesColumn.splice(1, 1, nominalEditor);  // TODO : incase we need supplier in feature enbale systemSettingsexpensesColumn array and changed splice code to 2,1
        this.expensesColumn.splice(1, 1, nominalEditor); // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
        this.updateCols();
    };
    ProposalTemplatePricingSectionService.prototype.setTaxEditor = function (searchData, taxLabel) {
        var _this = this;
        var vatEditor = {
            title: taxLabel,
            data: 'vatRate',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: function (instance, td, row, col, prop, value, cellProperties) {
                cellProperties.readOnly = false;
                cellProperties.placeholder = "";
                cellProperties.className = 'overflow_handsontable select_icon';
                if (!value || value == '') {
                    value = 3;
                }
                if (value && value != '') {
                    //let valueToCheck = parseFloat(value).toFixed(2);
                    var vatRate = _this.vatRates.find(function (item) { return item.id == value; });
                    var vat = void 0;
                    if (vatRate) {
                        vat = vatRate['description'];
                    }
                    value = vat;
                }
                if (row == instance.countRows() - 1) {
                    value = "";
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = '';
                    cellProperties.placeholder = "";
                    //cellProperties.readOnlyCellClassName = "calculate-class-margin";
                }
                else if (row == instance.countRows() - 2) {
                    //value = "";
                    cellProperties.className = '';
                    cellProperties.readOnlyCellClassName = "calculate-bg";
                }
                Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: this.formatTaxItems(searchData),
            }),
        };
        //this.systemSettingsexpensesColumn.splice(4, 1, vatEditor);  // TODO : incase we need supplier in feature enbale systemSettingsexpensesColumn array and changed splice code to 2,1
        this.expensesColumn.splice(4, 1, vatEditor); // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
        this.updateCols();
    };
    ProposalTemplatePricingSectionService.prototype.updateCols = function () {
        var _this = this;
        var hotInstancesSystemSettings = this.hotInstances.filter(function (instance) {
            return (instance.id.startsWith('system-settings-pricing-section-expensesId'));
        });
        if (hotInstancesSystemSettings) {
            hotInstancesSystemSettings.forEach(function (instance) {
                var core = instance.instance;
                //core.updateSettings({ columns: this.systemSettingsexpensesColumn });
                core.updateSettings({ columns: _this.expensesColumn });
            });
        }
        var hotInstancesView = this.hotInstances.filter(function (instance) {
            return (instance.id.startsWith('pricing-section-expensesId'));
        });
        if (hotInstancesView) {
            hotInstancesView.forEach(function (instance) {
                var core = instance.instance;
                core.updateSettings({ columns: _this.expensesColumn });
            });
        }
    };
    ProposalTemplatePricingSectionService.prototype.formatTaxItems = function (taxData) {
        var tax = [];
        var taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach(function (province) {
            var taxLabel = province[0]['taxProvinceName'];
            tax.push({ 'text': taxLabel, 'children': province });
        });
        return tax;
    };
    ProposalTemplatePricingSectionService.prototype.clearInstances = function () {
        this.hotInstances = [];
    };
    ProposalTemplatePricingSectionService.prototype.clearRow = function () {
        this.editedDataAdded = [];
    };
    ProposalTemplatePricingSectionService.prototype.ngOnDestroy = function () {
    };
    ProposalTemplatePricingSectionService.ngInjectableDef = i0.defineInjectable({ factory: function ProposalTemplatePricingSectionService_Factory() { return new ProposalTemplatePricingSectionService(i0.inject(i1.CsToastBoxService), i0.inject(i2.ProposalTemplateService), i0.inject(i3.CommonService), i0.inject("confirmationBoxHelper"), i0.inject("$scope"), i0.inject(i4.CurrencyPipe)); }, token: ProposalTemplatePricingSectionService, providedIn: "root" });
    return ProposalTemplatePricingSectionService;
}());
export { ProposalTemplatePricingSectionService };
