import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {Subject} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";

@Component({
  selector: 'app-sequence-add-contact',
  templateUrl: './sequence-add-contact.component.html',
  styles: ['form {height: auto;}']
})
export class SequenceAddContactComponent implements OnInit {

  public onClose: Subject<any>;
  form: FormGroup;

  contactId: number;
  type: string;
  title: string;
  helpText: string;
  saving: boolean = false;
  constructor(
      public modalRef: BsModalRef,
      private formBuilder: FormBuilder,
      private workflowHttpService: WorkflowhttpService
  ) { }

  ngOnInit() {
      this.onClose = new Subject();
      let formControlConfig = {};
      if(this.type == 'email') {
          formControlConfig['emailaddress'] = ['', [Validators.required, Validators.email]]
      }
      if(this.type == 'mobile') {
          formControlConfig['mobile'] = ['', [Validators.required]]
          formControlConfig['countrycode'] = ['', Validators.required]
      }
      this.form = this.formBuilder.group(formControlConfig);
  }

  get f() {
      return this.form.controls;
  }

  onChangeCountryCode(code) {
      this.form.controls['countrycode'].setValue(code);
  }

  addContactEmail(contact, id) {
      this.workflowHttpService.postAddContactEmail({ contact }, id)
      .subscribe( {
          next: (resp) => {
              console.log(resp)
              if(resp['status'] === 'success') {
                  this.onClose.next(contact);
                  this.modalRef.hide();
              }
              else {
                  this.modalRef.hide();
              }
          }
      })
  }

  addContactMobile(contact, id) {
      this.workflowHttpService.postAddContactMobile({ contact }, id)
          .subscribe( {
              next: (resp) => {
                  console.log(resp)
                  if(resp['status'] === 'success') {
                      this.onClose.next(resp['data']);
                      this.modalRef.hide();
                  }
                  else {
                      this.modalRef.hide();
                  }
              }
          })
  }

  save() {
      let contact = this.form.value;
      this.saving = true;
      contact['type'] = this.type;
      contact['contactId'] = this.contactId;
      if(this.type == 'email') {
          this.addContactEmail(contact, this.contactId)
      }
      else {
        this.addContactMobile(contact, this.contactId);
      }
  }
}
