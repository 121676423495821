import { OnDestroy, OnInit } from '@angular/core';
import { TriggerDatasetService } from "../services/trigger-dataset.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import { BsModalRef } from "ngx-bootstrap";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, range } from "rxjs/index";
import * as moment from "moment";
import { map } from 'rxjs/operators';
import { from } from "rxjs/index";
import { pluck } from "rxjs/operators";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var ordinal_suffix_of = function (i) {
    var j = i % 10, k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
};
var ɵ0 = ordinal_suffix_of;
var removeDuplicates = function (arr) {
    return arr.filter(function (item, index) { return arr.indexOf(item) === index; });
};
var ɵ1 = removeDuplicates;
var TriggerRulesPanelComponent = /** @class */ (function () {
    function TriggerRulesPanelComponent(modalRef, triggerService, formBuilder, httpService, commonService) {
        this.modalRef = modalRef;
        this.triggerService = triggerService;
        this.formBuilder = formBuilder;
        this.httpService = httpService;
        this.commonService = commonService;
        this.title = '';
        this.isMarketPlaceView = false;
        this.activeInputs = {};
        this.action = '';
        this.isEdit = false;
        this.activeScheduler = 'daily';
        this.hasDynamicFields = false;
        this.dependenciesList = {};
        this.dependencyDropDownList = [];
        this.dynamicFields = [];
        this.customFields = [];
        this.customFieldsGroup = [];
        this.dependencyName = '';
        this.select2Config = {};
        this.showMultipleDropdown = false;
        this.dataFunction = function (item) { return item.text; };
        this.deleteModalContent = {
            title: '',
            message: ''
        };
        this.openDeleteConfirmation = false;
        this.deleteConfirmTextInput = '';
        this.confirmText = 'DELETE';
        this.deleteError = false;
        this.inValidConfirmText = false;
        this.validation = false;
        this.validationMessage = '\'DELETE\' to remove the condition';
        this.dateList = [];
        this.selectedColName = [];
        this.fieldsStatic = [];
        this.fieldsCustom = [];
        this.fieldsCertficate = [];
        this.saving = false;
        this.isReadOnlyMode = false;
        this.diagramOptions = {};
        this.whitelistRules = [];
        this.showTaskBlock = true;
    }
    TriggerRulesPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.httpService.getDagrunnerStatus(this.diagramOptions.workflowId)
            .subscribe({
            next: function (resp) {
                if (resp.status == 'running') {
                    _this.diagramOptions.isRunningStatus = true;
                }
                else {
                    _this.diagramOptions.isRunningStatus = false;
                }
            }
        });
        this.warningMessage = 'This changes does not affect any running workflows. It will affect on the upcoming workflow';
        this.dateList = this.getDateList();
        this.onClose = new Subject();
        // console.log('dependenciesList', this.isMarketPlaceView);
        //this.triggerObjects = this.triggerService.getFields();
        this.operators = this.triggerService.getOperators();
        var formData = {
            mainObject: '',
            dependentId: '',
            rules: [],
            scheduler: {
                type: 'daily',
                fromTime: moment('08:00:00', 'HH:mm:ss').toDate(),
                fromDate: 1,
                weekdays: {
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: true,
                }
            }
        };
        if (this.isEdit) {
            var value = this.selectedCell.getValue();
            // console.log('v', value);
            formData['mainObject'] = value['mainObject'];
            formData['dependentId'] = value['dependentId'];
            formData['rules'] = value['rules'];
            var source$ = from(value['rules']);
            var colsName = source$.pipe(pluck('col_name'));
            colsName.subscribe(function (val) { return _this.selectedColName.push(val); });
            if (value['scheduler']) {
                //console.log( value['scheduler']);
                formData['scheduler'] = value['scheduler'];
                if (value['scheduler']['fromTime'] instanceof Date) {
                    formData['scheduler']['fromTime'] = moment(value['scheduler']['fromTime']).toDate();
                }
                else {
                    formData['scheduler']['fromTime'] = moment(value['scheduler']['fromTime'] + ':00', 'HH:mm:ss').toDate();
                }
            }
            this.activeMetaDatas = this.triggerObjects[formData['mainObject']];
            this.fieldsStatic = this.activeMetaDatas['fields'];
            this.initializeActiveInputs(value);
            if (formData['scheduler']) {
                this.activeScheduler = formData.scheduler['type'];
            }
        }
        this.form = this.formBuilder.group({
            mainObject: [{ value: formData.mainObject, disabled: this.isMarketPlaceView || this.isEdit }, [Validators.required]],
            dependentId: [formData.dependentId],
            conditions: 'AND',
            rules: this.formBuilder.array([]),
            scheduler: this.formBuilder.group({
                type: [formData.scheduler['type']],
                fromTime: [formData.scheduler['fromTime'], [Validators.required]],
                fromDate: [formData.scheduler['fromDate']],
                weekdays: this.formBuilder.group(formData.scheduler['weekdays']),
            })
        });
        if (this.isEdit) {
            _.forEach(formData['rules'], function (value) {
                _this.addRule(value);
            });
        }
        if (this.diagramOptions.hasOwnProperty('whitelist')) {
            this.whitelistRules = !this.diagramOptions['whitelist']['rules'] ? [] : this.diagramOptions['whitelist']['rules'];
        }
        this.isReadOnlyMode = this.isMarketPlaceView;
        this.subscription = this.httpService.workflowEventTrigger$.subscribe(function (action) {
            if (action === 'autoSave') {
                _this.showTaskBlock = false;
            }
        });
    };
    TriggerRulesPanelComponent.prototype.chooseScheduler = function () {
        var schedulerForm = this.form.controls['scheduler'];
        this.activeScheduler = schedulerForm.controls.type.value;
    };
    Object.defineProperty(TriggerRulesPanelComponent.prototype, "schedulerGroup", {
        get: function () {
            return this.form.controls.scheduler;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TriggerRulesPanelComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TriggerRulesPanelComponent.prototype, "r", {
        get: function () {
            return this.form.controls.rules;
        },
        enumerable: true,
        configurable: true
    });
    TriggerRulesPanelComponent.prototype.onBegainDeleteAction = function (rule, index) {
        console.log(rule.getRawValue(), index);
        this.deleteModalContent.title = 'Delete condition';
        this.deleteModalContent.deletableIndex = index;
        this.openDeleteConfirmation = true;
    };
    TriggerRulesPanelComponent.prototype.checkConfirmText = function () {
        this.inValidConfirmText = !(this.deleteConfirmTextInput.trim().toUpperCase() == this.confirmText);
    };
    TriggerRulesPanelComponent.prototype.removeTriggerCondition = function () {
        this.checkConfirmText();
        if (this.inValidConfirmText) {
            return false;
        }
        var deletableIndex = this.deleteModalContent.deletableIndex;
        this.removeRuleByIndex(deletableIndex);
        this.openDeleteConfirmation = false;
        this.deleteModalContent.deletableIndex = -1;
        this.deleteConfirmTextInput = '';
    };
    ;
    TriggerRulesPanelComponent.prototype.getSchedulingType = function () {
        return [
            { value: 'daily', text: 'Daily' },
            { value: 'weekly', text: 'Weekly' },
            { value: 'monthly', text: 'Monthly' },
        ];
    };
    TriggerRulesPanelComponent.prototype.getDateList = function () {
        var n = [];
        var numbers = range(0, 31);
        numbers.pipe(map(function (v) {
            var val = String(v + 1);
            return { value: v + 1, text: val.length === 1 ? '0' + val : val };
        }))
            .subscribe({
            next: function (value) { return n.push(value); }
        });
        return n;
    };
    TriggerRulesPanelComponent.prototype.makeAsDisabled = function (col_name) {
        if (!this.isMarketPlaceView || this.whitelistRules.length > 0 && this.whitelistRules.includes(col_name)) {
            return false;
        }
        return true;
    };
    TriggerRulesPanelComponent.prototype.weekDayNamesList = function () {
        return {
            'mon': { value: 1, text: 'Mon' },
            'tue': { value: 2, text: 'Tue' },
            'wed': { value: 3, text: 'Wed' },
            'thu': { value: 4, text: 'Thu' },
            'fri': { value: 5, text: 'Fri' },
            'sat': { value: 6, text: 'Sat' },
            'sun': { value: 0, text: 'Sun' },
        };
    };
    TriggerRulesPanelComponent.prototype.addRule = function (ruleValue) {
        if (ruleValue === void 0) { ruleValue = null; }
        if (this.dynamicFields.length == 0 && this.form.get('mainObject').value == 'certificates') {
            this.validation = true;
        }
        var formRules = this.form.controls.rules;
        var rule = {
            col_name: '',
            input: '',
            operator: 'equal',
            text: '',
            type: '',
            value: '',
            fieldType: 'static',
            pageType: '',
            templateId: '',
        };
        if (ruleValue) {
            rule['col_name'] = ruleValue['col_name'];
            rule['input'] = ruleValue['input'];
            rule['operator'] = ruleValue['operator'];
            rule['text'] = ruleValue['text'];
            rule['type'] = ruleValue['type'];
            rule['value'] = ruleValue['value'];
            rule['fieldType'] = _.isUndefined(ruleValue['fieldType']) ? 'static' : ruleValue['fieldType'];
            rule['pageType'] = _.isUndefined(ruleValue['pageType']) ? '' : ruleValue['pageType'];
            rule['templateId'] = _.isUndefined(ruleValue['templateId']) ? '' : ruleValue['templateId'];
        }
        formRules.push(this.formBuilder.group({
            col_name: [rule['col_name'], [Validators.required]],
            operator: [rule.operator],
            value: [rule.value, [Validators.required]],
            type: [rule.type],
            input: [rule.input],
            text: [rule.text],
            fieldType: [rule.fieldType],
            pageType: [rule.pageType],
            templateId: [rule.templateId]
        }));
    };
    TriggerRulesPanelComponent.prototype.initializeActiveInputs = function (selectedCellValue) {
        var _this = this;
        this.activeMetaDatas.fields.forEach(function (value) {
            // static fields
            _this.activeInputs[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                operators: value.operators
            };
            if (value.hasOwnProperty('options') && value.options.length > 0) {
                _this.activeInputs[value.col_name]['options'] = value.options;
            }
        });
        if (this.activeMetaDatas.hasOwnProperty('custom_fields')) {
            this.setCustomFieldsList();
        }
        if (this.activeMetaDatas.id === 'certificates') {
            this.setDependencyDropDownlist();
            this.dynamicFields = this.activeMetaDatas['dynamic_fields'][selectedCellValue['dependentId']];
            var activeDynamicFields = this.activeMetaDatas['dynamic_fields'][selectedCellValue['dependentId']];
            this.dynamicFields = activeDynamicFields['fields'];
            this.dynamicFields.forEach(function (value, index) {
                _this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: value.fieldType,
                    pageType: value.pageType,
                };
                if (value.hasOwnProperty('options') && value.options.length > 0) {
                    _this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        }
    };
    TriggerRulesPanelComponent.prototype.checkToShowAddRule = function () {
        if (this.isMarketPlaceView) {
            return false;
        }
        if (this.dependencyDropDownList.length > 0 && this.form.get('mainObject').value && !this.form.get('dependentId').value && this.validation) {
            return false;
        }
        if (!this.form.get('mainObject').value) {
            return false;
        }
        if (this.validation) {
            return false;
        }
        return true;
    };
    TriggerRulesPanelComponent.prototype.onSelectDependent = function () {
        var _this = this;
        var dependentId = this.form.get('dependentId').value;
        if (dependentId) {
            this.validation = false;
            this.dynamicFields = this.activeMetaDatas['dynamic_fields'][dependentId]['fields'];
            this.removeRules();
            //this.activeMetaDatas.fields = this.activeMetaDatas.fields.concat(dynamicFields);
            this.dynamicFields.forEach(function (value, index) {
                _this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: value.fieldType,
                    pageType: value.pageType,
                };
                if (value.hasOwnProperty('options') && value.options.length > 0) {
                    _this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        }
        else {
            this.validation = true;
        }
    };
    TriggerRulesPanelComponent.prototype.setCustomFieldsList = function () {
        var _this = this;
        this.customFields = this.activeMetaDatas['custom_fields'];
        this.customFields.forEach(function (template) {
            template['fields'].forEach(function (value) {
                _this.activeInputs[value.col_name] = value;
                if (value.hasOwnProperty('options') && value.options.length > 0) {
                    _this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
        });
    };
    TriggerRulesPanelComponent.prototype.setDependencyDropDownlist = function () {
        this.dependencyDropDownList = [];
        if (this.activeMetaDatas.id === 'certificates') {
            this.dependencyDropDownList = Object.values(this.activeMetaDatas['dynamic_fields']).map(function (item) {
                return {
                    text: item.text,
                    id: item.id
                };
            });
        }
    };
    TriggerRulesPanelComponent.prototype.onSelectMainObject = function () {
        var _this = this;
        this.validation = false;
        this.removeRules();
        if (this.form.get('mainObject').value != '') {
            this.activeMetaDatas = this.triggerObjects[this.form.get('mainObject').value];
            this.fieldsStatic = this.activeMetaDatas['fields'];
            console.log('activeMetaDatas ', this.activeMetaDatas);
            this.dependencyDropDownList = [];
            this.dynamicFields = [];
            if (this.activeMetaDatas.hasOwnProperty('dynamic_fields')) {
                this.setDependencyDropDownlist();
            }
            else {
                this.form.get('dependentId').setValue('');
            }
            if (this.activeMetaDatas.hasOwnProperty('custom_fields')) {
                this.setCustomFieldsList();
            }
            this.activeMetaDatas.fields.forEach(function (value, index) {
                _this.activeInputs[value.col_name] = {
                    input: value.input,
                    type: value.type,
                    text: value.text,
                    operators: value.operators,
                    fieldType: 'static',
                    pageType: '',
                };
                if (value.hasOwnProperty('options') && value.options.length > 0) {
                    _this.activeInputs[value.col_name]['options'] = value.options;
                }
            });
            console.log('d1', this.activeInputs);
        }
        else {
            this.dependencyDropDownList = [];
        }
    };
    TriggerRulesPanelComponent.prototype.removeRules = function () {
        var control = this.form.controls['rules'];
        for (var i = control.length - 1; i >= 0; i--) {
            control.removeAt(i);
        }
    };
    TriggerRulesPanelComponent.prototype.removeRuleByIndex = function (index) {
        var _this = this;
        var control = this.form.controls['rules'];
        control.removeAt(index);
        setTimeout(function () {
            _this.selectedColName = control.getRawValue().map(function (i) { return i['col_name']; });
        });
    };
    TriggerRulesPanelComponent.prototype.onSelectOperator = function (value, index) {
        var controlArray = this.form.controls['rules'];
        var operatorVal = controlArray.controls[index].get('operator').value;
        controlArray.controls[index].get('value').setValue('');
        if (operatorVal === 'in' || operatorVal === 'not_in') {
            this.showMultipleDropdown = true;
        }
        else {
            if ($("#multiple_select_" + index).length) {
                $("#multiple_select_" + index).select2('destroy');
            }
            this.showMultipleDropdown = false;
        }
    };
    TriggerRulesPanelComponent.prototype.onSelectMultipleSelect = function (value, index) {
        var controlArray = this.form.controls['rules'];
        controlArray.controls[index].get('value').setValue(value);
    };
    TriggerRulesPanelComponent.prototype.onSelectField = function (value, index) {
        var controlArray = this.form.controls['rules'];
        if ($("#multiple_select_" + index).length) {
            $("#multiple_select_" + index).select2('destroy');
        }
        this.showMultipleDropdown = false;
        this.selectedColName = controlArray.getRawValue().map(function (i) { return i['col_name']; });
        if (this.activeInputs[value]['input'] == 'select') {
            //controlArray.controls[index].get('operator').setValue(this.activeInputs[e.target.value]['operators'][0]);
        }
        controlArray.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        controlArray.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        controlArray.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        controlArray.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        controlArray.controls[index].get('fieldType').setValue(this.activeInputs[value]['fieldType']);
        controlArray.controls[index].get('pageType').setValue(this.activeInputs[value]['pageType']);
        controlArray.controls[index].get('value').setValue('');
        if (this.activeInputs[value]['fieldType'] === 'custom_field') {
            controlArray.controls[index].get('templateId').setValue(this.activeInputs[value]['templateId']);
        }
        return this.activeInputs[value];
    };
    TriggerRulesPanelComponent.prototype.prepareScheduleValue = function () {
        var scheduler = this.form.value.scheduler;
        var cronValue = '* * * * *', cronText = '';
        var fromTime = moment(scheduler['fromTime']).format('HH:mm');
        var _a = fromTime.split(':'), hour = _a[0], minutes = _a[1];
        if (scheduler['type'] === 'daily') {
            cronValue = minutes + ' ' + hour + ' * * *';
            cronText = 'Daily at ' + fromTime;
        }
        else if (scheduler['type'] === 'weekly') {
            var weekdaysList_1 = this.weekDayNamesList();
            var weekdays = _.chain(scheduler['weekdays']).map(function (v, k) {
                return { key: k, value: v, id: weekdaysList_1[k] };
            }).filter(function (v) { return v.value; })
                .pluck('id').sortBy('value').value();
            if (weekdays.length > 0) {
                var weekVal = _.pluck(weekdays, 'value');
                var weekTxt = _.pluck(weekdays, 'text');
                cronText = 'Every week at ' + fromTime + ' on ' + weekTxt.join(',');
                cronValue = minutes + ' ' + hour + ' * * ' + weekVal.join(',');
            }
            else {
                cronText = 'Every week at ' + fromTime + ' on Sunday';
                cronValue = minutes + ' ' + hour + ' * * 0';
            }
        }
        else if (scheduler['type'] === 'monthly') {
            cronText = 'Every month on the ' + ordinal_suffix_of(scheduler['fromDate']) + ' at ' + fromTime;
            cronValue = minutes + ' ' + hour + ' ' + scheduler['fromDate'] + ' * *';
        }
        return [cronText, cronValue, fromTime];
    };
    TriggerRulesPanelComponent.prototype.save = function () {
        var _this = this;
        if (!this.form.valid) {
            return false;
        }
        this.saving = true;
        this.action = 'saving';
        var formValues = this.form.value;
        if (this.isEdit && !formValues['mainObject']) {
            var value = this.selectedCell.getValue();
            formValues['mainObject'] = value['mainObject'];
        }
        if (this.form.get('mainObject').value === 'certificates') {
            console.log('formdata', formValues, this.activeInputs);
        }
        var returnDatas = {
            formData: formValues,
            fields: this.activeInputs
        };
        if (this.isScheduler) {
            var _a = this.prepareScheduleValue(), cronText = _a[0], cronValue = _a[1], fromTime = _a[2];
            returnDatas['formData']['scheduler']['fromTime'] = fromTime;
            returnDatas['formData']['cronText'] = cronText;
            returnDatas['formData']['cronValue'] = cronValue;
        }
        else {
            delete returnDatas['formData']['scheduler'];
        }
        // console.log(returnDatas);
        setTimeout(function () {
            _this.onClose.next(returnDatas);
        }, 0);
        this.modalRef.hide();
    };
    TriggerRulesPanelComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.showTaskBlock = true;
        }
    };
    return TriggerRulesPanelComponent;
}());
export { TriggerRulesPanelComponent };
export { ɵ0, ɵ1 };
