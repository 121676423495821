import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import * as moment from 'moment';
import { LongDatePipe } from "@app/pipes";
import {CsToastBoxService} from "@app/services";

declare var _:any;

@Component({
    selector: 'app-dagruns',
    templateUrl: './dagruns.component.html',
    styles: []
})
export class DagrunsComponent implements OnInit {

    currentShowingPageNumber:number = 1;
    totalEntriesCount: number = 0;
    totalPages: number = 0;
    dagrunsItems:any = [];
    objectName:string;
    loading:boolean = false;
    currentPage:number = 1;
    pageLimit:number = 20;
    selectedItem:any = {};
    dagSource:any = {};
    isScheduled:boolean = false;
    enableInfiniteScroll:boolean = false;

    dagrunsItemsScheduler: any = {};
    totalEntriesCountScheduler: number = 0;
    toggleOpenClose: number = 0;
    childLoading: boolean = false;
    isLoadingInfiniteScroll: boolean = false;
    childCurrentPage:number = 1;
    childPageLimit:number = 8;
    searchPlaceHolder = '';
    filterByText: string = '';
    filterParams: any = {};

    @Input() data:any;
    @Output() activeDagRunItem: EventEmitter<any> = new EventEmitter();
    @ViewChild('dagItemsContainer') dagItemsContainer: ElementRef;

    constructor(
        private workflowService: WorkflowhttpService,
        private longDatePipe: LongDatePipe,
        private toastBox: CsToastBoxService,
    ) { }

    ngOnInit() {
        this.loading=true;
        this.childCurrentPage=1;
        this.getDagRuns(true);
    }

    firstTimeLoad:boolean = false;
    getDagRuns(resetPage: boolean = false) {
        if(resetPage) {
            this.currentPage=1;
            this.firstTimeLoad=true;
        }
        this.workflowService.getDagrunsList(this.data['id'], {
            page: this.currentPage,
            limit: this.pageLimit
        }).subscribe( data => {
            //console.log('res', data);
            this.dagrunsItems = data['dagruns'];
            this.objectName = data['objectName'];
            this.totalEntriesCount = data['totalCount'];
            // this.dagSource = data['dagsource'];
            this.isScheduled = data['isScheduled'];
            this.totalPages = Math.ceil(data['totalCount'] / this.pageLimit)
            this.loading=false;
            if(!this.isScheduled)
                this.searchPlaceHolder = 'Search by '+this.objectName+' id, name, address';
        });
    }

    updateLimitValue() {

    }

    filterBy(filterParams: any, resetPage: boolean = false) {
        if(resetPage) {
            this.currentPage=1;
            this.firstTimeLoad=true;
        }
        let params = filterParams;
        this.filterParams = params;
        params['page'] = this.currentPage;
        params['limit'] = this.pageLimit;
        this.workflowService.getDagrunsList(this.data['id'], params).subscribe( data => {
            this.dagrunsItems = data['dagruns'];
            this.objectName = data['objectName'];
            this.totalEntriesCount = data['totalCount'];
            // this.dagSource = data['dagsource'];
            this.isScheduled = data['isScheduled'];
            this.totalPages = Math.ceil(data['totalCount'] / this.pageLimit)
            this.loading=false;
            if(!this.isScheduled)
                this.searchPlaceHolder = 'Search by '+this.objectName+' id, name, address';
            if(this.dagItemsContainer) {
                this.dagItemsContainer.nativeElement.scrollTop = 0;
            }
        }, err => {
            this.toastBox.show('Error!');
        })
    }

    schedulerDagRuns(item) {
        if(this.toggleOpenClose == item.id)
            this.toggleOpenClose = 0;
        else
            this.toggleOpenClose = item.id;
        this.dagrunsItemsScheduler = [];
        this.enableInfiniteScroll=false;
        this.childCurrentPage=1;
        if(!this.toggleOpenClose) {
            return;
        }
        this.childLoading = true;
        this.workflowService.getScheduledDagrunsList(this.data['id'], item['id'], this.objectName, {
            page: this.childCurrentPage,
            limit: this.childPageLimit
        }).subscribe( data => {
            this.childLoading = false;
            this.dagrunsItemsScheduler= data['dagruns'];
            this.totalEntriesCountScheduler = data['totalCount'];
            if(this.childCurrentPage==1 && this.totalEntriesCountScheduler > 3) {
                this.enableInfiniteScroll=true;
            }
        })
    }

    onScroll(item) {
        if(this.totalEntriesCountScheduler <= this.dagrunsItemsScheduler.length) {
            this.childCurrentPage=1;
            return
        }
        this.childCurrentPage++;
        this.isLoadingInfiniteScroll=true;
        this.workflowService.getScheduledDagrunsList(this.data['id'], item['id'], this.objectName, {
            page: this.childCurrentPage,
            limit: this.childPageLimit
        }).subscribe( data => {
            this.isLoadingInfiniteScroll=false;
            this.dagrunsItemsScheduler = this.dagrunsItemsScheduler.concat(data['dagruns']);
            this.totalEntriesCountScheduler = data['totalCount'];
        })
    }

    dagHistoryHandler(item) {
        this.selectedItem = item;
        //console.log(item);
        this.selectedItem = item;
        this.childCurrentPage=1;
        this.workflowService.getDagrunTasksList(item.id)
        .subscribe(data => {
            let sourceBlocks:any = {};
            let historyItems = data['historyItems'];
            if(Object.keys(historyItems).length > 0) {
                sourceBlocks = _.forEach(historyItems, (item, taskId) => {
                    item['status'] = historyItems[taskId]['status'];
                    return item;
                })
            }
            else {
                sourceBlocks={};
            }
            setTimeout(() => {
                this.activeDagRunItem.emit({
                    'dagRunId': item.id,
                    'xmlsource': item.xmlsource,
                    'dagSource': data['dagSource'],
                    'tasks': sourceBlocks,
                });
            },300);
        });
    }

    getCreatedDate(item, settingsFormat :boolean=false) {
        if(settingsFormat) {
            return this.longDatePipe.transform(item.createdondatetime['date']);
        }
        return moment.utc( item.createdondatetime['date'] ).local().fromNow();
    }

    getPropertyAddress(item:any) {
        let address = item.addressline1;
        if(item.address2) {
            address += ', ' + item.address2;
        }
        if(item.addressline3) {
            address += ', '+ item.addressline3;
        }
        if(item.county) {
            address += ', '+ item.county;
        }
        if(item.town) {
            address += ', '+ item.town;
        }
        if(item.postcode) {
            address += ', '+ item.postcode;
        }
        return address;
    }

    getDiaryDescription(item:any) {
        let desc = 'Event type: ';
        if(item.type === 1) {
            desc += 'Normal';
        }
        else if(item.type === 3) {
            desc += 'Job number '+ item.tablepkid;
        }
        else if(item.type === 2) {
            desc += 'Estimate number '+ item.tablepkid;
        }
        else if(item.type === 5) {
            desc += 'Opportunity number '+ item.tablepkid;
        }
        return desc;
    }

    fetchHistory(event?: any) {
        // console.log('fetchHistory', event);
        this.currentPage = event['page'];
        if(this.firstTimeLoad && event['page'] == 1) {
            this.firstTimeLoad = false;
            return;
        }
        this.filterBy(this.filterParams, false)
    }

}

