import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidepanelComponent } from './cs-sidepanel/sidepanel.component';
import {FormsModule} from "@angular/forms";
import {SidepanelOptions} from "@app/sidepanel/services/sidepanel-options";
import {ModalModule} from "ngx-bootstrap";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {TranslateModule} from '@ngx-translate/core';
import { PopupPanelComponent } from './cs-popup-panel/popup-panel.component';
import {CsModalBackdropComponent} from "@app/sidepanel/cs-backdrop/cs-modal-backdrop.component";
import { FullscreenModalService } from "@app/sidepanel/services/fullscreen-modal.service";
import { CsFullscreenPanelComponent } from './cs-fullscreen-panel/cs-fullscreen-panel.component';
import {CsMediumScreenPanelComponent} from "@app/sidepanel/cs-medium-screen-panel/cs-medium-screen-panel.component";
import {MediumScreenModalService} from "@app/sidepanel/services/medium-screen-modal.service";

@NgModule({
    declarations: [SidepanelComponent, PopupPanelComponent, CsModalBackdropComponent, CsFullscreenPanelComponent, CsMediumScreenPanelComponent],
    imports: [
        FormsModule,
        CommonModule,
        TranslateModule,
        ModalModule.forRoot()
    ],
    providers: [
        SidepanelOptions,
        SidepanelModalService,
        MediumScreenModalService,
        FullscreenModalService
    ],
    entryComponents: [
        SidepanelComponent,
        PopupPanelComponent,
        CsModalBackdropComponent,
        CsFullscreenPanelComponent,
        CsMediumScreenPanelComponent
    ],

})
export class SidepanelModule { }
