import { OnInit, EventEmitter } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsDaterangepickerConfig, BsDaterangepickerDirective } from "ngx-bootstrap";
var CsDatepickerComponent = /** @class */ (function () {
    function CsDatepickerComponent(settings) {
        this.settings = settings;
        this.class = 'w-100';
        this.datePickerType = 'default';
        this.isDpDisabled = false;
        this.isHideWeekNumbers = false;
        this.isReadOnly = false; // Restrict only input events (Ex: Typing into the input box)
        this.selectedDate = new EventEmitter();
        this.pickerShown = new EventEmitter();
        this.customBsConfig = new BsDatepickerConfig();
        this.customBsRangeConfig = new BsDaterangepickerConfig();
        this.format = 'd-m-y';
        this.formats = [
            { 'id': 0, 'format': 'DD/MM/YY' },
            { 'id': 1, 'format': 'DD/MM/YYYY' },
            { 'id': 2, 'format': 'DD/MMM/YY' },
            { 'id': 3, 'format': 'DD/MMM/YYYY' },
            { 'id': 4, 'format': 'DD-MM-YY' },
            { 'id': 5, 'format': 'DD-MM-YYYY' },
            { 'id': 6, 'format': 'DD-MMM-YY' },
            { 'id': 7, 'format': 'DD-MMM-YYYY' },
            { 'id': 50, 'format': 'MM/DD/YY' },
            { 'id': 51, 'format': 'MM/DD/YYYY' },
            { 'id': 52, 'format': 'MMM/DD/YY' },
            { 'id': 53, 'format': 'MMM/DD/YYYY' },
            { 'id': 54, 'format': 'MM-DD-YY' },
            { 'id': 55, 'format': 'MM-DD-YYYY' },
            { 'id': 56, 'format': 'MMM-DD-YY' },
            { 'id': 57, 'format': 'MMM-DD-YYYY' }
        ];
        this.bsConfigInit();
    }
    Object.defineProperty(CsDatepickerComponent.prototype, "defaultValue", {
        set: function (value) {
            if (value)
                this.setFormattedDate(value);
        },
        enumerable: true,
        configurable: true
    });
    CsDatepickerComponent.prototype.ngOnInit = function () {
        if (this.initialDateVal) {
            this.setFormattedDate(this.initialDateVal);
        }
        this.customBsConfig.showWeekNumbers = !this.isHideWeekNumbers;
        this.customBsRangeConfig.showWeekNumbers = !this.isHideWeekNumbers;
        this.customBsConfig.adaptivePosition = true;
        this.customBsRangeConfig.adaptivePosition = true;
        if (this.maxDate) {
            this.customBsConfig.maxDate = new Date();
            this.customBsRangeConfig.maxDate = new Date();
        }
    };
    CsDatepickerComponent.prototype.bsConfigInit = function () {
        var intlSettings = this.settings._csIntlSettings;
        var dateFormat = _.findWhere(this.formats, { 'id': parseInt(intlSettings['short_date']) });
        if (dateFormat) {
            this.format = dateFormat['format'];
        }
        if (this.datePickerType === 'inline') {
            this.customBsConfig.showWeekNumbers = false;
        }
        this.customBsConfig.dateInputFormat = this.format;
        this.customBsRangeConfig.rangeInputFormat = this.format;
    };
    /** BS Datepicker config changes */
    CsDatepickerComponent.prototype.onDateChanges = function (date) {
        this.selectedDate.emit({ 'startDate': new Date(date), 'endDate': '' });
    };
    CsDatepickerComponent.prototype.setFormattedDate = function (value) {
        if (this.datePickerType === 'inline' || this.datePickerType === 'default') {
            this.formattedDate = typeof value === 'string' ? new Date(value) : value;
        }
        else {
            value[0] = typeof value[0] === 'string' ? new Date(value[0]) : value[0];
            value[1] = typeof value[1] === 'string' ? new Date(value[1]) : value[1];
            this.formattedDateRange = value;
        }
    };
    CsDatepickerComponent.prototype.setBsDateConfigChange = function () {
        this.dataPicker.setConfig();
    };
    /** BS Date range picker config changes */
    CsDatepickerComponent.prototype.onDateRangeChanges = function (date) {
        this.setBsDateRangeConfigChange();
        this.selectedDate.emit({ 'startDate': new Date(date[0]), 'endDate': new Date(date[1]) });
    };
    CsDatepickerComponent.prototype.setBsDateRangeConfigChange = function () {
        this.daterangepicker.setConfig();
    };
    CsDatepickerComponent.prototype.getMinDate = function () {
        return this.customBsConfig.minDate;
    };
    CsDatepickerComponent.prototype.setMaxDate = function (maxDate) {
        this.customBsConfig.maxDate = new Date(maxDate);
        this.setBsDateConfigChange();
    };
    CsDatepickerComponent.prototype.setMinDate = function (minDate) {
        this.customBsConfig.minDate = new Date(minDate);
        this.setBsDateConfigChange();
    };
    CsDatepickerComponent.prototype.getMaxDate = function () {
        return this.customBsConfig.maxDate;
    };
    CsDatepickerComponent.prototype.onPickerShown = function (event) {
        this.pickerShown.emit(event);
    };
    return CsDatepickerComponent;
}());
export { CsDatepickerComponent };
