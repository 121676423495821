import { UpgradeModule, downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';
//--
import { HybridFactory, V5AuthService } from '@app/core';
import { SettingsService, setUpLocationSync } from "@app/core";
import { AppComponent } from '@app/app.component';
import { getDatepickerConfig } from '@app/directives';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient, 'resources/i18n/locale_', '.json');
}
//import {downgradeComponent} from "@angular/upgrade/src/common/downgrade_component";
import { SmartTableComponent } from "@app/smart_table/smart-table/smart-table.component";
import { ConfigKBUrlService } from "@app/core/config/ConfigKBUrlService";
import { AddEditCertificateComponent } from "@app/features/customers/jobs/add-edit-certificate/add-edit-certificate.component";
import { CsSignatureGeneratorComponent } from "@app/directives/material-directives/cs-signature-generator/cs-signature-generator.component";
import { CsFullScreenModalComponent } from "@app/directives/material-directives/cs-full-screen-modal/cs-full-screen-modal.component";
import { environment } from "../environments/environment";
import { DynamicCertificateFormParserComponent } from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/dynamic-certificate-form-parser.component";
import { CsToggleuserpreferenceComponent } from "@app/directives/input-directives/cs-toggleuserpreference/cs-toggleuserpreference.component";
import { MicroserviceHelperService } from '@app/shared/microservice/helper/microservice-helper.service';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import { UploadDocumentComponent } from "@app/features/ai-document-parsing/upload-document/upload-document.component";
import { RenewalTokenService } from "@app/core/auth/renewal-token-service";
import { CsDatepickerComponent } from "@app/directives/input-directives/cs-datepicker/cs-datepicker.component";
registerLocaleData(localeES);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
var ɵ0 = (HttpLoaderFactory), ɵ1 = getDatepickerConfig, ɵ2 = function (SettingsService) { return SettingsService.getCsLanguage(); };
var AppModule = /** @class */ (function () {
    function AppModule(upgrade, settings) {
        this.upgrade = upgrade;
        this.settings = settings;
    }
    // Bootstrapping angularJs application first and then Angular application, this helps to upgrade components into Angular application
    AppModule.prototype.ngDoBootstrap = function (app) {
        var locale = this.settings.getCsLanguage();
        console.log('Using locale : ', locale);
        var angularJsModule = angular.module('commusoft');
        angularJsModule.constant('ALL_PERMISSIONS', this.settings.getAllPermissions());
        angularJsModule.constant('MODULE_PERMISSIONS', this.settings.getModulePermissions());
        angularJsModule.constant('CS_VERSION', this.settings.getCsVersion());
        angularJsModule.constant('CS_LANGUAGE', this.settings.getCsLanguage());
        angularJsModule.constant('LOCALE_ID', locale);
        angularJsModule.constant('CS_COUNTRY', this.settings.getCountryCode());
        angularJsModule.constant('CS_Intl', this.settings.getIntlSettings());
        angularJsModule.constant('HybridFactory', HybridFactory);
        angularJsModule.constant('prefix', environment.apiPrefixPath);
        angularJsModule.directive('appSmartTable', downgradeComponent({ component: SmartTableComponent }));
        angularJsModule.directive('appSmartTable', downgradeComponent({ component: SmartTableComponent }));
        //angularJsModule.factory('NgSmartTableMetaDataService', downgradeInjectable(SmartTableMetaDataService) as any);
        angularJsModule.factory('NgConfigKBUrlService', downgradeInjectable(ConfigKBUrlService));
        angularJsModule.factory('NgSettingsService', downgradeInjectable(SettingsService));
        angularJsModule.directive('appAddEditCertificate', downgradeComponent({ component: AddEditCertificateComponent, inputs: ['data'], outputs: ['onSidePanelClose', 'onReloadSmartTable'] }));
        angularJsModule.directive('appCsSignatureGenerator', downgradeComponent({ component: CsSignatureGeneratorComponent }));
        angularJsModule.directive('ngFsModal', downgradeComponent({ component: CsFullScreenModalComponent }));
        angularJsModule.constant('COUNTRY_LIST', this.settings.getCountryMasterData());
        HybridFactory.ngUpgrade = this.upgrade;
        window.prefixVal = environment.apiPrefixPath;
        angularJsModule.constant('webservicePrefix', environment.webservicePrefix);
        angularJsModule.constant('adminPrefix', environment.adminPrefix);
        angularJsModule.constant('mapDetails', environment.mapDetails);
        angularJsModule.directive('csFormParser', downgradeComponent({ component: DynamicCertificateFormParserComponent }));
        angularJsModule.directive('csUserPreferenceToggle', downgradeComponent({ component: CsToggleuserpreferenceComponent }));
        angularJsModule.factory('NgMicroserviceHelperService', downgradeInjectable(MicroserviceHelperService));
        angularJsModule.factory('NgRenewalTokenService', downgradeInjectable(RenewalTokenService));
        angularJsModule.directive('appDatepickerComponent', downgradeComponent({ component: CsDatepickerComponent }));
        angularJsModule.factory('V5AuthService', downgradeInjectable(V5AuthService));
        angularJsModule.directive('appUploadDocument', downgradeComponent({ component: UploadDocumentComponent }));
        // AngularJs bootstrap
        this.upgrade.bootstrap(document.body, ['commusoft'], { strictDi: true });
        setUpLocationSync(this.upgrade);
        // Angular bootstrap
        app.bootstrap(AppComponent);
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
