<ng-container>
    <ng-container *ngIf="dataUrl != '' && !loading && totalRecordCount == 0 && loadingFirstTime">
        <div class="no-results --margin-top" icon="ss-info">
            <div class="no-results-wrap">
                <div class="warning-icon-wrap with-info-icon">
                    <span class="ss-info icon fix-left">&nbsp;</span>
                </div>
                <div class="warning-message" translate>{{defaultEmptyMessage}}</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="dataUrl != '' && loadingFirstTime && loading">
        <div class="spreadsheet-loading-wrap">
         <span class="loading-spreadsheet">
            <span class="loading-x-axis"></span>
            <span class="loading-y-axis"></span>
            <div class="loading-row row-1"></div>
            <div class="loading-row row-2"></div>
            <div class="loading-row row-3"></div>
            <div class="loading-row row-4"></div>
            <div class="loading-row row-5"></div>
           </span>
        </div>
    </ng-container>

    <div class="cs-settings-table-container"
         [ngClass]="{'row-action-enabled': actions.length,
         'row-reorder-enabled':rowReorder, 'cs-processing-on': processing,
         'visibility-hidden': (dataUrl != '' &&
         loadingFirstTime && loading) || (dataUrl != '' && loadingFirstTime && !loading && totalRecordCount == 0)}">
        <ng-container *ngIf="(dtOptions | json) != '{}'">
            <div *ngIf="rowSelect" class="select-all-link tab-button" (click)="onSelectAllRows()">
                <span class="ss-check" *ngIf="!selectAllCheckbox">&nbsp;</span>
                <span class="ss-delete" *ngIf="selectAllCheckbox">&nbsp;</span>
            </div>
            <table
            id="cs-settings-table-v2-{{this.tableId}}"
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="display nowrap"
            style="width:100%;border-collapse: collapse;"></table>
        </ng-container>
    </div>
</ng-container>
