import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService extends InterfaceService {

  constructor(
    private http: HttpClient
  ) { super(); }


  // Get list of job descriptions configured
  getJobDescriptions(params?: Object): Observable<any> {
    return this.http
      .get(this.getApiUrl(`list_JobDescriptions`, params));
  }

  // Get list of user groups configured
  getUserGroups(params?: Object): Observable<any> {
    return this.http
      .get(this.getApiUrl(`listUserGroups`, params));
  }

  // Get list of Asset groups
  getAssetGroups(params?: Object): Observable<any> {
    return this.http
      .get(this.getApiUrl('contract-templates/whats-included/asset-groups', params));
  }

  // Get notification preferences
  getNotificationPreferences(params?: Object): Observable<any> {
    return this.http
      .get(this.getApiUrl('system_settings/notification_preferences', params));
  }

  /* Get settings for ppm events

       Response :
         Array<{
            data: ""/{ jobs:number, notificationGroups: Array };
            default: [{ id: number, text: string }]
         }>
  */
  getSetupJobsPpmEvents():Observable<any> {
    return this.http.get(this.getApiUrl('system_settings/ppm-jobs'));
  }

  getEmailTemplatePortalSettings(params?: Object): Observable<any> {
    return this.http.get(this.getApiUrl(`listSettingsEmailTemplates`, params));
  }

  /* Set settings for ppm events

     Request  :  Data = { jobs:number, notificationGroups: Array }
     Response :  "success"

  */
  setSetupJobsPpmEvents(Data:object):Observable<any> {
    return this.http.post(this.getApiUrl('system_settings/ppm-jobs'),JSON.stringify(Data));
  }

  postInvoiceEmailTemplate(Data: object): Observable<any> {
      return this.http.post(this.getApiUrl('system_settings/invoice_message'), JSON.stringify(Data));
  }

  postInvoiceCustomTemplate(invoiceType: string, Data: object): Observable<any> {
      return this.http.post(this.getApiUrl('system_settings/invoice_message/update/'+ invoiceType), JSON.stringify(Data));
  }
  postCustomerPoratlEmailTemplate(Data: object): Observable<any> {
      return this.http.post(this.getApiUrl('system_settings/customer_portal_email_message'), JSON.stringify(Data));
  }
  saveCustomInvoiceNames(customInvoiceName: object):Observable<any>{
      return this.http.post(this.getApiUrl('system_settings/save_custom_invoice_names'), JSON.stringify(customInvoiceName));
  }
}
