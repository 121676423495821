//--
import { HybridFactory } from './hybrid-factory';
var HybridUrlHandlingStrategy = /** @class */ (function () {
    function HybridUrlHandlingStrategy() {
    }
    HybridUrlHandlingStrategy.prototype.shouldProcessUrl = function (url) {
        // Preventing Angular navigation for non-upgraded routes
        var urlString = decodeURIComponent(url.toString());
        var isUpgraded = HybridFactory.isUpgraded(urlString);
        return !!isUpgraded;
    };
    HybridUrlHandlingStrategy.prototype.extract = function (url) {
        return url;
    };
    HybridUrlHandlingStrategy.prototype.merge = function (url, whole) {
        return url;
    };
    return HybridUrlHandlingStrategy;
}());
export { HybridUrlHandlingStrategy };
