import * as tslib_1 from "tslib";
import { map, catchError } from 'rxjs/operators';
import { InterfaceResolver } from "@app/interfaces";
import { PartsAndPricesService } from "../services/parts-and-prices.service";
var PartsAndPricesResolver = /** @class */ (function (_super) {
    tslib_1.__extends(PartsAndPricesResolver, _super);
    function PartsAndPricesResolver(dataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        return _this;
    }
    PartsAndPricesResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return this.dataService.getPartsAndPricesMetadata()
            .pipe(map(function (data) { return data; }), catchError(function (Err) {
            return _this.fail();
        }));
    };
    return PartsAndPricesResolver;
}(InterfaceResolver));
export { PartsAndPricesResolver };
