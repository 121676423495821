import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
//--
import { ContractTemplateArchiveEvent, ContractTemplateDeleteEvent, InterfaceService } from '@app/interfaces';
import { ContractTemplate } from '@app/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContractTemplatesService = /** @class */ (function (_super) {
    tslib_1.__extends(ContractTemplatesService, _super);
    function ContractTemplatesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.eventSource = new Subject();
        _this.events$ = _this.eventSource.asObservable();
        return _this;
    }
    // Initializing contract template delete process
    ContractTemplatesService.prototype.triggerDeleteContractTemplate = function (contractId, customersCount) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                _this.eventSource.next(new ContractTemplateDeleteEvent(contractId, customersCount, resolve, reject));
            }, 50);
        });
    };
    // Initializing contract template archive process
    ContractTemplatesService.prototype.triggerArchiveContractTemplate = function (contractId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                _this.eventSource.next(new ContractTemplateArchiveEvent(contractId, resolve, reject));
            }, 50);
        });
    };
    // Add new contract template
    ContractTemplatesService.prototype.addTemplate = function (data) {
        return this.http
            .post(this.getApiUrl('contract-templates/add-template'), JSON.stringify(data))
            .pipe(map(function (Response) { return new ContractTemplate(Response); }));
    };
    // Get data for contract template listing
    ContractTemplatesService.prototype.getContractTemplate = function () {
        return this.http.get(this.getApiUrl('contract-templates/details'))
            .pipe();
    };
    // Get contract template basic details
    ContractTemplatesService.prototype.getTemplate = function (id) {
        return this.http
            .get(this.getApiUrl("contract-templates/" + id + "/get-template-details"))
            .pipe(map(function (Response) { return new ContractTemplate(Response); }));
    };
    // Update contract template basic details
    ContractTemplatesService.prototype.editTemplate = function (id, data) {
        return this.http
            .put(this.getApiUrl("contract-templates/" + id + "/edit-template"), JSON.stringify(data))
            .pipe(map(function (Response) { return new ContractTemplate(Response); }));
    };
    // Get data for contract template basic details configuration form
    ContractTemplatesService.prototype.getTemplateBasicDetailsFormData = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/form-data/basic-details"))
            .pipe(map(function (Response) {
            Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);
            return Response;
        }));
    };
    // Update contract template basic details
    ContractTemplatesService.prototype.configureTemplateBasicDetails = function (templateId, data) {
        return this.http.put(this.getApiUrl("contract-templates/" + templateId + "/setup/basic-details"), JSON.stringify(data))
            .pipe();
    };
    // Get Contracts list
    ContractTemplatesService.prototype.getContractsList = function (param) {
        return this.http
            .get(this.getApiUrl('contract-templates/details', param)).pipe(map(function (Response) { return Response; }));
    };
    // Delete Contract template
    ContractTemplatesService.prototype.deleteContractTemplate = function (id) {
        return this.http
            .delete(this.getApiUrl("contract-templates/" + id + "/delete-template"))
            .pipe(map(function (Response) { return new ContractTemplate(Response); }));
    };
    // Get data for contract template billable rates configuration form
    ContractTemplatesService.prototype.getTemplateBillableRatesFormData = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/form-data/billable-rates"))
            .pipe(map(function (Response) {
            Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);
            return Response;
        }));
    };
    // Get contract template billable rates labour/travel costs
    ContractTemplatesService.prototype.getTemplateBillableRatesLTCosts = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/labour-travel-costs"))
            .pipe();
    };
    // Store contract template billable rates labour/travel costs
    ContractTemplatesService.prototype.saveTemplateBillableRatesLTCosts = function (templateId, data) {
        return this.http.put(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/labour-travel-costs/save"), JSON.stringify(data));
    };
    // Get contract template billable rates pricing items
    ContractTemplatesService.prototype.getTemplateBillableRatesPricingItems = function (templateId, params) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/pricing-items", params))
            .pipe();
    };
    // Save contract template pricing items
    ContractTemplatesService.prototype.saveTemplateBillableRatesPricingItems = function (templateId, data) {
        return this.http.put(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/pricing-items/save"), JSON.stringify(data));
    };
    // Get contract template billable rates parts pricing
    ContractTemplatesService.prototype.getTemplateBillableRatesPartsPricing = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/parts-pricing"));
    };
    // Save contract template billable rates parts pricing
    ContractTemplatesService.prototype.saveTemplateBillableRatesPartsPricing = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/billable-rates/parts-pricing"), JSON.stringify(data));
    };
    // Update contract template billable rates parts pricing record
    ContractTemplatesService.prototype.updateTemplateBillableRatesPartsPricing = function (id, data) {
        return this.http.put(this.getApiUrl("contract-templates/billable-rates/parts-pricing/" + id), JSON.stringify(data));
    };
    // Delete contract template billable rates parts pricing
    ContractTemplatesService.prototype.deleteTemplateBillableRatesPartsPricing = function (partPriceId) {
        return this.http.delete(this.getApiUrl("contract-templates/billable-rates/parts-pricing/" + partPriceId));
    };
    // Get data for contract template whats included configuration form
    ContractTemplatesService.prototype.getTemplateWhatsIncludedFormData = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/form-data/whats-included")).pipe(map(function (Response) {
            Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);
            return Response;
        }));
    };
    // Get contract template whats included job FUP
    ContractTemplatesService.prototype.getTemplateWhatsIncludedJobFUP = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/whats-included/job-fup"));
    };
    // Save contract template whats included job FUP
    ContractTemplatesService.prototype.saveTemplateWhatsIncludedJobFUP = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/whats-included/job-fup"), JSON.stringify(data));
    };
    // Delete contract template contract template whats included job FUP
    ContractTemplatesService.prototype.deleteTemplateWhatsIncludedJobFUP = function (jobFUPId) {
        return this.http.delete(this.getApiUrl("contract-templates/whats-included/job-fup/" + jobFUPId));
    };
    // Get contract template whats included ppm
    ContractTemplatesService.prototype.getTemplateWhatsIncludedPPM = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/whats-included/ppm"));
    };
    // Save contract template whats included ppm
    ContractTemplatesService.prototype.saveTemplateWhatsIncludedPPM = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/whats-included/ppm"), JSON.stringify(data));
    };
    // Delete contract template contract template whats included ppm
    ContractTemplatesService.prototype.deleteTemplateWhatsIncludedPPM = function (ppmId, data) {
        return this.http.post(this.getApiUrl("contract-templates/whats-included/ppm/" + ppmId), JSON.stringify(data));
    };
    // Get contract template whats included assets
    ContractTemplatesService.prototype.getTemplateWhatsIncludedAssets = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/whats-included/assets"));
    };
    // Save contract template whats included assets
    ContractTemplatesService.prototype.saveTemplateWhatsIncludedAssets = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/whats-included/assets"), JSON.stringify(data));
    };
    // Changing contract status
    ContractTemplatesService.prototype.changeContractStatus = function (templateId, data) {
        return this.http.put(this.getApiUrl("contract-templates/" + templateId + "/change-status"), JSON.stringify(data));
    };
    // Get data for contract template invoicing configuration form
    ContractTemplatesService.prototype.getTemplateInvoicingFormData = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/form-data/invoicing")).pipe(map(function (Response) {
            Response['templateDetails'] = new ContractTemplate(Response['templateDetails']);
            return Response;
        }));
    };
    ContractTemplatesService.prototype.saveTemplateInvoicingSettings = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/invoicing/settings"), JSON.stringify(data));
    };
    ContractTemplatesService.prototype.getTemplateInvoicingDate = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/invoicing/settings-data"));
    };
    ContractTemplatesService.prototype.saveTemplateInvoiceBillingOption = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/invoicing/billing-option"), JSON.stringify(data));
    };
    ContractTemplatesService.prototype.getTemplateInvoicingDateEdit = function (templateId, invoicingId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/invoicing/edit/" + invoicingId));
    };
    ContractTemplatesService.prototype.deleteTemplateInvoiceOption = function (templateId, invoicingId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/invoicing/delete/" + invoicingId));
    };
    ContractTemplatesService.prototype.saveTemplateInvoiceBillingSetPriceChanges = function (templateId, data) {
        return this.http.post(this.getApiUrl("contract-templates/" + templateId + "/invoicing/set-price-changes"), JSON.stringify(data));
    };
    // Get list of job descriptions configured
    ContractTemplatesService.prototype.listCtJobDescriptions = function (params) {
        return this.http
            .get(this.getApiUrl("list_ctJobdescriptions", params));
    };
    ContractTemplatesService.prototype.getCtJobdescriptions = function (templateId) {
        return this.http.get(this.getApiUrl("contract_templates/" + templateId + "/whats-included/ppm/get-job-description"));
    };
    ContractTemplatesService.prototype.saveCtJobDescriptions = function (templateId, data) {
        return this.http.put(this.getApiUrl("contract_templates/" + templateId + "/whats-included/ppm/job-description"), JSON.stringify(data));
    };
    ContractTemplatesService.prototype.checkContractTemplateStatus = function (templateId) {
        return this.http.get(this.getApiUrl("contract-templates/" + templateId + "/get-template-details"));
    };
    ContractTemplatesService.ngInjectableDef = i0.defineInjectable({ factory: function ContractTemplatesService_Factory() { return new ContractTemplatesService(i0.inject(i1.HttpClient)); }, token: ContractTemplatesService, providedIn: "root" });
    return ContractTemplatesService;
}(InterfaceService));
export { ContractTemplatesService };
