import { Injectable } from '@angular/core';
import {interval, timer} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {MicroserviceHelperService} from "@app/shared/microservice/helper/microservice-helper.service";
import {V5AuthService} from "@app/core";


@Injectable({
    providedIn: 'root',
})
export class RenewalTokenService {
    private renewalIntervalMs: number;
    private isRunning:boolean = false;
    private timerSubscription;


    constructor(
        private readonly tokenService: MicroserviceHelperService,
        private readonly authService: V5AuthService
    ) {
       // this.start();
    }

    getRenewalInterval(): Promise<any> {
        return new Promise((resolve, reject ) => {
            let timestamp = this.tokenService.getTokenInterval();
            let accessToken = this.tokenService.getToken();
            setTimeout(() => {
                if(typeof timestamp == "number" && accessToken) {
                    this.renewalIntervalMs = timestamp;
                    resolve(timestamp);
                }
                else {
                    reject("Not resolved token interval.");
                }
            });

        });
    }

    private currentInterval():number {
        //one sec reduced from the actual expiry
        return ((this.renewalIntervalMs - (new Date()).getTime()) - 1000);
    }

    public start() {
        // this currently deprecated.
        if(this.isRunning) {
            return;
        }

        this.getRenewalInterval().then((timestamp) => {
            this.isRunning = true;
            this.renewalIntervalMs = timestamp;
            const duration = this.currentInterval();
            console.info('Hi, Renewal token interval initiated.', duration);
            if(duration <= 0) {
                this.isRunning = false;
                this.authService.renewToken()
                    .then( (resp) => {
                        this.tokenService.setToken(resp['renewalToken'])
                        this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                        this.start();
                    });
                return;
            }
            this.isRunning = true;
            this.timerSubscription = timer(duration).pipe(
                switchMap(() => {
                    console.log('Hi, Access token requested.');
                    this.isRunning = false;
                    return this.authService.renewToken();
                })
            )
            .subscribe( (resp) => {
                console.log('Access token refreshed successfully.', (new Date()).getMinutes());
                if(resp['renewalToken']) {
                    this.isRunning = false;
                    this.tokenService.setToken(resp['renewalToken'])
                    this.tokenService.setTokenInterval(resp['nextRenewalTime']);
                    this.start();
                }
            }, (err) => {
                this.isRunning = false;
                console.error(err);
            })
        }, () => {
            this.stop();
            console.log("Token interval handling error....")
        });
    }

    public getTokenService() {
        return this.tokenService;
    }

    public stop() {
        this.tokenService.clearTokenInterval();
        this.tokenService.clearToken();
        this.isRunning = false;
        console.info("Hoo, v5Token cleared!");
        if(this.timerSubscription) {
            console.info("The token renewal timer stopped!");
            this.timerSubscription.unsubscribe();
        }
    }

    public getAuthService() {
        return this.authService;
    }
}
