import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MicroserviceErrorNotificationComponent
} from './microservice-error-notification/microservice-error-notification.component';
import {DirectivesModule} from '@app/directives';

@NgModule({
    declarations: [
        MicroserviceErrorNotificationComponent
    ],
    imports: [
        CommonModule,
        DirectivesModule
    ],
    exports: [
        MicroserviceErrorNotificationComponent
    ],
})
export class MicroserviceModule {
}
