import {Inject, Injectable} from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { InterfaceService } from "@app/interfaces";

@Injectable({
  providedIn: 'root',
})
export class SessionLogService extends InterfaceService {
  private startTime: number;
  private endTime: number;

  constructor(
      private http: HttpClient,
  ) {
    super();
  }

  sessionStartTime(time:number){
    this.startTime = time;
  }

  sessionEndTime(time:number){
    this.endTime = time;
  }

  getDuration(){
    if (typeof this.endTime === 'number' && typeof this.startTime === 'number') {
      return this.endTime - this.startTime;
    }else{
        return null;
    }
  }

  //send data backend
  addToSessionLog(clientId:string = '',username:string = '', url: string, timeTaken: number): Observable<any> {
    let object = {
      'clientId': clientId,
      'username': username,
      'url': url,
      'timeTaken': timeTaken.toString(),
    };
    return this.http.post(this.getApiUrl(`add_to_sessionlog`), object);
  }
}
