/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./validation-error.component";
var styles_ValidationErrorComponent = [];
var RenderType_ValidationErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValidationErrorComponent, data: {} });
export { RenderType_ValidationErrorComponent as RenderType_ValidationErrorComponent };
function View_ValidationErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_ValidationErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "control-error-message show"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["customMessageContent", 1]], null, 1, "span", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationErrorComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !i0.ɵnov(_v, 1).innerHTML.trim(); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ValidationErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationErrorComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errorMessage !== null); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ValidationErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "validation-error", [], null, null, null, View_ValidationErrorComponent_0, RenderType_ValidationErrorComponent)), i0.ɵprd(512, null, i3.ValidationDepotService, i3.ValidationDepotService, []), i0.ɵdid(2, 49152, null, 0, i3.ValidationErrorComponent, [i3.ValidationDepotService], null, null)], null, null); }
var ValidationErrorComponentNgFactory = i0.ɵccf("validation-error", i3.ValidationErrorComponent, View_ValidationErrorComponent_Host_0, { formGroup: "formGroup", controlName: "controlName", showOnDirty: "showOnDirty" }, {}, ["*"]);
export { ValidationErrorComponentNgFactory as ValidationErrorComponentNgFactory };
var styles_ValidationCustomErrorMessageComponent = [];
var RenderType_ValidationCustomErrorMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValidationCustomErrorMessageComponent, data: {} });
export { RenderType_ValidationCustomErrorMessageComponent as RenderType_ValidationCustomErrorMessageComponent };
function View_ValidationCustomErrorMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ValidationCustomErrorMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationCustomErrorMessageComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.validation === _co.activeValidation); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ValidationCustomErrorMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "error-message", [], null, null, null, View_ValidationCustomErrorMessageComponent_0, RenderType_ValidationCustomErrorMessageComponent)), i0.ɵdid(1, 180224, null, 0, i3.ValidationCustomErrorMessageComponent, [i3.ValidationDepotService], null, null)], null, null); }
var ValidationCustomErrorMessageComponentNgFactory = i0.ɵccf("error-message", i3.ValidationCustomErrorMessageComponent, View_ValidationCustomErrorMessageComponent_Host_0, { validation: "validation" }, {}, ["*"]);
export { ValidationCustomErrorMessageComponentNgFactory as ValidationCustomErrorMessageComponentNgFactory };
