<div class="modal-header">
    <h4 class="modal-title pull-left">Schedule activity block</h4>
</div>

<div class="modal-body {{name}}">
    <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <app-condition-edge *ngIf="isConditional"
        [inputModel]="inputModel"
        [operators]="operators"
        [isMarketPlaceView]="isMarketPlaceView"
        [isReadOnlyMode]="isReadOnlyMode"
        [activeField] = "activeField">
    </app-condition-edge>

    <div class="item form-item p-15">
        <div class="control">
            <label class="control-label required_field">Description:</label>
            <input type="text" class="w-100" [(ngModel)]="inputModel.model['description']"/>
        </div>
        <div class="control">
            <label class="control-label">Notes:</label>
            <textarea type="text" class="w-100" rows="5" [(ngModel)]="inputModel.model['notes']"></textarea>
        </div>
        <div class="control cs-trigger">
            <label class="control-label required_field">Activity type:</label>
            <div class="btn-group cs-schedule-weekly" btnRadioGroup [(ngModel)]="inputModel.model['activityType']">
                <label *ngFor="let type of activityTypes"
                   class="btn btn-primary" [btnRadio]="type.id"
                   tabindex="0" role="button">
                    <i class="{{iconClassNames[type?.name]}}" aria-hidden="true"></i> {{type?.name}}
                </label>
            </div>
        </div>
        <hr style="float: none;">
        <div class="control">
            <label class="control-label">Due date:</label>
            <div class="cs-d-flex align-items-center">
                <div class="control-label">Current date</div>
                <div class="ml-10">+</div>
                <div class="ml-10">
                    <input type="text" class="control-input" style="width: 50px; margin:0" appDigitDecimaNumber maxlength="2" [(ngModel)]="inputModel.model['scheduleInterval']"/>
                </div>
                <div class="ml-10">
                    <select class="full-width-select" style="width: 80px; margin: 0" [(ngModel)]="inputModel.model['unit']">
                        <option *ngFor="let unit of units" value="{{unit}}">{{inputModel.model['scheduleInterval'] == 1 ? unit.slice(0, -1) : unit}}</option>
                    </select>
                </div>
                <div class="ml-10">at</div>
                <div class="ml-10">
                    <cs-timepicker [(ngModel)]="timeModel" [meridian]="false" (valueChanged)="onChangeTime($event)" class=""></cs-timepicker>
                </div>
            </div>
        </div>
        <hr style="float: none;">
        <div class="control">
            <label class="control-label">Assign to:</label>
            <div>
                <input
                    id="assign-to-input"
                    csSelect2
                    (selected)="selectedAsignee($event)"
                    [(ngModel)]="inputModel.model['assigneeIds']"
                    [dropdownvalue]="inputModel.model['assigneeIds']"
                    [select2Config]="select2LabelConfig"
                    class="full-width-select2 cs-select2-wfe mb-10"/>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn" (click)="removeCell()" class="btn btn-danger btn-medium float-left" type="button">Delete</button>
    <button class="btn btn-primary" (click)="addValue()" [disabled]="validateItems()"><span translate>Save</span></button>
</div>
