<div class="comm-layout-container h-100">
    <app-communication-segment *ngIf="allowSegmentation"></app-communication-segment>
    <app-template-form
        [roleList]="roleList"
        [previousRolesList]="previousRolesList"
        [selectedTemplateData]="selectedTemplateData"
        [viewMode]="viewMode"
        (selectedRolesEvent)="selectedRoles($event)"></app-template-form>
    <app-communication-template-view></app-communication-template-view>
</div>
