import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';


@Injectable()
export class ArticleEditorService extends InterfaceService {

    private $blockEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public blockEmitted: Observable<any> = this.$blockEmitted.asObservable();

    private $pageEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public pageEmitted: Observable<any> = this.$pageEmitted.asObservable();

    private $currentPage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentPage: Observable<any> = this.$currentPage.asObservable();

    public $template: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public template: Observable<any> = this.$template.asObservable();

    public $targetPage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public targetPage: Observable<any> = this.$targetPage.asObservable();

    private $settingEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public settingEmitted: Observable<any> = this.$settingEmitted.asObservable();

    private $blockSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public blockSelected: Observable<any> = this.$blockSelected.asObservable();

    constructor(private http: HttpClient) { super() }
    triggerEditor(action) {
        this.$blockEmitted.next(action)
    }

    triggerSettings(action) {
        this.$settingEmitted.next(action)
    }

    emitPages(action) {
        this.$pageEmitted.next(action)
    }
    pageContent(action) {
        this.$currentPage.next(action)
    }
    setTemplate(template) {
        this.$template.next(template)
    }
    addPages(url, data) {
        return this.http.post(this.getApiUrl(url), data)
    }

    savePages(url ,data) {
        return this.http.post(this.getApiUrl(url), data)
    }

    sendTargetPage(details) {
        this.$targetPage.next(details)
    }

    blockSelection(choosed) {
        this.$blockSelected.next(choosed)
    }
    saveEditorScreenshotImages(data){
        return this.http.post(this.getApiUrl(`editorThumbnailUpdate`), data)
    }
    editorFileUpload(data,templatesId=''){
        const formData: any = new FormData();
        const xhr = new XMLHttpRequest();
        formData.append('file', data, data.name);
        return this.http.post(this.getApiUrl(`uploadPDF?templatesId=${templatesId}`), formData)
    }
}
