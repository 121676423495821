import { EventEmitter, OnInit } from '@angular/core';
var CsInformationBarComponent = /** @class */ (function () {
    function CsInformationBarComponent() {
        this.showCloseBtn = false;
        this.information_data = [];
        this.supports_multiple = false;
        this.response = new EventEmitter();
        this.notification_bar = false;
        this.single_notification_bar = true;
        this.information_open = true;
    }
    CsInformationBarComponent.prototype.ngOnInit = function () {
    };
    CsInformationBarComponent.prototype.closeInfobar = function () {
        this.notification_bar = false;
    };
    CsInformationBarComponent.prototype.toggleInformationView = function () {
        this.information_open = !this.information_open;
    };
    CsInformationBarComponent.prototype.handleDraftInvoiceAction = function () {
        this.response.emit({ 'status': 'success' });
    };
    return CsInformationBarComponent;
}());
export { CsInformationBarComponent };
