/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cs-medium-screen-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cs-medium-screen-panel.component";
import * as i3 from "../services/sidepanel-options";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../features/customers/add-opportunity/service/add-opportunity.service";
var styles_CsMediumScreenPanelComponent = [i0.styles];
var RenderType_CsMediumScreenPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CsMediumScreenPanelComponent, data: {} });
export { RenderType_CsMediumScreenPanelComponent as RenderType_CsMediumScreenPanelComponent };
export function View_CsMediumScreenPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["role", "document"], ["style", "height: 100%"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-content"], ["style", "height: 100%"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("modal-dialog" + (_co.config.class ? (" " + _co.config.class) : "")); _ck(_v, 0, 0, currVal_0); }); }
export function View_CsMediumScreenPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cs-medium-screen-panel", [["class", "modal cs-fullscreen-panel-v2"], ["role", "dialog"], ["tabindex", "-1"]], [[1, "aria-modal", 0], [1, "aria-labelledby", 0], [1, "aria-describedby", 0]], [[null, "click"], ["window", "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CsMediumScreenPanelComponent_0, RenderType_CsMediumScreenPanelComponent)), i1.ɵdid(1, 245760, null, 0, i2.CsMediumScreenPanelComponent, [i3.SidepanelOptions, i1.ElementRef, "confirmationBoxHelper", "$scope", i4.TranslateService, i5.AddOpportunityService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).config.ariaLabelledBy; var currVal_2 = i1.ɵnov(_v, 1).config.ariaDescribedby; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var CsMediumScreenPanelComponentNgFactory = i1.ɵccf("app-cs-medium-screen-panel", i2.CsMediumScreenPanelComponent, View_CsMediumScreenPanelComponent_Host_0, {}, {}, ["*"]);
export { CsMediumScreenPanelComponentNgFactory as CsMediumScreenPanelComponentNgFactory };
