import {ModalBackdropComponent} from "ngx-bootstrap";
import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from "@angular/core";
import {CLASS_NAME} from "@app/sidepanel/services/sidepanel-options";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'cs-modal-backdrop',
    template: `
    `,
    styleUrls: ['./cs-modal-backdrop.component.scss'],
    host: { class: CLASS_NAME.BACKDROP }
})
export class CsModalBackdropComponent extends ModalBackdropComponent implements OnInit {
    modalsCount: number;
    constructor(element: ElementRef, renderer: Renderer2) {
        super(element, renderer);
    }
    ngOnInit() {
        super.ngOnInit();
        if(this.modalsCount) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-'+this.modalsCount);
        }
    }
}
