<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>Add block</h4>
    </div>
    <div class="modal-body ng-workflow-add-block" [hidden]="!showTaskBlock">        <!--<div *ngIf="options.isRunningStatus" class="wfe-block-notification-bar">
            <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
        </div>-->
        <ng-container *ngFor="let item of blocksList">
            <div class="body-content-section b-bottom bg-white px-15 title-section">
                <div class="row-fluid">
                    <div class="span12">{{item.title}}</div>
                </div>
            </div>
            <div *ngIf="item.title != 'Branching blocks'" class="body-content-section b-bottom">
                <div class="cs-block-view">
                    <ng-container *ngFor="let block of item.blocks">
                        <div class="cs-block text-center" *ngIf="block.visible" (click)="openCloseBlockConfig( block.id, null, isDisabled.indexOf(block.id) > -1 )"
                             [ngClass]="{'selected': selectedBlock=== block.id, 'disabled': isDisabled.indexOf(block.id) > -1 }">
                            <div class="wf-icon">
                                <i class="{{block.iconClass}}"></i>
                            </div>
                            <div class="block-title">{{ block.title }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="item.title == 'Branching blocks'" class="body-content-section b-bottom">
                <div class="cs-block-view">
                    <ng-container *ngFor="let block of item.blocks">
                        <div class="cs-block text-center" *ngIf="block.visible" tooltip="{{ toolTipMessage }}" placement="top" (click)="openCloseBlockConfig( block.id, null, isDisabled.indexOf(block.id) > -1 )" [ngClass]="{'selected': selectedBlock=== block.id, 'disabled': isDisabled.indexOf(block.id) > -1}">
                            <!--<div class="wf-icon" [innerHTML]="block.icon | keepHtml"></div>-->
                            <div class="wf-icon">
                                <i class="{{block.iconClass}}"></i>
                            </div>
                            <div class="block-title">{{ block.title }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="modalRef.hide()">Cancel</a>
    </div>

    <!--conditional delay block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='conditionalDelayBlock'}">
        <app-conditional-delay-block *ngIf="selectedBlockModel['name'] === 'conditionalDelayBlock'"
            [diagramOptions]="options"
            [isConditional]="isConditional"
            [isEdit]="isEdit"
            [activeField]="activeField"
            [operators]="operators"
            [inputModel]="selectedBlockModel"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [isReadOnlyMode]="isReadOnlyMode"
            [workflowType]="workflowType"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-conditional-delay-block>
    </div>
    <!--delay block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='delayBlock'}">
        <app-delay-block *ngIf="selectedBlockModel['name'] === 'delayBlock'"
             [diagramOptions]="options"
             [isConditional]="isConditional"
             [isEdit]="isEdit"
             [activeField]="activeField"
             [operators]="operators"
             [inputModel]="selectedBlockModel"
             [isMarketPlaceView]="isMarketPlaceView"
             [status] = "options.isRunningStatus"
             [deleteMessage] = "deleteMessage"
             [warningMessage] = "editBlockWarningMessage"
             [isReadOnlyMode]="isReadOnlyMode"
             [workflowType]="workflowType"
             (outputModel)="addBlockConfiguration($event)"
             (close)="closeBlockModal($event)">
        </app-delay-block>
    </div>
    <!--delay block end-->

    <!--app-scheduledactivity block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='scheduledActivityBlock'}">
        <app-scheduledactivity-block
            *ngIf="selectedBlockModel['name'] === 'scheduledActivityBlock'"
            [workflowType]="workflowType"
            [diagramOptions]="options"
            [inputModel]="selectedBlockModel"
            [isEdit]="isEdit"
            [hasChild]="hasChild"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [isConditional]="isConditional"
            [allFields]="selectedTriggerFields"
            [usersList]="options?.usersList"
            [activityTypes]="options?.scheduleActivityTypes"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [isReadOnlyMode]="isReadOnlyMode"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-scheduledactivity-block>
    </div>

    <!--setCustomFieldValue block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='setcustomfieldvalueBlock'}">
        <app-setcustomfieldvalue-block
            *ngIf="selectedBlockModel['name'] === 'setcustomfieldvalueBlock'"
            [inputModel]="selectedBlockModel"
            [isEdit]="isEdit"
            [hasChild]="hasChild"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [isConditional]="isConditional"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [isReadOnlyMode]="isReadOnlyMode"
            [allFields]="selectedTriggerFields"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-setcustomfieldvalue-block>
    </div>

    <!--conditional block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='conditionalBlock'}">
        <app-conditional-block
            *ngIf="selectedBlockModel['name'] === 'conditionalBlock'"
            [inputModel]="selectedBlockModel"
            [isEdit]="isEdit"
            [hasChild]="hasChild"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [operators]="operators"
            [isReadOnlyMode]="isReadOnlyMode"
            (outputModel)="addBlockConfiguration($event)"
            [isConditional]="isConditional"
            (close)="closeBlockModal($event)">
        </app-conditional-block>
    </div>

    <!--conditional block start-->
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open':showBlockConfig==='untilConditionBlock'}">
        <app-untilcondition-block
            *ngIf="selectedBlockModel['name'] === 'untilConditionBlock'"
            [inputModel]="selectedBlockModel"
            [isEdit]="isEdit"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [isReadOnlyMode]="isReadOnlyMode"
            (outputModel)="addBlockConfiguration($event)"
            [isConditional]="isConditional"
            (close)="closeBlockModal($event)">
        </app-untilcondition-block>
    </div>

    <div class="pos-abt-full wf-block bg-white" [ngClass]="{'open':showBlockConfig==='emailBlock'}">
        <app-email-block *ngIf="selectedBlockModel['name'] === 'emailBlock'"
            [workflowType]="workflowType"
            [diagramOptions]="options"
            [isConditional]="isConditional"
            [isEdit] = "isEdit"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [inputModel]="selectedBlockModel"
            [isReadOnlyMode]="isReadOnlyMode"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-email-block>
    </div>

    <div class="pos-abt-full wf-block-email wf-block bg-white" [ngClass]="{'open':showBlockConfig==='officeTaskBlock'}">
        <app-officetask-block *ngIf="selectedBlockModel['name'] === 'officeTaskBlock'"
            [workflowType]="workflowType"
            [diagramOptions]="options"
            [isConditional]="isConditional"
            [isEdit] = "isEdit"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [inputModel]="selectedBlockModel"
            [taggingList]="options['tags']"
            [mainObjectName]="mainObjectName"
            [isReadOnlyMode]="isReadOnlyMode"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-officetask-block>
    </div>

    <div class="pos-abt-full wf-block-email wf-block bg-white" [ngClass]="{'open':showBlockConfig==='addjobBlock'}">
        <app-addjob-block *ngIf="selectedBlockModel['name'] === 'addjobBlock'"
            [isConditional]="isConditional"
            [isEdit] = "isEdit"
            [isMarketPlaceView]="isMarketPlaceView"
            [status] = "options.isRunningStatus"
            [activeField]="activeField"
            [operators]="operators"
            [deleteMessage] = "deleteMessage"
            [warningMessage] = "editBlockWarningMessage"
            [inputModel]="selectedBlockModel"
            [isReadOnlyMode]="isReadOnlyMode"
            (outputModel)="addBlockConfiguration($event)"
            (close)="closeBlockModal($event)">
        </app-addjob-block>
    </div>

    <div class="pos-abt-full wf-block-email wf-block bg-white" [ngClass]="{'open':showBlockConfig==='chatmessageBlock'}">
        <app-chatmessage-block *ngIf="selectedBlockModel['name'] === 'chatmessageBlock'"
           [isConditional]="isConditional"
           [isEdit] = "isEdit"
           [isMarketPlaceView]="isMarketPlaceView"
           [activeField]="activeField"
           [operators]="operators"
           [inputModel]="selectedBlockModel"
           [taggingList]="options['tags']"
           [mainObjectName]="mainObjectName"
           [isReadOnlyMode]="isReadOnlyMode"
           (outputModel)="addBlockConfiguration($event)"
           (close)="closeBlockModal($event)">
        </app-chatmessage-block>
    </div>

    <div class="pos-abt-full wf-block bg-white" [ngClass]="{'open':showBlockConfig==='smsBlock'}">
        <cs-workflow-sms-block *ngIf="selectedBlockModel['name'] === 'smsBlock'"
           [workflowType]="workflowType"
           [diagramOptions]="options"
           [isConditional]="isConditional"
           [isEdit] = "isEdit"
           [activeField]="activeField"
           [operators]="operators"
           [inputModel]="selectedBlockModel"
           [isMarketPlaceView]="isMarketPlaceView"
           [status] = "options.isRunningStatus"
           [deleteMessage] = "deleteMessage"
           [warningMessage] = "editBlockWarningMessage"
           [isReadOnlyMode]="isReadOnlyMode"
           (outputModel)="addBlockConfiguration($event)"
           (close)="closeBlockModal($event)">
        </cs-workflow-sms-block>
    </div>

    <div class="pos-abt-full wf-block-email wf-block bg-white" [ngClass]="{'open':showBlockConfig==='notificationBlock'}">
        <app-notification-block *ngIf="selectedBlockModel['name'] === 'notificationBlock'"
          [workflowType]="workflowType"
          [diagramOptions]="options"
          [isConditional]="isConditional"
          [isEdit] = "isEdit"
          [isMarketPlaceView]="isMarketPlaceView"
          [status] = "options.isRunningStatus"
          [activeField]="activeField"
          [operators]="operators"
          [userRoles]="options['userRoles']"
          [taggingList]="options['tags']"
          [inputModel]="selectedBlockModel"
          [deleteMessage] = "deleteMessage"
          [warningMessage] = "editBlockWarningMessage"
          [mainObjectName]="mainObjectName"
          [isReadOnlyMode]="isReadOnlyMode"
          (outputModel)="addBlockConfiguration($event)"
          (close)="closeBlockModal($event)">
        </app-notification-block>
    </div>
</ng-container>
