/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cs-sidepanel.component";
import * as i3 from "@ngx-translate/core";
var styles_CsSidepanelComponent = [];
var RenderType_CsSidepanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CsSidepanelComponent, data: {} });
export { RenderType_CsSidepanelComponent as RenderType_CsSidepanelComponent };
function View_CsSidepanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["id", "page-overlay"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "in-view": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.sidePanelIsShowing); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CsSidepanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "in-view": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CsSidepanelComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "with-help-text ", _co.sidePanelClass, ""); var currVal_2 = _ck(_v, 2, 0, _co._showPanel); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co._showPanel; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.sidePanelId, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_CsSidepanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cs-sidepanel", [], null, [["document", "click"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CsSidepanelComponent_0, RenderType_CsSidepanelComponent)), i0.ɵdid(1, 114688, null, 0, i2.CsSidepanelComponent, ["$scope", "confirmationBoxHelper", i3.TranslateService, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CsSidepanelComponentNgFactory = i0.ɵccf("cs-sidepanel", i2.CsSidepanelComponent, View_CsSidepanelComponent_Host_0, { _panelName: "name", autoCloseWarning: "autoCloseConfirmation", sidePanelId: "panelId", sidePanelClass: "panelClass", showSidePanel: "show" }, { panelCloseEvent: "close" }, ["*"]);
export { CsSidepanelComponentNgFactory as CsSidepanelComponentNgFactory };
