import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    CommunicationDispatcherService
} from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import {CommunicationHttpService} from "@app/shared/communication-mapping/services/communication-http.service";
import {InputModel, TemplateModel} from "@app/shared/communication-mapping/services/input-model";
import {Subscription} from "rxjs";
import {CommunicationHelperService} from "@app/shared/communication-mapping/services/communication-helper.service";

@Component({
  selector: 'app-communication-layout',
  templateUrl: './communication-layout.component.html',
  styles: []
})
export class CommunicationLayoutComponent implements OnInit, OnDestroy {

    @Input() objectName: string = '';
    @Input() isCustomerSpecific: boolean = false;
    @Input() allowSegmentation: boolean = false;
    @Input() communicationScreenType: any = null;
    @Input() allowTypes: any = ['email', 'sms'];
    @Input() objectId: null|number = null; //tablePkId
    @Input() objectTypeId: string = '' //tableType
    @Input() categoryType: 'communication'|'notification' = 'communication';
    @Input() selectedTemplateData:any = {};

    @Output() $instance: EventEmitter<any> = new EventEmitter<any>();
    @Input() viewMode = false;

    subscription: Subscription;
    inputModel: InputModel = {
        communicationId: 0,
        customerId: null,
        allowTypes: this.allowTypes,
        templates: [],
        segmentationType1: null,
        segmentationType2: null,
        allowSegmentation: this.allowSegmentation,
        activeSegmentationValue1: null,
        activeSegmentationValue2: null,
        objectId: this.objectId,
        objectTypeId: this.objectTypeId,
        subject: null,
        message: null,
        title: null
    };
    isLoading: boolean = true;
    emailTemplateList: any = [];
    smsTemplateList: any = [];
    isValidFormData: boolean = false;
    roleList:any = [];
    previousRolesList:any = [];
    selectedRoleIdsList:any = [];
    clientDetails: any = {};


    constructor(
        private httpService: CommunicationHttpService,
        private eventDispatcher: CommunicationDispatcherService,
        private helperService: CommunicationHelperService,
    ) { }

    async ngOnInit() {
        // await this.getEmailTemplates();
        // await this.getSmsTemplates();
        await this.getData(this.objectName, this.categoryType, this.communicationScreenType);
        this.isLoading = false;
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: (data: any) => {
                if(typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                if(action == CommunicationDispatcherService.CONSTANTS['updateTemplateDetails']) {
                    let isValid = this.checkIsValidForm();
                    this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['checkIsValidForm'], {
                        isValid
                    })
                }
                else if(action == CommunicationDispatcherService.CONSTANTS['saveAndClose']) {
                    // console.log('saveAndClose');
                    this.saveCommunication();
                }
                else if(action == CommunicationDispatcherService.CONSTANTS['save']) {
                    this.saveCommunication();
                }
            }
        });

        this.eventDispatcher.dispatchEvent('event:loadedData', {
            data: {
                inputModel: this.inputModel,
                smsTemplateList: this.smsTemplateList,
                emailTemplateList: this.emailTemplateList,
                clientDetails: this.clientDetails,
                layoutComponent: this
            }
        });
        this.$instance.emit(this);
    }

    ngOnDestroy(): void {
        console.info('destroyed/unsubscribe communication layout component');
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    selectedRoles(roles){
        this.selectedRoleIdsList = roles;
        let isValid = this.checkIsValidForm();
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['checkIsValidForm'], {
            isValid
        })
    }

    checkIsValidForm() {
/*        if(this.isCustomerSpecific) {
            if(!this.inputModel['customerId']) {
                this.isValidFormData=false;
                return false;
            }
        }
        if(this.allowSegmentation) {
            if(!this.inputModel['segments'].length) {
                this.isValidFormData=false;
                return false;
            }
        }
        if(!this.inputModel.emailTemplateId && !this.inputModel.smsTemplateId) {
            this.isValidFormData=false;
            return false;
        }
        this.isValidFormData=true;*/
        if((this.selectedRoleIdsList.length || this.categoryType == 'communication') && this.inputModel.templates.length){
            return true;
        }
        return false;
    }

    async getData(objectName, categoryType, screenType) {
        return new Promise( (resolve, reject) => {
            const params = {};
            if(this.objectTypeId) {
                params['objectTypeId'] = this.objectTypeId;
            }
            if(this.objectId) {
                params['objectId'] = this.objectId;
            }

            this.httpService.getCommunicationByScreenType(objectName, categoryType, screenType, params)
            .toPromise()
            .then( (resp) => {
                let _data = resp['data'];
                if(this.categoryType == 'notification'){
                    this.roleList = resp['roles'];
                    this.previousRolesList =  resp['notificationPreferences'];

                }
                if(_data['communicationId'] != 0) {
                    this.inputModel.communicationId = _data['communicationId'];
                    this.inputModel.segmentationType1 = _data['segmentationType1'];
                    this.inputModel.segmentationType2 = _data['segmentationType2'];
                }
                this.inputModel.templates = this.loadTemplates(_data);
                this.clientDetails = resp['clientDetails']
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        });
    }

    loadTemplates(_data) {
        /*if( (!_data.hasOwnProperty('templates') || _data['templates'].length == 0) && Object.keys(this.selectedTemplateData).length === 0 ){
            return _data['defaultTemplates'];
        }
        else */if(this.selectedTemplateData && this.selectedTemplateData.hasOwnProperty('templates')){
            return this.selectedTemplateData['templates'];
        }
        else if(_data.hasOwnProperty('templates')){
            return _data['templates'];
        }
    }

    async getEmailTemplates() {
        return new Promise((resolve, reject) => {
            this.httpService.getEmailTemplate()
            .toPromise()
            .then( (resp) => {
                if(resp['emailTemplates']) {
                    this.emailTemplateList = resp['emailTemplates']
                }
                console.log('email', resp)
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        });
    }

    async getSmsTemplates() {
        return new Promise( (resolve, reject ) => {
            this.httpService.getSMSTemplate()
            .toPromise()
            .then( (resp ) => {
                if(resp['smsTemplates']) {
                    this.smsTemplateList = resp['smsTemplates']
                }
                console.log('sms', resp)
                resolve(resp);
            },
            (err) => {
                reject(err);
            })
        })
    }

    saveCommunication() {
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['beforeSaveAction'],{});
        const {communicationId, segmentationType1, segmentationType2, templates, customerId } = this.inputModel;
        const communication = {
            communicationId,
            segmentationType1,
            segmentationType2,
            customerId,
            templates,
            objectId: this.objectId,
            objectTypeId: this.objectTypeId,
            allowSegmentation: this.allowSegmentation,
            roleIds: this.selectedRoleIdsList
        };
        if(this.objectTypeId != '' && this.objectId == 0) {
            this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['inlineSaveAction'], {
                formData: { communication }
            });
        }
        else {
            this.httpService.saveCommunication(this.objectName, this.categoryType, this.communicationScreenType, { communication })
            .subscribe( {
                next: (resp) => {
                    this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['afterSaveAction'],{
                        'response': resp
                    });
                }
            })
        }
    }
}
