import { AfterViewInit, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
import { fontfamilyService } from "@app/shared/services/fontfamily.service";
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { AuthService } from "@app/core";
var ConfigureAdvanceStylingComponent = /** @class */ (function () {
    function ConfigureAdvanceStylingComponent(bsModalRef, opportunityService, fontFamilyService, proposaltemplateservice, authService) {
        this.bsModalRef = bsModalRef;
        this.opportunityService = opportunityService;
        this.fontFamilyService = fontFamilyService;
        this.proposaltemplateservice = proposaltemplateservice;
        this.authService = authService;
        this.tableHeaders = [];
        this.fontFamilyList = this.fontFamilyService.getFontFamilyArr();
        this.FontWeightList = [
            { name: 'Bold', value: 600, visibility: true },
            { name: 'Semi-Bold', value: 400, visibility: true },
            { name: 'Medium', value: 300, visibility: true },
            { name: 'Light', value: 100, visibility: true },
        ];
        this.colorPalateValue = '';
        this.existingValues = [];
        this.existingValuesBackup = [];
        this.any = 0;
        this.hashId = '';
        //content block
        this.contentBlockBorderWidth = 0;
        this.contentBlockBorderWidthTop = 0;
        this.contentBlockBorderWidthBottom = 0;
        this.contentBlockBorderWidthLeft = 0;
        this.contentBlockBorderWidthRight = 0;
        this.contentBlockBorderWidthIndividualSide = false;
        this.contentBlockBorderColor = "No colour";
        this.contentBlockBorderRadius = 0;
        this.contentBlockBorderRadiusIndividualSide = false;
        this.contentBlockBorderRadiusTopLeft = 0;
        this.contentBlockBorderRadiusTopRight = 0;
        this.contentBlockBorderRadiusBottomRight = 0;
        this.contentBlockBorderRadiusBottomLeft = 0;
        this.contentBlockBackgroundColor = "#FFFFFF";
        this.contentBlockPadding = 0;
        this.contentBlockPaddingIndividualSide = false;
        this.contentBlockPaddingTop = 0;
        this.contentBlockPaddingBottom = 0;
        this.contentBlockPaddingLeft = 0;
        this.contentBlockPaddingRight = 0;
        this.contentBlockMargin = 0;
        this.contentBlockMarginIndividualSide = false;
        this.contentBlockMarginTop = 0;
        this.contentBlockMarginBottom = 0;
        this.contentBlockMarginLeft = 0;
        this.contentBlockMarginRight = 0;
        //table border
        this.tableBorderWidth = 0;
        this.tableBorderWidthIndividualSide = false;
        this.tableBorderWidthTop = 0;
        this.tableBorderWidthBottom = 0;
        this.tableBorderWidthLeft = 0;
        this.tableBorderWidthRight = 0;
        this.tableBorderColor = "No colour";
        this.tableBorderVerticalBorderWidth = 0;
        this.tableBorderVerticalBorderColor = "No colour";
        //title block
        this.titleHeaderFont = "Arial";
        this.titleHeaderFontWeight = 400;
        this.titleTextSize = 14;
        this.titleTextColor = "No colour";
        this.titleTextAlignment = "left";
        this.titleTextBackgroundColor = "No background";
        this.titleTextHeight = 25;
        this.titleTextBorderWidth = 0;
        this.titleTextBorderWidthIndividualSide = false;
        this.titleTextBorderWidthTop = 0;
        this.titleTextBorderWidthBottom = 0;
        this.titleTextBorderWidthLeft = 0;
        this.titleTextBorderWidthRight = 0;
        this.titleTextBorderColor = "No colour";
        //row block
        this.rowHeaderFont = "Arial";
        this.rowHeaderFontWeight = 400;
        this.rowTextSize = 14;
        this.rowTextColor = "No colour";
        this.rowTextAlignment = "left";
        this.rowTextHeight = 27;
        this.rowTextBackgroundColor = "No background";
        this.rowTextAlternativeBackgroundColor = "No background";
        this.rowTextHorizontalBorderWidth = 0;
        this.rowTextHorizontalBorderColor = "No colour";
        //total summmary
        this.totalSummaryBorderWidth = 0;
        this.totalSummaryBorderWidthIndividualSide = false;
        this.totalSummaryBorderWidthTop = 0;
        this.totalSummaryBorderWidthBottom = 0;
        this.totalSummaryBorderWidthRight = 0;
        this.totalSummaryBorderWidthLeft = 0;
        this.totalSummaryBorderColor = "No colour";
        this.totalSummaryBackgroundColor = "No background";
        this.totalSummaryPadding = 0;
        this.totalSummaryPaddingIndividualSide = false;
        this.totalSummaryPaddingTop = 0;
        this.totalSummaryPaddingRight = 0;
        this.totalSummaryPaddingBottom = 0;
        this.totalSummaryPaddingLeft = 0;
        this.totalSummaryMargin = 0;
        this.totalSummaryMarginIndividualSide = false;
        this.totalSummaryMarginTop = 0;
        this.totalSummaryMarginRight = 0;
        this.totalSummaryMarginBottom = 0;
        this.totalSummaryMarginLeft = 0;
        this.totalSummarySubtotalHeaderFont = "Arial";
        this.totalSummarySubtotalHeaderFontWeight = 400;
        this.totalSummarySubtotalTextSize = 14;
        this.totalSummarySubtotalTextColor = "#505B65";
        this.totalSummarySubtotalLineHeight = 14;
        this.totalSummaryGrandTotalHeaderFont = "Arial";
        this.totalSummaryGrandTotalHeaderFontWeight = 400;
        this.totalSummaryGrandTotalTextSize = 14;
        this.totalSummaryGrandTotalTextColor = "#505B65";
        this.totalSummaryGrandTotalLineHeight = 14;
        //finance section
        this.financeFontStyle = 'Arial';
        this.financeFontSize = 14;
        this.financeTextColor = '#505B65';
        this.financeLineHeight = 20;
        //grants section
        this.grantsFontStyle = 'Arial';
        this.grantsFontSize = 14;
        this.grantsFontWeight = 400;
        this.grantsTextColor = '#505B65';
        this.grantsLineHeight = 14;
        this.taxStatus = "withouttax";
        this.breakdown = true;
        this.breakdownView = 'vertical';
        this.columnShowHide = true;
        this.subtotalVisible = 'yes';
        this.listHideShow = false;
        this.taxChange = false;
        this.showPriceBreakdown = true;
        this.horizontalVisibility = false;
        this.checkedTableColumnCount = 4;
    }
    ConfigureAdvanceStylingComponent.prototype.ngOnInit = function () {
        var googleFontObject = this.fontFamilyService.getGoogleFontObject();
        this.displayGoogleFontList = googleFontObject.map(function (item) { return item.name; });
        if (this.authService['settings']) {
            this.noColorLabel = this.authService['settings']._csCountryCode.country == 'United Kingdom' ? 'No colour' : 'No color';
            // this.titleTextColor = textColour;
            // this.contentBlockBorderColor = textColour;
        }
        if (this.vatEnabled) {
            this.tableHeaders = [
                { name: 'Quantity', value: '1', checked: true },
                { name: 'Unit price', value: '2', checked: true },
                { name: this.taxLabel, value: '3', checked: true },
                { name: "Total  (" + this.vatType + " " + this.taxLabel + ")", value: '4', checked: true }
            ];
        }
        else {
            this.tableHeaders = [
                { name: 'Quantity', value: '1', checked: true },
                { name: 'Unit price', value: '2', checked: true },
                { name: "Total", value: '4', checked: true }
            ];
        }
        this.isDisabledHorizentalOrVerticalOption;
        this.onClose = new Subject();
        var advStyle = this.existingValues && this.existingValues.advancedStyle || null;
        if (advStyle && (!advStyle.hasOwnProperty('save') || (advStyle.hasOwnProperty('sectionId') && !advStyle.hasOwnProperty('save')) || advStyle.hasOwnProperty('currentSectionId'))) {
            // this.getUndefinedTableDefultValue();
            this.existingValuesBackup = this.existingValues;
        }
        else {
            this.getUndefinedTableDefultValue();
        }
        if (this.configData) {
            this.breakdownView = (this.configData.breakdownView) ? this.configData.breakdownView : 'vertical';
            this.breakdown = (this.configData.breakdownSwitch) ? this.configData.breakdownSwitch : true;
            this.listHideShow = (this.configData.listHideShow) ? this.configData.listHideShow : false;
            if (typeof this.configData.showHideColumns != "undefined") {
                this.showPriceBreakdown = this.configData.showHideColumns;
            }
            // this.taxStatus =  this.configData.tax;
            if (this.configData.visibleColumn) {
                this.tableHeaders = this.configData.visibleColumn;
                if (this.configData.visibleColumn.length === 4) {
                    if (this.vatEnabled) {
                        this.tableHeaders[2]['name'] = this.taxLabel;
                        this.tableHeaders[3]['name'] = "Total  (" + this.vatType + " " + this.taxLabel + ")";
                    }
                    else {
                        if (this.tableHeaders[2]['name'] != 'Total') {
                            this.tableHeaders[2] = { name: "Total", value: '4', checked: true };
                            this.tableHeaders[2]['checked'] = this.tableHeaders[3]['checked'];
                            this.tableHeaders.pop(3);
                        }
                        /*this.tableHeaders[2] = {};
                        this.tableHeaders[3]['name'] = `Total`;*/
                    }
                }
                else if (this.configData.visibleColumn.length === 3) {
                    if (this.vatEnabled) {
                        this.tableHeaders.push({ name: this.taxLabel, value: '3', checked: false });
                        this.tableHeaders[3] = this.tableHeaders[2];
                        this.tableHeaders[2] = { name: this.taxLabel, value: '3', checked: false };
                    }
                }
                this.checkedTableColumnCount = this.tableHeaders.filter(function (header) { return header.checked; }).length;
            }
            if (this.configData.subtotalVisible) {
                this.subtotalVisible = this.configData.subtotalVisible;
            }
        }
        if (this.breakdownView == "horizontal") {
            this.horizontalVisibility = true;
        }
        else {
            this.horizontalVisibility = false;
        }
        if (this.showPriceBreakdown == true) {
            this.columnShowHide = true;
        }
        else {
            this.columnShowHide = false;
        }
        // if (this.isNormalTable){
        //     let oldEle = this.save(true);
        //     let msg = { type: 'normalTableStylingCheck', oldEle }
        //     window.top.postMessage(msg, '*')
        // }
        if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.advancedStyle) {
            var styleData = this.existingValues.advancedStyle;
            this.contentBlockBorderWidth = styleData.contentBlockBorderWidth || this.contentBlockBorderWidth;
            this.contentBlockBorderWidthTop = styleData.contentBlockBorderWidthTop || this.contentBlockBorderWidthTop,
                this.contentBlockBorderWidthBottom = styleData.contentBlockBorderWidthBottom || this.contentBlockBorderWidthBottom,
                this.contentBlockBorderWidthLeft = styleData.contentBlockBorderWidthLeft || this.contentBlockBorderWidthLeft,
                this.contentBlockBorderWidthRight = styleData.contentBlockBorderWidthRight || this.contentBlockBorderWidthRight,
                this.contentBlockBorderWidthIndividualSide = styleData.contentBlockBorderWidthIndividualSide || this.contentBlockBorderWidthIndividualSide,
                this.contentBlockBorderColor = ((styleData.contentBlockBorderColor === 'No border' || styleData.contentBlockBorderColor === 'transparent') ? 'No colour' : styleData.contentBlockBorderColor) || this.contentBlockBorderColor;
            this.contentBlockBorderRadius = styleData.contentBlockBorderRadius || this.contentBlockBorderRadius;
            this.contentBlockBorderRadiusBottomRight = styleData.contentBlockBorderRadiusBottomRight || this.contentBlockBorderRadiusBottomRight;
            this.contentBlockBorderRadiusBottomLeft = styleData.contentBlockBorderRadiusBottomLeft || this.contentBlockBorderRadiusBottomLeft;
            this.contentBlockBorderRadiusTopLeft = styleData.contentBlockBorderRadiusTopLeft || this.contentBlockBorderRadiusTopLeft;
            this.contentBlockBorderRadiusTopRight = styleData.contentBlockBorderRadiusTopRight || this.contentBlockBorderRadiusTopRight;
            this.contentBlockBorderRadiusIndividualSide = styleData.contentBlockBorderRadiusIndividualSide || this.contentBlockBorderRadiusIndividualSide;
            this.contentBlockBackgroundColor = ((styleData.contentBlockBackgroundColor === 'No border' || styleData.contentBlockBackgroundColor === 'transparent' || styleData.contentBlockBackgroundColor === 'No color' || styleData.contentBlockBackgroundColor === 'No background') ? '#FFFFFF' : styleData.contentBlockBackgroundColor) || this.contentBlockBackgroundColor;
            this.contentBlockPadding = styleData.contentBlockPadding || this.contentBlockPadding;
            this.contentBlockPaddingTop = styleData.contentBlockPaddingTop || this.contentBlockPaddingTop;
            this.contentBlockPaddingBottom = styleData.contentBlockPaddingBottom || this.contentBlockPaddingBottom;
            this.contentBlockPaddingLeft = styleData.contentBlockPaddingLeft || this.contentBlockPaddingLeft;
            this.contentBlockPaddingRight = styleData.contentBlockPaddingRight || this.contentBlockPaddingRight;
            this.contentBlockPaddingIndividualSide = styleData.contentBlockPaddingIndividualSide || this.contentBlockPaddingIndividualSide;
            this.contentBlockMargin = styleData.contentBlockMargin || this.contentBlockMargin;
            this.contentBlockMarginTop = styleData.contentBlockMarginTop || this.contentBlockMarginTop;
            this.contentBlockMarginBottom = styleData.contentBlockMarginBottom || this.contentBlockMarginBottom;
            this.contentBlockMarginLeft = styleData.contentBlockMarginLeft || this.contentBlockMarginLeft;
            this.contentBlockMarginRight = styleData.contentBlockMarginRight || this.contentBlockMarginRight;
            this.contentBlockMarginIndividualSide = styleData.contentBlockMarginIndividualSide || this.contentBlockMarginIndividualSide;
            this.tableBorderWidth = styleData.tableBorderWidth || this.tableBorderWidth;
            this.tableBorderWidthTop = styleData.tableBorderWidthTop || this.tableBorderWidthTop;
            this.tableBorderWidthBottom = styleData.tableBorderWidthBottom || this.tableBorderWidthBottom;
            this.tableBorderWidthLeft = styleData.tableBorderWidthLeft || this.tableBorderWidthLeft;
            this.tableBorderWidthRight = styleData.tableBorderWidthRight || this.tableBorderWidthRight;
            this.tableBorderWidthIndividualSide = styleData.tableBorderWidthIndividualSide || this.tableBorderWidthIndividualSide;
            this.tableBorderColor = ((styleData.tableBorderColor === 'No border' || styleData.tableBorderColor === 'transparent' || styleData.tableBorderColor === 'No color') ? 'No colour' : styleData.tableBorderColor) || this.tableBorderColor;
            this.tableBorderVerticalBorderWidth = styleData.tableBorderVerticalBorderWidth || this.tableBorderVerticalBorderWidth;
            this.tableBorderVerticalBorderColor = styleData.tableBorderVerticalBorderColor || this.tableBorderVerticalBorderColor;
            this.titleHeaderFont = styleData.titleHeaderFont || this.titleHeaderFont;
            this.titleHeaderFontWeight = styleData.titleHeaderFontWeight || this.titleHeaderFontWeight;
            this.titleTextSize = styleData.titleTextSize || this.titleTextSize;
            this.titleTextColor = ((styleData.titleTextColor === 'No border' || styleData.titleTextColor === 'transparent' || styleData.titleTextColor === 'No color') ? 'No colour' : styleData.titleTextColor) || this.titleTextColor;
            this.titleTextAlignment = styleData.titleTextAlignment || this.titleTextAlignment;
            this.titleTextBackgroundColor = ((styleData.titleTextBackgroundColor === 'No border' || styleData.titleTextBackgroundColor === 'transparent' || styleData.titleTextBackgroundColor === 'No color') ? 'No background' : styleData.titleTextBackgroundColor) || this.titleTextBackgroundColor;
            this.titleTextHeight = styleData.titleTextHeight || this.titleTextHeight;
            this.titleTextBorderWidth = styleData.titleTextBorderWidth || this.titleTextBorderWidth;
            this.titleTextBorderWidthTop = styleData.titleTextBorderWidthTop || this.titleTextBorderWidthTop;
            this.titleTextBorderWidthRight = styleData.titleTextBorderWidthRight || this.titleTextBorderWidthRight;
            this.titleTextBorderWidthLeft = styleData.titleTextBorderWidthLeft || this.titleTextBorderWidthLeft;
            this.titleTextBorderWidthBottom = styleData.titleTextBorderWidthBottom || this.titleTextBorderWidthBottom;
            this.titleTextBorderWidthIndividualSide = styleData.titleTextBorderWidthIndividualSide || this.titleTextBorderWidthIndividualSide;
            this.titleTextBorderColor = ((styleData.titleTextBorderColor === 'No border' || styleData.titleTextBorderColor === 'transparent' || styleData.titleTextBorderColor === 'No color') ? 'No colour' : styleData.titleTextBorderColor) || this.titleTextBorderColor;
            this.rowHeaderFont = styleData.rowHeaderFont || this.rowHeaderFont;
            this.rowHeaderFontWeight = styleData.rowHeaderFontWeight || this.rowHeaderFontWeight;
            this.rowTextSize = styleData.rowTextSize || this.rowTextSize;
            this.rowTextColor = ((styleData.rowTextColor === 'No border' || styleData.rowTextColor === 'transparent' || styleData.rowTextColor === 'No color') ? 'No colour' : styleData.rowTextColor) || this.rowTextColor;
            this.rowTextAlignment = styleData.rowTextAlignment || this.rowTextAlignment;
            this.rowTextHeight = styleData.rowTextHeight || this.rowTextHeight;
            this.rowTextBackgroundColor = ((styleData.rowTextBackgroundColor === 'No border' || styleData.rowTextBackgroundColor === 'transparent' || styleData.rowTextBackgroundColor === 'No color') ? 'No background' : styleData.rowTextBackgroundColor) || this.rowTextBackgroundColor;
            this.rowTextAlternativeBackgroundColor = ((styleData.rowTextAlternativeBackgroundColor === 'No border' || styleData.rowTextAlternativeBackgroundColor === 'transparent' || styleData.rowTextAlternativeBackgroundColor === 'No color' || styleData.rowTextAlternativeBackgroundColor === 'No alternative background' || styleData.rowTextAlternativeBackgroundColor === 'No alternate background') ? 'No background' : styleData.rowTextAlternativeBackgroundColor) || this.rowTextAlternativeBackgroundColor;
            this.rowTextHorizontalBorderWidth = styleData.rowTextHorizontalBorderWidth || this.rowTextHorizontalBorderWidth;
            this.rowTextHorizontalBorderColor = ((styleData.rowTextHorizontalBorderColor === 'No border' || styleData.rowTextHorizontalBorderColor === 'transparent' || styleData.rowTextHorizontalBorderColor === 'No color') ? 'No colour' : styleData.rowTextHorizontalBorderColor) || this.rowTextHorizontalBorderColor;
            this.totalSummaryBorderWidth = styleData.totalSummaryBorderWidth || this.totalSummaryBorderWidth;
            this.totalSummaryBorderWidthTop = styleData.totalSummaryBorderWidthTop || this.totalSummaryBorderWidthTop;
            this.totalSummaryBorderWidthRight = styleData.totalSummaryBorderWidthRight || this.totalSummaryBorderWidthRight;
            this.totalSummaryBorderWidthLeft = styleData.totalSummaryBorderWidthLeft || this.totalSummaryBorderWidthLeft;
            this.totalSummaryBorderWidthBottom = styleData.totalSummaryBorderWidthBottom || this.totalSummaryBorderWidthBottom;
            this.totalSummaryBorderWidthIndividualSide = styleData.totalSummaryBorderWidthIndividualSide || this.totalSummaryBorderWidthIndividualSide;
            this.totalSummaryBorderColor = ((styleData.totalSummaryBorderColor === 'No border' || styleData.totalSummaryBorderColor === 'transparent' || styleData.totalSummaryBorderColor === 'No color') ? 'No colour' : styleData.totalSummaryBorderColor) || this.totalSummaryBorderColor;
            this.totalSummaryBackgroundColor = ((styleData.totalSummaryBackgroundColor === 'No border' || styleData.totalSummaryBackgroundColor === 'transparent' || styleData.totalSummaryBackgroundColor === 'No color') ? 'No background' : styleData.totalSummaryBackgroundColor) || this.totalSummaryBackgroundColor;
            // this.totalSummaryPadding = styleData.totalSummaryPadding;
            // this.totalSummaryMargin = styleData.totalSummaryMargin;
            this.totalSummarySubtotalHeaderFont = styleData.totalSummarySubtotalHeaderFont || this.totalSummarySubtotalHeaderFont;
            this.totalSummarySubtotalHeaderFontWeight = styleData.totalSummarySubtotalHeaderFontWeight || this.totalSummarySubtotalHeaderFontWeight;
            this.totalSummarySubtotalTextSize = styleData.totalSummarySubtotalTextSize || this.totalSummarySubtotalTextSize;
            this.totalSummarySubtotalTextColor = ((styleData.totalSummarySubtotalTextColor === 'No border' || styleData.totalSummarySubtotalTextColor === 'transparent' || styleData.totalSummarySubtotalTextColor === 'No color') ? 'No colour' : styleData.totalSummarySubtotalTextColor) || this.totalSummarySubtotalTextColor;
            this.totalSummarySubtotalLineHeight = styleData.totalSummarySubtotalLineHeight || this.totalSummarySubtotalLineHeight;
            this.totalSummaryGrandTotalHeaderFont = styleData.totalSummaryGrandTotalHeaderFont || this.totalSummaryGrandTotalHeaderFont;
            this.totalSummaryGrandTotalHeaderFontWeight = styleData.totalSummaryGrandTotalHeaderFontWeight || this.totalSummaryGrandTotalHeaderFontWeight;
            this.totalSummaryGrandTotalTextSize = styleData.totalSummaryGrandTotalTextSize || this.totalSummaryGrandTotalTextSize;
            this.totalSummaryGrandTotalTextColor = ((styleData.totalSummaryGrandTotalTextColor === 'No border' || styleData.totalSummaryGrandTotalTextColor === 'transparent' || styleData.totalSummaryGrandTotalTextColor === 'No color') ? 'No colour' : styleData.totalSummaryGrandTotalTextColor) || this.totalSummaryGrandTotalTextColor;
            this.totalSummaryGrandTotalLineHeight = styleData.totalSummaryGrandTotalLineHeight || this.totalSummaryGrandTotalLineHeight;
            this.financeFontStyle = styleData.financeFontStyle || this.financeFontStyle;
            this.financeFontSize = styleData.financeFontSize || this.financeFontSize;
            this.financeTextColor = styleData.financeTextColor || this.financeTextColor;
            this.financeLineHeight = styleData.financeLineHeight || this.financeLineHeight;
            this.grantsFontStyle = styleData.grantsFontStyle || this.grantsFontStyle;
            this.grantsFontWeight = styleData.grantsFontWeight || this.grantsFontWeight;
            this.grantsFontSize = styleData.grantsFontSize || this.grantsFontSize;
            this.grantsTextColor = styleData.grantsTextColor || this.grantsTextColor;
            this.grantsLineHeight = styleData.grantsLineHeight || this.grantsLineHeight;
        }
    };
    ConfigureAdvanceStylingComponent.prototype.ngAfterViewInit = function () {
        if (this.editorStatus == 'style') {
            var that = this;
        }
        if ($('#tax2').is(':checked')) {
            this.taxInclusive = true;
        }
        // this.refreshSpectrum();
    };
    ConfigureAdvanceStylingComponent.prototype.updateStylesChanges = function (value, styleType) {
        var styleTypeToPropertyMap = {
            'titleHeaderFont': 'titleHeaderFont',
            'titleHeaderFontWeight': 'titleHeaderFontWeight',
            'rowHeaderFont': 'rowHeaderFont',
            'rowHeaderFontWeight': 'rowHeaderFontWeight',
            'totalSummarySubtotalHeaderFont': 'totalSummarySubtotalHeaderFont',
            'totalSummarySubtotalHeaderFontWeight': 'totalSummarySubtotalHeaderFontWeight',
            'totalSummaryGrandTotalHeaderFont': 'totalSummaryGrandTotalHeaderFont',
            'totalSummaryGrandTotalHeaderFontWeight': 'totalSummaryGrandTotalHeaderFontWeight',
        };
        var propertyToUpdate = styleTypeToPropertyMap[styleType];
        if (propertyToUpdate) {
            this[propertyToUpdate] = value;
        }
        this.sendChangesToEditor();
    };
    ConfigureAdvanceStylingComponent.prototype.titleAlignContent = function (alignment, position, alignmentType) {
        // $('.align-font').css({ opacity: '50%' });
        $('.title-set-remove-bg').removeClass("switch-color-content-alignment-" + alignmentType);
        $('.' + position + '').addClass("switch-color-content-alignment-" + alignmentType);
        if (alignmentType === 'titleTextAlignment' || alignmentType === 'rowTextAlignment') {
            this[alignmentType] = alignment;
            this.sendChangesToEditor();
        }
    };
    ConfigureAdvanceStylingComponent.prototype.detectChanges = function (data, event, tableType, actionType) {
        if (actionType === void 0) { actionType = ''; }
        var that = this;
        this.colorPalateValue = '';
        setTimeout(function () {
            that.colorPalateValue = data;
        }, 50);
        var topPosition = event.srcElement.getBoundingClientRect().top;
        if (topPosition >= 450) {
            this.openColorPalatePosition(event.srcElement, '-458px');
        }
        else {
            this.openColorPalatePosition(event.srcElement, 'unset');
        }
    };
    ConfigureAdvanceStylingComponent.prototype.getColor = function (data, type) {
        var typeToPropertyMap = {
            'contentBlockBorderColor': 'contentBlockBorderColor',
            'contentBlockBackgroundColor': 'contentBlockBackgroundColor',
            'tableBorderColor': 'tableBorderColor',
            'tableBorderVerticalBorderColor': 'tableBorderVerticalBorderColor',
            'titleTextColor': 'titleTextColor',
            'titleTextBackgroundColor': 'titleTextBackgroundColor',
            'titleTextBorderColor': 'titleTextBorderColor',
            'rowTextColor': 'rowTextColor',
            'rowTextBackgroundColor': 'rowTextBackgroundColor',
            'rowTextAlternativeBackgroundColor': 'rowTextAlternativeBackgroundColor',
            'rowTextHorizontalBorderColor': 'rowTextHorizontalBorderColor',
            'totalSummaryBorderColor': 'totalSummaryBorderColor',
            'totalSummaryBackgroundColor': 'totalSummaryBackgroundColor',
            'totalSummarySubtotalTextColor': 'totalSummarySubtotalTextColor',
            'totalSummaryGrandTotalTextColor': 'totalSummaryGrandTotalTextColor',
            'financeTextColor': 'financeTextColor',
            'grantsTextColor': 'grantsTextColor'
        };
        if (typeToPropertyMap.hasOwnProperty(type)) {
            this[typeToPropertyMap[type]] = data;
        }
        this.sendChangesToEditor();
    };
    ConfigureAdvanceStylingComponent.prototype.openColorPalatePosition = function (htmlElement, topValue) {
        var nextElementPopup = $(htmlElement).next().get(0);
        $(nextElementPopup).css('top', topValue);
    };
    ConfigureAdvanceStylingComponent.prototype.sendChangesToEditor = function (styleTobeChanged) {
        var data = this.formatStylingData();
        var msg = { type: 'changeSectionStylingNew', style: data, styleTobeChanged: styleTobeChanged || null };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
    };
    ConfigureAdvanceStylingComponent.prototype.formatStylingData = function () {
        // const getContentBlockValue = (property) => {
        //     if (this[property + 'IndividualSide']) {
        //         return [
        //             this[property + 'TopLeft'],
        //             this[property + 'TopRight'],
        //             this[property + 'BottomRight'],
        //             this[property + 'BottomLeft']
        //         ];
        //     }
        //     return this[property];
        // };
        this.contentBlockBackgroundColor = this.contentBlockBackgroundColor === 'No background' ? '#FFFFFF' : this.contentBlockBackgroundColor;
        this.contentBlockBorderColor = this.contentBlockBorderColor === 'No colour' ? ((this.contentBlockBorderWidth || this.contentBlockBorderWidthTop || this.contentBlockBorderWidthBottom || this.contentBlockBorderWidthLeft || this.contentBlockBorderWidthRight) ? '#cccccc' : this.contentBlockBorderColor) : this.contentBlockBorderColor;
        this.tableBorderColor = this.tableBorderColor === 'No colour' ? ((this.tableBorderWidth || this.tableBorderWidthTop || this.tableBorderWidthBottom || this.tableBorderWidthLeft || this.tableBorderWidthRight) ? '#cccccc' : this.tableBorderColor) : this.tableBorderColor;
        this.tableBorderVerticalBorderColor = this.tableBorderVerticalBorderColor === 'No colour' ? (this.tableBorderVerticalBorderWidth ? '#cccccc' : this.tableBorderVerticalBorderColor) : this.tableBorderVerticalBorderColor;
        this.titleTextBorderColor = this.titleTextBorderColor === 'No colour' ? ((this.titleTextBorderWidth || this.titleTextBorderWidthTop || this.titleTextBorderWidthBottom || this.titleTextBorderWidthLeft || this.titleTextBorderWidthRight) ? '#cccccc' : this.titleTextBorderColor) : this.titleTextBorderColor;
        this.totalSummaryBorderColor = this.totalSummaryBorderColor === 'No colour' ? ((this.totalSummaryBorderWidth || this.totalSummaryBorderWidthTop || this.totalSummaryBorderWidthBottom || this.totalSummaryBorderWidthLeft || this.totalSummaryBorderWidthRight) ? '#cccccc' : this.totalSummaryBorderColor) : this.totalSummaryBorderColor;
        var data = {
            contentBlockBorderWidth: this.contentBlockBorderWidth,
            contentBlockBorderWidthIndividualSide: this.contentBlockBorderWidthIndividualSide,
            contentBlockBorderWidthTop: this.contentBlockBorderWidthTop,
            contentBlockBorderWidthBottom: this.contentBlockBorderWidthBottom,
            contentBlockBorderWidthLeft: this.contentBlockBorderWidthLeft,
            contentBlockBorderWidthRight: this.contentBlockBorderWidthRight,
            contentBlockBorderColor: this.contentBlockBorderColor,
            contentBlockBorderRadius: this.contentBlockBorderRadius,
            contentBlockBorderRadiusIndividualSide: this.contentBlockBorderRadiusIndividualSide,
            contentBlockBorderRadiusTopLeft: this.contentBlockBorderRadiusTopLeft,
            contentBlockBorderRadiusTopRight: this.contentBlockBorderRadiusTopRight,
            contentBlockBorderRadiusBottomRight: this.contentBlockBorderRadiusBottomRight,
            contentBlockBorderRadiusBottomLeft: this.contentBlockBorderRadiusBottomLeft,
            contentBlockBackgroundColor: this.contentBlockBackgroundColor,
            contentBlockPadding: this.contentBlockPadding,
            contentBlockPaddingIndividualSide: this.contentBlockPaddingIndividualSide,
            contentBlockPaddingTop: this.contentBlockPaddingTop,
            contentBlockPaddingBottom: this.contentBlockPaddingBottom,
            contentBlockPaddingLeft: this.contentBlockPaddingLeft,
            contentBlockPaddingRight: this.contentBlockPaddingRight,
            contentBlockMargin: this.contentBlockMargin,
            contentBlockMarginIndividualSide: this.contentBlockMarginIndividualSide,
            contentBlockMarginTop: this.contentBlockMarginTop,
            contentBlockMarginBottom: this.contentBlockMarginBottom,
            contentBlockMarginLeft: this.contentBlockMarginLeft,
            contentBlockMarginRight: this.contentBlockMarginRight,
            tableBorderWidth: this.tableBorderWidth,
            tableBorderWidthIndividualSide: this.tableBorderWidthIndividualSide,
            tableBorderWidthTop: this.tableBorderWidthTop,
            tableBorderWidthBottom: this.tableBorderWidthBottom,
            tableBorderWidthLeft: this.tableBorderWidthLeft,
            tableBorderWidthRight: this.tableBorderWidthRight,
            tableBorderColor: this.tableBorderColor,
            tableBorderVerticalBorderWidth: this.tableBorderVerticalBorderWidth,
            tableBorderVerticalBorderColor: this.tableBorderVerticalBorderColor,
            titleHeaderFont: this.titleHeaderFont,
            titleHeaderFontWeight: this.titleHeaderFontWeight,
            titleTextSize: this.titleTextSize,
            titleTextColor: this.titleTextColor,
            titleTextAlignment: this.titleTextAlignment,
            titleTextHeight: this.titleTextHeight,
            titleTextBackgroundColor: this.titleTextBackgroundColor,
            titleTextBorderWidth: this.titleTextBorderWidth,
            titleTextBorderWidthIndividualSide: this.titleTextBorderWidthIndividualSide,
            titleTextBorderWidthTop: this.titleTextBorderWidthTop,
            titleTextBorderWidthBottom: this.titleTextBorderWidthBottom,
            titleTextBorderWidthLeft: this.titleTextBorderWidthLeft,
            titleTextBorderWidthRight: this.titleTextBorderWidthRight,
            titleTextBorderColor: this.titleTextBorderColor,
            rowHeaderFont: this.rowHeaderFont,
            rowHeaderFontWeight: this.rowHeaderFontWeight,
            rowTextSize: this.rowTextSize,
            rowTextColor: this.rowTextColor,
            rowTextAlignment: this.rowTextAlignment,
            rowTextHeight: this.rowTextHeight,
            rowTextBackgroundColor: this.rowTextBackgroundColor,
            rowTextAlternativeBackgroundColor: this.rowTextAlternativeBackgroundColor,
            rowTextHorizontalBorderWidth: this.rowTextHorizontalBorderWidth,
            rowTextHorizontalBorderColor: this.rowTextHorizontalBorderColor,
            totalSummaryBorderWidth: this.totalSummaryBorderWidth,
            totalSummaryBorderWidthIndividualSide: this.totalSummaryBorderWidthIndividualSide,
            totalSummaryBorderWidthTop: this.totalSummaryBorderWidthTop,
            totalSummaryBorderWidthBottom: this.totalSummaryBorderWidthBottom,
            totalSummaryBorderWidthRight: this.totalSummaryBorderWidthRight,
            totalSummaryBorderWidthLeft: this.totalSummaryBorderWidthLeft,
            totalSummaryBorderColor: this.totalSummaryBorderColor,
            totalSummaryBackgroundColor: this.totalSummaryBackgroundColor,
            // totalSummaryPadding: getContentBlockValue('totalSummaryPadding'),
            // totalSummaryMargin: getContentBlockValue('totalSummaryMargin'),
            totalSummarySubtotalHeaderFont: this.totalSummarySubtotalHeaderFont,
            totalSummarySubtotalHeaderFontWeight: this.totalSummarySubtotalHeaderFontWeight,
            totalSummarySubtotalTextSize: this.totalSummarySubtotalTextSize,
            totalSummarySubtotalTextColor: this.totalSummarySubtotalTextColor,
            totalSummarySubtotalLineHeight: this.totalSummarySubtotalLineHeight,
            totalSummaryGrandTotalHeaderFont: this.totalSummaryGrandTotalHeaderFont,
            totalSummaryGrandTotalHeaderFontWeight: this.totalSummaryGrandTotalHeaderFontWeight,
            totalSummaryGrandTotalTextSize: this.totalSummaryGrandTotalTextSize,
            totalSummaryGrandTotalTextColor: this.totalSummaryGrandTotalTextColor,
            totalSummaryGrandTotalLineHeight: this.totalSummaryGrandTotalLineHeight,
            financeFontStyle: this.financeFontStyle,
            financeFontSize: this.financeFontSize,
            financeTextColor: this.financeTextColor,
            financeLineHeight: this.financeLineHeight,
            grantsFontStyle: this.grantsFontStyle,
            grantsFontWeight: this.grantsFontWeight,
            grantsFontSize: this.grantsFontSize,
            grantsTextColor: this.grantsTextColor,
            grantsLineHeight: this.grantsLineHeight,
            save: true,
            currentSectionId: this.isNormalTable ? '' : this.currentSectionId,
            hashId: this.hashId,
            isNormalTable: this.isNormalTable,
            optionSummary: this.isOptionSummary ? true : false,
            sectionId: this.isNormalTable ? '' : this.currentSectionId,
        };
        return data;
    };
    ConfigureAdvanceStylingComponent.prototype.getUndefinedTableDefultValue = function () {
        var getUndefineStyleData = this.formatStylingData();
        this.existingValues = {
            'advancedStyle': getUndefineStyleData
        };
    };
    ConfigureAdvanceStylingComponent.prototype.handleSideProperty = function (type, property) {
        var isIndividualSide = !this[type];
        if (isIndividualSide) {
            $('.all-side-border-radius').addClass('selected-style');
            switch (property) {
                case 'contentBlockBorderWidth':
                case 'contentBlockPadding':
                case 'contentBlockMargin':
                case 'tableBorderWidth':
                case 'titleTextBorderWidth':
                case 'rowTextBorderWidth':
                case 'totalSummaryBorderWidth':
                case 'totalSummaryPadding':
                case 'totalSummaryMargin': {
                    this[property + "Top"] = this[property + "Bottom"] = this[property + "Left"] = this[property + "Right"] = 0;
                    break;
                }
                case 'contentBlockBorderRadius': {
                    this[property + "TopLeft"] = this[property + "BottomLeft"] = this[property + "TopRight"] = this[property + "BottomRight"] = 0;
                    break;
                }
                default: { }
            }
        }
        else {
            $('.all-side-border-radius').removeClass('selected-style');
        }
        this[property] = 0;
        this[type] = isIndividualSide;
        this.sendChangesToEditor();
    };
    ConfigureAdvanceStylingComponent.prototype.save = function (getJson) {
        var _this = this;
        if (getJson === void 0) { getJson = false; }
        var taxvalue = "Exclusive";
        if (this.taxStatus == "withtax") {
            taxvalue = "Inclusive";
            this.vatType = 'Inc';
        }
        else {
            this.vatType = 'Exc';
        }
        if (this.vatEnabled) {
            this.tableHeaders[3]['name'] = "Total  (" + this.vatType + " " + this.taxLabel + ")";
        }
        else {
            this.tableHeaders[2]['name'] = "Total";
        }
        var data = {
            tablePkId: this.tablePkId,
            tableType: this.tableType,
            value: taxvalue,
            type: "POST"
        };
        var breakdownSwitch = this.breakdown;
        var breakdownView = this.breakdownView;
        var tax = this.taxStatus;
        var showHideColumns = this.columnShowHide;
        var currentTableID = this.isNormalTable ? this.hashId : this.isOptionSummary == true ? "pricing-items-breakup-tpl_" + this.currentSectionId : "pricing_cs_component_" + this.currentSectionId;
        var visibleColumn = this.tableHeaders;
        var stylingData = '';
        var msg = { type: 'saveChangesNew' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
        if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
            stylingData = this.existingValues.advancedStyle;
        }
        else if (this.editorStatus == 'style') {
            stylingData = this.formatStylingData();
        }
        var options = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData, 'isOptionSummary': this.isOptionSummary, 'isNormalTable': this.isNormalTable, 'taxData': data, 'editorStatus': this.editorStatus };
        if (getJson) {
            return options;
        }
        else {
            options.save = true;
            var msg_1 = { type: 'breakdown', options: options, taxChange: this.taxChange };
            this.taxChange = false;
            window.top.postMessage(msg_1, '*');
            setTimeout(function () {
                _this.bsModalRef.hide();
            }, 300);
        }
        // this.taxChange = false;
        this.proposaltemplateservice.setEditorThumbnail('updateTableElement');
    };
    ConfigureAdvanceStylingComponent.prototype.cancel = function () {
        var msg = { type: 'revertAppliedStylesNew', sectionId: this.currentSectionId, tableType: this.isOptionSummary ? 'optionSummary' : 'pricingSection' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
        // let data:any = this.save(true)
        // this.onClose.next(data);
        this.bsModalRef.hide();
    };
    ConfigureAdvanceStylingComponent.prototype.taxStatusToggle = function (val) {
        this.taxChange = !this.taxChange;
        this.taxStatus = val;
        if (val == 'withtax') {
            this.taxInclusive = true;
            if ($('#yes').is(':checked')) {
                $('#yes').prop('checked', false);
                $('#no').prop('checked', true);
            }
        }
        else {
            this.taxInclusive = false;
            $('#yes').prop('checked', true);
            this.subtotalVisible = 'yes';
            if ($('#no').is(':checked')) {
                $('#yes').prop('checked', true);
                $('#no').prop('checked', false);
            }
        }
    };
    ConfigureAdvanceStylingComponent.prototype.priceTableBreakdown = function (val) {
        this.showPriceBreakdown = val;
        if (val == "withtblbreakdown") {
            this.columnShowHide = true;
        }
        else {
            this.columnShowHide = false;
        }
    };
    ConfigureAdvanceStylingComponent.prototype.visibleColumnChange = function (evt, id) {
        if (evt.target.checked) {
            this.tableHeaders[id].checked = true;
        }
        else if (!evt.target.checked) {
            this.tableHeaders[id].checked = false;
        }
        var hiddenColumn = [];
        this.tableHeaders.forEach(function (element, index, columnHeader) {
            if (!element.checked) {
                hiddenColumn.push(element.value);
            }
        });
        this.checkedTableColumnCount = this.tableHeaders.filter(function (header) { return header.checked; }).length;
    };
    ConfigureAdvanceStylingComponent.prototype.horizontalVisibilityToggle = function (val) {
        this.breakdownView = val;
        if (val == "horizontal") {
            this.horizontalVisibility = true;
        }
        else {
            this.horizontalVisibility = false;
        }
    };
    ConfigureAdvanceStylingComponent.prototype.subtotalStatusChange = function (val) {
        this.subtotalVisible = val;
    };
    return ConfigureAdvanceStylingComponent;
}());
export { ConfigureAdvanceStylingComponent };
