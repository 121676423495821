import { PipeTransform } from '@angular/core';
import * as moment from "moment";
var TimePipe = /** @class */ (function () {
    function TimePipe(settings, locale) {
        this.settings = settings;
        this.locale = locale;
        this.format = '';
    }
    TimePipe.prototype.transform = function (value, args) {
        if (value == '' || value == null) {
            return '';
        }
        var language = this.settings._csLanguage;
        if (language == 'en') {
            language = 'en-GB';
        }
        moment.locale(language);
        var intlSettings = this.settings._csIntlSettings;
        if (intlSettings['time'] == 1) {
            this.format = 'HH:mm';
        }
        else {
            this.format = 'hh:mm a';
        }
        var isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
        var utcTimeString = (isoRegex.test(value)) ? new Date(value) : new Date(value + ' UTC');
        utcTimeString = new Date(utcTimeString.toLocaleString(this.settings._csLanguage, { timeZone: this.settings._csCountryCode.timezone }));
        return moment(utcTimeString).format(this.format);
    };
    return TimePipe;
}());
export { TimePipe };
