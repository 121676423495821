<div class="ag-cell-wrapper ag-row-group customGroupCell" [ngClass]="{'expanded': isCellExpanded}"
     (click)="params?.value && onCellClicked($event)">
    <span class="ag-group-value m-0-pxl">{{ params?.valueFormatted || params?.value }}</span>

    <span class="ag-group-expanded" *ngIf="isCellExpanded">
        <span class="ag-icon ag-icon-expanded"></span>
    </span>

    <span class="ag-group-contracted" *ngIf="!isCellExpanded">
        <span class="ag-icon ag-icon-contracted"></span>
    </span>
</div>
