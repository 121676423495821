import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { keyframes } from '@angular/animations';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-invoice-edit-modal',
    templateUrl: './invoice-edit-modal.component.html',
    styleUrls: ['./invoice-edit-modal.component.scss']
})
export class InvoiceEditModalComponent implements OnInit {

    invoiceForm: FormGroup;
    public onClose: Subject<boolean>;
    error: boolean = false;
    constructor(
        private fb: FormBuilder,
        public bsModalRef: BsModalRef, ) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.buildForm();
    }

    buildForm() {
        this.invoiceForm = this.fb.group({
            'deposit': ['', [Validators.required]],
            'on_start_job': ['', [Validators.required]],
            'on_completion': ['', [Validators.required]]
        })
    }

    input() {
        let value: any = this.invoiceForm.value
        let sum: any=0;
        for (var k in value) {
            if(value.hasOwnProperty(k)){
                sum = sum + value[k];
            }
        }
        if(sum > 100) {
            this.error = true;
        }
        else{
            this.error = false;
        }
    }

    // Getting control values for validation check
    get f() {
        return this.invoiceForm.controls;
    }

    // submit form
    save() {

    }

}
