<div class="cs-timepicker">
    <!--Hour-->
    <select [(ngModel)]="hour" (change)="onHourChange()" class="hour-min">
        <option *ngFor="let hr of hoursDropdownItems" [value]="hr">{{hr}}</option>
    </select>
    <strong class="hour-colon">:</strong>&nbsp;
    <!-- Minute -->
    <select [(ngModel)]="minute" (change)="onMinuteChange()" class="hour-min">
        <option *ngFor="let min of minutesDropdownItems" [value]="min">{{min}}</option>
    </select>
    &ensp;
    <!-- Meridian -->
    <select *ngIf="_showMeridian" [(ngModel)]="meridian" (change)="onMeridianChange()" class="diary-time-meridian"
        class="meridian">
        <option value="AM">AM</option>
        <option value="PM">PM</option>
    </select>
</div>