var CsAgGridService = /** @class */ (function () {
    function CsAgGridService() {
    }
    CsAgGridService.prototype.setGridAutoHeight = function (params, detailPanelsHeight) {
        if (detailPanelsHeight === void 0) { detailPanelsHeight = 0; }
        if (params.gridHolder && params.gridWrapper && params.grid) {
            var gridHolder = params.gridHolder.nativeElement;
            var gridWrapper = params.gridWrapper.nativeElement;
            var grid = params.grid.nativeElement;
            var gridHolderStyle = getComputedStyle(gridHolder);
            var gridHolderHeight = gridHolder.clientHeight - (parseFloat(gridHolderStyle.paddingTop) + parseFloat(gridHolderStyle.paddingBottom));
            var gridTopSpace = grid.getBoundingClientRect().top - gridWrapper.getBoundingClientRect().top;
            var gridHeaderHeight = grid.querySelector('.ag-header').getBoundingClientRect().height;
            var gridBottomSpace = gridWrapper.getBoundingClientRect().bottom - grid.getBoundingClientRect().bottom;
            if (detailPanelsHeight === 0) {
                params.detailPanelsHeight = 0;
            }
            else {
                params.detailPanelsHeight += detailPanelsHeight;
            }
            var availableContainerHeight = gridHolderHeight - (gridTopSpace + gridHeaderHeight + gridBottomSpace);
            // Todo: Need to work this line as dynamic, instead of `params.pageSize * 40`
            var requiredContainerHeight = (params.pageSize * 40) + params.detailPanelsHeight;
            var currentLayout = availableContainerHeight < requiredContainerHeight ? 'normal' : 'autoHeight';
            if (params.gridLayout !== currentLayout) {
                params.gridApi.setDomLayout(currentLayout);
                params.gridApi.resetRowHeights();
                params.gridLayout = currentLayout;
            }
        }
    };
    // `setNodeState` is using, for update the custom state of each, respective row & cell state
    CsAgGridService.prototype.setNodeState = function (rowId, colId, stateParams) {
        if (stateParams === void 0) { stateParams = {}; }
        var _a, _b, _c;
        if (this.agNodeState) {
            if (this.agNodeState[rowId]) {
                Object.entries(this.agNodeState[rowId]).forEach(function (_a) {
                    var col = _a[0], state = _a[1];
                    if (col !== colId) {
                        state['isExpanded'] = false;
                    }
                });
                if (this.agNodeState[rowId][colId]) {
                    this.agNodeState[rowId][colId]['isExpanded'] = stateParams['isExpanded'];
                }
                else {
                    this.agNodeState[rowId][colId] = {
                        isExpanded: stateParams['isExpanded']
                    };
                }
            }
            else {
                this.agNodeState[rowId] = (_a = {},
                    _a[colId] = {
                        isExpanded: stateParams['isExpanded']
                    },
                    _a);
            }
        }
        else {
            this.agNodeState = (_b = {},
                _b[rowId] = (_c = {},
                    _c[colId] = {
                        isExpanded: stateParams['isExpanded']
                    },
                    _c),
                _b);
        }
    };
    CsAgGridService.prototype.getNodeState = function (rowId) {
        var rowNode = this.agNodeState && this.agNodeState[rowId];
        return (!!rowNode && rowNode);
    };
    CsAgGridService.prototype.resetNodeState = function () {
        this.agNodeState = null;
    };
    CsAgGridService.prototype.updateGridPagingData = function (params) {
        this.resetNodeState();
        this.updateGridPagination(params);
        this.renderGridSSData(params);
    };
    CsAgGridService.prototype.updateGridPagination = function (params) {
        params.gridApi.paginationSetPageSize(params.pageSize);
        params.gridApi.paginationGoToPage(params.currentPage - 1);
    };
    CsAgGridService.prototype.renderGridSSData = function (params) {
        params.gridApi.setServerSideDatasource(params.masterGridDataSource);
    };
    return CsAgGridService;
}());
export { CsAgGridService };
