import * as tslib_1 from "tslib";
import { ElementRef, Injector } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { Observable } from "rxjs/index";
var ReactComponentDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ReactComponentDirective, _super);
    function ReactComponentDirective(_hostRef, injector, ajsScope) {
        var _this = _super.call(this, 'reactComponent2', _hostRef, injector) || this;
        _this.ajsScope = ajsScope;
        return _this;
    }
    return ReactComponentDirective;
}(UpgradeComponent));
export { ReactComponentDirective };
