import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CommonDataService extends InterfaceService{

    private savedColorsData = new BehaviorSubject<any>(null)
    savedColorsData$ = this.savedColorsData.asObservable();

  constructor(private http: HttpClient) {
      super()
  }

    fetchSavedColorsData() {
        this.http.get(this.getApiUrl('getColorsCollection'))
            .subscribe(result => {
                if(result){
                    this.setsavedColorsData(result);
                }
            });
    }

    setsavedColorsData(data:any){
        this.savedColorsData.next(data)
    }
}
