<div class="modal-header">
    <h4 class="modal-title pull-left">Set custom field value</h4>
</div>
<div class="modal-body">
    <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <app-condition-edge *ngIf="isConditional"
        [inputModel]="inputModel"
        [operators]="operators"
        [isMarketPlaceView]="isMarketPlaceView"
        [isReadOnlyMode]="isReadOnlyMode"
        [activeField] = "activeField">
    </app-condition-edge>
    <div class="item form-item p-15">
        <div class="information-bar mb-10">
            <div class="py-10">Action will be skipped if custom fields template is not configured in related groups.</div>
        </div>
        <div class="control">
            <label class="control-label required_field">Custom fields</label>
            <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                    [disabled]="isMarketPlaceView || isEdit && hasChild"
                    csSelect2
                    [select2Config]="{}"
                    (selected)="onSelectField($event)"
                    [dropdownvalue]="inputModel.model.col_name"
                    [(ngModel)]="inputModel.model.col_name">
                <option value="" translate>Please.Choose</option>
                <optgroup *ngFor="let template of  inputModel.config['customFields']" [label]="'Custom fields: '+template['description']">
                    <option *ngFor="let field of template['fields']" [value]="field.col_name">{{field.text}}</option>
                </optgroup>
            </select>
        </div>

        <div class="control" *ngIf="activeCustomField">
            <label class="control-label required_field">Set value</label>
            <div class="d-block" [ngSwitch]="true">
                <div *ngSwitchCase="activeCustomField.input === 'number'">
                    <input type="text" class="control-input" appDigitDecimaNumber [(ngModel)]="inputModel.model.value"/>
                </div>
                <div *ngSwitchCase="activeCustomField.input === 'text'">
                    <input type="text" class="control-input" [(ngModel)]="inputModel.model.value"/>
                </div>
                <div *ngSwitchCase="activeCustomField.input === 'select' || activeCustomField.input === 'checkboxList'">
                    <select class="" [(ngModel)]="inputModel.model.value">
                        <option value="" translate>Please.Choose</option>
                        <option *ngFor="let option of activeCustomField.options" value="{{option.id}}">{{option.text}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn"  class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
    <button class="btn btn-primary" [disabled]="validateItems()" (click)="addValue()">
        <span translate>Save</span>
    </button>
</div>
