import { environment } from '../../environments/environment';
export { environment } from '../../environments/environment';

export function GetJobApiUrl(path: string, params?: object) {
    const jobPrefix = environment.microserviceApiJob;
    let url = environment.apiHost + jobPrefix + path;
    if (params instanceof Object) {
        url += '?';

        Object.entries(params).forEach(([param, value]) => {
            url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
        });
    }
    return url;
}