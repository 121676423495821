import { OnDestroy } from '@angular/core';

export class InterfaceClass {
    subscriptions: Object = {};
    ngOnDestroy() {
        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(
            ([name, subscription]) => subscription.unsubscribe()
        );
    }
}
