<!-- Table Editor -->
<!-- configStyle -->
<ng-container *ngIf="editorStatus == 'config'">
  <div class="modal-header pricing-view-padding">
    <h4 class="modal-title pricing-view-title" translate>Pricing view </h4>
  </div>
  <div class="modal-body scrollbar">
    <div class="tax-block" *ngIf="vatEnabled">
      <span class="tex-text" translate [translateParams]="{object: taxLabel}">vat</span>
    </div>
    <div class="tax-type-block vat-table-border" *ngIf="vatEnabled">
      <span class="tax-type-text"><span translate [translateParams]="{object: taxLabel}">vat</span> type</span>
      <div class="flex-container row-top-align">
        <div class="flex-item-lefts">
          <div class="vertical-view-section">
            <div class="radio-button">
              <input type="radio" id="tax1" class="background-radiobtn" name="tax" value="withouttax"
                (click)="taxStatusToggle('withouttax')" [checked]="taxStatus == 'withouttax'">
              <span class="radio-text" translate [translateParams]="{object: taxLabel}">excl.vat</span>
            </div>
          </div>
        </div>
        <div class="flex-item-right">
          <div class="horizental-view-section">
            <div class="radio-button">
              <input type="radio" id="tax2" class="background-radiobtn" name="tax" value="withtax"
                (click)="taxStatusToggle('withtax')" [checked]="taxStatus == 'withtax'">
              <span class="radio-text" translate [translateParams]="{object: taxLabel}">incl.vat</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tax-type-help-text">
        <span class="help-text">If the <span translate [translateParams]="{object: taxLabel}">vat</span> changes here, it will change for all options. If the
          option contains more
          than one price segment, changing to "Included" also changes the other price segment.</span>
      </div>
    </div>
    <div class="price-breakdown-table-block" [ngClass]="{'hide-price-breakdown-section':isOptionSummary == true }">
      <span class="tex-text">Price breakdown table</span>
    </div>
    <div class="tax-type-block" [ngClass]="{'hide-price-breakdown-section':isOptionSummary == true }">
      <span class="tax-type-text">Show price breakdown table</span>
      <div class="flex-container row-top-align">
        <div class="flex-item-left">
          <div class="vertical-view-section">
            <div class="radio-button">
              <input type="radio" class="background-radiobtn" name="tablebreakdown" value="withtblbreakdown"
                (click)="priceTableBreakdown('withtblbreakdown')"
                [checked]="showPriceBreakdown == 'withbreakdown' || columnShowHide">
              <span class="radio-text">With table breakdown</span>
            </div>
            <div class="vertical-border">
              <div class="mr-1 float-rights"
                style="padding: 10px 27px 32px;background-color: #f9f9f9;border-radius: 4px;">
                <div class="vertical-lines-one"></div>
                <div class="vertical-lines-one"></div>
                <div class="vertical-lines-one"></div>
                <div class="vertical-line-half"></div>
                <div class="vertical-line-half"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-item-right">
          <div class="horizental-view-section">
            <div class="radio-button">
              <input type="radio" class="background-radiobtn" name="tablebreakdown" value="withouttblbreakdown"
                (click)="priceTableBreakdown('withouttblbreakdown')"
                [checked]="showPriceBreakdown == 'withouttblbreakdown' || !columnShowHide">
              <span class="radio-text">Without table breakdown</span>
            </div>
            <div class="vertical-border">
              <div class="mr-1 float-rights"
                style="padding: 10px 25px 83px 0px;background-color: #f9f9f9;border-radius: 4px;">
                <div class="vertical-line-half right-top-align"></div>
                <div class="vertical-line-half"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tax-type-help-text">
        <span class="help-text">How do you want your pricing to be displayed either with table breakdown or without
          table breakdown.</span>
      </div>
      <div class="show-column" *ngIf="columnShowHide">
        <span class="show-column-text">Show column</span>
        <div class="column-attributes margin-bottom-space d-flex">
          <div class="cust-checkbox-section w-100 d-flex">
            <div class="cust-checkbox-sec d-flex w-25" *ngFor="let tableHeader of tableHeaders;let index = index">
              <input type="checkbox" class="checkbox-alignment" name="tableHeaders" value="{{tableHeader.value}}"
                [checked]="tableHeader.checked" [(ngModel)]="tableHeader.checked"
                (change)="visibleColumnChange($event,index)">
              <div class="check-box-name">
                <span class="radio-text"> {{tableHeader.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="display-total-block">
      <span class="tex-text">Displaying total</span>
    </div>
    <div class="tax-type-block" style=" padding-bottom: 50px;">
      <span class="tax-type-text">Show total view</span>
      <div class="alignment-block margin-total-align">
        <div class="flex-container">
          <div class="flex-item-right">
            <div class="horizental-view-section">
              <div class="radio-button">
                <input type="radio" class="background-radiobtn" name="breakdownview" value="horizontal"
                  (click)="horizontalVisibilityToggle('horizontal')" [disabled]="isDisabledHorizentalOrVerticalOption" [checked]="breakdownView == 'horizontal'">
                <span class="radio-text">Horizontal view</span>
              </div>
              <div class="horizental-border-outline">
                <div class="row">
                  <div class="col-md-6 horizental-row">
                    <div class="vertical-horizental-line-block ">
                      <div class="horizental-line-one-left"></div>
                      <div class="horizental-line-two-left"></div>
                      <div class="horizental-line-three-left"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="vertical-horizental-line-block ">
                      <div class="horizental-line-one-left horizental-line-one-right"></div>
                      <div class="horizental-line-two-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item-left">
            <div class="vertical-view-section">
              <div class="radio-button">
                <input type="radio" class="background-radiobtn" name="breakdownview" value="vertical"
                  (click)="horizontalVisibilityToggle('vertical')" [disabled]="isDisabledHorizentalOrVerticalOption" [checked]="breakdownView == 'vertical'">
                <span class="radio-text">Vertical view</span>
              </div>
              <div class="vertical-border-outline">
                <div class="mr-0 float-right" style="margin-top: 7px;">
                  <div class="vertical-line-one"></div>
                  <div class="vertical-line-two"></div>
                  <div class="vertical-line-three"></div>
                  <div class="vertical-line-four"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="tax-type-help-text mb-0">
        <span class="help-text">How do you want your total price to be displayed either vertical or horizontal
          view.</span>
      </div>
      <div class="sub-total-block " *ngIf="vatEnabled">
        <span class="tax-type-text">Show sub total and <span translate [translateParams]="{object: taxLabel}">vat</span></span>
        <div class="flex-container row-top-align">
          <div class="flex-item-lefts">
            <div class="vertical-view-section">
              <div class="radio-button">
                <input type="radio" id="yes" class="background-radiobtn" name="subtotal" value="yes"
                  (click)="subtotalStatusChange('yes')" [disabled]="taxInclusive" [checked]="subtotalVisible == 'yes'">
                <span class="radio-text">Yes</span>
              </div>
            </div>
          </div>
          <div class="flex-item-right">
            <div class="horizental-view-section">
              <div class="radio-button">
                <input type="radio" id="no" class="background-radiobtn" name="subtotal" value="no"
                  (click)="subtotalStatusChange('no')" [checked]="subtotalVisible == 'no' || vatType == 'Inc' ">
                <span class="radio-text">No</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
    <button class="btn btn-primary">
      <span translate (click)="save()">Save</span>
    </button>
  </div>
</ng-container>


<ng-container *ngIf="editorStatus == 'style'">
  <div class="modal-header header-padding">
    <h4 class="modal-title pricing-view-title" translate>Pricing table design</h4>
  </div>
  <div class="modal-body scrollbar">

    <div class="advance-style-table advance-styleing">
      <div class="advance-title-style">
        <div class="tax-block">
          <span class="tex-text">Title style</span>
        </div>
        <div class="over-all-title-style-block">
          <div class="font-style weight-style ">
            <span class="title-margin">Font style</span>
            <div class="form-group row-top-align ">
              <select [ngModel]="selectedHeaderFont" class="curser select-width-95 common-border-color"
                (change)="headerFontChange($event.target.value)">
                <option *ngFor="let fontfamily of titleStyleFontFamily;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="weight-style ">
            <span>Font weight</span>
            <div class="form-group row-top-align ">
              <select class="curser common-border-color select-width-95" [(ngModel)]="selectedHeaderFontWeight"
                (change)="headerFontWeightChange($event.target.value)">
                <ng-container *ngFor="let fontweight of titleStyleFontWeight">
                  <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="weight-style">
            <span>Font size</span>
            <div class="form-group row-top-align ">
              <div class="size-alignment">
                <input [(ngModel)]="titleSize" (input)="titleRefresh()" type="number"
                  class="size-text-box common-border-color" onwheel="return false">
                <span class="text-box-px">px</span>
              </div>
            </div>
          </div>
          <div class="weight-style ">
            <span>Align</span>
            <div class="form-group row-top-align text-position-width">
              <div class="display-flex content-alignment-border">
                <div class="title-first-alignment title-set-remove-bg left-align-background" [ngClass]="{
                  'switch-color-content-alignment':titleAlignment == 'left'
                  }" (click)="titleAlignContent('left','title-first-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-left"></i>
                  </span>
                </div>
                <div class="title-sec-alignment title-set-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':titleAlignment == 'center'
                  }" (click)="titleAlignContent('center','title-sec-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-center"></i>
                  </span>
                </div>
                <div class="title-third-alignment title-set-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':titleAlignment == 'right'
                  }" (click)="titleAlignContent('right','title-third-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-right"></i>
                  </span>
                </div>
                <div class="title-fourth-alignment title-set-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':titleAlignment == 'justify'
                  }" (click)="titleAlignContent('justify','title-fourth-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-justify"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="weight-style">
            <span>Height</span>
            <div class="form-group row-top-align ">
              <div class="size-alignment">
                <input [(ngModel)]="titleHeight" (input)="titleRefresh('height')" type="number"
                  class="size-text-box common-border-color">
                <span class="text-box-px">px</span>
              </div>
            </div>
          </div>

          <div class="weight-style ">
            <span>Text color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="titleClr"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleClr,$event,isNormalTable,'')" [(ngModel)]="titleClr">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue" (colorValue)="getColor($event,'titleTextColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>
          <div class="weight-style advance-style-block-end">
            <span>Background color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown"  [style.background]="titleBg"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleBg,$event,isNormalTable,'')" [(ngModel)]="titleBg">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue"  (colorValue)="getColor($event,'titleBackgroundColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>

        </div>
        <div class="tax-block row-style-block-style">
          <span class="tex-text">Row style</span>
        </div>

        <div class="over-all-title-style-block">
          <div class="font-style weight-style">
            <span>Font style</span>
            <div class="form-group row-top-align ">
              <select [ngModel]="seletedRowFont" class="curser common-border-color select-width-95"
                (change)="rowFontChange($event.target.value)">
                <option *ngFor="let fontfamily of titleStyleFontFamily;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="weight-style ">
            <span>Font weight</span>
            <div class="form-group row-top-align ">
              <select class="curser common-border-color select-width-95" [(ngModel)]="selectedRowFontWeight"
                (change)="rowFontWeightChange($event.target.value)">
                <ng-container *ngFor="let fontweight of rowStyleFontWeight">
                  <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="weight-style ">
            <span>Font size</span>
            <div class="form-group row-top-align ">
              <div class="size-alignment">
                <input [(ngModel)]="rowSize" (input)="rowRefresh()" type="number"
                  class="size-text-box common-border-color" onwheel="return false">
                <span class="text-box-px">px</span>
              </div>
            </div>
          </div>
          <div class="weight-style ">
            <span>Align</span>
            <div class="form-group row-top-align text-position-width">
              <div class="display-flex content-alignment-border">

                <div class="row-first-alignment row-set-remove-bg left-align-background" [ngClass]="{
                  'switch-color-content-alignment':rowAlignment == 'left'
                  }" (click)="rowAlignContent('left','row-first-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-left"></i>
                  </span>
                </div>
                <div class="row-sec-alignment row-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':rowAlignment == 'center'
                  }" (click)="rowAlignContent('center','row-sec-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-center"></i>
                  </span>
                </div>
                <div class="row-third-alignment row-set-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':rowAlignment == 'right'
                  }" (click)="rowAlignContent('right','row-third-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-right"></i>
                  </span>
                </div>
                <div class="row-fourth-alignment row-set-remove-bg" [ngClass]="{
                  'switch-color-content-alignment':rowAlignment == 'justify'
                  }" (click)="rowAlignContent('justify','row-fourth-alignment')">
                  <span class="align-font">
                    <i class="fas fa-align-justify"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="weight-style">
            <span>Minimum height</span>
            <div class="form-group row-top-align ">
              <div class="size-alignment">
                <input [(ngModel)]="rowHeight"  (input)="rowRefresh();handleInput($event)" type="number"
                  class="size-text-box common-border-color">
                <span class="text-box-px">px</span>
              </div>
            </div>
          </div>

          <div class="weight-style ">
            <span>Text color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowTextClr"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextClr,$event,isNormalTable,'')" [(ngModel)]="rowTextClr">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue" (colorValue)="getColor($event,'rowTextColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>
          <div class="weight-style top-margin-align">
            <span>Background color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowBgClr"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowBgClr,$event,isNormalTable,'')" [(ngModel)]="rowBgClr">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue" (colorValue)="getColor($event,'rowBackgroundColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>
          <div class="weight-style top-margin-align advance-style-block-end">
            <span>Alternative color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowAltClr"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowAltClr,$event,isNormalTable,'')" [(ngModel)]="rowAltClr">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue"  (colorValue)="getColor($event,'rowAlternateColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="tax-block row-style-block-style">
          <span class="tex-text">Border</span>
        </div>
        <div class="over-all-title-style-block">
          <div class="weight-style ">
            <span>Border width</span>
            <div class="form-group row-top-align ">
              <div class="size-alignment">
                <input [(ngModel)]="borderWidth" (change)="defaultColor($event)" (input)="borderRefresh();defaultColor($event)" type="number"
                  class="size-text-box common-border-color">
                <span class="text-box-px">px</span>
              </div>
            </div>
          </div>

          <div class="weight-style ">
            <span>Border radius</span>
            <div class="form-group row-top-align ">
              <div class="input-group-area border-alignment">
                <input [disabled]="borderIndividualSide" maxlength="3"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  [(ngModel)]="borderRadius" (input)="borderRefresh()" type="text"
                  class="mb-0 input-textbox-height advance-border-radius common-border-color">
                <span class="text-box-px" style="top: 0px;">px</span>
                <div class="all-side-border-radius" (click)="radiusAllSide()">
                  <i class="fas fa-expand-wide"></i>
                </div>
              </div>

              <div class="form-group" *ngIf="borderIndividualSide">
                <div class="border-radius-all-size-align">
                    <div>Top left border radius</div>
<!--                  <i class="fas fa-border-style border-text-icon-align  mrt-5 ft-16"></i>-->
                  <div class="input-group" style="margin-right: 4px;">
                    <div class="input-group-area border-alignment">
                      <input (input)="borderRefresh()" [(ngModel)]="borderTopLeft" type="text" maxlength="3"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="mb-0 input-textbox-height radius-input right-border ">
                      <span class="input-group-px ">px</span>
                    </div>
                  </div>
                    <div>Top right border radius</div>
<!--                  <i class="fas fa-border-style border-text-icon-align  mrt-5 ft-16"-->
<!--                    style="transform: rotate(90deg);"></i>-->
                  <div class="input-group" style="margin-right: 4px;">
                    <div class="input-group-area border-alignment">
                      <input (input)="borderRefresh()" [(ngModel)]="borderTopRight" type="text" maxlength="3"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="mb-0 input-textbox-height radius-input right-border">
                      <span class="input-group-px">px</span>
                    </div>
                  </div>
                    <div>Bottom right border radius</div>
<!--                  <i class="fas fa-border-style-alt border-text-icon-align  mrt-5 ft-16"></i>-->
                  <div class="input-group" style="margin-right: 4px;">
                    <div class="input-group-area border-alignment">
                      <input (input)="borderRefresh()" [(ngModel)]="borderBottomRight" type="text" maxlength="3"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="mb-0 input-textbox-height radius-input right-border">
                      <span class="input-group-px">px</span>
                    </div>
                  </div>
                    <div>Bottom left border radius</div>
<!--                  <i class="fas fa-border-style-alt border-text-icon-align  mrt-5 ft-16"-->
<!--                    style="transform: rotate(90deg);"></i>-->
                  <div class="input-group" style="margin: 0;">
                    <div class="input-group-area border-alignment">
                      <input (input)="borderRefresh()" [(ngModel)]="borderBottomLeft" type="text" maxlength="3"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="mb-0 input-textbox-height radius-input right-border">
                      <span class="input-group-px">px</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="weight-style advance-style-block-end" [ngClass]="[isNormalTable ? 'isNormaltable-added-bottom' : 'isPricingAndSummaryTable']" >
            <span>Border color</span>
            <div class="form-group row-top-align color-label-top">
                <div class="cs-color-palatte dropdown">
                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="borderColorStyle"></span>
                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(borderColorStyle,$event,isNormalTable,'borderColor')" [(ngModel)]="borderColor">
                    <div class="dropdown-menu dropdown-height">
                        <color-palatte [data]="myValue" (colorValue)="getColor($event,'borderColor')"></color-palatte>
                    </div>
                </div>
            </div>
          </div>
        </div>

          <ng-container *ngIf="!isNormalTable">
              <div class="tax-block row-style-block-style">
                  <span class="tex-text">Total</span>
              </div>
              <div class="total-section">
                  <span>Sub total</span>
              </div>
              <div class="over-all-title-style-block">

                  <div class="font-style weight-style ">
                      <span class="title-margin">Font style</span>
                      <div class="form-group row-top-align ">
                          <select [ngModel]="subtotalHeaderFont" class="curser select-width-95 common-border-color"
                                  (change)="subtotalFontChange($event.target.value)">
                              <option *ngFor="let fontfamily of titleStyleFontFamily;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                              </option>
                          </select>
                      </div>
                  </div>
                  <div class="weight-style ">
                      <span>Font weight</span>
                      <div class="form-group row-top-align ">
                          <select class="curser common-border-color select-width-95" [(ngModel)]="subtotalHeaderFontWeight"
                                  (change)="subtotalFontWeightChange($event.target.value)">
                              <ng-container *ngFor="let fontweight of subtotalTitleStyleFontWeight">
                                  <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                              </ng-container>
                          </select>
                      </div>
                  </div>
                  <div class="weight-style">
                      <span>Font size</span>
                      <div class="form-group row-top-align ">
                          <div class="size-alignment">
                              <input [(ngModel)]="subtotalTitleSize" (input)="titleRefresh();checkInputvalue($event,'subtotal')" max="20" type="number"
                                     class="size-text-box common-border-color" onwheel="return false">
                              <span class="text-box-px">px</span>
                          </div>
                      </div>
                  </div>
                  <div class="weight-style advance-style-block-end">
                      <span>Text color</span>
                      <div class="form-group row-top-align color-label-top">
                          <div class="cs-color-palatte dropdown">
                              <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="subtotalTitleClr"></span>
                              <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(subtotalTitleClr,$event,isNormalTable,'')" [(ngModel)]="subtotalTitleClr">
                              <div class="dropdown-menu dropdown-height">
                                  <color-palatte [data]="myValue" (colorValue)="getColor($event,'subtotal')"></color-palatte>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="total-section">
                  <span>Grand total</span>
              </div>
              <div class="over-all-title-style-block">
                  <div class="font-style weight-style ">
                      <span class="title-margin">Font style</span>
                      <div class="form-group row-top-align ">
                          <select [ngModel]="grandtotalHeaderFont" class="curser select-width-95 common-border-color"
                                  (change)="grandtotalFontChange($event.target.value)">
                              <option *ngFor="let fontfamily of titleStyleFontFamily;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                              </option>
                          </select>
                      </div>
                  </div>
                  <div class="weight-style ">
                      <span>Font weight</span>
                      <div class="form-group row-top-align ">
                          <select class="curser common-border-color select-width-95" [(ngModel)]="grandtotalHeaderFontWeight"
                                  (change)="grandtotalFontWeightChange($event.target.value)">
                              <ng-container *ngFor="let fontweight of grandtotalTitleStyleFontWeight">
                                  <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                              </ng-container>
                          </select>
                      </div>
                  </div>
                  <div class="weight-style">
                      <span>Font size</span>
                      <div class="form-group row-top-align ">
                          <div class="size-alignment">
                              <input [(ngModel)]="grandtotalTitleSize" (input)="titleRefresh();checkInputvalue($event,'grandtotal')" max="20" maxlength="2" type="number"
                                     class="size-text-box common-border-color" onwheel="return false">
                              <span class="text-box-px">px</span>
                          </div>
                      </div>
                  </div>
                  <div class="weight-style last-element-padding">
                      <span>Text color</span>
                      <div class="form-group row-top-align color-label-top">
                          <div class="cs-color-palatte dropdown">
                              <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="grandtotalTitleClr"></span>
                              <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(grandtotalTitleClr,$event,isNormalTable,'grandtotal')" [(ngModel)]="grandtotalTitleClr">
                              <div class="dropdown-menu dropdown-height">
                                  <color-palatte [data]="myValue" (colorValue)="getColor($event,'grandtotal')"></color-palatte>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer bottom-footer-alignment-table-format">
<!--    <button class="btn btn-primary btn-left" [disabled]="templateName!= null">-->
<!--      <span translate (click)="saveTemplate()">Save.template</span>-->
<!--    </button>-->
    <a href="javascript:void(0)" class="btn" (click)="cancel()">
      <span id="cancel-styles">Cancel</span>
    </a>
    <button class="btn btn-primary" (click)="save()">
      <span translate id="save-styles">Save</span>
    </button>
  </div>
</ng-container>
