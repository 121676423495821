<ng-container>
    <div class="modal-header">
        <span class="cursor-pointer float-right modal-close-icon-size" (click)="modalRef.hide()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <h4 class="modal-title pull-left" translate>Send.Sequence</h4>
    </div>
    <div class="modal-body ng-workflow-add-block">
        <div #diagramFormat></div>
        <ng-container *ngIf="!isLoading; else: loadingTpl">
            <div class="cs-d-flex flex-column height-100">
                <div class="select-sequence-template select-cols">
                    <div class="controls">
                        <input
                            [(ngModel)]="selectedSequenceModal"
                            (ngModelChange)="onChangeSequence()"
                            class="full-width-select"
                            csSelect2
                            [select2Config]="select2Config"
                            placeholder="Choose sequence template" />
                    </div>
                </div>
                <div class="select-sequence-template select-cols">
                    <div class="controls" [ngClass]="{'hidden': displaySelectedContactInfo['emailAddress'] || displaySelectedContactInfo['mobile']}">
                        <input
                            [(ngModel)]="selectedContactModal"
                            (ngModelChange)="onChangeContact()"
                            class="full-width-select" csSelect2
                            [select2Config]="select2ContactsConfig"
                            placeholder="Send sequence to"
                            ($instance)="setContactDropdown($event)"
                            [disabled]="disabledContact"/>
                    </div>
                    <div class="cs-d-flex pos-rlt cs-px3 display-contact" [ngClass]="{'hidden': !displaySelectedContactInfo['emailAddress'] && !displaySelectedContactInfo['mobile']}">
                        <div class="cs-mr2">{{ displaySelectedContactName }}</div>
                        <div class="cs-mr2" *ngIf="displaySelectedContactInfo['emailAddress'] && displaySelectedContactInfo['emailAddress'] != ''">
                            <i class="fas fa-envelope"></i> {{displaySelectedContactInfo['emailAddress']}}
                        </div>
                        <div class="cs-mr2" *ngIf="displaySelectedContactInfo['mobile'] && displaySelectedContactInfo['mobile'] != ''">
                            <i class="fas fa-mobile"></i> {{ displaySelectedContactInfo['mobile'] }}
                        </div>
                        <div class="contact-reset" (click)="onResetContact()"><i class="far fa-times"></i></div>
                    </div>
                </div>

                <div class="cs-p3 border-b" *ngIf="selectedContactModal" [hidden]="(selectedContactMobileModal != '' && selectedContactEmailModal != '')">
                    <div #mobileContainer class="anim boxed-input" *ngIf="selectedCustomerContact?.mobile &&  selectedCustomerContact.mobile.length > 1">
                        <div class="cs-mb2" *ngIf="selectedCustomerContact.mobile?.length > 1">Select a mobile number to send SMS messages</div>
                        <div class="form-check cs-mb1" *ngFor="let item of selectedCustomerContact.mobile; let last = last;" [ngClass]="{'cs-mb1': !last}">
                            <input
                                name="contact_mobile"
                                class="form-check-input"
                                type="radio"
                                [value]="item.telephone_id"
                                [checked]="selectedContactMobileModal == item.telephone_id"
                                (change)="onCheckedContact($event, 'mobile')"
                                id="dp_{{item.telephone_id}}">
                            <label class="form-check-label" for="dp_{{item.telephone_id}}">
                                +{{item.code}} {{item.phone_number}}
                            </label>
                        </div>
                    </div>
                    <div class="text-color-danger" *ngIf="selectedCustomerContact.mobile?.length == 0">
                        <i class="fas fa-exclamation-triangle"></i>The contact doesn’t have a mobile number.
                        <button (click)="showAddContact('mobile')" class="btn btn-plain link-color">Click to add a number</button>
                    </div>

                    <ng-container *ngIf="selectedCustomerContact?.email">
                        <div class="cs-mt2 text-color-danger" *ngIf="!selectedCustomerContact.email['emailAddress']">
                            <i class="fas fa-exclamation-triangle"></i> The contact doesn’t have an email address.
                            <button (click)="showAddContact('email')" class="btn btn-plain link-color">Click to add an email address</button>
                        </div>
                    </ng-container>
                </div>
                <div class="cs-flex-1 cs-p3 pos-rlt scrollable" *ngIf="selectedWorkflowBlocksList?.length; else: emptyMessage">
                    <div class="abt-full color-light" *ngIf="onChangeSequenceLoading"></div>
                    <ng-container *ngIf="!onChangeSequenceLoading; else: sequenceLoading">
                        <div class="cs-d-flex cs-py1 text-truncate" *ngFor="let block of selectedWorkflowBlocksList" [ngClass]="{'text-color-danger': (block.name==='emailBlock' && !contactHasEmail) || (block.name==='smsBlock' && !contactHasMobile)}">
                            <div class="cs-mr2"><i class="{{displayBlocks[block.name].iconClass}}"></i></div>
                            <div class="text-truncate" [ngClass]="{'text-line-through': (block.name==='emailBlock' && !contactHasEmail) || (block.name==='smsBlock' && !contactHasMobile)}">{{displayBlocks[block.name].text}}: {{getDisplayText(block)}}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn" (click)="modalRef.hide()" [disabled]="sendingSequenceText != 'Send'">Discard</button>
        <button class="btn sequence-modal-btn" [disabled]="sendingSequenceText != 'Send' || !selectedSequenceModal || !selectedContactModal" (click)="onEditWorkflow()">Edit</button>
        <button class="btn btn-primary"
            (click)="sendSequenceAction()"
            [disabled]="sendingSequenceText != 'Send'
            || !selectedSequenceModal
            || !selectedContactModal
            || (!displaySelectedContactInfo['emailAddress'] && !displaySelectedContactInfo['mobile'])
            || (contactHasMobile && !displaySelectedContactInfo['mobile'])
            || onChangeSequenceLoading" translate>
            <span>{{sendingSequenceText}}</span>
        </button>
    </div>
</ng-container>


<cs-full-screen-modal
    bodyClass="partsandprices_fslayouts"
    zIndex="1051"
    [header]="fullScreenModalOptions.title"
    (getCustomHeaderInstance)="setCustomHeaderInstance($event)"
    [customHeader]="customHeader"
    [showFooter]="false"
    (onContentOpen)="onShownFSModal()"
    (onContentClose)="onHiddenFSModal()">
    <app-sequence-container
        [csFullModal]="csFullModal"
        [isEnabled]="isOpenEditSequence"
        [sequenceObjectName]="objectName"
        [sequenceMode]="'sequence_config'"
        (readyToView)="readyToDiagramDisplay($event)">
    </app-sequence-container>
</cs-full-screen-modal>


<ng-template #sequenceLoading>
    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
            <div class="ph-row align-items-center">
                <div class="ph-col-8 mb3"></div>
                <div class="ph-col-10 mb3"></div>
                <div class="ph-col-6 mb3"></div>
                <div class="ph-col-12 mb3"></div>
                <div class="ph-col-8 mb3"></div>
                <div class="ph-col-6 mb3"></div>
                <div class="ph-col-8 mb3"></div>
                <div class="ph-col-12 mb3"></div>
                <div class="ph-col-4 mb3"></div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #emptyMessage>
    <div class="cs-p3 height-100">
        <div class="empty-message-container cs-d-flex justify-content-center flex-column text-center cs-d-flex-wrap height-100 pos-rlt" *ngIf="!onChangeSequenceLoading; else: sequenceLoading">
            <div class="empty-message-icon">
                <i class="fas fa-sitemap"></i>
            </div>
            <div class="empty-message-content">
                Choose a sequence template to preview what will get sent
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loadingTpl>
    <div class="cs-d-flex flex-column height-100">
        <div class="select-sequence-template select-cols">
            <div class="ph-item height-100">
                <div class="ph-col-12 height-100">
                    <div class="ph-row height-100 align-items-center">
                        <div class="ph-col-12 big"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="select-sequence-template select-cols">
            <div class="ph-item height-100">
                <div class="ph-col-12 height-100">
                    <div class="ph-row height-100 align-items-center">
                        <div class="ph-col-12 big"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cs-flex-1">
            <div class="ph-item height-100">
                <div class="ph-col-12 height-100">
                    <div class="ph-row align-items-center">
                        <div class="ph-col-8 mb3 mt3"></div>
                        <div class="ph-col-10 mb3"></div>
                        <div class="ph-col-6 mb3"></div>
                        <div class="ph-col-10 mb3"></div>
                        <div class="ph-col-8 mb3"></div>
                        <div class="ph-col-6 mb3"></div>
                        <div class="ph-col-10 mb3"></div>
                        <div class="ph-col-4 mb3"></div>
                        <div class="ph-col-8 mb3"></div>
                        <div class="ph-col-6 mb3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


