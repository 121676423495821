import { OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from "ngx-bootstrap";
import * as moment from 'moment';
import { WorkflowhttpService } from "../services/workflowhttp.service";
var capitalize = function (s) {
    if (typeof s !== 'string')
        return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
var ɵ0 = capitalize;
var HistoryTimelinePanelComponent = /** @class */ (function () {
    function HistoryTimelinePanelComponent(modalRef, dataService) {
        this.modalRef = modalRef;
        this.dataService = dataService;
        this.showSuccessStatus = false;
        this.showFailedStatus = false;
        this.showSkippedStatus = false;
        this.isTaskOnSameDate = false;
        this.showFullDetails = false;
        this.emailHidden = false;
        this.smsHidden = false;
        this.showLessDetails = false;
    }
    HistoryTimelinePanelComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.showSuccessStatus = this.taskItem['status'] === 'success';
        this.showFailedStatus = this.taskItem['status'] === 'failed';
        this.showSkippedStatus = this.taskItem['status'] === 'skipped';
        this.communicationId = this.taskItem['taskParams']["communicationId"];
        this.title = capitalize(this.cell['block_name']);
        if (this.title == 'EmailBlock' || this.title == 'SmsBlock') {
            this.showFullDetails = true;
        }
        this.startDate = moment.utc(this.taskItem['startDate']['date']).local();
        if (this.taskItem['endDate']) {
            this.endDate = moment.utc(this.taskItem['endDate']['date']).local();
        }
        if (this.endDate) {
            this.isTaskOnSameDate = this.startDate.format('YYYY-MM-DD') === this.endDate.format('YYYY-MM-DD');
        }
        console.log(this.taskItem);
    };
    HistoryTimelinePanelComponent.prototype.viewFullDetails = function (id) {
        var _this = this;
        if (this.title == 'EmailBlock') {
            if (id) {
                this.dataService.getCommunicationEmailDetails(id).subscribe(function (resp) {
                    if (!resp)
                        return;
                    _this.showFullDetails = false;
                    _this.emailHidden = true;
                    _this.showLessDetails = true;
                    _this.fromEmail = resp.fromEmail;
                    _this.toEmail = resp.toEmail;
                    _this.ccEmail = resp.ccEmail;
                    _this.bccEmail = resp.bccEmail;
                    _this.subject = resp.subject;
                    _this.message = resp.message;
                    _this.sentBy = resp.sentBy;
                    _this.status = resp.status;
                });
            }
        }
        else if (this.title == 'SmsBlock') {
            this.showFullDetails = false;
            this.showLessDetails = true;
            this.smsHidden = true;
            this.taskDetails = this.taskItem.taskParams.status.status;
            this.message = this.taskItem.taskParams.status.message;
        }
    };
    HistoryTimelinePanelComponent.prototype.viewLessDetails = function () {
        this.showFullDetails = true;
        this.showLessDetails = false;
        if (this.title == 'EmailBlock') {
            this.emailHidden = false;
        }
        else if (this.title == 'SmsBlock') {
            this.smsHidden = false;
        }
    };
    return HistoryTimelinePanelComponent;
}());
export { HistoryTimelinePanelComponent };
export { ɵ0 };
