import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UploadDocumentService } from '@app/features/ai-document-parsing/services/upload-document.service';
import { CsToastBoxService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
var UploadDocumentComponent = /** @class */ (function () {
    function UploadDocumentComponent(renderer, el, fileUploadingService, toastBox, translate) {
        this.renderer = renderer;
        this.el = el;
        this.fileUploadingService = fileUploadingService;
        this.toastBox = toastBox;
        this.translate = translate;
        this.docparserAllowedExtension = ['application/pdf', 'image/tiff'];
        this.mindeeAllowedExtension = ['application/pdf'];
        this.allowedFileType = ['pdf', 'tiff'];
        this.extensions = ['application/pdf', 'image/tiff', 'application/msword', 'applicaiton/vnd.openxmlformat', 'application/pdf', 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel'];
        this.selectedActions = "existingTemplate"; // Variable to store selected action in modal
        this.suppliersList = [];
        this.supplierId = null;
        this.uploadCount = 0;
        this.isFileUploaded = false;
        this.filesCount = 0;
        this.select2Options4Supplier = {};
        this.errorMsg = null; // To show error message for postDocParserDocument API
        this.successMsg = null;
        this.fileCountError = null;
        this.disableStartUpload = false; // To disable Start Upload Button
        this.progressValue = 0; // Set the initial progress value (0-100)
        this.showProgressBar = false;
        this.displayMessage = false;
        this.isFileDrop = false;
        this.isCustomDimensions = false;
        this.droppedFiles = [];
        this.navigate = false;
    }
    UploadDocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.select2Options4Supplier = {
            minimumInputLength: 2,
            width: "100%",
            initSelection: function () { },
            ajax: {
                url: environment.apiBasePath + 'get_all_suppliers',
                data: function (searchText, page) {
                    if (searchText == '') {
                        return false;
                    }
                    return { 'q': encodeURIComponent(searchText) };
                },
                results: function (data) {
                    data['response'] = data;
                    if (Array.isArray(data.response)) {
                        for (var _i = 0, _a = data.response; _i < _a.length; _i++) {
                            var item = _a[_i];
                            var index = _this.suppliersList.find(function (el) { return el.id == item.id; });
                            if (index == -1) {
                                _this.suppliersList.push(item);
                            }
                        }
                    }
                    return { results: data.response };
                },
            },
        };
    };
    UploadDocumentComponent.prototype.onResize = function (event) {
        this.getDimensions();
    };
    UploadDocumentComponent.prototype.getDimensions = function () {
        // Get the window dimensions
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        // Apply your custom logic to determine if the styles should be applied
        this.isCustomDimensions = windowWidth > 800 && windowHeight > 600; // Example: Apply styles if both width and height exceed certain values
        // Apply styles to the host element
        if (this.isCustomDimensions) {
            this.renderer.addClass(this.el.nativeElement, 'jobUploadSection');
        }
        else {
            this.renderer.removeClass(this.el.nativeElement, 'jobUploadSection');
        }
    };
    UploadDocumentComponent.prototype.onDragOver = function (event) {
        event.preventDefault();
        event.stopPropagation();
        // Check if CSV files are being dragged
        this.filesCount = event.dataTransfer.items.length;
        if (this.droppedFiles.length == 0) {
            document.getElementById("fileUploadingModal").style.display = "inline-block";
            var testAutomatedAI = document.getElementById('testAutomatedAI');
            testAutomatedAI.style.border = "2px dashed var(--neutral-focus, #9ED1F3)";
            testAutomatedAI.style.minHeight = "calc(100% + 15px)";
            testAutomatedAI.style.position = "relative";
            testAutomatedAI.style.zIndex = "20000";
            testAutomatedAI.style.backgroundColor = "rgba(56, 131, 193, 0.12)";
        }
    };
    UploadDocumentComponent.prototype.onDragLeave = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.droppedFiles.length == 0 && this.isFileDrop == false) {
            var testAutomatedAI = document.getElementById('testAutomatedAI');
            testAutomatedAI.style.removeProperty('border');
            testAutomatedAI.style.removeProperty('minHeight');
            testAutomatedAI.style.removeProperty('position');
            testAutomatedAI.style.removeProperty('zIndex');
            testAutomatedAI.style.removeProperty('backgroundColor');
            testAutomatedAI.style.backgroundColor = "#f7faff";
            document.getElementById("hideZIndexProperty").style.zIndex = "0";
        }
        else {
            document.getElementById("hideZIndexProperty").style.zIndex = "10";
        }
    };
    UploadDocumentComponent.prototype.onDrop = function (event) {
        this.getSelectedOptions(this.selectedActions, true);
        event.preventDefault();
        event.stopPropagation();
        this.isFileDrop = true;
        if (this.droppedFiles.length == 0) {
            var testAutomatedAI = document.getElementById('testAutomatedAI');
            testAutomatedAI.style.removeProperty('border');
            testAutomatedAI.style.removeProperty('minHeight');
            testAutomatedAI.style.removeProperty('position');
            testAutomatedAI.style.removeProperty('zIndex');
            testAutomatedAI.style.backgroundColor = "#f7faff";
        }
        var files = event.dataTransfer.files;
        if (files) {
            // Iterate over each file and add it to the droppedFiles array
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (!this.isFileInCollection(file)) {
                    this.droppedFiles.push(file);
                }
                // Additional logic for processing or handling each dropped file can be added here
            }
            // Additional logic for processing or handling all dropped files can be added here
            this.checkStartUpload();
        }
        this.fileUploadingService.sendFilesToUpload(this.droppedFiles);
    };
    UploadDocumentComponent.prototype.onFileSelected = function (event) {
        var inputElement = this.fileInput.nativeElement;
        var files = inputElement.files;
        if (files && files.length > 0) {
            this.handleFiles(files);
        }
        this.checkStartUpload();
    };
    UploadDocumentComponent.prototype.handleFiles = function (files) {
        for (var i = 0; i < files.length; i++) {
            if (!this.isFileInCollection(files[i])) {
                this.droppedFiles.push(files[i]);
            }
        }
    };
    UploadDocumentComponent.prototype.openModalBox = function () {
        var trigger = document.getElementById("uploadModalTrigger");
        trigger.click();
    };
    // This function will be called when we will select the options
    UploadDocumentComponent.prototype.getSelectedOptions = function (option, innerCall) {
        var _this = this;
        if (innerCall === void 0) { innerCall = false; }
        if (option == null) {
            this.selectedActions = 'existingTemplate';
        }
        else {
            this.selectedActions = option;
        }
        this.errorMsg = null;
        if (this.selectedActions == 'existingTemplate') {
            this.fileUploadingService.getSuppliers().subscribe(function (resp) {
                _this.suppliersList = resp;
            });
        }
        if (!innerCall) {
            this.supplierId = null;
        }
        this.checkStartUpload();
    };
    UploadDocumentComponent.prototype.getBorderStyle = function (percentage) {
        var borderColor = '#007bff'; // Blue color
        return "linear-gradient(90deg, " + borderColor + " " + percentage + "%, transparent 0%)";
    };
    // You can add your code after submission here . You will get selected option in "selectedActions", selected suppliers in "selectedSupplier", files in "files" this is an array of objects
    UploadDocumentComponent.prototype.startToUpload = function () {
        var _this = this;
        this.showProgressBar = true;
        this.successMsg = null;
        this.errorMsg = null;
        this.progressValue = 5; // Set the initial progress value (0-100)
        var duration = 5000; // Duration in milliseconds
        var increment = 5; // Value increment per 100 milliseconds
        var interval = 100; // Update every 100 milliseconds
        var steps = duration / interval;
        var data = {
            'supplierid': this.supplierId,
            'jobid': this.jobid,
            'files': this.droppedFiles
        };
        var updateValue = function () {
            if (_this.successMsg == null && _this.errorMsg == null) {
                if (_this.progressValue < 90) {
                    _this.progressValue += increment;
                }
                else {
                    // Wait till response comes from postDocParserDocument
                    _this.progressValue = 95;
                }
            }
        };
        if (this.selectedActions == "existingTemplate") {
            var stopTimer_1 = setInterval(updateValue, interval);
            // Integrate postDocParserDocument API
            this.fileUploadingService.postDocParserDocument(data).subscribe(function (resp) {
                if (resp['error'] == false) {
                    _this.successMsg = "";
                }
                else {
                    _this.errorMsg = resp['error'];
                }
            }, function (error) {
                if (error.hasOwnProperty('errorMessage')) {
                    _this.errorMsg = error['errorMessage'];
                }
                else {
                    _this.errorMsg = _this.translate.instant('Error.while.uploading.files');
                }
            }).add(function () {
                // Called when operation is complete (both success and error)
                clearInterval(stopTimer_1);
                if (_this.successMsg != null) {
                    _this.progressValue = 100;
                    setTimeout(function () {
                        _this.closeModal();
                    }, 100);
                }
                else {
                    _this.progressValue = 0;
                    _this.disableStartUpload = false;
                }
            });
        }
        else if (this.selectedActions == "newTemplate") {
            // Integrate postDocumentAIParser API
            var stopTimer_2 = setInterval(updateValue, interval);
            this.fileUploadingService.postDocumentAIParser(data).subscribe(function (resp) {
                if (resp['StatusCode'] == 200) {
                    _this.successMsg = resp['message'];
                }
                else {
                    _this.errorMsg = resp['error'];
                }
            }, function (error) {
                if (error.hasOwnProperty('errorMessage')) {
                    _this.errorMsg = error['errorMessage'];
                }
                else {
                    _this.errorMsg = _this.translate.instant('Error.while.uploading.files');
                }
            }).add(function () {
                // Called when operation is complete (both success and error)
                clearInterval(stopTimer_2);
                if (_this.successMsg != null) {
                    _this.progressValue = 100;
                    setTimeout(function () {
                        _this.closeModal();
                    }, 100);
                }
                else {
                    _this.progressValue = 0;
                    _this.disableStartUpload = false;
                }
            });
        }
    };
    UploadDocumentComponent.prototype.openFileInput = function () {
        this.fileInput.nativeElement.click();
    };
    UploadDocumentComponent.prototype.handleFileSelections = function (event) {
        var inputElement = event.target;
        var files = inputElement.files;
        // Handle the selected files
        this.addSelectedFiles(Array.from(files));
    };
    UploadDocumentComponent.prototype.addSelectedFiles = function (selectedFiles) {
        for (var _i = 0, selectedFiles_1 = selectedFiles; _i < selectedFiles_1.length; _i++) {
            var file = selectedFiles_1[_i];
            // Check if the file is not already in the filesCollection
            if (!this.isFileInCollection(file)) {
                // If not present, add it to the filesCollection
                this.droppedFiles.push(file);
            }
        }
    };
    // Function to check if a file is already in the filesCollection
    UploadDocumentComponent.prototype.isFileInCollection = function (file) {
        return this.droppedFiles.some(function (existingFile) {
            return existingFile.name === file.name && existingFile.size === file.size;
        });
    };
    UploadDocumentComponent.prototype.removeFile = function (fileToRemove) {
        this.droppedFiles = this.droppedFiles.filter(function (file) { return file !== fileToRemove; });
        this.checkStartUpload();
    };
    UploadDocumentComponent.prototype.closeModal = function () {
        if (this.successMsg != null && this.selectedActions == 'existingTemplate') {
            this.toastBox.show(this.translate.instant('autoparsing.file.upload.success'), 3000);
        }
        else if (this.successMsg != null && this.selectedActions == 'newTemplate') {
            this.toastBox.show(this.translate.instant('file.successfully.submitted.to.commusoft.supplier.ai'), 3000);
        }
        this.isFileDrop = false;
        var count = this.droppedFiles.length;
        for (var i = 0; i < count; i++) {
            this.droppedFiles.pop();
        }
        this.selectedActions = null;
        this.errorMsg = null;
        this.successMsg = null;
        this.disableStartUpload = false;
        this.fileCountError = null;
        this.displayMessage = false;
        this.progressValue = 0;
        this.supplierId = null;
        this.suppliersList = [];
        document.getElementById("hideZIndexProperty").style.zIndex = "0";
        setTimeout(function () {
            document.getElementById("fileUploadingModal").style.display = "none";
        }, 500);
    };
    UploadDocumentComponent.prototype.selectSupplier = function (event) {
        this.errorMsg = null;
        this.supplierId = event;
        this.checkStartUpload();
    };
    // This function will be used to check whether to call API or not. If all validations are passed call the API 
    UploadDocumentComponent.prototype.checkStartUpload = function () {
        this.disableStartUpload = false;
        this.progressValue = 0;
        this.errorMsg = null;
        this.successMsg = null;
        if (this.droppedFiles.length > 10) {
            this.fileCountError = this.translate.instant('maximum.file.limit', { fileLimit: 10 });
            this.disableStartUpload = false;
            return;
        }
        else {
            this.fileCountError = null;
        }
        if (this.selectedActions == 'existingTemplate' && this.supplierId == null) {
            this.disableStartUpload = false;
            return;
        }
        for (var i = 0; i < this.droppedFiles.length; i++) {
            var size = this.droppedFiles[i].size / (1024 * 1024);
            if ((this.selectedActions == 'existingTemplate' && this.docparserAllowedExtension.includes(this.droppedFiles[i].type) && size <= 5) || (this.selectedActions == 'newTemplate' && this.mindeeAllowedExtension.includes(this.droppedFiles[i].type) && size <= 5)) {
                this.disableStartUpload = true;
            }
            else {
                this.disableStartUpload = false;
                return;
            }
        }
        if (this.droppedFiles.length == 0) {
            this.disableStartUpload = false;
            return;
        }
    };
    return UploadDocumentComponent;
}());
export { UploadDocumentComponent };
