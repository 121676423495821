import { Injectable, Inject, forwardRef } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';

//--
import { ErrorHandlerService } from './error-handler.service';
import { HybridFactory } from '../hybrid-factory';
import { CsVersionService } from'@app/services/utility-services/cs-version.service';

import * as jQuery from 'jquery';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptorService implements HttpInterceptor {
    requestsInProcess: string[] = [];

    constructor(private errorHandler: ErrorHandlerService, private csVersionService:CsVersionService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestsInProcess.push(request.url);
        if((request.url.indexOf('check_contract_categories')) >0 || request.url.indexOf('___noPageloading') > 0 ){
            jQuery('#loading-indicator').hide();
        }else{
            jQuery('#loading-indicator').show();
        }

         // show loading indicator

        let modifiedRequest = request.clone({
          withCredentials: true, // For cookie authentication
          setHeaders: {'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone} // User's local timezone
        });

        // Intercepting Responses
        return next.handle(modifiedRequest)
            .pipe(
                // On Success
                tap((event: HttpEvent<any>) => {
                    // Skip other than 'Response' events
                    if (event.type !== 4) return event;

                    this.removeProcessedRequest(request.url);

                    let content = event['data'] || event['body'];

                    let csVersion = event.hasOwnProperty('headers') ? event['headers'].get('X-Csversion') : 1;
                    const clientId = event.hasOwnProperty('headers') ? event['headers'].get('x-clientid') : null;
                    const username = event.hasOwnProperty('headers') ? event['headers'].get('x-username') : null;
                    this.csVersionService.setUserDetails(clientId,username);
                    if(!isNaN(parseInt(csVersion)) && csVersion > 1) {
                        this.csVersionService.validateCSVersion(parseInt(csVersion));
                    }

                    if (content && content.location) {

                        if (content.location.endsWith("/"))
                            // redirect to login page
                            HybridFactory.navigate('/');
                        else {
                            if(!(request.url.indexOf('voip_user_settings') > 0))
                                HybridFactory.navigate(content.location);
                        }

                        return throwError(event);

                    } else return event;

                }),

                // On Error
                catchError((errorResponse: HttpEvent<any>) => {
                    //this.removeProcessedRequest(request.url);

                    if (errorResponse instanceof HttpErrorResponse) {
                        let errorCode: number = errorResponse.status;
                        let errorMessage: string = errorResponse['error'] || errorResponse['data'] || errorResponse['body'];

                        let error = {
                            errorCode: errorCode,
                            errorMessage: errorMessage
                        };

                        this.errorHandler.setError(error);

                        // Handling auth error
                        if (errorCode === 401 || errorCode === 403) {
                            // redirect to login page
                            HybridFactory.navigate('/logout', { errorMessage: errorMessage });
                        }

                        return throwError(error);
                    }

                }),
                // To handle cancelled when subscriber explicitly unsubscribes
                // It will also call a specified function when the source terminates on complete or error
                finalize(() => this.removeProcessedRequest(request.url)),
            );
    }

    removeProcessedRequest(url) {
        this.requestsInProcess = this.requestsInProcess.filter(r => r !== url);

        // Hide loading indicator
        if (!this.requestsInProcess.length) jQuery('#loading-indicator').hide();
    }
}
