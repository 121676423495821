import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InputModel } from "@app/shared/service-reminder-communication/services/input-model";
import { TranslateService } from "@ngx-translate/core";
import { ScHttpServiceService } from "@app/shared/service-reminder-communication/services/sc-http-service.service";
import { EventDispatcherService } from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
export var isEmpty = function (input) {
    if (typeof input == 'object') {
        return Object.keys(input).length === 0;
    }
    return !!input;
};
var ScLayoutComponent = /** @class */ (function () {
    function ScLayoutComponent(translate, httpService, eventDispatcher) {
        this.translate = translate;
        this.httpService = httpService;
        this.eventDispatcher = eventDispatcher;
        this.objectName = '';
        this.screenType = '';
        this.sourceId = 0;
        this.selectedTemplateData = null;
        this.emailTemplateList = [];
        this.smsTemplateList = [];
        this.emailTemplateListIndexById = {};
        this.smsTemplateListIndexById = {};
        this.singlePropertyInput = {};
        this.multiplePropertyInput = {};
        this.defaultTemplates = {};
        this.clientDetails = {};
        this._reservedSinglePropertyInput = {};
        this._reservedMultiplePropertyInput = {};
    }
    ScLayoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var singlePropertyMessage, defaultMessage, multiplePropertyMessage, defaultMessage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // await this.getEmailTemplates();
                    // await this.getSmsTemplates();
                    return [4 /*yield*/, this.getServiceReminderConfig()];
                    case 1:
                        // await this.getEmailTemplates();
                        // await this.getSmsTemplates();
                        _a.sent();
                        if (isEmpty(this.singlePropertyInput)) {
                            singlePropertyMessage = this.loadMessage(1);
                            this.singlePropertyInput = new InputModel(this.sourceId, 0, 0, this.translate.instant('First.Communication'), 1, this.screenType, 0, singlePropertyMessage, []);
                            defaultMessage = Object.keys(singlePropertyMessage).length ? singlePropertyMessage : {};
                            this.singlePropertyInput._intervalOld = null;
                            this.singlePropertyInput.setDefaultMessages(defaultMessage);
                            this.singlePropertyInput.status = 'pending';
                            this.singlePropertyInput.completedTasksCount = 0;
                        }
                        if (isEmpty(this.multiplePropertyInput)) {
                            multiplePropertyMessage = this.loadMessage(2);
                            this.multiplePropertyInput = new InputModel(this.sourceId, 0, 0, this.translate.instant('First.Communication'), 2, this.screenType, 0, multiplePropertyMessage, []);
                            this.multiplePropertyInput._intervalOld = null;
                            defaultMessage = Object.keys(multiplePropertyMessage).length ? multiplePropertyMessage : {};
                            this.multiplePropertyInput.setDefaultMessages(defaultMessage);
                            this.multiplePropertyInput.status = 'pending';
                            this.multiplePropertyInput.completedTasksCount = 0;
                        }
                        // this.setEmailTemplateList(this.emailTemplateList);
                        // this.setSmsTemplateList(this.smsTemplateList);
                        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['init'], {
                            sourceId: this.sourceId,
                            smsTemplateList: this.smsTemplateList,
                            emailTemplateList: this.emailTemplateList,
                            emailTemplateListIndexById: this.emailTemplateListIndexById,
                            smsTemplateListIndexById: this.smsTemplateListIndexById,
                            singlePropertyInput: this.singlePropertyInput,
                            multiplePropertyInput: this.multiplePropertyInput,
                            activeTab: 'single',
                            defaultTemplates: this.defaultTemplates,
                            clientDetails: this.clientDetails,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ScLayoutComponent.prototype.loadMessage = function (propertyType) {
        if (this.selectedTemplateData) {
            var index = this.selectedTemplateData.findIndex(function (x) { return x.propertyType == propertyType; });
            if (index !== -1) {
                return this.selectedTemplateData[index].messages;
            }
            return {};
        }
        return {};
    };
    ScLayoutComponent.prototype.getServiceReminderConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var serviceReminderConfig;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                serviceReminderConfig = {
                    sourceId: this.sourceId,
                    screenType: this.screenType,
                    objectName: this.objectName,
                };
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.httpService.serviceReminderConfig({ serviceReminderConfig: serviceReminderConfig }, _this.screenType)
                            .toPromise()
                            .then(function (resp) {
                            if (resp['location'] && typeof resp['location'] == 'string') {
                                _this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                            }
                            _this.clientDetails = resp['clientDetails'];
                            if (resp['serviceReminderConfig']) {
                                var data = resp['serviceReminderConfig'];
                                if (!isEmpty(data['singlePropertyInput'])) {
                                    _this.singlePropertyInput = _this.setInputModel(data['singlePropertyInput']);
                                    _this._reservedSinglePropertyInput = data['singlePropertyInput'];
                                }
                                if (!isEmpty(data['multiplePropertyInput'])) {
                                    _this.multiplePropertyInput = _this.setInputModel(data['multiplePropertyInput']);
                                    _this._reservedMultiplePropertyInput = data['multiplePropertyInput'];
                                }
                                _this.defaultTemplates = data['defaultTemplates'];
                                /*this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['dispatchReservedData'], {
                                    reservedSinglePropertyInput: JSON.parse(JSON.stringify(this._reservedSinglePropertyInput)),
                                    reservedMultiplePropertyInput: JSON.parse(JSON.stringify( this._reservedMultiplePropertyInput))
                                })*/
                            }
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    ScLayoutComponent.prototype.setInputModel = function (data) {
        if (data === void 0) { data = {}; }
        var input = new InputModel(data['sourceId'], data['serviceReminderConfigId'], data['communicationId'], data['name'], data['propertyType'], this.screenType, data['interval'], data['messages'], []);
        if (data['status']) {
            input.status = data['status'];
        }
        if (data['completedTasksCount']) {
            input.completedTasksCount = data['completedTasksCount'];
        }
        if (data['followUpMessage']) {
            input.setFollowupInputModel(data['followUpMessage']);
        }
        if (data['_intervalOld']) {
            input._intervalOld = data['_intervalOld'];
        }
        input.itemName = data['name'];
        return input;
    };
    ScLayoutComponent.prototype.getEmailTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.httpService.getEmailTemplate()
                            .toPromise()
                            .then(function (resp) {
                            if (resp['location'] && typeof resp['location'] == 'string') {
                                _this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                            }
                            else if (resp['emailTemplates']) {
                                _this.emailTemplateList = resp['emailTemplates'];
                            }
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    ScLayoutComponent.prototype.getSmsTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.httpService.getSMSTemplate()
                            .toPromise()
                            .then(function (resp) {
                            if (resp['location'] && typeof resp['location'] == 'string') {
                                _this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['close'], {});
                            }
                            else if (resp['smsTemplates']) {
                                _this.smsTemplateList = resp['smsTemplates'];
                            }
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    ScLayoutComponent.prototype.setEmailTemplateList = function (data) {
        var _this = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this.emailTemplateListIndexById[item.id] = item;
        });
    };
    ScLayoutComponent.prototype.setSmsTemplateList = function (data) {
        var _this = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this.smsTemplateListIndexById[item.id] = item;
        });
    };
    return ScLayoutComponent;
}());
export { ScLayoutComponent };
