import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import {Subscription} from "rxjs";
import {InputModel} from "@app/shared/communication-mapping/services/input-model";
import {CommunicationHelperService} from "@app/shared/communication-mapping/services/communication-helper.service";
import {
    CommunicationsComponent
} from "@app/features/customers/customer/property-view/communications/communications.component";

@Component({
  selector: 'app-communication-template-view',
  templateUrl: './communication-template-view.component.html',
  styleUrls: ['./communication-template-view.component.scss']
})
export class CommunicationTemplateViewComponent implements OnInit, OnDestroy {

  @Input() enableView: boolean = false;

  select2Options: any = {
      minimumResultsForSearch: -1
  };
  previewModel: string = 'email';
  currentViewPort: string = 'desktop';
  subscription: Subscription;
  inputModel: InputModel;
  select2PreviewModelInstance: any = null;
  emailMessage: string = '';
  smsMessage: string = '';
  letterMessage: string = '';
  emailTemplateListIndexById: any = {};
  smsTemplateListIndexById: any = {};
  loadingTemplateMessage: boolean = true;
  noMessageView: boolean = true;
  loadingMessage:boolean = true;
  clientDetails: any = {};
  randomMobileNumber: number = 0;
  layoutComponent: CommunicationsComponent;
  finalSave: boolean = true;
  constructor(
      private eventDispatcher: CommunicationDispatcherService,
      private helperService: CommunicationHelperService,
  ) { }

  ngOnInit() {
      this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
          next: (data: any) => {
              if(typeof data != 'object' || !data['actionName']) {
                  return;
              }
              const action = data['actionName'];
              if(action == 'event:loadedData') {
                  const value = data['data'];
                  this.inputModel = value['inputModel'];
                  this.clientDetails = value['clientDetails'];
                  const emailList:any[] = value['emailTemplateList'];
                  const smsList:any[] = value['smsTemplateList'];
                  this.loadingTemplateMessage = false;
                  if(emailList.length > 0) {
                      emailList.forEach( item => {
                        this.emailTemplateListIndexById[item.id] = item;
                      })
                  }
                  if(smsList.length > 0) {
                      smsList.forEach( item => {
                          this.smsTemplateListIndexById[item.id] = item;
                      })
                  }
                  this.layoutComponent = value['layoutComponent'];

                  const activeEmail = this.helperService.findCurrentTemplate(this.inputModel, 'email');
                  const activeSms = this.helperService.findCurrentTemplate(this.inputModel, 'sms');
                  const activeLetter = this.helperService.findCurrentTemplate(this.inputModel, 'letter');
                  if(activeEmail || activeSms) {
                      // this.enableView=true;
                      this.previewModel = this.helperService.getCurrentPreview(activeEmail, activeSms,activeLetter);

                      if(this.previewModel == 'sms' || this.previewModel == 'letter')
                          this.currentViewPort='mobile';
                      else
                          this.currentViewPort='desktop';

                      if(activeEmail) {
                          this.emailMessage = activeEmail['message'];
                          this.noMessageView = false;
                      }
                      if(activeSms) {
                          this.smsMessage = activeSms['message'];
                          this.noMessageView = false;
                      }
                      if(activeLetter) {
                          this.letterMessage = activeLetter['message'];
                          this.noMessageView = false;
                      }

                      this.reloadMessage();
                  }
                  else if(!activeEmail && !activeSms && !activeLetter) {
                      this.noMessageView = true;
                      this.loadingTemplateMessage = false;
                  }
              }
              if(action == 'event:updatedTemplateId') {
                  this.noMessageView=false;
                  this.previewModel = data['type'];
                  if(this.select2PreviewModelInstance) {
                      this.select2PreviewModelInstance.select2('val', this.previewModel);
                  }
                  if(data['type'] == 'email') {
                      this.emailMessage = this.emailTemplateListIndexById[data['value']]['message'];
                      this.currentViewPort='desktop';
                  }
                  else if(data['type'] == 'sms') {
                      this.smsMessage = this.smsTemplateListIndexById[data['value']]['message'];
                      this.currentViewPort='mobile';
                  }
                  this.reloadMessage();
              }

              if(action == CommunicationDispatcherService.CONSTANTS['updateTemplateDetails'] || action == CommunicationDispatcherService.CONSTANTS['updateFormValue']){

                  this.previewModel = data['type'];
                  this.noMessageView=true;

                if(this.select2PreviewModelInstance && this.previewModel) {
                    this.select2PreviewModelInstance.select2('val', this.previewModel);
                }
                  if ( data.multiValue && data.multiValue.length > 1){
                      data.multiValue.map( obj => {
                          if(data['type'] == 'email') {
                              this.emailMessage = data['value']['message'];
                              this.noMessageView = false;
                              this.currentViewPort='desktop';
                          }
                          else if(data['type'] == 'sms') {
                              this.smsMessage = data['value']['message'];
                              this.noMessageView = false;
                              this.currentViewPort='mobile';
                          }
                          else if(data['type'] == 'letter') {
                              this.letterMessage = data['value']['message'];
                              this.noMessageView = false;
                              this.currentViewPort='mobile';
                          }
                      });
                  }
                  else{
                      if(data.from == 'clearOption'){
                          this.emailMessage = '';
                          this.smsMessage = '';
                          this.letterMessage ='';
                      }

                      if(data['type'] == 'email') {
                          this.emailMessage = data['value']['message'];
                          this.noMessageView = false;
                          this.currentViewPort='desktop';
                      }
                      else if(data['type'] == 'sms') {
                          this.smsMessage = data['value']['message'];
                          this.noMessageView = false;
                          this.currentViewPort='mobile';
                      }
                      else if(data['type'] == 'letter') {
                          this.letterMessage = data['value']['message'];
                          this.noMessageView = false;
                          this.currentViewPort='mobile';
                      }
                      else if(data.inputModel.templates[0] && data.from == 'clearOption'){
                          if (data.inputModel.templates[0].communicationType == 'email'){
                              this.emailMessage = data.inputModel.templates[0].message;
                              this.noMessageView = false;
                              this.currentViewPort='desktop';
                              this.previewModel = 'email'
                          }
                          else if (data.inputModel.templates[0].communicationType == 'sms'){
                              this.smsMessage = data.inputModel.templates[0].message;
                              this.noMessageView = false;
                              this.currentViewPort='mobile';
                              this.previewModel = 'sms'
                          }
                          else if (data.inputModel.templates[0].communicationType == 'letter'){
                              this.letterMessage = data.inputModel.templates[0].message;
                              this.noMessageView = false;
                              this.currentViewPort='mobile';
                              this.previewModel = 'letter'
                          }
                      }
                  }
                this.reloadMessage();
              }
          }
      });
      this.randomMobileNumber = this.generatePhoneNumber();
  }

  setSelect2PreviewModel(instance) {
    this.select2PreviewModelInstance = instance;
  }

  ngOnDestroy(): void {
      if(this.subscription) {
          this.subscription.unsubscribe();
      }
  }

  changeViewPort(view: string) {
      if(view === this.currentViewPort)
          return;
      this.currentViewPort = view;
      this.reloadMessage();
  }

  onChangePreviewOption(value) {
      if(value == 'sms' || value == 'letter')
        this.currentViewPort = 'mobile';
      else if(value == 'email')
        this.currentViewPort = 'desktop';
      this.reloadMessage();
  }

  reloadTemplate() {
      this.loadingTemplateMessage=true;
      setTimeout(() => {
          this.loadingTemplateMessage=false;
      }, 600);
  }

  reloadMessage() {
    this.loadingMessage=true;
    setTimeout(() => {
      this.loadingMessage=false;
    }, 600);
  }

  generatePhoneNumber() {
      return Math.floor(Math.random() * 9000000000) + 1000000000;
  }
    save() {
        if (this.finalSave) {
            this.finalSave = false;
            this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['saveAndClose'], {})
        }
    }

  closeModal() {
      this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['close'], {});
  }

}
