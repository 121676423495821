import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, Injector } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
var AssetWidgetModalMigratedDirective = /** @class */ (function (_super) {
    tslib_1.__extends(AssetWidgetModalMigratedDirective, _super);
    function AssetWidgetModalMigratedDirective(_hostRef, injector, ajsScope) {
        var _this = _super.call(this, 'assetWidgetModal', _hostRef, injector) || this;
        _this.ajsScope = ajsScope;
        _this.autoOpen = false;
        _this.onSubmit = new EventEmitter();
        _this.onClose = new EventEmitter();
        return _this;
    }
    AssetWidgetModalMigratedDirective.prototype.openModal = function (data) {
        var _this = this;
        setTimeout(function () { return _this.ajsScope.$broadcast('jobAsset:open-widget', data); }, 50);
    };
    return AssetWidgetModalMigratedDirective;
}(UpgradeComponent));
export { AssetWidgetModalMigratedDirective };
