import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CsToastBoxService = /** @class */ (function () {
    function CsToastBoxService() {
        this.toastControlSource = new BehaviorSubject(null);
        this.toastControl$ = this.toastControlSource.asObservable();
    }
    CsToastBoxService.prototype.show = function (message, duration) {
        this.toastControlSource.next({
            action: 'show',
            message: message,
            duration: duration
        });
    };
    CsToastBoxService.prototype.hide = function () {
        this.toastControlSource.next({ action: 'hide' });
    };
    CsToastBoxService.ngInjectableDef = i0.defineInjectable({ factory: function CsToastBoxService_Factory() { return new CsToastBoxService(); }, token: CsToastBoxService, providedIn: "root" });
    return CsToastBoxService;
}());
export { CsToastBoxService };
