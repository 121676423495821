export const KB_Datas = {
    "United Kingdom": {
        "domainName": "https://kb.commusoft.co.uk/hc/en-gb/",
        "supportEmail": "support@commusoft.co.uk",
        "datas": {
            "uploadSupportedFilesUrl": "articles/360006529538-Which-file-formats-are-supported-within-Commusoft-",
            "searchPath": "search?utf8=%E2%9C%93&query=",
            "featureRequest": "requests/new",
            "importProperty": "articles/360021177658-Import-customers",
            "partsImport": "articles/224258868",
            "license": "articles/360014768017"
        }
    },
    "USA": {
        "domainName": "https://kb.commusoft.co.uk/hc/en-us/",
        "supportEmail": "support@commusoft.us",
        "datas": {
            "uploadSupportedFilesUrl": "articles/360006529538-Which-file-formats-are-supported-within-Commusoft-",
            "searchPath": "search?utf8=%E2%9C%93&query=",
            "featureRequest": "requests/new",
            "importProperty": "articles/360021177658-Import-customers",
            "partsImport": "articles/224258868",
            "license": "articles/360014768017"
        }
    }
};
