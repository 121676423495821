import { Component, Input } from '@angular/core';
import {CcCommonService} from '@app/features/customers/customer-contracts/cc-common.service';

@Component({
  selector: 'app-custom-tooltip',
  template: `
    <span>
        <span
            placement="right"
            [tooltip]="HtmlContent"
            class="labels"
            container="body"
            [ngStyle]="{ 'background-color': getStatusColor(message.data.status) }">
            {{ message.value }}
    </span>

    <ng-template #HtmlContent>
        <div style="text-align:left;">
            <div>
                <span translate>Submitted.by.user</span><span>: </span>
                <span *ngIf="message.data.tooltip.submittedByUser!=null">{{message.data.tooltip.submittedByUser.date | date: regionalDateFormat}} ({{message.data.tooltip.submittedByUser.date | date: 'h:mm'}})</span>
            </div> 
            <div>
                <span translate>Sent.to.AI</span><span>: </span>
                <span *ngIf="message.data.tooltip.sendToAi!=null">{{message.data.tooltip.sendToAi.date | date: regionalDateFormat}} ({{message.data.tooltip.sendToAi.date | date: 'h:mm'}})</span>
                
            </div>
            <div>
                <span translate>Information.received.back.from.AI</span><span>: </span>
                <span *ngIf="message.data.tooltip.informationReceive!=null">{{message.data.tooltip.informationReceive.date | date: regionalDateFormat}} ({{message.data.tooltip.informationReceive.date | date: 'h:mm'}})</span>
            </div>
        </div>
    </ng-template>
  `,
    styles: [`
        .labels {
            display: inline-block;
            color: #FFF;
            padding: 0.25rem 0.5rem;
            border-radius: 2px;
            line-height: normal;
            text-transform: lowercase;
        }
        
        .labels::first-letter {
            text-transform: uppercase;
        }
    `,],
})
export class CustomTooltipComponent {
    @Input() message: any;

    regionalDateFormat: string;

    constructor(private ccCommonService: CcCommonService) {
    }

    ngOnInit() {
        var format = this.ccCommonService.getRegionalDateFormat();
        
        format = format.replace(/D/gi, 'd')
        this.regionalDateFormat = format.replace(/Y/gi, 'y')
    }
  
    getStatusColor(status: string): string {
        switch (status) {
            case 'Ready':
                return '#48954F';
            case 'Processing':
                return '#A2A360';
            case 'Error':
                return '#BE6D6D';
            case 'Information Missing':
                return '#A2A360';
            default:
                return ''; // Default color or handle other cases as needed
        }
    }
}
