<ng-container>
    <ng-container *ngIf="!loading && smartTableInstance.getDatas().length === 0">
        <div class="no-results --margin-top" icon="ss-info">
            <div class="no-results-wrap">
                <div class="warning-icon-wrap with-info-icon">
                    <span class="ss-info icon fix-left">&nbsp;</span>
                </div>
                <div class="warning-message" translate>{{emptyMessage}}</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loadingFirstTime || (loading && smartTableInstance.getDatas()?.length === 0)">
        <div class="spreadsheet-loading-wrap">
         <span class="loading-spreadsheet">
            <span class="loading-x-axis"></span>
            <span class="loading-y-axis"></span>
            <div class="loading-row row-1"></div>
            <div class="loading-row row-2"></div>
            <div class="loading-row row-3"></div>
            <div class="loading-row row-4"></div>
            <div class="loading-row row-5"></div>
           </span>
        </div>
    </ng-container>
    <div class="cs-smart-table-container"
         #containerId
         [ngClass]="{
           'column-ordering': columnEdit===true,
           'row-action-enabled': actions.length,
           'with-checkbox-col': _rowSelect, 'hide': loadingFirstTime === true || smartTableInstance.getDatas().length === 0}">
    <ng-container *ngIf="(dtOptions | json) != '{}'">
        <div *ngIf="_rowSelect" class="select-all-link tab-button" (click)="onSelectAllRows()">
            <span class="ss-check" *ngIf="!selectAllCheckbox">&nbsp;</span>
            <span class="ss-delete" *ngIf="selectAllCheckbox">&nbsp;</span>
        </div>
        <ng-container *ngIf="columnEdit">
          <app-column-edit
             (onReorderColumn)="onReOrderColumn($event)"
             (onShowHideColumn)="onShowHideColumn($event)"
             (onSaveColumns)="onSaveColumns($event)"
             (onCloseModal)="onCloseColumnModal($event)"
             [isEnableActions]="actions.length > 0"
             [isEnableSelect]="_rowSelect"
             [saveUrl]="columnsSaveUrl"
             [category]="category"
             [columns]="columns">
          </app-column-edit>
        </ng-container>
        <table
          id="cs-smart-table-v2"
          datatable
          [dtOptions]="dtOptions"
          class="display nowrap"
          style="width:100%;border-collapse: collapse;">
            <tbody *ngIf="smartTableInstance.getDatas()?.length != 0">
            <tr *ngFor="let data of smartTableInstance.getDatas(); let i = index" [ngClass]="{'selected': data.selected===true}">
              <ng-container *ngFor="let col of columns; let colIndex = index">
                <ng-container *ngIf="!col.data.startsWith('@') && col.visible; else tpl_actions">
                    <td cellColor [column]="col" [value]="data[col.data]">{{ data[col.data] }}</td>
                </ng-container>
                <ng-template #tpl_actions>
                    <ng-container *ngIf="_rowSelect && col.data === '@empty_select'; else actions_btn">
                        <td class="select-checkbox" (click)="onSelectRow(data, col)"></td>
                    </ng-container>
                </ng-template>
                <ng-template #actions_btn>
                    <ng-container *ngIf="actions.length > 0 && col.data === '@empty_actions'">
                        <td class="st-last-column plus-actions" [ngClass]="{'active': toggleData.index===i}">
                            <app-action-buttons (toggleEvent)="toggleActions($event)"
                            (onClickButtonEvent)="renderActionButton($event)"
                            [index]="i"
                            [data]="data"
                            [buttons]="actions"
                            [type]="display">
                            </app-action-buttons>
                        </td>
                    </ng-container>
                </ng-template>
              </ng-container>
            </tr>
            </tbody>
            <tbody *ngIf="!loading && smartTableInstance.getDatas()?.length === 0">
                <tr>
                    <td class="no-data-available" [attr.colspan]="columnsCount">{{emptyMessage}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
</ng-container>