import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {mxgraph} from "mxgraph-factory";
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {CsTaggingService} from "@app/directives/material-directives/cs-tagging/cs-tagging.service";

@Component({
  selector: 'app-sequence-sms-widget',
  templateUrl: './sequence-sms-widget.component.html',
  styles: []
})
export class SequenceSmsWidgetComponent implements OnInit {

    templateForm: FormGroup;
    cell: mxgraph.mxCell;
    mxGraph: mxgraph.mxGraph;
    sender: any;
    isLoading: boolean = true;
    templateData: any = {};
    saving: boolean = false;
    tagsList: any[] = [];
    diagramOptions: any;
    workflowType: string;
    allowableTags: any[] = [];
    smsTextMessage:string = '';
    public onClose: Subject<any>;

    constructor(
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private httpService: WorkflowhttpService,
        private tagService: CsTaggingService,
    ) { }

    async ngOnInit() {
        this.onClose = new Subject();
        const cellValue = this.cell.getValue();
        const cellModel = cellValue['model'];

        this.templateForm = this.fb.group({
            'message': [cellModel.hasOwnProperty('customMessage') ? cellModel['customMessage']['message'] : '', Validators.required]
        });
        this.templateForm.get('message').valueChanges.subscribe( (content) => {
            if(content == '') {
                return;
            }
            const hasText = content.replace(/(<([^>]+)>)/gi, "");
            if(!hasText) {
                this.templateForm.get('message').setValue('');
            }
        })
        if(!cellModel.hasOwnProperty('customMessage')) {
            await this.getSmsTemplate();
        }
        await this.getTagsList();
        this.isLoading = false;
    }

    async getTagsList() {
        return this.tagService.getTagsList({
            tagList: this.allowableTags
        }).toPromise()
        .then ( (resp) => {
            this.tagsList = this.tagService.format4Quill(resp);
        });
    }

    async getSmsTemplate() {
        const {model} = this.cell.getValue();
        this.httpService.getEmailSmsTemplatesByIds({ids: model['templateId']})
        .toPromise()
        .then( (resp:any) => {
            this.templateData = resp['templates'][model['templateId']]
            this.templateForm.patchValue({
                message: this.templateData['message'],
            });
            this.templateForm.updateValueAndValidity();
        });
    }

    onSave() {
        const formValue = this.templateForm.value;
        formValue['displayContent'] = formValue['message'].replace(/(<([^>]+)>)/gi, "");
        this.onClose.next(formValue);
        this.modalRef.hide();
    }

}
