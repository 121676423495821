<!-- Title -->
<div class="modal-header">
    <h4 class="modal-title pull-left" translate>{{title}}</h4>
</div>
<!-- Image section -->
<div class="modal-body">
    <div class="help_section">
        <div class="help-text" translate>
        </div>
    </div>
    <div class="body-content-section">
        <div class="m-20">
            <image-cropper [imageURL]="imageUrl" [maintainAspectRatio]="true" [aspectRatio]="ratioPoint" format="png"
                (imageCropped)="imageCropped($event)" [transform]="imgtransform"></image-cropper>
        </div>
        <div class="m-20">
            <div class="image-section-para" translate>
                crop.adjustment
            </div>
            <div class="flex-container-layer">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let ratio of aspectRatio">
                        <div class="aspect-box" [ngClass]="{'selected-aspectbox': ratio.aspect === ratioPoint}"
                            (click)="selectRatio(ratio)">
                            <div class="aspect-box-inside" [ngClass]="{'selected-layer': ratio.aspect === ratioPoint}"
                                [style.padding-top]="getvalue(ratio.aspect)">
                            </div>
                            <div class="m-10 aspect-title" [ngClass]="{'selected-title': ratio.aspect === ratioPoint}">
                                {{ratio.ratio}}
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <div class="m-20">
            <div class="image-section-para" translate>
                straighten
            </div>
            <div class="flex-container-layer mbt-20">
                <div id="slider"></div>
                <div id="reset">
                    <i class="fas fa-sync-alt" (click)="resetImage()"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Footer -->
<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
    <button class="btn btn-primary" (click)="saveImageCrop()">
        <span translate>Save</span>
    </button>
</div>