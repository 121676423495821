import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { MicroserviceHelperService } from "@app/shared/microservice/helper/microservice-helper.service";
import * as i0 from "@angular/core";
import * as i1 from "../settings/settings.service";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../shared/microservice/helper/microservice-helper.service";
var SECURE_HASH_NAME = 'x_security_hash';
var V5_REFRESHTOKEN = 'xv5_refresh_token';
var V5AuthService = /** @class */ (function () {
    function V5AuthService(settings, cookieService, http, tokenService) {
        this.settings = settings;
        this.cookieService = cookieService;
        this.http = http;
        this.tokenService = tokenService;
        this.v5ApiBase = '';
        this.apiBase = environment.apiHost;
        this.username = '';
        this.debug = false;
        this.urlPaths = {
            authentication: 'json_login',
            renewalToken: 'token/renewal',
        };
        this.isRequestedToken = false;
        this.v5ApiBase = this.apiBase + '/v5/core/';
    }
    V5AuthService.prototype.getApiPath = function (pathName) {
        var apiUrlPath = '';
        if (this.urlPaths.hasOwnProperty(pathName)) {
            apiUrlPath = this.urlPaths[pathName];
        }
        if (this.debug) {
            apiUrlPath = '?DEBUG_SESSION_START=PHPSTORM';
        }
        return this.v5ApiBase + apiUrlPath;
    };
    V5AuthService.prototype.authenticate = function (username) {
        var _this = this;
        if (username === void 0) { username = ''; }
        var authTokenHash = this.cookieService.get(SECURE_HASH_NAME);
        if (!authTokenHash || this.tokenService.isTokenExist()) {
            return false;
        }
        var apiPath = this.getApiPath('authentication');
        this.doLoginReq(apiPath, {
            username: username,
            appToken: authTokenHash
        }).subscribe({
            next: function (resp) {
                if (resp['v5token']) {
                    _this.tokenService.setToken(resp['v5token']);
                }
            }
        });
    };
    V5AuthService.prototype.doLoginReq = function (path, data) {
        if (path === void 0) { path = ''; }
        if (data === void 0) { data = {}; }
        return this.http.post(path, data);
    };
    V5AuthService.prototype.renewToken = function () {
        var apiPath = this.getApiPath('renewalToken');
        this.isRequestedToken = true;
        return this.http.post(apiPath, {}).toPromise();
        /*.then( (resp) => {
            if(resp['renewalToken']) {
                this.tokenService.setToken(resp['renewalToken'])
                this.tokenService.setTokenInterval(resp['nextRenewalTime'])
            }
        }, (err) => {

        });*/
        /*.subscribe({
            next: resp => {
                if(resp['renewalToken']) {
                    this.tokenService.setToken(resp['renewalToken'])
                    this.tokenService.setTokenInterval(resp['nextRenewalTime'])
                }
            }
        })*/
    };
    V5AuthService.ngInjectableDef = i0.defineInjectable({ factory: function V5AuthService_Factory() { return new V5AuthService(i0.inject(i1.SettingsService), i0.inject(i2.CookieService), i0.inject(i3.HttpClient), i0.inject(i4.MicroserviceHelperService)); }, token: V5AuthService, providedIn: "root" });
    return V5AuthService;
}());
export { V5AuthService };
