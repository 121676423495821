import { EventEmitter, OnInit } from '@angular/core';
import { AddEditCertificateService } from "@app/services";
import { CsFullScreenModalComponent } from "@app/directives";
import { ActivatedRoute } from "@angular/router";
var AddEditCertificateComponent = /** @class */ (function () {
    function AddEditCertificateComponent(addEditCertificateService, router) {
        this.addEditCertificateService = addEditCertificateService;
        this.router = router;
        this.showSidepanel = false;
        this.certificates = [];
        this.categories = [];
        this.filterText = '';
        // Customer/landlord page info
        this.customerOrLandlordInfo = {
            showLandlord: false,
            customerInfo: [],
            landlordInfo: [],
            jobId: null,
            settingsCertificateId: null
        };
        this.draftData = {};
        this.currentPageIndex = -1;
        this.certificateName = '';
        this.customerOrLandlord = 'Customer';
        this.userName = '';
        this.status = 'DRAFT';
        this.valueEmitter = new EventEmitter();
        this.onReloadSmartTable = new EventEmitter();
    }
    AddEditCertificateComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data['type'] == 'ADD') {
            this.showSidepanel = true;
            this.addEditCertificateService.getDynamicCertificates().subscribe(function (res) {
                _this.certificates = res;
                for (var _i = 0, _a = Object.entries(res); _i < _a.length; _i++) {
                    var _b = _a[_i], key = _b[0], value = _b[1];
                    _this.categories.push(key);
                }
            }, function (error1) {
                _this.certificates = [];
            });
        }
        else {
            this.addEditCertificateService.getDynamicCertificateJsonDraft(this.data['certificateId']).subscribe(function (res) {
                if (res['certificatedata']) {
                    _this.certificateName = res['name'];
                    _this.userName = res['userName'];
                    _this.status = res['status'];
                    var certificateData = JSON.parse(res['certificatedata']);
                    if (certificateData) {
                        _this.draftData = certificateData['jsonDraft'];
                        _this.currentPageIndex = certificateData['currentPageIndex'];
                        _this.customerOrLandlord = certificateData['customerownership'];
                        var params = {};
                        params['lookupId'] = _this.data['certificateId'];
                        params['customerId'] = _this.data['customerId'];
                        if (res['type'] == 'DynamicCertificate') {
                            params['type'] = 1;
                            params['tablePkId'] = res['adminlookupsettingscertificateId'];
                        }
                        if (res['type'] == 'DynamicCertificate2.0') {
                            params['type'] = 2;
                            params['tablePkId'] = res['settingsdynamiccertificateId'];
                        }
                        _this.getDynamicCertificateJson(params, res['settingsCertificatesid']);
                    }
                }
            }, function (error1) {
            });
        }
        this.subTitle = "<p>Job number : " + this.data['jobId'] + " &nbsp; &nbsp;<i class=\"fa fa-home\"></i>\n                         " + this.data['addressline1'] + " " + this.data['addressline2'] + " ";
        if (this.data['county']) {
            this.subTitle += ', ' + this.data['county'];
        }
        if (this.data['postcode']) {
            this.subTitle += ', ' + this.data['postcode'];
        }
        this.subTitle += '</p>';
    };
    AddEditCertificateComponent.prototype.onSidepanelClose = function () {
        var _this = this;
        this.filterText = '';
        this.showSidepanel = false;
        setTimeout(function () {
            _this.valueEmitter.emit(false);
        }, 1000);
    };
    AddEditCertificateComponent.prototype.searchUpdated = function () {
        var queryString = this.filterText.trim();
        for (var _i = 0, _a = Object.entries(this.certificates); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            value.forEach(function (certificate) {
                certificate['show'] = certificate['name'].toLowerCase().includes(queryString.toLowerCase());
            });
        }
    };
    AddEditCertificateComponent.prototype.selectCertificate = function (category, certificateIndex, certificate) {
        // Deselect the previously selected certificate
        if (this.category)
            this.certificates[this.category][this.certificateIndex]['selected'] = false;
        this.certificates[category][certificateIndex]['selected'] = true;
        this.category = category;
        this.certificateIndex = certificateIndex;
        this.certificate = certificate;
        this.certificateName = certificate['name'];
    };
    AddEditCertificateComponent.prototype.confirmCertificate = function () {
        var params = {};
        params['customerId'] = this.data['customerId'];
        if (this.certificate['type'] == 'DynamicCertificate') {
            params['type'] = 1;
            params['tablePkId'] = this.certificate['adminlookupsettingscertificateId'];
        }
        if (this.certificate['type'] == 'DynamicCertificate2.0') {
            params['type'] = 2;
            params['tablePkId'] = this.certificate['settingsdynamiccertificateId'];
        }
        this.getDynamicCertificateJson(params);
    };
    AddEditCertificateComponent.prototype.getDynamicCertificateJson = function (params, settingsCertificatesid) {
        var _this = this;
        this.addEditCertificateService.getDynamicCertificateJson(params).subscribe(function (res) {
            if (res['json']) {
                _this.certificatePageData = JSON.parse(res['json']);
                _this.customerOrLandlordInfo.showLandlord = res['showLandlord'];
                _this.customerOrLandlordInfo.customerInfo = _this.data;
                _this.customerOrLandlordInfo.landlordInfo = res['landlord'];
                _this.customerOrLandlordInfo.jobId = _this.data['jobId'];
                _this.customerOrLandlordInfo.settingsCertificateId = settingsCertificatesid ? settingsCertificatesid : _this.certificate['id'];
                _this.filterText = '';
                _this.showSidepanel = false;
                _this.csFullModal.open();
            }
        }, function (error1) {
            console.log(error1);
        });
    };
    AddEditCertificateComponent.prototype.closeModal = function ($event) {
        this.csFullModal.close();
    };
    AddEditCertificateComponent.prototype._onClose = function (event) {
        var _this = this;
        this.onReloadSmartTable.emit(true);
        setTimeout(function () {
            _this.valueEmitter.emit(true);
        }, 1000);
    };
    AddEditCertificateComponent.prototype.onOpen = function (event) {
        console.log(event);
    };
    return AddEditCertificateComponent;
}());
export { AddEditCertificateComponent };
