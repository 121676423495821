/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidepanel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidepanel.component";
import * as i3 from "../services/sidepanel-options";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../services/sidepanel.service";
var styles_SidepanelComponent = [i0.styles];
var RenderType_SidepanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SidepanelComponent, data: {} });
export { RenderType_SidepanelComponent as RenderType_SidepanelComponent };
export function View_SidepanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["role", "document"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("modal-dialog" + (_co.config.class ? (" " + _co.config.class) : "")); _ck(_v, 0, 0, currVal_0); }); }
export function View_SidepanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidepanel-modal-container", [["class", "modal cs-sidepanel-v2"], ["role", "dialog"], ["tabindex", "-1"]], [[1, "aria-modal", 0], [1, "aria-labelledby", 0], [1, "aria-describedby", 0]], [[null, "click"], ["window", "keydown.esc"], ["document", "keydown.escape"], ["body", "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_2 && ad);
    } if (("body:click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onClickStarted($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_SidepanelComponent_0, RenderType_SidepanelComponent)), i1.ɵdid(1, 245760, null, 0, i2.SidepanelComponent, [i3.SidepanelOptions, i1.ElementRef, "confirmationBoxHelper", "$scope", i4.TranslateService, i5.SidepanelService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).config.ariaLabelledBy; var currVal_2 = i1.ɵnov(_v, 1).config.ariaDescribedby; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var SidepanelComponentNgFactory = i1.ɵccf("sidepanel-modal-container", i2.SidepanelComponent, View_SidepanelComponent_Host_0, {}, {}, ["*"]);
export { SidepanelComponentNgFactory as SidepanelComponentNgFactory };
