import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: (url) => Promise<boolean> | boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot,
                nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!CanDeactivateGuard.confirmationCallback) return true;

    if(!CanDeactivateGuard.invoked) CanDeactivateGuard.invoke(nextState.url);

    CanDeactivateGuard.invoked = false;

    return CanDeactivateGuard.invokeResult;

  }

  static confirmationCallback: {url: string; callback: (nextStateUrl) => boolean};
  static invoked: boolean;
  static invokeResult: boolean;
  static nextStateUrl: string;

  static invoke(nextStateUrl) {
    CanDeactivateGuard.nextStateUrl = nextStateUrl;

    if (CanDeactivateGuard.confirmationCallback) {
      CanDeactivateGuard.invoked = true;
      CanDeactivateGuard.invokeResult = CanDeactivateGuard.confirmationCallback.callback(nextStateUrl);
    }
  }

  static setConfirmationCallback(url, callback){
    CanDeactivateGuard.confirmationCallback = {
      url: url,
      callback: callback
    };
    CanDeactivateGuard.invoked = false;
    CanDeactivateGuard.invokeResult = true;
  }

  static removeConfirmationCallback(){
    CanDeactivateGuard.confirmationCallback = null;
    CanDeactivateGuard.invoked = false;
    CanDeactivateGuard.invokeResult = true;
    CanDeactivateGuard.nextStateUrl = null;
  }
}
