import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-custom-tag-form',
    templateUrl: './custom-tag-form.component.html',
    styleUrls: ['./custom-tag-form.component.scss']
})
export class CustomTagFormComponent {
    @Input() tag: any;
    @Input() form: FormGroup;
    get isNotValid() { return !this.form.controls[this.tag.key].valid && this.form.controls[this.tag.key].dirty; };
    constructor() {}
}
