import {Directive, TemplateRef, ViewContainerRef, Input, Inject, forwardRef} from '@angular/core';

//--
import { InterfaceClass } from '@app/interfaces';
import { AuthService } from '@app/core';


@Directive({
  selector: '[access]'
})
export class AccessDirective extends InterfaceClass {
  permittedFeatures: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(forwardRef(() => AuthService)) public authService: AuthService ) {
    super();

    // Hide content by default
    this.viewContainer.clear();
  }

  ngOnInit() { }

  @Input('access') set access(permittedFeatures: string[]) {
    this.permittedFeatures = permittedFeatures;
    this.bindTemplate();
  }

  // Add/Remove content from DOM
  bindTemplate() {
    if (this.authService.permitted(this.permittedFeatures) === true) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else this.viewContainer.clear();
  }
}
