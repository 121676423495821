var AppValidators = /** @class */ (function () {
    function AppValidators() {
    }
    AppValidators.getValidatorErrorMessages = function (validatorValue, controlName) {
        var config = {
            'required': "Please enter " + AppValidators.formatControlName(controlName),
            'invalidCreditCard': 'Please enter valid credit card number',
            'invalidEmailAddress': 'Please enter a valid email format ie. commusoft@gmail.com',
            'invalidPassword': 'Please enter valid password. Password must be at least 6 characters long, and contain a number.',
            'invalidAzureWindowsPassword': 'Password must be 12 characters long and must have 3 of the following: 1 lower case, 1 upper case, 1 number, 1 special character',
            'notEqual': "Entered " + controlName + " doesn't match",
            'minlength': "Minimum length " + validatorValue.requiredLength,
            'invalidPercentage': 'Value should be inbetween 0-100',
            'invalidEmailRecipients': 'Please enter valid email address. Multiple emails should be seperated by comma.',
            'invalidUrl': 'Please enter valid url.',
            'invalidPhoneNumber': 'Mobile number must be 10 digits.',
            'invalidNonEmptyList': 'Please select atleast one value.',
            'invalidPanNumber': 'PAN number must contain 10 digits',
            'invalidUserName': 'Please avoid space in username',
            'maxlength': "Maximum length " + validatorValue.requiredLength,
            'invalidInstanceName': 'Please enter valid name without space & special characters',
            'invalidMinimumLength': 'Name must be at least 4 characters long',
            'invalidBucketName': 'Bucket name must be in lowercase letters',
            'invalidCheckedList': 'Please select atleast one value',
            'invalidConfirmationText': 'Please enter valid text',
            'min': "Minimum value is " + validatorValue.min,
            'max': "Maximum value is " + validatorValue.max,
            'pattern': "Please enter a valid " + AppValidators.formatControlName(controlName)
        };
        return config;
    };
    AppValidators.formatControlName = function (name) {
        if (name) {
            name = name.replace(/_/g, " ");
            name = name.replace(/([a-z\d])([A-Z])/g, '$1 $2');
            name = name.toLowerCase();
            return name;
        }
        else
            return "input";
    };
    AppValidators.creditCardValidator = function (control) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB
        if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
            return null;
        }
        else {
            return { 'invalidCreditCard': true };
        }
    };
    AppValidators.emailValidator = function (control) {
        // RFC 2822 compliant regex
        if ((!control.value) || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        }
        else {
            return { 'invalidEmailAddress': true };
        }
    };
    AppValidators.passwordValidator = function (control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value && control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
            return null;
        }
        else {
            return { 'invalidPassword': true };
        }
    };
    AppValidators.azureWindowsPasswordValidator = function (control) {
        // {6,100}           - Assert password is between 12 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        // (?=.*[!@#$%^&*])  - Assert a special character
        if (control.value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,100}$/)) {
            return null;
        }
        else {
            return { 'invalidAzureWindowsPassword': true };
        }
    };
    AppValidators.nameWithoutSpaceValidator = function (control) {
        if (control.value.match(/^[a-zA-Z0-9\-_]{0,40}$/)) {
            return null;
        }
        else {
            return { 'invalidInstanceName': true };
        }
    };
    AppValidators.upperCaseValidator = function (control) {
        if (control.value.match(/^[a-z0-9\-_]{0,40}$/)) {
            return null;
        }
        else {
            return { 'invalidBucketName': true };
        }
    };
    AppValidators.minimumLengthValidator = function (control) {
        if (control.value.match(/^.{4,40}$/)) {
            return null;
        }
        else {
            return { 'invalidMinimumLength': true };
        }
    };
    AppValidators.percentageValidator = function (control) {
        // 0-100 percentage value is between 0 to 100
        if (control.value >= 0 && control.value <= 100) {
            return null;
        }
        else {
            return { 'invalidPercentage': true };
        }
    };
    AppValidators.multipleEmailValidator = function (control) {
        // Multiple emails seperated by comma regex
        if (control.value != '' && control.value != null) {
            var emailValue = control.value.split(',');
            var ischecked = false;
            for (var _i = 0, emailValue_1 = emailValue; _i < emailValue_1.length; _i++) {
                var value = emailValue_1[_i];
                var emailAddress = value.trim();
                if (emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    ischecked = true;
                }
                else {
                    ischecked = false;
                    break;
                }
            }
            if (ischecked) {
                return null;
            }
            else {
                return { 'invalidEmailRecipients': true };
            }
        }
    };
    AppValidators.urlValidator = function (control) {
        // Url regex
        if (!control.value || control.value.match(/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/)) {
            return null;
        }
        else {
            return { 'invalidUrl': true };
        }
    };
    AppValidators.phoneValidator = function (control) {
        var phoneNumber = /^\d{10}$/;
        if (control.value != null && control.value.match(phoneNumber) || null) {
            return null;
        }
        else {
            return { 'invalidPhoneNumber': true };
        }
    };
    AppValidators.pancardValidator = function (control) {
        var pan_number = /^[a-zA-Z0-9_.-]*$/;
        if (control.value.match(pan_number) || null) {
            return null;
        }
        else {
            return { 'invalidPanNumber': true };
        }
    };
    AppValidators.nonEmptylistValidator = function (control) {
        // Checks is array & has Minimum one item in array
        if (control.value instanceof Array && (control.value.length > 0)) {
            return null;
        }
        else {
            return { 'invalidNonEmptyList': true };
        }
    };
    AppValidators.equalValidator = function (group) {
        // Checks all values of controls in group are equal
        var controlNames = Object.keys(group.value);
        var first = controlNames.splice(0, 1)[0];
        for (var _i = 0, controlNames_1 = controlNames; _i < controlNames_1.length; _i++) {
            var controlName = controlNames_1[_i];
            if (group.controls[first].touched && group.controls[controlName].touched && group.value[controlName] != group.value[first]) {
                return { 'notEqual': true };
            }
        }
        return null;
    };
    AppValidators.userNameValidator = function (control) {
        var regex = /[^A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g; //_@./#&+-
        if (!control.value.match(regex)) {
            return null;
        }
        else {
            return { 'invalidUserName': true };
        }
    };
    AppValidators.nonCheckedlistValidator = function (control) {
        // Checks atleast one item is selected
        var ischecked = false;
        for (var _i = 0, _a = control.value; _i < _a.length; _i++) {
            var value = _a[_i];
            if (value) {
                ischecked = true;
                break;
            }
        }
        if (ischecked) {
            return null;
        }
        else {
            return { 'invalidCheckedList': true };
        }
    };
    AppValidators.confirmationTextValidator = function (confirmationText) {
        return function (control) {
            if (!control.value)
                return null;
            if (control.value.toLowerCase() == confirmationText.toLowerCase()) {
                return null;
            }
            else {
                return { 'invalidConfirmationText': true };
            }
        };
    };
    AppValidators.textByTrimValidator = function (control) {
        return control.value ? (String(control.value).trim().length === 0 ? { pattern: true } : null) : null;
    };
    AppValidators.emailLabelValidator = function (control) {
        // Multiple emails seperated by comma regex
        if (control.value != '' && control.value != null) {
            var emailValue = control.value.split(',');
            emailValue = emailValue.filter(function (e) { return !(e.includes('USER_') || e.includes('ROLE_')); });
            if (emailValue.length > 0) {
                var ischecked = false;
                for (var _i = 0, emailValue_2 = emailValue; _i < emailValue_2.length; _i++) {
                    var value = emailValue_2[_i];
                    var emailAddress = value.trim();
                    if (emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                        ischecked = true;
                    }
                    else {
                        ischecked = false;
                        break;
                    }
                }
                if (ischecked) {
                    return null;
                }
                else {
                    return { 'invalidEmailAddress': true };
                }
            }
            else {
                return null;
            }
        }
    };
    return AppValidators;
}());
export { AppValidators };
