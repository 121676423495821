import { OnDestroy, OnInit } from '@angular/core';
import { FollowUpInputModel, InputModel } from "@app/shared/service-reminder-communication/services/input-model";
import { TranslateService } from "@ngx-translate/core";
import { EventDispatcherService } from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import { ModalDirective } from "ngx-bootstrap";
import { AuthService } from "@app/core";
var ScTemplateFormComponent = /** @class */ (function () {
    function ScTemplateFormComponent(translate, eventDispatcher, authService) {
        this.translate = translate;
        this.eventDispatcher = eventDispatcher;
        this.authService = authService;
        this.singleAnimationState = 'in';
        this.multipleAnimationState = 'out';
        this.semicircle = false;
        this.radius = 20;
        this.sourceId = 0;
        this.isValidForm = false;
        this.inValidInterval = false;
        this.currentActiveMenuName = '';
        this.currentActiveInputModel = null;
        this.currentActiveMessages = {};
        this.totalTasksPercentage = 0;
        this.totalTasksCount = 0;
        this.totalTasksCompletedCount = 0;
        this.singlePropTotalTasksCount = 0;
        this.singlePropCompletedTasksCount = 0;
        this.multiplePropTotalTasksCount = 0;
        this.multiplePropCompletedTasksCount = 0;
        this.isLoading = true;
        this.isLoadingTemplate = true;
        this.intervalItems = [
            { id: 2, text: '2 days' },
            { id: 3, text: '3 days' },
            { id: 4, text: '4 days' },
            { id: 5, text: '5 days' },
            { id: 6, text: '6 days' },
            { id: 7, text: '7 days' },
            { id: 10, text: '10 days' },
            { id: 14, text: '14 days' },
            { id: 20, text: '20 days' },
        ];
        this.lastIntervalVal = 0;
        this.select2Options4Interval = {
            minimumResultsForSearch: -1,
            allowClear: false
        };
        this.select2Options4Email = {};
        this.select2Options4Sms = {};
        this.select2InstanceInterval = null;
        this.select2InstanceEmail = null;
        this.select2InstanceSms = null;
        this.emailTemplateList = [];
        this.smsTemplateList = [];
        this.emailTemplateListIndexById = {};
        this.smsTemplateListIndexById = {};
        this.csScrollBarActivated = false;
        this.curIndex = undefined;
        this.activeTab = 'single';
        this.showConfirmModel = false;
        //confirm modal options
        this.inValidConfirmText = false;
        this.deleteConfirmTextInput = '';
        this.buttonText = 'Delete';
        this.confirmText = 'DELETE';
        this.confirmLoading = false;
        this.deleteError = false;
        this.validationMessage = '\'DELETE\' to remove the follow up';
        this.defaultTemplates = {};
        this.smsScreenLabel = this.translate.instant('sms.templates');
        this.emailScreenLabel = this.translate.instant('Email.template');
        this.letterScreenLabel = this.translate.instant('letter.templates');
        this.selectedSmsTemplate = '';
        this.selectedEmailTemplate = '';
        this.selectedTemplateTitles = {
            sms: '',
            email: '',
            letter: ''
        };
    }
    ScTemplateFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.select2Options4Interval['placeholder'] = 'Please.Choose';
        this.select2Options4Interval['data'] = this.intervalItems;
        this.smsModulePermission = this.authService.hasModuleAccess('Sms');
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                var value = data['data'];
                if (action === EventDispatcherService.CONSTANTS['init']) {
                    _this.sourceId = value['sourceId'];
                    _this.singlePropertyInputModel = value['singlePropertyInput'];
                    _this.multiplePropertyInputModel = value['multiplePropertyInput'];
                    // this.defaultTemplates = value['defaultTemplates'];
                    _this.setEmailTemplateList(value['emailTemplateList']);
                    _this.setSmsTemplateList(value['smsTemplateList']);
                    _this.emailTemplateListIndexById = value['emailTemplateListIndexById'];
                    _this.smsTemplateListIndexById = value['smsTemplateListIndexById'];
                    _this.select2Options4Email['data'] = _this.emailTemplateList;
                    _this.select2Options4Sms['data'] = _this.smsTemplateList;
                    _this.isLoading = false;
                    _this.isLoadingTemplate = false;
                    if (_this.activeTab == 'single') {
                        _this.singlePropertyInputModel.setActive();
                        _this.currentActiveMenuName = _this.singlePropertyInputModel.itemName;
                        _this.currentActiveInputModel = _this.singlePropertyInputModel;
                    }
                    if (_this.activeTab == 'multiple') {
                        _this.multiplePropertyInputModel.setActive();
                        _this.currentActiveMenuName = _this.multiplePropertyInputModel.itemName;
                        _this.currentActiveInputModel = _this.multiplePropertyInputModel;
                    }
                    _this.currentActiveMessages = _this.currentActiveInputModel.messages;
                    _this.setCommunicationTitles();
                    _this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['currentActiveInput'], {
                        currentActiveInputModel: _this.currentActiveInputModel
                    });
                    //this.setPropTasksCount();
                    _this.dispatchCheckIsLastIndexEvent();
                    _this.checkValidation();
                }
                else if (action == EventDispatcherService.CONSTANTS['currentActiveInput']) {
                    /*if( this.currentActiveInputModel.interval ) {
                        this.currentActiveInputModel.status = 'completed';
                    }
                    this.setPropTasksCount();*/
                }
                else if (action == EventDispatcherService.CONSTANTS['goToNextItem']) {
                    if (value['actionName'] == 'onSelectTabMenu')
                        _this.onSelectTabMenu(value['activeTab']);
                    else if (value['actionName'] == 'onClickItem') {
                        _this.onClickItem(value['nextActiveItem'], value['index']);
                    }
                }
            }
        });
    };
    ScTemplateFormComponent.prototype.setCommunicationTitles = function () {
        var currentModal = this.currentActiveInputModel.messages;
        if (currentModal.hasOwnProperty('email') && currentModal.email && currentModal.email.hasOwnProperty('title')) {
            this.selectedTemplateTitles['email'] = currentModal.email.title;
        }
        else {
            this.selectedTemplateTitles['email'] = '';
        }
        if (currentModal.hasOwnProperty('sms') && currentModal.sms && currentModal.sms.hasOwnProperty('title')) {
            this.selectedTemplateTitles['sms'] = currentModal.sms.title;
        }
        else {
            this.selectedTemplateTitles['sms'] = '';
        }
        if (currentModal.hasOwnProperty('letter') && currentModal.letter && currentModal.letter.hasOwnProperty('title')) {
            this.selectedTemplateTitles['letter'] = currentModal.letter.title;
        }
        else {
            this.selectedTemplateTitles['sms'] = '';
        }
    };
    ScTemplateFormComponent.prototype.checkConfirmText = function () {
        this.inValidConfirmText = !(this.deleteConfirmTextInput.trim().toUpperCase() == this.confirmText);
    };
    ScTemplateFormComponent.prototype.checkIsLastItem = function () {
        var inputModelProperty;
        if (this.activeTab == 'single') {
            inputModelProperty = this.singlePropertyInputModel;
        }
        else if (this.activeTab == 'multiple') {
            inputModelProperty = this.multiplePropertyInputModel;
        }
        if (this.currentActiveInputModel.propertyType == 1) {
            return false;
        }
        if (this.currentActiveInputModel.propertyType == 2) {
            if (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0)
                return false;
            else {
                return (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length == 0) ||
                    (this.currentActiveInputModel instanceof FollowUpInputModel && inputModelProperty.followUpMessage.length == this.currentActiveInputModel.followUpOrder);
            }
        }
    };
    ScTemplateFormComponent.prototype.onSelectTabMenu = function (current) {
        if (current == this.activeTab) {
            return;
        }
        this.reloadTemplate();
        this.toggleAnimationDiv(current);
        this.activeTab = current;
        this.setActiveItem();
        this.currentActiveMessages = this.currentActiveInputModel.messages;
        this.filteredByMenuItemClicked(null);
        this.checkValidation();
        // this.setPropTasksCount();
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.dispatchCheckIsLastIndexEvent();
        this.dispatchUpdatedInputModel();
        this.setCommunicationTitles();
    };
    ScTemplateFormComponent.prototype.setActiveItem = function () {
        if (this.activeTab == 'single') {
            this.singlePropertyInputModel.setActive();
            this.multiplePropertyInputModel.setInactive();
            this.currentActiveMenuName = this.singlePropertyInputModel.itemName;
            this.currentActiveInputModel = this.singlePropertyInputModel;
        }
        if (this.activeTab == 'multiple') {
            this.multiplePropertyInputModel.setActive();
            this.singlePropertyInputModel.setInactive();
            this.currentActiveMenuName = this.multiplePropertyInputModel.itemName;
            this.currentActiveInputModel = this.multiplePropertyInputModel;
        }
    };
    ScTemplateFormComponent.prototype.checkValidation = function () {
        var _this = this;
        var inputModel = this.activeTab == 'single' ? this.singlePropertyInputModel : this.multiplePropertyInputModel;
        /*if(inputModel.status == 'completed')
            this.isValidForm = true;

        if(inputModel.followUpMessage.length >  0) {
            inputModel.followUpMessage.forEach( (followUpInputModel: InputModel) => {
                if(followUpInputModel.status != 'completed') {
                    this.isValidForm = false;
                }
            });
        }*/
        this.isValidForm = this.currentActiveInputModel.status === 'completed';
        if (this.currentActiveInputModel.status == 'pending') {
            var hasInValidMsg = function (element, index, messages) {
                return element.templateId == null;
            };
            if (this.currentActiveInputModel.interval == null) {
                this.isValidForm = false;
            }
            else {
                this.isValidForm = !Object.values(this.currentActiveInputModel.messages).every(hasInValidMsg);
            }
            if (this.isValidForm) {
                this.currentActiveInputModel.status = 'completed';
                this.currentActiveInputModel.completedTasksCount++;
            }
            /*else {
                this.currentActiveInputModel.status = 'pending';
                this.currentActiveInputModel.completedTasksCount--;
            }*/
        }
        if (this.isValidForm && inputModel.followUpMessage.length > 0) {
            var curVal_1 = this.currentActiveInputModel.interval;
            this.isValidForm = !inputModel.followUpMessage.some(function (item, index) { return _this.curIndex != index && curVal_1 == item.interval; });
            this.inValidInterval = !this.isValidForm;
        }
        this.setPropTasksCount();
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['isValidForm'], {
            isValidForm: this.isValidForm
        });
    };
    ScTemplateFormComponent.prototype.isValidInterval = function () {
        console.log('typeof this.currentActiveInputModel', this.currentActiveInputModel.interval);
        return typeof this.currentActiveInputModel.interval === "number";
    };
    ScTemplateFormComponent.prototype.getSinglePropTasksCount = function () {
        var count = this.singlePropertyInputModel.tasksCount;
        var completedCount = this.singlePropertyInputModel.completedTasksCount;
        if (this.singlePropertyInputModel.followUpMessage.length > 0) {
            this.singlePropertyInputModel.followUpMessage.forEach(function (followUpInputModel) {
                count = count + followUpInputModel.tasksCount;
                completedCount = completedCount + followUpInputModel.completedTasksCount;
            });
        }
        this.singlePropTotalTasksCount = count;
        this.singlePropCompletedTasksCount = completedCount;
    };
    ScTemplateFormComponent.prototype.getMultiplePropTasksCount = function () {
        var count = this.multiplePropertyInputModel.tasksCount;
        var completedCount = this.multiplePropertyInputModel.completedTasksCount;
        if (this.multiplePropertyInputModel.followUpMessage.length > 0) {
            this.multiplePropertyInputModel.followUpMessage.forEach(function (followUpInputModel) {
                count = count + followUpInputModel.tasksCount;
                completedCount = completedCount + followUpInputModel.completedTasksCount;
            });
        }
        this.multiplePropTotalTasksCount = count;
        this.multiplePropCompletedTasksCount = completedCount;
    };
    ScTemplateFormComponent.prototype.setPropTasksCount = function () {
        this.getSinglePropTasksCount();
        this.getMultiplePropTasksCount();
        this.getTasksInPercentage();
    };
    ScTemplateFormComponent.prototype.getTasksInPercentage = function () {
        this.totalTasksCount = this.singlePropTotalTasksCount + this.multiplePropTotalTasksCount;
        this.totalTasksCompletedCount = this.singlePropCompletedTasksCount + this.multiplePropCompletedTasksCount;
        this.totalTasksPercentage = Math.round((this.totalTasksCompletedCount / this.totalTasksCount) * 100);
        return this.totalTasksPercentage;
    };
    ScTemplateFormComponent.prototype.onClickItem = function (inputModel, index) {
        if (index === void 0) { index = undefined; }
        this.currentActiveMenuName = inputModel.itemName;
        this.currentActiveInputModel = inputModel;
        //this.select2InstanceInterval.select2('val', inputModel.interval);
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.curIndex = index;
        this.filteredByMenuItemClicked(index);
        this.checkValidation();
        //this.setPropTasksCount();
        this.reloadTemplate();
        this.dispatchUpdatedInputModel();
        this.dispatchCheckIsLastIndexEvent();
        this.setCommunicationTitles();
    };
    ScTemplateFormComponent.prototype.toggleAnimationDiv = function (tabName) {
        this.multipleAnimationState = this.multipleAnimationState === 'out' ? 'in' : 'out';
        this.singleAnimationState = this.singleAnimationState === 'out' ? 'in' : 'out';
    };
    ScTemplateFormComponent.prototype.onChangeIntervalTemplate = function (value) {
        if (this.currentActiveInputModel.interval == value) {
            return;
        }
        var oldValue = this.currentActiveInputModel.interval;
        this.currentActiveInputModel.interval = value ? parseInt(value) : null;
        if (!oldValue && value) {
            //this.currentActiveInputModel.status = 'completed';
            //this.currentActiveInputModel.completedTasksCount++;
            //this.lastIntervalVal = this.currentActiveInputModel.interval;
        }
        if (oldValue && !value) {
            this.currentActiveInputModel.status = 'pending';
            this.currentActiveInputModel.completedTasksCount--;
        }
        this.checkValidation();
        //this.setPropTasksCount();
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['currentActiveInput'], {
            currentActiveInputModel: this.currentActiveInputModel,
            eventName: 'onChangedInterval'
        });
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    };
    ScTemplateFormComponent.prototype.setTemplateDetails = function (template, type) {
        switch (type) {
            case 'email':
                this.onChangeEmailTemplate(template);
                break;
            case 'sms':
                this.onChangeSmsTemplate(template);
                break;
            case 'letter':
                this.onChangeLetterTemplate(template);
                break;
        }
    };
    ScTemplateFormComponent.prototype.onChangeEmailTemplate = function (value) {
        if (this.currentActiveInputModel.messages['email'].templateId == parseInt(value.templateId)) {
            return;
        }
        var oldValue = this.currentActiveInputModel.messages['email'].templateId;
        this.currentActiveInputModel.messages['email'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if (value) {
            this.currentActiveInputModel.messages['email'].message = value['message'];
            this.currentActiveInputModel.messages['email'].subject = value['subject'];
            this.currentActiveInputModel.messages['email'].title = value['templateName'];
        }
        if (!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if (oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'email',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    };
    ScTemplateFormComponent.prototype.onChangeSmsTemplate = function (value) {
        if (this.currentActiveInputModel.messages['sms'].templateId == parseInt(value.templateId)) {
            return;
        }
        var oldValue = this.currentActiveInputModel.messages['sms'].templateId;
        this.currentActiveInputModel.messages['sms'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if (value) {
            // const smsMessageItem = this.smsTemplateListIndexById[value];
            this.currentActiveInputModel.messages['sms'].message = value['message'];
            this.currentActiveInputModel.messages['sms'].title = value['templateName'];
            this.currentActiveInputModel.messages['sms'].subject = '';
        }
        if (!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if (oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'sms',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    };
    ScTemplateFormComponent.prototype.onChangeLetterTemplate = function (value) {
        if (this.currentActiveInputModel.messages['letter'].templateId == parseInt(value.templateId)) {
            return;
        }
        var oldValue = this.currentActiveInputModel.messages['letter'].templateId;
        this.currentActiveInputModel.messages['letter'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if (value) {
            // const smsMessageItem = this.smsTemplateListIndexById[value];
            this.currentActiveInputModel.messages['letter'].message = value['message'];
            this.currentActiveInputModel.messages['letter'].title = value['templateName'];
            this.currentActiveInputModel.messages['letter'].subject = '';
        }
        if (!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if (oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'letter',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    };
    ScTemplateFormComponent.prototype.dispatchUpdatedInputModel = function () {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['dispatchUpdatedForm'], {
            singlePropertyInputModel: this.singlePropertyInputModel,
            multiplePropertyInputModel: this.multiplePropertyInputModel,
            currentActiveInputModel: this.currentActiveInputModel,
        });
    };
    ScTemplateFormComponent.prototype.dispatchUnSavedChanges = function () {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['unSavedChanges'], {
            unSaveConfirmation: true
        });
    };
    ScTemplateFormComponent.prototype.dispatchPushDeletedItem = function (deletedItem) {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['pushDeletedItem'], {
            deletedItem: deletedItem
        });
    };
    ScTemplateFormComponent.prototype.dispatchCheckIsLastIndexEvent = function () {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['checkIsLastIndex'], {
            checkIsLastIndex: this.checkIsLastItem()
        });
    };
    ScTemplateFormComponent.prototype.addFollowUpMessage = function () {
        var MainInputModel;
        if (this.activeTab == 'single') {
            MainInputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
        }
        var index = MainInputModel.followUpMessage.length + 1;
        var name = this.translate.instant('Follow.Up');
        var inputModel = new FollowUpInputModel(this.sourceId, 0, 0, name + ' ' + index, MainInputModel.propertyType, MainInputModel.screenType, null, {}, []);
        inputModel._intervalOld = null;
        inputModel.followUpOrder = index;
        inputModel.itemName = 'follow.up.' + index;
        inputModel.isFollowUpItem = true;
        inputModel.setDefaultMessages();
        this.currentActiveMenuName = inputModel.itemName;
        MainInputModel.followUpMessage.push(inputModel);
        this.curIndex = index - 1;
        this.currentActiveInputModel = inputModel;
        //this.select2InstanceInterval.select2('val', inputModel.interval);
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.filteredIntervalInput();
        this.filteredByMenuItemClicked(index);
        // this.setPropTasksCount();
        this.reloadTemplate();
        this.dispatchUpdatedInputModel();
        this.setCommunicationTitles();
    };
    ScTemplateFormComponent.prototype.onScrollDown = function () {
        this.csScrollBarActivated = true;
    };
    ScTemplateFormComponent.prototype.onScrollTopReach = function () {
        this.csScrollBarActivated = false;
    };
    ScTemplateFormComponent.prototype.filteredIntervalInput = function () {
        var lastInterval;
        if (this.activeTab == 'single') {
            lastInterval = this.singlePropertyInputModel.getLastInterval();
        }
        else if (this.activeTab == 'multiple') {
            lastInterval = this.multiplePropertyInputModel.getLastInterval();
        }
        if (!lastInterval) {
            lastInterval = 0;
        }
        var filterIntervalItems = [];
        if (this.currentActiveInputModel.isFollowUpItem && lastInterval > 0) {
            filterIntervalItems = this.intervalItems.filter(function (item) {
                return item['id'] > lastInterval;
            });
        }
        else {
            filterIntervalItems = this.intervalItems;
        }
        this.select2Options4Interval['data'] = filterIntervalItems;
    };
    ScTemplateFormComponent.prototype.filteredByMenuItemClicked = function (index) {
        var MainInputModel;
        if (this.activeTab == 'single') {
            MainInputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
        }
        var isMain = !this.currentActiveInputModel.isFollowUpItem;
        var hasFollowups = MainInputModel.followUpMessage.length > 0;
        var currentValue = this.currentActiveInputModel.interval;
        if (isMain) {
            this.select2Options4Interval['data'] = this.intervalItems;
            return;
        }
        if (hasFollowups) {
            var selectedIntervals_1 = MainInputModel.getAllSelectedIntervals();
            this.select2Options4Interval['data'] = this.intervalItems.filter(function (item) {
                return currentValue == item['id'] || !selectedIntervals_1.includes(item['id']);
            });
        }
    };
    /*_filteredByMenuItemClicked(index)  {
        let selectedIntervals: any[];
        let MainInputModel: InputModel;
        if(this.activeTab == 'single') {
            MainInputModel = this.singlePropertyInputModel;
            selectedIntervals = MainInputModel.getAllSelectedIntervals();
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
            selectedIntervals = MainInputModel.getAllSelectedIntervals();
        }
        const isMain = !this.currentActiveInputModel.isFollowUpItem;
        const hasFollowups = MainInputModel.followUpMessage.length > 0;
        const currentValue = this.currentActiveInputModel.interval;
        let range = {};
        if(isMain && !hasFollowups) {
            this.select2Options4Interval['data'] = this.intervalItems;
            return;
        }
        else if(isMain && hasFollowups) {
            let firstFollowUpInputModel: InputModel = MainInputModel.followUpMessage[0];
            let inputVal = firstFollowUpInputModel.interval;
            if(inputVal) {
                range['less'] = inputVal;
            }
        }
        else if(!isMain) {
            if(index == 0)  {
                let prevInputVal = MainInputModel.interval;
                let nextInputVal = null;
                let hasNextIndex = MainInputModel.followUpMessage.length > 1;
                if(hasNextIndex) {
                    nextInputVal = MainInputModel.followUpMessage[1].interval;
                }
                range['greater'] = prevInputVal;
                if(nextInputVal) {
                    range['less'] = nextInputVal;
                }
            }
            else {
                let isLastIndex = index === (MainInputModel.followUpMessage.length - 1);
                let prevIndex = index - 1;
                let prevInputVal = MainInputModel.followUpMessage[prevIndex].interval;
                let nextInputVal = null;
                range['greater'] = prevInputVal;
                if(!isLastIndex) {
                    let nextIndex = index + 1;
                    nextInputVal = MainInputModel.followUpMessage[nextIndex].interval;
                    if(nextInputVal) {
                        range['less'] = nextInputVal;
                    }
                }
            }
        }

        let rangeLength = Object.keys(range).length;
        this.select2Options4Interval['data'] = this.intervalItems.filter((item) => {
            if(range['less'] && range['greater']) {
                return item['id'] < range['less'] && item['id'] > range['greater']
            }
            if(range['less']) {
                return  item['id'] < range['less'];
            }
            else if (range['greater']) {
                return  item['id'] > range['greater'];
            }
            else {
                return true;
            }
        });

    }*/
    /*disabledIntervalAlreadyAdded(itemId:number) {
        return this.singlePropertyInputModel.getAllSelectedIntervals().includes(itemId);
    }*/
    ScTemplateFormComponent.prototype.getOverlayStyle = function () {
        var isSemi = this.semicircle;
        var transform = (isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';
        return {
            top: isSemi ? 'auto' : '50%',
            bottom: isSemi ? '5%' : 'auto',
            left: '50%',
            transform: transform,
            fontSize: '12px',
        };
    };
    ScTemplateFormComponent.prototype.setEmailTemplateList = function (data) {
        var _this = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this.emailTemplateList.push({
                id: parseInt(item.id),
                text: item.templateName
            });
            // this.emailTemplateListIndexById[item.id] = item;
        });
    };
    ScTemplateFormComponent.prototype.setSmsTemplateList = function (data) {
        var _this = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            // this.smsTemplateListIndexById[item.id] = item;
        });
    };
    ScTemplateFormComponent.prototype.reloadTemplate = function () {
        var _this = this;
        this.isLoadingTemplate = true;
        setTimeout(function () {
            _this.isLoadingTemplate = false;
        }, 1000);
    };
    ScTemplateFormComponent.prototype.setSelect2InstanceInterval = function (instance) {
        this.select2InstanceInterval = instance;
    };
    ScTemplateFormComponent.prototype.setSelect2InstanceEmail = function (instance) {
        this.select2InstanceEmail = instance;
    };
    ScTemplateFormComponent.prototype.setSelect2InstanceSms = function (instance) {
        this.select2InstanceSms = instance;
    };
    ScTemplateFormComponent.prototype.onShowConfirmModal = function () {
        this.showConfirmModel = true;
        this.actionModal.show();
    };
    ScTemplateFormComponent.prototype.onHiddenModal = function () {
        this.showConfirmModel = false;
        this.actionModal.hide();
        this.confirmLoading = false;
        this.deleteConfirmTextInput = '';
    };
    ScTemplateFormComponent.prototype.deleteAction = function () {
        var _this = this;
        this.confirmLoading = true;
        var inputModel;
        if (this.activeTab == "single") {
            inputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == "multiple") {
            inputModel = this.multiplePropertyInputModel;
        }
        if (inputModel && typeof this.curIndex != undefined && this.curIndex > -1) {
            var deletedItem = this.currentActiveInputModel.serviceReminderConfigId;
            inputModel.removeFollowupItem(this.curIndex);
            this.dispatchUnSavedChanges();
            if (deletedItem) {
                this.dispatchPushDeletedItem(deletedItem);
            }
            inputModel.followUpMessage.forEach(function (item, index) {
                var count = index + 1;
                item.name = _this.translate.instant('Follow.Up') + ' ' + count;
                item.followUpOrder = count;
            });
            this.onClickItem(inputModel);
        }
        this.onHiddenModal();
    };
    ScTemplateFormComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return ScTemplateFormComponent;
}());
export { ScTemplateFormComponent };
