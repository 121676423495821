import {forwardRef, Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {SettingsService} from "@app/core";
import {formatDate} from "@angular/common";

@Pipe({
    name: 'long_date'
})
export class LongDatePipe implements PipeTransform {
    private ordinalWithDate: string = '';
    private smartFormat: string;
    private settingsFormatId: number;
    private readonly settingsFormats: { id: number, format: string }[] = [
        {'id': 0, 'format': 'EEE X#X MMM y'},
        {'id': 1, 'format': 'EEEE X#X MMM y'},
        {'id': 2, 'format': 'EEE X#X MMMM y'},
        {'id': 3, 'format': 'EEEE X#X MMMM y'},
        {'id': 50, 'format': 'EEE MMM X#X y'},
        {'id': 51, 'format': 'EEEE MMM X#X y'},
        {'id': 52, 'format': 'EEE MMMM X#X y'},
        {'id': 53, 'format': 'EEEE MMMM X#X y'}
    ];
    private readonly ordinalJSON: object;

    constructor(@Inject(forwardRef(() => SettingsService)) private settings,
                @Inject(LOCALE_ID) private locale: string) {

        this.ordinalJSON = JSON.parse(this.settings.ordinals);
        if (this.locale == 'en' || !this.locale) this.locale = 'en-GB';
    }

    public validateDateString(dateTime: string): boolean {
        const pattern = /^(\d\d\d\d)-(0[1-9]|1[0-2])-([0-2][1-9]|[1-3][0-1]|)/g;
        const dateObj = new Date(dateTime);
        // return (pattern.test(dateTime)) && ((new Date(dateTime)).getTime() > 0);
        return (pattern.test(dateTime)) && (!isNaN(dateObj.getTime())) && (dateTime === dateObj.toISOString().split('T')[0]);
    }

    public doSmartFormat(dateString: string, currentDate: string): string {
        this.smartFormat = undefined;
        const inputDateObj = new Date(dateString);
        const currentDateObj = new Date(currentDate);
        const nextDateObj = new Date(currentDateObj.getTime() + (24 * 60 * 60 * 1000));
        const prevDateObj = new Date(currentDateObj.getTime() - (24 * 60 * 60 * 1000));

        if ((currentDateObj.getFullYear() === inputDateObj.getFullYear()) && (currentDateObj.getMonth() === inputDateObj.getMonth()) && (currentDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat  = 'Today';
        } else if ((prevDateObj.getFullYear() === inputDateObj.getFullYear()) && (prevDateObj.getMonth() === inputDateObj.getMonth()) && (prevDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat =  'Yesterday';
        } else if ((nextDateObj.getFullYear() === inputDateObj.getFullYear()) && (nextDateObj.getMonth() === inputDateObj.getMonth()) && (nextDateObj.getDate() === inputDateObj.getDate())) {
            this.smartFormat =  'Tomorrow';
        }

        return this.smartFormat;
    }

    public getDayOfTheMonth(dateString: string): number {
        //covert mysql timestamp to JS timestamp
        let parsedDate = Date.parse(dateString.replace(/-/g, '/'));
        return new Date(parsedDate).getDate();
    }

    public getDateFormat(intlSetting: number): string {
        const format = this.settingsFormats.find(f => f.id == intlSetting);
        return format ? format.format : undefined;
    }

    transform(MYSQLDatetime: string, isSmartFormatRequired: boolean = false): string {

        const dateString = MYSQLDatetime.slice(0, 10);
        const IsDateValid = this.validateDateString(dateString);

        if (!IsDateValid) {
            return 'Invalid date';
        }

        if (isSmartFormatRequired && this.doSmartFormat(dateString, new Date().toISOString().split('T')[0])) {
            return this.smartFormat;
        } else {
            //TODO: Remove the default 0 while moving the regional settings screen to live as we don't want to hardcode anything for the client

            const intlSettings = this.settingsFormatId ? this.settingsFormatId : this.settings._csIntlSettings.long_date || 0;
            let format = this.getDateFormat(intlSettings);

            if (format && this.ordinalJSON.hasOwnProperty(this.locale)) {
                const date = this.getDayOfTheMonth(dateString);
                this.ordinalWithDate = this.ordinalJSON[this.locale][date];
            }else{

                //TODO: uncomment the below line and remove the other return statement  while moving regional settings screen to live as we don't want to hardcode anything for the client. Also update the testcases accordingly

                // return (!format) ? 'Format not available' : 'Undefined locale';
                format = 'EEE d MMM y';
            }

            return formatDate(dateString, format, this.locale).replace("X#X", this.ordinalWithDate);
        }
    }

    public transformById(MYSQLDatetime: string, settingsFormatId: number, isSmartFormatRequired: boolean,): string {
        this.settingsFormatId = settingsFormatId;
        return this.transform(MYSQLDatetime, isSmartFormatRequired);
    }

}
