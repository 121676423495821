import { EventEmitter, OnInit } from '@angular/core';
var CsPaginationPanelComponent = /** @class */ (function () {
    function CsPaginationPanelComponent() {
        this.pageOffsetList = [5, 10, 20, 50, 100];
        this.maxPagesDisplayLength = 10;
        this.onUpdatePagingParams = new EventEmitter();
    }
    Object.defineProperty(CsPaginationPanelComponent.prototype, "totalRecord", {
        set: function (value) {
            this._totalRecord = value;
            if (this.pagingParams && value >= 0) {
                var pageSize = this.pagingParams.pageSize || 5;
                this.setPageSize(pageSize, 'none');
            }
        },
        enumerable: true,
        configurable: true
    });
    CsPaginationPanelComponent.prototype.ngOnInit = function () {
        this.setInitialPagingParams();
        this.setPageList();
        this.setPaginationProps();
        this.updatePagingParams();
    };
    CsPaginationPanelComponent.prototype.setInitialPagingParams = function () {
        this.pagingParams = {
            currentPage: (this.initialParams && this.initialParams.currentPage) || 1,
            pageSize: (this.initialParams && this.initialParams.pageSize) || 5,
            startRow: null,
            endRow: null
        };
    };
    CsPaginationPanelComponent.prototype.setPageList = function () {
        this.pageList = Array(Math.ceil(this._totalRecord / this.pagingParams.pageSize)).fill(0).map(function (_, i) { return i + 1; });
    };
    CsPaginationPanelComponent.prototype.setPaginationProps = function () {
        var remainingLastPageRowCount = (this._totalRecord % this.pagingParams.pageSize);
        this.pagingParams.startRow = (this.pagingParams.pageSize * (this.pagingParams.currentPage - 1)) + 1;
        this.pagingParams.endRow = this.pageList.length === this.pagingParams.currentPage && remainingLastPageRowCount ? (this.pagingParams.startRow - 1) + remainingLastPageRowCount : this.pagingParams.pageSize * this.pagingParams.currentPage;
    };
    CsPaginationPanelComponent.prototype.setPageSize = function (offset, pagingEvent) {
        this.pagingParams.currentEvent = pagingEvent || 'pageSize';
        this.pagingParams.pageSize = offset;
        this.pagingParams.currentPage = Math.trunc(((this.pagingParams.startRow - 1) / this.pagingParams.pageSize)) + 1;
        this.setPageList();
        this.setPaginationProps();
        this.updatePagingParams();
    };
    CsPaginationPanelComponent.prototype.setCurrentPage = function (page) {
        this.pagingParams.currentEvent = 'pageNav';
        this.pagingParams.currentPage = page;
        this.setPaginationProps();
        this.updatePagingParams();
    };
    CsPaginationPanelComponent.prototype.updatePagingParams = function () {
        this.onUpdatePagingParams.emit(this.pagingParams);
    };
    return CsPaginationPanelComponent;
}());
export { CsPaginationPanelComponent };
