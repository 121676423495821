<div class="row-fluid certificate-page-container slim-scroll thin dynamic-form-wrapper dynamic-form-wrapper-v2">
    <div class="space-skipper">
        <div *ngIf="formData" class="page-info">
            <span>Page {{pageIndex == -1 ? 1 : pageIndex + 2}} of {{formData['pages']?.length + 1}} &nbsp; </span>
            <button class="btn btn-primary"
                    *ngIf="pageIndex < formData['pages']?.length - 1"
                    (click)="saveAndContinue()"
                    [disabled]="pageIndex >= formData['pages']?.length || !isFormValid"
                    translate>Save.and.continue <i class="fa fa-angle-right"></i>
            </button>
            <button class="btn btn-primary"
                    *ngIf="pageIndex == formData['pages']?.length - 1"
                    (click)="saveAndActivate()"
                    [disabled]="pageIndex >= formData['pages']?.length || !isFormValid"
                    translate>Save.and.complete <i class="fa fa-angle-right"></i>
            </button>
            <div class="option-div-c">
                <div class="option-div" data-toggle="dropdown">
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                <ul class="dropdown-menu small-menu quick-links" role="menu">
                    <li  class="links checking">
                        <a (click)="saveAndContinue(false)" *ngIf="this.status == 'DRAFT'">
                            <span  translate>Save.as.draft.and.close</span>
                        </a>
                        <a (click)="saveAndActivate()"
                           style="padding-bottom: unset;"
                           *ngIf="this.status == 'ACTIVE'">
                            <span translate>Save.and.generate.pdf</span>
                        </a>
                        <a (click)="closeModal_()"
                           style="padding-top: unset;"
                           *ngIf="this.status == 'ACTIVE'">
                            <span translate>Cancel</span>
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
    <section class="cs-dynamic-form-container">
        <section class="customise_settings_wrapper">
            <div class="span3 side-el-container">
                <div class="elements-sections clearfix">
                    <div class="elements-list-view" appCertificateContainerResize>
                        <div>
                            <div class="cert-page-header">
                                <div class="title" translate>Pages.on.form</div>
                            </div>

                            <div class="cert-page-name-container">
                                <div class="cert-page">
                                    <span class="cert-page-name"
                                          [ngClass]="{'active': pageIndex == -1}"
                                          (click)="selectPage(-1)"
                                          translate>customer.landlord</span>
                                    <ng-container *ngIf="formData">
                                        <span class="cert-page-name"
                                              [ngClass]="{'active': pageIndex == i, 'disabled' : pageIndex < i
                                              && (lastSavedPageIndex+1 < i && status == 'DRAFT')}"
                                              (click)="selectPage(i)"
                                              *ngFor="let page of formData['pages']; let i = index;">
                                            {{page['pageName']}}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="span9 draggable-container" appCertificateContainerResize>
                <form class="form-horizontal" [formGroup]="customerOrLandlordForm" *ngIf="pageIndex == -1 && customerOrLandlordForm">
                    <fieldset>
                        <!--Customer or landlord page-->
                        <div class="header-cl">
                            <h4 translate>customer.landlord</h4>
                        </div>
                        <div class="span3 radio-cl">
                            <input type="radio"
                                   value="Customer"
                                   formControlName="customerownership"> <label translate>Customer</label>
                            <div class="info" *ngIf="customerOrLandlordInfo && customerOrLandlordInfo.customerInfo">
                                <p>{{ customerOrLandlordInfo.customerInfo['customerName'] }}</p>
                                <p>{{ customerOrLandlordInfo.customerInfo['addressline1'] }}</p>
                                <p>{{ customerOrLandlordInfo.customerInfo['town'] }}</p>
                                <p>{{ customerOrLandlordInfo.customerInfo['county'] }}</p>
                                <p>{{ customerOrLandlordInfo.customerInfo['postcode'] }}</p>
                            </div>
                        </div>
                        <div class="span3 radio-cl" *ngIf="customerOrLandlordInfo && customerOrLandlordInfo.showLandlord">
                            <input type="radio"
                                   value="Landlord"
                                   formControlName="customerownership"> <label translate>Landlord</label>
                            <div class="info" *ngIf="customerOrLandlordInfo && customerOrLandlordInfo.landlordInfo">
                                <p>{{ customerOrLandlordInfo.landlordInfo['name'] }}</p>
                                <p>{{ customerOrLandlordInfo.landlordInfo['addressline1'] }}</p>
                                <p>{{ customerOrLandlordInfo.landlordInfo['town'] }}</p>
                                <p>{{ customerOrLandlordInfo.landlordInfo['county'] }}</p>
                                <p>{{ customerOrLandlordInfo.landlordInfo['postcode'] }}</p>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <div [hidden]="pageIndex == -1">
                    <app-dynamic-certificate-form-parser [formData]="formData"
                                                         [draftData]="draftData"
                                                         [currentPageNumber]="pageIndex"
                                                         [jobId]="customerOrLandlordInfo['jobId']"
                                                         [customerId]="customerOrLandlordInfo['customerInfo']['customerId']"
                                                         [certificateId]="customerOrLandlordInfo['settingsCertificateId']"
                                                         (formValueChanges)="getFormValueChanges($event)"
                                                         (formStatusChanges)="getFormStatusChanges($event)"
                    ></app-dynamic-certificate-form-parser>
                </div>
            </div>
        </section>
    </section>
</div>
