import Handsontable from 'handsontable';
declare var $: any;

export function HansontableColor() {
    class HansontableColorEditor extends Handsontable.editors.TextEditor {
        $textarea: any;
        value: any;
        TEXTAREA: any;
        textareaStyle: any;
        textareaParentStyle: any;
        instance: any;
        columnSettings: Object = {};
        colorOptions: any = {
            flat: true,
            showPaletteOnly: true,
            showPalette: true,
            clickoutFiresChange: true,
            preferredFormat: 'hex',
            palette: [
                ['#99C2E6', '#E6BD9A', '#E699C1', '#BD99E5', '#C2E69B', '#CADFF1', '#F2DDCA', '#F1CADF', '#DDCAF1',
                    '#E0F1CB', '#B9D6EE', '#EED2BA', '#EEB9D5', '#D2B9EE'], ['#D6EEBA', '#A9CCEA', '#EAC8AA', '#EAA9CB',
                    '#C8AAE9', '#CCEAAB', '#DAE9F6', '#F6E7DA', '#F6DAE9', '#E7DAF5', '#E9F6DA', '#8BAFCD', '#CEAA8D', '#C585A6'],
                ['#BFD3E5', '#AB8ED0', '#74B5EC', '#DC833F', '#E94F9E', '#3395E4', '#8E4CE8', '#E0EA72', '#70EDB1', '#73EEED',
                    '#3ACBEA', '#86EB40', '#E50E1A', '#40BA5B'],
                ['#A71A9F', '#838518', '#FFFFFF']
            ],
            show: function () {
                $('.sp-thumb-el').each(function(){
                    $(this).find('.sp-thumb-inner').addClass('higlight-color');
                });
            },
            move: function (color) {
                $('.sp-thumb-el').each(function(){
                    $(this).find('.sp-thumb-inner').addClass('higlight-color');
                });
            },
            beforeShow: function () {
                $('.sp-thumb-el').each(function(){
                    $(this).find('.sp-thumb-inner').addClass('higlight-color');
                });
            },
            hide: function () {
                $('.sp-thumb-el').each(function(){
                    $(this).find('.sp-thumb-inner').addClass('higlight-color');
                });
            },
            change: function () {
                $('.sp-thumb-el').each(function(){
                    $(this).find('.sp-thumb-inner').addClass('higlight-color');
                });
            },
        }

        constructor(hotInstance: Handsontable, row: number, col: number, prop: string | number, TD: HTMLTableCellElement, cellProperties: Handsontable.CellProperties) {
            super(hotInstance, row, col, prop, TD, cellProperties);
            this.columnSettings = hotInstance.getCellMeta(hotInstance.rowOffset(), hotInstance.colOffset());
        }


        prepare(row: number, col: number, prop: string | number, TD: HTMLTableCellElement, originalValue: any, cellProperties: Handsontable.CellProperties) {
            this.$textarea.val(originalValue || '');
            this.originalValue = originalValue;
            Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);
        }


        createElements() {
            super.createElements();
            this.TEXTAREA = document.createElement('input');
            this.TEXTAREA.setAttribute('id', 'picker');

            Handsontable.dom.addClass(this.TEXTAREA, 'handsontableInput');

            this.$textarea = $(this.TEXTAREA);
            this.textareaStyle = this.TEXTAREA.style;
            this.textareaStyle.width = 0;
            this.textareaStyle.height = 0;

            this.TEXTAREA_PARENT = document.createElement('DIV');
            Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
            this.textareaParentStyle = this.TEXTAREA_PARENT.style;
            this.textareaParentStyle.display = 'none';
            this.textareaParentStyle.width = "auto";

            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);

            this.instance.rootElement.appendChild(this.TEXTAREA_PARENT);

            this.instance._registerTimeout(setTimeout(() => {
                this.refreshDimensions();
            }, 0));
        }

        open(keyboardEvent?: KeyboardEvent) {
            this.init();
            this.refreshDimensions();
            this.textareaParentStyle.display = 'block';
            this.textareaParentStyle.position = 'fixed';
            this.textareaParentStyle.marginTop = '120px';
            this.textareaParentStyle.left = '60%';
            this.instance.addHook('beforeKeyDown', this.onBeforeKeyDown);
            //display the Colors Picker Dropdown
            if(this.$textarea.val() == "") {
                this.colorOptions['color'] = '#FFFFFF'
            } else {
                this.colorOptions['color'] = this.$textarea.val()
            }
            this.$textarea.spectrum(this.colorOptions)
            $('span.sp-thumb-el').removeAttr("title")
            $('span.sp-thumb-el').addClass('higlight-color')
            var self = this;
            setTimeout(function () {
                self.$textarea.on('change.spectrum move.spectrum', function (e, tinyColor) {
                    self.$textarea.val(tinyColor.toHexString())
                    self.value = self.$textarea.val()
                    self.close();
                    self.finishEditing();
                });
            }, 10)
        }

        close() {
            this.instance.listen();
            this.instance.removeHook('beforeKeyDown', this.onBeforeKeyDown);
            this.$textarea.off();
            this.$textarea.spectrum('hide');
            Handsontable.editors.TextEditor.prototype.close.apply(this, arguments);
        }

        getValue() {
            return this.$textarea.val();
        }

        init() {
            Handsontable.editors.TextEditor.prototype.init.apply(this, arguments);
        }

        onBeforeKeyDown = function (event?) {
            var instance = this;
            var that = instance.getActiveEditor();
            var keyCodes = Handsontable.helper.KEY_CODES; // Changed "keyCode" as "KEY_CODES" by Star Systems (Need to check when upgrade)
            var ctrlDown = (event.ctrlKey || event.metaKey) && !event.altKey; //catch CTRL but not right ALT (which in some systems triggers ALT+CTRL)
            if (event.keyCode === 17 || event.keyCode === 224 || event.keyCode === 91 || event.keyCode === 93) {
                //when CTRL or its equivalent is pressed and cell is edited, don't prepare selectable text in textarea
                event.stopImmediatePropagation();
                return;
            }
            var target = event.target;
            //Process only events that have been fired in the editor
            if (event.target.tagName !== "INPUT") {
                return;
            }
            switch (event.keyCode) {
                case keyCodes.ARROW_RIGHT:
                    if (Handsontable.dom.getCaretPosition(target) !== target.value.length) {
                        event.stopImmediatePropagation();
                    } else {
                        this.$textarea.spectrum('hide')
                    }
                    break;
                case keyCodes.ARROW_LEFT:
                    if (Handsontable.dom.getCaretPosition(target) !== 0) {
                        event.stopImmediatePropagation();
                    } else {
                        this.$textarea.spectrum('hide')
                    }
                    break;
                case keyCodes.ENTER:
                    event.preventDefault(); //don't add newline to field
                    break;
                case keyCodes.A:
                case keyCodes.X:
                case keyCodes.C:
                case keyCodes.V:
                    if (ctrlDown) {
                        event.stopImmediatePropagation(); //CTRL+A, CTRL+C, CTRL+V, CTRL+X should only work locally when cell is edited (not in table context)
                    }
                    break;
                case keyCodes.BACKSPACE:
                case keyCodes.DELETE:
                case keyCodes.HOME:
                case keyCodes.END:
                    event.stopImmediatePropagation(); //backspace, delete, home, end should only work locally when cell is edited (not in table context)
                    break;
            }
        }

        beginEditing = function () {
            var onBeginEditing = this.instance.getSettings().onBeginEditing;
            if (onBeginEditing && onBeginEditing() === false) {
                return;
            }
            Handsontable.editors.TextEditor.prototype.beginEditing.apply(this, arguments);
        }

        finishEditing = function () {
            this.instance.listen();
            return Handsontable.editors.TextEditor.prototype.finishEditing.apply(this, arguments);
        }
    }
    return HansontableColorEditor;
}