import { OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { Validators, FormBuilder } from '@angular/forms';
var PricingSectionUsepartkitComponent = /** @class */ (function () {
    function PricingSectionUsepartkitComponent(bsModalRef, formBuilder) {
        this.bsModalRef = bsModalRef;
        this.formBuilder = formBuilder;
        this.action = '';
        this.select2Config = {
            width: '100%'
        };
        this.showDescription = false;
        this.selectedPartKit = {};
        this.quantity = 1;
        this.subscriptions = {};
        this.partKitToDisplay = [];
        this.buttonDisabled = false;
    }
    PricingSectionUsepartkitComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.action = 'save';
        this.onClose = new Subject();
        this.partsKitForm = this.formBuilder.group({
            'partsKit': ['', Validators.required],
            'quantity': [1, Validators.required]
        });
        this.partsKitForm.get('quantity').valueChanges.subscribe(function (value) {
            _this.getQuantity(value);
        });
    };
    PricingSectionUsepartkitComponent.prototype.selectPartKit = function (event) {
        this.showDescription = true;
        this.selectedPartKit = this.partKitList.find(function (data) { return data.id == event; });
        if (this.partsKitForm.value.partsKit) {
            this.selectedKit = this.selectedPartKit;
            this.getQuantity(this.partsKitForm.get('quantity').value);
        }
        else {
            var quantity = parseFloat(this.partsKitForm.value.quantity) <= 0 ? 1 : this.partsKitForm.value.quantity;
            this.getQuantity(quantity);
        }
    };
    PricingSectionUsepartkitComponent.prototype.getQuantity = function (quantity) {
        var setPartKit = [];
        var value = this.partsKitForm.value;
        quantity = quantity ? quantity : 1;
        this.quantity = quantity;
        if (this.selectedPartKit && this.selectedPartKit['kitItems'].length) {
            for (var i = 0; i < this.selectedPartKit['kitItems'].length; i++) {
                var newElement = JSON.parse(JSON.stringify(this.selectedPartKit['kitItems'][i]));
                newElement.quantity = newElement.quantity * quantity;
                setPartKit.push(newElement);
            }
            this.partKitToDisplay = setPartKit;
        }
        else {
            this.partKitToDisplay = [];
        }
    };
    // Save parts kit
    PricingSectionUsepartkitComponent.prototype.savePartsKit = function () {
        // let kitItems = [];
        // this.selectedKit['kitItems'].forEach(element => {
        // if(!element['exists']){
        // 		kitItems.push(element);
        // }
        // });
        this.action = 'saving';
        var selectedItems = JSON.parse(JSON.stringify(this.selectedKit));
        selectedItems['kitItems'] = this.partKitToDisplay;
        this.onClose.next(selectedItems);
        this.bsModalRef.hide();
    };
    PricingSectionUsepartkitComponent.prototype.restrictSpecialChar = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57)) {
            return false;
        }
    };
    PricingSectionUsepartkitComponent.prototype.restrictZero = function (event) {
        var value = parseFloat(event.target.value);
        if (value == 0) {
            this.buttonDisabled = true;
        }
        else {
            this.buttonDisabled = false;
        }
        this.getQuantity(value);
    };
    return PricingSectionUsepartkitComponent;
}());
export { PricingSectionUsepartkitComponent };
