import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var EscapeHtmlPipe = /** @class */ (function () {
    function EscapeHtmlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    EscapeHtmlPipe.prototype.transform = function (content) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    };
    return EscapeHtmlPipe;
}());
export { EscapeHtmlPipe };
