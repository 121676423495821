import {
    Component,
    OnInit,
    Input,
    Output,
    ElementRef,
    OnChanges,
    EventEmitter,
    Inject, forwardRef
} from '@angular/core';

// ..
import { CountriesService, CommonService } from '@app/services';
import { SettingsService } from '@app/core';

declare var $: any, document: any, _: any;
@Component({
    selector: '[app-cs-phonewidget]',
    templateUrl: './cs-phonewidget.component.html',
    styles: [],

})

export class CsPhonewidgetComponent implements OnChanges {

    @Input('selectedCode') selectedCode: any;
    @Input('labelName') labelName: string;
    @Output('valueChanged') valueEmitter: EventEmitter<any> = new EventEmitter();
    countries: any;
    selectedCountry: string = '';
    selectedCountryID: any;
    selectedCountryCode: string = '';
    countryName:string = '';
    phoneInput: any;
    inputCollection: any;
    areaCode: string;
    showCountries: boolean;
    hasImageValue: boolean = false;
    constructor(private countriesService:CountriesService,
                private commonService:CommonService,
                private el_ref: ElementRef,
                @Inject(forwardRef(() => SettingsService)) private settings
    ) {
        this.countries = this.settings.countriesJSONData;
    }

    ngOnInit() {
        this.selectedCountryID = this.settings._csCountryCode.id;
        this.selectedCountryCode=this.settings._csCountryCode.code;
        if(!this.selectedCode) {
            if(this.selectedCountryCode=='' || this.selectedCountryCode==undefined || this.selectedCountryCode=='null') {
                this.commonService.getClientCountryCodes().subscribe((res: any) => {
                    this.selectedCountryID=res.id;
                    this.selectedCountryCode=res.code;
                    let that=this;
                    _.each( that.countries, function(val, key ) {
                        if (key == that.selectedCountryCode) {
                            let country_obj = that.countriesService.getCountryByCode(key);
                            that.hasImageValue = true;
                            that.selectedCountry = country_obj[0].id;
                            that.selectedCountryID = country_obj[0].id;
                            that.selectedCountryCode = key;
                            that.valueEmitter.emit(key);
                        }
                    });
                });
            }

            if(this.selectedCountryCode!='') {
                let that=this;
                _.each( that.countries, function(val, key ) {
                    if (key == that.selectedCountryCode) {
                        let country_obj = that.countriesService.getCountryByCode(key);
                        that.hasImageValue = true;
                        that.selectedCountry = country_obj[0].id;
                        that.selectedCountryID = country_obj[0].id;
                        that.selectedCountryCode = key;
                        that.valueEmitter.emit(key);
                    }
                });
            }
        }


        if(this.selectedCode) {
            let that=this;
            _.each( that.countries, function(val, key ) {
                if (key == that.selectedCode) {
                    let country_obj = that.countriesService.getCountryByCode(key);
                    that.hasImageValue = true;
                    that.selectedCountry = country_obj[0].id;
                    that.selectedCountryID = country_obj[0].id;
                    that.selectedCountryCode = key;
                    that.valueEmitter.emit(key);
                }
            });
        }

        this.phoneInput = $(this.el_ref.nativeElement).parent().find('.phone-number-input');
        this.inputCollection = $(this.el_ref.nativeElement).parent().find('.phone-number-wrapper');
        this.areaCode = $(this.el_ref.nativeElement).parent().find('.selected-area-code');
        let that = this;
        $(document).on('click', function (e) {
            if ($(e.target).closest("#countrycode-dropdown").length === 0) {
                $(that.el_ref.nativeElement).removeClass('open');
            }
        });
    }

    handleErrorMsgWidth(char_length) {
        if (char_length === 2) {
            this.phoneInput.css('width', '150px');
        } else if (char_length === 3) {
            this.phoneInput.css('width', '142px');
        }
    }

    selectCountry(code) {
        let country_obj = this.countriesService.getCountryByCode(code);
        this.selectedCountry = country_obj[0].id;
        this.selectedCountryID = country_obj[0].id;
        this.selectedCountryCode = code;
        this.valueEmitter.emit(code);
        this.showCountries = false;
        $(this.el_ref.nativeElement).removeClass('open');
        // The width of the input changes depends on the number of characters the country code is
        (code.length > 2)? this.handleErrorMsgWidth(3) : this.handleErrorMsgWidth(2);
    }

    openDropDown(){
        if(this.showCountries == true){
            this.showCountries = false;
            $(this.el_ref.nativeElement).removeClass('open');
        }else{
            $(this.el_ref.nativeElement).addClass('open');
            this.showCountries = true;
        }
    }

    ngOnChanges(changes) {
        if (changes.selectedCode !== undefined) {
            if(this.selectedCode != '') {
                let that = this;
                _.each( that.countries, function(val, key ) {
                    if (key == that.selectedCode) {
                        let country_obj = that.countriesService.getCountryByCode(key);
                        that.hasImageValue = true;
                        that.selectedCountry = country_obj[0].id;
                        that.selectedCountryID = country_obj[0].id;
                        that.selectedCountryCode = key;
                        that.valueEmitter.emit(key);
                    }
                });
            }
        }
    }
}
