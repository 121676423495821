import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DynamicCertificatesService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    /* Get dynamic certificates
        Response : Array
    */
    getDynamicCertificates(params?: object): Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/dynamic-certificates`, params));
    }

    /* Set new dynamic certificate form
        Response : Array{ 'status' =>'success', 'data' => newFormData }
    */
    setDynamicCertificates(Data:object):Observable<any> {
        return this.http.post(this.getApiUrl('system_settings/dynamic-certificates'),JSON.stringify(Data));
    }
    /* Edit dynamic certificate form
        Response : Array{ 'status' =>'success', 'data' => newData }
    */
    editDynamicCertificate(certificateId: number,Data: object, params?: object):Observable<any> {
        return this.http.put(this.getApiUrl(`system_settings/dynamic-certificates/${certificateId}/edit`, params), JSON.stringify(Data));
    }
    /* Delete dynamic certificate form
        Response : "success"
    */
    deleteDynamicCertificate(certificateId: number):Observable<any> {
        return this.http.put(this.getApiUrl(`system_settings/dynamic-certificates/${certificateId}/delete`), null);
    }
    /* Delete dynamic certificate form
        Response : "success"
    */
    reOrderDynamicCertificates(Data: object):Observable<any> {
        return this.http.put(this.getApiUrl(`system_settings/dynamic-certificates/reorder`), JSON.stringify(Data));
    }

    /* Download dynamic certificate zip file
        Response : Array{ 'error' => <boolean>, 'message' => <string>, 'fileLink' => <string> }
    */
    downloadDynamicCertificateZip(certificateId: number):Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/dynamic-certificates/${certificateId}/download-zip`));
    }

    /* Upload dynamic certificate zip file
        Response : Array{ 'error' => <boolean>, 'message' => <string>, 'data' => <array> }
    */
    uploadDynamicCertificateZip(data):Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/dynamic-certificates/upload-zip`), data);
    }
}