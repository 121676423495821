import { environment } from '../../environments/environment';
export { environment } from '../../environments/environment';

// get ApiURL
export function GetApiUrl(path: string, params?: Object) {

  let url = environment.apiHost + environment.apiBasePath + path;
  if (params instanceof Object) {
    url += '?';

    Object.entries(params).forEach(([param, value]) => {
      url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
    })
  }

  return url;
}
