import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CsVersionService } from '@app/services/utility-services/cs-version.service';
import { InterfaceService } from '@app/interfaces';
import { MicroserviceHelperService } from '@app/shared/microservice/helper/microservice-helper.service';
import { CommonDataService } from "@app/services/http-services/common-data.service";
var SettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsService, _super);
    function SettingsService(http, microserviceHelperService, versionService, commonService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.microserviceHelperService = microserviceHelperService;
        _this.versionService = versionService;
        _this.commonService = commonService;
        _this.userAccessDetailsSource = new BehaviorSubject(null);
        _this.userAccessDetails$ = _this.userAccessDetailsSource.asObservable();
        _this.modulePermissionsSource = new BehaviorSubject(null);
        _this.modulePermissions$ = _this.modulePermissionsSource.asObservable();
        _this.savedColorsData = new BehaviorSubject(null);
        _this.savedColorsData$ = _this.savedColorsData.asObservable();
        return _this;
    }
    SettingsService.prototype.dataFromA1App = function (appData) {
        this._csVersion = appData['CS_VERSION'];
        this._csLanguage = appData['CS_LANGUAGE'];
        this._csIntlSettings = appData['INTL_SETTINGS'];
        this.setModulePermissions(appData['MODULE_PERMISSIONS']);
        this.setAccessDetails(appData['ALL_PERMISSIONS']);
        this._csCountryCode = appData['COUNTRY_CODE'];
        this.updateCSVersion();
        this.updateSavedColors();
    };
    SettingsService.prototype.updateSavedColors = function () {
        if (this._allPermissions['accessDetails'] && this._allPermissions['accessDetails'].hasOwnProperty('clientsconfig')) {
            this.commonService.fetchSavedColorsData();
        }
    };
    SettingsService.prototype.updateCSVersion = function () {
        this.versionService.updateCSVersion(this._csVersion);
    };
    SettingsService.prototype.loadAppData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.fetchData().subscribe(function (appData) {
                _this._csVersion = appData['CS_VERSION'];
                _this._csLanguage = appData['CS_LANGUAGE'];
                _this._csIntlSettings = appData['INTL_SETTINGS'];
                _this.setModulePermissions(appData['MODULE_PERMISSIONS']);
                _this.setAccessDetails(appData['ALL_PERMISSIONS']);
                _this._csCountryCode = appData['COUNTRY_CODE'];
                _this.JWT_TOKEN = appData['JWT_TOKEN'];
                _this.ordinals = appData['ORDINALS'];
                var responseHeaders = {};
                appData.responseHeaders.keys().forEach(function (key) {
                    responseHeaders[key] = appData.responseHeaders.get(key);
                });
                _this.csVersionHeaders = responseHeaders;
                _this.updateCSVersion();
                _this.updateSavedColors();
                setTimeout(function () { return resolve(true); }, 50);
                if (appData['V5_WEB_TOKEN']) {
                    _this.microserviceHelperService.setToken(appData['V5_WEB_TOKEN']);
                }
            }, function (Error) {
                reject(false);
            });
            _this.http.get("./javascripts/countries.json").subscribe(function (data) {
                _this.countriesJSONData = data;
            });
        });
    };
    SettingsService.prototype.setAccessDetails = function (data) {
        this._allPermissions = data;
        this.userAccessDetailsSource.next(this._allPermissions['accessDetails'] || null);
    };
    SettingsService.prototype.isAuthenticated = function () {
        var accessDetails = this._allPermissions['accessDetails'];
        if (typeof accessDetails == 'object') {
            return Object.keys(accessDetails).length > 0;
        }
        return false;
    };
    SettingsService.prototype.setModulePermissions = function (data) {
        this._modulePermissions = data;
        this.modulePermissionsSource.next(this._modulePermissions || null);
    };
    SettingsService.prototype.getCountryCode = function () {
        return this._csCountryCode;
    };
    SettingsService.prototype.getCsVersion = function () {
        return this._csVersion;
    };
    SettingsService.prototype.getCsLanguage = function () {
        return this._csLanguage;
    };
    SettingsService.prototype.getModulePermissions = function () {
        return this._modulePermissions;
    };
    SettingsService.prototype.getAllPermissions = function () {
        return this._allPermissions;
    };
    SettingsService.prototype.getCountryMasterData = function () {
        return this.countriesJSONData;
    };
    SettingsService.prototype.getJWTTOKENData = function () {
        return this.microserviceHelperService.getToken();
    };
    SettingsService.prototype.getIntlSettings = function (refresh) {
        if (refresh === void 0) { refresh = false; }
        var promise = this._csIntlSettings;
        if (refresh === true) {
            var that_1 = this;
            promise = new Promise(function (resolve) {
                that_1.http.get(that_1.getApiUrl('get_intl'))
                    .subscribe(function (result) {
                    that_1._csIntlSettings = result;
                    resolve(result);
                });
            });
        }
        return promise;
    };
    // Fetch initial data from backend
    SettingsService.prototype.fetchData = function () {
        return this.http.get(this.getApiUrl('get_user_config_data?from=7'), { observe: 'response' }).pipe(map(function (Response) {
            return {
                ALL_PERMISSIONS: Response.body['accessDetailsResp'],
                MODULE_PERMISSIONS: Response.body['moduleDetails'],
                CS_VERSION: Response.body['csVersion'],
                CS_LANGUAGE: Response.body['clientLanguage'],
                INTL_SETTINGS: Response.body['intlSettings'],
                responseHeaders: Response.headers,
                COUNTRY_CODE: Response.body['clientCountryCode'],
                V5_WEB_TOKEN: Response.body.hasOwnProperty('v5Token') ? Response.body['v5Token'] : null,
                ORDINALS: Response.body['ordinalJson']
            };
        }));
        /*return forkJoin(
            this.http.get(this.getApiUrl('get_access_details'), { observe: 'response' }),
            this.http.get(this.getApiUrl('module_permission_check'), { observe: 'response' }),
            this.http.get(this.getApiUrl('get_version'), { observe: 'response' }),
            this.http.get(this.getApiUrl('get_client_language'), { observe: 'response',responseType : "text" }),
            this.http.get(this.getApiUrl('get_intl'), { observe: 'response'}),
            this.http.get(this.getApiUrl('get_client_country_code'), { observe: 'response'}),
        ).pipe(
            map(Response => {
                return {
                    ALL_PERMISSIONS: Response[0].body,
                    MODULE_PERMISSIONS: Response[1].body,
                    CS_VERSION: Response[2].body,
                    CS_LANGUAGE: Response[3].body,
                    INTL_SETTINGS: Response[4].body,
                    responseHeaders: Response[4].headers,
                    COUNTRY_CODE: Response[5].body,
                };
            })
        );*/
    };
    SettingsService.prototype.getRegionalDateFormats = function () {
        return {
            "0": 'DD/MM/YY',
            "1": 'DD/MM/YYYY',
            "2": 'DD/MMM/YY',
            "3": 'DD/MMM/YYYY',
            "4": 'DD-MM-YY',
            "5": 'DD-MM-YYYY',
            "6": 'DD-MMM-YY',
            "7": 'DD-MMM-YYYY',
            "50": 'MM/DD/YY',
            "51": 'MM/DD/YYYY',
            "52": 'MMM/DD/YY',
            "53": 'MMM/DD/YYYY',
            "54": 'MM-DD-YY',
            "55": 'MM-DD-YYYY',
            "56": 'MMM-DD-YY',
            "57": 'MMM-DD-YYYY'
        };
    };
    return SettingsService;
}(InterfaceService));
export { SettingsService };
