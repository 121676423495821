import {ElementRef, forwardRef, Inject, Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '@app/core';

@Injectable({
    providedIn: 'root'
})
export class CountriesService {
    private countries: any;
    constructor(
        @Inject(forwardRef(() => SettingsService)) private settings
    ) {
        this.countries = this.settings.countriesJSONData;
    }
    numberTypes: any;

    setNumberTypes(numberTypes) {
        this.numberTypes = numberTypes;
    }

    getNumberTypes() {
        return this.numberTypes;
    }

    getCountries() {
        return this.countries;
    }

    getCountryById(id) {
        for (let i = 0, l = this.countries.length; i < l; i++) {
            if (this.countries[i].id === id) {
                return this.countries[i];
            }
        }
    }

    getCountryByCode(code) {
        if(this.countries[code]['data'].length>0){
            return this.countries[code]['data'];
        }
    }
}
