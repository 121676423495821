import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ICsListViewLayoutParams, ICsPagingParams} from '@app/shared/cs-components/cs-components.interface';
import {GridApi} from 'ag-grid-community';
import {CsAgGridService} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.service";
import {ICsAgGridAutoHeightParams} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.interface";

@Component({
    selector: 'app-cs-list-view-layout',
    templateUrl: './cs-list-view-layout.component.html',
    styleUrls: ['cs-list-view-layout.component.scss'],
    providers: [CsAgGridService]
})
export class CsListViewLayoutComponent implements OnInit {

    @ViewChild('gridHolder') gridHolder: ElementRef;
    @ViewChild('gridWrapper') gridWrapper: ElementRef;
    @ViewChild('grid') grid: ElementRef;

    @Output() onLayoutReady = new EventEmitter<ICsListViewLayoutParams>();
    @Output() onPagingUpdate = new EventEmitter<ICsListViewLayoutParams>();

    @Input() isDisplayPanel = false;
    @Input() isHeaderAction = false;
    @Input() totalRecordCount: number;
    @Input() maxPagesDisplayLength = 5;
    @Input() csListViewParams: ICsListViewLayoutParams;

    gridAutoHeightParams: ICsAgGridAutoHeightParams;

    constructor(private _csAgGrid: CsAgGridService) {
    }

    ngOnInit() {
        this.setCsListViewParams();
        this.prepareGridAutoHeightParams();
        this.afterLayoutReady();
    }

    setCsListViewParams() {
        this.csListViewParams.pageSize = this.csListViewParams.pageSize || 5;
        this.csListViewParams.currentPage = this.csListViewParams.currentPage || 1;
        this.csListViewParams.setGridAutoHeight = this.setGridAutoHeight;
        this.csListViewParams.initGrid = this.initGrid;
    }

    prepareGridAutoHeightParams() {
        this.gridAutoHeightParams = {
            gridHolder: this.gridHolder,
            gridWrapper: this.gridWrapper,
            grid: this.grid,
            gridApi: this.csListViewParams.gridApi,
            gridLayout: 'normal',
            detailPanelsHeight: 0,
            pageSize: this.csListViewParams.pageSize
        };
    }

    afterLayoutReady() {
        this.onLayoutReady.emit(this.csListViewParams);
    }

    initGrid = (gridApi: GridApi) => {
        this.csListViewParams.gridApi = gridApi;
        this.gridAutoHeightParams.gridApi = gridApi;
        this._csAgGrid.renderGridSSData(this.csListViewParams);
    }

    updatePagingParams(params: ICsPagingParams) {
        Object.entries(params).forEach(([prop, val]) => {
            this.csListViewParams[prop] = val;
        });

        this.onPagingUpdate.emit(this.csListViewParams);

        this.gridAutoHeightParams.pageSize = this.csListViewParams.pageSize;

        if (this.csListViewParams.currentEvent && this.csListViewParams.currentEvent !== 'none') {
            this.updateGridData();
        }
    }

    updateGridData() {
        if (this.csListViewParams.gridType === 'ag-grid') {
            this._csAgGrid.updateGridPagingData(this.csListViewParams);
        }
    }

    setGridAutoHeight = (detailPanelsHeight = 0) => {
        if (this.csListViewParams.gridType === 'ag-grid') {
            this._csAgGrid.setGridAutoHeight(this.gridAutoHeightParams, detailPanelsHeight);
        }
    }
}
