<div class="pagination-wrapper">
    <div class="pagination-panel">
        <div class="data-info">Showing {{pagingParams?.startRow}} to {{pagingParams?.endRow}} of {{_totalRecord}} entries</div>
        <div class="controls">
            <div class="page-size">
                <div>Show</div>
                <div class="btn-group page-size-dropdown-wrapper" dropdown>
                    <button dropdownToggle
                            type="button"
                            class="btn dropdown-toggle">
                        <span>{{pagingParams?.pageSize}}</span>
                        <span class="caret"></span>
                    </button>

                    <ul *dropdownMenu class="dropdown-menu">
                        <li *ngFor="let offset of pageOffsetList"
                            [ngClass]="{'disabled': (offset === pagingParams?.pageSize)}"
                            (click)="(offset !== pagingParams?.pageSize) ? setPageSize(offset) : false">
                            <span>{{offset}}</span>
                        </li>
                    </ul>
                </div>
                <div>entries</div>
            </div>

            <div class="btn-group control-navigators">
                <div class="control btn btn-link first"
                     [ngClass]="{'disabled': pagingParams.currentPage === 1}"
                     (click)="pagingParams.currentPage > 1 ? setCurrentPage(1) : false">
                    <span>First</span>
                </div>

                <div class="control btn btn-link previous"
                     [ngClass]="{'disabled': pagingParams.currentPage === 1}"
                     (click)="pagingParams.currentPage > 1 ? setCurrentPage(pagingParams.currentPage - 1) : false">
                    <span>Previous</span>
                </div>

                <ng-container *ngFor="let page of pageList">
                    <ng-container *ngIf="page > 1 && page < pageList.length; else pageNumber">
                        <ng-container *ngIf="pagingParams.currentPage >= maxPagesDisplayLength; else clipEndPages">
                            <ng-container *ngIf="(pageList.length - pagingParams.currentPage + 1) >= maxPagesDisplayLength; else clipStartPages">
                                <ng-container *ngIf="page >= (pagingParams.currentPage - maxPagesDisplayLength + 3) && page <= (pagingParams.currentPage + 2)">
                                    <div class="control btn btn-link page disabled" *ngIf="page === (pagingParams.currentPage - maxPagesDisplayLength + 3) || page === (pagingParams.currentPage + 2); else pageNumber">
                                        <span>...</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-template #clipEndPages>
                        <ng-container *ngIf="page > maxPagesDisplayLength; else pageNumber">
                            <div class="control btn btn-link page disabled" *ngIf="page === maxPagesDisplayLength + 1">
                                <span>...</span>
                            </div>
                        </ng-container>
                    </ng-template>

                    <ng-template #clipStartPages>
                        <ng-container *ngIf="page <= (pageList.length - maxPagesDisplayLength); else pageNumber">
                            <div class="control btn btn-link page disabled" *ngIf="page === (pageList.length - maxPagesDisplayLength)">
                                <span>...</span>
                            </div>
                        </ng-container>
                    </ng-template>

                    <ng-template #pageNumber>
                        <div class="control btn btn-link page"
                             [ngClass]="{'disabled active': pagingParams.currentPage === page}"
                             (click)="page !== pagingParams.currentPage ? setCurrentPage(page) : false">
                            <span>{{page}}</span>
                        </div>
                    </ng-template>
                </ng-container>

                <div class="control btn btn-link next"
                     [ngClass]="{'disabled': pagingParams.currentPage === pageList.length}"
                     (click)="pagingParams.currentPage < pageList.length ? setCurrentPage(pagingParams.currentPage + 1) : false">
                    <span>Next</span>
                </div>

                <div class="control btn btn-link last"
                     [ngClass]="{'disabled': pagingParams.currentPage === pageList.length}"
                     (click)="pagingParams.currentPage < pageList.length ? setCurrentPage(pageList.length) : false">
                    <span>Last</span>
                </div>
            </div>
        </div>
    </div>
</div>
