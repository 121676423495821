/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-palatte.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../node_modules/ng-color/ng-color.ngfactory";
import * as i5 from "ng-color";
import * as i6 from "@angular/forms";
import * as i7 from "./color-palatte.component";
import * as i8 from "../../services/http-services/common-data.service";
import * as i9 from "@angular/router";
import * as i10 from "../../core/auth/auth.service";
var styles_ColorPalatteComponent = [i0.styles];
var RenderType_ColorPalatteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPalatteComponent, data: {} });
export { RenderType_ColorPalatteComponent as RenderType_ColorPalatteComponent };
function View_ColorPalatteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], null, null); }
function View_ColorPalatteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], null, null); }
function View_ColorPalatteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "saved"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "cursor-pointer"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayContents($event, "savedColors") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["saved.colors"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cursor-pointer"; var currVal_1 = (_co.savedColors ? "font-style" : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.savedColors; _ck(_v, 7, 0, currVal_3); }, null); }
function View_ColorPalatteComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "recent-colorrecent-colors-titles-help-text"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["recently.used.colors"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ColorPalatteComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "color-list"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recentColor(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_ColorPalatteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "canvas"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "ng-color-box", [["class", "main-canvas"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.color = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.colorChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ɵm_0, i4.RenderType_ɵm)), i1.ɵdid(3, 114688, null, 0, i5.ɵm, [i5.ɵd], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.ɵm]), i1.ɵdid(5, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(7, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 20, "div", [["class", "cp-preset-colors-class"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 12, "div", [["class", "details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "eye-drop"], ["id", "start-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.eyeDropperOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "inside-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-eye-dropper"], ["id", "i-dropper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "input", [["class", "color-value"], ["type", "text"]], [[8, "value", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        _co.colorInputValue($event);
        var pd_0 = (_co.checkInputValue($event, "input") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(15, { "border": 0 }), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "test"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "input", [["autocomplete", "off"], ["class", "alpha"], ["max", "100"], ["min", "0"], ["type", "number"]], [[8, "value", 0]], [[null, "keydown"], [null, "keyup"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.preventDecimal($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.alphaValue($event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.alphaValue($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(19, { "border": 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" % "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "recent-colors-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recently used:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_5)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [["class", "recent-color-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_6)), i1.ɵdid(28, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 2, "div", [["class", "apply-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "apply"]], null, [[null, "keydown.enter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeModal($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_7 = _co.color; _ck(_v, 5, 0, currVal_7); var currVal_9 = _ck(_v, 15, 0, ("1px solid" + _co.colorDisplay)); _ck(_v, 14, 0, currVal_9); var currVal_11 = _ck(_v, 19, 0, ("1px solid" + _co.colorDisplay)); _ck(_v, 18, 0, currVal_11); var currVal_12 = (_co.recentColors.length == 0); _ck(_v, 25, 0, currVal_12); var currVal_13 = _co.recentColors; _ck(_v, 28, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.colorDisplay; _ck(_v, 13, 0, currVal_8); var currVal_10 = _co.percentage; _ck(_v, 17, 0, currVal_10); }); }
function View_ColorPalatteComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "cross-icon"], ["fill", "none"], ["height", "16"], ["viewBox", "0 0 16 16"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z"], ["fill", "#8E8E8E"]], null, null, null, null, null))], null, null); }
function View_ColorPalatteComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-display"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "text-layout animated-background small line-1 loader-user loader-main"], ["style", "margin-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "text-layout animated-background small line-1 loader-line"], ["style", "margin-top: 10px"]], null, null, null, null, null))], null, null); }
function View_ColorPalatteComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "text-layout animated-background small div1 loading-line-one"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ColorPalatteComponent_10)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(4, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, 0, 1); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ColorPalatteComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "data-content-loop-section"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "saved-color-display-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "saved-clr-color-list saved-colors-list"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.colorPicker(_v.context.$implicit.color, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "saved-clr-hash-clr-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "color-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.colorPicker(_v.context.$implicit.color, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", " - ", ""]))], function (_ck, _v) { var currVal_0 = "data-content-loop-section"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.index == 0) ? "first-element-add-space" : "next-element-remove-space")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.color; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; var currVal_4 = _v.context.$implicit.color; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
function View_ColorPalatteComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "loop-each-objetc-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "line-and-text-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "saved-clr-live"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "saved-clr-collection-font"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_13)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.collectionName; _ck(_v, 4, 0, currVal_0); }); }
function View_ColorPalatteComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "saved-color-collection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_12)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColorPalatteComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "data-content-loop-section"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "saved-color-display-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "saved-clr-color-list saved-colors-list"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.colorPicker(_v.context.$implicit.color, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "saved-clr-hash-clr-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "color-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.colorPicker(_v.context.$implicit.color, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", " - ", ""]))], function (_ck, _v) { var currVal_0 = "data-content-loop-section"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.index == 0) ? "first-element-add-space" : "next-element-remove-space")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.color; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; var currVal_4 = _v.context.$implicit.color; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
function View_ColorPalatteComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "loop-each-objetc-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "line-and-text-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "saved-clr-live"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "saved-clr-collection-font"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_16)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.collectionName; _ck(_v, 4, 0, currVal_0); }); }
function View_ColorPalatteComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "saved-color-collection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_15)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchResult; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColorPalatteComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "help-text-style"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no search results"]))], null, null); }
function View_ColorPalatteComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "help-text-style"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["no.color.saved"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ColorPalatteComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "display-help-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.routeToSettingsScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Add color for future use "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "subtract"], ["src", "images/Subtract.png"]], null, null, null, null, null))], null, null); }
function View_ColorPalatteComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-color-display"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_18)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_19)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_20)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userSearchNotFound; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.savedColorLoading && (_co.collection.length == 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((!_co.savedColorLoading && (_co.collection.length == 0)) && _co.savedColourAccess); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ColorPalatteComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "saved-colors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "seach-content-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "search"], ["placeholder", "Search saved colours"]], [[8, "value", 0]], [[null, "keyup"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.searchValue($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.onFocus($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "saved-color-display"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_11)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_14)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_17)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCloseIcon; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.savedColorLoading; _ck(_v, 8, 0, currVal_2); var currVal_3 = (!_co.searchValues && !_co.savedColorLoading); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.searchResult.length; _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.userSearchNotFound || (_co.collection.length == 0)); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchColor; _ck(_v, 3, 0, currVal_0); }); }
export function View_ColorPalatteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "modal page-properties-background-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "colour-palatte-content-height set-modal-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "header"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "palatte"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "cursor-pointer"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayContents($event, "colorPalatte") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(8, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Color.palatte"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "contents"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_4)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPalatteComponent_7)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header"; var currVal_1 = ((!_co.savedColourAccess && (_co.collection.length == 0)) ? "no-border" : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "cursor-pointer"; var currVal_3 = (_co.colorPalatte ? "font-style" : ""); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = ""; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.colorPalatte; _ck(_v, 11, 0, currVal_5); var currVal_6 = ((_co.collection.length > 0) || _co.savedColourAccess); _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.colorPalatte; _ck(_v, 16, 0, currVal_7); var currVal_8 = _co.savedColors; _ck(_v, 18, 0, currVal_8); }, null); }
export function View_ColorPalatteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "color-palatte", [], null, [["document", "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.enter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentKeydownEnter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ColorPalatteComponent_0, RenderType_ColorPalatteComponent)), i1.ɵdid(1, 770048, null, 0, i7.ColorPalatteComponent, [i8.CommonDataService, i9.Router, i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorPalatteComponentNgFactory = i1.ɵccf("color-palatte", i7.ColorPalatteComponent, View_ColorPalatteComponent_Host_0, { data: "data", myValue: "myValue" }, { colorValue: "colorValue" }, []);
export { ColorPalatteComponentNgFactory as ColorPalatteComponentNgFactory };
