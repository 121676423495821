import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//--
import { InterfaceService } from '@app/interfaces';
import { JobsSla } from '@app/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JobsSlaService = /** @class */ (function (_super) {
    tslib_1.__extends(JobsSlaService, _super);
    function JobsSlaService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // fetching existing jobs sla
    JobsSlaService.prototype.fetchJobsSla = function (param) {
        return this.http
            .get(this.getApiUrl('system_settings/jobs-sla', param))
            .pipe(map(function (Response) { return Response; }));
    };
    // Add new contract template
    JobsSlaService.prototype.addJobsSla = function (data) {
        return this.http
            .post(this.getApiUrl('jobs-sla/add-new'), JSON.stringify(data))
            .pipe(map(function (Response) { return new JobsSla(Response); }));
    };
    // Update jobs sla
    JobsSlaService.prototype.editJobsSla = function (id, data) {
        return this.http
            .put(this.getApiUrl("jobs-sla/" + id + "/edit"), JSON.stringify(data))
            .pipe(map(function (Response) { return new JobsSla(Response); }));
    };
    // Checking delete option for jobs sla
    JobsSlaService.prototype.isDeleteJobsSla = function (id) {
        return this.http
            .get(this.getApiUrl("jobs-sla/" + id + "/is-sla-delete"))
            .pipe(map(function (Response) { return Response; }));
    };
    // Delete jobs sla
    JobsSlaService.prototype.deleteJobsSla = function (id) {
        return this.http
            .delete(this.getApiUrl("jobs-sla/" + id + "/delete"))
            .pipe(map(function (Response) { return new JobsSla(Response); }));
    };
    // Get data for contract template basic details configuration form
    JobsSlaService.prototype.getSetupSlaConfigureDetails = function (templateId) {
        return this.http.get(this.getApiUrl("sla-setup/" + templateId + "/configure"))
            .pipe(map(function (Response) {
            Response['settingsJobsSLA'] = new JobsSla(Response['settingsJobsSLA']);
            return Response;
        }));
    };
    JobsSlaService.prototype.addSLAConfigure = function (id, cofigureType, data) {
        return this.http
            .post(this.getApiUrl("jobs-sla/" + id + "/add-configure-new/" + cofigureType), JSON.stringify(data))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.prototype.editSLAConfigure = function (id, cofigureType, configureSlaId, data) {
        return this.http
            .put(this.getApiUrl("sla-setup/" + id + "/configure/" + cofigureType + "/edit/" + configureSlaId), JSON.stringify(data))
            .pipe(map(function (Response) { return new JobsSla(Response); }));
    };
    JobsSlaService.prototype.slaConfiguredetails = function (id, cofigureType) {
        return this.http.get(this.getApiUrl("sla-setup/" + id + "/configure/" + cofigureType))
            .pipe(map(function (Response) {
            Response['slaConfiguredetails'] = new JobsSla(Response['slaConfiguredetails']);
            return Response;
        }));
    };
    JobsSlaService.prototype.slaConfiguredetailsView = function (id, cofigureType, configureSlaId) {
        return this.http.get(this.getApiUrl("sla-setup/" + id + "/configure/" + cofigureType + "/" + configureSlaId))
            .pipe(map(function (Response) {
            Response['slaConfiguredetails'] = new JobsSla(Response['slaConfiguredetails']);
            return Response;
        }));
    };
    // Check duplicate name or not
    JobsSlaService.prototype.CheckSLADuplicateName = function (name, id) {
        return this.http.get(this.getApiUrl("sla/check-sla-name", { name: name, id: id }));
    };
    JobsSlaService.prototype.deleteSLAConfigure = function (id, cofigureType, action, data) {
        return this.http
            .post(this.getApiUrl("jobs-sla/" + id + "/delete-configure/" + cofigureType + "/" + action), JSON.stringify(data))
            .pipe(map(function (Response) { return new JobsSla(Response); }));
    };
    JobsSlaService.prototype.isDeleteJobsSlaMetric = function (slaId, configureType, id, action) {
        return this.http
            .get(this.getApiUrl("jobs-sla/" + slaId + "/" + configureType + "/" + id + "/is-sla-metric/" + action))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.prototype.addDefaultSLAEmailTemplate = function (Data) {
        return this.http.post(this.getApiUrl('system_settings/sla_email_template/add-data'), JSON.stringify(Data));
    };
    JobsSlaService.prototype.getDetailsRoute = function (id) {
        return this.http.get(this.getApiUrl("system_settings/sla_email_template/" + id + "/edit"))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.prototype.editDefaultSLAEmailTemplate = function (id, Data) {
        return this.http.post(this.getApiUrl("system_settings/sla_email_template/" + id + "/edit-details"), JSON.stringify(Data));
    };
    JobsSlaService.prototype.cloneSLAConfigure = function (id, cofigureType, data) {
        return this.http
            .post(this.getApiUrl("jobs-sla/" + id + "/clone-configuration/" + cofigureType), JSON.stringify(data))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.prototype.configureSLABusinessHours = function (slaId, data) {
        return this.http
            .put(this.getApiUrl("sla-setup/" + slaId + "/configureBusinessHours"), JSON.stringify(data))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.prototype.getSLABusinessHoursSetup = function (slaId) {
        return this.http.get(this.getApiUrl("sla-setup/" + slaId + "/businessHours"))
            .pipe(map(function (Response) { return Response; }));
    };
    JobsSlaService.ngInjectableDef = i0.defineInjectable({ factory: function JobsSlaService_Factory() { return new JobsSlaService(i0.inject(i1.HttpClient)); }, token: JobsSlaService, providedIn: "root" });
    return JobsSlaService;
}(InterfaceService));
export { JobsSlaService };
