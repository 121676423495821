import { ChangeDetectorRef, Component, OnInit, Inject, AfterViewInit, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { BsModalRef } from "ngx-bootstrap";
import { Subject } from 'rxjs';

declare var $:any;

@Component({
    selector: 'app-block-settings-modal',
    templateUrl: './block-settings-modal.component.html',
    styleUrls: ['./block-settings-modal.scss']
})
export class BlockSettingsModalComponent implements OnInit, AfterViewInit {
    public onClose: Subject<boolean>;
    settingsForm: FormGroup;
    action: string = 'save';
    title: string = '';
    customCss:any = ''
    options = {
        type: "component",
        showPalette: false,
        hideAfterPaletteSelect: true,
        showButtons: false,
        allowEmpty: false,
        preferredFormat: "hex",
        containerClassName: 'container-palette'
    }
    oldCustomCssVal:any = '';
    blockError: any = {
        topMargin:false,
        rightMargin:false,
        bottomMargin:false,
        leftMargin:false
    }
    margin_dir: any = {
        open: false,
        left: '',
        right: '',
        top: '',
        bottom: ''
    }
    restoreEle:any= {};
    id:any;
    margin: any = 0;
    borderColor: any = 'No border';
    modal: boolean;
    coverPage: boolean;
    myValue: any;
    @Input() pageId;
    constructor(
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private proposalTemplateService: ProposalTemplateService,
    ) { }

    ngOnInit() {
        this.onClose = new Subject();
        if(this.pageId){this.id = this.pageId;}
        if(this.id) {
            if($('#cs_editor').contents().find(this.id).attr('data-page-type') == 'EHP'){
                let id = $('#cs_editor').contents().find(this.id).attr('id')
                this.coverPage = true;
                $.fn.inlineStyle = function (prop) {
                    let styles = $(this).attr("style");
                    let value;
                    styles && styles.split(";").forEach(function (e) {
                        let style = e.split(":");
                        if ($.trim(style[0]) === prop) {
                            value = style[1];
                        }
                    });
                    return value;
                };
                this.borderColor = $('#cs_editor').contents().find('#'+ id).inlineStyle("border-color");
                if( this.borderColor && this.borderColor.includes('rgba')){
                    this.borderColor = this.hexCode(this.borderColor);
                } else if (this.borderColor && this.borderColor.includes('rgb')) {
                    this.borderColor = this.rgbToHex(this.borderColor)
                } else{
                    this.borderColor = "No border"
                }
                this.restoreEle.borderColor = this.borderColor;
            } else {
                this.coverPage = false;
                let existData = $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('page-property');
                if(existData && typeof existData !== 'undefined'){
                    existData = JSON.parse(existData);
                    this.margin_dir = existData.margin_dir
                    this.customCss = existData.customCss
                    this.borderColor = existData.borderColor
                    this.margin = existData.margin;
                } else {
                    this.margin = parseInt($('#cs_editor').contents().find(this.id).find('.cs_margin').css('margin'))
                }
                this.restoreEle.margin_dir = JSON.parse(JSON.stringify(this.margin_dir));
                this.restoreEle.customCss = this.customCss;
                this.restoreEle.borderColor = this.borderColor;
                this.restoreEle.margin = $('#cs_editor').contents().find(this.id).find('.cs_margin').css('margin');
                $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('old-property',JSON.stringify(this.restoreEle));
            }
        }
    }

    ngAfterViewInit() {
        var that = this;
        $.fn.inlineStyle = function (prop) {
            var styles = this.attr("style"),
                value;
            styles && styles.split(";").forEach(function (e) {
                var style = e.split(":");
                if ($.trim(style[0]) === prop) {
                    value = style[1];
                }
            });
            return value;
        };
    }
    rgbaToHex(r, g, b, a) {
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];

        // Pad single-digit output values
        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        })
        return ('#' + outParts.join(''));
    }

    refreshSpectrum(id){
        for (let index = 0; index < 4; index++) {
            $(id).trigger('change')
        }
    }

    hexCode(color){
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;

        return hex;
    }

    toggle(option) {
        switch (option) {
            case 'margin':
                if (this.margin_dir.open) {
                    this.margin_dir.open = false;
                    this.margin = 0;
                    $('#margin-icon').removeClass('selected-style')
                } else {
                    this.margin = 0;
                    var block = $('#cs_editor').contents().find(this.id)
                    block[0].style.removeProperty('margin');
                    $('#margin-icon').addClass('selected-style')
                    this.margin_dir.open = true
                    this.margin_dir.left = 0;
                    this.margin_dir.top = 0;
                    this.margin_dir.right = 0;
                    this.margin_dir.bottom = 0;
                }
            break;
        }
    }

    removeStyling(deleteEle) {
        $('#cs_editor').contents().find(this.id).each(function () {
            let styleEle: any = this;
            deleteEle.forEach(val => {
                styleEle.style.removeProperty(`${val}`);
            });
        });
    }
    setMargin(value, key) {
        if(value >=0 ){
            let that = this;
            let deleteEle = [];
            $('#cs_editor').contents().find(this.id).each(function () {
                let csMargin = $(this).find('.cs_margin');
                if (csMargin.length > 0) {
                    let marginElement = csMargin[0];
                    if (key != 'padding') {
                        marginElement.style.setProperty([key], `${value}px`, 'important');
                        marginElement.style.setProperty('--allsidemargin', `0`);
                    } else {
                        deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                        that.removeStyling(deleteEle);
                        that.margin_dir.left = '';
                        that.margin_dir.right = '';
                        that.margin_dir.bottom = '';
                        that.margin_dir.top = '';
                        marginElement.style.setProperty([key], `${value}px`, 'important');
                    }
                }
                if (value == null) {
                    deleteEle = [key]
                    that.removeStyling(deleteEle);
                }
            });
            let editorRef = $('#cs_editor').get(0);
            editorRef.contentWindow.postMessage({type: 'showmarkers', element: this.id}, '*');
        }
        else if (value < 0){
            if(key == "margin-top") {
                this.margin_dir.top = 0;
            } else if (key == "margin-right") {
                this.margin_dir.right = 0;
            } else if (key == "margin-left") {
                this.margin_dir.left = 0;
            } else if (key == "margin-bottom") {
                this.margin_dir.bottom = 0;
            }
        }
    }

    // Getting control values for validation check
	get f() {
		return this.settingsForm.controls;
    }

    validValue(value, control) {
        let pxExp = /([0-9]+px)/gm;
        let numExp = /^\d*$/gm;
        if (numExp.test(value) || /^([0-9]+?%)$/gm.test(value) || pxExp.test(value)) {
            this.blockError[control] = false
        } else if(value == '' || value == null) {
            this.blockError[control] = false
        } else {
            this.blockError[control] = true
        }
    }
    validateFormfunc() {
        let arrs = Object.values(this.blockError)
        return !(arrs.every( (val, i, arr) => val === false))
    }

    save(setAllPageValue) {
        $('#cs_editor').contents().find(this.id).addClass(this.customCss);
        $('#cs_editor').contents().find(this.id).removeClass(this.oldCustomCssVal);
        let data:any = {
            'margin_dir': this.margin_dir,
            'customCss': this.customCss,
            'borderColor': this.borderColor,
            'margin': this.margin,
            'setAllPage' : setAllPageValue,
        }
        data = JSON.stringify(data)
        if (setAllPageValue){
            const cs_page_Element = $("#cs_editor").contents().find('.cs_page').find('.cs_margin').get();
            $(cs_page_Element).attr('page-property',data);
            $(cs_page_Element).removeAttr('old-property');
        }else{
            $('#cs_editor').contents().find(this.id).find('.cs_margin').attr('page-property',data)
            $('#cs_editor').contents().find(this.id).find('.cs_margin').removeAttr('old-property')
        }
        let editorRef = $('#cs_editor').get(0);
        editorRef.contentWindow.postMessage({type: 'removeMarkers', element: this.id}, '*');
        this.proposalTemplateService.savePages(false);
    }
    // convert rgb to hex
    componentFromStr(numStr, percent) {
        var num = Math.max(0, parseInt(numStr, 10));
        return percent ?
            Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
    }

    rgbToHex(rgb) {
        rgb = rgb.trim()
        var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
        var result, r, g, b, hex = "";
        if ((result = rgbRegex.exec(rgb))) {
            r = this.componentFromStr(result[1], result[2]);
            g = this.componentFromStr(result[3], result[4]);
            b = this.componentFromStr(result[5], result[6]);

            hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
        return hex;
    }
    cancel(){
        $('#block-border-color').spectrum('destroy');
        let page = $('#cs_editor').contents().find(this.id)[0];
        let block = $('#cs_editor').contents().find(this.id).find('.cs_margin');
        if(block.length > 0 && this.coverPage == false){
            let marginElement = block[0];
            if(this.restoreEle.margin_dir.open == true){
                let deleteEle = ['margin'];
                this.removeStyling(deleteEle);
                marginElement.style.setProperty('--allsidemargin', `0`);
                marginElement.style.setProperty('margin-left',this.restoreEle.margin_dir.left+'px');
                marginElement.style.setProperty('margin-right',this.restoreEle.margin_dir.right+'px');
                marginElement.style.setProperty('margin-bottom',this.restoreEle.margin_dir.bottom+'px');
                marginElement.style.setProperty('margin-top',this.restoreEle.margin_dir.top+'px');
            }
            else{
                let deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                this.removeStyling(deleteEle);
                marginElement.style.setProperty('margin',this.restoreEle.margin);
            }
            block.css({ 'border-color': this.restoreEle.borderColor });
            $('#cs_editor').contents().find(this.id).find('.cs_margin').removeAttr('old-property')
        }
        this.borderColor = this.restoreEle.borderColor;
        if(this.borderColor == 'No border'){
            page.style.removeProperty('border-color');
        }else{
            page.style.setProperty('border-color', this.borderColor);
        }
        let editorRef = $('#cs_editor').get(0);
        editorRef.contentWindow.postMessage({type: 'removeMarkers', element: this.id}, '*');
        // this.bsModalRef.hide();
    }
    getColor(data){
        const selectedPage = $('#cs_editor').contents().find(this.id)
        selectedPage.css({ 'border-color': data })
        this.borderColor = data;
    }
    detectChanges(data){
        let that = this;
        this.myValue = ''
        setTimeout(() => {
            that.myValue = data;
        }, 50);
    }

    preventMinus(event) {
        if (event.keyCode == 109 || event.keyCode == 189) {
            event.preventDefault();
        }
    }
}
