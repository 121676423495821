import { AfterContentInit, ElementRef } from '@angular/core';
var AutoFocusDirective = /** @class */ (function () {
    function AutoFocusDirective(el) {
        this.el = el;
    }
    AutoFocusDirective.prototype.ngAfterContentInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.el.nativeElement.focus();
        }, 500);
    };
    return AutoFocusDirective;
}());
export { AutoFocusDirective };
