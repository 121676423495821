import * as tslib_1 from "tslib";
import { TemplateRef, ViewContainerRef } from '@angular/core';
//--
import { InterfaceClass } from '@app/interfaces';
import { AuthService } from '@app/core';
var AccessDirective = /** @class */ (function (_super) {
    tslib_1.__extends(AccessDirective, _super);
    function AccessDirective(templateRef, viewContainer, authService) {
        var _this = _super.call(this) || this;
        _this.templateRef = templateRef;
        _this.viewContainer = viewContainer;
        _this.authService = authService;
        _this.permittedFeatures = [];
        // Hide content by default
        _this.viewContainer.clear();
        return _this;
    }
    AccessDirective.prototype.ngOnInit = function () { };
    Object.defineProperty(AccessDirective.prototype, "access", {
        set: function (permittedFeatures) {
            this.permittedFeatures = permittedFeatures;
            this.bindTemplate();
        },
        enumerable: true,
        configurable: true
    });
    // Add/Remove content from DOM
    AccessDirective.prototype.bindTemplate = function () {
        if (this.authService.permitted(this.permittedFeatures) === true) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else
            this.viewContainer.clear();
    };
    return AccessDirective;
}(InterfaceClass));
export { AccessDirective };
