<ng-container *ngIf="editorStatus == 'config'">
    <div class="modal-header pricing-view-padding">
        <h4 class="modal-title pricing-view-title" translate>Pricing view </h4>
    </div>
    <div class="modal-body scrollbar configure-option">
        <div class="tax-block" *ngIf="vatEnabled">
            <span class="tex-text" translate [translateParams]="{object: taxLabel}">vat</span>
        </div>
        <div class="tax-type-block vat-table-border" *ngIf="vatEnabled">
            <span class="tax-type-text"><span translate [translateParams]="{object: taxLabel}">vat</span> type</span>
            <div class="flex-container row-top-align">
                <div class="flex-item-lefts">
                    <div class="vertical-view-section">
                        <div class="radio-button">
                            <input type="radio" id="tax1" class="background-radiobtn" name="tax" value="withouttax"
                                   (click)="taxStatusToggle('withouttax')" [checked]="taxStatus == 'withouttax'">
                            <span class="radio-text" translate [translateParams]="{object: taxLabel}">excl.vat</span>
                        </div>
                    </div>
                </div>
                <div class="flex-item-right">
                    <div class="horizental-view-section">
                        <div class="radio-button">
                            <input type="radio" id="tax2" class="background-radiobtn" name="tax" value="withtax"
                                   (click)="taxStatusToggle('withtax')" [checked]="taxStatus == 'withtax'">
                            <span class="radio-text" translate [translateParams]="{object: taxLabel}">incl.vat</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tax-type-help-text">
        <span class="help-text">If the <span translate [translateParams]="{object: taxLabel}">vat</span> changes here, it will change for all options. If the
          option contains more
          than one price segment, changing to "Included" also changes the other price segment.</span>
            </div>
        </div>
        <div class="price-breakdown-table-block" [ngClass]="{'hide-price-breakdown-section':isOptionSummary == true }">
            <span class="tex-text">Price breakdown table</span>
        </div>
        <div class="tax-type-block" [ngClass]="{'hide-price-breakdown-section':isOptionSummary == true }">
            <span class="tax-type-text">Show price breakdown table</span>
            <div class="flex-container row-top-align">
                <div class="flex-item-left">
                    <div class="vertical-view-section">
                        <div class="radio-button">
                            <input type="radio" class="background-radiobtn" name="tablebreakdown" value="withtblbreakdown"
                                   (click)="priceTableBreakdown('withtblbreakdown')"
                                   [checked]="showPriceBreakdown == 'withbreakdown' || columnShowHide">
                            <span class="radio-text">With table breakdown</span>
                        </div>
                        <div class="vertical-border">
                            <div class="mr-1 float-rights"
                                 style="padding: 10px 27px 32px;background-color: #f9f9f9;border-radius: 4px;">
                                <div class="vertical-lines-one"></div>
                                <div class="vertical-lines-one"></div>
                                <div class="vertical-lines-one"></div>
                                <div class="vertical-line-half"></div>
                                <div class="vertical-line-half"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-item-right">
                    <div class="horizental-view-section">
                        <div class="radio-button">
                            <input type="radio" class="background-radiobtn" name="tablebreakdown" value="withouttblbreakdown"
                                   (click)="priceTableBreakdown('withouttblbreakdown')"
                                   [checked]="showPriceBreakdown == 'withouttblbreakdown' || !columnShowHide">
                            <span class="radio-text">Without table breakdown</span>
                        </div>
                        <div class="vertical-border">
                            <div class="mr-1 float-rights"
                                 style="padding: 10px 25px 83px 0px;background-color: #f9f9f9;border-radius: 4px;">
                                <div class="vertical-line-half right-top-align"></div>
                                <div class="vertical-line-half"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tax-type-help-text">
        <span class="help-text">How do you want your pricing to be displayed either with table breakdown or without
          table breakdown.</span>
            </div>
            <div class="show-column" *ngIf="columnShowHide">
                <span class="show-column-text">Show column</span>
                <div class="column-attributes margin-bottom-space d-flex">
                    <div class="cust-checkbox-section w-100 d-flex">
                        <div class="cust-checkbox-sec d-flex w-25" *ngFor="let tableHeader of tableHeaders;let index = index">
                            <input type="checkbox" class="checkbox-alignment" name="tableHeaders" value="{{tableHeader.value}}"
                                   [checked]="tableHeader.checked" [(ngModel)]="tableHeader.checked" [disabled]="checkedTableColumnCount < 2 && tableHeader.checked"
                                   (change)="visibleColumnChange($event,index)">
                            <div class="check-box-name">
                                <span class="radio-text"> {{tableHeader.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-total-block">
            <span class="tex-text">Displaying total</span>
        </div>
        <div class="tax-type-block" style=" padding-bottom: 50px;">
            <span class="tax-type-text">Show total view</span>
            <div class="alignment-block margin-total-align">
                <div class="flex-container">
                    <div class="flex-item-right">
                        <div class="horizental-view-section">
                            <div class="radio-button">
                                <input type="radio" class="background-radiobtn" name="breakdownview" value="horizontal"
                                       (click)="horizontalVisibilityToggle('horizontal')" [disabled]="isDisabledHorizentalOrVerticalOption || !isFinanceDataAvailable" [checked]="breakdownView == 'horizontal'">
                                <span class="radio-text">Horizontal view</span>
                            </div>
                            <div class="horizental-border-outline">
                                <div class="row">
                                    <div class="col-md-6 horizental-row">
                                        <div class="vertical-horizental-line-block ">
                                            <div class="horizental-line-one-left"></div>
                                            <div class="horizental-line-two-left"></div>
                                            <div class="horizental-line-three-left"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="vertical-horizental-line-block ">
                                            <div class="horizental-line-one-left horizental-line-one-right"></div>
                                            <div class="horizental-line-two-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item-left">
                        <div class="vertical-view-section">
                            <div class="radio-button">
                                <input type="radio" class="background-radiobtn" name="breakdownview" value="vertical"
                                       (click)="horizontalVisibilityToggle('vertical')" [disabled]="isDisabledHorizentalOrVerticalOption" [checked]="breakdownView == 'vertical'">
                                <span class="radio-text">Vertical view</span>
                            </div>
                            <div class="vertical-border-outline">
                                <div class="mr-0 float-right" style="margin-top: 7px;">
                                    <div class="vertical-line-one"></div>
                                    <div class="vertical-line-two"></div>
                                    <div class="vertical-line-three"></div>
                                    <div class="vertical-line-four"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="tax-type-help-text mb-0">
        <span class="help-text">How do you want your total price to be displayed either vertical or horizontal
          view.</span>
            </div>
            <div class="sub-total-block " *ngIf="vatEnabled">
                <span class="tax-type-text">Show sub total and <span translate [translateParams]="{object: taxLabel}">vat</span></span>
                <div class="flex-container row-top-align">
                    <div class="flex-item-lefts">
                        <div class="vertical-view-section">
                            <div class="radio-button">
                                <input type="radio" id="yes" class="background-radiobtn" name="subtotal" value="yes"
                                       (click)="subtotalStatusChange('yes')" [disabled]="taxInclusive" [checked]="subtotalVisible == 'yes'">
                                <span class="radio-text">Yes</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item-right">
                        <div class="horizental-view-section">
                            <div class="radio-button">
                                <input type="radio" id="no" class="background-radiobtn" name="subtotal" value="no"
                                       (click)="subtotalStatusChange('no')" [checked]="subtotalVisible == 'no' || vatType == 'Inc' ">
                                <span class="radio-text">No</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
        <button class="btn btn-primary">
            <span translate (click)="save()">Save</span>
        </button>
    </div>
</ng-container>



<ng-container *ngIf="editorStatus == 'style'">
    <div class="modal-body scrollbar modal-top-aling">
        <div class="advance-styling-section">
            <div class="top-header-title">
                <span class="modal-title pricing-view-title" translate>{{isNormalTable ? 'table.design' : 'pricing.table.design'}}</span>
            </div>

            <div class="title-section each-title-border content-block-top-border">
                <span class="title-text-align">Content block</span>
            </div>

            <div  class="over-all-title-style-block">
                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>border</span>
                </div>
                <div class="each-section-gap">
                    <span>Border width</span>

                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input [disabled]="contentBlockBorderWidthIndividualSide" min="0" maxlength="3"  [(ngModel)]="contentBlockBorderWidth" (input)="sendChangesToEditor()"
                                   type="number" nxNoScroll class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('contentBlockBorderWidthIndividualSide', 'contentBlockBorderWidth')"><i class="fas fa-expand-wide"></i></div>
                        </div>

                        <div class="form-group" *ngIf="contentBlockBorderWidthIndividualSide">
                            <div class="border-radius-all-size-align common-margin-top-16">
                                <div translate class="common-margin-bottom-8">top</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderWidthTop" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border ">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderWidthBottom" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderWidthLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderWidthRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="each-section-gap">
                    <span translate>border.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="contentBlockBorderColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(contentBlockBorderColor,$event,isNormalTable,'contentBlockBorderColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="contentBlockBorderColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="contentBlockBorderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(contentBlockBorderColor,$event,isNormalTable,'contentBlockBorderColor')" [(ngModel)]="contentBlockBorderColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'contentBlockBorderColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="each-section-gap">
                    <span translate>border.radius</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input  [disabled]="contentBlockBorderRadiusIndividualSide" min="0" maxlength="3"
                                    [(ngModel)]="contentBlockBorderRadius" (input)="sendChangesToEditor()" type="number" nxNoScroll
                                    class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('contentBlockBorderRadiusIndividualSide', 'contentBlockBorderRadius')">
                                <i class="fas fa-expand-wide"></i>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="contentBlockBorderRadiusIndividualSide">
                            <div class="border-radius-all-size-align">
                                <div translate class="common-margin-bottom-8 common-margin-top-16" >top.left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderRadiusTopLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">top.right</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderRadiusTopRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom.right</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderRadiusBottomRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom.left</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockBorderRadiusBottomLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="each-section-gap">
                    <span translate>background.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <div class="cross" *ngIf="contentBlockBackgroundColor == 'No background'">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <ng-container *ngIf="contentBlockBackgroundColor !== 'No background'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="contentBlockBackgroundColor"></span>
                            </ng-container>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(contentBlockBackgroundColor,$event,isNormalTable,'')" [(ngModel)]="contentBlockBackgroundColor">
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'contentBlockBackgroundColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>Padding.margin</span>
                </div>
                <div class="each-section-gap">
                    <span translate>Padding</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input  [disabled]="contentBlockPaddingIndividualSide" min="0" maxlength="3"
                                    [(ngModel)]="contentBlockPadding" (input)="sendChangesToEditor()" type="number" nxNoScroll
                                    class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('contentBlockPaddingIndividualSide', 'contentBlockPadding')">
                                <i class="fas fa-expand-wide"></i>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="contentBlockPaddingIndividualSide">
                            <div class="border-radius-all-size-align">
                                <div translate class="common-margin-bottom-8 common-margin-top-16">top</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockPaddingTop" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockPaddingBottom" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockPaddingLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockPaddingRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="each-section-gap">
                    <span translate>Margin</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input  [disabled]="contentBlockMarginIndividualSide" min="0" maxlength="3"
                                    [(ngModel)]="contentBlockMargin" (input)="sendChangesToEditor()" type="number" nxNoScroll
                                    class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('contentBlockMarginIndividualSide', 'contentBlockMargin')">
                                <i class="fas fa-expand-wide"></i>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="contentBlockMarginIndividualSide">
                            <div class="border-radius-all-size-align">
                                <div translate class="common-margin-bottom-8 common-margin-top-16">top</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockMarginTop" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockMarginBottom" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockMarginLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="contentBlockMarginRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="title-section each-title-border">
                <span class="title-text-align" translate>table.border</span>
            </div>
            <div  class="over-all-title-style-block ">
                <div class="each-section-gap mt-0" >
                    <span translate>Border.width</span>

                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input [disabled]="tableBorderWidthIndividualSide" min="0" maxlength="3"  [(ngModel)]="tableBorderWidth" (input)="sendChangesToEditor()"
                                   type="number" nxNoScroll class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('tableBorderWidthIndividualSide', 'tableBorderWidth')"><i class="fas fa-expand-wide"></i></div>
                        </div>

                        <div class="form-group" *ngIf="tableBorderWidthIndividualSide">
                            <div class="border-radius-all-size-align">
                                <div translate class="common-margin-bottom-8 common-margin-top-16">top</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="tableBorderWidthTop" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border ">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="tableBorderWidthBottom" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="tableBorderWidthLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="tableBorderWidthRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>Border.colour</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="tableBorderColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(tableBorderColor,$event,isNormalTable,'tableBorderColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="tableBorderColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="tableBorderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(tableBorderColor,$event,isNormalTable,'tableBorderColor')" [(ngModel)]="tableBorderColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'tableBorderColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>vertical.border</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="tableBorderVerticalBorderWidth" (input)="sendChangesToEditor()" type="number" nxNoScroll class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>vertical.border.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="tableBorderVerticalBorderColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(tableBorderVerticalBorderColor,$event,isNormalTable,'tableBorderVerticalBorderColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="tableBorderVerticalBorderColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="tableBorderVerticalBorderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(tableBorderVerticalBorderColor,$event,isNormalTable,'tableBorderVerticalBorderColor')" [(ngModel)]="tableBorderVerticalBorderColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'tableBorderVerticalBorderColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="title-section">
                <span class="title-text-align" translate>title</span>
            </div>
            <div  class="over-all-title-style-block">
                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>text</span>
                </div>
                <div class="font-style weight-style each-section-gap">
                    <span class="title-margin" translate>font.family</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <select [ngModel]="titleHeaderFont"  class="curser select-width-100 common-border-color" (change)="updateStylesChanges($event.target.value,'titleHeaderFont')">
                            <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                </option>
                            </optgroup>
                            <optgroup label="Local fonts">
                                <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                </option>
                            </optgroup>
                        </select>
                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>font.weight</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <select class="curser common-border-color select-width-100" [(ngModel)]="titleHeaderFontWeight"
                                (change)="updateStylesChanges($event.target.value,'titleHeaderFontWeight')">
                            <ng-container *ngFor="let fontweight of FontWeightList">
                                <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>font.size</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="titleTextSize" (input)="sendChangesToEditor()" type="number" nxNoScroll class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>
                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>text.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="titleTextColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleTextColor,$event,isNormalTable,'')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="titleTextColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="titleTextColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleTextColor,$event,isNormalTable,'')" [(ngModel)]="titleTextColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'titleTextColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>alignment</span>
                    <div class="form-group row-top-align text-aligngment-width common-margin-top-8">
                        <div class="display-flex content-alignment-border">
                            <div class="title-first-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-titleTextAlignment':titleTextAlignment == 'left'}" (click)="titleAlignContent('left','title-first-alignment','titleTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-left"></i></span>
                            </div>
                            <div class="title-sec-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-titleTextAlignment':titleTextAlignment == 'center'}" (click)="titleAlignContent('center','title-sec-alignment','titleTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-center"></i></span>
                            </div>
                            <div class="title-third-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-titleTextAlignment':titleTextAlignment == 'right'}" (click)="titleAlignContent('right','title-third-alignment','titleTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-right"></i></span>
                            </div>
                            <div class="title-fourth-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-titleTextAlignment':titleTextAlignment == 'justify'}" (click)="titleAlignContent('justify','title-fourth-alignment','titleTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-justify"></i></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>style</span>
                </div>

                <div class="each-section-gap">
                    <span translate>height</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="titleTextHeight" (input)="sendChangesToEditor()" type="number" nxNoScroll
                                   class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>


                <div class="each-section-gap">
                    <span translate>background.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <div class="cross" *ngIf="titleTextBackgroundColor == 'No background'">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <ng-container *ngIf="titleTextBackgroundColor !== 'No background'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="titleTextBackgroundColor"></span>
                            </ng-container>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleTextBackgroundColor,$event,isNormalTable,'')" [(ngModel)]="titleTextBackgroundColor">
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue"  (colorValue)="getColor($event,'titleTextBackgroundColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>Border</span>
                </div>

                <div class="each-section-gap">
                    <span translate>Border.width</span>

                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="input-group-area border-alignment">
                            <input [disabled]="titleTextBorderWidthIndividualSide" min="0" maxlength="3"  [(ngModel)]="titleTextBorderWidth" (input)="sendChangesToEditor()"
                                   type="number" nxNoScroll class="mb-0 input-textbox-height advance-border-radius common-border-color">
                            <span class="text-box-px" style="top: 0px;">px</span>
                            <div class="all-side-border-radius" (click)="handleSideProperty('titleTextBorderWidthIndividualSide', 'titleTextBorderWidth')"><i class="fas fa-expand-wide"></i></div>
                        </div>

                        <div class="form-group" *ngIf="titleTextBorderWidthIndividualSide">
                            <div class="border-radius-all-size-align">
                                <div translate class="common-margin-bottom-8 common-margin-top-16">top</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="titleTextBorderWidthTop" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border ">
                                        <span class="input-group-px ">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="titleTextBorderWidthBottom" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                <div class="input-group" style="margin-right: 4px;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="titleTextBorderWidthLeft" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>
                                <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                <div class="input-group" style="margin: 0;">
                                    <div class="input-group-area border-alignment">
                                        <input min="0" maxlength="3" [(ngModel)]="titleTextBorderWidthRight" (input)="sendChangesToEditor()"
                                               type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                        <span class="input-group-px">px</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>Border.colour</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="titleTextBorderColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleTextBorderColor,$event,isNormalTable,'titleTextBorderColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="titleTextBorderColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="titleTextBorderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(titleTextBorderColor,$event,isNormalTable,'titleTextBorderColor')" [(ngModel)]="titleTextBorderColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'titleTextBorderColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="title-section">
                <span class="title-text-align" translate>row</span>
            </div>
            <div  class="over-all-title-style-block">
                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>text</span>
                </div>
                <div class="font-style weight-style each-section-gap">
                    <span class="title-margin" translate>font.family</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <select [ngModel]="rowHeaderFont" class="curser select-width-100 common-border-color"
                                (change)="updateStylesChanges($event.target.value,'rowHeaderFont')">
                            <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                </option>
                            </optgroup>
                            <optgroup label="Local fonts">
                                <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                </option>
                            </optgroup>
                        </select>
                    </div>
                </div>

                <div class="each-section-gap commmon-margin-top-12">
                    <span translate>font.weight</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <select class="curser common-border-color select-width-100" [(ngModel)]="rowHeaderFontWeight"
                                (change)="updateStylesChanges($event.target.value,'rowHeaderFontWeight')">
                            <ng-container *ngFor="let fontweight of FontWeightList">
                                <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="each-section-gap  commmon-margin-top-12">
                    <span translate>font.size</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="rowTextSize" (input)="sendChangesToEditor()" type="number" nxNoScroll class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>
                <div class="each-section-gap  commmon-margin-top-12">
                    <span translate>text.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="rowTextColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextColor,$event,isNormalTable,'rowTextColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="rowTextColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowTextColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextColor,$event,isNormalTable,'rowTextColor')" [(ngModel)]="rowTextColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'rowTextColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="each-section-gap  commmon-margin-top-12">
                    <span translate>alignment</span>
                    <div class="form-group row-top-align text-aligngment-width common-margin-top-8">
                        <div class="display-flex content-alignment-border">
                            <div class="row-first-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-rowTextAlignment':rowTextAlignment == 'left'}" (click)="titleAlignContent('left','row-first-alignment','rowTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-left"></i></span>
                            </div>
                            <div class="row-sec-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-rowTextAlignment':rowTextAlignment == 'center'}" (click)="titleAlignContent('center','row-sec-alignment','rowTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-center"></i></span>
                            </div>
                            <div class="row-third-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-rowTextAlignment':rowTextAlignment == 'right'}" (click)="titleAlignContent('right','row-third-alignment','rowTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-right"></i></span>
                            </div>
                            <div class="row-fourth-alignment title-set-remove-bg" [ngClass]="{'switch-color-content-alignment-rowTextAlignment':rowTextAlignment == 'justify'}" (click)="titleAlignContent('justify','row-fourth-alignment','rowTextAlignment')">
                                <span class="align-font"><i class="fas fa-align-justify"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>style</span>
                </div>

                <div class="each-section-gap">
                    <span translate>height</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="rowTextHeight" (input)="sendChangesToEditor()" type="number" nxNoScroll
                                   class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>


                <div class="each-section-gap">
                    <span translate>background.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <div class="cross" *ngIf="rowTextBackgroundColor == 'No background'">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <ng-container *ngIf="rowTextBackgroundColor !== 'No background'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowTextBackgroundColor"></span>
                            </ng-container>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextBackgroundColor,$event,isNormalTable,'rowTextBackgroundColor')" [(ngModel)]="rowTextBackgroundColor">
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue"  (colorValue)="getColor($event,'rowTextBackgroundColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="each-section-gap">
                    <span translate>alternative.background.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <div class="cross" *ngIf="rowTextAlternativeBackgroundColor == 'No background'">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                            <ng-container *ngIf="rowTextAlternativeBackgroundColor !== 'No background'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowTextAlternativeBackgroundColor"></span>
                            </ng-container>
                            <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextAlternativeBackgroundColor,$event,isNormalTable,'rowTextAlternativeBackgroundColor')" [(ngModel)]="rowTextAlternativeBackgroundColor">
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue"  (colorValue)="getColor($event,'rowTextAlternativeBackgroundColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sub-title-section">
                    <div class="vertical-line-border"></div>
                    <span class="sub-title-text" translate>Border</span>
                </div>

                <div class="each-section-gap">
                    <span translate>horizontal.border</span>
                    <div class="form-group row-top-align common-margin-top-8">
                        <div class="size-alignment">
                            <input [(ngModel)]="rowTextHorizontalBorderWidth" (input)="sendChangesToEditor()" type="number" nxNoScroll class="size-text-box common-border-color">
                            <span class="text-box-px">px</span>
                        </div>
                    </div>
                </div>

                <div class="each-section-gap  commmon-margin-top-12">
                    <span translate>horizontal.border.color</span>
                    <div class="form-group row-top-align color-label-top common-margin-top-3">
                        <div class="cs-color-palatte dropdown">
                            <ng-container *ngIf="rowTextHorizontalBorderColor == 'No colour'">
                            <div class="cross">
                                <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                </svg>
                            </div>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextHorizontalBorderColor,$event,isNormalTable,'rowTextHorizontalBorderColor')" [value]="noColorLabel">
                            </ng-container>
                            <ng-container *ngIf="rowTextHorizontalBorderColor !== 'No colour'">
                                <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="rowTextHorizontalBorderColor"></span>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(rowTextHorizontalBorderColor,$event,isNormalTable,'rowTextHorizontalBorderColor')" [(ngModel)]="rowTextHorizontalBorderColor">
                            </ng-container>
                            <div class="dropdown-menu dropdown-height">
                                <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'rowTextHorizontalBorderColor')"></color-palatte>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="!isNormalTable">
                <div class="title-section">
                    <span class="title-text-align" translate>total.summary</span>
                </div>
                <div  class="over-all-title-style-block ">
                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>Border</span>
                    </div>

                    <div class="each-section-gap">
                        <span translate>Border.width</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="input-group-area border-alignment">
                                <input [disabled]="totalSummaryBorderWidthIndividualSide" [(ngModel)]="totalSummaryBorderWidth" (input)="sendChangesToEditor()"
                                       type="number" nxNoScroll class="mb-0 input-textbox-height advance-border-radius common-border-color">
                                <span class="text-box-px" style="top: 0px;">px</span>
                                <div class="all-side-border-radius" (click)="handleSideProperty('totalSummaryBorderWidthIndividualSide', 'totalSummaryBorderWidth')"><i class="fas fa-expand-wide"></i></div>
                            </div>

                            <div class="form-group" *ngIf="totalSummaryBorderWidthIndividualSide">
                                <div class="border-radius-all-size-align">
                                    <div translate class="common-margin-bottom-8 common-margin-top-16">top</div>
                                    <div class="input-group" style="margin-right: 4px;">
                                        <div class="input-group-area border-alignment">
                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryBorderWidthTop" (input)="sendChangesToEditor()"
                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border ">
                                            <span class="input-group-px ">px</span>
                                        </div>
                                    </div>
                                    <div translate class="common-margin-bottom-8 common-margin-top-16">bottom</div>
                                    <div class="input-group" style="margin-right: 4px;">
                                        <div class="input-group-area border-alignment">
                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryBorderWidthBottom" (input)="sendChangesToEditor()"
                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                            <span class="input-group-px">px</span>
                                        </div>
                                    </div>
                                    <div translate class="common-margin-bottom-8 common-margin-top-16">right</div>
                                    <div class="input-group" style="margin-right: 4px;">
                                        <div class="input-group-area border-alignment">
                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryBorderWidthRight" (input)="sendChangesToEditor()"
                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                            <span class="input-group-px">px</span>
                                        </div>
                                    </div>
                                    <div translate class="common-margin-bottom-8 common-margin-top-16">left</div>
                                    <div class="input-group" style="margin: 0;">
                                        <div class="input-group-area border-alignment">
                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryBorderWidthLeft" (input)="sendChangesToEditor()"
                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">
                                            <span class="input-group-px">px</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>Border.colour</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <ng-container *ngIf="totalSummaryBorderColor == 'No colour'">
                                <div class="cross">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                    </svg>
                                </div>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummaryBorderColor,$event,isNormalTable,'totalSummaryBorderColor')" [value]="noColorLabel">
                                </ng-container>
                                <ng-container *ngIf="totalSummaryBorderColor !== 'No colour'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="totalSummaryBorderColor"></span>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummaryBorderColor,$event,isNormalTable,'totalSummaryBorderColor')" [(ngModel)]="totalSummaryBorderColor">
                                </ng-container>
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'totalSummaryBorderColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>style</span>
                    </div>
                    <div class="each-section-gap">
                        <span translate>background.color</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <div class="cross" *ngIf="totalSummaryBackgroundColor == 'No background'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                    </svg>
                                </div>
                                <ng-container *ngIf="totalSummaryBackgroundColor !== 'No background'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="totalSummaryBackgroundColor"></span>
                                </ng-container>
                                <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummaryBackgroundColor,$event,isNormalTable,'')" [(ngModel)]="totalSummaryBackgroundColor">
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'totalSummaryBackgroundColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>

<!--                    <div class="sub-title-section">-->
<!--                        <div class="vertical-line-border"></div>-->
<!--                        <span class="sub-title-text" translate>Padding.margin</span>-->
<!--                    </div>-->

<!--                    <div class="each-section-gap">-->
<!--                        <span translate>Padding</span>-->
<!--                        <div class="form-group row-top-align ">-->
<!--                            <div class="input-group-area border-alignment">-->
<!--                                <input  [disabled]="totalSummaryPaddingIndividualSide" min="0" maxlength="3"-->
<!--                                        [(ngModel)]="totalSummaryPadding" (input)="sendChangesToEditor()" type="number" nxNoScroll-->
<!--                                        class="mb-0 input-textbox-height advance-border-radius common-border-color">-->
<!--                                <span class="text-box-px" style="top: 0px;">px</span>-->
<!--                                <div class="all-side-border-radius" (click)="handleSideProperty('totalSummaryPaddingIndividualSide', 'totalSummaryPadding')">-->
<!--                                    <i class="fas fa-expand-wide"></i>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="form-group" *ngIf="totalSummaryPaddingIndividualSide">-->
<!--                                <div class="border-radius-all-size-align">-->
<!--                                    <div translate>top</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryPaddingTop" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px ">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>right</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryPaddingRight" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>bottom</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryPaddingBottom" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>left</div>-->
<!--                                    <div class="input-group" style="margin: 0;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryPaddingLeft" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="each-section-gap">-->
<!--                        <span translate>Margin</span>-->
<!--                        <div class="form-group row-top-align ">-->
<!--                            <div class="input-group-area border-alignment">-->
<!--                                <input  [disabled]="totalSummaryMarginIndividualSide" min="0" maxlength="3"-->
<!--                                        [(ngModel)]="totalSummaryMargin" (input)="sendChangesToEditor()" type="number" nxNoScroll-->
<!--                                        class="mb-0 input-textbox-height advance-border-radius common-border-color">-->
<!--                                <span class="text-box-px" style="top: 0px;">px</span>-->
<!--                                <div class="all-side-border-radius" (click)="handleSideProperty('totalSummaryMarginIndividualSide', 'totalSummaryMargin')">-->
<!--                                    <i class="fas fa-expand-wide"></i>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="form-group" *ngIf="totalSummaryMarginIndividualSide">-->
<!--                                <div class="border-radius-all-size-align">-->
<!--                                    <div translate>top</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryMarginTop" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px ">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>right</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryMarginRight" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>bottom</div>-->
<!--                                    <div class="input-group" style="margin-right: 4px;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryMarginBottom" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div translate>left</div>-->
<!--                                    <div class="input-group" style="margin: 0;">-->
<!--                                        <div class="input-group-area border-alignment">-->
<!--                                            <input min="0" maxlength="3" [(ngModel)]="totalSummaryMarginLeft" (input)="sendChangesToEditor()"-->
<!--                                                   type="number" nxNoScroll class="mb-0 input-textbox-height radius-input right-border">-->
<!--                                            <span class="input-group-px">px</span>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>sub.total.text</span>
                    </div>

                    <div class="font-style weight-style each-section-gap">
                        <span class="title-margin" translate>font.family</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select [ngModel]="totalSummarySubtotalHeaderFont" class="curser select-width-100 common-border-color"
                                    (change)="updateStylesChanges($event.target.value,'totalSummarySubtotalHeaderFont')">
                                <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                    <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                    </option>
                                </optgroup>
                                <optgroup label="Local fonts">
                                    <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.weight</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select class="curser common-border-color select-width-100" [(ngModel)]="totalSummarySubtotalHeaderFontWeight"
                                    (change)="updateStylesChanges($event.target.value,'totalSummarySubtotalHeaderFontWeight')">
                                <ng-container *ngFor="let fontweight of FontWeightList">
                                    <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.size</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="totalSummarySubtotalTextSize" (input)="sendChangesToEditor('totalSummarySubtotalTextSize')" type="number" nxNoScroll class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12 ">
                        <span translate>text.color</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <ng-container *ngIf="totalSummarySubtotalTextColor == 'No colour'">
                                <div class="cross">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                    </svg>
                                </div>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummarySubtotalTextColor,$event,isNormalTable,'totalSummarySubtotalTextColor')" [value]="noColorLabel">
                                </ng-container>
                                <ng-container *ngIf="totalSummarySubtotalTextColor !== 'No colour'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="totalSummarySubtotalTextColor"></span>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummarySubtotalTextColor,$event,isNormalTable,'totalSummarySubtotalTextColor')" [(ngModel)]="totalSummarySubtotalTextColor">
                                </ng-container>
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'totalSummarySubtotalTextColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>line.height</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="totalSummarySubtotalLineHeight" (input)="sendChangesToEditor('totalSummarySubtotalLineHeight')" type="number" nxNoScroll min="14" class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>

                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>grand.total.text</span>
                    </div>

                    <div class="font-style weight-style each-section-gap">
                        <span class="title-margin" translate>font.family</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select [ngModel]="totalSummaryGrandTotalHeaderFont" class="curser select-width-100 common-border-color"
                                    (change)="updateStylesChanges($event.target.value,'totalSummaryGrandTotalHeaderFont')">
                                <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                    <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                    </option>
                                </optgroup>
                                <optgroup label="Local fonts">
                                    <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.weight</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select class="curser common-border-color select-width-100" [(ngModel)]="totalSummaryGrandTotalHeaderFontWeight"
                                    (change)="updateStylesChanges($event.target.value,'totalSummaryGrandTotalHeaderFontWeight')">
                                <ng-container *ngFor="let fontweight of FontWeightList">
                                    <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.size</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="totalSummaryGrandTotalTextSize" (input)="sendChangesToEditor('totalSummaryGrandTotalTextSize')" type="number" nxNoScroll class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>text.color</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <ng-container *ngIf="totalSummaryGrandTotalTextColor == 'No colour'">
                                <div class="cross">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                    </svg>
                                </div>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummaryGrandTotalTextColor,$event,isNormalTable,'totalSummaryGrandTotalTextColor')" [value]="noColorLabel">
                                </ng-container>
                                <ng-container *ngIf="totalSummaryGrandTotalTextColor !== 'No colour'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="totalSummaryGrandTotalTextColor"></span>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(totalSummaryGrandTotalTextColor,$event,isNormalTable,'totalSummaryGrandTotalTextColor')" [(ngModel)]="totalSummaryGrandTotalTextColor">
                                </ng-container>
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'totalSummaryGrandTotalTextColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>line.height</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="totalSummaryGrandTotalLineHeight" (input)="sendChangesToEditor('totalSummaryGrandTotalLineHeight')" type="number" nxNoScroll min="14" class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>

                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>Finance</span>
                    </div>

                    <div class="font-style weight-style each-section-gap">
                        <span class="title-margin" translate>font.family</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select [(ngModel)]="financeFontStyle" class="curser select-width-100 common-border-color"
                                    (change)="updateStylesChanges($event.target.value,'financeFontStyle')">
                                <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                    <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                    </option>
                                </optgroup>
                                <optgroup label="Local fonts">
                                    <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.size</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="financeFontSize" (input)="sendChangesToEditor('financeFontSize')" type="number" nxNoScroll class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>text.color</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <ng-container *ngIf="financeTextColor == 'No colour'">
                                    <div class="cross">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                        </svg>
                                    </div>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(financeTextColor,$event,isNormalTable,'financeTextColor')" [value]="noColorLabel">
                                </ng-container>
                                <ng-container *ngIf="financeTextColor !== 'No colour'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="financeTextColor"></span>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(financeTextColor,$event,isNormalTable,'financeTextColor')" [(ngModel)]="financeTextColor">
                                </ng-container>
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'financeTextColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>line.height</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="financeLineHeight" (input)="sendChangesToEditor('financeLineHeight')" type="number" nxNoScroll min="14" class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>

                    <div class="sub-title-section">
                        <div class="vertical-line-border"></div>
                        <span class="sub-title-text" translate>Grants</span>
                    </div>

                    <div class="font-style weight-style each-section-gap">
                        <span class="title-margin" translate>font.family</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select [(ngModel)]="grantsFontStyle" class="curser select-width-100 common-border-color"
                                    (change)="updateStylesChanges($event.target.value,'grantsFontStyle')">
                                <optgroup label="Google fonts" *ngIf="displayGoogleFontList && displayGoogleFontList.length">
                                    <option *ngFor="let fontfamily of displayGoogleFontList;" [value]="fontfamily" [style.font-family]="fontfamily">{{fontfamily}}
                                    </option>
                                </optgroup>
                                <optgroup label="Local fonts">
                                    <option *ngFor="let fontfamily of fontFamilyList;" [value]="fontfamily.name" [style.font-family]="fontfamily.family">{{fontfamily.name}}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.weight</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <select class="curser common-border-color select-width-100" [(ngModel)]="grantsFontWeight"
                                    (change)="updateStylesChanges($event.target.value,'grantsFontWeight')">
                                <ng-container *ngFor="let fontweight of FontWeightList">
                                    <option [value]="fontweight.value" *ngIf="fontweight?.visibility">{{fontweight.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>

                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>font.size</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="grantsFontSize" (input)="sendChangesToEditor('grantsFontSize')" type="number" nxNoScroll class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>text.color</span>
                        <div class="form-group row-top-align color-label-top common-margin-top-3">
                            <div class="cs-color-palatte dropdown">
                                <ng-container *ngIf="grantsTextColor == 'No colour'">
                                    <div class="cross">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="cross-x-border" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M3.81322 3.81288C3.90697 3.71925 4.03405 3.66666 4.16655 3.66666C4.29905 3.66666 4.42614 3.71925 4.51989 3.81288L7.99989 7.29288L11.4799 3.81288C11.541 3.74707 11.6187 3.69895 11.7049 3.67361C11.7911 3.64827 11.8825 3.64663 11.9695 3.66888C12.0565 3.69113 12.1359 3.73644 12.1993 3.80003C12.2628 3.86362 12.3079 3.94314 12.3299 4.03022C12.3522 4.11713 12.3506 4.20845 12.3254 4.29456C12.3002 4.38067 12.2522 4.45839 12.1866 4.51955L8.70655 7.99955L12.1866 11.4796C12.2524 11.5407 12.3005 11.6184 12.3258 11.7046C12.3512 11.7907 12.3528 11.8821 12.3306 11.9692C12.3083 12.0562 12.263 12.1356 12.1994 12.199C12.1358 12.2624 12.0563 12.3075 11.9692 12.3296C11.8823 12.3518 11.791 12.3503 11.7049 12.3251C11.6188 12.2998 11.541 12.2519 11.4799 12.1862L7.99989 8.70622L4.51989 12.1862C4.42504 12.2745 4.29968 12.3226 4.17013 12.3203C4.04059 12.3181 3.91695 12.2657 3.82522 12.1742C3.73372 12.0825 3.68135 11.9588 3.67912 11.8293C3.67688 11.6998 3.72495 11.5744 3.81322 11.4796L7.29322 7.99955L3.81322 4.51955C3.71959 4.4258 3.66699 4.29872 3.66699 4.16622C3.66699 4.03372 3.71959 3.90663 3.81322 3.81288Z" fill="#979797"/>
                                        </svg>
                                    </div>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(grantsTextColor,$event,isNormalTable,'grantsTextColor')" [value]="noColorLabel">
                                </ng-container>
                                <ng-container *ngIf="grantsTextColor !== 'No colour'">
                                    <span class="color-box color-box-top" data-toggle="dropdown" [style.background]="grantsTextColor"></span>
                                    <input class="picker-color" readonly autocomplete="off" data-toggle="dropdown" (click)="detectChanges(grantsTextColor,$event,isNormalTable,'grantsTextColor')" [(ngModel)]="grantsTextColor">
                                </ng-container>
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="colorPalateValue" (colorValue)="getColor($event,'grantsTextColor')"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="each-section-gap commmon-margin-top-12">
                        <span translate>line.height</span>
                        <div class="form-group row-top-align common-margin-top-8">
                            <div class="size-alignment">
                                <input [(ngModel)]="grantsLineHeight" (input)="sendChangesToEditor('grantsLineHeight')" type="number" nxNoScroll min="14" class="size-text-box common-border-color">
                                <span class="text-box-px">px</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="modal-footer bottom-footer-alignment-table-format">
        <!--    <button class="btn btn-primary btn-left" [disabled]="templateName!= null">-->
        <!--      <span translate (click)="saveTemplate()">Save.template</span>-->
        <!--    </button>-->
        <a href="javascript:void(0)" class="btn" (click)="cancel()">
            <span id="cancel-styles">Cancel</span>
        </a>
        <button class="btn btn-primary" (click)="save()">
            <span translate id="save-styles">Save</span>
        </button>
    </div>

</ng-container>
