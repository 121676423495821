var InterfaceClass = /** @class */ (function () {
    function InterfaceClass() {
        this.subscriptions = {};
    }
    InterfaceClass.prototype.ngOnDestroy = function () {
        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(function (_a) {
            var name = _a[0], subscription = _a[1];
            return subscription.unsubscribe();
        });
    };
    return InterfaceClass;
}());
export { InterfaceClass };
