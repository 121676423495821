import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import {AddOpportunityService} from "@app/features/customers/add-opportunity/service/add-opportunity.service";
import {fontfamilyService} from "@app/shared/services/fontfamily.service";
import {ProposalTemplateService} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {AuthService} from "@app/core";



declare var $: any;
@Component({
  selector: 'app-configure-advance-styling',
  templateUrl: './configure-advance-styling.component.html',
  styleUrls: ['./configure-advance-styling.component.scss']
})
export class ConfigureAdvanceStylingComponent implements OnInit, AfterViewInit  {
    public onClose: Subject<boolean>;
    editorStatus: any;
    vatEnabled: any;
    tableHeaders: any = [];
    taxLabel: string | any;
    vatType: any | string;
    isDisabledHorizentalOrVerticalOption : any;
    isNormalTable: any;
    fontFamilyList = this.fontFamilyService.getFontFamilyArr();
    displayGoogleFontList : any;
    FontWeightList = [
        { name: 'Bold', value: 600,visibility:true },
        { name: 'Semi-Bold', value: 400,visibility:true },
        { name: 'Medium', value: 300,visibility:true },
        { name: 'Light', value: 100,visibility:true },
    ];


    colorPalateValue: string = '';
    existingValues: any = [];
    existingValuesBackup: any = [];
    currentSectionId; any = 0;
    isOptionSummary: any;
    hashId = '';


    //content block
    contentBlockBorderWidth: any = 0;
    contentBlockBorderWidthTop:any = 0;
    contentBlockBorderWidthBottom:any = 0;
    contentBlockBorderWidthLeft:any = 0;
    contentBlockBorderWidthRight:any = 0;
    contentBlockBorderWidthIndividualSide : boolean = false;
    contentBlockBorderColor : any = "No colour";
    contentBlockBorderRadius: any = 0;
    contentBlockBorderRadiusIndividualSide : boolean = false;
    contentBlockBorderRadiusTopLeft:any = 0;
    contentBlockBorderRadiusTopRight:any = 0;
    contentBlockBorderRadiusBottomRight:any = 0;
    contentBlockBorderRadiusBottomLeft:any = 0;
    contentBlockBackgroundColor : any = "#FFFFFF";
    contentBlockPadding :any = 0;
    contentBlockPaddingIndividualSide : boolean = false;
    contentBlockPaddingTop:any = 0;
    contentBlockPaddingBottom:any = 0;
    contentBlockPaddingLeft:any = 0;
    contentBlockPaddingRight:any = 0;
    contentBlockMargin :any = 0;
    contentBlockMarginIndividualSide : boolean = false;
    contentBlockMarginTop:any = 0;
    contentBlockMarginBottom:any = 0;
    contentBlockMarginLeft:any = 0;
    contentBlockMarginRight:any = 0;

    //table border
    tableBorderWidth: any = 0;
    tableBorderWidthIndividualSide : boolean = false;
    tableBorderWidthTop:any = 0;
    tableBorderWidthBottom:any = 0;
    tableBorderWidthLeft:any = 0;
    tableBorderWidthRight:any = 0;
    tableBorderColor : any = "No colour";
    tableBorderVerticalBorderWidth : any = 0;
    tableBorderVerticalBorderColor : any = "No colour";

    //title block
    titleHeaderFont: any = "Arial";
    titleHeaderFontWeight: any = 400;
    titleTextSize: any = 14;
    titleTextColor: any = "No colour";
    titleTextAlignment: any = "left";
    titleTextBackgroundColor: any = "No background";
    titleTextHeight: any = 25;
    titleTextBorderWidth: any = 0;
    titleTextBorderWidthIndividualSide : boolean = false;
    titleTextBorderWidthTop:any = 0;
    titleTextBorderWidthBottom:any = 0;
    titleTextBorderWidthLeft:any = 0;
    titleTextBorderWidthRight:any = 0;
    titleTextBorderColor: any = "No colour";

    //row block
    rowHeaderFont: any = "Arial";
    rowHeaderFontWeight: any = 400;
    rowTextSize: any = 14;
    rowTextColor: any = "No colour";
    rowTextAlignment: any = "left";
    rowTextHeight: any = 27;
    rowTextBackgroundColor: any = "No background";
    rowTextAlternativeBackgroundColor: any = "No background";
    rowTextHorizontalBorderWidth: any = 0;
    rowTextHorizontalBorderColor: any = "No colour";

    //total summmary
    totalSummaryBorderWidth: any = 0;
    totalSummaryBorderWidthIndividualSide : boolean = false;
    totalSummaryBorderWidthTop:any = 0;
    totalSummaryBorderWidthBottom:any = 0;
    totalSummaryBorderWidthRight:any = 0;
    totalSummaryBorderWidthLeft:any = 0;
    totalSummaryBorderColor: any = "No colour";
    totalSummaryBackgroundColor: any = "No background";
    totalSummaryPadding :any = 0;
    totalSummaryPaddingIndividualSide : boolean = false;
    totalSummaryPaddingTop:any = 0;
    totalSummaryPaddingRight:any = 0;
    totalSummaryPaddingBottom:any = 0;
    totalSummaryPaddingLeft:any = 0;
    totalSummaryMargin :any = 0;
    totalSummaryMarginIndividualSide : boolean = false;
    totalSummaryMarginTop:any = 0;
    totalSummaryMarginRight:any = 0;
    totalSummaryMarginBottom:any = 0;
    totalSummaryMarginLeft:any = 0;
    totalSummarySubtotalHeaderFont: any = "Arial";
    totalSummarySubtotalHeaderFontWeight: any = 400;
    totalSummarySubtotalTextSize: any = 14;
    totalSummarySubtotalTextColor: any = "#505B65";
    totalSummarySubtotalLineHeight: any = 14;
    totalSummaryGrandTotalHeaderFont: any = "Arial";
    totalSummaryGrandTotalHeaderFontWeight: any = 400;
    totalSummaryGrandTotalTextSize: any = 14;
    totalSummaryGrandTotalTextColor: any = "#505B65";
    totalSummaryGrandTotalLineHeight: any = 14;

    //finance section
    financeFontStyle: any = 'Arial';
    financeFontSize: any = 14;
    financeTextColor: any = '#505B65';
    financeLineHeight: any = 20;

    //grants section
    grantsFontStyle: any = 'Arial';
    grantsFontSize: any = 14;
    grantsFontWeight: any = 400;
    grantsTextColor: any = '#505B65';
    grantsLineHeight: any = 14;

    taxStatus: any = "withouttax";
    tablePkId: any;
    tableType: any;
    breakdown: boolean = true;
    breakdownView: any = 'vertical';
    columnShowHide: boolean = true;
    subtotalVisible: any = 'yes';
    listHideShow: boolean = false;
    taxChange: boolean = false;
    taxInclusive: boolean;
    showPriceBreakdown: any = true;
    horizontalVisibility: boolean = false;
    configData: any;
    noColorLabel: any;
    checkedTableColumnCount = 4;
    isFinanceDataAvailable: boolean;
  constructor(
      public bsModalRef: BsModalRef,
      private opportunityService: AddOpportunityService,
      private fontFamilyService: fontfamilyService,
      public proposaltemplateservice: ProposalTemplateService,
      private authService: AuthService
  ) {  }

  ngOnInit() {

      let googleFontObject = this.fontFamilyService.getGoogleFontObject();
      this.displayGoogleFontList = googleFontObject.map(item => item.name);

      if (this.authService['settings']){
          this.noColorLabel = this.authService['settings']._csCountryCode.country == 'United Kingdom' ? 'No colour' : 'No color';
          // this.titleTextColor = textColour;
          // this.contentBlockBorderColor = textColour;

      }
      if (this.vatEnabled) {
          this.tableHeaders = [
              {name: 'Quantity', value: '1', checked: true},
              {name: 'Unit price', value: '2', checked: true},
              {name: this.taxLabel, value: '3', checked: true},
              {name: `Total  (${this.vatType} ${this.taxLabel})`, value: '4', checked: true}
          ];
      } else {
          this.tableHeaders = [
              {name: 'Quantity', value: '1', checked: true},
              {name: 'Unit price', value: '2', checked: true},
              {name: `Total`, value: '4', checked: true}
          ];
      }
      this.isDisabledHorizentalOrVerticalOption;
      this.onClose = new Subject();
      const advStyle = this.existingValues && this.existingValues.advancedStyle || null;
      if (advStyle && (!advStyle.hasOwnProperty('save') || (advStyle.hasOwnProperty('sectionId') && !advStyle.hasOwnProperty('save')) || advStyle.hasOwnProperty('currentSectionId'))) {
          // this.getUndefinedTableDefultValue();
          this.existingValuesBackup = this.existingValues;
      } else {
          this.getUndefinedTableDefultValue();
      }

      if (this.configData ) {
          this.breakdownView = (this.configData.breakdownView) ? this.configData.breakdownView : 'vertical';
          this.breakdown = (this.configData.breakdownSwitch) ? this.configData.breakdownSwitch : true ;
          this.listHideShow = (this.configData.listHideShow) ? this.configData.listHideShow : false;
          if(typeof this.configData.showHideColumns != "undefined"){
              this.showPriceBreakdown = this.configData.showHideColumns;
          }
          // this.taxStatus =  this.configData.tax;
          if (this.configData.visibleColumn) {
              this.tableHeaders = this.configData.visibleColumn;
              if(this.configData.visibleColumn.length === 4) {
                  if (this.vatEnabled) {
                      this.tableHeaders[2]['name'] = this.taxLabel;
                      this.tableHeaders[3]['name'] = `Total  (${this.vatType} ${this.taxLabel})`;
                  } else {
                      if (this.tableHeaders[2]['name'] != 'Total') {
                          this.tableHeaders[2] = {name: `Total`, value: '4', checked: true};
                          this.tableHeaders[2]['checked'] = this.tableHeaders[3]['checked'];
                          this.tableHeaders.pop(3);
                      }
                      /*this.tableHeaders[2] = {};
                      this.tableHeaders[3]['name'] = `Total`;*/
                  }
              } else if(this.configData.visibleColumn.length === 3) {
                  if (this.vatEnabled) {
                      this.tableHeaders.push({name: this.taxLabel, value: '3', checked: false});
                      this.tableHeaders[3] = this.tableHeaders[2];
                      this.tableHeaders[2] = {name: this.taxLabel, value: '3', checked: false};
                  }
              }
              this.checkedTableColumnCount = this.tableHeaders.filter(header => header.checked).length;
          }
          if (this.configData.subtotalVisible) {
              this.subtotalVisible = this.configData.subtotalVisible;
          }
      }

      if (this.breakdownView == "horizontal") {
          this.horizontalVisibility = true;
      } else {
          this.horizontalVisibility = false;
      }
      if (this.showPriceBreakdown == true) {
          this.columnShowHide = true;
      } else {
          this.columnShowHide = false;
      }

      // if (this.isNormalTable){
      //     let oldEle = this.save(true);
      //     let msg = { type: 'normalTableStylingCheck', oldEle }
      //     window.top.postMessage(msg, '*')
      // }

      if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.existingValues.advancedStyle) {
          let styleData: any = this.existingValues.advancedStyle;

          this.contentBlockBorderWidth = styleData.contentBlockBorderWidth || this.contentBlockBorderWidth;
          this.contentBlockBorderWidthTop = styleData.contentBlockBorderWidthTop || this.contentBlockBorderWidthTop,
          this.contentBlockBorderWidthBottom = styleData.contentBlockBorderWidthBottom || this.contentBlockBorderWidthBottom,
          this.contentBlockBorderWidthLeft = styleData.contentBlockBorderWidthLeft || this.contentBlockBorderWidthLeft,
          this.contentBlockBorderWidthRight = styleData.contentBlockBorderWidthRight || this.contentBlockBorderWidthRight,
          this.contentBlockBorderWidthIndividualSide = styleData.contentBlockBorderWidthIndividualSide || this.contentBlockBorderWidthIndividualSide,
          this.contentBlockBorderColor = ((styleData.contentBlockBorderColor === 'No border' || styleData.contentBlockBorderColor === 'transparent') ? 'No colour' : styleData.contentBlockBorderColor) || this.contentBlockBorderColor;
          this.contentBlockBorderRadius = styleData.contentBlockBorderRadius || this.contentBlockBorderRadius;
          this.contentBlockBorderRadiusBottomRight = styleData.contentBlockBorderRadiusBottomRight || this.contentBlockBorderRadiusBottomRight;
          this.contentBlockBorderRadiusBottomLeft = styleData.contentBlockBorderRadiusBottomLeft || this.contentBlockBorderRadiusBottomLeft;
          this.contentBlockBorderRadiusTopLeft = styleData.contentBlockBorderRadiusTopLeft || this.contentBlockBorderRadiusTopLeft;
          this.contentBlockBorderRadiusTopRight = styleData.contentBlockBorderRadiusTopRight || this.contentBlockBorderRadiusTopRight;
          this.contentBlockBorderRadiusIndividualSide = styleData.contentBlockBorderRadiusIndividualSide || this.contentBlockBorderRadiusIndividualSide;
          this.contentBlockBackgroundColor = ((styleData.contentBlockBackgroundColor === 'No border' || styleData.contentBlockBackgroundColor === 'transparent' || styleData.contentBlockBackgroundColor === 'No color' || styleData.contentBlockBackgroundColor === 'No background') ? '#FFFFFF' : styleData.contentBlockBackgroundColor) || this.contentBlockBackgroundColor;
          this.contentBlockPadding = styleData.contentBlockPadding || this.contentBlockPadding;
          this.contentBlockPaddingTop = styleData.contentBlockPaddingTop || this.contentBlockPaddingTop;
          this.contentBlockPaddingBottom = styleData.contentBlockPaddingBottom || this.contentBlockPaddingBottom;
          this.contentBlockPaddingLeft = styleData.contentBlockPaddingLeft || this.contentBlockPaddingLeft;
          this.contentBlockPaddingRight = styleData.contentBlockPaddingRight || this.contentBlockPaddingRight;
          this.contentBlockPaddingIndividualSide = styleData.contentBlockPaddingIndividualSide || this.contentBlockPaddingIndividualSide;
          this.contentBlockMargin = styleData.contentBlockMargin || this.contentBlockMargin;
          this.contentBlockMarginTop = styleData.contentBlockMarginTop || this.contentBlockMarginTop;
          this.contentBlockMarginBottom = styleData.contentBlockMarginBottom || this.contentBlockMarginBottom;
          this.contentBlockMarginLeft = styleData.contentBlockMarginLeft || this.contentBlockMarginLeft;
          this.contentBlockMarginRight = styleData.contentBlockMarginRight || this.contentBlockMarginRight;
          this.contentBlockMarginIndividualSide = styleData.contentBlockMarginIndividualSide || this.contentBlockMarginIndividualSide;

          this.tableBorderWidth = styleData.tableBorderWidth || this.tableBorderWidth;
          this.tableBorderWidthTop = styleData.tableBorderWidthTop || this.tableBorderWidthTop;
          this.tableBorderWidthBottom = styleData.tableBorderWidthBottom || this.tableBorderWidthBottom;
          this.tableBorderWidthLeft = styleData.tableBorderWidthLeft || this.tableBorderWidthLeft;
          this.tableBorderWidthRight = styleData.tableBorderWidthRight || this.tableBorderWidthRight;
          this.tableBorderWidthIndividualSide = styleData.tableBorderWidthIndividualSide || this.tableBorderWidthIndividualSide;
          this.tableBorderColor = ((styleData.tableBorderColor === 'No border' || styleData.tableBorderColor === 'transparent' || styleData.tableBorderColor === 'No color') ? 'No colour' : styleData.tableBorderColor) || this.tableBorderColor;
          this.tableBorderVerticalBorderWidth = styleData.tableBorderVerticalBorderWidth || this.tableBorderVerticalBorderWidth;
          this.tableBorderVerticalBorderColor = styleData.tableBorderVerticalBorderColor || this.tableBorderVerticalBorderColor;

          this.titleHeaderFont = styleData.titleHeaderFont || this.titleHeaderFont;
          this.titleHeaderFontWeight = styleData.titleHeaderFontWeight || this.titleHeaderFontWeight;
          this.titleTextSize = styleData.titleTextSize || this.titleTextSize;
          this.titleTextColor = ((styleData.titleTextColor === 'No border' || styleData.titleTextColor === 'transparent' || styleData.titleTextColor === 'No color') ? 'No colour' : styleData.titleTextColor) || this.titleTextColor;
          this.titleTextAlignment = styleData.titleTextAlignment || this.titleTextAlignment;
          this.titleTextBackgroundColor = ((styleData.titleTextBackgroundColor === 'No border' || styleData.titleTextBackgroundColor === 'transparent' || styleData.titleTextBackgroundColor === 'No color') ? 'No background' : styleData.titleTextBackgroundColor) || this.titleTextBackgroundColor;
          this.titleTextHeight = styleData.titleTextHeight || this.titleTextHeight;
          this.titleTextBorderWidth = styleData.titleTextBorderWidth || this.titleTextBorderWidth;
          this.titleTextBorderWidthTop = styleData.titleTextBorderWidthTop || this.titleTextBorderWidthTop;
          this.titleTextBorderWidthRight = styleData.titleTextBorderWidthRight || this.titleTextBorderWidthRight;
          this.titleTextBorderWidthLeft = styleData.titleTextBorderWidthLeft || this.titleTextBorderWidthLeft;
          this.titleTextBorderWidthBottom = styleData.titleTextBorderWidthBottom || this.titleTextBorderWidthBottom;
          this.titleTextBorderWidthIndividualSide = styleData.titleTextBorderWidthIndividualSide || this.titleTextBorderWidthIndividualSide;
          this.titleTextBorderColor = ((styleData.titleTextBorderColor === 'No border' || styleData.titleTextBorderColor === 'transparent' || styleData.titleTextBorderColor === 'No color') ? 'No colour' : styleData.titleTextBorderColor) || this.titleTextBorderColor;

          this.rowHeaderFont = styleData.rowHeaderFont || this.rowHeaderFont;
          this.rowHeaderFontWeight = styleData.rowHeaderFontWeight || this.rowHeaderFontWeight;
          this.rowTextSize = styleData.rowTextSize || this.rowTextSize;
          this.rowTextColor = ((styleData.rowTextColor === 'No border' || styleData.rowTextColor === 'transparent' || styleData.rowTextColor === 'No color') ? 'No colour' : styleData.rowTextColor) || this.rowTextColor;
          this.rowTextAlignment = styleData.rowTextAlignment || this.rowTextAlignment;
          this.rowTextHeight = styleData.rowTextHeight || this.rowTextHeight;
          this.rowTextBackgroundColor = ((styleData.rowTextBackgroundColor === 'No border' || styleData.rowTextBackgroundColor === 'transparent' || styleData.rowTextBackgroundColor === 'No color') ? 'No background' : styleData.rowTextBackgroundColor) || this.rowTextBackgroundColor;
          this.rowTextAlternativeBackgroundColor = ((styleData.rowTextAlternativeBackgroundColor === 'No border' || styleData.rowTextAlternativeBackgroundColor === 'transparent' || styleData.rowTextAlternativeBackgroundColor === 'No color' || styleData.rowTextAlternativeBackgroundColor === 'No alternative background' || styleData.rowTextAlternativeBackgroundColor === 'No alternate background') ? 'No background' : styleData.rowTextAlternativeBackgroundColor) || this.rowTextAlternativeBackgroundColor;
          this.rowTextHorizontalBorderWidth = styleData.rowTextHorizontalBorderWidth || this.rowTextHorizontalBorderWidth;
          this.rowTextHorizontalBorderColor = ((styleData.rowTextHorizontalBorderColor === 'No border' || styleData.rowTextHorizontalBorderColor === 'transparent' || styleData.rowTextHorizontalBorderColor === 'No color') ? 'No colour' : styleData.rowTextHorizontalBorderColor) || this.rowTextHorizontalBorderColor;

          this.totalSummaryBorderWidth = styleData.totalSummaryBorderWidth || this.totalSummaryBorderWidth;
          this.totalSummaryBorderWidthTop = styleData.totalSummaryBorderWidthTop || this.totalSummaryBorderWidthTop;
          this.totalSummaryBorderWidthRight = styleData.totalSummaryBorderWidthRight || this.totalSummaryBorderWidthRight;
          this.totalSummaryBorderWidthLeft = styleData.totalSummaryBorderWidthLeft || this.totalSummaryBorderWidthLeft;
          this.totalSummaryBorderWidthBottom = styleData.totalSummaryBorderWidthBottom || this.totalSummaryBorderWidthBottom;
          this.totalSummaryBorderWidthIndividualSide = styleData.totalSummaryBorderWidthIndividualSide || this.totalSummaryBorderWidthIndividualSide;
          this.totalSummaryBorderColor = ((styleData.totalSummaryBorderColor === 'No border' || styleData.totalSummaryBorderColor === 'transparent' || styleData.totalSummaryBorderColor === 'No color') ? 'No colour' : styleData.totalSummaryBorderColor) || this.totalSummaryBorderColor;
          this.totalSummaryBackgroundColor = ((styleData.totalSummaryBackgroundColor === 'No border' || styleData.totalSummaryBackgroundColor === 'transparent' || styleData.totalSummaryBackgroundColor === 'No color') ? 'No background' : styleData.totalSummaryBackgroundColor) || this.totalSummaryBackgroundColor;
          // this.totalSummaryPadding = styleData.totalSummaryPadding;
          // this.totalSummaryMargin = styleData.totalSummaryMargin;
          this.totalSummarySubtotalHeaderFont = styleData.totalSummarySubtotalHeaderFont || this.totalSummarySubtotalHeaderFont;
          this.totalSummarySubtotalHeaderFontWeight = styleData.totalSummarySubtotalHeaderFontWeight || this.totalSummarySubtotalHeaderFontWeight;
          this.totalSummarySubtotalTextSize = styleData.totalSummarySubtotalTextSize || this.totalSummarySubtotalTextSize;
          this.totalSummarySubtotalTextColor = ((styleData.totalSummarySubtotalTextColor === 'No border' || styleData.totalSummarySubtotalTextColor === 'transparent' || styleData.totalSummarySubtotalTextColor === 'No color') ? 'No colour' : styleData.totalSummarySubtotalTextColor) || this.totalSummarySubtotalTextColor;
          this.totalSummarySubtotalLineHeight = styleData.totalSummarySubtotalLineHeight || this.totalSummarySubtotalLineHeight;
          this.totalSummaryGrandTotalHeaderFont = styleData.totalSummaryGrandTotalHeaderFont || this.totalSummaryGrandTotalHeaderFont;
          this.totalSummaryGrandTotalHeaderFontWeight = styleData.totalSummaryGrandTotalHeaderFontWeight || this.totalSummaryGrandTotalHeaderFontWeight;
          this.totalSummaryGrandTotalTextSize = styleData.totalSummaryGrandTotalTextSize || this.totalSummaryGrandTotalTextSize;
          this.totalSummaryGrandTotalTextColor = ((styleData.totalSummaryGrandTotalTextColor === 'No border' || styleData.totalSummaryGrandTotalTextColor === 'transparent' || styleData.totalSummaryGrandTotalTextColor === 'No color') ? 'No colour' : styleData.totalSummaryGrandTotalTextColor) || this.totalSummaryGrandTotalTextColor;
          this.totalSummaryGrandTotalLineHeight = styleData.totalSummaryGrandTotalLineHeight || this.totalSummaryGrandTotalLineHeight;
          this.financeFontStyle = styleData.financeFontStyle || this.financeFontStyle;
          this.financeFontSize = styleData.financeFontSize || this.financeFontSize;
          this.financeTextColor = styleData.financeTextColor || this.financeTextColor;
          this.financeLineHeight = styleData.financeLineHeight || this.financeLineHeight;
          this.grantsFontStyle = styleData.grantsFontStyle || this.grantsFontStyle;
          this.grantsFontWeight = styleData.grantsFontWeight || this.grantsFontWeight;
          this.grantsFontSize = styleData.grantsFontSize || this.grantsFontSize;
          this.grantsTextColor = styleData.grantsTextColor || this.grantsTextColor;
          this.grantsLineHeight = styleData.grantsLineHeight || this.grantsLineHeight;
      }

  }
    ngAfterViewInit() {
        if (this.editorStatus == 'style'){
            var that = this

        }
        if($('#tax2').is(':checked')){
            this.taxInclusive = true
        }
        // this.refreshSpectrum();
    }

    updateStylesChanges(value:any, styleType){
        const styleTypeToPropertyMap = {
            'titleHeaderFont': 'titleHeaderFont',
            'titleHeaderFontWeight': 'titleHeaderFontWeight',
            'rowHeaderFont': 'rowHeaderFont',
            'rowHeaderFontWeight': 'rowHeaderFontWeight',
            'totalSummarySubtotalHeaderFont': 'totalSummarySubtotalHeaderFont',
            'totalSummarySubtotalHeaderFontWeight': 'totalSummarySubtotalHeaderFontWeight',
            'totalSummaryGrandTotalHeaderFont': 'totalSummaryGrandTotalHeaderFont',
            'totalSummaryGrandTotalHeaderFontWeight': 'totalSummaryGrandTotalHeaderFontWeight',
        };
        const propertyToUpdate = styleTypeToPropertyMap[styleType];
        if (propertyToUpdate) {
            this[propertyToUpdate] = value;
        }
        this.sendChangesToEditor();
    }

    titleAlignContent(alignment, position, alignmentType) {
        // $('.align-font').css({ opacity: '50%' });
        $('.title-set-remove-bg').removeClass(`switch-color-content-alignment-${alignmentType}`);
        $('.' + position + '').addClass(`switch-color-content-alignment-${alignmentType}`);
        if (alignmentType === 'titleTextAlignment' || alignmentType === 'rowTextAlignment') {
            this[alignmentType] = alignment;
            this.sendChangesToEditor();
        }
    }


    detectChanges(data,event : MouseEvent,tableType,actionType=''){
        let that = this;
        this.colorPalateValue = ''
        setTimeout(() => {
            that.colorPalateValue = data;
        }, 50);
        const topPosition = event.srcElement.getBoundingClientRect().top;
        if (topPosition >= 450) {
            this.openColorPalatePosition(event.srcElement, '-458px');
        } else {
            this.openColorPalatePosition(event.srcElement, 'unset');
        }
    }

    getColor(data,type){
        const typeToPropertyMap = {
            'contentBlockBorderColor': 'contentBlockBorderColor',
            'contentBlockBackgroundColor': 'contentBlockBackgroundColor',
            'tableBorderColor': 'tableBorderColor',
            'tableBorderVerticalBorderColor': 'tableBorderVerticalBorderColor',
            'titleTextColor': 'titleTextColor',
            'titleTextBackgroundColor': 'titleTextBackgroundColor',
            'titleTextBorderColor': 'titleTextBorderColor',
            'rowTextColor': 'rowTextColor',
            'rowTextBackgroundColor': 'rowTextBackgroundColor',
            'rowTextAlternativeBackgroundColor' : 'rowTextAlternativeBackgroundColor',
            'rowTextHorizontalBorderColor': 'rowTextHorizontalBorderColor',
            'totalSummaryBorderColor': 'totalSummaryBorderColor',
            'totalSummaryBackgroundColor': 'totalSummaryBackgroundColor',
            'totalSummarySubtotalTextColor': 'totalSummarySubtotalTextColor',
            'totalSummaryGrandTotalTextColor': 'totalSummaryGrandTotalTextColor',
            'financeTextColor': 'financeTextColor',
            'grantsTextColor': 'grantsTextColor'
        };
        if (typeToPropertyMap.hasOwnProperty(type)) {
            this[typeToPropertyMap[type]] = data;
        }
        this.sendChangesToEditor();
    }

    openColorPalatePosition(htmlElement, topValue){
        let nextElementPopup = $(htmlElement).next().get(0);
        $(nextElementPopup).css('top', topValue);
    }

    sendChangesToEditor(styleTobeChanged?: string) {
        let data = this.formatStylingData();
        let msg = { type: 'changeSectionStylingNew', style: data, styleTobeChanged: styleTobeChanged || null };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
    }

    formatStylingData() {
        // const getContentBlockValue = (property) => {
        //     if (this[property + 'IndividualSide']) {
        //         return [
        //             this[property + 'TopLeft'],
        //             this[property + 'TopRight'],
        //             this[property + 'BottomRight'],
        //             this[property + 'BottomLeft']
        //         ];
        //     }
        //     return this[property];
        // };
        this.contentBlockBackgroundColor = this.contentBlockBackgroundColor === 'No background' ? '#FFFFFF' : this.contentBlockBackgroundColor;
        this.contentBlockBorderColor = this.contentBlockBorderColor === 'No colour' ? ((this.contentBlockBorderWidth || this.contentBlockBorderWidthTop || this.contentBlockBorderWidthBottom || this.contentBlockBorderWidthLeft || this.contentBlockBorderWidthRight) ? '#cccccc' : this.contentBlockBorderColor) : this.contentBlockBorderColor;
        this.tableBorderColor = this.tableBorderColor === 'No colour' ? ((this.tableBorderWidth || this.tableBorderWidthTop || this.tableBorderWidthBottom || this.tableBorderWidthLeft || this.tableBorderWidthRight) ? '#cccccc' : this.tableBorderColor) : this.tableBorderColor;
        this.tableBorderVerticalBorderColor = this.tableBorderVerticalBorderColor === 'No colour' ? (this.tableBorderVerticalBorderWidth ? '#cccccc' : this.tableBorderVerticalBorderColor) : this.tableBorderVerticalBorderColor;
        this.titleTextBorderColor = this.titleTextBorderColor === 'No colour' ? ((this.titleTextBorderWidth || this.titleTextBorderWidthTop || this.titleTextBorderWidthBottom || this.titleTextBorderWidthLeft || this.titleTextBorderWidthRight) ? '#cccccc' : this.titleTextBorderColor) : this.titleTextBorderColor;
        this.totalSummaryBorderColor = this.totalSummaryBorderColor === 'No colour' ? ((this.totalSummaryBorderWidth || this.totalSummaryBorderWidthTop || this.totalSummaryBorderWidthBottom || this.totalSummaryBorderWidthLeft || this.totalSummaryBorderWidthRight) ? '#cccccc' : this.totalSummaryBorderColor) : this.totalSummaryBorderColor;
        const data = {
            contentBlockBorderWidth: this.contentBlockBorderWidth,
            contentBlockBorderWidthIndividualSide: this.contentBlockBorderWidthIndividualSide,
            contentBlockBorderWidthTop: this.contentBlockBorderWidthTop,
            contentBlockBorderWidthBottom: this.contentBlockBorderWidthBottom,
            contentBlockBorderWidthLeft: this.contentBlockBorderWidthLeft,
            contentBlockBorderWidthRight: this.contentBlockBorderWidthRight,
            contentBlockBorderColor: this.contentBlockBorderColor,
            contentBlockBorderRadius: this.contentBlockBorderRadius,
            contentBlockBorderRadiusIndividualSide: this.contentBlockBorderRadiusIndividualSide,
            contentBlockBorderRadiusTopLeft: this.contentBlockBorderRadiusTopLeft,
            contentBlockBorderRadiusTopRight: this.contentBlockBorderRadiusTopRight,
            contentBlockBorderRadiusBottomRight: this.contentBlockBorderRadiusBottomRight,
            contentBlockBorderRadiusBottomLeft: this.contentBlockBorderRadiusBottomLeft,
            contentBlockBackgroundColor: this.contentBlockBackgroundColor,
            contentBlockPadding: this.contentBlockPadding,
            contentBlockPaddingIndividualSide: this.contentBlockPaddingIndividualSide,
            contentBlockPaddingTop: this.contentBlockPaddingTop,
            contentBlockPaddingBottom: this.contentBlockPaddingBottom,
            contentBlockPaddingLeft: this.contentBlockPaddingLeft,
            contentBlockPaddingRight: this.contentBlockPaddingRight,
            contentBlockMargin: this.contentBlockMargin,
            contentBlockMarginIndividualSide: this.contentBlockMarginIndividualSide,
            contentBlockMarginTop: this.contentBlockMarginTop,
            contentBlockMarginBottom: this.contentBlockMarginBottom,
            contentBlockMarginLeft: this.contentBlockMarginLeft,
            contentBlockMarginRight: this.contentBlockMarginRight,
            tableBorderWidth: this.tableBorderWidth,
            tableBorderWidthIndividualSide: this.tableBorderWidthIndividualSide,
            tableBorderWidthTop: this.tableBorderWidthTop,
            tableBorderWidthBottom: this.tableBorderWidthBottom,
            tableBorderWidthLeft: this.tableBorderWidthLeft,
            tableBorderWidthRight: this.tableBorderWidthRight,
            tableBorderColor: this.tableBorderColor,
            tableBorderVerticalBorderWidth: this.tableBorderVerticalBorderWidth,
            tableBorderVerticalBorderColor: this.tableBorderVerticalBorderColor,
            titleHeaderFont: this.titleHeaderFont,
            titleHeaderFontWeight: this.titleHeaderFontWeight,
            titleTextSize: this.titleTextSize,
            titleTextColor: this.titleTextColor,
            titleTextAlignment: this.titleTextAlignment,
            titleTextHeight: this.titleTextHeight,
            titleTextBackgroundColor: this.titleTextBackgroundColor,
            titleTextBorderWidth: this.titleTextBorderWidth,
            titleTextBorderWidthIndividualSide: this.titleTextBorderWidthIndividualSide,
            titleTextBorderWidthTop: this.titleTextBorderWidthTop,
            titleTextBorderWidthBottom: this.titleTextBorderWidthBottom,
            titleTextBorderWidthLeft: this.titleTextBorderWidthLeft,
            titleTextBorderWidthRight: this.titleTextBorderWidthRight,
            titleTextBorderColor: this.titleTextBorderColor,
            rowHeaderFont: this.rowHeaderFont,
            rowHeaderFontWeight: this.rowHeaderFontWeight,
            rowTextSize: this.rowTextSize,
            rowTextColor: this.rowTextColor,
            rowTextAlignment: this.rowTextAlignment,
            rowTextHeight: this.rowTextHeight,
            rowTextBackgroundColor: this.rowTextBackgroundColor,
            rowTextAlternativeBackgroundColor : this.rowTextAlternativeBackgroundColor,
            rowTextHorizontalBorderWidth: this.rowTextHorizontalBorderWidth,
            rowTextHorizontalBorderColor: this.rowTextHorizontalBorderColor,
            totalSummaryBorderWidth: this.totalSummaryBorderWidth,
            totalSummaryBorderWidthIndividualSide: this.totalSummaryBorderWidthIndividualSide,
            totalSummaryBorderWidthTop: this.totalSummaryBorderWidthTop,
            totalSummaryBorderWidthBottom: this.totalSummaryBorderWidthBottom,
            totalSummaryBorderWidthRight: this.totalSummaryBorderWidthRight,
            totalSummaryBorderWidthLeft: this.totalSummaryBorderWidthLeft,
            totalSummaryBorderColor: this.totalSummaryBorderColor,
            totalSummaryBackgroundColor: this.totalSummaryBackgroundColor,
            // totalSummaryPadding: getContentBlockValue('totalSummaryPadding'),
            // totalSummaryMargin: getContentBlockValue('totalSummaryMargin'),
            totalSummarySubtotalHeaderFont: this.totalSummarySubtotalHeaderFont,
            totalSummarySubtotalHeaderFontWeight: this.totalSummarySubtotalHeaderFontWeight,
            totalSummarySubtotalTextSize: this.totalSummarySubtotalTextSize,
            totalSummarySubtotalTextColor: this.totalSummarySubtotalTextColor,
            totalSummarySubtotalLineHeight: this.totalSummarySubtotalLineHeight,
            totalSummaryGrandTotalHeaderFont: this.totalSummaryGrandTotalHeaderFont,
            totalSummaryGrandTotalHeaderFontWeight: this.totalSummaryGrandTotalHeaderFontWeight,
            totalSummaryGrandTotalTextSize: this.totalSummaryGrandTotalTextSize,
            totalSummaryGrandTotalTextColor: this.totalSummaryGrandTotalTextColor,
            totalSummaryGrandTotalLineHeight: this.totalSummaryGrandTotalLineHeight,
            financeFontStyle: this.financeFontStyle,
            financeFontSize: this.financeFontSize,
            financeTextColor: this.financeTextColor,
            financeLineHeight: this.financeLineHeight,
            grantsFontStyle: this.grantsFontStyle,
            grantsFontWeight: this.grantsFontWeight,
            grantsFontSize: this.grantsFontSize,
            grantsTextColor: this.grantsTextColor,
            grantsLineHeight: this.grantsLineHeight,
            save: true,
            currentSectionId: this.isNormalTable ? '' : this.currentSectionId,
            hashId: this.hashId,
            isNormalTable: this.isNormalTable,
            optionSummary: this.isOptionSummary ? true : false,
            sectionId: this.isNormalTable ? '' : this.currentSectionId,
        };
        return data;
    }


    getUndefinedTableDefultValue(){
        let getUndefineStyleData = this.formatStylingData();
        this.existingValues = {
            'advancedStyle' : getUndefineStyleData
        }
    }

    handleSideProperty(type: string, property: string) {
        const isIndividualSide = !this[type];
        if (isIndividualSide) {
            $('.all-side-border-radius').addClass('selected-style');
            switch (property) {
                case 'contentBlockBorderWidth':
                case 'contentBlockPadding':
                case 'contentBlockMargin':
                case 'tableBorderWidth':
                case 'titleTextBorderWidth':
                case 'rowTextBorderWidth':
                case 'totalSummaryBorderWidth':
                case 'totalSummaryPadding':
                case 'totalSummaryMargin': {
                    this[`${property}Top`] = this[`${property}Bottom`] = this[`${property}Left`] = this[`${property}Right`] = 0;
                    break;
                }
                case 'contentBlockBorderRadius': {
                    this[`${property}TopLeft`] = this[`${property}BottomLeft`] = this[`${property}TopRight`] = this[`${property}BottomRight`] = 0;
                    break;
                }
                default: {}
            }
        } else {
            $('.all-side-border-radius').removeClass('selected-style');
        }
        this[property] = 0;
        this[type] = isIndividualSide;
        this.sendChangesToEditor();
    }

    save(getJson=false) {
        let taxvalue = "Exclusive";
        if (this.taxStatus == "withtax") {
            taxvalue = "Inclusive"
            this.vatType = 'Inc';
        } else {
            this.vatType = 'Exc';
        }
        if (this.vatEnabled) {
            this.tableHeaders[3]['name'] = `Total  (${this.vatType} ${this.taxLabel})`;
        } else {
            this.tableHeaders[2]['name'] = `Total`;
        }

        let data = {
            tablePkId: this.tablePkId,
            tableType: this.tableType,
            value: taxvalue,
            type: "POST"
        }
        let breakdownSwitch = this.breakdown;
        let breakdownView = this.breakdownView;
        let tax = this.taxStatus;
        let showHideColumns = this.columnShowHide;
        let currentTableID = this.isNormalTable ? this.hashId : this.isOptionSummary == true ? "pricing-items-breakup-tpl_" + this.currentSectionId :  "pricing_cs_component_" + this.currentSectionId;
        let visibleColumn = this.tableHeaders;
        let stylingData:any = '';


        let msg = { type: 'saveChangesNew' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');

        if (this.existingValues && this.existingValues.hasOwnProperty('advancedStyle') && this.editorStatus == 'config') {
            stylingData = this.existingValues.advancedStyle;
        }
        else if (this.editorStatus == 'style') {
            stylingData = this.formatStylingData();
        }
        let options:any = { 'breakdownSwitch': breakdownSwitch, 'breakdownView': breakdownView, 'tax': tax, 'tableId': currentTableID, 'subtotalVisible': this.subtotalVisible, 'currentSectionId': this.currentSectionId, 'visibleColumn': visibleColumn, 'listHideShow': this.listHideShow, 'showHideColumns': showHideColumns, 'advancedStyle': stylingData,'isOptionSummary':this.isOptionSummary,'isNormalTable':this.isNormalTable,'taxData':data,'editorStatus':this.editorStatus };
        if(getJson){
            return options;
        }
        else{
            options.save = true
            let msg = { type: 'breakdown', options, taxChange: this.taxChange }
            this.taxChange = false;
            window.top.postMessage(msg, '*')
            setTimeout(() => {
                this.bsModalRef.hide();
            }, 300);
        }

        // this.taxChange = false;
        this.proposaltemplateservice.setEditorThumbnail('updateTableElement');
    }
    cancel(){
        let msg = { type: 'revertAppliedStylesNew', sectionId: this.currentSectionId, tableType :  this.isOptionSummary ? 'optionSummary' : 'pricingSection' };
        $('#cs_editor').get(0).contentWindow.postMessage(msg, '*');
        // let data:any = this.save(true)
        // this.onClose.next(data);
        this.bsModalRef.hide();
    }

    taxStatusToggle(val) {
        this.taxChange = !this.taxChange;
        this.taxStatus = val;
        if(val == 'withtax'){
            this.taxInclusive = true;
            if($('#yes').is(':checked')){
                $('#yes').prop('checked', false)
                $('#no').prop('checked', true)
            }
        } else {
            this.taxInclusive = false;
            $('#yes').prop('checked', true);
            this.subtotalVisible = 'yes';
            if ($('#no').is(':checked')) {
                $('#yes').prop('checked', true);
                $('#no').prop('checked', false);
            }
        }
    }

    priceTableBreakdown(val) {
        this.showPriceBreakdown = val;
        if (val == "withtblbreakdown") {
            this.columnShowHide = true;
        }
        else {
            this.columnShowHide = false;
        }
    }

    visibleColumnChange(evt, id) {
        if (evt.target.checked) {
            this.tableHeaders[id].checked = true;
        }
        else if (!evt.target.checked) {
            this.tableHeaders[id].checked = false;
        }

        let hiddenColumn = [];
        this.tableHeaders.forEach((element, index, columnHeader) => {
            if (!element.checked) {
                hiddenColumn.push(element.value);
            }
        });
        this.checkedTableColumnCount = this.tableHeaders.filter(header => header.checked).length;
    }

    horizontalVisibilityToggle(val) {
        this.breakdownView = val;
        if (val == "horizontal") {
            this.horizontalVisibility = true;
        }
        else {
            this.horizontalVisibility = false;
        }
    }

    subtotalStatusChange(val) {
        this.subtotalVisible = val;
    }


}

