<div class="modal-header">
    <h4 class="modal-title pull-left">Add job task</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <div *ngIf="status && !isLoading && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <ng-container *ngIf="!isLoading">
        <app-condition-edge *ngIf="isConditional"
            [inputModel]="inputModel"
            [operators]="operators"
            [isReadOnlyMode]="isReadOnlyMode"
            [activeField] = "activeField">
        </app-condition-edge>
        <div class="item clearfix p-15" >
            <div class="control">
                <label class="control-label required_field">Add job description</label>
                <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                    [disabled]="isMarketPlaceView"
                    csSelect2
                    [select2Config]="{}"
                    [dropdownvalue]="inputModel.model.jobDescriptionId"
                    [(ngModel)]="inputModel.model.jobDescriptionId">
                    <option value="" disabled translate>Please.Choose</option>
                    <option *ngFor="let item of jobDescriptions" [value]="item.id">{{item.text}}</option>
                </select>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer" *ngIf="!isLoading">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
    <button class="btn btn-primary" (click)="addValue()" [disabled]="this.validateItems()">
        <span translate>Save</span>
    </button>
</div>
