import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    NgCollapseExpandAnimation,
    NgSlideInOutAnimation
} from "@app/shared/service-reminder-communication/services/animations";
import {
    FollowUpInputModel,
    InputModel, MessageInput,
    MessagesObj
} from "@app/shared/service-reminder-communication/services/input-model";
import {TranslateService} from "@ngx-translate/core";
import {EventDispatcherService} from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import {Subscription} from "rxjs";
import {ConfirmModalConfig} from "@app/interfaces/common-modal-popups";
import {ModalDirective} from "ngx-bootstrap";
import {AuthService} from "@app/core";

@Component({
    selector: 'app-sc-template-form',
    templateUrl: './sc-template-form.component.html',
    styleUrls: ['./sc-template-form.component.scss'],
    animations: [NgSlideInOutAnimation, NgCollapseExpandAnimation],
})
export class ScTemplateFormComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    singleAnimationState:string = 'in';
    multipleAnimationState:string = 'out';
    semicircle: boolean = false;
    radius: number = 20;
    sourceId: number = 0;
    isValidForm: boolean = false;
    inValidInterval: boolean = false;

    currentActiveMenuName: string = '';
    currentActiveInputModel: InputModel = null;
    currentActiveMessages: MessagesObj = {};

    totalTasksPercentage: number = 0;
    totalTasksCount: number = 0;
    totalTasksCompletedCount: number = 0;
    singlePropTotalTasksCount: number = 0;
    singlePropCompletedTasksCount: number = 0;
    multiplePropTotalTasksCount: number = 0;
    multiplePropCompletedTasksCount: number = 0;

    isLoading: boolean = true;
    isLoadingTemplate: boolean = true;
    intervalItems: any = [
        {id: 2,  text: '2 days'},
        {id: 3,  text: '3 days'},
        {id: 4,  text: '4 days'},
        {id: 5,  text: '5 days'},
        {id: 6,  text: '6 days'},
        {id: 7,  text: '7 days'},
        {id: 10, text: '10 days'},
        {id: 14, text: '14 days'},
        {id: 20, text: '20 days'},
    ];
    lastIntervalVal: number = 0;

    select2Options4Interval: any = {
        minimumResultsForSearch: -1,
        allowClear: false
    };
    select2Options4Email: any = {};
    select2Options4Sms: any = {};
    select2InstanceInterval:any = null;
    select2InstanceEmail:any = null;
    select2InstanceSms:any = null;

    emailTemplateList: any = [];
    smsTemplateList: any = [];
    emailTemplateListIndexById: any = {};
    smsTemplateListIndexById: any = {};

    csScrollBarActivated: boolean = false;

    curIndex: number|undefined = undefined;

    @Input() singlePropertyInputModel: InputModel;
    @Input() multiplePropertyInputModel: InputModel;
    @Input() activeTab: string|'single'|'multiple' = 'single';
    @Input() viewMode: boolean;

    @ViewChild('actionModal', { static: false } as any) actionModal: ModalDirective;

    showConfirmModel: boolean = false;

    //confirm modal options
    inValidConfirmText: boolean = false;
    deleteConfirmTextInput: string = '';
    buttonText:string = 'Delete';
    confirmText:string = 'DELETE';
    confirmLoading: boolean = false;
    deleteError: boolean = false;
    validationMessage:string = '\'DELETE\' to remove the follow up';
    defaultTemplates: any = {};
    smsScreenLabel:string = this.translate.instant('sms.templates');
    emailScreenLabel:string = this.translate.instant('Email.template');
    letterScreenLabel:string = this.translate.instant('letter.templates');
    selectedSmsTemplate: string = '';
    selectedEmailTemplate: string = '';
    smsModulePermission: boolean;
    selectedTemplateTitles: Object = {
        sms: '',
        email: '',
        letter: ''
    }
    constructor(
        private translate: TranslateService,
        private eventDispatcher: EventDispatcherService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.select2Options4Interval['placeholder'] = 'Please.Choose';
        this.select2Options4Interval['data'] = this.intervalItems;
        this.smsModulePermission  = this.authService.hasModuleAccess('Sms');
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: ( data: object) => {
                if(typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                const value = data['data'];
                if(action === EventDispatcherService.CONSTANTS['init']) {
                    this.sourceId = value['sourceId'];
                    this.singlePropertyInputModel = value['singlePropertyInput'];
                    this.multiplePropertyInputModel = value['multiplePropertyInput'];
                    // this.defaultTemplates = value['defaultTemplates'];
                    this.setEmailTemplateList(value['emailTemplateList']);
                    this.setSmsTemplateList(value['smsTemplateList']);
                    this.emailTemplateListIndexById = value['emailTemplateListIndexById'];
                    this.smsTemplateListIndexById = value['smsTemplateListIndexById'];
                    this.select2Options4Email['data'] = this.emailTemplateList;
                    this.select2Options4Sms['data'] = this.smsTemplateList;
                    this.isLoading = false;
                    this.isLoadingTemplate = false;
                    if(this.activeTab == 'single') {
                        this.singlePropertyInputModel.setActive();
                        this.currentActiveMenuName = this.singlePropertyInputModel.itemName;
                        this.currentActiveInputModel = this.singlePropertyInputModel;
                    }
                    if(this.activeTab == 'multiple') {
                        this.multiplePropertyInputModel.setActive();
                        this.currentActiveMenuName = this.multiplePropertyInputModel.itemName;
                        this.currentActiveInputModel = this.multiplePropertyInputModel;
                    }
                    this.currentActiveMessages = this.currentActiveInputModel.messages;
                    this.setCommunicationTitles();
                    this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['currentActiveInput'], {
                        currentActiveInputModel: this.currentActiveInputModel
                    });
                    //this.setPropTasksCount();
                    this.dispatchCheckIsLastIndexEvent();
                    this.checkValidation();
                }
                else if(action == EventDispatcherService.CONSTANTS['currentActiveInput']) {
                    /*if( this.currentActiveInputModel.interval ) {
                        this.currentActiveInputModel.status = 'completed';
                    }
                    this.setPropTasksCount();*/
                }
                else if(action == EventDispatcherService.CONSTANTS['goToNextItem']) {
                    if(value['actionName'] == 'onSelectTabMenu')
                        this.onSelectTabMenu( value['activeTab']);
                    else if(value['actionName'] == 'onClickItem') {
                        this.onClickItem(value['nextActiveItem'], value['index']);
                    }
                }
            }
        });
    }

    setCommunicationTitles(){
        let currentModal = this.currentActiveInputModel.messages;
        if(currentModal.hasOwnProperty('email') && currentModal.email && currentModal.email.hasOwnProperty('title')){
            this.selectedTemplateTitles['email'] = currentModal.email.title;
        }
        else{
            this.selectedTemplateTitles['email'] = '';
        }
        if(currentModal.hasOwnProperty('sms') && currentModal.sms && currentModal.sms.hasOwnProperty('title')){
            this.selectedTemplateTitles['sms'] = currentModal.sms.title;
        }
        else{
            this.selectedTemplateTitles['sms'] = '';
        }
        if(currentModal.hasOwnProperty('letter') && currentModal.letter && currentModal.letter.hasOwnProperty('title')){
            this.selectedTemplateTitles['letter'] = currentModal.letter.title;
        }
        else{
            this.selectedTemplateTitles['sms'] = '';
        }
    }

    checkConfirmText() {
        this.inValidConfirmText = !(this.deleteConfirmTextInput.trim().toUpperCase() == this.confirmText);
    }

    checkIsLastItem() {
        let inputModelProperty:InputModel;
        if(this.activeTab == 'single') {
            inputModelProperty = this.singlePropertyInputModel;
        }
        else if(this.activeTab == 'multiple') {
            inputModelProperty = this.multiplePropertyInputModel;
        }
        if(this.currentActiveInputModel.propertyType == 1) {
            return false;
        }

        if(this.currentActiveInputModel.propertyType == 2) {
            if(this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length > 0)
                return false;
            else {
                return (this.currentActiveInputModel.isFollowUpItem == false && this.currentActiveInputModel.followUpMessage.length == 0) ||
                    (this.currentActiveInputModel instanceof FollowUpInputModel && inputModelProperty.followUpMessage.length == this.currentActiveInputModel.followUpOrder);
            }
        }
    }

    onSelectTabMenu(current:string) {
        if(current == this.activeTab) {
            return;
        }
        this.reloadTemplate();
        this.toggleAnimationDiv(current);
        this.activeTab = current;
        this.setActiveItem();

        this.currentActiveMessages = this.currentActiveInputModel.messages;
        this.filteredByMenuItemClicked(null);


        this.checkValidation();
        // this.setPropTasksCount();
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        })
        this.dispatchCheckIsLastIndexEvent();
        this.dispatchUpdatedInputModel();
        this.setCommunicationTitles();
    }


    setActiveItem() {
        if(this.activeTab == 'single') {
            this.singlePropertyInputModel.setActive();
            this.multiplePropertyInputModel.setInactive();
            this.currentActiveMenuName = this.singlePropertyInputModel.itemName;
            this.currentActiveInputModel = this.singlePropertyInputModel;
        }
        if(this.activeTab == 'multiple') {
            this.multiplePropertyInputModel.setActive();
            this.singlePropertyInputModel.setInactive();
            this.currentActiveMenuName = this.multiplePropertyInputModel.itemName;
            this.currentActiveInputModel = this.multiplePropertyInputModel;
        }
    }

    checkValidation() {
        const inputModel: InputModel = this.activeTab == 'single' ? this.singlePropertyInputModel : this.multiplePropertyInputModel;
        /*if(inputModel.status == 'completed')
            this.isValidForm = true;

        if(inputModel.followUpMessage.length >  0) {
            inputModel.followUpMessage.forEach( (followUpInputModel: InputModel) => {
                if(followUpInputModel.status != 'completed') {
                    this.isValidForm = false;
                }
            });
        }*/
        this.isValidForm = this.currentActiveInputModel.status === 'completed';
        if(this.currentActiveInputModel.status == 'pending') {
            const hasInValidMsg = (element:MessageInput, index:number, messages:[]) => {
                return element.templateId == null;
            };
            if(this.currentActiveInputModel.interval == null) {
                this.isValidForm = false;
            }
            else {
                this.isValidForm = !Object.values(this.currentActiveInputModel.messages).every( hasInValidMsg );
            }



            if(this.isValidForm) {
                this.currentActiveInputModel.status = 'completed';
                this.currentActiveInputModel.completedTasksCount++;
            }

            /*else {
                this.currentActiveInputModel.status = 'pending';
                this.currentActiveInputModel.completedTasksCount--;
            }*/
        }
        if(this.isValidForm && inputModel.followUpMessage.length > 0) {
            const curVal = this.currentActiveInputModel.interval;
            this.isValidForm = !inputModel.followUpMessage.some( (item, index) => this.curIndex != index && curVal == item.interval);
            this.inValidInterval = !this.isValidForm;
        }
        this.setPropTasksCount();
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['isValidForm'], {
            isValidForm: this.isValidForm
        });
    }

    isValidInterval() {
        console.log('typeof this.currentActiveInputModel', this.currentActiveInputModel.interval)
        return typeof this.currentActiveInputModel.interval === "number";
    }

    getSinglePropTasksCount() {
        let count = this.singlePropertyInputModel.tasksCount;
        let completedCount = this.singlePropertyInputModel.completedTasksCount;
        if(this.singlePropertyInputModel.followUpMessage.length >  0) {
            this.singlePropertyInputModel.followUpMessage.forEach( (followUpInputModel: InputModel) => {
                count = count + followUpInputModel.tasksCount;
                completedCount = completedCount + followUpInputModel.completedTasksCount;
            });
        }
        this.singlePropTotalTasksCount = count;
        this.singlePropCompletedTasksCount = completedCount;
    }

    getMultiplePropTasksCount() {
        let count = this.multiplePropertyInputModel.tasksCount;
        let completedCount = this.multiplePropertyInputModel.completedTasksCount;
        if(this.multiplePropertyInputModel.followUpMessage.length >  0) {
            this.multiplePropertyInputModel.followUpMessage.forEach( (followUpInputModel: InputModel) => {
                count = count + followUpInputModel.tasksCount;
                completedCount = completedCount + followUpInputModel.completedTasksCount;
            });
        }
        this.multiplePropTotalTasksCount = count;
        this.multiplePropCompletedTasksCount = completedCount;
    }

    setPropTasksCount() {
        this.getSinglePropTasksCount();
        this.getMultiplePropTasksCount();
        this.getTasksInPercentage();
    }

    getTasksInPercentage() {
        this.totalTasksCount = this.singlePropTotalTasksCount + this.multiplePropTotalTasksCount;
        this.totalTasksCompletedCount = this.singlePropCompletedTasksCount + this.multiplePropCompletedTasksCount;
        this.totalTasksPercentage =  Math.round( (this.totalTasksCompletedCount / this.totalTasksCount) * 100 );
        return this.totalTasksPercentage;
    }

    onClickItem(inputModel: InputModel, index:number = undefined) {
        this.currentActiveMenuName = inputModel.itemName;
        this.currentActiveInputModel = inputModel;
        //this.select2InstanceInterval.select2('val', inputModel.interval);
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.curIndex = index;
        this.filteredByMenuItemClicked(index);
        this.checkValidation();
        //this.setPropTasksCount();
        this.reloadTemplate();
        this.dispatchUpdatedInputModel();
        this.dispatchCheckIsLastIndexEvent();
        this.setCommunicationTitles();
    }

    toggleAnimationDiv(tabName: string) {
        this.multipleAnimationState = this.multipleAnimationState === 'out' ? 'in' : 'out';
        this.singleAnimationState = this.singleAnimationState === 'out' ? 'in' : 'out';
    }

    onChangeIntervalTemplate(value:any) {
        if(this.currentActiveInputModel.interval == value) {
            return;
        }
        const oldValue = this.currentActiveInputModel.interval;
        this.currentActiveInputModel.interval = value ? parseInt(value) : null;
        if(!oldValue && value) {
            //this.currentActiveInputModel.status = 'completed';
            //this.currentActiveInputModel.completedTasksCount++;
            //this.lastIntervalVal = this.currentActiveInputModel.interval;
        }
        if(oldValue && !value) {
            this.currentActiveInputModel.status = 'pending';
            this.currentActiveInputModel.completedTasksCount--;
        }
        this.checkValidation();
        //this.setPropTasksCount();

        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['currentActiveInput'], {
            currentActiveInputModel: this.currentActiveInputModel,
            eventName: 'onChangedInterval'
        });
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();

    }

    setTemplateDetails(template,type){
        switch(type){
            case 'email':
                this.onChangeEmailTemplate(template);
                break;
            case 'sms':
                this.onChangeSmsTemplate(template);
                break;
            case 'letter':
                this.onChangeLetterTemplate(template);
                break;
        }
    }

    onChangeEmailTemplate(value:any) {
        if(this.currentActiveInputModel.messages['email'].templateId == parseInt(value.templateId)) {
            return;
        }
        const oldValue = this.currentActiveInputModel.messages['email'].templateId;
        this.currentActiveInputModel.messages['email'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if(value) {
            this.currentActiveInputModel.messages['email'].message = value['message'];
            this.currentActiveInputModel.messages['email'].subject = value['subject'];
            this.currentActiveInputModel.messages['email'].title = value['templateName'];
        }
        if(!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if(oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'email',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    }

    onChangeSmsTemplate(value:any) {
        if(this.currentActiveInputModel.messages['sms'].templateId == parseInt(value.templateId)) {
            return;
        }
        const oldValue = this.currentActiveInputModel.messages['sms'].templateId;
        this.currentActiveInputModel.messages['sms'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if(value) {
            // const smsMessageItem = this.smsTemplateListIndexById[value];
            this.currentActiveInputModel.messages['sms'].message = value['message'];
            this.currentActiveInputModel.messages['sms'].title = value['templateName'];
            this.currentActiveInputModel.messages['sms'].subject = '';
        }
        if(!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if(oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'sms',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    }

    onChangeLetterTemplate(value:any) {
        if(this.currentActiveInputModel.messages['letter'].templateId == parseInt(value.templateId)) {
            return;
        }
        const oldValue = this.currentActiveInputModel.messages['letter'].templateId;
        this.currentActiveInputModel.messages['letter'].templateId = value.templateId ? parseInt(value.templateId) : null;
        if(value) {
            // const smsMessageItem = this.smsTemplateListIndexById[value];
            this.currentActiveInputModel.messages['letter'].message = value['message'];
            this.currentActiveInputModel.messages['letter'].title = value['templateName'];
            this.currentActiveInputModel.messages['letter'].subject = '';
        }
        if(!oldValue && value) {
            //this.currentActiveInputModel.completedTasksCount++;
        }
        if(oldValue && !value) {
            //this.currentActiveInputModel.completedTasksCount--;
        }
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedTemplate'], {
            'changedTemplate': 'letter',
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.setPropTasksCount();
        this.dispatchUpdatedInputModel();
        this.dispatchUnSavedChanges();
    }

    dispatchUpdatedInputModel() {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['dispatchUpdatedForm'], {
            singlePropertyInputModel: this.singlePropertyInputModel,
            multiplePropertyInputModel: this.multiplePropertyInputModel,
            currentActiveInputModel: this.currentActiveInputModel,
        })
    }

    dispatchUnSavedChanges() {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['unSavedChanges'], {
            unSaveConfirmation: true
        })
    }

    dispatchPushDeletedItem(deletedItem: number) {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['pushDeletedItem'], {
            deletedItem
        })
    }

    dispatchCheckIsLastIndexEvent() {
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['checkIsLastIndex'], {
            checkIsLastIndex: this.checkIsLastItem()
        })
    }

    addFollowUpMessage() {
        let MainInputModel: InputModel;
        if(this.activeTab =='single') {
            MainInputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
        }
        const index = MainInputModel.followUpMessage.length + 1;
        const name = this.translate.instant('Follow.Up');
        const inputModel = new FollowUpInputModel(
            this.sourceId,
            0,
            0,
            name + ' '+ index,
            MainInputModel.propertyType,
            MainInputModel.screenType,
            null,
            {},
            []
        );
        inputModel._intervalOld = null;
        inputModel.followUpOrder = index;
        inputModel.itemName = 'follow.up.'+index;
        inputModel.isFollowUpItem = true;
        inputModel.setDefaultMessages();
        this.currentActiveMenuName = inputModel.itemName;
        MainInputModel.followUpMessage.push(inputModel);
        this.curIndex = index-1;
        this.currentActiveInputModel = inputModel;
        //this.select2InstanceInterval.select2('val', inputModel.interval);
        this.eventDispatcher.dispatchEvent(EventDispatcherService.CONSTANTS['onChangedMenuItem'], {
            activeTab: this.activeTab,
            currentActiveInputModel: this.currentActiveInputModel
        });
        this.checkValidation();
        // this.filteredIntervalInput();
        this.filteredByMenuItemClicked(index);
        // this.setPropTasksCount();
        this.reloadTemplate();
        this.dispatchUpdatedInputModel();
        this.setCommunicationTitles();
    }

    onScrollDown() {
        this.csScrollBarActivated = true;
    }

    onScrollTopReach() {
        this.csScrollBarActivated = false;
    }

    filteredIntervalInput() {
        let lastInterval: number;
        if(this.activeTab == 'single') {
            lastInterval = this.singlePropertyInputModel.getLastInterval();
        }
        else if (this.activeTab == 'multiple') {
            lastInterval = this.multiplePropertyInputModel.getLastInterval();
        }
        if(!lastInterval) {
            lastInterval = 0;
        }
        let filterIntervalItems:any[] = [];
        if(this.currentActiveInputModel.isFollowUpItem && lastInterval > 0) {
            filterIntervalItems = this.intervalItems.filter((item) => {
                return item['id'] > lastInterval;
            });
        }
        else {
            filterIntervalItems = this.intervalItems;
        }
        this.select2Options4Interval['data'] = filterIntervalItems;
    }

    filteredByMenuItemClicked(index) {
        let MainInputModel: InputModel;
        if(this.activeTab == 'single') {
            MainInputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
        }
        const isMain = !this.currentActiveInputModel.isFollowUpItem;
        const hasFollowups = MainInputModel.followUpMessage.length > 0;
        const currentValue = this.currentActiveInputModel.interval;
        if(isMain) {
            this.select2Options4Interval['data'] = this.intervalItems;
            return;
        }

        if(hasFollowups) {
            let selectedIntervals:any[] = MainInputModel.getAllSelectedIntervals();
            this.select2Options4Interval['data'] = this.intervalItems.filter((item) => {
                return currentValue == item['id'] || !selectedIntervals.includes(item['id'])
            });
        }
    }

    /*_filteredByMenuItemClicked(index)  {
        let selectedIntervals: any[];
        let MainInputModel: InputModel;
        if(this.activeTab == 'single') {
            MainInputModel = this.singlePropertyInputModel;
            selectedIntervals = MainInputModel.getAllSelectedIntervals();
        }
        else if (this.activeTab == 'multiple') {
            MainInputModel = this.multiplePropertyInputModel;
            selectedIntervals = MainInputModel.getAllSelectedIntervals();
        }
        const isMain = !this.currentActiveInputModel.isFollowUpItem;
        const hasFollowups = MainInputModel.followUpMessage.length > 0;
        const currentValue = this.currentActiveInputModel.interval;
        let range = {};
        if(isMain && !hasFollowups) {
            this.select2Options4Interval['data'] = this.intervalItems;
            return;
        }
        else if(isMain && hasFollowups) {
            let firstFollowUpInputModel: InputModel = MainInputModel.followUpMessage[0];
            let inputVal = firstFollowUpInputModel.interval;
            if(inputVal) {
                range['less'] = inputVal;
            }
        }
        else if(!isMain) {
            if(index == 0)  {
                let prevInputVal = MainInputModel.interval;
                let nextInputVal = null;
                let hasNextIndex = MainInputModel.followUpMessage.length > 1;
                if(hasNextIndex) {
                    nextInputVal = MainInputModel.followUpMessage[1].interval;
                }
                range['greater'] = prevInputVal;
                if(nextInputVal) {
                    range['less'] = nextInputVal;
                }
            }
            else {
                let isLastIndex = index === (MainInputModel.followUpMessage.length - 1);
                let prevIndex = index - 1;
                let prevInputVal = MainInputModel.followUpMessage[prevIndex].interval;
                let nextInputVal = null;
                range['greater'] = prevInputVal;
                if(!isLastIndex) {
                    let nextIndex = index + 1;
                    nextInputVal = MainInputModel.followUpMessage[nextIndex].interval;
                    if(nextInputVal) {
                        range['less'] = nextInputVal;
                    }
                }
            }
        }

        let rangeLength = Object.keys(range).length;
        this.select2Options4Interval['data'] = this.intervalItems.filter((item) => {
            if(range['less'] && range['greater']) {
                return item['id'] < range['less'] && item['id'] > range['greater']
            }
            if(range['less']) {
                return  item['id'] < range['less'];
            }
            else if (range['greater']) {
                return  item['id'] > range['greater'];
            }
            else {
                return true;
            }
        });

    }*/

    /*disabledIntervalAlreadyAdded(itemId:number) {
        return this.singlePropertyInputModel.getAllSelectedIntervals().includes(itemId);
    }*/

    getOverlayStyle() {
        const isSemi = this.semicircle;
        const transform = (isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';
        return {
            top: isSemi ? 'auto' : '50%',
            bottom: isSemi ? '5%' : 'auto',
            left: '50%',
            transform,
            fontSize: '12px',
        };
    }

    setEmailTemplateList(data: any) {
        if(!data.length) {
            return;
        }
        data.forEach( (item: any) => {
            this.emailTemplateList.push({
                id: parseInt(item.id),
                text: item.templateName
            });
            // this.emailTemplateListIndexById[item.id] = item;
        });
    }

    setSmsTemplateList(data: any) {
        if(!data.length) {
            return;
        }
        data.forEach( (item: any) => {
            this.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            // this.smsTemplateListIndexById[item.id] = item;
        });
    }

    reloadTemplate() {
        this.isLoadingTemplate=true;
        setTimeout(() => {
            this.isLoadingTemplate=false;
        }, 1000);
    }

    setSelect2InstanceInterval(instance) {
        this.select2InstanceInterval = instance;
    }

    setSelect2InstanceEmail(instance) {
        this.select2InstanceEmail = instance;
    }

    setSelect2InstanceSms(instance) {
        this.select2InstanceSms = instance;
    }

    onShowConfirmModal() {
        this.showConfirmModel = true;
        this.actionModal.show();
    }
    onHiddenModal() {
        this.showConfirmModel = false;
        this.actionModal.hide();
        this.confirmLoading = false;
        this.deleteConfirmTextInput = '';
    }

    deleteAction() {
        this.confirmLoading = true;
        let inputModel;
        if(this.activeTab == "single") {
            inputModel = this.singlePropertyInputModel;
        }
        else if (this.activeTab == "multiple") {
            inputModel = this.multiplePropertyInputModel;
        }
        if(inputModel && typeof this.curIndex != undefined && this.curIndex > -1) {
            const deletedItem = this.currentActiveInputModel.serviceReminderConfigId;
            inputModel.removeFollowupItem(this.curIndex);
            this.dispatchUnSavedChanges();
            if(deletedItem) {
                this.dispatchPushDeletedItem(deletedItem);
            }
            inputModel.followUpMessage.forEach( (item: FollowUpInputModel, index:number) => {
                const count = index+1;
                item.name = this.translate.instant('Follow.Up') + ' ' + count;
                item.followUpOrder = count;
            });
            this.onClickItem(inputModel);
        }
        this.onHiddenModal();
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
