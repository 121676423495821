/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./segments/communication-segment.component.ngfactory";
import * as i2 from "./segments/communication-segment.component";
import * as i3 from "@angular/common";
import * as i4 from "./template-form/template-form.component.ngfactory";
import * as i5 from "./template-form/template-form.component";
import * as i6 from "../services/communication-dispatcher.service";
import * as i7 from "../../../sidepanel/services/sidepanel-modal.service";
import * as i8 from "../services/communication-helper.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../core/auth/auth.service";
import * as i11 from "./templates/communication-template-view.component.ngfactory";
import * as i12 from "./templates/communication-template-view.component";
import * as i13 from "./communication-layout.component";
import * as i14 from "../services/communication-http.service";
var styles_CommunicationLayoutComponent = [];
var RenderType_CommunicationLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommunicationLayoutComponent, data: {} });
export { RenderType_CommunicationLayoutComponent as RenderType_CommunicationLayoutComponent };
function View_CommunicationLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-communication-segment", [], null, null, null, i1.View_CommunicationSegmentComponent_0, i1.RenderType_CommunicationSegmentComponent)), i0.ɵdid(1, 114688, null, 0, i2.CommunicationSegmentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_CommunicationLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "comm-layout-container h-100"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommunicationLayoutComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-template-form", [], null, [[null, "selectedRolesEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedRolesEvent" === en)) {
        var pd_0 = (_co.selectedRoles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TemplateFormComponent_0, i4.RenderType_TemplateFormComponent)), i0.ɵdid(4, 4964352, null, 0, i5.TemplateFormComponent, [i6.CommunicationDispatcherService, i7.SidepanelModalService, i8.CommunicationHelperService, i9.TranslateService, i10.AuthService, "confirmationBoxHelper", "$scope"], { roleList: [0, "roleList"], previousRolesList: [1, "previousRolesList"], selectedTemplateData: [2, "selectedTemplateData"], viewMode: [3, "viewMode"] }, { selectedRolesEvent: "selectedRolesEvent" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-communication-template-view", [], null, null, null, i11.View_CommunicationTemplateViewComponent_0, i11.RenderType_CommunicationTemplateViewComponent)), i0.ɵdid(6, 245760, null, 0, i12.CommunicationTemplateViewComponent, [i6.CommunicationDispatcherService, i8.CommunicationHelperService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowSegmentation; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.roleList; var currVal_2 = _co.previousRolesList; var currVal_3 = _co.selectedTemplateData; var currVal_4 = _co.viewMode; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); _ck(_v, 6, 0); }, null); }
export function View_CommunicationLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-communication-layout", [], null, null, null, View_CommunicationLayoutComponent_0, RenderType_CommunicationLayoutComponent)), i0.ɵdid(1, 245760, null, 0, i13.CommunicationLayoutComponent, [i14.CommunicationHttpService, i6.CommunicationDispatcherService, i8.CommunicationHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommunicationLayoutComponentNgFactory = i0.ɵccf("app-communication-layout", i13.CommunicationLayoutComponent, View_CommunicationLayoutComponent_Host_0, { objectName: "objectName", isCustomerSpecific: "isCustomerSpecific", allowSegmentation: "allowSegmentation", communicationScreenType: "communicationScreenType", allowTypes: "allowTypes", objectId: "objectId", objectTypeId: "objectTypeId", categoryType: "categoryType", selectedTemplateData: "selectedTemplateData", viewMode: "viewMode" }, { $instance: "$instance" }, []);
export { CommunicationLayoutComponentNgFactory as CommunicationLayoutComponentNgFactory };
