import { ElementRef } from '@angular/core';
import { Subject } from "rxjs/index";
var SmartTableInstanceService = /** @class */ (function () {
    function SmartTableInstanceService() {
        this.datatableElement = {};
        this.dtTrigger = new Subject();
        this._datas = [];
    }
    SmartTableInstanceService.prototype.refresh = function (params) {
        var _this = this;
        if (params === void 0) { params = null; }
        if ($(this.container.nativeElement).hasClass('hide') && this._datas.length !== 0) {
            $(this.container.nativeElement).removeClass('hide');
        }
        if (params) {
            this._component.setParams(params);
        }
        setTimeout(function () {
            _this.datatableElement.dtInstance.then(function (dtInstance) {
                //dtInstance.destroy();
                //this.dtTrigger.next();
                dtInstance.clear();
                dtInstance.ajax.reload();
            });
        }, 300);
    };
    SmartTableInstanceService.prototype.getSelectedRows = function () {
        return this._datas.filter(function (data) { return data.selected === true; });
    };
    SmartTableInstanceService.prototype.checkUncheckAllRows = function (toggleSwitch) {
        this._datas.forEach(function (data) { return data.selected = toggleSwitch; });
    };
    SmartTableInstanceService.prototype.setDatas = function (datas) {
        this._datas = datas;
    };
    SmartTableInstanceService.prototype.setComponent = function (component) {
        this._component = component;
    };
    SmartTableInstanceService.prototype.getDatas = function () {
        return this._datas;
    };
    SmartTableInstanceService.prototype.columnsAdjust = function () {
        var _this = this;
        setTimeout(function () {
            _this.datatableElement.dtInstance.then(function (dtInstance) {
                dtInstance.columns.adjust();
            });
        }, 100);
    };
    SmartTableInstanceService.prototype.reload = function (data) {
        if (data === void 0) { data = null; }
        this.refresh(data);
    };
    SmartTableInstanceService.prototype.empty = function () {
        this._datas = [];
        this.dtTrigger.unsubscribe();
    };
    return SmartTableInstanceService;
}());
export { SmartTableInstanceService };
