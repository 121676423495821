import { OnInit } from '@angular/core';
import { from, Subject } from "rxjs";
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap";
import { map } from "rxjs/operators";
var ExitCriteriaSidePanelComponent = /** @class */ (function () {
    function ExitCriteriaSidePanelComponent(formBuilder, modalRef) {
        this.formBuilder = formBuilder;
        this.modalRef = modalRef;
        this.list = [];
        this.action = '';
        this.isEdit = false;
        this.formDatas = {
            name: [],
        };
        this.saving = false;
    }
    ExitCriteriaSidePanelComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        if (this.data) {
            this.isEdit = true;
            this.formDatas.name = this.exitCondition;
        }
        this.form = this.formBuilder.group({
            'exitCriteria': [this.formDatas.name, [Validators.required]]
        });
        function groupByData(data) {
            var allConditions = [];
            var newItems = [];
            newItems.push(data);
            var source = from(newItems);
            var tags = source.pipe(map(function (groupItems) {
                var _item = { text: '', children: [] };
                var cnt = 0;
                groupItems.forEach(function (group) {
                    if (cnt === 0) {
                        _item.text = group.type;
                    }
                    if (group.default == false) {
                        _item.children.push({
                            id: group.id,
                            text: group.text
                        });
                    }
                    cnt++;
                });
                return _item;
            }));
            var subscribe = tags.subscribe(function (val) { return allConditions.push(val); });
            return allConditions;
        }
        this.select2LabelConfig = {
            width: '100%',
            data: groupByData(this.exitCondition),
            tags: true,
            multiple: true
        };
    };
    Object.defineProperty(ExitCriteriaSidePanelComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    ExitCriteriaSidePanelComponent.prototype.save = function () {
        var data = this.selectedData;
        this.onClose.next(this.selectedData);
        this.modalRef.hide();
    };
    ExitCriteriaSidePanelComponent.prototype.selectedCondition = function ($event) {
        this.selectedData = $event;
        this.saving = !!this.selectedData.length;
    };
    return ExitCriteriaSidePanelComponent;
}());
export { ExitCriteriaSidePanelComponent };
