import * as tslib_1 from "tslib";
import Handsontable from 'handsontable';
export function HansontableChosen(options) {
    var HansontableChosenEditor = /** @class */ (function (_super) {
        tslib_1.__extends(HansontableChosenEditor, _super);
        function HansontableChosenEditor(hotInstance, row, col, prop, TD, cellProperties) {
            var _this = _super.call(this, hotInstance, row, col, prop, TD, cellProperties) || this;
            _this.columnSettings = {};
            _this.contract = false;
            _this.onChosenChanged = function () {
                if (!options['chosenOptions'].multiple) {
                    this.close();
                    this.finishEditing();
                    // $('.search-field').children().focus();
                }
            };
            _this.onChosenClosed = function () {
                if (!options['chosenOptions'].multiple) {
                    this.close();
                    this.finishEditing();
                }
                else {
                }
            };
            _this.onBeforeKeyDown = function (event) {
                var instance = this;
                var that = instance.getActiveEditor();
                // $('.search-field').children().focus();
                var keyCodes = Handsontable.helper.KEY_CODES;
                var ctrlDown = (event.ctrlKey || event.metaKey) && !event.altKey; //catch CTRL but not right ALT (which in some systems triggers ALT+CTRL)
                //Process only events that have been fired in the editor
                if (event.target.tagName !== "INPUT") {
                    return;
                }
                if (event.keyCode === 17 || event.keyCode === 224 || event.keyCode === 91 || event.keyCode === 93) {
                    //when CTRL or its equivalent is pressed and cell is edited, don't prepare selectable text in textarea
                    event.stopImmediatePropagation();
                    return;
                }
                var target = event.target;
                switch (event.keyCode) {
                    case keyCodes.ARROW_RIGHT:
                        if (Handsontable.dom.getCaretPosition(target) !== target.value.length) {
                            event.stopImmediatePropagation();
                        }
                        else {
                            that.$textarea.trigger("chosen:close");
                        }
                        break;
                    case keyCodes.ARROW_LEFT:
                        if (Handsontable.dom.getCaretPosition(target) !== 0) {
                            event.stopImmediatePropagation();
                        }
                        else {
                            that.$textarea.trigger("chosen:close");
                        }
                        break;
                    case keyCodes.ENTER:
                        if (options['chosenOptions'].multiple) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            // event.stopPropagation();
                        }
                        break;
                    case keyCodes.A:
                    case keyCodes.X:
                    case keyCodes.C:
                    case keyCodes.V:
                        if (ctrlDown) {
                            event.stopImmediatePropagation(); //CTRL+A, CTRL+C, CTRL+V, CTRL+X should only work locally when cell is edited (not in table context)
                        }
                        break;
                    case keyCodes.BACKSPACE:
                        var txt = $(that.TEXTAREA_PARENT).find("input").val();
                        $(that.TEXTAREA_PARENT).find("input").val(txt.substr(0, txt.length - 1)).trigger("keyup.chosen");
                        event.stopImmediatePropagation();
                        break;
                    case keyCodes.DELETE:
                        var txt = $(that.TEXTAREA_PARENT).find("input").val();
                        $(that.TEXTAREA_PARENT).find("input").val(txt.substr(0, txt.length - 1)).trigger("keyup.chosen");
                        event.stopImmediatePropagation();
                        break;
                    case keyCodes.HOME:
                    case keyCodes.END:
                        event.stopImmediatePropagation(); //backspace, delete, home, end should only work locally when cell is edited (not in table context)
                        break;
                }
            };
            _this.getValue = function () {
                if (!this.$textarea.val()) {
                    return "";
                }
                if (typeof this.$textarea.val() === "object") {
                    return this.$textarea.val().join(",");
                }
                return this.$textarea.val();
            };
            _this.beginEditing = function (initialValue) {
                var onBeginEditing = this.instance.getSettings().onBeginEditing;
                if (onBeginEditing && onBeginEditing() === false) {
                    return;
                }
                Handsontable.editors.TextEditor.prototype.beginEditing.apply(this, arguments);
            };
            _this.finishEditing = function (isCancelled, ctrlDown) {
                this.instance.listen();
                return Handsontable.editors.TextEditor.prototype.finishEditing.apply(this, arguments);
            };
            _this.columnSettings = hotInstance.getCellMeta(hotInstance.rowOffset(), hotInstance.colOffset());
            return _this;
        }
        /*prepare(row: number, col: number, prop: string | number, TD: HTMLTableCellElement, originalValue: any, cellProperties: Handsontable.CellProperties) {
            this.$textarea.val(originalValue || '');
            this.originalValue = originalValue;
            Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);
        }*/
        HansontableChosenEditor.prototype.prepare = function (row, col, prop, TD, originalValue, cellProperties) {
            Handsontable.editors.TextEditor.prototype.prepare.apply(this, arguments);
            cellProperties.chosenOptions = $.extend({}, cellProperties.chosenOptions);
        };
        HansontableChosenEditor.prototype.createElements = function () {
            this.$body = $(document.body);
            this.TEXTAREA = document.createElement('select');
            // Handsontable copy paste plugin calls this.TEXTAREA.select()
            this.TEXTAREA.select = function () { };
            //this.TEXTAREA.setAttribute('type', 'text');
            this.$textarea = $(this.TEXTAREA);
            Handsontable.dom.addClass(this.TEXTAREA, 'handsontableInput');
            this.textareaStyle = this.TEXTAREA.style;
            this.textareaStyle.width = 0;
            this.textareaStyle.height = 0;
            this.TEXTAREA_PARENT = document.createElement('DIV');
            Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
            this.textareaParentStyle = this.TEXTAREA_PARENT.style;
            this.textareaParentStyle.top = 0;
            this.textareaParentStyle.left = 0;
            this.textareaParentStyle.display = 'none';
            this.textareaParentStyle.width = "250px";
            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
            this.instance.rootElement.appendChild(this.TEXTAREA_PARENT);
            var that = this;
            this.instance._registerTimeout(setTimeout(function () {
                that.refreshDimensions();
            }, 0));
        };
        HansontableChosenEditor.prototype.open = function (keyboardEvent) {
            //this.value = $('.chzn-choices').children('li.search-choice').remove();
            this.init();
            //this.originalValue = "";
            this.refreshDimensions();
            this.textareaParentStyle.display = 'block';
            this.instance.addHook('beforeKeyDown', this.onBeforeKeyDown);
            this.$textarea.css({
                height: $(this.TD).height() + 8,
                'min-width': $(this.TD).outerWidth()
            });
            //display the list
            this.$textarea.hide();
            //make sure that list positions matches cell position
            //this.$textarea.offset($(this.TD).offset());
            var option = $.extend({}, options, {
                width: "100%",
                search_contains: true
            });
            if (option['chosenOptions'].multiple) {
                this.$textarea.attr("multiple", true);
            }
            else {
                this.$textarea.attr("multiple", false);
            }
            this.$textarea.empty();
            this.$textarea.append("<option value=''></option>");
            var el = null;
            var originalValue = (this.originalValue + "").split(",");
            if (option['chosenOptions'].data && option['chosenOptions'].data.length) {
                for (var i = 0; i < option['chosenOptions'].data.length; i++) {
                    el = $("<option />");
                    el.attr("value", option['chosenOptions'].data[i].id);
                    el.html(option['chosenOptions'].data[i].text);
                    if (originalValue.indexOf(options['chosenOptions'].data[i].id + "") > -1) {
                        el.attr("selected", true);
                    }
                    this.$textarea.append(el);
                }
            }
            if ($(this.TEXTAREA_PARENT).find(".chosen-container").length) {
                this.$textarea.chosen2("destroy");
            }
            this.$textarea.chosen2(options);
            var self = this;
            setTimeout(function () {
                self.$textarea.on('change', self.onChosenChanged.bind(self));
                self.$textarea.on('chosen:hiding_dropdown', self.onChosenClosed.bind(self));
                self.$textarea.trigger("chosen:open");
                $(self.TEXTAREA_PARENT).find("input").focus();
                $(self.TEXTAREA_PARENT).find("input").on("keydown", function (e) {
                    if (e.keyCode === Handsontable.helper.KEY_CODES.ENTER /*|| e.keyCode === Handsontable.helper.KEY_CODES.BACKSPACE*/) {
                        if ($(this).val()) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        else {
                            // e.preventDefault();
                            // e.stopPropagation();
                            // self.close();
                            // self.finishEditing();
                        }
                    }
                    if (e.keyCode == Handsontable.helper.KEY_CODES.TAB || e.keyCode == Handsontable.helper.KEY_CODES.ESCAPE) {
                        self.close();
                        self.finishEditing();
                    }
                    if (e.keyCode === Handsontable.helper.KEY_CODES.BACKSPACE) {
                        var txt = $(self.TEXTAREA_PARENT).find("input").val();
                        $(self.TEXTAREA_PARENT).find("input").val(txt.substr(0, txt.length - 1)).trigger("keyup.chosen");
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    if (e.keyCode === Handsontable.helper.KEY_CODES.ARROW_DOWN || e.keyCode === Handsontable.helper.KEY_CODES.ARROW_UP) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
                setTimeout(function () {
                    self.$textarea.trigger("chosen:activate").focus();
                    if (keyboardEvent && keyboardEvent.keyCode && keyboardEvent.keyCode != 113) {
                        var key = keyboardEvent.keyCode;
                        var keyText = (String.fromCharCode((96 <= key && key <= 105) ? key - 48 : key)).toLowerCase();
                        $(self.TEXTAREA_PARENT).find("input").val(keyText).trigger("keyup.chosen");
                        self.$textarea.trigger("chosen:activate");
                    }
                    //   $('.search-field').children().focus();
                }, 1);
            }, 1);
        };
        ;
        HansontableChosenEditor.prototype.init = function () {
            Handsontable.editors.TextEditor.prototype.init.apply(this, arguments);
        };
        ;
        HansontableChosenEditor.prototype.close = function () {
            this.instance.listen();
            this.instance.removeHook('beforeKeyDown', this.onBeforeKeyDown);
            this.$textarea.off();
            this.$textarea.hide();
            this.$textarea.chosen2("destroy");
            Handsontable.editors.TextEditor.prototype.close.apply(this, arguments);
        };
        ;
        HansontableChosenEditor.prototype.focus = function () {
            this.instance.listen();
            // DO NOT CALL THE BASE TEXTEDITOR FOCUS METHOD HERE, IT CAN MAKE THIS EDITOR BEHAVE POORLY AND HAS NO PURPOSE WITHIN THE CONTEXT OF THIS EDITOR
            //Handsontable.editors.TextEditor.prototype.focus.apply(this, arguments);
        };
        ;
        return HansontableChosenEditor;
    }(Handsontable.editors.TextEditor));
    return HansontableChosenEditor;
}
