import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsScrollbarDirective } from './cs-scrollbar.directive';

@NgModule({
    declarations: [
      CsScrollbarDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CommonModule,
        CsScrollbarDirective
    ]
})
export class CsScrollbarModule { }
