import { MicroserviceHelperService } from '@app/shared/microservice/helper/microservice-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "../../helper/microservice-helper.service";
var MicroserviceHttpEndpointInterceptorService = /** @class */ (function () {
    function MicroserviceHttpEndpointInterceptorService(microserviceHelperService) {
        this.microserviceHelperService = microserviceHelperService;
    }
    MicroserviceHttpEndpointInterceptorService.prototype.intercept = function (request, next) {
        if (this.microserviceHelperService.isReplaceableEndpointUrl(request.url)) {
            var requestReplacementUrl = this.microserviceHelperService
                .getReplaceableEndpointReplacementUrl(request.url);
            // clone the http request
            request = request.clone({
                url: requestReplacementUrl,
                headers: request.headers.set('X-CS-ENDPOINT-REPLACEMENT', 'YES')
            });
        }
        return next.handle(request);
    };
    MicroserviceHttpEndpointInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function MicroserviceHttpEndpointInterceptorService_Factory() { return new MicroserviceHttpEndpointInterceptorService(i0.inject(i1.MicroserviceHelperService)); }, token: MicroserviceHttpEndpointInterceptorService, providedIn: "root" });
    return MicroserviceHttpEndpointInterceptorService;
}());
export { MicroserviceHttpEndpointInterceptorService };
