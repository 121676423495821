import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//--
import { InterfaceService } from '@app/interfaces';
import { Customer, CustomerContract } from '@app/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CcWhatsIncludedPPMService = /** @class */ (function (_super) {
    tslib_1.__extends(CcWhatsIncludedPPMService, _super);
    function CcWhatsIncludedPPMService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /* Get properties list with ppm counts mapped into the contracts
  
      Response:
        Array<{
          relationshipId: number;
          ppmCount: number;
          id: number;
          title: string;
          name: string;
          surname: string;
          companyName: string;
          mainContact: boolean;
          parentPropertiesId: number;
          addressline1: string;
          addressline2: string;
          addressline3: string;
          town: string;
          county: string;
          postcode: string;
        }
     */
    CcWhatsIncludedPPMService.prototype.getPropertiesList = function (contractId, params) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/ppm/get-properties-list", params));
    };
    /* Get list of ppm associated to the customer contract and the property
    
        Response :
          Array<{
            id: number;
            name: string;
            description: string;
          }>
    */
    CcWhatsIncludedPPMService.prototype.getPropertyPPMList = function (contractId, propertyId, params) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/" + propertyId + "/get-ppm-list", params));
    };
    /* Get configured PPM schedules of the customer contract property
  
          Response :
              {
                contractDetails: Object;
                customerDetails: Object;
                propertyDetails: Object;
                ppmList: Array<Object>;
              }
     */
    CcWhatsIncludedPPMService.prototype.getPPMSchedules = function (contractId, propertyId, params) {
        return this.http.get(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/" + propertyId + "/get-ppm-schedules"))
            .pipe(map(function (Response) {
            Response.contractDetails = new CustomerContract(Response.contractDetails);
            Response.customerDetails = new Customer(Response.customerDetails);
            Response.propertyDetails = new Customer(Response.propertyDetails);
            return Response;
        }));
    };
    /* Enable already deleted ppms
  
        Response:
          Details of the enabled PPMs
     */
    CcWhatsIncludedPPMService.prototype.enablePPMs = function (ppmIds) {
        return this.http.put(this.getApiUrl("customer-contracts/whats-included/ppm/enable-ppms"), JSON.stringify({ enabledPPMIds: ppmIds }));
    };
    /* Validates the ppm task schedules in order to minimise the jobs count
  
         Response:
            PPM schedules list with suggested week names
     */
    CcWhatsIncludedPPMService.prototype.validatePPMConfigurations = function (contractId, configData) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/ppm/validate-ppm-schedules"), JSON.stringify(configData));
    };
    /* Saves the ppm task schedules
  
        Response:
          Full details of the saved PPM configurations
     */
    CcWhatsIncludedPPMService.prototype.savePPMConfigurations = function (contractId, propertyId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/" + contractId + "/whats-included/" + propertyId + "/ppm/save-ppm-configurations"), JSON.stringify(data));
    };
    /* Deletes the ppm configuration
       
       Response :
         'success'
     */
    CcWhatsIncludedPPMService.prototype.deletePPMConfiguration = function (ppmId) {
        return this.http.delete(this.getApiUrl("customer-contracts/whats-included/ppm/" + ppmId + "/delete"));
    };
    /* Get list of assets associated to the PPM
  
     Response :
       Array<{
         id: number;
         text: string;
       }>
   */
    CcWhatsIncludedPPMService.prototype.getPPMAssets = function (ppmId) {
        return this.http.get(this.getApiUrl("customer-contracts/whats-included/ppm/" + ppmId + "/get-assets"));
    };
    /* Saving the assets selections for the PPM property
  
      Response :
        Array<{
          id: number;
          text: string;
        }>
    */
    CcWhatsIncludedPPMService.prototype.savePPMAssets = function (ppmId, assetIds) {
        return this.http.post(this.getApiUrl("customer-contracts/whats-included/ppm/" + ppmId + "/save-assets"), JSON.stringify({
            assetIds: assetIds
        }));
    };
    /* Deleting PPM task
  
      Response :
        string
    */
    CcWhatsIncludedPPMService.prototype.deletePPMTask = function (taskId) {
        return this.http.delete(this.getApiUrl("customer-contracts/whats-included/ppm-task/" + taskId + "/delete"));
    };
    /* Add new PPM task
  
      Response :
        {
          id: number;
          shortDescription: string;
          longDescription: string;
          repeatType: string;
          schedules: Object[];
        }
    */
    CcWhatsIncludedPPMService.prototype.addPPMTask = function (ppmId, data) {
        return this.http.post(this.getApiUrl("customer-contracts/whats-included/ppm/" + ppmId + "/add-new-task"), JSON.stringify(data));
    };
    // Get list of job descriptions configured
    CcWhatsIncludedPPMService.prototype.listCcJobDescriptions = function (params) {
        return this.http
            .get(this.getApiUrl("list_ctJobdescriptions", params));
    };
    CcWhatsIncludedPPMService.prototype.getCcJobdescriptions = function (templateId) {
        return this.http.get(this.getApiUrl("customer_contract/" + templateId + "/whats-included/ppm/get-job-description"));
    };
    CcWhatsIncludedPPMService.prototype.saveCcJobDescriptions = function (templateId, data) {
        return this.http.put(this.getApiUrl("customer_contract/" + templateId + "/whats-included/ppm/job-description"), JSON.stringify(data));
    };
    CcWhatsIncludedPPMService.prototype.checkCustomerContractStatus = function (templateId) {
        return this.http.get(this.getApiUrl("customer_contract/" + templateId + "/get-template-details"));
    };
    CcWhatsIncludedPPMService.ngInjectableDef = i0.defineInjectable({ factory: function CcWhatsIncludedPPMService_Factory() { return new CcWhatsIncludedPPMService(i0.inject(i1.HttpClient)); }, token: CcWhatsIncludedPPMService, providedIn: "root" });
    return CcWhatsIncludedPPMService;
}(InterfaceService));
export { CcWhatsIncludedPPMService };
