import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
var OpportunityTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(OpportunityTemplateService, _super);
    function OpportunityTemplateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.screenType = new BehaviorSubject('');
        _this.screenType$ = _this.screenType.asObservable();
        _this.closeModal = new BehaviorSubject('');
        _this.closeModal$ = _this.closeModal.asObservable();
        _this.saveData = new BehaviorSubject('');
        _this.saveData$ = _this.saveData.asObservable();
        _this.opportuniityData = new BehaviorSubject('');
        _this.opportuniityData$ = _this.opportuniityData.asObservable();
        _this.showPage = new BehaviorSubject(false);
        _this.showPage$ = _this.showPage.asObservable();
        return _this;
    }
    // Add/Edit opporuntity templates
    OpportunityTemplateService.prototype.addOrEditOpportunityTemplate = function (data) {
        return this.http.post(this.getApiUrl("addOrEditOppurtunityTemplate"), data);
    };
    // Get job description list
    OpportunityTemplateService.prototype.getJobDescriptionList = function (type, id) {
        return this.http.get(this.getApiUrl("get_opputunity_template_add_data?type=" + type + "&id=" + id));
    };
    // Clone opportunity template
    OpportunityTemplateService.prototype.cloneOpportunityTemplate = function (id, parentId) {
        return this.http.get(this.getApiUrl("cloneOppurtunityTemplate/" + id + "/" + parentId));
    };
    // Delete opportunity template
    OpportunityTemplateService.prototype.deleteOpportunityTemplate = function (id, params) {
        return this.http.delete(this.getApiUrl("" + id + "/deleteOppurtunityTemplate", params));
    };
    // Check opportunitytemplate already exists
    OpportunityTemplateService.prototype.checkTemplateName = function (params) {
        return this.http.get(this.getApiUrl("checkOppurtunityTemplateExists", params));
    };
    // Reorder Template list
    OpportunityTemplateService.prototype.reorderTemplate = function (data) {
        return this.http.put(this.getApiUrl('reorderOppurtunityTemplates'), data);
    };
    // Set screentype
    OpportunityTemplateService.prototype.setScreenType = function (data) {
        this.screenType.next(data);
    };
    // Set screentype
    OpportunityTemplateService.prototype.setData = function (data) {
        this.saveData.next(data);
    };
    //setOpportunityData
    OpportunityTemplateService.prototype.setOpportunityData = function (data) {
        this.opportuniityData.next(data);
    };
    // Save confirmations
    OpportunityTemplateService.prototype.saveConfirmations = function (data, templateId, menuType) {
        return this.http.post(this.getApiUrl("oppurtunityConfirmation/" + templateId + "?type=" + menuType), data);
    };
    // Get Reminders
    OpportunityTemplateService.prototype.getConfirmations = function (templateId, menuType, customerId, opportunityId) {
        if (customerId === void 0) { customerId = null; }
        if (opportunityId === void 0) { opportunityId = null; }
        var params = {
            "type": menuType,
        };
        if (customerId) {
            params['customerId'] = customerId;
        }
        if (opportunityId) {
            params['opportunityId'] = opportunityId;
        }
        return this.http.get(this.getApiUrl("getOppurtunityConfirmations/" + templateId, params));
    };
    // Save Reminders
    OpportunityTemplateService.prototype.saveReminders = function (data, templateId) {
        return this.http.post(this.getApiUrl("oppurtunityReminder/" + templateId), data);
    };
    // Get Reminders
    OpportunityTemplateService.prototype.getReminders = function (id) {
        return this.http.get(this.getApiUrl("getOppurtunityReminders/" + id));
    };
    // Save notes
    OpportunityTemplateService.prototype.saveNotes = function (data, id) {
        return this.http.post(this.getApiUrl("oppurtunityNotes/" + ("" + id)), data);
    };
    // Get notes
    OpportunityTemplateService.prototype.getNotes = function (id) {
        return this.http.get(this.getApiUrl("oppurtunityNotes/" + ("" + id)));
    };
    // Call configure close function
    OpportunityTemplateService.prototype.callCloseModalFuntion = function (data) {
        this.closeModal.next(data);
    };
    //add Attachments
    OpportunityTemplateService.prototype.addAttachments = function (file, mode, templateId) {
        var formData = new FormData();
        var xhr = new XMLHttpRequest();
        formData.append('file', file, file.name);
        return this.http.post(this.getApiUrl("upload_opportunity_template_file?mode=" + mode + "&selectedId=" + templateId), formData);
    };
    //Delete Attachments
    OpportunityTemplateService.prototype.deleteAttachments = function (id) {
        return this.http.delete(this.getApiUrl("deleteAttatchedFile/" + id));
    };
    //update Attached files
    OpportunityTemplateService.prototype.updateAttachments = function (id, data) {
        return this.http.post(this.getApiUrl("updateAttachedFile/" + id), data);
    };
    // add opportunity questions
    OpportunityTemplateService.prototype.addOrEditOpportunityQuestions = function (opportunityId, Data) {
        return this.http.put(this.getApiUrl("opportunityTemplateQuestions/" + opportunityId), JSON.stringify(Data));
    };
    // get opportunity questions
    OpportunityTemplateService.prototype.getOpportunityQuestions = function (opportunityId, params) {
        return this.http.get(this.getApiUrl("opportunityTemplateQuestions/" + opportunityId, params));
    };
    OpportunityTemplateService.prototype.getArriveQuestions = function (opportunityTemplateId) {
        return this.http.get(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/arriveQuestions"));
    };
    OpportunityTemplateService.prototype.saveArriveQuestions = function (opportunityTemplateId, params) {
        return this.http.post(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/saveOPArriveQuestions"), params);
    };
    OpportunityTemplateService.prototype.deleteArriveQuestions = function (opportunityTemplateId, arriveQuestionId) {
        return this.http.delete(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/deleteOPArriveQuestions/" + arriveQuestionId));
    };
    OpportunityTemplateService.prototype.getLeaveQuestions = function (opportunityTemplateId) {
        return this.http.get(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/leaveQuestions"));
    };
    OpportunityTemplateService.prototype.saveLeaveQuestions = function (opportunityTemplateId, params) {
        return this.http.post(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/saveOPLeaveQuestions"), params);
    };
    OpportunityTemplateService.prototype.deleteLeaveQuestions = function (opportunityTemplateId, leaveQuestionId) {
        return this.http.delete(this.getApiUrl("system_settings/opportunity_template/" + opportunityTemplateId + "/deleteOPLeaveQuestions/" + leaveQuestionId));
    };
    OpportunityTemplateService.prototype.setShowPage = function (data) {
        this.showPage.next(data);
    };
    OpportunityTemplateService.prototype.opportunityTemplateLinkedOpportunity = function (id) {
        return this.http.get(this.getApiUrl("checkIsOpportunityLinkOpportunityTemplate/" + id));
    };
    return OpportunityTemplateService;
}(InterfaceService));
export { OpportunityTemplateService };
