import * as tslib_1 from "tslib";
import { InterfaceService } from "@app/interfaces";
import { HttpClient } from "@angular/common/http";
var ScHttpServiceService = /** @class */ (function (_super) {
    tslib_1.__extends(ScHttpServiceService, _super);
    function ScHttpServiceService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    ScHttpServiceService.prototype.getEmailTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsEmailTemplates", params));
    };
    ScHttpServiceService.prototype.getSMSTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsSmsTemplates", params));
    };
    ScHttpServiceService.prototype.serviceReminderConfig = function (data, screenType) {
        return this.http.post(this.getApiUrl("communication/settings/" + screenType + "/servicereminder/config"), JSON.stringify(data));
    };
    ScHttpServiceService.prototype.saveServiceReminderConfig = function (data, screenType) {
        return this.http.post(this.getApiUrl("communication/settings/" + screenType + "/servicereminder/config/save"), JSON.stringify(data));
    };
    return ScHttpServiceService;
}(InterfaceService));
export { ScHttpServiceService };
