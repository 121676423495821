/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./add-communication.component";
var styles_AddCommunicationComponent = [];
var RenderType_AddCommunicationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddCommunicationComponent, data: {} });
export { RenderType_AddCommunicationComponent as RenderType_AddCommunicationComponent };
export function View_AddCommunicationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" test\n"]))], null, null); }
export function View_AddCommunicationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-communication", [], null, null, null, View_AddCommunicationComponent_0, RenderType_AddCommunicationComponent)), i0.ɵdid(1, 114688, null, 0, i1.AddCommunicationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddCommunicationComponentNgFactory = i0.ɵccf("app-add-communication", i1.AddCommunicationComponent, View_AddCommunicationComponent_Host_0, {}, {}, []);
export { AddCommunicationComponentNgFactory as AddCommunicationComponentNgFactory };
