<form (submit)="sendEmail()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>Send.request.title <span>{{title}}</span></h4>
	</div>
	<!-- Content -->
	<div class="modal-body" style="border-top: 1px solid #dadada;">
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<div *ngIf="emailContent" class="control-group clearfix">
						<app-tinymce type="email"
									 [selector]="'supplier-email-template'"
									 [data]="emailContent" [toolbar]="false"
									 [customClass]="{'message' : 'email_editor'}"
									 (outputdata)="updateMessage($event)">
						</app-tinymce>
					</div>
					<div *ngIf="!emailContent" class="control-group clearfix e-load-preview">
						<div class="w-30 animated-background"></div>
						<div class="w-100 animated-background"></div>
						<div class="w-30 animated-background"></div>
						<div class="flex_">
							<div *ngFor="let i of [0,1,2,3,4]" class="box animated-background"></div>
						</div>
						<div class="bdy animated-background"></div>
					</div>
					<span *ngIf="count >= 1" class="show-error" translate [translateParams]="{Object:count}">supplier.did.not.have.an.email.address.associated.with.their.account</span>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button [disabled]="disable" class="btn btn-primary">
			<span translate>Send.request</span>
			<!-- <span [hidden]="action != 'saving'" translate>Saving</span> -->
		</button>
	</div>
</form>