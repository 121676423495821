import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var CsSidepanelComponent = /** @class */ (function () {
    function CsSidepanelComponent(ajsScope, confirmationBoxHelper, translate, hostRef) {
        this.ajsScope = ajsScope;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.translate = translate;
        this.hostRef = hostRef;
        this.autoCloseWarning = true;
        this.sidePanelId = 'side-panel';
        this.sidePanelClass = 'narrow-side-panel';
        this._showPanel = false;
        this.sidePanelIsShowing = false;
        this.panelCloseEvent = new EventEmitter();
        this.escapeElements = ['bs-datepicker-container'];
    }
    CsSidepanelComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(CsSidepanelComponent.prototype, "showSidePanel", {
        set: function (value) {
            var _this = this;
            this._showPanel = value;
            if (value) {
                this.onPanelOpen();
                setTimeout(function () {
                    _this.sidePanelIsShowing = true;
                }, 100);
            }
            else {
                this.sidePanelIsShowing = false;
                this.onPanelClose();
            }
        },
        enumerable: true,
        configurable: true
    });
    // Listening for click on outside panel
    CsSidepanelComponent.prototype.onDocumentClick = function (event) {
        var _this = this;
        if (!this.hostRef || !this.sidePanelIsShowing)
            return;
        if (event.target['id'] != 'page-overlay') {
            return;
        }
        var canBeEscaped = function () {
            if (_this.hostRef.nativeElement.querySelector('#' + _this.sidePanelId).contains(event.target))
                return true;
            for (var i = 0; i < _this.escapeElements.length; i++) {
                if (jQuery(event.target).parents(_this.escapeElements[i]).length) {
                    return true;
                    break;
                }
            }
            if ((_this.hostRef.nativeElement).querySelector('.select2-container')) {
                var select2EscapeElements = ['select2-input', 'select2-focusser'];
                var activeElement = event.currentTarget.activeElement;
                if (activeElement) {
                    var classValue = $(activeElement).attr('class');
                    if (classValue) {
                        var classes = classValue.split(' ');
                        for (var j = 0; j < classes.length; j++) {
                            if (classes[j] == select2EscapeElements[0] || classes[j] == select2EscapeElements[1]) {
                                return true;
                            }
                        }
                    }
                }
            }
            if (jQuery(event.target)[0].classList.value == 'clear-link-container') {
                return true;
            }
            return false;
        };
        if (!canBeEscaped()) {
            this.showPanelCloseWarning();
        }
    };
    // Listening for 'escape' key press
    CsSidepanelComponent.prototype.onKeydownHandler = function (event) {
        if (!this.hostRef || !this.sidePanelIsShowing)
            return;
        if (!this.hostRef.nativeElement.contains(event.target)) {
            this.showPanelCloseWarning();
        }
    };
    // Get confirmation from user to close sidepanel
    CsSidepanelComponent.prototype.showPanelCloseWarning = function () {
        var _this = this;
        if (this.sidePanelId === "more-details-panel") {
            this.showSidePanel = false;
            this.panelCloseEvent.emit();
        }
        else if (this.autoCloseWarning)
            this.confirmationBoxHelper.getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
                .then(function () {
                _this.showSidePanel = false;
                _this.panelCloseEvent.emit();
            });
        else {
            this.showSidePanel = false;
            this.panelCloseEvent.emit();
        }
    };
    // Publish events on panel open
    CsSidepanelComponent.prototype.onPanelOpen = function () {
        this.ajsScope.$emit('sidepanel:open', this._panelName);
    };
    // Publish events on panel close
    CsSidepanelComponent.prototype.onPanelClose = function () {
        this.ajsScope.$emit("'sidepanel:" + this._panelName + ":closed");
        this.ajsScope.$emit('sidepanel:closed');
    };
    return CsSidepanelComponent;
}());
export { CsSidepanelComponent };
