import { Routes } from '@angular/router';
import { ListParsedDataComponent } from './list-parsed-data/list-parsed-data.component';
var routes = [
    {
        path: '',
        component: ListParsedDataComponent,
    }
];
var AiDocumentParsingRoutingModule = /** @class */ (function () {
    function AiDocumentParsingRoutingModule() {
    }
    return AiDocumentParsingRoutingModule;
}());
export { AiDocumentParsingRoutingModule };
