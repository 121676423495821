import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerJobTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerJobTemplateService, _super);
    function CustomerJobTemplateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    CustomerJobTemplateService.prototype.addJobNew = function (type, customerId, data) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + customerId + "/job/new_jobs"), JSON.stringify(data));
    };
    CustomerJobTemplateService.prototype.editJob = function (type, customerId, jobId, data) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + customerId + "/jobs/" + jobId + "/update"), JSON.stringify(data));
    };
    CustomerJobTemplateService.prototype.createContact = function (customerId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerId + "/create_contact_add"), JSON.stringify(data));
    };
    CustomerJobTemplateService.prototype.getSkills = function (jobId, params) {
        return this.http.get(this.getApiUrl("search_skills_description?jobId=" + jobId, params));
    };
    CustomerJobTemplateService.prototype.getSearchSkills = function (jobDescId) {
        return this.http.get(this.getApiUrl("get_assigned_skills?type=job_description&primaryId=" + jobDescId));
    };
    CustomerJobTemplateService.prototype.getDefaultJobDescriptions = function (jobDescId) {
        return this.http.get(this.getApiUrl("default_job_values/" + jobDescId));
    };
    CustomerJobTemplateService.prototype.getJobDescriptionPricingItem = function (jobDescId, params) {
        return this.http.get(this.getApiUrl("getJobDescriptionPricingItem/" + jobDescId, params));
    };
    CustomerJobTemplateService.prototype.getAssets = function (propertyId, assetId, jobId) {
        return this.http.get(this.getApiUrl("get_job_asset_details?propertyId=" + propertyId + "&jobId=" + jobId + "&assetId=" + assetId));
    };
    CustomerJobTemplateService.prototype.getContactPerference = function (contactId) {
        return this.http.get(this.getApiUrl("diary_jobservice_preference_validation?contactID=" + contactId));
    };
    CustomerJobTemplateService.prototype.getJDCustomTemplates = function (action, jobDescId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("listCustomConfigTemplates?screenMode=addCustomTemplates&action=" + action + "&objects=Jobs&objectTypes=JobDescription&objectTypeID=" + jobDescId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    CustomerJobTemplateService.prototype.getInvoiceCatCustomTemplates = function (action, invCatId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("listCustomConfigTemplates?screenMode=addCustomTemplates&action=" + action + "&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=" + invCatId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    CustomerJobTemplateService.prototype.checkInvCatCustomTemplatesAVl = function (invCatId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=" + invCatId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    CustomerJobTemplateService.prototype.checkJobDescCustomTemplatesAVl = function (jobDescId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=JobDescription&objectTypeID=" + jobDescId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    CustomerJobTemplateService.prototype.confirmDeleteCustomTemplatesAnswer = function (params) {
        return this.http.post(this.getApiUrl("confirmDeleteCustomTemplatesAnswer"), JSON.stringify(params));
    };
    CustomerJobTemplateService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerJobTemplateService_Factory() { return new CustomerJobTemplateService(i0.inject(i1.HttpClient)); }, token: CustomerJobTemplateService, providedIn: "root" });
    return CustomerJobTemplateService;
}(InterfaceService));
export { CustomerJobTemplateService };
