import {Inject, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CsVersionService {
    csVersion: number;
    reloadStarted: boolean = false;
    clientDetails = {
        'clientId' : '',
        'username' : ''
    }
    constructor() {
    }

    updateCSVersion(csVersion: any) {
        let updatedVersion = parseInt(csVersion);
        this.csVersion = !isNaN(updatedVersion) ? updatedVersion : 0;
    }

    validateCSVersion(version: number) {
        if(this.csVersion > 0 && this.csVersion != version && !this.reloadStarted) {
            this.reloadStarted = true;
            window.location.reload();
        }
    }

    //set userDetails
    setUserDetails(clientId,username){
        if (clientId !='' && username != '') {
            this.clientDetails.clientId = clientId;
            this.clientDetails.username = username;
        } else {
            console.error("Invalid userDetails provided");
        }
    }

    //get userdetails
    getuserDeatails(){
        return this.clientDetails;
    }

}
