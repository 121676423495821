import {forwardRef, Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {SettingsService} from "@app/core";
import {formatDate} from "@angular/common";
import * as moment from "moment";

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
    format: string = '';

    constructor(@Inject(forwardRef(() => SettingsService)) private settings,
                @Inject(LOCALE_ID) private locale: string) {
    }

    transform(value: any, args?: any): any {
        if(value=='' || value==null){
            return '';
        }
        let language = this.settings._csLanguage;
        if(language == 'en'){
            language = 'en-GB';
        }
        moment.locale(language);
        const intlSettings = this.settings._csIntlSettings;

        if (intlSettings['time'] == 1) {
            this.format = 'HH:mm';
        } else {
            this.format = 'hh:mm a';
        }

        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;
        let utcTimeString  = (isoRegex.test(value)) ? new Date(value) : new Date(value + ' UTC');
        utcTimeString = new Date(utcTimeString.toLocaleString(this.settings._csLanguage, { timeZone:  this.settings._csCountryCode.timezone }));

        return moment(utcTimeString).format(this.format);
  }

}
