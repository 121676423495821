import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from "@app/shared";
import { ConfirmationTemplateComponent } from './confirmation-template/confirmation-template.component';
import {DirectivesModule} from "@app/directives";
import {TranslateModule} from "@ngx-translate/core";
@NgModule({
  declarations: [ConfirmationTemplateComponent],
    imports: [
        // AppCommonModule,
        CommonModule,
        DirectivesModule,
        TranslateModule
    ],
  exports:[
    ConfirmationTemplateComponent
  ]
})
export class ConfirmationTemplatesModule { }
