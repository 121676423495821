/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../directives/input-directives/cs-phonewidget/cs-phonewidget.component.ngfactory";
import * as i5 from "../../directives/input-directives/cs-phonewidget/cs-phonewidget.component";
import * as i6 from "../../services/utility-services/countries.service";
import * as i7 from "../../services/http-services/common.service";
import * as i8 from "../../core/settings/settings.service";
import * as i9 from "./sequence-add-contact.component";
import * as i10 from "ngx-bootstrap/modal";
import * as i11 from "../services/workflowhttp.service";
var styles_SequenceAddContactComponent = ["form[_ngcontent-%COMP%] {height: auto;}"];
var RenderType_SequenceAddContactComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SequenceAddContactComponent, data: {} });
export { RenderType_SequenceAddContactComponent as RenderType_SequenceAddContactComponent };
function View_SequenceAddContactComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "control-error-message show"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Please.Enter.Valid.Email"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SequenceAddContactComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "control-error-message show"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Invalid.Email.Address"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SequenceAddContactComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.f.emailaddress.errors.required; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.f.emailaddress.errors.email; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SequenceAddContactComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "control-group clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [["class", "control-label required_field"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email:"])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "input", [["class", "control-input mb-0"], ["formControlName", "emailaddress"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(7, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i0.ɵdid(9, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_3)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "emailaddress"; _ck(_v, 7, 0, currVal_7); var currVal_8 = (_co.f.emailaddress.invalid && (_co.f.emailaddress.dirty || _co.f.emailaddress.touched)); _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SequenceAddContactComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "control-group clearfix phone-widget-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [["class", "control-label required_field"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Mobile:"])), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "phone-number-wrapper dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["app-cs-phonewidget", ""]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.onChangeCountryCode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CsPhonewidgetComponent_0, i4.RenderType_CsPhonewidgetComponent)), i0.ɵdid(6, 638976, null, 0, i5.CsPhonewidgetComponent, [i6.CountriesService, i7.CommonService, i0.ElementRef, i8.SettingsService], { labelName: [0, "labelName"] }, { valueEmitter: "valueChanged" }), (_l()(), i0.ɵeld(7, 0, null, null, 5, "input", [["class", "phone-number-input mb-0"], ["formControlName", "mobile"], ["placeholder", ""], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(10, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i0.ɵdid(12, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_8 = "mobile"; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 12).ngClassValid; var currVal_6 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_SequenceAddContactComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "form", [["class", "no-form-style"], ["id", "ngForm"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "item form-item p-15"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_2)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_6)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = (_co.type === "email"); _ck(_v, 7, 0, currVal_8); var currVal_9 = (_co.type === "mobile"); _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SequenceAddContactComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SequenceAddContactComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Saving"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SequenceAddContactComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h4", [["class", "modal-title pull-left"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(3, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-body ng-workflow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "help_section mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "p-15"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_1)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["class", "btn"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(14, 0, null, null, 4, "button", [["class", "btn btn-primary"], ["form", "ngForm"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_7)), i0.ɵdid(16, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SequenceAddContactComponent_8)), i0.ɵdid(18, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.form; _ck(_v, 10, 0, currVal_3); var currVal_5 = !_co.saving; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.saving; _ck(_v, 18, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.helpText; _ck(_v, 8, 0, currVal_2); var currVal_4 = (!_co.form.valid || _co.saving); _ck(_v, 14, 0, currVal_4); }); }
export function View_SequenceAddContactComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sequence-add-contact", [], null, null, null, View_SequenceAddContactComponent_0, RenderType_SequenceAddContactComponent)), i0.ɵdid(1, 114688, null, 0, i9.SequenceAddContactComponent, [i10.BsModalRef, i3.FormBuilder, i11.WorkflowhttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SequenceAddContactComponentNgFactory = i0.ɵccf("app-sequence-add-contact", i9.SequenceAddContactComponent, View_SequenceAddContactComponent_Host_0, {}, {}, []);
export { SequenceAddContactComponentNgFactory as SequenceAddContactComponentNgFactory };
