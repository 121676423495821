/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./tinymce.component";
var styles_TinymceComponent = [];
var RenderType_TinymceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TinymceComponent, data: {} });
export { RenderType_TinymceComponent as RenderType_TinymceComponent };
function View_TinymceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [["class", "control-label"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Email.title"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TinymceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TinymceComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "control-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "tiny-mce-single-line-input-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "input", [["type", "text"]], [[8, "id", 0], [8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.data["subject"] = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(8, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selector == "supplier-email-template"); _ck(_v, 2, 0, currVal_0); var currVal_10 = _co.data["subject"]; _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.selector, "-subject"); var currVal_2 = i0.ɵinlineInterpolate(1, "email-template-subject ", _co.customClass["subject"], ""); var currVal_3 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 10).ngClassValid; var currVal_8 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_TinymceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [["class", "control-label"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Email.body"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TinymceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TinymceComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TinymceComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "control-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "textarea", [], [[8, "id", 0], [8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.data["message"] = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "email"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.selector == "supplier-email-template"); _ck(_v, 4, 0, currVal_1); var currVal_11 = _co.data["message"]; _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.selector, ""); var currVal_3 = i0.ɵinlineInterpolate(1, "email-template-message ", _co.customClass["message"], ""); var currVal_4 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 11).ngClassValid; var currVal_9 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_TinymceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tinymce", [], null, null, null, View_TinymceComponent_0, RenderType_TinymceComponent)), i0.ɵdid(1, 4308992, null, 0, i4.TinymceComponent, [i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TinymceComponentNgFactory = i0.ɵccf("app-tinymce", i4.TinymceComponent, View_TinymceComponent_Host_0, { type: "type", data: "data", selector: "selector", customClass: "customClass", toolbar: "toolbar" }, { outputdata: "outputdata" }, []);
export { TinymceComponentNgFactory as TinymceComponentNgFactory };
