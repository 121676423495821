import { Injectable, EventEmitter, OnDestroy, Inject, Input } from '@angular/core';
import Handsontable from 'handsontable';
import { CsToastBoxService } from "@app/services";
import { HansontableColor } from '@app/directives';
import { AuthService } from "@app/core";

@Injectable({
    providedIn: 'root'
})

export class PipelineStageService {

    hotInstances = [];
    hotId: any;
    spreadsheetData: Array<any> = [];
    spreadSheetSettings = {};
    savechange: EventEmitter<object> = new EventEmitter();
    deletechange: EventEmitter<object> = new EventEmitter();
    deleteEvent: EventEmitter<object> = new EventEmitter();
    valid: boolean = false;
    allowDelete: boolean = false;
    row: any;
    col: any;
    tableInstance: any;
    action: any;
    baseSettings: any;

    constructor(private toastBox: CsToastBoxService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private authService: AuthService) {
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }

    emitSaveChangeEvent(obj) {
        this.savechange.emit(obj);
    }

    getSaveChangeEmitter() {
        return this.savechange;
    }


    emitdeleteChangeEvent(row) {
        this.deletechange.emit(row);
    }

    getdeleteChangeEmitter() {
        return this.deletechange;
    }

    emitdeleteEvent(row) {
        this.deleteEvent.emit(row);
    }
    getDeleteEvent() {
        return this.deleteEvent;
    }


    getSpreadSheetData(hotId) {
        return this.spreadsheetData;
    }

    removeData(hotId) {
        const instance: any = this.hotInstances[hotId]
        if (instance) { Handsontable.hooks.destroy(instance) }
        this.hotInstances = [];
        this.hotId = undefined;
        this.spreadsheetData = [];
    }

    populateData(hotId, data) {
        const instance = this.hotInstances[hotId]
        this.spreadsheetData = data;
        this.baseSettings = {
            preventOverflow: 'vertical',
            colHeaders: true,
            rowHeaders: true,
            viewportRowRenderingOffset: 1000,
            stretchH: 'all',
            copyPaste: false,
            afterValidate: function (isValid, value, row, prop, source) {
                // let isnum = /^\d+$/.test(value);
                // if (!isnum && prop == 'rotting') {
                //     instance.setDataAtCell(row, instance.propToCol(prop), null);
                // }
                // if (prop == 'rotting' && value != null && value.toString().length > 10) {
                //     let val = value.toString();
                //     let number = val.substring(0, 9);
                //     instance.setDataAtCell(row, instance.propToCol(prop), number);
                // }
            },
            tabMoves: function (e) {
                if (instance.getSelected()[0][1] + 1 === (instance.countCols() - 1)) {
                    instance.selectCell((instance.getSelected()[0][0] + 1), 0);
                    return {
                        row: 0,
                        col: 0
                    }
                } else {
                    return {
                        row: 0,
                        col: 1
                    }
                }
            },
            colWidths: [100, 10, 15, 15],
            enterMoves: function (e) {
                if (instance.getSelected()[0][1] + 1 === (instance.countCols() - 1)) {
                    instance.selectCell((instance.getSelected()[0][0] + 1), 0);
                    return {
                        row: 0,
                        col: 0
                    }
                } else {
                    return {
                        row: 0,
                        col: 1
                    }
                }
            },
            manualRowMove: true,
            data: this.spreadsheetData,
            columns: [{
                title: 'Stage',
                data: 'name',
                allowEmpty: false,
                allowInvalid: false,
                validator: 'emptyCellValidator',
                className: 'required',
            },
            {
                title: 'Colour',
                data: 'color',
                allowEmpty: false,
                allowInvalid: false,
                className: 'overflow_handsontable',
                validator: 'emptyCellValidator',
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    if (this.spreadsheetData[row][prop]) { value = this.spreadsheetData[row][prop]; }
                    else { value = '#FFFFFF' }
                    Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    let childElement: HTMLDivElement = document.createElement('div');
                    childElement.style.width = '15px';
                    childElement.style.height = '15px';
                    childElement.style.backgroundColor = value;
                    childElement.style.border = '1px solid #E3E3E3';
                    Handsontable.dom.empty(td);
                    td.appendChild(childElement);
                    let icon: HTMLElement = document.createElement('i');
                    icon.setAttribute('class', 'fa fa-angle-down');
                    let icondiv: HTMLDivElement = document.createElement('div');
                    icondiv.setAttribute('class', 'select_modified_icon');
                    icondiv.appendChild(icon);
                    td.appendChild(icondiv);
                },
                editor: HansontableColor()
            },
            {
                title: 'Rotting(days)',
                data: 'rotting',
                allowEmpty: true,
                allowInvalid: false,
                invalidCellClassName: 'highlight-error',
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    if (value && value != '') {
                        if (isNaN(value) || value.toString().indexOf('.') > -1 || value < 0) {
                            value = null;
                            this.toastBox.show('Invalid data', 1000);
                        }
                        else {
                            value = value.toString().substring(0, 9);
                        }
                    }
                    setTimeout(() => {
                        if (this.spreadsheetData[row]) {
                            this.spreadsheetData[row].rotting = value;
                        }
                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    }, 300);

                }
            },
            ],
            // Set column header class names
            afterGetColHeader: (col: number, TH: HTMLElement) => {
                if ((col === 0) && !Handsontable.dom.hasClass(TH, 'col_header_bg')) {
                    Handsontable.dom.addClass(TH, 'col_header_bg');
                }
            },
            beforeOnCellMouseDown: (e) => {
                if (e.target.innerText == 'Delete') {
                    e.stopImmediatePropagation()
                }
            }
        };
        this.checkPermissions();
        this.hotInstances[hotId].updateSettings(this.baseSettings);
        this.hotInstances[hotId].render();
    }
    checkPermissions() {
        const permissionAccess =  ['SetupPipelines.deleteaccess'];
        const allow = this.authService.permitted(permissionAccess) === true;
        if (allow) {
            this.action =    {
                title: 'Actions',
                readOnly: true,
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    let link: HTMLAnchorElement = document.createElement('a');
                    link.innerText = 'Delete';
                    if (this.spreadsheetData[row]['id']) {
                        link.style.cursor = 'pointer';
                    } else {
                        link.style.cursor = 'not-allowed';
                    }
                    td.style.cursor = 'not-allowed';
                    td.style.backgroundColor = '#eeeeee';
                    Handsontable.dom.addEvent(link, 'click', () => {
                        this.onRowDelete(instance, row, col);
                    });
                    Handsontable.dom.empty(td);
                    let content: HTMLDivElement = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                    return td;
                }
            }
            this.baseSettings['columns'].push(this.action);
        }
    };

    afterInit(hotId, hotInstance) {
        this.hotId = hotId;
        this.hotInstances[hotId] = hotInstance;
    }

    spreadsheetSettings() {
        return {}
    }

    onRowDelete(instance, row, col) {
        let that = this;
        const targetRow = that.spreadsheetData[row];
        this.row = row;
        this.col = col;
        this.tableInstance = instance;
        if (targetRow.hasOwnProperty('id') && targetRow['id']) {
            that.emitdeleteChangeEvent(targetRow)
        }
        // this.deleteData();
    }


    emptyCellValidator = (value, callback) => {
        if (value === '') {
            this.toastBox.show('Empty cell not allowed', 1000);
        }
        callback(true);
    }

    afterChange(changes, source, hotId) {
        const current_instance = this.hotInstances[hotId];
        if (source === 'loadData') {
            current_instance.alter('insert_row', this.spreadsheetData.length);
            current_instance.render();
        }
        if (source === 'edit' || source == 'Autofill.fill' || source == 'UndoRedo.undo') {
            for (let i = 0; i < changes.length; i++) {
                let change = changes[i];
                let rowId = change[0],
                    oldValue = change[2],
                    currentValue = change[3];

                //last row - insert new row
                if (this.spreadsheetData.length === rowId + 1 && source != 'Autofill.fill') {
                    if (this.spreadsheetData[rowId]['name']) {
                        this.hotInstances[hotId].alter('insert_row', this.spreadsheetData.length);
                    }
                }

                //auto save
                if (this.spreadsheetData[rowId]['name'] && oldValue != currentValue) {
                    let value = this.spreadsheetData[rowId]['rotting'];
                    let valid = true
                    if (value && value != '') {
                        if (isNaN(value)) {
                            valid = false
                        }
                        else if (value.toString().indexOf('.') > -1) {
                            valid = false
                        }
                    }
                    if(this.spreadsheetData[rowId]['rotting']) {
                        this.spreadsheetData[rowId]['rotting'] = value.toString().substring(0, 9);
                    }
                    this.emitSaveChangeEvent(this.spreadsheetData[rowId]);
                }
            }
        }
    }

    clearData() {
        const instance: any = this.hotInstances[this.hotId]
        if (instance) { Handsontable.hooks.destroy(instance) }
        this.hotInstances = [];
        this.hotId = undefined;
        this.spreadsheetData = [];
    }
    deleteData() {
        let that = this;
        const targetRow = that.spreadsheetData[that.row];
        if (that.allowDelete == true) {
            if (that.col === (that.tableInstance.getInstance().countCols() - 1)) {
                that.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', this.ajsScope)
                    .then(function () {
                        if (targetRow.hasOwnProperty('id') && targetRow['id']) {
                            that.emitdeleteEvent(targetRow)
                        }
                        that.spreadsheetData.splice(that.row, 1);
                        that.tableInstance.render();

                        let rowsCount = that.tableInstance.countRows();
                        if (rowsCount === 0 || rowsCount === that.row) {
                            that.tableInstance.alter('insert_row', rowsCount);
                            that.tableInstance.render();
                        }
                    }, function () {
                        return false;
                    });
            }
        }
    }
    allowDeleteMethod(param) {
        this.confirmationBoxHelper.hideConfirmationBox();
        this.allowDelete = param;
        if (param == true) {
            this.deleteData();
        }
    }
}
