<div class="modal-header">
    <h4 class="modal-title pull-left">SMS settings</h4>
</div>
<div>
    <div class="modal-body" >
        <div class="loading-wrap" *ngIf="loading">
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
        </div>
        <div *ngIf="status && !loading && isEdit" class="wfe-block-notification-bar">
            <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
        </div>
        <!--<div class="mth-5 padding-fifteen" *ngIf="!loading">
            <div class="no-results-wrap">
                <div class="warning-icon-wrap with-info-icon">
                    <span class="ss-info icon fix-left" style="margin: 1px 0 0 11px !important">&nbsp;</span>
                </div>
                <div class="warning-message">No sms template found for this block</div>
            </div>
        </div>-->
        <ng-container *ngIf="!loading">
            <app-condition-edge *ngIf="isConditional"
                            [inputModel]="inputModel"
                            [operators]="operators"
                            [isMarketPlaceView]="isMarketPlaceView"
                            [isReadOnlyMode]="isReadOnlyMode"
                            [activeField] = "activeField">
            </app-condition-edge>
            <div class="item form-item p-15"   [ngClass]="{'border-top': isConditional}">
                <div class="control" *ngIf="workflowType != 'Sequence'">
                    <label class="control-label required_field">To:</label>
                    <div>
                        <input (selected)="selectedSmsAddress($event)"
                               [(ngModel)]="inputModel.model.toSmsTag" [dropdownvalue]="inputModel.model.toSmsTag"
                               id="additional-sms"
                               csSelect2
                               [select2Config]="select2LabelConfig"
                               class="full-width-select2 cs-select2-wfe mb-10"/>
                    </div>
                </div>

                <div class="control">
                    <ng-template #popTemplate>
                        <div class="SMScontentScroll">
                            <span style="font-weight: bold">Message:</span><div [innerHtml]="message"></div>
                        </div>
                    </ng-template>
                    <a *ngIf="message" style="float:right;cursor:pointer;" class="btn-default btn-secondary"
                       [popover]="popTemplate"
                       [outsideClick]="true"
                       placement="left" container="body" containerClass="SMScustomClass">
                        Preview template
                    </a>
                    <label class="control-label required_field" translate>SMS.Template:</label>
                    <div>
                        <!--<select class="full-width-select2 cs-select2-wfe mb-10"
                                csSelect2 (ngModelChange)="uploadTemplateDetails($event)"
                                [select2Config]="{}"
                                [dropdownvalue]="inputModel.model.templateId"
                                [(ngModel)]="inputModel.model.templateId">
                            <option value="" disabled translate>Please.Choose</option>
                            <option *ngFor="let field of smsTemplateList" [value]="field.id">{{field.text}}</option>
                        </select>-->
                        <app-search-template [screen]="'sms_template'"
                             themeName="compact"
                             [screenLabel]="smsScreenLabel"
                             [showAddNewTemplate]="false"
                             [selectedTemplate]="this.activeTemplateTitle"
                             (selectedItem)="setTemplateDetails($event,'sms')">
                        </app-search-template>
                    </div>
                </div>
<!--                <div  *ngIf="!hidden">-->
<!--                    <div class="control">-->
<!--                        <label class="control-label  mb-10">Message:</label>-->
<!--                        <div  id="email-template-message">-->
<!--                            <p class="wf-block-email bg-white p-10 line-ellipse" [innerHTML]="previewMessage" ></p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </ng-container>
    </div>
    <div class="modal-footer" [hidden]="loading">
        <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
        <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
        <button class="btn btn-primary" [disabled]="saving || validateItems()" (click)="addValue()">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" translate>Saving</span>
        </button>
    </div>
</div>
