/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-table.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "angular-datatables/src/angular-datatables.directive";
import * as i5 from "../services/metadata.service";
import * as i6 from "./settings-table.component";
import * as i7 from "../../core/auth/auth.service";
import * as i8 from "@angular/common/http";
import * as i9 from "../../core/settings/settings.service";
import * as i10 from "@angular/router";
var styles_SettingsTableComponent = [i0.styles];
var RenderType_SettingsTableComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SettingsTableComponent, data: {} });
export { RenderType_SettingsTableComponent as RenderType_SettingsTableComponent };
function View_SettingsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "no-results --margin-top"], ["icon", "ss-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "no-results-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "warning-icon-wrap with-info-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "ss-info icon fix-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "warning-message"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 7, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.defaultEmptyMessage; _ck(_v, 8, 0, currVal_1); }); }
function View_SettingsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "spreadsheet-loading-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "span", [["class", "loading-spreadsheet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "loading-x-axis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "loading-y-axis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "loading-row row-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "loading-row row-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "loading-row row-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "loading-row row-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "loading-row row-5"]], null, null, null, null, null))], null, null); }
function View_SettingsTableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ss-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_SettingsTableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ss-delete"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_SettingsTableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "select-all-link tab-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectAllRows() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTableComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTableComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectAllCheckbox; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectAllCheckbox; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SettingsTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTableComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "table", [["class", "display nowrap"], ["datatable", ""], ["style", "width:100%;border-collapse: collapse;"]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(4, 212992, [[1, 4]], 0, i4.DataTableDirective, [i1.ElementRef], { dtOptions: [0, "dtOptions"], dtTrigger: [1, "dtTrigger"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowSelect; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.dtOptions; var currVal_3 = _co.dtTrigger; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "cs-settings-table-v2-", _co.tableId, ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_SettingsTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { datatableElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTableComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTableComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "cs-settings-table-container"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "row-action-enabled": 0, "row-reorder-enabled": 1, "cs-processing-on": 2, "visibility-hidden": 3 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SettingsTableComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(0, i3.JsonPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.dataUrl != "") && !_co.loading) && (_co.totalRecordCount == 0)) && _co.loadingFirstTime); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.dataUrl != "") && _co.loadingFirstTime) && _co.loading); _ck(_v, 5, 0, currVal_1); var currVal_2 = "cs-settings-table-container"; var currVal_3 = _ck(_v, 8, 0, _co.actions.length, _co.rowReorder, _co.processing, ((((_co.dataUrl != "") && _co.loadingFirstTime) && _co.loading) || ((((_co.dataUrl != "") && _co.loadingFirstTime) && !_co.loading) && (_co.totalRecordCount == 0)))); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.dtOptions)) != "{}"); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_SettingsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-settings-table", [], null, [["window", "keydown.arrowdown"], ["window", "keydown.arrowup"], ["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:keydown.arrowdown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).moveDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown.arrowup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).moveUp($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).documentClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_SettingsTableComponent_0, RenderType_SettingsTableComponent)), i1.ɵprd(512, null, i5.SmartTableMetaDataService, i5.SmartTableMetaDataService, [i2.TranslateService]), i1.ɵdid(2, 4440064, null, 0, i6.SettingsTableComponent, [i5.SmartTableMetaDataService, i7.AuthService, i8.HttpClient, i9.SettingsService, "ajsState", i10.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SettingsTableComponentNgFactory = i1.ɵccf("app-settings-table", i6.SettingsTableComponent, View_SettingsTableComponent_Host_0, { apiOptions: "apiOptions", metadata: "metadata", actions: "actions", rowReorder: "rowReorder", rowSelect: "rowSelect", data: "data", category: "category", emptyMessage: "emptyMessage", defaultEmptyMessage: "defaultEmptyMessage", dataUrl: "dataUrl", actionClass: "actionClass", tdAdditionalClass: "tdAdditionalClass", isExternalAPI: "isExternalAPI", rowSelectable: "rowSelectable", processing: "processing", searchMessage: "searchMessage", isPostMethod: "isPostMethod" }, { $instance: "$instance", $dataCount: "$dataCount", $selectedRow: "$selectedRow", rowReorderCallBack: "rowReorderCallBack", selectedCheckBoxValue: "selectedCheckBoxValue", overAllSelect: "overAllSelect", onSelectChanges: "onSelectChanges" }, []);
export { SettingsTableComponentNgFactory as SettingsTableComponentNgFactory };
