<div class="modal-header">
  <h4 class="modal-title pull-left">Select until condition block</h4>
</div>
<div class="modal-body untilcondition-block">
    <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
  <app-condition-edge *ngIf="isConditional"
      [inputModel]="inputModel"
      [operators]="operators"
      [isMarketPlaceView]="isMarketPlaceView"
      [isReadOnlyMode]="isReadOnlyMode"
      [activeField] = "activeField">
  </app-condition-edge>

  <div class="item form-item p-15">
    <div class="control">
      <label class="control-label required_field">Add conditional field</label>
      <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
              [disabled]="isMarketPlaceView"
              csSelect2
              ($instance)="setSelect2($event)"
              [select2Config]="{}"
              [dropdownvalue]="inputModel.model.col_name"
              [(ngModel)]="inputModel.model.col_name">
           <option value="" translate>Please.Choose</option>
          <option *ngFor="let field of inputModel.config.fields" [value]="field.id">{{field.text}}</option>
          <optgroup *ngFor="let template of  inputModel.config['customFields']" [label]="'Custom fields: '+template['description']">
              <option *ngFor="let field of template['fields']" [value]="field.col_name">{{field.text}}</option>
          </optgroup>
      </select>
    </div>

    <div class="control">
      <label class="control-label required_field">Repeat every</label>
      <select class="full-width-select mr-10" [(ngModel)]="inputModel.model.interval">
        <option *ngFor="let interval of inputModel.config.intervalList" value="{{interval}}">{{interval}}</option>
      </select>
      <select class="full-width-select" [(ngModel)]="inputModel.model.unit">
        <option *ngFor="let unit of inputModel.config.units" value="{{unit}}">{{unit}}</option>
      </select>
    </div>
    <hr style="float: none;"/>
    <div class="condition-item cs-d-flex align-items-center">
      <div class="box-ratio w-60">
        <input type="radio" id="t-option" name="selector" [value]="1" [(ngModel)]="inputModel.model.untilType" [checked]="inputModel.model.untilType==1">
        <label for="t-option" [ngClass]="{'required_field': inputModel.model.untilType==1}">Repeat until</label>
        <div class="check"><div class="inside"></div></div>
      </div>

      <div class="w-40">
        <div class="control-group datepicker-control-group">
          <div class="controls">
            <div class="input-append datepicker-append">
              <input type="text" readonly [disabled]="inputModel.model.untilType != 1" style="width:152px"
                 #dp="bsDatepicker"
                 [outsideClick]="true"
                 [minDate]="untilMin"
                 [maxDate]="untilMax"
                 [bsValue]="untilPeriodModel"
                 [(ngModel)]="untilPeriodModel"
                 placeholder="Choose until date"
                 class="form-control"
                 bsDatepicker/>
              <span class="add-on" (click)="inputModel.model.untilType == '1' && dp.toggle()">
              <span class="ss-calendar" [ngStyle]="{'color': inputModel.model.untilType == '1' ? '#606c7f' : '#ccc'}"></span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="control">
      <div class="condition-item cs-d-flex align-items-center">
        <div class="box-ratio w-60">
          <input type="radio" id="c-option" name="selector" [value]="2" [(ngModel)]="inputModel.model.untilType" [checked]="inputModel.model.untilType==2">
          <label for="c-option" [ngClass]="{'required_field': inputModel.model.untilType==2}">Repeat until after the execution date</label>
          <div class="check"><div class="inside"></div></div>
        </div>
        <div class="w-40">
          <input type="text"
                 class="control-input"
                 [(ngModel)]="inputModel.model.untilManualPeriod"
                 style="width: 50px; margin-right: 10px;"
                 appDigitDecimaNumber maxlength="2" [disabled]="inputModel.model.untilType != '2'"/>
          <select class="full-width-select" [(ngModel)]="inputModel.model.untilUnit" style="width: 120px;" [disabled]="inputModel.model.untilType != 2">
            <option *ngFor="let unit of inputModel.config.untilUnits" value="{{unit}}">{{unit}}</option>
          </select>
        </div>
      </div>
    </div>
    <hr/>

    <!--<div class="condition-item cs-d-flex align-items-center">
      <div class="cs-flex-1">
        <div class="control-group datepicker-control-group">
          <label class="control-label required_field">Repeat until</label>
          <div class="controls">
            <div class="input-append datepicker-append">
              <input type="text" readonly
                 #dp="bsDatepicker"
                 [outsideClick]="true"
                 [minDate]="untilMin"
                 [maxDate]="untilMax"
                 [bsValue]="untilPeriodModel"
                 [(ngModel)]="untilPeriodModel"
                 placeholder="Choose until date"
                 class="form-control"
                 bsDatepicker
              />
              <span class="add-on" (click)="dp.toggle()">
                <span class="ss-calendar"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="cs-flex-1">
          <div class="mt-10 p-10 nowrap">-Or-</div>
      </div>
      <div class="cs-flex-1">
        <div class="control">
          <label class="control-label">&nbsp;</label>
          <select class="full-width-select" style="width:185px">
            <option value="">From now +1 day</option>
            <option value="">From now +1 week</option>
          </select>
        </div>
      </div>
    </div>-->

  </div>
</div>
<div class="modal-footer">
  <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
  <button  [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
  <button class="btn btn-primary" [disabled]="validateItems()" (click)="addValue()">
    <span translate>Save</span>
  </button>
</div>
