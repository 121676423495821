import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
var EditSectionNameComponent = /** @class */ (function () {
    function EditSectionNameComponent(bsModalRef, fb) {
        this.bsModalRef = bsModalRef;
        this.fb = fb;
    }
    EditSectionNameComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.sectionForm = this.fb.group({
            'sectionName': [this.editDetails['sectionName']],
            'listName': [this.editDetails['listName']]
        });
    };
    // Save section name
    EditSectionNameComponent.prototype.saveSectionName = function () {
        this.onClose.next(this.sectionForm.value);
        this.bsModalRef.hide();
    };
    return EditSectionNameComponent;
}());
export { EditSectionNameComponent };
