import { Component, Input, Injectable, ContentChildren, forwardRef, AfterViewInit, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

//--
import { InterfaceClass } from '@app/interfaces';
import { AppValidators } from './app-validators';


// Temporary storage -----------------------------------------------------------------------
@Injectable()
export class ValidationDepotService {
    private activeValidationSource: BehaviorSubject<string> = new BehaviorSubject('')
    activeValidation$ = this.activeValidationSource.asObservable();

    setActiveValidation(name: string) {
        this.activeValidationSource.next(name || '');
    }
}

// Validation message component --------------------------------------------------------------
@Component({
    selector: 'validation-error',
    template: `  
        <span *ngIf="errorMessage !== null" class="control-error-message show">
            
            <span #customMessageContent><ng-content></ng-content></span>
            <span *ngIf="!customMessageContent.innerHTML.trim()" translate>{{errorMessage}}</span>
        </span>`,
    providers: [ValidationDepotService]
})
export class ValidationErrorComponent {
    hideComponentMessage: boolean = false;
    validation: string;

    @Input('formGroup') formGroup: FormGroup;
    @Input('controlName') controlName: string;
    @Input('showOnDirty') showOnDirty: boolean = false;

    constructor(private validatinDepot: ValidationDepotService) { }

    get errorMessage() {
        if (!this.formGroup || !this.controlName) return;
        let control: FormControl = <FormControl>this.formGroup.controls[this.controlName];

        for (let propertyName in control.errors) {
            
            if (control.errors.hasOwnProperty(propertyName)
                && ((this.showOnDirty && control.dirty) || control.touched)
            ) {
                let errorMsg: string = AppValidators.getValidatorErrorMessages(
                    control.errors[propertyName],
                    this.controlName
                )[propertyName];

                this.validatinDepot.setActiveValidation(propertyName);
                if (errorMsg) return errorMsg;
                else return control.errors[propertyName];
            }
        }

        return null;
    }
}

// Customer vaidation error message component -----------------------------------------------------
@Component({
    selector: 'error-message',
    template: `<ng-container *ngIf="validation === activeValidation"><ng-content></ng-content></ng-container>`
})
export class ValidationCustomErrorMessageComponent extends InterfaceClass {
    activeValidation: string;
    @Input('validation') validation: string;

    constructor(private validationDepot: ValidationDepotService) {
        super();

        this.subscriptions['activeValidation'] = this.validationDepot.activeValidation$.subscribe(name => this.activeValidation = name);
    }
}

// Find control name from FormControl ...............................................................
export function getControlName(control: any) {
    var controlName = null;
    var parent = control["_parent"];

    // only such parent, which is FormGroup, has a dictionary 
    // with control-names as a key and a form-control as a value
    if (parent instanceof FormGroup) {
        // now we will iterate those keys (i.e. names of controls)
        Object.keys(parent.controls).forEach((name) => {
            // and compare the passed control and 
            // a child control of a parent - with provided name (we iterate them all)
            if (control === parent.controls[name]) {
                // both are same: control passed to Validator
                //  and this child - are the same references
                controlName = name;
            }
        });
    }
    // we either found a name or simply return null
    return controlName;
}
