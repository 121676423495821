import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HybridFactory} from '@app/core/hybrid-factory';

@Injectable({
    providedIn: 'root'
})
export class HybridRedirectGuard implements CanActivate {

    canActivate(
        routeSnapshot: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (routeSnapshot.data.hasOwnProperty('redirectToUrl')) {
            const redirectToUrl = routeSnapshot.data['redirectToUrl'];
            if (redirectToUrl) {
                HybridFactory.navigate(redirectToUrl);
                return false;
            }
        }
        return true;
    }
}
