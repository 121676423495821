import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Renderer, ViewChild,
    AfterViewInit
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';


//--
import {CsToastBoxService} from "@app/services";
import {CsHeaderDirective} from "@app/directives/material-directives/cs-full-screen-modal/cs-header.directive";

declare var jQuery: any;


@Component({
    selector: 'cs-full-screen-modal',
    templateUrl: './cs-full-screen-modal.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        "class": "full-screen-widget ppm-widget animated"
    }
})
export class CsFullScreenModalComponent implements OnInit, AfterViewInit {
    @Input() header: string;
    @Input() showFooter: boolean = false;
    @Input() showTooltip: boolean = false;
    @Input() bodyClass: string = '';
    @Input() subTitle: string = '';
    @Input() subText: string = '';
    @Input() showSubTitleAsTemplate: boolean = false;
    @Input() hideCloseButton: boolean = false;
    @Input() mapTemplate : boolean = false;
    @Input() zIndex: string = '1030';
    @Input() headerZIndex = '1';

    @Input() customHeader:any = false;
    @Input() viewMode:any = false;
    @Output() onContentOpen: EventEmitter<any> = new EventEmitter();
    @Output() onContentClose: EventEmitter<any> = new EventEmitter();

    @Output() onMapContentApprove: EventEmitter<any> = new EventEmitter();
    @Output() onMapContentDelete: EventEmitter<any> = new EventEmitter();

    @Output() instance: EventEmitter<any> = new EventEmitter();
    @Output() getCustomHeaderInstance: EventEmitter<any> = new EventEmitter();
    @ViewChild('widgetBody') widgetBody: ElementRef;
    @ViewChild(CsHeaderDirective) dynamicHeader: CsHeaderDirective;
    @ViewChild('widgetHeader') widgetHeader: ElementRef;
    action: string = 'save';
    ppmList: object = [];
    headerName: string;

    constructor(
        private _hostRef: ElementRef,
        private _renderer: Renderer,
        private route: ActivatedRoute,
        private location: Location,
        private toastBox: CsToastBoxService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdRef:ChangeDetectorRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) {
    }

    ngOnInit() {
        // For modal box purpose
        this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
        this._renderer.setElementStyle(this._hostRef.nativeElement, 'z-index', this.zIndex);
        if(this.bodyClass) {
            this._renderer.setElementClass(this.widgetBody.nativeElement, this.bodyClass, true);
        }
    }

    ngAfterViewInit() {
        this.instance.emit(this);
        if(this.customHeader) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(this.customHeader as any);
            const viewContainerRef = this.dynamicHeader.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(factory);
            (<any>componentRef.instance).close = this.close.bind(this);
            this.getCustomHeaderInstance.emit(componentRef.instance)
            //console.log('instance',this.customHeader);
            this.cdRef.detectChanges();
        }
        if(this.headerZIndex && this.widgetHeader!= undefined){
            this._renderer.setElementStyle(this.widgetHeader.nativeElement, 'z-index', this.headerZIndex);
        }
    }

    // Open widget
    open() {
        jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block').addClass("bounceInUp").removeClass('bounceOutDown');
        // shorten the header if it is large
        this.onContentOpen.emit();
        setTimeout(() =>{
            this.shortenHeader();
        },100);
    }

    // Close widget
    close(doDestroy: boolean = false) {
        jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
        setTimeout(() => {
            this.onContentClose.emit();
        }, 100);

        if(doDestroy) {
            setTimeout( () => {
                jQuery(this._hostRef.nativeElement).remove();
            },300)
        }
    }

    ngOnDestroy() {
        // Remove content from body on destroy
        if(!this.mapTemplate) {
            jQuery(this._hostRef.nativeElement).remove();
        }
    }

    shortenHeader() {
        this.headerName = this.header;
        if (this.header && this.header.length > 75) {
            this.header = this.header.substring(0, 75) + '..';
        }
    }

    deleteTimesheet(){
        setTimeout(() => {
            this.onMapContentDelete.emit();
        }, 100);

    }
    approveMap(){
        setTimeout(() => {
            this.onMapContentApprove.emit();
        }, 100);

    }
}
