// Navigatiing into AngularJs routes without reload the page
export function navigateToAjs(path, params) {
    if (params instanceof Object) {
        path += '?';
        Object.entries(params).forEach(function (_a) {
            var param = _a[0], value = _a[1];
            path += encodeURIComponent(param) + "=" + encodeURIComponent(value) + "&";
        });
    }
    var anchor = document.getElementById('navigator');
    if (anchor) {
        anchor.setAttribute('href', path);
        setTimeout(function () { return anchor.click(); }, 10);
    }
}
