import { Directive, ElementRef, EventEmitter, Inject, Injector, Input, Output } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
  selector: 'asset-widget-modal-migrated'
})

export class AssetWidgetModalMigratedDirective extends UpgradeComponent {
  @Input('params') params: Object;
  @Input('templateUrl') templateUrl: string;
  @Input('controllerName') controllerName: string;
  @Input('autoOpen') autoOpen: boolean = false;
  @Input('viewScreen') viewScreen: 'form' | 'view';
  @Input('preSubmitHook') preSubmitHook: (datas: any) => Promise<boolean>;
  @Output('onSubmit') onSubmit: EventEmitter<{ items: any }> = new EventEmitter<{ items: any }>();
  @Output('onClose') onClose: EventEmitter<{ items: any }> = new EventEmitter<{ items: any }>();
  @Input('resolveUrl') resolveUrl: (params: Object) => string;

  constructor(
    _hostRef: ElementRef,
    injector: Injector,
    @Inject('$scope') private ajsScope
  ) {
    super('assetWidgetModal', _hostRef, injector);
  }

  openModal(data) {
    setTimeout(() => this.ajsScope.$broadcast('jobAsset:open-widget', data), 50);
  }

}
