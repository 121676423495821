import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import * as moment from "moment";
import {SettingsService} from "@app/core";

@Injectable({
    providedIn: 'root'
})
export class CcCommonService {

    _renderer: Renderer2;

    constructor(
        private settings: SettingsService,
        private rendererFactory: RendererFactory2) {
        this._renderer = this.rendererFactory.createRenderer(null, null);
    }

    private customerContractDetails = new BehaviorSubject({});
    currentBasicDetails$ = this.customerContractDetails.asObservable();
    utcFormat = 'YYYY-MM-DD h:mm:ss a';

    setCcBasicDetails(data) {
        this.customerContractDetails.next(data);
    }

    getCcBasicDetails() {
        return this.customerContractDetails.asObservable();
    }

    getUtcStringAsLocaleDate(value: string): Date {
        return new Date(moment(value).utc().format(this.utcFormat));
    }

    getLocaleDateAsUtcString(value: Date): string {
        return moment(value).format();
    }

    validateNumberInput(event: KeyboardEvent): boolean {
        const key = event.keyCode;
        const isValid = (key >= 48 && key <= 57)
            || (key >= 96 && key <= 105)
            || [8, 9, 13, 46, 110, 190, 37, 38, 39, 40].includes(key);
        if (!isValid) {
            event.stopImmediatePropagation();
            event.preventDefault();
            return false;
        }
        return true;
    }

    getRegionalDateFormat(dateType = 'short_date') {
        return this.settings.getRegionalDateFormats()[this.settings.getIntlSettings()[dateType]];
    }

    getCustomLocaleMoment(input?: moment.MomentInput, format?: moment.MomentFormatSpecification): moment.Moment {
        return moment(input, format).locale('en');
    }

    getTzFormattedDateString(value: string | number | Date) {
        const locale = this.settings.getCsLanguage();
        const timeZone = this.settings.getCountryCode().timezone;
        const tzDate = new Date(value).toLocaleString(locale, {timeZone: timeZone});
        const regionalDateFormat = this.getRegionalDateFormat();

        return moment(new Date(tzDate)).format(regionalDateFormat);
    }

    // To set the date-picker position
    setDpContainerPosition(DpInput: HTMLElement, dpContainer: HTMLElement, dpContainerHolder: HTMLElement) {
        if (DpInput && dpContainer) {
            const dpContainerHolderPos = dpContainerHolder.getBoundingClientRect();
            const dpInputPos = DpInput.getBoundingClientRect();

            this._renderer.setStyle(dpContainer, 'position', 'absolute');
            this._renderer.setStyle(dpContainer, 'top', (dpInputPos.bottom - dpContainerHolderPos.top + 1) + 'px');
            this._renderer.setStyle(dpContainer, 'left', (dpInputPos.left - dpContainerHolderPos.left + 1) + 'px');
        }
    }
}
