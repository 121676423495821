import {Component, HostBinding} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {CsAgGridService} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.service";
import {ICsAgGroupCellRendererParams} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid.interface";

@Component({
    selector: 'app-cs-ag-group-cell-renderer',
    templateUrl: './cs-ag-group-cell-renderer.component.html',
    styles: []
})
export class CsAgGroupCellRendererComponent implements ICellRendererAngularComp {

    @HostBinding('class') class = 'w-100 h-100';

    public params: ICsAgGroupCellRendererParams;
    public colId: string;
    public rowId: number;
    public data: object;
    public isDetailOpen = false;

    constructor(private _csAgGrid: CsAgGridService) {
    }

    agInit(params: ICsAgGroupCellRendererParams): void {
        this.params = params;
        this.colId = this.params.column.getColId();
        this.data = params.data;
        this.rowId = this.data['id'] || (+this.params.node.id + 1);
    }

    onCellClicked(event) {
        const rowNode = this.params.node;
        let isNodeExpanded = rowNode.expanded;
        let panelHeight = 0;
        if (this.params.detailParamsSetter) {
            this.params.detailParamsSetter(this.params);
        }

        if (isNodeExpanded && !this.isDetailOpen) {
            this.params.node.setExpanded(false);
            this.params.api.refreshCells();
            panelHeight = -rowNode.detailNode.rowHeight;
            if (this.params.layoutParams) this.params.layoutParams.setGridAutoHeight(panelHeight);
            isNodeExpanded = rowNode.expanded;
        }

        setTimeout(() => {
            this.isDetailOpen = !this.isDetailOpen;
            this._csAgGrid.setNodeState(this.rowId, this.colId, {isExpanded: this.isDetailOpen});
            this.params.node.setExpanded(this.isDetailOpen);
            this.params.api.refreshCells();
            panelHeight = rowNode.detailNode.rowHeight * (isNodeExpanded ? -1 : 1);
            if (this.params.layoutParams) this.params.layoutParams.setGridAutoHeight(panelHeight);
        }, 300);
    }

    get isCellExpanded() {
        const rowState = this._csAgGrid.getNodeState(this.rowId);
        let isExpanded = false;
        if (rowState) {
            isExpanded = !!rowState[this.colId] && rowState[this.colId].isExpanded;
        }

        this.isDetailOpen = isExpanded;
        return isExpanded;
    }

    refresh(params: any): boolean {
        // this.params = params;
        return true;
    }
}
