import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    allowDecimalInput(e:any, precision:number=16, scale:number=2) {

        let key = e.charCode || e.keyCode || 0;
        let isNumeric = ((key == 8) || (key == 9) || (key == 13) || (key == 46)
            || (key == 116) || (key == 123) || ((key >= 35) && (key <= 40))
            || ((key >= 48) && (key <= 57)) || ((key >= 96) && (key <= 105)) || (key == 190 || key == 110) );
        let actionKeys = ((key == 8) || (key == 9) || (key == 46) || (key == 13) || ((key >= 35) && (key <= 40)) );

        if (!isNumeric ) {
            e.preventDefault();
            return;
        }

        if (e.shiftKey ) {
            e.preventDefault();
        }

        let hasDecimal=e.target.value.indexOf('.') !== -1;
        let dotcontains = e.target.value.indexOf(".");
        let newValue = e.target.value;
        let caratPos = e.target.selectionStart;
        if(hasDecimal && (e.keyCode==190 || e.keyCode == 110) ) {
            e.preventDefault();
        }

        if(dotcontains > -1) {
            const arrVal = newValue.split(".");
            let _scale:number = caratPos > dotcontains ? scale-1 : precision-1 ;
            let ind:number = caratPos > dotcontains ? 1 : 0;
            if( arrVal[ind].length > _scale && !actionKeys){
                e.preventDefault();
            }
        }
        else{
            newValue.length > precision-1 && !(key == 190 || key == 110) && !actionKeys && e.preventDefault();
        }
    }
}