import { PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
var CurrencyPipe = /** @class */ (function () {
    // @Input('digits') digitsInfo: string = '1.2-2';
    function CurrencyPipe(ajsScope, settings, locale) {
        this.ajsScope = ajsScope;
        this.settings = settings;
        this.locale = locale;
    }
    CurrencyPipe.prototype.transform = function (value, currencyCode, display, digitsInfo, locale) {
        if (currencyCode === void 0) { currencyCode = this.ajsScope.CustomCurrencyCode; }
        if (display === void 0) { display = 'symbol'; }
        if (digitsInfo === void 0) { digitsInfo = ''; }
        if (locale === void 0) { locale = this.locale; }
        var intlSettings = this.settings._csIntlSettings;
        if (parseInt(intlSettings['currency']) === 0) {
            return formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.2-2');
        }
        else if (parseInt(intlSettings['currency']) === 1) {
            return formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'wide'), currencyCode, '1.0-0');
        }
        else if (parseInt(intlSettings['currency']) === 2) {
            var currency = parseInt(value);
            return getCurrencySymbol(currencyCode, 'wide') + currency.toString();
        }
        else if (parseInt(intlSettings['currency']) === 3) {
            var currency = formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.2-2');
            return currency.toString().replace(/,/g, '');
        }
        else if (parseInt(intlSettings['currency']) === 4) {
            var currency = formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.2-2');
            return currency.toString().replace(/,/g, ' ');
        }
        else if (parseInt(intlSettings['currency']) === 5) {
            var currency = formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.2-2');
            var currency_arr = currency.toString().split(".");
            return currency_arr[0].replace(/,/g, '.') + ',' + currency_arr[1];
        }
        else {
            if (value) {
                if (typeof value === 'string') {
                    value = parseFloat(value);
                }
                return this.ajsScope.CustomCurrency + value.toFixed(2).toString();
            }
            else if (value == 0) {
                return this.ajsScope.CustomCurrency + value.toFixed(2).toString();
            }
            else {
                return '';
            }
        }
    };
    return CurrencyPipe;
}());
export { CurrencyPipe };
