import { Injectable } from '@angular/core';
import { Observable } from "rxjs/internal/Observable";

declare var Notification: any;
@Injectable({
    providedIn: 'root'
})
/*
   This service uses web notification api.
   It used to generate notification on browsers.
   To generate notification instantiate the method.
   generateNotification() and subscribe for the events.

 */
export class PushNotificationsService {
    public permission: Permission;
    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }
    public isSupported(): boolean {
        return 'Notification' in window;
    }
    requestPermission(): void {
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function(status) {
                return self.permission = status;
            });
        }
    }
    create(title: string, options ? : PushNotification): any {
        let self = this;
        return new Observable(function(obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = function(e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function(e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function(e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function() {
                return obs.complete();
            };
        });
    }
    generateNotification(source:Source): Observable<any> {
        let self = this;
        let options = {
                body: source.alertContent,
                icon: "./favicon.ico",
        };
        return self.create(source.title, options);
    }
}
export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
    body ? : string;
    icon ? : string;
    tag ? : string;
    data ? : any;
    renotify ? : boolean;
    silent ? : boolean;
    sound ? : string;
    noscreen ? : boolean;
    sticky ? : boolean;
    dir ? : 'auto' | 'ltr' | 'rtl';
    lang ? : string;
    vibrate ? : number[];
}
export interface Source {
    title : string;
    alertContent: string;
    options ? : PushNotification;
}