import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
var PricingFormattingOptionsComponent = /** @class */ (function () {
    function PricingFormattingOptionsComponent(fb, bsModalRef, proposaltemplateService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.proposaltemplateService = proposaltemplateService;
        this.action = 'save';
        this.proposalTemplatePartsListConfig = {};
        this.formData = {
            id: null,
            capitalisation: 'None',
            lineSpacing: '1',
            textAlign: 'left',
            hBorder: true,
            vBorder: true,
            outlineBorder: true,
            borderStyle: 'solid',
            borderWidth: '1',
            borderColor: '#cccccc',
            rowColor: '#0000000d',
            alternateRowColor: '#0000000d',
            padding: '3px'
        };
        this.options = {
            type: "color",
            showPalette: "false",
            showInput: "true",
            showAlpha: "false",
            showButtons: "false",
            allowEmpty: "false",
            replacerClassName: 'formatting',
            preferredFormat: "hex",
        };
    }
    PricingFormattingOptionsComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        if (this.tagElt == 'title' || this.tagElt == 'textarea') {
        }
        this.buildForm();
        if (this.formatId) {
            this.settingsForm.controls.formatId.setValue(this.formatId);
        }
    };
    PricingFormattingOptionsComponent.prototype.ngAfterViewInit = function () {
        var that = this;
        $('#border-picker').spectrum(this.options);
        $('#border-picker').on('change.spectrum move.spectrum', function (e, tinyColor) {
            if (tinyColor) {
                that.settingsForm.controls.borderColor.setValue(tinyColor.toHexString());
            }
        });
        $('#row-picker').spectrum(this.options);
        $('#row-picker').on('change.spectrum move.spectrum', function (e, tinyColor) {
            if (tinyColor) {
                that.settingsForm.controls.rowColor.setValue(tinyColor.toHexString());
            }
        });
        $('#row-picker1').spectrum(this.options);
        $('#row-picker1').on('change.spectrum move.spectrum', function (e, tinyColor) {
            if (tinyColor) {
                that.settingsForm.controls.alternateRowColor.setValue(tinyColor.toHexString());
            }
        });
        $(".left").addClass('text-align-background');
    };
    PricingFormattingOptionsComponent.prototype.buildForm = function () {
        this.settingsForm = this.fb.group({
            id: [this.formData.id],
            capitalisation: [this.formData.capitalisation],
            lineSpacing: [this.formData.lineSpacing],
            textAlign: [this.formData.textAlign],
            hBorder: [this.formData.hBorder],
            vBorder: [this.formData.vBorder],
            outlineBorder: [this.formData.outlineBorder],
            borderStyle: [this.formData.borderStyle],
            borderWidth: [this.formData.borderWidth],
            borderColor: [this.formData.borderColor],
            rowColor: [this.formData.rowColor],
            alternateRowColor: [this.formData.alternateRowColor],
            padding: [this.formData.padding]
        });
    };
    PricingFormattingOptionsComponent.prototype.chooseAlign = function (option) {
        $('.btn-default').removeClass('text-align-background');
        this.settingsForm.controls.textAlign.setValue(option);
        $("." + option).addClass('text-align-background');
    };
    PricingFormattingOptionsComponent.prototype.save = function () {
        var _this = this;
        var data = {};
        data["formatData"] = this.settingsForm.value;
        if (this.tagElt == 'pricingtable') {
            data['sectionData'] = this.proposalTemplatePartsListConfig;
            this.proposaltemplateService.addOrEditPricingFormattingOptions(data).subscribe(function (resp) {
                if (resp) {
                    _this.onClose.next(data);
                    _this.bsModalRef.hide();
                }
            });
        }
        else {
            this.onClose.next(data);
            this.bsModalRef.hide();
        }
    };
    return PricingFormattingOptionsComponent;
}());
export { PricingFormattingOptionsComponent };
