import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContractDataService {

    private form = new BehaviorSubject('');
    currentForm = this.form.asObservable();

    constructor() { }
    changeForm(form: any) {
        this.form.next(form)
    }

}