import * as i0 from "@angular/core";
var CommonService = /** @class */ (function () {
    function CommonService() {
    }
    CommonService.prototype.allowDecimalInput = function (e, precision, scale) {
        if (precision === void 0) { precision = 16; }
        if (scale === void 0) { scale = 2; }
        var key = e.charCode || e.keyCode || 0;
        var isNumeric = ((key == 8) || (key == 9) || (key == 13) || (key == 46)
            || (key == 116) || (key == 123) || ((key >= 35) && (key <= 40))
            || ((key >= 48) && (key <= 57)) || ((key >= 96) && (key <= 105)) || (key == 190 || key == 110));
        var actionKeys = ((key == 8) || (key == 9) || (key == 46) || (key == 13) || ((key >= 35) && (key <= 40)));
        if (!isNumeric) {
            e.preventDefault();
            return;
        }
        if (e.shiftKey) {
            e.preventDefault();
        }
        var hasDecimal = e.target.value.indexOf('.') !== -1;
        var dotcontains = e.target.value.indexOf(".");
        var newValue = e.target.value;
        var caratPos = e.target.selectionStart;
        if (hasDecimal && (e.keyCode == 190 || e.keyCode == 110)) {
            e.preventDefault();
        }
        if (dotcontains > -1) {
            var arrVal = newValue.split(".");
            var _scale = caratPos > dotcontains ? scale - 1 : precision - 1;
            var ind = caratPos > dotcontains ? 1 : 0;
            if (arrVal[ind].length > _scale && !actionKeys) {
                e.preventDefault();
            }
        }
        else {
            newValue.length > precision - 1 && !(key == 190 || key == 110) && !actionKeys && e.preventDefault();
        }
    };
    CommonService.ngInjectableDef = i0.defineInjectable({ factory: function CommonService_Factory() { return new CommonService(); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
