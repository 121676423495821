<ng-container *ngIf="open">
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-0 " style="padding: 0px;">
              <h4 class="modal-title price-section-title" translate id="gridSystemModalLabel">
                <ng-container *ngIf="pricingsection;else mergeheadersection">
                  {{title}}
              </ng-container>
              <ng-template #mergeheadersection>
                  <span style="color: #3883C1;">{{title}}</span> > <span>Merge pricing records</span>
              </ng-template>
              </h4>
              <div class="help-section help-text">
                <span *ngIf="pricingsection" translate>pricing.section.line.items.help.text</span>
                <span *ngIf="!pricingsection" translate>pricing.section.merge.line.items.help.text</span>
            </div>
          </div>
          <div class="modal-body " [ngClass]="pricingsection ? 'modal-top-align':'merge-modal-top-align'">
            <div class="body-message" >
                <div *ngIf="loading">
                    <div class="loading-wrap">
                        <span class="circle1"></span>
                        <span class="circle2"></span>
                        <span class="circle3"></span>
                        <p class="loading-text">
                            Loading line items...
                        </p>
                    </div>
                </div>
                <div class="mth-5 padding-fifteen" *ngIf="!loading && !isPricingItemsAvailable">
                    <div class="no-results-wrap">
                        <div class="warning-icon-wrap with-info-icon">
                            <span class="ss-info icon fix-left">&nbsp;</span>
                        </div>
                        <!--<div class="warning-message" translate>No.pricing.items.added.yet</div> Some reason its not getting updated need to check-->
                        <div class="warning-message">No pricing item available to add</div>
                    </div>
                </div>
            <ng-container *ngIf="pricingsection;else mergebodysection">
              <div class="display-all sticky-content" [hidden]="!isPricingItemsAvailable || loading">
                  <div class="dropdown" id="dropdownMenuButton" (click)="dropDownToggle($event)">
                    <div class="content-section dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="dropdown-content">
                            <span class="mr-10" [hidden]="typeChoosed == 'supply'" translate>Display.all.labour.parts.and.expenses</span>
                        <span class="mr-10" [hidden]="typeChoosed != 'supply'" translate>Supply and fit</span>
                        </div>
                        <i id='iconupdown' class="fa fa-chevron-down" style="font-size: 10px;" aria-hidden="true"></i>
                    </div>
                      <!-- <div class="display-heading dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="mr-10" [hidden]="typeChoosed == 'supply'" translate>Display.all.labour.parts.and.expenses</span>
                          <span class="mr-10" [hidden]="typeChoosed != 'supply'" translate>Supply and fit</span>
                        </div> -->
                      <div class="dropdown-menu">
                          <span class="dropdown-menu-arrow"></span>
                          <div class="dropdown-menus">
                              <a class="dropdown-item supply-display-all-hover">
                                  <div   translate (click)="chooseType('display_all')">Display.all.labour.parts.and.expenses</div>
                              </a>
                              <a class="dropdown-item supply-display-all-hover">
                                  <div  translate (click)="chooseType('supply')">Supply and fit</div>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div id="showsearch" class="row-fluid small-padding mt-2 search-margin-bottom-align">
                      <div class="search-section">
                          <div class="search-location">
                              <i class="fas fa-search search-icon pricing-section-search-icon"></i>
                              <input id="search" class="search-input-box-align" placeholder="Search" (keyup)="onSearch($event,'inline')" value="" type="search">
                          </div>
                      </div>
                    <!--  <div class="span12">
                          <div class="app-filter search-with-tabs customer-history-search mb-1">
                              <div class="search_box small">
                                  <i class="fas fa-search search-icon pricing-section-search-icon"></i>
                                  <input id="search" placeholder="Search" (keyup)="onSearch($event,'inline')" value="" type="search">
                              </div>
                              <div class="select_all">
                                  <div id="select-all" class="checkbox-all"><input type="checkbox" [checked]="selectAllTop" (change)="handleSelect($event, 'all')"></div>
                                  <span class="select-text" translate>Select all</span>
                              </div>
                          </div>
                      </div>-->
                  </div>
              </div>
              <div class="list-items list-items-alignment" [hidden]="loading">
                  <div class="mth-5 padding-fifteen" *ngIf="isSearching && (typeChoosed=='display_all' && !filteredlabouritems.length && !filteredpartsitems.length && !filteredexpensesitems.length)
                  || (typeChoosed=='supply' && (!filteredsupplyfititems.length || !supplyFititems.length))">
                      <div class="no-results-wrap">
                          <div class="warning-icon-wrap with-info-icon">
                              <span class="ss-info icon fix-left">&nbsp;</span>
                          </div>
                          <!--<div class="warning-message" translate>No.pricing.items.added.yet</div> Some reason its not getting updated need to check-->
                          <div class="warning-message">No pricing items found for this search</div>
                      </div>
                  </div>
                  <ng-container *ngIf="typeChoosed !='supply'">
                      <div class="bold mth-5 mb-1 mt-0 font-size-alignment checkbox-display-center" translate *ngIf="filteredlabouritems.length">
                          <div class="labour-text">
                              <span class="text" translate>Labour</span>
                          </div>
                          <div class="right-side-align-select-all">
                              <input id="labour-select-all" type="checkbox" class="label-items-checkbox pricing-section-inputs" [checked]="selectAllLabour" (change)="handleSelect($event, 'labour')" >
                              <label for="labour-select-all" class="select-all-text">Select all</label>
                          </div>
                      </div>
                      <ng-container *ngFor="let items of filteredlabouritems;let l=index">
                          <div class="list-item-parts" (click)="selectPrices($event, 'labour', items, l)">
                              <div class="input-section">
                                  <input id="{{'labouritem' + l}}" [checked]="items.selected" class="pricing-section-input" type="checkbox" />
                              </div>
                              <div class="checkbox-all" id="{{'labour' + l}}">
                                  <div class="description-text-align">
                                      {{items.description}}
                                  </div>
                                  <div class="price-and-vat-section">
                                      <span class="total-margin-right-align">{{items.totalSalePrice | currency}}</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                              </div>
                          </div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="typeChoosed=='display_all'">
                      <div class="bold mth-5 mb-1 mt-1 font-size-alignment checkbox-display-center" translate *ngIf="filteredpartsitems.length">
                          <div class="part-text">
                              <span class="text" translate>Parts</span>
                          </div>
                          <div class="right-side-align-select-all">
                              <input id="parst-select-all" type="checkbox" class="label-items-checkbox pricing-section-inputs" [checked]="selectAllParts" (change)="handleSelect($event, 'parts')"  >
                              <label for="parst-select-all" class="select-all-text">Select all</label>
                          </div>
                      </div>
                      <ng-container *ngFor="let items of filteredpartsitems;let p=index">
                          <div class="list-item-parts" (click)="selectPrices($event, 'parts',items, p)">
                              <div class="input-section">
                                  <input id="{{'partsitem' + p}}" [checked]="items.selected" class="pricing-section-input" type="checkbox" />
                              </div>
                              <div class="checkbox-all" id="{{'parts' + p}}">
                                  <div class="description-text-align">
                                      {{items.description}}
                                  </div>
                                  <div class="price-and-vat-section" *ngIf="tableType == 'opportunity'">
                                      <span class="total-margin-right-align">{{items.totalSalePrice | currency}}</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                                  <div class="price-and-vat-section" *ngIf="tableType != 'opportunity'">
                                      <span class="total-margin-right-align">N/A</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                              </div>
                          </div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="typeChoosed=='supply'">
                      <div class="bold mth-5 mb-1 mt-1 font-size-alignment checkbox-display-center" translate *ngIf="filteredsupplyfititems.length">
                          <div class="supply-and-fit-text">
                              <span class="text" translate>Supply and fit</span>
                          </div>
                          <div class="right-side-align-select-all">
                              <input id="supply-fit-select-all" type="checkbox" class="label-items-checkbox pricing-section-inputs" [checked]="selectAllSupplyAndFit" (change)="handleSelect($event, 'supply')" >
                              <label for="supply-fit-select-all" class="select-all-text">Select all</label>
                          </div>
                      </div>
                      <ng-container *ngFor="let items of filteredsupplyfititems;let s=index">
                          <div class="list-item-parts" (click)="selectPrices($event, 'supply', items, s)">
                              <div class="input-section">
                                  <input id="{{'supplyitem' + s}}" [checked]="items.selected" class="pricing-section-input" type="checkbox" />
                              </div>
                              <div class="checkbox-all" id="{{'supply' + s}}">
                                  <div class="description-text-align">
                                      {{items.description}}
                                  </div>
                                  <div class="price-and-vat-section" *ngIf="tableType == 'opportunity' || (tableType != 'opportunity' && items.partCostId == 0)">
                                      <span class="total-margin-right-align">{{items.totalSalePrice | currency}}</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                                  <div class="price-and-vat-section" *ngIf="tableType != 'opportunity' && items.partCostId != 0">
                                      <span class="total-margin-right-align">N/A</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                              </div>
                          </div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="typeChoosed!='supply'">
                      <div class="bold mth-5 mb-1 mt-1 font-size-alignment checkbox-display-center" translate *ngIf="filteredexpensesitems.length">
                          <div class="expenses-text">
                              <span class="text" translate>Expenses</span>
                          </div>
                          <div class="right-side-align-select-all">
                          <input id="expenses-select-all" type="checkbox" class="label-items-checkbox pricing-section-inputs" [checked]="selectAllExpenses" (change)="handleSelect($event, 'expenses')"  >
                          <label for="expenses-select-all" class="select-all-text">Select all</label>
                         </div>
                      </div>
                      <ng-container *ngFor="let items of filteredexpensesitems;let e=index">
                          <div class="list-item-parts" (click)="selectPrices($event,'expenses',items, e )">
                              <div class="input-section">
                                  <input id="{{'expensesitem' + e}}" [checked]="items.selected" class="pricing-section-input" type="checkbox" />
                              </div>
                              <div class="checkbox-all" id="{{'expenses' + e}}">
                                  <div class="description-text-align">
                                      {{items.description}}
                                  </div>
                                  <div class="price-and-vat-section">
                                      <span class="total-margin-right-align">{{items.totalSalePrice | currency}}</span>
                                      <ng-container *ngIf="vatEnabled">
                                          <span  class="center-of-line">|</span> <span class="total-margin-left-align">{{items.tax}}% {{taxLabel}}</span>
                                      </ng-container>
                                  </div>
                              </div>
                          </div>
                      </ng-container>
                  </ng-container>
              </div>
          </ng-container>
          <ng-template #mergebodysection>
            <div class="row-fluid pad-15">
                <div class="span12">
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>Description.of.pricing.item</label>
                        <div class="controls" [formGroup]="mergeForm">
                            <input type="text" class="control-input" formControlName="description" autofocus>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
            </div>
          </div>
          <div class="modal-footer">
            <ng-container *ngIf="pricingsection;else mergefootersection">
              <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
              <button *ngIf="typeChoosed!='supply'" [disabled]="mergeEnable" class="btn btn-primary" (click)="mergeSave();">
                  <span translate>Merge.and.save</span>
              </button>
              <button [disabled]="submitEnable" class="btn btn-primary" (click)="savePricing()">
                  <span translate>Save</span>
              </button>
          </ng-container>
          <ng-template #mergefootersection>
              <a href="javascript:void(0)" class="btn" (click)="mergeCancel()">Cancel</a>
              <button [disabled]="!mergeForm.valid" class="btn btn-primary" (click)="mergePricingRecords()">
                  <span translate>Save</span>
              </button>
          </ng-template>
          </div>
        </div>
      </div>
</ng-container>
