import { EventEmitter, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { AddCommunicationComponent } from "@app/shared/communication-mapping/layouts/add-communication/add-communication.component";
import { CommunicationHelperService } from "@app/shared/communication-mapping/services/communication-helper.service";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "@app/core";
var TemplateFormComponent = /** @class */ (function () {
    function TemplateFormComponent(eventDispatcher, modalService, helperService, translate, authService, confirmationBoxHelper, ajsScope) {
        this.eventDispatcher = eventDispatcher;
        this.modalService = modalService;
        this.helperService = helperService;
        this.translate = translate;
        this.authService = authService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.isLoading = true;
        this.emailTemplateList = [];
        this.smsTemplateList = [];
        this.emailTemplateListIndexById = {};
        this.smsTemplateListIndexById = {};
        this.letterScreenLabel = this.translate.instant('letter.templates');
        this.smsScreenLabel = this.translate.instant('sms.templates');
        this.emailScreenLabel = this.translate.instant('email.templates');
        this.selectedSmsTemplate = this.translate.instant('sms.templates');
        this.selectedEmailTemplate = this.translate.instant('email.templates');
        this.selectedLetterTemplate = '';
        this.selectedRolesEvent = new EventEmitter();
        this.selectedRolesList = [];
        this.roleList = [];
        this.previousRolesList = [];
        this.selectedTemplateData = [];
        this.isSelectedEmailTemplateArchived = false;
        this.isSelectedSmsTemplateArchived = false;
        this.skipClearOption = true;
    }
    TemplateFormComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                /* Initial load */
                if (action == 'event:loadedData') {
                    var value = data['data'];
                    // this.setEmailTemplateList(value['emailTemplateList']);
                    // this.setSmsTemplateList(value['smsTemplateList']);
                    _this_1.inputModel = value['inputModel'];
                    var activeEmail = _this_1.helperService.findCurrentTemplate(_this_1.inputModel, 'email');
                    var activeSms = _this_1.helperService.findCurrentTemplate(_this_1.inputModel, 'sms');
                    if (activeEmail) {
                        _this_1.emailTemplateId = activeEmail['templateId'];
                        _this_1.selectedEmailTemplate = activeEmail['title'];
                        _this_1.isSelectedEmailTemplateArchived = activeEmail['isArchived'];
                    }
                    if (activeSms) {
                        _this_1.smsTemplateId = activeSms['templateId'];
                        _this_1.selectedSmsTemplate = activeSms['title'];
                        _this_1.isSelectedSmsTemplateArchived = activeSms['isArchived'];
                    }
                    _this_1.isLoading = false;
                }
                if (action == 'event:updaTemplateDetails') {
                    if (_this_1.inputModel && _this_1.inputModel.title != null) {
                        if (_this_1.inputModel && _this_1.inputModel.title && _this_1.inputModel.title.length > 0) {
                            _this_1.selectedEmailTemplate = _this_1.inputModel.title[0] ? _this_1.inputModel.title[0] : _this_1.translate.instant('email.templates');
                            _this_1.selectedSmsTemplate = _this_1.inputModel.title[1] ? _this_1.inputModel.title[1] : _this_1.translate.instant('sms.templates');
                        }
                        else {
                            _this_1.selectedEmailTemplate = _this_1.translate.instant('email.templates');
                            _this_1.selectedSmsTemplate = _this_1.translate.instant('sms.templates');
                        }
                    }
                }
            }
        });
        this.smsModulePermission = this.authService.hasModuleAccess('Sms');
        this.select2TemplateConfig = {
            width: '100%',
        };
    };
    TemplateFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.previousRolesList) {
            var _this_2 = this;
            var selectedOptionsList_1 = [];
            if (changes.previousRolesList.currentValue.length) {
                this.selectedRolesEvent.emit(this.previousRolesList);
                if (typeof this.previousRolesList !== undefined) {
                    setTimeout(function () {
                        $('.notification-roles option').each(function () {
                            var optionVal = JSON.parse(JSON.stringify($(this).val()));
                            var splittedString = optionVal.split(': ').pop();
                            splittedString = splittedString.replace(/["']/g, "");
                            if (_this_2.previousRolesList.includes(splittedString)) {
                                selectedOptionsList_1.push(optionVal);
                            }
                        });
                        if (selectedOptionsList_1.length) {
                            $('.notification-roles option').select2('val', selectedOptionsList_1).trigger('change');
                        }
                    }, 500);
                }
            }
        }
    };
    TemplateFormComponent.prototype.ngAfterViewInit = function () {
    };
    TemplateFormComponent.prototype.selectedRoles = function (value) {
        var _this_1 = this;
        if (value && typeof value !== undefined) {
            this.selectedRolesList = [];
            value.forEach(function (val) {
                var splittedString = val.split(': ').pop();
                splittedString = splittedString.replace(/["']/g, "");
                _this_1.selectedRolesList.push(splittedString);
            });
            this.selectedRolesEvent.emit(this.selectedRolesList);
        }
    };
    TemplateFormComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    TemplateFormComponent.prototype.setEmailTemplateList = function (data) {
        var _this_1 = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this_1.emailTemplateList.push({
                id: parseInt(item.id),
                text: item.templateName
            });
            _this_1.emailTemplateListIndexById[item.id] = item;
        });
    };
    TemplateFormComponent.prototype.setSmsTemplateList = function (data) {
        var _this_1 = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this_1.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            _this_1.smsTemplateListIndexById[item.id] = item;
        });
    };
    TemplateFormComponent.prototype.showAddCommunication = function (commType) {
        var initialState = {};
        this.sidePanelModalRef = this.modalService.show(AddCommunicationComponent, {
            initialState: initialState,
            size: 'xl',
            'class': 'cs-form-add-communication',
            panelType: 'sidePanel',
        });
    };
    TemplateFormComponent.prototype.setTemplateDetails = function (templateDetails, type) {
        var oldTemplateId = null;
        if (type == 'email') {
            oldTemplateId = this.emailTemplateId;
            this.selectedEmailTemplate = templateDetails.title;
            this.isSelectedEmailTemplateArchived = false;
        }
        else if (type == 'sms') {
            oldTemplateId = this.smsTemplateId;
            this.selectedSmsTemplate = templateDetails.title;
            this.isSelectedSmsTemplateArchived = false;
        }
        else if (type == 'letter') {
            oldTemplateId = this.letterTemplateId;
        }
        var _a = this.inputModel, templates = _a.templates, activeSegmentationValue1 = _a.activeSegmentationValue1, activeSegmentationValue2 = _a.activeSegmentationValue2, customerId = _a.customerId;
        if (templates.length == 0) {
            templates.push({
                id: 0,
                communicationType: type,
                templateId: templateDetails.templateId,
                segmentationValue1: activeSegmentationValue1,
                segmentationValue2: activeSegmentationValue2,
                customerId: customerId,
                title: templateDetails['templateName'],
                message: templateDetails['message'],
                subject: templateDetails['subject']
            });
        }
        else {
            var activeItem = templates.find(function (item) {
                return item.communicationType == type;
            });
            if (activeItem) {
                //activeItem['id'] = parseInt(templateDetails.id);
                activeItem['templateId'] = parseInt(templateDetails.templateId);
                activeItem['message'] = templateDetails.message;
                activeItem['subject'] = templateDetails.subject;
                activeItem['title'] = templateDetails.templateName;
            }
            else {
                templates.push({
                    id: 0,
                    communicationType: type,
                    templateId: templateDetails.templateId,
                    segmentationValue1: activeSegmentationValue1,
                    segmentationValue2: activeSegmentationValue2,
                    customerId: customerId,
                    title: templateDetails['templateName'],
                    message: templateDetails['message'],
                    subject: templateDetails['subject']
                });
            }
        }
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['updateFormValue'], {
            inputModel: this.inputModel,
            for: 'update'
        });
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['updateTemplateDetails'], {
            type: type,
            value: templateDetails,
            inputModel: this.inputModel
        });
    };
    TemplateFormComponent.prototype.clearConfirmation = function (val) {
        var _this_1 = this;
        /* if (this.inputModel && this.inputModel.activeSegmentationValue1 == -1 && this.inputModel.activeSegmentationValue2 == -1) {
             this.clearValue(val);
         } else {
             if (this.inputModel.templates.find((obj: any) => (obj.segmentationValue1 == -1 && obj.segmentationValue2 == -1))) {*/
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to clear the template ?', this.ajsScope)
            .then(function () {
            _this_1.clearValue(val);
            _this_1.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['save'], {});
        }).catch(function () {
            if (!_this_1.skipClearOption)
                _this_1.clearValue(val);
        });
        /* } else {
             this.clearValue(val);
         }
     }*/
    };
    TemplateFormComponent.prototype.clearValue = function (val) {
        var _this_1 = this;
        var templates = this.inputModel.templates;
        var segmentationValue1 = this.inputModel.allowSegmentation ? this.inputModel.activeSegmentationValue1 : -1;
        var segmentationValue2 = this.inputModel.allowSegmentation ? this.inputModel.activeSegmentationValue2 : -1;
        var activeItem = templates.find(function (item) {
            return (item.communicationType == val &&
                (item.segmentationValue1 == segmentationValue1 || item.segmentationValue1 == (_this_1.inputModel.allowSegmentation ? _this_1.inputModel.activeSegmentationValue1 : null)) &&
                (item.segmentationValue2 == segmentationValue2 || item.segmentationValue2 == (_this_1.inputModel.allowSegmentation ? _this_1.inputModel.activeSegmentationValue2 : null)));
        });
        if (activeItem) {
            if (activeItem.communicationType == 'email') {
                this.emailScreenLabel = this.translate.instant('email.templates');
                this.selectedEmailTemplate = this.translate.instant('email.templates');
            }
            else if (activeItem.communicationType == 'sms') {
                this.smsScreenLabel = this.translate.instant('sms.templates');
                this.selectedSmsTemplate = this.translate.instant('sms.templates');
            }
            this.inputModel.templates = this.inputModel.templates.filter(function (item) {
                return !(item.id == activeItem.id && item.communicationType == activeItem.communicationType && item.segmentationValue1 == activeItem.segmentationValue1);
            });
            this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['updateFormValue'], {
                inputModel: this.inputModel,
                from: 'clearOption'
            });
        }
    };
    return TemplateFormComponent;
}());
export { TemplateFormComponent };
